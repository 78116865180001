import React from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// method
import { setSortData } from "../methods/common.methods";
// constants
import { TabKey } from "../MasterMaintenance.constants";

export const MasterMaintenanceRoleTbody = (props: any) => {
  const { pageInfo, roleGroup, order, orderBy } = props;
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.role",
  });

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          const roleGroupNameList = roleGroup.filter(
            (item: any) => row.role_group == item.role_group
          );
          const roleGroupName =
            roleGroupNameList.length > 0
              ? roleGroupNameList[0].role_group_name
              : row.role_group;
          return (
            <TableRow className={classes.tbody} hover tabIndex={-1} key={index}>
              <TableCell>{roleGroupName}</TableCell>
              <TableCell className={classes.nowrap}>{row.role_id}</TableCell>
              <TableCell className={classes.nowrap}>{row.role_name}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => props.handleEdit(row, TabKey.role)}
                >
                  {t("edit")}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => props.handleDelete(row)}
                >
                  {t("delete")}
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
