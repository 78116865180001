import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AT0202
export const GetReadRepairJobPartsListAPI = (job_no: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_parts/${affiliate_cd}/${job_no}`,
    config: config,
  };
  return http(param);
};

// AT0205
export const MultipleUpdateRepairJobPartsAPI = (
  job_no: any,
  data: any,
  config: any
) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_parts/${affiliate_cd}/${job_no}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};
