import React, { useState, useEffect } from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import { InformationIcon } from "../../../modules/components/informationIcon/InformationIcon";
// import { ReactComponent as Euro } from "../../../assets/icons/Euro.svg";
// api
import api from "../../../modules/api/Api";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// method
import {
  getApiResultStatus,
  getCodeName,
  getTransportCostShow,
} from "../../../modules/common.methods";
import { TRANSPORT_MESSAGE } from "../constants/claimDetail.constants";
import { getWeightByAffilicateCd } from "../../../modules/common.methods";
import { affiliateCDLan } from "../../../modules/constants/common.constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "20px",
    },
    attentionCheck: {
      display: "flex",
      alignItems: "center",
      "& .MuiFormControlLabel-root": {
        marginRight: "0",
      },
    },
    pickUp: {
      display: "flex",
      alignItems: "center",
      "& .MuiFormControlLabel-root": {
        marginRight: "0",
      },
    },
    freightPosition: {
      display: "flex",
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      paddingLeft: "5px",
      alignItems: "center",
    },
    freight: {
      marginRight: "15px",
    },
    informationIcon: {
      display: "flex",
      alignItems: "center",
    },
    freightTextField: {
      width: "211px",
      height: "30px",
      marginTop: "4px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    shipping_method: { marginLeft: "100px", marginRight: "15px" },
    groupArea: {
      display: "inline-block",
      marginTop: "20px",
      //width: "225px",
      border: "1px solid #767676",
      padding: "15px 10px 5px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "12px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
    },
    checkboxList: {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
    },
    unit: {
      paddingLeft: "5px",
    },
    errorMessage: {
      color: "red",
    },
    euro: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      fill: "#fff",
    },
  })
);

export const ClaimDetailPartsControl = (props: any) => {
  const classes = useStyles();
  const { t, getAffiliateCDLan } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    setFormListValue,
    dataList,
    responseList,
    handleGetPrice,
    errorRegisterList,
    formErrorList,
    errorMessage,
    transportMessage,
    setTransportMessage,
    setTransportListValue,
    setFormErrorListValue,
    apiLoading,
    attentionCheckFlgBlur,
    clearErrorCheck,
    itemNoErrorList,
    freightList,
    region,
    models,
  } = props;

  const buttonDisableFlag = () => {
    const _itemNoErrorListNumber =
      itemNoErrorList.length > 0
        ? itemNoErrorList.filter(
            (item: any) => item.type !== "6" && item.type !== "8"
          ).length
        : 0;
    const _errorRegisterListNumber =
      errorRegisterList.length > 0
        ? errorRegisterList.filter(
            (item: any) => item.type !== "6" && item.type !== "8"
          ).length
        : 0;
    return (
      _errorRegisterListNumber !== 0 ||
      _itemNoErrorListNumber !== 0 ||
      (dataList.claim_line && dataList.claim_line.length === 0)
    );
  };

  const { getCommonConfig, getUserInfoRepair } = useCommonInfo();
  let config = getCommonConfig() as any;
  let userInfoRepair = getUserInfoRepair() as any;

  const getModelMessage = async (flag: any) => {
    let item_no: any;
    let item: any;
    let _newList: any = JSON.parse(JSON.stringify(dataList.claim_line));
    if (flag) {
      let _transportMessage: any = {
        weight: transportMessage.weight,
        shipping_method: transportMessage.shipping_method,
        transport_way_pick_up: transportMessage.transport_way_pick_up,
        transport_way_return: transportMessage.transport_way_return,
        transport_cost_flg: transportMessage.transport_cost_flg,
      };
      apiLoading(true);
      // getWeight
      await api
        .getItemDetailForWcoAPI(encodeURIComponent(dataList.model_no), config)
        .then(({ code, response }: { code: any; response: any }) => {
          if (getApiResultStatus(code)) {
            let _weight: number = response.weight;
            if (!_weight) {
              _transportMessage.weight = 0;
              setFormErrorListValue(
                "transport_message_error",
                com("messages.weight_error")
              );
            } else {
              _transportMessage.weight = _weight;
              _transportMessage.shipping_method =
                _weight > getWeightByAffilicateCd(config.affiliate_cd) ? 2 : 1;
              item_no =
                _weight > getWeightByAffilicateCd(config.affiliate_cd)
                  ? freightList[1]
                  : freightList[0];
            }
          } else {
            if (code === 404) {
              setFormErrorListValue(
                "transport_message_error",
                com("messages.weight_error")
              );
            } else {
              errorMessage(response);
            }
            apiLoading(false);
          }
        });
      if (item_no) {
        apiLoading(true);
        await api
          .getReadItemMasterAPI(encodeURIComponent(item_no), config)
          .then(({ code, response }: { code: any; response: any }) => {
            apiLoading(false);
            console.log("getReadItemMasterAPI", response);
            if (getApiResultStatus(code)) {
              let qty = 0;
              if (
                transportMessage.transport_way_pick_up &&
                transportMessage.transport_way_return
              ) {
                qty = 2;
              }
              if (
                (transportMessage.transport_way_pick_up &&
                  !transportMessage.transport_way_return) ||
                (transportMessage.transport_way_return &&
                  !transportMessage.transport_way_pick_up)
              ) {
                qty = 1;
              }
              item = {
                item_no: response.item_no,
                description: response.description,
                order_qty: qty,
                // webmsi_region: userInfoRepair.country,
                webmsi_region: region,
                webmsi_spec: models,
              };
              if (dataList.claim_line.length > 0) {
                // 存在チェック
                const _freightList = dataList.claim_line.filter((item: any) =>
                  freightList.includes(item.item_no)
                );
                // 存在なし場合は
                if (_freightList.length === 0) {
                  _newList.push(item);
                } else {
                  // 存在あり場合は
                  // dataList.claim_line.forEach((item: any) => {
                  //   if (
                  //     item.item_no !== "SWSPEDI" &&
                  //     item.item_no !== "SWTRANS"
                  //   ) {
                  //     _newList.push(item);
                  //   }
                  // });
                  // 配送料を追加
                  // _newList.push(item);
                }
              } else {
                // 配送料を追加
                _newList.push(item);
              }
            } else {
              if (code === 404) {
                setFormErrorListValue(
                  "transport_message_error",
                  com("messages.MS0004", {
                    rowNum: t("Item_No"),
                    param1: item_no,
                  })
                );
              } else {
                errorMessage(response);
              }
            }
          });
      }
      setFormListValue("claim_line", _newList);
      _transportMessage.transport_cost_flg = true;
      setTransportMessage(_transportMessage);
      // setShowItem(true);
    } else {
      // setShowItem(false);
      setTransportMessage({});
      // 配送料の削除
      if (dataList.claim_line.length > 0) {
        // 存在チェック
        const _freightList = dataList.claim_line.filter((item: any) =>
          freightList.includes(item.item_no)
        );
        // 存在あり場合は
        if (_freightList.length > 0) {
          _newList = [];
          // 存在あり場合は
          dataList.claim_line.forEach((item: any) => {
            if (!freightList.includes(item.item_no)) {
              _newList.push(item);
            }
          });
        }
        setFormListValue("claim_line", _newList);
      }
      // clear message
      setTransportMessage(TRANSPORT_MESSAGE);
    }
  };

  useEffect(() => {
    if (affiliateCDLan.hidden.includes(config.affiliate_cd)) {
      setFormListValue("attention_check_flg", true);
    }
  }, [config.affiliate_cd]);

  const changeFreightQty = () => {
    let qty = 0;
    if (
      transportMessage.transport_way_pick_up &&
      transportMessage.transport_way_return
    ) {
      qty = 2;
    }
    if (
      (transportMessage.transport_way_pick_up &&
        !transportMessage.transport_way_return) ||
      (transportMessage.transport_way_return &&
        !transportMessage.transport_way_pick_up)
    ) {
      qty = 1;
    }

    let newList = JSON.parse(JSON.stringify(dataList.claim_line));
    if (newList.length > 0) {
      let fiterList = newList.filter((item: any) =>
        freightList.includes(item.item_no)
      );
      if (fiterList.length > 0) {
        fiterList[0].order_qty = qty;
      }
      console.log("changeFreightQty", newList, qty);
      setFormListValue("claim_line", newList);
    }

    if (
      !transportMessage.transport_way_pick_up &&
      !transportMessage.transport_way_return
    ) {
      setFormErrorListValue(
        "transport_message_error",
        com("messages.transport_way_error")
      );
    } else {
      clearErrorCheck("transport_message_error");
    }
  };
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.pickUp}>
          {getTransportCostShow(config.affiliate_cd) && (
            <>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={transportMessage.transport_cost_flg}
                        onChange={(e: any) => getModelMessage(e.target.checked)}
                      />
                    }
                    label={t("txt_Picked_up")}
                  />
                </FormGroup>
              </FormControl>
              <InformationIcon txtInner={["picked_up"]} />
            </>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetPrice}
            disabled={buttonDisableFlag()}
          >
            {/* <Euro className={classes.euro}></Euro> */}
            {t("btn_get_price")}
          </Button>
        </div>
      </div>
      {getTransportCostShow(config.affiliate_cd) &&
        (!transportMessage.transport_cost_flg ? null : (
          <div>
            <div className={classes.freightPosition}>
              <div className={classes.freight}>{t("txt_freight")}</div>
              <div className={classes.informationIcon}>
                <div>
                  <TextField
                    variant="outlined"
                    className={classes.freightTextField}
                    value={transportMessage.weight}
                    disabled={true}
                  />
                </div>
              </div>
              <div className={classes.unit}>kg</div>
              <div className={classes.shipping_method}>
                {t("txt_shipping_methods")}
              </div>
              <div className={classes.informationIcon}>
                <div>
                  <TextField
                    variant="outlined"
                    className={classes.freightTextField}
                    value={getCodeName(
                      transportMessage.shipping_method,
                      com("shipping_method")
                    )}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={classes.groupArea}>
                <h3 className={classes.groupTitle}>{t("txt_tarnsport_way")}</h3>
                <FormControl component="fieldset">
                  <FormGroup className={classes.checkboxList}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={transportMessage.transport_way_pick_up}
                          onChange={(e: any) =>
                            setTransportListValue(
                              "transport_way_pick_up",
                              e.target.checked
                            )
                          }
                          onBlur={changeFreightQty}
                        />
                      }
                      label={t("txt_pick_up")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={transportMessage.transport_way_return}
                          onChange={(e: any) =>
                            setTransportListValue(
                              "transport_way_return",
                              e.target.checked
                            )
                          }
                          onBlur={changeFreightQty}
                        />
                      }
                      label={t("txt_return")}
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </div>
        ))}
      <p className={classes.errorMessage}>
        {formErrorList.transport_message_error}
      </p>
      {formErrorList.transport_message_error === "" && (
        <p className={classes.errorMessage}>
          {formErrorList.transport_message_error2}
        </p>
      )}
      {!affiliateCDLan.hidden.includes(config.affiliate_cd) && (
        <div className={classes.attentionCheck}>
          <FormControl
            component="fieldset"
            error={formErrorList.attention_check_flg_error !== ""}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={dataList.attention_check_flg}
                    disabled={true}
                    onChange={(e: any) =>
                      setFormListValue("attention_check_flg", e.target.checked)
                    }
                    name="gilad"
                  />
                }
                onBlur={attentionCheckFlgBlur}
                label={getAffiliateCDLan("parts_message", config.affiliate_cd)}
              />
            </FormGroup>
            <FormHelperText>
              {formErrorList.attention_check_flg_error}
            </FormHelperText>
          </FormControl>
          {affiliateCDLan.iconMarkShow.includes(config.affiliate_cd) && (
            <InformationIcon
              txtInner={["attention_check_flg"]}
              rightFlg={true}
            />
          )}
        </div>
      )}
    </div>
  );
};
