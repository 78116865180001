import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { SimulateSearchResultTableThead } from "./Simulate.SearchResult.Table.Thead";
import { SimulateSearchResultTableTbody } from "./Simulate.SearchResult.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotionListContainer: {
      maxHeight: "560px",
      // "overflow-y": "hidden",
    },
    item: {
      height: "56px",
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
    },
    itemShow: {
      "& span": {
        marginRight: "5px",
      },
    },
    itemHide: {
      display: "none",
    },
  })
);
export const SimulateSearchResultTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.simulate" });

  return (
    <Paper>
      <TableContainer className={classes.promotionListContainer}>
        <Table stickyHeader>
          <SimulateSearchResultTableThead
            indeterminate={props.indeterminate}
            allChecked={props.allChecked}
            onSelectAllClick={props.handleSelectAllClick}
          />
          <SimulateSearchResultTableTbody
            data={props.data}
            setData={props.setData}
            selected={props.selectedArray}
            handleClick={props.handleClick}
            freeGoodsList={props.freeGoodsList}
            setFreeGoodsValue={props.setFreeGoodsValue}
            qtyFoucs={props.qtyFoucs}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
};
