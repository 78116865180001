import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { DraftsSearchResultTableThead } from "./Drafts.SearchResult.Table.Thead";
import { DraftsSearchResultTableTbody } from "./Drafts.SearchResult.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "60%"
    },
    promotionListContainer: {
      maxHeight: "560px"
    },
    item: {
      height: "56px",
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
    }
  })
);

export const DraftsSearchResultTable = (props: any) => {
  const classes = useStyles();

  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.promo_no);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray, row.promo_no);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data.slice(0);
      const newSelecteds = data.map((item: any) => item.promo_no);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  // page
  const pageInfo = {
    page: props.page,
    rowsPerPage: props.rowsPerPage,
  };

  useEffect(() => {
    // init search
    if (props.needResetOrderBy) {
      props.setOrderBy(["created_date"]);
      props.setOrder("desc");
      props.setPage(0);
      props.setNeedResetOrderBy(false);
    }
  }, [props.needResetOrderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = props.orderBy.indexOf(property) > -1 && props.order === "asc";
    props.setOrder(isAsc ? "desc" : "asc");
    props.setOrderBy([property]);
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer className={classes.promotionListContainer}>
        <Table stickyHeader>
          <DraftsSearchResultTableThead
            indeterminate={props.indeterminate}
            allChecked={props.allChecked}
            onSelectAllClick={handleSelectAllClick}
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={handleRequestSort}
          />
          <DraftsSearchResultTableTbody
            data={props.data}
            pageInfo={pageInfo}
            order={props.order}
            orderBy={props.orderBy}
            handleClick={(event: any, row: any) => handleClick(event, row)}
            selected={props.selectedArray}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.data.length}
        rowsPerPage={props.rowsPerPage}
        page={props.data.length === 0 ? 0 : props.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
    </Paper>
  );
};
