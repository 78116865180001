import React from "react";
// hooks
import { useStyles } from "../hooks/common.styles";
// UI
import TextField from "@material-ui/core/TextField";

export const JobsDetailPartsDrawingMemoForEndUser = (props: any) => {
  const classes = useStyles();
  const {
    MemoForEndUser,
    setMemoForEndUserValue,
    memoRegisterMessage,
    memoDisabled,
    title,
  } = props;

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{title}</h3>
      <TextField
        value={MemoForEndUser}
        className={classes.multilineTextField}
        placeholder={memoRegisterMessage}
        variant="outlined"
        multiline
        rows={4}
        disabled={memoDisabled}
        onChange={(e) => setMemoForEndUserValue(e.target.value)}
      />
    </div>
  );
};
