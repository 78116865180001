// UI
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  item_area_right: {
    paddingLeft: "100px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  image: {
    border: "1px solid gray",
    "& svg": {
      maxWidth: "100px",
      width: "100%",
      height: "auto",
    },
  },
  message: {
    // display: "flex",
    // width: "100%",
    textAlign: "center",
  },
  text: {
    fontSize: "14px",
    paddingLeft: "10px",
  },
  type: {
    display: "block",
    borderBottom: "1px dashed  gray",
    paddingBottom: "5px",
    marginBottom: "5px",
  },
  uploadButton: {
    height: "30px",
    padding: "0 16px",
  },
  button: {
    paddingTop: "10px",
  },
  uploadInput: {
    display: "none",
  },
  list: {
    padding: "5px 0 0 0",
    margin: "0",
    "& li": {
      listStyle: "none",
      padding: "5px 0 0 0",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px dotted gray",
    },
    "& li + li": {
      borderBottom: "1px dotted gray",
    },
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  explainArea: {
    display: "flex",
    alignItems: "center",
  },
}));
