import React from "react";
// components
import { JobsDetailGeneralRepairModelTableList } from "./JobsDetail.General.RepairModel.TableList";
import { JobsDetailGeneralRepairModelForm } from "./JobsDetail.General.RepairModel.Form";
// hooks
import { useStyles } from "../hooks/useStyles";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    modelArea: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    leftArea: {
      width: "58%",
    },
    RightArea: {
      width: "39%",
    },
    groupArea: {
      marginTop: "-3px",
      border: "1px solid #767676",
      padding: "20px 10px 10px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "0",
      },
      "& textarea": {
        width: "100%",
      },
    },
  })
);

export const JobsDetailGeneralRepairModel = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.label",
  });
  const {
    dataList,
    formList,
    setFormListValue,
    formErrorList,
    setFormErrorListValue,
    getRepairHistory,
    onBlurOfModelNo,
    onBlurOfSerialNo,
    userPermisssionInfo,
    repairJobPartsList,
    setButtonDisableFlg,
    setRepairHistoryList,
  } = props;

  const handleLinkClick = () => {
    props.setStartFlg(false);
    props.setPartsTabActive(false);
  };

  return (
    <div className={myClasses.groupArea}>
      <h3 className={classes.groupTitle}>{t("repair_model")}</h3>
      <div className={myClasses.modelArea}>
        <div className={myClasses.leftArea}>
          <JobsDetailGeneralRepairModelForm
            formList={formList}
            setFormListValue={(project: any, value: any) =>
              setFormListValue(project, value)
            }
            formErrorList={formErrorList}
            setFormErrorListValue={setFormErrorListValue}
            getRepairHistory={getRepairHistory}
            dataList={dataList}
            onBlurOfModelNo={onBlurOfModelNo}
            onBlurOfSerialNo={onBlurOfSerialNo}
            userPermisssionInfo={userPermisssionInfo}
            repairJobPartsList={repairJobPartsList}
            setButtonDisableFlg={setButtonDisableFlg}
            setRepairHistoryList={setRepairHistoryList}
          />
        </div>
        <div className={myClasses.RightArea}>
          {props.data && props.data.length > 0 && (
            <JobsDetailGeneralRepairModelTableList
              data={props.data}
              handleLinkClick={handleLinkClick}
              userPermisssionInfo={props.userPermisssionInfo}
            />
          )}
        </div>
      </div>
    </div>
  );
};
