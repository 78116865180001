import React from "react";
// hooks
import { useStyles } from "../hooks/PartsDrawing.styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { determineDataType } from "../../../modules/common.methods";
export const JobsDetailPartsDrawingTbody = (props: any) => {
  const classes = useStyles();
  const {
    data,
    tableSelectedList,
    emphasizeParts,
    handleClick,
    handleDoubleClick,
    handleMouseOver,
    handleMouseleave,
    openLink,
  } = props;

  const setClassName = (row: any) => {
    if (
      tableSelectedList.includes(row.uniqueID) ||
      (emphasizeParts !== "" && emphasizeParts == row.uniqueID)
    ) {
      return classes.emphasize;
    }
  };

  return (
    <TableBody className={classes.tbody}>
      {determineDataType(data) &&
        data.map((row: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <TableRow
                id={row.displayId}
                hover
                tabIndex={-1}
                onClick={(event) => handleClick(event, row)}
                onDoubleClick={(event) => handleDoubleClick(event, row)}
                className={setClassName(row)}
                onMouseOver={() => handleMouseOver(row.drawingNo, "mouseover")}
                onMouseLeave={() => handleMouseleave(row.drawingNo, "")}
              >
                <TableCell className={classes.nowrapCell}>
                  {row.displayId}
                </TableCell>
                <TableCell className={classes.nowrapCell}>
                  {row.partNo}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell className={classes.BOQty}>{row.order_qty}</TableCell>
                <TableCell className={classes.technicalInformation_link}>
                  {row.open && row.open.no ? (
                    <span onClick={() => openLink(row.open.path, "open")}>
                      {row.open.no}
                    </span>
                  ) : null}
                </TableCell>
                <TableCell className={classes.technicalInformation_link}>
                  {row.close && row.close.no ? (
                    <span onClick={() => openLink(row.close.path, "close")}>
                      {row.close.no}
                    </span>
                  ) : null}
                </TableCell>
                <TableCell className={classes.nowrapCell}>
                  {row.interchangeCode}
                </TableCell>
              </TableRow>
              {determineDataType(row.setParts) &&
                row.setParts.map((setPartsItem: any, setPartsIndex: number) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={"setParts" + setPartsIndex}
                    className={setClassName(setPartsItem)}
                    onClick={(event) => handleClick(event, setPartsItem)}
                    onDoubleClick={(event) =>
                      handleDoubleClick(event, setPartsItem)
                    }
                    onMouseOver={() =>
                      handleMouseOver(setPartsItem.drawingNo, "mouseover")
                    }
                    onMouseLeave={() =>
                      handleMouseleave(setPartsItem.drawingNo, "")
                    }
                  >
                    <TableCell className={classes.noteNo}>
                      {setPartsItem.optionalNo}
                    </TableCell>
                    <TableCell>{setPartsItem.partNo}</TableCell>
                    <TableCell>{setPartsItem.name}</TableCell>
                    <TableCell className={classes.BOQty}>
                      {setPartsItem.qty}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{setPartsItem.interchangeCode}</TableCell>
                  </TableRow>
                ))}
              {determineDataType(row.notes) &&
                row.notes.map((notesItem: any, notesIndex: number) => (
                  <TableRow hover tabIndex={-1} key={"notes" + notesIndex}>
                    <TableCell colSpan={2} className={classes.noteNo}>
                      {notesItem.noteNo}
                    </TableCell>
                    <TableCell> {notesItem.note}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};
