import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import {
  date,
  time,
} from "../../../modules/components/numberFormat/NumberFormat.Date";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { i18text } from "../../../modules/common.methods";

export const JobsDetailGeneralRepairMediaTbody = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });

  const editDateTime = (dateTime: string) => {
    let editedDateTime = "";
    if (dateTime && dateTime !== "") {
      let dateTimeLong = new Date(dateTime).getTime();
      editedDateTime = date(dateTimeLong) + " " + time(dateTimeLong);
    }
    return editedDateTime;
  };

  const editDescription = (
    table_id: string,
    column_id: string,
    content: any
  ) => {
    let editedDescription = "";
    let getKey = table_id + "_" + column_id;
    if (getKey) {
      let statusName = content;
      let array = com(column_id);
      if (Array.isArray(array) && content && content !== "") {
        for (let index in array) {
          if (array[index].code.toString() === content) {
            statusName = array[index].name;
            break;
          }
        }
      }
      if (
        column_id === "entry_date" ||
        column_id === "received_date" ||
        column_id === "warranty_valid_date" ||
        column_id === "quote_sent_date" ||
        column_id === "reply_date" ||
        column_id === "estimated_repair_start date" ||
        column_id === "repair_started_date" ||
        column_id === "repair_end_date" ||
        column_id === "return_shipment_date" ||
        column_id === "pickup_scheduled_date" ||
        column_id === "pickup_date" ||
        column_id === "completed_date" ||
        column_id === "estimated_arrival_date"
      ) {
        if (isNaN(statusName) && !isNaN(Date.parse(statusName))) {
          statusName = editDateTime(statusName);
        }
      }
      editedDescription =
        i18text(com, "messages_table_name.", getKey) + ": " + statusName;
    }
    return editedDescription;
  };

  const editDescriptionByUpdateType = (row: any) => {
    let editedDescription = "";
    if (row.update_type === 1) {
      editedDescription = com("messages.MS0043", {
        tableName: i18text(com, "messages_table_name.", row.edit_table_id),
      });
    } else if (row.update_type === 3) {
      editedDescription = com("messages.MS0044", {
        tableName: i18text(com, "messages_table_name.", row.edit_table_id),
      });
    } else {
      editedDescription = editDescription(
        row.edit_table_id,
        row.edit_column_id,
        row.edit_content
      );
    }
    return editedDescription;
  };

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody className={classes.tbody}>
      {props.data.repair_updated_history &&
        setSortData(
          props.data.repair_updated_history,
          "desc",
          "created_date"
        ).map((row: any, index: number) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell className={classes.dateCell}>
                {editDateTime(row.created_date)}
              </TableCell>
              <TableCell className={classes.dateCell}>
                {row.created_by ? row.created_by : ""}
              </TableCell>
              <TableCell className={classes.DescriptionCell}>
                {editDescriptionByUpdateType(row)}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
