import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// root
import { Link } from "react-router-dom";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// route
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";

type Order = "asc" | "desc";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
  })
);

export const DraftsSearchResultTableTbody = (props: any) => {
  const { pageInfo, selected, order, orderBy } = props;
  const classes = useStyles();
  const isSelected = (name: string) => selected.indexOf(name) > -1;
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);

        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]" ||
            orderBys[0] === "promo_type"
          ) {
            const aValue = Number(aValues[0]);
            const bValue = Number(bValues[0]);
            if (bValues === null && aValues !== null) {
              sort = -1;
            } else if (bValue !== null && aValue === null) {
              sort = 1;
            } else if (bValue < aValue) {
              sort = -1;
            } else if (bValue > aValue) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          const isItemSelected = isSelected(row.promo_no);
          const path =
            GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) + `?promotionNo=${row.promo_no}&drafts=true`;
        return (
          <TableRow
              className={classes.tableRow}
              hover
              onClick={(event) => props.handleClick(event, row)}
              tabIndex={-1}
              key={row.promo_no}
              selected={isItemSelected}
            >
            <TableCell padding="checkbox"  data-testid={"draft_item_checkbox"+index}>
              <Checkbox checked={isItemSelected}  />
            </TableCell>
            <TableCell className={classes.nowrap} align="center">
              {!row.created_date || row.created_date === ""
              ? ""
              : date(new Date(row.created_date).getTime())}
            </TableCell>
            <TableCell  data-testid={"resultList_promotion_type"+index} className={classes.nowrap} align="center">
              {com("promotion_type").map((option: any) => {
                if (option.code === row.promo_type) {
                  return option.name
                }
              })}
            </TableCell>
            <TableCell  data-testid={"resultList_promo_name"+index} className={classes.nowrap} align="center">
              {row.promo_name}
            </TableCell>
            <TableCell data-testid={"resultList_go"+index} className={classes.nowrap} align="center">
              <Link to={path}>{t("go")}</Link>
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  );
};
