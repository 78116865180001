import React, { useState } from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
    divWidth:{
      width: "500px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
  })
);

export const JobsDetailErrorMessageBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "models.model_window",
  });

  const contents = <div className={classes.divWidth}><p className={classes.message}>{props.message}</p></div>;

  const windownButton = (
    <div className={classes.buttonControl}>
      <Button 
      className={classes.buttonWidth}
      variant="contained" 
      onClick={props.handleClose} 
      color="primary">
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_warning"
      contents={contents}
      button={windownButton}
      openFlg={props.open}
      handleCloseEvent={props.handleClose}
    />
  );
};
