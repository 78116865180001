import React from "react";
// hooks
import { useStyles } from "../hooks/configuration.useStyles";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useLocale } from "../../../modules/hooks/UseLocale";
import { SUPPORT_LANGUAGES } from "../../../modules/constants/supportLanguages";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomArea: {
      paddingTop: "30px",
    },
    groupArea: {
      marginTop: "20px",
      border: "1px solid #767676",
      padding: "15px 10px 5px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "15px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
      marginTop: "9px",
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        width: "20%",
        minWidth: "100px",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
        color: "#686868",
      },
    },
  })
);

export const PersonInformation = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.configuration" });
  const { config } = props;
  const classes = myStyles();
  const getName = () => {
    return Object.keys(config).length > 0
      ? `${config.given_name ? config.given_name : ""} ${
          config.middle_name ? config.middle_name : ""
        } ${config.family_name ? config.family_name : ""}`
      : "";
  };
  return (
    <div className={classes.bottomArea}>
      <div className={classes.groupArea}>
        <h3 className={classes.groupTitle}>{t("user_info")}</h3>
        <table className={classes.tableLayout}>
          <tbody>
            <tr>
              <th>{t("name")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={getName()}
                />
              </td>
            </tr>
            <tr>
              <th>{t("e_mail")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  value={config.mail_address}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
