/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// component
// import { AllocateTechnicianTableThead } from "./Jobs.SearchResult.Title.Allocate.Table.Thead";
// import { AllocateTechnicianTableTbody } from "./Jobs.SearchResult.Title.Allocate.Table.Tbody";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AllocateModelContainer: {
      width: "703px",
      height: "520px",
      // border: "1px solid rgba(198, 209, 221, 0.5)",
    },
    title: {
      background: "rgba(240, 242, 244, 0.5)",
      textAlign: "right",
      padding: "10px",
    },
    cell: {
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "5px 10px",
    },
    buttonControl: {
      display: "flex",
      // width: "100%",
      justifyContent: "space-between",
    },
    root: {
      //   width: "100%",
    },
    container: {
      maxHeight: "517px",
      width: "100%",
      "& span": {
        fontWeight: "bolder"
      }
    },
    buttonSetting: {
      marginLeft: "50px",
    },
    makitaImg: {
      width: "70%",
      float: "right"
    },
    tTeble: {
      "& td": {
        textAlign: "left"
      }
    },
    titleHead: {
      textAlign: "center"
    },
    windowOut: {
      width: "100%"
    }
  })
);

export const ClaimDetailEndUserConditions = (props: any) => {
  const classes = useStyles();
  const { t, com } = useLocale();

  const handleClick = (value: any) => props.setSelectedValue(value);
  // Allocate Model window show / hidden
  const handleClose = () => {
    props.CondiionsClose(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? "desc" : "asc");
    props.setOrderBy(property);
  };

  const AllocateModelWindow = (
    <div className={classes.AllocateModelContainer}>
      <Paper className={classes.root}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4} style={{ width: "100%" }}>
              <img src={require('../../../assets/icons/Makita.png')} className={classes.makitaImg} />
            </Grid>
          </Grid>
        </div>
        <h3 className={classes.titleHead}>Datenschutzhinweis von</h3>
        <h3>Unsere Kontaktinformationen</h3>
        <table className={classes.tTeble}>
          <tr>
            <td> Name</td>
            <td>text</td>
          </tr>
          <tr>
            <td> Anschrift:</td>
            <td>text</td>
          </tr>
          <tr>
            <td> Telefonnummer:</td>
            <td>text</td>
          </tr>
          <tr>
            <td>  E-Mail:</td>
            <td>text</td>
          </tr>
        </table>
        <h3>Welche Art von personenbezogenen Daten sammeln wir?</h3>
        <p>
          Derzeit sammeln und verarbeiten wir die folgenden Daten:
        </p>
        <ul>
          <li>Personenidentifikatoren, Kontakte und Merkmale (zum Beispiel Name und Kontaktinformationen)</li>
          <li>Kauf- und Transaktionsinformationen (wie z. B. das Produkt, das Sie gekauft haben)</li>
          <li>Zahlungsinformationen (wie z. B. Ihre Zahlungsmethode, Lieferadresse und
            Rechnungsanschrift)</li>
          <li>Kundenserviceinformationen (beispielsweise Anfragen an den Kundenservice,
            Kommentare und Reparaturhistorie)</li>
          <li>Informationen bezüglich Ihrer persönlichen oder beruflichen Interessen und Erfahrungen
            mit unseren Produkten sowie der bevorzugten Arten der Kontaktaufnahme</li>
        </ul>
        <h3>
          Wie und warum wir die personenbezogenen Daten erheben
        </h3>
        <p>
          Der Großteil der personenbezogenen Daten, die wir verarbeiten, wird uns direkt von Ihnen zur Verfügung gestellt.
        </p>
        <p>
          Soweit wir personenbezogene Daten kraft Gesetz oder unter den Bedingungen eines mit Ihnen geschlossenen Vertrags erheben müssen und Sie die geforderten Daten nicht bereitstellen, können wir eventuell den mit Ihnen geschlossenen Vertrag oder den mit Ihnen beabsichtigten Vertrag nicht erfüllen (zum Beispiel, um Ihnen Waren zu liefern oder für Sie Dienstleistungen zu erbringen). In diesem Fall müssen wir eventuell ein Produkt oder eine Dienstleistung stornieren, aber wir werden Sie benachrichtigen, wenn dies der Fall sein sollte.
        </p>
        <p>
          Wir verwenden die Daten, die Sie uns geben, um
        </p>
        <ul>
          <li>
            Sie als Neukunden zu registrieren
          </li>
          <li>
            Ihre Produkte zu registrieren
          </li>
          <li>
            Ihre Bestellung/Ihre Anfrage zu bearbeiten und auszuliefern, einschließlich der
            Abwicklung von Zahlungen, Gebühren und Kosten
          </li>
          <li>
            Ihnen Vorschläge über Waren oder Dienstleistungen, die für Sie von Interesse sein
            könnten, zu machen oder Empfehlungen zu geben.
          </li>
        </ul>
        <p>
          Wir können für diese Zwecke diese Daten mit unseren Dienstleistern, einschließlich der Makita Werkzeug GmbH, teilen.
        </p>
        <p>
          Gemäß der Datenschutz-Grundverordnung (DSGVO) sind die Rechtsgrundlagen für die Verarbeitung dieser Daten die folgenden:
        </p>
        <h3>
          (a) Ihre Einwilligung, sofern Sie uns Ihre Einwilligung für einen bestimmten Zweck geben. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie uns über die vorstehenden Kontaktinformationen kontaktieren.
        </h3>
        <h3>
          (b) Wir haben eine vertragliche Verpflichtung.
        </h3>
        <h3>
          (c) Wir haben eine rechtliche Verpflichtung.
        </h3>
        <h3>
          (d) Wir haben ein berechtigtes Interesse.
        </h3>
        <br />
        <h3>
          Internationale Datenübermittlungen
        </h3>
        <p>
          Einige der Makita Corporation sind außerhalb des Europäischen Wirtschaftsraums („EWR“) ansässig, so dass die Verarbeitung Ihrer personenbezogenen Daten durch diese Unternehmen, Lieferanten und Dienstleister den Transfer von Daten außerhalb des EWR beinhaltet.
        </p>
        <p>
          Wann immer wir Ihre personenbezogenen Daten außerhalb des EWR transferieren, stellen wir sicher, dass diese ein ähnliches Schutzniveau gewährleisten, indem wir dafür sorgen, dass wenigstens eine der folgenden Schutzmaßnahmen ergriffen wird:
        </p>
        <ul>
          <li>
            Wir können Ihre personenbezogenen Daten an Länder übermitteln, die ein angemessenes Schutzniveau für personenbezogene Daten bieten.
          </li>
          <li>
            Soweit wir gewisse Dienstleister einsetzen, werden spezifische Verträge entsprechend der DSGVO verwendet.
          </li>
        </ul>
        <p>
          Bitte wenden Sie sich an uns, wenn Sie weitere Informationen zu dem spezifischen Mechanismus wünschen, den wir verwenden, wenn wir Ihre personenbezogenen Daten außerhalb des EWR transferieren.
        </p>
        <br />
        <h3>
          Wie lange verwenden wir Ihre personenbezogenen Daten?
        </h3>
        <p>
          Wir speichern Ihre personenbezogenen Daten nur so lange, wie dies notwendig ist, um die Zwecke, für die wir diese gesammelt haben, zu erfüllen. Diese Zwecke beinhalten die Erfüllung rechtlicher, regulatorischer, steuerlicher Anforderungen und Rechnungslegungs- und Berichtspflichten.
        </p>
        <p>
          Für die Festlegung einer geeigneten Speicherfrist für personenbezogene Informationen berücksichtigen wir die Menge, Art und Sensibilität personenbezogener Daten, das potentielle Schadensrisiko durch eine nicht autorisierte Verwendung oder Offenlegung Ihrer personenbezogenen Daten, die Zwecke, für die wir Ihre personenbezogenen Daten verwenden, und ob wir diese Zwecke durch andere Mittel erreichen können, sowie die geltenden rechtlichen, regulatorischen, steuerlichen Anforderungen und Rechnungslegungs- und Berichtspflichten.
        </p>
        <h3>
          Ihre Datenschutzrechte
        </h3>
        <p>
          Gemäß dem Datenschutzgesetz haben Sie u. a. die folgenden Rechte:
        </p>
        <p>
          <span>Auskunftsrecht</span>
          – Sie haben das Recht, Kopien Ihrer personenbezogenen Daten von uns zu verlangen.
        </p>
        <p>
          <span>Recht auf Berichtigung</span>
          – Sie haben das Recht, die Berichtigung Ihrer personenbezogenen Daten von uns zu verlangen, wenn Sie der Meinung sind, dass diese unrichtig sind. Sie haben ferner das Recht, die Vervollständigung von Informationen, die Ihrer Meinung nach unvollständig sind, von uns zu verlangen.
        </p>
        <p>
          <span>Recht auf Löschung </span>
          – Sie haben das Recht, unter gewissen Umständen die Löschung Ihrer personenbezogenen Daten von uns zu verlangen.
        </p>
        <p>
          <span>Recht auf Einschränkung der Verarbeitung </span>
          – Sie haben das Recht, unter gewissen Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten von uns zu verlangen.
        </p>
        <p>
          <span>Widerspruchsrecht</span>
          – Sie haben das Recht, unter gewissen Umständen Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen.
        </p>
        <p>
          <span>Recht auf Datenübertragbarkeit</span>
          – Sie haben unter gewissen Umständen das Recht, von uns die Übertragung der personenbezogenen Daten, die Sie uns gegeben haben, an eine andere Organisation oder an Sie zu verlangen.
        </p>
        <p>
          Für die Ausübung Ihrer Rechte entstehen Ihnen keine Kosten. Jedoch können wir eine kleine Gebühr verlangen, wenn Ihre Forderung eindeutig unbegründet, wiederholt oder unzumutbar ist. Alternativ können wir uns unter diesen Umständen weigern, Ihrer Aufforderung nachzukommen.
        </p>
        <p>
          Bitte kontaktieren Sie uns, wie oben dargelegt, wenn Sie eine Anfrage stellen möchten.
        </p>
        <br />
        <h3>
          Wie Sie sich beschweren können?
        </h3>
        <p>
          Sollten Sie Bedenken hinsichtlich der Verwendung Ihrer personenbezogenen Daten durch uns haben, können Sie sich bei uns beschweren, indem Sie uns, wie oben dargelegt, kontaktieren.
        </p>
        <p>
          Sie können sich auch bei der Regierungsbehörde an Ihrem Standort beschweren, wenn Sie mit der Art und Weise, wie wir Ihre Daten verwenden, unzufrieden sind.
        </p>
        <table className={classes.tTeble}>
          <tr>
            <td> Name der Regierungsbehörde:</td>
            <td>text</td>
          </tr>
          <tr>
            <td> Adresse:</td>
            <td>text</td>
          </tr>
          <tr>
            <td> Telefonnummer:</td>
            <td>text</td>
          </tr>
          <tr>
            <td>Website oder E-Mail-Adresse:</td>
            <td>text</td>
          </tr>
        </table>

      </Paper>
    </div>
  );

  const AllocateModelWindowButton = (
    <div className={classes.windowOut}>
      <div>
        <Checkbox
          color="primary"
        />
        I confirm that I have read the safety instructions and agree with the conditions
      </div>
      <div className={classes.buttonControl}>

        <Button variant="contained" onClick={handleClose}>
          Download Privacy Notice
        </Button>
        <Button
          className={classes.buttonSetting}
          variant="contained"
          color="primary"
        >
          ok
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <ModelWindow
        title="claim_detail_PrivacyNotice"
        contents={AllocateModelWindow}
        button={AllocateModelWindowButton}
        openFlg={props.CondiionsOpen}
        handleCloseEvent={handleClose}
      />
    </>
  );
};
