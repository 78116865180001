import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& .MuiFormHelperText-root": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "25px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& dl": {
        display: "flex",
        margin: 0,
        marginRight: "20px",
      },
      "& dd": {
        marginLeft: "15px",
        margin: 0,
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
        marginTop: "-4px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
    },
    rows02: {
      display: "flex",
      width: "100%",
      height: "22px",
      flexWrap: "wrap",
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
        marginTop: "-4px",
      },
    },
    promotionType: {
      width: "215px",
    },
    priority: {
      width: "80px",
    },
    allowOverlap: {
      marginTop: "-10px",
    },
    exclusive: {
      marginTop: "-10px",
    },
    webShopFlag: {
      marginTop: "-10px",
    },
    othersFlag: {
      marginTop: "-10px",
    },
    textField: {
      marginTop: "-4px",
      "& input": {
        height: "20px",
        lineHeight: "20px",
        padding: "5px",
      },
    },
    analysisCode: {
      display: "flex",
      "& .MuiFormControl-root": {
        width: "150px",
        marginRight: "10px",
      },
    },
    line: {
      border: "1px solid #767676",
      padding: "10px 5px 5px 10px",
      borderRadius: "10px",
      width:"15%",
      marginTop: "20px",
    },
    groupArea: {
      display: "flex",
      marginTop: "5px",
    },
  })
);

export const General = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    searchValueList,
    setSearchValue,
    changeDataListen,
    errorValueList,
    analysisList,
    reviewFlg,
    fromDraftsFlag,
    analysisCodeDescription,
    changeAnalysisCodeDescription,
  } = props;
  const classes = useStyles();

  const getOptionLabel2 = (option: any) =>
    option.analysis_cd + ":" + option.description || "";
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  return (
    <div className={classes.root}>
      <h1>{t("general")}</h1>
      <div className={classes.rows}>
        <dl>
          <dt>{t("promotion_type")}</dt>
          <dd className={classes.promotionType} data-testid="promotion_type">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                id="promotion_type"
                value={searchValueList.promo_type}
                onChange={(e: any) =>
                  setSearchValue("promo_type", e.target.value)
                }
                onBlur={(e) => changeDataListen("promo_type", e.target.value)}
                disabled={
                  (props.createPromotionFlag !== "" && !fromDraftsFlag) ||
                  reviewFlg
                }
              >
                {Array.isArray(com("promotion_type")) ? (
                  com("promotion_type").map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
        {!props.disabledCondition && (
          <dl>
            <dt>{t("priority")}</dt>
            <dd className={classes.priority} data-testid="priority">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={errorValueList.priority_error !== ""}
              >
                <Select
                  id="priority"
                  value={searchValueList.priority}
                  onChange={(e: any) =>
                    setSearchValue("priority", e.target.value)
                  }
                  onBlur={(e) => changeDataListen("priority", e.target.value)}
                  disabled={reviewFlg}
                  native
                >
                  <option value={""}></option>
                  {Array.isArray(com("priority_option")) ? (
                    com("priority_option").map((option: any) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
                <FormHelperText>{errorValueList.priority_error}</FormHelperText>
              </FormControl>
            </dd>
          </dl>
        )}
        <dl>
          <dt>{t("analysis_cd")}</dt>
          <dd className={classes.analysisCode} data-testid="analysis_cd">
            <Autocomplete
              id="analysis_cd"
              freeSolo
              disableClearable
              options={analysisList}
              getOptionLabel={getOptionLabel2}
              filterOptions={filterOptions2}
              inputValue={searchValueList.analysis_cd}
              value={searchValueList.analysis_cd}
              disabled={reviewFlg}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "200px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) =>
                option.analysis_cd.toString().toUpperCase() ===
                searchValueList.analysis_cd.toString().toUpperCase()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  onBlur={() => {
                    props.onBlurOfAnalysisCode();
                  }}
                  error={errorValueList.analysis_cd_error !== ""}
                  helperText={errorValueList.analysis_cd_error}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  setSearchValue("analysis_cd", value.analysis_cd);
                  changeAnalysisCodeDescription(value.analysis_cd);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  // const val = value.replace(/[^\w]/g, "").substr(0, 15);
                  const val = value.substr(0, 15);
                  setSearchValue("analysis_cd", val);
                  changeAnalysisCodeDescription(val);
                }
              }}
            />
            <div>{analysisCodeDescription}</div>
          </dd>
        </dl>
        {!props.disabledCondition && (
          <div className={classes.allowOverlap} data-testid="allow_overlap">
            <FormControlLabel
              checked={searchValueList.allow_overlap === "0" ? false : true}
              value="allow_overlap"
              control={<Checkbox color="primary" />}
              label={t("allow_overlap")}
              labelPlacement="end"
              disabled={reviewFlg}
              onChange={(e: any) =>
                setSearchValue(
                  "allow_overlap",
                  searchValueList.promo_type === "50"
                    ? "1"
                    : e.target.checked === false
                    ? "0"
                    : "1"
                )
              }
            />
          </div>
        )}

        <div className={classes.exclusive} data-testid="exclusive_flag">
          <FormControlLabel
            checked={searchValueList.exclusive_flag === "0" ? false : true}
            value="exclusive_flag"
            control={<Checkbox color="primary" />}
            label={t("exclusive_flag")}
            labelPlacement="end"
            disabled={reviewFlg}
            onChange={(e: any) =>
              setSearchValue(
                "exclusive_flag",
                e.target.checked === false ? "0" : "1"
              )
            }
          />
        </div>
      </div>     
      <div className={classes.line}>
      {t("applied_for")}
      <div className={classes.groupArea}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={errorValueList.webshop_error !== ""}
        >
        <div className={classes.rows02}>
          <div className={classes.webShopFlag}>
              <FormControlLabel
                checked={searchValueList.webshop_flag === "0" ? false : true}
                value="webshop_flag"
                control={<Checkbox color="primary" />}
                label={t("webshop_flag")}
                labelPlacement="end"
                onChange={(e: any) =>
                  setSearchValue(
                    "webshop_flag",
                    e.target.checked === false ? "0" : "1"
                  )
                }
              />
            </div>
            <div className={classes.othersFlag}>
              <FormControlLabel
                checked={searchValueList.others_flag === "0" ? false : true}
                value="others_flag"
                control={<Checkbox color="primary" />}
                label={t("others_flag")}
                labelPlacement="end"
                onChange={(e: any) =>
                  setSearchValue(
                    "others_flag",
                    e.target.checked === false ? "0" : "1"
                  )
                }
              />
            </div>
          </div> 
          <FormHelperText>{errorValueList.webshop_error}</FormHelperText>
        </FormControl>       
        </div>     
      </div>       
    </div>
  );
};
