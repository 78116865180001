import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM0302
export const GetReadItemMasterAPI = (item_no: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/items/${affiliate_cd}/${item_no}`,
    config: config,
  };
  return http(param);
};

// AM0303
export const UpdateItemMasterAPI = (data: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/items/${affiliate_cd}/${data.item_no}`,
    config: config,
    method: methods.patch,
    data: data,
  };
  return http(param);
};

// AM0305
export const GetItemDetailListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/items/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

// AM0307
export const GetItemDetailForJobsAPI = (model_no: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/items/job/${affiliate_cd}/${model_no}`,
    config: config,
  };
  return http(param);
};

// AM0309
export const GetItemDetailForWcoAPI = (model_no: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/items/wco/${affiliate_cd}/${model_no}`,
    config: config,
  };
  return http(param);
};

// get base item list
export const GetBaseItemListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/items/base/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
