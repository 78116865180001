import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM1101
export const CreatePrintSettingAPI = (sub: any, data: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/print_settings/${affiliate_cd}/${sub}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM1102
export const GetPrintSettingListAPI = (sub: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/print_settings/${affiliate_cd}/${sub}`,
    config: config,
  };
  return http(param);
};

// AM1103
export const DeletePrintSettingAPI = (
  printer_id: any,
  service_center_id: any,
  form_id: any,
  sub: any,
  data: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/print_settings/${affiliate_cd}/${sub}/${form_id}/${service_center_id}/${printer_id}`,
    method: methods.delete,
    data,
    config: config,
  };
  return http(param);
};
