import React, { useContext, useEffect, FC } from "react";
import { useCommonInfo } from "../hooks/CommonInfoProvider";
import {
  WebSocketEventLocationModel,
  WebSocketEventNotificationModel,
  WebSocketHeartBeatData,
  WebSocketMessageType,
} from "../models/WebSocketEventModel";
import {
  NotificationContext,
  NotificationContextType,
} from "../hooks/NotificationContext";
import {
  APIAudience,
  AUTH0_AUDIENCES,
} from "../constants/api.audience.constants";

const heartRate = 9 * 60 * 1000; // Set 9 minutes because API Gateway idle timeout is 10 minutes.
let client: { isConnected: any; getClient: any; close?: () => void };
let heartBeat: number;

const WebSocketComponent: FC = () => {
  const { getCommonConfig } = useCommonInfo();
  const commonConfig = getCommonConfig() as any;
  let token = commonConfig.token;
  const context: NotificationContextType = useContext(NotificationContext);

  useEffect(() => {
    // config.repairShowFlag Repair部分が表示・非表示用フラグ
    if (token !== undefined && commonConfig.repairShowFlag) {
      client = reconnectingSocket(
        `${commonConfig.system_attribute.websocket_endpoint}?token=`,
        context,
        token,
        commonConfig
      );
      console.log("websocket token", token, context, new Date());
    }
  }, [token]);

  return <></>;
};

async function getToken(
  config: any = "",
  apiAudience: APIAudience = AUTH0_AUDIENCES.MAKMATE
) {
  var audience =
    apiAudience === AUTH0_AUDIENCES.WEBMSI
      ? process.env.REACT_APP_AUTH0_WEBMSI_API_AUDIENCE!
      : process.env.REACT_APP_AUTH0_MAKMATE_API_AUDIENCE!;
  var scope =
    apiAudience === AUTH0_AUDIENCES.WEBMSI
      ? process.env.REACT_APP_AUTH0_WEBMSI_API_SCOPE!
      : process.env.REACT_APP_AUTH0_MAKMATE_API_SCOPE!;

  // @ts-ignore
  if (window.Cypress) {
    const itemKey = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`;
    const auth0 = JSON.parse(window.localStorage.getItem(itemKey)!);
    console.log(auth0);
    return Promise.resolve(auth0.body.access_token);
  } else {
    return config
      .getAccessTokenSilently({
        audience: audience,
        scope: scope,
      })
      .then(
        (res: any) => res,
        (error: any) => {
          console.log("websocket token error", error);
          //  return error ? error : "error";
          return "error";
        }
      );
  }
}

/**
 * Healthcheck at heartRate intervals.
 */
const HeartBeat = () => {
  console.log("websocket HeartBeat", new Date());
  return window.setInterval(() => {
    console.log("websocket heartRate", client.isConnected(), new Date());
    if (client.isConnected()) {
      client.getClient().send(WebSocketHeartBeatData);
    } else {
      console.log("connected failed");
    }
  }, heartRate);
};

/**
 * Connect and reconnect WebSocket.
 * @param url websocket endpoint with auth token.
 */
const reconnectingSocket = (
  url: string,
  context: NotificationContextType,
  token: any,
  commonConfig: any
) => {
  console.log("websocket reconnectingSocket", url + token, context, new Date());
  let client: WebSocket;
  let isConnected = false;
  let reconnectOnClose = true;

  const start = async () => {
    console.log("websocket start", url + token, context, new Date());
    let _token = token;
    _token = await getToken(commonConfig, "");

    if (_token && _token !== "error") {
      const _url = url + _token;
      client = new WebSocket(_url);
      client.onopen = () => {
        console.log("websocket onopen", _url, context, new Date());
        isConnected = true;
        client.send(WebSocketHeartBeatData); // To check connection_id
        clearInterval(heartBeat);
        HeartBeat();
      };
    } else {
      if (_token === "error") {
        isConnected = false;
        // Reconnecting
        setTimeout(start, 3000);
      }
    }
    const close = client.close;

    client.close = () => {
      console.log("websocket close", new Date());
      reconnectOnClose = false;
      close.call(client);
    };

    client.onmessage = (event) => {
      console.log("websocket onmessage", new Date());

      let message;
      try {
        message = JSON.parse(event.data);
        if (message.notification_id !== undefined) {
          context.setPushNotification(message);
        } else if (message.length > 0) {
          context.setLocationControl(message);
        }
      } catch (e) {
        console.log(event.data);
      }
    };

    client.onerror = (e) => {
      console.log("websocket onerror", new Date());
      console.error(e);
    };

    client.onclose = () => {
      console.log("websocket onclose", new Date());
      isConnected = false;
      // Reconnecting
      setTimeout(start, 3000);
    };
  };

  start();

  return {
    close: () => client.close(),
    getClient: () => client,
    isConnected: () => isConnected,
  };
};

// const switchWebSocketMessageType = (message: any) => {
//   if (message.notification_id !== undefined) {
//     return
//   } else if  {
//     return
//   }
//   return
// };

export default WebSocketComponent;
