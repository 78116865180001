import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateAppRoutes, RouteModel } from "../../pages/AppRoutes";
import { useAuth0Redirect } from "../hooks/UseAuth0Redirect";
import { useDrawer } from "../hooks/UseDrawer";
import { NavigationBar } from "./navigation/Navigation.Bar";
import { NavigationDrawerMenu } from "./navigation/drawers/Navigation.Drawer.Menu";
import { NavigationDrawerNotification } from "./navigation/drawers/Navigation.Drawer.Notification";
import { SecureHttpRequestProvider } from "../hooks/SecureHttpRequestProvider";
import { useHistory } from "react-router-dom";
import { getAppRouteKey, PathKey } from "../../pages/AppRoutes";
import { useCommonInfo } from "../hooks/CommonInfoProvider";
import { TabKey } from "../../pages/locationControl/LocationControl";
import WebSocketComponent from "./WebSocket";
import {
  NotificationContext,
  NotificationContextProvider,
  NotificationContextType,
  useNotitificationContext,
} from "../hooks/NotificationContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 2,
      position: "relative",
    },
    background: {
      zIndex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(10),
      [theme.breakpoints.only("xs")]: {
        marginLeft: theme.spacing(7),
      },
    },
  })
);

export const PrivateContents: FC = () => {
  const classes = useStyles();

  const { resetRedirect } = useAuth0Redirect();
  const menuDrawer = useDrawer(false);
  const notificationDrawer = useDrawer(false);
  const {
    setHotKey,
    setLocationHotKey,
    getLocationHotKey,
    setHistoryRouter,
  } = useCommonInfo();

  // From next time, redirect to Auth0 page.
  resetRedirect();

  // Webhook operation

  const history = useHistory() as any;

  useEffect(() => {
    const path =
      history.location.pathname.indexOf("?") !== -1
        ? history.location.pathname.split("?")[0]
        : history.location.pathname;
    const key = String(getAppRouteKey(history.location.pathname));
    setHistoryRouter(key);
  }, [history.location]);

  const getBackgroundColor = () => {
    const key = getAppRouteKey(history.location.pathname);
    let color = "";
    if (key === PathKey.LOCATIONCONTROL) {
      const backgroundTabName = getLocationHotKey() as any;
      switch (backgroundTabName.locationTabName) {
        case TabKey.REPAIR:
          color = "#E2F0D9";
          break;
        case TabKey.PUTAWAY:
          color = "#FFF2CC";
          break;
        case TabKey.SENDBACK:
          color = "#FBE5D6";
          break;
        case TabKey.DISPOSE:
          color = "#DEEBF7";
          break;
      }
    }
    return { background: color };
  };
  
  const keyPress = (event: any) => {
    const key = getAppRouteKey(history.location.pathname);
    if (key === PathKey.LOCATIONCONTROL) {
      const backgroundTabName = getLocationHotKey() as any;
      if (event.key === "PageDown") {
        switch (backgroundTabName.locationTabName) {
          case TabKey.REPAIR:
            setLocationHotKey("locationTabName", TabKey.PUTAWAY);
            break;
          case TabKey.PUTAWAY:
            setLocationHotKey("locationTabName", TabKey.SENDBACK);
            break;
          case TabKey.SENDBACK:
            setLocationHotKey("locationTabName", TabKey.DISPOSE);
            break;
          case TabKey.DISPOSE:
            setLocationHotKey("locationTabName", TabKey.REPAIR);
            break;
        }
      } else if (event.key === "PageUp") {
        switch (backgroundTabName.locationTabName) {
          case TabKey.REPAIR:
            setLocationHotKey("locationTabName", TabKey.DISPOSE);
            break;
          case TabKey.PUTAWAY:
            setLocationHotKey("locationTabName", TabKey.REPAIR);
            break;
          case TabKey.SENDBACK:
            setLocationHotKey("locationTabName", TabKey.PUTAWAY);
            break;
          case TabKey.DISPOSE:
            setLocationHotKey("locationTabName", TabKey.SENDBACK);
            break;
        }
      }
    }
    if (key === PathKey.JOBS) {
      if (event.key === "F8") {
        setHotKey("handleF8Search", true);
      }
    }
    if (key === PathKey.JOBSDETAIL) {
      if (event.key === "F2") {
        setHotKey("handleF2Search", true);
      }
      if (event.key === "F8") {
        setHotKey("handleF8Save", true);
      }
      if (event.key === "F9") {
        setHotKey("handleF9Refresh", true);
      }
      if (event.key === "F10") {
        event.preventDefault();
        setHotKey("handleF10Register", true);
      }
      if (event.key === "F11") {
        event.preventDefault();
        setHotKey("handleF11Complete", true);
      }
      if (event.key === "PageDown") {
        setHotKey("handlePageDown", true);
      }
      if (event.key === "PageUp") {
        setHotKey("handlePageUp", true);
      }
    }
    if (key === PathKey.PROMOTION) {
      if (event.key === "F2") {
        setHotKey("handleF2PromotionCustomerSearch", true);
      }
    }
    if (key === PathKey.PROMOTIONDETAIL) {
      if (event.key === "F2") {
        setHotKey("handleF2PromotionDetailCustomerSearch", true);
      }
      if (event.key === "F6") {
        event.preventDefault();
        setHotKey("handleF6RegisterPromotion", true);
      }
      if (event.key === "F8") {
        event.preventDefault();
        setHotKey("handleF8SaveToDraft", true);
      }
      if (event.key === "F9") {
        event.preventDefault();
        setHotKey("handleF9Refresh", true);
      }
    }
    if (key === PathKey.ORDERDETAIL) {
      if (event.key === "F6") {
        event.preventDefault();
        setHotKey("handleF6OrderDetailSubmit", true);
      }
    }
    if (key === PathKey.SIMULATE) {
      if (event.key === "F2") {
        setHotKey("handleF2SimulateCustomerSearch", true);
      }
      if (event.key === "F6") {
        event.preventDefault();
        setHotKey("handleF6Simulate", true);
      }
    }
  };

  return (
    <SecureHttpRequestProvider>
      <div onKeyDown={(e) => keyPress(e)} tabIndex={0}>
        <div className={classes.background} style={getBackgroundColor()}></div>
        <div className={classes.root}>
          <NotificationContextProvider>
            <NavigationBar
              onToggleMenuDrawerEvent={menuDrawer.onToggleDrawer}
              onToggleNotificationDrawerEvent={
                notificationDrawer.onToggleDrawer
              }
            />
            <NavigationDrawerMenu
              isDrawerOpen={menuDrawer.isDrawerOpen}
              onCloseEvent={menuDrawer.onCloseDrawer}
              onMouseOverDrawerEvent={menuDrawer.onMouseOverDrawer}
              onMouseLeaveDrawerEvent={menuDrawer.onMouseLeaveDrawer}
            />
            <Switch>
              {PrivateAppRoutes.map((m: any, i: any) => {
                return (
                  <Route
                    key={i}
                    path={m.path}
                    exact
                    render={(props) => (
                      <main className={classes.content}>
                        <m.component<RouteModel> {...props} routes={m.routes} />
                      </main>
                    )}
                  />
                );
              })}
            </Switch>
            <WebSocketComponent />
            <NavigationDrawerNotification
              isDrawerOpen={notificationDrawer.isDrawerOpen}
              onToggleNotificationDrawerEvent={
                notificationDrawer.onToggleDrawer
              }
            />
          </NotificationContextProvider>
        </div>
      </div>
    </SecureHttpRequestProvider>
  );
};
