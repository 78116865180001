export const methods = {
  get: "GET",
  post: "POST",
  patch: "PATCH",
  delete: "DELETE",
};

export const lastModified = "Last-Modified";

export const responseStatusCodes = {
  success: 200,
  noContent: 204,
};

export const IsSuccessResponse = (c: number): boolean => ((c / 100) | 0) === 2;
export const IsNothingData = (c: number): boolean => c === 404;

export const Sum = (...n: number[]): number =>
  n.filter((f) => f !== undefined).reduce((a, v) => a + v, 0);

export const DIFF_KEY = {
  tool_info: [
    "model_no",
    "serial_no",
    "condition_of_tool",
    "device_runtime",
    "purchased_date",
    "received_date",
    "repair_date",
    "manufactured_year",
    "manufactured_month",
    "error_type",
    "guarantee_type",
    "customer_po_no",
    "attention_check_flg",
    "privacy_check_flg",
  ],

  claim_note: ["claim_no", "sub_no", "note_type", "note"],

  claim_address: [
    "customer_type",
    "company_name",
    "first_name",
    "last_name",
    "postal_code",
    "address_line_1",
    "address_line_2",
    "city",
    "state",
    "country_code",
    "mail_address",
    "tel_no",
    "fax_no",
    "freight_distance",
    "enduser_no",
  ],
};

export const semicolon = [
  { affiliate_cd: "MD", sign: ";" },
  { affiliate_cd: "MFN", sign: ";" },
  { affiliate_cd: "MOS", sign: ";" },
  { affiliate_cd: "MSW", sign: ";" },
  { affiliate_cd: "MNW", sign: ";" },
  { affiliate_cd: "MES", sign: ";" },
  { affiliate_cd: "MDK", sign: ";" },
  { affiliate_cd: "MUK", sign: "," },
];
export const respiarShowList = ["MD"];

export const userInfoListRename = {
  sub: {
    old: "sub",
    new: "sub",
  },
  given_name: {
    old: "given_name",
    new: "gn",
  },
  family_name: {
    old: "family_name",
    new: "fn",
  },
  middle_name: { old: "middle_name", new: "mn" },
  affiliate_cd: { old: "affiliate_cd", new: "acd" },
  primary_locale: { old: "primary_locale", new: "pl" },
  tel_no: { old: "tel_no", new: "tel" },
  mail_address: { old: "mail_address", new: "ma" },
  use_repair: { old: "use_repair", new: "urepair" },
  use_dz: { old: "use_dz", new: "udz" },
  delete_flg: { old: "delete_flg", new: "df" },
  created_date: { old: "created_date", new: "cd" },
  created_by: { old: "created_by", new: "cb" },
  updated_date: { old: "updated_date", new: "ud" },
  updated_by: { old: "updated_by", new: "ub" },
  user_block: { old: "user_block", new: "ublock" },
  userinfo_repair: {
    old: "userinfo_repair",
    new: "userinfo_repair",
    type: "object",
    list: {
      sub: {
        old: "sub",
        new: "sub",
      },
      erp_user_id: {
        old: "erp_user_id",
        new: "eui",
      },
      service_center: {
        old: "service_center",
        new: "sc",
      },
      country: {
        old: "country",
        new: "country",
      },
      delete_flg: {
        old: "delete_flg",
        new: "df",
      },
      created_date: {
        old: "created_date",
        new: "cd",
      },
      created_by: {
        old: "created_by",
        new: "cb",
      },
      updated_date: {
        old: "updated_date",
        new: "ud",
      },
      updated_by: {
        old: "updated_by",
        new: "ub",
      },
    },
  },
  userinfodz: {
    old: "userinfodz",
    new: "userinfodz",
    type: "object",
    list: {
      sub: {
        old: "sub",
        new: "sub",
      },
      given_name: {
        old: "given_name",
        new: "gn",
      },
      family_name: {
        old: "family_name",
        new: "fn",
      },
      middle_name: {
        old: "middle_name",
        new: "mn",
      },
      primary_locale: {
        old: "primary_locale",
        new: "pl",
      },
      tel_no: {
        old: "tel_no",
        new: "tel",
      },
      mail_address: {
        old: "mail_address",
        new: "ma",
      },
      customer_no: {
        old: "customer_no",
        new: "cno",
      },
      customer_name: {
        old: "customer_name",
        new: "cname",
      },
      role_id: {
        old: "role_id",
        new: "rid",
      },
      sms_receive_notification: {
        old: "sms_receive_notification",
        new: "srn",
      },
      mail_receive_notification: {
        old: "mail_receive_notification",
        new: "mrn",
      },
      delete_flg: {
        old: "delete_flg",
        new: "df",
      },
      created_date: {
        old: "created_date",
        new: "cd",
      },
      created_by: {
        old: "created_by",
        new: "cb",
      },
      updated_date: {
        old: "updated_date",
        new: "ud",
      },
      updated_by: {
        old: "updated_by",
        new: "ub",
      },
      user_block: {
        old: "user_block",
        new: "ublock",
      },
      repair_access: {
        old: "repair_access",
        new: "ra",
      },
      eordering_ordering: {
        old: "eordering_ordering",
        new: "eo",
      },
      eordering_display_dealer_price: {
        old: "eordering_display_dealer_price",
        new: "eddp",
      },
      eordering_order_invoices: {
        old: "eordering_order_invoices",
        new: "eoi",
      },
      contacts_new: {
        old: "contacts_new",
        new: "cn",
      },
      wco_claim: {
        old: "wco_claim",
        new: "wc",
      },
      wco_credit_note: {
        old: "wco_credit_note",
        new: "wcn",
      },
      dz_notification_repair_shipment: {
        old: "dz_notification_repair_shipment",
        new: "dnrs",
      },
      dz_notification_repair_bo: {
        old: "dz_notification_repair_bo",
        new: "dnrb",
      },
      dz_notification_order_summary: {
        old: "dz_notification_order_summary",
        new: "dnos",
      },
      dz_notification_wco_approve: {
        old: "dz_notification_wco_approve",
        new: "dnwa",
      },
      dz_notification_wco_decline: {
        old: "dz_notification_wco_decline",
        new: "dnwd",
      },
      nc_show: {
        old: "nc_show",
        new: "ns",
      },
      nc_apply_cancel: {
        old: "nc_apply_cancel",
        new: "nac",
      },
      last_login_date: {
        old: "last_login_date",
        new: "lld",
      },
    },
  },
};

export const affiliateCDLan = {
  hidden: ["MUK", "ME"],
  iconMarkShow: ["MD"],
};
