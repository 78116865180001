import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailConditionsSetTableThead } from "./PromotionDetail.Conditions.Set.Table.Thead";
import { PromotionDetailConditionsSetTableTbody } from "./PromotionDetail.Conditions.Set.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      boxShadow: "none",
    },
    promotionContainer: {
      maxHeight: "560px",
      marginBottom: "20px",
      border: "1px solid rgba(0, 0, 0, 20%)",
      borderRadius: "5px",
    },
    buttonArea: {
      "& > button": {
        marginRight: "10px",
      },
    },
    rowsWidth: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "&:not(:last-child)": {
        marginBottom: "10px",
      },
    },
    specialPrice: {
      marginRight: "0px !important",
      "& dt": {
        marginTop: "4px",
      },
      "& dd": {
        paddingTop: "4px",
      },
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    unit: {
      marginTop: "5px",
    },
    errorText: {
      color: "#f44336",
      fontSize: "12px",
      marginBottom: "20px",
    },
  })
);

export const PromotionDetailConditionsSetTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  const { getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.index);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray, row.index);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data.condition_set.slice(0);
      const newSelecteds = data.map((item: any) => item.index);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg =
      list.length > 0 && list.length < props.data.condition_set.length;
    props.setIndeterminate(flg);
    const allCheckedFlg =
      list.length > 0 && list.length === props.data.condition_set.length;
    props.setAllChecked(allCheckedFlg);
  };

  const getTotalSpecialPrice = () => {
    const _list: any = props.data.condition_set;
    return _list.length > 0
      ? _list.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator +
            (isNaN(currentValue.quantity) ? 0 : Number(currentValue.quantity)) *
              (isNaN(currentValue.unit_price)
                ? 0
                : Number(currentValue.unit_price)),
          0
        )
      : 0;
  };

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer className={classes.promotionContainer}>
          <Table stickyHeader>
            <PromotionDetailConditionsSetTableThead
              indeterminate={props.indeterminate}
              allChecked={props.allChecked}
              onSelectAllClick={handleSelectAllClick}
              reviewFlg={props.reviewFlg}
              salesAnalysisList={props.salesAnalysisList}
            />
            <PromotionDetailConditionsSetTableTbody
              data={props.data}
              selected={props.selectedArray}
              handleClick={(event: any, row: any) => handleClick(event, row)}
              changeDataListen={props.changeDataListen}
              errorValueList={props.errorValueList}
              setCustomFieldValue={props.setCustomFieldValue}
              setSingleOrSetValue={props.setSingleOrSetValue}
              getOptionLabel2={props.getOptionLabel2}
              filterOptions2={props.filterOptions2}
              categorySet={props.categorySet}
              onBlurOfItemNo={props.onBlurOfItemNo}
              onFocusOfItemNo={props.onFocusOfItemNo}
              reviewFlg={props.reviewFlg}
              unitPrice={props.unitPrice}
              calcUnitPrice={props.calcUnitPrice}
              specialPrice={props.specialPrice}
              onBlurOfSetItem={props.onBlurOfSetItem}
              onFocusOfSetItem={props.onFocusOfSetItem}
              salesAnalysisList={props.salesAnalysisList}
              setUnitPriceFormat={props.setUnitPriceFormat}
              setUnitPrice={props.setUnitPrice}
            />
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.errorText}>
        {props.errorValueList.set_item_error}
      </div>
      <div className={classes.rowsWidth}>
        <div className={classes.buttonArea}>
          <Button
            data-testid="btn_condition_set_Add"
            variant="contained"
            color="primary"
            startIcon={<AddBoxIcon />}
            onClick={props.addSet}
            disabled={props.reviewFlg || props.onSetItemNoFlag}
          >
            {t("btn_Add")}
          </Button>
          <Button
            data-testid="btn_condition_set_Delete"
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={props.deleteSet}
            disabled={
              (props.selectedRowArray.length > 0 ? false : true) ||
              props.onSetItemNoFlag
            }
          >
            {t("btn_Delete")}
          </Button>
        </div>
        <dl className={classes.specialPrice}>
          <dt>{t("total_special_price")}:</dt>
          <dd data-testid="condition_set_special_price">
            {currency(getTotalSpecialPrice(), config.currency)}
          </dd>
        </dl>
      </div>
    </>
  );
};
