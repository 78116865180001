import React from "react";
// hooks
import { useStyles } from "../hooks/configuration.useStyles";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useLocale } from '../../../modules/hooks/UseLocale';
import { SUPPORT_LANGUAGES } from '../../../modules/constants/supportLanguages';

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      "& th": {
        width: "100px",
      },
    },
  })
);

export const LocaleSelect = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.configuration" });
  const classes = useStyles();
  const myClasses = myStyles();
  const { formList, setFormListValue } = props;
  const {locale} = useLocale();

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t('groupTitle_Locale')}</h3>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t('language')}</th>
            <td>
              <FormControl variant="outlined">
                <Select
                {...locale}>
                {
                    SUPPORT_LANGUAGES.map((m) => <MenuItem key={m.code} value={m.code}>{m.text}</MenuItem>)
                }
                </Select>
              </FormControl>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
