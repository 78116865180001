import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

var userRepairDisabledFlag = true;

export let headCells: HeadCell[] = [
  { id: "Edit", label: "" },
  { id: "Delete", label: "" },
  {
    id: "given_name",
    label: "given_name",
    searchFlg: true,
  },
  {
    id: "family_name",
    label: "family_name",
    searchFlg: true,
  },
  {
    id: "middle_name",
    label: "middle_name",
    searchFlg: true,
  },
  { id: "affiliate_cd", label: "affiliate_cd", searchFlg: true },
  { id: "primary_locale", label: "primary_locale", searchFlg: true },
  { id: "tel_no", label: "tel_no", searchFlg: true },
  { id: "mail_address", label: "mail_address", searchFlg: true },
  { id: "technician_code", label: "technician_code", searchFlg: true },
  { id: "service_center", label: "service_center", searchFlg: true },
  { id: "user_block", label: "user_block", searchFlg: true },
  { id: "Edit", label: "" },
  { id: "Delete", label: "" },
];

export const MasterMaintenanceUserMasterThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath = "modules.components.MasterMaintenance.userMaster";

  userRepairDisabledFlag = props.userRepairDisabledFlag;

  if (!props.userRepairDisabledFlag) {
    headCells = [
      { id: "Edit", label: "" },
      { id: "Delete", label: "" },
      {
        id: "given_name",
        label: "given_name",
        searchFlg: true,
      },
      {
        id: "family_name",
        label: "family_name",
        searchFlg: true,
      },
      {
        id: "middle_name",
        label: "middle_name",
        searchFlg: true,
      },
      { id: "affiliate_cd", label: "affiliate_cd", searchFlg: true },
      { id: "primary_locale", label: "primary_locale", searchFlg: true },
      { id: "tel_no", label: "tel_no", searchFlg: true },
      { id: "mail_address", label: "mail_address", searchFlg: true },
      { id: "user_block", label: "user_block", searchFlg: true },
      { id: "Edit", label: "" },
      { id: "Delete", label: "" },
    ];
  }

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
