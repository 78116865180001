export const WebSocketHeartBeatData = JSON.stringify({
  heartbeat: "healthcheck",
});

export interface WebSocketEventNotificationModel {
  notification_id?: number;
  job_no?: string;
  message?: string;
  updated_date?: string;
}

export interface WebSocketEventLocationModel {
  service_center_id?: string;
  repair?: {
    total_num: number;
    location_control?: LocationControlModel[];
  };
  send_back?: {
    total_num: number;
    location_control?: LocationControlModel[];
  };
  dispose?: {
    total_num: number;
    location_control?: LocationControlModel[];
  };
}

interface LocationControlModel {
  job_no: number;
  location_no: string;
  model_no: string;
  quote_result: number;
  updated_date: string;
}

export interface RepairNotificationMessage {
  notification_id: number;
  job_no: number;
  message: string;
  checked: boolean;
  data_type: any;
  message_type: any;
  updated_date: string;
}

const WebSocketMessageType = {
  WebSocketEventNotificationModel: "WebSocketEventNotificationModel",
  WebSocketEventLocationModel: "WebSocketEventLocationModel",
} as const;
export type WebSocketMessageType = typeof WebSocketMessageType[keyof typeof WebSocketMessageType];
