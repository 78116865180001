import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { OrderSearchResultTableThead } from "./Order.SearchResult.Table.Thead";
import { OrderSearchResultTableTbody } from "./Order.SearchResult.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";

// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { PathKey } from "../../../pages/AppRoutes";
// route
import { useHistory } from "react-router-dom";
import { getAPIErrorMessage } from "../../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderListContainer: {
      maxHeight: "560px",
    },
  })
);

export const OrderSearchResultTable = (props: any) => {
  const classes = useStyles();
  const { resultData,beforeToDetail } = props;
  const { t } = useLocale({ defaultPath: "modules.components.order" });

  // pager
  const [needResetOrderBy, setNeedResetOrderBy] = useState(false);

  const history = useHistory();
  const { getOrderInfo, setOrderInfo, getHistoryRouter, getCommonConfig } =
    useCommonInfo();
  const config = getCommonConfig() as any;
  let historyRouter = getHistoryRouter() as any;
  let pageMessage = getOrderInfo() as any;

  const handleChangePage = (event: unknown, newPage: number) => {
    const obj = Object.assign({}, pageMessage, { page: newPage });
    setOrderInfo(obj);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const number = parseInt(event.target.value, 10);
    const obj = Object.assign({}, pageMessage, { perPage: number, page: 0 });
    setOrderInfo(obj);
  };

  // order
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(["order_no"]);

  const [startFlg, setStartFlg] = useState(false);
  // init
  useEffect(() => {
    if (!startFlg && Object.keys(config).length > 0) {
      if (historyRouter.to === String(PathKey.ORDER)) {
        if (historyRouter.from !== String(PathKey.ORDERDETAIL)) {
          const obj = Object.assign({}, pageMessage, { page: 0 });
          setOrderInfo(obj);
        }
        setStartFlg(true);
      }
    }
  }, [startFlg, config, historyRouter]);

  useEffect(() => {
    // init search
    if (needResetOrderBy) {
      setOrderBy(["no"]);
      setOrder("desc");
      const obj = Object.assign({}, pageMessage, { page: 0 });
      setOrderInfo(obj);
      props.setNeedResetOrderBy(false);
    }
  }, [needResetOrderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy.indexOf(property) > -1 && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy([property]);
  };

  const getPageInfo = () => {
    return {
      page: pageMessage.page,
      rowsPerPage: pageMessage.perPage,
    };
  };

  return (
    <>
      <TableContainer className={classes.orderListContainer}>
        <Table>
          <OrderSearchResultTableThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <OrderSearchResultTableTbody
            data={resultData}
            pageInfo={getPageInfo()}
            order={order}
            orderBy={orderBy}
            beforeToDetail={beforeToDetail}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={resultData.length}
        rowsPerPage={pageMessage.perPage}
        page={pageMessage.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
    </>
  );
};
