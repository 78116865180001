import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tbody: {
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
    },
    tableRow: {
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowRed: {
      backgroundColor: "rgba(230, 0, 18, 0.6);",
    },
    tableRowYellow: {
      backgroundColor: "rgba(246, 172, 25, 0.6);",
    },
    tableRowHighlight: {
      backgroundColor: "#F9FF85",
    },
    tableRowHeader: { 
      "& .MuiTableCell-stickyHeader": {
        width: "40px",
        backgroundColor: "rgba(240, 242, 244)",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    nowrapTechnician: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      width: "40px",
    },
    groupContainer: {
      maxHeight: 650,
    },
    tfoot: {
      "& td": {
        background: "rgba(240, 242, 244)",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        borderTop: "1px solid rgba(198, 209, 221)",
      },
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
    },
    jobListContainer: {
      maxHeight: 560,
    },
  })
);
