import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailConditionsSingleTableThead } from "./PromotionDetail.Conditions.Single.Table.Thead";
import { PromotionDetailConditionsSingleTableTbody } from "./PromotionDetail.Conditions.Single.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import {
  priceInputBySign,
  decimalPointIsLenth,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      boxShadow: "none",
    },
    promotionContainer: {
      maxHeight: "560px",
      marginBottom: "20px",
      border: "1px solid rgba(0, 0, 0, 20%)",
      borderRadius: "5px",
    },
    buttonArea: {
      "& > button": {
        marginRight: "10px",
      },
    },
    rowsWidth: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "&:not(:last-child)": {
        marginBottom: "10px",
      },
    },
    specialPrice: {
      marginRight: "0px !important",
      "& dt": {
        marginTop: "4px",
      },
      "& dd": {
        display: "flex",
        "& .MuiFormControl-root": {
          width: "120px",
          marginRight: "5px",
        },
      },
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    unit: {
      marginTop: "5px",
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    radioContainer: {
      display: "flex",
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
  })
);

export const PromotionDetailConditionsSingleTable = (props: any) => {
  const classes = useStyles();

  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  const { getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;

  const singleData = props.data.condition_single[0];
  const errorData = props.errorValueList.condition_single[0];
  const type = "condition_single";

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer className={classes.promotionContainer}>
          <Table stickyHeader>
            <PromotionDetailConditionsSingleTableThead
              salesAnalysisList={props.salesAnalysisList}
            />

            <PromotionDetailConditionsSingleTableTbody
              data={props.data}
              changeDataListen={props.changeDataListen}
              errorValueList={props.errorValueList}
              setSingleOrSetValue={props.setSingleOrSetValue}
              getOptionLabel2={props.getOptionLabel2}
              filterOptions2={props.filterOptions2}
              categorySingle={props.categorySingle}
              onBlurOfItemNo={props.onBlurOfItemNo}
              onFocusOfSingleItem={props.onFocusOfSingleItem}
              reviewFlg={props.reviewFlg}
              salesAnalysisList={props.salesAnalysisList}
            />
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.rows}>
        <RadioGroup
          data-testid="single_moreThanOrTimes"
          name="moreThanOrTimes"
          className={classes.radioContainer}
          value={singleData.single_type}
          onChange={(e) => {
            props.setSingleOrSetValue("single_type", e.target.value, 0, type);
          }}
        >
          <FormControl
            variant="outlined"
            className={classes.formControl}
            disabled={props.reviewFlg}
          >
            <div className={classes.radioContainer}>
              <FormControlLabel
                className={classes.radio}
                value="1"
                control={<Radio color="default" />}
                label={t("more_than")}
              />
              <FormControlLabel
                className={classes.radio}
                value="2"
                control={<Radio color="default" />}
                label={t("times")}
              />
            </div>
          </FormControl>
        </RadioGroup>
        <dl className={classes.specialPrice}>
          <dt>{t("special_price")}</dt>
          <dd  data-testid="condition_single_special_price">
            <TextField
              variant="outlined"
              value={props.singleSpecialPriceFormat}
              onChange={(e: any) => {
                let value = "";
                let _price = priceInputBySign(e.target.value);
                if (
                  !isNaN(_price) &&
                  _price >= 0 &&
                  decimalPointIsLenth(e.target.value)
                ) {
                  value = e.target.value.slice(0, 11);
                } else {
                  value = singleData.special_price_format
                    ? singleData.special_price_format
                    : "";
                }
                props.setSingleOrSetValue("special_price", value, 0, type);
                props.setSingleSpecialPriceFormat(value);

              }}
              onBlur={(e) =>
                props.changeDataListen(
                  "single_special_price",
                  e.target.value,
                  0
                )
              }
              error={errorData.special_price_error !== ""}
              helperText={errorData.special_price_error}
              disabled={props.reviewFlg}
            />
            <div className={classes.unit}>
              {symbolByCurrency(config.currency)}
            </div>
          </dd>
        </dl>
      </div>
    </>
  );
};
