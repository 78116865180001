import React from "react";
// hooks
import { useStyles } from "../hooks/PartsDrawing.styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

import { priceOutputByCountryCode } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    label: "txt_id",
  },
  {
    id: "Item",
    label: "txt_item",
  },
  {
    id: "Description",
    label: "txt_description",
  },
  {
    id: "Qty",
    label: "txt_order_qty",
  },
  {
    id: "AllocatedQty",
    label: "txt_allocated_qty",
  },
  {
    id: "BOQty",
    label: "txt_BO_Qty",
  },
  {
    id: "ETA",
    label: "estimated_arrival_date",
  },
  {
    id: "AltemativeItem",
    label: "txt_Altemative_Item",
  },
  {
    id: "Price",
    label: "txt_Price",
  },
  {
    id: "currency",
    label: "txt_currency",
  },
  {
    id: "amount",
    label: "txt_Amount",
  },
];

export const JobsDetailPartsDrawingRegisterThead = (props: any) => {
  const classes = useStyles();
  const { indeterminate, onSelectAllClick, checked } = props;
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.parts_drawing",
  });

  const getClassName = (id: string) => {
    if (id === "id") {
      return classes.registerCellId;
    } else if (id === "Qty") {
      return classes.Qty;
    } else if (id === "AllocatedQty") {
      return classes.AllocatedQty;
    } else if (id === "BOQty") {
      return classes.BOQty;
    } else if (id === "ETA") {
      return classes.estimatedArrivalDate;
    } else if (id === "AltemativeItem") {
      return classes.AltemativeItem;
    } else if (id === "currency") {
      return classes.currency;
    } else if (id === "amount") {
      return classes.amount;
    }
  };
  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.totalName} colSpan={10}>
          {props.totalName}
        </TableCell>
        <TableCell className={classes.amountNumberCell}>
          {priceOutputByCountryCode(props.total)}
        </TableCell>
        <TableCell className={classes.numberCell}>{symbolByCurrency(props.currency)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onSelectAllClick}
            checked={checked}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className={getClassName(headCell.id)}>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
