import React, { useState } from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { CustomerSearchResultTable } from "./JobsDetail.General.Message.CSearch.Table";
import { CustomerSearchControl } from "./JobsDetail.General.Message.CSearch.SearchControl";
import { AlertMesssageBox } from "../../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";
// api
import api from "../../../modules/api/Api";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CSearchModelContainer: {
      width: "1300px",
      height: "700px",
      overflow: "auto",
    },
    title: {
      background: "rgba(240, 242, 244, 0.5)",
      textAlign: "right",
      padding: "10px",
    },
    cell: {
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      padding: "5px 10px",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "left",
    },
    buttonSetting: {
      marginLeft: "15px",
    },
  })
);

const defaultValue = {
  customer_no: "",
  customer_name: "",
  address: "",
  city: "",
  postal_cd: "",
  state_cd: "",
  tel_no: "",
};

export const GeneralMessageCSearch = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.prompt_csearch",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  // search value
  const [conditionList, setConditionList] = useState(defaultValue);
  // search result
  const [resultList, setResultList] = useState<any[]>([]);
  // radio selected value
  const [radioSelectedValue, setRadioSelectedValue] = useState("");
  // research flag
  const [researchFlg, setResearchFlg] = useState(false);
  // alert
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  const { getCommonConfig, setCommonInfo } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  let config = getCommonConfig() as any;

  const handleClose = () => {
    props.CSearchClose(false);
  };

  const isAllEmpty = () => {
    let result = false;
    if (
      conditionList.customer_no === "" &&
      conditionList.customer_name === "" &&
      conditionList.address === "" &&
      conditionList.city === "" &&
      conditionList.postal_cd === "" &&
      conditionList.state_cd === "" &&
      conditionList.tel_no === ""
    ) {
      result = true;
    }
    return result;
  };

  const handleClickSearch = () => {
    setAlertMessage("");
    if (isAllEmpty()) {
      let message = com("messages.MS0007");
      setAlertType("error");
      setAlertMessage(message);
    } else {
      apiLoading(true);
      let query_parameters =
        "customer_no=" +
        encodeURIComponent(conditionList.customer_no) +
        "&customer_name=" +
        encodeURIComponent(conditionList.customer_name) +
        "&address_1=" +
        encodeURIComponent(conditionList.city) +
        "&address_3=" +
        encodeURIComponent(conditionList.address) +
        "&state_cd=" +
        encodeURIComponent(conditionList.state_cd) +
        "&postal_cd=" +
        encodeURIComponent(conditionList.postal_cd) +
        "&tel_no=" +
        encodeURIComponent(conditionList.tel_no);

      search(query_parameters);
      setRadioSelectedValue("");
    }
  };

  // common search method
  const search = (query_parameters: string) => {
    // alert(query_parameters);
    setAlertMessage("");
    setResultList([]);
    // Read Customer Info List AM0206
    api
      .getCustomerDetailListAPIforSearch(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AM0206 response: ", response);
        if (!getApiResultStatus(code)) {
          let message = com("messages." + response.message_id, {
            param1: com("messages_key_name." + response.key),
          });
          setAlertMessage(message);
          setAlertType("error");
        } else {
          if (response.length >= 2000) {
            setAlertMessage(com("messages.MS0049"));
            setAlertType("warning");
          }
          setResultList(response);
          setResearchFlg(true);
          setOrderBy("customer_no");
        }
        apiLoading(false);
      });
  };

  const handleClickOk = () => {
    const data = radioSelectedValue ? radioSelectedValue : resultList[0];
    setConditionList(defaultValue);
    setResultList([]);
    setOrder("asc");
    setOrderBy("");
    props.handleSend(data);
  };

  const CSearchModelWindow = (
    <div className={classes.CSearchModelContainer}>
      <AlertMesssageBox
        show={alertMessage && alertMessage !== ""}
        message={alertMessage}
        type={alertType}
      />
      <CustomerSearchControl
        conditionList={conditionList}
        setConditionList={setConditionList}
        handleClickSearch={handleClickSearch}
        researchFlg={researchFlg}
        setResearchFlg={setResearchFlg}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />
      <CustomerSearchResultTable
        data={resultList}
        setRadioSelectedValue={setRadioSelectedValue}
        researchFlg={researchFlg}
        setResearchFlg={setResearchFlg}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </div>
  );

  const CSearchModelWindowButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        disabled={resultList.length === 0}
        onClick={handleClickOk}
        color="primary"
      >
        {t("prompt_button_OK")}
      </Button>
      <Button
        className={classes.buttonSetting}
        variant="contained"
        onClick={handleClose}
      >
        {t("prompt_button_Cancel")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_CSearch"
      contents={CSearchModelWindow}
      button={CSearchModelWindowButton}
      openFlg={props.CSearchOpen}
      handleCloseEvent={handleClose}
    />
  );
};
