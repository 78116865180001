import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "center",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowHeader: {
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "rgba(240, 242, 244)",
      },
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "line_no", label: "line_no" },
  { id: "item", label: "item" },
  { id: "item_description", label: "item_description" },
  { id: "qty", label: "qty" },
  { id: "list_price", label: "list_price" },
  { id: "net_price", label: "net_price" },
  { id: "amount", label: "amount" },
  { id: "promo_no", label: "promo_no" },
  { id: "discount_rate", label: "discount_rate" },
  { id: "free_goods_flag", label: "free_goods_flag" },
];

export const SimulateSearchResultTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.simulate" });
  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox">
          <Checkbox
            data-testid="allCheckbox"
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
