import React, { FC, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { defaultValue as orderInfoDefaultValue } from "../../pages/order/Order";
import { defaultValue as promoDefaultValue } from "../../pages/promotion/Promotion";

const initialCommonInfoContext = {
  getCommonInfo: () => {},
  setCommonInfo: (property: string, value: any) => {},
  setCommonInfoFull: (value: any) => {},
  setCommonConfig: (value: any) => {},
  getCommonConfig: () => {},
  setUserInfoRepair: (value: any) => {},
  getUserInfoRepair: () => {},
  setUserPermissions: (value: any) => {},
  getUserPermissions: () => {},
  getHotKey: () => {},
  setHotKey: (property: string, value: any) => {},
  getLocationHotKey: () => {},
  setLocationHotKey: (property: string, value: any) => {},
  getHistoryRouter: () => {},
  setHistoryRouter: (value: any) => {},
  getJobsSearchMessage: () => {},
  setJobsSearchMessage: (value: any) => {},
  getPromotionInfo: () => {},
  setPromotionInfo: (value: any) => {},
  getOrderInfo: () => {},
  setOrderInfo: (value: any) => {},
  getJobsPageMessage: () => {},
  setJobsPageMessage: (value: any) => {},
  getSimulateInfo: () => {},
  setSimulateInfo: (value: any) => {},
  getPromoPageInfo: () => {},
  setPromoPageInfo: (value: any) => {},
  getPromoDetailPageInfo: () => {},
  setPromoDetailPageInfo: (value: any) => {},
  getSalesAnalysisDefinition: () => {},
  setSalesAnalysisDefinition: (value: any) => {},
  getClaimDetailInfo: () => {},
  setClaimDetailInfo: (value: any) => {},
  getClaimsPageInfo: () => {},
  setClaimsPageInfo: (value: any) => {},
  getClaimsSearchMessage: () => {},
  setClaimsSearchMessage: (value: any) => {},
  getLoggerManage: () => {},
  setLoggerManage: (key: any, value: any) => {},
  setLoggerListManage: (value: any) => {},
  setClearLoggerListMagange: () => {},
};

const CommonInfoContext = createContext(initialCommonInfoContext);
export const useCommonInfo = () => useContext(CommonInfoContext);

interface Props {
  children: any;
}

interface initialCommonInfoMessage {
  loadingFlg: boolean;
  itemList: any[];
  itemBaseList: any[];
  customerList: any[];
  technicianList: any[];
  serviceCenterList: any[];
  token: string;
  configFlg: boolean;
}

const initialCommonInfoMessage = {
  loadingFlg: false,
  itemList: [],
  itemBaseList: [],
  customerList: [],
  technicianList: [],
  serviceCenterList: [],
  token: "",
  configFlg: false,
};

interface initialHotKeyMessage {
  handleF2Search: boolean;
  handleF8Save: boolean;
  handleF9Refresh: boolean;
  handlePageDown: boolean;
  handlePageUp: boolean;
}

const initialHotKeyMessage = {
  handleF2Search: false,
  handleF8Save: false,
  handleF9Refresh: false,
  handlePageDown: false,
  handlePageUp: false,
};

interface initialLocationHotKeyMessage {
  locationTabName: string;
}

const initialLocationHotKeyMessage = {
  locationTabName: "",
};

interface initialHistoryRouter {
  from: string;
  to: string;
}

const initialHistoryRouter = {
  from: "",
  to: "",
};

const initalCommonConfig = {};
const initalUserInfoRepair = {};
const initalUserPermissions = {
  permission_group: undefined,
  permission: undefined,
};

export const CommonInfoProvider: FC<Props> = ({ children }) => {
  const [commonInfo, setCommonInfo] = useState<initialCommonInfoMessage>(
    initialCommonInfoMessage
  );
  const [commonConfig, setCommonConfig] = useState(initalCommonConfig);
  const [userInfoRepair, setUserInfoRepair] = useState(initalUserInfoRepair);
  const [userPermissions, setUserPermissions] = useState(initalUserPermissions);
  const [hotKey, setHotKey] =
    useState<initialHotKeyMessage>(initialHotKeyMessage);
  const [locationHotKey, setLocationHotKey] =
    useState<initialLocationHotKeyMessage>(initialLocationHotKeyMessage);
  const [jobsSearch, setJobsSearch] = useState({});
  const [promotionInfo, setPromotionInfo] = useState(promoDefaultValue);
  const [orderInfo, setOrderInfo] = useState(orderInfoDefaultValue);
  const [jobsPage, setJobsPage] = useState({});
  const [simulateInfo, setSimulateInfo] = useState({});
  const [historyRouter, setHistoryRouter] =
    useState<initialHistoryRouter>(initialHistoryRouter);
  const [promoPage, setPromoPage] = useState({
    page: 0,
    perPage: 10,
  });
  const [loggerManager, setLoggerManager] = useState({
    loginDateTime: "",
    loggerList: [],
  });
  const [promoDetailPage, setPromoDetailPage] = useState({
    reviewFlg: false,
  });
  const [salesAnalysisDefinition, setSalesAnalysisDefinition] = useState({});
  const [claimsPage, setClaimsPage] = useState({
    page: 0,
    perPage: 10,
  });
  const [claimDetailInfomation, setClaimDetailInfomation] = useState({});
  const [claimsSearch, setClaimsSearch] = useState({});

  const commonInfoContext = {
    getCommonInfo: () => {
      return commonInfo;
    },
    setCommonInfo: (property: string, value: any) => {
      setCommonInfo((prevState: any) => {
        const list = Object.assign({}, prevState, { [property]: value });
        return list;
      });
    },
    setCommonInfoFull: (value: any) => {
      setCommonInfo((prevState: any) => {
        const list = Object.assign({}, prevState, { ...value });
        return list;
      });
    },
    setCommonConfig: (value: any) => {
      setCommonConfig((prevState: any) => {
        const list = Object.assign({}, prevState, { ...value });
        return list;
      });
    },
    getCommonConfig: () => {
      return commonConfig;
    },
    setUserInfoRepair: (value: any) => {
      const list = Object.assign({}, value);
      setUserInfoRepair(list);
    },
    getUserInfoRepair: () => {
      return userInfoRepair;
    },
    setUserPermissions: (value: any) => {
      const list = Object.assign({}, value);
      setUserPermissions(list);
    },
    getUserPermissions: () => {
      return userPermissions;
    },
    getHotKey: () => {
      return hotKey;
    },
    setHotKey: (property: string, value: any) => {
      const list = Object.assign({}, hotKey, { [property]: value });
      setHotKey(list);
    },
    getLocationHotKey: () => {
      return locationHotKey;
    },
    setLocationHotKey: (property: string, value: any) => {
      const list = Object.assign({}, locationHotKey, { [property]: value });
      setLocationHotKey(list);
    },
    getHistoryRouter: () => {
      return historyRouter;
    },
    setHistoryRouter: (value: any) => {
      const list = Object.assign({}, { from: historyRouter.to, to: value });
      setHistoryRouter(list);
    },
    getJobsSearchMessage: () => {
      return jobsSearch;
    },
    setJobsSearchMessage: (value: any) => {
      const list = Object.assign({}, value);
      setJobsSearch(list);
    },
    getPromotionInfo: () => {
      return promotionInfo;
    },
    setPromotionInfo: (value: any) => {
      const message = Object.assign({}, value);
      setPromotionInfo(message);
    },
    getOrderInfo: () => {
      return orderInfo;
    },
    setOrderInfo: (value: any) => {
      setOrderInfo(value);
    },
    getJobsPageMessage: () => {
      return jobsPage;
    },
    setJobsPageMessage: (value: any) => {
      const list = Object.assign({}, value);
      setJobsPage(list);
    },
    getSimulateInfo: () => {
      return simulateInfo;
    },
    setSimulateInfo: (value: any) => {
      const list = Object.assign({}, value);
      setSimulateInfo(list);
    },
    getPromoPageInfo: () => {
      return promoPage;
    },
    setPromoPageInfo: (value: any) => {
      const list = Object.assign({}, value);
      setPromoPage(list);
    },
    getPromoDetailPageInfo: () => {
      return promoDetailPage;
    },
    setPromoDetailPageInfo: (value: any) => {
      const list = Object.assign({}, value);
      setPromoDetailPage(list);
    },
    getSalesAnalysisDefinition: () => {
      return salesAnalysisDefinition;
    },
    setSalesAnalysisDefinition: (value: any) => {
      const list = Object.assign({}, value);
      setSalesAnalysisDefinition(list);
    },
    getClaimsPageInfo: () => {
      return claimsPage;
    },
    setClaimsPageInfo: (value: any) => {
      const list = Object.assign({}, value);
      setClaimsPage(list);
    },
    getClaimDetailInfo: () => {
      return claimDetailInfomation;
    },
    setClaimDetailInfo: (value: any) => {
      const list = Object.assign({}, value);
      setClaimDetailInfomation(list);
    },
    getClaimsSearchMessage: () => {
      return claimsSearch;
    },
    setClaimsSearchMessage: (value: any) => {
      const list = Object.assign({}, value);
      setClaimsSearch(list);
    },
    getLoggerManage: () => {
      return loggerManager;
    },
    setLoggerManage: (key: any, value: any) => {
      setLoggerManager((prevState: any) => {
        return { ...prevState, [key]: value };
      });
    },
    setLoggerListManage: (value: any) => {
      setLoggerManager((prevState: any) => {
        let _o = JSON.parse(JSON.stringify(prevState));
        _o.loggerList.push(value);
        return _o;
      });
    },
    setClearLoggerListMagange: () => {
      setLoggerManager((prevState: any) => {
        let _o = JSON.parse(JSON.stringify(prevState));
        _o.loggerList = [];
        return _o;
      });
    },
  };
  return (
    <CommonInfoContext.Provider value={{ ...commonInfoContext }}>
      {children}
    </CommonInfoContext.Provider>
  );
};
