import React from "react";
// components
import { SummaryChangesHistoryThead } from "./Summary.Changes.History.Thead";
import { SummaryChangesHistoryTbody } from "./Summary.Changes.History.Tbody";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hooks
import { useStyles } from "../hooks/Summary.Changes.styles";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

export const SummaryChangesHistory = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath:
      "modules.components.jobDetail.general.group_repair_updated_history",
  });
  const { dataList, currency, errorType } = props;
  return (
    <>
      <TableContainer className={classes.groupContainer}>
        <Table stickyHeader>
          <SummaryChangesHistoryThead />
          <SummaryChangesHistoryTbody
            dataList={dataList}
            currency={currency}
            errorType={errorType}
          />
        </Table>
      </TableContainer>
    </>
  );
};
