import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AT0101
export const CreateRepairJobAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/${affiliate_cd}`,
    method: methods.post,
    data: data,
    flg: true,
    config: config,
  };
  return http(param);
};

// AT0102
export const GetReadRepairJobAPI = (job_no: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/${affiliate_cd}/${job_no}`,
    config: config,
  };
  return http(param);
};

// AT0103
export const UpdateRepairJobAPI = (job_no: any, data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/${affiliate_cd}/${job_no}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0104
export const DeleteRepairJobAPI = (job_no: any, data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/${affiliate_cd}/${job_no}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0105
export const GetReadRepairJobListAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/jobs/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

// AT0107
export const GetReadRepairJobTechList = (config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/qty/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

// AT0109
export const CreateRepairMediaAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/media/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0110
export const CreateRepairNoteAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/note/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0111
export const DeleteRepairMediaAPI = (
  job_no: any,
  sub_no: any,
  data: any,
  config: any
) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/media/${affiliate_cd}/${job_no}/${sub_no}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0112
export const DeleteRepairNoteAPI = (
  job_no: any,
  note_type: any,
  sub_no: any,
  data: any,
  config: any
) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/note/${affiliate_cd}/${job_no}/${note_type}/${sub_no}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0114
export const QuotaResendAPI = (job_no: string, data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/resend/${affiliate_cd}/${job_no}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0115
export const GetRepairJobLocationControlAPI = (config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/jobloc/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

// AT0116
export const UpdateMultipleRepairJobAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/${affiliate_cd}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0119
export const DeleteReceivedJob = (job_no: any, data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/received/${affiliate_cd}/${job_no}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0120
export const CreateRepairContactAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/contact/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AT0121
export const CreateRepairAddressAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/address/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

export const DownloadCSVRepairListAPI = (data: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/jobs/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
    flg: true,
  };
  return http(param);
};

export const GetDownloadJobRepairByS3singedUrlApi = (
  config: any,
  query_parameters: any
) => {
  const { baseURL, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_headers/dls3url/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
