import React, { FC } from "react";
// language
import { useLocale } from "../../hooks/UseLocale";
import { getLocale } from "../../i18n";
import moment from "moment";
import "moment/min/locales";
// components
import MomentUtils from "@date-io/moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

// props
type Props = {
  value: any;
  onChange: (date: any) => void;
  className?: any;
  invalidDateMessage?: any;
  maxDateMessage?: any;
  minDateMessage?: any;
  error?: any;
  helperText?: any;
  onError?: (error: any, value: any) => void;
  onBlur?: (e: any) => void;
  onAccept?: (date: any) => void;
  ampm?: boolean;
  keyboardIcon?: any;
  disabled?: boolean;
};

export const TimePicker: FC<Props> = ({
  value,
  onChange,
  className,
  invalidDateMessage,
  maxDateMessage = "",
  minDateMessage = "",
  error,
  helperText,
  onError,
  onBlur,
  onAccept,
  ampm = false,
  keyboardIcon = <ScheduleIcon />,
  disabled = false,
}) => {
  const { com } = useLocale({ defaultPath: "common.constants" });
  let localInvalidDateMessage = com("messages.MS0047");
  if (invalidDateMessage) {
    localInvalidDateMessage = invalidDateMessage;
  }

  let localMaxDateMessage = "";
  if (maxDateMessage !== "") {
    localMaxDateMessage = maxDateMessage;
  }

  let localMinDateMessage = "";
  if (minDateMessage !== "") {
    localMinDateMessage = minDateMessage;
  }

  // set locale
  const localeStr = getLocale();
  const localeArray = [
    localeStr.toLowerCase(),
    ...localeStr.split("-"),
  ] as string[];
  moment.locale(localeArray);

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <KeyboardTimePicker
          // format={moment.localeData().longDateFormat("LT")}
          format="HH:mm"
          autoOk
          variant="inline"
          margin="normal"
          value={value}
          onChange={(date: any) => {
            // alert(moment(date).format("L"));
            onChange(date);
          }}
          error={error}
          helperText={helperText}
          onError={(error: any, value: any) => {
            if (onError) {
              onError(error, value);
            }
          }}
          onAccept={(date: any) => {
            if (onAccept) {
              onAccept(date);
            }
          }}
          onBlur={(e: any) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          className={className}
          invalidDateMessage={localInvalidDateMessage}
          ampm={ampm}
          keyboardIcon={keyboardIcon}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
