import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import {
  priceInputBySign,
  decimalPointIsLenth,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      minHeight: "48px",
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:not(:first-child)": {
          verticalAlign: "top",
          paddingTop: "8px",
          paddingBottom: "5px",
        },
      },
      "&:last-child td": {
        borderBottom: "none",
      },
    },
    checkbox: {
      minHeight: "42px",
    },
    discountRate: {
      display: "flex",
      "& .MuiInputBase-root": {
        marginRight: "5px",
      },
    },
    unit: {
      marginTop: "5px",
      paddingLeft: "5px",
    },
    plannerCode: {
      width: "100%",
    },
    item_no: {
      width: "140px",
      margin: "0 auto",
    },
    unitPrice: {
      textAlign: "right",
      marginTop: "5px",
    },
    quantity: {
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    warning: {
      color: "#ff9800",
    },
    unitPriceWrapper: {
      display: "flex",
    },
  })
);

export const PromotionDetailConditionsSetTableTbody = (props: any) => {
  const { selected } = props;
  const { com } = useLocale({ defaultPath: "common.constants" });
  const classes = useStyles();

  const { getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;

  const isSelected = (name: string) => selected.indexOf(name) > -1;

  const errorData = props.errorValueList.condition_set;
  const type = "condition_set";

  const getRoundNumber = (number: number) => {
    const roundNumber = Math.round(Number(number) * 100) / 100;
    return roundNumber;
  };

  return (
    <TableBody data-testid="condition_set_body">
      {props.data.condition_set.map((row: any, index: number) => {
        const total = props.unitPrice.reduce(
          (preValue: any, curValue: any, _index: any) => {
            const _qty = isNaN(
              Number(props.data.condition_set[_index].quantity)
            )
              ? 1
              : Number(props.data.condition_set[_index].quantity);
            return preValue + curValue * _qty;
          },
          0
        );

        // let calcPrice: any = 0;
        // if (props.specialPrice !== "" && total !== 0) {
        //   const item_qty = isNaN(Number(row.quantity))
        //     ? 1
        //     : Number(row.quantity);

        //   let calcPriceFlag = false;
        //   if (props.data.condition_set.length > 0) {
        //     for (let i = 0; i < props.data.condition_set.length; i++) {
        //       if (props.data.condition_set[i].quantity > 1) {
        //         calcPriceFlag = true;
        //         break;
        //       }
        //     }
        //   }

        //   if (index === props.data.condition_set.length - 1) {
        //     const _total = props.data.condition_set.reduce(
        //       (preValue: any, curValue: any, _index: number) => {
        //         const _qty = isNaN(
        //           Number(props.data.condition_set[_index].quantity)
        //         )
        //           ? 1
        //           : Number(props.data.condition_set[_index].quantity);
        //         if (calcPriceFlag) {
        //           return _index === props.data.condition_set.length - 1
        //             ? preValue
        //             : preValue +
        //                 ((props.unitPrice[_index] * _qty) / total) *
        //                   props.specialPrice;
        //         } else {
        //           return _index === props.data.condition_set.length - 1
        //             ? preValue
        //             : preValue +
        //                 getRoundNumber(
        //                   ((props.unitPrice[_index] * _qty) / total) *
        //                     props.specialPrice
        //                 );
        //         }
        //       },
        //       0
        //     );
        //     calcPrice = getRoundNumber(
        //       Number(props.specialPrice - _total) / item_qty
        //     );
        //   } else {
        //     calcPrice = getRoundNumber(
        //       (((props.unitPrice[index] * item_qty) / total) *
        //         props.specialPrice) /
        //         item_qty
        //     );
        //   }
        // }

        // calcPrice = currency(calcPrice >= 0 ? calcPrice : 0, config.currency);
        const isItemSelected = isSelected(row.index);
        const errorFlg1 =
          errorData &&
          errorData[index] &&
          errorData[index].quantity_error !== "";
        const errorFlg2 =
          errorData &&
          errorData[index] &&
          errorData[index].item_no_error !== "";
        const errorFlg3 =
          errorData &&
          errorData[index] &&
          errorData[index].unit_price_error !== "";
        const total_unit_price =
          (isNaN(row.quantity) ? 0 : Number(row.quantity)) *
          (isNaN(row.unit_price) ? 0 : Number(row.unit_price));
        return (
          <TableRow
            className={classes.tableRow}
            hover={true}
            tabIndex={-1}
            key={row.index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div
                className={classes.checkbox}
                data-testid={"condition_set_checkbox" + index}
              >
                {
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => props.handleClick(event, row)}
                    disabled={props.reviewFlg}
                  />
                }
              </div>
            </TableCell>
            <TableCell>{row.item_class}</TableCell>
            <TableCell>{row.discount_code}</TableCell>
            <TableCell>
              <div
                className={classes.item_no}
                data-testid={"condition_set_item_no" + index}
              >
                <Autocomplete
                  id="item_no"
                  freeSolo
                  disableClearable
                  options={props.categorySet}
                  getOptionLabel={props.getOptionLabel2}
                  filterOptions={props.filterOptions2}
                  inputValue={props.data.condition_set[index].item_no}
                  value={props.data.condition_set[index].item_no}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "200px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) =>
                    option.item_no.toString().toUpperCase() ===
                    props.data.condition_set[index].item_no
                      .toString()
                      .toUpperCase()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      onBlur={() => {
                        props.onBlurOfItemNo(type, index);
                      }}
                      onFocus={() => props.onFocusOfItemNo(type, index)}
                      error={errorFlg2}
                      helperText={
                        errorFlg2 ? errorData[index].item_no_error : ""
                      }
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {
                      props.setSingleOrSetValue(
                        "item_no",
                        value.item_no,
                        index,
                        type
                      );
                    }
                  }}
                  onBlur={() => props.onBlurOfSetItem(index)}
                  onFocus={() => props.onFocusOfSetItem(index)}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      const val = value.substr(0, 35);
                      props.setSingleOrSetValue("item_no", val, index, type);
                    }
                  }}
                  disabled={props.reviewFlg}
                />
              </div>
            </TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>
              <div
                className={classes.quantity}
                data-testid={"condition_set_quantity" + index}
              >
                <TextField
                  variant="outlined"
                  value={row.quantity}
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^\d]/g, "")
                      .substr(0, 9);
                    props.setSingleOrSetValue("quantity", value, index, type);
                  }}
                  onBlur={(e) =>
                    props.changeDataListen(
                      "set_quantity",
                      e.target.value,
                      index
                    )
                  }
                  error={errorFlg1}
                  helperText={errorFlg1 ? errorData[index].quantity_error : ""}
                  disabled={props.reviewFlg}
                />
                {/* {row.quantity && Number(row.quantity) > 1 && (
                  <span className={classes.warning}>
                    {com("messages.promotion_special_price_qty")}
                  </span>
                )} */}
              </div>
            </TableCell>

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac1") && (
                <TableCell>{row.isac1}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac2") && (
                <TableCell>{row.isac2}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac3") && (
                <TableCell>{row.isac3}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac4") && (
                <TableCell>{row.isac4}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac5") && (
                <TableCell>{row.isac5}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac6") && (
                <TableCell>{row.isac6}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac7") && (
                <TableCell>{row.isac7}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac8") && (
                <TableCell>{row.isac8}</TableCell>
              )}

            <TableCell>
              <div className={classes.unitPriceWrapper}>
                <TextField
                  variant="outlined"
                  value={row.unit_price_format}
                  onChange={(e: any) => {
                    let value = "";
                    let _price: any = priceInputBySign(e.target.value);
                    if (
                      !isNaN(_price) &&
                      _price >= 0 &&
                      decimalPointIsLenth(e.target.value)
                    ) {
                      value = e.target.value.slice(0, 11);
                    } else {
                      value = row.unit_price_format;
                      _price = row.unit_price;
                    }
                    //  props.setUnitPriceFormat(value);
                    _price = _price.toString();
                    //props.setUnitPrice(_price);
                    props.setSingleOrSetValue(
                      "unit_price",
                      { price: _price, format: value },
                      index,
                      type
                    );
                  }}
                  onBlur={(e) =>
                    props.changeDataListen(
                      "set_unit_price",
                      row.unit_price,
                      index
                    )
                  }
                  error={errorFlg3}
                  helperText={
                    errorFlg3 ? errorData[index].unit_price_error : ""
                  }
                  disabled={props.reviewFlg}
                />
                <div className={classes.unit}>
                  {symbolByCurrency(config.currency)}
                </div>
              </div>
            </TableCell>
            <TableCell>{currency(total_unit_price, config.currency)}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
