import { methods } from "../constants/common.constants";
import { http } from "./Http";

// AM0101
export const CreateUserInfoAPI = (data: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0102
export const GetReadUserInfoAPI = (sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/${sub}`,
    config: config,
  };
  return http(param);
};

// AM0103
export const GetReadUserInfoAPIforDZ = (sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/dz/${sub}`,
    config: config,
  };
  return http(param);
};

// AM0104
export const UpdateUserInfoAPIforDZ = (data: any, sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/dz/${sub}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0105
export const UpdateUserInfoAPI = (data: any, sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/${sub}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0106
export const DeleteUserInfoAPI = (data: any, sub: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/${sub}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0107
export const GetUserinfoDetailListAPI = (config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users?biztype=1`,
    config: config,
  };
  return http(param);
};

// AM0107
export const GetUserinfoDetailListAPIByDz = (config: any, query_parameters: string) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users?biztype=0`+ query_parameters,
    config: config,
  };
  return http(param);
};

// AM0108
export const GetReadUserInfoAPIForUserinfoRepair = (config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/userinfo_repair`,
    config: config,
  };
  return http(param);
};

// AM0109
export const UpdateUserInfoAPIForUserinfoRepair = (data: any, config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/userinfo_repair`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0113
export const ResetUserInfoMFAAPI = (sub: any, config: any) => {
  const { baseURL, token } = config;
  const data = {
    updated_date: "2021-11-10T12:20:05Z",
  };
  const param = {
    token: token,
    url: `${baseURL}/v1/users/mfa/${sub}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0114
export const GetUserInfoCSVAPIForDownload = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/users/csv/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
