import React, { useState } from "react";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// api
import api from "../../../modules/api/Api";
// components
import { SearchResultTitleAllocate } from "./Jobs.SearchResult.Title.Allocate";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import Button from "@material-ui/core/Button";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  JobAllocateIcon,
  RepairRegisterIcon,
} from "../../../modules/components/icons/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "10px 20px 0 20px",
      borderBottom: "1px solid #CFCFCF",
    },
    area: {
      display: "flex",
      alignItems: "center",
      paddingBottom: "10px",
    },
    item: {
      paddingRight: "10px",
    },
  })
);

const defaultValue = {
  erp_user_id: "",
  name: "",
};

export const SearchResultTitle = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.jobs" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getCommonInfo, getCommonConfig } = useCommonInfo();
  const commonInfo = getCommonInfo() as any;
  const config = getCommonConfig() as any;
  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  // Allocate button click event
  const [AllocateOpen, setAllocateOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleAllocate = () => {
    setAllocateOpen(true);
  };

  const AllocateClose = () => {
    setAllocateOpen(false);
  };

  // Check whether the selected jobs whether are already allocated.
  const checkAlreadyAllocated = () => {
    let hasAlreadyAllocated = false;
    for (let index1 in props.data) {
      if (
        props.selectedArray.indexOf(props.data[index1].job_no) > -1 &&
        props.data[index1].technician_cd &&
        props.data[index1].technician_cd !== ""
      ) {
        hasAlreadyAllocated = true;
        setConfirmationOpen(hasAlreadyAllocated);
        break;
      }
    }
    return hasAlreadyAllocated;
  };

  const creatUpdateDataForAllocation = () => {
    let list: any = [];
    props.selectedRowArray.forEach((item: any) => {
      let itemMessage = {
        job_no: item.job_no,
        technician_cd: selectedValue.erp_user_id,
        location_no: "",
        taskForQAExcluded: "JobList",
        updated_date: item.updated_date,
      };
      list.push(itemMessage);
    });
    return list;
  };

  // update the Technician code of Job Header when allocation AT0116
  const allocateJobs = async () => {
    await api
      .updateMultipleRepairJobAPI(creatUpdateDataForAllocation(), config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT0116 response: ", response);
        if (code !== 200) {
          const obj: any = {
            no: props.alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name." + response.key),
            }),
            alertType: "error",
          };
          let alertMessageListNew: any = [obj];
          props.setAlertMessageList(alertMessageListNew);
        } else {
          const obj: any = {
            no: props.alertMessageList.length,
            message: com("messages.MS0022"),
            alertType: "success",
          };
          let alertMessageListNew: any = [obj];
          props.setAlertMessageList(alertMessageListNew);
        }
      });
    props.setReSearchFlg(true);
  };

  const getTechnicianValue = (hasConfirmed = false) => {
    if (hasConfirmed || !checkAlreadyAllocated()) {
      // Allocte the jobs to the seleted technician
      allocateJobs();
      setConfirmationOpen(false);
      setAllocateOpen(false);
      setOrder("asc");
      setSelectedValue(defaultValue);
    }
  };

  const selected = (
    <>
      {props.userPermisssionInfo.job_allocation_authFlg && (
        <div className={classes.item}>
          <span>{props.numSelected}</span>
          {t("txt_JobsSelected")}
        </div>
      )}
      {props.userPermisssionInfo.job_allocation_authFlg && (
        <div className={classes.item}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<JobAllocateIcon />}
            onClick={handleAllocate}
          >
            {t("btn_Allocate")}
          </Button>
        </div>
      )}
      {props.userPermisssionInfo.job_allocation_authFlg && (
        <div className={classes.item}>
          <Button variant="text" onClick={props.handleCancel}>
            {t("btn_Cancel")}
          </Button>
        </div>
      )}
    </>
  );

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.area}>
          {props.numSelected > 0 ? selected : null}
        </div>
        <div className={classes.area}>
          {/* {props.userPermisssionInfo.job_list_download_authFlg && (
            <div className={classes.item}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                disabled={
                  !(props.selectedArray && props.selectedArray.length > 0)
                }
                onClick={() => props.downloadCSV(props.selectedArray)}
              >
                {t("btn_download_job")}
              </Button>
            </div>
          )} */}
          {props.userPermisssionInfo.job_list_download_authFlg && (
            <div className={classes.item}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={props.downloadCSVBySearch}
              >
                {t("btn_download_job")}
              </Button>
            </div>
          )}
          {props.userPermisssionInfo.job_register_authFlg && (
            <div className={classes.item}>
              <Button
                variant="contained"
                startIcon={<RepairRegisterIcon />}
                onClick={props.handleRegister}
                color="primary"
              >
                {t("btn_Register")}
              </Button>
            </div>
          )}
          <div>
            <Button
              variant="contained"
              startIcon={<LibraryBooksIcon />}
              onClick={props.handleTechnicianList}
            >
              {t("btn_TechnicianList")}
            </Button>
          </div>
        </div>
      </div>
      <SearchResultTitleAllocate
        AllocateOpen={AllocateOpen}
        list={commonInfo.technicianList}
        AllocateClose={AllocateClose}
        handleSend={getTechnicianValue}
        confirmationOpen={confirmationOpen}
        setConfirmationOpen={setConfirmationOpen}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
    </React.Fragment>
  );
};
