import { withStyles } from "@material-ui/core/styles";
import { BarGraphVertical } from "./bargraph_vertical.component";

export const StyledBarGraphVertical = withStyles((theme) => ({
  root: {
    margin:"5px",
    width:"auto",
    display: "block",
    background:
      "rgb(255,255,255)",
      padding:"15px",

    "& > *": {
      margin: "10px",
      width: "min-content"
    }
  },

  title: {
    fontSize: "30px",
    fontWeight: "bold",
    width:"auto",
    margin: "auto",
    height: "auto",
    textAlign: "left",
    color: "black",
    padding: "10px"
  },
  title2: {
    width:"auto",
    fontSize:"18px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    padding: "10px"
  },
  svgText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fill: "white"
  }
}))(BarGraphVertical);
