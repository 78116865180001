import React, { useEffect, useState } from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const JobsDetailGeneralRepairModelForm = (props: any) => {
  const classes = useStyles();
  const {
    dataList,
    formList,
    setFormListValue,
    formErrorList,
    getRepairHistory,
    onBlurOfModelNo,
    onBlurOfSerialNo,
    userPermisssionInfo,
    repairJobPartsList,
    setButtonDisableFlg,
    setRepairHistoryList,
  } = props;
  const { getCommonInfo, setCommonInfo } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.label",
  });
  const [initFlg, setInitFlg] = useState(true);
  const [tempModelNo, setTempModelNo] = useState("");
  const [tempSerialNo, setTempSerialNo] = useState("");

  useEffect(() => {
    if (initFlg && dataList.statusName !== "") {
      setTempModelNo(dataList.model_no);
      setTempSerialNo(dataList.serial_no);
      setInitFlg(false);
    }
  });

  const localOnBlurOfModelNo = async (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const _model_no = e.target.value;
    console.log("localOnBlurOfModelNo", e.target.value, _model_no);
    if (onBlurOfModelNo()) {
      if (
        (_model_no !== tempModelNo || formList.serial_no !== tempSerialNo) &&
        formList.serial_no !== "" && formList.serial_no !== "*" &&
        _model_no !== ""
      ) {
        apiLoading(true);
        let query_parameters =
          "model_no=" +
          encodeURIComponent(_model_no) +
          "&serial_no=" +
          encodeURIComponent(formList.serial_no) +
          "&claim_no=" +
          encodeURIComponent(0) +
          "&job_no=" +
          encodeURIComponent(Number(formList.job_no) ? Number(formList.job_no) : 0);
        await getRepairHistory(query_parameters, formList.job_no);
        apiLoading(false);
        setTempModelNo(formList.model_no);
        setTempSerialNo(formList.serial_no);
      } else if (formList.serial_no === "*") {
        setRepairHistoryList([]);
      }
    }
  };

  const localOnBlurOfSerialNo = async (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onBlurOfSerialNo()) {
      if (
        (e.target.value !== tempSerialNo ||
          formList.model_no !== tempModelNo) &&
        formList.model_no !== "" &&
        e.target.value !== "" && e.target.value !== "*"
      ) {
        apiLoading(true);
        let query_parameters =
          "model_no=" +
          encodeURIComponent(formList.model_no) +
          "&serial_no=" +
          encodeURIComponent(e.target.value) +
          "&claim_no=" +
          encodeURIComponent(0) +
          "&job_no=" +
          encodeURIComponent(Number(formList.job_no) ? Number(formList.job_no) : 0);
        await getRepairHistory(query_parameters, formList.job_no);
        apiLoading(false);
        setTempModelNo(formList.model_no);
        setTempSerialNo(formList.serial_no);
      } else if (formList.serial_no === "*") {
        setRepairHistoryList([]);
      }
    }
  };

  const getOptionLabel = (option: any) => option.model_no || "";
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });

  const getModelNoStatus = () => {
    if (userPermisssionInfo.model_no_authFlg) {
      if (repairJobPartsList.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getSerialNoStatus = () => {
    if (userPermisssionInfo.serial_no_authFlg) {
      if (repairJobPartsList.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <table className={classes.tableLayout}>
      <tbody>
        <tr>
          <th>{t("model_no")}</th>
          <td>
            <Autocomplete
              disabled={getModelNoStatus()}
              className={classes.autoComplete}
              id="model_no"
              freeSolo
              disableClearable
              options={commonInfo.itemList}
              getOptionLabel={getOptionLabel}
              filterOptions={filterOptions}
              inputValue={formList.model_no}
              value={formList.model_no}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "200px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) =>
                option.model_no.toString().toUpperCase() ===
                formList.model_no.toString().toUpperCase()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={formErrorList.model_no_error !== ""}
                  helperText={formErrorList.model_no_error}
                  onBlur={localOnBlurOfModelNo}
                  onFocus={() => setButtonDisableFlg("focus")}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  setFormListValue("model_no", value.model_no);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  setFormListValue("model_no", value);
                }
              }}
            />
          </td>
        </tr>
        <tr>
          <th>{t("serial_no")}</th>
          <td>
            <TextField
              disabled={getSerialNoStatus()}
              value={formList.serial_no}
              variant="outlined"
              onChange={(e) => setFormListValue("serial_no", e.target.value)}
              inputProps={{ maxLength: 15 }}
              error={formErrorList.serial_no_error !== ""}
              helperText={formErrorList.serial_no_error}
              onBlur={localOnBlurOfSerialNo}
              onFocus={() => setButtonDisableFlg("focus")}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
