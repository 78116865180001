import React from "react";
// hooks
import { useStyles } from "./ClaimHistory.styles";
// language
import { useLocale } from "../../hooks/UseLocale";
import { date } from "../numberFormat/NumberFormat.Date";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// methods
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { getCodeNameByNumber, i18text } from "../../../modules/common.methods";

export const ClaimDetailHistoryTbody = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { dataList } = props;

  const editDateTime = (dateTime: string) => {
    let editedDateTime = "";
    if (dateTime && dateTime !== "") {
      let dateTimeLong = new Date(dateTime).getTime();
      //editedDateTime = date(dateTimeLong) + " " + time(dateTimeLong);
      editedDateTime = date(dateTimeLong);
    }
    return editedDateTime;
  };

  const editDescription = (
    table_id: string,
    column_id: string,
    content: any
  ) => {
    let editedDescription = "";
    let getKey = table_id + "_" + column_id;
    if (getKey) {
      let statusName = content;
      if (column_id === "status") {
        column_id = "wco_" + column_id;
      }
      let array = com(column_id);
      if (Array.isArray(array) && content && content !== "") {
        for (let index in array) {
          if (array[index].code.toString() === content) {
            statusName = array[index].name;
            break;
          }
        }
      }
      if (
        column_id === "entry_date" ||
        column_id === "purchased_date" ||
        column_id === "received_date" ||
        column_id === "repair_date" ||
        column_id === "approve_date"
      ) {
        if (isNaN(statusName) && !isNaN(Date.parse(statusName))) {
          statusName = editDateTime(statusName);
        }
      }

      // 金額
      if (
        column_id === "total_amount" ||
        column_id === "net_price" ||
        column_id === "amount"
      ) {
        if (!isNaN(statusName)) {
          statusName = statusName
            ? currency(Number(statusName), props.currency)
            : "-";
        }
      }

      // check or not Check
      if (
        column_id === "attention_check_flg" ||
        column_id === "transport_cost_flg"
      ) {
        statusName = getCodeNameByNumber(Number(content), com("Check_flg"));
      }

      console.log("editDescription", column_id, statusName, content);

      // weight
      if (column_id === "weight") {
        if (Number(statusName) !== 0) {
          statusName += " kg";
        } else {
          statusName = "";
        }
      }

      // device_runtime
      if (column_id === "device_runtime") {
        if (Number(statusName) !== 0) {
          statusName += " h";
        } else {
          statusName = "";
        }
      }

      // pre approve flg
      if (column_id === "pre_approve_flg") {
        statusName = getCodeNameByNumber(Number(content), com("pre_approved"));
      }

      // error type
      if (column_id === "error_type") {
        let arrayErrorType = props.errorType;
        if (Array.isArray(arrayErrorType) && content && content !== "") {
          for (let index in arrayErrorType) {
            if (arrayErrorType[index].error_type.toString() === content) {
              statusName = arrayErrorType[index].error_description;
              break;
            }
          }
        }
      }

      editedDescription =
        i18text(com, "messages_table_name.", getKey) + ": " + statusName;
    }
    return editedDescription;
  };

  const editDescriptionByUpdateType = (row: any) => {
    let editedDescription = "";
    if (row.update_type === 1) {
      editedDescription = com("messages.MS0043", {
        tableName: i18text(com, "messages_table_name.", row.edit_table_id),
      });
    } else if (row.update_type === 3) {
      editedDescription = com("messages.MS0044", {
        tableName: i18text(com, "messages_table_name.", row.edit_table_id),
      });
    } else {
      editedDescription = editDescription(
        row.edit_table_id,
        row.edit_column_id,
        row.edit_content
      );
    }
    return editedDescription;
  };

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  const getShowFlag = (row: any) => {
    return row.edit_column_id !== "manually_update_flg" && 
    row.edit_column_id !== "note_type" && 
    row.edit_column_id !== "note"
  };

  return (
    <TableBody className={classes.tbody}>
      {dataList &&
        setSortData(dataList, "desc", "created_date").map(
          (row: any, index: number) => {
            return (
              getShowFlag(row) && (
                <TableRow hover tabIndex={-1} key={index}>
                  <TableCell className={classes.dateCell}>
                    {editDateTime(row.created_date)}
                  </TableCell>
                  <TableCell className={classes.dateCell}>
                    {row.changed_by ? row.changed_by : ""}
                  </TableCell>
                  <TableCell className={classes.DescriptionCell}>
                    {editDescriptionByUpdateType(row)}
                  </TableCell>
                </TableRow>
              )
            );
          }
        )}
    </TableBody>
  );
};
