import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        color: "red",
        marginLeft: "0px",
      },
    },
    tableUserInfo: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "200px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "25px",
      },
    },
    tableUserInfo02: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        maxWidth: "185px",
        fontWeight: "normal",
        whiteSpace: "break-spaces",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingLeft: "5px",
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "20px",
      },
    },
    mark: {
      color: "red",
      // paddingTop: "5px",
    },
    mark2: {
      color: "gray",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    textFieldForCustomer: {
      width: "30%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    vertialAlignTop: {
      verticalAlign: "top",
    },
    displayFlexArea: {
      display: "flex",
    },
    inputContainer: {
      paddingRight: "5px",
      width: "120px",
      minWidth: "120px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    inputNameContainer: {
      flex: "1 1 auto",
      overflow: "hidden",
      wordBreak: "break-all",
      fontSize: "0.75rem",
      marginTop: "3px",
      textAlign: "left",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    textArea: {
      width: "100%",
      height: "130px",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px 0 5px 5px",
      },
    },
    textAreaForCustomer: {
      width: "100%",
      height: "80px",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px 0 5px 5px",
      },
    },
    selectContainer: {
      width: "100%",
      height: "30px",
    },
    select: {
      height: "30px",
      "& .MuiOutlinedInput-input": {
        // paddingTop: "17px",
        padding: "5px",
      },
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    checkboxLayout: {
      padding: 0,
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "350px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
    },
    buttonContainer2: {
      width: "100%",
      maxWidth: "450px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
    },
    buttonWidth: {
      width: "95px",
      height: "35px",
    },
    buttonWidth2: {
      width: "130px",
      height: "35px",
    },
    autoComplete: {
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
          lineHeight: "30px",
          height: "30px",
        },
    },
    phoneNumberInput: {
      height: "30px",
      "& .form-control": {
        width: "100%",
        background: "none",
      },
    },

    iconButton: {
      position: "absolute",
      right: "3px",
      top: "4px",
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
    },
    groupArea: {
      border: "1px solid #767676",
      padding: "10px 5px 5px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "12px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
    },
    customerNoStyle: {
      paddingBottom: "5px !important",
    },
    customerNo: {
      position: "relative",
      "& .MuiOutlinedInput-input": {
        paddingRight: "30px",
        minHeight: "30px",
        //   padding: "0",
        paddingLeft: "5px",
      },

      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    line: {
      border: "1px solid black",
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);
