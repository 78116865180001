import React, { useEffect } from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "20px",
      textAlign: "right",
    },
    item: {
      paddingBottom: "20px",
    },
  })
);

export const JobsDetailPartsDrawingRegisterButton = (props: any) => {
  const classes = useStyles();
  const {
    handleRegister,
    handleRegisterComplete,
    registerButtonFlg,
    repairCompleteFlg,
    registerDisableFlg,
    errorRegisterList,
    itemNoErrorList,
  } = props;

  const { getHotKey, setHotKey } = useCommonInfo();

  let hotKeyInfo = getHotKey() as any;
  useEffect(() => {
    if (
      hotKeyInfo.handleF10Register &&
      registerButtonFlg &&
      !registerButtonDisabled()
    ) {
      handleRegister();
    }
    setHotKey("handleF10Register", false);
  }, [hotKeyInfo.handleF10Register]);

  useEffect(() => {
    if (hotKeyInfo.handleF11Complete && repairCompleteFlg && !props.disabled) {
      handleRegisterComplete();
    }
    setHotKey("handleF11Complete", false);
  }, [hotKeyInfo.handleF11Complete]);

  // language
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.parts_drawing",
  });

  const registerButtonDisabled = () => {
    if (errorRegisterList.length > 0) {
      return true;
    } else if (registerDisableFlg) {
      return true;
    } else if (
      itemNoErrorList.filter((item: any) => item.type === "5").length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        {registerButtonFlg && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
            disabled={registerButtonDisabled()}
          >
            {t("btn_register")}
          </Button>
        )}
      </div>
      <div>
        {repairCompleteFlg && (
          <Button
            variant="contained"
            color="primary"
            disabled={props.disabled}
            onClick={handleRegisterComplete}
            startIcon={<CheckCircleOutlineIcon />}
          >
            {t("btn_complete")}
          </Button>
        )}
      </div>
    </div>
  );
};
