import React, { useState, useEffect } from "react";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { Paper, Typography } from "@material-ui/core";

const colors = ["rgb(77, 196, 255)",  "rgb(255, 128, 130)","rgb(3, 175, 122)","rgb(246, 170, 0)"];

const RenderLabelContent = ({ percent, x, y, midAngle, classes, value }) => {
  return (
    <g
      transform={`translate(${x}, ${y})`}
      textAnchor={midAngle < -90 || midAngle >= 90 ? "end" : "start"}
    >
      <text className={classes.svgText} x={0} y={-5}>{`${value}`}</text>
      <text className={classes.svgText} x={0} y={15}>{`${(
        percent * 100
      ).toFixed(0)}%`}</text>
      </g>
  );
};

export const PieGraph = ({ title, callData, classes }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(callData);
  }, [callData]);

  const handleHeight = () => {
    return data.length > 3 ? 200 + data.length * 14 : 250;
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <Typography className={classes.title}  >
        {title}
        </Typography>
        <Typography className={classes.title2} >
        <PieChart width={420} height={375}>
         
          <Pie 
            data={data}
            dataKey="count"
            cy={210}
            startAngle={180}
            endAngle={0}
            innerRadius={38}
            outerRadius={142}
            label={<RenderLabelContent classes={classes} />}
            paddingAngle={2}
            isAnimationActive={true}
          >
            {data.map((_, index) => (
              <Cell
                key={`slice-${index}`}
                strokeWidth={"1"}
                stroke={null}
                fill={colors[index]}
              />
            ))}
            
          </Pie>
        <Legend 
        layout="vertical" 
        textAlign= 'middle'
        align="center"
         />
        </PieChart>
        </Typography>
      </Paper>
  );

}