import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailCustomerTableThead } from "./PromotionDetail.Customer.Table.Thead";
import { PromotionDetailCustomerTableTbody } from "./PromotionDetail.Customer.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      boxShadow: "none",
    },
    promotionContainer: {
      maxHeight: "560px",
      marginBottom: "20px",
      border: "1px solid rgba(0, 0, 0, 20%)",
      borderRadius: "5px",
    },
    buttonArea: {
      "& > button": {
        marginRight: "10px",
      },
    },
    errorText: {
      color: "#f44336",
      fontSize: "12px",
      marginBottom: "20px",
    },
  })
);

export const PromotionDetailCustomerTable = (props: any) => {
  const classes = useStyles();

  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.index);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray, row.index);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data.slice(0);
      const newSelecteds = data.map((item: any) => item.index);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  const addCustomerRow = () => {
    // ready one row
    const getCustomerField = JSON.parse(JSON.stringify(props.data[0]));
    for (let key in getCustomerField) {
      getCustomerField[key] = "";
    }

    // add row at last
    const getCustomerData = JSON.parse(JSON.stringify(props.data));
    getCustomerData.push(JSON.parse(JSON.stringify(getCustomerField)));
    getCustomerData[getCustomerData.length - 1].index =
      getCustomerData.length - 1;
    props.setData("promotion_customer", getCustomerData);

    const getOldData = JSON.parse(JSON.stringify(props.oldData));
    getOldData.push(JSON.parse(JSON.stringify(getCustomerField)));
    getOldData[getOldData.length - 1].index = getOldData.length - 1;
    props.setOldData("promotion_customer", getOldData);

    if (props.selectedArray.length > 0) {
      props.setIndeterminate(true);
    } else {
      props.setIndeterminate(false);
    }
    props.setAllChecked(false);

    const getDefaultField = JSON.parse(JSON.stringify(props.defaultValueList));
    getDefaultField.push(JSON.parse(JSON.stringify(getCustomerField)));
    getDefaultField[getDefaultField.length - 1].index =
      getDefaultField.length - 1;
    props.setDefaultValue("promotion_customer", getDefaultField);

    // add error list
    const errorValueField = JSON.parse(
      JSON.stringify(props.errorValueList.promotion_customer[0])
    );
    for (let key in errorValueField) {
      errorValueField[key] = "";
    }
    const getErrorData = JSON.parse(
      JSON.stringify(props.errorValueList.promotion_customer)
    );
    getErrorData.push(JSON.parse(JSON.stringify(errorValueField)));
    const errorValue = Object.assign({}, props.errorValueList, {
      promotion_customer: getErrorData,
    });
    props.setErrorValueList(errorValue);

    // add disabled flag
    const editListAry = JSON.parse(JSON.stringify(props.customerEditList));
    editListAry.push(false);
    props.setCustomerEditList(editListAry);
  };

  const deleteCustomerRow = () => {

    // ready one row
    const getCustomerField = JSON.parse(JSON.stringify(props.data[0]));

    for (let key in getCustomerField) {
      getCustomerField[key] = "";
    }

    // delete row with "seq_no"
    if (props.hasSeqNoRow) {
      const hasSeqNo = props.selectedRowArray.some((item: any) => {
        return item.seq_no && item.seq_no !== "";
      });
      props.setHasSeqNoRow(!hasSeqNo);
    }

    // filter not selected item
    const getCustomerData = props.data.filter((item: any) => {
      return props.selectedArray.indexOf(item.index) === -1;
    });

    const getOldData = props.oldData.filter((item: any) => {
      return props.selectedArray.indexOf(item.index) === -1;
    });

    if (getCustomerData.length === 0) {
      getCustomerData.push(getCustomerField);
      getOldData.push(getCustomerField);
    }

    getCustomerData.forEach((item: any, index: number) => {
      item.index = index;
    });

    getOldData.forEach((item: any, index: number) => {
      item.index = index;
    });

    props.setData(
      "promotion_customer",
      JSON.parse(JSON.stringify(getCustomerData))
    );

    props.setOldData(
      "promotion_customer",
      JSON.parse(JSON.stringify(getOldData))
    );

    props.setSelectedArray([]);
    props.setSelectedRowArray([]);
    props.setIndeterminate(false);
    props.setAllChecked(false);

    const getDefaultField = props.defaultValueList.filter((item: any) => {
      return props.selectedArray.indexOf(item.index) === -1;
    });
    if (getDefaultField.length === 0) {
      getDefaultField.push(getCustomerField);
    }
    getDefaultField.forEach((item: any, index: number) => {
      item.index = index;
    });
    props.setDefaultValue(
      "promotion_customer",
      JSON.parse(JSON.stringify(getDefaultField))
    );

    // remove error list
    const getErrorData = JSON.parse(
      JSON.stringify(props.errorValueList.promotion_customer)
    );
    const getErrorField = getErrorData[0];
    for (let key in getErrorField) {
      getErrorField[key] = "";
    }
    const errorData = getErrorData.filter((item: any, index: number) => {
      return props.selectedArray.indexOf(index) === -1;
    });
    if (errorData.length === 0) {
      errorData.push(getErrorField);
    }
    const errorValue = Object.assign({}, props.errorValueList, {
      promotion_customer: errorData,
    });
    props.setErrorValueList(errorValue);

    // remove disabled flag
    const editListAry = props.customerEditList.filter(
      (item: any, index: number) => {
        return props.selectedArray.indexOf(index) === -1;
      }
    );
    if (editListAry.length === 0) {
      editListAry.push(false);
    }
    props.setCustomerEditList(editListAry);
  };

  const setCustomerValue = (property: any, value: any, index: number) => {
    const setCustomerField = JSON.parse(JSON.stringify(props.data));
    setCustomerField[index][property] = value;
    props.setData("promotion_customer", setCustomerField);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer className={classes.promotionContainer}>
          <Table stickyHeader>
            <PromotionDetailCustomerTableThead
              indeterminate={props.indeterminate}
              allChecked={props.allChecked}
              onSelectAllClick={handleSelectAllClick}
              reviewFlg={props.reviewFlg}
              salesAnalysisList={props.salesAnalysisList}
            />
            <PromotionDetailCustomerTableTbody
              data={props.data}
              selected={props.selectedArray}
              handleClick={(event: any, row: any) => handleClick(event, row)}
              handleCSearch={props.handleCSearch}
              setCustomerValue={setCustomerValue}
              customerEditList={props.customerEditList}
              onBlurOfCustomerNo={props.onBlurOfCustomerNo}
              onFocusOfCustomerNo={props.onFocusOfCustomerNo}
              errorValueList={props.errorValueList}
              reviewFlg={props.reviewFlg}
              setLastIndex={props.setLastIndex}
              salesAnalysisList={props.salesAnalysisList}
              changeDataListen={props.changeDataListen}
            />
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.errorText}>
        {props.errorValueList.customer_error}
      </div>
      <div className={classes.errorText} data-testid="customer_oneFieldRequird_error">
        {props.errorValueList.customer_oneFieldRequird_error}
      </div>
      <div className={classes.buttonArea}>
        <Button
          variant="contained"
          data-testid="btn_customer_add"
          color="primary"
          startIcon={<AddBoxIcon />}
          onClick={addCustomerRow}
          disabled={props.reviewFlg || props.onCustomerNoFlag}
        >
          {t("btn_Add")}
        </Button>
        <Button
          variant="contained"
          data-testid="btn_customer_delete"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={(props.selectedRowArray.length > 0 ? false : true)  || props.onCustomerNoFlag}
          onClick={deleteCustomerRow}
        >
          {t("btn_Delete")}
        </Button>
      </div>
    </>
  );
};
