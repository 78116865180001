import React, { useState, useEffect } from "react";
// methods
import {
  init,
  getSvgPath,
  zoom,
  handleTableMouseEvent,
  setTableSelectedListFun,
  getRegisterOptions,
  getReisterStatus,
  getRegisterInsertMessage,
  getRegisterOrderMessage,
  setSvgSelectedListFun,
  getSelectedCurrectMessage,
  handleTableDoubleClickEvent,
  handleSvgDoubleClickEvent,
  addReigsterMessage,
  deleteReigsterMessage,
  handleClickEventBySvgFunc,
  setDefaultSVGandPartsFunc,
  getRepairCompleteDisableFunc,
} from "../methods/JobsDetail.PartsDrawing.Methods";
// components
import { JobsDetailPartsDrawingSvgMessage } from "./JobsDetail.PartsDrawing.SvgMessage";
import { JobsDetailPartsDrawingItemMemorandum } from "./JobsDetail.PartsDrawing.ItemMemorandum";
import { JobsDetailPartsDrawingTechnicalInformation } from "./JobsDetail.PartsDrawing.TechnicalInformation";
import { JobsDetailPartsDrawingModel } from "./JobsDetail.PartsDrawing.Model";
import { JobsDetailPartsDrawingTableControl } from "./JobsDetail.PartsDrawing.TableControl";
import { JobsDetailPartsDrawingRegisterThead } from "./JobsDetail.PartsDrawing.Register.Thead";
import { JobsDetailPartsDrawingRegisterTbody } from "./JobsDetail.PartsDrawing.Register.Tbody";
import { JobsDetailPartsDrawingMemoForEndUser } from "./JobsDetail.PartsDrawing.MemoForEndUser";
import { JobsDetailPartsDrawingRegisterButton } from "./JobsDetail.PartsDrawing.Register.Button";
// constants
import {
  zoomScale,
  layerList,
} from "../constants/JobsDetail.PartsDrawing.constants";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { JobsDetailPartsDrawingThead } from "./JobsDetail.PartsDrawing.Thead";
import { JobsDetailPartsDrawingTbody } from "./JobsDetail.PartsDrawing.Tbody";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// method
import { getPartsDrawingData } from "../methods/JobsDetail.Common.Methods";
import {
  getApiResultStatus,
  IsBlank,
  getRegionPullDownDefault,
  determineDataType,
} from "../../../modules/common.methods";
import { priceOutputByCountryCode } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// api
import api from "../../../modules/api/Api";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { getLocale } from "../../../modules/i18n";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFluid: {
      marginTop: "10px",
    },
    itemMemorandumContainer: {
      marginTop: "-20px",
    },
    tabArea: {
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    tabButton: {
      minWidth: "auto",
      background: "#fff",
      color: "#3D899F",
      border: "1px solid gray",
      borderBottom: "none",
      // textTransform: "inherit",
      "&:hover": {
        background: "gray",
        color: "#fff",
      },
      "&.Mui-selected": {
        background: "gray",
        color: "#fff",
      },
    },
    tabLayer: {
      border: "1px solid #000",
      position: "relative",
      overflow: "hidden",
    },
    layerContainer: {
      position: "relative",
      zIndex: 0,
    },
    groupContainer: {
      maxHeight: 370,
      border: "1px solid rgba(198, 209, 221, 0.5)",
    },
    zoomButtonContainer: {
      position: "absolute",
      bottom: 0,
      let: 0,
      zIndex: 1,
    },
    drawingContainer: {
      position: "relative",
    },
    registerMessageControl: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
    MemoForEndUser: {
      width: "70%",
    },
    registerError: {
      color: "red",
    },
    registerErrorOrange: {
      color: "#ff9800",
    },
    registerTotal: {
      textAlign: "right",
    },
    svgArea: {
      paddingTop: "20px",
    },
    rightArea: {
      paddingTop: "92px!important",
    },
  })
);

let dropX = 0;
let dropY = 0;
let _LoggerListManage: any = [];

export const JobsDetailPartsDrawing = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.parts_drawing",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const {
    partsTabFlg,
    partsTabStarted,
    dataList,
    errorMessage,
    successMessage,
    updateBaseData,
    userPermisssionInfo,
    formList,
  } = props;
  // register response
  const [registerResponse, setRegisterResponse] = useState([]);
  // pages
  const [pages, setPages] = useState([]);
  // regionList
  const [regionList, setRegionList] = useState([]);
  const [holdRegionList, setholdRegionList] = useState([]);
  // models
  const [specificationModels, setSpecificationModels] = useState([]);
  // parts
  const [parts, setParts] = useState([]);
  // register
  const [registerData, setRegisterData] = useState([]);
  // svg path
  const [svgDocPath, setSvgDocPath] = useState([]);
  // drop style
  const [dropStyle, setDropStyle] = useState({ transform: "translate(0, 0)" });
  // common
  const {
    getCommonConfig,
    setCommonInfo,
    getUserInfoRepair,
    getLoggerManage,
    setLoggerListManage,
    setClearLoggerListMagange,
  } = useCommonInfo();

  // バグ調査のため、ログを取得
  let loggerMessage = getLoggerManage() as any;

  const setLoggerListManageFun = (text: any) => {
    setLoggerListManage(text);
    _LoggerListManage.push(text);
  };
  const setClearLoggerListMagangeFun = () => {
    _LoggerListManage = [];
    setClearLoggerListMagange();
  };

  let userInfoRepair = getUserInfoRepair() as any;
  let config = getCommonConfig() as any;
  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  // svg mouseWheel
  const [wheelList, setWheelList] = useState([]);
  const mouseWheel = (e: any) => {
    e.preventDefault();

    const list = wheelList.slice(0) as any;
    const deltaY: any = e.deltaY;
    list.unshift(deltaY);
    setWheelList(list);
  };
  // svg dragMouseDown
  const dragMouseDown = (e: any) => {
    let strPosX = 0,
      strPosY = 0,
      endPosX = 0,
      endPosY = 0;
    e.preventDefault();
    strPosX = e.clientX;
    strPosY = e.clientY;

    document.onmousemove = function (e2: any) {
      e2.preventDefault();
      endPosX = strPosX - e2.clientX;
      endPosY = strPosY - e2.clientY;
      // Reset Start Position
      strPosX = e2.clientX;
      strPosY = e2.clientY;
      // set the element's new position:
      dropX = dropX - Number(endPosX);
      dropY = dropY - Number(endPosY);
      const tabLayerDrawing2 = document.getElementById("tabLayerDrawing");
      if (tabLayerDrawing2 && tabLayerDrawing2.style) {
        tabLayerDrawing2.style.transform = `translate(${dropX + "px"}, ${
          dropY + "px"
        })`;
      }
    };

    document.onmouseup = function () {
      // Clear event
      document.onmouseup = null;
      document.onmousemove = null;
    };
  };
  const [partsDrawingData, setPartsDrawingData] = useState(
    getPartsDrawingData()
  );
  const [innerShowFlg, setInnerShowFlg] = useState(false);
  const [moviesList, setMoviesList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [fullParts, setFullParts] = useState([]);
  const [active, setActive] = useState<boolean>(false);
  const [region, setRegion] = useState("");
  const MODEL = dataList.model_no;
  let msiApiRegion: any;
  let registerDatalist: any = [];
  const requestCountrys: any = process.env.REACT_APP_WEBMSI_REQUEST_COUNTRYS;
  const [changeRegionValue, setChangeRegionValue] = useState("");
  let regionValue = "";

  useEffect(() => {
    if (partsTabFlg) {
      let _region: any;
      let _models: any;
      let _needCountrys: any = [];
      let apiListStatus = [];
      partsTabStarted();
      getDefaultValue();

      if (dataList.job_no) {
        setLoggerListManageFun(`JDP:F1:v='${dataList.job_no}'`);
        // AT0202
        const RegisterListMessage = getRegisterDataList().then(
          (response: any) => {
            if (response && response.length > 0) {
              registerDatalist = response.slice(0);
            } else {
              registerDatalist = [];
            }
          }
        );
        apiListStatus.push(RegisterListMessage);
      }

      const regionsForModel = api
        .getRegionsForModel(MODEL, config)
        .then(({ code, response }: { code: any; response: any }) => {
          if (getApiResultStatus(code)) {
            msiApiRegion = response;
            if (msiApiRegion.regions !== "" && msiApiRegion.regions !== null) {
              if (msiApiRegion.regions.length > 0) {
                msiApiRegion.regions.forEach((item: any) => {
                  if (requestCountrys.includes(item)) {
                    _needCountrys.push(item);
                  }
                });
                if (_needCountrys.length > 0) {
                  setholdRegionList(_needCountrys);
                } else {
                  setRegionList([]);
                }
              }
            }
          } else {
            apiLoading(false);
          }
        });
      apiListStatus.push(regionsForModel);
      Promise.all(apiListStatus).then(() => {
        setLoggerListManageFun(`JDP:F1_F1:v='${registerDatalist.length}'`);

        if (registerDatalist.length > 0) {
          if (registerDatalist[0].webmsi_region !== "") {
            _region = registerDatalist[0].webmsi_region;
            _models = registerDatalist[0].webmsi_spec;
            getAllInfomation(_region, _needCountrys, _models);
          } else {
            // _region = userInfoRepair.country;
            getAllInfomation(_region, _needCountrys);
          }
        } else {
          // _region = userInfoRepair.country;
          getAllInfomation(_region, _needCountrys);
        }
      });
    }
  }, [partsTabFlg]);

  useEffect(() => {
    setLoggerListManageFun(`JDP:F2:v='${changeRegionValue}'`);

    if (changeRegionValue) {
      let apiListStatus = [];
      regionValue = changeRegionValue;
      partsTabStarted();
      if (dataList.job_no) {
        // AT0202
        const RegisterListMessage = getRegisterDataList().then(
          (response: any) => {
            if (response && response.length > 0) {
              registerDatalist = response.slice(0);
            }
          }
        );
        apiListStatus.push(RegisterListMessage);
      }
      Promise.all(apiListStatus).then(() => {
        // regions
        setRegionList(holdRegionList);
        getAllInfomation(region, holdRegionList, "");
      });
    }
  }, [changeRegionValue]);

  const getAllInfomation = (
    _region: any,
    _needCountrys: any,
    _models?: any
  ) => {
    setLoggerListManageFun(`JDP:F3:v='${_region}'v='${_models}'`);

    apiLoading(true);
    // get parts Message
    const LOCALE = getLocale();
    let apiList = [];
    let msiApiResult: any;
    if (dataList.model_no) {
      const getMemoMessage = api
        .getItemDetailForJobsAPI(encodeURIComponent(dataList.model_no), config)
        .then(({ code, response }: { code: any; response: any }) => {
          if (getApiResultStatus(code)) {
            const newData = {
              model_no: response.model_no,
              memo: response.memo,
            };
            setPartsDrawingData(newData);
          } else {
            if (code !== 404) {
              errorMessage(response);
            }
          }
        });
      apiList.push(getMemoMessage);
    }

    _region = getRegionPullDownDefault(
      _needCountrys,
      _region,
      userInfoRepair.country,
      process.env.REACT_APP_SORT_PRIORITY
    );

    console.log("_needCountrys2", _region, _needCountrys);

    if (!MODEL || !LOCALE || !_region) {
      setInnerShowFlg(false);
    } else {
      setLoggerListManageFun(
        `JDP:F3_F1:v='${_region}',v='${LOCALE}',v='${MODEL}'`
      );
      setInnerShowFlg(true);
      const api2 = getRegionList(MODEL, LOCALE, _region).then(
        ({ code, response }: { code: any; response: any }) => {
          if (getApiResultStatus(code)) {
            if (response.specificationModels.length > 0) {
              msiApiResult = response;
              setRegion(_region);
              regionValue = _region;
              setModels(_models);
            } else {
              return getRegionList(MODEL, LOCALE, userInfoRepair.country).then(
                ({ code, response }: { code: any; response: any }) => {
                  if (getApiResultStatus(code)) {
                    if (response.specificationModels.length > 0) {
                      msiApiResult = response;
                      setRegion(userInfoRepair.country);
                      regionValue = userInfoRepair.country;
                      setModels(_models);
                    } else {
                      return getRegionList(
                        MODEL,
                        LOCALE,
                        _needCountrys[0]
                      ).then(
                        ({ code, response }: { code: any; response: any }) => {
                          if (getApiResultStatus(code)) {
                            msiApiResult = response;
                            setRegion(_needCountrys[0]);
                            regionValue = _needCountrys[0];
                            setModels(_models);
                          } else {
                            apiLoading(false);
                          }
                        }
                      );
                    }
                  } else {
                    apiLoading(false);
                  }
                }
              );
            }
          } else {
            apiLoading(false);
          }
        }
      );
      apiList.push(api2);
      console.log("getAllInfomation msiApiResult", msiApiResult);

      Promise.all(apiList).then(() => {
        setLoggerListManageFun(`JDP:F3_F2`);
        let partsList: any = [];
        if (msiApiResult) {
          try {
            const data: any = init(msiApiResult);

            // pages list
            setPages(data.self_pages);
            // regions
            setRegionList(_needCountrys);
            // models
            setSpecificationModels(data.self_specificationModels);
            // parts
            setParts(data.self_parts);
            setFullParts(data.self_parts);
            setMoviesList(data.technicalInformation_movies);
            setDocumentsList(data.technicalInformation_documents);
            partsList = data.self_parts;
            const pathlist: any = getSvgPath(data.self_drawings);
            setSvgDocPath(pathlist);
            if (data.dataFlg) {
              setInnerShowFlg(false);
            }
          } catch (e) {
            setInnerShowFlg(false);
          }
        } else {
          setInnerShowFlg(false);
        }
        if (registerDatalist.length > 0) {
          setCurrency(registerDatalist[0].currency);
          setTotalFunc(registerDatalist, "default");
          setDefaultHighLight(registerDatalist, partsList);
        } else {
          if (dataList.status === "300") {
            const message: any = getRegisterOptions(
              dataList.repair_option,
              regionValue
            );
            setTotalFunc(message, "addDefaultMessage");
          }
          setTableSelectedList([""]);
        }
        if (dataList.repair_note && dataList.repair_note.length > 0) {
          dataList.repair_note.forEach((item: any) => {
            if (item.note_type === 20) {
              setMemoForEndUser(item.note);
            }
          });
        }
        apiLoading(false);
      });
      const tabLayerId: any = document.getElementById("tabLayerId");
      const tabLayerDrawing = document.getElementById("tabLayerDrawing");
      if (tabLayerId) {
        tabLayerId.addEventListener("wheel", function (e: any) {
          mouseWheel(e);
        });
      }
      if (tabLayerDrawing) {
        tabLayerDrawing.addEventListener("mousedown", function (e: any) {
          dragMouseDown(e);
        });
      }

      return () => {
        if (tabLayerId) {
          tabLayerId.removeEventListener(
            "wheel",
            function (e: any) {
              mouseWheel(e);
            },
            false
          );
        }
        if (tabLayerDrawing) {
          tabLayerDrawing.removeEventListener(
            "mousedown",
            function (e: any) {
              dragMouseDown(e);
            },
            false
          );
        }
      };
    }
  };

  const getRegionList = (MODEL: any, LOCALE: any, _region: any) => {
    return api.getPartsModel(MODEL, LOCALE, _region, config);
  };

  const [changeParts, setChangeParts] = useState([]);
  useEffect(() => {
    setLoggerListManageFun(`JDP:F12:v='${JSON.stringify(changeParts)}'`);
    const drawingNo: any = changeParts[changeParts.length - 1];
    const currentMessage = getSelectedCurrectMessage(drawingNo, fullParts);
    if (currentMessage.message) {
      var table = document.getElementsByClassName(classes.groupContainer)[0];
      table.scrollTop =
        document.getElementById(currentMessage.message.displayId)?.offsetTop! -
        table.clientHeight / 2;
      const { newTableData, newSvgData } = handleClickEventBySvgFunc(
        currentMessage.message,
        tableSelectedList,
        registerData,
        svgSelectedList
      );
      setTableSelectedList(newTableData);
      setSvgSelectedList(newSvgData);
    }
  }, [changeParts]);
  const [currency, setCurrency] = useState("");
  const getDefaultValue = () => {
    setRegisterResponse([]);
    setPages([]);
    setRegionList([]);
    setholdRegionList([]);
    setSpecificationModels([]);
    setParts([]);
    setRegisterData([]);
    setSvgDocPath([]);
    setDropStyle({ transform: "translate(0, 0)" });
    setWheelList([]);
    setPartsDrawingData(getPartsDrawingData());
    setInnerShowFlg(false);
    setMoviesList([]);
    setDocumentsList([]);
    setFullParts([]);
    setChangeParts([]);
    setCurrency("");
    setDoubleClickList([]);
    setCurrentPages(0);
    setEmphasizeParts("");
    setSvgSelectedList([]);
    setScale(1);
    setRegion("");
    setModels("Full");
    setTableSelectedList([]);
    setItemNo("");
    setIndeterminate(false);
    setChecked(false);
    setSelected([]);
    setItemNoError(false);
    setItemNoErrorMessage("");
    setMemoForEndUser("");
    setErrorRegisterList([]);
    setItemNoErrorList([]);
    setOpen(false);
    setMessageBoxButton("");
    setMessageBoxText("messages.MS0030");
    setRegisterMessageBoxType("");
    setTotal("");
  };

  const setDefaultHighLight = (list: any, partsList: any) => {
    const { newTableData, newSvgData } = setDefaultSVGandPartsFunc(
      list,
      partsList
    );
    if (newTableData.length === 0) {
      setTableSelectedList([""]);
    } else {
      setTableSelectedList(newTableData);
    }

    setSvgSelectedList(newSvgData);
  };

  const getRegisterDataList = () => {
    setLoggerListManageFun(`JDP:F4:v='${dataList.job_no}'`);

    return api
      .getReadRepairJobPartsListAPI(dataList.job_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        let list = [];
        if (getApiResultStatus(code)) {
          if (response && response.length > 0) {
            list = response.slice(0);
            if (list.length > 0) {
              list.forEach((item: any) => {
                item.net_price_format = priceOutputByCountryCode(
                  item.net_price
                );
              });
            }
            setRegisterResponse(JSON.parse(JSON.stringify(list)));
            setRegisterData(JSON.parse(JSON.stringify(list)));
          }
        } else {
          setRegisterResponse([]);
          if (code !== 404) {
            errorMessage(response);
          }
        }
        return list;
      });
  };

  useEffect(() => {
    const deltaY = wheelList[0];
    if (deltaY !== undefined) {
      let s: any = scale;
      if (deltaY > 0) {
        s = scale - zoomScale;
      } else {
        s = scale + zoomScale;
      }
      s = s < 0 ? 1 : s;
      setScale(s);
      zoom(s);
    }
  }, [wheelList]);

  // doubleClickList
  const [doubleClickList, setDoubleClickList] = useState([]);
  useEffect(() => {
    setLoggerListManageFun(`JDP:F13:v='${JSON.stringify(doubleClickList)}'`);
    const drawingNo: any = changeParts[changeParts.length - 1];
    const currentMessage = getSelectedCurrectMessage(drawingNo, fullParts);
    if (currentMessage.message) {
      const { newReigsterData, newSvgData, newTableData } =
        handleSvgDoubleClickEvent(
          currentMessage.message,
          registerData,
          svgSelectedList,
          tableSelectedList,
          fullParts
        );
      setTotalFunc(newReigsterData);
      registerScrollMoving();
      setSvgSelectedList(newSvgData);
      setTableSelectedList(newTableData);
      setSelectedInformation(selected, newReigsterData);
    }
  }, [doubleClickList]);

  // tab change
  const [currentPages, setCurrentPages] = React.useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentPages(newValue);
  };

  let timer: any;
  const registerScrollMoving = () => {
    const element: any = document.getElementById("registerTable");
    const Head: any = document.getElementById("registerHead");
    clearInterval(timer);
    timer = setInterval(setScrollMoving, 1000);
  };

  const setScrollMoving = () => {
    const element: any = document.getElementById("registerTable");
    const top =
      element.scrollHeight > element.clientHeight
        ? element.scrollHeight - element.clientHeight
        : 0;

    if (element.scrollTop + 10 < top) {
      element.scrollTop = top;
    } else {
      clearInterval(timer);
    }
  };

  // svg
  const [emphasizeParts, setEmphasizeParts] = useState("");
  const handleMouseOverEventBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    if (layer === "layer2") {
      handleTableMouseEvent(id, "mouseover");
    }
    const currentMessage = getSelectedCurrectMessage(id, fullParts);
    if (currentMessage.message) {
      setEmphasizeParts(currentMessage.message.uniqueID);
    }
  };
  const handleMouseLeaveEventBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    if (layer === "layer2") {
      handleTableMouseEvent(id, "");
    }
    setEmphasizeParts("");
  };
  const [svgSelectedList, setSvgSelectedList] = useState([]);
  const handleClickEventBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    let newPartsList: any = changeParts.slice(0);
    newPartsList.push(id);
    setChangeParts(newPartsList);
  };
  const handleDoubleClickBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    let newPartsList: any = doubleClickList.slice(0);
    newPartsList.push(id);
    setDoubleClickList(newPartsList);
  };

  // zoom
  const [scale, setScale] = useState(1);
  const zoomIn = () => {
    const s = scale + zoomScale;
    setScale(s);
    zoom(s);
  };
  const zoomOut = () => {
    let s = scale - zoomScale;
    if (s < 0) s = 1;
    setScale(s);
    zoom(s);
  };

  // change regions
  const handleChangeregions = (value: string) => {
    setChangeRegionValue(value);
    setRegion(value);
  };

  // current model
  const [models, setModels] = useState("Full");

  // change models
  const handleChangeModels = (value: string) => {
    setModels(value);
    const part = specificationModels.filter(
      (f: any) => f.specificationCode === value
    )[0]["parts"];
    setParts(part);
  };

  // table selected list
  const [tableSelectedList, setTableSelectedList] = useState<string[]>([]);
  const handleTableRowClick = (e: any, row: any) => {
    setLoggerListManageFun(`JDP:F14:v='${JSON.stringify(row)}'`);
    // page
    if (row.page !== undefined && row.page > 0) {
      if (pages.length > 1) {
        setCurrentPages(row.page - 1);
      }
    }
    const newTableData = setTableSelectedListFun(
      row,
      tableSelectedList,
      registerData
    );
    setTableSelectedList(newTableData);
    const newSvgData = setSvgSelectedListFun(
      row,
      svgSelectedList,
      fullParts,
      tableSelectedList,
      registerData
    );
    setSvgSelectedList(newSvgData);
  };
  const handleDoubleClickByTable = (e: any, row: any) => {
    setLoggerListManageFun(`JDP:F15:v='${JSON.stringify(row)}'`);
    const id = row.drawingNo;
    // page
    if (row.page !== undefined && row.page > 0) {
      if (pages.length > 1) {
        setCurrentPages(row.page - 1);
      }
    }
    const { newReigsterData, newSvgData, newTableData } =
      handleTableDoubleClickEvent(
        row,
        registerData,
        svgSelectedList,
        tableSelectedList,
        fullParts
      );
    setTotalFunc(newReigsterData);
    registerScrollMoving();
    setSvgSelectedList(newSvgData);
    setTableSelectedList(newTableData);
    setSelectedInformation(selected, newReigsterData);
  };

  // control button's information
  const [itemNo, setItemNo] = useState("");
  const setItemNoValue = (value: any) => setItemNo(value);
  var _flg: any;
  var _data: any;
  async function handleAddEvent() {
    const { flg, data } = await searchItemNo();
    setLoggerListManageFun(`JDP:F5:v='${JSON.stringify(data)}'`);
    if (flg) {
      if (data) {
        data.order_qty = 1;
        _flg = flg;
        _data = data;
      }
      addItem(data);
    }
  }
  const addItem = (data: any) => {
    setLoggerListManageFun(
      `JDP:F7:v='${JSON.stringify(data)}',v='${
        registerData.length > 0 ? JSON.stringify(registerData.length) : ""
      }'`
    );
    const { newRegisterData, newSvgData, newTableData } = addReigsterMessage(
      ItemNoError,
      data,
      fullParts,
      tableSelectedList,
      svgSelectedList,
      registerData
    );
    setTotalFunc(newRegisterData, "clickAddButton");
    registerScrollMoving();
    setItemNo("");
    setSvgSelectedList(newSvgData);
    setTableSelectedList(newTableData);
  };
  const handleDeleteEvent = () => {
    setLoggerListManageFun(
      `JDP:F8:v='${selected.length > 0 ? JSON.stringify(selected) : ""}',v='${
        registerData.length > 0 ? JSON.stringify(registerData) : ""
      }'`
    );
    const { newTableData, newSvgData, newReigsterList } = deleteReigsterMessage(
      registerData,
      selected,
      fullParts,
      svgSelectedList,
      tableSelectedList
    );
    setTableSelectedList(newTableData);
    setSvgSelectedList(newSvgData);
    setTotalFunc(newReigsterList);
    setSelected([]);
    setChecked(false);
    setIndeterminate(false);
  };

  // register
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = registerData.slice(0);
      const newSelecteds = data.map((item: any, index: number) =>
        Number(index)
      );
      setSelectedInformation(newSelecteds);
      return;
    }
    setSelectedInformation([]);
  };
  const [selected, setSelected] = useState([]);
  const setRegisterDataProperty = (type: string, value: any, index: number) => {
    let list = registerData.slice(0);
    list[index] = Object.assign({}, list[index], { [type]: value });
    setTotalFunc(list, "registerData");
  };
  const registerRowClick = (row: any) => {
    if (row !== undefined) {
      const selectedList: any = selected.slice(0);
      const selectedIndex: any = selectedList.indexOf(row);
      let newSelected: string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelectedInformation(newSelected);
    }
  };
  const setSelectedInformation = (list: any, registerList: any = []) => {
    const registerListMessage =
      registerList.length > 0 ? registerList : registerData;
    setSelected(list);
    const flg = list.length > 0 && list.length < registerListMessage.length;
    setIndeterminate(flg);
    const check = list.length > 0 && list.length === registerListMessage.length;
    setChecked(check);
  };

  const [ItemNoError, setItemNoError] = useState(false);
  const [ItemNoErrorMessage, setItemNoErrorMessage] = useState("");
  const searchItemNo = (_itemNo: any = "", clickType?: any) => {
    setLoggerListManageFun(
      `JDP:F6:v='${_itemNo}',v='${clickType}',v='${itemNo}'`
    );
    let tableFlg = true;
    if (!_itemNo) {
      tableFlg = false;
      _itemNo = itemNo;
    }
    if (_itemNo) {
      if (clickType === "clickAddButton") {
        apiLoading(true);
      }

      // get item number
      // AM0302
      return api
        .getReadItemMasterAPI(encodeURIComponent(_itemNo), config)
        .then(({ code, response }: { code: any; response: any }) => {
          if (clickType === "clickAddButton") {
            apiLoading(false);
          }
          if (!getApiResultStatus(code)) {
            if (code === 404) {
              if (!tableFlg) {
                setItemNoError(true);
                const message = com("messages." + response.message_id, {
                  param1: itemNo,
                  rowNum: t("Item_No"),
                });
                setItemNoErrorMessage(message);
              }
            }
            return {
              flg: false,
              data: null,
            };
          } else {
            setItemNoError(false);
            setItemNoErrorMessage("");
            return {
              flg: true,
              data: response,
            };
          }
        })
        .then((response: any) => response);
    } else {
      setItemNoError(false);
      setItemNoErrorMessage("");
      return {
        flg: true,
        data: null,
      };
    }
  };

  // Memo
  const [MemoForEndUser, setMemoForEndUser] = useState("");
  const setMemoForEndUserValue = (value: any) => setMemoForEndUser(value);
  const [errorRegisterList, setErrorRegisterList] = useState([]);
  const [itemNoErrorList, setItemNoErrorList] = useState([]);
  let handleRegisterDatalist: any = [];
  const handleRegister = () => {
    setLoggerListManageFun(
      `JDP:F9:v='${
        registerData.length > 0 ? JSON.stringify(registerData) : ""
      }'`
    );
    window.scrollTo(0, 0);
    apiLoading(true);
    const status = getReisterStatus(dataList.repair_option, dataList.status);
    let apiFlg = false;
    let param: any = {};
    let _registerData = registerData.slice(0);
    _registerData.forEach((item: any) => {
      if (!item["webmsi_region"]) {
        item["webmsi_region"] = region;
      }
      if (!item["webmsi_spec"] || item["webmsi_spec"] === undefined) {
        item["webmsi_spec"] =
          models === undefined || models === "" ? "Full" : models;
      }
    });
    if (status === "order") {
      const { errorList, insertList, updateList, deleteList } =
        getRegisterOrderMessage(registerResponse, _registerData);
      if (errorList.length > 0) {
        setErrorRegisterList(errorList);
      } else {
        setErrorRegisterList([]);
        if (formList.quote_result === 7 && formList.status === "510") {
          if (_registerData.length > 0) {
            apiFlg = true;
            _registerData.forEach((item: any) => {
              item["price_change"] = 1;
            });
            param.insert = _registerData;
          } else {
            apiFlg = true;
            param.insert = [];
          }
        } else {
          if (updateList.length > 0) {
            apiFlg = true;
            param.update = updateList;
          }
          if (deleteList.length > 0) {
            apiFlg = true;
            param.delete = deleteList;
          }
          if (insertList.length > 0) {
            apiFlg = true;
            param.insert = insertList;
          }
        }
      }
    }
    if (status === "quote") {
      const { errorList, insertList } = getRegisterInsertMessage(_registerData);
      if (errorList.length > 0) {
        setErrorRegisterList(errorList);
      } else {
        setErrorRegisterList([]);
        apiFlg = true;
        param.insert = insertList;
      }
    }

    if (apiFlg) {
      param.logger = loggerMessage;
      param.loggerMessage = _LoggerListManage;
      // AT0205
      api
        .multipleUpdateRepairJobPartsAPI(dataList.job_no, param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          if (!getApiResultStatus(code)) {
            errorMessage(response);
            apiLoading(false);
          } else {
            // clear log
            setClearLoggerListMagangeFun();

            let apiList = [];
            const api1 = getRegisterDataList().then((response: any) => {
              if (response && response.length > 0) {
                handleRegisterDatalist = response.slice(0);
              } else {
                handleRegisterDatalist = [];
              }
            });
            apiList.push(api1);
            if (dataList.location_no && dataList.status === "300") {
              const AT0103_param = {
                location_no: "",
                taskForQAExcluded: "JobDetailParts",
                updated_date: dataList.updated_date,
                logger: loggerMessage,
                loggerMessage: _LoggerListManage,
              };
              // AT0103
              const api2 = api
                .updateRepairJobAPI(dataList.job_no, AT0103_param, config)
                .then(({ code, response }: { code: any; response: any }) => {
                  if (!getApiResultStatus(code)) {
                    errorMessage(response);
                  } else {
                    // clear log
                    setClearLoggerListMagangeFun();
                    successMessage("register");
                  }
                });
              apiList.push(api2);
            } else {
              successMessage("register");
            }
            Promise.all(apiList).then(() => {
              setTotalFunc(handleRegisterDatalist);
              apiLoading(false);
              updateBaseData();
            });
          }
        });
      setItemNoErrorList([]);
    } else {
      apiLoading(false);
    }
  };

  const getErrorMessageByitem = (row: any) => {
    let message: any;
    if (row.type === "1") {
      message = com("messages.MA0005", {
        param1: t("txt_order_qty"),
        rowNum: `#${row.row} `,
      });
    } else if (row.type === "2") {
      message = com("messages.MS0011", {
        param1: t("txt_order_qty"),
        rowNum: `#${row.row}`,
      });
    } else if (row.type === "3") {
      message = com("messages.MS0011", {
        param1: t(row.param),
        rowNum: `#${row.row}`,
      });
    } else if (row.type === "4") {
      message = `#${row.row} ` + com("messages.ordered_qty_error");
    } else if (row.type === "5") {
      message =
        `#${row.row} ` +
        t("Item_No") +
        com("messages.MS0004", {
          param1: row.item_no,
        });
    } else if (row.type === "6") {
      message =
        `#${row.row} ` +
        com("messages.MS0052", {
          itemNum: row.item_no,
        });
    }
    return message;
  };

  const getErrorMessageByInfo = () => {
    let list: any = [];
    if (itemNoErrorList.length > 0 && errorRegisterList.length === 0) {
      list = itemNoErrorList.slice(0);
    } else if (errorRegisterList.length > 0 && itemNoErrorList.length === 0) {
      list = errorRegisterList.slice(0);
    } else {
      list = []
        .concat(itemNoErrorList, errorRegisterList)
        .sort((x: any, y: any): any => {
          console.log(x, y);
          return x.row - y.row;
        });
    }
    return list;
  };

  const errorRegisterMessage = getErrorMessageByInfo().map(
    (item: any, index: number) => (
      <div
        className={
          item.type !== "6"
            ? classes.registerError
            : classes.registerErrorOrange
        }
      >
        <p key={index}>{getErrorMessageByitem(item)}</p>
      </div>
    )
  );

  // message box
  const [open, setOpen] = useState(false);
  const [MessageBoxButton, setMessageBoxButton] = useState("");
  const [MessageBoxText, setMessageBoxText] = useState("messages.MS0030");
  const [RegisterMessageBoxType, setRegisterMessageBoxType] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = () => {
    setLoggerListManageFun(
      `JDP:F10:v='${
        registerData.length > 0 ? JSON.stringify(registerData) : ""
      }'`
    );
    setOpen(false);
    if (RegisterMessageBoxType === "3") {
    } else {
      apiLoading(true);

      const param = {
        status: RegisterMessageBoxType === "1" ? "800" : "600",
        updated_date: dataList.updated_date,
        logger: loggerMessage,
        loggerMessage: _LoggerListManage,
      };
      // T0103
      api
        .updateRepairJobAPI(dataList.job_no, param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            // clear
            setClearLoggerListMagangeFun();
            if (RegisterMessageBoxType === "1") {
              setOpen(true);
              setMessageBoxButton("ok");
              setRegisterMessageBoxType("3");
              setMessageBoxText("messages.MS0032");
            }
            registerMessageMemo();
          }
        });
    }
  };

  const registerMessageMemo = () => {
    if (MemoForEndUser) {
      apiLoading(true);

      const param = {
        job_no: dataList.job_no,
        note_type: 20,
        note: MemoForEndUser,
      };
      // AT0110
      api
        .createRepairNoteAPI(param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            successMessage("complete");
            updateBaseData();
          }
        });
    } else {
      successMessage("complete");
      updateBaseData();
    }
  };

  const handleRegisterComplete = () => {
    setLoggerListManageFun(
      `JDP:F11:v='${
        registerData.length > 0 ? JSON.stringify(registerData) : ""
      }'`
    );
    const selfPickupList = dataList.repair_address.filter(
      (item: any) => item.address_type === 2
    );
    let selfPickup = selfPickupList[0].self_pickup;
    if (selfPickup) {
      setOpen(true);
      setRegisterMessageBoxType("1");
    } else {
      setOpen(true);
      setRegisterMessageBoxType("2");
    }
  };

  //
  const alternateItem = com("alternate_item");
  const [alternateItemList, setAlternateItem] = useState(alternateItem);

  const [total, setTotal] = useState("");
  async function setTotalFunc(list: any, type: any = "") {
    let t: any = 0;
    let newList = list.slice(0);
    let errorList: any = [];
    if (list.length > 0) {
      for (let i = 0; i < newList.length; i++) {
        let item = newList[i];
        const amount: any =
          !IsBlank(item.order_qty) &&
          !IsBlank(item.net_price) &&
          !isNaN(item.order_qty) &&
          !isNaN(item.net_price)
            ? Number(item.order_qty) * Number(item.net_price)
            : 0;
        t = t + amount;
        item.amount = amount ? amount.toFixed(2) : 0;
        if (type === "default") {
          item.rightFlg = Boolean(item.net_price);
          if (typeof item.net_price === "number") {
            item.net_price = item.net_price.toFixed(2);
          }
        }

        // diff exist check
        let diffFlg = true;
        if (registerData.length > 0) {
          let diffList = registerData.filter(
            (oldItem: any) => String(oldItem.item_no) === String(item.item_no)
          );
          if (diffList.length === 0) {
            diffFlg = true;
          } else {
            diffFlg = false;
          }
        }
        if (diffFlg) {
          if (
            type === "default" ||
            type === "addDefaultMessage" ||
            type === "registerData"
          ) {
            //do nothing
          } else {
            item["webmsi_region"] = region;
            item["webmsi_spec"] =
              models === undefined || models === "" ? "Full" : models;
            if (type === "clickAddButton") {
              // when click button
              // const { flg, data } = await searchItemNo(
              //   item.item_no,
              //   "clickAddButton"
              // );
              errorList = judgeSuspend(_flg, _data, item, i, errorList);
            } else {
              const { flg, data } = await searchItemNo(item.item_no);
              errorList = judgeSuspend(flg, data, item, i, errorList);
            }
          }
        } else {
          if (item.suspend_exist === "1") {
            errorList.push({
              row: i + 1,
              type: "5",
              item_no: item.item_no,
            });
          } else if (item.suspend_exist === "2") {
            errorList.push({
              row: i + 1,
              type: "6",
              item_no: item.item_no,
            });
          }
        }
      }
    }
    console.log("setTotalFunc errorList", errorList, newList);
    setItemNoErrorList(errorList);
    if (type !== "registerData") {
      if (newList.length > 0) {
        for (let j = 0; j < newList.length; j++) {
          afterChangeCheck(j, j, newList);
        }
      }
    }
    if (newList.length === 0) {
      setItemNoErrorList([]);
      setErrorRegisterList([]);
    }
    t = (t as number).toFixed(2);
    setRegisterData(newList);
    setTotal(t);
    apiLoading(false);
  }

  const afterChangeCheck = (row: any, index: number, getCheckList: any) => {
    const newList = getCheckList.slice(0);
    let errorList: any = [];
    newList.forEach((item: any, subIndex: number) => {
      if (IsBlank(item.order_qty) || item.order_qty === "0") {
        const error = {
          row: subIndex + 1,
          type: "1",
        };
        errorList.push(error);
      } else {
        if (isNaN(item.order_qty)) {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_order_qty",
          };
          errorList.push(error);
        }
      }

      if (
        !IsBlank(item.order_qty) &&
        !IsBlank(item.allocated_qty) &&
        item.order_qty < item.allocated_qty
      ) {
        const error = {
          row: subIndex + 1,
          type: "4",
          param: "txt_order_qty",
        };
        errorList.push(error);
      }

      if (!IsBlank(item.net_price)) {
        const price: any = Number(item.net_price);
        if (!isNaN(price)) {
          item.net_price = price.toFixed(2);
        } else {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_Price",
          };
          errorList.push(error);
        }
      }
    });
    setErrorRegisterList(errorList);
  };

  const judgeSuspend = (
    flg: any,
    data: any,
    item: any,
    i: any,
    errorList: any
  ) => {
    if (!flg || (data && data.suspend_flg === true)) {
      if (!flg) {
        item.suspend_exist = "1";
        errorList.push({
          row: i + 1,
          type: "5",
          item_no: item.item_no,
        });
      } else {
        item.suspend_exist = "2";
        errorList.push({
          row: i + 1,
          type: "6",
          item_no: item.item_no,
        });
      }
    }
    return errorList;
  };

  const getRepairCompleteDisable = () => {
    return getRepairCompleteDisableFunc(registerData, registerResponse);
  };

  const openLink = (url: any, type: any = "") => {
    apiLoading(true);
    api
      .getFileUrl(url, config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const url = response.url;
          if (url) {
            window.open(url);
          }
        }
      });
  };

  const setRegisterProjectBlur = (row: any, index: number) => {
    const newList = registerData.slice(0);
    let errorList: any = [];
    newList.forEach((item: any, subIndex: number) => {
      if (IsBlank(item.order_qty) || item.order_qty === "0") {
        const error = {
          row: subIndex + 1,
          type: "1",
        };
        errorList.push(error);
      } else {
        if (isNaN(item.order_qty)) {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_order_qty",
          };
          errorList.push(error);
        }
      }

      if (
        !IsBlank(item.order_qty) &&
        !IsBlank(item.allocated_qty) &&
        item.order_qty < item.allocated_qty
      ) {
        const error = {
          row: subIndex + 1,
          type: "4",
          param: "txt_order_qty",
        };
        errorList.push(error);
      }

      if (!IsBlank(item.net_price)) {
        const price: any = Number(item.net_price);
        if (!isNaN(price)) {
          item.net_price = price.toFixed(2);
        } else {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_Price",
          };
          errorList.push(error);
        }
      }
    });
    setRegisterData(newList);
    setErrorRegisterList(errorList);
  };

  const [svgPaths, setSvgPaths] = useState([]);
  useEffect(() => {
    if (tableSelectedList.length > 0) {
      setSvgPaths(svgDocPath);
    }
  }, [svgDocPath, tableSelectedList]);

  const rightAreaTop = (
    <>
      <JobsDetailPartsDrawingModel
        region={region}
        models={models}
        regionTitle={t("txt_region")}
        title={t("txt_Spec")}
        documentTitle={t("txt_TechnicalInformationDocument")}
        regionList={regionList}
        handleChangeregions={handleChangeregions}
        specificationModels={specificationModels}
        handleChangeModels={handleChangeModels}
        documentsList={documentsList}
        documentClick={openLink}
      />
      <div>
        <TableContainer className={classes.groupContainer}>
          <Table stickyHeader>
            <JobsDetailPartsDrawingThead />
            <JobsDetailPartsDrawingTbody
              data={parts}
              handleClick={handleTableRowClick}
              tableSelectedList={tableSelectedList}
              emphasizeParts={emphasizeParts}
              handleMouseOver={handleTableMouseEvent}
              handleMouseleave={handleTableMouseEvent}
              handleDoubleClick={handleDoubleClickByTable}
              openLink={openLink}
            />
          </Table>
        </TableContainer>
      </div>
    </>
  );

  const rightAreaBottom = (
    <>
      <JobsDetailPartsDrawingTableControl
        itemNo={itemNo}
        setItemNoValue={setItemNoValue}
        handleAddEvent={handleAddEvent}
        handleDeleteEvent={handleDeleteEvent}
        searchItemNo={searchItemNo}
        error={ItemNoError}
        errorMessage={ItemNoErrorMessage}
        itemNoDisabled={!userPermisssionInfo.item_no_authFlg}
        addButtonDisabled={userPermisssionInfo.add_item_authFlg}
        deleteButtonDisabled={userPermisssionInfo.delete_item_authFlg}
        itemNoName={t("Item_No")}
        AddButtonName={t("AddButtonName")}
        DeleteButtonName={t("DeleteButtonName")}
      />
      <div>
        {errorRegisterList.length > 0 || itemNoErrorList.length > 0
          ? errorRegisterMessage
          : null}
        <TableContainer className={classes.groupContainer} id="registerTable">
          <Table stickyHeader>
            <JobsDetailPartsDrawingRegisterThead
              indeterminate={indeterminate}
              checked={checked}
              onSelectAllClick={onSelectAllClick}
              totalName={t("txt_Total") + ":"}
              total={total}
              currency={currency}
            />
            <JobsDetailPartsDrawingRegisterTbody
              data={registerData}
              setRegisterData={setRegisterData}
              selected={selected}
              status={dataList.status}
              setRegisterDataProperty={setRegisterDataProperty}
              registerRowClick={registerRowClick}
              alternateItemList={alternateItemList}
              orderQtyAuthDisable={!userPermisssionInfo.order_qty_authFlg}
              setRegisterProjectBlur={setRegisterProjectBlur}
              priceDisabled={!userPermisssionInfo.price_authFlg}
            />
          </Table>
        </TableContainer>
      </div>
      <div className={classes.registerMessageControl}>
        <div className={classes.MemoForEndUser}>
          <JobsDetailPartsDrawingMemoForEndUser
            MemoForEndUser={MemoForEndUser}
            memoRegisterMessage={t("txt_memo_placeholder")}
            setMemoForEndUserValue={setMemoForEndUserValue}
            memoDisabled={!userPermisssionInfo.endUser_memo_authFlg}
            title={t("Memo_For_End_User")}
          />
        </div>

        <JobsDetailPartsDrawingRegisterButton
          handleRegister={handleRegister}
          disabled={getRepairCompleteDisable()}
          registerButtonFlg={userPermisssionInfo.register_btn_authFlg}
          repairCompleteFlg={userPermisssionInfo.repair_compelete_btn_authFlg}
          registerDisableFlg={userPermisssionInfo.register_authFlg}
          handleRegisterComplete={handleRegisterComplete}
          errorRegisterList={errorRegisterList}
          itemNoErrorList={itemNoErrorList}
        />
      </div>
    </>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div className={classes.itemMemorandumContainer}>
            <JobsDetailPartsDrawingItemMemorandum
              memo={partsDrawingData.memo}
              title={t("Item_Memorandum")}
            />
          </div>
          {moviesList && moviesList.length > 0 && innerShowFlg ? (
            <JobsDetailPartsDrawingTechnicalInformation
              title={t("txt_TechnicalInformation")}
              moviesList={moviesList}
              moviesClick={openLink}
            />
          ) : null}
          {innerShowFlg && (
            <div className={classes.svgArea}>
              <Tabs
                value={currentPages}
                onChange={handleTabChange}
                className={classes.tabArea}
                variant="scrollable"
                scrollButtons="auto"
              >
                {determineDataType(pages) &&
                  pages.map((item) => (
                    <Tab
                      label={item}
                      key={item}
                      className={classes.tabButton}
                    />
                  ))}
              </Tabs>
              <div
                className={innerShowFlg ? classes.tabLayer : undefined}
                id="tabLayerId"
              >
                <div
                  className={classes.drawingContainer}
                  id="tabLayerDrawing"
                  style={dropStyle}
                >
                  {determineDataType(svgPaths) &&
                    svgPaths.map((item: any, index: number) => (
                      <div
                        className={classes.layerContainer}
                        hidden={currentPages !== index}
                        key={index}
                        id="svgContainer"
                      >
                        {determineDataType(layerList) &&
                          layerList.map((subItem) => {
                            return (
                              <JobsDetailPartsDrawingSvgMessage
                                key={item[subItem]}
                                path={item[subItem]}
                                layer={subItem}
                                handleMouseOver={handleMouseOverEventBySvg}
                                handleMouseLeave={handleMouseLeaveEventBySvg}
                                handleClick={handleClickEventBySvg}
                                handleDoubleClick={handleDoubleClickBySvg}
                                selected={tableSelectedList}
                                contentsUrl={config.webMSIContentsURL}
                              />
                            );
                          })}
                      </div>
                    ))}
                </div>
                {innerShowFlg && (
                  <div className={classes.zoomButtonContainer}>
                    <IconButton onClick={zoomIn}>
                      <ZoomIn />
                    </IconButton>
                    <IconButton onClick={zoomOut}>
                      <ZoomOut />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={8} className={classes.rightArea}>
          {innerShowFlg && rightAreaTop}
          {rightAreaBottom}
        </Grid>
      </Grid>
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com(MessageBoxText)}
        buttonType={MessageBoxButton}
      />
    </>
  );
};
