import React, { useState, useEffect } from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// components
import { GeneralMessageCSearch } from "./JobsDetail.General.Message.CSearch";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import { TimePicker } from "../../../modules/components/datePicker/TimePicker";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const JobsDetailGeneralMessage = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.label",
  });
  const { com } = useLocale();
  const {
    formList,
    setFormListValue,
    setFormList,
    formErrorList,
    setFormErrorListValue,
    onBlurOfCustomerNo,
    onBlurOfCustomerPONo,
    onBlurOfLocationNo,
    onBlurOfReceivedDateDate,
    onBlurOfReceivedDateTime,
    onBlurOfTechnicianCode,
    userPermisssionInfo,
    repairJobPartsList,
    setButtonDisableFlg,
    customerNoTemp,
    setCustomerNoTemp,
    setConfirmationOpen,
    setPopUpTitle,
    setPopUpContent,
    setPopUpButton,
    setAlertMessage,
    setAlertType,
  } = props;
  const { getCommonInfo, getHotKey, setHotKey } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  let hotKeyInfo = getHotKey() as any;

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  useEffect(() => {
    if (hotKeyInfo.handleF2Search && userPermisssionInfo.customer_no_authFlg) {
      handleCSearch();
    }
    setHotKey("handleF2Search", false);
  }, [hotKeyInfo.handleF2Search]);

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      let message = com("common.constants.messages.MS0085", {
        param1: row.customer_no,
      });
      setAlertMessage(message);
      setAlertType("warning");
    } else {
      setAlertMessage("");
      setAlertType("");
    }
    setFormErrorListValue("customer_no_error", "");
    let mail_address_repair: any;
    if (row.customer_no !== formList.customer_no) {
      mail_address_repair = row.mail_address_repair;
      // display the pupup of memorandum
      if (row.memorandum && row.memorandum !== "") {
        setConfirmationOpen(true);
        setPopUpTitle("prompt_title_memorandum");
        setPopUpContent(row.memorandum);
        setPopUpButton("notification");
      }
    } else {
      mail_address_repair = formList.contactDealer.mail_address;
    }
    // as the defualt value for customer info
    setFormList((prevState: any) => ({
      ...prevState,
      customer_no: row.customer_no,
      customer_name: row.customer_name,
      currency: row.currency,
      limit_amount_currency: row.currency,
      contactDealer: {
        ...prevState.contactDealer,
        name: row.customer_name,
        mail_address: mail_address_repair ? mail_address_repair : "",
        tel_no: row.tel_no ? row.tel_no : "",
        fax_no: row.fax_no ? row.fax_no : "",
        sms_receive_notification: row.sms_receive_notification,
        mail_receive_notification: row.mail_receive_notification,
      },
      addressReturnTo: {
        ...prevState.addressReturnTo,
        name: row.customer_name ? row.customer_name : "",
        country: row.country ? row.country : "",
        address_1: row.address_1 ? row.address_1 : "",
        address_2: row.address_2 ? row.address_2 : "",
        address_3: row.address_3 ? row.address_3 : "",
        postal_cd: row.postal_cd ? row.postal_cd : "",
        self_pickup: false,
      },
      addressBillTo: {
        ...prevState.addressBillTo,
        name: row.customer_name ? row.customer_name : "",
        country: row.country ? row.country : "",
        address_1: row.address_1 ? row.address_1 : "",
        address_2: row.address_2 ? row.address_2 : "",
        address_3: row.address_3 ? row.address_3 : "",
        postal_cd: row.postal_cd ? row.postal_cd : "",
      },
    }));
  };

  const getCustomerNoStatus = () => {
    if (userPermisssionInfo.customer_no_authFlg) {
      if (repairJobPartsList.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getAccessoryAttachedStatus = () => {
    if (userPermisssionInfo.accessory_attached_authFlg) {
      if (repairJobPartsList.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormListValue("accessory_attached", event.target.checked);
  };

  const getOptionLabel = (option: any) =>
    option.erp_user_id + " : " + option.name || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    // limit: 10,
    stringify: getOptionLabel,
  });

  const handleStr = () => {
    if (
      formList.tracking_no_return !== undefined &&
      formList.tracking_no_return !== ""
    ) {
      let str_tracking_no = formList.tracking_no_return;
      if (formList.tracking_no_return.substr(0, 1) == "%") {
        str_tracking_no = str_tracking_no
          .replace(str_tracking_no.substr(0, 8), "")
          .replace(str_tracking_no.substr(-6, 6), "");
        return (
          <Link
            href={process.env.REACT_APP_DPD_URL + str_tracking_no}
            target="_blank"
            rel="noopener"
          >
            {str_tracking_no}
          </Link>
        );
      } else {
        if (
          formList.return_carrier !== undefined &&
          formList.return_carrier !== ""
        ) {
          if (formList.return_carrier.toUpperCase() === "DACH") {
            return (
              <Link
                href={process.env.REACT_APP_DACH_URL + str_tracking_no}
                target="_blank"
                rel="noopener"
              >
                {str_tracking_no}
              </Link>
            );
          } else if (formList.return_carrier.toUpperCase() === "DHL") {
            return (
              <Link
                href={process.env.REACT_APP_DHL_URL + str_tracking_no + process.env.REACT_APP_DHL_URL_LANGUAGE}
                target="_blank"
                rel="noopener"
              >
                {str_tracking_no}
              </Link>
            );
          } else if (formList.return_carrier.toUpperCase() === "DPD") {
            return (
              <Link
                href={process.env.REACT_APP_DPD_URL + str_tracking_no}
                target="_blank"
                rel="noopener"
              >
                {str_tracking_no}
              </Link>
            );
          } else {
            return str_tracking_no;
          }
        } else {
          return str_tracking_no;
        }
      }
    } else if (
      formList.tracking_no_pickup !== undefined &&
      formList.tracking_no_pickup !== ""
    ) {
      let str_tracking_no = formList.tracking_no_pickup;
      if (formList.tracking_no_pickup.substr(0, 1) == "%") {
        str_tracking_no = str_tracking_no
          .replace(str_tracking_no.substr(0, 8), "")
          .replace(str_tracking_no.substr(-6, 6), "");
        return (
          <Link
            href={process.env.REACT_APP_DPD_URL + str_tracking_no}
            target="_blank"
            rel="noopener"
          >
            {str_tracking_no}
          </Link>
        );
      } else {
        if (
          formList.pickup_carrier !== undefined &&
          formList.pickup_carrier !== ""
        ) {
          if (formList.pickup_carrier.toUpperCase() === "DACH") {
            return (
              <Link
                href={process.env.REACT_APP_DACH_URL + str_tracking_no}
                target="_blank"
                rel="noopener"
              >
                {str_tracking_no}
              </Link>
            );
          } else if (formList.pickup_carrier.toUpperCase() === "DHL") {
            return (
              <Link
                href={process.env.REACT_APP_DHL_URL + str_tracking_no + process.env.REACT_APP_DHL_URL_LANGUAGE}
                target="_blank"
                rel="noopener"
              >
                {str_tracking_no}
              </Link>
            );
          } else {
            return str_tracking_no;
          }
        } else {
          return str_tracking_no;
        }
      }
    } else {
      return formList.trackingNo;
    }
  };

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <>
      <table className={classes.tableLayout}>
        <tbody>
          <tr>
            <th>{t("job_no")}</th>
            <td>
              <p>{formList.job_no}</p>
            </td>
            <td></td>
            <td className={classes.generalBlankTd}></td>
            <td></td>
          </tr>
          <tr>
            <th>{t("service_center_name")}</th>
            <td colSpan={4}>
              <p>{formList.service_center_name}</p>
            </td>
          </tr>
          <tr>
            <th>{t("tracking_no")}</th>
            <td colSpan={3}>{handleStr()}</td>
            <td></td>
          </tr>
          <tr>
            <th>{t("status")}</th>
            <td colSpan={4}>
              <p>{formList.statusName}</p>
            </td>
          </tr>
          <tr>
            <th>{t("order_no")}</th>
            <td colSpan={4}>
              <p>{formList.order_no}</p>
            </td>
          </tr>
          <tr>
            <th>{t("dz_order_no")}</th>
            <td colSpan={4}>
              <p>{formList.dz_order_no}</p>
            </td>
          </tr>
          <tr>
            <th>
              {t("customer_no")}
              <span>*</span>
            </th>
            <td className={classes.vertialAlignTop} colSpan={4}>
              <div className={classes.displayFlexArea}>
                <div className={classes.inputContainer}>
                  {/*
                  <TextField
                    id="customer_number_input"
                    disabled={getCustomerNoStatus()}
                    value={formList.customer_no}
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/[^\d]/g, "")
                        .substr(0, 8);
                      if (value && value !== "") {
                        setFormListValue("customer_no", Number(value));
                        setCustomerNoTemp(formList.customer_no);
                      } else {
                        setFormListValue("customer_no", value);
                      }
                    }}
                    onFocus={() => setButtonDisableFlg("focus")}
                    onBlur={(e) => {
                      const value = e.target.value
                        .replace(/[^\d]/g, "")
                        .substr(0, 8);
                      setCustomerNoTemp(formList.customer_no);
                      onBlurOfCustomerNo(true, value);
                    }}
                    error={formErrorList.customer_no_error !== ""}
                    helperText={formErrorList.customer_no_error}
                  />
*/}

                  <Autocomplete
                    id="customer_number_input"
                    className={classes.autoComplete}
                    freeSolo
                    disabled={getCustomerNoStatus()}
                    options={commonInfo.customerList}
                    getOptionLabel={getOptionCustomerLabel}
                    filterOptions={filterCustomerOptions}
                    inputValue={String(formList.customer_no)}
                    value={String(formList.customer_no)}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          minWidth: "300px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                    getOptionSelected={(option: any, value: any) => {
                      let isSelected = false;
                      if (option.customer_no && formList.customer_no) {
                        isSelected =
                          option.customer_no.toString().toUpperCase() ===
                          formList.customer_no.toString().toUpperCase();
                      }
                      return isSelected;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        error={formErrorList.customer_no_error !== ""}
                        helperText={formErrorList.customer_no_error}
                        onFocus={() => setButtonDisableFlg("focus")}
                        onBlur={(e) => {
                          const value = e.target.value
                            .replace(/[^\d]/g, "")
                            .substr(0, 8);
                          setCustomerNoTemp(formList.customer_no.toString());
                          onBlurOfCustomerNo(true, value);
                        }}
                      />
                    )}
                    onChange={(event: object, value: any, reason: string) => {
                      if (reason === "select-option") {
                        setFormListValue(
                          "customer_no",
                          value.customer_no.toString()
                        );
                        setCustomerNoTemp(formList.customer_no.toString());
                      }
                    }}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === "input" || reason === "clear") {
                        const val = value.substr(0, 8);

                        setFormListValue("customer_no", value);
                        setCustomerNoTemp(formList.customer_no.toString());
                      }
                    }}
                  />
                </div>

                <div className={classes.customerButtonContainer}>
                  <Button
                    disabled={getCustomerNoStatus()}
                    className={classes.customerSearchBtn}
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleCSearch}
                  />
                </div>

                <div className={classes.inputNameContainer}>
                  {!formErrorList.customer_no_error && (
                    <p>{formList.customer_name}</p>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>{t("customer_po_no")}</th>
            <td colSpan={3}>
              <TextField
                disabled={!userPermisssionInfo.customer_po_no_authFlg}
                value={formList.customer_po_no}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("customer_po_no", e.target.value)
                }
                onBlur={(e) => {
                  onBlurOfCustomerPONo(e.target.value);
                  setFormListValue(
                    "customer_po_no",
                    formList.customer_po_no.toUpperCase()
                  );
                }}
                error={formErrorList.customer_po_no_error !== ""}
                helperText={formErrorList.customer_po_no_error}
                inputProps={{ maxLength: 30 }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <th>{t("location_no")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.location_no_authFlg}
                value={formList.location_no}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("location_no", e.target.value)
                }
                onBlur={(e) => {
                  onBlurOfLocationNo(e.target.value);
                }}
                error={formErrorList.location_no_error !== ""}
                helperText={formErrorList.location_no_error}
                inputProps={{ maxLength: 5 }}
              />
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <th>
              {t("received_date")}
              <span>*</span>
            </th>
            <td colSpan={2} className={classes.generalDateTd}>
              <DatePicker
                disabled={!userPermisssionInfo.received_date_authFlg}
                value={formList.received_date_date}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setFormListValue(
                      "received_date_date",
                      new Date(date).getTime()
                    );
                  } else {
                    setFormListValue("received_date_date", date);
                  }
                }}
                onAccept={(date: any) =>
                  setFormListValue(
                    "received_date_date",
                    new Date(date).setHours(0, 0, 0, 0)
                  )
                }
                className={classes.datePickerComponent}
                onBlur={onBlurOfReceivedDateDate}
                error={formErrorList.received_date_date_error !== ""}
                helperText={formErrorList.received_date_date_error}
                onError={(error: any, value: any) => {
                  if (
                    error !== formErrorList.received_date_date_error &&
                    error !== ""
                  ) {
                    setFormErrorListValue("received_date_date_error", error);
                  }
                }}
              />
            </td>
            <td className={classes.generalTimeTd}>
              <TimePicker
                disabled={!userPermisssionInfo.received_date_authFlg}
                value={formList.received_date_time}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setFormListValue(
                      "received_date_time",
                      new Date(date).getTime()
                    );
                  } else {
                    setFormListValue("received_date_time", date);
                  }
                }}
                onAccept={(date: any) =>
                  setFormListValue(
                    "received_date_time",
                    new Date(date).getTime()
                  )
                }
                className={classes.datePickerComponent}
                onBlur={onBlurOfReceivedDateTime}
                error={formErrorList.received_date_time_error !== ""}
                helperText={formErrorList.received_date_time_error}
                onError={(error: any, value: any) => {
                  if (
                    error !== formErrorList.received_date_time_error &&
                    error !== ""
                  ) {
                    setFormErrorListValue("received_date_time_error", error);
                  }
                }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <th>{t("technician_cd")}</th>
            <td colSpan={4} className={classes.vertialAlignTop}>
              <div className={classes.displayFlexArea}>
                <div className={classes.inputContainer}>
                  <Autocomplete
                    disabled={!userPermisssionInfo.technician_cd_authFlg}
                    className={classes.autoComplete}
                    id="technician_cd"
                    freeSolo
                    disableClearable
                    options={commonInfo.technicianList}
                    getOptionLabel={getOptionLabel}
                    filterOptions={filterOptions}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          minWidth: "250px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                    inputValue={formList.technician_cd}
                    value={formList.technician_cd}
                    getOptionSelected={(option: any, value: any) =>
                      option.erp_user_id.toUpperCase() ===
                      formList.technician_cd.toUpperCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        error={formErrorList.technician_cd_error !== ""}
                        helperText={formErrorList.technician_cd_error}
                        onBlur={onBlurOfTechnicianCode}
                      />
                    )}
                    onChange={(event: object, value: any, reason: string) => {
                      if (reason === "select-option") {
                        setFormListValue("technician_cd", value.erp_user_id);
                      }
                    }}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === "input" || reason === "clear") {
                        setFormListValue("technician_cd", value);
                      }
                    }}
                  />
                </div>
                <div className={classes.inputNameContainer}>
                  {!formErrorList.technician_cd_error && (
                    <p>{formList.technician_name}</p>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>{t("accessory_attached")}</th>
            <td colSpan={4}>
              <Checkbox
                disabled={getAccessoryAttachedStatus()}
                className={classes.checkboxLayout}
                color="primary"
                checked={formList.accessory_attached}
                onChange={handleChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <GeneralMessageCSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </>
  );
};
