import React, { useEffect, useState } from "react";
// components
import { JobsDetailCustomerInfoRepairContactDelaer } from "./JobsDetail.CustomerInfo.RepairContactDelaer";
import { JobsDetailCustomerInfoRepairContactEndUser } from "./JobsDetail.CustomerInfo.RepairContactEndUser";
import { JobsDetailCustomerInfoRepairAddressPickUp } from "./JobsDetail.CustomerInfo.RepairAddressPickUp";
import { JobsDetailCustomerInfoRepairAddressReturn } from "./JobsDetail.CustomerInfo.RepairAddressReturn";
import { JobsDetailCustomerInfoRepairAddressBillTo } from "./JobsDetail.CustomerInfo.RepairAddressBillTo";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import countries from "i18n-iso-countries";
import { CopyAddressIcon } from "../../../modules/components/icons/Icons";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    buttonArea: {
      marginTop: "20px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    buttonGrid: {
      textAlign: "center",
    },
  })
);

export const JobsDetailCustomerInfo = (props: any) => {
  const myClasses = myStyles();
  const [initFlg, setInitFlg] = useState(true);
  const [countriesList, setCountriesList] = useState([]);
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.customer_info",
  });
  const {
    formList,
    setFormListValue,
    setFormList,
    formErrorList,
    setFormErrorList,
    setFormErrorListValue,
    onBlurOfDealerName,
    onBlurOfDealerEmailAddress,
    onBlurOfEndUserName,
    onBlurOfEndUserEmailAddress,
    onBlurOfPickupName,
    onBlurOfPickupAddress2,
    onBlurOfPickupAddress3,
    onBlurOfPickupAddress1,
    onBlurOfPickupPostalCd,
    onBlurOfReturnAddressName,
    onBlurOfReturnAddress2,
    onBlurOfReturnAddress3,
    onBlurOfReturnAddress1,
    onBlurOfReturnPostalCd,
    onBlurOfBillToAddressName,
    onBlurOfBillToAddress2,
    onBlurOfBillToAddress3,
    onBlurOfBillToAddress1,
    onBlurOfBillToPostalCd,
    userPermisssionInfo,
  } = props;

  useEffect(() => {
    if (initFlg) {
      // country pull down
      const countriesListObj: any = countries.getAlpha2Codes();
      const countriesListArray: any = getCountriesListArray(countriesListObj);
      setCountriesList(countriesListArray);
      setInitFlg(false);
    }
  });

  const getCountriesListArray = (list: any) => {
    let newList: any = [];
    const objList: any = Object.keys(list);
    for (let i = 0; i < objList.length; i++) {
      const obj = {
        id: objList[i],
        name: list[objList[i]],
      };
      newList.push(obj);
    }
    return newList;
  };

  const handleCopy = () => {
    setFormList((prevState: any) => ({
      ...prevState,
      addressBillTo: {
        ...prevState.addressBillTo,
        name: prevState.addressReturnTo.name,
        country: prevState.addressReturnTo.country,
        address_1: prevState.addressReturnTo.address_1,
        address_2: prevState.addressReturnTo.address_2,
        address_3: prevState.addressReturnTo.address_3,
        postal_cd: prevState.addressReturnTo.postal_cd,
      },
    }));
      onBlurOfBillToAddressName(formList.addressReturnTo.name);
      onBlurOfBillToAddress1(formList.addressReturnTo.address_1);
      onBlurOfBillToAddress2(formList.addressReturnTo.address_2);
      onBlurOfBillToAddress3(formList.addressReturnTo.address_3);
      onBlurOfBillToPostalCd(formList.addressReturnTo.postal_cd);
  };

  return (
    <Grid container className={myClasses.root} spacing={1}>
      <Grid item md={12} lg={4}>
        <JobsDetailCustomerInfoRepairContactDelaer
          formList={formList}
          setFormListValue={setFormListValue}
          setFormList={setFormList}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          onBlurOfDealerName={onBlurOfDealerName}
          onBlurOfDealerEmailAddress={onBlurOfDealerEmailAddress}
          userPermisssionInfo={userPermisssionInfo}
        />
      </Grid>
      <Grid item md={12} lg={4}>
        <JobsDetailCustomerInfoRepairContactEndUser
          formList={formList}
          setFormListValue={setFormListValue}
          setFormList={setFormList}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          onBlurOfEndUserName={onBlurOfEndUserName}
          onBlurOfEndUserEmailAddress={onBlurOfEndUserEmailAddress}
          userPermisssionInfo={userPermisssionInfo}
        />
      </Grid>
      <Grid item md={12} lg={4}></Grid>
      <Grid item md={12} lg={4}>
        <JobsDetailCustomerInfoRepairAddressPickUp
          formList={formList}
          setFormListValue={setFormListValue}
          setFormList={setFormList}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          countriesList={countriesList}
          onBlurOfPickupName={onBlurOfPickupName}
          onBlurOfPickupAddress2={onBlurOfPickupAddress2}
          onBlurOfPickupAddress3={onBlurOfPickupAddress3}
          onBlurOfPickupAddress1={onBlurOfPickupAddress1}
          onBlurOfPickupPostalCd={onBlurOfPickupPostalCd}
          userPermisssionInfo={userPermisssionInfo}
        />
      </Grid>
      <Grid item md={12} lg={4}>
        <JobsDetailCustomerInfoRepairAddressReturn
          formList={formList}
          setFormListValue={setFormListValue}
          setFormList={setFormList}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          countriesList={countriesList}
          onBlurOfReturnAddressName={onBlurOfReturnAddressName}
          onBlurOfReturnAddress2={onBlurOfReturnAddress2}
          onBlurOfReturnAddress3={onBlurOfReturnAddress3}
          onBlurOfReturnAddress1={onBlurOfReturnAddress1}
          onBlurOfReturnPostalCd={onBlurOfReturnPostalCd}
          userPermisssionInfo={userPermisssionInfo}
        />
      </Grid>
      <Grid item md={12} lg={1} className={myClasses.buttonGrid}>
        <Button
          className={myClasses.buttonArea}
          variant="contained"
          color="primary"
          startIcon={<CopyAddressIcon />}
          onClick={handleCopy}
        >
          {t("btn_copy")}
        </Button>
      </Grid>
      <Grid item md={12} lg={3}>
        <JobsDetailCustomerInfoRepairAddressBillTo
          formList={formList}
          setFormListValue={setFormListValue}
          setFormList={setFormList}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          countriesList={countriesList}
          onBlurOfBillToAddressName={onBlurOfBillToAddressName}
          onBlurOfBillToAddress2={onBlurOfBillToAddress2}
          onBlurOfBillToAddress3={onBlurOfBillToAddress3}
          onBlurOfBillToAddress1={onBlurOfBillToAddress1}
          onBlurOfBillToPostalCd={onBlurOfBillToPostalCd}
          userPermisssionInfo={userPermisssionInfo}
        />
      </Grid>
    </Grid>
  );
};
