import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& .MuiFormHelperText-root": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "25px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& dl": {
        display: "flex",
        margin: 0,
        marginRight: "20px",
      },
      "& dd": {
        marginLeft: "15px",
        margin: 0,
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
        marginTop: "-4px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      alignItems: "flex-start",
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
        marginTop: "-4px",
      },
    },
    paymentTermCode: {
      display: "flex",
      "& .MuiFormControl-root": {
        width: "200px",
        marginRight: "10px"
      }
    },
    table: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "3px",
      padding: "5px",
      marginTop: "-4px",
      "& table": {
        textAlign: "center",
        borderCollapse: "collapse",
      },
      "& tr:not(:last-child)": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
      },
      "& th, & td": {
        fontWeight: "normal",
        padding: "10px",
      },
    },
  })
);

export const Term = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const {
    setSearchValue,
    searchValueList,
    errorValueList,
    changeTermCodeTable,
    termCodeTable,
    paymentTermList,
    reviewFlg,
    onBlurOfPaymentTermCode
  } = props;
  const classes = useStyles();
  
  const getOptionLabel2 = (option: any) =>
    option.terms_cd + ":" + option.terms_description || "";
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    stringify: getOptionLabel2,
  });

  return (
    <div className={classes.root}>
      <h1>{t("term")}</h1>
      <div className={classes.rows}>
        <dl>
          <dt>{t("terms_cd")}</dt>
          <dd className={classes.paymentTermCode}  data-testid="paymentTermCode">
            <Autocomplete
              id="analysis_cd"
              freeSolo
              disableClearable
              options={paymentTermList}
              getOptionLabel={getOptionLabel2}
              filterOptions={filterOptions2}
              inputValue={searchValueList.terms_cd}
              value={searchValueList.terms_cd}
              disabled={reviewFlg}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "200px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) =>
                option.terms_cd.toString().toUpperCase() ===
                searchValueList.terms_cd.toString().toUpperCase()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  onBlur={() => {
                    onBlurOfPaymentTermCode();
                  }}
                  error={errorValueList.terms_cd_error !== ""}
                  helperText={errorValueList.terms_cd_error}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  changeTermCodeTable(value.terms_cd);
                  setSearchValue("terms_cd", value.terms_cd);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  const val = value.substr(0, 2);
                  changeTermCodeTable(val);
                  setSearchValue("terms_cd", val);
                }
              }}
            />
            <div>{termCodeTable && termCodeTable.terms_description}</div>
          </dd>
        </dl>
        {termCodeTable && (
          <div className={classes.table} data-testid="termCodeTable">
            <table>
              <tr>
                <th>{t("table_no")}</th>
                <th>{t("table_days")}</th>
                <th>{t("table_discount_rate")}</th>
              </tr>
              <tr>
                <td>1</td>
                <td>{termCodeTable.discount_days}</td>
                <td>{termCodeTable.discount_percentage}%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{termCodeTable.discount_days_2}</td>
                <td>{termCodeTable.discount_percentage_2}%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{termCodeTable.discount_days_3}</td>
                <td>{termCodeTable.discount_percentage_3}%</td>
              </tr>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
