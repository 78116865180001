import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "technician_code", label: "technician_code" },
  { id: "technician_name", label: "technician_name" },
  { id: "service_center", label: "service_center" },
  { id: "working_days", label: "working_days" },
  { id: "goods_in", label: "goods_in" },
  { id: "task_for_qa", label: "task_for_qa" },
  { id: "job_entry", label: "job_entry" },
  { id: "worked_tool_qty", label: "worked_tool_qty" },
  { id: "waranty_repair", label: "waranty_repair" },
  { id: "charge_repair", label: "charge_repair" },
  { id: "inspection_for_quote", label: "inspection_for_quote" },
  { id: "acc", label: "acc" },
  { id: "avr_repair_time_format", label: "avr_repair_time" },
  { id: "vs_total_avr_format", label: "vs_total_avr" },
];

export const TechnicianTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.report" });

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.theadCell}
            key={headCell.id}
            sortDirection={orderBy.indexOf(headCell.id) > -1 ? order : false}
          >
            <TableSortLabel
              active={orderBy.indexOf(headCell.id) > -1}
              direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy.indexOf(headCell.id) > -1 ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
