import React from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// constants
import { TabKey } from "../MasterMaintenance.constants";
// method
import { setSortData } from "../methods/common.methods";

export const MasterMaintenanceDZUserInfoTbody = (props: any) => {
  const { pageInfo, selected, order, orderBy, config } = props;
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.dZUserInfo",
  });
  const codeList = useLocale({ defaultPath: "common.constants" });
  const roleMessageList = codeList.com("role_id");

  const getRoleName = (roleID: any) => {
    let roleName;
    let roleList;
    roleList = roleMessageList.filter(
      (subItem: any) => Number(subItem.code) === Number(roleID)
    );
    if (roleList.length > 0) {
      roleName = roleList[0].name;
    }
    return roleName;
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          return (
            <TableRow className={classes.tbody} hover tabIndex={-1} key={index}>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => props.handleEdit(row, TabKey.DZUserInfo)}
                >
                  {t("edit")}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => props.handleDelete(row)}
                >
                  {t("delete")}
                </Button>
              </TableCell>
              <TableCell className={classes.nowrap}>{row.given_name}</TableCell>
              <TableCell className={classes.nowrap}>
                {row.family_name}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.middle_name}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.affiliate_cd}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.primary_locale && row.primary_locale.toLocaleUpperCase()}
              </TableCell>
              <TableCell className={classes.nowrap}>{row.tel_no}</TableCell>
              <TableCell className={classes.nowrap}>
                {row.mail_address}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {getRoleName(row.userinfodz.role_id)}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.customer_no}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.customer_name}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.sms_receive_notification ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.mail_receive_notification ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {!row.userinfodz.created_date ||
                row.userinfodz.created_date === ""
                  ? ""
                  : date(new Date(row.userinfodz.created_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {!row.userinfodz.last_login_date ||
                row.userinfodz.last_login_date === ""
                  ? ""
                  : date(new Date(row.userinfodz.last_login_date).getTime())}
              </TableCell>
              {config.affiliate_cd.toUpperCase() === "MD" && (
                <TableCell className={classes.nowrap}>
                  {row.userinfodz.repair_access ? "Y" : "N"}
                </TableCell>
              )}
              <TableCell className={classes.nowrap}>
                {row.userinfodz.eordering_ordering ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.eordering_display_dealer_price ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.eordering_order_invoices ? "Y" : "N"}
              </TableCell>
              {config.affiliate_cd.toUpperCase() === "MD" && (
                <>
                  <TableCell className={classes.nowrap}>
                    {row.userinfodz.nc_show ? "Y" : "N"}
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    {row.userinfodz.nc_apply_cancel ? "Y" : "N"}
                  </TableCell>
                </>
              )}
              <TableCell className={classes.nowrap}>
                {row.userinfodz.contacts_new ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.wco_claim ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.userinfodz.wco_credit_note ? "Y" : "N"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.user_block ? "Y" : "N"}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => props.handleEdit(row, TabKey.DZUserInfo)}
                >
                  {t("edit")}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => props.handleDelete(row)}
                >
                  {t("delete")}
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
