import React, { FC, useEffect, useState, useCallback } from "react";
import clsx from "clsx";
// route
import { useHistory, useParams } from "react-router-dom";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { ClaimDetailEndUser } from "./components/ClaimDetail.EndUser";
import { ClaimDetailToolInformation } from "./components/ClaimDetail.ToolInformation";
import { ClaimDetailParts } from "./components/ClaimDetail.Parts";
import { ClaimDetailUpload } from "./components/ClaimDetail.Upload";
import { ClaimDetailCommon } from "./components/ClaimDetail.Common";
import { ClaimDetailMemo } from "./components/ClaimDetail.Memo";
import { ClaimDetailPopup } from "./components/ClaimDetail.Popup";
import { ClaimDetailHistory } from "../../modules/components/claimHistory/ClaimHistory";

// UI
import { useStyles } from "./hooks/ClaimDetail.styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// methods
import {
  getApiResultStatus,
  handleDiffChangedData,
  getFormatByKey,
  diffContents as diffClaimDetailContents,
  getPartsList,
  illegalCharactersErrorMessage,
} from "../../modules/common.methods";
import { getFileName } from "./methods/ClaimDetail.Upload.Methods";
import { diffContents } from "./methods/ClaimDetail.Common.Methods";
// api
import api from "../../modules/api/Api";
import {
  defaultErrorValueBlank,
  TRANSPORT_MESSAGE,
} from "./constants/claimDetail.constants";
// check
import {
  isEmptyCheck,
  isEmptyNumber,
  checkMaxLength,
  isEmptyStringOrNumber,
  emailValidation,
  checkIllegalChar,
} from "../../modules/hooks/UseValication";
import {
  DEFAULT_VALUE_BLANK,
  CLAIM_NO_QUERY_STRING,
} from "../../modules/constants/claim.constants";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
import { setLoborTotal } from "../../modules/methods/claims.methods";
import {
  getPriceParam,
  setPriceAPIResult,
} from "./methods/ClaimDetail.Parts.Methods";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../modules/hooks/Permissions";
import SaveIcon from "@material-ui/icons/Save";

import { priceOutputByCountryCode } from "../../modules/components/numberFormat/NumberFormat.Currency";

import countries from "i18n-iso-countries";

// const stateEditDefaultValue = ["1", "1", "1", "1"];
// const tabEditCtrollDefaultValue = [false, false, false, false];

export const Page: FC = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { com } = useLocale();
  const history = useHistory();
  // alert
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [startFlg, setStartFlg] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  // tab
  const [value, setValue] = useState(0);
  //
  const [claimNo, setClaimNo] = useState(
    props.history.location.search.replace(CLAIM_NO_QUERY_STRING, "")
  );
  // form page value
  const [formList, setFormList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );
  const [formErrorList, setFormErrorList] = useState(defaultErrorValueBlank);
  // data DB value
  const [dataList, setDataList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );
  const [repairHistoryList, setRepairHistoryList] = useState([]);

  // page Type=> 0:create, 1:Edit
  const [claimType, setClaimType] = useState(0);
  const [claimStatus, setClaimStatus] = useState("0");
  const illegalChar: any = process.env.REACT_APP_ILLEGAL_CHAR;

  // parts
  const [partsInitFlag, setPartsInitFlag] = useState(false);

  // const [stateData, setStateData] = useState([]) as any;
  // const [tabCtroll, setTabCtroll] = useState([]) as any;

  const [tempModelNo, setTempModelNo] = useState("");
  const [tempSerialNo, setTempSerialNo] = useState("");
  const [errorType, setErrorType] = useState([]);
  const [clearPartsFlag, setClearPartsFlag] = useState(false);
  // common
  const {
    getCommonInfo,
    getCommonConfig,
    setCommonInfo,
    getUserPermissions,
    setUserPermissions,
    setCommonInfoFull,
    getClaimDetailInfo,
    setClaimDetailInfo,
    getUserInfoRepair,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  let config = getCommonConfig() as any;
  let userInfoRepair = getUserInfoRepair() as any;

  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

  const getNewClaimNo = (): string => {
    return history.location.search.replace(CLAIM_NO_QUERY_STRING, "");
  };

  const getCountriesListArray = (list: any) => {
    let newList: any = [];
    const objList: any = Object.keys(list);
    for (let i = 0; i < objList.length; i++) {
      const obj = {
        id: objList[i],
        name: list[objList[i]],
      };
      newList.push(obj);
    }
    return newList;
  };

  // set permission of ClaimDetail
  const setPermission = async () => {
    let localUserPermissions = {};
    if (userPermissions.permission_group) {
      localUserPermissions = userPermissions;
    } else {
      await getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
    }

    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const tempUserPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(tempUserPermissionsInfo);
  };

  const [partsDefaultList, setPartsDefaultList] = useState({
    list_all: [],
    labor_list: [],
    administrative_cost_list: [],
    freight_list: [],
  });

  // page Init
  useEffect(() => {
    const _claim_no = getNewClaimNo();
    if (_claim_no) {
      setClaimNo(_claim_no);
      // edit tab is p&d,create tab is enduser
      setValue(2);
      setClaimType(1);
    } else {
      handleCloseReturn();
    }
    if (Object.keys(config).length === 0) {
      apiLoading(true);
    }
    if (!startFlg && Object.keys(config).length > 0) {
      console.log("claimDetail start");
      setStartFlg(true);

      const _parts_list = getPartsList(config.affiliate_cd);
      setPartsDefaultList(_parts_list);

      // initTabButton(_claim_no);
      initialize(_claim_no);
      setPermission();
    }

    // country pull down
    const countriesListObj: any = countries.getAlpha2Codes();
    const countriesListArray: any = getCountriesListArray(countriesListObj);
    setCountriesList(countriesListArray);
  }, [startFlg, config]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const handleRefresh = () => {
    setRefreshFlag(true);
    setValue(2);
    setClaimType(1);
    initialize(claimNo);
    changeMemoMessage("");
    setPermission();
    setItemNoErrorList([]);
    setAlertMessage("");
    setAlertType("");
  };

  // useEffect(() => {
  //   handleTabCtrol();
  // }, [stateData]);

  useEffect(() => {
    if (
      dataList.serial_no !== "" &&
      dataList.serial_no !== "*" &&
      dataList.model_no !== ""
    ) {
      setTempModelNo(dataList.model_no);
      setTempSerialNo(dataList.serial_no);
      let query_parameters =
        "model_no=" +
        encodeURIComponent(dataList.model_no) +
        "&serial_no=" +
        encodeURIComponent(dataList.serial_no) +
        "&claim_no=" +
        encodeURIComponent(dataList.claim_no ? dataList.claim_no : 0) +
        "&job_no=" +
        encodeURIComponent(0);
      getRepairHistory(query_parameters);
    }
    if (dataList.model_no !== "") {
      getReadModelErrorTypeList(dataList.model_no);
    }
  }, [dataList]);

  const getReadModelErrorTypeList = async (model_no: any) => {
    apiLoading(true);
    await api
      .getReadClaimLaborAPI(encodeURIComponent(model_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get Model Error Type List", code, response);
        if (getApiResultStatus(code)) {
          if (response.length > 0) {
            if (Array.isArray(response)) {
              response.map((item: any, index: number) => {
                item.index = index;
              });
            }
          }
          setErrorType(response);
          const _charges = setLoborTotal(
            response,
            dataList,
            transportMessage,
            partsDefaultList
          );
          setChargesValue(_charges);
        } else {
          if (code === 404) {
            setErrorType([]);
            let message = com("common.constants.messages.MS0100", {
              param1: t("txt_model_no"),
            });
            setFormErrorListValue("model_no_error", message);
          } else {
            errorMessage(response);
          }
        }
      });
    // .then(() => {
    //   apiLoading(false);
    // });
  };

  const [chargesValue, setChargesValue] = useState({
    qty_amount: 0,
    parts_amount: 0,
    total_amount: 0,
  });

  // const initTabButton = (claimNo: String) => {
  //   setStateData(stateEditDefaultValue);
  //   setTabCtroll(tabEditCtrollDefaultValue);
  // };

  // const handleTabCtrol = () => {
  //   let arrayStateData = [...stateData];

  //   let arrayTabCtroll = [...tabCtroll];

  //   // arrayTabCtroll[0] = false;
  //   // arrayTabCtroll[1] = false;

  //   if (arrayStateData[0] === "1" || arrayStateData[0] === "2") {
  //     arrayTabCtroll[2] = false;
  //   } else {
  //     arrayTabCtroll[2] = true;
  //   }

  //   if (arrayStateData[1] === "1" || arrayStateData[1] === "2") {
  //     arrayTabCtroll[3] = false;
  //   } else {
  //     arrayTabCtroll[3] = true;
  //   }

  //   setTabCtroll(arrayTabCtroll);
  // };

  // initialize
  const initialize = async (param_claimNo: string) => {
    console.log("claimDetail initialize");
    // init
    apiLoading(true);
    let apiList = [];
    // let localUserPermissions = {};
    let masterModelList: any = [];
    let masterItemList: any = [];

    //  get base item master list
    if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
      let api1 = api
        .getBaseItemListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0305 response: ", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            masterItemList = response;
          }
        });
      apiList.push(api1);
    } else {
      masterItemList = commonInfo.itemBaseList;
    }

    // // permission
    // if (!userPermissions.permission_group) {
    //   const api2 = getUserPermissionsRe().then((r: any) => {
    //     localUserPermissions = r.response;
    //     setUserPermissions(r.response);
    //   });
    //   apiList.push(api2);
    // } else {
    //   localUserPermissions = userPermissions;
    // }

    // item master AM0305
    if (commonInfo.itemList.length === 0) {
      const api3 = api
        .getItemDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0305 response: ", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            masterModelList = response;
          }
        });
      apiList.push(api3);
    } else {
      masterModelList = commonInfo.itemList;
    }

    // fetch claim detail AT1702
    if (param_claimNo) {
      const api4 = getReadClaimDetailInfo(param_claimNo);
      apiList.push(api4);
    }

    Promise.all(apiList).then(async () => {
      console.log("claimDetail api complete");
      setPartsInitFlag(true);
      setCommonInfoFull({
        itemList: masterModelList,
        itemBaseList: masterItemList,
        //loadingFlg: false,
      });
    });
  };

  // fetch the detail info AT1702
  const getReadClaimDetailInfo = (claimNo: string) => {
    return api
      .getReadClaimDetailAPI(Number(claimNo), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT1702 response: ", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          // 詳細情報の取得
          if (!response.claim_line) {
            response.claim_line = DEFAULT_VALUE_BLANK.claim_line;
          }
          if (!response.claim_address) {
            response.claim_address = DEFAULT_VALUE_BLANK.claim_address;
          }

          // 更新：net_price_format
          if (response.claim_line.length > 0) {
            response.claim_line.forEach((item: any) => {
              item.net_price_format = priceOutputByCountryCode(
                item.net_price ? item.net_price : 0
              );
            });
            setTempPartsList(JSON.parse(JSON.stringify(response.claim_line)));
          }

          // set status
          setClaimStatus(response.status);

          // transportMessage情報の初期化
          setTransportMessage({
            weight: response.weight,
            shipping_method: response.shipping_method,
            transport_way_pick_up: response.transport_way_pick_up,
            transport_way_return: response.transport_way_return,
            transport_cost_flg: response.transport_cost_flg,
          });

          // set response data
          setDataList(JSON.parse(JSON.stringify(response)));
          // set page data
          setFormList(JSON.parse(JSON.stringify(response)));
          // tools information
          getToolsInformation(response.claim_address);

          // 権限設定
          if (
            Number(response.status) !== 200 &&
            Number(response.status) !== 210
          ) {
            const path = response.claim_no
              ? GetPrivateAppRoutePath(PathKey.SUMMARY) +
                "?claimno=" +
                response.claim_no
              : GetPrivateAppRoutePath(PathKey.SUMMARY);
            handleOnClick(path);
          }

          // memoMessage初期化
          if (response.claim_note.length > 0) {
            const _list: any = response.claim_note.filter(
              (item: any) => item.note_type === 99
            );
            const _message: any = _list.length > 0 ? _list[0].note : "";
            setMemoMessage(_message);
          }
        }
      });
  };

  //end user infomation toolS
  const [endUserInfoToolsList, setEndUserInfoToolsList] = useState([]) as any;
  const getToolsInformation = (data: any) => {
    if (data && data.enduser_no) {
      //apiLoading(true);
      api
        .getTools(config, data.enduser_no)
        .then(({ code, response }: { code: any; response: any }) => {
          //apiLoading(false);
          console.log("get end user detail tools", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setEndUserInfoToolsList(response);
          }
        });
    }
  };

  // error message
  const errorMessage = (response: any) => {
    apiLoading(false);
    let message = "";
    if (response.message_id) {
      message = com("common.constants.messages." + response.message_id, {
        param1: response.key
          ? com("common.constants.messages_key_name." + response.key)
          : "",
      });
    } else if (response.message) {
      if (response.status && response.status === 403) {
        message = com("common.constants.messages.MA0007");
      } else {
        message = response.message;
      }
    }
    if (message) {
      setAlertMessage(message);
      setAlertType("error");
    }
  };

  // tab change
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log("parts handleChange", newValue);
    // clear error
    clearErrorText();

    if (newValue === 2) {
      setPartsInitFlag(true);
    } else {
      setPartsInitFlag(false);
    }

    setValue(newValue);
  };

  // media file upload event
  const handleUpload = (files: any, name: string) => {
    // loading
    if (files && files.length > 0) {
      const _mediaList = formList.claim_media ? formList.claim_media[name] : [];
      const fileName = getFileName(files, _mediaList);
      const file = files[0];
      apiLoading(true);
      // Get Upload signed url AT1801
      api
        .getUploadSignedUrlByWco(encodeURIComponent(fileName), config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("AT0401 response", response);
          if (getApiResultStatus(code)) {
            const data = response;
            api.getUploadFile(data.content, file).then((response: any) => {
              apiLoading(false);
              // uid
              if (getApiResultStatus(response)) {
                console.log("getUploadFile", file);
                const params: any = {
                  media_url: data.uid,
                  media_name: fileName,
                  media_type: name === "claim_media_invoice" ? 1 : 2,
                  update_flg: "0", // 0:create,1:update,2:delete
                };
                let _data: any = JSON.parse(
                  JSON.stringify(formList.claim_media)
                );
                _data[name].push(params);
                setFormListValue("claim_media", _data);
              } else {
                errorMessage(response);
              }
            });
          } else {
            errorMessage(response);
          }
        });
    }
  };

  const photoDelete = (index: number, name: string) => {
    let list = JSON.parse(JSON.stringify(formList.claim_media));
    let item: any = list[name][index];
    if (item.sub_no) {
      item.update_flg = 2;
    } else {
      list[name].splice(index, 1);
    }
    setFormListValue("claim_media", list);
  };

  // set data
  const setFormListValue = (key: string, value: any, subKey: string = "") => {
    // clear error
    clearErrorText();

    console.log("setFormListValue", key, value, subKey);
    value = getFormatByKey(key, value, subKey);
    if (subKey !== "") {
      setFormList((prevState: any) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setFormList((prevState: any) => ({ ...prevState, [key]: value }));
    }
  };

  const [transportMessage, setTransportMessage] = useState(TRANSPORT_MESSAGE);
  const setTransportListValue = (
    key: string,
    value: any,
    subKey: string = ""
  ) => {
    value = getFormatByKey(key, value, subKey);
    if (subKey !== "") {
      setTransportMessage((prevState: any) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setTransportMessage((prevState: any) => ({ ...prevState, [key]: value }));
    }
  };

  // set error value
  const setFormErrorListValue = (key: string, value: any) => {
    setFormErrorList((prevState) => ({ ...prevState, [key]: value }));
  };

  // common search method
  const getRepairHistory = (query_parameters: string) => {
    setRepairHistoryList([]);
    // Read Repair Job List AT1708
    return api
      .getReadRepairHistoryListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1708 response: ", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response.history_list && response.history_list.length > 0) {
            response.history_list.map((item: any) => {
              if (item.history_no) {
                let obj: any = {
                  history_no: item.history_no,
                  entry_date: item.entry_date,
                  customer_no: item.customer_no,
                  type_kind: item.type_kind,
                };
                newList.push(obj);
              }
            });
          }
          setRepairHistoryList(newList);
        }
      });
  };

  // Model No. check
  const onBlurOfModelNo = () => {
    console.log("onBlurOfModelNo", formList.model_no);
    let _model_no: string = formList.model_no;
    let noError = true;
    if (isEmptyCheck(_model_no)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_model_no"),
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
    } else if (!checkMaxLength(_model_no, 15)) {
      let message = com("common.constants.messages.MS0002", {
        param1: t("txt_model_no"),
        param2: 15,
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
    } else if (!masterExitOfModelNo(_model_no)) {
      let message = com("common.constants.messages.MS0004", {
        param1: t("txt_model_no"),
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
    }
    if (noError) {
      setFormErrorListValue("model_no_error", "");
      setFormErrorListValue("serial_no_error", "");
    }
    return noError;
  };

  // Serial No. check
  const onBlurOfSerialNo = () => {
    let noError = true;
    if (isEmptyCheck(formList.serial_no)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_serial_no"),
      });
      setFormErrorListValue("serial_no_error", message);
      noError = false;
    } else if (!checkMaxLength(formList.serial_no, 15)) {
      let message = com("common.constants.messages.MS0002", {
        param1: t("txt_serial_no"),
        param2: 15,
      });
      setFormErrorListValue("serial_no_error", message);
      noError = false;
    }
    if (noError) {
      setFormErrorListValue("serial_no_error", "");
    }
    return noError;
  };

  const masterExitOfModelNo = (value: string) => {
    let isExisted = false;
    let items = commonInfo.itemList;

    if (value && value !== "") {
      if (items.length > 0) {
        for (let index in items) {
          if (
            items[index].model_no.toString().toUpperCase() ===
            value.toString().toUpperCase()
          ) {
            isExisted = true;
            let modelNoByDB = items[index].model_no.toString();
            setFormListValue("model_no", modelNoByDB);
            break;
          }
        }
      } else {
        // TODO call api to check whether existed
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  const onBlurOfpurchasedDate = () => {
    let noError = true;
    setFormErrorListValue("purchased_date_error", "");
    if (isEmptyStringOrNumber(formList.purchased_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_purchased_date"),
      });
      setFormErrorListValue("purchased_date_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfReceivedDate = () => {
    let noError = true;
    setFormErrorListValue("received_date_error", "");
    if (isEmptyStringOrNumber(formList.received_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_received_date"),
      });
      setFormErrorListValue("received_date_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfRepairDate = () => {
    let noError = true;
    setFormErrorListValue("repair_date_error", "");
    if (isEmptyStringOrNumber(formList.repair_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("txt_repair_date"),
      });
      setFormErrorListValue("repair_date_error", message);
      noError = false;
    }
    return noError;
  };

  const getNote = (value: any) => {
    let note = "";
    if (value.length > 0) {
      value.forEach((item: any) => {
        if (item.note_type === 10) {
          note = item.note;
        }
      });
    }
    return note;
  };

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // end user Tab check
  const endUserCheck = () => {
    // customer Type require
    let _flg1: boolean = valueValicationCheck(
      formList.claim_address.customer_type,
      "txt_customer_type",
      "customer_type_error"
    );

    let _flg2: boolean = false;
    if (Number(formList.claim_address.customer_type) === 1) {
      /**
       * Customer Type = Commercial
       * company Name require
       */
      _flg2 = valueValicationCheck(
        formList.claim_address.company_name,
        "txt_company_name",
        "company_name_error"
      );
      clearErrorCheck("last_name_error");
    } else if (Number(formList.claim_address.customer_type) === 2) {
      /**
       * Customer Type = Private
       * Last Name require
       */
      _flg2 = valueValicationCheck(
        formList.claim_address.last_name,
        "txt_last_name",
        "last_name_error"
      );
      clearErrorCheck("company_name_error");
    }

    // mail address check
    let _flg3: boolean = formList.claim_address.mail_address
      ? mailValicationCheack(
          formList.claim_address.mail_address,
          "txt_email",
          "mail_address_error"
        )
      : true;

    // postal code check
    let _flg4: boolean = valueValicationCheck(
      formList.claim_address.postal_code,
      "txt_postal_code",
      "postal_code_error"
    );

    let _flg5 = formList.privacy_check_flg;
    if (formList.privacy_check_flg) {
      clearErrorCheck("privacy_check_flg_error");
    } else {
      const message: any = com(
        "common.constants.messages.privacy_notice_error"
      );
      setFormErrorListValue("privacy_check_flg_error", message);
    }

    const _flg = _flg1 && _flg2 && _flg3 && _flg4 && _flg5;
    return _flg;
  };

  // Parts drawing cost tab check
  const [errorRegisterList, setErrorRegisterList] = useState([]);
  const [itemNoErrorList, setItemNoErrorList] = useState([]);
  const partsCheck = () => {
    console.log("partsCheck");
    let _flg1: boolean = formList.attention_check_flg;
    if (_flg1) {
      clearErrorCheck("attention_check_flg_error");
    } else {
      const message: any = com("common.constants.messages.MS0001", {
        param1: t("attention_check_flg"),
      });
      setFormErrorListValue("attention_check_flg_error", message);
    }

    let _flg2: boolean = true;
    let _flg3: boolean = true;
    if (formList.claim_line.length > 0) {
      formList.claim_line.forEach((item: any) => {
        // transport way の checkboxがチェックしてない場合は、
        const _parts_list: any = partsDefaultList.list_all;
        if (_parts_list.includes(item.item_no) && !item.order_qty) {
          _flg3 = false;
        }
      });
    } else {
      // _flg2 = false;
    }

    if (_flg2) {
      clearErrorCheck("claim_line_error");
    } else {
      const message: any = com("common.constants.messages.MS0001", {
        param1: t("claim_lines"),
      });
      setFormErrorListValue("claim_line_error", message);
    }

    if (_flg3) {
      clearErrorCheck("transport_message_error");
    } else {
      setFormErrorListValue(
        "transport_message_error",
        com("common.constants.messages.transport_way_error")
      );
    }

    let _flg4: boolean = true;
    const _freightLists: any = partsDefaultList.freight_list;
    const _freightList = formList.claim_line.filter((item: any) =>
      _freightLists.includes(item.item_no)
    );

    if (
      (transportMessage.transport_cost_flg && _freightList.length === 0) ||
      (!transportMessage.transport_cost_flg && _freightList.length > 0)
    ) {
      _flg4 = false;
    }
    if (_flg4) {
      clearErrorCheck("transport_message_error");
    } else {
      setFormErrorListValue(
        "transport_message_error",
        com("common.constants.messages.transport_cost_error")
      );
    }

    let _flg5: boolean = true;
    if (
      transportMessage.transport_cost_flg &&
      !(
        transportMessage.transport_way_pick_up ||
        transportMessage.transport_way_return
      )
    ) {
      _flg5 = false;
    }

    if (_flg5) {
      clearErrorCheck("transport_message_error");
    } else {
      setFormErrorListValue(
        "transport_message_error",
        com("common.constants.messages.transport_way_error")
      );
    }

    // RegisterやItem No.入力チェック
    let _flg6: boolean = true;
    const _itemNoErrorListNumber =
      itemNoErrorList.length > 0
        ? itemNoErrorList.filter(
            (item: any) => item.type !== "6" && item.type !== "8"
          ).length
        : 0;
    const _errorRegisterListNumber =
      errorRegisterList.length > 0
        ? errorRegisterList.filter(
            (item: any) => item.type !== "6" && item.type !== "8"
          ).length
        : 0;
    _flg6 = _itemNoErrorListNumber === 0 && _errorRegisterListNumber === 0;
    console.log(
      "P&D check",
      _flg1,
      ",",
      _flg2,
      ",",
      _flg3,
      ",",
      _flg4,
      ",",
      _flg6
    );
    return _flg1 && _flg2 && _flg3 && _flg4 && _flg5 && _flg6;
  };

  const ToolInfoCheck = () => {
    // Model No check
    let _flg1: boolean = onBlurOfModelNo();
    // Serial No check
    let _flg2: boolean = onBlurOfSerialNo();
    // Condition Of Tool check
    let _flg3: boolean = valueValicationCheck(
      formList.condition_of_tool,
      "txt_condition_of_tool",
      "condition_of_tool_error"
    );
    // purchased Date check
    let _flg4: boolean = onBlurOfpurchasedDate();
    // Received Date check
    let _flg5: boolean = onBlurOfReceivedDate();
    // Repair Date check
    let _flg6: boolean = onBlurOfRepairDate();
    // Error Type check
    let _flg7: boolean = valueValicationCheck(
      formList.error_type,
      "txt_error_type",
      "error_type_error"
    );
    // Guarantee Type check
    let _flg8: boolean = valueValicationCheck(
      formList.guarantee_type,
      "txt_guarantee_type",
      "guarantee_type_error"
    );

    const _flg =
      _flg1 && _flg2 && _flg3 && _flg4 && _flg5 && _flg6 && _flg7 && _flg8;
    return _flg;
  };
  const endUserNoValicationCheck = (value: any = "") => {
    return true;
  };

  const memoValicationCheck = () => {
    let _flg = memoMessage !== "";
    if (_flg) {
      setMemoErrorMessage("");
    } else {
      setMemoErrorMessage(
        com("common.constants.messages.MS0001", {
          param1: t("summary_decline_note"),
        })
      );
    }
    return _flg;
  };

  const valueValicationCheck = (
    value: any = "",
    name: any = "",
    error_name: any = ""
  ) => {
    console.log("valueValicationCheck", value);
    if (isEmptyStringOrNumber(value)) {
      const message: any = com("common.constants.messages.MS0001", {
        param1: t(name),
      });
      setFormErrorListValue(error_name, message);
      return false;
    } else {
      clearErrorCheck(error_name);
      return true;
    }
  };

  const mailValicationCheack = (
    value: any,
    name: any = "",
    error_name: any = ""
  ) => {
    let _flg = true;

    if (!emailValidation(value)) {
      let message = com("common.constants.messages.MS0040");
      setFormErrorListValue(error_name, message);
      _flg = false;
    }

    // if (value) {
    //   if (checkIllegalChar(value)) {
    //     let message = com("common.constants.messages.MS0093", {
    //       param1: JSON.parse(illegalChar),
    //     });
    //     setFormErrorListValue(error_name, message);
    //     _flg = false;
    //   }
    // }

    if (_flg) {
      clearErrorCheck(error_name);
    }

    return _flg;
  };

  const clearErrorCheck = (error_name: any) => {
    setFormErrorListValue(error_name, "");
  };

  const setRegisterData = (list: any) => {
    setFormListValue("claim_line", list);
  };

  const fileDownload = (row: any) => {
    apiLoading(true);
    // Get Download signed url AT0402
    api
      .getDownloadSignedUrlByWco(formList.claim_no, row.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          apiLoading(false);
          const url = response.content;
          let w = window.open();
          if (w) {
            w.location.href = url;
          }
        }
      });
  };

  const setForListFull = (newList: any) => {
    setFormList((prevState: any) => ({
      ...prevState,
      ...newList,
    }));
  };
  // return button
  const [returnWindowFlag, setReturnWindowFlag] = useState(false);
  const handleToRetrun = () => {
    let _flg = diffMethods();
    if (_flg) {
      handleCloseReturnSummary();
    } else {
      // popup
      setReturnWindowFlag(true);
    }
  };
  const handleCloseReturnChanges = () => {
    setReturnWindowFlag(false);
  };
  const handleCloseReturn = () => {
    // change
    const path = GetPrivateAppRoutePath(PathKey.CLAIM);
    handleOnClick(path);
  };
  const handleCloseReturnSummary = () => {
    // change
    const path =
      GetPrivateAppRoutePath(PathKey.SUMMARY) + "?claimno=" + formList.claim_no;

    handleOnClick(path);
  };

  const [partsErrorFlag, setPartsErrorFlag] = useState(true);
  // check Input Message
  const checkInputMessage = async (type: string = "") => {
    console.log("checkInputMessage", type);
    // false: have error message
    let _flg1 = partsCheck();
    setPartsErrorFlag(_flg1);
    console.log("checkInputMessage flg1", _flg1);
    let _flg2 = true;
    if (type === "decline") {
      _flg2 = memoValicationCheck();
    }

    if (!_flg1 || !_flg2) {
      return _flg1 && _flg2;
    }

    let { _price_flag, newFormList } = await handleGetPrice();

    return { flag: _flg1 && _flg2 && _price_flag, newFormList: newFormList };
  };

  // message box
  const [total, setTotal] = useState("");
  const [tempPartsList, setTempPartsList] = useState([]);
  const handleGetPrice = async () => {
    console.log("handleGetPrice");
    setItemNoErrorList([]);
    let _newList: any = [];
    let _flg1 = true;
    let _success_flag = true;
    const registerData: any = formList.claim_line;
    registerData.forEach((item: any) => {
      const _itemList = _newList.filter(
        (subItem: any) => item.item_no === subItem.item_no
      );
      if (_itemList.length > 0) {
        const qty = _itemList.reduce((qty: number, subItem: any) => {
          qty += subItem.order_qty;
          return qty;
        }, 0);
        _itemList[0].order_qty = qty;
      } else {
        _newList.push(item);
      }
      // transport way の checkboxがチェックしてない場合は、
      const _parts_list: any = partsDefaultList.list_all;
      if (_parts_list.includes(item.item_no) && !item.order_qty) {
        _flg1 = false;
      }
    });
    console.log("handleGetPrice _flg1", _flg1);

    let _newFormList: any = {};
    if (_flg1) {
      apiLoading(true);
      clearErrorCheck("transport_message_error2");
      const { params, lineNoList } = getPriceParam(
        dataList.claim_line,
        registerData,
        dataList.customer_no
      );
      console.log("handleGetPrice _params", params, lineNoList);
      // AM2201
      await api
        .getReadPriceAPIforClaim(params, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("handleGetPrice api success");
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            // setFormListValue("partsQtyChangeFlag", false);
            clearErrorCheck("claim_line_error");
            if (response && response.lines && response.lines.length > 0) {
              const { _total, newList, resultList } = setPriceAPIResult(
                response,
                lineNoList,
                registerData
              );

              // 値の設定
              setTotal(String(_total));
              console.log("handleGetPrice api success", newList, resultList);
              _newFormList = {
                claim_line: resultList,
                currency: resultList[0].currency,
              };
              setForListFull(_newFormList);
            }
          }
        });
    } else {
      _success_flag = false;
      setFormErrorListValue(
        "transport_message_error2",
        com("common.constants.messages.transport_way_error")
      );
    }
    console.log("handleGetPrice _success_flag", _success_flag, _newFormList);
    console.log("handleToSave newFormList", _newFormList);
    setTempPartsList(JSON.parse(JSON.stringify(_newFormList.claim_line)));
    return {
      _price_flag: _success_flag,
      newFormList: _newFormList,
    };
  };

  //decline button
  const [declineFlag, setDeclineFlag] = useState(false);
  const [declineSuccessFlag, setDeclineSuccessFlag] = useState(false);
  const [memoErrorMessage, setMemoErrorMessage] = useState("");

  const handleToDeclilne = async () => {
    setAlertMessage("");
    setAlertType("");
    const { flag }: any = await checkInputMessage("decline");
    if (flag) {
      setDeclineFlag(true);
    }
  };

  const handleCloseDecline = () => {
    setDeclineFlag(false);
  };
  const handleToDeclilneFunc = () => {
    console.log("handleToDeclilneFunc", memoMessage);
    setDeclineFlag(false);
    let flg = memoValicationCheck();
    if (flg) {
      const _chargesValue = setLoborTotal(
        errorType,
        formList,
        transportMessage,
        partsDefaultList
      );
      const params = handleDiffChangedData(
        formList,
        dataList,
        "300",
        memoMessage,
        transportMessage,
        _chargesValue
      );
      console.log("handleToDeclilneFunc", params);
      if (Object.keys(params).length > 0) {
        apiLoading(true);
        api
          .updateClaimAPI(formList.claim_no, config, params)
          .then(({ code, response }: { code: any; response: any }) => {
            apiLoading(false);

            if (!getApiResultStatus(code)) {
              errorMessage(response);
            } else {
              setDeclineSuccessFlag(true);
            }
          });
      } else {
        handleCloseReturn();
      }
    }
  };
  const handleCloseDeclineSuccessChanges = () => {
    handleCloseReturn();
  };

  // approve button
  const [approveFlag, setApproveFlag] = useState(false);
  const [approveSuccessFlag, setApproveSuccessFlag] = useState(false);
  const handleToApprove = async () => {
    const { flag }: any = await checkInputMessage("approve");
    if (flag) {
      setApproveFlag(true);
    }
  };
  const handleCloseApprove = () => {
    setApproveFlag(false);
  };
  const handleToApproveFunc = () => {
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );
    const params = handleDiffChangedData(
      formList,
      dataList,
      "400",
      memoMessage,
      transportMessage,
      _chargesValue
    );
    if (Object.keys(params).length > 0) {
      apiLoading(true);
      api
        .updateClaimAPI(formList.claim_no, config, params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          setApproveFlag(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setApproveSuccessFlag(true);
          }
        });
    } else {
      handleCloseReturn();
    }
  };
  const handleCloseApproveSuccessChanges = () => {
    handleCloseReturn();
  };

  const diffMethods = () => {
    const _claim_no = getNewClaimNo();
    const _chargesValue = setLoborTotal(
      errorType,
      formList,
      transportMessage,
      partsDefaultList
    );
    return diffContents(
      _claim_no,
      formList,
      dataList,
      transportMessage,
      _chargesValue
    );
  };

  // memo
  const [memoMessage, setMemoMessage] = useState("");
  const changeMemoMessage = (newData: any) => {
    setMemoMessage(newData);
  };

  const [errorMessageText, setErrorMessageText] = useState("");

  const clearErrorText = () => {
    // save error
    if (errorMessageText !== "") {
      setErrorMessageText("");
    }
  };

  const handleToSave = async () => {
    console.log("handleToSave");
    let _flg = diffClaimDetailMethods();
    if (_flg) {
      const _message = com("common.constants.messages.MS0117");
      setAlertMessage(_message);
      setAlertType("error");
    } else {
      const _flg1 =
        (errorRegisterList.length > 0
          ? errorRegisterList.filter(
              (item: any) => item.type !== "6" && item.type !== "8"
            ).length
          : 0) ||
        (itemNoErrorList.length > 0
          ? itemNoErrorList.filter(
              (item: any) => item.type !== "6" && item.type !== "8"
            ).length
          : 0);
      if (_flg1) {
        const _message = com("common.constants.messages.save_error");
        setErrorMessageText(_message);
      } else {
        clearErrorText();
        const { flag, newFormList }: any = await checkInputMessage("save");
        if (flag) {
          const _formList = Object.assign({}, formList, newFormList);
          const _chargesValue = setLoborTotal(
            errorType,
            _formList,
            transportMessage,
            partsDefaultList
          );
          // memoMessage保存の仕様追加
          const params = handleDiffChangedData(
            _formList,
            dataList,
            "save",
            memoMessage,
            transportMessage,
            _chargesValue
          );
          console.log("handleToSave", params, formList, newFormList, _formList);
          if (Object.keys(params).length > 0) {
            // pre_approve権限なければ、Pre_approve設定できません。
            if (userPermisssionInfo.wco_claim_pre_approve_authFlg) {
              params.pre_approve_flg = true;
            }

            params.update_flg = "1";
            params.updated_date = formList.updated_date;
            apiLoading(true);
            api
              .updateClaimAPI(formList.claim_no, config, params)
              .then(({ code, response }: { code: any; response: any }) => {
                apiLoading(false);

                if (!getApiResultStatus(code)) {
                  errorMessage(response);
                } else {
                  handleCloseReturn();
                }
              });
          }
        }
      }
    }
  };

  const diffClaimDetailMethods = () => {
    const _claim_no = getNewClaimNo();
    // memoMessage保存の仕様追加
    return diffClaimDetailContents(
      _claim_no,
      formList,
      dataList,
      memoMessage,
      transportMessage
    );
  };

  const fileDownloadFile = (message: any) => {
    console.log("claim detail file", message);
    api
      .getFileDZ(config, message.link)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("claim detail file response", response);
        if (!getApiResultStatus(code)) {
          errorMessage({ message_id: "MA0003" });
        } else {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download = message.filename;
          a.click();
        }
      });
  };

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertMessage && alertMessage !== ""}
        message={alertMessage}
        type={alertType}
      />
      <div className={classes.claim_header}>
        <ClaimDetailCommon
          claimType={claimType}
          claimStatus={claimStatus}
          setFormListValue={setFormListValue}
          formList={formList}
        />
        {claimType !== 0 && (
          <div className={classes.history_area}>
            <ClaimDetailHistory
              dataList={formList.claim_updated_history}
              currency={config.currency}
              errorType={errorType}
            />
          </div>
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.root}>
          <AppBar className={classes.AppBar} position="static">
            <Tabs value={value} onChange={handleChange}>
              <Tab
                className={clsx(classes.tabButton)}
                label={t("tab_endUser")}
              />
              <Tab
                className={clsx(classes.tabButton)}
                label={t("tab_toolinformation")}
              />
              <Tab
                className={clsx(
                  classes.tabButton,
                  !partsErrorFlag && classes.errorTabButton
                )}
                label={t("tab_parts_drawings")}
              />
              <Tab
                className={clsx(classes.tabButton)}
                label={t("tab_upload")}
              />

              <Tab
                className={clsx(
                  classes.tabButton,
                  memoErrorMessage !== "" && classes.errorTabButton
                )}
                label={t("tab_memo")}
              />
            </Tabs>
          </AppBar>
          <div>
            {(claimStatus === "200" || claimStatus === "210") && (
              <Button
                variant="contained"
                color="primary"
                className={classes.searchButton}
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
              >
                {t("btn_refresh")}
              </Button>
            )}
            {/* userPermisssionInfo.wco_claim_update_authFlgはSmmaryと同じ設定 */}
            {(Number(claimStatus) === 200 || Number(claimStatus) === 210) &&
              !dataList.pre_approve_flg && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.searchButton}
                  onClick={handleToSave}
                  startIcon={<SaveIcon />}
                >
                  {t("btn_save")}
                </Button>
              )}
            {userPermisssionInfo.wco_claim_update_authFlg &&
              (Number(claimStatus) === 200 || Number(claimStatus) === 210) && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.searchButton}
                  onClick={handleToDeclilne}
                  startIcon={<CancelIcon />}
                >
                  {t("btn_decline")}
                </Button>
              )}

            {userPermisssionInfo.wco_claim_approve_authFlg &&
              (Number(claimStatus) === 200 || Number(claimStatus) === 210) &&
              dataList.pre_approve_flg && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.searchButton}
                  onClick={handleToApprove}
                  startIcon={<CheckCircleOutlineIcon />}
                >
                  {t("btn_approve")}
                </Button>
              )}

            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={handleToRetrun}
              startIcon={<ArrowBackIcon />}
            >
              {t("btn_quit")}
            </Button>
          </div>
        </div>
        <Paper className={classes.paper}>
          <p className={classes.errorTab}>{errorMessageText}</p>
          <div hidden={value !== 0}>
            <ClaimDetailEndUser
              formList={formList}
              formErrorList={formErrorList}
              endUserNoValicationCheck={endUserNoValicationCheck}
              valueValicationCheck={valueValicationCheck}
              setFormListValue={setFormListValue}
              clearErrorCheck={clearErrorCheck}
              mailValicationCheack={mailValicationCheack}
              countriesList={countriesList}
            />
          </div>
          <div hidden={value !== 1}>
            <ClaimDetailToolInformation
              formList={formList}
              setFormList={setFormList}
              dataList={dataList}
              setDataList={setDataList}
              data={repairHistoryList}
              setFormListValue={setFormListValue}
              getRepairHistory={getRepairHistory}
              onBlurOfModelNo={onBlurOfModelNo}
              onBlurOfSerialNo={onBlurOfSerialNo}
              setRepairHistoryList={setRepairHistoryList}
              tempModelNo={tempModelNo}
              setTempModelNo={setTempModelNo}
              tempSerialNo={tempSerialNo}
              setTempSerialNo={setTempSerialNo}
              errorType={errorType}
              setErrorType={setErrorType}
              formErrorList={formErrorList}
              setFormErrorList={setFormErrorList}
              setFormErrorListValue={setFormErrorListValue}
              getReadModelErrorTypeList={getReadModelErrorTypeList}
              onBlurOfpurchasedDate={onBlurOfpurchasedDate}
              onBlurOfReceivedDate={onBlurOfReceivedDate}
              onBlurOfRepairDate={onBlurOfRepairDate}
              getNote={getNote}
              setStartFlg={setStartFlg}
            />
          </div>
          <div hidden={value !== 2}>
            <ClaimDetailParts
              dataList={formList}
              partsInitFlag={partsInitFlag}
              errorMessage={errorMessage}
              registerData={formList.claim_line}
              setRegisterData={setRegisterData}
              setFormListValue={setFormListValue}
              formErrorList={formErrorList}
              setForListFull={setForListFull}
              clearPartsFlag={clearPartsFlag}
              setClearPartsFlag={setClearPartsFlag}
              transportMessage={transportMessage}
              setTransportMessage={setTransportMessage}
              setTransportListValue={setTransportListValue}
              responseList={dataList}
              setFormErrorListValue={setFormErrorListValue}
              clearErrorCheck={clearErrorCheck}
              handleGetPrice={handleGetPrice}
              errorRegisterList={errorRegisterList}
              setErrorRegisterList={setErrorRegisterList}
              itemNoErrorList={itemNoErrorList}
              setItemNoErrorList={setItemNoErrorList}
              total={total}
              setTotal={setTotal}
              tempPartsList={tempPartsList}
              partsDefaultList={partsDefaultList}
              refreshFlag={refreshFlag}
              setRefreshFlag={setRefreshFlag}
            />
          </div>
          <div hidden={value !== 3}>
            <ClaimDetailUpload
              claimMedia={formList.claim_media}
              handleUpload={handleUpload}
              photoDelete={photoDelete}
              fileDownload={fileDownload}
              endUserInfoToolsList={endUserInfoToolsList}
              fileDownloadFile={fileDownloadFile}
              formList={formList}
            />
          </div>
          <div hidden={value !== 4}>
            <ClaimDetailMemo
              list={formList.claim_note}
              memoMessage={memoMessage}
              changeMemoMessage={changeMemoMessage}
              memoErrorMessage={memoErrorMessage}
            />
          </div>
        </Paper>
      </div>
      <ClaimDetailPopup
        returnWindowFlag={returnWindowFlag}
        handleCloseReturnChanges={handleCloseReturnChanges}
        handleCloseReturn={handleCloseReturnSummary}
        declineFlag={declineFlag}
        declineSuccessFlag={declineSuccessFlag}
        handleCloseDecline={handleCloseDecline}
        handleCloseDeclineChanges={handleToDeclilneFunc}
        handleCloseDeclineSuccessChanges={handleCloseDeclineSuccessChanges}
        approveFlag={approveFlag}
        approveSuccessFlag={approveSuccessFlag}
        handleCloseApprove={handleCloseApprove}
        handleCloseApproveChanges={handleToApproveFunc}
        handleCloseApproveSuccessChanges={handleCloseApproveSuccessChanges}
      />
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
