import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// hook
import {
  date,
  time,
} from "../../../modules/components/numberFormat/NumberFormat.Date";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { getCodeNameByNumber } from "../../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderDetailContainer: {
      padding: "30px 20px 20px 20px",
      borderTop: "1px solid #CFCFCF",
    },
    thead: {
      "& th": {
        border: "1px solid rgba(198, 209, 221)",
        background: "rgba(240, 242, 244)",
        textAlign: "center",
        paddingLeft: "8px",
        paddingRight: "8px",
        letterSpacing: "-0.3px",
        width: "8%",
      },
    },
    tableRow: {
      "& td": {
        border: "1px solid rgba(198, 209, 221)",
        paddingLeft: "8px",
        paddingRight: "8px",
        padding: "8px !important",
      },
    },
    autoComplete: {
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
      {
        // padding: "0px",
        paddingLeft: "5px",
        padding: "8px",
      },
    },
    textField: {
      padding: "0px",
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0 5px",
        fontSize: "0.875rem",
      },
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "order_no", label: "table_order_no" },
  { id: "dz_order_no", label: "table_order_dz_order_no" },
  { id: "order_entry_date", label: "table_order_entry_date" },
  { id: "request_ship_date", label: "table_request_shipdate" },
  { id: "customer_no", label: "table_customer_no" },
  { id: "customer_po_no", label: "table_customer_po_no" },
  { id: "payment_term_code", label: "table_payment_term_code" },
  { id: "total_amount", label: "table_total_amount" },
  { id: "approved", label: "table_approved" },
  { id: "confirmed", label: "table_confirmed" },
  { id: "dz_user", label: "table_dz_user" },
  { id: "person_in_charge", label: "table_person_in_charge" },
];

export const OrderDetailTable = (props: any) => {
  const classes = useStyles();
  const {
    tableData,
    personInChargeState,
    personInChargeSubState,
    personInChargeNameState,
    updateSelectPersonInChargeStateByName,
    updateSelectPersonInChargeStateBySub,
    onBlurPersonInCharge,
    errorFlag,
    setErrorFlag,
    orderHeader,
    setorderHeaderValue,
    onBlurOfCustomerPONo,
    orderHeaderError,
    customerPoNoAuthFlg
  } = props;
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const getOptionLabel = (option: any) => option.name || "";
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });
  const editDateTime = (dateTime: string) => {
    let editedDateTime = "";
    if (dateTime && dateTime !== "") {
      let dateTimeLong = new Date(dateTime).getTime();
      editedDateTime = date(dateTimeLong) + " " + time(dateTimeLong);
    }
    return editedDateTime;
  };


  return (
    <TableContainer className={classes.orderDetailContainer}>
      <Table>
        <TableHead className={classes.thead}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.label}>{t(headCell.label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            className={classes.tableRow}
            tabIndex={-1}
            key={tableData.order_no}
          >
            <TableCell align="center">{tableData.order_no}</TableCell>
            <TableCell align="center">{tableData.dz_order_no}</TableCell>
            <TableCell align="center">
              {!tableData.order_entry_date || tableData.order_entry_date === ""
                ? ""
                : editDateTime(tableData.order_entry_date)}
            </TableCell>
            <TableCell align="center">
              {!tableData.request_ship_date ||
                tableData.request_ship_date === ""
                ? ""
                : date(new Date(tableData.request_ship_date).getTime())}
            </TableCell>
            <TableCell align="center">{tableData.customer_no}</TableCell>
            <TableCell align="center">
              {customerPoNoAuthFlg &&
                props.userPermisssionInfo.csd_order_detail_submit_authFlg ? (
                <TextField
                  className={classes.textField}
                  value={orderHeader.customer_po_no}
                  variant="outlined"
                  onChange={(e) =>
                    setorderHeaderValue("customer_po_no", e.target.value)
                  }
                  onBlur={(e) => {
                    onBlurOfCustomerPONo(e.target.value);
                    setorderHeaderValue(
                      "customer_po_no",
                      orderHeader.customer_po_no
                    );
                  }}
                  error={orderHeaderError.customer_po_no_error !== ""}
                  helperText={orderHeaderError.customer_po_no_error}
                  inputProps={{ maxLength: 30 }}
                />
              ) : (
                tableData.customer_po_no
              )}
            </TableCell>
            <TableCell align="center">{tableData.payment_term_code}</TableCell>
            <TableCell align="right">
              {!isNaN(tableData.total_amount) &&
                currency(tableData.total_amount, tableData.currency)}
            </TableCell>
            <TableCell align="center">
              {getCodeNameByNumber(
                tableData.order_approve,
                com("order_approved")
              )}
            </TableCell>
            <TableCell align="center">
              {getCodeNameByNumber(
                tableData.order_confirmation,
                com("order_confirmed")
              )}
            </TableCell>
            <TableCell align="center">{tableData.dz_user}</TableCell>
            <TableCell align="center">
              <Autocomplete
                className={classes.autoComplete}
                id="person_in_charge"
                freeSolo
                disableClearable
                disabled={
                  personInChargeState.length === 0 ||
                  tableData.order_approve === 1
                }
                options={personInChargeState}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                inputValue={personInChargeNameState}
                value={personInChargeNameState}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      minWidth: "200px",
                    }}
                    placement="bottom-start"
                  />
                )}
                getOptionSelected={(option: any, value: any) => {
                  if (personInChargeSubState === "") {
                    return false;
                  } else {
                    return option.sub === personInChargeSubState;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    onBlur={() => {
                      onBlurPersonInCharge();
                    }}
                    onFocus={() => setErrorFlag(false)}
                    error={errorFlag}
                    helperText={
                      errorFlag && personInChargeNameState.length > 0
                        ? com("messages.MA0004", {
                          param1: t("table_person_in_charge"),
                        })
                        : ""
                    }
                  />
                )}
                onChange={(event: any, value: any, reason: string) => {
                  if (reason === "select-option") {
                    console.log("reason1");

                    updateSelectPersonInChargeStateBySub(
                      value.sub,
                      personInChargeState
                    );
                  }
                }}
                onInputChange={(event: any, value: any, reason: string) => {
                  if (reason === "input" || reason === "clear") {
                    console.log("reason2");

                    updateSelectPersonInChargeStateByName(
                      value,
                      personInChargeState
                    );
                  }
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
