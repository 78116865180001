import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionSearchResultSpecialPriceTableThead } from "./Promotion.SearchResult.SpecialPrice.Table.Thead";
import { PromotionSearchResultSpecialPriceTableTbody } from "./Promotion.SearchResult.SpecialPrice.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotionListContainer: {
      maxHeight: "560px",
    },
  })
);

export const PromotionSearchResultSpecialPriceTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  // pager
  const { getPromoPageInfo, setPromoPageInfo } = useCommonInfo();
  let pageMessage = getPromoPageInfo() as any;
  const handleChangePage = (event: unknown, newPage: number) => {
    const obj = Object.assign({}, pageMessage, { page: newPage });
    setPromoPageInfo(obj);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const number = parseInt(event.target.value, 10);
    const obj = Object.assign({}, pageMessage, { perPage: number, page: 0 });
    setPromoPageInfo(obj);
  };
  const getPageInfo = () => {
    return {
      page: pageMessage.page,
      rowsPerPage: pageMessage.perPage,
    };
  };

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.promo_no);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray, row.promo_no);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data.slice(0);
      const newSelecteds = data.map((item: any) => item.promo_no);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  // order
  const { order, setOrder, orderBy, setOrderBy } = props;

  useEffect(() => {
    // init search
    if (props.needResetOrderBy) {
      setOrderBy(["promo_no"]);
      setOrder("desc");
      const obj = Object.assign({}, pageMessage, { page: 0 });
      setPromoPageInfo(obj);
      props.setNeedResetOrderBy(false);
    }
  }, [props.needResetOrderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy.indexOf(property) > -1 && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy([property]);
  };

  return (
    <Paper>
      <TableContainer className={classes.promotionListContainer}>
        <Table stickyHeader>
          <PromotionSearchResultSpecialPriceTableThead
            indeterminate={props.indeterminate}
            allChecked={props.allChecked}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            salesAnalysisList={props.salesAnalysisList}
          />
          <PromotionSearchResultSpecialPriceTableTbody
            data={props.data}
            pageInfo={getPageInfo()}
            order={order}
            orderBy={orderBy}
            handleClick={(event: any, row: any) => handleClick(event, row)}
            selected={props.selectedArray}
            salesAnalysisList={props.salesAnalysisList}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.data.length}
        rowsPerPage={pageMessage.perPage}
        page={pageMessage.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
    </Paper>
  );
};
