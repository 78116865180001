import React from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// date
import {
  date,
  time,
} from "../../../modules/components/numberFormat/NumberFormat.Date";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#fff",
      borderRadius: "10px",
      "& p": {
        margin: "0",
      },
    },
    header: {
      borderBottom: "1px solid #BDBDBD",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      padding: "5px 10px",
      fontSize: "12px",
      "& h2": {
        fontSize: "12px",
        margin: "0",
      },
      "& p": {
        margin: "0 0 0 5px",
      },
    },
    contents: {
      padding: "5px 10px",
      fontSize: "12px",
      wordBreak: "break-word",
      whiteSpace: "pre-line",
    },
    headerTitle: {
      display: "flex",
      flexWrap: "wrap",
    },
    buttonArea: {
      display: "flex",
      flexWrap: "wrap",
    },
    buttonContainer: {
      padding: 0,
      fontSize: "10px",
      "& .MuiSvgIcon-root": {
        width: "15px",
        height: "15px",
        marginRight: "15px",
      },
    },
  })
);
const name = (name: string = "") => {
  name = name.trim();
  return name && <p>- {name}</p>;
};
const dateMessage = (dateValue: string = "") => {
  dateValue = dateValue.trim();
  const d: any = new Date(dateValue).getTime();
  return dateValue && <p>- {date(d) + " " + time(d)}</p>;
};

export const JobsDetailMemoBox = (props: any) => {
  const myClasses = myStyles();
  const { title, item, buttonFlg, userPermisssionInfo } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.jobDetail.memo" });

  const buttonControl = (buttonFlg: boolean = false) => {
    return (
      buttonFlg && (
        <div className={myClasses.buttonArea}>
          {userPermisssionInfo.edit_memo_authFlg && (
            <IconButton
              color="primary"
              className={myClasses.buttonContainer}
              onClick={() => props.handleEditEvent(item)}
            >
              <EditIcon />
            </IconButton>
          )}
          {userPermisssionInfo.delete_memo_authFlg && (
            <IconButton
              color="secondary"
              className={myClasses.buttonContainer}
              onClick={() => props.handleDeleteEvent(item)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      )
    );
  };
  return (
    <div className={myClasses.root}>
      <div className={myClasses.header}>
        <div className={myClasses.headerTitle}>
          <h2>{t(title)}</h2>
          {name(item.updated_by)}
          {dateMessage(item.updated_date)}
        </div>
        {buttonControl(buttonFlg)}
      </div>
      <div className={myClasses.contents}>
        <p>{item.note}</p>
      </div>
    </div>
  );
};
