import React, { useState, useEffect } from "react";
// route
import { Prompt, useHistory } from "react-router-dom";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { MasterMaintenanceSelector } from "./components/MasterMaintenance.selector";
import { MasterMaintenanceUserMaster } from "./components/MasterMaintenance.UserMaster";
import { MasterMaintenanceUserMasterEditor } from "./components/MasterMaintenance.UserMaster.Editor";
import { MasterMaintenanceDZUserInfo } from "./components/MasterMaintenance.DZUserInfo";
import { MasterMaintenanceDZUserInfoEditor } from "./components/MasterMaintenance.DZUserInfo.Editor";
import { MasterMaintenanceDZUserRegistration } from "./components/MasterMaintenance.DZUserRegistration";
import { MasterMaintenanceDZUserRegistrationEditor } from "./components/MasterMaintenance.DZUserRegistration.Editor";
import { MasterMaintenanceCustomerMaster } from "./components/MasterMaintenance.CustomerMaster";
import { MasterMaintenanceCustomerMasterEditor } from "./components/MasterMaintenance.CustomerMaster.Editor";
import { MasterMaintenanceItemMaster } from "./components/MasterMaintenance.ItemMaster";
import { MasterMaintenanceItemMasterEditor } from "./components/MasterMaintenance.ItemMaster.Editor";
import { MasterMaintenanceRole } from "./components/MasterMaintenance.Role";
import { MasterMaintenanceRoleEditor } from "./components/MasterMaintenance.Role.Editor";
import { MasterMaintenanceUserRole } from "./components/MasterMaintenance.UserRole";
import { MasterMaintenanceUserRoleEditor } from "./components/MasterMaintenance.UserRole.Editor";
import { MasterMaintenanceServiceCenter } from "./components/MasterMaintenance.ServiceCenter";
import { MasterMaintenanceServiceCenterEditor } from "./components/MasterMaintenance.ServiceCenter.Editor";
import { MasterMaintenancePrintSetting } from "./components/MasterMaintenance.PrintSetting";
import { MasterMaintenancePrintSettingEditor } from "./components/MasterMaintenance.PrintSetting.Editor";
import { MasterMaintenanceRepairDestination } from "./components/MasterMaintenance.RepairDestination";
import { MasterMaintenanceRepairDestinationEditor } from "./components/MasterMaintenance.RepairDestination.Editor";
import { MasterMaintenanceLaborMaster } from "./components/MasterMaintenance.LaborMaster";
import { MasterMaintenanceLaborMasterEditor } from "./components/MasterMaintenance.LaborMaster.Editor";
import { MessageBox } from "../../modules/components/messageBox/MessageBox";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { headCells as UserMasterHeader } from "./components/MasterMaintenance.UserMaster.Thead";
import { headCells as DZUserInfoHeader } from "./components/MasterMaintenance.DZUserInfo.Thead";
import { headCells as DZUserRegistrationHeader } from "./components/MasterMaintenance.DZUserRegistration.Thead";
import { headCells as PrintSettingHeader } from "./components/MasterMaintenance.PrintSetting.Thead";
import { headCells as RepairDestinationHeader } from "./components/MasterMaintenance.RepairDestination.Thead";
import { headCells as ServiceCenterHeader } from "./components/MasterMaintenance.ServiceCenter.Thead";
import { headCells as CustomerMasterHeader } from "./components/MasterMaintenance.CustomerMaster.Thead";
import { headCells as UserRoleHeader } from "./components/MasterMaintenance.UserRole.Thead";
import { headCells as RoleHeader } from "./components/MasterMaintenance.Role.Thead";
import { headCells as ItemMasterHeader } from "./components/MasterMaintenance.ItemMaster.Thead";
import { headCells as LaborMasterHeader } from "./components/MasterMaintenance.LaborMaster.Thead";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FileSaver from "file-saver";

// api
import ISO6391 from "iso-639-1";
import countries from "i18n-iso-countries";
// methods
import {
  getApiResultStatus,
  getAPIErrorMessage,
  getCountry,
} from "../../modules/common.methods";
import {
  SearchResultMethods,
  getUseMasterNewData,
  getDZUserInfoNewData,
  getDZUserRegistrationNewData,
  getDZUserInfoFromegistrationNewData,
  getCustomerMasterNewData,
  getItemMasterNewData,
  getRoleNewData,
  getServiceCenterNewData,
  getEditOrRegisterSetting,
  createDeleteParam,
  createDeleteClaimLaborParam,
  getCountriesListArray,
} from "./methods/common.methods";
// constants
import { defaultEditValue, TabKey } from "./MasterMaintenance.constants";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../modules/hooks/Permissions";
// api
import api from "../../modules/api/Api";
// setListId
import {
  setListId,
  getCSVContents,
  getCSVFileName,
  getDZuserInfoCSVContents,
  getUserMasterInfoCSVContents,
} from "./methods/common.methods";

import {
  renameResponseData,
  renameResponseDataArray,
} from "../../modules/common.methods";
import { userInfoListRename } from "../../modules/constants/common.constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlArea: {
      borderBottom: "1px solid #CFCFCF",
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "20px",
      },
    },
    hidden: {
      display: "none",
    },
  })
);

export function Page() {
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance",
  });

  // error common json
  const errorList: any = {
    error_description_error: "",
    labour_value_error: "",
  };

  const [selectorValue, setSelectorValue] = useState("selectOne");
  const [editStatus, setEditStatus] = useState("");
  const classes = useStyles();
  // use Master
  const [useMasterList, setUseMasterList] = useState([]);
  const [useMasterResultList, setUseMasterResultList] = useState([]);
  // dZUserInfo
  const [dZUserInfoDataList, setDZUserInfoDataList] = useState([]);
  const [dZUserInfoResultList, setDZUserInfoResultList] = useState([]);
  // dZUserRegistration
  const [dZUserRegistrationDataList, setDZUserRegistrationDataList] = useState(
    []
  );
  const [dZUserRegistrationResultList, setDZUserRegistrationResultList] =
    useState([]);
  // custormer master
  const [customerMasterDataList, setCustomerMasterDataList] = useState([]);
  const [customerMasterResultList, setCustomerMasterResultList] = useState([]);
  // item master
  const [itemMasterDataList, setItemMasterDataList] = useState([]);
  const [itemMasterResultList, setItemMasterResultList] = useState([]);
  // role
  const [roleDataList, setRoleDataList] = useState([]);
  const [roleResultList, setRoleResultList] = useState([]);
  // user role
  const [userRoleDataList, setUserRoleDataList] = useState([]);
  const [userRoleResultList, setUserRoleResultList] = useState([]);
  // service center
  const [serviceCenterDataList, setServiceCenterDataList] = useState([]);
  const [serviceCenterResultList, setServiceCenterResultList] = useState([]);
  // Print Setting
  const [printSettingDataList, setPrintSettingDataList] = useState([]);
  const [printSettingResultList, setPrintSettingResultList] = useState([]);
  // repair destination
  const [repairDestinationDataList, setRepairDestinationDataList] = useState(
    []
  );
  const [repairDestinationResultList, setRepairDestinationResultList] =
    useState([]);
  // Labor Master
  const [laborMasterDataList, setLaborMasterDataList] = useState([]);
  const [laborMasterResultList, setLaborMasterResultList] = useState([]);

  const [editList, setEditList] = useState(defaultEditValue);
  const [affiliateCd, setAffiliateCd] = useState([]);
  const [primaryLocale, setPrimaryLocale] = useState([]);
  const [documentFormData, setDocumentFormData] = useState([]);
  const [printerdata, setPrinterdata] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [roleGroup, setRoleGroup] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [permissionCheckedList, setPermissionCheckedList] = useState({});
  const [permissionCheckedListDefault, setPermissionCheckedListDefault] =
    useState({});
  const [itemServiceCenterCheckedList, setItemServiceCenterCheckedList] =
    useState({});
  const [
    itemServiceCenterCheckedListDefault,
    setItemServiceCenterCheckedListDefault,
  ] = useState({});
  const [roleGroupFlg, setRoleGroupFlg] = useState(false);
  const [roleDataCheckedList, setRoleDataCheckedList] = useState({});
  const [roleDataCheckedListDefault, setRoleDataCheckedListDefault] = useState(
    {}
  );
  const [roleDataStartCheckedNumber, setRoleDataStartCheckedNumber] =
    useState(0);
  const [ERPUserDetailList, setERPUserDetailList] = useState([]);
  const [errorValueList, setErrorValueList] = useState(errorList);
  const [selectorList, setSelectorList] = useState([]) as any;
  // UseRepair show 条件
  const [userRepairDisabledFlag, setUserRepairDisabledFlag] = useState(false);
  const primaryLocales: any = process.env.REACT_APP_PRIMARY_LOCALE;
  // DZ User Info start flag
  const [dzUserInfoStartFlg, setDzUserInfoStartFlg] = useState(false);

  // loading
  const {
    setCommonInfo,
    getCommonConfig,
    getCommonInfo,
    setCommonInfoFull,
    getUserPermissions,
    setUserPermissions,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  const commonInfo = getCommonInfo() as any;

  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;
  // API
  const [startFlg, setStartFlg] = useState(false);

  const { com } = useLocale({ defaultPath: "common.constants" });
  const credit_note_interval = useLocale({
    defaultPath: "common.constants",
  });
  const [telUsedCountry, setTelUsedCountry] = useState("de");

  // start
  useEffect(() => {
    if (!startFlg && config.token) {
      // tel used country
      //for node.js version test
      const _country = getCountry(config.affiliate_cd);
      setTelUsedCountry(_country);

      setStartFlg(true);
      apiLoading(true);
      let localUserPermissions = {};
      if (userPermissions.permission_group) {
        localUserPermissions = userPermissions;
        permissionsInfomation.setPermissionsInfo(localUserPermissions);
        const tempUserPermissionsInfo =
          permissionsInfomation.getPermissionsInfo();
        setUserPermisssionInfo(tempUserPermissionsInfo);
        setSelectorList(showSelectorList(tempUserPermissionsInfo));
        getDefultValue(tempUserPermissionsInfo);
      } else {
        getUserPermissionsRe().then((r: any) => {
          localUserPermissions = r.response;
          setUserPermissions(r.response);
          permissionsInfomation.setPermissionsInfo(localUserPermissions);
          const tempUserPermissionsInfo =
            permissionsInfomation.getPermissionsInfo();
          setUserPermisssionInfo(tempUserPermissionsInfo);
          setSelectorList(showSelectorList(tempUserPermissionsInfo));
          getDefultValue(tempUserPermissionsInfo);
        });
      }
    }
  });

  // init
  useEffect(() => {
    if (Object.keys(config)) {
      // config.repairShowFlag Repair部分が表示・非表示用フラグ
      setUserRepairDisabledFlag(config.repairShowFlag);
    }
  }, [config]);

  // alert edit message
  const [alertEditMessageList, setAlertEditMessageList] = useState([]) as any;

  const changeData = (type: any, value: any, object: any = "") => {
    console.log("changeData", type, value, object, editList);
    let newList;
    if (type == "role_group") {
      setRoleGroupFlg(false);
    }
    if (object !== "") {
      if (object === "permission") {
        newList = Object.assign({}, permissionCheckedList, { [type]: value });
        setPermissionCheckedList(newList);
      } else if (object === "roleList") {
        newList = Object.assign({}, roleDataCheckedList, { [type]: value });
        setRoleDataCheckedList(newList);
      }
      if (object === "item_service_center") {
        newList = Object.assign({}, itemServiceCenterCheckedList, {
          [type]: value,
        });
        setItemServiceCenterCheckedList(newList);
      } else {
        const newObjectList = Object.assign({}, editList[object], {
          [type]: value,
        });
        newList = Object.assign({}, editList, { [object]: newObjectList });
        setEditList(newList);
      }
    } else {
      setEditList((prevList: any) =>
        Object.assign({}, prevList, { [type]: value })
      );
    }
  };

  const SearchResult = (key: any, tabName: any) => {
    setAlertMessageList([]);
    let list: any;
    let headers: any;
    let type: any;
    switch (tabName) {
      case TabKey.useMaster:
        list = useMasterList;
        headers = UserMasterHeader;
        type = TabKey.useMaster;
        break;
      case TabKey.DZUserInfo:
        list = dZUserInfoDataList;
        headers = DZUserInfoHeader;
        type = TabKey.DZUserInfo;
        break;
      case TabKey.DZUserRegistration:
        list = dZUserRegistrationDataList;
        headers = DZUserRegistrationHeader;
        type = TabKey.DZUserRegistration;
        break;
      case TabKey.customerMaster:
        list = customerMasterDataList;
        headers = CustomerMasterHeader;
        break;
      case TabKey.itemMaster:
        list = itemMasterDataList;
        headers = ItemMasterHeader;
        break;
      case TabKey.role:
        list = roleDataList;
        headers = RoleHeader;
        type = TabKey.role;
        break;
      case TabKey.userRole:
        list = userRoleDataList;
        headers = UserRoleHeader;
        break;
      case TabKey.serviceCenter:
        list = serviceCenterDataList;
        headers = ServiceCenterHeader;
        break;
      case TabKey.printSetting:
        list = printSettingDataList;
        headers = PrintSettingHeader;
        break;
      case TabKey.repairDestination:
        list = repairDestinationDataList;
        headers = RepairDestinationHeader;
        break;
      case TabKey.laborMaster:
        list = laborMasterDataList;
        headers = LaborMasterHeader;
        break;
    }
    const newList: any = SearchResultMethods(key, list, headers, type);
    switch (tabName) {
      case TabKey.useMaster:
        setUseMasterResultList(newList);
        break;
      case TabKey.DZUserInfo:
        setDZUserInfoResultList(newList);
        break;
      case TabKey.DZUserRegistration:
        setDZUserRegistrationResultList(newList);
        break;
      case TabKey.customerMaster:
        setCustomerMasterResultList(newList);
        break;
      case TabKey.itemMaster:
        setItemMasterResultList(newList);
        break;
      case TabKey.role:
        setRoleResultList(newList);
        break;
      case TabKey.userRole:
        setUserRoleResultList(newList);
        break;
      case TabKey.serviceCenter:
        setServiceCenterResultList(newList);
        break;
      case TabKey.printSetting:
        setPrintSettingResultList(newList);
        break;
      case TabKey.repairDestination:
        setRepairDestinationResultList(newList);
        break;
      case TabKey.laborMaster:
        setLaborMasterResultList(newList);
        break;
    }
  };

  const handleChange = (value: any) => {
    setSelectorValue(value);
  };

  useEffect(() => {
    if (selectorValue === "DZUserInfo") {
      if (dzUserInfoAlertMessageList.length > 0) {
        setAlertMessageList(dzUserInfoAlertMessageList)
      } else {
        setAlertMessageList([]);
      }
    } else {
      setAlertMessageList([]);
    }
  }, [selectorValue]);

  const changeSelecter = (value: any) => {
    return selectorValue !== value ? classes.hidden : "";
  };

  const changeTabInformation = (flg: boolean) => {
    return !flg ? classes.hidden : "";
  };

  const handleEdit = async (row: any, type: any = "") => {
    setEditStatus("edit");
    setAlertMessageList([]);
    setAlertEditMessageList([]);

    if (type === TabKey.DZUserRegistration) {
      if (row.customer_no !== "") {
        const customerInfo: any = customerMasterDataList.filter(
          (item: any) => item.customer_no == row.customer_no
        );
        if (customerInfo.length > 0) {
          row.customer_name = customerInfo[0].customer_name;
        }
      }
    }

    if (type === TabKey.customerMaster) {
      apiLoading(true);
      if (row.no !== "") {
        await api
          .getReadCustomerMasterAPI(row.no, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("get AM0202 customerInfo", code, response);
            if (getApiResultStatus(code)) {
              row.customer_no = response.customer_no;
              row.customer_name = response.customer_name;
              row.mail_address_repair = response.mail_address_repair;
              row.memorandum = response.memorandum;
              row.sms_receive_notification = response.sms_receive_notification;
              row.mail_receive_notification =
                response.mail_receive_notification;
              row.asc_flg = response.asc_flg;
              row.wco_picture = response.wco_picture;
              row.wco_auto_approve = response.wco_auto_approve;
              row.wco_credit_note_interval =
                response.asc_flg && response.wco_credit_note_interval !== "0"
                  ? response.wco_credit_note_interval
                  : undefined;
              row.updated_date = response.updated_date;
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    }

    if (type === TabKey.itemMaster) {
      apiLoading(true);
      if (row.item_no !== "") {
        await api
          .getReadItemMasterAPI(encodeURIComponent(row.item_no), config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("get AM0302 itemInfo", code, response);
            if (getApiResultStatus(code)) {
              row.model_no = response.model_no;
              row.description = response.description;
              row.memo = response.memo;
              if (
                response.service_center_list &&
                response.service_center_list.length > 0
              ) {
                let checkList: any = {};
                response.service_center_list.forEach((item: any) => {
                  checkList[item.service_center_id] = true;
                });
                setItemServiceCenterCheckedList(checkList);
              } else {
                clearItemServiceCenterCheckedList();
              }
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    }
    const newList = Object.assign(
      {},
      defaultEditValue,
      row,
      getEditOrRegisterSetting(false, type, config.affiliate_cd)
    );
    setEditList(newList);
    clearPrermissionCheckedList();
    clearRoleDataCheckedList();
  };

  const handleRegister = (type: any = "") => {
    setEditStatus("edit");
    setAlertMessageList([]);
    setAlertEditMessageList([]);

    const newList = Object.assign(
      {},
      defaultEditValue,
      getEditOrRegisterSetting(true, type, config.affiliate_cd)
    );
    setEditList(newList);
    clearPrermissionCheckedList();
    clearRoleDataCheckedList();
  };

  const handleResetMFA = (sub: any) => {
    apiLoading(true);
    window.scrollTo(0, 0);
    // AM0113
    api
      .resetUserInfoMFAAPI(sub, config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("reset AM0113 useInfo", code, response);
        if (getApiResultStatus(code)) {
          let message = "";
          message = com("messages.MS0112");
          const obj: any = {
            no: alertEditMessageList.length,
            message: message,
            alertType: "success",
          };
          const newList: any = alertEditMessageList.slice(0);
          newList.push(obj);
          setAlertEditMessageList(newList);
        } else {
          const message = com("messages." + response.message_id, {
            param1: t("API." + response.key),
          });
          const obj: any = {
            no: alertMessageList.length,
            message: message,
            alertType: "error",
          };
          const newList: any = alertMessageList.slice(0);
          newList.push(obj);
          setAlertEditMessageList(newList);
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const clearPrermissionCheckedList = () => {
    const list = Object.assign({}, permissionCheckedListDefault);
    setPermissionCheckedList(list);
  };

  const clearItemServiceCenterCheckedList = () => {
    const list = Object.assign({}, itemServiceCenterCheckedListDefault);
    setItemServiceCenterCheckedList(list);
  };

  const clearRoleDataCheckedList = () => {
    const list = Object.assign({}, roleDataCheckedListDefault);
    setRoleDataCheckedList(list);
  };

  // editor back event
  const handleBack = (type: any = "") => {
    setEditStatus("");
    setAlertMessageList([]);
    if (type === TabKey.itemMaster) {
      clearItemServiceCenterCheckedList();
    }
  };

  // editor delete event
  const handleDelete = (row: any, type: any) => {
    apiLoading(true);
    setEditStatus("");
    setAlertMessageList([]);

    const param = createDeleteParam(row.updated_date);
    if (type === TabKey.useMaster) {
      // AM0106
      api
        .deleteUserInfoAPI(param, row.sub, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("delete AM0106 useInfo", code, response);
          if (getApiResultStatus(code)) {
            successMessage("delete");
            window.scrollTo(0, 0);
            return (
              getUserInfoDetailListAPI(),
              userPermisssionInfo.user_master_DZFlg
                ? getDzinfoDetailListAPI()
                : ""
            );
          } else {
            errorMessage(response);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    } else if (type === TabKey.DZUserInfo) {
      // AM0106;
      api
        .deleteUserInfoAPI(param, row.sub, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("delete AM0106 dzInfo", code, response);
          if (getApiResultStatus(code)) {
            successMessage("delete");
            window.scrollTo(0, 0);
            return (
              getDzinfoDetailListAPI(),
              userPermisssionInfo.user_master_consoleFlg
                ? getUserInfoDetailListAPI()
                : ""
            );
          } else {
            errorMessage(response);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    } else if (type === TabKey.role) {
      // AM0504
      api
        .deleteRoleMasterAPI(row.role_group, row.role_id, param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("delete AM0504 role", code, response);
          if (getApiResultStatus(code)) {
            successMessage("delete");
            window.scrollTo(0, 0);
            return getRoleListAPI();
          } else {
            errorMessage(response);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    } else if (type === TabKey.serviceCenter) {
      // AM0704
      api
        .deleteServiceCenterMasterAPI(row.service_center_id, param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("delete AM0704 service center", code, response);
          if (getApiResultStatus(code)) {
            successMessage("delete");
            window.scrollTo(0, 0);
            return getServiceCenterDetailListAPI();
          } else {
            errorMessage(response);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    } else if (type === TabKey.laborMaster) {
      const paramClaimLabor = createDeleteClaimLaborParam(row);
      api
        .updateClaimLaborAPI(
          encodeURIComponent(row.model_no),
          paramClaimLabor,
          config
        )
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            successMessage("delete");
            apiLoading(false);
            return getLaborMasterListAPI();
          } else {
            apiLoading(false);
            return errorMessage(response);
          }
        });
    }
  };

  // editor submit event
  const handleSubmit = (
    type: any,
    registerFlg: boolean = false,
    dzuserRegistration?: any
  ) => {
    apiLoading(true);
    setEditStatus("");
    setAlertMessageList([]);

    let getNewData: any;
    let paramUserInfo: any;
    switch (type) {
      case TabKey.useMaster:
        getNewData = getUseMasterNewData;
        break;
      case TabKey.itemMaster:
        getNewData = getItemMasterNewData;
        break;
      case TabKey.DZUserInfo:
        getNewData = getDZUserInfoNewData;
        break;
      case TabKey.DZUserRegistration:
        getNewData = getDZUserRegistrationNewData;
        paramUserInfo = getDZUserInfoFromegistrationNewData;
        break;
      case TabKey.customerMaster:
        getNewData = getCustomerMasterNewData;
        break;
      case TabKey.role:
        getNewData = getRoleNewData;
        break;
      case TabKey.serviceCenter:
        getNewData = getServiceCenterNewData;
        break;
    }
    let param: any;
    if (type === TabKey.itemMaster) {
      param = getNewData(
        editList,
        serviceCenterDataList,
        itemServiceCenterCheckedList
      );
    } else if (type === TabKey.userRole) {
      //param = getNewData(editList, roleDataList, roleDataCheckedList);
    } else if (type === TabKey.role) {
      param = getNewData(editList, permissionList, permissionCheckedList);
    } else if (type === TabKey.DZUserRegistration) {
      paramUserInfo = getDZUserInfoFromegistrationNewData(editList);
      param = getNewData(editList);
    } else {
      param = getNewData(editList);
    }

    if (type === TabKey.useMaster) {
      if (registerFlg) {
        // AM0101
        api
          .createUserInfoAPI(param, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("post AM0101 userInfo", code, response);
            if (getApiResultStatus(code)) {
              successMessage("post");
              return (
                getUserInfoDetailListAPI(),
                userPermisssionInfo.user_master_DZFlg
                  ? getDzinfoDetailListAPI()
                  : ""
              );
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      } else {
        // AM0105
        api
          .updateUserInfoAPI(param, editList.sub, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM0105 userInfo", code, response);
            if (getApiResultStatus(code)) {
              successMessage("patch");
              return (
                getUserInfoDetailListAPI(),
                userPermisssionInfo.user_master_DZFlg
                  ? getDzinfoDetailListAPI()
                  : ""
              );
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    } else if (type === TabKey.DZUserInfo) {
      if (registerFlg) {
        // AM0101
        api
          .createUserInfoAPI(param, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("post AM0101 DZUserInfo", code, response);
            if (getApiResultStatus(code)) {
              successMessage("post");
              return (
                getDzinfoDetailListAPI(),
                userPermisssionInfo.user_master_consoleFlg
                  ? getUserInfoDetailListAPI()
                  : ""
              );
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      } else {
        // AM0105
        api
          .updateUserInfoAPI(param, editList.sub, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM0105 DZUserInfo", code, response);
            if (getApiResultStatus(code)) {
              successMessage("patch");
              return (
                getDzinfoDetailListAPI(),
                userPermisssionInfo.user_master_consoleFlg
                  ? getUserInfoDetailListAPI()
                  : ""
              );
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    } else if (type === TabKey.DZUserRegistration) {
      if (dzuserRegistration === "reject") {
        param.confirmation = "2";
        // AM0113
        api
          .updateDZUserRegistrationAPI(
            param,
            encodeURIComponent(param.mail_address),
            config
          )
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM0113 DZUserRegistration", code, response);
            if (getApiResultStatus(code)) {
              successMessage("patch");
              return getDZUserRegistrationListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      } else if (dzuserRegistration === "confirm") {
        param.confirmation = "1";
        // // AM0101
        api
          .createUserInfoAPI(paramUserInfo, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("post AM0101 DZUserInfo", code, response);
            if (getApiResultStatus(code)) {
              successMessage("post");
              // AM0113
              api
                .updateDZUserRegistrationAPI(
                  param,
                  encodeURIComponent(param.mail_address),
                  config
                )
                .then(({ code, response }: { code: any; response: any }) => {
                  console.log(
                    "patch AM0113 DZUserRegistration",
                    code,
                    response
                  );
                  if (getApiResultStatus(code)) {
                    successMessage("patch");
                    return (
                      getDzinfoDetailListAPI(), getDZUserRegistrationListAPI()
                    );
                  } else {
                    errorMessage(response);
                  }
                });
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    } else if (type === TabKey.customerMaster) {
      // AM0203
      api
        .updateCustomerMasterAPI(editList.customer_no, param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("patch AM0203 customer", code, response);
          if (getApiResultStatus(code)) {
            successMessage("patch");
            return getCustomerDetailListAPI();
          } else {
            errorMessage(response);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    } else if (type === TabKey.itemMaster) {
      // AM0303
      api
        .updateItemMasterAPI(param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("patch AM0303 item", code, response);
          if (getApiResultStatus(code)) {
            successMessage("patch");
            clearItemServiceCenterCheckedList();
            return getItemDetailListAPI();
          } else {
            errorMessage(response);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    } else if (type === TabKey.role) {
      if (registerFlg) {
        // AM1301
        api
          .createRoleandRolePermissionAPI(param, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("post AM1301 role", code, response);
            if (getApiResultStatus(code)) {
              successMessage("post");
              return getRoleListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      } else {
        // AM1304
        api
          .updatePermissionAPI(
            param,
            editList.role_group,
            editList.role_id,
            config
          )
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM1304 role", code, response);
            if (getApiResultStatus(code)) {
              successMessage("patch");
              return getRoleListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    } else if (type === TabKey.userRole) {
      if (roleDataStartCheckedNumber == 0) {
        // AM1201
        api
          .createUserRoleAPI(
            roleDataList,
            roleDataCheckedList,
            editList.sub,
            config
          )
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM1201 user_role", code, response);
            if (getApiResultStatus(code)) {
              successMessage("post");
              return getUserInfoDetailListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      } else {
        // AM1203
        api
          .updateUserRoleAPI(
            editList,
            roleDataList,
            roleDataCheckedList,
            config
          )
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM1203 user_role", code, response);
            if (getApiResultStatus(code)) {
              successMessage("patch");
              return getUserInfoDetailListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    } else if (type === TabKey.serviceCenter) {
      if (registerFlg) {
        // AM0701
        api
          .createServiceCenterMasterAPI(param, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("post AM0701 userrole", code, response);
            if (getApiResultStatus(code)) {
              successMessage("post");
              return getServiceCenterDetailListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      } else {
        // AM0703
        api
          .updateServiceCenterMasterAPI(
            editList.service_center_id,
            param,
            config
          )
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("patch AM0703 userrole", code, response);
            if (getApiResultStatus(code)) {
              successMessage("patch");
              return getServiceCenterDetailListAPI();
            } else {
              errorMessage(response);
            }
          })
          .then(() => {
            apiLoading(false);
          });
      }
    }
  };

  const downloadCSV = () => {
    setCSV(laborMasterResultList);
  };

  const downloadDZUserInfoCSV = () => {
    setdDZUserInfoCSV(dZUserInfoResultList);
  };

  const setCSV = (data: any) => {
    const str = getCSVContents(t, data, config);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "claimLaborList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const setdDZUserInfoCSV = (data: any) => {
    const str = getDZuserInfoCSVContents(t, data, config);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "DZuserInfoList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const setUserMasterInfoCSV = (data: any) => {
    const str = getUserMasterInfoCSVContents(t, data, config, com, serviceCenterDataList);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "UserMasterInfoList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const downloadUserMasterInfoCSV = () => {
    apiLoading(true);
    api
      .getUserInfoCSVAPIForDownload(config)
      .then(({ response, code }: { response: any; code: any }) => {
        if (!getApiResultStatus(code)) {
          apiLoading(false);
          errorMessage(response);
        } else {
          const url = response.content;
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              if (!getApiResultStatus(code)) {
                errorMessage(response);
                apiLoading(false);
              } else {
                let reader: any = new FileReader();
                reader.readAsText(response, "UTF-8");
                reader.onload = function () {
                  apiLoading(false);
                  let data = this.result;
                  setUserMasterInfoCSV(JSON.parse(data));
                };
              }
            });
        }
      });
  };

  // message box
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState("");
  const [changeView, setChangeView] = useState(false);
  const history = useHistory();

  const handleSend = () => {
    setChangeView(false);
    setOpen(false);
    setEditStatus("");
    setChangeView(true);
  };
  useEffect(() => {
    if (changeView) {
      history.push(path);
    }
  }, [changeView]);
  const handleClose = () => {
    setOpen(false);
  };

  // alert
  const [alertMessageList, setAlertMessageList] = useState([]) as any;
  const [dzUserInfoAlertMessageList, setDzUserInfoAlertMessageList] = useState([]) as any;

  const errorMessage = (response: any) => {
    console.log(
      "errorMessage",
      "response.key:",
      response.key,
      "response.message_id:",
      response.message_id
    );
    const message = com("messages." + response.message_id, {
      param1: t("API." + response.key),
    });
    const obj: any = {
      no: alertMessageList.length,
      message: message,
      alertType: "error",
    };
    const newList: any = alertMessageList.slice(0);
    newList.push(obj);
    setAlertMessageList(newList);
  };
  const successMessage = (type: any) => {
    let message = "";
    switch (type) {
      case "delete":
        message = com("messages.MS0014");
        break;
      case "post":
        message = com("messages.MS0015");
        break;
      default:
        message = com("messages.MS0015");
        break;
    }
    const obj: any = {
      no: alertMessageList.length,
      message: message,
      alertType: "success",
    };
    const newList: any = alertMessageList.slice(0);
    newList.push(obj);
    setAlertMessageList(newList);
  };

  const showSelectorList = (userPermisssionInfo: any) => [
    {
      value: "selectOne",
      label: "-----" + t("selectMenu.selectOne") + "-----",
      showFlg: true,
    },
    {
      value: "UserMaster",
      label: t("selectMenu.select_user_master"),
      showFlg: userPermisssionInfo.user_master_consoleFlg,
    },
    {
      value: "DZUserInfo",
      label: t("selectMenu.select_DZ_userInfo"),
      showFlg: userPermisssionInfo.user_master_DZFlg,
    },
    {
      value: "DZUserRegistration",
      label: t("selectMenu.select_DZ_userRegistration"),
      showFlg: userPermisssionInfo.user_master_DZFlg,
    },
    {
      value: "CustomerMaster",
      label: t("selectMenu.select_customer_master"),
      showFlg: userPermisssionInfo.customer_memorandum_masterFlg,
    },
    {
      value: "ItemMaster",
      label: t("selectMenu.select_item_master"),
      showFlg: userPermisssionInfo.item_master,
    },
    {
      value: "Role",
      label: t("selectMenu.select_role"),
      showFlg: userPermisssionInfo.role_masterFlg,
    },
    {
      value: "UserRole",
      label: t("selectMenu.select_user_role"),
      showFlg: userPermisssionInfo.role_masterFlg,
    },
    {
      value: "ServiceCenter",
      label: t("selectMenu.select_service_center"),
      showFlg: userPermisssionInfo.service_senter_masterFlg,
    },
    {
      value: "PrintSetting",
      label: t("selectMenu.select_print_setting"),
      showFlg: userPermisssionInfo.printer_masterFlg,
    },
    {
      value: "RepairDestination",
      label: t("selectMenu.select_repair_destination"),
      showFlg: userPermisssionInfo.repair_destination_masterFlg,
    },
    {
      value: "LaborMaster",
      label: t("selectMenu.select_labor_master"),
      showFlg: userPermisssionInfo.wco_labor_master_authFlg,
    },
  ];

  // AM0107
  const getUserInfoDetailListAPI = () => {
    return api
      .getUserinfoDetailListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AM0107 userInfo", response);
        if (getApiResultStatus(code)) {
          let newList = [];
          if (response && response.length > 0) {
            // ファイル軽量化のため、パラメータ名前が短くになりました。
            response = renameResponseDataArray(userInfoListRename, response);
            newList = setListId(response);
            // user master
            setUseMasterList(newList);
            setUseMasterResultList(newList);
            // Print Setting
            setPrintSettingDataList(newList);
            setPrintSettingResultList(newList);
            // user role
            setUserRoleDataList(newList);
            setUserRoleResultList(newList);
          }
        } else {
          if (code === 404) {
            // user master
            setUseMasterList([]);
            setUseMasterResultList([]);
            // Print Setting
            setPrintSettingDataList([]);
            setPrintSettingResultList([]);
            // user role
            setUserRoleDataList([]);
            setUserRoleResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  // AM0107
  const getDzinfoDetailListAPI = () => {
    let query_parameters = "";
    return api
      .getUserinfoDetailListAPIByDz(config, query_parameters)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AM0107 DZInfo", response);
        if (getApiResultStatus(code)) {
          let newList = [];
          if (response && response.length > 0) {
            // ファイル軽量化のため、パラメータ名前が短くになりました。
            response = renameResponseDataArray(userInfoListRename, response);
            newList = setListId(response);
            // DZ userInfo
            setDZUserInfoDataList(newList);
            setDZUserInfoResultList(newList);
            if (response.length >= 4000) {
              const obj: any = {
                message: com("messages.MS0118"),
                alertType: "warning",
              };
              let alertMessageListNew: any = [obj];
              setDzUserInfoAlertMessageList(alertMessageListNew);
            }
          }
        } else {
          if (code === 404) {
            setDZUserInfoDataList([]);
            setDZUserInfoResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  const getDZUserRegistrationListAPI = () => {
    return api
      .getDZUserRegistrationListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get DZ User Registration List", response);
        if (getApiResultStatus(code)) {
          let newList = [];
          if (response && response.length > 0) {
            newList = setListId(response);
            // DZ User Registration
            setDZUserRegistrationDataList(newList);
            setDZUserRegistrationResultList(newList);
            const dZUserRegistrationStatus = getMasterMaintenanceSelect();
            if (dZUserRegistrationStatus.length > 0) {
              for (let i = 0; i < newList.length; i++) {
                if (
                  newList[i]["mail_address"] === dZUserRegistrationStatus[1]
                ) {
                  setSelectorValue("DZUserRegistration");
                  handleEdit(newList[i], TabKey.DZUserRegistration);
                }
              }
            }
          }
        } else {
          if (code === 404) {
            setDZUserRegistrationDataList([]);
            setDZUserRegistrationResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  // AM0205
  const getCustomerDetailListAPI = () => {
    return api
      .getMasterCustomerDetailListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get master customer ", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response && response.length > 0) {
            newList = setListId(response);
            setCustomerMasterDataList(newList);
            setCustomerMasterResultList(newList);
          }
        } else {
          if (code === 404) {
            setCustomerMasterDataList([]);
            setCustomerMasterResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  // AM0305
  const getItemDetailListAPI = () => {
    let masterItemList: any = [];
    let itemListApi;
    itemListApi = api
      .getItemDetailListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AM0305 item master", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response && response.length > 0) {
            newList = setListId(response);
            masterItemList = response;
            setItemMasterDataList(newList);
            setItemMasterResultList(newList);
          }
        } else {
          if (code === 404) {
            setItemMasterDataList([]);
            setItemMasterResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });

    Promise.all([itemListApi]).then(() => {
      setCommonInfoFull({
        itemList: masterItemList,
      });
      apiLoading(false);
    });
  };

  const getRoleListAPI = () => {
    return api
      .getRoleListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("getRoleListAPI", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          let newCheckList: any = {};
          if (response && response.length > 0) {
            newList = setListId(response);
            newList.forEach((item: any) => {
              newCheckList["G" + item.role_group + "ID" + item.role_id] = false;
            });
            console.log("roleDataList", newList);
            setRoleDataList(newList);
            setRoleResultList(newList);
            setRoleDataCheckedList(newCheckList);
            setRoleDataCheckedListDefault(newCheckList);
          }
        } else {
          if (code === 404) {
            setRoleDataList([]);
            setRoleResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  const getServiceCenterDetailListAPI = () => {
    return api
      .getServiceCenterDetailListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response && response.length > 0) {
            newList = setListId(response);
            // Service Center
            setServiceCenterDataList(newList);
            setServiceCenterResultList(newList);
            // Repair Destinat
            setRepairDestinationDataList(newList);
            setRepairDestinationResultList(newList);
          }
        } else {
          if (code === 404) {
            // Service Center
            setServiceCenterDataList([]);
            setServiceCenterResultList([]);
            // Repair Destinat
            setRepairDestinationDataList([]);
            setRepairDestinationResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  //
  const getLaborMasterListAPI = () => {
    return api
      .getReadClaimLaborListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get Claim Labor", response);
        if (getApiResultStatus(code)) {
          let newList = [];
          if (response && response.length > 0) {
            newList = setListId(response);
            // Labor master
            setLaborMasterDataList(newList);
            setLaborMasterResultList(newList);
          }
        } else {
          if (code === 404) {
            setLaborMasterDataList([]);
            setLaborMasterResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  const getMasterMaintenanceSelect = (): string[] => {
    let str = [] as any;
    if (history.location.search) {
      const masterMaintenanceStr = history.location.search.split("&");
      const selectorvalue = masterMaintenanceStr[0].replace(
        "?selectorvalue=",
        ""
      );
      str.push(selectorvalue);
      const mailaddress = masterMaintenanceStr[1].replace("mailaddress=", "");
      str.push(mailaddress);
    }
    return str;
  };

  const getDefultValue = (userPermisssionInfo: any) => {
    // AM0107
    // user master
    // Print Setting
    // user role
    let masterItemList: any = [];
    let mastercustomerList: any = [];

    let api1: any;
    if (
      userPermisssionInfo.user_master_consoleFlg ||
      userPermisssionInfo.printer_masterFlg ||
      userPermisssionInfo.role_masterFlg
    ) {
      api1 = getUserInfoDetailListAPI();
    }

    // AM0107
    // DZ info master
    let api2: any;
    if (userPermisssionInfo.user_master_DZFlg) {
      api2 = getDzinfoDetailListAPI();
    }

    // customer master
    // AM0205
    let api3: any;
    let api18: any;
    if (
      userPermisssionInfo.user_master_consoleFlg ||
      userPermisssionInfo.user_master_DZFlg ||
      userPermisssionInfo.customer_memorandum_masterFlg
    ) {
      api3 = api
        .getMasterCustomerDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("get master customer", response);
          if (getApiResultStatus(code)) {
            let newList: any = [];
            if (response && response.length > 0) {
              newList = setListId(response);
              const credit_note_interval_list = credit_note_interval.com(
                "wco_credit_note_interval"
              );
              if (newList.length > 0) {
                newList.forEach((item: any) => {
                  if (!item.asc) {
                    item.pic_sort = "";
                    item.app_sort = "";
                    //  item.inr_sort = "";
                    item.credit_note_interval_message = "";
                  } else {
                    item.pic_sort = item.pic ? "Y" : "N";
                    item.app_sort = item.app ? "Y" : "N";
                    //item.inr_sort = item.inr? "Y" : "N";
                    item.credit_note_interval_message = "";
                    if (credit_note_interval_list.length > 0) {
                      let credit_note_interval_item =
                        credit_note_interval_list.filter(
                          (subItem: any) =>
                            Number(subItem.code) === Number(item.inr)
                        );
                      if (credit_note_interval_item.length > 0) {
                        item.credit_note_interval_message =
                          credit_note_interval_item[0].name;
                      }
                    }
                  }
                });
              }
              setCustomerMasterDataList(newList);
              setCustomerMasterResultList(newList);
            }
          } else {
            if (code === 404) {
              setCustomerMasterDataList([]);
              setCustomerMasterResultList([]);
            } else {
              errorMessage(response);
            }
          }
        });

      api18 = api
        .getCustomerDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("get AM0205 customer master", response);
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              mastercustomerList = response;
            }
          } else {
            if (code !== 404) {
              errorMessage(response);
            }
          }
        });
    }

    // item master
    // AM0305
    let api4: any;
    if (
      userPermisssionInfo.item_master ||
      userPermisssionInfo.wco_labor_master_authFlg
    ) {
      // api4 = getItemDetailListAPI();
      api4 = api
        .getItemDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("get AM0305 item master", response);
          if (getApiResultStatus(code)) {
            let newList: any = [];
            if (response && response.length > 0) {
              newList = setListId(response);
              masterItemList = response;
              setItemMasterDataList(newList);
              setItemMasterResultList(newList);
            }
          } else {
            if (code === 404) {
              setItemMasterDataList([]);
              setItemMasterResultList([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // role
    let api5: any;
    if (userPermisssionInfo.role_masterFlg) {
      api5 = getRoleListAPI();
    }

    // Service Center
    // Repair Destinat
    let api6: any;
    if (
      userPermisssionInfo.user_master_consoleFlg ||
      userPermisssionInfo.item_master ||
      userPermisssionInfo.service_senter_masterFlg ||
      userPermisssionInfo.printer_masterFlg ||
      userPermisssionInfo.repair_destination_masterFlg
    ) {
      api6 = getServiceCenterDetailListAPI();
    }

    // Affiliate Code data
    let api7: any;
    if (
      userPermisssionInfo.user_master_consoleFlg ||
      userPermisssionInfo.user_master_DZFlg
    ) {
      api7 = api
        .getAffiliateCodeListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setAffiliateCd(response);
            }
          } else {
            if (code === 404) {
              setAffiliateCd([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // Primary locale
    const primaryLocalseList: any = ISO6391.getAllCodes();
    let needSetprimaryLocale: any = [];
    if (primaryLocalseList.length > 0) {
      primaryLocalseList.forEach((item: any) => {
        if (primaryLocales.includes(item.toLocaleUpperCase())) {
          needSetprimaryLocale.push(item);
        }
      });
      if (needSetprimaryLocale.length > 0) {
        setPrimaryLocale(needSetprimaryLocale);
      } else {
        setPrimaryLocale([]);
      }
    }



    // AM1801
    let api10: any;
    if (userPermisssionInfo.printer_masterFlg) {
      api10 = api
        .getDocumentFormListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setDocumentFormData(response);
            }
          } else {
            if (code === 404) {
              setDocumentFormData([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // AM1005
    let api11: any;
    if (userPermisssionInfo.printer_masterFlg) {
      api11 = api
        .getPrinterDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setPrinterdata(response);
            }
          } else {
            if (code === 404) {
              setPrinterdata([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // country pull down
    const countriesListObj: any = countries.getAlpha2Codes();
    const countriesListArray: any = getCountriesListArray(countriesListObj);
    setCountriesList(countriesListArray);

    // AM2001
    let api12: any;
    if (userPermisssionInfo.role_masterFlg) {
      api12 = api
        .getRoleGroupListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setRoleGroup(response);
            }
          } else {
            if (code === 404) {
              setRoleGroup([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // AM1901
    let api13: any;
    if (userPermisssionInfo.role_masterFlg) {
      api13 = api
        .getPermissionListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            let newList: any = [];
            let checkList: any = {};
            if (response && response.length > 0) {
              newList = setListId(response);
              newList.forEach((item: any) => {
                checkList[
                  "G" + item.permission_group + "ID" + item.permission_id
                ] = false;
              });
              setPermissionCheckedList(checkList);
              setPermissionCheckedListDefault(checkList);
              setPermissionList(newList);
            }
          } else {
            if (code === 404) {
              setPermissionList([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // AM0405
    let api14: any;
    if (userPermisssionInfo.user_master_consoleFlg) {
      api14 = api
        .getERPUserDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setERPUserDetailList(response);
            }
          } else {
            if (code === 404) {
              setERPUserDetailList([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // AM1701
    let api15: any;
    if (userPermisssionInfo.repair_destination_masterFlg) {
      api15 = api
        .getPostalCodeListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setRegionData(response);
            }
          } else {
            if (code === 404) {
              setRegionData([]);
            } else {
              errorMessage(response);
            }
          }
        });
    }

    // AM0116
    // DZ User Registration
    let api16: any;
    if (userPermisssionInfo.user_master_DZFlg) {
      api16 = getDZUserRegistrationListAPI();
    }

    // Labor Master
    let api17: any;
    if (userPermisssionInfo.wco_labor_master_authFlg) {
      api17 = getLaborMasterListAPI();
    }

    Promise.all([
      api1,
      api2,
      api3,
      api4,
      api5,
      api6,
      api7,
      api10,
      api11,
      api12,
      api13,
      api14,
      api15,
      api16,
      api17,
      api18,
    ]).then(() => {
      setCommonInfoFull({
        customerList: mastercustomerList,
        itemList: masterItemList,
        loadingFlg: false,
      });
      apiLoading(false);
    });
  };

  const setPermissionValue = (list: any) => {
    setPermissionCheckedList(list);
  };

  const setRoleDataListValue = (list: any) => {
    setRoleDataCheckedList(list);
  };

  const useMasterUpload = (file: any) => { };

  // common search method
  const dzUserInfoSearch = (data: any, query_parameters: string) => {
    setDzUserInfoStartFlg(false);
    setAlertMessageList([])
    setDzUserInfoAlertMessageList([])
    api
      .getUserinfoDetailListAPIByDz(config, query_parameters)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AM0107 DZInfo", response);
        if (getApiResultStatus(code)) {
          let newList = [];
          if (response && response.length > 0) {
            // ファイル軽量化のため、パラメータ名前が短くになりました。
            response = renameResponseDataArray(userInfoListRename, response);
            newList = setListId(response);
            // DZ userInfo
            setDZUserInfoDataList(newList);
            setDZUserInfoResultList(newList);
            if (response.length >= 4000) {
              const obj: any = {
                message: com("messages.MS0118"),
                alertType: "warning",
              };
              let alertMessageListNew: any = [obj];
              setDzUserInfoAlertMessageList(alertMessageListNew);
              setAlertMessageList(alertMessageListNew)

            }
          }
        } else {
          if (code === 404) {
            const obj: any = {
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setDzUserInfoAlertMessageList(alertMessageListNew);
            setAlertMessageList(alertMessageListNew)
            setDZUserInfoDataList([]);
            setDZUserInfoResultList([]);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  const contents = (
    <div>
      <Prompt
        message={(location) => {
          if (editStatus !== "edit") {
            return true;
          }
          setPath(location.pathname);
          setOpen(true);
          return false;
        }}
      />
      {alertMessageList.length > 0 && (
        <AlertMesssageBox
          key={alertMessageList[alertMessageList.length - 1].no}
          show={true}
          message={alertMessageList[alertMessageList.length - 1].message}
          type={alertMessageList[alertMessageList.length - 1].alertType}
        />
      )}
      <div className={changeTabInformation(editStatus !== "edit")}>
        <div className={classes.controlArea}>
          {console.log("selectorList", selectorList)}
          <MasterMaintenanceSelector
            selectorValue={selectorValue}
            selectorList={selectorList}
            handleChange={handleChange}
          />
        </div>
        {/* UserMaster tab */}
        {userPermisssionInfo.user_master_consoleFlg && (
          <div className={changeSelecter("UserMaster")}>
            <MasterMaintenanceUserMaster
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              useMasterResultList={useMasterResultList}
              handleDelete={handleDelete}
              handleRegister={handleRegister}
              useMasterUpload={useMasterUpload}
              config={config}
              api={api}
              getUserInfoDetailListAPI={getUserInfoDetailListAPI}
              getDzinfoDetailListAPI={getDzinfoDetailListAPI}
              serviceCenterDataList={serviceCenterDataList}
              customerMasterData={customerMasterDataList}
              userPermisssionInfo={userPermisssionInfo}
              userRepairDisabledFlag={userRepairDisabledFlag}
              downloadUserMasterInfoCSV={downloadUserMasterInfoCSV}
            />
          </div>
        )}
        {/* DZUserInfo tab */}
        {userPermisssionInfo.user_master_DZFlg && (
          <div className={changeSelecter("DZUserInfo")}>
            <MasterMaintenanceDZUserInfo
              dZUserInfoData={dZUserInfoDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              dZUserInfoResultList={dZUserInfoResultList}
              handleDelete={handleDelete}
              handleRegister={handleRegister}
              config={config}
              api={api}
              getDzinfoDetailListAPI={getDzinfoDetailListAPI}
              customerMasterData={customerMasterDataList}
              downloadDZUserInfoCSV={downloadDZUserInfoCSV}
              search={dzUserInfoSearch}
              setAlertMessageList={setAlertMessageList}
              dzUserInfoStartFlg={dzUserInfoStartFlg}
              setDzUserInfoStartFlg={setDzUserInfoStartFlg}
            />
          </div>
        )}
        {/* DZUserRegistration tab */}
        {userPermisssionInfo.user_master_DZFlg && (
          <div className={changeSelecter("DZUserRegistration")}>
            <MasterMaintenanceDZUserRegistration
              dZUserRegistrationDataList={dZUserRegistrationDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              dZUserRegistrationResultList={dZUserRegistrationResultList}
              handleRegister={handleRegister}
              config={config}
              api={api}
              getDZUserRegistrationListAPI={getDZUserRegistrationListAPI}
            />
          </div>
        )}
        {/* CustomerMaster tab */}
        {userPermisssionInfo.customer_memorandum_masterFlg && (
          <div className={changeSelecter("CustomerMaster")}>
            <MasterMaintenanceCustomerMaster
              customerMasterData={customerMasterDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              customerMasterResultList={customerMasterResultList}
            />
          </div>
        )}
        {/* ItemMaster tab */}
        {userPermisssionInfo.item_master && (
          <div className={changeSelecter("ItemMaster")}>
            <MasterMaintenanceItemMaster
              itemMasterData={itemMasterDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              itemMasterResultList={itemMasterResultList}
            />
          </div>
        )}
        {/* Role tab */}
        {userPermisssionInfo.role_masterFlg && (
          <div className={changeSelecter("Role")}>
            <MasterMaintenanceRole
              roleData={roleDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              roleResultList={roleResultList}
              handleDelete={handleDelete}
              handleRegister={handleRegister}
              roleGroup={roleGroup}
              roleGroupFlg={roleGroupFlg}
              setRoleGroupFlg={setRoleGroupFlg}
            />
          </div>
        )}
        {/* UserRole tab */}
        {userPermisssionInfo.role_masterFlg && (
          <div className={changeSelecter("UserRole")}>
            <MasterMaintenanceUserRole
              userRoleData={userRoleDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              userRoleResultList={userRoleResultList}
            />
          </div>
        )}
        {/* ServiceCenter tab */}
        {userPermisssionInfo.service_senter_masterFlg && (
          <div className={changeSelecter("ServiceCenter")}>
            <MasterMaintenanceServiceCenter
              serviceCenterData={serviceCenterDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              serviceCenterResultList={serviceCenterResultList}
              handleDelete={handleDelete}
              handleRegister={handleRegister}
            />
          </div>
        )}
        {/* PrintSetting tab */}
        {userPermisssionInfo.printer_masterFlg && (
          <div className={changeSelecter("PrintSetting")}>
            <MasterMaintenancePrintSetting
              printSettingData={printSettingDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              printSettingResultList={printSettingResultList}
            />
          </div>
        )}
        {/* RepairDestination tab */}
        {userPermisssionInfo.repair_destination_masterFlg && (
          <div className={changeSelecter("RepairDestination")}>
            <MasterMaintenanceRepairDestination
              repairDestinationData={repairDestinationDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              repairDestinationResultList={repairDestinationResultList}
            />
          </div>
        )}
        {/* LaborMaster tab */}
        {userPermisssionInfo.wco_labor_master_authFlg && (
          <div className={changeSelecter("LaborMaster")}>
            <MasterMaintenanceLaborMaster
              laborMasterDataList={laborMasterDataList}
              handleEdit={handleEdit}
              SearchResult={SearchResult}
              laborMasterResultList={laborMasterResultList}
              getLaborMasterListAPI={getLaborMasterListAPI}
              handleRegister={handleRegister}
              downloadCSV={downloadCSV}
              errorValueList={errorValueList}
              setErrorValueList={setErrorValueList}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div>
      {selectorValue === "UserMaster" && editStatus === "edit" && (
        <MasterMaintenanceUserMasterEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          affiliateCd={affiliateCd}
          primaryLocale={primaryLocale}
          serviceCenterDataList={serviceCenterDataList}
          ERPUserDetailList={ERPUserDetailList}
          useMasterList={useMasterList}
          customerMasterData={customerMasterDataList}
          alertEditMessageList={alertEditMessageList}
          setAlertEditMessageList={setAlertEditMessageList}
          handleResetMFA={handleResetMFA}
          apiLoading={apiLoading}
          config={config}
          getApiResultStatus={getApiResultStatus}
          userRepairDisabledFlag={userRepairDisabledFlag}
          editList={editList}
          setEditList={setEditList}
          telUsedCountry={telUsedCountry}
        />
      )}
      {selectorValue === "DZUserInfo" && editStatus === "edit" && (
        <MasterMaintenanceDZUserInfoEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          affiliateCd={affiliateCd}
          primaryLocale={primaryLocale}
          customerMasterData={customerMasterDataList}
          alertEditMessageList={alertEditMessageList}
          setAlertEditMessageList={setAlertEditMessageList}
          handleResetMFA={handleResetMFA}
          editList={editList}
          setEditList={setEditList}
          telUsedCountry={telUsedCountry}
        />
      )}
      {selectorValue === "DZUserRegistration" && editStatus === "edit" && (
        <MasterMaintenanceDZUserRegistrationEditor
          editData={editList}
          editList={editList}
          setEditList={setEditList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          affiliateCd={affiliateCd}
          primaryLocale={primaryLocale}
          customerMasterData={customerMasterDataList}
          telUsedCountry={telUsedCountry}
          alertEditMessageList={alertEditMessageList}
          setAlertEditMessageList={setAlertEditMessageList}
        />
      )}
      {selectorValue === "CustomerMaster" && editStatus === "edit" && (
        <MasterMaintenanceCustomerMasterEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
        />
      )}
      {selectorValue === "ItemMaster" && editStatus === "edit" && (
        <MasterMaintenanceItemMasterEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          serviceCenterDataList={serviceCenterDataList}
          itemServiceCenterCheckedList={itemServiceCenterCheckedList}
        />
      )}
      {selectorValue === "Role" && editStatus === "edit" && (
        <MasterMaintenanceRoleEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          roleGroup={roleGroup}
          permissionList={permissionList}
          permissionCheckedList={permissionCheckedList}
          setPermissionValue={setPermissionValue}
          apiLoading={apiLoading}
          GetPrinterDetailListAPI
          config={config}
          roleGroupFlg={roleGroupFlg}
          setRoleGroupFlg={setRoleGroupFlg}
        />
      )}
      {selectorValue === "UserRole" && editStatus === "edit" && (
        <MasterMaintenanceUserRoleEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          roleDataList={roleDataList}
          roleDataCheckedList={roleDataCheckedList}
          setRoleDataListValue={setRoleDataListValue}
          setRoleDataStartCheckedNumber={setRoleDataStartCheckedNumber}
          apiLoading={apiLoading}
          config={config}
        />
      )}
      {selectorValue === "ServiceCenter" && editStatus === "edit" && (
        <MasterMaintenanceServiceCenterEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          countriesList={countriesList}
        />
      )}
      {selectorValue === "PrintSetting" && editStatus === "edit" && (
        <MasterMaintenancePrintSettingEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          serviceCenterDataList={serviceCenterDataList}
          documentFormData={documentFormData}
          printerdata={printerdata}
          config={config}
        />
      )}
      {selectorValue === "RepairDestination" && editStatus === "edit" && (
        <MasterMaintenanceRepairDestinationEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          regionData={regionData}
          config={config}
        />
      )}
      {selectorValue === "LaborMaster" && editStatus === "edit" && (
        <MasterMaintenanceLaborMasterEditor
          editData={editList}
          changeData={changeData}
          handleBack={handleBack}
          config={config}
          errorMessage={errorMessage}
          getLaborMasterListAPI={getLaborMasterListAPI}
          setAlertMessageList={setAlertMessageList}
          successMessage={successMessage}
        />
      )}
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("messages.MS0010")}
      />
    </div>
  );
  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
}
