import React from "react";
// UI
import { useStyles } from "../hooks/useStyles";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export const WagonDZOverviewItem = (props: any) => {
  const classes = useStyles();
  const {
    list,
    subTitle,
    handleEditEvent,
    handleDeleteEvent,
    wangonNumber,
  } = props;

  if (list && list.length <= 0) {
    return null;
  }

  const itemList =
    list &&
    list.map((item: any, index: number) => (
      <tr key={index}>
        <td className={classes.no}>{index + 1}</td>
        <td>{item.job_no}</td>
        <td>{item.model_no}</td>
        <td className={classes.wagonDzTableButton}>
          <IconButton
            color="primary"
            onClick={() => handleEditEvent(item, subTitle, wangonNumber)}
          >
            <Edit />
          </IconButton>
        </td>
        <td className={classes.wagonDzTableButton}>
          <IconButton
            color="secondary"
            onClick={() => handleDeleteEvent(item, subTitle, wangonNumber)}
          >
            <DeleteIcon />
          </IconButton>
        </td>
      </tr>
    ));

  return <tbody className={classes.wagonDzTableTbody}>{itemList}</tbody>;
};
