import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailUpdateHistoryTable } from "./PromotionDetail.UpdateHistory.Table";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal"
      }
    }
  })
);

export const UpdateHistory = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>{t("update_history")}</h1>
      <PromotionDetailUpdateHistoryTable
        data={props.data}
      />
    </div>
  );
};
