import { RepairConfigurationModel } from "../models/RepairConfigurationModel";
import { http } from "./Http";

export const GetRepairConfiguration = (
  token: string,
  url: string
): Promise<RepairConfigurationModel> => {
  return http({
    token: token,
    url: process.env.REACT_APP_GLOBAL_CONFIGURATION_URI!,
  }).then((response) => {
    return response as RepairConfigurationModel;
  });
};
