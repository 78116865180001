import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& dl": {
        paddingLeft: "10px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
      },
      '& > svg': {
        margin: theme.spacing(2),
      },
      flexGrow: 1,
    },
    controlArea: {
      borderBottom: "1px solid #CFCFCF",
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    advancedButton: {
      paddingTop: "10px",
      "& button": {
        width: "140px",
        padding: "5px 0",
      },
    },
    searchControlSelect: {
      width: "140px",
    },
    buttonArea: {
      padding: "32px 0 0 10px",
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePickerArea: {
      width: "400px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePicker: {
      width: "47%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
    },
    datePickerInterval: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "4px",
      marginLeft: "-4px",
      borderTop: "1px solid #000",
      width: "5px",
      display: "block",
    },
    advancedHideFlg: {
      display: "none",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      height: 80,
      width: 174,
    },
    paper02: {
      height: 160,
      width: 356,
    },
    paper03: {
      height: 160,
      width: 356,
      background:"#039f54",
    },
    button: {
      height: 80,
      width: 174,
    },
    buttonToNextLocation: {
      height: 55,
      width: 230,
    },
    textFieldInput: {
      height: 133,
      width: 540,
      fontSize: "16px",
      "& .MuiFilledInput-root": {
        fontSize: "40px",
        height: 500
      }
    },
    fontCenter: {
      textAlign: "center",
      fontSize: "40px",
    },
    fontCenter02: {
      textAlign: "center",
      fontSize: "40px",
      color:"white",
    },
    control: {
      padding: theme.spacing(2),
    },
    width700: {
      width: 750,
    },
    divStyle:{
      height: 80,
      width: 356,
    },
    buttonText: {
      display: "block",
      paddingRight: "14px",
    },
    fontCenterModel: {
      color:"black",
      fontSize:"16px",
    },
    fontCenterModel02: {
      color:"white",
      fontSize:"16px",
    },
  })
);
