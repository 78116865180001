import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "Type", label: "media_type" },
  { id: "FileName", label: "media_name" },
  { id: "UploadedDate", label: "created_date" },
  { id: "Download", label: "download" },
  { id: "Delete", label: "delete" },
];

export const JobsDetailGeneralRepairMediaThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.group_repair_media",
  });

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
