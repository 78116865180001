import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import api from "../api/Api";
import { useGlobalConfiguration } from "./GlobalConfiguration";
import { useGlobalUserInformation } from "./GlobalUserInformation";
import { useUserPermission } from "./UserPermission";
import { useCommonInfo } from "./CommonInfoProvider";
import { APIAudience } from "../constants/api.audience.constants";
import { useWebMSIConfiguration } from "./WebMSIConfiguration";
import { AUTH0_AUDIENCES } from "../constants/api.audience.constants";
import { respiarShowList } from "../constants/common.constants";

interface HttpContextInterface {
  // Dashboard
  getDashboardMessageList: () => Promise<Response>;
  postDashboardMessage: (body: any) => Promise<Response>;
  getUserPermissionsRe: () => Promise<Response>;
}

const stub = (): never => {
  throw new Error("This method is not implemented");
};

const initialConfigurationContext = {
  // For Dashboard
  getDashboardMessageList: stub,
  postDashboardMessage: stub,
  getUserPermissionsRe: stub,
};

const SecureHttpRequestContext = createContext<HttpContextInterface>(
  initialConfigurationContext
);
export const useSecureHttpRequest = () => useContext(SecureHttpRequestContext);

interface Props {
  children: any;
}

export const SecureHttpRequestProvider: FC<Props> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { getGlobalConfiguration } = useGlobalConfiguration();
  const { getGlobalUserInformation } = useGlobalUserInformation();
  const { getUserPermissions } = useUserPermission();
  const { setCommonConfig, setUserPermissions, setLoggerManage } =
    useCommonInfo();
  const { getWebMSIConfiguration } = useWebMSIConfiguration();

  // Wrapper for Auth0 Access Token
  const tokenHandler = (
    func: any,
    apiAudience: APIAudience,
    ...params: any[]
  ): Promise<any> => {
    if (isAuthenticated) {
      var audience =
        apiAudience === AUTH0_AUDIENCES.WEBMSI
          ? process.env.REACT_APP_AUTH0_WEBMSI_API_AUDIENCE!
          : process.env.REACT_APP_AUTH0_MAKMATE_API_AUDIENCE!;
      var scope =
        apiAudience === AUTH0_AUDIENCES.WEBMSI
          ? process.env.REACT_APP_AUTH0_WEBMSI_API_SCOPE!
          : process.env.REACT_APP_AUTH0_MAKMATE_API_SCOPE!;

      return getAccessToken(audience, scope).then((token) => {
        return func(token, ...params).then((res: any) => {
          return {
            response: res,
            token: token,
            getAccessTokenSilently: getAccessTokenSilently,
          };
        });
      });
    }
    throw new Error("Not Authenticated");
  };

  const getAccessToken = (
    audience: APIAudience,
    scope: string
  ): Promise<any> => {
    // @ts-ignore
    if (window.Cypress) {
      const itemKey = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`;
      const auth0 = JSON.parse(window.localStorage.getItem(itemKey)!);
      console.log(auth0);
      return Promise.resolve(auth0.body.access_token);
    } else {
      return getAccessTokenSilently({
        audience: audience,
        scope: scope,
      });
    }
  };

  const apiCallHandler = (
    func: any,
    attr = "web_console_endpoint",
    ...params: any[]
  ): Promise<any> => {
    // Make sure all configurations were loaded
    // Loading Global Config API
    return tokenHandler(getGlobalConfiguration, AUTH0_AUDIENCES.MAKMATE).then(
      ({ response }: { response: any }) => {
        // Loading Global User Information
        return tokenHandler(
          getGlobalUserInformation,
          AUTH0_AUDIENCES.MAKMATE,
          response.endpoint
        ).then(
          ({
            response,
            token,
            getAccessTokenSilently,
          }: {
            response: any;
            token: string;
            getAccessTokenSilently: any;
          }) => {
            console.log("config setting", response);
            // config.repairShowFlag Repair部分が表示・非表示用フラグ
            let config = Object.assign(
              {},
              response,
              { token: token, getAccessTokenSilently: getAccessTokenSilently },
              {
                repairShowFlag: respiarShowList.includes(response.affiliate_cd),
                baseURL:
                  response.system_attribute.web_console_endpoint.replace(
                    "/repair",
                    ""
                  ) + process.env.REACT_APP_REPAIR_PATH,
                webMSIURL: response.system_attribute.web_msi_endpoint,
                baseURLShop:
                  response.system_attribute.web_console_endpoint.replace(
                    "/repair",
                    ""
                  ) + process.env.REACT_APP_WEBSHOP_PATH,
                baseURLClaim:
                  response.system_attribute.web_console_endpoint.replace(
                    "/repair",
                    ""
                  ) + process.env.REACT_APP_CLAIM_PATH,
              }
            );
            return tokenHandler(
              getWebMSIConfiguration,
              AUTH0_AUDIENCES.WEBMSI,
              response.system_attribute.web_msi_endpoint
            ).then((_response: any) => {
              console.log(
                "WEBMSI response",
                _response,
                _response.response.contentsUrl,
                _response.response.contents_url
              );
              if (_response.response.contents_url) {
                config = Object.assign(config, {
                  webMSIContentsURL: _response.response.contents_url,
                });
              } else {
                config = Object.assign(config, {
                  webMSIContentsURL: "",
                });
              }
              console.log("WEBMSI config", config);
              setCommonConfig(config);
              // Call local
              return tokenHandler(
                func,
                AUTH0_AUDIENCES.MAKMATE,
                response.system_attribute[attr],
                params
              );
            });
          }
        );
      }
    );
  };

  // Actual Methods
  const httpRequestContext: HttpContextInterface = {
    getDashboardMessageList: (): Promise<any> => {
      return apiCallHandler(api.getDashboardMessageList);
    },
    postDashboardMessage: (body: any): Promise<any> => {
      return apiCallHandler(api.postDashboardMessage, body);
    },
    getUserPermissionsRe: (): Promise<any> => {
      return apiCallHandler(getUserPermissions);
    },
  };

  // Initialize
  useEffect(() => {
    apiCallHandler(getUserPermissions).then((res) => {
      setUserPermissions(res.response);
    });
    // login date timeをログに記録
    setLoggerManage("loginDateTime", new Date().toUTCString());
  }, []);

  return (
    <SecureHttpRequestContext.Provider value={{ ...httpRequestContext }}>
      {children}
    </SecureHttpRequestContext.Provider>
  );
};

const test = (_token: string) => {
  //    console.log(token);
  Promise.resolve(console.log("calling"));
};
