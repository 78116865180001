import React, { useState, useEffect } from "react";
// component
import { PromotionDetailUpdateHistoryTableThead } from "./PromotionDetail.UpdateHistory.Table.Thead";
import { PromotionDetailUpdateHistoryTableTbody } from "./PromotionDetail.UpdateHistory.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promotionContainer: {
      maxWidth: "970px",
      width: "70%",
      maxHeight: "250px",
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      borderBottom: "1px solid rgba(198, 209, 221, 0.5)",
    }
  })
);

export const PromotionDetailUpdateHistoryTable = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.promotionContainer}>
        <Table stickyHeader>
          <PromotionDetailUpdateHistoryTableThead />
          {(props.data.updated_history && props.data.updated_history.length) > 0 && <PromotionDetailUpdateHistoryTableTbody
            data={props.data}
          />}
        </Table>
      </TableContainer>
    </>
  );
};
