import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeadCell {
  id: string;
  label: string;
  hiddenSortIcon: boolean;
}

const headCells: HeadCell[] = [
  { id: "credit_note_no", label: "credit_note_no", hiddenSortIcon: false },
  { id: "customer_no", label: "customer_no", hiddenSortIcon: false },
  { id: "customer_name", label: "customer_name", hiddenSortIcon: false },
  { id: "customer_po_no", label: "customer_po_no", hiddenSortIcon: false },
  { id: "ref_no", label: "ref_no", hiddenSortIcon: false },

  { id: "contents_message", label: "claim_no", hiddenSortIcon: true },
  { id: "issue_date", label: "issue_date", hiddenSortIcon: false },
  { id: "total_amount_without_tax", label: "total_amount", hiddenSortIcon: false },
  { id: "media_name", label: "document", hiddenSortIcon: false },
];

export const SearchResultTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.ap" });

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy.indexOf(headCell.id) > -1 ? order : false}
            align="center"
          >
            <TableSortLabel
              active={
                !headCell.hiddenSortIcon && orderBy.indexOf(headCell.id) > -1
              }
              direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hiddenSortIcon}
            >
              {t(headCell.label)}
              {orderBy.indexOf(headCell.id) > -1 ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
