import { DashboardMessageListModel, DashboardMessageModel } from "../models/DashboardMessageListModel";
import { http } from "./Http";
import { methods } from "../constants/common.constants";

const ENDPOINT = "v1/dashboard_messages";

// AM1401
export const PostDashboardMessageAPI = (
  body: any,
  config: any
): Promise<void> => {
  const { affiliate_cd, baseURL, token } = config;
  return http({
    token: token,
    url: `${baseURL}/${ENDPOINT}/${affiliate_cd}`,
    method: methods.post,
    data: body,
  });
};


// AM1402
export const GetDashboardMessageAPI = (
message_type: number,
permission_group: number,
config: any
): Promise<DashboardMessageModel> => {
  const { affiliate_cd, baseURL, token } = config;
  return http({
    token: token,
    url: `${baseURL}/${ENDPOINT}/${affiliate_cd}/${message_type}/${permission_group}`,
  });
};

// AM1403
export const UpdateDashboardMessageAPI = (
  dashboardMessage: DashboardMessageModel,
  config: any
): Promise<void> => {
  const { affiliate_cd, baseURL, token } = config;
  const { message_type, permission_group, message, updated_date } = dashboardMessage;
  return http({
    token: token,
    url: `${baseURL}/${ENDPOINT}/${affiliate_cd}/${message_type}/${permission_group}`,
    method: methods.patch,
    data: {
      message: message,
      updated_date: updated_date,
    },
  });
};

// AM1404
export const DeleteDashboardMessageAPI = (
  data: any,
  config: any
): Promise<void> => {
  const { affiliate_cd, baseURL, token } = config;
  const { message_type, permission_group } = data;
  return http({
    token: token,
    url: `${baseURL}/${ENDPOINT}/${affiliate_cd}/${message_type}/${permission_group}`,
    method: methods.delete,
    data: data,
  });
};

// AM1405
export const GetDashboardMessageListAPI = (
  config: any
) => {
  const { affiliate_cd, baseURL, token } = config;
  return http({
    token: token,
    url: `${baseURL}/${ENDPOINT}/${affiliate_cd}`,
  });
};