import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { ReceivingForm } from "./components/Receiving.Form";
import { WagonDZOverview } from "./components/Receiving.WagonDZOverview";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// api
import api from "../../modules/api/Api";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// methods
import {
  getApiResultStatus,
  downloadToImage,
} from "../../modules/common.methods";
import {
  getJonNoInfo,
  getReceiveParams,
  getWagonList,
  getDeleteData,
  getRepairNoteDefaultData,
  getFormDefaultData,
  getResponseData,
  getRepairListByStatus,
  getJobTypeByNumber,
  getMediaByNumber,
  getNoteByNumber,
  getJobTypeByBoolean,
  getDiffContents,
} from "./Receiving.Method";
import {
  isEmptyStringOrNumber,
  checkLength,
  checkMinLength,
} from "../../modules/hooks/UseValication";
import { MessageBox } from "../../modules/components/messageBox/MessageBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: "100%",
      padding: theme.spacing(1),
    },
    control: {
      padding: theme.spacing(2),
    },
  })
);

export const Page: FC = () => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.receiving" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  // list
  const [dzListFlg, setDzListFlg] = useState(true);
  const [dzList, setDzList] = useState(getWagonList());
  const [ndzList, setNDzList] = useState(getWagonList());
  const [responseData, setResponseData] = useState(getResponseData());
  // from
  const [formData, setFormData] = useState(getFormDefaultData());
  const [jobNoButtonDisableFlg, setJobNoButtonDisableFlg] = useState(false);
  const [jobNoInputDisableFlg, setJobNoInputDisableFlg] = useState(false);
  const [jobNoErrorFlg, setJobNoErrorFlg] = useState(false);
  const [jobNoErrorMessage, setJobNoErrorMessage] = useState("");
  const [wagonNoErrorFlg, setWagonNoErrorFlg] = useState(false);
  const [wagonNoErrorMessage, setWagonNoErrorMessage] = useState("");
  const [receiveButtonDisabledFlg, setReceiveButtonDisabledFlg] = useState(
    true
  );
  const [repairNote, setRepairNote] = useState(getRepairNoteDefaultData());
  const [repairMedia, setRepairMedia] = useState([]);

  // loading
  const { setCommonInfo, getCommonConfig, getUserInfoRepair, } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  let userInfoRepair = getUserInfoRepair() as any;

  const changeData = (project: string, data: any, type: any = "") => {
    if (type === "repair_note") {
      const newData = Object.assign({}, repairNote, { [project]: data });
      setRepairNote(newData);
    } else {
      const newData = Object.assign({}, formData, { [project]: data });
      console.log("newData", newData);
      setFormData(newData);
    }
  };

  const getWagonNumber = (type: any) => {
    const title = type ? dzList.title : ndzList.title;
    console.log("dzListFlg", dzListFlg, title);
    return title;
  };

  const clearValue = (location_no: any = "") => {
    let newFormData;
    if (location_no) {
      newFormData = Object.assign({}, getFormDefaultData(), {
        location_no: location_no,
      });
    } else {
      setAlertShow(false);
      newFormData = Object.assign({}, getFormDefaultData());
    }
    setFormData(newFormData);
    setRepairNote(getRepairNoteDefaultData());
    setRepairMedia([]);
    setDzListFlg(true);
    if (jobNoButtonDisableFlg) {
      setJobNoButtonDisableFlg(false);
    }
    if (jobNoInputDisableFlg) {
      setJobNoInputDisableFlg(false);
    }
  };

  const handleEditEvent = (item: any, subTitle: any, location_no: any) => {
    const jobType = subTitle === "DZ";
    console.log("handleEditEvent", item, subTitle, location_no, jobType);
    apiLoading(true);
    // AT0102
    getJonNoMessage(item.job_no).then((response: any) => {
      console.log("getJonNoMessage", response);
      apiLoading(false);
      if (response) {
        setResponseData(response);
        setJobNoButtonDisableFlg(true);
        setJobNoInputDisableFlg(true);
        setDzListFlg(jobType);
        setJobNoErrorFlg(false);
        setJobNoErrorMessage("");
        setWagonNoErrorFlg(false);
        setWagonNoErrorMessage("");
        const newData = Object.assign({}, getFormDefaultData(), response);
        setFormData(newData);
        const repairMediaList = response.repair_media.filter((item: any) =>
          getMediaByNumber(item.media_type)
        );
        const repairNoteList = response.repair_note.filter((item: any) =>
          getNoteByNumber(item.note_type)
        );
        if (repairMediaList.length > 0) {
          setRepairMedia(repairMediaList);
        } else {
          setRepairMedia([]);
        }
        if (repairNoteList.length > 0) {
          setRepairNote(repairNoteList[0]);
        } else {
          setRepairNote(getRepairNoteDefaultData());
        }
      }
    });
  };

  const handleDeleteEvent = (item: any, subTitle: any, location_no: any) => {
    apiLoading(true);
    const jobType = getJobTypeByBoolean(subTitle);
    const params = {
      updated_date: item.updated_date,
    };
    // AT0119
    api
      .deleteReceivedJob(item.job_no, params, config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          clearValue();
          getList(location_no, jobType);
          successMessage("delete");
        }
      });
  };

  // error message
  const errorMessage = (response: any) => {
    console.log("errorMessage", response.key, response.message_id);
    setAlertShow(true);
    const message = com(response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  const successMessage = (type: any) => {
    let message = "";
    switch (type) {
      case "delete":
        message = com("MS0014");
        break;
      case "post":
        message = com("MS0015");
        break;
      default:
        message = com("MS0015");
        break;
    }
    setAlertShow(true);
    setAlertMessage(message);
    setAlertType("success");
  };

  const fileUploadSuccess = (response: any, file: any) => {
    const photoInfo: any = {
      media_name: encodeURIComponent(file.name),
      media_url: response.content,
      uid: response.uid,
      media_type: 2,
    };

    const newData: any = repairMedia.slice(0);
    newData.push(photoInfo);
    console.log("fileUploadSuccess", newData);
    setRepairMedia(newData);
    apiLoading(false);
  };

  const photoDeleteHandler = (item: any, index: any) => {
    console.log("photoDeleteHandler", item, index);

    if (item.updated_date) {
      const params = {
        updated_date: item.updated_date,
      };
      api
        .deleteRepairMediaAPI(formData.job_no, item.sub_no, params, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("deleteRepairMediaAPI", response);
          if (getApiResultStatus(code)) {
            successMessage("delete");
            photoDelete(index);
          } else {
            errorMessage(response);
          }
        });
    } else {
      photoDelete(index);
    }
  };

  const photoDelete = (index: any) => {
    console.log("photoDelete", index, repairMedia);
    const newData: any = repairMedia.slice(0);
    newData.splice(index, 1);
    setRepairMedia(newData);
  };

  const handlePrintEvent = () => {
    apiLoading(true);
    // print button click
    // AT0603
    api
      .getReceiptLabelPrintAPI(formData.job_no, formData.labelsField, config)
      .then((response: any) => {
        console.log("getReceiptLabelPrintAPI", response);
        if (!getApiResultStatus(response)) {
          errorMessage(response);
        }
        apiLoading(false);
      });
  };

  // job no valication
  const jobNumberValicationCheck = () => {
    if (isEmptyStringOrNumber(formData.job_no)) {
      setJobNoErrorFlg(true);
      let message = com("MS0001", {
        param1: t("job_no"),
      });
      setJobNoErrorMessage(message);
    } else if (checkLength(formData.job_no, 8)) {
      setJobNoErrorFlg(true);
      let message = t("job_no_message");
      setJobNoErrorMessage(message);
    } else {
      apiLoading(true);
      // AT0102
      getJonNoMessage(formData.job_no).then((response: any) => {
        apiLoading(false);
        if (response) {
          if (getRepairListByStatus(response.status)) {
            console.log("getJonNoMessage response", response);
            setResponseData(response);
            if (!formData.jobNoFlg) {
              const jobType: boolean = getJobTypeByNumber(response.job_type);
              const newData = Object.assign({}, formData, {
                job_no: response.job_no,
                location_no: getWagonNumber(jobType),
                service_center_id: response.service_center_id,
                updated_date: response.updated_date,
              });
              console.log("jobType", jobType);
              setDzListFlg(jobType);
              setFormData(newData);
            }
            setJobNoErrorFlg(false);
            setJobNoErrorMessage("");
          } else {
            setJobNoErrorFlg(true);
            const message = com("MS0016", {
              jobNo: formData.job_no,
            });
            setJobNoErrorMessage(message);
          }
        }
      });
    }
  };

  const getJonNoMessage = (job_no: any) => {
    // AT0102
    return api
      .getReadRepairJobAPI(job_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("getReadRepairJobAPI", response);
        if (getApiResultStatus(code)) {
          return response;
        } else {
          setJobNoErrorFlg(false);
          setJobNoErrorMessage("");
          setWagonNoErrorFlg(false);
          setWagonNoErrorMessage("");
          errorMessage(response);
          return null;
        }
      });
  };

  const wagonNumberValicationCheck = () => {
    if (isEmptyStringOrNumber(formData.location_no)) {
      setWagonNoErrorFlg(true);
      const message1 = com("MS0001", {
        param1: t("location_no"),
        param2: 200,
      });
      setWagonNoErrorMessage(message1);
    } else {
      setWagonNoErrorFlg(false);
      setWagonNoErrorMessage("");
    }
  };

  const fileUpload = (files: any) => {
    // loading
    if (files && files.length > 0) {
      const file = files[0];
      apiLoading(true);
      // AT0401
      api
        .getUploadSignedUrl(file.name, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("getUploadSignedUrl", response);
          if (getApiResultStatus(code)) {
            const data = response;
            api.getUploadFile(response.content, file).then((response: any) => {
              // uid
              if (getApiResultStatus(response)) {
                console.log("getUploadFile", file);
                fileUploadSuccess(data, file);
              }
            });
          } else {
            errorMessage(response);
          }
        });
    }
  };

  async function handleReceiveEvent() {
    console.log("handleReceiveEvent", repairNote.updated_date);
    let apiFlg = true;
    apiLoading(true);
    if (repairMedia.length > 0) {
      for (let i = 0; i < repairMedia.length; i++) {
        const item: any = repairMedia[i];
        if (!item.updated_date) {
          const data = await photoUploadApi(item);

          if (data.code && !getApiResultStatus(data.code)) {
            apiLoading(false);
            apiFlg = false;
            errorMessage(data.response);
            break;
          }
        }
      }
    }

    console.log("receive repair_note", repairNote.updated_date);
    if (apiFlg && repairNote.note && !repairNote.updated_date) {
      const data = await memoUploadApi();
      if (!getApiResultStatus(data.code)) {
        apiLoading(false);
        apiFlg = false;
        errorMessage(data.response);
      }
    }

    if (apiFlg) {
      const jobType = dzListFlg ? 2 : 1;
      // diff contents
      const { flg, diffContentsData } = diffContentsByPatch();
      if (!flg) {
        if (apiFlg) {
          const data = await receivePatchApi(diffContentsData);
          if (!getApiResultStatus(data.code)) {
            apiLoading(false);
            errorMessage(data.response);
            apiFlg = false;
          } else {
            successMessage("patch");
            clearValue(formData.location_no);
          }
        }

        if (apiFlg) {
          getList(formData.location_no, jobType);
        }
      } else {
        clearValue(formData.location_no);
        apiLoading(false);
      }
    }
  }

  const diffContentsByPatch = () => {
    let flg = true;
    let newData: any = getDiffContents();
    // note
    if (responseData.repair_note.length > 0) {
      const repairNoteList: any = responseData.repair_note.filter((item: any) =>
        getNoteByNumber(item.note_type)
      );
      if (repairNoteList.length > 0) {
        if (repairNote.updated_date) {
          flg = repairNoteList[0].note === repairNote.note;
          newData.note = flg;
        }
      }
    }

    newData.tracking_no_pickup =
      responseData.tracking_no_pickup === formData.tracking_no_pickup;
    newData.accessory_attached =
      responseData.accessory_attached === formData.accessory_attached;

    if (flg) {
      // tracking_no_pickup
      flg = responseData.tracking_no_pickup === formData.tracking_no_pickup;
    }

    if (flg) {
      // accessory_attached
      flg = responseData.accessory_attached === formData.accessory_attached;
    }

    if (flg) {
      // location_no
      flg = responseData.location_no === formData.location_no;
    }
    return {
      flg: flg,
      diffContentsData: newData,
    };
  };

  async function getList(location_no: any, job_type: any) {
    const data: any = await getListApi(location_no, job_type);
    apiLoading(false);
    if (!getApiResultStatus(data.code)) {
      errorMessage(data.response);
      let clearData = {
        title: location_no,
        sub_no: "",
        list: [],
      };
      if (getJobTypeByNumber(job_type)) {
        setDzList(clearData);
      } else {
        setNDzList(clearData);
      }
    } else {
      const dataList = {
        title: location_no,
        sub_no: data.response.total_num,
        list: data.response.jobs_list.slice(0),
      };
      if (getJobTypeByNumber(job_type)) {
        setDzList(dataList);
      } else {
        setNDzList(dataList);
      }
    }
  }

  const getListApi = (location_no: any, job_type: any) => {
    const query_parameters = `location_no=${location_no}&&job_type=${job_type}&&status=300&&requester=receiving`;
    return api.getReadRepairJobListAPI(query_parameters, config);
  };

  const photoUploadApi = (data: any) => {
    // AT0109
    const params = {
      job_no:
        typeof formData.job_no === "number"
          ? formData.job_no
          : Number(formData.job_no),
      media_url: data.uid,
      media_type: 2,
      media_name: data.media_name,
      updated_date: data.updated_date,
    };
    return api.createRepairMediaAPI(params, config);
  };

  const memoUploadApi = () => {
    console.log("memo", repairNote);
    const params = {
      job_no:
        typeof formData.job_no === "number"
          ? formData.job_no
          : Number(formData.job_no),
      note_type: 10,
      note: repairNote.note,
      updated_date: repairNote.updated_date,
    };
    // AT0110
    return api.createRepairNoteAPI(params, config);
  };

  const receivePatchApi = (diffContentsData: any) => {
    const params = getReceiveParams(formData, repairNote, diffContentsData, userInfoRepair);
    // AT0103
    return api.updateRepairJobAPI(formData.job_no, params, config);
  };

  // job no button click event
  const handleRegisterJobEvent = () => {
    apiLoading(true);
    getJonNoInfo(config).then(
      ({ successFlg, data }: { successFlg: boolean; data: any }) => {
        if (successFlg) {
          const newData = Object.assign({}, formData, {
            job_no: data.job_no,
            updated_date: data.updated_date,
            location_no: getWagonNumber(false),
            jobNoFlg: true,
          });
          setDzListFlg(false);
          setJobNoButtonDisableFlg(true);
          setJobNoInputDisableFlg(true);
          setFormData(newData);
          setJobNoErrorFlg(false);
          setJobNoErrorMessage("");
        } else {
          errorMessage(data);
        }
        apiLoading(false);
      }
    );
  };

  useEffect(() => {
    if (
      jobNoErrorFlg ||
      wagonNoErrorFlg ||
      isEmptyStringOrNumber(formData.job_no) ||
      isEmptyStringOrNumber(formData.location_no)
    ) {
      if (!receiveButtonDisabledFlg) {
        setReceiveButtonDisabledFlg(true);
      }
    } else {
      if (receiveButtonDisabledFlg) {
        setReceiveButtonDisabledFlg(false);
      }
    }
  });

  const changeMessage = (data: any, type: any) => {
    const list = type === "DZ" ? dzList : ndzList;
    const newData = Object.assign({}, list, { title: data });
    type === "DZ" ? setDzList(newData) : setNDzList(newData);
  };

  const downloadPhoto = (item: any) => {
    apiLoading(true);
    // AT0402
    api
      .getDownloadSignedUrl(formData.job_no, item.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const url = response.content;
          console.log("url", url);
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              apiLoading(false);

              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                downloadToImage(response);
              }
            });
        }
      });
  };

  const handleNextWagonClick = (location_no: any, subTitle: any) => {
    apiLoading(true);
    console.log("handleNextWagonClick", subTitle);
    const jobType = getJobTypeByBoolean(subTitle);
    // AT0602
    api
      .getWagonLoadingListPrintAPI(location_no, jobType, config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          if (code === 404) {
            errorMessage({ message_id: "MA0010" });
          } else {
            errorMessage(response);
          }
        } else {
          subTitle === "DZ"
            ? setDzList(getWagonList())
            : setNDzList(getWagonList());
          clearValue();
        }
      });
  };

  const findCarInfor = (location_no: any, subTitle: any) => {
    location_no = location_no.trim();
    const jobType = getJobTypeByBoolean(subTitle);
    if (location_no) {
      getList(location_no, jobType);
      clearValue();
    } else {
      if (getJobTypeByNumber(jobType)) {
        setDzList(getWagonList());
        setAlertShow(false);
        setAlertMessage("");
      } else {
        setNDzList(getWagonList());
        setAlertShow(false);
        setAlertMessage("");
      }
    }
  };

  // message box
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(getDeleteData());
  const handleSend = () => {
    setOpen(false);
    handleDeleteEvent(
      deleteData.item,
      deleteData.subTitle,
      deleteData.wangonNumber
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  // delete button click event
  const handleDeleteConfirmEvent = (
    item: any,
    subTitle: any,
    wangonNumber: any
  ) => {
    setOpen(true);
    const deleteDataList = {
      item: item,
      subTitle: subTitle,
      wangonNumber: wangonNumber,
    };
    setDeleteData(deleteDataList);
  };

  const contents = (
    <div className={classes.root}>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <Grid container spacing={2}>
        <Grid item md={12} lg={4}>
          <Paper className={classes.paper}>
            <ReceivingForm
              jobNoErrorFlg={jobNoErrorFlg}
              jobNoErrorMessage={jobNoErrorMessage}
              formData={formData}
              changeData={changeData}
              jobNumberValicationCheck={jobNumberValicationCheck}
              handleRegisterJobEvent={handleRegisterJobEvent}
              jobNoInputDisableFlg={jobNoInputDisableFlg}
              jobNoButtonDisableFlg={jobNoButtonDisableFlg}
              fileUpload={fileUpload}
              photoDelete={photoDeleteHandler}
              handlePrintEvent={handlePrintEvent}
              handleReceiveEvent={handleReceiveEvent}
              errorMessage={errorMessage}
              wagonNoErrorFlg={wagonNoErrorFlg}
              wagonNoErrorMessage={wagonNoErrorMessage}
              wagonNumberValicationCheck={wagonNumberValicationCheck}
              receiveButtonDisabledFlg={receiveButtonDisabledFlg}
              repairNote={repairNote}
              downloadPhoto={downloadPhoto}
              repairMedia={repairMedia}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className={classes.paper}>
            <WagonDZOverview
              subTitle="DZ"
              data={dzList}
              changeMessage={changeMessage}
              handleEditEvent={handleEditEvent}
              handleDeleteEvent={handleDeleteConfirmEvent}
              btnNextWagon={t("btn_NextWagon")}
              handleNextWagonClick={handleNextWagonClick}
              findCarInfor={findCarInfor}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className={classes.paper}>
            <WagonDZOverview
              subTitle="NDZ"
              data={ndzList}
              changeMessage={changeMessage}
              handleEditEvent={handleEditEvent}
              handleDeleteEvent={handleDeleteConfirmEvent}
              btnNextWagon={t("btn_NextWagon")}
              handleNextWagonClick={handleNextWagonClick}
              findCarInfor={findCarInfor}
            />
          </Paper>
        </Grid>
      </Grid>
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
