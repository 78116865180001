import { Button, Grid, GridSize, TextField } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import api from "../../modules/api/Api";
import { Alert } from "../../modules/components/alert/Alert";
import { HomeRepair } from "../../modules/components/home/Home.Repiar";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import { useLocale } from "../../modules/hooks/UseLocale";
import {
  DashboardMessageModel,
  DashboardMessageRequestModel,
  DashboardTitleModel,
  ErrorResponseModel,
  MessageType,
  PermissionGroup,
} from "../../modules/models/DashboardMessageListModel";
import { Buffer } from "buffer";
import { PermissionGroupModel } from "../../modules/models/PermissionGroupModel";
import { useStyles } from "./DashboardPage.style";
import Permissions from "../../modules/hooks/Permissions";
import { AlertBox } from "../../modules/components/alert/AlertBox";
import {
  IsNothingData,
  IsSuccessResponse,
  responseStatusCodes,
} from "../../modules/constants/common.constants";
import { AlertProps, AlertTitle } from "@material-ui/lab";
import { GetPrivateAppRoutePath, PathKey } from "../AppRoutes";
import { HomeNewMessage } from "../../modules/components/home/Home.NewMessage";
import { ModelWindow } from "../../modules/components/model/ModelWindow";
import { HomeDialog } from "../../modules/components/home/Home.Dialog";
import { UserPermissionModel } from "../../modules/models/UserPermissionModel";
import { GetDashboardMessageAPI } from "../../modules/api/Dashboard";
import { PermScanWifi } from "@material-ui/icons";

export const Page = () => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.configuration" });
  const { getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;
  const [permissionGroups, setPermissionGroups] = useState<
    PermissionGroupModel[]
  >([]);
  const [dashboardMessageList, setDashboardMessageList] = useState<
    DashboardMessageModel[]
  >([]);
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermissionInfo, setUserPermissionInfo] = useState({}) as any;
  const [responses, setResponses] = useState<ResponseAlertModel[]>([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(-1);
  const [titles, setTitles] = useState<DashboardTitleModel[]>([]);
  const [permissionInfo, setPermissionInfo] = useState([]) as any;
  // Dashboard show 条件
  const [dashboardShowRepairFlag, setDashboardShowRepairFlag] = useState(false);

  useEffect(() => {
    if (config.token) {
      init();
      load();
    }
  }, [config.token]);

  // init
  useEffect(() => {
    if (Object.keys(config)) {
      // config.repairShowFlag Repair部分が表示・非表示用フラグ
      setDashboardShowRepairFlag(config.repairShowFlag);
    }
  }, [config]);

  useEffect(() => {
    if (
      Object.keys(userPermissionInfo).length !== 0 &&
      permissionGroups.length > 0
    ) {
      loadTitles();
    }
  }, [dashboardMessageList, userPermissionInfo, permissionGroups]);

  /**
   * init is called once only when the page is loaded.
   */
  const init = () => {
    setPermission();
    api
      .getPermissionGroupListAPI(config)
      .then(
        ({
          code,
          response,
        }: {
          code: number;
          response: PermissionGroupModel[] | ErrorResponseModel;
        }) => {
          if (IsSuccessResponse(code)) {
            setPermissionGroups(response as PermissionGroupModel[]);
          } else {
            setResponses([
              ...responses,
              MakeResponse(code, (response as ErrorResponseModel).message_id),
            ]);
          }
        }
      );
  };

  /**
   * load is called when some contents is loaded.
   */
  const load = () => {
    api
      .getDashboardMessageList(config)
      .then(
        ({
          code,
          response,
        }: {
          code: number;
          response: DashboardMessageModel[] | ErrorResponseModel;
        }) => {
          if (IsSuccessResponse(code)) {
            setDashboardMessageList(
              MappingResponse(
                dashboardMessageList,
                response as DashboardMessageModel[],
                permissionGroups
              )
            );
          } else if (IsNothingData(code)) {
            setDashboardMessageList([]);
          } else {
            setResponses([
              ...responses,
              MakeResponse(code, (response as ErrorResponseModel).message_id),
            ]);
          }
        }
      );
  };

  /**
   * loadTitles loads all available dashboard titles which is shown at a selection of "Add Message".
   */
  const loadTitles = () => {
    let s: DashboardTitleModel[] = [];
    for (var i = 0; i < permissionGroups.length; i++) {
      for (let j = 0; j< permissionInfo.length; j++) {
        if (permissionGroups[i].permission_group === permissionInfo[j].permission_group) {
          if (permissionGroups[i].permission_group === 1 && getRepairPermission()) {
            s.push(
              MakeTitle(
                MessageType.SYSTEM,
                permissionGroups[i].permission_group,
                permissionGroups[i].permission_group_name,
                dashboardMessageList
              )
            );
            s.push(
              MakeTitle(
                MessageType.BUSINESS,
                permissionGroups[i].permission_group,
                permissionGroups[i].permission_group_name,
                dashboardMessageList
              )
            );
            break;
          }
          if (permissionGroups[i].permission_group === 2 && getCSDPermission()) {
            s.push(
              MakeTitle(
                MessageType.SYSTEM,
                permissionGroups[i].permission_group,
                permissionGroups[i].permission_group_name,
                dashboardMessageList
              )
            );
            s.push(
              MakeTitle(
                MessageType.BUSINESS,
                permissionGroups[i].permission_group,
                permissionGroups[i].permission_group_name,
                dashboardMessageList
              )
            );
            break;
          }
          if (permissionGroups[i].permission_group === 3 && getWCOPermission()) {
            s.push(
              MakeTitle(
                MessageType.SYSTEM,
                permissionGroups[i].permission_group,
                permissionGroups[i].permission_group_name,
                dashboardMessageList
              )
            );
            s.push(
              MakeTitle(
                MessageType.BUSINESS,
                permissionGroups[i].permission_group,
                permissionGroups[i].permission_group_name,
                dashboardMessageList
              )
            );
            break;
          }         
        }
      }
    }
    setTitles(s);
  };

  /**
   * setPermission set my permissions.
   */
  const setPermission = async () => {
    await getUserPermissionsRe().then((r: any) => {
      let p: UserPermissionModel[] = r.response as UserPermissionModel[];
      setPermissionInfo(p);
      permissionsInfomation.setPermissionsInfo(p);
      const tempUserPermissionsInfo = permissionsInfomation.getPermissionsInfo();
      setUserPermissionInfo(tempUserPermissionsInfo);
    });
  };

  /**
   * handleChange is called when a dashboard message is changed.
   * @param event detect target by id.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.id);
    setDashboardMessageList(
      dashboardMessageList.map((m, i) => {
        if (i === id) {
          m.message = event.target.value;
          m.is_edit = true;
        }
        return m;
      })
    );
  };

  /**
   * handleSave is called when a save button is clicked.
   * @param event detect target by id.
   */
  const handleSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // Save
    const id = parseInt(event.currentTarget.id);
    const data = dashboardMessageList[id];
    api
      .updateDashboardMessage(data, config)
      .then(
        ({
          code,
          response,
        }: {
          code: number;
          response?: ErrorResponseModel;
        }) => {
          setResponses([
            ...responses,
            MakeResponse(code, response?.message_id),
          ]);
          if (IsSuccessResponse(code)) {
            load();
          }
        }
      );
  };

  /**
   * handleDelete is called when a delete button is clicked.
   * Set the target to delete.
   * @param event detect target by id.
   */
  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const id = parseInt(event.currentTarget.id);
    setDeleteTargetId(id);
    setConfirmationOpen(true);
  };

  const deleteMessage = () => {
    const data = dashboardMessageList[deleteTargetId];
    api
      .deleteDashboardMessage(data, config)
      .then(
        ({
          code,
          response,
        }: {
          code: number;
          response?: ErrorResponseModel;
        }) => {
          setResponses([
            ...responses,
            MakeResponse(code, response?.message_id),
          ]);
          if (IsSuccessResponse(code)) {
            setDashboardMessageList(dashboardMessageList.filter(f => f.message_type !== data.message_type && f.permission_group !== data.permission_group));
            load();
          }
        }
      );
    setDeleteTargetId(-1);
    setConfirmationOpen(false);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
  };

  const addMessage = (message_type: number, permission_group: number) => {
    let body: DashboardMessageRequestModel = {
      message_type: message_type,
      permission_group: permission_group,
      message: t("txtInitMessage"),
    };
    api
      .postDashboardMessage(body, config)
      .then(
        ({
          code,
          response,
        }: {
          code: number;
          response?: ErrorResponseModel;
        }) => {
          setResponses([
            ...responses,
            MakeResponse(code, response?.message_id),
          ]);
          if (IsSuccessResponse(code)) {
            load();
          }
        }
      );
  };

  const getCount = (): GridSize  => {
    let smCount: GridSize = 12;
    let sum = 0;
    if (dashboardMessageList) {
      for (let i=0; i<dashboardMessageList.length; i++) {
        if (dashboardMessageList[i].message_type === MessageType.BUSINESS) {
          if((dashboardMessageList[i].permission_group === PermissionGroup.REPAIR && getRepairPermission()) || 
             (dashboardMessageList[i].permission_group === PermissionGroup.CSD && getCSDPermission()) || 
             (dashboardMessageList[i].permission_group === PermissionGroup.WCO && getWCOPermission())) {
            sum++;
         }
        }
      }
      if (sum === 2) {
        smCount = 6;
      } else if (sum === 3) {
        smCount = 4;
      }
    }
    return smCount;
  }

  const getRepairPermission = () => {
    let flg = false;
    if (dashboardShowRepairFlag && (
        userPermissionInfo.job_allocation_authFlg ||
        userPermissionInfo.job_register_authFlg ||
        userPermissionInfo.jobdetail_general_authFlg ||
        userPermissionInfo.jobdetail_parts_and_drawing_authFlg ||
        userPermissionInfo.customer_info_authFlg ||
        userPermissionInfo.delete_memo_authFlg ||
        userPermissionInfo.price_authFlg ||
        userPermissionInfo.delete_job_btn_authFlg || 
        userPermissionInfo.receiving_authFlg || 
        userPermissionInfo.location_control_authFlg)) {
          flg = true; 
    }
    return flg;
  };

  const getGoToRepairPermission = () => {
    let flg = false;
    if (userPermissionInfo.job_allocation_authFlg ||
        userPermissionInfo.job_register_authFlg ||
        userPermissionInfo.jobdetail_general_authFlg ||
        userPermissionInfo.jobdetail_parts_and_drawing_authFlg || 
        userPermissionInfo.customer_info_authFlg || 
        userPermissionInfo.delete_memo_authFlg || 
        userPermissionInfo.price_authFlg || 
        userPermissionInfo.delete_job_btn_authFlg) {
          flg = true; 
    }
    return flg;
  };

  const getCSDPermission = () => {
    let flg = false;
    if (userPermissionInfo.csd_promotion_show_authFlg ||
        userPermissionInfo.csd_order_show_authFlg ||
        userPermissionInfo.csd_simulation_add_item_authFlg ||
        userPermissionInfo.csd_simulation_delete_item_authFlg ||
        userPermissionInfo.csd_simulation_clear_authFlg ||
        userPermissionInfo.csd_simulation_simulate_authFlg ||
        userPermissionInfo.csd_simulation_view_promo_detail_authFlg) {
          flg = true; 
    }
    return flg;
  };

  const getGoToCSDPermission = () => {
    let flg = false;
    if (userPermissionInfo.csd_promotion_show_authFlg) {
          flg = true; 
    }
    return flg;
  };

  const getWCOPermission = () => {
    let flg = false;
    if (userPermissionInfo.wco_claim_update_authFlg ||
        userPermissionInfo.wco_claim_inquiry_authFlg ||
        userPermissionInfo.wco_claim_approve_authFlg ||
        userPermissionInfo.wco_claim_pre_approve_authFlg ||
        userPermissionInfo.wco_claim_note_authFlg ||
        userPermissionInfo.wco_labor_master_authFlg ||
        userPermissionInfo.wco_business_message_editor) {
          flg = true; 
    }
    return flg;
  };

  const getGoToWCOPermission = () => {
    let flg = false;
    if (userPermissionInfo.wco_claim_update_authFlg ||
        userPermissionInfo.wco_claim_inquiry_authFlg ||
        userPermissionInfo.wco_claim_approve_authFlg ||
        userPermissionInfo.wco_claim_pre_approve_authFlg) {
          flg = true; 
    }
    return flg;
  };

  return (
    <>
      <HomeNewMessage titles={titles} handleAdd={addMessage} />
      <Grid container spacing={1}>
        {dashboardMessageList.map((m, i) => {
          if (m.message_type === MessageType.SYSTEM) {
            if (m.permission_group === PermissionGroup.REPAIR && getRepairPermission()) {
              return (
                <Grid container item key={i} justify="flex-end">
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <AlertTitle className={classes.alertTitle}>
                        {t(
                          permissionGroups.find(
                            (f) => f.permission_group === m.permission_group
                          )?.permission_group_name
                        )}
                      </AlertTitle>
                      <div className={classes.alertText}>{m.message}</div>
                    </Alert>
                  </Grid>
                  { userPermissionInfo.system_message_editor && <>
                    <Grid item xs={12}>
                      <TextField
                        id={`${i}`}
                        fullWidth
                        value={m.message}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                    <Button
                      color="secondary"
                      disableElevation
                      id={`${i}`}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleDelete(event)}
                    >
                      {t("btn_Delete")}
                    </Button>
                    <Button
                      color="primary"
                      disabled={!m.is_edit}
                      disableElevation
                      id={`${i}`}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleSave(event)}
                    >
                      {t("btn_Save")}
                    </Button>
                  </> }
                </Grid>
              );
            }
            if (m.permission_group === PermissionGroup.CSD && getCSDPermission()) {
              return (
                <Grid container item key={i} justify="flex-end">
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <AlertTitle className={classes.alertTitle}>
                        {t(
                          permissionGroups.find(
                            (f) => f.permission_group === m.permission_group
                          )?.permission_group_name
                        )}
                      </AlertTitle>
                      <div className={classes.alertText}>{m.message}</div>
                    </Alert>
                  </Grid>
                  { userPermissionInfo.system_message_editor && <>
                    <Grid item xs={12}>
                      <TextField
                        id={`${i}`}
                        fullWidth
                        value={m.message}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                    <Button
                      color="secondary"
                      disableElevation
                      id={`${i}`}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleDelete(event)}
                    >
                      {t("btn_Delete")}
                    </Button>
                    <Button
                      color="primary"
                      disabled={!m.is_edit}
                      disableElevation
                      id={`${i}`}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleSave(event)}
                    >
                      {t("btn_Save")}
                    </Button>
                  </> }
                </Grid>
              );
            }
            if (m.permission_group === PermissionGroup.WCO && getWCOPermission()) {
              return (
                <Grid container item key={i} justify="flex-end">
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <AlertTitle className={classes.alertTitle}>
                        {t(
                          permissionGroups.find(
                            (f) => f.permission_group === m.permission_group
                          )?.permission_group_name
                        )}
                      </AlertTitle>
                      <div className={classes.alertText}>{m.message}</div>
                    </Alert>
                  </Grid>
                  { userPermissionInfo.system_message_editor && <>
                    <Grid item xs={12}>
                      <TextField
                        id={`${i}`}
                        fullWidth
                        value={m.message}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                    <Button
                      color="secondary"
                      disableElevation
                      id={`${i}`}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleDelete(event)}
                    >
                      {t("btn_Delete")}
                    </Button>
                    <Button
                      color="primary"
                      disabled={!m.is_edit}
                      disableElevation
                      id={`${i}`}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleSave(event)}
                    >
                      {t("btn_Save")}
                    </Button>
                  </> }
                </Grid>
              );
            }
          }
          if (m.message_type === MessageType.BUSINESS) {
            if (m.permission_group === PermissionGroup.REPAIR && getRepairPermission()) {
              return (
                <Grid item xs={12} sm={getCount()} key={i}>
                  <HomeRepair
                    title={
                      permissionGroups.find(
                        (f) => f.permission_group === m.permission_group
                      )?.permission_group_name
                    }
                    message={m.message}
                    id={`${i}`}
                    handleSave={handleSave}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    editFlag={m.is_edit}
                    editor={userPermissionInfo.repair_business_message_editor}
                    goto={GetPrivateAppRoutePath(PathKey.JOBS)}
                    showIconAndLink={getGoToRepairPermission()}
                  />
                </Grid>
              );
            }
            if (m.permission_group === PermissionGroup.CSD && getCSDPermission()) {
              return (
                <Grid item xs={12} sm={getCount()} key={i}>
                  <HomeRepair
                    title={
                      permissionGroups.find(
                        (f) => f.permission_group === m.permission_group
                      )?.permission_group_name
                    }
                    message={m.message}
                    id={`${i}`}
                    handleSave={handleSave}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    editFlag={m.is_edit}
                    editor={userPermissionInfo.csd_business_message_editor}
                    goto={GetPrivateAppRoutePath(PathKey.PROMOTION)}
                    showIconAndLink={getGoToCSDPermission()}
                  />
                </Grid>
              );
            }
            if (m.permission_group === PermissionGroup.WCO && getWCOPermission()) {
              return (
                <Grid item xs={12} sm={getCount()} key={i}>
                  <HomeRepair
                    title={
                      permissionGroups.find(
                        (f) => f.permission_group === m.permission_group
                      )?.permission_group_name
                    }
                    message={m.message}
                    id={`${i}`}
                    handleSave={handleSave}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    editFlag={m.is_edit}
                    editor={userPermissionInfo.wco_business_message_editor}
                    goto={GetPrivateAppRoutePath(PathKey.CLAIM)}
                    showIconAndLink={getGoToWCOPermission()}
                  />
                </Grid>
              );
            }
          }
        })}
      </Grid>
      {responses.map((m, i) => {
        return (
          <AlertBox
            key={i}
            open={true}
            severity={m.severity}
            messageId={m.message}
          />
        );
      })}
      <HomeDialog
        openFlag={confirmationOpen}
        handleClose={handleClose}
        handleDelete={deleteMessage}
      ></HomeDialog>
    </>
  );
};

// window.atob/btoa doesn't support surrogate pair.
const decode = (s: string): string => Buffer.from(s, "base64").toString();
const encode = (s: string): string => Buffer.from(s).toString("base64");

const MakeResponse = (code: number, message?: string): ResponseAlertModel => {
  return {
    severity: IsSuccessResponse(code) ? "success" : "error",
    message: message,
  };
};

interface ResponseAlertModel {
  severity: AlertProps["severity"];
  message?: string;
}

const MappingResponse = (
  local: DashboardMessageModel[],
  remote: DashboardMessageModel[],
  perms: PermissionGroupModel[]
): DashboardMessageModel[] =>
  remote.map((m, i) => {
    let existLocal = local.find(
      (f) =>
        f.message_type === m.message_type &&
        f.permission_group === m.permission_group
    );
    if (existLocal !== undefined) {
      if (m.updated_date === existLocal.updated_date) {
        m.is_edit = existLocal.is_edit;
      }
      if (m.is_edit) {
        m.message = existLocal.message;
      } else {
        m.message = decode(m.message);
      }
    } else {
      m.message = decode(m.message);
    }
    m.title = MakeMessageTypeString(
      m.message_type,
      perms.find((f) => f.permission_group === m.permission_group)
        ?.permission_group_name
    )!;
    return m;
  });

const MakeMessageTypeString = (t: number, s?: string) =>
  t === MessageType.SYSTEM ? `System - ${s}` : s;

const MakeTitle = (
  t: number,
  p: number,
  s?: string,
  d?: DashboardMessageModel[]
): DashboardTitleModel => ({
  message_type: t,
  permission_group: p,
  title: MakeMessageTypeString(t, s)!,
  isAdded: d!.some((f) => f.message_type === t && f.permission_group === p)!,
});
