import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "25px",
      },
    },
    promotionCode: {
      display: "flex",
      margin: "0px",
      "& dd": {
        fontWeight: "bold",
        marginLeft: "15px"
      }
    }
  })
);

export const PromotionCode = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const {
    searchValueList
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>{t("promotion_code")}</h1>
      <dl className={classes.promotionCode}>
        <dt>{t("promotion_code")}:</dt>
        <dd>{searchValueList.promotion_code[0].promo_code}</dd>
      </dl>
    </div>
  );
};
