import React, { useState, useCallback, useEffect } from "react";
// route
import { useHistory } from "react-router-dom";
// component
import { DraftsSearchResultTable } from "./Drafts.SearchResult.Table";

export const DraftsSearchResult = (props: any) => {
  const {
    numSelected,
    setNumSelected,
    selectedArray,
    setSelectedArray,
    selectedRowArray,
    setSelectedRowArray,
    indeterminate,
    setIndeterminate,
    allChecked,
    setAllChecked,
    needResetOrderBy,
    setNeedResetOrderBy,
    resultData,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
  } = props

  const history = useHistory();
  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelected(num);
  };

  // Cancel button click event
  const handleCancel = () => {
    setSelectedArray([]);
    setSelectedRowArray([]);
    changeNumSelected(0);
    setIndeterminate(false);
    setAllChecked(false);
  };

  useEffect(() => {
    // init search
    if (props.searchFlg) {
      handleCancel();
      props.setSearchFlg(false);
      setNeedResetOrderBy(true);
    }
  }, [props.searchFlg]);

  //
  const handleOnClick = useCallback((path: string) => history.push(path), [
    history,
  ]);

  // Register button click event
  const handleRegister = () => {
    // alert("handleRegister");
    const path = "/private/promotionDetail";
    sessionStorage.removeItem("createJobNo");
    handleOnClick(path);
  };

  return (
    <div>
      <DraftsSearchResultTable
        data={resultData}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedRowArray={selectedRowArray}
        setSelectedRowArray={setSelectedRowArray}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        changeNumSelected={(num: number) => changeNumSelected(num)}
        needResetOrderBy={needResetOrderBy}
        setNeedResetOrderBy={setNeedResetOrderBy}
        numSelected={numSelected}
        resultData={resultData}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};
