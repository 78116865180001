import React, { useEffect, useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { MasterMaintenanceSearchControlCSearch } from "./MasterMaintenance.CSearch";

// UI
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import { PhoneNumberInput } from "../../../modules/components/phoneInput/PhoneNumberInput";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AlertMesssageBox } from "../../../modules/components/alert/AlertMesssageBox";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// hook
import { useStyles } from "../hooks/editor.useStyles";
// constants
import { TabKey } from "../MasterMaintenance.constants";

import {
  isEmptyCheck,
  emailValidation,
  checkMaxLength,
  isEmptyStringOrNumber,
  checkUmlautsCharUnicode,
} from "../../../modules/hooks/UseValication";
// api
import api from "../../../modules/api/Api";

export const MasterMaintenanceUserMasterEditor = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.userMaster",
  });

  const [customerNo, setCustomerNo] = useState("");
  const [customerName, setCustomerName] = useState("");

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = React.useState(false);

  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getCommonConfig, getCommonInfo, getUserInfoRepair } = useCommonInfo();

  let commonInfo = getCommonInfo() as any;

  // init country
  let userInfoRepair = getUserInfoRepair() as any;
  const {
    changeData,
    editData,
    handleBack,
    handleSubmit,
    affiliateCd,
    primaryLocale,
    serviceCenterDataList,
    useMasterList,
    customerMasterData,
    ERPUserDetailList,
    alertEditMessageList,
    setAlertEditMessageList,
    handleResetMFA,
    apiLoading,
    config,
    getApiResultStatus,
    userRepairDisabledFlag,
    editList,
    setEditList,
    telUsedCountry,
  } = props;
  const {
    sub,
    given_name,
    family_name,
    middle_name,
    affiliate_cd,
    primary_locale,
    tel_no,
    mail_address,
    user_block,
    mailAddressFlg,
    registerFlg,
    userinfo_repair,
    userinfodz,
    use_repair,
    use_dz,
  } = editData;

  const handleCSearch = () => {
    if (use_dz) {
      setCSearchOpen(true);
    }
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    setAlertEditMessageList([]);

    if (row.customer_closed === true) {
      let message = com("messages.MS0085", {
        param1: row.customer_no,
      });

      const obj: any = {
        no: alertEditMessageList.length,
        message: message,
        alertType: "warning",
      };
      const newList: any = alertEditMessageList.slice(0);
      newList.push(obj);
      setAlertEditMessageList(newList);
    }

    const customerValue = {
      customer_no: row.customer_no.toString(),
    };

    const newObjectList = Object.assign(
      {},
      editList["userinfodz"],
      customerValue
    );

    let newList = Object.assign({}, editList, {
      ["userinfodz"]: newObjectList,
    });

    setEditList(newList);

    setCustomerNoErrorMessage("");
    setCustomerNoErrorFlg(false);

    if (row.customer_no.toString() !== customerNo) {
      setCustomerNo(row.customer_no.toString());
    }
  };

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("given_name" === itemName) {
      givenNameValidationCheck(itemValue);
    } else if ("family_name" === itemName) {
      familyNameValidationCheck(itemValue);
    } else if ("middle_name" === itemName) {
      middleNameValidationCheck(itemValue);
    } else if ("mail_address" === itemName) {
      mailAddressValidationCheck(itemValue);
    }

    if (userRepairDisabledFlag) {
      if ("erp_user_id" === itemName) {
        technicianCodeValidationCheck(itemValue);
      } else if ("service_center" === itemName) {
        serviceCenterValidationCheck(itemValue);
      }
    }

    if (use_dz) {
      if ("customer_no" === itemName) {
        customerNoValidationCheck(itemValue);
      }
    }
  };

  useEffect(() => {
    if (use_dz === false) {
      setCustomerNoErrorFlg(false);
      setCustomerNoErrorMessage("");
      userinfodz.role_id = roleId[1].code;
      userinfodz.customer_no = "";
      userinfodz.sms_receive_notification = false;
      userinfodz.mail_receive_notification = false;
      if (commonCheckResult()) {
        setDisabledFlg(true);
      } else {
        setDisabledFlg(false);
      }
    } else {
      if (
        commonCheckResult() ||
        isEmptyCheck(userinfodz.customer_no) ||
        !checkMaxLength(userinfodz.customer_no, 8) ||
        customerNoErrorFlg
      ) {
        setDisabledFlg(true);
      } else {
        setDisabledFlg(false);
      }
    }
    if (
      !isEmptyStringOrNumber(userinfodz.customer_no) &&
      customerNo !== String(userinfodz.customer_no)
    ) {
      setCustomerNo(String(userinfodz.customer_no));
    }
  });

  useEffect(() => {
    if (customerNo === "") {
      setCustomerName("");
      return;
    }

    let searchCustomerNo = Number(customerNo);
    let selectCustomerInfo = commonInfo.customerList.filter((item: any) => {
      return item.customer_no === searchCustomerNo;
    });
    if (selectCustomerInfo && selectCustomerInfo.length > 0) {
      setCustomerName(selectCustomerInfo[0].customer_name);
    } else {
      setCustomerName("");
    }
  }, [customerNo]);

  const isBlank = (name: any) => {
    if (name !== null && name !== undefined && name !== '') {
      return !checkMaxLength(name.trim(), 50);
    } else {
      return false;
    }
  }

  const commonCheckResult = () => {
    if (
      isEmptyCheck(given_name) ||
      !checkMaxLength(given_name.trim(), 50) ||
      givenNameErrorFlg ||
      isEmptyCheck(family_name) ||
      !checkMaxLength(family_name.trim(), 50) ||
      familyNameErrorFlg ||
      isBlank(middle_name) ||
      middleNameErrorFlg ||
      (mailAddressFlg &&
        (isEmptyCheck(mail_address) ||
          !checkMaxLength(mail_address, 254) ||
          mailAddressErrorFlg)) ||
      (userRepairDisabledFlag &&
        (!checkMaxLength(userinfo_repair.erp_user_id, 10) ||
          technicianCodeErrorFlg ||
          isEmptyCheck(userinfo_repair.service_center) ||
          serviceCenterErrorFlg))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [disabledFlg, setDisabledFlg] = React.useState(true);
  const [givenNameErrorFlg, setGivenNameErrorFlg] = React.useState(false);
  const [givenNameErrorMessage, setGivenNameErrorMessage] = React.useState("");
  const [middleNameErrorFlg, setMiddleNameErrorFlg] = React.useState(false);
  const [middleNameErrorMessage, setMiddleNameErrorMessage] =
    React.useState("");
  const [familyNameErrorFlg, setFamilyNameErrorFlg] = React.useState(false);
  const [familyNameErrorMessage, setFamilyNameErrorMessage] =
    React.useState("");
  const [mailAddressErrorFlg, setMailAddressErrorFlg] = React.useState(false);
  const [mailAddressErrorMessage, setMailAddressErrorMessage] =
    React.useState("");
  const [technicianCodeErrorFlg, setTechnicianCodeErrorFlg] =
    React.useState(false);

  const [technicianCodeErrorMessage, setTechnicianCodeErrorMessage] =
    React.useState("");

  const [serviceCenterErrorFlg, setServiceCenterErrorFlg] =
    React.useState(false);
  const [customerNoErrorFlg, setCustomerNoErrorFlg] = React.useState(false);
  const [customerNoErrorMessage, setCustomerNoErrorMessage] =
    React.useState("");
  const roleId = com("role_id");

  const [serviceCenterErrorMessage, setServiceCenterErrorMessage] =
    React.useState("");

  //Given Name check
  const givenNameValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value.trim())) {
      flg = isEmptyCheck(value.trim());
      let message = com("messages.MS0001", {
        param1: t("given_name"),
      });
      setGivenNameErrorFlg(flg);
      setGivenNameErrorMessage(message);
    } else if (!checkMaxLength(value.trim(), 50)) {
      flg = !checkMaxLength(value.trim(), 50);
      let message = com("messages.MS0002", {
        param1: t("given_name"),
        param2: 50,
      });
      setGivenNameErrorFlg(flg);
      setGivenNameErrorMessage(message);
    } else {
      setGivenNameErrorFlg(flg);
      setGivenNameErrorMessage("");
    }
  };

  //Family Name check
  const familyNameValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value.trim())) {
      flg = isEmptyCheck(value.trim());
      let message = com("messages.MS0001", {
        param1: t("family_name"),
      });
      setFamilyNameErrorFlg(flg);
      setFamilyNameErrorMessage(message);
    } else if (!checkMaxLength(value.trim(), 50)) {
      flg = !checkMaxLength(value.trim(), 50);
      let message = com("messages.MS0002", {
        param1: t("family_name"),
        param2: 50,
      });
      setFamilyNameErrorFlg(flg);
      setFamilyNameErrorMessage(message);
    } else {
      setFamilyNameErrorFlg(flg);
      setFamilyNameErrorMessage("");
    }
  };

  //Middle Name check
  const middleNameValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value.trim(), 50)) {
      flg = !checkMaxLength(value.trim(), 50);
      let message = com("messages.MS0002", {
        param1: t("middle_name"),
        param2: 50,
      });
      setMiddleNameErrorFlg(flg);
      setMiddleNameErrorMessage(message);
    } else {
      setMiddleNameErrorFlg(flg);
      setMiddleNameErrorMessage("");
    }
  };

  //Mail Address check
  const mailAddressValidationCheck = (value: any) => {
    let flg = false;
    let message = "";
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      message = com("messages.MS0001", {
        param1: t("mail_address"),
      });
    } else {
      if (!checkMaxLength(value, 254)) {
        flg = !checkMaxLength(value, 254);
        message = com("messages.MS0002", {
          param1: t("mail_address"),
          param2: 254,
        });
      } else if (checkUmlautsCharUnicode(value)) {
        message = com("messages.MS0122");
        flg = true;
      } else if (!emailValidation(value)) {
        message = com("messages.MS0040");
        flg = true;
      }
    }
    setMailAddressErrorFlg(flg);
    setMailAddressErrorMessage(message);
  };

  //Technician Code check
  const technicianCodeValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = !isEmptyCheck(value);
      setTechnicianCodeErrorFlg(flg);
      setTechnicianCodeErrorMessage("");
    } else if (!checkMaxLength(value, 10)) {
      flg = !checkMaxLength(value, 10);
      let message = com("messages.MS0002", {
        param1: t("technician_code"),
        param2: 10,
      });
      setTechnicianCodeErrorFlg(flg);
      setTechnicianCodeErrorMessage(message);
    } else {
      masterExitOfTechnician(value);
    }
  };

  const masterExitOfTechnician = (value: any) => {
    let isExisted = false;
    let technicians = ERPUserDetailList;
    if (value !== "" && technicians.length > 0) {
      for (let index in technicians) {
        if (
          technicians[index].erp_user_id.toUpperCase() === value.toUpperCase()
        ) {
          isExisted = true;
          changeData(
            "erp_user_id",
            technicians[index].erp_user_id,
            "userinfo_repair"
          );
          const useMasterListHaveFlg = useMasterList.filter(
            (item: any) =>
              item.userinfo_repair.erp_user_id ===
              technicians[index].erp_user_id
          );
          if (useMasterListHaveFlg.length > 0) {
            let message = com("messages.MS0048");
            setTechnicianCodeErrorFlg(true);
            setTechnicianCodeErrorMessage(message);
          }
          break;
        }
      }
      if (!isExisted) {
        apiLoading(true);
        api
          .getERPUserDetailAPI(value, config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("get AM0402 getERPUserDetailAPI", code, response);
            if (!getApiResultStatus(code)) {
              let message = com("messages.MS0004", {
                param1: t("technician_code"),
              });
              setTechnicianCodeErrorFlg(true);
              setTechnicianCodeErrorMessage(message);
            } else {
              changeData("erp_user_id", value.toUpperCase(), "userinfo_repair");
              setTechnicianCodeErrorFlg(false);
              setTechnicianCodeErrorMessage("");
            }
            apiLoading(false);
          });
      }
    }
  };

  //Service Center check
  const serviceCenterValidationCheck = (value: any) => {
    const flg: boolean = isEmptyCheck(value);
    if (flg) {
      let message = com("messages.MS0046", {
        param1: t("service_center"),
      });
      setServiceCenterErrorFlg(true);
      setServiceCenterErrorMessage(message);
    } else {
      setServiceCenterErrorFlg(false);
      setServiceCenterErrorMessage("");
    }
  };

  //Customer No. check
  const customerNoValidationCheck = (value: any) => {
    let flg = false;
    const customerNoHaveFlg = customerMasterData.filter(
      (item: any) => item.no == value
    );
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("customer_no"),
      });
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage(message);
    } else if (!checkMaxLength(value, 8)) {
      flg = !checkMaxLength(value, 8);
      let message = com("messages.MS0002", {
        param1: t("customer_no"),
        param2: 8,
      });
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage(message);
    } else if (customerNoHaveFlg.length === 0) {
      flg = true;
      let message = com("messages.MS0004", {
        param1: t("customer_no"),
      });
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage(message);
    } else {
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage("");
    }
  };

  const onBlurOfCustomerNo = () => {
    setAlertEditMessageList([]);
    setCustomerNoErrorFlg(false);

    if (isEmptyStringOrNumber(userinfodz.customer_no)) {
      setCustomerNoErrorMessage("");
      setCustomerNoErrorFlg(false);
      setCustomerNo("");
      setCustomerName("");
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(userinfodz.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_no"),
            });
            setCustomerNoErrorMessage(message);
            setCustomerName("");
            setCustomerNoErrorFlg(true);
          } else {
            setCustomerNoErrorMessage("");
            setCustomerNoErrorFlg(false);

            if (response.customer_closed === true) {
              let message = com("messages.MS0085", {
                param1: response.customer_no,
              });

              const obj: any = {
                no: alertEditMessageList.length,
                message: message,
                alertType: "warning",
              };
              const newList: any = alertEditMessageList.slice(0);
              newList.push(obj);
              setAlertEditMessageList(newList);
            }
            setCustomerName(response.customer_name);
          }
        });
    }
  };

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <>
      <div>
        {alertEditMessageList.length > 0 && (
          <AlertMesssageBox
            key={alertEditMessageList[alertEditMessageList.length - 1].no}
            show={true}
            message={
              alertEditMessageList[alertEditMessageList.length - 1].message
            }
            type={
              alertEditMessageList[alertEditMessageList.length - 1].alertType
            }
          />
        )}
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <table className={classes.tableUserInfo}>
          <tbody>
            <tr></tr>
            <tr>
              <th>
                {t("given_name")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <TextField
                  error={givenNameErrorFlg}
                  helperText={givenNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={given_name}
                  onBlur={(e) => changeDataListen("given_name", e.target.value)}
                  onChange={(e) => changeData("given_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("family_name")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <TextField
                  error={familyNameErrorFlg}
                  helperText={familyNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={family_name}
                  onBlur={(e) =>
                    changeDataListen("family_name", e.target.value)
                  }
                  onChange={(e) => changeData("family_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("middle_name")}</th>
              <td>
                <TextField
                  error={middleNameErrorFlg}
                  helperText={middleNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={middle_name}
                  onBlur={(e) =>
                    changeDataListen("middle_name", e.target.value)
                  }
                  onChange={(e) => changeData("middle_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("affiliate_cd")}</th>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={affiliate_cd}
                    onChange={(e) => changeData("affiliate_cd", e.target.value)}
                    disabled
                  >
                    {affiliateCd.map((item: any) => (
                      <MenuItem
                        value={item.affiliate_cd}
                        key={item.affiliate_cd}
                      >
                        {item.affiliate_cd}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </td>
            </tr>
            <tr>
              <th>{t("primary_locale")}</th>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={primary_locale.toLocaleLowerCase()}
                    onChange={(e) =>
                      changeData("primary_locale", e.target.value)
                    }
                  >
                    {primaryLocale.map((item: any) => (
                      <MenuItem value={item} key={item}>
                        {item.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </td>
            </tr>
            <tr>
              <th>{t("tel_no")}</th>
              <td className={classes.phoneNumberInput}>
                <PhoneNumberInput
                  country={telUsedCountry}
                  value={tel_no}
                  onBlur={(e) => changeDataListen("tel_no", e)}
                  onChange={(e) => changeData("tel_no", "+" + e)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("mail_address")}
                {mailAddressFlg && <span className={classes.mark}>*</span>}
              </th>
              <td>
                <TextField
                  error={mailAddressErrorFlg}
                  helperText={mailAddressErrorMessage}
                  disabled={!mailAddressFlg}
                  variant="outlined"
                  className={classes.textField}
                  value={mail_address}
                  onBlur={(e) =>
                    changeDataListen("mail_address", e.target.value)
                  }
                  onChange={(e) => changeData("mail_address", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={classes.groupArea}>
                  <h3 className={classes.groupTitle}>{t("use_repair")}</h3>
                  <table className={classes.tableUserInfo02}>
                    <tr>
                      <th>{t("use_repair")}</th>
                      <td>
                        <Checkbox
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={true}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("technician_code")}</th>
                      <td>
                        <TextField
                          error={technicianCodeErrorFlg}
                          helperText={technicianCodeErrorMessage}
                          disabled={!userRepairDisabledFlag}
                          variant="outlined"
                          className={classes.textField}
                          value={userinfo_repair.erp_user_id}
                          onBlur={(e) =>
                            changeDataListen("erp_user_id", e.target.value)
                          }
                          onChange={(e) =>
                            changeData(
                              "erp_user_id",
                              e.target.value,
                              "userinfo_repair"
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {t("service_center")}
                        {userRepairDisabledFlag && (
                          <span className={classes.mark}>*</span>
                        )}
                      </th>
                      <td>
                        <FormControl
                          variant="outlined"
                          className={classes.selectContainer}
                        >
                          <Select
                            error={serviceCenterErrorFlg}
                            id="demo-simple-select-outlined"
                            className={classes.select}
                            value={userinfo_repair.service_center}
                            disabled={!userRepairDisabledFlag}
                            onBlur={(e) =>
                              changeDataListen("service_center", e.target.value)
                            }
                            onChange={(e) =>
                              changeData(
                                "service_center",
                                e.target.value,
                                "userinfo_repair"
                              )
                            }
                          >
                            {serviceCenterDataList.map((item: any) => (
                              <MenuItem
                                value={item.service_center_id}
                                key={item.service_center_id}
                              >
                                {item.address_1}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {serviceCenterErrorMessage}
                          </FormHelperText>
                        </FormControl>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className={classes.groupArea}>
                  <h3 className={classes.groupTitle}>{t("use_dz")}</h3>
                  <table className={classes.tableUserInfo02}>
                    <tr>
                      <th>{t("use_dz")}</th>
                      <td>
                        <Checkbox
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={use_dz}
                          onChange={(e) => {
                            if (!use_dz) {
                              setCustomerName("");
                            }
                            changeData("use_dz", e.target.checked);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("role_id")}</th>
                      <td>
                        <RadioGroup
                          name="roleId"
                          className={classes.radioContainer}
                          value={
                            Object.keys(userinfodz).length === 0 ||
                              userinfodz.role_id === undefined
                              ? roleId[1].code
                              : userinfodz.role_id
                          }
                          onChange={(e) =>
                            changeData("role_id", e.target.value, "userinfodz")
                          }
                        >
                          <FormControlLabel
                            disabled={!use_dz}
                            className={classes.radio}
                            value={roleId[0].code}
                            control={<Radio color="primary" />}
                            label={roleId[0].name}
                          />
                          <FormControlLabel
                            disabled={!use_dz}
                            className={classes.radio}
                            value={roleId[1].code}
                            control={<Radio color="primary" />}
                            label={roleId[1].name}
                          />
                          <FormControlLabel
                            disabled={!use_dz}
                            className={classes.radio}
                            value={roleId[2].code}
                            control={<Radio color="primary" />}
                            label={roleId[2].name}
                          />
                        </RadioGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {t("customer_number")}
                        <span className={use_dz ? classes.mark : classes.mark2}>
                          *
                        </span>
                      </th>
                      <td className={classes.customerNo}>
                        {/*                        
                        <TextField
                          disabled={!use_dz}
                          error={customerNoErrorFlg}
                          helperText={customerNoErrorMessage}
                          variant="outlined"
                          className={classes.textField}
                          value={userinfodz.customer_no}
                          onBlur={(e) =>
                            changeDataListen("customer_no", e.target.value)
                          }
                          onChange={(e) =>
                            changeData(
                              "customer_no",
                              e.target.value,
                              "userinfodz"
                            )
                          }
                        />
*/}
                        <Autocomplete
                          id="customer_no"
                          freeSolo
                          disabled={!use_dz}
                          disableClearable
                          options={commonInfo.customerList}
                          getOptionLabel={getOptionCustomerLabel}
                          filterOptions={filterCustomerOptions}
                          inputValue={
                            userinfodz && userinfodz.customer_no
                              ? String(userinfodz.customer_no)
                              : ""
                          }
                          value={
                            userinfodz && userinfodz.customer_no
                              ? String(userinfodz.customer_no)
                              : ""
                          }
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              style={{
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                minWidth: "300px",
                              }}
                              placement="bottom-start"
                            />
                          )}
                          getOptionSelected={(option: any, value: any) => {
                            let isSelected = false;
                            if (option.customer_no && userinfodz.customer_no) {
                              isSelected =
                                option.customer_no.toString().toUpperCase() ===
                                userinfodz.customer_no.toString().toUpperCase();
                            }
                            return isSelected;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              variant="outlined"
                              error={customerNoErrorFlg}
                              helperText={customerNoErrorMessage}
                              onBlur={onBlurOfCustomerNo}
                            />
                          )}
                          onChange={(
                            event: object,
                            value: any,
                            reason: string
                          ) => {
                            if (reason === "select-option") {
                              changeData(
                                "customer_no",
                                value.customer_no.toString(),
                                "userinfodz"
                              );
                            }
                          }}
                          onInputChange={(
                            event: object,
                            value: string,
                            reason: string
                          ) => {
                            if (reason === "input" || reason === "clear") {
                              const val = value.substr(0, 8);
                              changeData("customer_no", val, "userinfodz");
                            }
                          }}
                        />

                        <IconButton
                          className={classes.iconButton}
                          onClick={handleCSearch}
                        >
                          <SearchIcon />
                        </IconButton>

                        <div className={classes.inputNameContainer}>
                          {customerName}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t("sms_receive_notification")}</th>
                      <td>
                        <Checkbox
                          disabled={!use_dz}
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={userinfodz.sms_receive_notification}
                          onChange={(e) =>
                            changeData(
                              "sms_receive_notification",
                              e.target.checked,
                              "userinfodz"
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("mail_receive_notification")}</th>
                      <td>
                        <Checkbox
                          disabled={!use_dz}
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={userinfodz.mail_receive_notification}
                          onChange={(e) =>
                            changeData(
                              "mail_receive_notification",
                              e.target.checked,
                              "userinfodz"
                            )
                          }
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
            {!registerFlg && (
              <tr>
                <th>{t("user_block")}</th>
                <td>
                  <Checkbox
                    className={classes.checkboxLayout}
                    color="primary"
                    checked={user_block}
                    onChange={(e) => changeData("user_block", e.target.checked)}
                  />
                </td>
              </tr>
            )}
            {!registerFlg && (
              <tr>
                <th>{t("mfa")}</th>
                <td>
                  <Button
                    className={classes.buttonWidth}
                    variant="contained"
                    color="primary"
                    onClick={() => handleResetMFA(sub)}
                  >
                    {t("btn_reset")}
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonWidth2}
            variant="contained"
            onClick={handleBack}
          >
            {t("back")}
          </Button>
          <Button
            className={classes.buttonWidth2}
            disabled={disabledFlg}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(TabKey.useMaster, registerFlg)}
          >
            {t("submit")}
          </Button>
        </div>
      </form>

      <MasterMaintenanceSearchControlCSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </>
  );
};
