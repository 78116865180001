import React, { useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailFreeGoodsTable } from "./PromotionDetail.FreeGoods.Table";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "20px"
      },
      "& h2": {
        fontSize: "14px",
        fontWeight: "normal",
        marginBottom: "20px"
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& dl": {
        display: "flex",
        margin: 0,
        marginRight: "20px",
        alignItems: "center"
      },
      "& dd": {
        marginLeft: "15px",
        margin: 0
      }
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      alignItems: "center",
      "&:not(:last-child)": {
        marginBottom: "20px"
      }
    },
    discountCode: {
      width: "100px"
    },
    itemClass: {
      width: "100px"
    },
    isac: {
      width: "80px"
    },
    plannerCode: {
      width: "70px",
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
    item: {
      width: "100px"
    },
    consideredItemName: {
      width: "250px"
    }
  })
);

export const FreeGoods = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    searchValueList,
    setSearchValue,
    oldData,
    setOldData,
    errorValueList,
    changeDataListen,
    setErrorValueList,
    setDefaultValue,
    defaultValueList,
    numSelectedFreeGoods,
    setNumSelectedFreeGoods,
    selectedArrayFreeGoods,
    setSelectedArrayFreeGoods,
    selectedRowArrayFreeGoods,
    setSelectedRowArrayFreeGoods,
    indeterminateFreeGoods,
    setIndeterminateFreeGoods,
    allCheckedFreeGoods,
    setAllCheckedFreeGoods,
    getOptionLabel2,
    filterOptions2,
    categoryFreeGoods,
    onBlurOfItemNo,
    onFocusOfItemNo,
    freeGoodsEditList,
    setFreeGoodsEditList,
    plannerCodeChange,
    setHasSeqNoRow,
    hasSeqNoRow,
    reviewFlg,
    onFreeGoodsItemNoFlag,
    salesAnalysisList

  } = props
  const classes = useStyles();

  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelectedFreeGoods(num);
  };

  return (
    <div className={classes.root}>
      <h1>{t("free_goods")}</h1>
      <div className={classes.rows}>
        <dl>
          <dt>{t("planner_code")}</dt>
          <dd className={classes.plannerCode}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                id="planner_code"
                value={searchValueList.planner_code_free_goods}
                onChange={(e: any) => {
                  setSearchValue("planner_code_free_goods", e.target.value)
                  plannerCodeChange("free_goods", e.target.value)
                }}
                disabled={reviewFlg}
                native
              >
                <option value={""}></option>
                {Array.isArray(com("planner_code")) ? (
                  com("planner_code").map((option: any) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
      </div>

      <PromotionDetailFreeGoodsTable
        data={searchValueList.free_goods}
        setData={setSearchValue}
        oldData={oldData.free_goods}
        setOldData={setOldData}
        selectedArray={selectedArrayFreeGoods}
        setSelectedArray={setSelectedArrayFreeGoods}
        selectedRowArray={selectedRowArrayFreeGoods}
        setSelectedRowArray={setSelectedRowArrayFreeGoods}
        indeterminate={indeterminateFreeGoods}
        setIndeterminate={setIndeterminateFreeGoods}
        allChecked={allCheckedFreeGoods}
        setAllChecked={setAllCheckedFreeGoods}
        numSelected={numSelectedFreeGoods}
        changeNumSelected={(num: number) => changeNumSelected(num)}
        errorValueList={errorValueList}
        changeDataListen={changeDataListen}
        setErrorValueList={setErrorValueList}
        setDefaultValue={setDefaultValue}
        defaultValueList={defaultValueList}
        getOptionLabel2={getOptionLabel2}
        filterOptions2={filterOptions2}
        categoryFreeGoods={categoryFreeGoods}
        onBlurOfItemNo={onBlurOfItemNo}
        onFocusOfItemNo={onFocusOfItemNo}
        freeGoodsEditList={freeGoodsEditList}
        setFreeGoodsEditList={setFreeGoodsEditList}
        plannerCodeChange={plannerCodeChange}
        setHasSeqNoRow={setHasSeqNoRow}
        hasSeqNoRow={hasSeqNoRow}
        reviewFlg={reviewFlg}
        onFreeGoodsItemNoFlag={onFreeGoodsItemNoFlag}
        salesAnalysisList={salesAnalysisList}

      />
    </div>
  );
};
