import {
  getCodeNameByNumber,
  getCodeNameByCodeNumber,
  getSignByCSV,
} from "../../../modules/common.methods";
import { getOnlyCurrency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { getDelimiter } from "../../../modules/components/numberFormat/NumberFormat.Currency";

export const semicolon = ["MD"];

// date length
export const date2length = (v: any, len: number) => {
  let _t: string = typeof v === "number" ? v.toString() : v;
  for (let i = 0; i < len; i++) {
    if (typeof _t === "string" && _t.length <= len - 1) {
      _t = "0" + _t;
    }
  }
  return _t;
};

// get CSV Date
export const getCSVDate = (date: any) => {
  const _date = new Date(date);
  let y = _date.getFullYear().toString();
  let m: any = date2length(_date.getMonth() + 1, 2);
  let d: any = date2length(_date.getDate(), 2);
  return `${y}-${m}-${d}`;
};

// get CSV file name
export const getCSVFileName = () => {
  const date = new Date();
  let y = date.getFullYear().toString();
  let m: any = date2length(date.getMonth() + 1, 2);
  let d: any = date2length(date.getDate(), 2);
  let hh = date2length(date.getHours(), 2);
  let mm = date2length(date.getMinutes(), 2);
  let ss = date2length(date.getSeconds(), 2);

  return y + m + d + hh + mm + ss;
};

export const getSign = (config: any) => {
  return getSignByCSV(config.affiliate_cd);
};

export const getCSVTitleInfo = (t: any) => {
  let _title: any = {
    claim_no: t("csv_claim_info.claim_no"),
    ref_no: t("csv_claim_info.ref_no"),
    status: t("csv_claim_info.status"),
    customer_no: t("csv_claim_info.customer_no"),
    customer_name: t("csv_claim_info.customer_name"),
    enduser_no: t("csv_claim_info.enduser_no"),
    customer_type: t("csv_claim_info.customer_type"),
    company_name: t("csv_claim_info.company_name"),
    user_name: t("csv_claim_info.user_name"),
    address_line_1: t("csv_claim_info.address_line_1"),
    address_line_2: t("csv_claim_info.address_line_2"),
    postal_code: t("csv_claim_info.postal_code"),
    city: t("csv_claim_info.city"),
    state: t("csv_claim_info.State"),
    country_code: t("csv_claim_info.country_code"),
    mail_address: t("csv_claim_info.mail_address"),
    tel_no: t("csv_claim_info.tel_no"),
    fax_no: t("csv_claim_info.fax_no"),
    transport_way_pick_up: t("csv_claim_info.transport_way_pick_up"),
    transport_way_return: t("csv_claim_info.transport_way_return"),
    customer_po_no: t("csv_claim_info.customer_po_no"),
    entry_date: t("csv_claim_info.entry_date"),
    model_no: t("csv_claim_info.model_no"),
    serial_no: t("csv_claim_info.serial_no"),
    condition_of_tool: t("csv_claim_info.condition_of_tool"),
    device_runtime: t("csv_claim_info.device_runtime"),
    purchased_date: t("csv_claim_info.purchased_date"),
    received_date: t("csv_claim_info.received_date"),
    repair_date: t("csv_claim_info.repair_date"),
    manufactured_month: t("csv_claim_info.manufactured_month"),
    manufactured_year: t("csv_claim_info.manufactured_year"),
    error_description: t("csv_claim_info.error_description"),
    guarantee_type: t("csv_claim_info.guarantee_type"),
    total_amount: t("csv_claim_info.total_amount"),
    total_amount_currency: t("csv_claim_info.total_amount_currency"),
    line_no: t("csv_claim_info.line_no"),
    item_no: t("csv_claim_info.item_no"),
    description: t("csv_claim_info.description"),
    order_qty: t("csv_claim_info.qty"),
    net_price: t("csv_claim_info.price"),
    net_price_currency: t("csv_claim_info.price_currency"),
    amount: t("csv_claim_info.amount"),
    amount_currency: t("csv_claim_info.amount_currency")

  };
  return _title;
};

export const getCSVTitle = (sign: any, t: any) => {
  let str = "";

  const _title: any = getCSVTitleInfo(t);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item] !== "") {
      if (itemIndex < titleSize) {
        str += _title[item] + sign;
      } else {
        str += _title[item];
      }
    }
  }
  str += "\n";
  return str;
};

export const doQuotationMarks = (item: any) => {
  let str = String(item);
  return str.replace(/[\"]/g, '""');
};

// get CSV contents
export const getCSVContents = (com: any, t: any, data: any, config: any) => {
  let str = "";
  let totalAmountCurrencySetFlg = 0;
  let netPriceCurrencySetFlg = 0;
  let amountCurrencySetFlg = 0;
  const sign = getSign(config);
  const _title = getCSVTitleInfo(t);

  str += getCSVTitle(sign, t);
  data.forEach((item: any) => {
    for (let key in _title) {
      let v = "";
      if (key === "user_name") {
        v =
          item["first_name"] === null || item["first_name"] === undefined
            ? ""
            : item["first_name"];
        v =
          v +
          (item["last_name"] === null || item["last_name"] === undefined
            ? ""
            : " " + item["last_name"]);
        v = doQuotationMarks(v);
      } else {
        v = item[key] === null || item[key] === undefined ? "" : item[key];
        v = doQuotationMarks(v);
        let currency = "";

        if (key === "entry_date") {
          if (v) {
            v = getCSVDate(v);
          } else {
            v = "";
          }
        } else if (key === "status" && v !== "") {
          v = v + ":" + getCodeNameByNumber(v, com("wco_status"));
        } else if (key === "guarantee_type" && v !== "") {
          v = v + ":" + getCodeNameByCodeNumber(v, com("guarantee_type"));
        } else if (key === "transport_way_pick_up" && v !== "") {
          v = v + ":" + getCodeNameByCodeNumber(v, com("transport_way_pick_up"));
        } else if (key === "transport_way_return" && v !== "") {
          v = v + ":" + getCodeNameByCodeNumber(v, com("transport_way_return"));
        } else if (key === "customer_type" && v !== "") {
          v = v + ":" + getCodeNameByCodeNumber(v, com("customer_type"));
        } else if (key === "condition_of_tool" && v !== "") {
          v = v + ":" + getCodeNameByCodeNumber(v, com("condition_of_tool"));
        } else if (key === "total_amount" && v !== "") {
          v = getDelimiter(Number(v), item.currency);
          totalAmountCurrencySetFlg = 1;
        } else if (key === "total_amount_currency" && totalAmountCurrencySetFlg === 1) {
          currency = getOnlyCurrency(item.currency)
          v = currency;
          totalAmountCurrencySetFlg = 0;
        } else if (key === "net_price" && v !== "") {
          v = getDelimiter(Number(v), item.currency);
          netPriceCurrencySetFlg = 1;
        } else if (key === "net_price_currency" && netPriceCurrencySetFlg === 1) {
          currency = getOnlyCurrency(item.currency)
          v = currency;
          netPriceCurrencySetFlg = 0;
        } else if (key === "amount" && v !== "") {
          v = getDelimiter(Number(v), item.currency);
          amountCurrencySetFlg = 1;
        } else if (key === "amount_currency" && amountCurrencySetFlg === 1) {
          currency = getOnlyCurrency(item.currency)
          v = currency;
          amountCurrencySetFlg = 0;
        }
      }
      str += '"' + v + '"' + sign;
    }

    str += "\n";
  });
  return str;
};

// approve一括の検証
export const preApproveCheck = (param: any, list: any) => {
  let _result: any = [];
  let _message_list: any = [];
  let _message: any = {};
  param.forEach((item: any) => {
    // itemの値はclaim_noです。
    let _flg = false;
    const _itemFilter: any = JSON.parse(JSON.stringify(list)).filter(
      (subItem: any) => subItem.claim_no === item
    );
    const _item: any = _itemFilter[0];
    if (_itemFilter.length > 0) {
      // item status
      if (_item.status !== "200" && _item.status !== "210") {
        _flg = true;
        // status:entered or status:re-enteredの場合は、更新できます。それ以外、errorになります。
        _message = {
          claim_no: item,
          error_type: "2",
        };
        _message_list.push(_message);
      } else {
        // pre-approve:trueの場合は、更新できます。それ以外、errorになります。
        if (!_item.pre_approve_flg) {
          _flg = true;

          _message = {
            claim_no: item,
            error_type: "1",
          };
          _message_list.push(_message);
        }
      }

      // 検証問題なければ、
      if (!_flg) {
        _result.push({
          claim_no: item,
          status: "400",
          update_flg: "1",
          updated_date: _item.updated_date,
        });
      }
    }
  });
  return {
    result: _result,
    message_list: _message_list,
  };
};
