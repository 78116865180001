import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { SimulateSearchControlCSearch } from "./Simulate.SearchControl.CSearch";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// api
import api from "../../../modules/api/Api";
// check
import { isEmptyStringOrNumber } from "../../../modules/hooks/UseValication";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",
      "& .MuiFormHelperText-root": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& dl": {
        paddingLeft: "10px",
        marginBottom: "0px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    row: {
      width: "100%",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    rowTop: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "30px",
    },
    rowBottom: {
      padding: "0 10px",
    },
    rowLeft: {
      display: "flex",
    },
    rowRight: {
      display: "flex",
    },
    customerDiscountCode: {
      width: "170px",
    },
    customerNo: {
      width: "125px",
      position: "relative",
      "& .MuiOutlinedInput-input": {
        paddingRight: "30px",
      },
    },
    customerName: {
      width: "150px",
      marginLeft: "10px",
      marginTop: "35px",
    },
    item: {
      width: "125px",
    },
    qty: {
      width: "73px",
    },
    buttonArea: {
      marginLeft: "10px",
      paddingTop: "38px",
    },
    button: {
      height: "30px",
    },
    iconButton: {
      position: "absolute",
      right: "3px",
      top: "7px",
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
    },
    datePicker: {
      margin: "0",
      width: "150px",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
  })
);

export const SimulateSearchControl = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.simulate" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const {
    setErrorValue,
    searchValueList,
    setSearchValue,
    setSearchValueList,
    errorValueList,
    selectedSimulate,
    commonInfo,
    setErrorValueList,
    addEventHandle,
    deleteEventHandle,
    data,
    customerNoDisabled,
    setCustomerNoDisabled,
    userPermisssionInfo,
    setAlertShow,
    setAlertMessage,
    setAlertType,
  } = props;
  const { getCommonConfig, getHotKey, setHotKey } = useCommonInfo();
  let config = getCommonConfig() as any;
  let hotKeyInfo = getHotKey() as any;

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabledByCustomer, setButtonDisabledByCustomer] =
    useState(false);

  useEffect(() => {
    if (hotKeyInfo.handleF2SimulateCustomerSearch) {
      handleCSearch();
    }
    setHotKey("handleF2SimulateCustomerSearch", false);
  }, [hotKeyInfo.handleF2SimulateCustomerSearch]);

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      let message = com("MS0085", {
        param1: row.customer_no,
      });
      setAlertShow(true);
      setAlertMessage(message);
      setAlertType("warning");
    } else {
      setAlertShow(false);
      setAlertMessage("");
      setAlertType("");
    }
    const customerValue = {
      customer_no: row.customer_no,
      customer_name: row.customer_name,
      customer_discount_code: row.customer_discount_cd,
    };
    let valueList = Object.assign({}, searchValueList, customerValue);
    setSearchValueList(valueList);

    const errorList = Object.assign({}, errorValueList, {
      customer_no_error: "",
    });
    setErrorValueList(errorList);
    setCustomerNoDisabled(true);
  };

  const onBlurOfCustomerNo = () => {
    setErrorValue("customer_no_error", "");
    if (isEmptyStringOrNumber(searchValueList.customer_no)) {
      setCustomerNoDisabled(false);
      setButtonDisabledByCustomer(false);
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(searchValueList.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("MS0004", {
              param1: t("customer_no"),
            });
            setErrorValue("customer_no_error", message);
            setCustomerNoDisabled(false);
          } else {
            if (response.customer_closed === true) {
              let message = com("MS0085", {
                param1: response.customer_no,
              });
              setAlertShow(true);
              setAlertMessage(message);
              setAlertType("warning");
            } else {
              setAlertShow(false);
              setAlertMessage("");
              setAlertType("");
            }
            setButtonDisabledByCustomer(false);
            const customerValue = {
              // customer_no: response.customer_no,
              customer_name: response.customer_name,
              customer_discount_code: response.customer_discount_cd,
            };
            const valueList = Object.assign({}, searchValueList, customerValue);
            setSearchValueList(valueList);
            setCustomerNoDisabled(true);
          }
        });
    }
  };

  async function onBlurOfItemNo() {
    if (searchValueList.item_no) {
      let _flg = false;
      let _item: any;

      // get item number
      // AM0302
      props.apiLoading(true);
      await api
        .getReadItemMasterAPI(
          encodeURIComponent(searchValueList.item_no),
          config
        )
        .then(({ code, response }: { code: any; response: any }) => {
          props.apiLoading(false);
          if (!getApiResultStatus(code)) {
            if (code === 404) {
              _flg = false;
            }
          } else {
            _flg = true;
            _item = response;
          }
        });

      if (_flg) {
        const itemValue = {
          item_no: _item.item_no,
          item_discount_cd: _item.item_discount_cd,
          item_class: _item.item_class,
          isac3: _item.isac3,
          isac6: _item.isac6,
          description: _item.description,
        };
        const valueList = Object.assign({}, searchValueList, itemValue);
        setSearchValueList(valueList);

        const errorList = Object.assign({}, errorValueList, {
          item_no_error: "",
        });
        setErrorValueList(errorList);
      } else {
        const message = com("MS0004", {
          rowNum: t("item_no"),
          param1: searchValueList.item_no,
        });
        const itemValue = {
          item_no_error: message,
        };
        const errorList = Object.assign({}, errorValueList, itemValue);
        setErrorValueList(errorList);
      }

      setButtonDisableFlg("");

      if (data && data.length > 0) {
        console.log("order exist", searchValueList.item_no);
        let _isExist = data.filter(
          (item: any) => item.item_no === searchValueList.item_no
        );
        if (_isExist.length > 0) {
          const message = com("MS0055", {
            rowNum: t("item_no"),
            param1: searchValueList.item_no,
          });
          const itemValue = {
            item_no_error: message,
          };
          const errorList = Object.assign({}, errorValueList, itemValue);
          setErrorValueList(errorList);
        }
      }
    } else {
      const errorList = Object.assign({}, errorValueList, {
        item_no_error: "",
      });
      setErrorValueList(errorList);
    }
  }

  const getOptionLabel2 = (option: any) => option.item_no || "";
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  const setButtonDisableFlg = (event: string = "") => {
    if (event === "focus") {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const checkStartDate = (date: any) => {
    if (date > 0 && date < new Date().setHours(0, 0, 0, 0)) {
      let message = com("MS0092", {
        startDate: t("start_date"),
        endDate: t("today"),
      });
      let valueList = Object.assign({}, searchValueList, {
        start_date: date,
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        start_date: date,
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    }
  };

  const onBlurOfStartDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.start_date > 0 &&
      searchValueList.start_date < new Date().setHours(0, 0, 0, 0)
    ) {
      let message = com("MS0092", {
        startDate: t("start_date"),
        endDate: t("today"),
      });
      setErrorValue("start_date_error", message);
    } else {
      setErrorValue("start_date_error", "");
    }
  };

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.row}>
          <div className={classes.rowTop}>
            <div className={classes.rowLeft}>
              <dl>
                <dt>{t("start_date")}</dt>
                <dd>
                  <DatePicker
                    value={searchValueList.start_date}
                    onChange={(date: any) => setSearchValue("start_date", date)}
                    onAccept={(date: any) =>
                      checkStartDate(new Date(date).setHours(0, 0, 0, 0))
                    }
                    className={classes.datePicker}
                    error={errorValueList.start_date_error !== ""}
                    helperText={errorValueList.start_date_error}
                    onBlur={(e: any) => onBlurOfStartDate(e)}
                    onError={(error: any, value: any) => {
                      if (
                        error !== errorValueList.start_date_error &&
                        error !== ""
                      ) {
                        setErrorValue("start_date_error", error);
                      }
                    }}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t("customer_discount_code")}</dt>
                <dd className={classes.customerDiscountCode}>
                  <TextField
                    value={searchValueList.customer_discount_code}
                    variant="outlined"
                    disabled={customerNoDisabled}
                    onChange={(e: any) => {
                      const value = e.target.value
                        //  .replace(/[^\w]/g, "")
                        .substr(0, 2);
                      setSearchValue("customer_discount_code", value);
                    }}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t("customer_no")}</dt>
                <dd className={classes.customerNo} data-testid="customer_no">
                  <Autocomplete
                    id="customer_no"
                    freeSolo
                    disableClearable
                    options={commonInfo.customerList}
                    getOptionLabel={getOptionCustomerLabel}
                    filterOptions={filterCustomerOptions}
                    inputValue={String(searchValueList.customer_no)}
                    value={String(searchValueList.customer_no)}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          minWidth: "300px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                    getOptionSelected={(option: any, value: any) => {
                      let isSelected = false;
                      if (option.customer_no && searchValueList.customer_no) {
                        isSelected =
                          option.customer_no.toString().toUpperCase() ===
                          searchValueList.customer_no.toString().toUpperCase();
                      }
                      return isSelected;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        error={errorValueList.customer_no_error !== ""}
                        helperText={errorValueList.customer_no_error}
                        onBlur={onBlurOfCustomerNo}
                        onFocus={() => setButtonDisabledByCustomer(true)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            props.searchHandle("search");
                          }
                        }}
                      />
                    )}
                    onChange={(event: object, value: any, reason: string) => {
                      if (reason === "select-option") {
                        setSearchValue(
                          "customer_no",
                          value.customer_no.toString()
                        );
                      }
                    }}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === "input" || reason === "clear") {
                        const val = value.substr(0, 8);
                        setSearchValue("customer_no", val);
                      }
                    }}
                  />
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleCSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </dd>
              </dl>
              <div className={classes.customerName}>
                {searchValueList.customer_name}
              </div>
            </div>
            <div className={classes.rowRight}>
              <dl>
                <dt>{t("promotion_code")}</dt>
                <dd className={classes.item}>
                  <TextField
                    value={searchValueList.promotion_code}
                    variant="outlined"
                    onChange={(e: any) => {
                      const value = e.target.value
                        .replace(/[^\w]/g, "")
                        .substr(0, 10);
                      setSearchValue("promotion_code", value);
                    }}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t("item")}</dt>
                <dd className={classes.item} data-testid="item_no">
                  <Autocomplete
                    id="item_no"
                    freeSolo
                    disableClearable
                    options={commonInfo.itemBaseList}
                    getOptionLabel={getOptionLabel2}
                    filterOptions={filterOptions2}
                    inputValue={searchValueList.item_no}
                    value={searchValueList.item_no}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          minWidth: "200px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                    getOptionSelected={(option: any, value: any) =>
                      option.item_no.toString().toUpperCase() ===
                      searchValueList.item_no.toString().toUpperCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        error={errorValueList.item_no_error !== ""}
                        helperText={errorValueList.item_no_error}
                        onBlur={onBlurOfItemNo}
                        onFocus={() => setButtonDisableFlg("focus")}
                      />
                    )}
                    onChange={(event: object, value: any, reason: string) => {
                      if (reason === "select-option") {
                        setSearchValue("item_no", value.item_no);
                      }
                    }}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === "input" || reason === "clear") {
                        const val = value.substr(0, 35);
                        setSearchValue("item_no", val);
                      }
                    }}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t("qty")}</dt>
                <dd className={classes.qty} data-testid="qty">
                  <TextField
                    value={searchValueList.order_qty}
                    variant="outlined"
                    onChange={(e: any) => {
                      let value = e.target.value
                        .replace(/[^\d.]/g, "")
                        .substr(0, 10);
                      setSearchValue("order_qty", value);
                    }}
                  />
                </dd>
              </dl>
              <div className={classes.buttonArea}>
                {userPermisssionInfo.csd_simulation_add_item_authFlg && (
                  <Button
                    data-testid="btn_add"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={
                      !searchValueList.item_no ||
                      errorValueList.item_no_error !== "" ||
                      buttonDisabled ||
                      errorValueList.customer_no_error !== "" ||
                      errorValueList.start_date_error !== "" ||
                      buttonDisabledByCustomer
                    }
                    startIcon={<AddBoxIcon />}
                    onClick={addEventHandle}
                  >
                    {t("btn_add")}
                  </Button>
                )}
              </div>
              <div className={classes.buttonArea}>
                {userPermisssionInfo.csd_simulation_delete_item_authFlg && (
                  <Button
                    data-testid="btn_delete"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    disabled={
                      selectedSimulate ||
                      errorValueList.customer_no_error !== "" ||
                      errorValueList.start_date_error !== "" ||
                      buttonDisabledByCustomer
                    }
                    startIcon={<DeleteIcon />}
                    onClick={deleteEventHandle}
                  >
                    {t("btn_delete")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <SimulateSearchControlCSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </>
  );
};
