import {
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useNotitificationContext } from "../../../hooks/NotificationContext";
import { useLocale } from "../../../hooks/UseLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: "border-box",
      width: "100%",
      padding: theme.spacing(1),
    },
    content: {
      boxSizing: "border-box",
      width: "100%",
      backgroundColor: "#ff9800",
      color: "white",
    },
    messageBody: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

export const NavigationDrawerNotificationConfirmationItem = () => {
  const { t } = useLocale({
    defaultPath: "modules.components.navigationDrawer",
  });
  const classes = useStyles();
  const [visible, setVisible] = useState(true);
  const {
    numberOfNotificationMessages,
    setNumberOfNotificationMessages,
  } = useNotitificationContext();

  if (!window.Notification || Notification.permission !== "default") {
    return null;
  }

  const handleOnClick = () => {
    // Ask Push Notification permission
    Notification.requestPermission().then((result) => {
      setVisible(false);
      setNumberOfNotificationMessages(numberOfNotificationMessages - 1);
    });
  };

  return (
    <div>
      {visible && (
        <div className={classes.root}>
          <Card className={classes.content}>
            <CardActionArea onClick={() => handleOnClick()}>
              <CardContent>
                <Grid container>
                  <Grid item xs={1} container></Grid>
                  <Grid item xs={11} container>
                    <Grid container>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Typography gutterBottom variant="subtitle1">
                              {t("txtNotifyMe")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={classes.messageBody}
                      >
                        {t("txtNotifyMeExplanation")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      )}
    </div>
  );
};
