import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailFreeGoodsTableThead } from "./PromotionDetail.FreeGoods.Table.Thead";
import { PromotionDetailFreeGoodsTableTbody } from "./PromotionDetail.FreeGoods.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      boxShadow: "none"
    },
    promotionContainer: {
      maxHeight: "560px",
      marginBottom: "20px",
      border: "1px solid rgba(0, 0, 0, 20%)",
      borderRadius: "5px"
    },
    buttonArea: {
      "& > button": {
        marginRight: "10px",
      },
    },
    errorText: {
      color: "#f44336",
      fontSize: "12px",
      marginBottom: "20px"
    }
  })
);

export const PromotionDetailFreeGoodsTable = (props: any) => {
  const classes = useStyles();

  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.index);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray, row.index);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data
        .slice(0)
      const newSelecteds = data.map((item: any) => item.index);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  const addFreeGoodsRow = () => {
    // ready one row
    const getFreeGoodsField = JSON.parse(JSON.stringify(props.data[0]));
    for(let key in getFreeGoodsField) {
      if(key !== "data_type") {
        getFreeGoodsField[key] = ""; 
      }
    };
    
    // add row at last
    const getFreeGoodsData = JSON.parse(JSON.stringify(props.data));
    getFreeGoodsData.push(JSON.parse(JSON.stringify(getFreeGoodsField)));
    getFreeGoodsData[getFreeGoodsData.length - 1].index = getFreeGoodsData.length - 1;
    props.setData("free_goods", getFreeGoodsData);

    const getOldData = JSON.parse(JSON.stringify(props.oldData));
    getOldData.push(JSON.parse(JSON.stringify(getFreeGoodsField)));
    getOldData[getOldData.length - 1].index = getOldData.length - 1;
    props.setOldData("free_goods", getOldData);

    if(props.selectedArray.length > 0) {
      props.setIndeterminate(true);
    } else {
      props.setIndeterminate(false);
    };
    props.setAllChecked(false);

    const getDefaultData = JSON.parse(JSON.stringify(props.defaultValueList.free_goods));
    getDefaultData.push(JSON.parse(JSON.stringify(getFreeGoodsField)));
    getDefaultData[getDefaultData.length - 1].index = getDefaultData.length - 1;
    props.setDefaultValue("free_goods", getDefaultData);

    // add error list
    const errorValueField = JSON.parse(JSON.stringify(props.errorValueList.free_goods[0]));
    for(let key in errorValueField) {
      errorValueField[key] = "";
    };
    const getErrorData = JSON.parse(JSON.stringify(props.errorValueList.free_goods));
    getErrorData.push(JSON.parse(JSON.stringify(errorValueField)));
    const errorValue = Object.assign({}, props.errorValueList, {free_goods: getErrorData});
    props.setErrorValueList(errorValue);

    // add disabled flag
    const editListAry = JSON.parse(JSON.stringify(props.freeGoodsEditList));
    editListAry.push(false);
    props.setFreeGoodsEditList(editListAry);
  };

  const deleteFreeGoodsRow = () => {
    // ready one row
    const getFreeGoodsField = JSON.parse(JSON.stringify(props.data[0]));
    for(let key in getFreeGoodsField) {
      if(key !== "data_type") {
        getFreeGoodsField[key] = ""; 
      }
    };

    // delete row with "seq_no"
    if (props.hasSeqNoRow) {
      const hasSeqNo = props.selectedRowArray.some((item: any) => {
        return item.seq_no && item.seq_no !== "";
      });
      props.setHasSeqNoRow(!hasSeqNo);
    }

    // filter not selected item
    const getFreeGoodsData = props.data.filter((item: any) => {
      return props.selectedArray.indexOf(item.index) === -1;
    });

    const getOldData = props.oldData.filter((item: any) => {
      return props.selectedArray.indexOf(item.index) === -1;
    });

    if(getFreeGoodsData.length === 0) {
      getFreeGoodsData.push(getFreeGoodsField);
      getOldData.push(getFreeGoodsField);
    };
    getFreeGoodsData.forEach((item:any, index: number) => {
      item.index = index;
    });

    getOldData.forEach((item: any, index: number) => {
      item.index = index;
    });

    props.setData("free_goods", JSON.parse(JSON.stringify(getFreeGoodsData)));

    props.setOldData("free_goods",JSON.parse(JSON.stringify(getOldData)));

    props.setSelectedArray([]);
    props.setSelectedRowArray([]);
    props.setIndeterminate(false);
    props.setAllChecked(false);

    const getDefaultField = props.defaultValueList.free_goods.filter((item: any) => {
      return props.selectedArray.indexOf(item.index) === -1;
    });;
    if(getDefaultField.length === 0) {
      getDefaultField.push(getFreeGoodsField);
    };
    getDefaultField.forEach((item: any, index: number) => {
      item.index = index;
    });
    props.setDefaultValue("free_goods", JSON.parse(JSON.stringify(getDefaultField)));

    // remove error list
    const getErrorData = JSON.parse(JSON.stringify(props.errorValueList.free_goods));
    const getErrorField = getErrorData[0];
    for(let key in getErrorField) {
      getErrorField[key] = "";
    };
    const errorData = getErrorData.filter((item: any, index: number) => {
      return props.selectedArray.indexOf(index) === -1;
    });
    if(errorData.length === 0) {
      errorData.push(getErrorField)
    };
    const errorValue = Object.assign({}, props.errorValueList, {free_goods: errorData});
    props.setErrorValueList(errorValue);

    // remove disabled flag
    const editListAry = props.freeGoodsEditList.filter((item: any, index: number) => {
      return props.selectedArray.indexOf(index) === -1;
    });
    if(editListAry.length === 0) {
      editListAry.push(false);
    };
    props.setFreeGoodsEditList(editListAry);
  };

  const setFreeGoodsValue = (property: any, value: any, index: number) => {
    const setFreeGoodsField = JSON.parse(JSON.stringify(props.data));
    setFreeGoodsField[index][property] = value;
    props.setData("free_goods", setFreeGoodsField);
  }

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer className={classes.promotionContainer}>
          <Table stickyHeader>
            <PromotionDetailFreeGoodsTableThead
              indeterminate={props.indeterminate}
              allChecked={props.allChecked}
              onSelectAllClick={handleSelectAllClick}
              reviewFlg={props.reviewFlg}
              salesAnalysisList={props.salesAnalysisList}
            />
            <PromotionDetailFreeGoodsTableTbody
              data={props.data}
              selected={props.selectedArray}
              handleClick={(event: any, row: any) => handleClick(event, row)}
              handleCSearch={props.handleCSearch}
              changeDataListen={props.changeDataListen}
              errorValueList={props.errorValueList}
              setFreeGoodsValue={setFreeGoodsValue}
              getOptionLabel2={props.getOptionLabel2}
              filterOptions2={props.filterOptions2}
              categoryFreeGoods={props.categoryFreeGoods}
              onBlurOfItemNo={props.onBlurOfItemNo}
              onFocusOfItemNo={props.onFocusOfItemNo}
              freeGoodsEditList={props.freeGoodsEditList}
              reviewFlg={props.reviewFlg}
              salesAnalysisList={props.salesAnalysisList}
            />
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.errorText}  data-testid="free_goods_item_error">{props.errorValueList.free_goods_item_error}</div>
      <div className={classes.buttonArea}>
        <Button
          data-testid="btn_free_goods_add"
          variant="contained"
          onClick={addFreeGoodsRow}
          color="primary"
          startIcon={<AddBoxIcon />}
          disabled={props.reviewFlg || props.onFreeGoodsItemNoFlag}
        >
          {t("btn_Add")}
        </Button>
        <Button
          data-testid="btn_free_goods_delete"
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={(props.selectedRowArray.length > 0 ? false : true) || props.onFreeGoodsItemNoFlag}
          onClick={deleteFreeGoodsRow}
        >
          {t("btn_Delete")}
        </Button>
      </div>
    </>
  );
};
