import React, { useState, useEffect } from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";

export const AllocateTechnicianTableTbody = (props: any) => {
  const { order, orderBy } = props;
  const classes = useStyles();

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    // init selected on the first row.
    if (props.selectedValue.erp_user_id === "" && rowIndex === 0) {
      props.handleClick(props.data[rowIndex]);
    }
  }, [selectedValue]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedValue(event.target.value);
    setRowIndex(index);
  };

  const handleClick = (row: any) => {
    props.handleClick(row);
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy).map(
        (row: any, index: number) => {
          // select the first record
          if (selectedValue === "" && index === 0) {
            setSelectedValue(row.erp_user_id);
          }
          return (
            <TableRow
              className={classes.tbody}
              hover
              onClick={() => handleClick(row)}
              key={row.erp_user_id}
            >
              <TableCell>
                <Radio
                  name="technicianSelection"
                  checked={selectedValue === row.erp_user_id}
                  value={row.erp_user_id}
                  onChange={(event: any) => handleChange(event, index)}
                />
              </TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          );
        }
      )}
    </TableBody>
  );
};
