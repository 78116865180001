import React from "react";
// component
import { PromotionSearchResultPaymentTermTable } from "./Promotion.SearchResult.PaymentTerm.Table";

export const PromotionSearchResultPaymentTerm = (props: any) => {
  const {
    numSelected,
    selectedArray,
    setSelectedArray,
    selectedRowArray,
    setSelectedRowArray,
    indeterminate,
    setIndeterminate,
    allChecked,
    setAllChecked,
    needResetOrderBy,
    setNeedResetOrderBy,
    changeNumSelected,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    salesAnalysisList,
  } = props;

  return (
    <div>
      <PromotionSearchResultPaymentTermTable
        data={props.resultData}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedRowArray={selectedRowArray}
        setSelectedRowArray={setSelectedRowArray}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        changeNumSelected={(num: number) => changeNumSelected(num)}
        needResetOrderBy={needResetOrderBy}
        setNeedResetOrderBy={setNeedResetOrderBy}
        numSelected={numSelected}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        salesAnalysisList={salesAnalysisList}
      />
    </div>
  );
};
