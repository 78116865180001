import { http } from "./Http";

// AM2001
export const GetRoleGroupListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/role_groups/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
