import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from "@material-ui/icons/Remove";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// components
import { OrderDetailButton } from "./orderDetail.Button";

// root
import { useHistory } from "react-router-dom";
// method
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { getCodeNameByNumber } from "../../../modules/common.methods";
import { getApiResultStatus, IsBlank } from "../../../modules/common.methods";
// route
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import {
  priceInputBySign,
  decimalPointIsLenth,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderDetailContainer: {
      padding: "0 40px 20px",
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderBottomWidth: "2px",
      },
    },
    nowrap: {
      wordBreak: "break-all",
      width: "163px",
    },
    quantity: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end ",
      "& .MuiOutlinedInput-input": {
        width: "72px",
        height: "30px",
        padding: "0",
        textAlign: "center",
        fontSize: "0.875rem",
      },
      marginLeft: "-35px",
    },
    netPrice: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& .MuiOutlinedInput-input": {
        width: "80px",
        height: "30px",
        padding: "0 5px",
        textAlign: "right",
        fontSize: "0.875rem",
      },
    },
    mark: {
      marginLeft: "5px",
    },
    smallButton: {
      width: "20px",
      height: "20px",
      margin: "0 3px",
      background: "transparent!important",
      boxShadow: "none!important",
      color: "inherit",
      padding: 0,
      fontSize: "inherit",
      minWidth: "auto",
    },
    totalAmount: {
      "& .MuiTableCell-body": {
        borderBottom: "none",
      },
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "blue",
    },
    cell: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    root: {
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "20px",
      },
    },
    line: {
      border: "none",
      background: "#D0D0D0",
      height: "1px",
    },
    cellNote: { whiteSpace: "inherit", wordBreak: "break-all" },
    promotions: { whiteSpace: "break-spaces", wordBreak: "break-all" },
  })
);

interface HeadCell {
  id: string;
  label: string;
  align: string;
}

const headCells: HeadCell[] = [
  { id: "line_no", label: "table_line_no", align: "center" },
  { id: "item", label: "table_item", align: "center" },
  { id: "line_note", label: "table_line_note", align: "center" },
  { id: "promo_no", label: "table_promo_no", align: "center" },
  { id: "quantity", label: "table_quantity", align: "center" },

  // { id: "original_order_qty", label: "table_original_order_qty", align: "center" },

  { id: "list_price", label: "table_list_price", align: "right" },
  { id: "discount_rate", label: "table_discount_rate", align: "right" },
  { id: "net_price", label: "table_net_price", align: "right" },

  // { id: "original_net_price", label: "table_original_net_price", align: "right" },

  { id: "free_goods_flag", label: "table_free_goods_flag", align: "center" },
  { id: "total", label: "table_total", align: "right" },
];

const headCells_original: HeadCell[] = [
  { id: "line_no", label: "table_line_no", align: "center" },
  { id: "item", label: "table_item", align: "center" },
  { id: "line_note", label: "table_line_note", align: "center" },
  { id: "promo_no", label: "table_promo_no", align: "center" },
  { id: "quantity", label: "table_quantity", align: "center" },

  // { id: "original_order_qty", label: "table_original_order_qty", align: "center" },

  { id: "list_price", label: "table_list_price", align: "right" },
  { id: "discount_rate", label: "table_discount_rate", align: "right" },
  { id: "net_price", label: "table_net_price", align: "right" },

  // { id: "original_net_price", label: "table_original_net_price", align: "right" },

  { id: "free_goods_flag", label: "table_free_goods_flag", align: "center" },
  { id: "total", label: "table_total", align: "right" },
];

export const OrderDetailAmount = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    detailData,
    setDetailData,
    originalDetailData,
    tableData,
    setErrorMessageInfo,
    setTotalAmount,
    userPermisssionInfo,
    diffResultCurrentOriginal,
    orderHeader, customerPoNoAuthFlg
  } = props;

  // change image
  const { setPromoDetailPageInfo, getPromoDetailPageInfo } = useCommonInfo();
  const promoDetailPageInfo = getPromoDetailPageInfo() as any;
  const history = useHistory();

  const decreaseCount = (data: any, index: number) => {
    let list = [...detailData];
    let quantity: number = Number(data.order_qty);
    if (quantity > 0 && !data.buttonFlg) {
      quantity = quantity - 1;
      list[index].order_qty = quantity;
      setDetailData(list);
    }
  };

  const increaseCount = (data: any, index: any) => {
    let list = [...detailData];
    let quantity: number = Number(data.order_qty);
    if (!data.buttonFlg) {
      quantity = quantity + 1;
      list[index].order_qty = quantity;
      setDetailData(list);
    }
  };

  const changeQuantity = (value: any, index: any) => {
    let list = [...detailData];
    list[index].order_qty = value;
    setDetailData(list);
  };

  const detailBlur = () => {
    let newData = detailData.slice(0);
    let errorList: any = [];
    if (detailData.length > 0) {
      detailData.forEach((row: any, index: any) => {
        let errorFlg = false;
        if (row.editFlg) {
          // if (Number(row.order_qty) === 0) {
          //   row.order_qty = 1;
          // }

          if (isNaN(row.net_price) || Number(row.net_price) === 0) {
            const message = com("messages.MS0011", {
              param1: t("table_net_price"),
              rowNum: `#${index + 1} `,
            });
            errorList.push({
              message: message,
            });
          }

          if (errorFlg) {
            newData[index].buttonFlg = true;
          } else {
            newData[index].buttonFlg = false;
          }
          let netPrice = newData[index].net_price;
          if (!isNaN(Number(netPrice))) {
            newData[index].net_price = Number(netPrice);
          }
        }
      });
    }
    setDetailData(newData);
    setErrorMessageInfo(errorList);
  };

  const changePrice = (value: any, index: any) => {
    let list = [...detailData];
    list[index].net_price = priceInputBySign(value);
    list[index].net_price_format = value;
    setDetailData(list);
  };

  const totalAmount = () => {
    let total: any = 0;
    if (detailData.length > 0) {
      total = detailData.reduce((preValue: any, curValue: any) => {
        return (
          preValue + Number(curValue.order_qty) * Number(curValue.net_price)
        );
      }, 0);
    }
    setTotalAmount(total);
    return total;
  };

  const originalTotalAmount = () => {
    let total: any = 0;
    if (originalDetailData.length > 0) {
      total = originalDetailData.reduce((preValue: any, curValue: any) => {
        return (
          preValue + Number(curValue.order_qty) * Number(curValue.net_price)
        );
      }, 0);
    }
    return total;
  };

  const changeView = (path: string) => {
    let obj = Object.assign({}, promoDetailPageInfo, { reviewFlg: true });
    setPromoDetailPageInfo(obj);
    history.push(path);
  };

  return (
    <TableContainer className={classes.orderDetailContainer}>
      <Table>
        <TableHead className={classes.thead}>
          <TableRow>
            {headCells.map((headCell) =>
              headCell.align === "right" ? (
                <TableCell key={headCell.label} align="right">
                  {t(headCell.label)}
                </TableCell>
              ) : (
                <TableCell key={headCell.label} align="center">
                  {t(headCell.label)}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {detailData.length > 0 &&
            detailData.map((row: any, index: number) => {
              const editFlg = row.editFlg;
              const item = row.item_description
                ? `${row.item_no}<br>${row.item_description}`
                : `${row.item_no}`;
              return (
                <TableRow
                  tabIndex={-1}
                  key={index}
                // className={classes.tableRow}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell
                    align="center"
                    dangerouslySetInnerHTML={{ __html: item }}
                  ></TableCell>
                  <TableCell className={classes.cellNote} align="center">
                    {row.line_note}
                  </TableCell>
                  <TableCell className={classes.promotions} align="center">
                    {row.promotions && row.promotions.length > 0
                      ? row.promotions.map((item: any, index: number) => {
                        const path =
                          GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
                          `?promotionNo=${item}`;
                        if (row.promotions.length - 1 === index) {
                          return (
                            <span
                              className={classes.link}
                              onClick={() => {
                                changeView(path);
                              }}
                            >
                              {item}
                            </span>
                          );
                        } else {
                          return (
                            <>
                              <span
                                className={classes.link}
                                onClick={() => {
                                  changeView(path);
                                }}
                              >
                                {item}
                              </span>
                              ,
                            </>
                          );
                        }
                      })
                      : ""}
                  </TableCell>
                  <TableCell align="right">
                    <div className={classes.quantity}>
                      {editFlg ? (
                        <>
                          <Button
                            data-testid={"list_subtract_btn" + index}
                            className={classes.smallButton}
                            variant="contained"
                            color="primary"
                            component="span"
                            disabled={
                              row.order_qty < 1 ||
                              row.buttonFlg ||
                              !row.onlyFlg ||
                              !userPermisssionInfo.csd_order_detail_edit_qty_authFlg
                            }
                            onClick={() => decreaseCount(row, index)}
                          >
                            <MinimizeIcon fontSize="small" />
                          </Button>
                          <TextField
                            data-testid={"list_order_qty" + index}
                            value={row.order_qty}
                            variant="outlined"
                            disabled={
                              !userPermisssionInfo.csd_order_detail_edit_qty_authFlg ||
                              !row.onlyFlg
                            }
                            onBlur={detailBlur}
                            onChange={(e: any) => {
                              const value = e.target.value
                                .replace(/[^\d]/g, "")
                                .substr(0, 10);
                              changeQuantity(value, index);
                            }}
                          />
                          <Button
                            data-testid={"list_add_btn" + index}
                            className={classes.smallButton}
                            variant="contained"
                            color="primary"
                            component="span"
                            disabled={
                              row.buttonFlg ||
                              !userPermisssionInfo.csd_order_detail_edit_qty_authFlg ||
                              !row.onlyFlg
                            }
                            onClick={() => increaseCount(row, index)}
                          >
                            <AddIcon fontSize="small" />
                          </Button>
                        </>
                      ) : (
                        row.order_qty
                      )}
                    </div>
                  </TableCell>
                  {/*
                  <TableCell align="center">
                    {row.original_order_qty}
                  </TableCell>                  
*/}
                  <TableCell align="right">
                    {!isNaN(row.list_price) &&
                      currency(row.list_price, tableData.currency)}
                  </TableCell>
                  <TableCell align="right">
                    {row.discount_percentage
                      ? row.discount_percentage + "%"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <div className={classes.netPrice}>
                      {editFlg ? (
                        <>
                          <TextField
                            data-testid={"list_net_price" + index}
                            value={row.net_price_format}
                            variant="outlined"
                            disabled={
                              !userPermisssionInfo.csd_order_detail_edit_net_price_authFlg ||
                              !row.onlyFlg
                            }
                            onBlur={detailBlur}
                            onChange={(e: any) => {
                              let value = "";
                              let _price = priceInputBySign(e.target.value);
                              if (
                                !isNaN(_price) &&
                                _price >= 0 &&
                                decimalPointIsLenth(e.target.value)
                              ) {
                                value = e.target.value.slice(0, 12);
                              } else {
                                value = row.net_price_format;
                              }
                              changePrice(value, index);
                            }}
                          />
                          <div className={classes.mark}>
                            {symbolByCurrency(tableData.currency)}
                          </div>
                        </>
                      ) : (
                        !isNaN(row.net_price) &&
                        currency(row.net_price, tableData.currency)
                      )}
                    </div>
                  </TableCell>
                  {/*                  
                  <TableCell align="right">
                  {!isNaN(row.original_net_price) &&
                      currency(row.original_net_price, tableData.currency)}
                  </TableCell>                  
*/}
                  <TableCell align="center">
                    {getCodeNameByNumber(row.free_goods, com("freeGoods_flg"))}
                  </TableCell>
                  <TableCell className={classes.nowrap} align="right">
                    {!isNaN(row.order_qty * row.net_price) &&
                      currency(
                        row.order_qty * row.net_price,
                        tableData.currency
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow className={classes.totalAmount}>
            <TableCell colSpan={7} />
            <TableCell align="center">{t("table_total_amount")}</TableCell>
            <TableCell align="right">
              {currency(totalAmount(), tableData.currency)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <OrderDetailButton
        diffFlg={props.diffFlg}
        tableData={tableData}
        discardChangeHandle={props.discardChangeHandle}
        submitHandle={props.submitHandle}
        returnHandle={props.returnHandle}
        userPermisssionInfo={userPermisssionInfo}
        personInChargeSubState={props.personInChargeSubState}
        handleRefresh={props.handleRefresh}
        orderHeader={orderHeader}
        customerPoNoAuthFlg={customerPoNoAuthFlg}
      ></OrderDetailButton>

      {!diffResultCurrentOriginal && (
        <>
          <br />
          <div className={classes.root}>
            <h1>{t("table_original_order")}</h1>
          </div>
          <hr className={classes.line} />
          <Table>
            <TableHead className={classes.thead}>
              <TableRow>
                {headCells_original.map((headCell) =>
                  headCell.align === "right" ? (
                    <TableCell key={headCell.label} align="right">
                      {t(headCell.label)}
                    </TableCell>
                  ) : (
                    <TableCell key={headCell.label} align="center">
                      {t(headCell.label)}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {originalDetailData.length > 0 &&
                originalDetailData.map((row: any, index: number) => {
                  const editFlg = row.editFlg;
                  const item = row.item_description
                    ? `${row.item_no}<br>${row.item_description}`
                    : `${row.item_no}`;
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        dangerouslySetInnerHTML={{ __html: item }}
                      ></TableCell>
                      <TableCell className={classes.cellNote} align="center">
                        {row.line_note}
                      </TableCell>
                      <TableCell className={classes.promotions} align="center">
                        {row.promotions && row.promotions.length > 0
                          ? row.promotions.map((item: any, index: number) => {
                            const path =
                              GetPrivateAppRoutePath(
                                PathKey.PROMOTIONDETAIL
                              ) + `?promotionNo=${item}`;
                            if (row.promotions.length - 1 === index) {
                              return (
                                <span
                                  className={classes.link}
                                  onClick={() => {
                                    changeView(path);
                                  }}
                                >
                                  {item}
                                </span>
                              );
                            } else {
                              return (
                                <>
                                  <span
                                    className={classes.link}
                                    onClick={() => {
                                      changeView(path);
                                    }}
                                  >
                                    {item}
                                  </span>
                                  ,
                                </>
                              );
                            }
                          })
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        <div>{row.order_qty}</div>
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.list_price) &&
                          currency(row.list_price, tableData.currency)}
                      </TableCell>
                      <TableCell align="right">
                        {row.discount_percentage
                          ? row.discount_percentage + "%"
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <div className={classes.netPrice}>
                          {!isNaN(row.net_price) &&
                            currency(row.net_price, tableData.currency)}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {getCodeNameByNumber(
                          row.free_goods,
                          com("freeGoods_flg")
                        )}
                      </TableCell>
                      <TableCell className={classes.nowrap} align="right">
                        {!isNaN(row.order_qty * row.net_price) &&
                          currency(
                            row.order_qty * row.net_price,
                            tableData.currency
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow className={classes.totalAmount}>
                <TableCell colSpan={7} />
                <TableCell align="center">{t("table_total_amount")}</TableCell>
                <TableCell align="right">
                  {currency(originalTotalAmount(), tableData.currency)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
};
