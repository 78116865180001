import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tbody: {
      "& td": {
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    contents: {
      wordBreak: "break-word",
      whiteSpace: "pre-line",
    },
    groupContainer: {
      maxHeight: 300,
    },
    table: {
      width: "auto",
      "& th": {
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "center",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
        border: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingBottom: "20px",
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    memo: {
      overflow: "hidden",
      height: "60px",
      "& .MuiTableCell-root": {
        padding: "5px",
      },
    },
    advancedButton: {
      "& button": {
        width: "140px",
        padding: "5px 0",
      },
    },
    buttonCondition: {
      marginTop: "10px",
      marginLeft: "10px",
      height: "35px",
      "& .MuiButton-label": {
        paddingTop: "2px",
      },
    },
    buttonAdvance: {
      marginTop: "10px",
      marginLeft: "10px",
      height: "48px",
      "& .MuiButton-label": {
        paddingTop: "2px",
      },
    },
    advancedHideFlg: {
      display: "none",
    },
    datePickerArea: {
      width: "400px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      // marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePicker: {
      width: "47%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    datePickerInterval: {
      position: "absolute",
      top: "20px",
      left: "50%",
      marginTop: "0",
      marginLeft: "-4px",
      borderTop: "1px solid #000",
      width: "5px",
      display: "block",
    },
    buttonArea: {
      padding: "32px 0 0 10px",
      "& .MuiButton-root": {
        padding: "6px 12px",
      },
    },
    buttonContainerArea: {
      display: "flex",
      paddingTop: "25px",
    },
    buttonArea2: {
      padding: "0 0 0 10px",
      "& .MuiButton-root": {
        padding: "6px 12px",
      },
    },
  })
);
