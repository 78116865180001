import React from "react";
import clsx from "clsx";
// hooks
import { useStyles } from "../hooks/useStyles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import MailIcon from "@material-ui/icons/MailOutline";
import Badge from "@material-ui/core/Badge";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import { TimePicker } from "../../../modules/components/datePicker/TimePicker";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupArea: {
      border: "1px solid #767676",
      padding: "15px 5px 5px 5px",
      position: "relative",
      marginTop: "20px",
      borderRadius: "10px",
    }, 
    buttonLayoutArea: {
      position: "relative",
      minHeight: "36px",
    },
    buttonArea: {
      width: "100%",
      minWidth: "35px",
      top: 0,
      right: 0,
      height: "36px",
      "& .MuiButton-root": {
        padding: "3px 15px 3px 5px",
      },
      "& .MuiBadge-colorSecondary": {
        color: "rgb(42, 95, 111)",
        background: "#fff",
      },
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "12px",
      },
    },
    buttonText: {
      display: "block",
      paddingRight: "14px",
      width: "100%",
    },
    quoteSendDateTd: {
      minWidth: "50px",
      width: "20%",
    },
    quoteBlankTd: {
      minWidth: "50px",
      width: "40%",
    },  
    quoteDateTd: {
      minWidth: "130px",
      width: "40%",
    },
    quoteResutl: {
      "& .MuiOutlinedInput-input": {
        minWidth: "40%",
        width: "100%",
      },
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        width: "18%",
        minWidth: "120px",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingBottom: "10px",
        // position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
        color: "#686868",
      },
    },
    componentHidden: {
      display: "none",
    },
  })
);

export const JobsDetailGeneralQuote = (props: any) => {
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.group_quote",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const classes = useStyles();
  const myClasses = myStyles();
  const {
    formList,
    setFormListValue,
    dataList,
    setFormList,
    handleResend,
    formErrorList,
    setFormErrorListValue,
    onBlurOfReplyDateDate,
    onBlurOfReplyDateTime,
    userPermisssionInfo,
    repairJobPartsList,
  } = props;

  const onChangeHandle = (value: number) => {
    let newData = Object.assign({}, formList, {
      quote_result: value,
      trade_in_item_no: dataList.trade_in_item_no,
      trade_in_approve: dataList.trade_in_approve,
    });

    setFormList(newData);
  };

  const quoteResultDisabledFlg = () => {
    let disabledFlg = false;
    if (!userPermisssionInfo.quote_result_authFlg) {
      disabledFlg = true;
    } else {
      if (dataList.quote_result === 1 && repairJobPartsList.length > 0) {
        disabledFlg = true;
      } else if (dataList.quote_result === 2) {
        disabledFlg = true;
      } else if (dataList.quote_result === 3 && dataList.trade_in_approve === true) {
        disabledFlg = true;
      } else if (dataList.quote_result === 4 && dataList.trade_in_approve === true) {
        disabledFlg = true;
      } else if (dataList.quote_result === 5) {
        disabledFlg = true;
      } else if (dataList.quote_result === 6) {
        disabledFlg = true;
      } else if (dataList.quote_result === 7 && dataList.status === "510") {
        disabledFlg = true;
      }
    } 
    return disabledFlg;
  };

  return (
    <div className={myClasses.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name")}</h3>
      <table className={myClasses.tableLayout}>
        <tbody>
          <tr>
            <td>{t("quote_sent_date")}</td>
            <td className={myClasses.quoteSendDateTd}>
              <p>
                {!formList.quote_sent_date || formList.quote_sent_date === ""
                  ? ""
                  : date(new Date(formList.quote_sent_date).getTime())}
              </p>
            </td>
          </tr>
          <tr>
            <td>{t("quote_remind_date")}</td>
            <td className={myClasses.quoteSendDateTd}>
              <p>
                {!formList.quote_remind_date || formList.quote_remind_date === ""
                  ? ""
                  : date(new Date(formList.quote_remind_date).getTime())}
              </p>
            </td>
            <td colSpan={2} className={myClasses.buttonLayoutArea}>
            {userPermisssionInfo.resend_btn_authFlg && (
              <div
                className={clsx(
                  myClasses.buttonArea
                )}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<MailIcon />}
                  onClick={handleResend}
                >
                  <Badge
                    badgeContent={formList.quote_sent_count !== 0 && formList.quote_sent_count !== 1 ? formList.quote_sent_count - 1 : "0"}
                    color="secondary"
                  >
                    <span className={myClasses.buttonText}>
                      {t("btn_resend")}
                    </span>
                  </Badge>
                </Button>
              </div>
            )}
            </td>
          </tr>
          <tr>
            <th>{t("quote_url")}</th>
            <td colSpan={3}>
              <p className={classes.quoteUrl}>{formList.quote_url}</p>
            </td>
          </tr>
          <tr>
            <th>{t("reply_date")}</th>
            <td colSpan={2} className={myClasses.quoteDateTd}>
              {
              <DatePicker
                disabled={!userPermisssionInfo.reply_date_authFlg}
                value={formList.reply_date_date}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setFormListValue(
                      "reply_date_date",
                      new Date(date).getTime()
                    );
                  } else {
                    setFormListValue("reply_date_date", date);
                  }
                }}
                onAccept={(date: any) =>
                  setFormListValue(
                    "reply_date_date",
                    new Date(date).setHours(0, 0, 0, 0)
                  )
                }
                className={classes.datePickerComponent}
                onBlur={onBlurOfReplyDateDate}
                error={formErrorList.reply_date_date_error !== ""}
                helperText={formErrorList.reply_date_date_error}
                onError={(error: any, value: any) => {
                  if (
                    error !== formErrorList.reply_date_date_error &&
                    error !== ""
                  ) {
                    setFormErrorListValue("reply_date_date_error", error);
                  }
                }}
              />
            }
            </td>
            <td></td>
          </tr>
          <tr>
            <th></th>
            <td colSpan={2} className={myClasses.quoteDateTd}>
              <TimePicker
                disabled={!userPermisssionInfo.reply_date_authFlg}
                value={formList.reply_date_time}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setFormListValue(
                      "reply_date_time",
                      new Date(date).getTime()
                    );
                  } else {
                    setFormListValue("reply_date_time", date);
                  }
                }}
                onAccept={(date: Date) =>
                  setFormListValue("reply_date_time", new Date(date).getTime())
                }
                className={classes.datePickerComponent}
                onBlur={onBlurOfReplyDateTime}
                error={formErrorList.reply_date_time_error !== ""}
                helperText={formErrorList.reply_date_time_error}
                onError={(error: any, value: any) => {
                  if (
                    error !== formErrorList.reply_date_time_error &&
                    error !== ""
                  ) {
                    setFormErrorListValue("reply_date_time_error", error);
                  }
                }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <th>{t("quote_result")}</th>
            <td colSpan={3} className={myClasses.quoteDateTd}>
              <FormControl variant="outlined">
                <Select
                  disabled={quoteResultDisabledFlg()}
                  id="quote_result"
                  value={formList.quote_result}
                  onChange={(e) => onChangeHandle(Number(e.target.value))}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                  className={myClasses.quoteResutl}
                >
                  {Array.isArray(com("quote_result")) ? (
                    com("quote_result").map((option: any) => (
                      <MenuItem key={option.code} value={option.code}>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className={myClasses.quoteBlankTd}></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
