import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { LocaleSelect } from "./components/LocaleSelect";
import { ServiceCenterSelect } from "./components/ServiceCenterSelect";
import { PersonInformation } from "./components/PersonInformation";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { getRepairPermission } from "../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    root: {
      width: "100%",
      maxWidth: "456px",
      margin: "0 auto",
    },
  })
);

export const Page: FC = () => {
  const classes = useStyles();

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Message");
  const [alertType, setAlertType] = useState("error");

  // 仕様追加 ユーザー詳細の表示
  const { getCommonConfig } = useCommonInfo();
  let config = getCommonConfig() as any;

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <div className={classes.container}>
        <div className={classes.root}>
          <PersonInformation config={config} />
          <LocaleSelect />
          {config.affiliate_cd && getRepairPermission(config.affiliate_cd) && (
            <ServiceCenterSelect
              setAlertShow={setAlertShow}
              setAlertMessage={setAlertMessage}
              setAlertType={setAlertType}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
