import React, { FC } from "react";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";
import { AppRoutes } from "../../../pages/AppRoutes";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { CacheLocation } from "@auth0/auth0-react";

export const ApplicationRouter: FC = () => {
  const history = createBrowserHistory();

  // @ts-ignore
  const cacheLocation : CacheLocation = window.Cypress ? 'localstorage' : 'memory'

  const backToredirectUri = () => {
    let redirectUri: string;
    if (window.location.pathname.includes("/private/dashboard") && 
    window.location.search.includes("error=unauthorized&error_description=user%20is%20blocked")) {
      redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_ERROR_URI!;
      history.push("/error");
    } else {
      redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI!
    }
    return redirectUri;
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      cacheLocation={cacheLocation}
      redirectUri={backToredirectUri()}
      >
      <Router history={history}>
        <Switch>
          {
            // Switch & Route does not support nested path.
            AppRoutes.map((m, i) => {
              if (m.component === undefined) return <></>;
              if (m.isPublic) {
                return (
                  <Route
                    key={i}
                    exact
                    path={m.path}
                    render={(props) => <m.component {...props} />}
                  />
                );
              } else {
                // Remove component
                var params = { path: m.path };
                return (
                  <Route
                    key={i}
                    component={withAuthenticationRequired(m.component, {
                      returnTo: window.location.hash.substr(1),
                      onRedirecting: () => (
                        <div>Redirecting you to the login...</div>
                      ),
                    })}
                    {...params}
                  />
                );
              }
            })
          }
        </Switch>
      </Router>
    </Auth0Provider>
  );
};
