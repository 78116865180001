import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export const headCells: HeadCell[] = [
  {
    id: "given_name",
    label: "given_name",
    searchFlg: true,
  },
  {
    id: "family_name",
    label: "family_name",
    searchFlg: true,
  },
  {
    id: "middle_name",
    label: "middle_name",
    searchFlg: true,
  },
  { id: "mail_address", label: "mail_address", searchFlg: true },
  { id: "technician_code", label: "technician_code", searchFlg: true },
  { id: "Edit", label: "" },
];

export const MasterMaintenanceUserRoleThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath = "modules.components.MasterMaintenance.userRole";

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
