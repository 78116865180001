import React from "react";
// language
import { useLocale } from "../../hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
    },
    theadCell: {
      background: "rgba(240, 242, 244)",
    },

    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
  })
);

export const SortTableThead = (props: any) => {
  const classes = useStyles();
  const {
    order,
    orderBy,
    onRequestSort,
    createSortHandler,
    defaultPath,
  } = props;
  const { t } = useLocale({ defaultPath: defaultPath });

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {props.checkboxFlg && (
          <TableCell className={classes.theadCell} padding="checkbox">
            <Checkbox
              indeterminate={props.indeterminate}
              onChange={props.onSelectAllClick}
              checked={props.checked}
            />
          </TableCell>
        )}

        {props.headCells.map((headCell: any) => (
          <TableCell
            className={classes.theadCell}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label && t(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
