import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/messageToCustomer.userStyles";
import DescriptionIcon from "@material-ui/icons/Description";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const SummaryDocuments = (props: any) => {
  const classes = useStyles();
  const {
    data,
    fileDownload,
    endUserInfoToolsList,
    formList,
    fileDownloadFile,
  } = props;

  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    let _list = [];
    if (endUserInfoToolsList.length > 0) {
      const _modelNoList = endUserInfoToolsList.filter(
        (item: any) => item.unique_key === formList.unique_key
      );
      if (
        _modelNoList.length > 0 &&
        _modelNoList[0].invoices &&
        _modelNoList[0].invoices.length > 0
      ) {
        _list = _modelNoList[0].invoices;
      }
    }
    setDocumentList(_list);
  }, [endUserInfoToolsList, formList.unique_key]);

  return (
    <form noValidate autoComplete="off">
      <div className={classes.outerLayer}>
        <div className={classes.titleBox}>
          <div className={classes.titleBody}>
            <div>
              <DescriptionIcon className={classes.icon} />
            </div>
            <div className={classes.text}>{t("summary_title_document")}</div>
          </div>
        </div>
        <div className={classes.media_list}>
          {documentList &&
            documentList.length > 0 &&
            documentList.map((item: any, index: number) => (
              <div key={"document" + index} className={classes.media_item}>
                <DescriptionIcon className={classes.iconSvg} />
                {
                  <p
                    className={classes.link}
                    onClick={() => fileDownloadFile(item)}
                  >
                    {item.filename}
                  </p>
                }
              </div>
            ))}
          {data &&
            data.length > 0 &&
            data.map((item: any, index: number) => (
              <div key={index} className={classes.media_item}>
                <DescriptionIcon className={classes.iconSvg} />
                {item.updated_date ? (
                  <p
                    className={classes.link}
                    onClick={() => fileDownload(item)}
                  >
                    {item.media_name}
                  </p>
                ) : (
                  <p>{item.media_name}</p>
                )}
              </div>
            ))}
        </div>
      </div>
    </form>
  );
};
