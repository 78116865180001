import React, { useRef, useEffect } from "react";
// methods
import { getSvg } from "../methods/ClaimDetail.Parts.Methods";
// css
import { useStyles } from "../hooks/Parts.styles";

export const ClaimDetailPartsSvgMessage = (props: any) => {
  const {
    path,
    layer,
    handleMouseOver,
    handleMouseLeave,
    handleClick,
    handleDoubleClick,
    selected,
    contentsUrl,
  } = props;
  const svgEl = useRef(null);
  const classes = useStyles();
  useEffect(() => {
    if (layer === "layer2") {
      getSvg(contentsUrl, path).then((res: any) => {
        const el: any = svgEl.current;
        for (let i = 0; i < res.childNodes[0].childNodes.length; i++) {
          const node = res.childNodes[0].childNodes[i];
          let dnum = parseInt(node.dataset.id);
          if (
            selected.find((f: string) => parseInt(f.split(":")[1]) === dnum)
          ) {
            node.classList.add("selected");
          }
          node.addEventListener("mouseover", (e: any) =>
            handleMouseOver(e, node, layer)
          );
          node.addEventListener("mouseleave", (e: any) =>
            handleMouseLeave(e, node, layer)
          );
          node.addEventListener("click", (e: any) =>
            handleClick(e, node, layer)
          );
          node.addEventListener("dblclick", (e: any) =>
            handleDoubleClick(e, node, layer)
          );
        }
        if (el) {
          const doc = res.documentElement;
          el.appendChild(doc);
        }
      });
    }
    console.log("parts svg", contentsUrl, path);
  }, [path]);
  if (layer === "layer1") {
    return (
      <div className={`drawing ${classes.svgMessage} ${classes.layer1}`}>
        <object data={contentsUrl + path}></object>
      </div>
    );
  } else {
    return (
      <div
        className={`drawing ${classes.svgMessage} ${classes.layer2}`}
        ref={svgEl}
      ></div>
    );
  }
};
