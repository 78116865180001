import React, { useState, useEffect } from "react";
import clsx from "clsx";
// components
import { MasterMaintenanceSearchBox } from "./MasterMaintenance.SearchBox";
import { MasterMaintenanceDZUserInfoThead } from "./MasterMaintenance.DZUserInfo.Thead";
import { MasterMaintenanceDZUserInfoTbody } from "./MasterMaintenance.DZUserInfo.Tbody";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { MasterMaintenanceErrorMessageBox } from "./MasterMaintenance.ErrorMessageBox";
import { TabKey } from "../MasterMaintenance.constants";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { CustomerSearch } from "../../../modules/components/customerSearch/CSearch";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import RefreshIcon from "@material-ui/icons/Refresh";
// hook
import { useStyles } from "../hooks/table.useStyles";
// methods
import {
  isEmptyCheck,
  emailValidation,
  checkMaxLength,
  checkTypeNumber,
  isEmptyStringOrNumber,
  checkUmlautsCharUnicode,
} from "../../../modules/hooks/UseValication";

import { getApiResultStatus } from "../../../modules/common.methods";

import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
    },
    buttonArea: {
      marginRight: "10px",
    },
    input: {
      display: "none",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
    leftButton: {
      display: "flex",
    },
    searchCondition: {
      display: "flex",
      flexWrap: "wrap",
    },
    row: {
      display: "flex",
      width: "100%",
      // flexWrap: "wrap",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
      "& dl": {
        paddingLeft: "10px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
      {
        padding: "0px",
        paddingLeft: "5px",
      },
    },
    dzUserInfoDd: {
      width: "100px",
      position: "relative",
    },
    iconButton1: {
      position: "absolute",
      right: "3px",
      top: "7px",
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
    },
  })
);

const defaultValue = {
  customer_no: "",
  customer_name: "",
  name: "",
  mail_address: "",
  created_date_from: 0,
  created_date_to: 0,
  last_login_date_from: 0,
  last_login_date_to: 0,
  customer_no_error: "",
  name_error: "",
  mail_address_error: "",
  created_date_from_error: "",
  created_date_to_error: "",
  last_login_date_from_error: "",
  last_login_date_to_error: "",
};

export const MasterMaintenanceDZUserInfo = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.dZUserInfo",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { setCommonInfo, getCommonInfo, getCommonConfig } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const commonInfo = getCommonInfo() as any;
  const [searchKey, setSearchKey] = useState("");
  const {
    config,
    api,
    getDzinfoDetailListAPI,
    customerMasterData,
    downloadDZUserInfoCSV,
  } = props;
  // pager
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [firstLoad, setFirstLoad] = useState(false);

  // delete
  const [deleteRow, setDeleteRow] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_date");

  useEffect(() => {
    // init search
    if (!props.dzUserInfoStartFlg) {
      setOrderBy("created_date");
      setOrder("desc");
      props.setDzUserInfoStartFlg(true);
    }
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // search
  const handleChangeKey = (value: any) => {
    setSearchKey(value);
    props.SearchResult(value, TabKey.DZUserInfo);
    setPage(0);
  };

  const handleSearch = () => {
    props.SearchResult(searchKey, TabKey.DZUserInfo);
    setPage(0);
  };

  // delete button click event
  const handleDelete = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  // message box
  const [open, setOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    props.handleDelete(deleteRow, TabKey.DZUserInfo);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };
  const handleSuccessAndErrorMessageClose = () => {
    setSuccessAndErrorMessageOpen(false);
  };

  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  // success and error message box
  const [successAndErrorMessageOpen, setSuccessAndErrorMessageOpen] =
    useState(false);
  // search value
  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const [alertMessageList, setAlertMessageList] = useState([]);

  const [errMessageList, setErrMessageList] = useState([]);
  const [showSuccessAndErrorMessage, setShowSuccessAndErrorMessage] = useState(
    []
  );
  const [successAndErrorMessageList, setSuccessAndErrorMessageList] = useState(
    []
  );

  useEffect(() => {
    let data: any = {};
    if (!firstLoad) {
      data = Object.assign({}, defaultValue, {
        created_date_from: null,
        created_date_to: null,
        last_login_date_from: null,
        last_login_date_to: null,
      });
      setSearchValueList(data);
      setFirstLoad(true);
    }
  }, [firstLoad]);

  let successQuantity = 0;
  let successQuantityList: any = [];
  let errorDataList: any = [];
  let errorMessage = {};
  let count = 0;
  let timerId: any;

  //click upload button
  const handlerUpload = (files: any) => {
    const file = files[0];
    if (files && files.length > 0) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = reader.result;
        var dataList = parseData(data);
        const errorNumber = handleInputValue(dataList);
        if (errorNumber.length > 0) {
          setErrorMessageOpen(true);
        } else {
          apiLoading(true);
          createUserInfo(dataList, 1);
        }
      };
      reader.onerror = errorHandler;
      reader.readAsText(file);
    }
  };

  const createUserInfo = async (dataList: any, i: any) => {
    clearTimeout(timerId)
    if (i < dataList.length) {
      const param = getDZUserInfoNewData(dataList[i]);
      await api
        .createUserInfoAPI(param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("post AM0101 userInfo", code, response);
          if (getApiResultStatus(code)) {
            successQuantity = successQuantity + 1;
            successQuantityList.push(successQuantity);
          } else {
            errorMessage = com("messages." + response.message_id, {
              rowNum: t("row") + "" + Number(i + 1),
              param1: "API." + t(response.key),
            });
            errorDataList.push(errorMessage + "\n");
          }
          count++;
          timerId = setTimeout(() => {
            createUserInfo(dataList, i);
          }, 1000)
          i++;
          if (count === dataList.length - 1) {
            clearTimeout(timerId)
            setShowSuccessAndErrorMessage(
              com("messages.MS0012", {
                registerQty: successQuantityList.length,
              })
            );
            setSuccessAndErrorMessageList(errorDataList);
            apiLoading(false);
            setSuccessAndErrorMessageOpen(true);
            getDzinfoDetailListAPI();
          }
        });
    }
  };

  // parse Data
  const parseData = (data: any) => {
    const rowLines = data.split(/\r\n|\n/);
    let dataList = [];
    for (let i = 0; i < rowLines.length; i++) {
      const rowMessage1 = rowLines[i].split(",");
      if (rowMessage1.length > 1) {
        rowMessage1[13] = i;
        dataList.push(rowMessage1);
      } else if (rowMessage1.length <= 1) {
        const rowMessage2 = rowLines[i].split(";");
        if (rowMessage2.length > 1) {
          rowMessage2[13] = i;
          dataList.push(rowMessage2);
        }
      }
    }
    return dataList;
  };

  // handle Input Value
  const handleInputValue = (value: any) => {
    let errorData: any = [];
    let message = {};
    if (14 != value[0].length) {
      message = com("messages.MS0045", {});
      errorData.push(message + "\n");
      setErrMessageList(errorData);
      return errorData;
    } else if (1 == value.length) {
      message = t("data");
      errorData.push(message + "\n");
      setErrMessageList(errorData);
      return errorData;
    } else {
      for (var j = 1; j < value.length; j++) {
        let validateData = [] as any;
        validateData = value[j];
        if (validateData.length === 0) {
          continue;
        }
        if (validateData.length === 14) {
          if (isEmptyCheck(validateData[0].trim())) {
            message = com("messages.MS0001", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("given_name"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[0].trim(), 50)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("given_name"),
              param2: 50,
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[1].trim())) {
            message = com("messages.MS0001", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("family_name"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[1].trim(), 50)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("family_name"),
              param2: 50,
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[2].trim(), 50)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("middle_name"),
              param2: 50,
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[3].trim())) {
            message = com("messages.MS0001", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("affiliate_cd"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[3].trim(), 5)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("affiliate_cd"),
              param2: 5,
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[4].trim())) {
            message = com("messages.MS0001", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("primary_locale"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[4].trim(), 2)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("primary_locale"),
              param2: 2,
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[5].trim(), 25)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("tel_no"),
              param2: 25,
            });
            errorData.push(message + "\n");
          }
          if (
            !checkTypeNumber(
              validateData[5].trim().substr(0, 1) == "+"
                ? validateData[5].trim().substr(1)
                : validateData[5].trim()
            )
          ) {
            message = com("messages.MS0011", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("tel_no"),
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[6].trim())) {
            message = com("messages.MS0001", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("mail_address"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[6].trim(), 254)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("mail_address"),
              param2: 254,
            });
            errorData.push(message + "\n");
          }
          if (checkUmlautsCharUnicode(validateData[6].trim())) {
            message = com("messages.MS0122", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("mail_address"),
            });
            errorData.push(message + "\n");
          } else if (!emailValidation(validateData[6].trim())) {
            message = com("messages.MS0040", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("mail_address"),
            });
            errorData.push(message + "\n");
          }
          if (validateData[7].trim().toUpperCase() != "FALSE") {
            message = com("messages.MS0011", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("use_repair"),
            });
            errorData.push(message + "\n");
          }
          if (validateData[8].trim().toUpperCase() != "TRUE") {
            message = com("messages.MS0011", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("use_dz"),
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[9].trim())) {
            message = com("messages.MS0001", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("customer_no"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[9].trim(), 8)) {
            message = com("messages.MS0002", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("customer_no"),
              param2: 8,
            });
            errorData.push(message + "\n");
          }
          if (validateData[9].trim()) {
            const customerNoHaveFlg = customerMasterData.filter(
              (item: any) => item.no == validateData[9].trim()
            );
            if (customerNoHaveFlg.length == 0) {
              message = com("messages.MS0004", {
                rowNum: t("row") + "" + Number(validateData[13] + 1),
                param1: t("customer_no"),
              });
              errorData.push(message + "\n");
            }
          }
          if (
            validateData[10].trim() != "1" &&
            validateData[10].trim() != "2" &&
            validateData[10].trim() != "3"
          ) {
            message = com("messages.MS0011", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("role_id"),
            });
            errorData.push(message + "\n");
          }
          if (
            validateData[11].trim().toUpperCase() != "TRUE" &&
            validateData[11].trim().toUpperCase() != "FALSE"
          ) {
            message = com("messages.MS0011", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("sms_receive_notification"),
            });
            errorData.push(message + "\n");
          }
          if (
            validateData[12].trim().toUpperCase() != "TRUE" &&
            validateData[12].trim().toUpperCase() != "FALSE"
          ) {
            message = com("messages.MS0011", {
              rowNum: t("row") + "" + Number(validateData[13] + 1),
              param1: t("mail_receive_notification"),
            });
            errorData.push(message + "\n");
          }
        } else {
          message = com("messages.MS0045", {});
          errorData.push(message + "\n");
          setErrMessageList(errorData);
          return errorData;
        }
      }
      setErrMessageList(errorData);
      return errorData;
    }
  };

  // get DZUserInfo new data
  const getDZUserInfoNewData = (editList: any) => {
    return {
      given_name: editList[0].trim(),
      family_name: editList[1].trim(),
      middle_name: editList[2].trim(),
      affiliate_cd: editList[3].trim(),
      primary_locale: editList[4].trim(),
      tel_no: editList[5].trim(),
      mail_address: editList[6].trim(),
      use_repair: false,
      use_dz: true,
      user_block: false,
      userinfodz: {
        customer_no: editList[9].trim(),
        role_id: editList[10].trim(),
        sms_receive_notification:
          editList[11].trim().toUpperCase() === "TRUE" ? true : false,
        mail_receive_notification:
          editList[12].trim().toUpperCase() === "TRUE" ? true : false,
      },
    };
  };

  const DZUserInfoModelWindow = (
    <div className={myClasses.divWidth}>
      {successAndErrorMessageList.length > 0 && (
        <h2 className={myClasses.title}>{t("error")}</h2>
      )}
      <p className={myClasses.message}>{successAndErrorMessageList}</p>
    </div>
  );

  const DZUserInfoModelWindowButton = (
    <div className={myClasses.buttonControl}>
      <Button
        className={myClasses.buttonWidth}
        variant="contained"
        onClick={handleSuccessAndErrorMessageClose}
        color="primary"
      >
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  const errorHandler = (e: any) => {
    if (e.target.error.name == "NotReadableError") {
      alert("Canno't read file !");
    }
  };

  // advanced button click event
  const [advancedShowFlg, setAdvancedShowFlg] = useState(true);
  const handleAdvancedEvent = () => {
    setAdvancedShowFlg(!advancedShowFlg);
  };

  const changeAdvanced = () => {
    return advancedShowFlg ? <AddIcon /> : <MinimizeIcon />;
  };

  // set search value
  const setSearchValue = (property: any, value: any) => {
    setSearchValueList((preSearchValueList) => {
      return Object.assign({}, preSearchValueList, { [property]: value });
    });
  };

  // focus out customer No.
  const onBlurOfCustomerNo = () => {
    setSearchValue("customer_no_error", "");
    if (isEmptyStringOrNumber(searchValueList.customer_no)) {
      const customerValue = {
        customer_name: "",
        customer_no_error: "",
      };
      const valueList = Object.assign({}, searchValueList, customerValue);
      setSearchValueList(valueList);
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(searchValueList.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_number"),
            });
            setSearchValue("customer_no_error", message);
          } else {
            if (response.customer_closed === true) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages.MS0085", {
                  param1: response.customer_no,
                }),
                alertType: "warning",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            }
            const customerValue = {
              customer_name: response.customer_name,
              // customer_no: response.customer_no,
              customer_no_error: "",
            };
            const valueList = Object.assign({}, searchValueList, customerValue);
            setSearchValueList(valueList);
          }
        });
    }
  };

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      const obj: any = {
        no: alertMessageList.length,
        message: com("messages.MS0085", {
          param1: row.customer_no,
        }),
        alertType: "warning",
      };
      let alertMessageListNew: any = [obj];
      setAlertMessageList(alertMessageListNew);
    }
    const customerValue = {
      customer_name: row.customer_name,
      customer_no: row.customer_no,
    };
    const valueList = Object.assign({}, searchValueList, customerValue);
    setSearchValueList(valueList);
  };

  // created date from
  const [createdDateFromFlag, setCreatedDateFromFlag] = useState(true);
  const onCreatedDateFromClose = () => {
    setCreatedDateFromFlag(false);
  };
  const onCreatedDateFromOpen = () => {
    setCreatedDateFromFlag(true);
  };

  useEffect(() => {
    if (!createdDateFromFlag) {
      onBlurOfCreatedDateFrom();
    }
  }, [createdDateFromFlag]);

  // created date to
  const [createdDateToFlag, setCreatedDateToFlag] = useState(true);
  const onCreatedDateToClose = () => {
    setCreatedDateToFlag(false);
  };
  const onCreatedDateToOpen = () => {
    setCreatedDateToFlag(true);
  };

  // check date from
  const checkCreatedDateFrom = (date: any) => {
    if (
      searchValueList.created_date_to > 0 &&
      date > searchValueList.created_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_created_date_from"),
        endDate: t("txt_created_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        created_date_from_error: message,
        created_date_from: date,
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        created_date_from_error: "",
        created_date_from: date,
      });
      setSearchValueList(valueList);
    }
  };

  // check date to
  const checkCreatedDateTo = (date: any) => {
    if (
      searchValueList.created_date_from > 0 &&
      searchValueList.created_date_from > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_created_date_from"),
        endDate: t("txt_created_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        created_date_from_error: message,
        created_date_to: date,
        created_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        created_date_from_error: "",
        created_date_to: date,
        created_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  //focus out date from
  const onBlurOfCreatedDateFrom = () => {
    console.log("onBlurOf CreatedDate from", searchValueList.created_date_from);
    if (
      searchValueList.created_date_to > 0 &&
      searchValueList.created_date_from > 0 &&
      searchValueList.created_date_from > searchValueList.created_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_created_date_from"),
        endDate: t("txt_created_date_to"),
      });
      setSearchValue("created_date_from_error", message);
    } else {
      setSearchValue("created_date_from_error", "");
    }
  };

  //focus out date to
  const onBlurOfCreatedDateTo = () => {
    console.log("onBlurOf EntryDate to", searchValueList.created_date_from);
    if (
      searchValueList.created_date_to > 0 &&
      searchValueList.created_date_from > 0 &&
      searchValueList.created_date_from > searchValueList.created_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_created_date_from"),
        endDate: t("txt_created_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        created_date_from_error: message,
        created_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        created_date_from_error: "",
        created_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  // last login date from
  const [lastLoginDateFromFlag, setLastLoginDateFromFlag] = useState(true);
  const onLastLoginDateFromClose = () => {
    setLastLoginDateFromFlag(false);
  };
  const onLastLoginDateFromOpen = () => {
    setLastLoginDateFromFlag(true);
  };

  useEffect(() => {
    if (!lastLoginDateFromFlag) {
      onBlurOfLastLoginDateFrom();
    }
  }, [lastLoginDateFromFlag]);

  // last login date to
  const [lastLoginDateToFlag, setLastLoginDateToFlag] = useState(true);
  const onLastLoginDateToClose = () => {
    setLastLoginDateToFlag(false);
  };
  const onLastLoginDateToOpen = () => {
    setLastLoginDateToFlag(true);
  };

  // check date from
  const checkLastLoginDateFrom = (date: any) => {
    if (
      searchValueList.last_login_date_to > 0 &&
      date > searchValueList.last_login_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_last_login_date_from"),
        endDate: t("txt_last_login_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        last_login_date_from_error: message,
        last_login_date_from: date,
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        last_login_date_from_error: "",
        last_login_date_from: date,
      });
      setSearchValueList(valueList);
    }
  };

  // check date to
  const checkLastLoginDateTo = (date: any) => {
    if (
      searchValueList.last_login_date_from > 0 &&
      searchValueList.last_login_date_from > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_last_login_date_from"),
        endDate: t("txt_last_login_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        last_login_date_from_error: message,
        last_login_date_to: date,
        last_login_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        last_login_date_from_error: "",
        last_login_date_to: date,
        last_login_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  //focus out date from
  const onBlurOfLastLoginDateFrom = () => {
    console.log(
      "onBlurOf LastLoginDate from",
      searchValueList.last_login_date_from
    );
    if (
      searchValueList.last_login_date_to > 0 &&
      searchValueList.last_login_date_from > 0 &&
      searchValueList.last_login_date_from > searchValueList.last_login_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_last_login_date_from"),
        endDate: t("txt_last_login_date_to"),
      });
      setSearchValue("last_login_date_from_error", message);
    } else {
      setSearchValue("last_login_date_from_error", "");
    }
  };

  //focus out date to
  const onBlurOfLastLoginDateTo = () => {
    console.log(
      "onBlurOf LastLoginDate to",
      searchValueList.last_login_date_to
    );
    if (
      searchValueList.last_login_date_to > 0 &&
      searchValueList.last_login_date_from > 0 &&
      searchValueList.last_login_date_from > searchValueList.last_login_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_last_login_date_from"),
        endDate: t("txt_last_login_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        last_login_date_from_error: message,
        last_login_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        last_login_date_from_error: "",
        last_login_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  const getOptionLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  // clear button click event
  const handleClearEvent = () => {
    setAlertMessageList([]);
    props.setAlertMessageList([]);
    const valueList = Object.assign({}, defaultValue, {
      created_date_from: null,
      created_date_to: null,
      last_login_date_from: null,
      last_login_date_to: null,
    });
    setSearchValueList(valueList);
  };

  const conditionSearch = (data: any) => {
    let param_createdDateFrom = "";
    let param_createdDateTo = "";
    let param_lastLoginDateFrom = "";
    let param_lastLoginDateFTo = "";
    let created_date_from = data.created_date_from;
    if (created_date_from !== null) {
      param_createdDateFrom = new Date(created_date_from).toISOString();
    } else {
      param_createdDateFrom = "";
    }

    let created_date_to = data.created_date_to;
    if (created_date_to !== null) {
      param_createdDateTo = new Date(created_date_to).toISOString();
    } else {
      param_createdDateTo = "";
    }

    let last_login_date_from = data.last_login_date_from;
    if (last_login_date_from !== null) {
      param_lastLoginDateFrom = new Date(last_login_date_from).toISOString();
    } else {
      param_lastLoginDateFrom = "";
    }

    let last_login_date_to = data.last_login_date_to;
    if (last_login_date_to !== null) {
      param_lastLoginDateFTo = new Date(last_login_date_to).toISOString();
    } else {
      param_lastLoginDateFTo = "";
    }

    let query_parameters =
      "&customer_no=" +
      encodeURIComponent(data.customer_no) +
      "&name=" +
      encodeURIComponent(data.name) +
      "&mail_address=" +
      encodeURIComponent(data.mail_address) +
      "&created_date_from=" +
      encodeURIComponent(param_createdDateFrom) +
      "&created_date_to=" +
      encodeURIComponent(param_createdDateTo) +
      "&last_login_date_from=" +
      encodeURIComponent(param_lastLoginDateFrom) +
      "&last_login_date_to=" +
      encodeURIComponent(param_lastLoginDateFTo);
    console.log("query_parameters", query_parameters);
    props.search(data, query_parameters);
  };

  const isAllErrMsgmpty = () => {
    let result = false;
    if (
      searchValueList.customer_no_error === "" &&
      searchValueList.name_error === "" &&
      searchValueList.mail_address_error === "" &&
      searchValueList.created_date_from_error === "" &&
      searchValueList.created_date_to_error === "" &&
      searchValueList.last_login_date_from_error === "" &&
      searchValueList.last_login_date_to_error === ""
    ) {
      result = true;
    }
    return result;
  };

  // search button click event
  const handleSearchEvent = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isAllErrMsgmpty()) {
      conditionSearch(searchValueList);
      setPage(0);
    }
  };

  return (
    <div>
      <div className={myClasses.header}>
        <div className={myClasses.leftButton}>
          <MasterMaintenanceSearchBox
            searchKey={searchKey}
            handleChangeKey={handleChangeKey}
            handleSearch={handleSearch}
          />
          <div>
            <Button
              className={classes.buttonAdvance}
              variant="contained"
              startIcon={changeAdvanced()}
              onClick={handleAdvancedEvent}
            >
              {t("btn_Advanced")}
            </Button>
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={myClasses.buttonArea}
            startIcon={<RefreshIcon />}
            onClick={getDzinfoDetailListAPI}
          >
            {t("refresh")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={myClasses.buttonArea}
            startIcon={<CloudDownloadIcon />}
            onClick={downloadDZUserInfoCSV}
          >
            {t("download")}
          </Button>
          <>
            <input
              accept=".csv"
              className={myClasses.input}
              id="contained-button-file1"
              multiple
              type="file"
              onChange={(e: any) => handlerUpload(e.target.files)}
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />
            <label htmlFor="contained-button-file1">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={myClasses.buttonArea}
                startIcon={<BackupIcon />}
              >
                {t("upload")}
              </Button>
            </label>
          </>

          <Button
            variant="contained"
            color="primary"
            onClick={() => props.handleRegister(TabKey.DZUserInfo)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t("register")}
          </Button>
        </div>
      </div>
      <div className={clsx(advancedShowFlg && classes.advancedHideFlg)}>
        <div className={myClasses.row}>
          <div className={myClasses.searchCondition}>
            <dl>
              <dt>{t("customer_number")}</dt>
              <dd
                className={myClasses.dzUserInfoDd}
                data-testid="search_customer_no"
              >
                <Autocomplete
                  id="dzUserInfo_customer_no"
                  freeSolo
                  disableClearable
                  options={commonInfo.customerList}
                  getOptionLabel={getOptionCustomerLabel}
                  filterOptions={filterCustomerOptions}
                  inputValue={String(searchValueList.customer_no)}
                  value={String(searchValueList.customer_no)}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "300px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) => {
                    let isSelected = false;
                    if (option.customer_no && searchValueList.customer_no) {
                      isSelected =
                        option.customer_no.toString().toUpperCase() ===
                        searchValueList.customer_no.toString().toUpperCase();
                    }
                    return isSelected;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      error={searchValueList.customer_no_error !== ""}
                      helperText={searchValueList.customer_no_error}
                      onBlur={onBlurOfCustomerNo}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          conditionSearch(searchValueList);
                        }
                      }}
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {
                      setSearchValue(
                        "customer_no",
                        value.customer_no.toString()
                      );
                    }
                  }}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      const val = value.substr(0, 8);
                      setSearchValue("customer_no", val);
                    }
                  }}
                />
                <IconButton
                  className={myClasses.iconButton1}
                  onClick={handleCSearch}
                  id="btn_claim_list_search"
                  tabIndex={0}
                >
                  <SearchIcon />
                </IconButton>
              </dd>
            </dl>
            <dl>
              <dd className={myClasses.dzUserInfoDd}>
                <p>{searchValueList.customer_name}</p>
              </dd>
            </dl>
            <dl>
              <dt>{t("search_name")}</dt>
              <dd className={myClasses.dzUserInfoDd}>
                <TextField
                  value={searchValueList.name}
                  variant="outlined"
                  onChange={(e) => {
                    setSearchValue("name", e.target.value);
                  }}
                  error={searchValueList.name_error !== ""}
                  helperText={searchValueList.name_error}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      conditionSearch(searchValueList);
                    }
                  }}
                />
              </dd>
            </dl>
            <dl>
              <dt>{t("mail_address")}</dt>
              <dd className={myClasses.dzUserInfoDd}>
                <TextField
                  value={searchValueList.mail_address}
                  variant="outlined"
                  onChange={(e) => {
                    setSearchValue("mail_address", e.target.value);
                  }}
                  error={searchValueList.mail_address_error !== ""}
                  helperText={searchValueList.mail_address_error}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      conditionSearch(searchValueList);
                    }
                  }}
                />
              </dd>
            </dl>
            <dl className={classes.datePickerArea}>
              <dt>{t("created_date")}</dt>
              <dd className={classes.datePickerContainer}>
                <DatePicker
                  value={searchValueList.created_date_from}
                  onChange={(date: any) =>
                    setSearchValue("created_date_from", date)
                  }
                  onAccept={(date: any) =>
                    checkCreatedDateFrom(new Date(date).setHours(0, 0, 0, 0))
                  }
                  className={classes.datePicker}
                  error={searchValueList.created_date_from_error !== ""}
                  helperText={searchValueList.created_date_from_error}
                  onBlur={onBlurOfCreatedDateFrom}
                  onError={(error: any, value: any) => {
                    if (
                      error !== searchValueList.created_date_from_error &&
                      error !== ""
                    ) {
                      setSearchValue("created_date_from_error", error);
                    }
                  }}
                  onOpen={onCreatedDateFromOpen}
                  onClose={onCreatedDateFromClose}
                />
                <span className={classes.datePickerInterval}></span>
                <DatePicker
                  value={searchValueList.created_date_to}
                  onChange={(date: any) => {
                    if (date && date !== "") {
                      setSearchValue(
                        "created_date_to",
                        new Date(date).setHours(23, 59, 59, 999)
                      );
                    } else {
                      setSearchValue("created_date_to", date);
                    }
                  }}
                  onAccept={(date: any) =>
                    checkCreatedDateTo(new Date(date).setHours(23, 59, 59, 999))
                  }
                  className={classes.datePicker}
                  error={searchValueList.created_date_to_error !== ""}
                  helperText={searchValueList.created_date_to_error}
                  onBlur={onBlurOfCreatedDateTo}
                  onError={(error: any, value: any) => {
                    if (
                      error !== searchValueList.created_date_to_error &&
                      error !== ""
                    ) {
                      setSearchValue("created_date_to_error", error);
                    }
                  }}
                  onOpen={onCreatedDateToOpen}
                  onClose={onCreatedDateToClose}
                />
              </dd>
            </dl>
            <dl className={classes.datePickerArea}>
              <dt>{t("last_login_date")}</dt>
              <dd className={classes.datePickerContainer}>
                <DatePicker
                  value={searchValueList.last_login_date_from}
                  onChange={(date: any) =>
                    setSearchValue("last_login_date_from", date)
                  }
                  onAccept={(date: any) =>
                    checkLastLoginDateFrom(new Date(date).setHours(0, 0, 0, 0))
                  }
                  className={classes.datePicker}
                  error={searchValueList.last_login_date_from_error !== ""}
                  helperText={searchValueList.last_login_date_from_error}
                  onBlur={onBlurOfLastLoginDateFrom}
                  onError={(error: any, value: any) => {
                    if (
                      error !== searchValueList.last_login_date_from_error &&
                      error !== ""
                    ) {
                      setSearchValue("last_login_date_from_error", error);
                    }
                  }}
                  onOpen={onLastLoginDateFromOpen}
                  onClose={onLastLoginDateFromClose}
                />
                <span className={classes.datePickerInterval}></span>
                <DatePicker
                  value={searchValueList.last_login_date_to}
                  onChange={(date: any) => {
                    if (date && date !== "") {
                      setSearchValue(
                        "last_login_date_to",
                        new Date(date).setHours(23, 59, 59, 999)
                      );
                    } else {
                      setSearchValue("last_login_date_to", date);
                    }
                  }}
                  onAccept={(date: any) =>
                    checkLastLoginDateTo(
                      new Date(date).setHours(23, 59, 59, 999)
                    )
                  }
                  className={classes.datePicker}
                  error={searchValueList.last_login_date_to_error !== ""}
                  helperText={searchValueList.last_login_date_to_error}
                  onBlur={onBlurOfLastLoginDateTo}
                  onError={(error: any, value: any) => {
                    if (
                      error !== searchValueList.last_login_date_to_error &&
                      error !== ""
                    ) {
                      setSearchValue("last_login_date_to_error", error);
                    }
                  }}
                  onOpen={onLastLoginDateToOpen}
                  onClose={onLastLoginDateToClose}
                />
              </dd>
            </dl>
          </div>
          <div className={classes.buttonContainerArea}>
            <div className={classes.buttonArea2}>
              <Button
                className={classes.buttonCondition}
                disabled={!isAllErrMsgmpty()}
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                onClick={(event) => handleSearchEvent(event)}
                data-testid="search_button"
              >
                {t("btn_search")}
              </Button>
            </div>
            <div className={classes.buttonArea2} data-testid="clear_button">
              <Button
                className={classes.buttonCondition}
                variant="contained"
                onClick={handleClearEvent}
                startIcon={<RefreshIcon />}
              >
                {t("btn_Clear")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <MasterMaintenanceDZUserInfoThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            config={config}
          />
          <MasterMaintenanceDZUserInfoTbody
            data={props.dZUserInfoResultList}
            pageInfo={pageInfo}
            order={order}
            orderBy={orderBy}
            handleEdit={props.handleEdit}
            handleDelete={handleDelete}
            config={config}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        component="div"
        count={props.dZUserInfoResultList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("messages.MS0013")}
      />
      {errMessageList.length > 0 && (
        <MasterMaintenanceErrorMessageBox
          open={errorMessageOpen}
          handleClose={handleErrorMessageClose}
          message={errMessageList}
        />
      )}
      {
        <ModelWindow
          title={showSuccessAndErrorMessage}
          notTitle={true}
          contents={DZUserInfoModelWindow}
          button={DZUserInfoModelWindowButton}
          openFlg={successAndErrorMessageOpen}
          handleCloseEvent={handleSuccessAndErrorMessageClose}
        />
      }
      <CustomerSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </div>
  );
};
