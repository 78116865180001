import React, { useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component

// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";

// common
import { getApiResultStatus } from "../../../modules/common.methods";
import { isEmptyStringOrNumber } from "../../../modules/hooks/UseValication";
import { CustomerSearch } from "../../../modules/components/customerSearch/CSearch";
import { AlertMesssageBox } from "../../../modules/components/alert/AlertMesssageBox";
import { OrderSearchControlButton } from "./Order.SearchControl.Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderTop: "1px solid #CFCFCF",
      borderBottom: "1px solid #CFCFCF",
      paddingBottom: "25px",
      "& dl": {
        paddingLeft: "10px",
        marginBottom: "0px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        minHeight: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
    orderApproved: {
      width: "200px",
    },
    orderConfirmed: {
      width: "200px",
    },
    buttonCondition: {
      marginTop: "38px",
      marginLeft: "10px",
      height: "30px",
    },
    order_no: {
      width: "100px",
    },
    customerNo: {
      width: "120px",
      position: "relative",
      "& .MuiOutlinedInput-input": {
        paddingRight: "30px",
      },
    },
    iconButton: {
      position: "absolute",
      right: "3px",
      top: "7px",
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
    },
    Autocomplete: {
      width: "100%",
    },
  })
);

export const OrderSearchControl = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { orderData, changeOrderData, setOrderData, searchHandler, userPermisssionInfo, api, config } = props;

  // set search value
  const setSearchValue = (property: any, value: any) => {
    setOrderData((orderData: any) => {
      return Object.assign({}, orderData, { [property]: value });
    });
  };

  // focus out customer No.
  const onBlurOfCustomerNo = () => {
    setSearchValue("customer_no_error", "");
    if (isEmptyStringOrNumber(orderData.customer_no)) {
      props.setAlertShow(false);
      props.setAlertMessage("");
      props.setAlertType("");
      const customerValue = {
        customer_no_error: "",
      };
      const valueList = Object.assign({}, orderData, customerValue);
      setOrderData(valueList);
    } else {
      //API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(orderData.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_no"),
            });
            setSearchValue("customer_no_error", message);
          } else {
            if (response.customer_closed === true) {
              const message = com("messages.MS0085");
              props.setAlertShow(true);
              props.setAlertMessage(message);
              props.setAlertType("warning");
            const customerValue = {
              customer_no_error: "",
            };
            const valueList = Object.assign({}, orderData, customerValue);
            setOrderData(valueList);
          }
        }
        });
    }
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      const message = com("messages.MS0085");
      props.setAlertShow(true);
      props.setAlertMessage(message);
      props.setAlertType("warning");
    }
    const customerValue = {
      customer_no: row.customer_no,
    };
    const valueList = Object.assign({}, orderData, customerValue);
    setOrderData(valueList);
  };

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  const isAllErrMsgmpty = () => {
    let result = false;
    if (
      orderData.customer_no_error === ""
    ) {
      result = true;
    }
    return result;
  };

  return (
    <>
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <dl>
          <dt>{t("order_no")}</dt>
          <dd>
            <TextField
              data-testid="order_no"
              value={orderData.order_no}
              variant="outlined"
              onChange={(e: any) => {
                const value = e.target.value
                  .replace(/[^\d]/g, "")
                  .substr(0, 10);
                changeOrderData("order_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  props.searchHandler();
                }
              }}
              className={classes.order_no}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("order_approved")}</dt>
          <dd className={classes.orderApproved}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                multiple
                id="service_center"
                value={orderData.order_approved}
                onChange={(e: any) => {
                  changeOrderData("order_approved", e.target.value);
                }}
                disabled={orderData.order_no}
                renderValue={() => ""}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {Array.isArray(com("order_approved")) ? (
                  com("order_approved").map((approved: any) => (
                    <MenuItem key={approved.code} value={approved.code}>
                      <Checkbox
                        color="primary"
                        checked={
                          orderData.order_approved.indexOf(approved.code) > -1
                        }
                      />
                      <ListItemText primary={approved.name} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
        <dl>
          <dt>{t("order_confirmed")}</dt>
          <dd className={classes.orderConfirmed}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                multiple
                id="service_center"
                value={orderData.order_confirmed}
                onChange={(e: any) => {
                  changeOrderData("order_confirmed", e.target.value);
                }}
                disabled={orderData.order_no}
                renderValue={() => ""}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {Array.isArray(com("order_confirmed")) ? (
                  com("order_confirmed").map((confirmed: any) => (
                    <MenuItem key={confirmed.code} value={confirmed.code}>
                      <Checkbox
                        color="primary"
                        checked={
                          orderData.order_confirmed.indexOf(confirmed.code) > -1
                        }
                      />
                      <ListItemText primary={confirmed.name} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
        <dl>
          <dt>{t("customer_no")}</dt>
          <dd className={classes.customerNo}>
            <Autocomplete
              id="customer_no"
              freeSolo
              disableClearable
              className={classes.Autocomplete}
              options={props.commonInfo.customerList}
              getOptionLabel={getOptionCustomerLabel}
              filterOptions={filterCustomerOptions}
              inputValue={String(orderData.customer_no)}
              value={String(orderData.customer_no)}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "300px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) => {
                let isSelected = false;
                if (option.customer_no && orderData.customer_no) {
                  isSelected =
                    option.customer_no.toString().toUpperCase() ===
                    orderData.customer_no
                      .toString()
                      .toUpperCase();
                }
                return isSelected;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={orderData.customer_no_error !== ""}
                  helperText={orderData.customer_no_error}
                  onBlur={onBlurOfCustomerNo}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  changeOrderData(
                    "customer_no",
                    value.customer_no.toString()
                  );
                }
              }}
              onInputChange={(
                event: object,
                value: string,
                reason: string
              ) => {
                if (reason === "input" || reason === "clear") {
                  const val = value.substr(0, 8);
                  changeOrderData("customer_no", val);
                }
              }}
            />
            <IconButton
              className={classes.iconButton}
              onClick={handleCSearch}
              id="btn_order_list_search"
              tabIndex={0}
            >
              <SearchIcon />
            </IconButton>
          </dd>
        </dl>
        <div>
          {userPermisssionInfo.csd_order_list_search_authFlg && (
            <Button
              data-testid="btn_Search"
              className={classes.buttonCondition}
              disabled={!isAllErrMsgmpty()}
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={searchHandler}
            >
              {t("btn_Search")}
            </Button>
          )}
        </div>
        <CustomerSearch
          CSearchOpen={CSearchOpen}
          CSearchClose={CSearchClose}
          handleSend={(row: any) => {
            getCustomerRow(row);
          }}
      />
      </div>
      <OrderSearchControlButton
        downloadCSVBySearch={props.downloadCSVBySearch}
        orderData={orderData}
        setOrderData={setOrderData}
        changeOrderData={changeOrderData}
    />
    </form>
    
    </>
  );
};
