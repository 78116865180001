import React, { useState, useCallback, useEffect } from "react";
import { SummaryUsedPartsTableTbody } from "./Summary.UsedParts.Table.Tbody";
import { SummaryUsedPartsTableThead } from "./Summary.UsedParts.Table.Thead";
// UI
import { useStyles } from "../hooks/usedParts.userStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

import { SummaryUsedPartsHistoryTableTbody } from "./Summary.UsedParts.History.Table.Tbody";

interface Data {
  warrantyCharges: string;
  laborTime: string;
  freight: string;
  partsQtyUsed: string;
  partsAmount: string;
  partsVAT: string;
  partsSubtotal: string;
}

export const SummaryUsedPartdTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const {
    dataList,
    formList,
    partsListAll,
    changesBeforeCurrency,
    changesBeforeList,
  } = props;

  const getLength = (list: any) => {
    return list.length > 0
      ? list.filter((item: any) => !partsListAll.includes(item.item_no))
      : [];
  };

  return (
    <form noValidate autoComplete="off">
      <div>
        <div className={classes.box}>{t("summary_use_parts")}</div>
        <SummaryUsedPartsTableThead />
        <SummaryUsedPartsTableTbody
          dataList={dataList}
          formList={formList}
          partsListAll={partsListAll}
        />
      </div>
      {getLength(changesBeforeList).length > 0 && (
        <div>
          <div className={classes.box}>{t("summary_changes_parts_title")}</div>
          <SummaryUsedPartsTableThead />
          <SummaryUsedPartsHistoryTableTbody
            dataList={changesBeforeList}
            changesBeforeCurrency={changesBeforeCurrency}
            partsListAll={partsListAll}
          />
        </div>
      )}
    </form>
  );
};
