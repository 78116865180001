import React from "react";
import clsx from "clsx";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "center",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& th:nth-child(4)": {
        width: "350px",
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowHeader: {
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "rgba(240, 242, 244)",
      },
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
  checkFlg?: boolean;
}

const headCells: HeadCell[] = [
  { id: "customer_type", label: "customer_type" },
  { id: "customer_no", label: "promotion_customer_no" },
  { id: "customer_name", label: "customer_name" },
  { id: "discount_code", label: "customer_discount_code" },
  { id: "csac1", label: "csac1", checkFlg: true },
  { id: "csac2", label: "csac2", checkFlg: true },
  { id: "csac3", label: "csac3", checkFlg: true },
  { id: "csac4", label: "csac4", checkFlg: true },
  { id: "csac5", label: "csac5", checkFlg: true },
  { id: "csac6", label: "csac6", checkFlg: true },
  { id: "csac7", label: "csac7", checkFlg: true },
  { id: "csac8", label: "csac8", checkFlg: true },
  { id: "csac9", label: "csac9", checkFlg: true },
  { id: "csac10", label: "csac10", checkFlg: true },
  { id: "promotion_region", label: "promotion_region", checkFlg: true },
];

export const PromotionDetailCustomerTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox">
          <Checkbox
            data-testid="customer_allCheckbox"
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
            disabled={props.reviewFlg}
          />
        </TableCell>
        {headCells.map((headCell: any) =>
          (props.salesAnalysisList.customer_list &&
            props.salesAnalysisList.customer_list.length > 0 &&
            props.salesAnalysisList.customer_list.includes(headCell.id)) ||
          !headCell.checkFlg ? (
            <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};
