import React, { useState, useEffect } from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// component
import { ModelTableThead } from "./Report.Model.Table.Thead";
import { ModelTableTbody } from "./Report.Model.Table.Tbody";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const ModelTable = (props: any) => {
  const {
    data
  } = props;
  const classes = useStyles();
  // order
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(["service_center", "technician_code"]);
  const [orderFlg, setOrderFlg] = useState(false);
  useEffect(() => {
    // init search
    if (props.needResetOrderBy) {
      setOrderBy(["service_center", "technician_code"]);
      setOrder("desc");
      if (orderFlg) {
        setOrderFlg(true);
      }
      props.setNeedResetOrderBy(false);
    }
  }, [props.needResetOrderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy.indexOf(property) > -1 && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy([property]);
  };

  return (
    <div>
        <TableContainer>
          <Table className={classes.table}>
            <ModelTableThead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <ModelTableTbody
              data={data}
              order={order}
              orderBy={orderBy}
              commonInfo={props.commonInfo}
            />
          </Table>
        </TableContainer>
    </div>
  );
};
