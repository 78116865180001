import React, { useState, useEffect } from "react";
// hooks
import { useStyles } from "../hooks/configuration.useStyles";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { useLocale } from "../../../modules/hooks/UseLocale";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import api from "../../../modules/api/Api";
import { useNotitificationContext } from "../../../modules/hooks/NotificationContext";
import { Sum } from "../../../modules/constants/common.constants";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      "& th": {
        width: "100px",
      },
    },
  })
);

export const ServiceCenterSelect = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.configuration" });
  const classes = useStyles();
  const myClasses = myStyles();
  const [initFlg, setInitFlg] = useState(true);
  const {
    setCommonInfo,
    getCommonInfo,
    getCommonConfig,
    setCommonInfoFull,
    getUserInfoRepair,
    setUserInfoRepair,
  } = useCommonInfo();
  const commonInfo = getCommonInfo() as any;
  const config = getCommonConfig() as any;
  const userInfoRepair = getUserInfoRepair() as any;
  const [userServicenter, setUserServiceCenter] = useState("");
  const [updateDate, setupdateDate] = useState("");
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const [isloadingCompleted, setIsloadingCompleted] = useState(false);
  const [selectCompleted, setSelectCompleted] = useState(false);
  // Notification
  const { locationControl, setNumberOfLocationControl } =
    useNotitificationContext();

  const successMessage = (type: any) => {
    let message = "";
    switch (type) {
      case "patch":
        message = com("MS0015");
        break;
      default:
        message = com("MS0015");
        break;
    }
    props.setAlertShow(true);
    props.setAlertMessage(message);
    props.setAlertType("success");
  };

  const errorMessage = (response: any) => {
    props.setAlertShow(true);
    const message = com(response.message_id, {
      param1: t(response.key),
    });
    props.setAlertMessage(message);
    props.setAlertType("error");
  };

  useEffect(() => {
    // init search
    if (initFlg && config.token) {
      setInitFlg(false);
      let masterServiceCenterList: any = [];
      let localUserInfoRepair = {};

      let serviceCenterListApi;
      let userInfoRepairApi;

      // fetch master data
      // service center master AM0705
      serviceCenterListApi = api
        .getServiceCenterDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0705 response: ", response);
          if (code !== 200) {
            props.setAlertMessage(
              com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              })
            );
          } else {
            masterServiceCenterList = response;
          }
        });

      // Read UserInfo API For Userinfo Repair AM0108

      userInfoRepairApi = api
        .getReadUserInfoAPIForUserinfoRepair(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0108 response: ", response);
          if (code !== 200) {
            props.setAlertMessage(
              com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              })
            );
          } else {
            localUserInfoRepair = response;
          }
        });

      Promise.all([serviceCenterListApi, userInfoRepairApi]).then(() => {
        setCommonInfoFull({
          serviceCenterList: masterServiceCenterList,
        });
        setUserInfoRepair(localUserInfoRepair);
        setIsloadingCompleted(true);
      });
    }
    if (isloadingCompleted && !selectCompleted) {
      setUserServiceCenter(userInfoRepair.service_center);
      setupdateDate(userInfoRepair.updated_date);
      setSelectCompleted(true);
    }
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserServiceCenter(event.target.value as string);
  };

  const handleSave = () => {
    apiLoading(true);
    props.setAlertShow(false);
    const data = {
      service_center: userServicenter,
      updated_date: updateDate,
    };

    // AM0109
    api
      .updateUserInfoAPIForUserinfoRepair(data, config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("UserinfoRepair response", response);
        if (getApiResultStatus(code)) {
          getUserinfoAfterUpdate("patch");
        } else {
          errorMessage(response);
        }
      });
  };
  // AM0108
  const getUserinfoAfterUpdate = (type: any = "") => {
    api
      .getReadUserInfoAPIForUserinfoRepair(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("ReadUserInfoAPI response", response);
        if (getApiResultStatus(code)) {
          setupdateDate(response.updated_date);
          setUserInfoRepair(response);
          successMessage(type);

          // config.repairShowFlag Repair部分が表示・非表示用フラグ
          if (config.repairShowFlag) {
            getLocationDatalListAPI();
          }
        } else {
          errorMessage(response);
        }
      });
  };

  // AT0115
  const getLocationDatalListAPI = () => {
    return api
      .getRepairJobLocationControlAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AT0115 LocationDatal", response);
        let list = response;
        if (getApiResultStatus(code)) {
          if (response) {
            if (!userInfoRepair.service_center) {
              api
                .getReadUserInfoAPIForUserinfoRepair(config)
                .then(({ response, code }: { response: any; code: any }) => {
                  console.log("AM0108 response: ", response);
                  if (code !== 200) {
                    errorMessage(response);
                  } else {
                    setUserInfoRepair(response);
                    getServiceCenterInformation(response, list);
                  }
                });
            } else {
              getServiceCenterInformation(userServicenter, list);
            }
          }
        } else {
          errorMessage(response);
        }
      });
  };

  const getServiceCenterInformation = (data: any, list: any) => {
    const service_center = userServicenter;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.service_center_id === service_center) {
        setNumberOfLocationControl(
          Sum(
            item.repair.total_num,
            item.send_back.total_num,
            item.dispose.total_num
          )
        );
        apiLoading(false);
      }
    }
  };

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("groupTitle_ServiceCenter")}</h3>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t("serciceCenter")}</th>
            <td>
              <FormControl variant="outlined">
                <Select
                  id="demo-simple-select-outlined"
                  value={userServicenter}
                  onChange={handleChange}
                  native
                >
                  {commonInfo.serviceCenterList.map((servicecenter: any) => (
                    <option
                      key={servicecenter.service_center_id}
                      value={servicecenter.service_center_id}
                    >
                      {servicecenter.address_1}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button
                className={classes.buttonPosition}
                variant="contained"
                color="primary"
                component="span"
                onClick={handleSave}
              >
                {t("btn_Save")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
