import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "0px",
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        color: "red",
        marginLeft: "0px",
      },
      "& .MuiButton-startIcon": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& .react-tel-input .form-control": {
        width: "100% !important",
      },
    },
    textWidth: {
      width: "100% !important",
    },
    root02: {
      "& .MuiTableCell-root": {
        padding: "5px 10px",
      },
    },
    rootEndUser: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        color: "red",
        marginLeft: "0px",
      },
      "& .MuiButton-startIcon": {
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    tableUserInfo: {
      width: "100%",
      margin: "0 auto",
      borderCollapse: "separate",
      borderSpacing: "10px",
      "& th": {
        width: "200px",
        padding: "5px 0",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        //  paddingBottom: "10px",
        width: "13%",
      },
      "& .MuiIconButton-root": {
        padding: "0px 7px",
      },
      "& .MuiIconButton-label": {
        marginTop: "2px",
      },
    },
    tableToolInfo: {
      width: "100%",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "200px",
        padding: "5px 0",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "25px",
        width: "13%",
        display: "contents",
      },
      "& .MuiIconButton-root": {
        padding: "0px 7px",
      },
      "& .MuiIconButton-label": {
        marginTop: "2px",
      },
    },
    tableUserInfo02: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "185px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingLeft: "5px",
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "20px",
      },
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& th:last-child": {
        textAlign: "left",
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& td:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    mark: {
      color: "red",
    },
    mark2: {
      color: "gray",
    },
    textField: {
      width: "100%",
      //  height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        //   height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    device: {
      display: "flex",
    },
    textFieldToolDevice: {
      flex: "1 1 auto",
      paddingBottom: "6px",
      width: "90%",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    textFieldEnderuserNo: {
      width: "78%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    textFieldForCustomer: {
      width: "30%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    vertialAlignTop: {
      verticalAlign: "top",
    },
    deviceHour: {
      marginLeft: "5px",
      fontSize: "large",
      fontWeight: "normal",
    },
    displayFlexArea: {
      display: "flex",
    },
    inputContainer: {
      paddingRight: "5px",
      width: "120px",
      minWidth: "120px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    inputNameContainer: {
      flex: "1 1 auto",
      overflow: "hidden",
      wordBreak: "break-all",
      fontSize: "0.75rem",
      marginTop: "3px",
      textAlign: "left",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    textArea: {
      width: "100%",
      height: "130px",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px 0 5px 5px",
      },
    },
    textAreaForCustomer: {
      width: "100%",
      height: "80px",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px 0 5px 5px",
      },
    },
    selectContainer: {
      width: "100%",
      //height: "30px",
    },
    selectContainer02: {
      width: "48%",
      // height: "30px",
    },
    select: {
      height: "30px",
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    checkboxLayout: {
      padding: "0px 0px !important",
    },
    checkboxLayoutTool: {
      marginTop: "-3px",
      float: "left",
    },
    enderUserBtn: {
      width: "35px",
      height: "30px",
      minWidth: "20px",
      marginLeft: "9px",
      marginTop: "-2px",
    },
    buttonContainer: {
      width: "100%",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
    },
    buttonWidth: {
      height: "38px",
      width: "183px",
      marginLeft: "-10px",
    },
    buttonMyContacts: {
      height: "38px",
      marginLeft: "8px",
    },
    buttonPrevious: {
      height: "38px",
      marginRight: "20px",
    },
    buttonNext: {
      height: "38px",
      marginRight: "40px",
    },
    importBox: {
      display: "flex",
      justifyContent: "flex-end",
    },
    importEnduserTitle: {
      marginRight: "10px",
      whiteSpace: "nowrap",
      marginTop: "8px",
    },
    autoComplete: {
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
          lineHeight: "30px",
          height: "30px",
        },
    },
    phoneNumberInput: {
      height: "30px",
      "& .form-control": {
        width: "100%",
        background: "none",
      },
    },
    groupArea: {
      border: "1px solid #767676",
      padding: "10px 5px 5px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "12px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
    },
    customerNoStyle: {
      paddingBottom: "5px !important",
    },
    popover: {
      pointerEvents: "none",
      marginLeft: "40px",
    },
    paper: {
      padding: theme.spacing(1),
    },
    hoverBtn: {
      marginTop: "5px",
      color: "#979797",
      marginLeft: "7px",
    },
    checkboxThBox: {
      width: "70px !important",
    },
    checkboxTdBox: {
      // width: "217px !important",
      // paddingBottom: "15px",
      marginLeft: "0px",
      paddingLeft: "40px",
      display: "flex",
    },
    hoverBtnPickUp: {
      marginTop: "-31px",
      color: "#979797",
      marginLeft: "70px",
    },
    hoverBtnComfirm: {
      color: "#979797",
    },
    hoverBtnPickUpForModel: {
      color: "#979797",
      top: "2px",
      right: "-30px",
      position: "absolute",
    },
    tdBox: {
      position: "relative",
    },
    popCard: {
      width: "300px",
    },
    buttonBody: {
      display: "flex",
    },
    thForToolDelivered: {
      padding: "1px 7px !important",
    },
    tdForToolDelivered: {
      marginTop: "-3px",
      padding: "3px 0px",
    },
    freightPosition: {
      display: "flex",
      position: "absolute",
      marginLeft: "-50px",
    },
    freight: {
      marginRight: "15px",
    },
    pickedUpTr: {
      marginRight: "-94px",
      lineHeight: "42px",
    },
    freightTextField: {
      width: "211px",
      height: "30px",
      marginTop: "4px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    informationIcon: {
      display: "flex",
      alignItems: "center",
    },
    multilineTextField: {
      width: "100%",
    },
    groupContainer: {
      maxHeight: 200,
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      borderBottom: "1px solid rgba(198, 209, 221, 0.5)",
      position: "absolute",
      width: "10%",
    },
    groupContainerTool: {
      maxHeight: 200,
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      borderBottom: "1px solid rgba(198, 209, 221, 0.5)",
      position: "absolute",
      width: "15%",
    },
    generalModelLink: {
      color: "#3D899F",
    },
    generalModelLinkTool: {
      color: "#3D899F",
      float: "right",
    },
    datePicker: {
      width: "100%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    checkBoxTh: {
      width: "0px !important",
      padding: "5px 0",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      textAlign: "right",
      verticalAlign: "Top",
      paddingRight: "15px",
      paddingBottom: "20px",
    },
    checkBoxBody: {
      marginLeft: "40px",
      marginRight: "-10px",
      marginTop: "-3px",
    },
    dateBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    datePickerBox: {
      "& .MuiIconButton-root": "0px",
    },
    privacyCheckArea: {
      display: "flex",
    },
    checkboxArea: {
      position: "relative",
      margin: "0",
      padding: "0",
      "& .MuiButtonBase-root": {
        position: "absolute",
        left: "-35px",
      },
    },
    checkboxLink: {
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      paddingLeft: "5px",
    },
  })
);
