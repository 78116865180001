import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      width: "70%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "3px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
      },
    },
    groupContainer: {
      maxHeight: 250,
    },
    groupArea: {
      marginTop: "20px",
      border: "1px solid #767676",
      padding: "20px 10px 10px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "0px",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "13px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
    },
    ServiceCenter: {
      width: "100%",
    },
    buttonPosition: {
      height: "29px",
      position: "absolute",
      marginTop:"3px",
      marginLeft:"10px"
    },
  })
);
