import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "10px 20px 15px 20px",
      flexDirection: "row-reverse",
    },
    area: {
      display: "flex",
      alignItems: "center",
    },
    area02: {
      display: "flex",
      alignItems: "center",
      width:"100%",
    },
    item: {
      "&:not(:last-child)": {
        marginRight: "10px",
      },
      "& span": {
        marginRight: "5px",
      },
    },
    change: {
      display: "flex",
      width:"100%",

    },
    datePickerArea: {
      paddingLeft: "10px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      margin:"0",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePicker: {
      width: "47%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    datePickerInterval: {
      position: "absolute",
      top: "20px",
      left: "50%",
      marginTop: "4px",
      marginLeft: "-4px",
      borderTop: "1px solid #000",
      width: "5px",
      display: "block",
    }, 
  })
);

export const OrderSearchControlButton = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { orderData, setOrderData, changeOrderData } = props;

  const onBlurOfOrderEntryDateFrom = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      orderData.order_entry_date_to > 0 &&
      orderData.order_entry_date_from > 0 &&
      orderData.order_entry_date_from > orderData.order_entry_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_order_entry_date_from"),
        endDate: t("txt_order_entry_date_to"),
      });
      changeOrderData("order_entry_date_from_error", message);
    } else {
      changeOrderData("order_entry_date_from_error", "");
    }
  };

  const onBlurOfOrderEntryDateTo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      orderData.order_entry_date_to > 0 &&
      orderData.order_entry_date_from > 0 &&
      orderData.order_entry_date_from > orderData.order_entry_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_order_entry_date_from"),
        endDate: t("txt_order_entry_date_to"),
      });
      let valueList = Object.assign({}, orderData, {
        order_entry_date_from_error: message,
        order_entry_date_to_error: "",
      });
      setOrderData(valueList);
    } else {
      let valueList = Object.assign({}, orderData, {
        order_entry_date_from_error: "",
        order_entry_date_to_error: "",
      });
      setOrderData(valueList);
    }
  };

  const checkOrderEntryDateFrom = (date: any) => {
    if (
      orderData.order_entry_date_to > 0 &&
      date > orderData.order_entry_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_order_entry_date_from"),
        endDate: t("txt_order_entry_date_to"),
      });
      let valueList = Object.assign({}, orderData, {
        order_entry_date_from_error: message,
        order_entry_date_from: date,
      });
      setOrderData(valueList);
    } else {
      let valueList = Object.assign({}, orderData, {
        order_entry_date_from_error: "",
        order_entry_date_from: date,
      });
      setOrderData(valueList);
    }
  };

  const checkOrderEntryDateTo = (date: any) => {
    if (
      orderData.order_entry_date_from > 0 &&
      orderData.order_entry_date_from > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_order_entry_date_from"),
        endDate: t("txt_order_entry_date_to"),
      });
      let valueList = Object.assign({}, orderData, {
        order_entry_date_from_error: message,
        order_entry_date_to: date,
        order_entry_date_to_error: "",
      });
      setOrderData(valueList);
    } else {
      let valueList = Object.assign({}, orderData, {
        order_entry_date_from_error: "",
        order_entry_date_to: date,
        order_entry_date_to_error: "",
      });
      setOrderData(valueList);
    }
  };

  return (
    <div className={classes.change}>
      <div className={classes.area02}>
        <dl className={classes.datePickerArea}>
          <dt>{t("order_entry_date")}</dt>
          <dd className={classes.datePickerContainer}>
            <DatePicker
              value={orderData.order_entry_date_from}
              onChange={(date: any) => 
                changeOrderData("order_entry_date_from", date)              
              }
              onAccept={(date: any) =>
                checkOrderEntryDateFrom(new Date(date).setHours(0, 0, 0, 0))
              }
              className={classes.datePicker}
              error={orderData.order_entry_date_from_error !== ""}
              helperText={orderData.order_entry_date_from_error}
              onBlur={(e: any) => onBlurOfOrderEntryDateFrom(e)}
              onError={(error: any, value: any) => {
                if (
                  error !== orderData.order_entry_date_from_error &&
                  error !== ""
                ) {
                  changeOrderData("order_entry_date_from_error", error);
                }
              }}
            />
            <span className={classes.datePickerInterval}></span>
            <DatePicker
              value={orderData.order_entry_date_to}
              onChange={(date: any) => {
                if (date && date !== "") {
                  changeOrderData(
                    "order_entry_date_to",
                    new Date(date).setHours(23, 59, 59, 999)
                  );
                } else {
                  changeOrderData("order_entry_date_to", date);
                }
              }}
              onAccept={(date: any) =>
                checkOrderEntryDateTo(new Date(date).setHours(23, 59, 59, 999))
              }
              className={classes.datePicker}
              error={orderData.order_entry_date_to_error !== ""}
              helperText={orderData.order_entry_date_to_error}
              onBlur={(e: any) => onBlurOfOrderEntryDateTo(e)}
              onError={(error: any, value: any) => {
                if (
                  error !== orderData.order_entry_date_to_error &&
                  error !== ""
                ) {
                  changeOrderData("order_entry_date_to_error", error);
                }
              }}
            />
          </dd>
        </dl>
      </div>
      <div className={classes.root}>   
        <div className={classes.area}>
          <div className={classes.item}>
            <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={props.downloadCSVBySearch}
            >
            {t("download_to_csv")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
