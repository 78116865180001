import React, { useState, useEffect } from "react";
import { CustomizedLabel } from "../common/customizedLabel";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { Paper, Typography } from "@material-ui/core";

// language
import { useLocale } from "../../../../modules/hooks/UseLocale";

//rgb for the pie-charts
const colors = ["#00BFFF", "#87CEEB", "#F0E68C","#FFB6C1","#FA8072"];

export const BarGraphRepair = ({ title, callData, classes }) => {
  const [barDataRepair, setData] = useState([]);
  useEffect(() => 
  {setData(JSON.parse(callData));
  }, [callData]);
  
  const { t } = useLocale({ defaultPath: "modules.components.report" });


return (

    <Paper elevation={3} className={classes.root}>

        <Typography className={classes.title}  >
        {title}
        </Typography>
        <Typography className={classes.title2} >
        
        <BarChart  
        barSize={100}
        barCategoryGap={-100}
        barGap={-100}
           width={420} height={375}
          data={barDataRepair}
          
          margin={{
            top: 20,
            left:0,
            right:50,
            bottom: 50}}
          >
               
        <Legend 
        layout="vertical" 
        textAlign= 'middle'
        align="center"
        wrapperStyle={{top: 290}} />

        <YAxis
        type="number"
        interval={0}
        allowDataOverflow={true}  />


             < XAxis 
             tick={false}
             type="category"
             interval={0}
             allowDataOverflow={true} />        
           
           <Tooltip cursor={false} wrapperStyle={{ display: "none" }} />
        
        <CartesianGrid  />
        <Bar  
         label={<CustomizedLabel />}
         dataKey={t("chart_component_repaired")} 
         fill="#4dc4ff"
         maxBarSize={200}
        />

         <Bar 
          label={<CustomizedLabel />}  
          dataKey={t("chart_component_outstanding_repair")} 
          fill="#ffcabf" 
          maxBarSize={200} 
        />

        <Bar  
        label={<CustomizedLabel />}
        dataKey={t("chart_component_b_o")} 
        fill="#ff8082" 
        maxBarSize={200} 
        />
        </BarChart>
      </Typography>
    </Paper>
  );
};
