import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell
} from "recharts";
import { Paper, Typography } from "@material-ui/core";

import { getLocale } from "../../../../modules/i18n";

// language
import { useLocale } from "../../../../modules/hooks/UseLocale";

//rgb for the pie-charts
const colors = ["#00BFFF", "#87CEEB", "#F0E68C","#FFB6C1","#FA8072"];

export const BarGraphVertical = ({ title, callData, classes }) => {
  const [barData, setData] = useState([]);
console.log("test",barData);
  useEffect(() => {
    setData(callData);
  }, [callData]);


  const handleHeight = () => {
    return barData.length > 3 ? 200 + barData.length * 14 : 250;
  };
  
  const { t } = useLocale({ defaultPath: "modules.components.report" });


return (
    <Paper elevation={3} className={classes.root}>

        <Typography className={classes.title}  >
        {title}
        </Typography>
        <Typography className={classes.title2} >
        <BarChart
          width={750} height={300}
          data={barData}
          margin={{
            top: 10,
            right: 10,
            left: 50,
            bottom: 80
          }}
          >
          {barData.map((_, index) => (
            <Cell
              key={`slice-${index}`}
              strokeWidth={"1"}
              stroke={null}
              fill={colors[index]}
            />
            ))}

        <Legend verticalAlign="top" iconSize={10}  wrapperStyle={{top: 230, left: 100 }} />
        <CartesianGrid />
        
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={(value) => new Intl.NumberFormat(getLocale()).format(value)} />
        <Bar dataKey={t("repaired_within_24")} stackId="a" fill="rgb(0, 90, 255)" />
        <Bar dataKey={t("repaired_within_48")} stackId="a" fill="rgb(77, 196, 255)" />
        <Bar dataKey={t("repaired_within_72")} stackId="a" fill="rgb(3, 175, 122)" />
        <Bar dataKey={t("repaired_within_5")} stackId="a" fill="rgb(246, 170, 0)" />
        <Bar dataKey={t("outstanding_over_5")} stackId="a" fill= "rgb(255, 128, 130)" />
        </BarChart>
      </Typography>
    </Paper>
  );
};
