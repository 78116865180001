import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { Paper, Typography } from "@material-ui/core";
import { getLocale } from "../../../../modules/i18n";

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;
};

export const BarGraph = ({ title, callData, classes }) => {
  const [data, setData] = useState([]);

    useEffect(() => {
        setData(callData);
    }, [callData]);
  
     return (
      <Paper elevation={3} className={classes.root}>
   <Typography className={classes.title}  >
        {title}
        </Typography>
        <Typography className={classes.title2} >


           <BarChart
            width={750} height={300}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 60,
              bottom: 10
            }}
            layout="vertical"
            stackOffset="expand"
          >           

            <XAxis 
              interval={0}
              type="number"
              ticks={[0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]}
              tickFormatter={(tick) => {
                return `${tick*100}%`;
              }}
              allowDataOverflow={true} >
            </XAxis>
             <YAxis 
              type="category"
              interval={0}
              dataKey="name"
              stroke="#000000"
              fontSize="12"
            />        
           
           <Tooltip formatter={(value) => new Intl.NumberFormat(getLocale()).format(value)} />
            <CartesianGrid />
            
            <Bar dataKey="completed" fill="rgb(77, 196, 255)" stackId="a">
            </Bar>
            <Bar dataKey="failed" fill="rgb(255, 128, 130)" stackId="a">
            </Bar>
          </BarChart>
          </Typography>
      </Paper>
    );
}
