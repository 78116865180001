import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";
import { DEFAULT_VALUE_BLANK } from "../../../modules/constants/claim.constants";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// hook
import { useStyles } from "../hooks/EndUser.style";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";

import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";

// compontent
import { InformationIcon } from "../../../modules/components/informationIcon/InformationIcon";
interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "repairHistory",
    label: "txt_repair_history",
  },
];

export const ClaimDetailToolInformation = (props: any) => {
  const classes = useStyles();
  const {
    formList,
    setFormList,
    dataList,
    setDataList,
    data,
    setFormListValue,
    onBlurOfModelNo,
    onBlurOfSerialNo,
    getRepairHistory,
    setRepairHistoryList,
    tempModelNo,
    setTempModelNo,
    tempSerialNo,
    setTempSerialNo,
    errorType,
    setErrorType,
    formErrorList,
    setFormErrorList,
    setFormErrorListValue,
    getReadModelErrorTypeList,
    onBlurOfpurchasedDate,
    onBlurOfReceivedDate,
    onBlurOfRepairDate,
    getNote,
    valueValicationCheck,
    setStartFlg,
  } = props;

  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getCommonInfo, setCommonInfo } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showItem, setShowItem] = useState(false);
  //hover button
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const open = Boolean(anchorEl);

  const [initFlg, setInitFlg] = useState(true);

  useEffect(() => {
    if (initFlg) {
      setInitFlg(false);
    }
  });

  const handleClickLink = (typeKind: any) => {
    if (typeKind === 2) {
      setStartFlg(false);
      setFormList(JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)));
      setDataList(JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)));
    }
  };

  const localOnBlurOfModelNo = async (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const _model_no = e.target.value;
    if (onBlurOfModelNo()) {
      if (
        (_model_no !== tempModelNo || formList.serial_no !== tempSerialNo) &&
        formList.serial_no !== "" &&
        formList.serial_no !== "*" &&
        _model_no !== ""
      ) {
        apiLoading(true);
        let query_parameters =
          "model_no=" +
          encodeURIComponent(_model_no) +
          "&serial_no=" +
          encodeURIComponent(formList.serial_no) +
          "&claim_no=" +
          encodeURIComponent(formList.claim_no ? formList.claim_no : 0) +
          "&job_no=" +
          encodeURIComponent(0);
        await getRepairHistory(query_parameters);
        apiLoading(false);
        setTempModelNo(formList.model_no);
        setTempSerialNo(formList.serial_no);
      }
      if (_model_no !== "") {
        getReadModelErrorTypeList(encodeURIComponent(_model_no));
      }
    }
  };

  const localOnBlurOfSerialNo = async (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onBlurOfSerialNo()) {
      if (
        (e.target.value !== tempSerialNo ||
          formList.model_no !== tempModelNo) &&
        formList.model_no !== "" &&
        e.target.value !== "" &&
        e.target.value !== "*"
      ) {
        apiLoading(true);
        let query_parameters =
          "model_no=" +
          encodeURIComponent(formList.model_no) +
          "&serial_no=" +
          encodeURIComponent(e.target.value) +
          "&claim_no=" +
          encodeURIComponent(formList.claim_no ? formList.claim_no : 0) +
          "&job_no=" +
          encodeURIComponent(0);
        await getRepairHistory(query_parameters);
        apiLoading(false);
        setTempModelNo(formList.model_no);
        setTempSerialNo(formList.serial_no);
      }
    }
  };

  const serialNoOnChange = (value: any) => {
    if (value) {
      setFormListValue("serial_no", "*");
      setTempSerialNo("*");
      setRepairHistoryList([]);
      setFormErrorListValue("serial_no_error", "");
    } else {
      setFormListValue("serial_no", "");
    }
  };

  const getOptionLabel = (option: any) => option.model_no || "";
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });

  return (
    <>
      <form className={classes.rootEndUser} noValidate autoComplete="off">
        <table className={classes.tableUserInfo}>
          <tbody>
            <tr>
              <th>{t("txt_customer_po_no")}</th>
              <td>
                <div className={classes.tdBox}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    value={formList.customer_po_no}
                    disabled={true}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {t("txt_model_no")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <div className={classes.tdBox}>
                  <div>
                    <Autocomplete
                      disabled
                      className={classes.autoComplete}
                      id="model_no"
                      freeSolo
                      disableClearable
                      options={commonInfo.itemList}
                      getOptionLabel={getOptionLabel}
                      filterOptions={filterOptions}
                      inputValue={formList.model_no}
                      value={formList.model_no}
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          style={{
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                            minWidth: "200px",
                          }}
                          placement="bottom-start"
                        />
                      )}
                      getOptionSelected={(option: any, value: any) =>
                        option.model_no.toString().toUpperCase() ===
                        formList.model_no.toString().toUpperCase()
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          error={
                            formErrorList.model_no_error !== "" &&
                            formErrorList.model_no_error !== undefined
                          }
                          helperText={formErrorList.model_no_error}
                          onBlur={localOnBlurOfModelNo}
                          //onFocus={() => setButtonDisableFlg("focus")}
                        />
                      )}
                      onChange={(event: object, value: any, reason: string) => {
                        if (reason === "select-option") {
                          setFormListValue("model_no", value.model_no);
                        }
                      }}
                      onInputChange={(
                        event: object,
                        value: string,
                        reason: string
                      ) => {
                        if (reason === "input" || reason === "clear") {
                          setFormListValue("model_no", value);
                        }
                      }}
                    />
                  </div>
                  <div className={classes.hoverBtnPickUpForModel}>
                    <InformationIcon imageFile="model_no" />
                  </div>
                </div>
              </td>
              <th>
                {t("txt_serial_no")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <div className={classes.tdBox}>
                  <div>
                    <TextField
                      disabled
                      variant="outlined"
                      error={
                        formErrorList.serial_no_error !== "" &&
                        formErrorList.serial_no_error !== undefined
                      }
                      helperText={formErrorList.serial_no_error}
                      className={classes.textField}
                      value={formList.serial_no}
                      onChange={(e) =>
                        setFormListValue("serial_no", e.target.value)
                      }
                      onBlur={localOnBlurOfSerialNo}
                    />
                  </div>
                  <div className={classes.hoverBtnPickUpForModel}>
                    <InformationIcon imageFile="serial_no" />
                  </div>
                </div>
              </td>

              <td colSpan={2}>
                <div className={classes.checkboxTdBox}>
                  <div>
                    <Checkbox
                      disabled
                      className={classes.checkboxLayoutTool}
                      color="primary"
                      onChange={(e) => serialNoOnChange(e.target.checked)}
                    />
                  </div>
                  <div>{t("serial_no_not_readable")}</div>
                </div>
              </td>
              <th>
                {props.data && props.data.length > 0 && (
                  <TableContainer className={classes.groupContainerTool}>
                    <Table stickyHeader className={classes.root02}>
                      <TableHead className={classes.thead}>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell key={headCell.id}>
                              {t(headCell.label)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tbody}>
                        {props.data.map((row: any, index: number) => {
                          let path;
                          if (row.type_kind === 1) {
                            path =
                              GetPrivateAppRoutePath(PathKey.JOBSDETAIL) +
                              `?jobno=${row.history_no}`;
                          } else {
                            path =
                              GetPrivateAppRoutePath(PathKey.SUMMARY) +
                              `?claimno=${row.history_no}`;
                          }
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell>
                                <span>
                                  {!row.entry_date || row.entry_date === ""
                                    ? ""
                                    : date(new Date(row.entry_date).getTime())}
                                </span>
                                <Link
                                  to={path}
                                  className={classes.generalModelLinkTool}
                                  onClick={() => handleClickLink(row.type_kind)}
                                >
                                  {row.history_no}
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </th>
              <td></td>
            </tr>
            <tr>
              <th>
                {t("txt_condition_of_tool")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                  error={formErrorList.condition_of_tool_error !== ""}
                >
                  <Select
                    disabled
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={formList.condition_of_tool}
                    onChange={(e) =>
                      setFormListValue("condition_of_tool", e.target.value)
                    }
                    onBlur={(e) =>
                      valueValicationCheck(
                        e.target.value,
                        "txt_condition_of_tool",
                        "condition_of_tool_error"
                      )
                    }
                  >
                    {Array.isArray(com("condition_of_tool")) ? (
                      com("condition_of_tool").map((conditionOfTool: any) => (
                        <MenuItem
                          key={conditionOfTool.code}
                          value={conditionOfTool.code}
                        >
                          <ListItemText primary={conditionOfTool.name} />
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                  <FormHelperText>
                    {formErrorList.condition_of_tool_error}
                  </FormHelperText>
                </FormControl>
              </td>
              <th>{t("txt_device_runtime")}</th>
              <td>
                <div className={classes.device}>
                  <TextField
                    disabled
                    variant="outlined"
                    className={classes.textFieldToolDevice}
                    value={formList.device_runtime}
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/[^\d]/g, "")
                        .substr(0, 3);
                      return setFormListValue("device_runtime", value);
                    }}
                  />
                  <span className={classes.deviceHour}>h</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {t("txt_purchased_date")}
                <span className={classes.mark}>*</span>
              </th>
              <td className={classes.datePickerBox}>
                <DatePicker
                  disabled
                  value={formList.purchased_date}
                  onChange={(date: any) =>
                    setFormListValue("purchased_date", date)
                  }
                  onAccept={(date: any) => new Date(date).setHours(0, 0, 0, 0)}
                  className={classes.datePicker}
                  onBlur={onBlurOfpurchasedDate}
                  error={
                    formErrorList.purchased_date_error !== "" &&
                    formErrorList.purchased_date_error !== undefined
                  }
                  helperText={formErrorList.purchased_date_error}
                />
              </td>
              <th>
                {t("txt_received_date")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <DatePicker
                  disabled
                  value={formList.received_date}
                  onChange={(date: any) =>
                    setFormListValue("received_date", date)
                  }
                  onAccept={(date: any) => new Date(date).setHours(0, 0, 0, 0)}
                  className={classes.datePicker}
                  onBlur={onBlurOfReceivedDate}
                  error={
                    formErrorList.received_date_error !== "" &&
                    formErrorList.received_date_error !== undefined
                  }
                  helperText={formErrorList.received_date_error}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("txt_repair_date")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <DatePicker
                  disabled
                  value={formList.repair_date}
                  onChange={(date: any) =>
                    setFormListValue("repair_date", date)
                  }
                  onAccept={(date: any) => new Date(date).setHours(0, 0, 0, 0)}
                  className={classes.datePicker}
                  onBlur={onBlurOfRepairDate}
                  error={
                    formErrorList.repair_date_error !== "" &&
                    formErrorList.repair_date_error !== undefined
                  }
                  helperText={formErrorList.repair_date_error}
                />
              </td>
              <th>{t("txt_manufacture_date")}</th>
              <td>
                <div className={classes.tdBox}>
                  <div className={classes.dateBox}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectContainer02}
                    >
                      <Select
                        disabled
                        id="demo-simple-select-outlined"
                        className={classes.select}
                        value={formList.manufactured_year}
                        onChange={(e) =>
                          setFormListValue("manufactured_year", e.target.value)
                        }
                      >
                        {Array.isArray(com("manufactured_year")) ? (
                          com("manufactured_year").map(
                            (manufacturedYear: any) => (
                              <MenuItem
                                key={manufacturedYear.code}
                                value={manufacturedYear.code}
                              >
                                <ListItemText primary={manufacturedYear.name} />
                              </MenuItem>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.selectContainer02}
                    >
                      <Select
                        disabled
                        id="demo-simple-select-outlined"
                        className={classes.select}
                        value={formList.manufactured_month}
                        onChange={(e) =>
                          setFormListValue("manufactured_month", e.target.value)
                        }
                      >
                        {Array.isArray(com("manufactured_month")) ? (
                          com("manufactured_month").map(
                            (manufacturedMonth: any) => (
                              <MenuItem
                                key={manufacturedMonth.code}
                                value={manufacturedMonth.code}
                              >
                                <ListItemText
                                  primary={manufacturedMonth.name}
                                />
                              </MenuItem>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.hoverBtnPickUpForModel}>
                    <InformationIcon imageFile="manufactured_date" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {t("txt_error_type")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                  error={formErrorList.error_type_error !== ""}
                >
                  <Select
                    disabled
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={formList.error_type}
                    onChange={(e) =>
                      setFormListValue("error_type", e.target.value)
                    }
                    onBlur={(e) =>
                      valueValicationCheck(
                        e.target.value,
                        "txt_error_type",
                        "error_type_error"
                      )
                    }
                  >
                    {Array.isArray(errorType) ? (
                      errorType.map((errorType: any) => (
                        <MenuItem
                          key={errorType.error_type}
                          value={errorType.error_type}
                        >
                          <ListItemText primary={errorType.error_description} />
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                  <FormHelperText>
                    {formErrorList.error_type_error}
                  </FormHelperText>
                </FormControl>
              </td>
              <th>
                {t("txt_guarantee_type")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                  error={formErrorList.guarantee_type_error !== ""}
                >
                  <Select
                    disabled
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={formList.guarantee_type}
                    onChange={(e) =>
                      setFormListValue("guarantee_type", e.target.value)
                    }
                    onBlur={(e) =>
                      valueValicationCheck(
                        e.target.value,
                        "txt_guarantee_type",
                        "guarantee_type_error"
                      )
                    }
                  >
                    {Array.isArray(com("guarantee_type")) ? (
                      com("guarantee_type").map((guaranteeType: any) => (
                        <MenuItem
                          key={guaranteeType.code}
                          value={guaranteeType.code}
                        >
                          <ListItemText primary={guaranteeType.name} />
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                  <FormHelperText>
                    {formErrorList.guarantee_type_error}
                  </FormHelperText>
                </FormControl>
              </td>
            </tr>
            <tr>
              <th>{t("txt_fault_description")}</th>
              <td colSpan={3}>
                <TextField
                  disabled
                  value={getNote(formList.claim_note)}
                  className={classes.multilineTextField}
                  placeholder={t("placeholder_fault_description")}
                  variant="outlined"
                  multiline
                  rows={3}
                  inputProps={{ maxLength: 2048 }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  );
};
