import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export const headCells: HeadCell[] = [
  {
    id: "no",
    label: "customer_number",
    searchFlg: true,
  },
  {
    id: "name",
    label: "customer_name",
    searchFlg: true,
  },
  {
    id: "mail_r",
    label: "mail_address_repair",
    searchFlg: true,
  },
  {
    id: "mem",
    label: "memorandum",
    searchFlg: true,
  },
  {
    id: "sms",
    label: "sms_receive_notification",
    searchFlg: true,
  },
  {
    id: "mail_n",
    label: "mail_receive_notification",
    searchFlg: true,
  },
  {
    id: "asc",
    label: "asc_flg",
    searchFlg: true,
  },
  {
    id: "pic_sort",
    label: "picture",
    searchFlg: true,
  },
  {
    id: "app_sort",
    label: "wco_auto_approve",
    searchFlg: true,
  },
  {
    id: "credit_note_interval_message",
    label: "interval",
    searchFlg: true,
  },
  { id: "Edit", label: "" },
];

export const MasterMaintenanceCustomerMasterThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath = "modules.components.MasterMaintenance.customerMaster";

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
