import { Page as LoginPage } from "./login/LoginPage";
import { Page as DashboardPage } from "./dashboard/DashboardPage";
import { Page as ErrorPage } from "./error/ErrorPage";
import { Page as BlockedErrorPage } from "./error/BlockedErrorPage";
import { Page as Receiving } from "./receiving/Receiving";
import { Page as Jobs } from "./Jobs/Jobs";
import { Page as JobsDetail } from "./JobsDetail/JobsDetail";
import { Page as MasterMaintenance } from "./masterMaintenance/MasterMaintenance";
import { Page as LocationControl } from "./locationControl/LocationControl";
import { Page as Configuration } from "./configuration/Configuration";
import { Page as Shuriken } from "./shuriken/Shuriken";
import { Page as Promotion } from "./promotion/Promotion";
import { Page as PromotionDetail } from "./promotionDetail/PromotionDetail";
import { Page as Order } from "./order/Order";
import { Page as OrderDetail } from "./orderDetail/OrderDetail";
import { Page as Simulate } from "./simulate/Simulate";
import { Page as Drafts } from "./Drafts/Drafts";
import { Page as Report } from "./report/Report";
import { Page as Claim } from "./claim/Claim";
import { Page as ClaimDetail } from "./claimDetail/ClaimDetail";
import { Page as Summary } from "./summary/Summary";
// import { Page as Complete } from "./complete/Complete";
import { Page as Ap } from "./ap/Ap";
import { PrivateContents } from "../modules/components/PrivateContents";

export interface RouteModel {
  key: PathKey;
  path: string;
  component: any;
  isPublic?: boolean;
  title?: string;
}

export enum PathKey {
  LOGIN,
  LOGIN_ERROR,
  PRIVATE,
  DASHBOARD,
  RECEIVING,
  RECEIVING_ERROR,
  JOBS,
  JOBSDETAIL,
  MASTERMAINTENANCE,
  LOCATIONCONTROL,
  Configuration,
  ERROR,
  Shuriken,
  PROMOTION,
  PROMOTIONDETAIL,
  ORDER,
  ORDERDETAIL,
  SIMULATE,
  Drafts,
  REPORT,
  CLAIM,
  CLAIMDETAIL,
  SUMMARY,
  COMPLETE,
  AP,
}

export const AppRoutes: RouteModel[] = [
  // For identify private route, set here
  {
    key: PathKey.PRIVATE,
    path: "/private",
    component: PrivateContents,
  },
  {
    key: PathKey.RECEIVING_ERROR,
    path: "/receiving/error",
    component: ErrorPage,
    isPublic: true,
  },
  {
    key: PathKey.LOGIN,
    path: "/",
    component: LoginPage,
    isPublic: true,
  },
  {
    key: PathKey.LOGIN_ERROR,
    path: "/error",
    component: BlockedErrorPage,
    isPublic: true,
  },

  // Error path must be the end
  {
    key: PathKey.ERROR,
    path: "*",
    component: ErrorPage,
    isPublic: true,
  },
];

// withAuthenticationRequired does not allow passing additional parameters
export const PrivateAppRoutes: RouteModel[] = [
  {
    key: PathKey.DASHBOARD,
    path: "/private/dashboard",
    component: DashboardPage,
  },
  {
    key: PathKey.Configuration,
    path: "/private/configuration",
    component: Configuration,
    title: "Configuration_title",
  },
  {
    key: PathKey.RECEIVING,
    path: "/private/receiving",
    component: Receiving,
    title: "Receiving_title",
  },
  {
    key: PathKey.JOBS,
    path: "/private/jobs",
    component: Jobs,
    title: "Jobs_title",
  },
  {
    key: PathKey.JOBSDETAIL,
    path: "/private/jobDetail",
    component: JobsDetail,
    title: "JobDetail_title",
  },
  {
    key: PathKey.MASTERMAINTENANCE,
    path: "/private/masterMaintenance",
    component: MasterMaintenance,
    isPublic: true,
    title: "masterMaintenance_title",
  },
  {
    key: PathKey.RECEIVING_ERROR,
    path: "/receiving/error",
    component: ErrorPage,
    isPublic: true,
  },
  {
    key: PathKey.LOCATIONCONTROL,
    path: "/private/locationControl",
    component: LocationControl,
    title: "location_title",
  },
  {
    key: PathKey.Shuriken,
    path: "/private/takumiMobile",
    component: Shuriken,
  },
  {
    key: PathKey.PROMOTION,
    path: "/private/promotion",
    component: Promotion,
    title: "promotion_title",
  },
  {
    key: PathKey.PROMOTIONDETAIL,
    path: "/private/promotionDetail",
    component: PromotionDetail,
    title: "promotionDetail_title",
  },
  {
    key: PathKey.ORDER,
    path: "/private/order",
    component: Order,
    title: "order_title",
  },
  {
    key: PathKey.ORDERDETAIL,
    path: "/private/orderDetail",
    component: OrderDetail,
    title: "orderDetail_title",
  },
  {
    key: PathKey.SIMULATE,
    path: "/private/simulate",
    component: Simulate,
    title: "simulate_title",
  },
  {
    key: PathKey.Drafts,
    path: "/private/drafts",
    component: Drafts,
    title: "drafts_title",
  },
  {
    key: PathKey.REPORT,
    path: "/private/report",
    component: Report,
    title: "report_title",
  },
  {
    key: PathKey.CLAIM,
    path: "/private/claim",
    component: Claim,
    title: "claim_title",
  },
  {
    key: PathKey.CLAIMDETAIL,
    path: "/private/claimDetail",
    component: ClaimDetail,
    title: "claimDetail_title",
  },
  {
    key: PathKey.SUMMARY,
    path: "/private/summary",
    component: Summary,
    title: "summary_title",
  },
  // {
  //   key: PathKey.COMPLETE,
  //   path: "/private/complete",
  //   component: Complete,
  //   title: "complete_title",
  // },
  {
    key: PathKey.AP,
    path: "/private/creditNoteList",
    component: Ap,
    title: "ap_title",
  },
  {
    key: PathKey.ERROR,
    path: "/private/*",
    component: ErrorPage,
  },
];

const getRoutePath = (routes: RouteModel[], key: PathKey) => {
  var route = routes.filter((f) => f.key === key);
  if (route.length > 0) {
    // Should be one record
    return route[0].path;
  } else {
    return routes[routes.length - 1].path;
  }
};

export const getAppRouteTitle = (path: string) => {
  var route = PrivateAppRoutes.filter((f) => f.path === path);
  if (route.length > 0) {
    // Should be one record
    return route[0].title;
  }
  return "";
};
export const getAppRouteKey = (path: string) => {
  var route = PrivateAppRoutes.filter((f) => f.path === path);
  if (route.length > 0) {
    // Should be one record
    return route[0].key;
  }
  return "";
};
export const GetAppRoutePath = (key: PathKey) => {
  return getRoutePath(AppRoutes, key);
};

export const GetPrivateAppRoutePath = (key: PathKey) => {
  return getRoutePath(PrivateAppRoutes, key);
};
