import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/control.useStyles";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import "moment/min/locales";
import {
  emailValidation,
  checkMaxLength,
  checkIllegalChar,
  checkIllegalCharUnicode,
  checkIllegalCharResult,
  checkIllegalCharResultUnicode,
  checkTypeNumber,
} from "../../../modules/hooks/UseValication";
// UI
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { illegalCharactersErrorMessage } from "../../../modules/common.methods";

export const SearchControlAdvanced = (props: any) => {
  const classes = useStyles();
  const { getCommonInfo } = useCommonInfo();
  const commonInfo = getCommonInfo() as any;
  const { searchValueList, setSearchValue, setSearchValueList } = props;
  const { t } = useLocale({ defaultPath: "modules.components.jobs" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const illegalChar: any = process.env.REACT_APP_ILLEGAL_CHAR;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // setPersonName(event.target.value as string[]);
    setSearchValue("status", event.target.value);
  };

  const handleQuoteResultChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSearchValue("quote_result", event.target.value);
  };

  const handleTradeInApproveChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSearchValue("trade_in_approve", event.target.value);
  };

  // master exist validation
  const masterExitOfTechnician = () => {
    let isExisted = false;
    let technicians = commonInfo.technicianList;
    if (searchValueList.technician_cd !== "" && technicians.length > 0) {
      for (let index in technicians) {
        if (
          technicians[index].erp_user_id.toUpperCase() ===
          searchValueList.technician_cd.toUpperCase()
        ) {
          isExisted = true;
          setSearchValue("technician_cd", technicians[index].erp_user_id);
          break;
        }
      }
    } else {
      isExisted = true;
    }

    return isExisted;
  };

  // focus out
  const onBlurOfCustomerName = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMaxLength(e.target.value, 50)) {
      let message = com("messages.MS0002", {
        param1: t("txt_customer_name"),
        param2: 50,
      });
      setSearchValue("customer_name_error", message);
    } else {
      setSearchValue("customer_name_error", "");
    }
  };

  const onBlurOfTechnicianCode = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMaxLength(e.target.value, 10)) {
      let message = com("messages.MS0002", {
        param1: t("txt_technician_cd"),
        param2: 10,
      });
      setSearchValue("technician_cd_error", message);
    } else if (!masterExitOfTechnician()) {
      let message = com("messages.MS0004", {
        param1: t("txt_technician_cd"),
      });
      setSearchValue("technician_cd_error", message);
    } else {
      setSearchValue("technician_cd_error", "");
    }
  };

  // const onBlurOfEmailAddress = (
  //   e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  // ) => {
  //   if (!emailValidation(e.target.value)) {
  //     let message = com("messages.MS0040");
  //     setSearchValue("mail_address_error", message);
  //   } else {
  //     setSearchValue("mail_address_error", "");
  //   }
  // };

  const onBlurOfReceiveDateFrom = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.received_date_to > 0 &&
      searchValueList.received_date_from > 0 &&
      searchValueList.received_date_from > searchValueList.received_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_received_date_from"),
        endDate: t("txt_received_date_to"),
      });
      setSearchValue("received_date_from_error", message);
    } else {
      setSearchValue("received_date_from_error", "");
    }
  };

  const onBlurOfReceiveDateTo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.received_date_to > 0 &&
      searchValueList.received_date_from > 0 &&
      searchValueList.received_date_from > searchValueList.received_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_received_date_from"),
        endDate: t("txt_received_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        received_date_from_error: message,
        received_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        received_date_from_error: "",
        received_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  const onBlurOfLocationNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (checkIllegalCharUnicode(e.target.value)) {
      let error_list = illegalCharactersErrorMessage(
        checkIllegalCharResultUnicode(e.target.value)
      );
      let _error_message = "";
      if (error_list.length > 0) {
        error_list.forEach((item: any, index: number) => {
          if (index !== 0) {
            _error_message += ",";
          }
          if (item.flg) {
            _error_message += com(`messages.${item.message_id}`);
          } else {
            _error_message += item.message_id;
          }
        });
      }
      let message = com("messages.MS0093", {
        param1: _error_message,
      });
      setSearchValue("location_no_error", message);
    } else {
      setSearchValue("location_no_error", "");
    }
  };

  const onBlurOfQuoteSentCount = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkTypeNumber(e.target.value)) {
      let message = com("messages.MS0039");
      setSearchValue("quote_sent_count_error", message);
    } else {
      setSearchValue("quote_sent_count_error", "");
    }
  };

  const checkReceiveDateFrom = (date: any) => {
    if (
      searchValueList.received_date_to > 0 &&
      date > searchValueList.received_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_received_date_from"),
        endDate: t("txt_received_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        received_date_from_error: message,
        received_date_from: date,
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        received_date_from_error: "",
        received_date_from: date,
      });
      setSearchValueList(valueList);
    }
  };

  const checkReceiveDateTo = (date: any) => {
    if (
      searchValueList.received_date_from > 0 &&
      searchValueList.received_date_from > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_received_date_from"),
        endDate: t("txt_received_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        received_date_from_error: message,
        received_date_to: date,
        received_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        received_date_from_error: "",
        received_date_to: date,
        received_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  const getOptionLabel = (option: any) => option.customer_name || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });

  const getOptionLabel2 = (option: any) =>
    option.erp_user_id + " : " + option.name || "";

  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    // limit: 10,
    stringify: getOptionLabel2,
  });

  return (
    <>
      <div className={classes.row}>
        <dl>
          <dt>{t("txt_customer_name")}</dt>
          <dd className={classes.customerNmDd}>
            <Autocomplete
              className={classes.autoComplete}
              id="customer_name"
              freeSolo
              disableClearable
              options={commonInfo.customerList}
              getOptionLabel={getOptionLabel}
              filterOptions={filterOptions}
              inputValue={searchValueList.customer_name}
              value={searchValueList.customer_name}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "300px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) =>
                option.customer_name.toUpperCase() ===
                searchValueList.customer_name.toUpperCase()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={searchValueList.customer_name_error !== ""}
                  helperText={searchValueList.customer_name_error}
                  onBlur={onBlurOfCustomerName}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  setSearchValue("customer_name", value.customer_name);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  setSearchValue("customer_name", value);
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_address")}</dt>
          <dd>
            <TextField
              variant="outlined"
              value={searchValueList.address}
              onChange={(e) => setSearchValue("address", e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_city")}</dt>
          <dd>
            <TextField
              variant="outlined"
              value={searchValueList.address_1}
              onChange={(e) => setSearchValue("address_1", e.target.value)}
              inputProps={{ maxLength: 50 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_mail_address")}</dt>
          <dd className={classes.emailDd}>
            <TextField
              variant="outlined"
              value={searchValueList.mail_address}
              onChange={(e) => setSearchValue("mail_address", e.target.value)}
              inputProps={{ maxLength: 254 }}
              error={searchValueList.mail_address_error !== ""}
              helperText={searchValueList.mail_address_error}
              // onBlur={onBlurOfEmailAddress}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_status")}</dt>
          <dd>
            <FormControl variant="outlined">
              <Select
                multiple
                id="status"
                className={classes.searchControlSelect}
                value={searchValueList.status}
                onChange={handleChange}
                renderValue={() => ""}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {Array.isArray(com("status")) ? (
                  com("status").map((status: any) => (
                    <MenuItem key={status.code} value={status.code}>
                      <Checkbox
                        color="primary"
                        checked={
                          searchValueList.status.indexOf(status.code) > -1
                        }
                      />
                      <ListItemText primary={status.name} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_technician_cd")}</dt>
          <dd className={classes.technicianDd}>
            <Autocomplete
              className={classes.autoComplete}
              id="technician_cd"
              freeSolo
              disableClearable
              options={commonInfo.technicianList}
              getOptionLabel={getOptionLabel2}
              filterOptions={filterOptions2}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "250px",
                  }}
                  placement="bottom-start"
                />
              )}
              inputValue={searchValueList.technician_cd}
              value={searchValueList.technician_cd}
              getOptionSelected={(option: any, value: any) =>
                option.erp_user_id.toUpperCase() ===
                searchValueList.technician_cd.toUpperCase()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={searchValueList.technician_cd_error !== ""}
                  helperText={searchValueList.technician_cd_error}
                  onBlur={onBlurOfTechnicianCode}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  setSearchValue("technician_cd", value.erp_user_id);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  setSearchValue("technician_cd", value);
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_order_no")}</dt>
          <dd>
            <TextField
              variant="outlined"
              value={searchValueList.order_no}
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/g, "").substr(0, 8);
                return setSearchValue("order_no", value);
              }}
            />
          </dd>
        </dl>
        <dl className={classes.datePickerArea}>
          <dt>{t("txt_received_date")}</dt>
          <dd className={classes.datePickerContainer}>
            <DatePicker
              value={searchValueList.received_date_from}
              onChange={(date: any) =>
                setSearchValue("received_date_from", date)
              }
              onAccept={(date: any) =>
                checkReceiveDateFrom(new Date(date).setHours(0, 0, 0, 0))
              }
              className={classes.datePicker}
              error={searchValueList.received_date_from_error !== ""}
              helperText={searchValueList.received_date_from_error}
              onBlur={(e: any) => onBlurOfReceiveDateFrom(e)}
              onError={(error: any, value: any) => {
                if (
                  error !== searchValueList.received_date_from_error &&
                  error !== ""
                ) {
                  setSearchValue("received_date_from_error", error);
                }
              }}
            />
            <span className={classes.datePickerInterval}></span>
            <DatePicker
              value={searchValueList.received_date_to}
              onChange={(date: any) => {
                if (date && date !== "") {
                  setSearchValue(
                    "received_date_to",
                    new Date(date).setHours(23, 59, 59, 999)
                  );
                } else {
                  setSearchValue("received_date_to", date);
                }
              }}
              onAccept={(date: any) =>
                checkReceiveDateTo(new Date(date).setHours(23, 59, 59, 999))
              }
              className={classes.datePicker}
              error={searchValueList.received_date_to_error !== ""}
              helperText={searchValueList.received_date_to_error}
              onBlur={(e: any) => onBlurOfReceiveDateTo(e)}
              onError={(error: any, value: any) => {
                if (
                  error !== searchValueList.received_date_to_error &&
                  error !== ""
                ) {
                  setSearchValue("received_date_to_error", error);
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_location")}</dt>
          <dd>
            <TextField
              className={classes.inputWidth120}
              variant="outlined"
              value={searchValueList.location_no}
              onChange={(e) => setSearchValue("location_no", e.target.value)}
              onBlur={onBlurOfLocationNo}
              error={searchValueList.location_no_error !== ""}
              helperText={searchValueList.location_no_error}
              inputProps={{ maxLength: 5 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_quote_sent_count")}</dt>
          <dd>
            <TextField
              className={classes.inputWidth120}
              variant="outlined"
              value={searchValueList.quote_sent_count}
              onChange={(e) =>
                setSearchValue("quote_sent_count", e.target.value)
              }
              onBlur={onBlurOfQuoteSentCount}
              error={searchValueList.quote_sent_count_error !== ""}
              helperText={searchValueList.quote_sent_count_error}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_quote_result")}</dt>
          <dd>
            <FormControl variant="outlined">
              <Select
                multiple
                className={classes.searchControlSelect}
                value={searchValueList.quote_result}
                onChange={handleQuoteResultChange}
                renderValue={() => ""}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {Array.isArray(com("quote_result")) ? (
                  com("quote_result").map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      <Checkbox
                        color="primary"
                        checked={
                          searchValueList.quote_result.indexOf(option.code) > -1
                        }
                      />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_trade_in_approve")}</dt>
          <dd>
            <FormControl variant="outlined">
              <Select
                className={classes.searchControlSelect}
                value={searchValueList.trade_in_approve}
                onChange={handleTradeInApproveChange}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {Array.isArray(com("trade_in_approve")) ? (
                  com("trade_in_approve").map((option: any) => (
                    <MenuItem key={option.code} value={option.code}>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </dd>
        </dl>
      </div>
    </>
  );
};
