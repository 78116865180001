//empty check
export const isEmptyCheck = (value: any) => {
  return !value || Object.keys(value).length === 0;
};

export const isEmptyStringOrNumber = (value: any) => {
  return value === undefined || value === null || value === "";
};

export const isEmptyNumber = (value: number) => {
  return value === undefined || value === null || isNaN(value);
};

//email check
export const emailValidation = (value: any) => {
  let result = false;
  let validator = require('validator');

  if (value) {
    result = validator.isEmail(value, { allow_utf8_local_part: false });
  }
  return result;
};

// min length check
export const checkMinLength = (value: string, minLength: number) => {
  let result = true;
  if (value && value.length < minLength) {
    result = false;
  }
  return result;
};

// max length check
export const checkMaxLength = (value: string, maxLength: number) => {
  let result = true;
  if (value && value.length > maxLength) {
    result = false;
  }
  return result;
};

// digital number check
export const checkTypeNumber = (value: string) => {
  let result = true;
  if (value) {
    result = /^[0-9]+$/.test(value);
  }
  return result;
};

//digital number check without 0
export const checkTypeNumberWithoutZero = (value: string) => {
  let result = true;
  if (value) {
    if (Number(value) === 0) {
      result = false;
    } else {
      result = /^[0-9]+$/.test(value);
    }
  }
  return result;
};

export const checkLength = (value: string, length: number) => {
  let result = true;
  const target = typeof value === "number" ? String(value) : value;
  if (target && target.length === length) {
    result = false;
  }
  return result;
};

const illegalChar: any = process.env.REACT_APP_ILLEGAL_CHAR;
export const checkIllegalChar = (value: string) => {
  let result = true;

  result =
    JSON.parse(illegalChar).filter((n: string) => value.indexOf(n) > -1)
      .length > 0;
  return result;
};

const illegaCharUnicode: any = process.env.REACT_APP_ILLEGAL_CHAR_UNICODE;
export const checkIllegalCharUnicode = (value: string) => {
  let flag = true;
  if (value) {
    const res = new RegExp("[" + illegaCharUnicode + "]+");
    const unicode_flag = value.match(res);
    flag = Boolean(unicode_flag);
  }
  return flag;
};

export const checkIllegalCharResult = (value: string) => {
  let result = true;

  result = JSON.parse(illegalChar).filter((n: string) => value.indexOf(n) > -1);
  return result;
};

export const checkIllegalCharResultUnicode = (value: any) => {
  let arr = [];
  if (value && value.length > 0) {
    const res = new RegExp("[" + illegaCharUnicode + "]+");
    for (let i in value) {
      const unicode_flag = value[i].match(res);
      if (unicode_flag) {
        arr.push(value[i]);
      }
    }
  }
  return arr;
};

const umlautsCharUnicode: any = process.env.REACT_APP_GERMAN_UMLAUTS_CHAR_UNICODE;
export const checkUmlautsCharUnicode = (value: string) => {
  let flag = true;
  if (value) {
    const res = new RegExp("[" + umlautsCharUnicode + "]+");
    const unicode_flag = value.match(res);
    flag = Boolean(unicode_flag);
  }
  return flag;
};


