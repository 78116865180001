import React, { FC, useEffect, useState } from "react";
// route
import { useHistory } from "react-router-dom";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { SearchControl } from "./components/Ap.SearchControl";
import { SearchResult } from "./components/Ap.SearchResult";
import { ContentsMessageBox } from "./components/Ap.Contents.MessageBox";
// common
import { getApiResultStatus } from "../../modules/common.methods";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// components
import { ModelWindow } from "../../modules/components/model/ModelWindow";

// api
import api from "../../modules/api/Api";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
import { isEmptyStringOrNumber } from "../../modules/hooks/UseValication";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../modules/hooks/Permissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: 0,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
  })
);

const defaultValue = {
  credit_note_no: "",
  credit_note_no_error: "",
  customer_po_no: "",
  customer_no_1: "",
  customer_no_1_error: "",
  customer_name_1: "",
  customer_no_2: "",
  customer_no_2_error: "",
  customer_name_2: "",
  ref_no: "",
  claim_no: "",
  ap_from: null as any,
  ap_from_error: "",
  ap_to: null as any,
  ap_to_error: "",
  status: [],
};

const CREDIT_NOTE_NO_QUERY_STRING = "?creditnoteno=";

export const Page: FC = () => {
  const classes = useStyles();

  const {
    setCommonInfo,
    getCommonConfig,
    getUserPermissions,
    setUserPermissions,
    getCommonInfo,
    setCommonInfoFull,
  } = useCommonInfo();
  let config = getCommonConfig() as any;
  let commonInfo = getCommonInfo() as any;

  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;
  // start
  const [startFlg, setStartFlg] = useState(false);
  // search value
  const [searchValueList, setSearchValueList] = useState(
    JSON.parse(JSON.stringify(defaultValue))
  );
  // const [searchDataResult, setSearchDataResult] = useState(data);
  // const [resultData, setResultData] = useState(data);
  const [resultData, setResultData] = useState([]);

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const [issueUpdatedDate, setIssueUpdatedDate] = useState("");
  const [issueCustomerNo, setIssueCustomerNo] = useState("");

  const { t } = useLocale({ defaultPath: "modules.components.ap" });
  const { getName } = useLocale({ defaultPath: "models.model_window" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  // popup
  const [contentsOpen, setCotnentsOpen] = useState(false);
  const [contentsListMessage, setContentsListMessage] = useState([]);
  const [customerPoNoOpen, setCustomerPoNoOpen] = useState(false);
  const [customerPoNoListMessage, setCustomerPoNoListMessage] = useState([]);
  const [alertMessageList, setAlertMessageList] = useState([]);

  // history
  const history = useHistory();
  const getCreditNoteNo = (): string => {
    return history.location.search.indexOf(CREDIT_NOTE_NO_QUERY_STRING) >= 0
      ? history.location.search.replace(CREDIT_NOTE_NO_QUERY_STRING, "")
      : "";
  };

  const CustomerPoNonModelWindow = (
    <div className={classes.divWidth}>
      <p className={classes.message}>
        {customerPoNoListMessage &&
          customerPoNoListMessage.length > 0 &&
          customerPoNoListMessage.map((item, itemIndex) => {
            if (itemIndex === 0) {
              return item;
            } else {
              return <>, {item}</>;
            }
          })}
      </p>
    </div>
  );

  const dateFormat = (data: any) => {
    const formatDoubleDigit = (data: any) => {
      return data >= 10 ? data : "0" + data;
    };
    const getYear = data.getFullYear();
    const getMonth = formatDoubleDigit(data.getMonth() + 1);
    const getDate = formatDoubleDigit(data.getDate());
    return getYear + "-" + getMonth + "-" + getDate;
  };

  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  // set permission of Ap
  const setPermission = async () => {
    let localUserPermissions = {};
    if (userPermissions.permission_group) {
      localUserPermissions = userPermissions;
    } else {
      await getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
    }

    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const tempUserPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(tempUserPermissionsInfo);
  };

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    if(response.message_id == "MA0004"){
      response.message_id = "MS0004"
    }
    const message = com("messages." + response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  //init
  useEffect(() => {
    const _credit_note_no = getCreditNoteNo();
    if (!startFlg && Object.keys(config).length > 0) {
      setStartFlg(true);
      setPermission();
      getCustomerDetailList();
      if (_credit_note_no) {
        handleCreditNoteNo(_credit_note_no);
      } else {
        handleSearchAll();
      }
    }
  }, [startFlg, config]);

  const handleCreditNoteNo = (_credit_note_no: any) => {
    setSearchValue("credit_note_no", _credit_note_no);
    const param = Object.assign({}, defaultValue, {
      credit_note_no: _credit_note_no,
      paramFlag: true,
    });
    handleSearchEvent(param);
  };

  const handleSearchAll = () => {
    handleSearchEvent();
  };

  const getCustomerDetailList = () => {
    if (commonInfo.customerList.length === 0) {
      api
        .getCustomerDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0205 response: ", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setCommonInfoFull({
              customerList: response,
              loadingFlg: false,
            });
          }
        });
    }
  };

  const setSearchValue = (property: any, value: any) => {
    setSearchValueList((preSearchValueList: any) => {
      return Object.assign({}, preSearchValueList, { [property]: value });
    });
  };

  const checkApFrom = (date: any) => {
    if (searchValueList.ap_to > 0 && date > searchValueList.ap_to) {
      let message = com("messages.MS0005", {
        startDate: t("ap_from"),
        endDate: t("ap_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        ap_from_error: message,
        ap_from: date,
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        ap_from_error: "",
        ap_from: date,
      });
      setSearchValueList(valueList);
    }
  };

  const onBlurOfApFrom = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.ap_to > 0 &&
      searchValueList.ap_from > 0 &&
      searchValueList.ap_from > searchValueList.ap_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("ap_from"),
        endDate: t("ap_to"),
      });
      setSearchValue("ap_from_error", message);
    } else {
      setSearchValue("ap_from_error", "");
    }
  };

  const checkApTo = (date: any) => {
    if (searchValueList.ap_from > 0 && searchValueList.ap_from > date) {
      let message = com("messages.MS0005", {
        startDate: t("ap_from"),
        endDate: t("ap_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        ap_from_error: message,
        ap_to: date,
        ap_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        ap_from_error: "",
        ap_to: date,
        ap_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  const onBlurOfApTo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.ap_to > 0 &&
      searchValueList.ap_from > 0 &&
      searchValueList.ap_from > searchValueList.ap_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("ap_from"),
        endDate: t("ap_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        ap_from_error: message,
        ap_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        ap_from_error: "",
        ap_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  const getQueryParameters = (param: any) => {
    let param_select_status = "";
    let param_entryDateFrom = "";
    let param_entryDateTo = "";
    let paramNum = 0;

    const _searchValueList = param && param.paramFlag ? param : searchValueList;

    // status
    if (_searchValueList.status.length > 0) {
      for (let index in _searchValueList.status) {
        param_select_status =
          param_select_status + "&status=" + _searchValueList.status[index];
      }
    } else {
      param_select_status = "&status=";
    }

    // ap from
    let entry_date_from = _searchValueList.ap_from;
    if (entry_date_from !== null) {
      param_entryDateFrom = new Date(entry_date_from).toISOString();
    } else {
      param_entryDateFrom = "";
    }

    // ap to
    let entry_date_to = _searchValueList.ap_to;
    if (entry_date_to !== null) {
      param_entryDateTo = new Date(entry_date_to).toISOString();
    } else {
      param_entryDateTo = "";
    }

    let query_parameters = "";
    if (!isEmptyStringOrNumber(_searchValueList.credit_note_no)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      query_parameters =
        query_parameters +
        "credit_note_no=" +
        encodeURIComponent(_searchValueList.credit_note_no);
      paramNum++;
    }

    if (!isEmptyStringOrNumber(_searchValueList.customer_no_1)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      query_parameters =
        query_parameters +
        "customer_no=" +
        encodeURIComponent(_searchValueList.customer_no_1);
      paramNum++;
    }

    if (!isEmptyStringOrNumber(_searchValueList.customer_po_no)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      query_parameters =
        query_parameters +
        "customer_po_no=" +
        encodeURIComponent(_searchValueList.customer_po_no);
      paramNum++;
    }

    if (!isEmptyStringOrNumber(_searchValueList.ref_no)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      query_parameters =
        query_parameters +
        "ref_no=" +
        encodeURIComponent(_searchValueList.ref_no);
      paramNum++;
    }

    if (!isEmptyStringOrNumber(_searchValueList.claim_no)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      query_parameters =
        query_parameters +
        "claim_no=" +
        encodeURIComponent(_searchValueList.claim_no);
      paramNum++;
    }

    if (!isEmptyStringOrNumber(_searchValueList.ap_from)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      // query_parameters =
      //   query_parameters +
      //   "issue_date_from=" +
      //   encodeURIComponent(dateFormat(new Date(_searchValueList.ap_from)));
      query_parameters =
        query_parameters +
        "issue_date_from=" +
        encodeURIComponent(new Date(_searchValueList.ap_from).toISOString());
      paramNum++;
    }

    if (!isEmptyStringOrNumber(_searchValueList.ap_to)) {
      query_parameters =
        paramNum === 0 ? query_parameters : query_parameters + "&";
      // query_parameters =
      //   query_parameters +
      //   "issue_date_to=" +
      //   encodeURIComponent(dateFormat(new Date(_searchValueList.ap_to)));
      query_parameters =
        query_parameters +
        "issue_date_to=" +
        encodeURIComponent(new Date(_searchValueList.ap_to).toISOString());
      paramNum++;
    }

    // let query_parameters = "customer_no="+encodeURIComponent("789");
    return query_parameters;
  };

  // search button click event
  const handleSearchEvent = (param: any = "") => {
    if (isAllErrMsgmpty()) {
      const query_parameters = getQueryParameters(param);
      setAlertMessage("");
      setAlertShow(false);

      apiLoading(true);
      // AT1706
      api
        .getCreditNotesForSearch(query_parameters, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AT1706 response: ", response);
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
            setSearchDataResult([]);
          } else {
            if (response) {
              if (response.total_num && response.total_num >= 2000) {
                const message = com("messages.MS0049");
                setAlertMessage(message);
                setAlertShow(true);
                setAlertType("warning");
              }

              response.claims_list.forEach((record: any) => {
                if (record.customer_po_no && record.customer_po_no.length > 0) {
                  let naCount = 0;
                  const new_customer_po_no: any[] =
                    record.customer_po_no.filter((item: any) => {
                      if (item === "N/A") {
                        if (naCount === 0) {
                          naCount++;
                          return true;
                        }
                      } else {
                        return true;
                      }
                    });
                  record.customer_po_no = new_customer_po_no;
                }
              });

              setSearchDataResult(response.claims_list);
            }
          }
        });
    }
  };

  // Customer No onBlur event
  const handleCustomerSearch = (query_customer_no: String) => {
    setSearchValue("customer_no_2_error", "");
    setAlertMessage("");
    setAlertShow(false);
    if (isEmptyStringOrNumber(searchValueList.customer_no_2)) {
      const customerValue = {
        customer_name_2: "",
        customer_no_2_error: "",
      };
      const valueList = Object.assign({}, searchValueList, customerValue);
      setSearchValueList(valueList);
    } else {
      api
        .getReadCustomerMasterAPI(query_customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0202 response: ", response);
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_no"),
            });
            setSearchValue("customer_no_2_error", message);
            setSearchValue("customer_name_2", "");
            setIssueUpdatedDate("");
            setIssueCustomerNo("");
          } else {
            if (response) {
              if (!response.asc_flg) {
                const message = com("messages.MS0109", {
                  param1: t("customer_no"),
                  param2: query_customer_no,
                });

                setIssueUpdatedDate("");
                setIssueCustomerNo("");
                setAlertMessage(message);
                setAlertShow(true);
                setAlertType("warning");
                const customerValue = {
                  customer_name_2: response.customer_name,
                  // customer_no_2: response.customer_no,
                  customer_no_2_error: "",
                };
                const valueList = Object.assign(
                  {},
                  searchValueList,
                  customerValue
                );
                setSearchValueList(valueList);
              } else {
                if (response.customer_closed === true) {
                  const message = com("messages.MS0085", {
                    param1: query_customer_no,
                  });
                  setAlertMessage(message);
                  setAlertShow(true);
                  setAlertType("warning");
                }
                const customerValue = {
                  customer_name_2: response.customer_name,
                  // customer_no_2: response.customer_no,
                  customer_no_2_error: "",
                };
                const valueList = Object.assign(
                  {},
                  searchValueList,
                  customerValue
                );
                setSearchValueList(valueList);

                // if (response.total_num && response.total_num >= 2000) {
                //   const message = com("messages.MS0049");
                //   setAlertMessage(message);
                //   setAlertShow(true);
                //   setAlertType("warning");
                // }

                setIssueUpdatedDate(response.updated_date);
                setIssueCustomerNo(response.customer_no);
              }
            }
          }
        });
    }
  };

  // issue button click event
  const handleCreditIssue = () => {
    let data: any = {
      updated_date: issueUpdatedDate,
      wco_credit_note_creation: 1,
    };
    setAlertMessage("");
    setAlertShow(false);

    apiLoading(true);
    // AT1706
    api
      .updateCustomerMasterAPI(issueCustomerNo, data, config)
      .then(({ response, code }: { response: any; code: any }) => {
        apiLoading(false);

        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const message = com("messages.MS0058");
          setAlertMessage(message);
          setAlertShow(true);
          setAlertType("success");

          setIssueUpdatedDate("");
        }
      });
  };

  const setSearchDataResult = (data: any) => {
    //let newData: any = data.list.slice(0);
    let newData: any = data.slice(0);
    setResultData(newData);
  };

  // clear button click event
  const handleClearEvent = () => {
    setSearchValueList(JSON.parse(JSON.stringify(defaultValue)));
    setAlertMessage("");
    setAlertShow(false);
    setAlertType("");
  };

  const handleDownload = (row: any) => {
    apiLoading(true);
    // Get Download signed url AT1802
    api
      .getDownloadCreditNoteSignedUrlByWco(row.ref_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const url = response.content;
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              apiLoading(false);
              if (!getApiResultStatus(code)) {
                setAlertShow(true);
                const message = com("messages.MS0035");
                setAlertMessage(message);
                setAlertType("error");
              } else {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement("a");
                a.href = url;
                a.download = row.media_name;
                a.click();
              }
            });
        }
      });
  };

  // popup close
  const contentsClose = () => {
    setCotnentsOpen(false);
    setContentsListMessage([]);
  };

  // popup open
  const moreShow = (data: any) => {
    setCotnentsOpen(true);
    setContentsListMessage(data);
  };

  // popup close
  const customerPoNoClose = () => {
    setCustomerPoNoOpen(false);
    setCustomerPoNoListMessage([]);
  };

  // popup open
  const moreShowCustomerPoNo = (data: any) => {
    setCustomerPoNoOpen(true);
    setCustomerPoNoListMessage(data);
  };

  const setCustomerMessage = (row: any, customerNoSelect: any) => {
    if (row.customer_closed === true) {
      setAlertShow(true);
      const message = com("messages.MS0085", {
        param1: row.customer_no,
      });
      setAlertMessage(message);
      setAlertType("warning");
    }

    const customerValue: any = {
      customer_name: row.customer_name,
      // customer_no_1: row.customer_no,
    };

    if (customerNoSelect === "1") {
      customerValue.customer_no_1 = row.customer_no;
      customerValue.customer_name_1 = row.customer_name;
    } else {
      customerValue.customer_no_2 = row.customer_no;
      customerValue.customer_name_2 = row.customer_name;
    }
    const valueList = Object.assign({}, searchValueList, customerValue);
    setSearchValueList(valueList);
  };

  const isAllErrMsgmpty = () => {
    let result = false;
    if (searchValueList.customer_no_1_error === "") {
      result = true;
    }
    return result;
  };

  const CustomerPoNonModelWindowButton = (
    <div className={classes.buttonControl}>
      <Button
        className={classes.buttonWidth}
        variant="contained"
        onClick={customerPoNoClose}
        color="primary"
      >
        {getName("prompt_button_Close")}
      </Button>
    </div>
  );

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      {alertMessageList.length > 0 &&
        alertMessageList.map((item: any) => (
          <AlertMesssageBox
            key={item.no}
            show={item.message}
            message={item.message}
            type={item.alertType}
          />
        ))}
      <div>
        <SearchControl
          alertMessageList={alertMessageList}
          setAlertMessageList={setAlertMessageList}
          setSearchValue={setSearchValue}
          searchValueList={searchValueList}
          issueUpdatedDate={issueUpdatedDate}
          setSearchValueList={setSearchValueList}
          handleSearchEvent={handleSearchEvent}
          handleClearEvent={handleClearEvent}
          handleCustomerSearch={handleCustomerSearch}
          handleCreditIssue={handleCreditIssue}
          checkApFrom={checkApFrom}
          onBlurOfApFrom={onBlurOfApFrom}
          checkApTo={checkApTo}
          onBlurOfApTo={onBlurOfApTo}
          setCustomerMessage={setCustomerMessage}
          isAllErrMsgmpty={isAllErrMsgmpty}
        />
        <SearchResult
          data={resultData}
          moreShow={moreShow}
          moreShowCustomerPoNo={moreShowCustomerPoNo}
          handleDownload={handleDownload}
          userPermisssionInfo={userPermisssionInfo}
        />
      </div>
      <ContentsMessageBox
        open={contentsOpen}
        contentsListMessage={contentsListMessage}
        handleClose={contentsClose}
      />

      {customerPoNoOpen && (
        <ModelWindow
          title={"ap_customer_po_no"}
          contents={CustomerPoNonModelWindow}
          button={CustomerPoNonModelWindowButton}
          openFlg={customerPoNoOpen}
          handleCloseEvent={customerPoNoClose}
        />
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
