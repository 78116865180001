export const changedHistoryProject: any = {
  transport_way_pick_up: "claim_header_transport_way_pick_up",
  transport_way_return: "claim_header_transport_way_return",
  transport_cost_flg: "claim_header_transport_cost_flg",
  total_amount: "claim_header_total_amount",
  claim_note: "claim_note_note",
  error_type: "claim_header_error_type",
};

export const changedHistoryClearProject = [
  "status",
  "re_entered_count",
  "updated_date",
];

export const changedHistory = (data: any) => {
  let _data: any = JSON.parse(JSON.stringify(data));
  let _history_data: any = [];
  if (_data.claim_changed_history) {
    diffChangeData(_data, _data.claim_changed_history, _history_data);
  }
  return _history_data;
};

const diffChangeData = (new_data: any, old_data: any, _history_data: any) => {
  for (let pro in old_data) {
    if (!changedHistoryClearProject.includes(pro)) {
      if (pro === "claim_address") {
        if (old_data.claim_address) {
          diffChangeData(
            new_data.claim_address,
            old_data.claim_address,
            _history_data
          );
        }
      } else if (pro === "claim_changed_history") {
      } else if (pro === "claim_line") {
      } else if (pro === "claim_media") {
      } else if (pro === "claim_note") {
        let old_note_list: any =
          old_data.claim_note.length > 0
            ? old_data.claim_note.filter((item: any) => item.note_type === 10)
            : [];
        let old_note = old_note_list.length > 0 ? old_note_list[0].note : "";
        let new_note_list: any =
          new_data.claim_note.length > 0
            ? new_data.claim_note.filter((item: any) => item.note_type === 10)
            : [];
        let new_note = new_note_list.length > 0 ? new_note_list[0].note : "";
        if (new_note !== old_note) {
          console.log("test note", new_note, old_note);
          pushHistory(
            new_note,
            old_note,
            pro,
            new_data.updated_date,
            _history_data
          );
        }
      } else if (pro === "claim_updated_history") {
      } else {
        if (checkType(new_data[pro]) !== checkType(old_data[pro])) {
          pushHistory(
            checkType(new_data[pro]),
            checkType(old_data[pro]),
            pro,
            new_data.updated_date,
            _history_data
          );
        }
      }
    }
  }
};

const pushHistory = (
  new_data: any,
  old_data: any,
  name: string,
  dateTime: any,
  list: any
) => {
  if (changedHistoryProject[name]) {
    let item = {
      name: name,
      description: changedHistoryProject[name],
      before_value: old_data,
      after_value: new_data,
      created_date: dateTime,
    };
    list.push(item);
  }
};

const checkType = (data: any) => {
  return data === null || data === undefined ? "" : data;
};
