import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkArea: {
      padding: 0,
    },
  })
);

export const JobsDetailGeneralTradeIn = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.group_trade_in",
  });
  const {
    formList,
    setFormListValue,
    formErrorList,
    onBlurOfTradeInItemNo,
    userPermisssionInfo,
  } = props;
  const { getCommonInfo } = useCommonInfo();

  let commonInfo = getCommonInfo() as any;

  const getOptionLabel = (option: any) => option.model_no || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name")}</h3>
      <table className={classes.tableLayout}>
        <tbody>
          <tr>
            <th>{t("trade_in_item_no")}</th>
            <td>
              <Autocomplete
                disabled={!userPermisssionInfo.trade_in_item_no_authFlg}
                className={classes.autoComplete}
                id="trade_in_item_no"
                freeSolo
                disableClearable
                options={[]}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                inputValue={formList.trade_in_item_no}
                value={formList.trade_in_item_no}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      minWidth: "200px",
                    }}
                    placement="bottom-start"
                  />
                )}
                getOptionSelected={(option: any, value: any) =>
                  option.model_no.toString().toUpperCase() ===
                  formList.trade_in_item_no.toString().toUpperCase()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    error={formErrorList.trade_in_item_no_error !== ""}
                    helperText={formErrorList.trade_in_item_no_error}
                    onBlur={onBlurOfTradeInItemNo}
                  />
                )}
                onChange={(event: object, value: any, reason: string) => {
                  if (reason === "select-option") {
                    setFormListValue("trade_in_item_no", value.model_no);
                  }
                }}
                onInputChange={(
                  event: object,
                  value: string,
                  reason: string
                ) => {
                  if (reason === "input" || reason === "clear") {
                    setFormListValue("trade_in_item_no", value);
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <th>{t("trade_in_item_price")}</th>
            <td>
              <p>
                {formList.trade_in_item_price
                  ? currency(
                      formList.trade_in_item_price,
                      formList.trade_in_item_price_currency
                    )
                  : ""}
              </p>
            </td>
          </tr>
          <tr>
            <th>{t("trade_in_approve")}</th>
            <td>
              <Checkbox
                disabled={!userPermisssionInfo.trade_in_approve_authFlg}
                className={myClasses.checkArea}
                checked={formList.trade_in_approve}
                onChange={(e) =>
                  setFormListValue("trade_in_approve", e.target.checked)
                }
                color="primary"
              />
            </td>
          </tr>
          <tr>
            <th>{t("trade_in_reference")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.trade_in_reference_authFlg}
                variant="outlined"
                value={formList.trade_in_reference}
                onChange={(e) =>
                  setFormListValue("trade_in_reference", e.target.value)
                }
                color="primary"
                inputProps={{ maxLength: 30 }}
                />           
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
