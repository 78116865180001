import React, { useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
// constants
import { TabKey } from "../MasterMaintenance.constants";
import {
  checkLength,
  isEmptyCheck,
  checkMaxLength,
} from "../../../modules/hooks/UseValication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "20px",
      },
    },
    mark: {
      color: "red",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
      },
    },
    selectContainer: {
      width: "100%",
      height: "30px",
    },
    select: {
      height: "30px",
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "300px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
    },
    buttonWidth: {
      width: "90px",
      height: "35px",
    },
    buttonWidth2: {
      width: "130px",
      height: "35px",
    },
  })
);
export const MasterMaintenanceServiceCenterEditor = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.serviceCenter",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { changeData, editData, handleBack, handleSubmit, countriesList } =
    props;
  const {
    service_center_id,
    name,
    address_1,
    address_2,
    address_3,
    country,
    state_cd,
    postal_cd,
    facility_cd,
    warehouse,
    registerFlg,
  } = editData;

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("service_center_id" === itemName) {
      serviceCenterIdValidationCheck(itemValue);
    } else if ("name" === itemName) {
      serviceCenterNameValidationCheck(itemValue);
    } else if ("address_1" === itemName) {
      address1ValidationCheck(itemValue);
    } else if ("address_2" === itemName) {
      address2ValidationCheck(itemValue);
    } else if ("address_3" === itemName) {
      address3ValidationCheck(itemValue);
    } else if ("state_cd" === itemName) {
      stateCodeValidationCheck(itemValue);
    } else if ("postal_cd" === itemName) {
      postalCodeValidationCheck(itemValue);
    } else if ("facility_cd" === itemName) {
      facilityCodeValidationCheck(itemValue);
    } else if ("warehouse" === itemName) {
      warehouseValidationCheck(itemValue);
    }
  };

  useEffect(() => {
    if (
      isEmptyCheck(service_center_id) ||
      !checkMaxLength(service_center_id, 3) ||
      serviceCenterIdErrorFlg ||
      isEmptyCheck(name) ||
      !checkMaxLength(name, 50) ||
      serviceCenterNameErrorFlg ||
      isEmptyCheck(address_1) ||
      !checkMaxLength(address_1, 50) ||
      address1ErrorFlg ||
      !checkMaxLength(address_2, 50) ||
      address2ErrorFlg ||
      !checkMaxLength(address_3, 50) ||
      address3ErrorFlg ||
      !checkMaxLength(state_cd, 3) ||
      stateCodeErrorFlg ||
      !checkMaxLength(postal_cd, 9) ||
      postalCodeErrorFlg ||
      isEmptyCheck(facility_cd) ||
      !checkMaxLength(facility_cd, 2) ||
      facilityCodeErrorFlg ||
      isEmptyCheck(warehouse) ||
      !checkMaxLength(warehouse, 3) ||
      warehouseErrorFlg
    ) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
  });

  const [disabledFlg, setDisabledFlg] = React.useState(true);
  const [serviceCenterIdErrorFlg, setServiceCenterIdErrorFlg] =
    React.useState(false);
  const [serviceCenterIdErrorMessage, setServiceCenterIdErrorMessage] =
    React.useState("");
  const [serviceCenterNameErrorFlg, setServiceCenterNameErrorFlg] =
    React.useState(false);
  const [serviceCenterNameErrorMessage, setServiceCenterNameErrorMessage] =
    React.useState("");
  const [address1ErrorFlg, setAddress1ErrorFlg] = React.useState(false);
  const [address1ErrorMessage, setAddress1ErrorMessage] = React.useState("");
  const [address2ErrorFlg, setAddress2ErrorFlg] = React.useState(false);
  const [address2ErrorMessage, setAddress2ErrorMessage] = React.useState("");
  const [address3ErrorFlg, setAddress3ErrorFlg] = React.useState(false);
  const [address3ErrorMessage, setAddress3ErrorMessage] = React.useState("");
  const [stateCodeErrorFlg, setStateCodeErrorFlg] = React.useState(false);
  const [stateCodeErrorMessage, setStateCodeErrorMessage] = React.useState("");
  const [postalCodeErrorFlg, setPostalCodeErrorFlg] = React.useState(false);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] =
    React.useState("");
  const [facilityCodeErrorFlg, setFacilityCoderErrorFlg] =
    React.useState(false);
  const [facilityCodeErrorMessage, setFacilityCodeErrorMessage] =
    React.useState("");
  const [warehouseErrorFlg, setWarehouseErrorFlg] = React.useState(false);
  const [warehouseErrorMessage, setWarehouseErrorMessage] = React.useState("");

  //service Center Id check
  const serviceCenterIdValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("service_center_id"),
      });
      setServiceCenterIdErrorFlg(flg);
      setServiceCenterIdErrorMessage(message);
    } else if (!checkMaxLength(value, 3)) {
      flg = !checkMaxLength(value, 3);
      let message = com("messages.MS0002", {
        param1: t("service_center_id"),
        param2: 3,
      });
      setServiceCenterIdErrorFlg(flg);
      setServiceCenterIdErrorMessage(message);
    } else {
      setServiceCenterIdErrorFlg(flg);
      setServiceCenterIdErrorMessage("");
    }
  };

  //service Center Name check
  const serviceCenterNameValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("name"),
      });
      setServiceCenterNameErrorFlg(flg);
      setServiceCenterNameErrorMessage(message);
    } else if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 50);
      let message = com("messages.MS0002", {
        param1: t("name"),
        param2: 50,
      });
      setServiceCenterNameErrorFlg(flg);
      setServiceCenterNameErrorMessage(message);
    } else {
      setServiceCenterNameErrorFlg(flg);
      setServiceCenterNameErrorMessage("");
    }
  };

  //Address 1 check
  const address1ValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("address_1"),
      });
      setAddress1ErrorFlg(flg);
      setAddress1ErrorMessage(message);
    } else if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 50);
      let message = com("messages.MS0002", {
        param1: t("address_1"),
        param2: 50,
      });
      setAddress1ErrorFlg(flg);
      setAddress1ErrorMessage(message);
    } else {
      setAddress1ErrorFlg(flg);
      setAddress1ErrorMessage("");
    }
  };

  //Address 2 check
  const address2ValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 50);
      let message = com("messages.MS0002", {
        param1: t("address_2"),
        param2: 50,
      });
      setAddress2ErrorFlg(flg);
      setAddress2ErrorMessage(message);
    } else {
      setAddress2ErrorFlg(flg);
      setAddress2ErrorMessage("");
    }
  };

  //Address 3 check
  const address3ValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 50);
      let message = com("messages.MS0002", {
        param1: t("address_3"),
        param2: 50,
      });
      setAddress3ErrorFlg(flg);
      setAddress3ErrorMessage(message);
    } else {
      setAddress3ErrorFlg(flg);
      setAddress3ErrorMessage("");
    }
  };

  //State Code check
  const stateCodeValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 3)) {
      flg = !checkMaxLength(value, 3);
      let message = com("messages.MS0002", {
        param1: t("state_cd"),
        param2: 3,
      });
      setStateCodeErrorFlg(flg);
      setStateCodeErrorMessage(message);
    } else {
      setStateCodeErrorFlg(flg);
      setStateCodeErrorMessage("");
    }
  };

  //Postal Code check
  const postalCodeValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 9)) {
      flg = !checkMaxLength(value, 9);
      let message = com("messages.MS0002", {
        param1: t("postal_cd"),
        param2: 9,
      });
      setPostalCodeErrorFlg(flg);
      setPostalCodeErrorMessage(message);
    } else {
      setPostalCodeErrorFlg(flg);
      setPostalCodeErrorMessage("");
    }
  };

  //Facility Code check
  const facilityCodeValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("facility_cd"),
      });
      setFacilityCoderErrorFlg(flg);
      setFacilityCodeErrorMessage(message);
    } else if (!checkMaxLength(value, 2)) {
      flg = !checkMaxLength(value, 2);
      let message = com("messages.MS0002", {
        param1: t("facility_cd"),
        param2: 2,
      });
      setFacilityCoderErrorFlg(flg);
      setFacilityCodeErrorMessage(message);
    } else {
      setFacilityCoderErrorFlg(flg);
      setFacilityCodeErrorMessage("");
    }
  };

  //warehouse check
  const warehouseValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("warehouse"),
      });
      setWarehouseErrorFlg(flg);
      setWarehouseErrorMessage(message);
    } else if (!checkMaxLength(value, 3)) {
      flg = !checkMaxLength(value, 3);
      let message = com("messages.MS0002", {
        param1: t("warehouse"),
        param2: 3,
      });
      setWarehouseErrorFlg(flg);
      setWarehouseErrorMessage(message);
    } else {
      setWarehouseErrorFlg(flg);
      setWarehouseErrorMessage("");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>
              {t("service_center_id")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                error={serviceCenterIdErrorFlg}
                helperText={serviceCenterIdErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={service_center_id}
                onBlur={(e) =>
                  changeDataListen("service_center_id", e.target.value)
                }
                onChange={(e) =>
                  changeData("service_center_id", e.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <th>
              {t("name")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                error={serviceCenterNameErrorFlg}
                helperText={serviceCenterNameErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={name}
                onBlur={(e) => changeDataListen("name", e.target.value)}
                onChange={(e) => changeData("name", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>
              {t("address_1")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                error={address1ErrorFlg}
                helperText={address1ErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={address_1}
                onBlur={(e) => changeDataListen("address_1", e.target.value)}
                onChange={(e) => changeData("address_1", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>{t("address_2")}</th>
            <td>
              <TextField
                error={address2ErrorFlg}
                helperText={address2ErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={address_2}
                onBlur={(e) => changeDataListen("address_2", e.target.value)}
                onChange={(e) => changeData("address_2", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>{t("address_3")}</th>
            <td>
              <TextField
                error={address3ErrorFlg}
                helperText={address3ErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={address_3}
                onBlur={(e) => changeDataListen("address_3", e.target.value)}
                onChange={(e) => changeData("address_3", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>{t("country")}</th>
            <td>
              <FormControl
                variant="outlined"
                className={classes.selectContainer}
              >
                <Select
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  value={country}
                  onChange={(e) => changeData("country", e.target.value)}
                >
                  {countriesList.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>{t("state_cd")}</th>
            <td>
              <TextField
                error={stateCodeErrorFlg}
                helperText={stateCodeErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={state_cd}
                onBlur={(e) => changeDataListen("state_cd", e.target.value)}
                onChange={(e) => changeData("state_cd", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>{t("postal_cd")}</th>
            <td>
              <TextField
                error={postalCodeErrorFlg}
                helperText={postalCodeErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={postal_cd}
                onBlur={(e) => changeDataListen("postal_cd", e.target.value)}
                onChange={(e) => changeData("postal_cd", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>
              {t("facility_cd")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                error={facilityCodeErrorFlg}
                helperText={facilityCodeErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={facility_cd}
                onBlur={(e) => changeDataListen("facility_cd", e.target.value)}
                onChange={(e) => changeData("facility_cd", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>
              {t("warehouse")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                error={warehouseErrorFlg}
                helperText={warehouseErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={warehouse}
                onBlur={(e) => changeDataListen("warehouse", e.target.value)}
                onChange={(e) => changeData("warehouse", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonWidth2}
          variant="contained"
          onClick={handleBack}
        >
          {t("back")}
        </Button>
        <Button
          className={classes.buttonWidth2}
          disabled={disabledFlg}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(TabKey.serviceCenter, registerFlg)}
        >
          {t("submit")}
        </Button>
      </div>
    </form>
  );
};
