import React from "react";
// UI
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: " 0 0 8px 0",
      maxWidth: 300,
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
  })
);

export const MasterMaintenanceSelector = (props: any) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={props.selectorValue}
        onChange={(e) => props.handleChange(e.target.value)}
      >
        {props.selectorList &&
          props.selectorList.map(
            (item: any, index: number) =>
              item.showFlg && (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              )
          )}
      </Select>
    </FormControl>
  );
};
