import { http } from "./Http";
import { methods } from "../constants/common.constants";

export const GetSalesAnalysisDefinitionAPI = (config: any) => {
  const { baseURLShop, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/sad/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
