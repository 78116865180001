import React from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { determineDataType } from "../../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modelContainer: {
      display: "flex",
      flexWrap: "wrap",
      paddingBottom: "20px",
    },
    modelArea: {
      display: "flex",
    },
    documentsArea: {
      display: "flex",
    },
    region: {
      margin: 0,
      paddingRight: "10px",
    },
    title: {
      margin: 0,
      paddingRight: "18px",
    },
    documentTitle: {
      margin: 0,
      paddingLeft: "15px",
      paddingRight: "5px",
    },
    selectContainer: {
      width: "260px",
    },
    modelsSelect: {
      height: "30px",
      width: "260px",
      "& .MuiOutlinedInput-input": {
        minWidth: "50px",
        padding: "0px 5px",
      },
      "& .MuiSelect-select:focus": {
        background: "none",
      },
    },
    doucumentList: {
      display: "flex",
      flexWrap: "wrap",
      margin: 0,
      padding: 0,
      listStyle: "none",
      "& li": {
        paddingRight: "20px",
        paddingBottom: "10px",
      },
    },
    link: {
      "& p": {
        margin: 0,
        color: "rgb(42, 95, 111)",
        textDecoration: "underline",
        cursor: "pointer",
      },
      "& p:hover": {
        textDecoration: "none",
      },
    },
  })
);
export const ClaimDetailPartsModel = (props: any) => {
  const classes = useStyles();
  const {
    regionTitle,
    region,
    models,
    specificationModels,
    handleChangeregions,
    handleChangeModels,
    regionList,
    title,
    documentTitle,
    documentsList,
    documentClick,
  } = props;

  return (
    <>
      <div className={classes.modelContainer}>
        <div className={classes.modelArea}>
          <p className={classes.region}>{regionTitle}</p>
          <div className={classes.selectContainer}>
            <FormControl variant="outlined">
              <Select
                id="demo-simple-select-outlined"
                className={classes.modelsSelect}
                value={region}
                onChange={(e) => handleChangeregions(e.target.value)}
                native
              >
                {determineDataType(regionList) &&
                  regionList.map((item: any) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={classes.modelContainer}>
        <div className={classes.modelArea}>
          <p className={classes.title}>{title}</p>
          <div className={classes.selectContainer}>
            <FormControl variant="outlined">
              <Select
                id="demo-simple-select-outlined"
                className={classes.modelsSelect}
                value={models}
                onChange={(e) => handleChangeModels(e.target.value)}
                native
              >
                {determineDataType(specificationModels) &&
                  specificationModels.map((item: any) => (
                    <option
                      key={item.specificationCode}
                      value={item.specificationCode}
                    >
                      {item.specificationCode === "Full"
                        ? "ALL"
                        : item.specificationCode + ":" + item.modelNo}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {determineDataType(documentsList) && (
          <div className={classes.documentsArea}>
            <p className={classes.documentTitle}>{documentTitle}：</p>
            <ul className={classes.doucumentList}>
              {documentsList.map((item: any) => (
                <li className={classes.link} key={item.name}>
                  <p onClick={() => documentClick(item.path, "document")}>
                    {item.name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
