import React, { FC, useState, useEffect } from "react";
// UI
import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavigationMenu } from "./Navigation.Menu";
// language
import { useLocale } from "../../hooks/UseLocale";
import { useHistory } from "react-router-dom";
import { getAppRouteTitle } from "../../../pages/AppRoutes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    leftMessage: {
      display: "flex",
      alignItems: "center",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  })
);

type Props = {
  onToggleMenuDrawerEvent: () => void;
  onToggleNotificationDrawerEvent: () => void;
};

export const NavigationBar: FC<Props> = ({
  onToggleMenuDrawerEvent,
  onToggleNotificationDrawerEvent,
}) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "models.page_title" });
  const history = useHistory() as any;

  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const title: any = getAppRouteTitle(history.location.pathname);
    if (title !== pageTitle) {
      setPageTitle(title);
    }
  });

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftMessage}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onToggleMenuDrawerEvent}
              edge="start"
              // className={clsx(classes.menuButton, {
              //     [classes.hide]: open,
              // })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">Takumi</Typography>
          </div>
          {pageTitle && <div className={classes.title} data-testid="pageTitle">{t(pageTitle)}</div>}
          <NavigationMenu
            onToggleNotificationDrawerEvent={onToggleNotificationDrawerEvent}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
};
