import React, { FC, useEffect, useState } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { DraftsSearchControl } from "./components/Drafts.SearchControl";
import { DraftsSearchResult } from "./components/Drafts.SearchResult";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { getApiResultStatus } from "../../modules/common.methods";
import { ModelWindow } from "../../modules/components/model/ModelWindow";
// api
import api from "../../modules/api/Api";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// permissions
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const defaultValue = {
  created_date: null as any,
  promotion_type: "0",
  promo_name: ""
};

const errorList = {
  created_date_error: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
  })
);

export const Page: FC = () => {
  const classes = useStyles();
  // start
  const [startFlg, setStartFlg] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [needResetOrderBy, setNeedResetOrderBy] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const [defaultValueList, setDefaultValueList] = useState(defaultValue);
  const [errorValueList, setErrorValueList] = useState(errorList);
  const [changeCondition, setChangeCondition] = useState(true);
  const [resultData, setResultData] = useState([]);

  const [searchButtondisabled, setSearchButtondisabled] = useState(false);

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  // order
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(["created_date"]);

  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

   // delete promotion alert
   const [deletePromotion, setDeletePromotion] = useState(false);

  // language
  const { t } = useLocale({
    defaultPath: "modules.components.promotion",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const { getName } = useLocale({ defaultPath: "models.model_window" });

  // loading
  const {
    setCommonInfo,
    getCommonConfig,
    getUserPermissions,
    setUserPermissions,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;

  // permissions
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();

  // error message
  const errorMessage = (type: any, message: any) => {
    setAlertShow(true);
    setAlertMessage(message);
    setAlertType(type);
  };

  useEffect(() => {
    if (!startFlg && Object.keys(config).length > 0) {
      setStartFlg(true);
      getDefaultApiList();
      searchHandle(false);
    }
  }, [startFlg, config]);

  // get Default Api List
  const getDefaultApiList = () => {
    let apiList = [];
    let localUserPermissions = {};
    // permission
    if (!userPermissions.permission_group) {
      const api1 = getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
      apiList.push(api1);
    } else {
      localUserPermissions = userPermissions;
    }

    Promise.all(apiList).then(() => {
      apiLoading(false);
      initStart(localUserPermissions);
    });
  };

  const initStart = (localUserPermissions: any) => {
    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(userPermissionsInfo);
    apiLoading(false);
  };

  useEffect(() => {
    if (JSON.stringify(searchValueList) === JSON.stringify(defaultValueList)) {
      setChangeCondition(true);
    } else {
      setChangeCondition(false);
    }
  }, [searchValueList, defaultValueList]);

  // set search value
  const setSearchValue = (property: any, value: any) => {
    const valueList = Object.assign({}, searchValueList, { [property]: value });
    setSearchValueList(valueList);
  };

  // set error message
  const setErrorValue = (property: any, value: any) => {
    const errorList = Object.assign({}, errorValueList, { [property]: value });
    setErrorValueList(errorList);
  };

  // clear button click event
  const handleClearEvent = () => {
    const valueList = Object.assign({}, defaultValueList);
    setSearchValueList(valueList);
  };

  // search button disabled
  useEffect(() => {
    const flag = Object.values(errorValueList).some((item: any) => {
      return item !== "";
    });
    setSearchButtondisabled(flag);
  }, [errorValueList]);

  const dateFormat = (data: any) => {
    const formatDoubleDigit = (data: any) => {
      return data >= 10 ? data : "0" + data;
    };
    const getYear = data.getFullYear();
    const getMonth = formatDoubleDigit(data.getMonth() + 1);
    const getDate = formatDoubleDigit(data.getDate());
    return getYear + "-" + getMonth + "-" + getDate;
  };

  // search
  const searchHandle = (deleteFlag: boolean) => {
    setResultData([]);
    setOrder("desc");
    setOrderBy(["created_date"]);
    setNumSelected(0);
    setPage(0);
    setRowsPerPage(10);
    setSelectedArray([]);
    setSelectedRowArray([]);
    setIndeterminate(false);
    setAllChecked(false);
    let _params: any = "";
    const objData = JSON.parse(JSON.stringify(searchValueList));
    let number: number = 0;
    for (let key in objData) {
      let item = objData[key];
      if (
        item !== "" &&
        item !== null &&
        item !== false
      ) {
        if (number !== 0) {
          _params += "&";
        }

        // promo type:all
        if (key === "promotion_type" && item === "0") {
          _params += `${key}=`;
        } else {
          if (key === "created_date") {
            item = dateFormat(new Date(item));
          };
          _params += `${key}=${encodeURIComponent(item)}`; 
        }
        number++;
      }
    };

    getSearchData(_params, deleteFlag);
  }

  const getSearchData = (query_parameters: any, deleteFlag: boolean) => {
    apiLoading(true);
    if (deleteFlag) {
      const message = com("MS0014");
      errorMessage("success", message);
    } else {
      setAlertMessage("");
      setAlertShow(false);
    }
    // AM2101
    api
      .getReadPromotionDraftListAPI(config, query_parameters)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          const message = com(response.message_id, {
            param1: t(response.key),
          });
          errorMessage("error", message);
        } else {
          if (response) {
            setResultData(JSON.parse(JSON.stringify(response)))
          }
        }
      });
  }

  // delete promotion alert
  const deletePromotionAlert = () => {
    setDeletePromotion(true);
  };

  // close promotion alert
  const closePromotionAlert = () => {
    setDeletePromotion(false);
  };

  // delete draft
  const deletePromotionHandler = () => {
    setDeletePromotion(false);
    let count = 0;
    apiLoading(true);
    if (selectedRowArray && selectedRowArray.length > 0) {
      selectedRowArray.forEach((item: any) => {
        let params = {
          updated_date: item.updated_date,
        };
        count++;
        api
          .deletePromotionAPI(config, String(item.promo_no), params)
          .then(({ code, response }: { code: any; response: any }) => {
            if (!getApiResultStatus(code)) {
              const message = com(response.message_id, {
                param1: t(response.key),
              });
              errorMessage("error", message);
            }
          });
      });
      if (count === selectedRowArray.length) {
        apiLoading(false);
        setTimeout(() => {
          searchHandle(true);
        }, 100)
      }
    }
  };

  const deletePromotionButton = (
    <div className={classes.buttonControl}>
      <Button
        data-testid="prompt_button_No" 
        variant="contained"
        color="secondary"
        onClick={closePromotionAlert}
      >
        {getName("prompt_button_No")}
      </Button>
      <Button data-testid="prompt_button_Yes" variant="contained" onClick={deletePromotionHandler}>
        {getName("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionDeletePromotion = (
    <p>{com("MS0070")}</p>
  );

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <DraftsSearchControl
        numSelected={numSelected}
        searchValueList={searchValueList}
        changeCondition={changeCondition}
        setSearchValue={setSearchValue}
        handleClearEvent={handleClearEvent}
        setSearchButtondisabled={setSearchButtondisabled}
        searchButtondisabled={searchButtondisabled}
        errorValueList={errorValueList}
        setErrorValue={setErrorValue}
        searchHandle={searchHandle}
        deletePromotionAlert={deletePromotionAlert}
        userPermisssionInfo={userPermisssionInfo}
      />
      <DraftsSearchResult
        numSelected={numSelected}
        setNumSelected={setNumSelected}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedRowArray={selectedRowArray}
        setSelectedRowArray={setSelectedRowArray}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        needResetOrderBy={needResetOrderBy}
        setNeedResetOrderBy={setNeedResetOrderBy}
        resultData={resultData}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {deletePromotion && <ModelWindow
        title="prompt_title_attention"
        contents={attentionDeletePromotion}
        openFlg={deletePromotion}
        button={deletePromotionButton}
        handleCloseEvent={closePromotionAlert}
      />}
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
