import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// root
import { useHistory } from "react-router-dom";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
// route
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
// method
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { getCodeName } from "../../../modules/common.methods";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      minHeight: "48px",
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      "&:last-child td": {
        borderBottom: "none",
      },
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
        overflow: "hidden",
      },
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    checkbox: {
      minHeight: "42px",
    },
    smallButton: {
      width: "20px",
      height: "20px",
      margin: "0 3px",
      background: "transparent!important",
      boxShadow: "none!important",
      color: "inherit",
      padding: 0,
      fontSize: "inherit",
      minWidth: "auto",
    },
    qty: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& .MuiOutlinedInput-input": {
        width: "73px",
        height: "30px",
        padding: "0",
        textAlign: "center",
        fontSize: "0.875rem",
      },
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "blue",
    },
    promotions: { whiteSpace: "break-spaces", wordBreak: "break-all" },
  })
);

export const SimulateSearchResultTableTbody = (props: any) => {
  const { selected } = props;
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.simulate" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const isSelected = (name: string) => selected.indexOf(name) > -1;
  // change image
  const { setPromoDetailPageInfo, getPromoDetailPageInfo } = useCommonInfo();
  const promoDetailPageInfo = getPromoDetailPageInfo() as any;
  const history = useHistory();

  const decreaseCount = (data: any, index: number) => {
    let list = [...props.data];
    let qty: number = Number(data.order_qty);
    if (qty > 1) {
      qty = qty - 1;
      list[index].order_qty = qty;
      props.setData(list);
    }
  };

  const increaseCount = (data: any, index: any) => {
    let list = [...props.data];
    let qty: number = Number(data.order_qty);
    qty = qty + 1;
    list[index].order_qty = qty;
    props.setData(list);
  };

  const changeQty = (value: any, index: any) => {
    let list = [...props.data];
    list[index].order_qty = value;
    props.setData(list);
  };

  const changeView = (path: string) => {
    let obj = Object.assign({}, promoDetailPageInfo, { reviewFlg: true });
    setPromoDetailPageInfo(obj);
    history.push(path);
  };

  const qtyBlur = (index: any) => {
    let list = [...props.data];
    if (Number(list[index].order_qty) === 0) {
      list[index].order_qty = 1;
    }
    props.setData(list);
    props.qtyFoucs();
  };

  const getDiscountRate = (row: any) => {
    // const _net_price = isNaN(row.net_price) ? 0 : Number(row.net_price);
    // const _list_price = isNaN(row.list_price) ? 0 : Number(row.list_price);
    // let _discount_rate = 0;
    // if (_list_price !== 0 && _net_price !== 0) {
    //   _discount_rate = 100 - Math.round((_net_price / _list_price) * 100);
    // }
    // return !_discount_rate ? "-" : _discount_rate + "%";
    if (row && row.promotions && row.promotions.length > 0) {
      let _discount_rate = 1;
      row.promotions.forEach((item: any) => {
        if (item.promotion_type === "10" || item.promotion_type === "50") {
          _discount_rate *= 1 - item.promotion_discount_percentage / 100;
        }
      });
      //const c = 100 - Math.round(_discount_rate * 100);
      const d = Number(((1 - _discount_rate) * 100).toFixed(2));
      return d > 0 ? d + "%" : "-";
    } else {
      return "-";
    }
  };

  return (
    <TableBody>
      {props.data &&
        props.data.length > 0 &&
        props.data.map((row: any, index: number) => {
         
          const isItemSelected = isSelected(row.line_no);
          return (
            <TableRow
              className={classes.tableRow}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <div className={classes.checkbox}>
                  {
                    <Checkbox
                      data-testid={"resultList_isItemSelected"+index}
                      checked={isItemSelected}
                      onClick={(event) => props.handleClick(event, row)}
                    />
                  }
                </div>
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {index + 1}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.item_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.description}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                <div className={classes.qty}>
                  <Button
                    className={classes.smallButton}
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={row.order_qty <= 1}
                    onClick={() => decreaseCount(row, index)}
                  >
                    <MinimizeIcon />
                  </Button>
                  <TextField
                    value={row.order_qty}
                    variant="outlined"
                    onChange={(e: any) => {
                      const _value = e.target.value.replace(/[^\d]/g, "");
                      changeQty(_value, index);
                    }}
                    onFocus={() => props.qtyFoucs("focus")}
                    onBlur={() => qtyBlur(index)}
                  />
                  <Button
                    className={classes.smallButton}
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => increaseCount(row, index)}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </TableCell>
              <TableCell className={classes.nowrap} align="right" data-testid={"resultList_list_price"+index}>
                {!isNaN(row.dealer_specific_net_price) &&
                  currency(row.dealer_specific_net_price, row.currency)}
              </TableCell>
              <TableCell className={classes.nowrap} align="right" data-testid={"resultList_net_price"+index}>
                {!isNaN(row.net_price) && currency(row.net_price, row.currency)}
              </TableCell>
              <TableCell className={classes.nowrap} align="right" data-testid={"resultList_amount"+index}>
                {!isNaN(row.net_price) &&
                  currency(row.order_qty * row.net_price, row.currency)}
              </TableCell>
              <TableCell className={classes.promotions} align="center"  data-testid={"resultList_promotion_no"+index}>
                {row.promotions &&
                  row.promotions.length > 0 &&
                  row.promotions.map((item: any, index: number) => {

                    const path =
                      GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
                      `?promotionNo=${item.promotion_no}`;
                    const discountPath =
                    GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
                    `?promotionNo=${row.dealer_discount_promotion_no}`;
                    if (row.promotions.length - 1 === index) {
                      return (
                        <>
                        <span
                          className={classes.link}
                          onClick={() => {
                            changeView(path);
                          }}
                        >
                          {item.promotion_no}
                        </span>
                        {row.hasOwnProperty("dealer_discount_promotion_no") && (
                        <>,
                        <span
                        className={classes.link}
                        onClick={() => {
                          changeView(discountPath);
                        }}
                      >
                        {row.dealer_discount_promotion_no}
                      </span>
                      </>
                      )}
                    </>

                      );
                    } else {
                      return (
                        <>
                          <span
                            className={classes.link}
                            onClick={() => {
                              changeView(path);
                            }}
                          >
                            {item.promotion_no}
                          </span>
                          ,
                        </>
                      );
                    }
                  })}

                  {
                    (((!row.promotions) || 
                    row.promotions.length === 0) &&
                    row.hasOwnProperty("dealer_discount_promotion_no")) && (
                        <span
                        className={classes.link}
                        onClick={() => {
                          changeView(GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
                          `?promotionNo=${row.dealer_discount_promotion_no}`);
                        }}
                      >
                        {row.dealer_discount_promotion_no}
                      </span>
                    )
                  }
              </TableCell>
              <TableCell className={classes.nowrap} align="right" data-testid={"resultList_discountRate"+index}>
                {getDiscountRate(row)}
              </TableCell>
              <TableCell className={classes.nowrap} align="center"></TableCell>
            </TableRow>
          );
        })}
      {props.freeGoodsList &&
        props.freeGoodsList.length > 0 &&
        props.freeGoodsList.map((item: any, index: number) => {
          const row = item.list[item.selected];
          const path =
            GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
            `?promotionNo=${row.promotion_no}`;
          return (
            <TableRow className={classes.tableRow} tabIndex={-1} key={index}>
              <TableCell padding="checkbox"></TableCell>
              <TableCell className={classes.nowrap} align="center">
                {index + props.data.length + 1}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {item.list && item.list.length <= 1 ? (
                  row.item_no
                ) : (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      id="promotion_type"
                      value={item.selected}
                      onChange={(e: any) =>
                        props.setFreeGoodsValue(e.target.value, index)
                      }
                    >
                      {item.list.map((option: any, index: any) => (
                        <MenuItem key={index} value={index}>
                          <ListItemText primary={option.item_no} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.description}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                <div className={classes.qty}>{row.order_qty}</div>
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {!isNaN(row.dealer_specific_net_price) &&
                  currency(row.dealer_specific_net_price, row.currency)}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {!isNaN(row.net_price) && currency(row.net_price, row.currency)}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {!isNaN(row.net_price) &&
                  currency(row.order_qty * row.net_price, row.currency)}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                <span
                  className={classes.link}
                  onClick={() => {
                    changeView(path);
                  }}
                >
                  {row.promotion_no}
                </span>
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                -
              </TableCell>
              <TableCell className={classes.nowrap} align="center"  data-testid={"resultList_freeGoods_flg"+index}>
                {getCodeName(1, com("freeGoods_flg"))}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
