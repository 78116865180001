import { Button, ButtonGroup, ClickAwayListener, createStyles, Grid, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, Theme } from '@material-ui/core';
import React, { FC, useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useLocale } from '../../hooks/UseLocale';
import { DashboardTitleModel } from '../../models/DashboardMessageListModel';

// const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

type Props = {
  titles: DashboardTitleModel[];
  handleAdd: (message_type: number, permission_group: number) => void;
};

export const HomeNewMessage: FC<Props> = ({
  titles = [],
  handleAdd,
}) => {
  const { t } = useLocale({ defaultPath: "modules.components.dashboard" });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    handleAdd(titles[index].message_type, titles[index].permission_group);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  if (titles.length === 0) {
    return null;
  }

  return (
    <Grid container direction="row" justify="flex-end" className={classes.controlArea}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        style={{ marginBottom: "1em" }}
      >
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          startIcon={<AddBoxIcon />}
        >
          {t("txt_AddMessage")}
        </Button>
      </ButtonGroup>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 3000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {titles.map((m, i) => (
                    <MenuItem
                      key={m.title}
                      disabled={m.isAdded}
                      // selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, i)}
                    >
                      {m.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      maxHeight: "42px"
    },
    input: {
      fontFamily: "monospace, serif;",
      fontSize: "1em"
    },
    iconWrap: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    icon: {
      marginRight: "0.5em",
    },
    controlArea: {
      borderBottom: "1px solid #CFCFCF",
      margin: "0.5em 0"
    },
  })
);