import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export const headCells: HeadCell[] = [
  {
    id: "service_center_id",
    label: "service_center_id",
    searchFlg: true,
  },
  {
    id: "name",
    label: "name",
    searchFlg: true,
  },
  { id: "Edit", label: "" },
];

export const MasterMaintenanceRepairDestinationThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath = "modules.components.MasterMaintenance.repairDestination";

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
