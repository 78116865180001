import { Button, ButtonGroup, ClickAwayListener, createStyles, Grid, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, Theme } from '@material-ui/core';
import React, { FC, useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useLocale } from '../../hooks/UseLocale';
import { ModelWindow } from '../model/ModelWindow';


type Props = {
  openFlag: boolean;
  handleClose: () => void;
  handleDelete: () => void;
};

export const HomeDialog: FC<Props> = ({
  openFlag = false,
  handleClose,
  handleDelete,
}) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "models.model_window" });

  const confirmationButtons = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleClose}>
        {t("prompt_button_Cancel")}
      </Button>
      <Button
        variant="contained"
        onClick={handleDelete}
        color="primary"
        className={classes.buttonSetting}
      >
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_Confrim"
      contents={t("prompt_message_delete")}
      button={confirmationButtons}
      openFlg={openFlag}
      handleCloseEvent={handleClose}
    />
  );
};

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
    },
    buttonSetting: {
      marginLeft: "auto",
    },
  })
);