import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { PromotionSearchControl } from "./components/Promotion.SearchControl";
import { PromotionSearchResultAll } from "./components/Promotion.SearchResult.All";
import { PromotionSearchResultDiscount } from "./components/Promotion.SearchResult.Discount";
import { PromotionSearchResultFreeGoods } from "./components/Promotion.SearchResult.FreeGoods";
import { PromotionSearchResultSpecialPrice } from "./components/Promotion.SearchResult.SpecialPrice";
import { PromotionSearchResultPromotionCode } from "./components/Promotion.SearchResult.PromotionCode";
import { PromotionSearchResultPaymentTerm } from "./components/Promotion.SearchResult.PaymentTerm";
import { PromotionSearchResultDealerDiscount } from "./components/Promotion.SearchResult.DealerDiscount";
import { PromotionErrorMessageBox } from "./components/Promotion.ErrorMessageBox";
import { ModelWindow } from "../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// api
import api from "../../modules/api/Api";
// common
import {
  getApiResultStatus,
  getAPIErrorInfo,
} from "../../modules/common.methods";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { PathKey } from "../../pages/AppRoutes";
// permissions
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FileSaver from "file-saver";
import { getCSVContents, getCSVFileName } from "./methods/Promotion.Methods";

export const defaultValue = {
  promo_type: "0",
  promo_no: "",
  promo_name: "",
  start_date: null as any,
  end_date: null as any,
  customer_type: "",
  customer_discount_code: "",
  csac1: "",
  csac2: "",
  csac3: "",
  csac4: "",
  csac5: "",
  csac6: "",
  csac7: "",
  csac8: "",
  csac9: "",
  csac10: "",
  promotion_region: "",
  customer_no: "",
  item_discount_code: "",
  item_class: "",
  isac1: "",
  isac2: "",
  isac3: "",
  isac4: "",
  isac5: "",
  isac6: "",
  isac7: "",
  isac8: "",
  item_no: "",
  priority: "",
  promotion_status: [] as any,
  discount_rate_from: "",
  discount_rate_end: "",
  free_goods_items: "",
  promotion_code: "",
  allow_overlap: false,
  special_price_from_format: "",
  special_price_from: "",
  special_price_to_format: "",
  special_price_to: "",
};

export const defaultPromotionType = ["promo_type"];

export const defaultItem = [
  "item_discount_code",
  "item_class",
  "isac1",
  "isac2",
  "isac3",
  "isac4",
  "isac5",
  "isac6",
  "isac7",
  "isac8",
  "item_no",
  "priority",
  "allow_overlap",
];

export const defaultDiscountRate = ["discount_rate_from", "discount_rate_end"];

export const defaultSpecialPrice = ["special_price_from", "special_price_to"];

export const defaultFreeGoods = ["free_goods_items"];

export const defaultPromotionCode = ["promotion_code"];

export const errorList = {
  start_date_error: "",
  end_date_error: "",
  discount_rate_from_error: "",
  discount_rate_end_error: "",
  customer_no_error: "",
  item_no_error: "",
  free_goods_error: "",
  special_price_from_error: "",
  special_price_end_error: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
  })
);

export const Page: FC = () => {
  const classes = useStyles();
  // start
  const [startFlg, setStartFlg] = useState(false);
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.promotion",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getName } = useLocale({ defaultPath: "models.model_window" });
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  // loading
  const {
    setCommonInfo,
    getCommonConfig,
    setPromotionInfo,
    getHistoryRouter,
    getPromotionInfo,
    getCommonInfo,
    setCommonInfoFull,

    getPromoPageInfo,
    setPromoPageInfo,
    getUserPermissions,
    setUserPermissions,
    getSalesAnalysisDefinition,
    setSalesAnalysisDefinition,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  let commonInfo = getCommonInfo() as any;
  let historyRouter = getHistoryRouter() as any;
  let pageMessage = getPromoPageInfo() as any;
  let salesAnalysisDefinition = getSalesAnalysisDefinition() as any;

  // permissions
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

  const [selectorValue, setSelectorValue] = useState("0");
  const [resultData, setResultData] = useState([]);
  const [selectedPromotions, setSelectedPromotions] = useState(true);
  const [errMessageList, setErrMessageList] = useState([]);
  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const [defaultValueList, setDefaultValueList] = useState(defaultValue);
  const [changeCondition, setChangeCondition] = useState(true);
  const [customerNoDisabled, setCustomerNoDisabled] = useState(false);
  const [itemNoDisabled, setItemNoDisabled] = useState(false);
  const [errorValueList, setErrorValueList] = useState(errorList);
  const [errorDefaultList, setErrorDefaultList] = useState(errorList);
  const [searchButtondisabled, setSearchButtondisabled] = useState(false);

  const [numSelected, setNumSelected] = useState(0);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [needResetOrderBy, setNeedResetOrderBy] = useState(false);

  const [salesAnalysisList, setSalesAnalysisList] = useState({}) as any;

  // order
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(["promo_no"]);

  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);

  // delete promotion alert
  const [deletePromotion, setDeletePromotion] = useState(false);

  const [oldCustomerNo, setOldCustomerNo] = useState("");
  const [oldItemNo, setOldItemNo] = useState("");

  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelected(num);
    if (num > 0) {
      setSelectedPromotions(false);
    } else {
      setSelectedPromotions(true);
    }
  };

  const handleClose = () => {
    setErrorMessageOpen(false);
  };

  // Cancel button click event
  const handleClear = () => {
    setSelectedArray([]);
    setSelectedRowArray([]);
    changeNumSelected(0);
    setIndeterminate(false);
    setAllChecked(false);
  };

  // error message
  const errorMessage = (response: any, type = "error") => {
    setAlertShow(true);
    const message = com("messages." + response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType(type);
  };

  // init
  useEffect(() => {
    if (!startFlg && Object.keys(config).length > 0) {
      if (historyRouter.to === String(PathKey.PROMOTION)) {
        if (historyRouter.from === String(PathKey.PROMOTIONDETAIL)) {
          let info: any = getPromotionInfo();
          setSearchValueList(info);
          getDefaultAPI(info);
          searchHandle("search", info);
          if (info.item_no) {
            setItemNoDisabled(true);
          } else {
            setItemNoDisabled(false);
          }
          setOldItemNo(info.item_no);

          if (info.customer_no) {
            setCustomerNoDisabled(true);
          } else {
            setCustomerNoDisabled(false);
          }
          setOldCustomerNo(info.customer_no);
        } else {
          setPromotionInfo(defaultValue);
          const obj = Object.assign({}, pageMessage, {
            page: 0,
          });
          setPromoPageInfo(obj);
          getDefaultAPI();
          searchHandle("search");
        }
        setStartFlg(true);
      }
    } else {
      if (!startFlg) {
        apiLoading(true);
      }
    }
  }, [startFlg, config, historyRouter]);

  const getDefaultAPI = (info?: any) => {
    apiLoading(true);
    let apiList = [];
    let localUserPermissions = {};
    let masterCustomerList: any = [];

    setAlertMessage("");
    setAlertShow(false);
    // get base item master list

    if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
      const api1 = api
        .getBaseItemListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setCommonInfo("itemBaseList", response);
          }
        });
      //apiList.push(api1);
    }
    // permission
    if (!userPermissions.permission_group) {
      const api2 = getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
      apiList.push(api2);
    } else {
      localUserPermissions = userPermissions;
    }

    // sales_analysis_definition API
    if (Object.keys(salesAnalysisDefinition).length === 0) {
      const api3 = api
        .getSalesAnalysisDefinitionAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("getSalesAnalysisDefinitionAPI", response);
          if (!getApiResultStatus(code)) {
            // MOS多言語の場合は、404は正確です。
            // errorMessage(response);
            if (code !== 404) {
              errorMessage(response);
            } else {
              const obj: any = {};
              obj.customer_list = [];
              obj.item_list = [];
              setSalesAnalysisDefinition(obj);
              setSalesAnalysisList(obj);
            }
          } else {
            let array1: any = JSON.parse(JSON.stringify(response));
            array1.customer_list.sort((item1: any, item2: any) => {
              let str1 = String(item1);
              let str2 = String(item2);

              if (Number(str1.slice(4)) < Number(str2.slice(4))) {
                return -1;
              } else {
                return 1;
              }
            });
            setSalesAnalysisDefinition(array1);
            setSalesAnalysisList(array1);
          }
        });
      apiList.push(api3);
    } else {
      setSalesAnalysisList(salesAnalysisDefinition);
    }

    if (commonInfo.customerList.length === 0) {
      const api4 = api
        .getCustomerDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0205 response: ", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            masterCustomerList = response;
          }
        });
      apiList.push(api4);
    } else {
      masterCustomerList = commonInfo.customerList;
    }

    // AM2101
    // let query_parameters = "promo_type=";
    // if (info) {
    //   query_parameters = `promo_type=${info.promo_type}`;
    // }
    // const api3 = api
    //   .getReadPromotionListAPI(config, query_parameters)
    //   .then(({ code, response }: { code: any; response: any }) => {
    //     if (!getApiResultStatus(code)) {
    //       errorMessage(response);
    //       setSearchDataResult([]);
    //     } else {
    //       if (response) {
    //         if (response.total_num && response.total_num >= 2000) {
    //           const message = com("MS0049");
    //           setAlertMessage(message);
    //           setAlertShow(true);
    //           setAlertType("warning");
    //         }
    //         setSearchDataResult(response);
    //       }
    //     }
    //   });
    // apiList.push(api3);

    if (apiList.length > 0) {
      Promise.all(apiList).then(() => {
        initStart(localUserPermissions);
        setCommonInfoFull({
          customerList: masterCustomerList,
          loadingFlg: false,
        });
        const element = document.getElementById("btn_promotion_list_search");
        if (element) {
          setTimeout(() => {
            element.focus();
          });
        }
      });
    } else {
      initStart(localUserPermissions);
      const element = document.getElementById("btn_promotion_list_search");
      if (element) {
        setTimeout(() => {
          element.focus();
        });
      }
    }
  };

  const initStart = (localUserPermissions: any) => {
    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(userPermissionsInfo);
    // apiLoading(false);
  };

  const dateFormat = (data: any) => {
    const formatDoubleDigit = (data: any) => {
      return data >= 10 ? data : "0" + data;
    };
    const getYear = data.getFullYear();
    const getMonth = formatDoubleDigit(data.getMonth() + 1);
    const getDate = formatDoubleDigit(data.getDate());
    return getYear + "-" + getMonth + "-" + getDate;
  };

  const searchHandle = (flag: any, _searchValueList: any = "") => {
    apiLoading(true);
    setResultData([]);
    setOrderBy(["promo_no"]);
    setOrder("desc");
    setNumSelected(0);
    setSelectedArray([]);
    setSelectedRowArray([]);
    setIndeterminate(false);
    setAllChecked(false);
    setSelectedPromotions(true);
    let checkData: any[] = [];
    let _params: any = "";
    let _page: boolean = false;
    if (_searchValueList === "") {
      _searchValueList = searchValueList;
      _page = true;
    }
    setSelectorValue(_searchValueList.promo_type);

    // all
    if (_searchValueList.promo_type === "10") {
      checkData = [...defaultFreeGoods, ...defaultPromotionCode];
    } else if (_searchValueList.promo_type === "30") {
      checkData = [...defaultDiscountRate, ...defaultPromotionCode];
    } else if (_searchValueList.promo_type === "40") {
      checkData = [
        ...defaultDiscountRate,
        ...defaultFreeGoods,
        ...defaultPromotionCode,
      ];
    } else if (_searchValueList.promo_type === "50") {
      checkData = [...defaultFreeGoods];
    } else if (_searchValueList.promo_type === "60") {
      checkData = [
        ...defaultItem,
        ...defaultDiscountRate,
        ...defaultFreeGoods,
        ...defaultPromotionCode,
      ];
    } else if (_searchValueList.promo_type === "20") {
      checkData = [
        // ...defaultItem,
        ...defaultFreeGoods,
        ...defaultPromotionCode,
      ];
    }

    const objData = JSON.parse(JSON.stringify(_searchValueList));

    let number: number = 0;
    for (let key in objData) {
      if (!checkData.includes(key)) {
        let item = objData[key];
        if (
          item !== "" &&
          item !== null &&
          item !== false &&
          !Array.isArray(item)
        ) {
          if (
            key.includes("csac") ||
            key.includes("promotion_region") || 
            key.includes("customer_type") ||
            key.includes("customer_discount_code")
          ) {
            if (
              objData["customer_no"] !== null &&
              objData["customer_no"] !== ""
            ) {
              continue;
            }
          }
          if (
            key.includes("isac") ||
            key.includes("item_discount_code") ||
            key.includes("item_class")
          ) {
            if (objData["item_no"] !== null && objData["item_no"] !== "") {
              continue;
            }
          }
          if (
            key.includes("special_price_from_format") ||
            key.includes("special_price_to_format")
          ) {
            continue;
          }

          // if (
          //   (key === "special_price_from" || key === "special_price_to") &&
          //   Number(item) === 0
          // ) {
          //   continue;
          // }

          if (number !== 0) {
            _params += "&";
          }

          // promo type:all
          if (key === "promo_type" && item === "0") {
            _params += `${key}=`;
          } else {
            if (key === "start_date" || key === "end_date") {
              if (item !== null) {
                item = dateFormat(new Date(item));
              } else {
                item = "";
              }
            }
            if (key === "allow_overlap") {
              item = item ? "1" : "0";
            }
            _params += `${key}=${encodeURIComponent(item)}`;
          }
          number++;
        } else {
          if (Array.isArray(item) && item.length > 0) {
            for (let subkey in item) {
              if (number !== 0) {
                _params += "&";
              }
              let subItem = item[subkey];
              _params += `${key}=${subItem}`;
              number++;
            }
          }
        }
      }
    }

    setPromotionInfo(_searchValueList);
    getSearchData(_params, _page, flag);
  };

  const getSearchData = (query_parameters: any, _page: boolean, flag: any) => {
    if (flag === "delete") {
      const message = com("messages.MS0014");
      setAlertMessage(message);
      setAlertShow(true);
      setAlertType("success");
    } else if (flag === "upload") {
      const message = com("messages.MS0091");
      setAlertMessage(message);
      setAlertShow(true);
      setAlertType("success");
    } else if (flag === "search") {
      setAlertMessage("");
      setAlertShow(false);
    }

    apiLoading(true);
    // AM2101
    api
      .getReadPromotionListAPI(config, query_parameters)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AM2101", response);
        apiLoading(false);
        // page
        if (_page) {
          const obj = Object.assign({}, pageMessage, {
            page: 0,
          });
          setPromoPageInfo(obj);
        }
        if (!getApiResultStatus(code)) {
          if (code === 400 && response && response.message_id === "MA0012") {
            errorMessage(
              getAPIErrorInfo(response, "getReadPromotionListAPI"),
              "warning"
            );
          } else {
            errorMessage(response);
          }
          setSearchDataResult([]);
        } else {
          if (response) {
            if (response.total_num && response.total_num >= 2000) {
              const message = com("messages.MS0049");
              setAlertMessage(message);
              setAlertShow(true);
              setAlertType("warning");
            }

            // search result
            setSearchDataResult(response.promo_list);
          }
        }
      });
  };

  const setSearchDataResult = (data: any) => {
    //let newData: any = data.list.slice(0);
    let newData: any = data.slice(0);
    setResultData(newData);
  };

  // delete promotion alert
  const deletePromotionAlert = () => {
    setDeletePromotion(true);
  };

  // close promotion alert
  const closePromotionAlert = () => {
    setDeletePromotion(false);
  };

  // delete promotion
  const deletePromotionHandler = () => {
    setDeletePromotion(false);

    apiLoading(true);
    if (selectedRowArray && selectedRowArray.length > 0) {
      callDeletePromotionAPI(selectedRowArray, 0);
    }
  };

  const callDeletePromotionAPI = (selectedRowArray: any, item_index: any) => {
    const item = selectedRowArray[item_index];
    let params = {
      updated_date: item.updated_date,
    };
    // count++;
    api
      .deletePromotionAPI(config, String(item.promo_no), params)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          return;
        } else {
          if (item_index < selectedRowArray.length - 1) {
            callDeletePromotionAPI(selectedRowArray, item_index + 1);
          } else {
            // apiLoading(false);
            setTimeout(() => {
              searchHandle("delete");
            }, 100);
          }
        }
      });
  };

  const deletePromotionButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        data-testid="prompt_button_No"
        color="secondary"
        onClick={closePromotionAlert}
      >
        {getName("prompt_button_No")}
      </Button>
      <Button
        variant="contained"
        data-testid="prompt_button_Yes"
        onClick={deletePromotionHandler}
      >
        {getName("prompt_button_Yes")}
      </Button>
    </div>
  );

  const downloadCSV = () => {
    const param: any = selectedArray;
    setAlertMessage("");
    setAlertShow(false);
    // AM2107
    api
      .getDownloadPromotionAPI(config, param)
      .then(({ response, code }: { response: any; code: any }) => {
        if (!getApiResultStatus(code)) {
          if (code === 400 && response && response.message_id === "MA0012") {
            errorMessage(
              getAPIErrorInfo(response, "getDownloadPromotionAPI"),
              "warning"
            );
          } else {
            if (code !== 404) {
              errorMessage(response);
            }
          }
        } else {
          console.log("AM2107", code, response);
          setCSV(response);
        }
      });
  };

  const setCSV = (data: any) => {
    const str = getCSVContents(com, t, data, config, salesAnalysisDefinition);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "promotionList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const attentionDeletePromotion = <p>{com("messages.MS0069")}</p>;

  const createParam = (flag: any = false) => {
    let checkData: any[] = [];
    let _params: any = "";
    let _searchValueList: any = {};
    if (!flag) {
      _searchValueList = searchValueList;
    }

    // all
    if (_searchValueList.promo_type === "10") {
      checkData = [...defaultFreeGoods, ...defaultPromotionCode];
    } else if (_searchValueList.promo_type === "30") {
      checkData = [...defaultDiscountRate, ...defaultPromotionCode];
    } else if (_searchValueList.promo_type === "40") {
      checkData = [
        ...defaultDiscountRate,
        ...defaultFreeGoods,
        ...defaultPromotionCode,
      ];
    } else if (_searchValueList.promo_type === "50") {
      checkData = [...defaultFreeGoods];
    } else if (_searchValueList.promo_type === "60") {
      checkData = [
        ...defaultItem,
        ...defaultDiscountRate,
        ...defaultFreeGoods,
        ...defaultPromotionCode,
      ];
    } else if (_searchValueList.promo_type === "20") {
      checkData = [
        // ...defaultItem,
        ...defaultFreeGoods,
        ...defaultPromotionCode,
      ];
    }

    const objData = JSON.parse(JSON.stringify(_searchValueList));

    let number: number = 0;
    for (let key in objData) {
      if (!checkData.includes(key)) {
        let item = objData[key];
        if (
          item !== "" &&
          item !== null &&
          item !== false &&
          !Array.isArray(item)
        ) {
          if (
            key.includes("csac") ||
            key.includes("customer_type") ||
            key.includes("customer_discount_code")
          ) {
            if (
              objData["customer_no"] !== null &&
              objData["customer_no"] !== ""
            ) {
              continue;
            }
          }
          if (
            key.includes("isac") ||
            key.includes("item_discount_code") ||
            key.includes("item_class")
          ) {
            if (objData["item_no"] !== null && objData["item_no"] !== "") {
              continue;
            }
          }
          if (
            key.includes("special_price_from_format") ||
            key.includes("special_price_to_format")
          ) {
            continue;
          }

          if (number !== 0) {
            _params += "&";
          }

          // promo type:all
          if (key === "promo_type" && item === "0") {
            _params += `${key}=`;
          } else {
            if (key === "start_date" || key === "end_date") {
              if (item !== null) {
                item = dateFormat(new Date(item));
              } else {
                item = "";
              }
            }
            if (key === "allow_overlap") {
              item = item ? "1" : "0";
            }
            _params += `${key}=${encodeURIComponent(item)}`;
          }
          number++;
        } else {
          if (Array.isArray(item) && item.length > 0) {
            for (let subkey in item) {
              if (number !== 0) {
                _params += "&";
              }
              let subItem = item[subkey];
              _params += `${key}=${subItem}`;
              number++;
            }
          }
        }
      }
    }
    return _params;
  };

  const downloadCSVBySearch = () => {
    apiLoading(true);
    setAlertMessage("");
    setAlertShow(false);
    let _params: any = createParam();
    api
      .getDownloadPromotionByS3singedUrlApi(config, _params)
      .then(({ response, code }: { response: any; code: any }) => {
        if (!getApiResultStatus(code)) {
          apiLoading(false);
          errorMessage(response);
        } else {
          const url = response.content;
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              if (!getApiResultStatus(code)) {
                setAlertShow(true);
                const message = com("messages.MS0035");
                setAlertMessage(message);
                setAlertType("error");
                apiLoading(false);
              } else {
                let reader: any = new FileReader();
                reader.readAsText(response, "UTF-8");
                reader.onload = function () {
                  apiLoading(false);
                  let data = this.result;
                  setCSV(JSON.parse(data));
                };
              }
            });
        }
      });
  };

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <PromotionSearchControl
        searchHandle={searchHandle}
        selectedPromotions={selectedPromotions}
        searchValueList={searchValueList}
        setSearchValueList={setSearchValueList}
        defaultValueList={defaultValueList}
        changeCondition={changeCondition}
        setChangeCondition={setChangeCondition}
        customerNoDisabled={customerNoDisabled}
        setCustomerNoDisabled={setCustomerNoDisabled}
        itemNoDisabled={itemNoDisabled}
        setItemNoDisabled={setItemNoDisabled}
        errorValueList={errorValueList}
        setErrorValueList={setErrorValueList}
        errorDefaultList={errorDefaultList}
        searchButtondisabled={searchButtondisabled}
        setSearchButtondisabled={setSearchButtondisabled}
        numSelected={numSelected}
        setResultData={setResultData}
        setSelectorValue={setSelectorValue}
        handleClear={handleClear}
        setErrMessageList={setErrMessageList}
        setErrorMessageOpen={setErrorMessageOpen}
        apiLoading={apiLoading}
        userPermisssionInfo={userPermisssionInfo}
        deletePromotionAlert={deletePromotionAlert}
        downloadCSV={downloadCSV}
        setAlertShow={setAlertShow}
        setAlertMessage={setAlertMessage}
        setAlertType={setAlertType}
        errorMessage={errorMessage}
        salesAnalysisDefinition={salesAnalysisDefinition}
        salesAnalysisList={salesAnalysisList}
        setOldCustomerNo={setOldCustomerNo}
        oldCustomerNo={oldCustomerNo}
        setOldItemNo={setOldItemNo}
        oldItemNo={oldItemNo}
        downloadCSVBySearch={downloadCSVBySearch}
      />
      {selectorValue === "0" && (
        <PromotionSearchResultAll
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}
      {selectorValue === "10" && (
        <PromotionSearchResultDiscount
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      {selectorValue === "20" && (
        <PromotionSearchResultDealerDiscount
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      {selectorValue === "30" && (
        <PromotionSearchResultFreeGoods
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      {selectorValue === "40" && (
        <PromotionSearchResultSpecialPrice
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      {selectorValue === "50" && (
        <PromotionSearchResultPromotionCode
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      {selectorValue === "60" && (
        <PromotionSearchResultPaymentTerm
          resultData={resultData}
          setSelectedPromotions={setSelectedPromotions}
          numSelected={numSelected}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          needResetOrderBy={needResetOrderBy}
          setNeedResetOrderBy={setNeedResetOrderBy}
          changeNumSelected={changeNumSelected}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      <PromotionErrorMessageBox
        open={errorMessageOpen}
        errMessageList={errMessageList}
        handleClose={handleClose}
      />

      {deletePromotion && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeletePromotion}
          openFlg={deletePromotion}
          button={deletePromotionButton}
          handleCloseEvent={closePromotionAlert}
        />
      )}
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
