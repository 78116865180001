import { Message } from "@material-ui/icons";

export interface DashboardMessageListModel {
    messages?: DashboardMessageModel[]
}

export interface DashboardMessageModel {
    message_type: number;
    permission_group: number;
    message: string;
    updated_date: string;
    is_edit?: boolean;
    title: string;
};

export enum MessageType {
    SYSTEM = 1 ,
    BUSINESS = 2,
};

export enum PermissionGroup {
    REPAIR = 1,
    CSD = 2,
    WCO = 3,
};

export interface ErrorResponseModel {
    code: number;
    message_id: string;
    key: string;
};

export interface DashboardTitleModel {
    message_type: number;
    permission_group: number;
    title: string;
    isAdded: boolean;
};

export interface DashboardMessageRequestModel {
    message_type: number;
    permission_group: number;
    message: string;
};