import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      fontSize: "20px",
      marginTop: "20px",
      borderLeft: "3px solid #8DA1B9",
      borderBottom: "3px solid #8DA1B9",
      paddingLeft: "5px",
    },
    row: {
      fontSize: "16px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #D5B4B4",
      "& :first-child": {
        fontWeight: "bold",
      },
    },
    spacialRemark: {
      maxWidth: "66%",
      maxHeight: "223px",
      overflowWrap: "break-word",
      overflowY: "auto",
    },
    message: {
      textAlign: "right",
    },
    groupContainer: {
      width: "100%",
      maxHeight: 177,
      borderBottom: "1px solid #D5B4B4",
      borderRight: "1px solid #D5B4B4",
      borderLeft: "1px solid #D5B4B4",
    },
    root02: {
      "& .MuiTableCell-root": {
        padding: "2px 10px",
      },
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid #D5B4B4)",
      },
      "& th:last-child": {
        textAlign: "left",
        borderRight: "1px solid #D5B4B4",
        borderLeft: "1px solid #D5B4B4",
        backgroundColor: "#9A9393",
        color: "#fff",
        padding: "0px",
        // backgroundColor: "#fafafa"
      },
    },
    tbody: {
      backgroundColor: "#fafafa",
      "& td": {
        // borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& td:last-child": {
        borderRight: "1px solid #D5B4B4",
        borderBottom: "1px solid #D5B4B4",
        width: "220px",
      },
    },
    generalModelLink: {
      color: "#3D899F",
      float: "right",
      textDecoration: "underline",
      cursor: "pointer",
    },
    generalModelLinkNoUnderLine: {
      float: "right",
    },
    closeIcon: {
      float: "right",
      color: "#fff",
      padding: "3px !important",
    },
    tableHeadTitle: {
      float: "left",
      marginTop: "2px",
      padding: "10px",
      fontWeight: "bold",
    },
    closeBtn: {
      float: "right",
      padding: "11px 12px",
    },
    checkboxLayout: {
      padding: 0,
    },
    pre_approve: {
      display: "flex",
      paddingTop: "30px",
    },
    pre_approve_title: {
      fontWeight: "bold",
      fontSize: "16px",
      paddingRight: "10px",
    },
    pre_approve_error: {
      color: "red",
      margin: "0",
    },
  })
);
