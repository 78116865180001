import React from "react";
import { getLocale } from "../../../../modules/i18n";

export const CustomizedLabel = ({x, y, fill, width, value}) => {
    const locationX = width >= 180?x + width/2+30:x + width/2;
    return <text 
              x={locationX}
              y={y} 
              dy={-4} 
              fill={fill}
              textAnchor="middle">

       {new Intl.NumberFormat(getLocale()).format(value)}
   </text>
  };