import React, { useState, useEffect } from "react";

import { CustomizedLabel } from "../common/customizedLabel";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { Paper, Typography } from "@material-ui/core";

// language
import { useLocale } from "../../../../modules/hooks/UseLocale";

//rgb for the pie-charts
const colors = ["#00BFFF", "#87CEEB", "#F0E68C","#FFB6C1","#FA8072"];

export const BarGraphQA = ({ title, callData, classes }) => {
  const [barDataQA, setData] = useState([]);
  useEffect(() => {
    setData(JSON.parse(callData));
  }, [callData]);
  
  const { t } = useLocale({ defaultPath: "modules.components.report" });


return (
    <Paper elevation={3} className={classes.root}>

        <Typography className={classes.title}  >
        {title}
        </Typography>
        <Typography className={classes.title2} >
        <BarChart  
         className={classes.num}
        maxBarSize={200}
        barCategoryGap={-60}
        barGap={-60}
      
           width={200} height={375}
           position="center"
          data={barDataQA}
          margin={{
            top: 20,
            left:0,
            right:20,
            bottom: 50,}}
          >
               
        <Legend 
        layout="vertical" 
        textAlign= 'middle'
        align="center"
        wrapperStyle={{top: 340}} />

        <YAxis
        type="number"
        interval={0}
        allowDataOverflow={true}  />


             < XAxis 
             tick={false}
             type="category"
             interval={0}
             allowDataOverflow={true} />        
           
           <Tooltip cursor={false} wrapperStyle={{ display: "none" }} />
        
        <CartesianGrid  />

        <Bar 
        label={<CustomizedLabel />}
        dataKey={t("chart_component_waiting")}  
        stackId="a" 
        fill="#4dc4ff" />

         </BarChart>

      </Typography>
    </Paper>
  );
};
