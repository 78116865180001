import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export const headCells: HeadCell[] = [
  { id: "model_no", label: "model", searchFlg: true},
  { id: "error_description", label: "error_description", searchFlg: true},
  { id: "labor_value", label: "labor_value", searchFlg: true },
  { id: "Edit", label: "" },
  { id: "Delete", label: "" },
];

export const MasterMaintenanceLaborMasterThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath = "modules.components.MasterMaintenance.laborMaster";

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
