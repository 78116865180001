import React, { useState, useEffect } from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// component
import { SearchResultTableThead } from "./Ap.SearchResult.Table.Thead";
import { SearchResultTableTbody } from "./Ap.SearchResult.Table.Tbody";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { PathKey } from "../../AppRoutes";
// route
import { useHistory } from "react-router-dom";

export const SearchResultTable = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobs",
  });
  const history = useHistory();
  const { getJobsPageMessage, setJobsPageMessage, getHistoryRouter } =
    useCommonInfo();
  // pager
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let historyRouter = getHistoryRouter() as any;
  let pageMessage = getJobsPageMessage() as any;
  const [initFlg, setInitFlg] = useState(true);
  const { moreShow, moreShowCustomerPoNo, handleDownload } = props;

  useEffect(() => {
    if (initFlg && props.isloadingCompleted) {
      if (historyRouter.to === String(PathKey.JOBS)) {
        if (
          history.action === "POP" &&
          historyRouter.from === String(PathKey.JOBSDETAIL)
        ) {
          setPage(pageMessage.page);
        } else {
          const obj = Object.assign({}, pageMessage, { page: 0 });
          setJobsPageMessage(obj);
        }
        setInitFlg(false);
      }
    }
  }, [historyRouter, initFlg, props.isloadingCompleted]);

  const handleChangePage = (event: unknown, newPage: number) => {
    const obj = Object.assign({}, pageMessage, { page: newPage });
    setJobsPageMessage(obj);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const number = parseInt(event.target.value, 10);
    setRowsPerPage(number);
    const obj = Object.assign({}, pageMessage, { RowsPerPage: number });
    setJobsPageMessage(obj);
    setPage(0);
  };

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.job_no);
    // row selected job_no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected job_no list
      newSelected = newSelected.concat(props.selectedArray, row.job_no);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected job_no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected job_no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected job_no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data.slice(0);
      const newSelecteds = data.map((item: any) => item.job_no);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(["received_date", "job_no"]);
  const [orderFlg, setOrderFlg] = useState(false);
  useEffect(() => {
    // init search
    if (props.needResetOrderBy) {
      setOrderBy(["received_date", "job_no"]);
      setOrder("desc");
      if (pageMessage.RowsPerPage) {
        setRowsPerPage(pageMessage.RowsPerPage);
      } else {
        setRowsPerPage(10);
      }
      if (orderFlg) {
        setPage(0);
        setOrderFlg(true);
      }
      props.setNeedResetOrderBy(false);
    }
  }, [props.needResetOrderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy.indexOf(property) > -1 && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy([property]);
  };

  useEffect(() => {
    if (props.searchClickFlg) {
      setPage(0);
      props.setSearchClickFlg(false);
    }
  }, [props.searchClickFlg]);

  return (
    <div>
      <Paper>
        <TableContainer className={classes.jobListContainer}>
          <Table stickyHeader>
            <SearchResultTableThead
              indeterminate={props.indeterminate}
              allChecked={props.allChecked}
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              userPermisssionInfo={props.userPermisssionInfo}
            />
            <SearchResultTableTbody
              data={props.data}
              pageInfo={pageInfo}
              order={order}
              orderBy={orderBy}
              handleClick={(event: any, row: any) => handleClick(event, row)}
              selected={props.selectedArray}
              userPermisssionInfo={props.userPermisssionInfo}
              moreShow={moreShow}
              moreShowCustomerPoNo={moreShowCustomerPoNo}
              handleDownload={handleDownload}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t("label_rows_per_page")}
        />
      </Paper>
    </div>
  );
};
