import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM0504
export const DeleteRoleMasterAPI = (
  role_group: any,
  role_id: any,
  data: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/roles/${affiliate_cd}/${role_group}/${role_id}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0505
export const GetRoleListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/roles/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
