import { stringify } from "querystring";
import {
  getCodeNameByNumber,
  getSignByCSV,
} from "../../../modules/common.methods";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";

export const getCSVTitleInfo = (t: any) => {
  return {
    received_date: {
      name: t("table_receive_date"),
      prop: "rcd",
      label: "Receive date",
    },
    elapsed_time_number: {
      name: t("table_elapsed_time"),
      prop: "et",
      label: "Elapsed time",
    },
    job_no: { name: t("table_job_no"), prop: "jn", label: "Job no." },
    order_no: { name: t("txt_order_no"), prop: "on", label: "Order No." },
    statusName: { name: t("table_status"), prop: "st", label: "Status" },
    model_no: { name: t("table_model"), prop: "mn", label: "Model" },
    serial_no: { name: t("table_serial_no"), prop: "sn", label: "Serial No." },
    accessory_attached: {
      name: t("accessory_attached"),
      prop: "aa",
      label: "Accessory attached",
    },
    customer_po_no: {
      name: t("table_customer_po_no"),
      prop: "cpn",
      label: "Customer PO no.",
    },
    customer_no: {
      name: t("table_customer_no"),
      prop: "cn",
      label: "Customer No.",
    },
    customer_name: {
      name: t("table_customer_name"),
      prop: "ca",
      label: "Customer Name",
    },
    postal_cd: { name: t("table_postcode"), prop: "pc", label: "Postal code" },
    address_1: { name: t("table_city"), prop: "a1", label: "City" },
    address: { name: t("table_address"), prop: "a2", label: "Address" },
    mail_address: {
      name: t("txt_mail_address"),
      prop: "ea",
      label: "Email Address",
    },
    repair_option: {
      name: t("repair_option"),
      prop: "ro",
      label: "Repair Option",
    },
    quoteResultName: {
      name: t("table_quote_result"),
      prop: "qr",
      label: "Quote Result",
    },
    repair_amount: {
      name: t("txt_Total"),
      prop: "ra",
      label: "Repair Amount",
    },
    quote_send_date: {
      name: t("quote_sent_date"),
      prop: "qs",
      label: "Quote send date",
    },
    quote_remind_date: {
      name: t("quote_remind_date"),
      prop: "qd",
      label: "Quote remind date",
    },
    quote_answer_date: {
      name: t("reply_date"),
      prop: "rd",
      label: "Quote answer date",
    },
    repair_start_date: {
      name: t("repair_start_date"),
      prop: "rsd",
      label: "Repair start date",
    },
    repair_end_date: {
      name: t("repair_end_date"),
      prop: "red",
      label: "Repair end date",
    },
    repair_completed_date: {
      name: t("repair_completed_date"),
      prop: "cd",
      label: "Repair completed date",
    },
    quote_sent_count: {
      name: t("txt_quote_sent_count"),
      prop: "qc",
      label: "Quote Sent Count",
    },
    location_no: {
      name: t("table_location_no"),
      prop: "ln",
      label: "Location No.",
    },
    name: { name: t("table_technician"), prop: "tc", label: "Technician" },
    trade_in_approve: {
      name: t("txt_trade_in_approve"),
      prop: "ti",
      label: "Trade-In Approve",
    },
    service_center: {
      name: t("txt_service_center"),
      prop: "sc",
      label: "Service Center",
    },
    tracking_no: {
      name: t("txt_tracking_no"),
      prop: "tnp",
      label: "Tracking No.",
    },
    memo: { name: t("memo"), prop: "me", label: "Memo" },
    self_pickup: { name: t("table_self_pickup"), prop: "sp", label: "Self Pickup" },
  };
};

export const getCSVTitle = (sign: any, t: any) => {
  let str = "";

  const _title: any = getCSVTitleInfo(t);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item].name !== "") {
      if (itemIndex < titleSize) {
        str += _title[item].name + sign;
      } else {
        str += _title[item].name;
      }
    }
  }
  str += "\n";
  return str;
};

export const getSign = (config: any) => {
  return getSignByCSV(config.affiliate_cd);
};

export const doQuotationMarks = (item: any) => {
  let str = String(item);
  return str.replace(/[\"]/g, '""');
};

// date length
export const date2length = (v: any, len: number) => {
  let _t: string = typeof v === "number" ? v.toString() : v;
  for (let i = 0; i < len; i++) {
    if (typeof _t === "string" && _t.length <= len - 1) {
      _t = "0" + _t;
    }
  }
  return _t;
};

// get CSV contents
export const getCSVContents = (com: any, t: any, data: any, config: any) => {
  let str = "";
  const sign: any = getSign(config);
  const _title: any = getCSVTitleInfo(t);

  str += getCSVTitle(sign, t);
  if (data && data.length > 0) {
    data.forEach((item: any) => {
      for (let _key in _title) {
        let key: any = _title[_key].prop;
        let v = "";

        v = item[key] === null || item[key] === undefined ? "" : item[key];
        v = doQuotationMarks(v);

        if (_key === "statusName") {
          v = getStatusName(item[key], com, "status");
          // Accessory attached
        } else if (_key === "accessory_attached") {
          // let _code: any = Number(item[key]);
          // v = getStatusName(_code, com, "csv_checked");
          // Repair Option
        } else if (_key === "repair_option") {
          let _code: any = Number(item[key]);
          v = getStatusName(_code, com, "repair_option");
        } else if (_key === "quoteResultName") {
          v = getStatusName(item[key], com, "quote_result");
          //Total Amount 
        } else if (_key === "repair_amount") {
          if(v) {
            v = v.replace(".", ",")
          } else {
            v = "";
          }         
        }  else if (_key === "trade_in_approve") {
          let _code: any = Number(item[key]);
          v = getStatusName(_code, com, "csv_checked");
          // tracking no
        } else if (_key === "tracking_no") {
          v = handleStr(item);
          // address
        } else if (_key === "address") {
          if (item.a2 && item.a3) {
            v = item.a2 + ", " + item.a3;
          } else {
            if (item.a2) {
              v = item.a2;
            } else {
              v = item.a3;
            }
          }
        } else if (_key === "self_pickup") {
          let _code: any = Number(item[key]);
          v = getStatusName(_code, com, "csv_checked");
        } else if (
            _key === "received_date"
            || _key === "quote_send_date"
            || _key === "quote_remind_date"
            || _key === "quote_answer_date"
            || _key === "repair_start_date"
            || _key === "repair_end_date"
            || _key === "repair_completed_date"
          ) {
            if(item[key]!=""){
              v = convertToLocalISODate(item[key]);
            }
        }

        v = v === "" ? "-" : v;

        str += '"' + v + '"' + sign;
      }

      str += "\n";
    });
  }
  return str;
};

// get CSV file name
export const getCSVFileName = () => {
  const date = new Date();
  let y = date.getFullYear().toString();
  let m: any = date2length(date.getMonth() + 1, 2);
  let d: any = date2length(date.getDate(), 2);
  let hh = date2length(date.getHours(), 2);
  let mm = date2length(date.getMinutes(), 2);
  let ss = date2length(date.getSeconds(), 2);

  return y + m + d + hh + mm + ss;
};

const handleStr = (data: any) => {
  data.tracking_no_pickup = data.tnp;
  data.tracking_no_return = data.tnr;
  if (data.tracking_no_return !== undefined && data.tracking_no_return !== "") {
    let str_tracking_no = data.tracking_no_return;
    if (data.tracking_no_return.substr(0, 1) == "%") {
      str_tracking_no = str_tracking_no
        .replace(str_tracking_no.substr(0, 8), "")
        .replace(str_tracking_no.substr(-6, 6), "");
      return str_tracking_no;
    } else {
      return str_tracking_no;
    }
  } else if (
    data.tracking_no_pickup !== undefined &&
    data.tracking_no_pickup !== ""
  ) {
    let str_tracking_no = data.tracking_no_pickup;
    if (data.tracking_no_pickup.substr(0, 1) == "%") {
      str_tracking_no = str_tracking_no
        .replace(str_tracking_no.substr(0, 8), "")
        .replace(str_tracking_no.substr(-6, 6), "");
      return str_tracking_no;
    } else {
      return str_tracking_no;
    }
  } else {
    return data.trackingNo ? data.trackingNo : "";
  }
};

// fetch the status name from constants
const getStatusName = (code: any, com: any, type: any) => {
  const statusList = com(type);

  let statusName = "";
  if (code || code === 0) {
    for (let index in statusList) {
      if (statusList[index].code === code) {
        statusName = statusList[index].name;
        break;
      }
    }
  }
  return statusName;
};

export const isAllEmpty = (data: any) => {
  let result = false;
  if (
    data.job_no === "" &&
    data.customer_no === "" &&
    data.customer_po_no === "" &&
    data.model_no === "" &&
    data.serial_no === "" &&
    data.tracking_no === "" &&
    data.postal_cd === "" &&
    data.service_center_id.length === 0 &&
    data.customer_name === "" &&
    data.address === "" &&
    data.address_1 === "" &&
    data.mail_address === "" &&
    data.status.length === 0 &&
    data.technician_cd === "" &&
    data.order_no === "" &&
    data.received_date_from === null &&
    data.received_date_to === null &&
    data.location_no === "" &&
    data.quote_sent_count === "" &&
    data.quote_result.length === 0 &&
    data.trade_in_approve === ""
  ) {
    result = true;
  }
  return result;
};

export const isAllErrMsgmpty = (data: any) => {
  let result = false;
  if (
    data.job_no_error === "" &&
    data.customer_no_error === "" &&
    data.model_no_error === "" &&
    data.customer_name_error === "" &&
    data.mail_address_error === "" &&
    data.technician_cd_error === "" &&
    data.received_date_from_error === "" &&
    data.received_date_to_error === "" &&
    data.location_no_error === "" &&
    data.quote_sent_count_error === ""
  ) {
    result = true;
  }
  return result;
};

export const defaultValue = {
  job_no: "",
  customer_no: "",
  customer_po_no: "",
  model_no: "",
  serial_no: "",
  tracking_no: "",
  postal_cd: "",
  service_center_id: [""],
  customer_name: "",
  address: "",
  address_1: "",
  mail_address: "",
  status: ["300", "400", "510"],
  technician_cd: "",
  order_no: "",
  received_date_from: null,
  received_date_to: null,
  location_no: "",
  quote_sent_count: "",
  quote_result: [],
  trade_in_approve: "",
  job_no_error: "",
  customer_no_error: "",
  model_no_error: "",
  customer_name_error: "",
  mail_address_error: "",
  technician_cd_error: "",
  received_date_from_error: "",
  received_date_to_error: "",
  location_no_error: "",
  quote_sent_count_error: "",
};

export const createParam = (data: any, appendErr = false) => {
  let param_serviceCenterId = "";
  let param_status = "";
  let param_receivedDateFrom = "";
  let param_receivedDateTo = "";
  let param_quote_result = "";
  if (data.service_center_id.length > 0) {
    for (let index in data.service_center_id) {
      param_serviceCenterId =
        param_serviceCenterId +
        "&service_center_id=" +
        data.service_center_id[index];
    }
  } else {
    param_serviceCenterId = "&service_center_id=";
  }
  if (data.status.length > 0) {
    for (let index in data.status) {
      param_status = param_status + "&status=" + data.status[index];
    }
  } else {
    param_status = "&status=";
  }
  let received_date_from = data.received_date_from;
  if (received_date_from !== null) {
    param_receivedDateFrom = new Date(received_date_from).toISOString();
  } else {
    param_receivedDateFrom = "";
  }

  let received_date_to = data.received_date_to;
  if (received_date_to !== null) {
    param_receivedDateTo = new Date(received_date_to).toISOString();
  } else {
    param_receivedDateTo = "";
  }
  if (data.quote_result.length > 0) {
    for (let index in data.quote_result) {
      param_quote_result =
        param_quote_result + "&quote_result=" + data.quote_result[index];
    }
  } else {
    param_quote_result = "&quote_result=";
  }

  let query_parameters =
    "job_no=" +
    encodeURIComponent(data.job_no) +
    "&customer_no=" +
    encodeURIComponent(data.customer_no) +
    "&customer_po_no=" +
    encodeURIComponent(data.customer_po_no) +
    "&model_no=" +
    encodeURIComponent(data.model_no) +
    "&serial_no=" +
    encodeURIComponent(data.serial_no) +
    "&tracking_no=" +
    encodeURIComponent(data.tracking_no) +
    "&postal_cd=" +
    encodeURIComponent(data.postal_cd) +
    param_serviceCenterId +
    "&customer_name=" +
    encodeURIComponent(data.customer_name) +
    "&address=" +
    encodeURIComponent(data.address) +
    "&address_1=" +
    encodeURIComponent(data.address_1) +
    "&mail_address=" +
    encodeURIComponent(data.mail_address) +
    param_status +
    "&technician_cd=" +
    encodeURIComponent(data.technician_cd) +
    "&order_no=" +
    encodeURIComponent(data.order_no) +
    "&received_date_from=" +
    encodeURIComponent(param_receivedDateFrom) +
    "&received_date_to=" +
    encodeURIComponent(param_receivedDateTo) +
    "&location_no=" +
    encodeURIComponent(data.location_no) +
    "&quote_sent_count=" +
    encodeURIComponent(data.quote_sent_count) +
    param_quote_result +
    "&trade_in_approve=" +
    encodeURIComponent(data.trade_in_approve);

  return query_parameters;
};

const convertToLocalISODate = (utcDate: Date) => {
  let localYear = new Date(utcDate).getFullYear();
  let localMonth = String(new Date(utcDate).getMonth() + 1).padStart(2, '0');
  let localDay = String(new Date(utcDate).getDate()).padStart(2, '0');

  let received_time = new Date(
    utcDate
  ).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  return `${localYear}-${localMonth}-${localDay}` + "T" + received_time + "Z";
}
