import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
    },
    theadCell: {
      background: "rgba(240, 242, 244)",
    },
    tbody: {
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
    },
    tbody2: {
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      "& td:not(:nth-child(1))": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    contents: {
      wordBreak: "break-all",
      whiteSpace: "pre-line",
    },
    groupContainer: {
      maxHeight: 300,
    },
    AllocateModelContainer: {
      width: "1792px",
      overflow: "auto",
    },
    container: {
      maxHeight: "700px",
    },
    table: {
      width: "auto",
      "& th": {
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "center",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
        border: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingBottom: "20px",
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    tableStickyHeader: {
      borderLeft: "1px solid rgba(198, 209, 221)",
      borderRight: "1px solid rgba(198, 209, 221)",
    },
    memo: {
      overflow:'hidden',
      height:'60px',
      "& .MuiTableCell-root": {
        padding: "5px",
      }
    }
  })
);
