import React from "react";
// components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// hooks
import { useStyles } from "../hooks/useStyles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
export const JobsDetailCustomerInfoRepairAddressPickUp = (props: any) => {
  const classes = useStyles();
  const { 
          formList,
          formErrorList,
          onBlurOfPickupName,
          onBlurOfPickupAddress2,
          onBlurOfPickupAddress3,
          onBlurOfPickupAddress1,
          onBlurOfPickupPostalCd,
          setFormListValue, 
          countriesList, 
          userPermisssionInfo 
        } = props;
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.customer_info",
  });

  const handleStr = (key: any, value: any) => {
    let str_tracking_no_pickup = value;
    if(formList.tracking_no_pickup.substr(0,1) == "%") {   
      let beforeStr = formList.tracking_no_pickup.substr(0,8);
      let afterStr = formList.tracking_no_pickup.substr(-6,6);
      str_tracking_no_pickup = beforeStr.concat(str_tracking_no_pickup).concat(afterStr); 

    }
    return setFormListValue(key, str_tracking_no_pickup);
  }
  
  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name_address_pickup")}</h3>
      <table className={classes.tableLayout}>
        <tbody>
          <tr>
            <th>
            </th>
            <td className={classes.tdHight}>
            </td>
          </tr>
          <tr>
            <th>{t("name")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressPickup.name}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressPickup", e.target.value, "name")
                }
                inputProps={{ tabIndex: -1, maxLength: 30 }}
                onBlur={onBlurOfPickupName}
                error={formErrorList.pickup_name_error !== ""}
                helperText={formErrorList.pickup_name_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("country")}</th>
            <td>
              <FormControl variant="outlined">
                <Select
                  disabled={!userPermisssionInfo.customer_info_authFlg}
                  id="country_pickup"
                  value={formList.addressPickup.country}
                  onChange={(e) =>
                    setFormListValue("addressPickup", e.target.value, "country")
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                  inputProps={{ tabIndex: -1 }}
                >
                  {countriesList.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>{t("address_2")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressPickup.address_2}
                variant="outlined"
                onChange={(e) => setFormListValue("addressPickup", e.target.value, "address_2")}
                onBlur={onBlurOfPickupAddress2}
                error={formErrorList.pickup_address_2_error !== ""}
                helperText={formErrorList.pickup_address_2_error}
                inputProps={{ tabIndex: -1, maxLength: 50 }} />
            </td>
          </tr>
          <tr>
            <th>{t("address_3")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressPickup.address_3}
                variant="outlined"
                onChange={(e) => setFormListValue("addressPickup", e.target.value, "address_3")}
                onBlur={onBlurOfPickupAddress3}
                error={formErrorList.pickup_address_3_error !== ""}
                helperText={formErrorList.pickup_address_3_error}
                inputProps={{ tabIndex: -1, maxLength: 50 }} />
            </td>
          </tr>
          <tr>
            <th>{t("address_1")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressPickup.address_1}
                variant="outlined"
                onChange={(e) => setFormListValue("addressPickup", e.target.value, "address_1")}
                onBlur={onBlurOfPickupAddress1}
                error={formErrorList.pickup_address_1_error !== ""}
                helperText={formErrorList.pickup_address_1_error}
                inputProps={{ tabIndex: -1, maxLength: 50 }} />
            </td>
          </tr>
          <tr>
            <th>{t("postal_cd")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressPickup.postal_cd}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressPickup", e.target.value, "postal_cd")
                }
                onBlur={onBlurOfPickupPostalCd}
                error={formErrorList.pickup_postal_cd_error !== ""}
                helperText={formErrorList.pickup_postal_cd_error}
                inputProps={{ tabIndex: -1, maxLength: 9 }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className={classes.tableLayout}>
        <tbody>
          <tr>
            <th>{t("pickup_scheduled_date")}</th>
            <td>
              <p>
                {!formList.pickup_scheduled_date ||
                formList.pickup_scheduled_date === ""
                  ? ""
                  : date(new Date(formList.pickup_scheduled_date).getTime())}
              </p>
            </td>
          </tr>
          <tr>
            <th>{t("pickup_carrier")}</th>
            <td>
              <p>{formList.pickup_carrier_name}</p>
            </td>
          </tr>
          <tr>
            <th>{t("pickup_date")}</th>
            <td>
              <p>
                {!formList.pickup_date || formList.pickup_date === ""
                  ? ""
                  : date(new Date(formList.pickup_date).getTime())}
              </p>
            </td>
          </tr>
          <tr>
            <th>{t("tracking_no_pickup")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={(formList.tracking_no_pickup.substr(0,1) == "%")?formList.tracking_no_pickup.replace(formList.tracking_no_pickup.substr(0,8),"").replace(formList.tracking_no_pickup.substr(-6,6),""):formList.tracking_no_pickup}
                variant="outlined"
                onChange={(e) =>
                  handleStr("tracking_no_pickup", e.target.value)
                }
                inputProps={{ tabIndex: -1, maxLength: (formList.tracking_no_pickup.substr(0,1) == "%")?16:30 }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
