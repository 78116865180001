import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowHeader: { 
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "rgba(240, 242, 244)",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    }
  })
);

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "created_date", label: "created_date" },
  { id: "promo_type", label: "promotion_type" },
  { id: "promo_name", label: "promotion_name" },
  { id: "details", label: "details" }
];

export const DraftsSearchResultTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.drafts" });

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy.indexOf(headCell.id) > -1 ? order : false}
          >
            <TableSortLabel
              active={orderBy.indexOf(headCell.id) > -1}
              direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy.indexOf(headCell.id) > -1 ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
