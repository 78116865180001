import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
// constants
import { TabKey } from "../MasterMaintenance.constants";
// api
import api from "../../../modules/api/Api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "20px",
      },
    },
    mark: {
      color: "red",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
        paddingTop: "5px",
      },
    },
    selectContainer: {
      width: "100%",
      height: "30px",
    },
    select: {
      height: "30px",
      "& .MuiSelect-selectMenu": {},
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "300px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    line: {
      border: "1px solid black",
    },
    buttonWidth: {
      width: "90px",
      height: "35px",
    },
    buttonWidth2: {
      width: "130px",
      height: "35px",
    },
  })
);
export const MasterMaintenanceUserRoleEditor = (props: any) => {
  const classes = useStyles();
  const {
    changeData,
    editData,
    handleBack,
    handleSubmit,
    roleDataList,
    roleDataCheckedList,
    setRoleDataListValue,
    setRoleDataStartCheckedNumber,
    apiLoading,
    config,
  } = props;
  const {
    given_name,
    family_name,
    middle_name,
    mail_address,
    userinfo_repair,
    sub,
  } = editData;
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.userRole",
  });

  // start
  const [startFlg, setStartFlg] = useState(false);
  useEffect(() => {
    if (!startFlg) {
      setStartFlg(true);
      apiLoading(true);
      let i = 0;
      // AM1202
      api
        .getReadUserRoleListAPI(sub, config)
        .then(({ response }: { response: any }) => {
          apiLoading(false);
          console.log("getReadUserRoleListAPI response", response);
          if (response && response.length > 0) {
            let newList: any = {};
            response.forEach((item: any) => {
              newList["G" + item.role_group + "ID" + item.role_id] =
                item.selected_role_id !== undefined;
              console.log("item:", item);
              if (item.selected_role_id !== undefined) {
                i++;
              }
            });
            setRoleDataListValue(newList);
          }
          setRoleDataStartCheckedNumber(i);
        });
    }
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>{t("given_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={given_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("family_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={family_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("middle_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={middle_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("mail_address")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={mail_address}
              />
            </td>
          </tr>
          <tr>
            <th>{t("technician_code")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={userinfo_repair.erp_user_id}
              />
            </td>
          </tr>
          <tr>
            <th>{t("role")}</th>
            <td className={classes.line}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {roleDataList.map((item: any) => (
                    <FormControlLabel
                      key={"G" + item.role_group + "ID" + item.role_id}
                      control={
                        <Checkbox
                          checked={
                            roleDataCheckedList[
                              "G" + item.role_group + "ID" + item.role_id
                            ]
                          }
                          color="primary"
                          onChange={(e: any) =>
                            changeData(
                              "G" + item.role_group + "ID" + item.role_id,
                              e.target.checked,
                              "roleList"
                            )
                          }
                          name={item.role_name}
                        />
                      }
                      label={item.role_name}
                      labelPlacement="start"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.buttonWidth2}
                  variant="contained"
                  onClick={handleBack}
                >
                  {t("back")}
                </Button>
                <Button
                  className={classes.buttonWidth2}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(TabKey.userRole)}
                >
                  {t("submit")}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
