import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// api
import api from "../../../modules/api/Api";
// check
import {
  isEmptyCheck,
  checkMaxLength,
} from "../../../modules/hooks/UseValication";
// methods
import {
  getApiResultStatus,
  getSignByCSV,
} from "../../../modules/common.methods";
import { checkSplitSymbol } from "../methods/common.methods";

import { TabKey } from "../MasterMaintenance.constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& dl": {
        paddingLeft: "10px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    table: {
      width: "100%",
      maxWidth: "800px",
      margin: "0 auto",
      borderSpacing: "0",
      "& tr": {
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    mark: {
      color: "red",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
      },
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "300px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
    },
    divStyle: {
      paddingTop: "20px",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
    },
    buttonContainer02: {
      width: "100%",
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      border: "1px solid rgba(198, 209, 221)",
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        background: "rgba(240, 242, 244)",
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    buttonWidth: {
      width: "90px",
      height: "35px",
    },
    buttonWidth2: {
      width: "130px",
      height: "35px",
    },
    thStyle: {
      textAlign: "left",
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    tdStyle: {
      verticalAlign: "top",
      width: "30px",
    },
    tdStyle2: {
      verticalAlign: "top",
    },
    tdBtnStyle: {
      verticalAlign: "top",
      width: "30px",
      paddingRight: "10px",
    },
    tdTextStyle: {
      width: "90px",
    },
    textStyle: {
      height: "30px",
    },
    faultCheckBox: {
      marginLeft: "35%",
      color: "rgba(0, 0, 0, 0.54)",
      "&.Mui-checked": {
        color: "#3D899F",
      },
    },
    textFieldWidth: {
      width: "45%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiFormHelperText-contained": {
        marginTop: "-3px",
        marginLeft: "0px",
      },
    },
    autoComplete: {
      width: "420px",
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
      {
        padding: "0px",
        paddingLeft: "5px",
      },
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
  checkFlg?: boolean;
}

const headCells: HeadCell[] = [
  { id: "error_description", label: "error_description" },
  { id: "labour_value", label: "labor_value" },
  { id: "fault_description", label: "fault_description" },
];

export const MasterMaintenanceLaborMasterEditor = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.laborMaster",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const errorList: any = {
    laborMaster: [
      {
        laborMaster_error_description: "",
        laborMaster_labor_value: "",
      },
    ],
  };
  const [errorValueList, setErrorValueList] = useState(errorList);

  const {
    editData,
    changeData,
    handleBack,
    errorMessage,
    getLaborMasterListAPI,
    setAlertMessageList,
    successMessage,
  } = props;

  const { model_no, registerFlg } = editData;

  const defaultValue: any = [
    {
      created_by: "",
      created_date: null as any,
      error_description: "",
      error_type: "",
      labor_value: "",
      model_no: "",
      updated_by: "",
      updated_date: null as any,
      index: 0,
      update_flg: "",
    },
  ];

  // loading
  const { setCommonInfo, getCommonConfig, getCommonInfo, setCommonInfoFull } =
    useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  const commonInfo = getCommonInfo() as any;

  const [startFlg, setStartFlg] = useState(false);
  const [pageData, setPageData] = useState(defaultValue);
  const [oldPageData, setOldPageData] = useState(defaultValue);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const [allChecked, setAllChecked] = useState(false);
  const [disabledFlg, setDisabledFlg] = useState(true);

  useEffect(() => {
    if (!startFlg) {
      setStartFlg(true);
      getItemDetailList();
      if (!registerFlg) {
        getReadModelErrorTypeList(model_no);
      }
    }
  });

  const initErrorList = (listData: any) => {
    let _errorList: any = {};
    let laborMaster: any = [];
    listData.forEach(() => {
      let getRow = { laborMaster_error_description: "", laborMaster_labor_value: "" };
      laborMaster.push(getRow);
    });
    _errorList.laborMaster = laborMaster;
    setErrorValueList(_errorList);
  };

  const getItemDetailList = () => {
    apiLoading(true);
    let masterItemList: any = [];
    let itemListApi;
    if (commonInfo.itemList.length === 0) {
      itemListApi = api
        .getItemDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0305 response: ", response);
          if (code !== 200) {
            errorMessage(response);
          } else {
            masterItemList = response;
          }
        });
    } else {
      masterItemList = commonInfo.itemList;
    }

    Promise.all([itemListApi]).then(() => {
      setCommonInfoFull({
        itemList: masterItemList,
      });
      apiLoading(false);
    });
  };

  const getReadModelErrorTypeList = async (model_no: any) => {
    apiLoading(true);
    await api
      .getReadClaimLaborAPI(encodeURIComponent(model_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get Model Error Type List", code, response);
        if (getApiResultStatus(code)) {
          if (response.length > 0) {
            if (Array.isArray(response)) {
              response.map((item: any, index: number) => {
                item.index = index;
              });
            }
          }
          setPageData(response);
          setOldPageData(response);
          initErrorList(response);
          refeshSubmitButton(response);
        } else {
          if (code !== 404) {
            errorMessage(response);
          } else {
            setDisabledFlg(true);
          }
          const getErrorTypeField = JSON.parse(JSON.stringify(pageData[0]));
          for (let key in getErrorTypeField) {
            getErrorTypeField[key] = "";
          }

          // delete all exsit value
          const getAfterDeletedData = pageData.filter((item: any) => {
            return pageData.indexOf(item.index) !== -1;
          });

          // add blank row
          if (getAfterDeletedData.length === 0) {
            getAfterDeletedData.push(getErrorTypeField);
          }
          getAfterDeletedData.forEach((item: any, index: number) => {
            item.index = index;
          });
          setPageData(getAfterDeletedData);
          setOldPageData([]);
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const refeshSubmitButton = (pageData: any) => {
    let errorValueFlg = false;
    if (model_no === "" || model_no === null || model_no === undefined) {
      errorValueFlg = true;
    }

    const errorList = JSON.parse(JSON.stringify(pageData));
    if (Object.keys(errorList).length > 0) {
      for (let key in errorList) {
        if (errorList[key].error_description === "" || errorList[key].labor_value === "") {
          errorValueFlg = true;
        }
      }
    }
    if (errorValueFlg) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
  };

  const addErrorTypeRow = () => {
    setDisabledFlg(true);
    // ready one row
    const getErrorTypeField = JSON.parse(JSON.stringify(pageData[0]));
    for (let key in getErrorTypeField) {
      getErrorTypeField[key] = "";
    }

    // add row at last
    const getErrorTypeData = JSON.parse(JSON.stringify(pageData));
    if (
      pageData[pageData.length - 1]["error_description"] !== "" &&
      pageData[pageData.length - 1]["labor_value"] !== ""
    ) {
      getErrorTypeData.push(JSON.parse(JSON.stringify(getErrorTypeField)));
      getErrorTypeData[getErrorTypeData.length - 1].index =
        getErrorTypeData.length - 1;
      setPageData(getErrorTypeData);

      const _errorList = JSON.parse(JSON.stringify(errorValueList));
      let getRow: any = {};
      getRow = _errorList.laborMaster[0];
      _errorList.laborMaster.push({ ...getRow });

      setErrorValueList(_errorList);
    }
  };

  const deleteErrorTypeRow = () => {
    // ready one row
    const getErrorTypeField = JSON.parse(JSON.stringify(pageData[0]));

    for (let key in getErrorTypeField) {
      getErrorTypeField[key] = "";
    }

    // delete row with "index"
    const getAfterDeletedData = pageData.filter((item: any) => {
      return selectedArray.indexOf(item.index) === -1;
    });

    if (getAfterDeletedData.length === 0) {
      if (model_no === "") {
        setDisabledFlg(true);
      }
      getAfterDeletedData.push(getErrorTypeField);
    }

    setSelectedArray([]);
    setSelectedRowArray([]);
    setIndeterminate(false);
    setAllChecked(false);

    getAfterDeletedData.forEach((item: any, index: number) => {
      item.index = index;
    });

    const _errorList = JSON.parse(JSON.stringify(errorValueList));

    const getAfterDeletedErrorList = _errorList.laborMaster.filter(
      (item: any, index: any) => {
        return selectedArray.indexOf(index) === -1;
      }
    );

    if (getAfterDeletedErrorList.length === 0) {
      let getRow = { error_description_error: "" };

      getAfterDeletedErrorList.push(getRow);
    }
    _errorList.laborMaster = getAfterDeletedErrorList;
    setErrorValueList(_errorList);

    setPageData(JSON.parse(JSON.stringify(getAfterDeletedData)));
  };

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selectedArray.indexOf(row.index);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(selectedArray, row.index);
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(1);
    } else if (selectedIndex === selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        selectedArray.slice(0, selectedIndex),
        selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    setSelectedArray(list);
    setSelectedRowArray(rowList);
    setNumSelected(list.length);
    const flg = list.length > 0 && list.length < pageData.length;
    setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === pageData.length;
    setAllChecked(allCheckedFlg);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = pageData.slice(0);
      const newSelecteds = data.map((item: any) => item.index);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const handleSubmit = () => {
    apiLoading(true);
    setAlertMessageList([]);
    let param: any = [];
    if (oldPageData.length === 0) {
      for (let i = 0; i < pageData.length; i++) {
        if (pageData[i]["error_type"] === "") {
          pageData[i].model_no = model_no;
          pageData[i].update_flg = "0";
          param.push(pageData[i]);
        }
      }
      return api
        .createClaimLaborAPI(param, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (getApiResultStatus(code)) {
            successMessage("post");
            apiLoading(false);
            return getReadModelErrorTypeList(model_no), getLaborMasterListAPI();
          } else {
            apiLoading(false);
            return errorMessage(response);
          }
        });
    } else {
      let needEditData: any = updateData(oldPageData, pageData);
      param = needEditData.slice(0);
      return api
        .updateClaimLaborAPI(encodeURIComponent(model_no), param, config)
        .then(({ response, code }: { response: any; code: any }) => {
          successMessage("patch");
          if (getApiResultStatus(code)) {
            apiLoading(false);
            return getReadModelErrorTypeList(model_no), getLaborMasterListAPI();
          } else {
            apiLoading(false);
            return errorMessage(response);
          }
        });
    }
  };

  const updateData = (oldPageData: any, pageData: any) => {
    let newList: any = [];
    for (let i = 0; i < oldPageData.length; i++) {
      // updata data
      for (let j = 0; j < pageData.length; j++) {
        if (
          oldPageData[i]["model_no"] === pageData[j]["model_no"] &&
          oldPageData[i]["error_type"] === pageData[j]["error_type"]
        ) {
          if (
            oldPageData[i]["error_description"] !==
            pageData[j]["error_description"] ||
            oldPageData[i]["labor_value"] !== pageData[j]["labor_value"] ||
            oldPageData[i]["fault_description"] !==
            pageData[j]["fault_description"]
          ) {
            pageData[j].update_flg = "1";
            newList.push(pageData[j]);
          }
        }
      }
    }

    // insert data
    for (let k = 0; k < pageData.length; k++) {
      if (
        pageData[k]["error_type"] === "" &&
        pageData[k]["error_description"] !== "" &&
        pageData[k]["labor_value"] !== ""
      ) {
        pageData[k].model_no = model_no;
        pageData[k].update_flg = "0";
        newList.push(pageData[k]);
      }
    }

    // delete data
    for (let m = 0; m < oldPageData.length; m++) {
      const item = oldPageData[m];
      let DeleteFlg = false;
      for (let i = 0; i < pageData.length; i++) {
        const subItem = pageData[i];
        if (
          item.model_no === subItem.model_no &&
          item.error_type === subItem.error_type
        ) {
          DeleteFlg = true;
          break;
        }
      }
      if (!DeleteFlg) {
        oldPageData[m].update_flg = "2";
        newList.push(oldPageData[m]);
      }
    }
    return newList;
  };

  //
  const setClaimLaborErrorTypeValue = (
    type: any,
    value: any,
    row: any,
    index: any
  ) => {
    let newList: any = pageData.slice(0);
    let getNewErrorTypeField = JSON.parse(JSON.stringify(pageData[index]));
    getNewErrorTypeField = Object.assign({}, getNewErrorTypeField, {
      [type]: value,
    });
    newList[index] = getNewErrorTypeField;
    setPageData(newList);
  };

  const onBlurOfModelNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAlertMessageList([]);
    let _model_no: any = e.target.value;
    let noError = true;
    if (isEmptyCheck(_model_no)) {
      let message = com("messages.MS0001", {
        param1: t("model_no"),
      });
      changeData("model_no_error", message);
      noError = false;
    } else if (!checkMaxLength(_model_no, 15)) {
      let message = com("messages.MS0002", {
        param1: t("model_no"),
        param2: 15,
      });
      changeData("model_no_error", message);
      noError = false;
    } else if (!masterExitOfModelNo(_model_no)) {
      let message = com("messages.MS0004", {
        param1: t("model_no"),
      });
      changeData("model_no_error", message);
      noError = false;
    }
    if (noError) {
      changeData("model_no_error", "");
      getReadModelErrorTypeList(_model_no);
    }
    return noError;
  };

  const masterExitOfModelNo = (value: string) => {
    let isExisted = false;
    let items = commonInfo.itemList;

    if (value && value !== "") {
      if (items.length > 0) {
        for (let index in items) {
          if (
            items[index].model_no.toString().toUpperCase() ===
            value.toString().toUpperCase()
          ) {
            isExisted = true;
            let modelNoByDB = items[index].model_no.toString();
            changeData("model_no", modelNoByDB);
            break;
          }
        }
      } else {
        // TODO call api to check whether existed
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  const setErrorValue = (property: any, value: any) => {
    const errorList = Object.assign({}, errorValueList, { [property]: value });
    setErrorValueList(errorList);
  };

  const changeDataListen = (name: string, value: any, index: number) => {
    const errorValue = errorValueList.laborMaster;
    //Error Description
    if (name === "laborMaster_error_description") {
      if (value === "" || value === undefined) {
        errorValue[index].laborMaster_error_description =
          com("messages.MS0001", {
            param1: t("error_description"),
          });
        refeshSubmitButton(pageData);
      } else {
        errorValue[index].laborMaster_error_description = "";
        refeshSubmitButton(pageData);
      }
    }
    //Labor Value
    if (name === "laborMaster_labor_value") {
      if (value === "" || value === undefined) {
        errorValue[index].laborMaster_labor_value =
          com("messages.MS0001", {
            param1: t("labor_value"),
          });
        refeshSubmitButton(pageData);
      } else {
        errorValue[index].laborMaster_labor_value = "";
        refeshSubmitButton(pageData);
      }
    }
    setErrorValue("laborMaster", errorValue);
  };

  const isSelected = (name: any) => selectedArray.indexOf(name) > -1;

  const getOptionLabel = (option: any) => option.model_no || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.divStyle}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <th className={classes.thStyle}>
                {t("model_no")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <Autocomplete
                  disabled={!registerFlg}
                  className={classes.autoComplete}
                  id="model_no"
                  freeSolo
                  disableClearable
                  options={commonInfo.itemList}
                  getOptionLabel={getOptionLabel}
                  filterOptions={filterOptions}
                  inputValue={model_no}
                  value={model_no}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "200px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) =>
                    option.model_no.toString().toUpperCase() ===
                    model_no.toString().toUpperCase()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      error={
                        editData.model_no_error !== "" &&
                        editData.model_no_error !== undefined
                      }
                      helperText={editData.model_no_error}
                      onBlur={onBlurOfModelNo}
                      className={classes.textFieldWidth}
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {
                      changeData("model_no", value.model_no);
                      changeData("model_no_error", "");
                    }
                  }}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      changeData("model_no", value);
                      changeData("model_no_error", "");
                    }
                  }}
                />
              </td>
              <td className={classes.tdBtnStyle}>
                <Button
                  className={classes.buttonContainer02}
                  variant="contained"
                  color="secondary"
                  component="span"
                  onClick={deleteErrorTypeRow}
                  startIcon={<DeleteIcon />}
                >
                  {t("delete")}
                </Button>
              </td>
              <td className={classes.tdStyle2}>
                <Button
                  className={classes.buttonContainer02}
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={addErrorTypeRow}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t("add")}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.thead}>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={indeterminate}
                  checked={allChecked}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {headCells.map((headCell: any) => (
                <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((row: any, index: any) => {
              const isItemSelected = isSelected(index);
              const errorData = errorValueList.laborMaster;
              const errorDescriptionErrorFlg =
                errorData &&
                errorData[index] &&
                errorData[index].laborMaster_error_description &&
                errorData[index].laborMaster_error_description !== "";
              const laborValueErrorFlg =
                errorData &&
                errorData[index] &&
                errorData[index].laborMaster_labor_value &&
                errorData[index].laborMaster_labor_value !== "";
              return (
                <TableRow
                  className={classes.thead}
                  hover={true}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, row)}
                    />
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      value={row.error_description}
                      onChange={(e: any) => {
                        setClaimLaborErrorTypeValue(
                          "error_description",
                          e.target.value,
                          row,
                          index
                        );
                      }}
                      onBlur={(e) =>
                        changeDataListen(
                          "laborMaster_error_description",
                          e.target.value,
                          index
                        )
                      }
                      error={errorDescriptionErrorFlg}
                      helperText={
                        errorDescriptionErrorFlg
                          ? errorData[index].laborMaster_error_description
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      value={row.labor_value}
                      onChange={(e: any) => {
                        let value = e.target.value
                        if (value.charAt(0) === '0') {
                          return;
                        } else {
                          value = value.replace(/[^0-9]/g, "")
                            .substr(0, 8);
                        }
                        setClaimLaborErrorTypeValue(
                          "labor_value",
                          value,
                          row,
                          index
                        );
                      }}
                      onBlur={(e) =>
                        changeDataListen(
                          "laborMaster_labor_value",
                          e.target.value,
                          index
                        )
                      }
                      error={laborValueErrorFlg}
                      helperText={
                        laborValueErrorFlg
                          ? errorData[index].laborMaster_labor_value
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      className={classes.faultCheckBox}
                      checked={
                        row.fault_description !== undefined
                          ? row.fault_description
                          : false
                      }
                      onChange={(e: any) => {
                        setClaimLaborErrorTypeValue(
                          "fault_description",
                          e.target.checked,
                          row,
                          index
                        );
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonWidth2}
          variant="contained"
          onClick={handleBack}
        >
          {t("back")}
        </Button>
        <Button
          className={classes.buttonWidth2}
          disabled={disabledFlg}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          {t("submit")}
        </Button>
      </div>
    </form>
  );
};
