import React, { FC } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { getLocale } from "../../i18n";
import moment from "moment";
import "moment/min/locales";
// components
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// props
type Props = {
  value: any;
  onChange: (date: any) => void;
  className?: any;
  invalidDateMessage?: any;
  maxDateMessage?: any;
  minDateMessage?: any;
  error?: any;
  helperText?: any;
  maxDate?: any;
  minDate?: any;
  onError?: (error: any, value: any) => void;
  onBlur?: (e: any) => void;
  onAccept?: (date: any) => void;
  onOpen?: (value?: any) => void;
  onClose?: (value?: any) => void;
  disabled?: boolean;
};

export const DatePicker: FC<Props> = ({
  value,
  onChange,
  className,
  invalidDateMessage,
  maxDateMessage = "",
  minDateMessage = "",
  error,
  helperText,
  maxDate = new Date("9999-12-31"),
  minDate = new Date("1000-01-01"),
  onError,
  onBlur,
  onAccept,
  onOpen,
  onClose,
  disabled = false,
}) => {
  const { com } = useLocale({ defaultPath: "common.constants" });
  let localInvalidDateMessage = com("messages.MS0041");
  if (invalidDateMessage) {
    localInvalidDateMessage = invalidDateMessage;
  }

  let localMaxDateMessage = "";
  if (maxDateMessage !== "") {
    localMaxDateMessage = maxDateMessage;
  }

  let localMinDateMessage = "";
  if (minDateMessage !== "") {
    localMinDateMessage = minDateMessage;
  }

  // set locale
  const localeStr = getLocale();
  const localeArray = [
    localeStr.toLowerCase(),
    ...localeStr.split("-"),
  ] as string[];
  moment.locale(localeArray);

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <KeyboardDatePicker
          format={moment.localeData().longDateFormat("L")}
          autoOk
          variant="inline"
          margin="normal"
          value={value === undefined ? null : value}
          onChange={(date: any) => {
            // alert(moment(date).format("L"));
            onChange(date);
          }}
          error={error}
          helperText={helperText}
          onError={(error: any, value: any) => {
            if (onError) {
              onError(error, value);
            }
          }}
          onAccept={(date: any) => {
            if (onAccept) {
              onAccept(date);
            }
          }}
          onBlur={(e: any) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          className={className}
          invalidDateMessage={localInvalidDateMessage}
          maxDateMessage={localMaxDateMessage}
          minDateMessage={localMinDateMessage}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          onOpen={() => {
            if (onOpen) {
              onOpen();
            }
          }}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
