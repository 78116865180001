import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import { DraftsSearchControlButton } from "./Drafts.SearchControl.Button";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",
      marginBottom: "10px",

      "& dl": {
        paddingLeft: "10px",
        marginBottom: "0px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    promotionType: {
      width: "180px",
    },
    promotionName: {
      width: "180px",
    },
    dateArea: {
      display: "flex",
      flexWrap: "wrap",
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePickerArea: {
      width: "160px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePicker: {
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    searchButtonArea: {
      padding: "32px 0 0 10px",
      "& .MuiButton-root": {
        padding: "6px 12px",
      },
    },
    button: {
      marginTop: "6px",
      marginRight: "10px",
      height: "30px",
      "& .MuiButton-label": {
        paddingTop: "2px",
      },
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
  })
);

export const DraftsSearchControl = (props: any) => {
  const {
    numSelected,
    searchValueList,
    changeCondition,
    setSearchValue,
    handleClearEvent,
    searchButtondisabled,
    errorValueList,
    setErrorValue,
    searchHandle,
    deletePromotionAlert,
    userPermisssionInfo
  } = props

  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.drafts" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const onBlurOfCreatedDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setErrorValue("created_date_error", "");
  };

  const checkCreatedDate = () => {
    setErrorValue("created_date_error", "");
  };

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.row}>
          <dl className={classes.datePickerArea}>
            <dt>{t("created_date")}</dt>
            <dd className={classes.datePickerContainer}>
              <DatePicker
                value={searchValueList.created_date}
                onChange={(date: any) =>
                  setSearchValue("created_date", date)
                }
                onAccept={checkCreatedDate}
                className={classes.datePicker}
                error={errorValueList.created_date_error !== ""}
                helperText={errorValueList.created_date_error}
                onBlur={(e: any) => onBlurOfCreatedDate(e)}
                onError={(error: any, value: any) => {
                  if (
                    error !== errorValueList.created_date_error &&
                    error !== ""
                  ) {
                    setErrorValue("created_date_error", error);
                  }
                }}
              />
            </dd>
          </dl>
          <dl>
            <dt>{t("promotion_type")}</dt>
            <dd className={classes.promotionType}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="promotion_type"
                  value={searchValueList.promotion_type}
                  onChange={(e: any) =>
                    setSearchValue("promotion_type", e.target.value)
                  }
                >
                  <MenuItem key={"0"} value={"0"}>
                    <ListItemText primary={t("all")} />
                  </MenuItem>
                  {Array.isArray(com("promotion_type")) ? (
                    com("promotion_type").map((option: any) => (
                      <MenuItem key={option.code} value={option.code}>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
          <dl>
            <dt>{t("promotion_name")}</dt>
            <dd className={classes.promotionName}  data-testid="promo_name" >
              <TextField
                value={searchValueList.promo_name}
                variant="outlined"
                onChange={(e) => {
                  const value = e.target.value.substr(0, 50)
                  setSearchValue("promo_name", value)
                }}
              />
            </dd>
          </dl>
          <div className={classes.searchButtonArea}>
            {userPermisssionInfo.csd_drafts_search_authFlg && (
              <Button
                data-testid="btn_Search"
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                disabled={searchButtondisabled}
                onClick={() => {
                  searchHandle(false)
                }}
              >
                {t("btn_Search")}
              </Button>
            )}
            {userPermisssionInfo.csd_drafts_clear_authFlg && (
              <Button
                className={classes.button}
                variant="contained"
                disabled={changeCondition}
                onClick={handleClearEvent}
                startIcon={<RefreshIcon />}
              >
                {t("btn_Clear")}
              </Button>
            )}
          </div>
        </div>
      </form>
      <DraftsSearchControlButton
        numSelected={numSelected}
        deletePromotionAlert={deletePromotionAlert}
      />
    </>
  );
};
