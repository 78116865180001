import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "model", label: "model" },
  { id: "total_repair", label: "total_repair" },
  { id: "waranty_repair", label: "waranty_repair" },
  { id: "charge_repair", label: "charge_repair" },
  { id: "inspection_for_quote", label: "inspection_for_quote" },
  { id: "percent", label: "percent" },
  { id: "avr_repair_time_format", label: "avr_repair_time" },
  { id: "top_used_parts", label: "top_used_parts" },
  { id: "description", label: "description" },
];

export const ModelTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.report" });

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.theadCell}
            key={headCell.id}
            sortDirection={orderBy.indexOf(headCell.id) > -1 ? order : false}
          >
            <TableSortLabel
              active={orderBy.indexOf(headCell.id) > -1}
              direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy.indexOf(headCell.id) > -1 ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
