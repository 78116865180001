import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  discardChageDisabled,
} from "../methods/OrderDetail.Methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "20px 40px 0 40px",
    },
    rootLeft: {
      display: "flex",
    },
    rootRight: {
      display: "flex",
    },
    item: {
      marginRight: "10px",
    },
  })
);

export const OrderDetailButton = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { tableData, orderHeader, customerPoNoAuthFlg } = props;
  const buttonFocus = () => {
    console.log("buttonFocus");
  };

  const buttonShowFlag = (tableData: any) => {
    return customerPoNoAuthFlg
      ? true
      : tableData.order_confirmation === 0;
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootLeft}>
        <div className={classes.item}>
          <Button
            variant="contained"
            onClick={props.returnHandle}
            data-testid="return_to_order_list"
          >
            {t("return_to_order_list")}
          </Button>
        </div>
      </div>
      <div className={classes.rootRight}>
        <div className={classes.item}>
          {buttonShowFlag(tableData) &&
            props.userPermisssionInfo.csd_order_detail_submit_authFlg && (
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleRefresh}
                startIcon={<RefreshIcon />}
              >
                {t("refresh")}
              </Button>
            )}
        </div>

        <div className={classes.item}>
          {buttonShowFlag(tableData) &&
            props.userPermisssionInfo.csd_order_detail_submit_authFlg && (
              <Button
                variant="contained"
                color="secondary"
                onClick={props.discardChangeHandle}
                disabled={discardChageDisabled(
                  props.diffFlg,
                  tableData,
                  orderHeader, customerPoNoAuthFlg
                )}
                startIcon={<DeleteIcon />}
              >
                {t("discard_changes")}
              </Button>
            )}
        </div>
        <div className={classes.item}>
          {buttonShowFlag(tableData) &&
            props.userPermisssionInfo.csd_order_detail_submit_authFlg && (
              <Button
                variant="contained"
                onClick={props.submitHandle}
                color="primary"
                id="btn_order_submit"
                tabIndex={0}
                onFocus={() => buttonFocus()}
              >
                {t("submit")}
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};
