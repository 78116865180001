import React from "react";
// hooks
import { useStyles } from "../hooks/Summary.Changes.styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "DateTime",
    label: "summary_changes_dateTime",
  },
  {
    id: "description",
    label: "summary_changes_description",
  },
  {
    id: "before",
    label: "summary_changes_before",
  },
  {
    id: "after",
    label: "summary_changes_after",
  },
];

export const SummaryChangesHistoryThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
