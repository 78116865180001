import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221)",
      },
    },
    theadCell: {
      background: "rgba(240, 242, 244)",
    },
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td:last-child": {
        borderRight: "1px solid rgba(198, 209, 221)",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    groupContainer: {
      maxHeight: 300,
    },
    tfoot: {
      "& td": {
        background: "rgba(240, 242, 244)",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        borderTop: "1px solid rgba(198, 209, 221)",
      },
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
    },
  })
);
