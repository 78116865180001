import React from "react";
// Methods
import { addBtnDisableFlg } from "../Receiving.Method";
// components
import { PhotoItem } from "./Receiving.Form.PhotoItem";
// UI
import { useStyles } from "../hooks/useStyles";
import Button from "@material-ui/core/Button";

export const Photo = (props: any) => {
  const classes = useStyles();
  return (
    <tr>
      <td className={classes.titleCell}>
        <p>{props.text}</p>
      </td>
      <td className={classes.postionCell}>
        <input
          accept="image/*"
          id="contained-button-file"
          type="file"
          className={classes.photoInput}
          disabled={addBtnDisableFlg(props)}
          onChange={(e) => props.fileUpload(e.target.files)}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled={addBtnDisableFlg(props)}
          >
            {props.btnAdd}
          </Button>
        </label>
        <ul className={classes.photoList}>
          <PhotoItem
            repair_media={props.repair_media}
            photoDelete={props.photoDelete}
            downloadPhoto={props.downloadPhoto}
          />
        </ul>
      </td>
    </tr>
  );
};
