import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetPrivateAppRoutePath, PathKey } from "../../../../pages/AppRoutes";
import api from "../../../api/Api";
import { useNotitificationContext } from "../../../hooks/NotificationContext";
import { useDrawer } from "../../../hooks/UseDrawer";
import { useLocale } from "../../../hooks/UseLocale";
import numberFormat from "../../numberFormat/NumberFormat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: "border-box",
      width: "100%",
      padding: theme.spacing(1),
    },
    content: {
      boxSizing: "border-box",
      width: "100%",
    },
    messageBody: {
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      whiteSpace: "break-spaces",
    },
  })
);

type Props = {
  checked?: boolean;
  job_no: number;
  updated_date: string;
  message: string;
  notification_id: number;
  config: any;
  data_type: any;
  message_type: any;
  onToggleNotificationDrawerEvent: () => void;
};

export const NavigationDrawerNotificationItem: FC<Props> = ({
  checked = false,
  job_no,
  updated_date,
  message,
  notification_id,
  config,
  data_type,
  message_type,
  onToggleNotificationDrawerEvent,
}) => {
  const { t } = useLocale({ defaultPath: "models.job" });
  const classes = useStyles();
  const history = useHistory();
  const [unread, setUnread] = useState(true);
  const {
    numberOfNotificationMessages,
    setNumberOfNotificationMessages,
  } = useNotitificationContext();

  const handleOnClick = (job_no: number) => {
    // Close the drawer
    onToggleNotificationDrawerEvent();
    // Update to checked
    setUnread(false);
    if (!checked) {
      updateRepairNotificationMessage();
    }
    // Jump to the job detail
    history.push(
      `${GetPrivateAppRoutePath(PathKey.JOBSDETAIL)}?jobno=${job_no}`
    );
  };

  const updateRepairNotificationMessage = () => {
    return api
      .updateRepairNotificationMessageAPI(notification_id, config)
      .then(() => {
        setNumberOfNotificationMessages(numberOfNotificationMessages - 1);
      });
  };

  const changeNotificationBackGround = () => {
    let color = "";
    if (data_type === 1) {
      if (message_type === 1) {
        color = "#E2EFDA";  //Green
      }else if (message_type === 2) {
        color = "#DEEBF7";  //blue
      }
    }
    else if (data_type === 2) {
      if (message_type === 1) {
        color = "#DEEBF7";  //blue
      }else if (message_type === 2) {
        color = "#FBE5D6"; //pink
      }
    }else if (data_type === 3 || data_type === 5) {
      color = "#E2EFDA";  //Green
    }
    return { background: color };
  };

  return (
    <div className={classes.root}>
      <Card className={classes.content}>
        <CardActionArea onClick={()=> handleOnClick(job_no)}>
          <CardContent style={changeNotificationBackGround()}>
            <Grid container>
              <Grid item xs={1} container>
                <Grid>
                  {!checked && unread && (
                    <Badge color="secondary" variant="dot" />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={11} container>
                <Grid container>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          {t("jobNo")} {job_no}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {numberFormat.time(Date.parse(updated_date))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm container>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.messageBody}
                  >
                    {message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};
