import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowHeader: {
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "rgba(240, 242, 244)",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
  hiddenSortIcon: boolean;
  checkFlg?: boolean;
}

const headCells: HeadCell[] = [
  { id: "promo_no", label: "table_no", hiddenSortIcon: false },
  { id: "promo_name", label: "prom_name", hiddenSortIcon: false },
  { id: "start_date", label: "table_start_date", hiddenSortIcon: false },
  { id: "end_date", label: "table_end_date", hiddenSortIcon: false },
  { id: "priority", label: "table_priority", hiddenSortIcon: false },
  { id: "promotion_status", label: "table_status", hiddenSortIcon: false },
  { id: "_cust_type", label: "table_cust_type", hiddenSortIcon: true },
  { id: "_customer_no", label: "table_cust_no", hiddenSortIcon: true },
  { id: "_customer_name", label: "table_cust_name", hiddenSortIcon: true },
  { id: "_customer_disc", label: "table_cust_disc", hiddenSortIcon: true },
  {
    id: "csac1",
    label: "table_cust_csac1",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac2",
    label: "table_cust_csac2",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac3",
    label: "table_cust_csac3",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac4",
    label: "table_cust_csac4",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac5",
    label: "table_cust_csac5",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac6",
    label: "table_cust_csac6",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac7",
    label: "table_cust_csac7",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac8",
    label: "table_cust_csac8",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac9",
    label: "table_cust_csac9",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "csac10",
    label: "table_cust_csac10",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  {
    id: "promotion_region",
    label: "table_cust_promotion_region",
    hiddenSortIcon: true,
    checkFlg: true,
  },
  { id: "_item_class", label: "table_item_class", hiddenSortIcon: true },
  { id: "_item_disc", label: "table_item_disc", hiddenSortIcon: true },
  { id: "_item_no", label: "table_item_no", hiddenSortIcon: true },
  { id: "isac1", label: "table_isac1", hiddenSortIcon: true, checkFlg: true },
  { id: "isac2", label: "table_isac2", hiddenSortIcon: true, checkFlg: true },
  { id: "isac3", label: "table_isac3", hiddenSortIcon: true, checkFlg: true },
  { id: "isac4", label: "table_isac4", hiddenSortIcon: true, checkFlg: true },
  { id: "isac5", label: "table_isac5", hiddenSortIcon: true, checkFlg: true },
  { id: "isac6", label: "table_isac6", hiddenSortIcon: true, checkFlg: true },
  { id: "isac7", label: "table_isac7", hiddenSortIcon: true, checkFlg: true },
  { id: "isac8", label: "table_isac8", hiddenSortIcon: true, checkFlg: true },
  {
    id: "_free_goods_item_no",
    label: "table_free_goods",
    hiddenSortIcon: true,
  },
  {
    id: "_free_goods_item_description",
    label: "table_free_goods_description",
    hiddenSortIcon: true,
  },
  { id: "allow_overlap", label: "table_overlap", hiddenSortIcon: false },
  { id: "_details", label: "table_details", hiddenSortIcon: true },
];

export const PromotionSearchResultFreeGoodsTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) =>
          (props.salesAnalysisList.customer_list &&
            props.salesAnalysisList.customer_list.length > 0 &&
            props.salesAnalysisList.customer_list.includes(headCell.id)) ||
          (props.salesAnalysisList.item_list &&
            props.salesAnalysisList.item_list.length > 0 &&
            props.salesAnalysisList.item_list.includes(headCell.id)) ||
          !headCell.checkFlg ? (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy.indexOf(headCell.id) > -1 ? order : false}
            >
              <TableSortLabel
                active={
                  !headCell.hiddenSortIcon && orderBy.indexOf(headCell.id) > -1
                }
                direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                hideSortIcon={headCell.hiddenSortIcon}
              >
                {t(headCell.label)}
                {orderBy.indexOf(headCell.id) > -1 ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};
