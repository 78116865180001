import React from "react";
// Methods
import {
  decreaseCount,
  increaseCount,
  printDisableFlg,
} from "../Receiving.Method";
// UI
import { useStyles } from "../hooks/useStyles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Print from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from "@material-ui/icons/Remove";

export const Labels = (props: any) => {
  const classes = useStyles();

  return (
    <tr>
      <td className={classes.titleCell}>
        <p>{props.text}</p>
      </td>
      <td className={classes.flexCell}>
        <Button
          className={classes.smallButton}
          variant="contained"
          color="primary"
          component="span"
          disabled={!props.labelsField}
          onClick={() => decreaseCount(props)}
        >
          <MinimizeIcon />
        </Button>
        <p className={classes.labelsText}>{props.labelsField}</p>
        <Button
          className={classes.smallButton}
          variant="contained"
          color="primary"
          component="span"
          disabled={props.labelsField === 10}
          onClick={() => increaseCount(props)}
        >
          <AddIcon />
        </Button>
        <div className={classes.printButton}>
          <IconButton
            onClick={props.handlePrintEvent}
            disabled={printDisableFlg(props)}
          >
            <Print />
          </IconButton>
        </div>
      </td>
    </tr>
  );
};
