import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
// input
import { PhoneNumberInput } from "../../../modules/components/phoneInput/PhoneNumberInput";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      "& .react-tel-input .form-control": {
        width: "100%",
      },
      "& th": {
        width: "100px",
      },
    },
  })
);

export const JobsDetailCustomerInfoRepairContactEndUser = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.customer_info",
  });
  const { getUserInfoRepair } = useCommonInfo();
  // init country
  let userInfoRepair = getUserInfoRepair() as any;
  const {
    formList,
    setFormListValue,
    formErrorList,
    onBlurOfEndUserName,
    onBlurOfEndUserEmailAddress,
    userPermisssionInfo,
  } = props;

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name_contact_endUser")}</h3>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t("contact_name")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.contactEndUser.name}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("contactEndUser", e.target.value, "name")
                }
                error={formErrorList.endUser_name_error !== ""}
                helperText={formErrorList.endUser_name_error}
                onBlur={onBlurOfEndUserName}
                inputProps={{ maxLength: 50 }}
              />
            </td>
          </tr>
          <tr>
            <th>{t("mail_address")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.contactEndUser.mail_address}
                type="email"
                variant="outlined"
                onChange={(e) =>
                  setFormListValue(
                    "contactEndUser",
                    e.target.value,
                    "mail_address"
                  )
                }
                onBlur={onBlurOfEndUserEmailAddress}
                error={formErrorList.endUser_mail_address_error !== ""}
                helperText={formErrorList.endUser_mail_address_error}
                inputProps={{ maxLength: 254 }}
              />
            </td>
          </tr>
          <tr>
            <th>{t("tel_no")}</th>
            <td>
              <PhoneNumberInput
                disabled={!userPermisssionInfo.customer_info_authFlg}
                country={
                  userInfoRepair.country && userInfoRepair.country.toLowerCase()
                }
                value={formList.contactEndUser.tel_no}
                onChange={(value) =>
                  setFormListValue("contactEndUser", "+" + value, "tel_no")
                }
              />
            </td>
          </tr>
          <tr>
            <th>{t("fax_no")}</th>
            <td>
              <PhoneNumberInput
                disabled={!userPermisssionInfo.customer_info_authFlg}
                country={
                  userInfoRepair.country && userInfoRepair.country.toLowerCase()
                }
                value={formList.contactEndUser.fax_no}
                onChange={(value) =>
                  setFormListValue("contactEndUser", "+" + value, "fax_no")
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t("sms_receive_notification")}</th>
            <td>
              <Checkbox
                disabled={!userPermisssionInfo.customer_info_authFlg}
                className={classes.checkboxLayout}
                color="primary"
                checked={formList.contactEndUser.sms_receive_notification}
                onChange={(e) =>
                  setFormListValue(
                    "contactEndUser",
                    e.target.checked,
                    "sms_receive_notification"
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <th>{t("mail_receive_notification")}</th>
            <td>
              <Checkbox
                disabled={!userPermisssionInfo.customer_info_authFlg}
                className={classes.checkboxLayout}
                color="primary"
                checked={formList.contactEndUser.mail_receive_notification}
                onChange={(e) =>
                  setFormListValue(
                    "contactEndUser",
                    e.target.checked,
                    "mail_receive_notification"
                  )
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
