import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { getCodeNameByNumber } from "../../../modules/common.methods";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      paddingBottom: "20px",
      marginBottom: "20px",
    },
    title: {
      fontSize: "16px",
    },
    text: {
      marginLeft: "20px",
    },
    promotionName: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      marginLeft: "15px",
    },
    textField: {
      width: "260px",
      "& input": {
        height: "20px",
        lineHeight: "20px",
        padding: "5px",
      },
    },
  })
);
export const PromotionName = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { searchValueList, setSearchValue } = props;
  const classes = useStyles();

  let promotionStatus = "";
  if (searchValueList.promotion_status === "100") {
    promotionStatus = getCodeNameByNumber("100", com("status_option"));
  } else if (searchValueList.promotion_status === "200") {
    promotionStatus = getCodeNameByNumber("200", com("status_option"));
  } else if (searchValueList.promotion_status === "600") {
    promotionStatus = getCodeNameByNumber("600", com("status_option"));
  }

  const promoNameErrorFlg = props.errorValueList.promo_name_error !== "";

  return (
    <dl className={classes.root}>
      <dt className={classes.title}>{t("promo_name")}</dt>
      <dd className={classes.promotionName}  data-testid="promo_name">
        <TextField
          variant="outlined"
          className={classes.textField}
          value={searchValueList.promo_name}
          onChange={(e) => {
            const value = e.target.value.substr(0, 50);
            setSearchValue("promo_name", value);
          }}
          onBlur={(e) =>
            props.changeDataListen(
              "promo_name",
              e.target.value,
              0
            )
          }
          error={promoNameErrorFlg}
          helperText={
            promoNameErrorFlg ? props.errorValueList.promo_name_error : ""
          }

          disabled={props.reviewFlg}
        />
        {props.createPromotionFlag && !props.fromDraftsFlag && (
          <>
            <div className={classes.text} data-testid="promo_no">
              {t("No")} {/\d+$/.exec(props.createPromotionFlag)}
            </div>
            <div className={classes.text}>
              {t("status")}: {promotionStatus}
            </div>
          </>
        )}
      </dd>
    </dl>
  );
};
