import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailCategoryConsideredTableThead } from "./PromotionDetail.Category.Considered.Table.Thead";
import { PromotionDetailCategoryConsideredTableTbody } from "./PromotionDetail.Category.Considered.Table.Tbody";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      boxShadow: "none"
    },
    promotionContainer: {
      maxHeight: "560px",
      marginBottom: "20px",
      border: "1px solid rgba(0, 0, 0, 20%)",
      borderRadius: "5px"
    },
    buttonArea: {
      "& > button": {
        marginRight: "10px",
      },
    },
    errorText: {
      color: "#f44336",
      fontSize: "12px",
      marginBottom: "20px"
    }
  })
);

export const PromotionDetailCategoryConsideredTable = (props: any) => {
  const classes = useStyles();

  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = props.selectedArray.indexOf(row.index);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray, row.index);
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(1);
    } else if (selectedIndex === props.selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(props.selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        props.selectedArray.slice(0, selectedIndex),
        props.selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = props.selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(props.selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = props.data
        .slice(0)
      const newSelecteds = data.map((item: any) => item.index);
      setSelectedInformation(newSelecteds, data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    props.setSelectedArray(list);
    props.setSelectedRowArray(rowList);
    props.changeNumSelected(list.length);
    const flg = list.length > 0 && list.length < props.data.length;
    props.setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === props.data.length;
    props.setAllChecked(allCheckedFlg);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer className={classes.promotionContainer}>
          <Table stickyHeader>
            <PromotionDetailCategoryConsideredTableThead
              indeterminate={props.indeterminate}
              allChecked={props.allChecked}
              onSelectAllClick={handleSelectAllClick}
              reviewFlg={props.reviewFlg}
              searchValueList={props.searchValueList}
              salesAnalysisList={props.salesAnalysisList}
            />
            <PromotionDetailCategoryConsideredTableTbody
              data={props.data}
              changeDataListen={props.changeDataListen}
              selected={props.selectedArray}
              handleClick={(event: any, row: any) => handleClick(event, row)}
              handleCSearch={props.handleCSearch}
              setValue={props.setValue}
              getOptionLabel2={props.getOptionLabel2}
              filterOptions2={props.filterOptions2}
              categoryConsidered={props.categoryConsidered}
              onBlurOfItemNo={props.onBlurOfItemNo}
              onFocusOfItemNo={props.onFocusOfItemNo}
              errorValueList={props.errorValueList}
              consideredEditList={props.consideredEditList}
              reviewFlg={props.reviewFlg}
              salesAnalysisList={props.salesAnalysisList}
            />
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.errorText}>{props.errorValueList.considered_item_error}</div>
      <div className={classes.buttonArea}>
        <Button
          data-testid="btn_catergory_considered_Add"
          variant="contained"
          color="primary"
          startIcon={<AddBoxIcon />}
          onClick={() => {
            props.addOneRow("category_considered")
          }}
          disabled={props.reviewFlg || props.onConsideredItemNoFlag}
        >
          {t("btn_Add")}
        </Button>
        <Button
          data-testid="btn_catergory_considered_Delete"
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={(props.selectedRowArray.length > 0 ? false : true) || props.onConsideredItemNoFlag}
          onClick={() => {
            props.deleteRow("category_considered")
          }}
        >
          {t("btn_Delete")}
        </Button>
      </div>
    </>
  );
};
