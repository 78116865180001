import React, { useState, useEffect } from "react";
import clsx from "clsx";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import { PromotionSearchControlButton } from "./Promotion.SearchControl.Button";
import { PromotionSearchControlCSearch } from "./Promotion.SearchControl.CSearch";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from "@material-ui/icons/Remove";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// api
import api from "../../../modules/api/Api";
// check
import { isEmptyStringOrNumber } from "../../../modules/hooks/UseValication";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";
import {
  priceInputBySign,
  decimalPointIsLenth,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",
      marginBottom: "10px",
      "& .MuiFormHelperText-root": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& dl": {
        paddingLeft: "10px",
        marginBottom: "0px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        minHeight: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 20px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    rowLeft: {
      width: "195px",
      display: "flex",
    },
    rowRight: {
      width: "calc(100% - 195px)",
    },
    rowRightTop: {
      display: "flex",
    },
    promotion_type: {
      width: "185px",
    },
    dateArea: {
      display: "flex",
      flexWrap: "wrap",
    },
    no: {
      width: "120px",
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePickerArea: {
      width: "160px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePicker: {
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    customerArea: {
      flexWrap: "wrap",
      display: "flex",
      justifyContent: "flex-end",
    },
    customerType: {
      width: "130px",
    },
    promotion_region: {
      width: "130px",
    },
    promoName: {
      width: "130px",
    },
    customerDiscountCode: {
      // width: "190px",
    },
    csac: {
      width: "60px",
    },
    customerNo: {
      width: "120px",
      position: "relative",
      "& .MuiOutlinedInput-input": {
        paddingRight: "30px",
      },
    },
    iconButton: {
      position: "absolute",
      right: "3px",
      top: "7px",
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
    },
    searchButtonArea: {
      display: "flex",
      padding: "32px 0 0 10px",
      "& .MuiButton-root": {
        padding: "6px 12px",
      },
    },
    searchButtonCondition: {
      marginTop: "6px",
      height: "30px",
      "& .MuiButton-label": {
        paddingTop: "2px",
      },
      "&:not(:last-child)": {
        marginRight: "10px",
      },
    },
    itemDiscountCode: {
      width: "120px",
    },
    itemClass: {
      width: "80px",
    },
    isac: {
      width: "60px",
    },
    itemNo: {
      width: "110px",
    },
    advancedButton: {
      "& button": {
        width: "100%",
        padding: "5px 0",
      },
    },
    expandButton: {
      height: "30px",
    },
    rowRightBottom: {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: "10px",
      borderTop: "1px solid #CFCFCF",
      paddingBottom: "10px",
    },
    priority: {
      width: "120px",
    },
    status: {
      width: "150px",
    },
    freeGoods: {
      width: "110px",
    },
    promotionCode: {
      width: "120px",
    },
    allowOverlap: {
      margin: "32px 0 0 15px",
    },
    discountRate: {
      width: "190px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "& span": {
        marginTop: "5px",
      },
      "& .MuiFormControl-root": {
        width: "65px",
      },
    },
    discountRateCell: {
      width: "80px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "& .MuiInputBase-input": {
        width: "60px",
      },
      "& .MuiFormHelperText-contained": {
        margin: 0,
      },
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
    advancedHideFlg: {
      display: "none",
    },
    priorityType: {
      "& .MuiOutlinedInput-input": {
        padding: "0px",
        height: "30px",
        lineHeight: "30px",
        paddingLeft: "5px",
      },
      "& .MuiAutocomplete-root": {
        padding: "0px",
        height: "30px",
        lineHeight: "30px",
        paddingLeft: "5px",
      },
    },
  })
);

export const PromotionSearchControl = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    searchValueList,
    setSearchValueList,
    defaultValueList,
    changeCondition,
    setChangeCondition,
    customerNoDisabled,
    setCustomerNoDisabled,
    itemNoDisabled,
    setItemNoDisabled,
    errorValueList,
    setErrorValueList,
    errorDefaultList,
    searchButtondisabled,
    setSearchButtondisabled,
    setResultData,
    handleClear,
    selectedPromotions,
    numSelected,
    setErrMessageList,
    setErrorMessageOpen,
    userPermisssionInfo,
    deletePromotionAlert,
    setAlertShow,
    setAlertMessage,
    setAlertType,
    salesAnalysisDefinition,
  } = props;

  const { getCommonConfig, getCommonInfo, getHotKey, setHotKey } =
    useCommonInfo();
  let config = getCommonConfig() as any;
  let commonInfo = getCommonInfo() as any;
  let hotKeyInfo = getHotKey() as any;

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  // advanced button click event
  const [advancedShowFlg, setAdvancedShowFlg] = useState(false);
  // const [oldCustomerNo, setOldCustomerNo] = useState("");
  // const [oldItemNo, setOldItemNo] = useState("");

  const handleAdvancedEvent = () => {
    setAdvancedShowFlg(!advancedShowFlg);
  };

  const changeAdvanced = () => {
    return advancedShowFlg ? <MinimizeIcon /> : <AddIcon />;
  };

  const buttonFocus = () => {
    console.log("buttonFocus");
  };

  useEffect(() => {
    if (JSON.stringify(searchValueList) === JSON.stringify(defaultValueList)) {
      setChangeCondition(true);
    } else {
      setChangeCondition(false);
    }
  }, [searchValueList, defaultValueList]);

  useEffect(() => {
    if (hotKeyInfo.handleF2PromotionCustomerSearch) {
      handleCSearch();
    }
    setHotKey("handleF2PromotionCustomerSearch", false);
  }, [hotKeyInfo.handleF2PromotionCustomerSearch]);

  const setSearchValue = (property: any, value: any) => {
    let valueList = Object.assign({}, searchValueList, { [property]: value });
    let errorList = null;
    if (property === "promo_type") {
      handleClear();

      if (value !== "50") {
        valueList = Object.assign({}, valueList, { allow_overlap: false });
      }

      if (value !== "40" && value !== "0") {
        valueList = Object.assign({}, valueList, {
          special_price_from_format: "",
          special_price_from: "",
          special_price_to_format: "",
          special_price_to: "",
        });

        errorList = Object.assign({}, errorValueList, {
          special_price_from_error: "",
          special_price_to_error: "",
        });
      }

      if (
        value === "0" ||
        value === "10" ||
        value === "30" ||
        value === "40" ||
        value === "50"
      ) {
        if (value === "10" || value === "40" || value === "50") {
          valueList = Object.assign({}, valueList, { free_goods_items: "" });
        }

        if (value === "10" || value === "30" || value === "40") {
          valueList = Object.assign({}, valueList, { promotion_code: "" });
        }

        if (value === "30" || value === "40") {
          const conditionValue = {
            discount_rate_from: "",
            discount_rate_end: "",
          };
          valueList = Object.assign({}, valueList, conditionValue);

          const errorValue = {
            discount_rate_from_error: "",
            discount_rate_end_error: "",
          };
          errorList = Object.assign({}, errorValueList, errorValue);
        }
      } else if (value === "60" || value === "20") {
        const conditionValue = {
          priority: "",
          allow_overlap: false,
          item_discount_code: "",
          item_class: "",
          isac1: "",
          isac2: "",
          isac3: "",
          isac4: "",
          isac5: "",
          isac6: "",
          isac7: "",
          isac8: "",
          item_no: "",
          free_goods_items: "",
          promotion_code: "",
        };
        valueList = Object.assign({}, valueList, conditionValue);

        if (value === "60") {
          const conditionValue = {
            discount_rate_from: "",
            discount_rate_end: "",
          };
          valueList = Object.assign({}, valueList, conditionValue);

          const errorValue = {
            discount_rate_from_error: "",
            discount_rate_end_error: "",
          };
          errorList = Object.assign({}, errorValueList, errorValue);
        }

        setItemNoDisabled(false);
      }
    }

    setSearchValueList(valueList);
    if (errorList !== null) {
      setErrorValueList(errorList);
    }
  };

  const setErrorValue = (property: any, value: any) => {
    const errorList = Object.assign({}, errorValueList, { [property]: value });
    setErrorValueList(errorList);
  };

  const onBlurOfStartDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.end_date > 0 &&
      searchValueList.start_date > 0 &&
      searchValueList.start_date > searchValueList.end_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      setErrorValue("start_date_error", message);
    } else {
      setErrorValue("start_date_error", "");
    }
  };

  const onBlurOfEndDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.end_date > 0 &&
      searchValueList.start_date > 0 &&
      searchValueList.start_date > searchValueList.end_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
        end_date_error: "",
      });
      setErrorValueList(errorList);
    } else {
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
        end_date_error: "",
      });
      setErrorValueList(errorList);
    }
  };

  const checkStartDate = (date: any) => {
    if (searchValueList.end_date > 0 && date > searchValueList.end_date) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        start_date: date,
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        start_date: date,
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    }
  };

  const checkEndDate = (date: any) => {
    if (searchValueList.start_date > 0 && searchValueList.start_date > date) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        end_date: date,
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
        end_date_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        end_date: date,
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
        end_date_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    }
  };

  const checkDiscountRateFrom = (rate: any) => {
    if (rate !== "" && (Number(rate) > 99 || Number(rate) < 1)) {
      let message = com("messages.MS0045");
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_from: rate,
      });
      let errorList = Object.assign({}, errorValueList, {
        discount_rate_from_error: message,
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else if (
      rate !== "" &&
      searchValueList.discount_rate_end !== "" &&
      searchValueList.discount_rate_end !== "0"
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_from_discount_rate"),
        endDate: t("txt_end_discount_rate"),
      });
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_from: rate,
      });
      let errorList = "";
      if (Number(rate) > Number(searchValueList.discount_rate_end)) {
        errorList = Object.assign({}, errorValueList, {
          discount_rate_from_error: message,
          discount_rate_end_error: "",
        });
      } else if (Number(rate) <= Number(searchValueList.discount_rate_end)) {
        errorList = Object.assign({}, errorValueList, {
          discount_rate_from_error: "",
          discount_rate_end_error: "",
        });
      }
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else if (
      rate == "" &&
      Number(searchValueList.discount_rate_to) >= 1 &&
      Number(searchValueList.discount_rate_to) <= 99
    ) {
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_from: rate,
      });
      let errorList = Object.assign({}, errorValueList, {
        discount_rate_from_error: "",
        discount_rate_end_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_from: rate,
      });
      let errorList = Object.assign({}, errorValueList, {
        discount_rate_from_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    }
  };

  const checkDiscountRateEnd = (rate: any) => {
    if (rate !== "" && (Number(rate) > 99 || Number(rate) < 1)) {
      let message = com("messages.MS0045");
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_end: rate,
      });
      let errorList = Object.assign({}, errorValueList, {
        discount_rate_end_error: message,
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else if (
      searchValueList.discount_rate_from !== "" &&
      searchValueList.discount_rate_from !== "0" &&
      rate !== ""
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_from_discount_rate"),
        endDate: t("txt_end_discount_rate"),
      });
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_end: rate,
      });
      let errorList = "";
      if (Number(searchValueList.discount_rate_from) > Number(rate)) {
        errorList = Object.assign({}, errorValueList, {
          discount_rate_from_error: message,
          discount_rate_end_error: "",
        });
      } else if (Number(searchValueList.discount_rate_from) <= Number(rate)) {
        errorList = Object.assign({}, errorValueList, {
          discount_rate_from_error: "",
          discount_rate_end_error: "",
        });
      }
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else if (
      rate == "" &&
      Number(searchValueList.discount_rate_from) >= 1 &&
      Number(searchValueList.discount_rate_from) <= 99
    ) {
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_end: rate,
      });
      let errorList = Object.assign({}, errorValueList, {
        discount_rate_from_error: "",
        discount_rate_end_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        discount_rate_end: rate,
      });
      let errorList = Object.assign({}, errorValueList, {
        discount_rate_end_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    }
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      let message = com("messages.MS0085", {
        param1: row.customer_no,
      });
      setAlertShow(true);
      setAlertMessage(message);
      setAlertType("warning");
    } else {
      setAlertShow(false);
      setAlertMessage("");
      setAlertType("");
    }
    console.log("row csac", row);
    const customerValue = {
      customer_type: row.customer_type,
      customer_no: row.customer_no,
      customer_discount_code: row.customer_discount_cd,
      csac1: row.csac1,
      csac2: row.csac2,
      csac3: row.csac3,
      csac4: row.csac4,
      csac5: row.csac5,
      csac6: row.csac6,
      csac7: row.csac7,
      csac8: row.csac8,
      csac9: row.csac9,
      csac10: row.csac10,
      promotion_region: row.promotion_region,
    };
    const valueList = Object.assign({}, searchValueList, customerValue);
    setSearchValueList(valueList);

    const errorList = Object.assign({}, errorValueList, {
      customer_no_error: "",
    });
    setErrorValueList(errorList);
    setCustomerNoDisabled(true);
  };

  const onBlurOfCustomerNo = () => {
    if (props.oldCustomerNo) {
      if (!searchValueList.customer_no) {
        const customerValue = {
          customer_type: "",
          customer_no: "",
          customer_discount_code: "",
          csac1: "",
          csac2: "",
          csac3: "",
          csac4: "",
          csac5: "",
          csac6: "",
          csac7: "",
          csac8: "",
          csac9: "",
          csac10: "",
          promotion_region: "",
        };
        const valueList = Object.assign({}, searchValueList, customerValue);
        setSearchValueList(valueList);
      }
    }
    props.setOldCustomerNo(searchValueList.customer_no);

    setErrorValue("customer_no_error", "");
    if (isEmptyStringOrNumber(searchValueList.customer_no)) {
      setCustomerNoDisabled(false);
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(searchValueList.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_no"),
            });
            setErrorValue("customer_no_error", message);
            const customerValue = {
              customer_type: "",
              customer_discount_code: "",
              csac1: "",
              csac2: "",
              csac3: "",
              csac4: "",
              csac5: "",
              csac6: "",
              csac7: "",
              csac8: "",
              csac9: "",
              csac10: "",
              promotion_region: "",
            };
            const valueList = Object.assign({}, searchValueList, customerValue);
            setSearchValueList(valueList);
            setCustomerNoDisabled(false);
          } else {
            if (response.customer_closed === true) {
              let message = com("messages.MS0085", {
                param1: response.customer_no,
              });
              setAlertShow(true);
              setAlertMessage(message);
              setAlertType("warning");
            } else {
              setAlertShow(false);
              setAlertMessage("");
              setAlertType("");
            }
            console.log("response csac", response);
            const customerValue = {
              customer_type: response.customer_type,
              // customer_no: response.customer_no,
              customer_discount_code: response.customer_discount_cd,
              csac1: response.csac1,
              csac2: response.csac2,
              csac3: response.csac3,
              csac4: response.csac4,
              csac5: response.csac5,
              csac6: response.csac6,
              csac7: response.csac7,
              csac8: response.csac8,
              csac9: response.csac9,
              csac10: response.csac10,
              promotion_region: response.promotion_region,
            };
            const valueList = Object.assign({}, searchValueList, customerValue);
            setSearchValueList(valueList);
            setCustomerNoDisabled(true);
          }
        });
    }
  };

  async function onBlurOfItemNo() {
    if (props.oldItemNo) {
      if (!searchValueList.item_no) {
        const itemValue = {
          item_discount_code: "",
          item_no: "",
          item_class: "",
          isac1: "",
          isac2: "",
          isac3: "",
          isac4: "",
          isac5: "",
          isac6: "",
          isac7: "",
          isac8: "",
        };
        const valueList = Object.assign({}, searchValueList, itemValue);
        setSearchValueList(valueList);
      }
    }
    props.setOldItemNo(searchValueList.item_no);

    if (searchValueList.item_no) {
      let _flg = false;
      let _item: any;

      // get item number
      // AM0302
      console.log("apiLoading true8");
      // props.apiLoading(true);
      await api
        .getReadItemMasterAPI(
          encodeURIComponent(searchValueList.item_no),
          config
        )
        .then(({ code, response }: { code: any; response: any }) => {
          // props.apiLoading(false);
          if (!getApiResultStatus(code)) {
            if (code === 404) {
              //  _flg = false;
            }
          } else {
            _flg = true;
            _item = response;
          }
        });

      if (_flg) {
        const itemValue = {
          item_discount_code: _item.item_discount_cd,
          item_class: _item.item_class,
          item_no: _item.item_no,
          isac1: _item.isac1,
          isac2: _item.isac2,
          isac3: _item.isac3,
          isac4: _item.isac4,
          isac5: _item.isac5,
          isac6: _item.isac6,
          isac7: _item.isac7,
          isac8: _item.isac8,
        };
        const valueList = Object.assign({}, searchValueList, itemValue);
        setSearchValueList(valueList);

        const errorList = Object.assign({}, errorValueList, {
          item_no_error: "",
        });
        setErrorValueList(errorList);
        setItemNoDisabled(true);
      } else {
        const itemValue = {
          item_discount_code: "",
          item_class: "",
          isac1: "",
          isac2: "",
          isac3: "",
          isac4: "",
          isac5: "",
          isac6: "",
          isac7: "",
          isac8: "",
        };
        const valueList = Object.assign({}, searchValueList, itemValue);
        setSearchValueList(valueList);
        let message = com("messages.MS0004", {
          param1: t("item_no"),
        });
        setErrorValue("item_no_error", message);
        setItemNoDisabled(false);
      }
    } else {
      const errorList = Object.assign({}, errorValueList, {
        item_no_error: "",
      });
      setErrorValueList(errorList);
      setItemNoDisabled(false);
    }
  }

  const onBlurOfFreeGoods = () => {
    if (searchValueList.free_goods_items.length > 0) {
      if (commonInfo.itemBaseList.length > 0) {
        const itemNoArray = commonInfo.itemBaseList.filter((item: any) => {
          return (
            item.item_no.toUpperCase() ===
            searchValueList.free_goods_items.toUpperCase()
          );
        });

        if (itemNoArray.length > 0) {
          setSearchValue("free_goods_items", itemNoArray[0].item_no);
          setErrorValue("free_goods_error", "");
        } else {
          const message = com("messages.MS0004", {
            rowNum: t("item"),
            param1: searchValueList.free_goods_items,
          });
          setErrorValue("free_goods_error", message);
        }
      }
    } else {
      setErrorValue("free_goods_error", "");
    }
  };

  useEffect(() => {
    const flag = Object.values(errorValueList).some((item: any) => {
      return item !== "";
    });
    setSearchButtondisabled(flag);
  }, [errorValueList]);

  // clear button click event
  const handleClearEvent = () => {
    setAlertShow(false);
    handleClear();
    setCustomerNoDisabled(false);
    setItemNoDisabled(false);
    const valueList = Object.assign({}, defaultValueList);
    setSearchValueList(valueList);
    const errorList = Object.assign({}, errorDefaultList);
    setErrorValueList(errorList);
    setResultData([]);
  };

  const getOptionLabel2 = (option: any) => option.item_no || "";
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  const checkSpecialPrice = (type: string) => {
    let errorList: any = null;
    let form_message = "";
    let to_messsage = "";
    if (
      searchValueList.special_price_from &&
      searchValueList.special_price_to
    ) {
      if (
        Number(searchValueList.special_price_from) >
        Number(searchValueList.special_price_to)
      ) {
        form_message = com("messages.MS0005", {
          startDate: t("txt_from_special_price"),
          endDate: t("txt_end_special_price"),
        });
      }
    } else {
      if (searchValueList.special_price_to === "0") {
        to_messsage = com("messages.MS0045");
      }
    }

    errorList = {
      special_price_from_error: form_message,
      special_price_end_error: to_messsage,
    };

    setErrorValueList((prevState: any) => ({
      ...prevState,
      ...errorList,
    }));
  };

  const setSpecialPriceValue = (type: string, value: any, price: any) => {
    price = value === "" ? "" : price;
    let valueList: any = null;
    if (type === "from") {
      valueList = {
        special_price_from_format: value,
        special_price_from: price,
      };
    } else {
      valueList = { special_price_to_format: value, special_price_to: price };
    }

    setSearchValueList((prevState: any) => ({
      ...prevState,
      ...valueList,
    }));
  };

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.row}>
          <div className={classes.rowLeft}>
            <dl>
              <dt>{t("promotion_type")}</dt>
              <dd
                className={classes.promotion_type}
                data-testid="promotion_type"
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    id="promotion_type"
                    value={searchValueList.promo_type}
                    onChange={(e: any) =>
                      setSearchValue("promo_type", e.target.value)
                    }
                  >
                    <MenuItem key={"0"} value={"0"}>
                      <ListItemText primary={t("all_promotions")} />
                    </MenuItem>
                    {Array.isArray(com("promotion_type")) ? (
                      com("promotion_type").map((option: any) => (
                        <MenuItem key={option.code} value={option.code}>
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </dd>
              <dd className={classes.advancedButton} data-testid="btn_Advanced">
                <Button
                  variant="contained"
                  className={classes.expandButton}
                  startIcon={changeAdvanced()}
                  onClick={handleAdvancedEvent}
                >
                  {t("btn_Advanced")}
                </Button>
              </dd>
            </dl>
          </div>
          <div className={classes.rowRight}>
            <div className={classes.rowRightTop}>
              <div className={classes.dateArea}>
                <dl>
                  <dt>{t("No")}</dt>
                  <dd className={classes.no} data-testid="promo_no">
                    <TextField
                      value={searchValueList.promo_no}
                      variant="outlined"
                      onChange={(e: any) => {
                        const value = e.target.value
                          .replace(/[^\d]/g, "")
                          .substr(0, 10);
                        setSearchValue("promo_no", value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          props.searchHandle("search");
                        }
                      }}
                    />
                  </dd>
                </dl>
              </div>
              <div className={classes.customerArea}>
                <dl>
                  <dt>{t("prom_name")}</dt>
                  <dd className={classes.promoName}>
                    <TextField
                      value={searchValueList.promo_name}
                      variant="outlined"
                      onChange={(e: any) => {
                        const value = e.target.value.substr(0, 50);
                        setSearchValue("promo_name", value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          props.searchHandle("search");
                        }
                      }}
                    />
                  </dd>
                </dl>
                {(searchValueList.promo_type === "0" ||
                  searchValueList.promo_type === "10" ||
                  searchValueList.promo_type === "30" ||
                  searchValueList.promo_type === "40" ||
                  searchValueList.promo_type === "50") && (
                  <dl>
                    <dt>{t("priority")}</dt>
                    <dd className={classes.priority}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          id="priority"
                          value={searchValueList.priority}
                          className={classes.priorityType}
                          onChange={(e: any) =>
                            setSearchValue("priority", e.target.value)
                          }
                          native
                        >
                          <option value={""}></option>
                          {Array.isArray(com("priority_option")) ? (
                            com("priority_option").map((option: any) => (
                              <option key={option.code} value={option.code}>
                                {option.name}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                    </dd>
                  </dl>
                )}
                <dl>
                  <dt>{t("customer_type")}</dt>
                  <dd
                    className={classes.customerType}
                    data-testid="customer_type"
                  >
                    <TextField
                      value={searchValueList.customer_type}
                      variant="outlined"
                      onChange={(e: any) => {
                        const value = e.target.value
                          // .replace(/[^\w]/g, "")
                          .substr(0, 4);
                        setSearchValue("customer_type", value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          props.searchHandle("search");
                        }
                      }}
                      disabled={customerNoDisabled}
                    />
                  </dd>
                </dl>
                <dl>
                  <dt>{t("customer_discount_code")}</dt>
                  <dd
                    className={classes.customerDiscountCode}
                    data-testid="customer_discount_code"
                  >
                    <TextField
                      value={searchValueList.customer_discount_code}
                      variant="outlined"
                      onChange={(e: any) => {
                        const value = e.target.value
                          // .replace(/[^\w]/g, "")
                          .substr(0, 2);
                        setSearchValue("customer_discount_code", value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          props.searchHandle("search");
                        }
                      }}
                      disabled={customerNoDisabled}
                    />
                  </dd>
                </dl>
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac1") && (
                    <dl>
                      <dt>{t("csac1")}</dt>
                      <dd className={classes.csac} data-testid="csac1">
                        <TextField
                          value={searchValueList.csac1}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac1", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac2") && (
                    <dl>
                      <dt>{t("csac2")}</dt>
                      <dd className={classes.csac} data-testid="csac2">
                        <TextField
                          value={searchValueList.csac2}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac2", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac3") && (
                    <dl>
                      <dt>{t("csac3")}</dt>
                      <dd className={classes.csac} data-testid="csac3">
                        <TextField
                          value={searchValueList.csac3}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac3", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac4") && (
                    <dl>
                      <dt>{t("csac4")}</dt>
                      <dd className={classes.csac} data-testid="csac4">
                        <TextField
                          value={searchValueList.csac4}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac4", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac5") && (
                    <dl>
                      <dt>{t("csac5")}</dt>
                      <dd className={classes.csac} data-testid="csac5">
                        <TextField
                          value={searchValueList.csac5}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac5", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac6") && (
                    <dl>
                      <dt>{t("csac6")}</dt>
                      <dd className={classes.csac} data-testid="csac6">
                        <TextField
                          value={searchValueList.csac6}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac6", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac7") && (
                    <dl>
                      <dt>{t("csac7")}</dt>
                      <dd className={classes.csac} data-testid="csac7">
                        <TextField
                          value={searchValueList.csac7}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac7", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac8") && (
                    <dl>
                      <dt>{t("csac8")}</dt>
                      <dd className={classes.csac} data-testid="csac8">
                        <TextField
                          value={searchValueList.csac8}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac8", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac9") && (
                    <dl>
                      <dt>{t("csac9")}</dt>
                      <dd className={classes.csac} data-testid="csac9">
                        <TextField
                          value={searchValueList.csac9}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac9", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("csac10") && (
                    <dl>
                      <dt>{t("csac10")}</dt>
                      <dd className={classes.csac} data-testid="csac10">
                        <TextField
                          value={searchValueList.csac10}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("csac10", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                  {props.salesAnalysisList.customer_list &&
                  props.salesAnalysisList.customer_list.length > 0 &&
                  props.salesAnalysisList.customer_list.includes("promotion_region") && (
                    <dl>
                      <dt>{t("promotion_region")}</dt>
                      <dd className={classes.promotion_region} data-testid="promotion_region">
                        <TextField
                          value={searchValueList.promotion_region}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("promotion_region", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={customerNoDisabled}
                        />
                      </dd>
                    </dl>
                  )}
                <dl>
                  <dt>{t("customer_no")}</dt>
                  <dd className={classes.customerNo} data-testid="customer_no">
                    <Autocomplete
                      id="customer_no"
                      freeSolo
                      disableClearable
                      options={commonInfo.customerList}
                      getOptionLabel={getOptionCustomerLabel}
                      filterOptions={filterCustomerOptions}
                      inputValue={String(searchValueList.customer_no)}
                      value={String(searchValueList.customer_no)}
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          style={{
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                            minWidth: "300px",
                          }}
                          placement="bottom-start"
                        />
                      )}
                      getOptionSelected={(option: any, value: any) => {
                        // return (option.customer_no === searchValueList.customer_no);
                        let isSelected = false;
                        if (option.customer_no && searchValueList.customer_no) {
                          isSelected =
                            option.customer_no.toString().toUpperCase() ===
                            searchValueList.customer_no
                              .toString()
                              .toUpperCase();
                        }
                        return isSelected;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          error={errorValueList.customer_no_error !== ""}
                          helperText={errorValueList.customer_no_error}
                          onBlur={onBlurOfCustomerNo}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                        />
                      )}
                      onChange={(event: object, value: any, reason: string) => {
                        if (reason === "select-option") {
                          setSearchValue(
                            "customer_no",
                            value.customer_no.toString()
                          );
                        }
                      }}
                      onInputChange={(
                        event: object,
                        value: string,
                        reason: string
                      ) => {
                        if (reason === "input" || reason === "clear") {
                          const val = value.substr(0, 8);
                          setSearchValue("customer_no", val);
                        }
                      }}
                    />
                    <IconButton
                      className={classes.iconButton}
                      onClick={handleCSearch}
                      id="btn_promotion_list_search"
                      tabIndex={0}
                      onFocus={() => buttonFocus()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </dd>
                </dl>
                <dl className={classes.datePickerArea}>
                  <dt>{t("start_date")}</dt>
                  <dd
                    className={classes.datePickerContainer}
                    data-testid="start_date"
                  >
                    <DatePicker
                      value={searchValueList.start_date}
                      onChange={(date: any) =>
                        setSearchValue("start_date", date)
                      }
                      onAccept={(date: any) =>
                        checkStartDate(new Date(date).setHours(0, 0, 0, 0))
                      }
                      className={classes.datePicker}
                      error={errorValueList.start_date_error !== ""}
                      helperText={errorValueList.start_date_error}
                      onBlur={(e: any) => onBlurOfStartDate(e)}
                      onError={(error: any, value: any) => {
                        if (
                          error !== errorValueList.start_date_error &&
                          error !== ""
                        ) {
                          setErrorValue("start_date_error", error);
                        }
                      }}
                    />
                  </dd>
                </dl>
                <dl className={classes.datePickerArea}>
                  <dt>{t("end_date")}</dt>
                  <dd
                    className={classes.datePickerContainer}
                    data-testid="end_date"
                  >
                    <DatePicker
                      value={searchValueList.end_date}
                      onChange={(date: any) => {
                        if (date && date !== "") {
                          setSearchValue(
                            "end_date",
                            new Date(date).setHours(23, 59, 59, 999)
                          );
                        } else {
                          setSearchValue("end_date", date);
                        }
                      }}
                      onAccept={(date: any) =>
                        checkEndDate(new Date(date).setHours(23, 59, 59, 999))
                      }
                      className={classes.datePicker}
                      error={errorValueList.end_date_error !== ""}
                      helperText={errorValueList.end_date_error}
                      onBlur={(e: any) => onBlurOfEndDate(e)}
                      onError={(error: any, value: any) => {
                        if (
                          error !== errorValueList.end_date_error &&
                          error !== ""
                        ) {
                          setErrorValue("end_date_error", error);
                        }
                      }}
                    />
                  </dd>
                </dl>
                {(searchValueList.promo_type === "0" ||
                  searchValueList.promo_type === "10" ||
                  searchValueList.promo_type === "20" ||
                  searchValueList.promo_type === "30" ||
                  searchValueList.promo_type === "40" ||
                  searchValueList.promo_type === "50") && (
                  <>
                    <dl>
                      <dt>{t("item_discount_code")}</dt>
                      <dd
                        className={classes.itemDiscountCode}
                        data-testid="item_discount_code"
                      >
                        <TextField
                          value={searchValueList.item_discount_code}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 3);
                            setSearchValue("item_discount_code", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={itemNoDisabled}
                        />
                      </dd>
                    </dl>
                    <dl>
                      <dt>{t("item_class")}</dt>
                      <dd
                        className={classes.itemClass}
                        data-testid="item_class"
                      >
                        <TextField
                          value={searchValueList.item_class}
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value
                              // .replace(/[^\w]/g, "")
                              .substr(0, 5);
                            setSearchValue("item_class", value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              props.searchHandle("search");
                            }
                          }}
                          disabled={itemNoDisabled}
                        />
                      </dd>
                    </dl>
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac1") && (
                        <dl>
                          <dt>{t("isac1")}</dt>
                          <dd className={classes.isac} data-testid="isac1">
                            <TextField
                              value={searchValueList.isac1}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac1", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac2") && (
                        <dl>
                          <dt>{t("isac2")}</dt>
                          <dd className={classes.isac} data-testid="isac2">
                            <TextField
                              value={searchValueList.isac2}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac2", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac3") && (
                        <dl>
                          <dt>{t("isac3")}</dt>
                          <dd className={classes.isac} data-testid="isac3">
                            <TextField
                              value={searchValueList.isac3}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac3", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac4") && (
                        <dl>
                          <dt>{t("isac4")}</dt>
                          <dd className={classes.isac} data-testid="isac4">
                            <TextField
                              value={searchValueList.isac4}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac4", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac5") && (
                        <dl>
                          <dt>{t("isac5")}</dt>
                          <dd className={classes.isac} data-testid="isac5">
                            <TextField
                              value={searchValueList.isac5}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac5", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac6") && (
                        <dl>
                          <dt>{t("isac6")}</dt>
                          <dd className={classes.isac} data-testid="isac6">
                            <TextField
                              value={searchValueList.isac6}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac6", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac7") && (
                        <dl>
                          <dt>{t("isac7")}</dt>
                          <dd className={classes.isac} data-testid="isac7">
                            <TextField
                              value={searchValueList.isac7}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac7", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    {props.salesAnalysisList.item_list &&
                      props.salesAnalysisList.item_list.length > 0 &&
                      props.salesAnalysisList.item_list.includes("isac8") && (
                        <dl>
                          <dt>{t("isac8")}</dt>
                          <dd className={classes.isac} data-testid="isac8">
                            <TextField
                              value={searchValueList.isac8}
                              variant="outlined"
                              onChange={(e: any) => {
                                const value = e.target.value
                                  // .replace(/[^\w]/g, "")
                                  .substr(0, 5);
                                setSearchValue("isac8", value);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                              disabled={itemNoDisabled}
                            />
                          </dd>
                        </dl>
                      )}
                    <dl>
                      <dt>{t("item")}</dt>
                      <dd className={classes.itemNo} data-testid="item_no">
                        <Autocomplete
                          id="item_no"
                          freeSolo
                          disableClearable
                          options={commonInfo.itemBaseList}
                          getOptionLabel={getOptionLabel2}
                          filterOptions={filterOptions2}
                          inputValue={searchValueList.item_no}
                          value={searchValueList.item_no}
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              style={{
                                whiteSpace: "nowrap",
                                wordBreak: "keep-all",
                                minWidth: "200px",
                              }}
                              placement="bottom-start"
                            />
                          )}
                          getOptionSelected={(option: any, value: any) =>
                            option.item_no.toString().toUpperCase() ===
                            searchValueList.item_no.toString().toUpperCase()
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              variant="outlined"
                              error={errorValueList.item_no_error !== ""}
                              helperText={errorValueList.item_no_error}
                              onBlur={onBlurOfItemNo}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  props.searchHandle("search");
                                }
                              }}
                            />
                          )}
                          onChange={(
                            event: object,
                            value: any,
                            reason: string
                          ) => {
                            if (reason === "select-option") {
                              setSearchValue("item_no", value.item_no);
                            }
                          }}
                          onInputChange={(
                            event: object,
                            value: string,
                            reason: string
                          ) => {
                            if (reason === "input" || reason === "clear") {
                              const val = value.substr(0, 35);
                              setSearchValue("item_no", val);
                            }
                          }}
                        />
                      </dd>
                    </dl>
                  </>
                )}
              </div>
              <div>
                <div className={classes.searchButtonArea}>
                  {userPermisssionInfo.csd_promotion_list_search_authFlg && (
                    <Button
                      className={classes.searchButtonCondition}
                      data-testid="btn_Search"
                      variant="contained"
                      color="primary"
                      startIcon={<SearchIcon />}
                      onClick={() => {
                        props.searchHandle("search");
                      }}
                      disabled={searchButtondisabled}
                    >
                      {t("btn_Search")}
                    </Button>
                  )}
                  {userPermisssionInfo.csd_promotion_list_clear_authFlg && (
                    <Button
                      className={classes.searchButtonCondition}
                      data-testid="btn_Clear"
                      variant="contained"
                      onClick={handleClearEvent}
                      startIcon={<RefreshIcon />}
                      disabled={changeCondition}
                    >
                      {t("btn_Clear")}
                    </Button>
                  )}
                </div>
                {/* <div className={classes.searchButtonArea}>
                  {userPermisssionInfo.csd_promotion_list_download_authFlg && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudDownloadIcon />}
                      onClick={props.downloadCSVBySearch}
                    >
                      {t("download_to_csv")}
                    </Button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(!advancedShowFlg && classes.advancedHideFlg)}>
          <div className={classes.rowRightBottom}>
            <dl>
              <dt>{t("status")}</dt>
              <dd className={classes.status} data-testid="status">
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    multiple
                    id="status"
                    value={searchValueList.promotion_status}
                    onChange={(e: any) =>
                      setSearchValue("promotion_status", e.target.value)
                    }
                    renderValue={() => ""}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {Array.isArray(com("status_option")) ? (
                      com("status_option").map((option: any) => (
                        <MenuItem key={option.code} value={option.code}>
                          <Checkbox
                            color="primary"
                            checked={
                              searchValueList.promotion_status.indexOf(
                                option.code
                              ) > -1
                            }
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </dd>
            </dl>
            {(searchValueList.promo_type === "0" ||
              searchValueList.promo_type === "10" ||
              searchValueList.promo_type === "20" ||
              searchValueList.promo_type === "50") && (
              <dl>
                <dt>{t("discount_rate")}</dt>
                <dd className={classes.discountRate}>
                  <div
                    className={classes.discountRateCell}
                    data-testid="discount_rate_from"
                  >
                    <TextField
                      value={searchValueList.discount_rate_from}
                      variant="outlined"
                      onChange={(e: any) => {
                        const value = e.target.value
                          .replace(/[^\d]/g, "")
                          .substr(0, 3);
                        checkDiscountRateFrom(value);
                      }}
                      error={errorValueList.discount_rate_from_error !== ""}
                      helperText={errorValueList.discount_rate_from_error}
                    />
                    <span>%</span>
                  </div>
                  <span>～</span>
                  <div
                    className={classes.discountRateCell}
                    data-testid="discount_rate_to"
                  >
                    <TextField
                      value={searchValueList.discount_rate_end}
                      variant="outlined"
                      onChange={(e: any) => {
                        const value = e.target.value
                          .replace(/[^\d]/g, "")
                          .substr(0, 3);
                        checkDiscountRateEnd(value);
                      }}
                      error={errorValueList.discount_rate_end_error !== ""}
                      helperText={errorValueList.discount_rate_end_error}
                    />
                    <span>%</span>
                  </div>
                </dd>
              </dl>
            )}
            {(searchValueList.promo_type === "0" ||
              searchValueList.promo_type === "40") && (
              <dl>
                <dt>{t("special_price")}</dt>
                <dd className={classes.discountRate}>
                  <div
                    className={classes.discountRateCell}
                    data-testid="special_price_from"
                  >
                    <TextField
                      value={searchValueList.special_price_from_format}
                      variant="outlined"
                      onChange={(e: any) => {
                        let value = "";
                        let _price: any = priceInputBySign(e.target.value);
                        if (
                          !isNaN(_price) &&
                          _price >= 0 &&
                          decimalPointIsLenth(e.target.value)
                        ) {
                          value = e.target.value.slice(0, 11);
                        } else {
                          value = searchValueList.special_price_from_format;
                          _price = searchValueList.special_price_from;
                        }
                        _price = _price.toString();
                        setSpecialPriceValue("from", value, _price);
                      }}
                      onBlur={(e) => checkSpecialPrice("from")}
                      error={errorValueList.special_price_from_error !== ""}
                      helperText={errorValueList.special_price_from_error}
                    />
                    <span>{symbolByCurrency(config.currency)}</span>
                  </div>
                  <span>～</span>
                  <div
                    className={classes.discountRateCell}
                    data-testid="special_price_to"
                  >
                    <TextField
                      value={searchValueList.special_price_to_format}
                      variant="outlined"
                      onChange={(e: any) => {
                        let value = "";
                        let _price: any = priceInputBySign(e.target.value);
                        if (
                          !isNaN(_price) &&
                          _price >= 0 &&
                          decimalPointIsLenth(e.target.value)
                        ) {
                          value = e.target.value.slice(0, 11);
                        } else {
                          value = searchValueList.special_price_to_format;
                          _price = searchValueList.special_price_to;
                        }
                        _price = _price.toString();
                        setSpecialPriceValue("to", value, _price);
                      }}
                      onBlur={(e) => checkSpecialPrice("to")}
                      error={errorValueList.special_price_end_error !== ""}
                      helperText={errorValueList.special_price_end_error}
                    />
                    <span>{symbolByCurrency(config.currency)}</span>
                  </div>
                </dd>
              </dl>
            )}
            {(searchValueList.promo_type === "0" ||
              searchValueList.promo_type === "30") && (
              <dl>
                <dt>{t("free_goods")}</dt>
                <dd className={classes.freeGoods} data-testid="free_goods">
                  <Autocomplete
                    id="free_goods"
                    freeSolo
                    disableClearable
                    options={commonInfo.itemBaseList}
                    getOptionLabel={getOptionLabel2}
                    filterOptions={filterOptions2}
                    inputValue={searchValueList.free_goods_items}
                    value={searchValueList.free_goods_items}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          minWidth: "200px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                    getOptionSelected={(option: any, value: any) =>
                      option.item_no.toString().toUpperCase() ===
                      searchValueList.free_goods_items.toString().toUpperCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        error={errorValueList.free_goods_error !== ""}
                        helperText={errorValueList.free_goods_error}
                        onBlur={onBlurOfFreeGoods}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            props.searchHandle("search");
                          }
                        }}
                      />
                    )}
                    onChange={(event: object, value: any, reason: string) => {
                      if (reason === "select-option") {
                        setSearchValue("free_goods_items", value.item_no);
                      }
                    }}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === "input" || reason === "clear") {
                        const val = value.substr(0, 35);
                        setSearchValue("free_goods_items", val);
                      }
                    }}
                  />
                </dd>
              </dl>
            )}
            {(searchValueList.promo_type === "0" ||
              searchValueList.promo_type === "50") && (
              <dl>
                <dt>{t("promotion_code")}</dt>
                <dd
                  className={classes.promotionCode}
                  data-testid="promotion_code"
                >
                  <TextField
                    value={searchValueList.promotion_code}
                    variant="outlined"
                    onChange={(e: any) => {
                      const value = e.target.value
                        .replace(/[^\w]/g, "")
                        .substr(0, 10);
                      setSearchValue("promotion_code", value);
                    }}
                  />
                </dd>
              </dl>
            )}
            {(searchValueList.promo_type === "0" ||
              searchValueList.promo_type === "10" ||
              searchValueList.promo_type === "30" ||
              searchValueList.promo_type === "40" ||
              searchValueList.promo_type === "50") && (
              <div className={classes.allowOverlap} data-testid="allow_overlap">
                <FormControlLabel
                  checked={
                    searchValueList.promo_type === "50"
                      ? true
                      : searchValueList.allow_overlap
                  }
                  value="allow_overlap"
                  control={<Checkbox color="primary" />}
                  label={t("allow_overlap")}
                  labelPlacement="end"
                  onChange={(e: any) =>
                    setSearchValue(
                      "allow_overlap",
                      searchValueList.promo_type === "50"
                        ? true
                        : e.target.checked
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
      </form>
      <PromotionSearchControlButton
        setSearchValueList={setSearchValueList}
        searchValueList={searchValueList}
        defaultValueList={defaultValueList}
        selectedPromotions={selectedPromotions}
        numSelected={numSelected}
        setErrMessageList={setErrMessageList}
        setErrorMessageOpen={setErrorMessageOpen}
        userPermisssionInfo={userPermisssionInfo}
        deletePromotionAlert={deletePromotionAlert}
        downloadCSV={props.downloadCSV}
        searchHandle={props.searchHandle}
        errorMessage={props.errorMessage}
        setAlertShow={setAlertShow}
        salesAnalysisDefinition={salesAnalysisDefinition}
        downloadCSVBySearch={props.downloadCSVBySearch}
      />
      <PromotionSearchControlCSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </>
  );
};
