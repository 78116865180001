import React, { useState, useEffect } from "react";
// components
import { MasterMaintenanceSearchBox } from "./MasterMaintenance.SearchBox";
import { MasterMaintenancePrintSettingThead } from "./MasterMaintenance.PrintSetting.Thead";
import { MasterMaintenancePrintSettingTbody } from "./MasterMaintenance.PrintSetting.Tbody";
import { TabKey } from "../MasterMaintenance.constants";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
// hook
import { useStyles } from "../hooks/table.useStyles";

export const MasterMaintenancePrintSetting = (props: any) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState("");
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.printSetting",
  });
  const { printSettingData } = props;
  // pager
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // delete
  const [deleteRow, setDeleteRow] = useState(0);

  // const [resultList, setResultList] = useState(useMasterData.slice(0));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // search
  const handleChangeKey = (value: any) => {
    setSearchKey(value);
    props.SearchResult(value, TabKey.printSetting);
    setPage(0);
  };

  const handleSearch = () => {
    props.SearchResult(searchKey, TabKey.printSetting);
    setPage(0);
  };

  return (
    <div>
      <MasterMaintenanceSearchBox
        searchKey={searchKey}
        handleChangeKey={handleChangeKey}
        handleSearch={handleSearch}
      />
      <TableContainer>
        <Table className={classes.table}>
          <MasterMaintenancePrintSettingThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <MasterMaintenancePrintSettingTbody
            data={props.printSettingResultList}
            pageInfo={pageInfo}
            order={order}
            orderBy={orderBy}
            handleEdit={props.handleEdit}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        component="div"
        count={props.printSettingResultList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
    </div>
  );
};
