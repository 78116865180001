import React from "react";
// components
import { JobsDetailGeneralRepairMediaThead } from "./JobsDetail.General.RepairUpdatedHistory.Thead";
import { JobsDetailGeneralRepairMediaTbody } from "./JobsDetail.General.RepairUpdatedHistory.Tbody";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hooks
import { useStyles } from "../hooks/useStyles";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

export const JobsDetailGeneralRepairUpdatedHistory = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath:
      "modules.components.jobDetail.general.group_repair_updated_history",
  });

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name")}</h3>
      <TableContainer className={classes.groupContainer}>
        <Table stickyHeader>
          <JobsDetailGeneralRepairMediaThead />
          <JobsDetailGeneralRepairMediaTbody data={props.data} />
        </Table>
      </TableContainer>
    </div>
  );
};
