import React, { useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailConditionsUnitBaseTable } from "./PromotionDetail.Conditions.UnitBase.Table";
import { PromotionDetailConditionsAmountBaseTable } from "./PromotionDetail.Conditions.AmountBase.Table";
import { PromotionDetailConditionsSingleTable } from "./PromotionDetail.Conditions.Single.Table";
import { PromotionDetailConditionsSetTable } from "./PromotionDetail.Conditions.Set.Table";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  priceInputBySign,
  decimalPointIsLenth,
  priceInputBySignForDecimal,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "20px",
      paddingBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "25px",
      },
      "& h2": {
        marginTop: "0px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& dl": {
        display: "flex",
        margin: 0,
        marginRight: "20px",
      },
      "& dd": {
        marginLeft: "15px",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    radioGroup: {
      display: "block",
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
    },
    rowsWidth: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
    },
    rowsHalf: {
      display: "flex",
    },
    discountRate: {
      display: "flex",
      "& .MuiFormControl-root": {
        width: "80px",
        marginRight: "5px",
        marginTop: "-4px",
      },
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    borderBottom: {
      "&:not(:last-of-type)": {
        width: "480px",
        marginBottom: "20px",
        paddingBottom: "20px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      },
    },
    borderBottom2: {
      "&:not(:last-of-type)": {
        width: "100%",
        marginBottom: "20px",
        paddingBottom: "20px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      },
    },
    discountCode: {
      width: "100px",
    },
    itemClass: {
      width: "100px",
    },
    isac: {
      width: "80px",
    },
    plannerCode: {
      width: "70px",
    },
    item_no: {
      width: "140px",
    },
    unitPacket: {
      width: "120px",
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    },
    specialPrice: {
      display: "flex",
      "& .MuiFormControl-root": {
        width: "120px",
        marginRight: "5px",
      },
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    radioContainer: {
      display: "flex",
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    group: {
      paddingBottom: "20px",
      "&:not(:last-child)": {
        marginBottom: "20px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      },
    },
    buttonArea: {
      display: "flex",
      "& button": {
        marginRight: "10px",
      },
    },
    unit: {
      marginTop: "1px",
    },
    singleArea: {
      "& .MuiFormControl-root": {
        marginTop: "-4px",
      },
    },
    errorText: {
      color: "#f44336",
      fontSize: "12px",
      marginTop: "20px",
    },
    rowsPlannerCode: {
      display: "flex",
      margin: 0,
      alignItems: "center",
    },
  })
);

export const Conditions = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    searchValueList,
    setSearchValue,

    oldData,
    setOldData,

    errorValueList,
    changeDataListen,
    setErrorValueList,
    defaultValueList,
    setDefaultValue,
    specialPrice,
    setSpecialPrice,
    getOptionLabel2,
    filterOptions2,
    categorySingle,
    categorySet,
    setCategorySet,
    onBlurOfItemNo,
    onFocusOfItemNo,
    onFocusOfSingleItem,
    plannerCodeChange,
    numSelectedSet,
    setNumSelectedSet,
    selectedArraySet,
    setSelectedArraySet,
    selectedRowArraySet,
    setSelectedRowArraySet,
    indeterminateSet,
    setIndeterminateSet,
    allCheckedSet,
    setAllCheckedSet,
    categoryAll,
    numSelectedUnitBase,
    setNumSelectedUnitBase,
    selectedArrayUnitBase,
    setSelectedArrayUnitBase,
    selectedRowArrayUnitBase,
    setSelectedRowArrayUnitBase,
    indeterminateUnitBase,
    setIndeterminateUnitBase,
    allCheckedUnitBase,
    setAllCheckedUnitBase,
    numSelectedAmountBase,
    setNumSelectedAmountBase,
    selectedArrayAmountBase,
    setSelectedArrayAmountBase,
    selectedRowArrayAmountBase,
    setSelectedRowArrayAmountBase,
    indeterminateAmountBase,
    setIndeterminateAmountBase,
    allCheckedAmountBase,
    setAllCheckedAmountBase,
    setHasSeqNoRow,
    hasSeqNoRow,
    reviewFlg,
    onSetItemNoFlag,
    unitPrice,
    setUnitPrice,
    calcUnitPrice,
    specialPriceFormat,
    setSpecialPriceFormat,
    setSingleSpecialPriceFormat,
    singleSpecialPriceFormat,
    salesAnalysisList,
  } = props;
  const classes = useStyles();

  // change the selected number
  const changeNumSelectedUnitBase = (num: number) => {
    setNumSelectedUnitBase(num);
  };

  const changeNumSelectedAmountBase = (num: number) => {
    setNumSelectedAmountBase(num);
  };

  const changeNumSelectedSet = (num: number) => {
    setNumSelectedSet(num);
  };

  // set single or set value
  const setSingleOrSetValue = (
    property: any,
    value: any,
    index: any,
    type: any
  ) => {
    const setField = JSON.parse(JSON.stringify(searchValueList[type]));
    if (property === "special_price") {
      setField[index]["special_price"] = priceInputBySign(value).toString();
      setField[index]["special_price_format"] = value;
    } else if (property === "unit_price") {
      setField[index]["unit_price"] = value.price;
      setField[index]["unit_price_format"] = value.format;
    } else {
      setField[index][property] = value;
    }

    if (property === "unit_price" || property === "quantity") {
      setField[index]["total_unit_price"] =
        setField[index]["quantity"] * setField[index]["unit_price"];
      setField[index]["total_unit_price_format"] = priceInputBySign(
        setField[index]["total_unit_price"]
      ).toString();
    }

    setSearchValue(type, setField);
  };

  // add set row
  const addSet = () => {
    // ready one row
    const getSetField = JSON.parse(
      JSON.stringify(searchValueList.condition_set[0])
    );
    for (let key in getSetField) {
      if (key !== "data_type") {
        getSetField[key] = "";
      }
    }

    // add row at last
    const getSetData = JSON.parse(
      JSON.stringify(searchValueList.condition_set)
    );
    getSetData.push(JSON.parse(JSON.stringify(getSetField)));
    getSetData[getSetData.length - 1].index = getSetData.length - 1;
    setSearchValue("condition_set", getSetData);

    const getOldData = JSON.parse(JSON.stringify(oldData["condition_set"]));
    getOldData.push(JSON.parse(JSON.stringify(getSetField)));
    getOldData[getOldData.length - 1].index = getOldData.length - 1;
    props.setOldData("condition_set", getOldData);

    const getDefaultField = JSON.parse(
      JSON.stringify(defaultValueList.condition_set)
    );
    getDefaultField.push(JSON.parse(JSON.stringify(getSetField)));
    getDefaultField[getDefaultField.length - 1].index =
      getDefaultField.length - 1;
    setDefaultValue("condition_set", getDefaultField);

    if (selectedArraySet.length > 0) {
      setIndeterminateSet(true);
    } else {
      setIndeterminateSet(false);
    }
    setAllCheckedSet(false);

    // add error list
    const errorValueField = JSON.parse(
      JSON.stringify(props.errorValueList.condition_set[0])
    );
    for (let key in errorValueField) {
      errorValueField[key] = "";
    }
    const getErrorData = JSON.parse(
      JSON.stringify(props.errorValueList.condition_set)
    );
    getErrorData.push(JSON.parse(JSON.stringify(errorValueField)));
    const errorValue = Object.assign({}, props.errorValueList, {
      condition_set: getErrorData,
    });
    setErrorValueList(errorValue);

    // add item in categorySet
    categorySet.push(categoryAll);

    // add Unit Price
    const getUnitPrice = JSON.parse(JSON.stringify(unitPrice));
    getUnitPrice.push(0);
    setUnitPrice(getUnitPrice);
  };

  const deleteSet = () => {
    // ready one row
    const getSetField = JSON.parse(
      JSON.stringify(searchValueList.condition_set[0])
    );
    for (let key in getSetField) {
      if (key !== "data_type") {
        getSetField[key] = "";
      }
    }

    // delete row with "seq_no"
    if (hasSeqNoRow) {
      const hasSeqNo = selectedRowArraySet.some((item: any) => {
        return item.seq_no && item.seq_no !== "";
      });
      setHasSeqNoRow(!hasSeqNo);
    }

    // filter not selected item
    let getSetData = searchValueList.condition_set.filter((item: any) => {
      return selectedArraySet.indexOf(item.index) === -1;
    });

    let getOldData: any = oldData.condition_set.filter((item: any) => {
      return selectedArraySet.indexOf(item.index) === -1;
    });

    if (getSetData.length === 0) {
      getSetData.push({ ...getSetField }, { ...getSetField });
      getOldData.push({ ...getSetField }, { ...getSetField });
    }

    if (getSetData.length === 1) {
      getSetData.push(getSetField);
      getOldData.push(JSON.parse(JSON.stringify(getSetField)));
    }

    getSetData.forEach((item: any, index: number) => {
      item.index = index;
    });

    getOldData.forEach((item: any, index: number) => {
      item.index = index;
    });

    setSearchValue("condition_set", JSON.parse(JSON.stringify(getSetData)));

    setOldData("condition_set", JSON.parse(JSON.stringify(getOldData)));

    setSelectedArraySet([]);
    setSelectedRowArraySet([]);
    setIndeterminateSet(false);
    setAllCheckedSet(false);

    let getDefaultData = defaultValueList.condition_set.filter((item: any) => {
      return selectedArraySet.indexOf(item.index) === -1;
    });

    if (getDefaultData.length === 0) {
      getDefaultData.push({ ...getSetField }, { ...getSetField });
    }

    if (getDefaultData.length === 1) {
      getDefaultData.push(getSetField);
    }

    getDefaultData.forEach((item: any, index: number) => {
      item.index = index;
    });

    setDefaultValue(
      "condition_set",
      JSON.parse(JSON.stringify(getDefaultData))
    );

    // remove error list
    const getErrorData = JSON.parse(
      JSON.stringify(errorValueList.condition_set)
    );
    const getErrorField = getErrorData[0];
    for (let key in getErrorField) {
      getErrorField[key] = "";
    }

    let errorData = getErrorData.filter((item: any, index: any) => {
      return selectedArraySet.indexOf(index) === -1;
    });

    if (errorData.length === 0) {
      errorData.push({ ...getErrorField }, { ...getErrorField });
    }

    if (errorData.length === 1) {
      errorData.push(getErrorField);
    }

    const errorValue = Object.assign({}, errorValueList, {
      condition_set: errorData,
    });
    setErrorValueList(errorValue);

    // remove item in categorySet
    const categorySetAry = categorySet.filter((item: any, index: number) => {
      return selectedArraySet.indexOf(index) === -1;
    });

    if (categorySetAry.length === 0) {
      categorySetAry.push(categoryAll, categoryAll);
    }

    if (categorySetAry.length === 1) {
      categorySetAry.push(categoryAll);
    }
    setCategorySet(categorySetAry);

    // remove Unit Price
    let getUnitPrice = unitPrice.filter((item: any, index: number) => {
      return selectedArraySet.indexOf(index) === -1;
    });

    if (getUnitPrice.length === 0) {
      getUnitPrice.push(0, 0);
    }

    if (getUnitPrice.length === 1) {
      getUnitPrice.push(0);
    }

    setUnitPrice(getUnitPrice);
  };

  // set Promotion Code value
  const setPromotionCodeValue = (property: any, value: any) => {
    const setPromotionCodeField = JSON.parse(
      JSON.stringify(searchValueList.promotion_code)
    );
    if (property === "discount_rate") {
      setPromotionCodeField[0]["discount_rate"] =
        priceInputBySignForDecimal(value).toString();
      setPromotionCodeField[0]["discount_rate_format"] = value;
    } else {
      setPromotionCodeField[0][property] = value;
    }
    setSearchValue("promotion_code", setPromotionCodeField);
  };

  // set Dealer Discount value
  const setDealerDiscountValue = (property: any, value: any) => {
    const setDealerDiscountField = JSON.parse(
      JSON.stringify(searchValueList.dealer_discount)
    );
    if (property === "discount_rate") {
      setDealerDiscountField[0]["discount_rate"] =
        priceInputBySignForDecimal(value).toString();
      setDealerDiscountField[0]["discount_rate_format"] = value;
    }
    setSearchValue("dealer_discount", setDealerDiscountField);
  };

  const addDiscountRow = (type: any) => {
    // ready one row
    const getDiscountField = JSON.parse(
      JSON.stringify(searchValueList[type][0])
    );
    for (let key in getDiscountField) {
      if (key !== "data_type") {
        getDiscountField[key] = "";
      }
    }

    // add row at last
    const getDiscountData = JSON.parse(JSON.stringify(searchValueList[type]));
    getDiscountData.push(JSON.parse(JSON.stringify(getDiscountField)));
    getDiscountData[getDiscountData.length - 1].index =
      getDiscountData.length - 1;
    setSearchValue(type, getDiscountData);

    if (type === "condition_unit_base") {
      if (selectedArrayUnitBase.length > 0) {
        setIndeterminateUnitBase(true);
      } else {
        setIndeterminateUnitBase(false);
      }
      setAllCheckedUnitBase(false);
    } else if (type === "condition_amount_base") {
      if (selectedArrayAmountBase.length > 0) {
        setIndeterminateAmountBase(true);
      } else {
        setIndeterminateAmountBase(false);
      }
      setAllCheckedAmountBase(false);
    }

    const getDefaultField = JSON.parse(JSON.stringify(defaultValueList[type]));
    getDefaultField.push(JSON.parse(JSON.stringify(getDiscountField)));
    getDefaultField[getDefaultField.length - 1].index =
      getDefaultField.length - 1;
    setDefaultValue(type, getDefaultField);

    // add error list
    const errorValueField = JSON.parse(JSON.stringify(errorValueList[type][0]));
    for (let key in errorValueField) {
      errorValueField[key] = "";
    }
    const getErrorData = JSON.parse(JSON.stringify(errorValueList[type]));
    getErrorData.push(JSON.parse(JSON.stringify(errorValueField)));
    const errorValue = Object.assign({}, errorValueList, {
      [type]: getErrorData,
    });
    setErrorValueList(errorValue);
  };

  const deleteDiscountRow = (type: any) => {
    // ready one row
    const getDiscountField = JSON.parse(
      JSON.stringify(searchValueList[type][0])
    );
    for (let key in getDiscountField) {
      if (key !== "data_type") {
        getDiscountField[key] = "";
      }
    }

    // delete row with "seq_no"
    if (hasSeqNoRow) {
      let hasSeqNo = false;
      if (type === "condition_unit_base") {
        hasSeqNo = selectedRowArrayUnitBase.some((item: any) => {
          return item.seq_no && item.seq_no !== "";
        });
      } else if (type === "condition_amount_base") {
        hasSeqNo = selectedRowArrayAmountBase.some((item: any) => {
          return item.seq_no && item.seq_no !== "";
        });
      }
      setHasSeqNoRow(!hasSeqNo);
    }

    // filter not selected item
    let getDiscountData: any = "";
    if (type === "condition_unit_base") {
      getDiscountData = searchValueList[type].filter((item: any) => {
        return selectedArrayUnitBase.indexOf(item.index) === -1;
      });
    } else if (type === "condition_amount_base") {
      getDiscountData = searchValueList[type].filter((item: any) => {
        return selectedArrayAmountBase.indexOf(item.index) === -1;
      });
    }

    if (getDiscountData.length === 0) {
      getDiscountData.push(getDiscountField);
    }
    getDiscountData.forEach((item: any, index: number) => {
      item.index = index;
    });
    setSearchValue(type, getDiscountData);

    if (type === "condition_unit_base") {
      setSelectedArrayUnitBase([]);
      setSelectedRowArrayUnitBase([]);
      setIndeterminateUnitBase(false);
      setAllCheckedUnitBase(false);
    } else if (type === "condition_amount_base") {
      setSelectedArrayAmountBase([]);
      setSelectedRowArrayAmountBase([]);
      setIndeterminateAmountBase(false);
      setAllCheckedAmountBase(false);
    }

    let getDefaultData: any = "";
    if (type === "condition_unit_base") {
      getDefaultData = defaultValueList[type].filter((item: any) => {
        return selectedArrayUnitBase.indexOf(item.index) === -1;
      });
    } else if (type === "condition_amount_base") {
      getDefaultData = defaultValueList[type].filter((item: any) => {
        return selectedArrayAmountBase.indexOf(item.index) === -1;
      });
    }

    if (getDefaultData.length === 0) {
      getDefaultData.push(getDiscountField);
    }
    getDefaultData.forEach((item: any, index: number) => {
      item.index = index;
    });
    setDefaultValue(type, getDefaultData);

    // remove error list
    const getErrorData = JSON.parse(JSON.stringify(errorValueList[type]));
    const getErrorField = getErrorData[0];
    for (let key in getErrorField) {
      getErrorField[key] = "";
    }

    let errorData: any = "";
    if (type === "condition_unit_base") {
      errorData = getErrorData.filter((item: any, index: any) => {
        return selectedArrayUnitBase.indexOf(index) === -1;
      });
    } else if (type === "condition_amount_base") {
      errorData = getErrorData.filter((item: any, index: any) => {
        return selectedArrayAmountBase.indexOf(index) === -1;
      });
    }

    if (errorData.length === 0) {
      errorData.push(getErrorField);
    }
    const errorValue = Object.assign({}, errorValueList, { [type]: errorData });
    setErrorValueList(errorValue);
  };

  const setCustomFieldValue = (
    property: any,
    value: any,
    index: number,
    type: any
  ) => {
    const setDiscountField = JSON.parse(JSON.stringify(searchValueList[type]));
    if (property === "purchase_amount") {
      setDiscountField[index]["purchase_amount"] =
        priceInputBySignForDecimal(value).toString();
      setDiscountField[index]["purchase_amount_format"] = value;
    } else if (property === "discount_rate") {
      setDiscountField[index]["discount_rate"] =
        priceInputBySignForDecimal(value).toString();
      setDiscountField[index]["discount_rate_format"] = value;
    } else {
      setDiscountField[index][property] = value;
    }
    setSearchValue(type, setDiscountField);
  };

  const Single = () => {
    return (
      <div className={classes.borderBottom2}>
        <h2>
          <FormControlLabel
            value="1"
            control={<Radio color="default" />}
            label={t("single")}
            disabled={reviewFlg}
          />
        </h2>
        <div className={classes.rows}>
          <dl className={classes.rowsPlannerCode}>
            <dt>{t("planner_code")}</dt>
            <dd className={classes.plannerCode}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="planner_code"
                  value={searchValueList.planner_code_single.plannerCode}
                  onChange={(e: any) => {
                    setSearchValue("planner_code_single", e.target.value);
                    plannerCodeChange("condition_single", e.target.value);
                  }}
                  disabled={reviewFlg}
                  native
                >
                  <option value={""}></option>
                  {Array.isArray(com("planner_code")) ? (
                    com("planner_code").map((option: any) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
        </div>

        <PromotionDetailConditionsSingleTable
          data={searchValueList}
          errorValueList={errorValueList}
          changeDataListen={changeDataListen}
          setSingleOrSetValue={setSingleOrSetValue}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categorySingle={categorySingle}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfSingleItem={onFocusOfSingleItem}
          reviewFlg={reviewFlg}
          setSingleSpecialPriceFormat={setSingleSpecialPriceFormat}
          singleSpecialPriceFormat={singleSpecialPriceFormat}
          salesAnalysisList={salesAnalysisList}
        />
      </div>
    );
  };

  const Set = () => {
    return (
      <div className={classes.borderBottom2}>
        <h2>
          <FormControlLabel
            value="2"
            control={<Radio color="default" />}
            label={t("set")}
            disabled={reviewFlg}
          />
        </h2>
        <div className={classes.rows}>
          <dl className={classes.rowsPlannerCode}>
            <dt>{t("planner_code")}</dt>
            <dd className={classes.plannerCode}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="planner_code"
                  value={searchValueList.planner_code_set}
                  onChange={(e: any) => {
                    setSearchValue("planner_code_set", e.target.value);
                    plannerCodeChange("condition_set", e.target.value);
                  }}
                  disabled={props.reviewFlg}
                  native
                >
                  <option value={""}></option>
                  {Array.isArray(com("planner_code")) ? (
                    com("planner_code").map((option: any) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
        </div>

        <PromotionDetailConditionsSetTable
          data={searchValueList}
          selectedArray={selectedArraySet}
          setSelectedArray={setSelectedArraySet}
          selectedRowArray={selectedRowArraySet}
          setSelectedRowArray={setSelectedRowArraySet}
          indeterminate={indeterminateSet}
          setIndeterminate={setIndeterminateSet}
          allChecked={allCheckedSet}
          setAllChecked={setAllCheckedSet}
          numSelected={numSelectedSet}
          changeNumSelected={(num: number) => changeNumSelectedSet(num)}
          errorValueList={errorValueList}
          changeDataListen={changeDataListen}
          addSet={addSet}
          deleteSet={deleteSet}
          setSingleOrSetValue={setSingleOrSetValue}
          specialPrice={specialPrice}
          setSpecialPrice={setSpecialPrice}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categorySet={categorySet}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfItemNo={onFocusOfItemNo}
          reviewFlg={reviewFlg}
          onSetItemNoFlag={onSetItemNoFlag}
          unitPrice={unitPrice}
          calcUnitPrice={calcUnitPrice}
          onBlurOfSetItem={props.onBlurOfSetItem}
          onFocusOfSetItem={props.onFocusOfSetItem}
          specialPriceFormat={specialPriceFormat}
          setSpecialPriceFormat={setSpecialPriceFormat}
          salesAnalysisList={salesAnalysisList}
        />
      </div>
    );
  };

  const PromotionCode = () => {
    const promotionCodeData = searchValueList.promotion_code[0];
    return (
      <div className={classes.rows}>
        <dl>
          <dt>{t("discount_rate")}</dt>
          <dd
            className={classes.discountRate}
            data-testid="promotionCode_discount_rate"
          >
            <TextField
              variant="outlined"
              value={promotionCodeData.discount_rate_format}
              onChange={(e: any) => {
                let value = "";
                let _discount_rate = priceInputBySign(e.target.value);
                if (
                  !isNaN(_discount_rate) &&
                  _discount_rate >= 0 &&
                  decimalPointIsLenth(e.target.value)
                ) {
                  value = e.target.value.slice(0, 11);
                } else {
                  value = promotionCodeData.discount_rate_format
                    ? promotionCodeData.discount_rate_format
                    : "";
                }
                setPromotionCodeValue("discount_rate", value);
              }}
              onBlur={(e) =>
                changeDataListen("promotionCode_discount_rate", e.target.value)
              }
              error={errorValueList.promotionCode_discount_rate_error !== ""}
              helperText={errorValueList.promotionCode_discount_rate_error}
              disabled={reviewFlg}
            />
            %
          </dd>
        </dl>
        <RadioGroup
          name="singleOrMultiple"
          data-testid="singleOrMultiple"
          value={promotionCodeData.promo_code_type}
          onChange={(e) => {
            setPromotionCodeValue("promo_code_type", e.target.value);
          }}
        >
          <FormControl
            variant="outlined"
            className={classes.formControl}
            disabled={reviewFlg}
          >
            <div className={classes.radioContainer}>
              <FormControlLabel
                className={classes.radio}
                value="1"
                control={<Radio color="default" />}
                label={t("single_use")}
              />
              <FormControlLabel
                className={classes.radio}
                value="2"
                control={<Radio color="default" />}
                label={t("multiple_use")}
              />
            </div>
          </FormControl>
        </RadioGroup>
      </div>
    );
  };

  const DealerDiscount = () => {
    return (
      <div className={classes.rows}>
        <dl>
          <dt>{t("discount_rate")}</dt>
          <dd
            className={classes.discountRate}
            data-testid="dealerDiscount_discount_rate"
          >
            <TextField
              variant="outlined"
              value={searchValueList.dealer_discount[0].discount_rate_format}
              onChange={(e: any) => {
                let value = "";
                let _discount_rate = priceInputBySign(e.target.value);
                if (
                  !isNaN(_discount_rate) &&
                  _discount_rate >= 0 &&
                  decimalPointIsLenth(e.target.value)
                ) {
                  value = e.target.value.slice(0, 11);
                } else {
                  value = searchValueList.dealer_discount[0]
                    .discount_rate_format
                    ? searchValueList.dealer_discount[0].discount_rate_format
                    : "";
                }
                setDealerDiscountValue("discount_rate", value);
              }}
              onBlur={(e) =>
                changeDataListen("dealerDiscount_discount_rate", e.target.value)
              }
              error={errorValueList.dealerDiscount_discount_rate_error !== ""}
              helperText={errorValueList.dealerDiscount_discount_rate_error}
              disabled={reviewFlg}
            />
            %
          </dd>
        </dl>
      </div>
    );
  };

  const getComponents = () => {
    if (searchValueList.promo_type === "10") {
      return (
        <RadioGroup
          name="unitBaseOrAmountBase"
          data-testid="unitBase_or_amountBase"
          value={searchValueList.unitBase_or_amountBase}
          onChange={(e) => {
            setSearchValue("unitBase_or_amountBase", e.target.value);
            changeDataListen("unitBase_or_amountBase", e.target.value);
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <div className={classes.borderBottom}>
              <h2>
                <FormControlLabel
                  value="1"
                  control={<Radio color="default" />}
                  label={t("unit_base")}
                  disabled={reviewFlg}
                />
              </h2>
              <PromotionDetailConditionsUnitBaseTable
                data={searchValueList.condition_unit_base}
                setData={setSearchValue}
                selectedArray={selectedArrayUnitBase}
                setSelectedArray={setSelectedArrayUnitBase}
                selectedRowArray={selectedRowArrayUnitBase}
                setSelectedRowArray={setSelectedRowArrayUnitBase}
                indeterminate={indeterminateUnitBase}
                setIndeterminate={setIndeterminateUnitBase}
                allChecked={allCheckedUnitBase}
                setAllChecked={setAllCheckedUnitBase}
                numSelected={numSelectedUnitBase}
                changeNumSelected={(num: number) =>
                  changeNumSelectedUnitBase(num)
                }
                errorValueList={errorValueList}
                changeDataListen={changeDataListen}
                setErrorValueList={setErrorValueList}
                addDiscountRow={addDiscountRow}
                deleteDiscountRow={deleteDiscountRow}
                setCustomFieldValue={setCustomFieldValue}
                reviewFlg={reviewFlg}
                unitBaseOrAmountBaseValue={
                  searchValueList.unitBase_or_amountBase
                }
              />
            </div>
            <div className={classes.borderBottom}>
              <h2>
                <FormControlLabel
                  value="2"
                  control={<Radio color="default" />}
                  label={t("amount_base")}
                  disabled={reviewFlg}
                />
              </h2>
              <PromotionDetailConditionsAmountBaseTable
                data={searchValueList.condition_amount_base}
                selectedArray={selectedArrayAmountBase}
                setSelectedArray={setSelectedArrayAmountBase}
                selectedRowArray={selectedRowArrayAmountBase}
                setSelectedRowArray={setSelectedRowArrayAmountBase}
                indeterminate={indeterminateAmountBase}
                setIndeterminate={setIndeterminateAmountBase}
                allChecked={allCheckedAmountBase}
                setAllChecked={setAllCheckedAmountBase}
                numSelected={numSelectedAmountBase}
                changeNumSelected={(num: number) =>
                  changeNumSelectedAmountBase(num)
                }
                errorValueList={errorValueList}
                changeDataListen={changeDataListen}
                addDiscountRow={addDiscountRow}
                deleteDiscountRow={deleteDiscountRow}
                setCustomFieldValue={setCustomFieldValue}
                reviewFlg={reviewFlg}
                unitBaseOrAmountBaseValue={
                  searchValueList.unitBase_or_amountBase
                }
              />
            </div>
          </FormControl>
        </RadioGroup>
      );
    } else if (searchValueList.promo_type === "20") {
      return <>{DealerDiscount()}</>;
    } else if (searchValueList.promo_type === "40") {
      return (
        <RadioGroup
          name="SingleOrSet"
          data-testid="single_or_set"
          value={searchValueList.single_or_set}
          onChange={(e) => {
            setSearchValue("single_or_set", e.target.value);
            changeDataListen("single_or_set", e.target.value);
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            {Single()}
            {Set()}
          </FormControl>
        </RadioGroup>
      );
    } else if (searchValueList.promo_type === "50") {
      return <>{PromotionCode()}</>;
    }
  };
  return (
    <div className={classes.root}>
      <h1>{t("conditions")}</h1>
      {getComponents()}
    </div>
  );
};
