import { http } from "./Http";


// AM0402
export const GetERPUserDetailAPI = (erp_user_id: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/erp_users/${affiliate_cd}/${erp_user_id}`,
    config: config,
  };
  return http(param);
};

// AM0405
export const GetERPUserDetailListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/erp_users/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
