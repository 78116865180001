import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { SimulateSearchResultTable } from "./Simulate.SearchResult.Table";
// root
import { useHistory } from "react-router-dom";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import CalculateIcon from "@material-ui/icons/Calculate";
// route
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
import { SimulateIcon } from "../../../modules/components/icons/Icons";
// method
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      border: "1px solid #CFCFCF",
      padding: "20px",
      marginTop: "30px",
      backgroundColor: "#fff",
      margin: "0 20px",
    },
    tableTitle: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "10px",
    },
    tableTitleItem: {
      marginLeft: "20px",
      "& span": {
        minWidth: "40px",
        display: "inline-block",
      },
    },
    btnSimulate: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "20px 40px 0 40px",
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "blue",
    },
    button: {
      height: "30px",
      marginLeft: "10px",
    },
  })
);

export const SimulateSearchResult = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.simulate" });
  const {
    handleClearEvent,
    data,
    setData,
    freeGoods,
    totalAmount,
    calcAmount,
    selectedArray,
    setSelectedArray,
    selectedRowArray,
    setSelectedRowArray,
    setIndeterminate,
    indeterminate,
    changeNumSelected,
    allChecked,
    setAllChecked,
    numSelected,
    handleClick,
    handleSelectAllClick,
    freeGoodsList,
    setFreeGoodsValue,
    userPermisssionInfo,
    setSimulateDisabled,
    simulateDisaled,
    errorValueList,
    paymentTermInfo,
  } = props;
  const history = useHistory();
  const { setPromoDetailPageInfo, getPromoDetailPageInfo } = useCommonInfo();
  const promoDetailPageInfo = getPromoDetailPageInfo() as any;

  const qtyFoucs = (type: string = "") => {
    if (type === "focus") {
      setSimulateDisabled(true);
    } else {
      setSimulateDisabled(false);
    }
  };
  const buttonFocus = () => {
    console.log("buttonFocus");
  };

  const changeView = (paymentTermInfo: any) => {
    const path =
      GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
      `?promotionNo=${paymentTermInfo.promotion_no}`;
    let obj = Object.assign({}, promoDetailPageInfo, { reviewFlg: true });
    setPromoDetailPageInfo(obj);
    history.push(path);
  };
  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.tableTitle}>
          <div className={classes.tableTitleItem}>
            {t("payment_term")}:
            <span>
              {paymentTermInfo.terms_cd}&nbsp;
              {paymentTermInfo.terms_description &&
                `{${paymentTermInfo.terms_description}}`}
            </span>
          </div>

          {paymentTermInfo.promotion_no && (
            <div className={classes.tableTitleItem}>
              {t("payment_term")} {t("promo_no")}:
              <span
                className={classes.link}
                onClick={() => {
                  changeView(paymentTermInfo);
                }}
              >
                {paymentTermInfo.promotion_no}
              </span>
            </div>
          )}
          <div className={classes.tableTitleItem}>
            {t("text_total_amount")}:
            {data && data.length > 0 && (
              <span>
                {totalAmount !== 0 &&
                  totalAmount !== "" &&
                  currency(totalAmount, data[0].currency)}
              </span>
            )}
          </div>
          <div className={classes.tableTitleItem}>
            {t("text_free_goods")}:<span>{freeGoods}</span>
          </div>
        </div>
        <SimulateSearchResultTable
          data={data}
          setData={setData}
          selectedArray={selectedArray}
          setSelectedArray={setSelectedArray}
          selectedRowArray={selectedRowArray}
          setSelectedRowArray={setSelectedRowArray}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          numSelected={numSelected}
          handleClick={handleClick}
          handleSelectAllClick={handleSelectAllClick}
          changeNumSelected={(num: number) => changeNumSelected(num)}
          freeGoodsList={freeGoodsList}
          setFreeGoodsValue={setFreeGoodsValue}
          qtyFoucs={qtyFoucs}
        ></SimulateSearchResultTable>
      </div>
      <div className={classes.btnSimulate}>
        {userPermisssionInfo.csd_simulation_clear_authFlg && (
          <Button
            data-testid="btn_Clear"
            className={classes.button}
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={handleClearEvent}
          >
            {t("btn_Clear")}
          </Button>
        )}
        {userPermisssionInfo.csd_simulation_simulate_authFlg && (
          <Button
            data-testid="btn_simulate"
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={
              (data && data.length === 0) ||
              simulateDisaled ||
              errorValueList.customer_no_error !== "" ||
              errorValueList.start_date_error !== ""
            }
            onClick={() => calcAmount()}
            id="btn_simulate"
            tabIndex={0}
            startIcon={<CalculateIcon />}
            onFocus={() => buttonFocus()}
          >
            {t("btn_simulate")}
          </Button>
        )}
      </div>
    </>
  );
};
