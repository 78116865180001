import React from "react";
// UI
import { useStyles } from "../hooks/useStyles";
import TextField from "@material-ui/core/TextField";

export const TrackingNo = (props: any) => {
  const classes = useStyles();

  return (
    <tr>
      <td className={classes.titleCell}>
        <p>{props.text}</p>
      </td>
      <td className={classes.postionCell}>
        <TextField
          variant="outlined"
          value={props.tracking_no_pickup}
          className={classes.textField}
          onChange={(e) =>
            props.changeData("tracking_no_pickup", e.target.value.substr(0, 30))
          }
        />
      </td>
    </tr>
  );
};
