import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AT0801
export const GetRepairNotificationMessageListAPI = (config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  // yesterday
  const seq_date = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
  const param = {
    token: token,
    url: `${baseURL}/v1/job_notification/list/${affiliate_cd}/${seq_date}`,
    config: config,
  };
  return http(param);
};

// AT0802
export const UpdateRepairNotificationMessageAPI = (
  notification_id: any,
  config: any
) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/job_notification/${affiliate_cd}/${notification_id}`,
    method: methods.patch,
    config: config,
  };
  return http(param);
};
