import {
  layerList,
  SELECTED_CLASS,
} from "../constants/JobsDetail.PartsDrawing.constants";
import { determineDataType } from "../../../modules/common.methods";
export const init = (self_original: any) => {
  let self_pages = [] as any;
  let self_drawings = [] as any;
  let self_specificationModels = [] as any;
  let self_parts = [] as any;

  let fullParts = [] as any;
  let dataFlg = false;

  let self_technicalInformation = self_original.technicalInformation;
  let technicalInformation_movies = self_technicalInformation
    ? self_technicalInformation.movies
    : [];
  let technicalInformation_documents = self_technicalInformation
    ? self_technicalInformation.documents
    : [];
  let technicalInformation_specificationModels =
    self_original.specificationModels ? self_original.specificationModels : [];

  if (determineDataType(self_original["specificationModels"])) {
    const pages = determineDataType(self_original["drawings"])
      ? new Set(self_original["drawings"].map((m: any) => m["page"]))
      : [];

    (Array.from(pages) as number[])
      .sort((a, b) => a - b)
      .forEach((e: any) => {
        self_pages.push(e);
        const item = {
          layer1: self_original["drawings"].filter(
            (f: any) => f.page === e && f.page && f.role === "Layer1"
          ),
          layer2: self_original["drawings"].filter(
            (f: any) => f.page === e && f.page && f.role === "Layer2"
          ),
        };
        self_drawings.push(item);
      });

    self_original["specificationModels"].forEach((e: any) => {
      const specificationCode = e.specificationCode;
      e["parts"].forEach((item: any, index: number) => {
        item.sid = index;
        item.uniqueID =
          String(item.partNo) +
          ":" +
          String(item.drawingNo) +
          ":" +
          String(item.generation);
        item.item_no = item.partNo;
        item.order_qty = item.qty;
        item.description = item.name;

        if (item.setParts && item.setParts.length > 0) {
          item.setParts.forEach((subItem: any) => {
            subItem.uniqueID =
              String(subItem.partNo) +
              ":" +
              String(item.drawingNo) +
              ":" +
              String(item.partNo);
            subItem.item_no = subItem.partNo;
            subItem.order_qty = subItem.qty;
            subItem.description = subItem.name;
          });
        }

        if (technicalInformation_specificationModels.length > 0) {
          technicalInformation_specificationModels.forEach((subList: any) => {
            const sub_specificationCode = subList.specificationCode;
            if (specificationCode === sub_specificationCode) {
              if (subList.parts.length > 0) {
                let partsList = subList.parts.filter(
                  (subItem: any) =>
                    subItem.partNo === item.partNo &&
                    subItem.generation === item.generation
                );
                if (partsList.length > 0) {
                  if (partsList[0].engineeringChange) {
                    item.open = partsList[0].engineeringChange.open;
                    item.close = partsList[0].engineeringChange.close;
                  }
                }
              }
            }
          });
        }

        const list = fullParts.filter(
          (fullItem: any) => fullItem.uniqueID === item.uniqueID
        );
        if (list.length === 0) {
          fullParts.push(item);
        }
      });
      self_specificationModels.push(e);
    });

    self_specificationModels.unshift({
      specificationCode: "Full",
      parts: fullParts,
    });
    self_parts = fullParts;

    self_parts.sort(function (a: any, b: any) {
      if (a.displayId < b.displayId) {
        return -1;
      } else if (a.displayId > b.displayId) {
        return 1;
      } else {
        if (a.optionalNo < b.optionalNo) {
          return -1;
        } else if (a.optionalNo > b.optionalNo) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  } else {
    dataFlg = true;
  }

  return {
    self_original,
    self_pages,
    self_specificationModels,
    self_parts,
    self_drawings,
    technicalInformation_movies,
    technicalInformation_documents,
    dataFlg,
  };
};

export const getSvgPath = (data: any) => {
  let list: any[] = [];
  if (determineDataType(data)) {
    data.map((item: any) => {
      let obj: any = {};
      if (determineDataType(layerList)) {
        layerList.map((layer: string) => {
          const path = item[layer].filter((f: any) =>
            f.path.endsWith(".svg")
          )[0].path;
          obj[layer] = path;
        });
        list.push(obj);
      }
    });
  }
  return list;
};

export const getSvg = (url: string, path: string) => {
  return fetch(url + path, { method: "GET" })
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      const doc = new DOMParser().parseFromString(result, "image/svg+xml");
      return doc;
    });
};

// myself
export const setMyself = (id: any, flg: boolean) => {
  // For myself
  const element: any = document.getElementById("tabLayerDrawing");
  const selectedPath = element.querySelectorAll(`[data-id="${id}"`);
  if (selectedPath) {
    for (let i = 0; i < selectedPath.length; i++) {
      const selectedPathItem = selectedPath[i];
      if (flg) {
        selectedPathItem.classList.remove(SELECTED_CLASS);
      } else {
        selectedPathItem.classList.add(SELECTED_CLASS);
      }
    }
  }
};

export const handleTableMouseEvent = (id: number, type: string = "") => {
  const element: any = document.getElementById("tabLayerDrawing");
  const selectedObjList = element.querySelectorAll(`[data-id="${id}"`);
  if (selectedObjList) {
    for (let i = 0; i < selectedObjList.length; i++) {
      const selectedObjItem = selectedObjList[i];
      if (type === "mouseover") {
        selectedObjItem.classList.add("emphasize");
      } else {
        selectedObjItem.classList.remove("emphasize");
      }
    }
  }
};

export const zoom = (scale: any) => {
  const drawings = document.querySelectorAll("#tabLayerDrawing .drawing");
  drawings.forEach((f: any) => {
    if (f.style) {
      f.style.transform = `scale(${scale}, ${scale})`;
    }
  });
};

export const setTableSelectedListFun = (
  item: any,
  tableSelectedList: any,
  registerData: any
) => {
  const id = item.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);
  if (index < 0) {
    newTableData.push(id);
  } else {
    let flg = false;
    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        flg = true;
      }
    }
    if (!flg) {
      newTableData.splice(index, 1);
    }
  }

  return newTableData;
};

export const setSvgSelectedListFun = (
  item: any,
  svgSelectedList: any,
  fullParts: any,
  tableSelectedList: any,
  registerData: any
) => {
  const drawingNo = String(item.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);
  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
    setMyself(item.drawingNo, false);
  } else {
    let flg = false;
    let registerFlg = false;
    // table
    const partsList: any = getPartInfomationByDrawingNo(fullParts, item);

    if (partsList.length > 0) {
      for (let i = 0; i < partsList.length; i++) {
        const subItem = partsList[i];
        const index = tableSelectedList.indexOf(subItem.uniqueID);
        if (index >= 0 && item.uniqueID !== subItem.uniqueID) {
          flg = true;
          break;
        }
      }
    }

    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        registerFlg = true;
      }
    }

    if (!flg && !registerFlg) {
      newSvgData.splice(drawingIndex, 1);
      setMyself(item.drawingNo, true);
    }
  }
  return newSvgData;
};

export const getItemNoMessage = () => {
  return { item_no: "", description: "" };
};

export const getRegisterOptions = (option: any, region: any) => {
  if (option === 1 || option === 2) {
    return [
      {
        item_no: "11",
        description: "Arbeitswerte K",
        order_qty: 6,
        webmsi_region: region,
      },
    ];
  } else if (option === 3 || option === 4 || option === 5) {
    return [
      {
        item_no: "FRACHT1",
        description: "Transportkosten Standard",
        order_qty: 1,
        webmsi_region: region,
      },
      {
        item_no: "909990",
        description: "Reinigungs-Schmiermittel",
        order_qty: 1,
        webmsi_region: region,
      },
      {
        item_no: "10",
        description: "Arbeitswerte",
        order_qty: 7,
        webmsi_region: region,
      },
    ];
  } else {
    return [];
  }
};

export const getReisterStatus = (option: any, status: any) => {
  if (
    (option === 3 || option === 4 || option === 5) &&
    (status === "300" || status === "500")
  ) {
    return "quote";
  } else if (
    option === 1 ||
    option === 2 ||
    ((option === 3 || option === 4 || option === 5) &&
      (status === "400" || status === "410" || status === "510"))
  ) {
    return "order";
  }
  return "";
};

export const getRegisterInsertMessage = (list: any) => {
  let insertList: any = [];
  let errorList: any = [];
  list.forEach((item: any, index: any) => {
    if (!item.order_qty) {
      const error = {
        row: index + 1,
        type: "1",
      };
      errorList.push(error);
    }
    const message = setIsertResponse(item);
    insertList.push(message);
  });
  return {
    insertList: insertList,
    errorList: errorList,
  };
};

const setIsertResponse = (item: any) => {
  return {
    item_no: item.item_no,
    order_qty: getNumberDataType(item.order_qty),
    net_price: getNumberDataType(item.net_price),
    webmsi_region: item.webmsi_region,
    webmsi_spec: item.webmsi_spec,
  };
};

export const getRegisterOrderMessage = (responseList: any, list: any) => {
  let insertList: any = [];
  let errorList: any = [];
  let updateList: any = [];
  let deleteList: any = [];
  for (let i = 0; i < responseList.length; i++) {
    const item = responseList[i];
    let DeleteFlg = false;
    for (let i = 0; i < list.length; i++) {
      const subItem = list[i];
      if (item.item_no === subItem.item_no && item.sub_no === subItem.sub_no) {
        DeleteFlg = true;
        break;
      }
    }
    if (!DeleteFlg) {
      const message = {
        sub_no: item.sub_no,
        updated_date: item.updated_date,
      };
      deleteList.push(message);
    }
  }

  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    if (!item.order_qty) {
      const error = {
        row: i + 1,
        type: "1",
      };
      errorList.push(error);
    } else if (isNaN(getNumberDataType(item.order_qty) as number)) {
      const error = {
        row: i + 1,
        type: "2",
      };
      errorList.push(error);
    }

    let InsertFlg = false;
    for (let i = 0; i < responseList.length; i++) {
      const subItem = responseList[i];
      if (item.item_no === subItem.item_no && item.sub_no === subItem.sub_no) {
        let UpdateFlg = false;
        let message: any = {};
        if (
          getNumberDataType(item.order_qty) !==
          getNumberDataType(subItem.order_qty)
        ) {
          UpdateFlg = true;
          message.order_qty = getNumberDataType(item.order_qty);
        }
        if (
          getNumberDataType(item.net_price) !==
          getNumberDataType(subItem.net_price)
        ) {
          UpdateFlg = true;
          message.net_price = getNumberDataType(item.net_price);
        }
        if (item.webmsi_region !== subItem.webmsi_region) {
          UpdateFlg = true;
          message.webmsi_region = item.webmsi_region;
        }
        if (item.webmsi_spec !== subItem.webmsi_spec) {
          UpdateFlg = true;
          message.webmsi_spec = item.webmsi_spec;
        }
        if (UpdateFlg) {
          message.sub_no = subItem.sub_no;
          message.item_no = item.item_no;
          message.updated_date = subItem.updated_date;
          updateList.push(message);
        }
        InsertFlg = true;
        break;
      }
    }
    if (!InsertFlg) {
      const message = setIsertResponse(item);
      insertList.push(message);
    }
  }

  return {
    insertList: insertList,
    errorList: errorList,
    updateList: updateList,
    deleteList: deleteList,
  };
};

const getNumberDataType = (value: any) => {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    if (value !== "") {
      return Number(value);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

// get selected message information
export const getSelectedCurrectMessage = (id: any, fullParts: any) => {
  let currentMessage: any = { id: "", message: null };
  if (fullParts.length > 0) {
    const partList: any = fullParts.filter(
      (item: any) => String(item.drawingNo) === String(id)
    );
    if (partList.length > 0) {
      partList.forEach((item: any) => {
        if (currentMessage.id === "") {
          currentMessage.id = item.generation;
          currentMessage.message = item;
        }
      });
    }
  }
  return currentMessage;
};

export const handleTableDoubleClickEvent = (
  message: any,
  registerData: any,
  svgSelectedList: any,
  tableSelectedList: any,
  fullParts: any
) => {
  let newReigsterData: any = [];
  // const registerList = registerData.filter(
  //   (item: any) => item.uniqueID == message.uniqueID
  // );

  const drawingNo = String(message.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);

  const id = message.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);
  console.log("index", drawingIndex, index);

  // if (registerList.length == 0) {
  newReigsterData = registerData.slice(0);
  const messageForNewRegisterData = JSON.parse(JSON.stringify((message)));
  // 登録時はqtyを1とする。
  messageForNewRegisterData.order_qty = 1;
  newReigsterData.push(messageForNewRegisterData);

  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
  }

  if (index < 0) {
    newTableData.push(id);
  }
  setMyself(message.drawingNo, false);
  // } else {
  //   registerData.forEach((item: any) => {
  //     if (item.uniqueID !== message.uniqueID) {
  //       newReigsterData.push(item);
  //     }
  //   });

  //   const partsList: any = getPartInfomationByItemNo(fullParts, message);

  //   if (partsList.length > 0) {
  //     partsList.forEach((item: any) => {
  //       const index = newTableData.indexOf(item.uniqueID);
  //       if (index >= 0) {
  //         newTableData.splice(index, 1);

  //         const drawingNo2 = String(item.drawingNo);
  //         const drawingNoIndex2 = newSvgData.indexOf(drawingNo2);
  //         if (drawingNoIndex2 >= 0) {
  //           const partsList2: any = getPartInfomationByDrawingNo(
  //             fullParts,
  //             item
  //           );
  //           if (partsList2.length > 0) {
  //             let svgFlg = false;
  //             partsList2.forEach((subItem: any) => {
  //               const index = newTableData.indexOf(subItem.uniqueID);
  //               if (index >= 0) {
  //                 svgFlg = true;
  //               }
  //             });
  //             if (!svgFlg) {
  //               newSvgData.splice(drawingNoIndex2, 1);
  //               setMyself(drawingNo2, true);
  //             }
  //           }
  //         }
  //       }
  //     });
  //   }
  // }

  return {
    newReigsterData: newReigsterData,
    newSvgData: newSvgData,
    newTableData: newTableData,
  };
};

export const handleSvgDoubleClickEvent = (
  message: any,
  registerData: any,
  svgSelectedList: any,
  tableSelectedList: any,
  fullParts: any
) => {
  let newReigsterData: any = [];
  // const registerList = registerData.filter(
  //   (item: any) => item.uniqueID == message.uniqueID
  // );

  const drawingNo = String(message.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);

  const id = message.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);

  // if (registerList.length == 0) {
  newReigsterData = registerData.slice(0);
  const messageForNewRegisterData = JSON.parse(JSON.stringify((message)));
  // 登録時はqtyを1とする。
  messageForNewRegisterData.order_qty = 1;
  newReigsterData.push(messageForNewRegisterData);

  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
  }

  if (index < 0) {
    newTableData.push(id);
  }
  setMyself(message.drawingNo, false);
  //}
  // else {
  //   registerData.forEach((item: any) => {
  //     if (item.uniqueID !== message.uniqueID) {
  //       newReigsterData.push(item);
  //     }
  //   });

  //   const partsList: any = getPartInfomationByItemNo(fullParts, message);
  //   if (partsList.length > 0) {
  //     partsList.forEach((item: any) => {
  //       const index = newTableData.indexOf(item.uniqueID);
  //       if (index >= 0) {
  //         newTableData.splice(index, 1);
  //       }
  //     });
  //   }

  //   const partsList2: any = getPartInfomationByDrawingNo(fullParts, message);
  //   if (partsList2.length > 0) {
  //     let svgFlg = false;
  //     partsList2.forEach((item: any) => {
  //       const index = newTableData.indexOf(item.uniqueID);
  //       if (index >= 0) {
  //         svgFlg = true;
  //       }
  //     });
  //     if (!svgFlg) {
  //       newSvgData.splice(drawingIndex, 1);
  //       setMyself(message.drawingNo, true);
  //     }
  //   }
  // }

  return {
    newReigsterData: newReigsterData,
    newSvgData: newSvgData,
    newTableData: newTableData,
  };
};

export const addReigsterMessage = (
  ItemNoError: any,
  itemNoMessage: any,
  fullParts: any,
  tableSelectedList: any,
  svgSelectedList: any,
  registerData: any
) => {
  let list: any = [];
  let newTableData = tableSelectedList.slice(0);
  let newSvgData = svgSelectedList.slice(0);
  let newRegisterData: any = registerData.slice(0);

  // get item no
  if (itemNoMessage) {
    // itemNo
    const data = Object.assign({}, itemNoMessage);
    list.push(data);
    const partsList: any = getPartInfomationByItemNo(fullParts, data);

    if (partsList.length > 0) {
      partsList.forEach((item: any) => {
        const index = tableSelectedList.indexOf(item.uniqueID);
        if (index < 0) {
          newTableData.push(item.uniqueID);
        }

        const drawingNo = String(item.drawingNo);
        const drawingIndex = svgSelectedList.indexOf(String());
        if (drawingIndex < 0) {
          newSvgData.push(drawingNo);
          setMyself(item.drawingNo, false);
        }
      });
    }
    newRegisterData.push(itemNoMessage);
  }

  // get table item setTableSelectedList
  // const newTableSelectedList: any = [];
  // tableSelectedList.forEach((item: any) => {
  //   const partList: any = getPartInfomationFromUniqueID(fullParts, item);
  //   if (partList.length > 0) {
  //     // if (
  //     //   (itemNoMessage && itemNoMessage.item_no !== partList[0].item_no) ||
  //     //   !itemNoMessage
  //     // ) {
  //     newTableSelectedList.push(partList[0]);
  //     //}
  //   }
  // });

  //const newList = list.concat(newTableSelectedList);

  //let itemNoList: any = [];
  //newList.forEach((item: any) => {
  // const key: string = item.item_no;
  // let index: any = -1;
  // for (let i = 0; i < registerData.length; i++) {
  //   const registerItem: any = registerData[i];
  //   if (registerItem.item_no === key) {
  //     index = i;
  //     break;
  //   }
  // }
  // let itemNoIndex = itemNoList.indexOf(item.item_no);
  // if (index < 0 && itemNoIndex < 0) {
  //   itemNoList.push(item.item_no);
  //newRegisterData.push(item);
  //}
  //});

  return {
    newRegisterData: newRegisterData,
    newSvgData: newSvgData,
    newTableData: newTableData,
  };
};

export const deleteReigsterMessage = (
  registerData: any,
  selected: any,
  fullParts: any,
  svgSelectedList: any,
  tableSelectedList: any
) => {
  let newReigsterList: any = [];
  // register data
  registerData.forEach((item: any, index: number) => {
    const name: string =
      item.uniqueID !== undefined
        ? item.uniqueID
        : item.item_no + ":" + (item.sub_no !== undefined ? item.sub_no : 0);
    const selectedIndex = selected.indexOf(index);
    if (selectedIndex === -1) {
      newReigsterList.push(item);
    }
  });

  // selected Data Information
  let newTableData: any = [];
  let newSvgData: any = [];
  if (tableSelectedList.length > 0 && newReigsterList.length > 0) {
    tableSelectedList.forEach((tableItem: any) => {
      const _item_no = tableItem.split(":")[0];
      console.log("deleteReigsterMessage _item_no", _item_no);
      newReigsterList.forEach((subItem: any) => {
        if (_item_no === subItem.item_no) {
          newTableData.push(tableItem);
        }
      });
    });
  }

  if (svgSelectedList.length > 0) {
    svgSelectedList.forEach((svgItem: any) => {
      let flag = false;
      const _itemList = getPartInfomationByDrawingNo(fullParts, {
        drawingNo: svgItem,
      });
      if (_itemList.length > 0 && newReigsterList.length > 0) {
        _itemList.forEach((_item: any) => {
          newReigsterList.forEach((_newItem: any) => {
            if (_newItem.item_no === _item.item_no) {
              flag = true;
            }
          });
        });
      }
      if (flag) {
        setMyself(svgItem, false);
        newSvgData.push(svgItem);
      } else {
        setMyself(svgItem, true);
      }
    });
  }

  return {
    newTableData: newTableData,
    newSvgData: newSvgData,
    newReigsterList: newReigsterList,
  };
};

export const handleClickEventBySvgFunc = (
  item: any,
  tableSelectedList: any,
  registerData: any,
  svgSelectedList: any
) => {
  const id = item.uniqueID;
  const index: number = tableSelectedList.indexOf(id);
  let newTableData: any = tableSelectedList.slice(0);
  if (index < 0) {
    newTableData.push(id);
  } else {
    let flg = false;
    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        flg = true;
      }
    }
    if (!flg) {
      newTableData.splice(index, 1);
    }
  }

  const drawingNo = String(item.drawingNo);
  const drawingIndex = svgSelectedList.indexOf(drawingNo);
  let newSvgData: any = svgSelectedList.slice(0);
  if (drawingIndex < 0) {
    newSvgData.push(drawingNo);
    setMyself(item.drawingNo, false);
  } else {
    let flg = false;
    let registerFlg = false;

    // svg
    if (index < 0) {
      flg = true;
    }

    for (let i = 0; i < registerData.length; i++) {
      const subItem = registerData[i];
      if (item.item_no === subItem.item_no) {
        registerFlg = true;
      }
    }

    if (!flg && !registerFlg) {
      newSvgData.splice(drawingIndex, 1);
      setMyself(item.drawingNo, true);
    }
  }
  return {
    newTableData: newTableData,
    newSvgData: newSvgData,
  };
};

export const setDefaultSVGandPartsFunc = (list: any, partsList: any) => {
  let newTableData: any = [];
  let newSvgData: any = [];
  list.forEach((item: any) => {
    partsList.forEach((subItem: any) => {
      if (item.item_no === subItem.item_no) {
        const id = subItem.uniqueID;
        const index = newTableData.indexOf(id);
        if (index < 0) {
          newTableData.push(subItem.uniqueID);
        }

        const drawingNo = String(subItem.drawingNo);
        const drawingIndex = newSvgData.indexOf(drawingNo);
        if (drawingIndex < 0) {
          newSvgData.push(drawingNo);
          setMyself(subItem.drawingNo, false);
        }
      }
      if (subItem.setParts && subItem.setParts.length > 0) {
        subItem.setParts.forEach((subItem2: any) => {
          if (item.item_no === subItem2.item_no) {
            const id = subItem2.uniqueID;
            const index = newTableData.indexOf(id);
            if (index < 0) {
              newTableData.push(subItem2.uniqueID);
            }

            const drawingNo = String(subItem2.drawingNo);
            const drawingIndex = newSvgData.indexOf(drawingNo);
            if (drawingIndex < 0) {
              newSvgData.push(drawingNo);
              setMyself(subItem2.drawingNo, false);
            }
          }
        });
      }
    });
  });
  return {
    newTableData: newTableData,
    newSvgData: newSvgData,
  };
};

const getPartInfomationByItemNo = (fullParts: any, message: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (message.item_no === item.item_no) {
      list.push(item);
    }

    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (message.item_no === partsItem.item_no) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

const getPartInfomationByDrawingNo = (fullParts: any, message: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (Number(message.drawingNo) === Number(item.drawingNo)) {
      list.push(item);
    }
    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (Number(message.drawingNo) === Number(partsItem.drawingNo)) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

const getPartInfomationByUniqueID = (fullParts: any, message: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (message.UniqueID === item.UniqueID) {
      list.push(item);
    }

    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (message.UniqueID === partsItem.UniqueID) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

const getPartInfomationFromUniqueID = (fullParts: any, uniqueID: any) => {
  let list: any = [];
  fullParts.forEach((item: any) => {
    if (item.uniqueID === uniqueID) {
      list.push(item);
    }
    if (item.setParts && item.setParts.length > 0) {
      item.setParts.forEach((partsItem: any) => {
        if (partsItem.uniqueID === uniqueID) {
          list.push(partsItem);
        }
      });
    }
  });
  return list;
};

export const getRepairCompleteDisableFunc = (
  registerData: any,
  oldData: any
) => {
  let flg = false;
  if (registerData.length > 0) {
    registerData.forEach((item: any) => {
      if (Number(item.order_qty) !== Number(item.allocated_qty)) {
        flg = true;
      }
    });
    // new Data VS old Data
    if (!flg) {
      for (let i = 0; i < oldData.length; i++) {
        const _old_item: any = oldData[i];
        let _newItem = registerData.filter(
          (subItem: any) =>
            subItem.item_no === _old_item.item_no &&
            subItem.sub_no === _old_item.sub_no
        );
        if (_newItem.length > 0) {
          flg = !(
            Number(_newItem[0].order_qty) === Number(_old_item.order_qty) &&
            Number(_newItem[0].net_price) === Number(_old_item.net_price)
          );
        } else {
          flg = true;
        }
        if (flg) {
          break;
        }
      }
    }
  } else {
    flg = true;
  }
  return flg;
};
