import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailSearchControlCSearch } from "./PromotionDetail.CSearch";
import { PromotionDetailCustomerTable } from "./PromotionDetail.Customer.Table";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "20px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
    },
  })
);

export const Customer = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const {
    searchValueList,
    setSearchValue,

    oldData,
    setOldData,

    defaultValueList,
    setDefaultValue,
    customerEditList,
    setCustomerEditList,
    onBlurOfCustomerNo,
    onFocusOfCustomerNo,
    errorValueList,
    setErrorValueList,
    setErrorValue,
    setHasSeqNoRow,
    hasSeqNoRow,
    numSelected,
    setNumSelected,
    selectedArray,
    setSelectedArray,
    selectedRowArray,
    setSelectedRowArray,
    indeterminate,
    setIndeterminate,
    allChecked,
    setAllChecked,
    reviewFlg,
    onCustomerNoFlag,
    setAlertShow,
    setAlertMessage,
    setAlertType,
    salesAnalysisList,
  } = props;


  const classes = useStyles();
  const [customerNoIndex, setCustomerNoIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  // loading
  const { getHotKey, setHotKey } = useCommonInfo();

  let hotKeyInfo = getHotKey() as any;

  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelected(num);
  };

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  useEffect(() => {
    if (hotKeyInfo.handleF2PromotionDetailCustomerSearch) {
      handleCSearch(lastIndex);
    }
    setHotKey("handleF2PromotionDetailCustomerSearch", false);
  }, [hotKeyInfo.handleF2PromotionDetailCustomerSearch]);

  const handleCSearch = (index: number) => {
    setCSearchOpen(true);
    setCustomerNoIndex(index);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      let message = com("MS0085", {
        param1: row.customer_no,
      });
      setAlertShow(true);
      setAlertMessage(message);
      setAlertType("warning");
    } else {
      setAlertShow(false);
      setAlertMessage("");
      setAlertType("");
    }
    const editListAry = JSON.parse(JSON.stringify(customerEditList));
    editListAry[customerNoIndex] = true;
    setCustomerEditList(editListAry);

    const setCustomerField = JSON.parse(
      JSON.stringify(searchValueList.promotion_customer)
    );
    setCustomerField[customerNoIndex]["customer_no"] = row.customer_no;
    setCustomerField[customerNoIndex]["customer_name"] = row.customer_name;
    setCustomerField[customerNoIndex]["discount_code"] =
      row.customer_discount_cd;
    setCustomerField[customerNoIndex]["customer_type"] = row.customer_type;
    setCustomerField[customerNoIndex]["csac1"] = row.csac1;
    setCustomerField[customerNoIndex]["csac2"] = row.csac2;
    setCustomerField[customerNoIndex]["csac3"] = row.csac3;
    setCustomerField[customerNoIndex]["csac4"] = row.csac4;
    setCustomerField[customerNoIndex]["csac5"] = row.csac5;
    setCustomerField[customerNoIndex]["csac6"] = row.csac6;
    setCustomerField[customerNoIndex]["csac7"] = row.csac7;
    setCustomerField[customerNoIndex]["csac8"] = row.csac8;
    setCustomerField[customerNoIndex]["csac9"] = row.csac9;
    setCustomerField[customerNoIndex]["csac10"] = row.csac10;
    setCustomerField[customerNoIndex]["promotion_region"] = row.promotion_region;
    setSearchValue("promotion_customer", setCustomerField);

    const setErrorField = JSON.parse(
      JSON.stringify(errorValueList.promotion_customer)
    );
    setErrorField[customerNoIndex]["customer_no_error"] = "";
    setErrorValue("promotion_customer", setErrorField);
  };

  return (
    <div className={classes.root}>
      <h1>{t("customer")}</h1>
      <PromotionDetailCustomerTable
        data={searchValueList.promotion_customer}
        setData={setSearchValue}
        oldData={oldData.promotion_customer}
        setOldData={setOldData}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedRowArray={selectedRowArray}
        setSelectedRowArray={setSelectedRowArray}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        numSelected={numSelected}
        changeNumSelected={(num: number) => changeNumSelected(num)}
        handleCSearch={handleCSearch}
        defaultValueList={defaultValueList.promotion_customer}
        setDefaultValue={setDefaultValue}
        customerEditList={customerEditList}
        setCustomerEditList={setCustomerEditList}
        onBlurOfCustomerNo={onBlurOfCustomerNo}
        onFocusOfCustomerNo={onFocusOfCustomerNo}
        errorValueList={errorValueList}
        setErrorValueList={setErrorValueList}
        setHasSeqNoRow={setHasSeqNoRow}
        hasSeqNoRow={hasSeqNoRow}
        reviewFlg={reviewFlg}
        onCustomerNoFlag={onCustomerNoFlag}
        setLastIndex={setLastIndex}
        salesAnalysisList={salesAnalysisList}
        changeDataListen={props.changeDataListen}
      />

      <PromotionDetailSearchControlCSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </div>
  );
};
