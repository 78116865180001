import React from "react";
// hooks
import { useStyles } from "../hooks/Summary.Changes.styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hooks
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// methods
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { getCodeNameByNumber, i18text } from "../../../modules/common.methods";

export const SummaryChangesHistoryTbody = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({
    defaultPath: "common.constants",
  });
  const { dataList, errorType } = props;

  const editDateTime = (dateTime: string) => {
    let editedDateTime = "";
    if (dateTime && dateTime !== "") {
      let dateTimeLong = new Date(dateTime).getTime();
      //editedDateTime = date(dateTimeLong) + " " + time(dateTimeLong);
      editedDateTime = date(dateTimeLong);
    }
    return editedDateTime;
  };

  const editDescriptionByUpdateType = (row: any, value: any) => {
    let _value: any = value;

    // 金額
    if (
      row.name === "total_amount" ||
      row.name === "net_price" ||
      row.name === "amount"
    ) {
      if (!isNaN(value)) {
        _value = value ? currency(Number(value), props.currency) : "-";
      }
    }

    // weight
    if (row.name === "weight") {
      if (Number(value) !== 0) {
        _value += " kg";
      } else {
        _value = "";
      }
    }

    // device_runtime
    if (row.name === "device_runtime") {
      if (Number(value) !== 0) {
        _value += " h";
      } else {
        _value = "";
      }
    }

    if (
      row.name === "transport_way_pick_up" ||
      row.name === "transport_way_return" ||
      row.name === "transport_cost_flg" ||
      row.name === "attention_check_flg" ||
      row.name === "transport_cost_flg" ||
      row.name === "pre_approve_flg"
    ) {
      _value = getCodeNameByNumber(Number(value), com("freeGoods_flg"));
    }

    // error type
    if (row.name === "error_type") {
      let arrayErrorType = props.errorType;
      if (Array.isArray(arrayErrorType) && value && value !== "") {
        for (let index in arrayErrorType) {
          if (arrayErrorType[index].error_type.toString() === value) {
            _value = arrayErrorType[index].error_description;
            break;
          }
        }
      }
    }

    return _value;
  };

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody className={classes.tbody}>
      {dataList &&
        setSortData(dataList, "desc", "created_date").map(
          (row: any, index: number) => {
            return (
              <TableRow hover tabIndex={-1} key={index}>
                <TableCell className={classes.dateCell}>
                  {editDateTime(row.created_date)}
                </TableCell>
                <TableCell className={classes.dateCell}>
                  {i18text(com, "messages_table_name.", row.description)}
                </TableCell>
                <TableCell className={classes.DescriptionCell}>
                  {editDescriptionByUpdateType(row, row.before_value)}
                </TableCell>
                <TableCell className={classes.DescriptionCell}>
                  {editDescriptionByUpdateType(row, row.after_value)}
                </TableCell>
              </TableRow>
            );
          }
        )}
    </TableBody>
  );
};
