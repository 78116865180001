import React, { useState, useCallback, useEffect } from "react";
// UI
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { CustomerSearch } from "../../../modules/components/customerSearch/CSearch";

// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import {
  checkMaxLength,
  isEmptyStringOrNumber,
} from "../../../modules/hooks/UseValication";
// api
import api from "../../../modules/api/Api";
// hook
import { useStyles } from "../hooks/control.useStyles";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";

export const ClaimSearchControl = (props: any) => {
  const { getCommonInfo, getCommonConfig } = useCommonInfo();
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const commonInfo = getCommonInfo() as any;
  let config = getCommonConfig() as any;
  const {
    alertMessageList,
    setAlertMessageList,
    searchValueList,
    setSearchValue,
    setSearchValueList,
    handleSearchEvent,
    handleClearEvent,
    conditionSearch,
    isAllErrMsgmpty,
  } = props;

  // check date from
  const checkEntryDateFrom = (date: any) => {
    if (
      searchValueList.entry_date_to > 0 &&
      date > searchValueList.entry_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_entry_date_from"),
        endDate: t("txt_entry_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        entry_date_from_error: message,
        entry_date_from: date,
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        entry_date_from_error: "",
        entry_date_from: date,
      });
      setSearchValueList(valueList);
    }
  };

  // check date to
  const checkEntryDateTo = (date: any) => {
    if (
      searchValueList.entry_date_from > 0 &&
      searchValueList.entry_date_from > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_entry_date_from"),
        endDate: t("txt_entry_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        entry_date_from_error: message,
        entry_date_to: date,
        entry_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        entry_date_from_error: "",
        entry_date_to: date,
        entry_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  // approve date from
  const checkApprovedDateFrom = (date: any) => {
    if (
      searchValueList.approved_date_to > 0 &&
      date > searchValueList.approved_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_approved_date_from"),
        endDate: t("txt_approved_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        approved_date_from_error: message,
        approved_date_from: date,
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        approved_date_from_error: "",
        approved_date_from: date,
      });
      setSearchValueList(valueList);
    }
  };

  // approve date to
  const checkApprovedDateTo = (date: any) => {
    if (
      searchValueList.entry_date_from > 0 &&
      searchValueList.entry_date_from > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_approved_date_from"),
        endDate: t("txt_approved_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        approved_date_from_error: message,
        approved_date_to: date,
        approved_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        approved_date_from_error: "",
        approved_date_to: date,
        approved_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  // check model is exsit
  const masterExitOfModelNo = () => {
    let isExisted = false;
    let items = commonInfo.itemList;
    if (searchValueList.model_no !== "" && items.length > 0) {
      for (let index in items) {
        if (
          items[index].model_no.toString().toUpperCase() ===
          searchValueList.model_no.toString().toUpperCase()
        ) {
          isExisted = true;
          let modelNoByDB = items[index].model_no.toString();
          setSearchValue("model_no", modelNoByDB);
          break;
        }
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  // check item is exsit
  const masterExitOfItemNo = () => {
    let isExisted = false;
    let items = commonInfo.itemBaseList;
    if (searchValueList.parts_used !== "" && items.length > 0) {
      for (let index in items) {
        if (
          items[index].item_no.toString().toUpperCase() ===
          searchValueList.parts_used.toString().toUpperCase()
        ) {
          isExisted = true;
          let itemNoByDB = items[index].item_no.toString();
          console.log("parts_used", itemNoByDB);
          setSearchValue("parts_used", itemNoByDB);
          break;
        }
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  // focus out customer No.
  const onBlurOfCustomerNo = () => {
    setSearchValue("customer_no_error", "");
    if (isEmptyStringOrNumber(searchValueList.customer_no)) {
      const customerValue = {
        customer_name: "",
        customer_no_error: "",
      };
      const valueList = Object.assign({}, searchValueList, customerValue);
      setSearchValueList(valueList);
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(searchValueList.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("txt_customer_no"),
            });
            setSearchValue("customer_no_error", message);
          } else {
            if (response.customer_closed === true) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages.MS0085", {
                  param1: response.customer_no,
                }),
                alertType: "warning",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            }
            const customerValue = {
              customer_name: response.customer_name,
              // customer_no: response.customer_no,
              customer_no_error: "",
            };
            const valueList = Object.assign({}, searchValueList, customerValue);
            setSearchValueList(valueList);
          }
        });
    }
  };

  // focus out model No.
  const onBlurOfModelNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMaxLength(e.target.value, 35)) {
      let message = com("messages.MS0002", {
        param1: t("txt_model_no"),
        param2: 35,
      });
      setSearchValue("model_no_error", message);
    } else {
      setSearchValue("model_no_error", "");
    }
  };

  // focus out item No.(parts_used)
  const onBlurOfItemNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMaxLength(e.target.value, 35)) {
      let message = com("messages.MS0002", {
        param1: t("txt_parts_used"),
        param2: 35,
      });
      setSearchValue("parts_used_error", message);
    } else if (!masterExitOfItemNo()) {
      let message = com("messages.MS0004", {
        param1: t("txt_parts_used_message"),
      });
      setSearchValue("parts_used_error", message);
    } else {
      setSearchValue("parts_used_error", "");
    }
  };

  //focus out date from
  const onBlurOfEntryDateFrom = () => {
    console.log(
      "onBlurOf EntryDate from",
      searchValueList.approved_date_from,
      searchValueList.entry_date_from
    );
    if (
      searchValueList.entry_date_to > 0 &&
      searchValueList.entry_date_from > 0 &&
      searchValueList.entry_date_from > searchValueList.entry_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_entry_date_from"),
        endDate: t("txt_entry_date_to"),
      });
      setSearchValue("entry_date_from_error", message);
    } else {
      setSearchValue("entry_date_from_error", "");
    }
  };

  //focus out date to
  const onBlurOfEntryDateTo = () => {
    console.log(
      "onBlurOf EntryDate to",
      searchValueList.approved_date_from,
      searchValueList.entry_date_from
    );
    if (
      searchValueList.entry_date_to > 0 &&
      searchValueList.entry_date_from > 0 &&
      searchValueList.entry_date_from > searchValueList.entry_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_entry_date_from"),
        endDate: t("txt_entry_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        entry_date_from_error: message,
        entry_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        entry_date_from_error: "",
        entry_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  //focus out approve date from
  const onBlurOfApprovedDateFrom = () => {
    console.log(
      "onBlurOf ApprovedDate from",
      searchValueList.approved_date_from,
      searchValueList.approved_date_to
    );
    if (
      searchValueList.approved_date_to > 0 &&
      searchValueList.approved_date_from > 0 &&
      searchValueList.approved_date_from > searchValueList.approved_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_approved_date_from"),
        endDate: t("txt_approved_date_to"),
      });
      setSearchValue("approved_date_from_error", message);
    } else {
      setSearchValue("approved_date_from_error", "");
    }
  };

  //focus out approve date to
  const onBlurOfApprovedDateTo = () => {
    console.log(
      "onBlurOf ApprovedDate to",
      searchValueList.approved_date_from,
      searchValueList.approved_date_to
    );
    if (
      searchValueList.approved_date_to > 0 &&
      searchValueList.approved_date_from > 0 &&
      searchValueList.approved_date_from > searchValueList.approved_date_to
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_approved_date_from"),
        endDate: t("txt_approved_date_to"),
      });
      let valueList = Object.assign({}, searchValueList, {
        approved_date_from_error: message,
        approved_date_to_error: "",
      });
      setSearchValueList(valueList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        approved_date_from_error: "",
        approved_date_to_error: "",
      });
      setSearchValueList(valueList);
    }
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    if (row.customer_closed === true) {
      const obj: any = {
        no: alertMessageList.length,
        message: com("messages.MS0085", {
          param1: row.customer_no,
        }),
        alertType: "warning",
      };
      let alertMessageListNew: any = [obj];
      setAlertMessageList(alertMessageListNew);
    }
    const customerValue = {
      customer_name: row.customer_name,
      customer_no: row.customer_no,
    };
    const valueList = Object.assign({}, searchValueList, customerValue);
    setSearchValueList(valueList);
  };

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchValue("select_status", event.target.value);
  };

  const getOptionLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const getOptionLabel2 = (option: any) => option.model_no || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  const getOptionLabel3 = (option: any) => option.item_no || "";
  const filterOptions3 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  // entry date from
  const [entryDateFromFlag, setEntryDateFromFlag] = useState(true);
  const onEntryDateFromClose = () => {
    setEntryDateFromFlag(false);
  };
  const onEntryDateFromOpen = () => {
    setEntryDateFromFlag(true);
  };
  useEffect(() => {
    if (!entryDateFromFlag) {
      onBlurOfEntryDateFrom();
    }
  }, [entryDateFromFlag]);

  // entry date to
  const [entryDateToFlag, setEntryDateToFlag] = useState(true);
  const onEntryDateToClose = () => {
    setEntryDateToFlag(false);
  };
  const onEntryDateToOpen = () => {
    setEntryDateToFlag(true);
  };
  useEffect(() => {
    if (!entryDateToFlag) {
      onBlurOfApprovedDateTo();
    }
  }, [entryDateToFlag]);

  // approved date from
  const [approvedDateFromFlag, setApprovedDateFromFlag] = useState(true);
  const onApprovedDateFromClose = () => {
    setApprovedDateFromFlag(false);
  };
  const onApprovedDateFromOpen = () => {
    setApprovedDateFromFlag(true);
  };
  useEffect(() => {
    if (!approvedDateFromFlag) {
      onBlurOfApprovedDateFrom();
    }
  }, [approvedDateFromFlag]);

  // approved date to
  const [approvedDateToFlag, setApprovedDateToFlag] = useState(true);
  const onApprovedDateToClose = () => {
    setApprovedDateToFlag(false);
  };
  const onApprovedDateToOpen = () => {
    setApprovedDateToFlag(true);
  };
  useEffect(() => {
    if (!approvedDateToFlag) {
      onBlurOfApprovedDateTo();
    }
  }, [approvedDateToFlag]);

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <div className={classes.searchCondition}>
          <dl>
            <dt>{t("txt_claim_no")}</dt>
            <dd className={classes.claimDd} data-testid="search_claim_no">
              <TextField
                value={searchValueList.claim_no}
                id="search_claim_no"
                variant="outlined"
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^\d]/g, "")
                    .substr(0, 10);
                  return setSearchValue("claim_no", value);
                }}
                error={searchValueList.claim_no_error !== ""}
                helperText={searchValueList.claim_no_error}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    conditionSearch(searchValueList);
                  }
                }}
              />
            </dd>
          </dl>
          <dl>
            <dt>{t("txt_customer_no")}</dt>
            <dd className={classes.claimDd} data-testid="search_customer_no">
              <Autocomplete
                id="customer_no"
                freeSolo
                disableClearable
                options={commonInfo.customerList}
                getOptionLabel={getOptionCustomerLabel}
                filterOptions={filterCustomerOptions}
                inputValue={String(searchValueList.customer_no)}
                value={String(searchValueList.customer_no)}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      minWidth: "300px",
                    }}
                    placement="bottom-start"
                  />
                )}
                getOptionSelected={(option: any, value: any) => {
                  let isSelected = false;
                  if (option.customer_no && searchValueList.customer_no) {
                    isSelected =
                      option.customer_no.toString().toUpperCase() ===
                      searchValueList.customer_no.toString().toUpperCase();
                  }
                  return isSelected;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    error={searchValueList.customer_no_error !== ""}
                    helperText={searchValueList.customer_no_error}
                    onBlur={onBlurOfCustomerNo}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        conditionSearch(searchValueList);
                      }
                    }}
                  />
                )}
                onChange={(event: object, value: any, reason: string) => {
                  if (reason === "select-option") {
                    setSearchValue("customer_no", value.customer_no.toString());
                  }
                }}
                onInputChange={(
                  event: object,
                  value: string,
                  reason: string
                ) => {
                  if (reason === "input" || reason === "clear") {
                    const val = value.substr(0, 8);
                    setSearchValue("customer_no", val);
                  }
                }}
              />
              <IconButton
                className={classes.iconButton1}
                onClick={handleCSearch}
                id="btn_claim_list_search"
                tabIndex={0}
                //onFocus={() => buttonFocus()}
              >
                <SearchIcon />
              </IconButton>
            </dd>
          </dl>
          <dl>
            <dd className={classes.claimDd}>
              <p>{searchValueList.customer_name}</p>
            </dd>
          </dl>
          <dl>
            <dt>{t("txt_customer_po_no")}</dt>
            <dd className={classes.claimCustomerPONo}>
              <TextField
                value={searchValueList.customer_po_no}
                variant="outlined"
                onChange={(e) => {
                  setSearchValue("customer_po_no", e.target.value);
                }}
                error={searchValueList.customer_po_no_error !== ""}
                helperText={searchValueList.customer_po_no_error}
                inputProps={{ maxLength: 30 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    conditionSearch(searchValueList);
                  }
                }}
              />
            </dd>
          </dl>
          <dl>
            <dt>{t("txt_model_no")}</dt>
            <dd className={classes.claimModelNo}>
              <Autocomplete
                className={classes.autoComplete}
                id="model_no"
                freeSolo
                disableClearable
                options={commonInfo.itemList}
                getOptionLabel={getOptionLabel2}
                filterOptions={filterOptions2}
                inputValue={searchValueList.model_no}
                value={searchValueList.model_no}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      minWidth: "200px",
                    }}
                    placement="bottom-start"
                  />
                )}
                getOptionSelected={(option: any, value: any) =>
                  option.model_no.toString().toUpperCase() ===
                  searchValueList.model_no.toString().toUpperCase()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    error={searchValueList.model_no_error !== ""}
                    helperText={searchValueList.model_no_error}
                    onBlur={onBlurOfModelNo}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        conditionSearch(searchValueList);
                      }
                    }}
                  />
                )}
                onChange={(event: object, value: any, reason: string) => {
                  if (reason === "select-option") {
                    console.log("reason1");
                    setSearchValue("model_no", value.model_no);
                  }
                }}
                onInputChange={(
                  event: object,
                  value: string,
                  reason: string
                ) => {
                  if (reason === "input" || reason === "clear") {
                    console.log("reason2");
                    setSearchValue("model_no", value);
                  }
                }}
              />
            </dd>
          </dl>
          <dl>
            <dt>{t("txt_select_status")}</dt>
            <dd>
              <FormControl variant="outlined">
                <Select
                  multiple
                  id="select_status"
                  className={classes.searchControlSelect}
                  value={searchValueList.select_status}
                  onChange={handleChange}
                  renderValue={() => ""}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {Array.isArray(com("wco_status")) ? (
                    com("wco_status").map((status: any) => (
                      <MenuItem key={status.code} value={status.code}>
                        <Checkbox
                          color="primary"
                          checked={
                            searchValueList.select_status.indexOf(status.code) >
                            -1
                          }
                        />
                        <ListItemText primary={status.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
          <dl>
            <dt>{t("txt_postal_code")}</dt>
            <dd className={classes.claimDd}>
              <TextField
                value={searchValueList.postal_code}
                variant="outlined"
                onChange={(e) => {
                  setSearchValue("postal_code", e.target.value);
                }}
                error={searchValueList.postal_code_error !== ""}
                helperText={searchValueList.postal_code_error}
                inputProps={{ maxLength: 15 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    conditionSearch(searchValueList);
                  }
                }}
              />
            </dd>
          </dl>
          <dl className={classes.datePickerArea}>
            <dt>
              <span>{t("txt_entry_date")}</span>
            </dt>
            <dd className={classes.datePickerContainer}>
              <DatePicker
                value={searchValueList.entry_date_from}
                onChange={(date: any) =>
                  setSearchValue("entry_date_from", date)
                }
                onAccept={(date: any) =>
                  checkEntryDateFrom(new Date(date).setHours(0, 0, 0, 0))
                }
                className={classes.datePicker}
                error={searchValueList.entry_date_from_error !== ""}
                helperText={searchValueList.entry_date_from_error}
                onBlur={onBlurOfEntryDateFrom}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.entry_date_from_error &&
                    error !== ""
                  ) {
                    setSearchValue("entry_date_from_error", error);
                  }
                }}
                onOpen={onEntryDateFromOpen}
                onClose={onEntryDateFromClose}
              />
              <span className={classes.datePickerInterval}></span>
              <DatePicker
                value={searchValueList.entry_date_to}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setSearchValue(
                      "entry_date_to",
                      new Date(date).setHours(23, 59, 59, 999)
                    );
                  } else {
                    setSearchValue("entry_date_to", date);
                  }
                }}
                onAccept={(date: any) =>
                  checkEntryDateTo(new Date(date).setHours(23, 59, 59, 999))
                }
                className={classes.datePicker}
                error={searchValueList.entry_date_to_error !== ""}
                helperText={searchValueList.entry_date_to_error}
                onBlur={onBlurOfEntryDateTo}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.entry_date_to_error &&
                    error !== ""
                  ) {
                    setSearchValue("entry_date_to_error", error);
                  }
                }}
                onOpen={onEntryDateToOpen}
                onClose={onEntryDateToClose}
              />
            </dd>
          </dl>
          <dl className={classes.datePickerArea}>
            <dt>
              <span>{t("txt_approved_date")}</span>
            </dt>
            <dd className={classes.datePickerContainer}>
              <DatePicker
                value={searchValueList.approved_date_from}
                onChange={(date: any) =>
                  setSearchValue("approved_date_from", date)
                }
                onAccept={(date: any) =>
                  checkApprovedDateFrom(new Date(date).setHours(0, 0, 0, 0))
                }
                className={classes.datePicker}
                error={searchValueList.approved_date_from_error !== ""}
                helperText={searchValueList.approved_date_from_error}
                onBlur={onBlurOfApprovedDateFrom}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.approved_date_from_error &&
                    error !== ""
                  ) {
                    setSearchValue("approved_date_from_error", error);
                  }
                }}
                onOpen={onApprovedDateFromOpen}
                onClose={onApprovedDateFromClose}
              />
              <span className={classes.datePickerInterval}></span>
              <DatePicker
                value={searchValueList.approved_date_to}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setSearchValue(
                      "approved_date_to",
                      new Date(date).setHours(23, 59, 59, 999)
                    );
                  } else {
                    setSearchValue("approved_date_to", date);
                  }
                }}
                onAccept={(date: any) =>
                  checkApprovedDateTo(new Date(date).setHours(23, 59, 59, 999))
                }
                className={classes.datePicker}
                error={searchValueList.approved_date_to_error !== ""}
                helperText={searchValueList.approved_date_to_error}
                onBlur={onBlurOfApprovedDateTo}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.approved_date_to_error &&
                    error !== ""
                  ) {
                    setSearchValue("approved_date_to_error", error);
                  }
                }}
                onOpen={onApprovedDateToOpen}
                onClose={onApprovedDateToClose}
              />
            </dd>
          </dl>
          <dl>
            <dt>{t("summary_pre_approve")}</dt>
            <dd>
              <FormControl variant="outlined">
                <Select
                  className={classes.searchControlSelect}
                  value={searchValueList.select_pre_approve}
                  onChange={(e: any) =>
                    setSearchValue("select_pre_approve", e.target.value)
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {Array.isArray(com("pre_approved")) ? (
                    com("pre_approved").map((item: any) => (
                      <MenuItem key={item.code} value={item.code}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
        </div>
        <div className={classes.buttonArea}>
          <Button
            className={classes.buttonCondition}
            disabled={!isAllErrMsgmpty()}
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            onClick={(event) => handleSearchEvent(event)}
            data-testid="search_button"
          >
            {t("btn_search")}
          </Button>
        </div>
        <div className={classes.buttonArea} data-testid="clear_button">
          <Button
            className={classes.buttonCondition}
            variant="contained"
            onClick={handleClearEvent}
            startIcon={<RefreshIcon />}
          >
            {t("btn_Clear")}
          </Button>
        </div>
        <CustomerSearch
          CSearchOpen={CSearchOpen}
          CSearchClose={CSearchClose}
          handleSend={(row: any) => {
            getCustomerRow(row);
          }}
        />
      </div>
    </form>
  );
};
