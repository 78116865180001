import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "& .makeStyles-paper-22": {
        padding: "0px 16px",
        marginTop: "16px",
      },
    },
    paper: {
      padding: theme.spacing(2),
      backgroundColor: "#F9F9F9",
      boxShadow: "none",
    },
    history_area: {
      width: "50%",
      //width: "100%",
      // maxWidth: "1000px",
      padding: "40px 20px 0 20px",
    },
    history_container: {
      display: "flex",
      alignContent: "space-between",
    },
    change_history_area: {
      width: "50%",
      padding: "40px 20px 0 20px",
    },
    boxTitle: {
      fontSize: "20px",
      marginTop: "20px",
      borderLeft: "3px solid #8DA1B9",
      borderBottom: "3px solid #8DA1B9",
      paddingLeft: "5px",
    },
  })
);
