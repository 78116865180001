import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";

// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// api
import api from "../../modules/api/Api";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// methods
import {
  getApiResultStatus,
  downloadToImage,
} from "../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    messageArea: {
      textAlign: "center",
    },
    message: {
      paddingTop: "10px",
      "& > div": {
        width: "100%",
      },
    },
  })
);

export const Page: FC = () => {
  const classes = useStyles();
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  // language
  const { t } = useLocale({ defaultPath: "modules.components.TakumiMobile" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });

  // loading
  const { setCommonInfo, getCommonConfig } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  const handleDownload = () => {
    apiLoading(true);
    api
      .getAppFile(config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("response", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          apiLoading(false);
        } else {
          const url: any = response ? response.content : "";
          if (url) {
            api
              .getDownloadFile(url)
              .then(({ code, response }: { code: any; response: any }) => {
                apiLoading(false);
                if (!getApiResultStatus(code)) {
                  errorMessage(response);
                } else {
                  let url = window.URL.createObjectURL(response);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = "Takumi.apk";
                  a.click();
                }
              });
          } else {
            apiLoading(false);
          }
        }
      });
  };
  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = com(response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
  };
  const contents = (
    <div className={classes.container}>
      <div className={classes.messageArea}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={handleDownload}
        >
          {t("download")}
        </Button>
        <div className={classes.message}>
          <AlertMesssageBox
            show={alertShow}
            message={alertMessage}
            type={alertType}
          />
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
