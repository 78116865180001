import React, { useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// hook
import { useStyles } from "../hooks/editor.useStyles";
// constants
import { TabKey } from "../MasterMaintenance.constants";

import { checkMaxLength } from "../../../modules/hooks/UseValication";

export const MasterMaintenanceItemMasterEditor = (props: any) => {
  const classes = useStyles();
  const {
    changeData,
    editData,
    handleBack,
    handleSubmit,
    serviceCenterDataList,
    itemServiceCenterCheckedList,
  } = props;
  const { model_no, description, memo } = editData;
  const [disabledFlg, setDisabledFlg] = React.useState(true);

  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.itemMaster",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const [memorandumErrorFlg, setMemorandumErrorFlg] = React.useState(false);
  const [memorandumErrorMessage, setMemorandumErrorMessage] =
    React.useState("");

  useEffect(() => {
    if (!checkMaxLength(memo, 2048) || memorandumErrorFlg) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
  });

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("memo" === itemName) {
      memorandumValidationCheck(itemValue);
    }
  };

  //memorandum check
  const memorandumValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 2048)) {
      flg = !checkMaxLength(value, 2048);
      let message = com("messages.MS0002", {
        param1: t("memorandum"),
        param2: 2048,
      });
      setMemorandumErrorFlg(flg);
      setMemorandumErrorMessage(message);
    } else {
      setMemorandumErrorFlg(flg);
      setMemorandumErrorMessage("");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>{t("item_no")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={model_no}
              />
            </td>
          </tr>
          <tr>
            <th>{t("description")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={description}
              />
            </td>
          </tr>
          <tr>
            <th>{t("memorandum")}</th>
            <td>
              <TextField
                error={memorandumErrorFlg}
                helperText={memorandumErrorMessage}
                variant="outlined"
                className={classes.textArea}
                value={memo}
                onBlur={(e) => changeDataListen("memo", e.target.value)}
                onChange={(e) => changeData("memo", e.target.value)}
                multiline
                rows={6}
              />
            </td>
          </tr>
          <tr>
            <th>
              <b>{t("repair_destination")}</b>
            </th>
          </tr>
          <tr>
            <th>{t("service_center")}</th>
            <td className={classes.line}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {serviceCenterDataList.map((item: any) => (
                    <FormControlLabel
                      key={item.service_center_id}
                      control={
                        <Checkbox
                          checked={
                            item.service_center_id === "100"
                              ? true
                              : itemServiceCenterCheckedList[
                                  item.service_center_id
                                ] === true
                              ? true
                              : false
                          }
                          disabled={
                            item.service_center_id === "100" ? true : false
                          }
                          color="primary"
                          onChange={(e: any) =>
                            changeData(
                              item.service_center_id,
                              e.target.checked,
                              "item_service_center"
                            )
                          }
                          name={item.address_1}
                        />
                      }
                      label={item.address_1}
                      labelPlacement="start"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.buttonWidth2}
                  variant="contained"
                  onClick={() => handleBack(TabKey.itemMaster)}
                >
                  {t("back")}
                </Button>
                <Button
                  className={classes.buttonWidth2}
                  variant="contained"
                  color="primary"
                  disabled={disabledFlg}
                  onClick={() => handleSubmit(TabKey.itemMaster)}
                >
                  {t("submit")}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
