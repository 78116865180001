import React, { useState } from "react";
import clsx from "clsx";
// components
import { JobsDetailGeneralMessage } from "./JobsDetail.General.Message";
import { JobsDetailGeneralRepairModel } from "./JobsDetail.General.RepairModel";
import { JobsDetailGeneralFaultDescription } from "./JobsDetail.General.FaultDescription";
import { JobsDetailGeneralPartialRepairMemo } from "./JobsDetail.General.PartialRepairMemo";
import { JobsDetailGeneralRepairOption } from "./JobsDetail.General.RepairOption";
import { JobsDetailGeneralRepairUpdatedHistory } from "./JobsDetail.General.RepairUpdatedHistory";
import { JobsDetailGeneralRepairMedia } from "./JobsDetail.General.RepairMedia";
import { JobsDetailGeneralQuote } from "./JobsDetail.General.Quote";
import { JobsDetailGeneralTradeIn } from "./JobsDetail.General.TradeIn";
// UI
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useStyles } from "../hooks/JobsDetail.styles";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export const JobsDetailGeneral = (props: any) => {
  const myClasses = myStyles();
  const classes = useStyles();
  const {
    formList,
    dataList,
    setFormListValue,
    setFormList,
    repairHistoryList,
    setStartFlg,
    formErrorList,
    setFormErrorList,
    setFormErrorListValue,
    userPermisssionInfo,
    handleResend,
    mediaType,
    setMediaType,
    handleUpload,
    handleOnClickLink,
    handleDownload,
    handleDeleteMedia,
    getRepairHistory,
    onBlurOfCustomerNo,
    onBlurOfCustomerPONo,
    onBlurOfLocationNo,
    onBlurOfReceivedDateDate,
    onBlurOfReceivedDateTime,
    onBlurOfTechnicianCode,
    onBlurOfWarrantyApprovedBy,
    onBlurOfModelNo,
    onBlurOfSerialNo,
    onBlurOfReplyDateDate,
    onBlurOfReplyDateTime,
    onBlurOfTradeInItemNo,
    handleSave,
    repairJobPartsList,
    setButtonDisableFlg,
    setRepairHistoryList,
    setPartsTabActive,
    customerNoTemp,
    setCustomerNoTemp,
    setConfirmationOpen,
    setPopUpTitle,
    setPopUpContent,
    setPopUpButton,
    setAlertMessage,
    setAlertType,
  } = props;

  return (
    <Grid container className={myClasses.root} spacing={1}>
      <Grid item lg={4}>
        <JobsDetailGeneralMessage
          formList={formList}
          setFormListValue={setFormListValue}
          setFormList={setFormList}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          dataList={dataList}
          userPermisssionInfo={userPermisssionInfo}
          onBlurOfCustomerNo={onBlurOfCustomerNo}
          onBlurOfCustomerPONo={onBlurOfCustomerPONo}
          onBlurOfLocationNo={onBlurOfLocationNo}
          onBlurOfReceivedDateDate={onBlurOfReceivedDateDate}
          onBlurOfReceivedDateTime={onBlurOfReceivedDateTime}
          onBlurOfTechnicianCode={onBlurOfTechnicianCode}
          repairJobPartsList={repairJobPartsList}
          setButtonDisableFlg={setButtonDisableFlg}
          customerNoTemp={customerNoTemp}
          setCustomerNoTemp={setCustomerNoTemp}
          setConfirmationOpen={setConfirmationOpen}
          setPopUpTitle={setPopUpTitle}
          setPopUpContent={setPopUpContent}
          setPopUpButton={setPopUpButton}
          setAlertMessage={setAlertMessage}
          setAlertType={setAlertType}
        />
        <JobsDetailGeneralRepairModel
          formList={formList}
          setFormListValue={setFormListValue}
          data={repairHistoryList}
          setStartFlg={setStartFlg}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          getRepairHistory={getRepairHistory}
          dataList={dataList}
          onBlurOfModelNo={onBlurOfModelNo}
          onBlurOfSerialNo={onBlurOfSerialNo}
          userPermisssionInfo={userPermisssionInfo}
          repairJobPartsList={repairJobPartsList}
          setButtonDisableFlg={setButtonDisableFlg}
          setRepairHistoryList={setRepairHistoryList}
          setPartsTabActive={setPartsTabActive}
        />
        <div
          className={clsx(
            formList.repair_option === 5 && classes.componentHidden
          )}
        >
          <JobsDetailGeneralFaultDescription
            formList={formList}
            setFormListValue={setFormListValue}
            formErrorList={formErrorList}
            setFormErrorList={setFormErrorList}
            setFormErrorListValue={setFormErrorListValue}
            userPermisssionInfo={userPermisssionInfo}
          />
        </div>
        <div
          className={clsx(
            formList.repair_option !== 5 && classes.componentHidden
          )}
        >
          <JobsDetailGeneralPartialRepairMemo
            formList={formList}
            setFormListValue={setFormListValue}
            formErrorList={formErrorList}
            setFormErrorList={setFormErrorList}
            setFormErrorListValue={setFormErrorListValue}
            userPermisssionInfo={userPermisssionInfo}
          />
        </div>
      </Grid>
      <Grid item lg={3}>
        <JobsDetailGeneralRepairOption
          formList={formList}
          setFormList={setFormList}
          setFormListValue={setFormListValue}
          formErrorList={formErrorList}
          setFormErrorList={setFormErrorList}
          setFormErrorListValue={setFormErrorListValue}
          dataList={dataList}
          onBlurOfWarrantyApprovedBy={onBlurOfWarrantyApprovedBy}
          handleSave={handleSave}
          userPermisssionInfo={userPermisssionInfo}
          repairJobPartsList={repairJobPartsList}
        />
        <div
          className={clsx(
            (!formList.repair_option ||
              formList.repair_option === 1 ||
              formList.repair_option === 2) &&
              classes.componentHidden
          )}
        >
          <JobsDetailGeneralQuote
            formList={formList}
            setFormList={setFormList}
            setFormListValue={setFormListValue}
            formErrorList={formErrorList}
            setFormErrorList={setFormErrorList}
            setFormErrorListValue={setFormErrorListValue}
            dataList={dataList}
            handleResend={handleResend}
            onBlurOfReplyDateDate={onBlurOfReplyDateDate}
            onBlurOfReplyDateTime={onBlurOfReplyDateTime}
            userPermisssionInfo={userPermisssionInfo}
            repairJobPartsList={repairJobPartsList}
          />
        </div>
        <div
          className={clsx(
            (!formList.repair_option ||
              formList.repair_option === 1 ||
              formList.repair_option === 2 ||
              !formList.quote_result ||
              (formList.quote_result &&
                formList.quote_result !== 3 &&
                formList.quote_result !== 4)) &&
              classes.componentHidden
          )}
        >
          <JobsDetailGeneralTradeIn
            formList={formList}
            setFormListValue={setFormListValue}
            formErrorList={formErrorList}
            setFormErrorList={setFormErrorList}
            setFormErrorListValue={setFormErrorListValue}
            onBlurOfTradeInItemNo={onBlurOfTradeInItemNo}
            userPermisssionInfo={userPermisssionInfo}
          />
        </div>
      </Grid>
      <Grid item lg={5}>
        <JobsDetailGeneralRepairUpdatedHistory data={formList} />
        <JobsDetailGeneralRepairMedia
          data={formList}
          mediaType={mediaType}
          setMediaType={setMediaType}
          handleUpload={handleUpload}
          handleOnClickLink={handleOnClickLink}
          handleDownload={handleDownload}
          handleDeleteMedia={handleDeleteMedia}
          userPermisssionInfo={userPermisssionInfo}
        />
      </Grid>
    </Grid>
  );
};
