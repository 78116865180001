import React from "react";

// component
import { TechnicianTable } from "./Report.Technician.Table";

export const Technician = (props: any) => {
  const {
    data
  } = props;
  return (
    <>
      <TechnicianTable
        data={data}
      />
    </>
  );
};
