import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  Icon,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { inherits } from "util";
import { useLocale } from "../../hooks/UseLocale";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";

type Props = {
  title?: string;
  message: string;
  id: string;
  editFlag?: boolean;
  editor: boolean;
  goto: string;
  showIconAndLink: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDelete: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export const HomeRepair: FC<Props> = ({
  title = "",
  message,
  id,
  editFlag = false,
  editor = false,
  goto,
  showIconAndLink = false,
  handleChange,
  handleSave,
  handleDelete,
}) => {
  const { t } = useLocale({ defaultPath: "modules.components.dashboard" });
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (showIconAndLink && goto) {
      history.push(goto);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={t(title)}
          titleTypographyProps={{ variant: "h6" }}
          className={classes.root}
        />
        <CardContent>
          <ReactMarkdown plugins={[gfm]}>{message}</ReactMarkdown>
        </CardContent>
        <CardActionArea onClick={handleClick}>
          <CardContent>
            <Typography variant="body1" className={classes.iconWrap}>
              {showIconAndLink ? (<ArrowForwardIcon fontSize="default" className={classes.icon} />) : (<span className={classes.spanHeight}></span>)}
              {showIconAndLink ? (t("txtGoto") + " " + title) : "" }
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      { editor && <Grid container direction="row" justify="flex-end">
        <Grid item xs={12}>
          <TextField
            value={message}
            fullWidth
            id={id}
            multiline
            onChange={handleChange}
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Button
          color="secondary"
          disableElevation
          id={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleDelete(event)
          }
        >
          {t("btn_Delete")}
        </Button>
        <Button
          color="primary"
          disabled={!editFlag}
          disableElevation
          id={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleSave(event)
          }
        >
          {t("btn_Save")}
        </Button>
      </Grid>}
    </>
  );
};

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      maxHeight: "42px",
    },
    input: {
      fontFamily: "monospace, serif;",
      fontSize: "1em",
    },
    iconWrap: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    icon: {
      marginRight: "0.5em",
    },
    spanHeight: {
      height: "24px",
    },
  })
);
