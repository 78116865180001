import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AT1302
export const GetReadOrderAPIforDZ = (config: any, order_no: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/dz/${affiliate_cd}/${order_no}`,
    config: config,
  };
  return http(param);
};

// AT1303
export const UpdateOrderAPIforDZ = (config: any, order_no: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/dz/${affiliate_cd}/${order_no}`,
    config: config,
  };
  return http(param);
};

// AT1305
export const GetReadOrderListAPI = (config: any, query_parameters: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

// AT1306
export const GetReadOrderAPI = (config: any, order_no: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/${affiliate_cd}/${order_no}`,
    config: config,
  };
  return http(param);
};

// AT1307
export const UpdateOrderAPI = (config: any, order_no: any, data: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/${affiliate_cd}/${order_no}`,
    config: config,
    method: methods.patch,
    data: data,
  };
  return http(param);
};

// AT1309
export const UpdateOrderPersonInChargeAPI = (config: any, order_no: any, data: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/header/${affiliate_cd}/${order_no}`,
    config: config,
    method: methods.patch,
    data: data,
    flg: true,
  };
  return http(param);
};

// AT1310
export const GetReadOrderPersonInChargeListAPI = (config: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/userlist/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

// AT1311
export const GetOrderCSVListAPI = (config: any, query_parameters: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/orders/csv/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
