import React from "react";
// UI
import { useStyles } from "../hooks/useStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const JobNo = (props: any) => {
  const classes = useStyles();
  const {
    handleRegisterJobEvent,
    text,
    job_no,
    error,
    errorMessage,
    jobNoInputDisableFlg,
    jobNoButtonDisableFlg,
    changeData,
    jobNumberValicationCheck,
  } = props;

  return (
    <tr>
      <td className={classes.titleCell}>
        <p>
          {props.text}
          <span className={classes.mustMark}>*</span>
        </p>
      </td>
      <td className={classes.postionCell}>
        <TextField
          variant="outlined"
          value={job_no}
          className={classes.textField}
          error={error}
          disabled={jobNoInputDisableFlg}
          helperText={errorMessage}
          onChange={(e) => {
            const value = e.target.value.replace(/[^\d]/g, "").substr(0, 8);
            changeData("job_no", value);
          }}
          onBlur={jobNumberValicationCheck}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              jobNumberValicationCheck();
            }
          }}
        />
        <Button
          className={classes.buttonPosition}
          variant="contained"
          color="primary"
          component="span"
          disabled={jobNoButtonDisableFlg}
          onClick={handleRegisterJobEvent}
        >
          +{text}
        </Button>
      </td>
    </tr>
  );
};
