import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/ClaimDetail.Upload.styles";
import DescriptionIcon from "@material-ui/icons/Description";
import ImageIcon from "@material-ui/icons/Image";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { InformationIcon } from "../../../modules/components/informationIcon/InformationIcon";

// language
import { useLocale } from "../../../modules/hooks/UseLocale";

// component
export const ClaimDetailUpload = (props: any) => {
  const classes = useStyles();
  const {
    claimMedia,
    handleUpload,
    photoDelete,
    fileDownload,
    endUserInfoToolsList,
    fileDownloadFile,
    formList,
  } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  const [documentList, setDocumentList] = useState([]);
  useEffect(() => {
    let _list = [];
    if (endUserInfoToolsList.length > 0) {
      const _modelNoList = endUserInfoToolsList.filter(
        (item: any) => item.unique_key === formList.unique_key
      );
      if (
        _modelNoList.length > 0 &&
        _modelNoList[0].invoices &&
        _modelNoList[0].invoices.length > 0
      ) {
        _list = _modelNoList[0].invoices;
      }
    }
    setDocumentList(_list);
  }, [endUserInfoToolsList, formList.unique_key]);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.message}>
          <div className={classes.image}>
            <DescriptionIcon color="primary" />
          </div>
          {/* <p className={classes.text}>
            <span className={classes.type}>
              {t("upload_invoice_description_type")}
            </span>
            {t("upload_invoice_description")}
          </p> */}
        </div>
        <div className={classes.explainArea}>
          <p className={classes.title}>{t("upload_invoice_title")}</p>
          <InformationIcon txtInner={["upload_type", "upload_size"]} />
        </div>
        <div className={classes.button}>
          <input
            accept="image/*,.pdf"
            id="contained-button-file"
            type="file"
            className={classes.uploadInput}
          />
          <label htmlFor="contained-button-file">
            <Button
              disabled
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
              className={classes.uploadButton}
            >
              {t("btn_upload")}
            </Button>
          </label>
        </div>
        <ul className={classes.list}>
          {documentList &&
            documentList.length > 0 &&
            documentList.map((item: any, index: number) => (
              <li key={"document" + index}>
                <p
                  className={classes.link}
                  onClick={() => fileDownloadFile(item)}
                >
                  {item.filename}
                </p>
              </li>
            ))}
          {claimMedia &&
          claimMedia.claim_media_invoice &&
          claimMedia.claim_media_invoice.length > 0
            ? claimMedia.claim_media_invoice.map((item: any, index: number) =>
                item.update_flg !== 2 ? (
                  <li key={"claim_media_invoice" + index}>
                    {item.updated_date ? (
                      <p
                        className={classes.link}
                        onClick={() => fileDownload(item)}
                      >
                        {item.media_name}
                      </p>
                    ) : (
                      <p>{item.media_name}</p>
                    )}
                  </li>
                ) : null
              )
            : null}
        </ul>
      </div>
      <div className={classes.item_area_right}>
        <div className={classes.message}>
          <div className={classes.image}>
            <ImageIcon color="primary" />
          </div>
          {/* <p className={classes.text}>
            <span className={classes.type}>
              {t("upload_image_description_type")}
            </span>
            {t("upload_image_description")}
          </p> */}
        </div>
        <div className={classes.explainArea}>
          <p className={classes.title}>{t("upload_image_title")}</p>
          <InformationIcon txtInner={["upload_type", "upload_size"]} />
        </div>
        <div className={classes.button}>
          <input
            accept="image/*,.pdf"
            id="contained-button-file2"
            type="file"
            className={classes.uploadInput}
          />
          <label htmlFor="contained-button-file2">
            <Button
              disabled
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
              className={classes.uploadButton}
            >
              {t("btn_upload")}
            </Button>
          </label>
        </div>
        <ul className={classes.list}>
          {claimMedia &&
          claimMedia.claim_media_picture &&
          claimMedia.claim_media_picture.length > 0
            ? claimMedia.claim_media_picture.map((item: any, index: number) =>
                item.update_flg !== 2 ? (
                  <li key={"claim_media_picture" + index}>
                    {item.updated_date ? (
                      <p
                        className={classes.link}
                        onClick={() => fileDownload(item)}
                      >
                        {item.media_name}
                      </p>
                    ) : (
                      <p>{item.media_name}</p>
                    )}
                  </li>
                ) : null
              )
            : null}
        </ul>
      </div>
    </div>
  );
};
