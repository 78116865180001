import { withStyles } from "@material-ui/core/styles";
import { BarGraphGoodsOut } from "./barhraph_goods_out.component";

export const StyledBarGraphGoodsOut = withStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "min-content",
    display: "block",
    background:
      "rgb(255,255,255)",

    "& > *": {
      margin: "10px",
      width: "min-content"
    }
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    width:"auto",
    margin: "auto",
    height: "auto",
    textAlign: "left",
    color: "black",
    padding: "10px"
  },
  title2: {
    width:"auto",
    fontSize:"18px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    padding: "10px"
  },
  svgText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fill: "white"
  }
}))(BarGraphGoodsOut);
