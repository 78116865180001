import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { PromotionDetailCategoryConsideredTable } from "./PromotionDetail.Category.Considered.Table";
import { PromotionDetailCategoryExcludedTable } from "./PromotionDetail.Category.Excluded.Table";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "20px",
      position: "relative",
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "20px"
      },
      "& h2": {
        fontSize: "16px",
        fontWeight: "normal",
        marginBottom: "20px"
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
      "& dl": {
        display: "flex",
        margin: 0,
        marginRight: "20px",
        alignItems: "center"
      },
      "& dd": {
        marginLeft: "15px",
        margin: 0
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    rootInnerFirst: {
      width: "100%",
      paddingBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
    rootInnerSecond: {
      paddingBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      alignItems: "center",
      marginBottom: "20px"
    },
    plannerCode: {
      width: "70px",
    },
    formControl: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "30px",
        lineHeight: "30px",
      },
    }
  })
);

export const Category = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const classes = useStyles();

  const {
    searchValueList,
    setSearchValue,

    oldData,
    setOldData,

    changeDataListen,
    setErrorValueList,
    defaultValueList,
    setDefaultValue,
    errorValueList,
    getOptionLabel2,
    filterOptions2,
    categoryConsidered,
    categoryExcluded,
    onBlurOfItemNo,
    onFocusOfItemNo,
    consideredEditList,
    setConsideredEditList,
    excludedEditList,
    setExcludedEditList,
    plannerCodeChange,
    numSelectedConsidered,
    setNumSelectedConsidered,
    selectedArrayConsidered,
    setSelectedArrayConsidered,
    selectedRowArrayConsidered,
    setSelectedRowArrayConsidered,
    indeterminateConsidered,
    setIndeterminateConsidered,
    allCheckedConsidered,
    setAllCheckedConsidered,
    numSelectedExcluded,
    setNumSelectedExcluded,
    selectedArrayExcluded,
    setSelectedArrayExcluded,
    selectedRowArrayExcluded,
    setSelectedRowArrayExcluded,
    indeterminateExcluded,
    setIndeterminateExcluded,
    allCheckedExcluded,
    setAllCheckedExcluded,
    setHasSeqNoRow,
    hasSeqNoRow,
    reviewFlg,
    onConsideredItemNoFlag,
    onExcludedItemNoFlag,
    salesAnalysisList

  } = props

  // change the selected number
  const changeNumSelectedConsidered = (num: number) => {
    setNumSelectedConsidered(num);
  };

  const changeNumSelectedExcluded = (num: number) => {
    setNumSelectedExcluded(num);
  };

  const addOneRow = (type: any) => {
    // ready one row
    const getRowField = JSON.parse(JSON.stringify(searchValueList[type][0]));
    for(let key in getRowField) {
      if(key !== "data_type") {
        getRowField[key] = "";
      }
    };

    // add row at last
    const getCategoryData = JSON.parse(JSON.stringify(searchValueList[type]));
    getCategoryData.push(JSON.parse(JSON.stringify(getRowField)));
    getCategoryData[getCategoryData.length - 1].index = getCategoryData.length - 1;
    setSearchValue(type, getCategoryData);

    const getOldData = JSON.parse(JSON.stringify(oldData[type]));
    getOldData.push(JSON.parse(JSON.stringify(getRowField)));
    getOldData[getOldData.length - 1].index = getOldData.length - 1;
    props.setOldData(type, getOldData);

    if(type === "category_considered") {
      if(selectedArrayConsidered.length > 0) {
        setIndeterminateConsidered(true);
      } else {
        setIndeterminateConsidered(false);
      };
      setAllCheckedConsidered(false);
    } else if (type === "category_excluded") {
      if(selectedArrayExcluded.length > 0) {
        setIndeterminateExcluded(true);
      } else {
        setIndeterminateExcluded(false);
      };
      setAllCheckedExcluded(false);
    }

    const getDefaultField = JSON.parse(JSON.stringify(defaultValueList[type]));
    getDefaultField.push(JSON.parse(JSON.stringify(getRowField)));
    getDefaultField[getDefaultField.length - 1].index = getDefaultField.length - 1;
    setDefaultValue(type, getDefaultField);

    // add error list
    const errorValueField = JSON.parse(JSON.stringify(errorValueList[type][0]));
    for(let key in errorValueField) {
      errorValueField[key] = "";
    };
    const getErrorData = JSON.parse(JSON.stringify(errorValueList[type]));
    getErrorData.push(JSON.parse(JSON.stringify(errorValueField)));
    const errorValue = Object.assign({}, errorValueList, {[type]: getErrorData});
    setErrorValueList(errorValue);

    // add disabled flag
    let editListAry:any[] = [];
    if(type === "category_considered") {
      editListAry = JSON.parse(JSON.stringify(consideredEditList));
    } else if (type === "category_excluded") {
      editListAry = JSON.parse(JSON.stringify(excludedEditList));
    };
    editListAry.push(false);
    if(type === "category_considered") {
      setConsideredEditList(editListAry);
    } else if (type === "category_excluded") {
      setExcludedEditList(editListAry);
    };
  };

  const deleteRow = (type: any) => {
    // ready one row
    const getRowField = JSON.parse(JSON.stringify(searchValueList[type][0]));
    for(let key in getRowField) {
      if(key !== "data_type") {
        getRowField[key] = "";
      }
    };

    // delete row with "seq_no"
    if (hasSeqNoRow) {
      let hasSeqNo = false;
      if(type === "category_considered") {
        hasSeqNo = selectedRowArrayConsidered.some((item: any) => {
          return item.seq_no && item.seq_no !== "";
        });
      } else if (type === "category_excluded") {
        hasSeqNo = selectedRowArrayExcluded.some((item: any) => {
          return item.seq_no && item.seq_no !== "";
        });
      };
      setHasSeqNoRow(!hasSeqNo);
    }

    // filter not selected item
    let getCategoryData: any = "";
    if(type === "category_considered") {
      getCategoryData = searchValueList[type].filter((item: any) => {
        return selectedArrayConsidered.indexOf(item.index) === -1;
      });
    } else if (type === "category_excluded") {
      getCategoryData = searchValueList[type].filter((item: any) => {
        return selectedArrayExcluded.indexOf(item.index) === -1;
      });
    };

    let getOldData: any = "";
    if(type === "category_considered") {
      getOldData = oldData[type].filter((item: any) => {
        return selectedArrayConsidered.indexOf(item.index) === -1;
      });
    } else if (type === "category_excluded") {
        getOldData = oldData[type].filter((item: any) => {
          return selectedArrayExcluded.indexOf(item.index) === -1;
      });
    };

    if(getCategoryData.length === 0) {
      getCategoryData.push(JSON.parse(JSON.stringify(getRowField)));
      getOldData.push(JSON.parse(JSON.stringify(getRowField)));
    };
    getCategoryData.forEach((item: any, index: number) => {
      item.index = index;
    });

    getOldData.forEach((item: any, index: number) => {
      item.index = index;
    });

    setSearchValue(type, JSON.parse(JSON.stringify(getCategoryData)));

    setOldData(type, JSON.parse(JSON.stringify(getOldData)));

    if(type === "category_considered") {
      setSelectedArrayConsidered([]);
      setSelectedRowArrayConsidered([]);
      setIndeterminateConsidered(false);
      setAllCheckedConsidered(false);
    } else if (type === "category_excluded") {
      setSelectedArrayExcluded([]);
      setSelectedRowArrayExcluded([]);
      setIndeterminateExcluded(false);
      setAllCheckedExcluded(false);
    };

    let getDefaultField: any = "";
    if(type === "category_considered") {
      getDefaultField = defaultValueList[type].filter((item: any) => {
        return selectedArrayConsidered.indexOf(item.index) === -1;
      });
    } else if (type === "category_excluded") {
      getDefaultField = defaultValueList[type].filter((item: any) => {
        return selectedArrayExcluded.indexOf(item.index) === -1;
      });
    };
    if(getDefaultField.length === 0) {
      getDefaultField.push(JSON.parse(JSON.stringify(getRowField)));
    };
    getDefaultField.forEach((item: any, index: number) => {
      item.index = index;
    });
    setDefaultValue(type, JSON.parse(JSON.stringify(getDefaultField))); 

    // remove error list
    const getErrorData = JSON.parse(JSON.stringify(errorValueList[type]));
    const getErrorField = getErrorData[0];
    for(let key in getErrorField) {
      getErrorField[key] = "";
    };
    let errorData: any[] = [];
    if(type === "category_considered") {
      errorData = getErrorData.filter((item: any, index: number) => {
        return selectedArrayConsidered.indexOf(index) === -1;
      });
    } else if (type === "category_excluded") {
      errorData = getErrorData.filter((item: any, index: number) => {
        return selectedArrayExcluded.indexOf(index) === -1;
      });
    };
    if(errorData.length === 0) {
      errorData.push(getErrorField)
    };

    const errorValue = Object.assign({}, errorValueList, {[type]: errorData});
    setErrorValueList(errorValue);

    // remove disabled flag
    let editListAry:any[] = [];
    if(type === "category_considered") {
      editListAry = consideredEditList.filter((item: any, index: number) => {
        return selectedArrayConsidered.indexOf(index) === -1;
      });
    } else if (type === "category_excluded") {
      editListAry = excludedEditList.filter((item: any, index: number) => {
        return selectedArrayExcluded.indexOf(index) === -1;
      });
    };
    if(editListAry.length === 0) {
      editListAry.push(false);
    };

    if(type === "category_considered") {
      setConsideredEditList(editListAry);
    } else if (type === "category_excluded") {
      setExcludedEditList(editListAry);
    };
  };

  const setValue = (property: any, value: any, index: number, type: any) => {
    const setCategoryField = JSON.parse(JSON.stringify(searchValueList[type]));
    setCategoryField[index][property] = value;
    setSearchValue(type, setCategoryField);
  };
  
  return (
    <div className={classes.root}>
      <h1>{t("category")}</h1>
      <div className={classes.rootInnerFirst}>
        <h2>{t("considered")}</h2>
        <div className={classes.rows}>
          <dl>
            <dt>{t("planner_code")}</dt>
            <dd className={classes.plannerCode} data-testid="considered_planner_code">
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="planner_code"
                  value={searchValueList.planner_code_considered}
                  onChange={(e: any) => {
                    setSearchValue("planner_code_considered", e.target.value);
                    plannerCodeChange("category_considered", e.target.value);
                  }}
                  disabled={reviewFlg}
                  native
                >
                  <option value={""}></option>
                  {Array.isArray(com("planner_code")) ? (
                    com("planner_code").map((option: any) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
        </div>
        
        <PromotionDetailCategoryConsideredTable
          data={searchValueList.category_considered}
          changeDataListen={changeDataListen}
          searchValueList={searchValueList}

          selectedArray={selectedArrayConsidered}
          setSelectedArray={setSelectedArrayConsidered}
          selectedRowArray={selectedRowArrayConsidered}
          setSelectedRowArray={setSelectedRowArrayConsidered}
          indeterminate={indeterminateConsidered}
          setIndeterminate={setIndeterminateConsidered}
          allChecked={allCheckedConsidered}
          setAllChecked={setAllCheckedConsidered}
          numSelected={numSelectedConsidered}
          changeNumSelected={(num: number) => changeNumSelectedConsidered(num)}
          addOneRow={addOneRow}
          setValue={setValue}
          deleteRow={deleteRow}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categoryConsidered={categoryConsidered}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfItemNo={onFocusOfItemNo}
          errorValueList={errorValueList}
          consideredEditList={consideredEditList}
          reviewFlg={reviewFlg}
          onConsideredItemNoFlag={onConsideredItemNoFlag}
          salesAnalysisList={salesAnalysisList}

        />
      </div>

      <div className={classes.rootInnerSecond}>
        <h2>{t("excluded")}</h2>
        <div className={classes.rows}>
          <dl>
            <dt>{t("planner_code")}</dt>
            <dd className={classes.plannerCode}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="planner_code"
                  value={searchValueList.planner_code_excluded}
                  onChange={(e: any) => {
                    setSearchValue("planner_code_excluded", e.target.value);
                    plannerCodeChange("category_excluded", e.target.value);
                  }}
                  disabled={reviewFlg}
                  native
                >
                  <option value={""}></option>
                  {Array.isArray(com("planner_code")) ? (
                    com("planner_code").map((option: any) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
        </div>

        <PromotionDetailCategoryExcludedTable
          data={searchValueList.category_excluded}
          selectedArray={selectedArrayExcluded}
          setSelectedArray={setSelectedArrayExcluded}
          selectedRowArray={selectedRowArrayExcluded}
          setSelectedRowArray={setSelectedRowArrayExcluded}
          indeterminate={indeterminateExcluded}
          setIndeterminate={setIndeterminateExcluded}
          allChecked={allCheckedExcluded}
          setAllChecked={setAllCheckedExcluded}
          numSelected={numSelectedExcluded}
          changeNumSelected={(num: number) => changeNumSelectedExcluded(num)}
          addOneRow={addOneRow}
          setValue={setValue}
          deleteRow={deleteRow}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categoryExcluded={categoryExcluded}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfItemNo={onFocusOfItemNo}
          errorValueList={errorValueList}
          excludedEditList={excludedEditList}
          reviewFlg={reviewFlg}
          onExcludedItemNoFlag={onExcludedItemNoFlag}
          salesAnalysisList={salesAnalysisList}
          changeDataListen={changeDataListen}

        />
      </div>
    </div>
  );
};
