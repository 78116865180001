import React from "react";
import {
  createStyles,
  Icon,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  Theme,
} from "@material-ui/core";
import { ReactComponent as CopyAddress } from "../../../assets/icons/CopyAddress.min.svg";
import { ReactComponent as Dispose } from "../../../assets/icons/Dispose.min.svg";
import { ReactComponent as JobAllocate } from "../../../assets/icons/JobAllocate.min.svg";
import { ReactComponent as Jobs } from "../../../assets/icons/Jobs.min.svg";
import { ReactComponent as LocationControl } from "../../../assets/icons/LocationControl.min.svg";
import { ReactComponent as MasterMaintenance } from "../../../assets/icons/MasterMaintenance.min.svg";
import { ReactComponent as MobileApp } from "../../../assets/icons/MobileApp.min.svg";
import { ReactComponent as Putaway } from "../../../assets/icons/Putaway.min.svg";
import { ReactComponent as RepairRegister } from "../../../assets/icons/RepairRegister.min.svg";
import { ReactComponent as RepairStart } from "../../../assets/icons/RepairStart.min.svg";
import { ReactComponent as Return } from "../../../assets/icons/Return.min.svg";
import { ReactComponent as ServiceCenter } from "../../../assets/icons/ServiceCenter.min.svg";
import { ReactComponent as Wagon } from "../../../assets/icons/Wagon.min.svg";
import { ReactComponent as Wagon2 } from "../../../assets/icons/Wagon2.min.svg";
import { ReactComponent as WagonList } from "../../../assets/icons/WagonList.min.svg";
import { ReactComponent as WagonListOnline } from "../../../assets/icons/WagonListOnline.min.svg";
import { ReactComponent as Promotion } from "../../../assets/icons/Promotion.min.svg";
import { ReactComponent as Order } from "../../../assets/icons/Order.min.svg";
import { ReactComponent as Simulate } from "../../../assets/icons/Simulate.min.svg";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ReceiptIcon from "@material-ui/icons/Receipt";

export const CopyAddressIcon = () => (
  <SvgIcon>
    <CopyAddress />
  </SvgIcon>
);

export const DisposeIcon = () => (
  <SvgIcon>
    <Dispose />
  </SvgIcon>
);

export const JobAllocateIcon = () => (
  <SvgIcon>
    <JobAllocate />
  </SvgIcon>
);

export const JobsIcon = () => (
  <SvgIcon>
    <Jobs />
  </SvgIcon>
);

export const LocationControlIcon = () => (
  <SvgIcon>
    <LocationControl />
  </SvgIcon>
);

export const MasterMaintenanceIcon = () => (
  <SvgIcon>
    <MasterMaintenance />
  </SvgIcon>
);

export const MobileAppIcon = () => (
  <SvgIcon>
    <MobileApp />
  </SvgIcon>
);

export const PutawayIcon = () => (
  <SvgIcon>
    <Putaway />
  </SvgIcon>
);

export const RepairRegisterIcon = () => (
  <SvgIcon>
    <RepairRegister />
  </SvgIcon>
);

export const RepairStartIcon = () => (
  <SvgIcon>
    <RepairStart />
  </SvgIcon>
);

export const ReturnIcon = () => (
  <SvgIcon>
    <Return />
  </SvgIcon>
);

export const ServiceCenterIcon = () => (
  <SvgIcon>
    <ServiceCenter />
  </SvgIcon>
);

export const WagonIcon = () => (
  <SvgIcon>
    <Wagon />
  </SvgIcon>
);

export const Wagon2Icon = () => (
  <SvgIcon>
    <Wagon2 />
  </SvgIcon>
);

export const WagonListIcon = () => (
  <SvgIcon>
    <WagonList />
  </SvgIcon>
);

export const WagonListOnlineIcon = () => (
  <SvgIcon>
    <WagonListOnline />
  </SvgIcon>
);

export const PromotionIcon = () => (
  <SvgIcon>
    <Promotion />
  </SvgIcon>
);

export const OrderIcon = () => (
  <SvgIcon>
    <Order />
  </SvgIcon>
);

export const SimulateIcon = () => (
  <SvgIcon>
    <Simulate />
  </SvgIcon>
);

export const ClaimIcon = () => (
  <SvgIcon>
    <AssignmentIcon />
  </SvgIcon>
);

export const ApIcon = () => (
  <SvgIcon>
    <ReceiptIcon />
  </SvgIcon>
);
