export const getFileName = (files: any, claimMedia: any) => {
  const file = files[0];
  let _fileName = file.name;
  let fileNameList = _fileName.split(".");
  if (claimMedia.length > 0) {
    for (let i = 0; i < claimMedia.length; i++) {
      let flg = false;
      for (let j = 0; j < claimMedia.length; j++) {
        if (_fileName === claimMedia[j].media_name) {
          flg = true;
          break;
        }
      }
      if (!flg) {
        break;
      } else {
        let _name = "";
        if (fileNameList.length > 2) {
          for (let a = 0; a < fileNameList.length; i++) {
            if (a < fileNameList.length) {
              _name += fileNameList[a];
            }
          }
        } else {
          _name = fileNameList[0];
        }
        _fileName =
          _name +
          "(" +
          (i + 1) +
          ")" +
          "." +
          fileNameList[fileNameList.length - 1];
      }
    }
  }
  return _fileName;
};
