import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
        backgroundColor: "rgba(240, 242, 244)",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      }
    }
  })
);

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "DateTime",
    label: "created_date",
  },
  {
    id: "UpdatedBy",
    label: "created_by",
  },
  {
    id: "Description",
    label: "description",
  },
];

export const PromotionDetailUpdateHistoryTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
          >
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
