import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormHelperText from "@material-ui/core/FormHelperText";

// components
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { MasterMaintenanceErrorMessageBox } from "./MasterMaintenance.ErrorMessageBox";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// api
import {
  GetPrintSettingListAPI,
  CreatePrintSettingAPI,
  DeletePrintSettingAPI,
} from "../methods/api.methods";
// methods
import {
  deleteItemFromList,
  createDeleteParam,
} from "../methods/common.methods";
import { getApiResultStatus } from "../../../modules/common.methods";
// valication
import { isEmptyCheck } from "../../../modules/hooks/UseValication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& dl": {
        paddingLeft: "10px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "20px",
      },
    },
    table02: {
      width: "100%",
      maxWidth: "800px",
      margin: "0 auto",
      borderSpacing: "0",
    },
    mark: {
      color: "red",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
        paddingTop: "5px",
      },
    },
    selectContainer: {
      width: "100%",
      height: "30px",
    },
    select: {
      height: "30px",
      width: "210px",
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
        paddingTop: "10px",
      },
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "300px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
    },
    divStyle: {
      paddingTop: "20px",
    },
    buttonContainer02: {
      width: "100%",
      height: "30px",
      maxWidth: "300px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      border: "1px solid rgba(198, 209, 221)",
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        background: "rgba(240, 242, 244)",
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    buttonWidth: {
      width: "90px",
      height: "35px",
    },
    tdStyle: {
      verticalAlign: "top",
    },
  })
);

const printSettingsDefault = {
  form_id: "",
  service_center_id: "",
  printer_id: "",
};

export const MasterMaintenancePrintSettingEditor = (props: any) => {
  const classes = useStyles();
  const {
    editData,
    handleBack,
    serviceCenterDataList,
    documentFormData,
    printerdata,
    config,
  } = props;
  const {
    given_name,
    family_name,
    middle_name,
    mail_address,
    userinfo_repair,
    sub,
  } = editData;

  const [pageData, setPageData] = useState([]);
  const [startFlg, setStartFlg] = useState(false);
  const [printSettings, setPrintSettings] = useState(printSettingsDefault);
  const [errMessageList, setErrMessageList] = useState([]);

  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  // delete
  const [deleteRow, setDeleteRow] = useState([]);

  // loading
  const { setCommonInfo } = useCommonInfo();

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  useEffect(() => {
    if (!startFlg) {
      setStartFlg(true);
      GetPrintSettingListAPI(sub, config).then((response: any) => {
        setPageData(response);
      });
    }
  });

  const addList = () => {
    apiLoading(true);
    const param = {
      sub: sub,
      form_id: printSettings.form_id,
      service_center_id: printSettings.service_center_id,
      printer_id: printSettings.printer_id,
    };

    if (pageData.length !== 0) {
      const printerHaveFlg = pageData.filter(
        (item: any) =>
          item.sub + item.form_id + item.service_center_id ===
          sub + printSettings.form_id + printSettings.service_center_id
      );

      if (printerHaveFlg.length > 0) {
        let errorData: any = [];
        let message = {};
        message = com("MS0051");
        errorData.push(message + "\n");
        setErrMessageList(errorData);
        setErrorMessageOpen(true);
        apiLoading(false);
      } else {
        creatPrinterList(param);
      }
    } else {
      creatPrinterList(param);
    }
  };

  const creatPrinterList = (param: any) => {
    // AM1101
    CreatePrintSettingAPI(sub, param, config)
      .then((response: any) => {
        if (response !== undefined && response.code === 400) {
          let errorData: any = [];
          let message = {};
          message = com("MS0051");
          errorData.push(message + "\n");
          setErrMessageList(errorData);
          setErrorMessageOpen(true);
        } else {
          setPageData(response);
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const changeData = (type: any, value: any) => {
    const newList = Object.assign({}, printSettings, { [type]: value });
    setPrintSettings(newList);
  };

  // delete button click event
  const handleDeleteSet = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  const handleDelete = (row: any) => {
    apiLoading(true);
    const param = createDeleteParam(row.updated_date);

    DeletePrintSettingAPI(
      row.printer_id,
      row.service_center_id,
      row.form_id,
      sub,
      param,
      config
    )
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("delete AM1103 printSetting", code, response);
        if (getApiResultStatus(code)) {
          window.scrollTo(0, 0);
          const newList = deleteItemFromList(row.rid, pageData);
          setPageData(newList);
        } else {
          const message = com(response.message_id, {
            param1: t("API." + response.key),
          });
          setErrMessageList(message);
          setErrorMessageOpen(true);
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  // message box
  const [open, setOpen] = useState(false);
  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    handleDelete(deleteRow);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };

  const [disabledFlg, setDisabledFlg] = React.useState(true);
  const [formErrorFlg, setFormErrorFlg] = React.useState(false);
  const [formErrorMessage, setFormErrorMessage] = React.useState("");
  const [serviceCenterErrorFlg, setServiceCenterErrorFlg] =
    React.useState(false);
  const [serviceCenterErrorMessage, setServiceCenterErrorMessage] =
    React.useState("");
  const [printerErrorFlg, setPrinterErrorFlg] = React.useState(false);
  const [printerErrorMessage, setPrinterErrorMessage] = React.useState("");

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("form_id" === itemName) {
      formValidationCheck(itemValue);
    } else if ("service_center_id" === itemName) {
      serviceCenterValidationCheck(itemValue);
    } else if ("printer_id" === itemName) {
      printerValidationCheck(itemValue);
    }
  };

  // form check
  const formValidationCheck = (value: any) => {
    const flg = isEmptyCheck(value);
    if (flg) {
      setFormErrorFlg(flg);
      let message = com("MS0046", {
        param1: t("printSetting.form"),
      });
      setFormErrorMessage(message);
    } else {
      setFormErrorFlg(flg);
      setFormErrorMessage("");
    }
  };

  // service center check
  const serviceCenterValidationCheck = (value: any) => {
    const flg = isEmptyCheck(value);
    if (flg) {
      setServiceCenterErrorFlg(flg);
      let message = com("MS0046", {
        param1: t("printSetting.service_center"),
      });
      setServiceCenterErrorMessage(message);
    } else {
      setServiceCenterErrorFlg(flg);
      setServiceCenterErrorMessage("");
    }
  };

  // printer check
  const printerValidationCheck = (value: any) => {
    const flg = isEmptyCheck(value);
    if (flg) {
      setPrinterErrorFlg(flg);
      let message = com("MS0046", {
        param1: t("printSetting.printer"),
      });
      setPrinterErrorMessage(message);
    } else {
      setPrinterErrorFlg(flg);
      setPrinterErrorMessage("");
    }
  };

  useEffect(() => {
    if (
      isEmptyCheck(printSettings.form_id) ||
      formErrorFlg ||
      isEmptyCheck(printSettings.service_center_id) ||
      serviceCenterErrorFlg ||
      isEmptyCheck(printSettings.printer_id) ||
      printerErrorFlg
    ) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>{t("printSetting.given_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={given_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("printSetting.family_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={family_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("printSetting.middle_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={middle_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("printSetting.mail_address")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={mail_address}
              />
            </td>
          </tr>
          <tr>
            <th>{t("printSetting.technician_code")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={userinfo_repair.erp_user_id}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.divStyle}>
        <table className={classes.table02}>
          <tbody>
            <tr>
              <th align="left">
                {t("printSetting.form")}
                <span className={classes.mark}>*</span>
              </th>
              <th align="left">
                {t("printSetting.service_center")}
                <span className={classes.mark}>*</span>
              </th>
              <th align="left">
                {t("printSetting.printer")}
                <span className={classes.mark}>*</span>
              </th>
            </tr>
            <tr>
              <td className={classes.tdStyle}>
                <FormControl variant="outlined" error={formErrorFlg}>
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={printSettings.form_id}
                    onBlur={(e) => changeDataListen("form_id", e.target.value)}
                    onChange={(e) => changeData("form_id", e.target.value)}
                  >
                    {documentFormData.map((item: any) => (
                      <MenuItem value={item.form_id} key={item.form_id}>
                        {item.form_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formErrorMessage}</FormHelperText>
                </FormControl>
              </td>
              <td className={classes.tdStyle}>
                <FormControl variant="outlined" error={serviceCenterErrorFlg}>
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={printSettings.service_center_id}
                    onBlur={(e) =>
                      changeDataListen("service_center_id", e.target.value)
                    }
                    onChange={(e) =>
                      changeData("service_center_id", e.target.value)
                    }
                  >
                    {serviceCenterDataList.map((item: any) => (
                      <MenuItem
                        value={item.service_center_id}
                        key={item.service_center_id}
                      >
                        {item.address_1}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{serviceCenterErrorMessage}</FormHelperText>
                </FormControl>
              </td>
              <td className={classes.tdStyle}>
                <FormControl variant="outlined" error={printerErrorFlg}>
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={printSettings.printer_id}
                    onBlur={(e) =>
                      changeDataListen("printer_id", e.target.value)
                    }
                    onChange={(e) => changeData("printer_id", e.target.value)}
                  >
                    {printerdata.map((item: any) => (
                      <MenuItem value={item.printer_id} key={item.printer_id}>
                        {item.printer_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{printerErrorMessage}</FormHelperText>
                </FormControl>
              </td>
              <td className={classes.tdStyle}>
                <Button
                  className={classes.buttonContainer02}
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={addList}
                  disabled={disabledFlg}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t("printSetting.add")}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Table className={classes.table02}>
          <TableHead>
            <TableRow className={classes.thead}>
              <TableCell align="center">{t("printSetting.form")}</TableCell>
              <TableCell align="center">{t("printSetting.service_center")}</TableCell>
              <TableCell align="center">{t("printSetting.printer")}</TableCell>
              <TableCell align="center">{t("printSetting.delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((row: any, index: number) => {
              const formName = documentFormData.filter(
                (item: any) => item.form_id == row.form_id
              );
              const serviceCenterName = serviceCenterDataList.filter(
                (item: any) => item.service_center_id === row.service_center_id
              );
              const printerId = printerdata.filter(
                (item: any) => item.printer_id === row.printer_id
              );
              return (
                <TableRow key={index}>
                  <TableCell className={classes.nowrap}>
                    {formName.length > 0 ? formName[0].form_name : ""}
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    {serviceCenterName.length > 0
                      ? serviceCenterName[0].name
                      : ""}
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    {printerId.length > 0 ? printerId[0].printer_name : ""}
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    <IconButton
                      onClick={() => handleDeleteSet(row)}
                      color="secondary"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonWidth}
          variant="contained"
          onClick={handleBack}
        >
          {t("printSetting.back")}
        </Button>
      </div>
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
      {errMessageList.length > 0 && (
        <MasterMaintenanceErrorMessageBox
          open={errorMessageOpen}
          handleClose={handleErrorMessageClose}
          message={errMessageList}
        />
      )}
    </form>
  );
};
