import { methods } from "../constants/common.constants";
import { http } from "./Http";

// AM0113
export const UpdateDZUserRegistrationAPI = (
  data: any,
  email: any,
  config: any
) => {
  const { affiliate_cd, baseURLShop, token } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/user_registration/${affiliate_cd}/${email}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0116
export const GetDZUserRegistrationListAPI = (config: any) => {
  const { affiliate_cd, baseURLShop, token } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/user_registration/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
