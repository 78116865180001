import React, { FC, useState, useEffect, useCallback } from "react";
import clsx from "clsx";
// route
import { useHistory, useParams } from "react-router-dom";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { JobsDetailGeneral } from "./components/JobsDetail.General";
import { JobsDetailCustomerInfo } from "./components/JobsDetail.CustomerInfo";
import { JobsDetailMemo } from "./components/JobsDetail.Memo";
import { JobsDetailPartsDrawing } from "./components/JobsDetail.PartsDrawing";
import { ModelWindow } from "../../modules/components/model/ModelWindow";
import { JobsDetailErrorMessageBox } from "./components/JobsDetail.ErrorMessageBox";
// UI
import { useStyles } from "./hooks/JobsDetail.styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PrintIcon from "@material-ui/icons/Print";
import Paper from "@material-ui/core/Paper";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import TextField from "@material-ui/core/TextField";
import {
  defaultValueBlank,
  defaultErrorValueBlank,
  OtherContents,
} from "./constants/JobsDetail.constants";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// api
import api from "../../modules/api/Api";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// methods
import {
  getApiResultStatus,
  downloadToImage,
  illegalCharactersErrorMessage,
} from "../../modules/common.methods";
// check
import {
  checkMinLength,
  checkMaxLength,
  isEmptyCheck,
  isEmptyNumber,
  isEmptyStringOrNumber,
  emailValidation,
  checkIllegalChar,
  checkIllegalCharUnicode,
  checkIllegalCharResult,
  checkIllegalCharResultUnicode,
  checkUmlautsCharUnicode,
} from "../../modules/hooks/UseValication";
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import { getMemoEditValue } from "./methods/JobsDetail.Common.Methods";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
const JOB_NO_QUERY_STRING = "?jobno=";

let _LoggerListManage: any = [];

export const Page: FC = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.jobDetail" });
  const { com } = useLocale();
  const statusList = com("common.constants.status");
  const [dataList, setDataList] = useState(defaultValueBlank);
  const [partsTabFlg, setPartsTabFlg] = useState(false);
  const [partsTabActive, setPartsTabActive] = useState(false);
  // hidden button
  const [saveButtonHiddenFlg, setSaveButtonHiddenFlg] = useState(false);
  const [printButtonHiddenFlg, setPrintButtonHiddenFlg] = useState(false);

  // form value
  const [formList, setFormList] = useState(defaultValueBlank);
  const [formErrorList, setFormErrorList] = useState(defaultErrorValueBlank);
  const [repairHistoryList, setRepairHistoryList] = useState([]);
  const [repairJobPartsList, setRepairJobPartsList] = useState([]);
  // media type dropdown value
  const [mediaType, setMediaType] = useState("");
  // memo tab's trigger send / save cancel button area
  const [status, setStatus] = useState(true);
  // memo tab's textarea's data
  const [memoEditData, setMemoEditData] = useState(getMemoEditValue());
  // memo
  const [otherMemoList, setOtherMemoList] = useState([]);
  const [internalUseMemoList, setInternalUseMemoList] = useState([]);
  const [customerMemorandum, setCustomerMemorandum] = useState({});
  // alert
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  // confirmation popup
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  // confirmation popup title
  const [popUpTitle, setPopUpTitle] = useState("");
  // confirmation popup content
  const [popUpContent, setPopUpContent] = useState("");
  // confirmation popup button
  const [popUpButton, setPopUpButton] = useState("");
  // confirmation popup type
  const [popupType, setPopupType] = useState("");
  // media row data
  const [mediaRowData, setMediaRowData] = useState({
    sub_no: 0,
    updated_date: "",
  });
  // check result Genaral Tab
  const [genaralTabError, setGenaralTabError] = useState(false);
  // check result Genaral Tab
  const [custInfoTabError, setCustInfoTabError] = useState(false);
  const [buttonFlg, setButtonFlg] = useState(false);
  const {
    getCommonInfo,
    getCommonConfig,
    setCommonInfo,
    setCommonInfoFull,
    getUserInfoRepair,
    setUserInfoRepair,
    getUserPermissions,
    setUserPermissions,
    getHotKey,
    setHotKey,
    getLoggerManage,
    setLoggerListManage,
    setClearLoggerListMagange,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  let commonInfo = getCommonInfo() as any;
  let hotKeyInfo = getHotKey() as any;
  let config = getCommonConfig() as any;
  let userInfoRepair = getUserInfoRepair() as any;
  let userPermissions = getUserPermissions() as any;
  const [startFlg, setStartFlg] = useState(false);
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const [jobNo, setJobNo] = useState(
    props.history.location.search.replace(JOB_NO_QUERY_STRING, "")
  );
  const [searchValue, setSearchValue] = useState("");
  const [jobNoInputFlg, setJobNoInputFlg] = useState(true);
  const [customerNoTemp, setCustomerNoTemp] = useState(formList.customer_no);
  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [errMessageList, setErrMessageList] = useState([]);
  const illegalChar: any = process.env.REACT_APP_ILLEGAL_CHAR;

  useEffect(() => {
    setLoggerListManageFun(`JD:F69:v='${history.location}'`);
    if (jobNo !== getNewJobNo()) {
      console.log("get job no", config.token);
      initializeByJobNumber();
    }
  }, [history.location]);

  // バグ調査のため、ログを取得
  let loggerMessage = getLoggerManage() as any;
  const setLoggerListManageFun = (text: any) => {
    setLoggerListManage(text);
    _LoggerListManage.push(text);
  };
  const setClearLoggerListMagangeFun = () => {
    _LoggerListManage = [];
    setClearLoggerListMagange();
  };

  const getNewJobNo = (): string => {
    return history.location.search.replace(JOB_NO_QUERY_STRING, "");
  };

  // error message
  const errorMessage = (response: any) => {
    apiLoading(false);
    console.log("errorMessage", response.key, response.message_id);
    const message = com("common.constants.messages." + response.message_id, {
      param1: response.key
        ? com("common.constants.messages_key_name." + response.key)
        : "",
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  const successMessage = (type: any, jobNo = "") => {
    let message = "";
    switch (type) {
      case "delete":
        message = com("common.constants.messages.MS0014");
        break;
      case "post":
        message = com("common.constants.messages.MS0015");
        break;
      case "resend":
        message = com("common.constants.messages.MS0024");
        break;
      case "save":
        message = com("common.constants.messages.MS0025", {
          jobNo: jobNo,
        });
        break;
      case "print":
        message = com("common.constants.messages.MS0026");
        break;
      case "register":
        message = com("common.constants.messages.MS0029");
        break;
      case "complete":
        message = com("common.constants.messages.MS0031", {
          jobNo: jobNo,
        });
        break;
      default:
        message = com("common.constants.messages.MS0015");
    }
    setAlertMessage(message);
    setAlertType("success");
    return message;
  };

  useEffect(() => {
    if (!startFlg && config.token) {
      setLoggerListManageFun(`JD:F70`);
      initializeByJobNumber();
    }
  }, [startFlg, config]);

  useEffect(() => {
    if (hotKeyInfo.handleF8Save && !saveButtonHiddenFlg) {
      setLoggerListManageFun(`JD:F71`);
      handleSave();
    }
    setHotKey("handleF8Save", false);
  }, [hotKeyInfo.handleF8Save]);

  useEffect(() => {
    if (hotKeyInfo.handleF9Refresh) {
      setLoggerListManageFun(`JD:F72`);
      handleRefresh();
    }
    setHotKey("handleF9Refresh", false);
  }, [hotKeyInfo.handleF9Refresh]);

  useEffect(() => {
    if (hotKeyInfo.handlePageDown) {
      setLoggerListManageFun(`JD:F73`);
      tabChangeDown();
    }
    setHotKey("handlePageDown", false);
  }, [hotKeyInfo.handlePageDown]);

  useEffect(() => {
    if (hotKeyInfo.handlePageUp) {
      setLoggerListManageFun(`JD:F74`);
      tabChangeUp();
    }
    setHotKey("handlePageUp", false);
  }, [hotKeyInfo.handlePageUp]);

  const initializeByJobNumber = () => {
    if (config.token) {
      let j = getNewJobNo();
      const sessionJobNo = sessionStorage.getItem("createJobNo");
      setLoggerListManageFun(
        `JD:F1:v='${j}',v='${sessionJobNo}',v='${dataList.job_no}'`
      );
      if (j === "") {
        if (dataList.job_no) {
          j = dataList.job_no;
        } else if (sessionJobNo) {
          j = sessionJobNo;
        }
      }
      setJobNo(j);
      initialize(j);
    }
  };

  // initialize
  const initialize = (param_jobNo: string) => {
    console.log("job detal start");
    handleRefresh();
    // init
    apiLoading(true);
    setStartFlg(true);
    // tab 初期化
    setPartsTabActive(false);
    setValue(0);

    let masterTechnicianList: any = [];
    let masterItemList: any = [];
    let masterCustomerList: any = [];
    let localUserInfoRepair = { service_center: "", country: "" };

    let erpUserListApi;
    let itemListApi;
    let customerListApi;
    let userInfoRepairApi;
    let repairJobPartsListApi;

    let status = "";
    let memorandum = "";

    // fetch master data
    // erp user master(technician) AM0405
    if (commonInfo.technicianList.length === 0) {
      erpUserListApi = api
        .getERPUserDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0405 response: ", response);
          if (!getApiResultStatus(code)) {
            let message = com(
              "common.constants.messages." + response.message_id,
              {
                param1: com(
                  "common.constants.messages_key_name." + response.key
                ),
              }
            );
            setAlertMessage(message);
            setAlertType("error");
          } else {
            masterTechnicianList = response;
          }
        });
    } else {
      masterTechnicianList = commonInfo.technicianList;
      erpUserListApi = true;
    }

    // item master AM0305
    if (!commonInfo.itemList || commonInfo.itemList.length === 0) {
      itemListApi = api
        .getItemDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0305 response: ", response);
          if (!getApiResultStatus(code)) {
            let message = com(
              "common.constants.messages." + response.message_id,
              {
                param1: com(
                  "common.constants.messages_key_name." + response.key
                ),
              }
            );
            setAlertMessage(message);
            setAlertType("error");
          } else {
            masterItemList = response;
          }
        });
    } else {
      masterItemList = commonInfo.itemList;
      itemListApi = true;
    }

    // get customer master list
    if (!commonInfo.customerList || commonInfo.customerList.length === 0) {
      customerListApi = api
        .getCustomerDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("get AM0205 customer master: ", response);
          if (!getApiResultStatus(code)) {
            let message = com(
              "common.constants.messages." + response.message_id,
              {
                param1: com(
                  "common.constants.messages_key_name." + response.key
                ),
              }
            );
            setAlertMessage(message);
            setAlertType("error");
          } else {
            masterCustomerList = response;
          }
        });
    } else {
      masterCustomerList = commonInfo.customerList;
      customerListApi = true;
    }

    // service center master AM0108
    if (!userInfoRepair.service_center) {
      userInfoRepairApi = api
        .getReadUserInfoAPIForUserinfoRepair(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0108 response: ", response);
          if (!getApiResultStatus(code)) {
            let message = com(
              "common.constants.messages." + response.message_id,
              {
                param1: com(
                  "common.constants.messages_key_name." + response.key
                ),
              }
            );
            setAlertMessage(message);
            setAlertType("error");
          } else {
            localUserInfoRepair = response;
          }
        });
    } else {
      localUserInfoRepair = userInfoRepair;
      userInfoRepairApi = true;
    }
    console.log("param_jobNo");
    repairJobPartsListApi = refreshPage(param_jobNo);

    Promise.all([
      erpUserListApi,
      itemListApi,
      customerListApi,
      userInfoRepairApi,
      repairJobPartsListApi,
    ]).then(() => {
      setLoggerListManageFun(
        `JD:F2:v='${param_jobNo}',v='${commonInfo.technicianList ? commonInfo.technicianList.length : 0
        }',v='${commonInfo.itemList.length}',v='${commonInfo.customerList ? commonInfo.customerList.length : 0
        }',v='${userInfoRepair.service_center
          ? userInfoRepair.service_center.length
          : 0
        }'`
      );

      let getJobDetailApi;
      let getRepairHistoryApi;
      // fetch job detail AT0102
      getJobDetailApi = handleInitEvent(
        param_jobNo,
        localUserInfoRepair.service_center
      ).then(async (response: any) => {
        // fetch repair history AT0101
        if (
          response &&
          response.model_no !== "" &&
          response.serial_no !== "" &&
          response.serial_no !== "*"
        ) {
          setLoggerListManageFun(
            `JD:F2_F1:v='${response.model_no}',v='${response.serial_no}',v='${response.status}',v='${response.technician_cd}',v='${response.service_center_id}',v='${response.job_type}'`
          );
          let query_parameters =
            "model_no=" +
            encodeURIComponent(response.model_no) +
            "&serial_no=" +
            encodeURIComponent(response.serial_no) +
            "&claim_no=" +
            encodeURIComponent(0) +
            "&job_no=" +
            encodeURIComponent(Number(param_jobNo) ? Number(param_jobNo) : 0);
          getRepairHistoryApi = await getRepairHistory(
            query_parameters,
            Number(param_jobNo)
          );
        } else {
          getRepairHistoryApi = true;
        }
        if (response) {
          status = response.status;
          memorandum = response.memorandum;
        } else {
          // TODO disable
        }
      });

      // waiting for job detail loading
      Promise.all([getJobDetailApi, getRepairHistoryApi]).then(() => {
        setCommonInfoFull({
          technicianList: masterTechnicianList,
          itemList: masterItemList,
          customerList: masterCustomerList,
          loadingFlg: false,
        });
        setUserInfoRepair(localUserInfoRepair);

        // display the pupup of memorandum
        if (status && memorandum && memorandum !== "" && Number(status) < 400) {
          setConfirmationOpen(true);
          setPopUpTitle("prompt_title_memorandum");
          setPopUpContent(memorandum);
          setPopUpButton("notification");
        }
      });
    });
  };

  // common search method
  const getRepairHistory = (query_parameters: string, job_no: number) => {
    setLoggerListManageFun(`JD:F3:v='${query_parameters}',v='${job_no}'`);
    setRepairHistoryList([]);
    // Read Repair Job List AT1708
    return api
      .getReadRepairHistoryListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1708 response: ", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response.history_list && response.history_list.length > 0) {
            response.history_list.map((item: any) => {
              if (item.history_no) {
                let obj: any = {
                  history_no: item.history_no,
                  entry_date: item.entry_date,
                  customer_no: item.customer_no,
                  type_kind: item.type_kind,
                };
                newList.push(obj);
              }
            });
          }
          setRepairHistoryList(newList);
        }
      });
  };

  // fetch the detail info AT0102
  const getReadRepairList = (
    jobNo: string,
    type: any = "",
    needCloseLoading = false,
    errorExsit = false
  ) => {
    setLoggerListManageFun(
      `JD:F4:v='${jobNo}',v='${type}',v='${needCloseLoading}',v='${errorExsit}'`
    );
    setGenaralTabError(false);
    setCustInfoTabError(false);
    return api
      .getReadRepairJobAPI(Number(jobNo), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT0102 response: ", response);
        if (getApiResultStatus(code)) {
          setLoggerListManageFun(
            `JD:F4_F1:v='${response.model_no}',v='${response.serial_no}',v='${response.status}',v='${response.technician_cd}',v='${response.service_center_id}',v='${response.job_type}'`
          );
          // edit the JobDetail Response
          let editedResponse = editJobDetailResponse(response, type);
          // set permission of job detail
          setPermission(editedResponse.status, editedResponse.quote_result);
          if (type && !errorExsit) {
            successMessage(type, jobNo);
          }
          if (needCloseLoading) {
            apiLoading(false);
          }
          return editedResponse;
        } else {
          errorMessage(response);
        }
        if (needCloseLoading) {
          apiLoading(false);
        }
      });
  };

  // set permission of job detail
  const setPermission = async (status: string, quoteResult: any) => {
    let localUserPermissions = {};
    if (userPermissions.permission_group) {
      localUserPermissions = userPermissions;
    } else {
      await getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
    }

    permissionsInfomation.setPermissionsInfo(
      localUserPermissions,
      status,
      quoteResult
    );
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    console.log("userPermissionsInfo", userPermissionsInfo);
    setUserPermisssionInfo(userPermissionsInfo);
  };

  const editJobDetailResponse = (response: any, type: any = ""): any => {
    setLoggerListManageFun(
      `JD:F5:v='${response.model_no}',v='${response.serial_no}',v='${response.status}',v='${response.technician_cd}',v='${response.service_center_id}',v='${response.job_type}'`
    );
    let editedJobDetailResponse = response;
    console.log("response:", response);

    // status name
    editedJobDetailResponse.statusName = getStatusName(
      editedJobDetailResponse.status
    );

    // DZ Order No.
    if (!editedJobDetailResponse.dz_order_no) {
      editedJobDetailResponse.dz_order_no = "";
    }

    // Customer No.
    if (!editedJobDetailResponse.customer_no) {
      editedJobDetailResponse.customer_no = "";
    } else {
      if (
        type !== "save" &&
        type !== "parts_register" &&
        type !== "post" &&
        type !== "patch" &&
        type !== "delete" &&
        (editedJobDetailResponse.status === "100" ||
          editedJobDetailResponse.status === "200" ||
          editedJobDetailResponse.status === "300" ||
          editedJobDetailResponse.status === "400" ||
          editedJobDetailResponse.status === "410" ||
          editedJobDetailResponse.status === "500" ||
          editedJobDetailResponse.status === "510")
      ) {
        api
          .getReadCustomerMasterAPI(editedJobDetailResponse.customer_no, config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0202 response", response);
            if (!getApiResultStatus(code)) {
              let message = com("common.constants.messages.MS0004", {
                param1: t("general.label.customer_no"),
              });
              setFormErrorListValue("customer_no_error", message);
            } else {
              if (response.customer_closed === true) {
                let message = com("common.constants.messages.MS0085", {
                  param1: response.customer_no,
                });
                setAlertMessage(message);
                setAlertType("warning");
              }
            }
          });
      }
    }

    // Received Date
    if (
      editedJobDetailResponse.received_date &&
      editedJobDetailResponse.received_date !== ""
    ) {
      let dateTimeLong = Date.parse(editedJobDetailResponse.received_date);
      editedJobDetailResponse.received_date_date = dateTimeLong;
      editedJobDetailResponse.received_date_time = dateTimeLong;
    } else {
      editedJobDetailResponse.received_date_date = null;
      editedJobDetailResponse.received_date_time = null;
    }

    // Repair Option
    if (!editedJobDetailResponse.repair_option) {
      editedJobDetailResponse.repair_option = "";
    }

    // Limit Amount
    if (!editedJobDetailResponse.limit_amount) {
      editedJobDetailResponse.limit_amount = "";
    }

    // Quote Sent Date

    // Reply Date
    if (
      editedJobDetailResponse.reply_date &&
      editedJobDetailResponse.reply_date !== ""
    ) {
      let dateTimeString = Date.parse(editedJobDetailResponse.reply_date);
      editedJobDetailResponse.reply_date_date = dateTimeString;
      editedJobDetailResponse.reply_date_time = dateTimeString;
    } else {
      editedJobDetailResponse.reply_date_date = null;
      editedJobDetailResponse.reply_date_time = null;
    }

    // Quote Result
    if (!editedJobDetailResponse.quote_result) {
      editedJobDetailResponse.quote_result = "";
    }

    // Trade-In Item Price

    // Repair Note
    let newOtherList: any = [];
    let newInternalList: any = [];
    if (
      editedJobDetailResponse.repair_note &&
      editedJobDetailResponse.repair_note.length > 0
    ) {
      editedJobDetailResponse.repair_note.forEach((item: any) => {
        if (item.note_type === 10) {
          // Memo For Internal Use
          newInternalList.push(item);
        } else if (item.note_type === 50) {
          // Other Contents
          newOtherList.push(item);
        } else if (item.note_type === 30) {
          // Fault Description
          editedJobDetailResponse.noteFaultDescription = item;
          // editedJobDetailResponse.noteFaultDescription.job_no =
          //   editedJobDetailResponse.job_no;
        } else if (item.note_type === 40) {
          // Partial Repair Memo
          editedJobDetailResponse.notePartialRepairMemo = item;
          // editedJobDetailResponse.notePartialRepairMemo.job_no =
          //   editedJobDetailResponse.job_no;
        }
      });
    }
    // memo list
    setOtherMemoList(newOtherList);
    setInternalUseMemoList(newInternalList);
    const newCustomerMemorandum = {
      note: editedJobDetailResponse.memorandum,
    };
    setCustomerMemorandum(newCustomerMemorandum);

    // Repair Contact
    if (
      editedJobDetailResponse.repair_contact &&
      editedJobDetailResponse.repair_contact.length > 0
    ) {
      editedJobDetailResponse.repair_contact.forEach((item: any) => {
        if (item.contact_type === 1) {
          // Dealer(Customer)
          editedJobDetailResponse.contactDealer = item;
        } else if (item.contact_type === 2) {
          // EndUser
          editedJobDetailResponse.contactEndUser = item;
        }
      });
    }

    // Repair Address
    if (
      editedJobDetailResponse.repair_address &&
      editedJobDetailResponse.repair_address.length > 0
    ) {
      editedJobDetailResponse.repair_address.forEach((item: any) => {
        if (item.address_type === 1) {
          // Pickup
          editedJobDetailResponse.addressPickup = item;
        } else if (item.address_type === 2) {
          // Return To(From Makita)
          editedJobDetailResponse.addressReturnTo = item;
        } else if (item.address_type === 3) {
          // Bill To
          editedJobDetailResponse.addressBillTo = item;
        }
      });
    }

    const localDataList = Object.assign({}, formList, editedJobDetailResponse);
    const localFormList = Object.assign({}, formList, editedJobDetailResponse);
    if (
      !editedJobDetailResponse.limit_amount_currency ||
      editedJobDetailResponse.limit_amount_currency === ""
    ) {
      localFormList.limit_amount_currency = editedJobDetailResponse.currency;
    }
    setFormList(localFormList);
    setDataList(localDataList);

    return editedJobDetailResponse;
  };

  // fetch the status name from constants
  const getStatusName = (code: string) => {
    let statusName = "";
    if (code && code !== "") {
      for (let index in statusList) {
        if (statusList[index].code === code) {
          statusName = statusList[index].name;
          break;
        }
      }
    }
    return statusName;
  };

  // create new job header AT0101
  const createRepairJob = (service_center_id: string) => {
    setLoggerListManageFun(`JD:F6:v='${service_center_id}'`);
    const param = {
      service_center_id: service_center_id,
      job_type: 1,
      status: "300",
      logger: loggerMessage,
      loggerMessage: _LoggerListManage,
    };
    return api
      .createRepairJobAPI(param, config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT0101 response: ", response);
        sessionStorage.setItem("createJobNo", response.job_no);

        if (getApiResultStatus(code)) {
          // clear log
          setClearLoggerListMagangeFun();
          // edit the JobDetail Response
          let editedResponse = editJobDetailResponse(response);
          // set permission of job detail
          setPermission(editedResponse.status, editedResponse.quote_result);
          // edit the JobDetail Response
          return editedResponse;
        } else {
          errorMessage(response);
        }
      });
  };

  // init the job detail
  const handleInitEvent = (jobNo: string, service_center_id: string) => {
    setLoggerListManageFun(`JD:F7:v='${jobNo}',v='${service_center_id}'`);
    // register a new job
    if (jobNo === "") {
      // register a new job AT0101
      return createRepairJob(service_center_id);
    } else {
      // fetch the detail info AT0102
      return getReadRepairList(jobNo);
    }
  };

  // tab
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setJobNoInputFlg(true);
    if (newValue === 2) {
      console.log("partsTabActive:", partsTabActive);
      if (!partsTabActive) {
        setPartsTabFlg(true);
      }
      const { diffFlg } = diffContents();
      if (diffFlg) {
        const message = t("general.label.save_message");
        setAlertMessage(message);
        setAlertType("warning");
        return;
      }
    }
    setAlertMessage("");
    setSaveButtonHiddenFlg(false);
    setPrintButtonHiddenFlg(false);
    if (newValue === 0) {
      refreshPage(dataList.job_no);
    } else if (newValue === 2) {
      if (!partsTabActive) {
        setPartsTabFlg(true);
      }
      setSaveButtonHiddenFlg(true);
    } else if (newValue === 3) {
      setSaveButtonHiddenFlg(true);
    }
    setValue(newValue);
  };

  const tabChangeDown = () => {
    setJobNoInputFlg(true);
    setAlertMessage("");
    setPartsTabFlg(false);
    setSaveButtonHiddenFlg(false);
    setPrintButtonHiddenFlg(false);
    let newValue = 0;
    if (value < 3) {
      newValue = value + 1;
      if (newValue === 2 && isPartsDisable()) {
        newValue++;
      }
    }
    if (newValue === 0) {
      refreshPage(dataList.job_no);
    } else if (newValue === 2) {
      if (!partsTabActive) {
        setPartsTabFlg(true);
      }
      setSaveButtonHiddenFlg(true);
    } else if (newValue === 3) {
      setSaveButtonHiddenFlg(true);
    }
    setValue(newValue);
  };

  const tabChangeUp = () => {
    setJobNoInputFlg(true);
    setAlertMessage("");
    setPartsTabFlg(false);
    setSaveButtonHiddenFlg(false);
    setPrintButtonHiddenFlg(false);
    let newValue = 0;
    if (value === 0) {
      newValue = 3;
    } else if (value <= 3) {
      newValue = value - 1;
      if (newValue === 2 && isPartsDisable()) {
        newValue--;
      }
    }
    if (newValue === 0) {
      refreshPage(dataList.job_no);
    } else if (newValue === 2) {
      if (!partsTabActive) {
        setPartsTabFlg(true);
      }
      setSaveButtonHiddenFlg(true);
    } else if (newValue === 3) {
      setSaveButtonHiddenFlg(true);
    }
    setValue(newValue);
  };

  const refreshPage = (jobNo: any) => {
    setLoggerListManageFun(`JD:F8:v='${jobNo}'`);
    if (jobNo !== "" && jobNo !== undefined) {
      return api
        .getReadRepairJobPartsListAPI(jobNo, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("AT0202 response: ", response);
          let list = [];
          if (getApiResultStatus(code)) {
            if (response && response.length > 0) {
              setLoggerListManageFun(`JD:F8_GS:v='${response.length}'`);
              list = response.slice(0);
              setRepairJobPartsList(list);
            }
          } else {
            setRepairJobPartsList([]);
            if (code !== 404) {
              errorMessage(response);
            }
          }
        });
    }
  };

  const isPartsDisable = () => {
    return (
      !dataList.repair_option ||
      dataList.repair_option === "" ||
      ((dataList.repair_option === 1 || dataList.repair_option === 2) &&
        (!dataList.warranty_approved_by ||
          dataList.warranty_approved_by === "")) ||
      isEmptyCheck(dataList.model_no) ||
      isEmptyCheck(dataList.serial_no)
    );
  };

  // set data
  const setFormListValue = (key: string, value: any, subKey: string = "") => {
    if (subKey !== "") {
      setFormList((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setFormList((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  // set data
  const setDataListValue = (key: string, value: any, subKey: string = "") => {
    if (subKey !== "") {
      setDataList((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setDataList((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  // set error value
  const setFormErrorListValue = (key: string, value: any) => {
    setFormErrorList((prevState) => ({ ...prevState, [key]: value }));
  };

  // change memo textarea value
  const ChangeTextAreaValue = (value: any) => {
    const newData = Object.assign({}, memoEditData, { note: value });
    setMemoEditData(newData);
  };

  const handleClick = () => {
    setLoggerListManageFun(`JD:F9:v='${status}'`);
    setAlertMessage("");
    apiLoading(true);
    // create
    if (status) {
      const data = {
        job_no: formList.job_no,
        note_type: 10,
        note: memoEditData.note,
      };
      // AT0110
      api
        .createRepairNoteAPI(data, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("createRepairNoteAPI response", response);
          if (getApiResultStatus(code)) {
            getReadRepairList(formList.job_no, "post", true);
          } else {
            errorMessage(response);
          }
        });
    } else {
      // modify
      setStatus(true);
      console.log("memoEditData", memoEditData);
      const data = {
        repair_note: [memoEditData],
        updated_date: dataList.updated_date,
        logger: loggerMessage,
        loggerMessage: _LoggerListManage,
      };

      // AT0103
      api
        .updateRepairJobAPI(formList.job_no, data, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("updateRepairJobAPI response", response);
          if (getApiResultStatus(code)) {
            // clear log
            setClearLoggerListMagangeFun();
            getReadRepairList(formList.job_no, "patch", true);
          } else {
            errorMessage(response);
          }
        });
    }
    setMemoEditData(getMemoEditValue());
  };

  const handleCancel = () => {
    setLoggerListManageFun(`JD:F10`);
    setStatus(true);
    setMemoEditData(getMemoEditValue());
    setAlertMessage("");
  };

  const handleEditEvent = (data: any) => {
    setLoggerListManageFun(`JD:F11`);
    setStatus(false);
    const newData = Object.assign({}, data);
    setMemoEditData(newData);
  };

  // delete repair note
  const handleDeleteEvent = (item: any) => {
    setLoggerListManageFun(`JD:F12`);
    setAlertMessage("");
    apiLoading(true);
    const data = {
      updated_date: item.updated_date,
    };
    // AT0112
    api
      .deleteRepairNoteAPI(
        formList.job_no,
        item.note_type,
        item.sub_no,
        data,
        config
      )
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("updateRepairJobAPI response", response);
        if (getApiResultStatus(code)) {
          getReadRepairList(formList.job_no, "delete", true);
        } else {
          errorMessage(response);
        }
      });
  };

  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };

  // Refresh button click event
  const handleRefresh = () => {
    setLoggerListManageFun(`JD:F13`);
    setJobNoInputFlg(true);
    setAlertMessage("");
    setStartFlg(false);
    setFormErrorList(defaultErrorValueBlank);
    setConfirmationOpen(false);
    setPopUpTitle("");
    setPopUpContent("");
    setPopUpButton("");
    setPopupType("");
    setGenaralTabError(false);
    setCustInfoTabError(false);
    setSaveButtonHiddenFlg(false);
  };

  // Refresh button click event
  const handleRefresh2 = () => {
    setLoggerListManageFun(`JD:F14:v='${value}'`);
    setJobNoInputFlg(true);
    setPartsTabActive(false);
    if (value === 2) {
      setPartsTabFlg(true);
    } else {
      setPartsTabFlg(false);
    }
    handleRefresh();
  };

  const updateBaseData = () => {
    setLoggerListManageFun(`JD:F15`);
    getReadRepairList(formList.job_no, "parts_register", true, true);
  };

  // save button click event
  const handleSave = () => {
    setLoggerListManageFun(
      `JD:F16:v1='${formList.model_no}',v2='${formList.serial_no}',v3='${formList.status}',v4='${formList.technician_cd}',v5='${formList.service_center_id}',v6='${formList.job_type}',v7='${userInfoRepair.erp_user_id},v8='${userInfoRepair.erp_user_id}'`
    );
    setJobNoInputFlg(true);
    setPartsTabActive(false);
    setAlertMessage("");
    runAllValidations().then((checkResult) => {
      if (!checkResult) {
        return;
      } else {
        apiLoading(true);
        const {
          needUpdateData,
          needInsertNote,
          needInsertContact,
          needInsertAddress,
        } = diffContents();
        // call api to update the job detail.
        callSaveApis(
          needUpdateData,
          needInsertNote,
          needInsertContact,
          needInsertAddress
        );
      }
    });
  };

  const diffContents = () => {
    let diffFlg = false;
    // let needUpdateData1: { [key: string]: any } = {};
    // update data object
    let needUpdateData: { [key: string]: any } = {};
    // update note array
    let repair_note: any = [];
    // update contact array
    let repair_contact: any = [];
    // update address array
    let repair_address: any = [];

    // insert note data array
    let needInsertNote: any = [];
    // insert contact data array
    let needInsertContact: any = [];
    // insert address data array
    let needInsertAddress: any = [];

    let dateChangedFlgObject = {
      // received_date changed flg
      isReceivedDateChanged: false,
      // reply_date changed flg
      isReplyDateChanged: false,
    };

    // skip properties array
    let skipProperties = [
      "customer_name",
      "currency",
      "received_date",
      "received_date_date",
      "received_date_time",
      "reply_date",
      "reply_date_date",
      "reply_date_time",
    ];

    // loop the dataList by key
    Object.keys(dataList).map((key) => {
      // skip loop when the property is array
      if (!Array.isArray(dataList[key])) {
        if (
          Object.prototype.toString.call(dataList[key]) !==
          "[object Boolean]" &&
          !dataList[key]
        ) {
          if (formList[key] !== dataList[key]) {
            // check the received_date and reply_date whether changed and edit the date value.
            editDateWhenFirstUpdate(key, needUpdateData, dateChangedFlgObject);
            if (skipProperties.indexOf(key) < 0) {
              needUpdateData[key] = formList[key];
            }
          }
        } else if (
          Object.prototype.toString.call(dataList[key]) === "[object Boolean]"
        ) {
          if (formList[key] !== dataList[key]) {
            needUpdateData[key] = formList[key];
          }
        } else if (
          Object.prototype.toString.call(dataList[key]) === "[object Object]"
        ) {
          // when the property is object(note, contact, address)
          // set the note, contact, addree info when need to update or insert.
          setUpdateObject(
            key,
            repair_note,
            repair_contact,
            repair_address,
            needInsertNote,
            needInsertContact,
            needInsertAddress
          );
        } else {
          if (formList[key] !== dataList[key]) {
            // check the received_date and reply_date whether changed and edit the date value.
            editDateWhenChanged(key, needUpdateData, dateChangedFlgObject);
            if (skipProperties.indexOf(key) < 0) {
              needUpdateData[key] = formList[key];
            }
          }
        }
      }
    });

    // set update array
    if (repair_note.length > 0) {
      needUpdateData.repair_note = repair_note;
    }
    if (repair_contact.length > 0) {
      needUpdateData.repair_contact = repair_contact;
    }
    if (repair_address.length > 0) {
      needUpdateData.repair_address = repair_address;
    }

    // set job_no, updated_date
    if (Object.keys(needUpdateData).length > 0) {
      let flg = false;
      for (let property in needUpdateData) {
        if (
          property === "customer_no" ||
          property === "model_no" ||
          property === "repair_option" ||
          property === "serial_no" ||
          property === "quote_result"
        ) {
          flg = true;
        }
      }
      if (flg) {
        diffFlg = true;
      }
    }
    needUpdateData.job_no = formList.job_no;
    needUpdateData.updated_date = formList.updated_date;
    needUpdateData.taskForQAExcluded = "JobDetail";

    setLoggerListManageFun(
      `JD:F16_F1:v1='${needUpdateData.technician_cd}',v2='${userInfoRepair.erp_user_id
      }',v3='${formList.technician_cd !== userInfoRepair.erp_user_id}',v4='${userInfoRepair.erp_user_id
      }'`
    );
    if (
      needUpdateData.technician_cd === undefined &&
      userInfoRepair.erp_user_id
    ) {
      if (formList.technician_cd !== userInfoRepair.erp_user_id) {
        setLoggerListManageFun(
          `JD:F16_F3:v1='${formList.technician_cd}',v2='${userInfoRepair.erp_user_id}'`
        );
        needUpdateData.technician_cd = userInfoRepair.erp_user_id;
        if (
          repairJobPartsList.length <= 0 &&
          (formList.status === "100" ||
            formList.status === "200" ||
            formList.status === "300" ||
            formList.status === "400" ||
            formList.status === "410" ||
            formList.status === "500") &&
          formList.quote_result === ""
        ) {
          setLoggerListManageFun(
            `JD:F16_F4:v='${userInfoRepair.service_center}'`
          );
          needUpdateData.service_center_id = userInfoRepair.service_center;
        }
      } else {
        setLoggerListManageFun(
          `JD:F16_F5:v1='${formList.technician_cd}',v2='${userInfoRepair.erp_user_id}',v3='${formList.service_center_id}',v4='${userInfoRepair.service_center}'`
        );
        // technician_cd is same, when change service_center
        if (
          formList.technician_cd === userInfoRepair.erp_user_id &&
          formList.service_center_id !== userInfoRepair.service_center
        ) {
          setLoggerListManageFun(`JD:F16_F6:v1='${formList.status}'`);
          if (
            repairJobPartsList.length <= 0 &&
            (formList.status === "100" ||
              formList.status === "200" ||
              formList.status === "300" ||
              formList.status === "400" ||
              formList.status === "410" ||
              formList.status === "500") &&
            formList.quote_result === ""
          ) {
            setLoggerListManageFun(
              `JD:F16_F7:v='${userInfoRepair.service_center}'`
            );
            needUpdateData.service_center_id = userInfoRepair.service_center;
          }
        }
      }
    } else {
      setLoggerListManageFun(
        `JD:F16_F2:v1='${needUpdateData.technician_cd}',v2='${userInfoRepair.erp_user_id
        }',v3='${formList.technician_cd !== userInfoRepair.erp_user_id}',v4='${userInfoRepair.erp_user_id
        }'`
      );
    }

    // add
    if (formList.status === "500" && formList.quote_result) {
      needUpdateData.status = "510";
    }

    console.log(
      "change",
      needUpdateData,
      needInsertNote,
      needInsertContact,
      needInsertAddress,
      diffFlg
    );
    return {
      needUpdateData,
      needInsertNote,
      needInsertContact,
      needInsertAddress,
      diffFlg,
    };
  };

  // call api to update the job detail.
  const callSaveApis = async (
    needUpdateData: any,
    needInsertNote: any[],
    needInsertContact: any[],
    needInsertAddress: any[]
  ) => {
    if (
      (dataList.repair_option === 3 ||
        dataList.repair_option === 4 ||
        dataList.repair_option === 5) &&
      (formList.repair_option === 1 || formList.repair_option === 2)
    ) {
      if (
        dataList.limit_amount !== undefined &&
        dataList.limit_amount !== "" &&
        dataList.limit_amount !== 0 &&
        dataList.limit_amount !== null
      ) {
        needUpdateData.limit_amount = 0;
      }
      if (
        dataList.quote_sent_date !== undefined &&
        dataList.quote_sent_date !== "" &&
        dataList.quote_sent_date !== null
      ) {
        needUpdateData.quote_sent_date = "";
      }
      if (
        dataList.quote_sent_count !== undefined &&
        dataList.quote_sent_count !== "" &&
        dataList.quote_sent_count !== 0 &&
        dataList.quote_sent_count !== null
      ) {
        needUpdateData.quote_sent_count = 0;
      }
      if (
        dataList.quote_url !== undefined &&
        dataList.quote_url !== "" &&
        dataList.quote_url !== null
      ) {
        needUpdateData.quote_url = "";
      }
      if (
        dataList.reply_date !== undefined &&
        dataList.reply_date !== "" &&
        dataList.reply_date !== null
      ) {
        needUpdateData.reply_date = "";
      }
      if (
        dataList.reply_date_date !== undefined &&
        dataList.reply_date_date !== "" &&
        dataList.reply_date_date !== 0 &&
        dataList.reply_date_date !== null
      ) {
        needUpdateData.reply_date_date = null;
      }
      if (
        dataList.reply_date_time !== undefined &&
        dataList.reply_date_time !== "" &&
        dataList.reply_date_time !== 0 &&
        dataList.reply_date_time !== null
      ) {
        needUpdateData.reply_date_time = null;
      }
      if (
        dataList.quote_result !== undefined &&
        dataList.quote_result !== "" &&
        dataList.quote_result !== 0 &&
        dataList.quote_result !== null
      ) {
        needUpdateData.quote_result = 0;
      }
    }
    // add log
    needUpdateData.logger = loggerMessage;
    needUpdateData.loggerMessage = _LoggerListManage;

    let errorStop = false;

    if (!errorStop && needInsertNote.length > 0) {
      for (let i = 0; i < needInsertNote.length; i++) {
        // Create Repair note API AT0110
        await api
          .createRepairNoteAPI(needInsertNote[i], config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("AT0110 response", response);
            if (!getApiResultStatus(code)) {
              errorMessage(response);
              errorStop = true;
            }
          });
        if (errorStop) {
          break;
        }
      }
    }

    // insert contact data array
    if (!errorStop && needInsertContact.length > 0) {
      for (var i = 0; i < needInsertContact.length; i++) {
        // Create Repair note API AT0120
        await api
          .createRepairContactAPI(needInsertContact[i], config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("AT0120 response", response);
            if (!getApiResultStatus(code)) {
              errorMessage(response);
              errorStop = true;
            }
          });
        if (errorStop) {
          break;
        }
      }
    }

    // insert address data array
    if (!errorStop && needInsertAddress.length > 0) {
      for (var i = 0; i < needInsertAddress.length; i++) {
        // Create Repair note API AT0121
        await api
          .createRepairAddressAPI(needInsertAddress[i], config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("AT0121 response", response);
            if (!getApiResultStatus(code)) {
              errorMessage(response);
              errorStop = true;
            }
          });
        if (errorStop) {
          break;
        }
      }
    }

    // Update Repair Job API AT0103
    await api
      .updateRepairJobAPI(formList.job_no, needUpdateData, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT0103 response: ", response);
        if (!getApiResultStatus(code)) {
          // clear log
          setClearLoggerListMagangeFun();
          errorMessage(response);
          errorStop = true;
        }
      });

    let errorExsit = false;
    if (errorStop) {
      errorExsit = true;
    } else {

    }

    // fetch the detail info AT0102
    getReadRepairList(formList.job_no, "save", true, errorExsit);   
  };

  // set the note, contact, addree info when need to update or insert.
  const setUpdateObject = (
    key: string,
    repair_note: any[],
    repair_contact: any[],
    repair_address: any[],
    needInsertNote: any[],
    needInsertContact: any[],
    needInsertAddress: any[]
  ) => {
    if (key.startsWith("note")) {
      // when the object is note
      // console.log("note【formList】key[" + key + "]: " + formList[key]);
      let updateObject = getNeedUpdateObject(key, "note");
      if (formList[key].updated_date) {
        // need update
        if (updateObject.isNeedUpdateFlg) {
          repair_note.push(updateObject.data);
        }
      } else {
        // need insert
        needInsertNote.push(updateObject.data);
      }
    } else if (key.startsWith("contact")) {
      // when the object is contact
      // console.log(
      //   "contact【formList】key[" + key + "]: " + formList[key]
      // );
      let updateObject = getNeedUpdateObject(key, "contact");
      if (formList[key].updated_date) {
        // need update
        if (updateObject.isNeedUpdateFlg) {
          repair_contact.push(updateObject.data);
        }
      } else {
        // need insert
        needInsertContact.push(updateObject.data);
      }
    } else if (key.startsWith("address")) {
      // when the object is address
      // console.log(
      //   "address【formList】key[" + key + "]: " + formList[key]
      // );
      let updateObject = getNeedUpdateObject(key, "address");
      if (formList[key].updated_date) {
        if (updateObject.isNeedUpdateFlg) {
          // need update
          repair_address.push(updateObject.data);
        }
      } else {
        // need insert
        needInsertAddress.push(updateObject.data);
      }
    }
  };

  // check the received_date and reply_date whether changed and edit the date value.
  const editDateWhenFirstUpdate = (
    key: string,
    needUpdateData: any,
    dateChangedFlgObject: any
  ) => {
    if (key === "received_date_date") {
      // check and edit the receivedDate
      // eg. dateTime:[2021-01-22T19:39:12], date:[01/22/2012]
      if (!dateChangedFlgObject.isReceivedDateChanged) {
        if (formList.received_date_date && formList.received_date_date !== "") {
          let received_date_form = new Date(
            formList.received_date_date
          ).toLocaleDateString("en-US");

          let received_time_form = new Date(
            formList.received_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          needUpdateData.received_date = new Date(
            received_date_form + " " + received_time_form
          ).toISOString();
        }

        dateChangedFlgObject.isReceivedDateChanged = true;
      }
    } else if (key === "received_date_time") {
      // check and edit the receivedDate
      // eg. dateTime:[2021-01-22T19:39:12], time:[19:39]
      if (!dateChangedFlgObject.isReceivedDateChanged) {
        if (formList.received_date_time && formList.received_date_time !== "") {
          let received_time_form = new Date(
            formList.received_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          let received_date_form = new Date(
            formList.received_date_date
          ).toLocaleDateString("en-US");

          needUpdateData.received_date = new Date(
            received_date_form + " " + received_time_form
          ).toISOString();
        }
        dateChangedFlgObject.isReceivedDateChanged = true;
      }
    } else if (key === "reply_date_date") {
      // check and edit the replyDate
      // eg. dateTime:[2021-01-22T19:39:12], date:[01/22/2012]
      if (!dateChangedFlgObject.isReplyDateChanged) {
        if (formList.reply_date_date && formList.reply_date_date !== "") {
          let reply_date_form = new Date(
            formList.reply_date_date
          ).toLocaleDateString("en-US");

          let reply_time_form = new Date(
            formList.reply_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          needUpdateData.reply_date = new Date(
            reply_date_form + " " + reply_time_form
          ).toISOString();
        }
        dateChangedFlgObject.isReplyDateChanged = true;
      }
    } else if (key === "reply_date_time") {
      // check and edit the replyDate
      // eg. dateTime:[2021-01-22T19:39:12], time:[19:39]
      if (!dateChangedFlgObject.isReplyDateChanged) {
        if (formList.reply_date_time && formList.reply_date_time !== "") {
          let reply_time_form = new Date(
            formList.reply_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          let reply_date_form = new Date(
            formList.reply_date_date
          ).toLocaleDateString("en-US");

          needUpdateData.reply_date = new Date(
            reply_date_form + " " + reply_time_form
          ).toISOString();
        }
        dateChangedFlgObject.isReplyDateChanged = true;
      }
    }
  };

  // check the received_date and reply_date whether changed and edit the date value.
  const editDateWhenChanged = (
    key: string,
    needUpdateData: any,
    dateChangedFlgObject: any
  ) => {
    if (key === "received_date_date") {
      // check and edit the receivedDate
      // eg. dateTime:[2021-01-22T19:39:12], date:[01/22/2012]
      if (!dateChangedFlgObject.isReceivedDateChanged) {
        if (formList.received_date_date && formList.received_date_date != "") {
          let received_date_form = new Date(
            formList.received_date_date
          ).toLocaleDateString("en-US");

          let received_date_data = new Date(
            dataList.received_date_date
          ).toLocaleDateString("en-US");

          if (received_date_form !== received_date_data) {
            let received_time_form = new Date(
              formList.received_date_time
            ).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });

            needUpdateData.received_date = new Date(
              received_date_form + " " + received_time_form
            ).toISOString();
          }
        } else {
          needUpdateData.received_date = "";
        }
        dateChangedFlgObject.isReceivedDateChanged = true;
      }
    } else if (key === "received_date_time") {
      // check and edit the receivedDate
      // eg. dateTime:[2021-01-22T19:39:12], time:[19:39]
      if (!dateChangedFlgObject.isReceivedDateChanged) {
        if (formList.received_date_time && formList.received_date_time != "") {
          let received_time_form = new Date(
            formList.received_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          let received_time_data = new Date(
            dataList.received_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          if (received_time_form !== received_time_data) {
            let received_date_form = new Date(
              formList.received_date_date
            ).toLocaleDateString("en-US");

            needUpdateData.received_date = new Date(
              received_date_form + " " + received_time_form
            ).toISOString();
          }
        } else {
          needUpdateData.received_date = "";
        }
        dateChangedFlgObject.isReceivedDateChanged = true;
      }
    } else if (key === "reply_date_date") {
      // check and edit the replyDate
      // eg. dateTime:[2021-01-22T19:39:12], date:[01/22/2012]
      if (!dateChangedFlgObject.isReplyDateChanged) {
        if (formList.reply_date_date && formList.reply_date_date != "") {
          let reply_date_form = new Date(
            formList.reply_date_date
          ).toLocaleDateString("en-US");

          let reply_date_data = new Date(
            dataList.reply_date_date
          ).toLocaleDateString("en-US");

          if (reply_date_form !== reply_date_data) {
            let reply_time_form = new Date(
              formList.reply_date_time
            ).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });

            needUpdateData.reply_date = new Date(
              reply_date_form + " " + reply_time_form
            ).toISOString();
          }
        } else {
          needUpdateData.reply_date = "";
        }
        dateChangedFlgObject.isReplyDateChanged = true;
      }
    } else if (key === "reply_date_time") {
      // check and edit the replyDate
      // eg. dateTime:[2021-01-22T19:39:12], time:[19:39]
      if (!dateChangedFlgObject.isReplyDateChanged) {
        if (formList.reply_date_date && formList.reply_date_date != "") {
          let reply_time_form = new Date(
            formList.reply_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          let reply_time_data = new Date(
            dataList.reply_date_time
          ).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });

          if (reply_time_form !== reply_time_data) {
            let reply_date_form = new Date(
              formList.reply_date_date
            ).toLocaleDateString("en-US");

            needUpdateData.reply_date = new Date(
              reply_date_form + " " + reply_time_form
            ).toISOString();
          }
        } else {
          needUpdateData.reply_date = "";
        }
        dateChangedFlgObject.isReplyDateChanged = true;
      }
    }
  };

  // check the note, contact, addree info whether need to update or insert.
  const getNeedUpdateObject = (key: string, type: string) => {
    let returnObject = { isNeedUpdateFlg: false, data: {} };
    let needUpdateObjectFlg = false;
    // update data
    let needUpdateData: { [key: string]: any } = {};

    if (dataList[key].updated_date) {
      // loop the object of dataList by subKey
      Object.keys(dataList[key]).map((subKey) => {
        // update flag of property
        let needUpdatePropFlg = false;

        if (
          Object.prototype.toString.call(dataList[key][subKey]) !==
          "[object Boolean]" &&
          !dataList[key][subKey]
        ) {
          if (formList[key][subKey]) {
            // data property is null or undefined
            needUpdatePropFlg = true;
          }
        } else if (
          Object.prototype.toString.call(dataList[key][subKey]) ===
          "[object Boolean]"
        ) {
          if (formList[key][subKey] !== dataList[key][subKey]) {
            needUpdatePropFlg = true;
          }
        } else {
          // form property is different to data(value was changed)
          if (formList[key][subKey] !== dataList[key][subKey]) {
            needUpdatePropFlg = true;
          }
        }
        if (needUpdatePropFlg) {
          needUpdateData[subKey] = formList[key][subKey];
          needUpdateObjectFlg = true;
        }
      });
    }

    if (needUpdateObjectFlg) {
      // set the PK and updated_date for update data
      // needUpdateData.job_no = formList.job_no;
      needUpdateData.updated_date = formList[key].updated_date;
      if (type === "note") {
        needUpdateData.sub_no = formList[key].sub_no;
        needUpdateData.note_type = formList[key].note_type;
      } else if (type === "contact") {
        needUpdateData.contact_type = formList[key].contact_type;
      } else if (type === "address") {
        needUpdateData.address_type = formList[key].address_type;
      }
      returnObject.isNeedUpdateFlg = true;
      returnObject.data = needUpdateData;
    } else {
      needUpdateData = formList[key];
      needUpdateData.job_no = formList.job_no;
      returnObject.isNeedUpdateFlg = false;
      returnObject.data = needUpdateData;
    }
    return returnObject;
  };

  // print button click event
  const handlePrint = () => {
    setLoggerListManageFun(`JD:F17`);
    setJobNoInputFlg(true);
    setAlertMessage("");
    apiLoading(true);
    printRepairRegistration();
    apiLoading(false);
  };

  // call api to update the job detail.
  const printRepairRegistration = async () => {
    let errorExsit = false;
    // Repair Registration Print API AT0605
    await api
      .getConsoleRepairRegistrationPrintAPI(formList.job_no, config)
      .then(async ({ response, code }: { response: any; code: any }) => {
        console.log("AT0605 response: ", response);
        if (getApiResultStatus(code)) {
          successMessage("print");
        } else {
          if (code === 404) {
            if (response.key === "file") {
              errorMessage({ message_id: "download_button_error" });
            } else {
              errorMessage({ message_id: "MA0010" });
            }
          } else {
            errorMessage(response);
            errorExsit = true;
          }
        }
      });

    return errorExsit;
  };

  const handleJobDeleteConfirm = () => {
    setLoggerListManageFun(`JD:F18`);
    setConfirmationOpen(true);
    setPopUpTitle("prompt_title_Confrim");
    setPopUpContent(
      com("common.constants.messages.MS0028", {
        jobNo: formList.job_no,
      })
    );
    setPopUpButton("");
    setPopupType("job_delete");
  };

  // delete button click event
  const handleJobDelete = () => {
    setLoggerListManageFun(`JD:F19`);
    setAlertMessage("");
    apiLoading(true);
    // Delete Repair Job API AT0104
    api
      .deleteRepairJobAPI(
        formList.job_no,
        { updated_date: formList.updated_date },
        config
      )
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT0104 response", response);
        if (getApiResultStatus(code)) {
          // getReadRepairList(formList.job_no, "delete", true);
          setConfirmationOpen(true);
          setPopUpTitle("prompt_title_Confrim");
          setPopUpContent(com("common.constants.messages.MS0014"));
          setPopUpButton("notification");
          setPopupType("job_delete");
        } else {
          errorMessage(response);
        }
      });
  };

  const handleJobCompleteConfirm = () => {
    setLoggerListManageFun(
      `JD:F20:v='${formList.model_no}',v='${formList.serial_no}',v='${formList.status}',v='${formList.technician_cd}',v='${formList.service_center_id}',v='${formList.job_type}'`
    );
    setConfirmationOpen(true);
    setPopUpTitle("prompt_title_Confrim");
    setPopUpContent(
      com("common.constants.messages.MS0030", {
        jobNo: formList.job_no,
      })
    );
    setPopUpButton("");
    setPopupType("job_complete");
  };

  // repair complete
  const handleComplete = () => {
    setLoggerListManageFun(
      `JD:F21:v='${formList.model_no}',v='${formList.serial_no}',v='${formList.status}',v='${formList.technician_cd}',v='${formList.service_center_id}',v='${formList.job_type}'`
    );
    setAlertMessage("");
    apiLoading(true);
    const data = {
      status: "800",
      manual_complete_flg: "1",
      updated_date: dataList.updated_date,
      logger: loggerMessage,
      loggerMessage: _LoggerListManage,
    };
    // AT0103
    api
      .updateRepairJobAPI(formList.job_no, data, config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("handleComplete response", response);
        if (getApiResultStatus(code)) {
          // clear log
          setClearLoggerListMagangeFun();
          setConfirmationOpen(true);
          setPopUpTitle("prompt_title_Confrim");
          setPopUpContent(com("common.constants.messages.MS0015"));
          setPopUpButton("notification");
          setPopupType("job_complete");
        } else {
          errorMessage(response);
        }
      });
  };

  const partsTabStarted = () => {
    setLoggerListManageFun(`JD:F22`);
    setPartsTabActive(true);
    setPartsTabFlg(false);
  };

  const confirmationContents = (
    <div className={classes.confirmationContainer}>
      <p>{popUpContent}</p>
    </div>
  );

  const handleConfirmationClose = (type: string) => {
    setLoggerListManageFun(`JD:F23:v='${type}'`);
    setConfirmationOpen(false);
    setPopUpTitle("");
    setPopUpContent("");
    // setPopUpButton("");
    setPopupType("");
    let path;
    switch (type) {
      case "job_delete":
        path = GetPrivateAppRoutePath(PathKey.JOBS);
        moveForward(path);
        break;
      case "job_complete":
        path = GetPrivateAppRoutePath(PathKey.JOBS);
        moveForward(path);
        break;
      default:
    }
  };

  const handleConfirmationResend = () => {
    setLoggerListManageFun(`JD:F24`);
    setConfirmationOpen(true);
    setPopUpTitle("prompt_title_Confrim");
    setPopUpContent(com("common.constants.messages.MS0023"));
    setPopUpButton("");
    setPopupType("quote_resend");
  };

  // resend button click event
  const handleQuoteResend = () => {
    setLoggerListManageFun(`JD:F25`);
    setAlertMessage("");
    apiLoading(true);
    let data = [];
    let contactDealer = Object.assign({}, formList.contactDealer);
    // set the mail flag of dealer to true.
    //contactDealer.mail_receive_notification = true;
    data.push(contactDealer);
    data.push(formList.contactEndUser);
    let params: any = [];
    data.forEach((item: any) => {
      let o: any = {};
      let flg = false;
      console.log("item", item);
      for (let p in item) {
        let v = item[p];
        console.log(p, "::", v);
        // mail address
        if (p === "mail_address") {
          if (item[p]) {
            flg = true;
          }
        } else if (p === "tel_no" || p === "fax_no") {
          if (item[p] && item[p] !== "+" && item[p].length > 5) {
            flg = true;
          } else {
            v = "";
          }
        }
        o[p] = v;
      }
      if (flg) {
        params.push(o);
      }
    });
    console.log("data", data, params);
    if (params.length > 0) {
      // Quota Resend API AT0114
      api
        .quotaResendAPI(formList.job_no, params, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("AT0114 response", response);
          if (getApiResultStatus(code)) {
            successMessage("resend");
          } else {
            errorMessage(response);
          }
          apiLoading(false);
        });
    } else {
      const message = t("general.label.resend_message");
      setAlertMessage(message);
      setAlertType("error");
      apiLoading(false);
    }
  };

  // move forward to jobs
  const moveForward = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  const handleConfirmationYes = (type: string) => {
    setLoggerListManageFun(`JD:F26`);
    setConfirmationOpen(false);
    setPopUpTitle("");
    setPopUpContent("");
    setPopUpButton("");
    setPopupType("");
    switch (type) {
      case "job_delete":
        handleJobDelete();
        break;
      case "job_complete":
        handleComplete();
        break;
      case "quote_resend":
        handleQuoteResend();
        break;
      case "media_delete":
        handleDeleteMedia();
        break;
      default:
    }
  };

  // media file upload event
  const handleUpload = (files: any) => {
    setLoggerListManageFun(`JD:F27`);
    // loading
    if (files && files.length > 0) {
      const file = files[0];
      let fileName = file.name;
      let _fileName = fileName;
      let fileNameList = fileName.split(".");
      if (formList.repair_media.length > 0) {
        for (let i = 0; i < formList.repair_media.length; i++) {
          let flg = false;
          for (let j = 0; j < formList.repair_media.length; j++) {
            if (_fileName === formList.repair_media[j].media_name) {
              flg = true;
              break;
            }
          }
          console.log("flg", flg);
          if (!flg) {
            break;
          } else {
            let _name = "";
            console.log("fileNameList", fileNameList);
            if (fileNameList.length > 2) {
              for (let a = 0; a < fileNameList.length; i++) {
                if (a < fileNameList.length) {
                  _name += fileNameList[a];
                }
              }
            } else {
              _name = fileNameList[0];
            }
            console.log("_name", _name);
            _fileName =
              _name +
              "(" +
              (i + 1) +
              ")" +
              "." +
              fileNameList[fileNameList.length - 1];
            console.log("fileName", _fileName);
          }
        }
      }
      fileName = _fileName;
      apiLoading(true);
      // Get Upload signed url AT0401
      api
        .getUploadSignedUrl(encodeURIComponent(fileName), config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("AT0401 response", response);
          if (getApiResultStatus(code)) {
            const data = response;
            api.getUploadFile(response.content, file).then((response: any) => {
              // uid
              if (getApiResultStatus(response)) {
                console.log("getUploadFile", file);
                // Create Repair media API AT0109
                const params = {
                  job_no: formList.job_no,
                  media_url: data.uid,
                  media_type: Number(mediaType),
                  media_name: fileName,
                  media_size: file.size,
                  // updated_date: response.updated_date,
                };
                api
                  .createRepairMediaAPI(params, config)
                  .then(({ code, response }: { code: any; response: any }) => {
                    if (getApiResultStatus(code)) {
                      getReadRepairList(formList.job_no, undefined, true, true);
                    } else {
                      errorMessage(response);
                    }
                  });
              } else {
                errorMessage(response);
              }
            });
          } else {
            errorMessage(response);
          }
        });
    }
  };

  // media file download event
  const handleDownload = (row: any) => {
    setLoggerListManageFun(`JD:F28`);
    apiLoading(true);
    // Get Download signed url AT0402
    api
      .getDownloadSignedUrl(formList.job_no, row.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const url = response.content;
          console.log("url", url);
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              apiLoading(false);

              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement("a");
                a.href = url;
                a.download = row.media_name;
                a.click();
              }
            });
        }
      });
  };

  // media file preview event
  const handleOnClickLink = (row: any) => {
    setLoggerListManageFun(`JD:F29`);
    apiLoading(true);
    // Get Download signed url AT0402
    api
      .getDownloadSignedUrl(formList.job_no, row.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          apiLoading(false);
          const url = response.content;
          console.log("url", url);
          let w = window.open();
          if (w) {
            w.location.href = url;
          }
        }
      });
  };

  const handleMediaDeleteConfirm = (row: any) => {
    setLoggerListManageFun(`JD:F30`);
    setMediaRowData(row);
    setConfirmationOpen(true);
    setPopUpTitle("prompt_title_Confrim");
    setPopUpContent(
      com("common.constants.messages.MS0027", {
        mediaFileName: row.media_name,
      })
    );
    setPopUpButton("");
    setPopupType("media_delete");
  };

  // Delete Repair media API AT0111
  const handleDeleteMedia = () => {
    setLoggerListManageFun(`JD:F31`);
    apiLoading(true);
    let params = {
      updated_date: mediaRowData.updated_date,
    };
    api
      .deleteRepairMediaAPI(
        formList.job_no,
        mediaRowData.sub_no,
        params,
        config
      )
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("AT0111 response", response);
        if (getApiResultStatus(code)) {
          getReadRepairList(formList.job_no, undefined, true, true);
        } else {
          errorMessage(response);
        }
      });
  };

  // multiple validations [start]
  // execute all validations
  async function runAllValidations() {
    let checkResult = true;
    setGenaralTabError(false);
    setCustInfoTabError(false);
    if (!onBlurOfTechnicianCode()) {
      console.log("onBlurOfTechnicianCode");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (!onBlurOfCustomerNo()) {
      console.log("onBlurOfCustomerNo");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (!onBlurOfCustomerPONo()) {
      console.log("onBlurOfCustomerPONo");
      checkResult = false;
      setGenaralTabError(true);
    }
    if (!onBlurOfLocationNo()) {
      console.log("onBlurOfLocationNo");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (!onBlurOfReceivedDateDate()) {
      console.log("onBlurOfReceivedDateDate");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (!onBlurOfReceivedDateTime()) {
      console.log("onBlurOfReceivedDateTime");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (!onBlurOfModelNo()) {
      console.log("onBlurOfModelNo");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (!onBlurOfSerialNo()) {
      console.log("onBlurOfSerialNo");
      checkResult = false;
      setGenaralTabError(true);
    }

    if (formList.repair_option === 1 || formList.repair_option === 2) {
      if (!onBlurOfWarrantyApprovedBy()) {
        console.log("onBlurOfWarrantyApprovedBy");
        checkResult = false;
        setGenaralTabError(true);
      }
    } else {
      if (!onBlurOfReplyDateDate()) {
        console.log("onBlurOfReplyDateDate");
        checkResult = false;
        setGenaralTabError(true);
      }

      if (!onBlurOfReplyDateTime()) {
        console.log("onBlurOfReplyDateTime");
        checkResult = false;
        setGenaralTabError(true);
      }
      if (formList.quote_result === 3 || formList.quote_result === 4) {
        await onBlurOfTradeInItemNo().then((noError) => {
          if (!noError) {
            checkResult = false;
            setGenaralTabError(true);
          } else {
            setAlertMessage("");
            setAlertType("");
          }
        });
      }
    }

    if (!onBlurOfDealerName()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfDealerEmailAddress()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfEndUserName()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfEndUserEmailAddress()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfPickupName()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfPickupAddress2()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfPickupAddress3()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfPickupAddress1()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfPickupPostalCd()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfReturnAddressName(formList.addressReturnTo.name)) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfReturnAddress2()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfReturnAddress3()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfReturnAddress1()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfReturnPostalCd()) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfBillToAddressName(formList.addressBillTo.name)) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfBillToAddress2(formList.addressBillTo.address_2)) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfBillToAddress3(formList.addressBillTo.address_3)) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfBillToAddress1(formList.addressBillTo.address_1)) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    if (!onBlurOfBillToPostalCd(formList.addressBillTo.postal_cd)) {
      checkResult = false;
      setCustInfoTabError(true);
    }

    return checkResult;
  }

  // master exist validation
  const masterExitOfTechnician = (value: string, type: string = "") => {
    setLoggerListManageFun(
      `JD:F32:v='${value}',v='${type}',v='${commonInfo.technicianList.length}'`
    );
    let isExisted = false;
    let technicians = commonInfo.technicianList;

    if (value && value !== "") {
      if (technicians.length > 0) {
        for (let index in technicians) {
          if (
            technicians[index].erp_user_id.toUpperCase() === value.toUpperCase()
          ) {
            setLoggerListManageFun(
              `JD:F32_F1:v='${technicians[index].erp_user_id}',v='${repairJobPartsList.length}',v='${technicians[index].name}',v='${formList.status}',v='${technicians[index].service_center}',v='${formList.service_center_id}'`
            );
            isExisted = true;
            if (type) {
              setFormListValue(type, technicians[index].erp_user_id);
              if (type === "technician_cd") {
                setFormListValue("technician_name", technicians[index].name);
                if (
                  repairJobPartsList.length <= 0 &&
                  (formList.status === "100" ||
                    formList.status === "200" ||
                    formList.status === "300" ||
                    formList.status === "400" ||
                    formList.status === "410" ||
                    formList.status === "500") &&
                  formList.quote_result === ""
                ) {
                  setFormListValue(
                    "service_center_id",
                    technicians[index].service_center !== ""
                      ? technicians[index].service_center
                      : formList.service_center_id
                  );
                }
              } else {
                setFormListValue(
                  "warranty_approved_by_name",
                  technicians[index].name
                );
              }
            }
            break;
          }
        }
      } else {
        // TODO call api to check whether existed
      }
    } else {
      isExisted = true;
    }

    return isExisted;
  };

  const masterExitOfModelNo = (value: string) => {
    setLoggerListManageFun(
      `JD:F33:v='${value}',v='${commonInfo.itemList.length}'`
    );
    let isExisted = false;
    let items = commonInfo.itemList;

    if (value && value !== "") {
      if (items.length > 0) {
        for (let index in items) {
          if (
            items[index].model_no.toString().toUpperCase() ===
            value.toString().toUpperCase()
          ) {
            setLoggerListManageFun(`JD:F33_F1:v='${items[index].model_no}'`);
            isExisted = true;
            let modelNoByDB = items[index].model_no.toString();
            setFormListValue("model_no", modelNoByDB);
            break;
          }
        }
      } else {
        // TODO call api to check whether existed
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  const masterExitOfItemNo = (itemNo: string) => {
    setLoggerListManageFun(`JD:F34:v='${itemNo}'`);
    apiLoading(true);
    // AM0302
    return api
      .getReadItemMasterAPI(encodeURIComponent(itemNo), config)
      .then(({ code, response }: { code: any; response: any }) => {
        let isExisted = true;
        let isSuspend = true;
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          if (code === 404) {
            isExisted = false;
          }
        } else {
          if (!response.suspend_flg) {
            setFormListValue("trade_in_item_no", response.item_no);
            isExisted = true;
            isSuspend = false;
          } else {
            isExisted = true;
            isSuspend = true;
          }
        }
        return {
          isExisted: isExisted,
          isSuspend: isSuspend,
        };
      })
      .then((response: any) => response);
  };

  const onBlurOfTechnicianCode = () => {
    setLoggerListManageFun(
      `JD:F35:v='${formList.technician_cd}',v='${dataList.technician_cd}'`
    );
    let noError = true;
    setFormErrorListValue("technician_cd_error", "");
    if (
      formList.technician_cd === "" &&
      formList.technician_cd !== dataList.technician_cd
    ) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.label.technician_cd"),
      });
      setLoggerListManageFun(`JD:F35_F1`);
      setFormErrorListValue("technician_cd_error", message);
      noError = false;
    } else if (!checkMaxLength(formList.technician_cd, 10)) {
      setLoggerListManageFun(`JD:F35_F2`);
      let message = com("common.constants.messages.MS0002", {
        param1: t("general.label.technician_cd"),
        param2: 10,
      });
      setFormErrorListValue("technician_cd_error", message);
      noError = false;
    } else if (
      !masterExitOfTechnician(formList.technician_cd, "technician_cd")
    ) {
      setLoggerListManageFun(`JD:F35_F3`);
      let message = com("common.constants.messages.MS0004", {
        param1: t("general.label.technician_cd"),
      });
      setFormErrorListValue("technician_cd_error", message);
      noError = false;
    }
    return noError;
  };
  const setButtonDisableFlg = (type: any) => {
    if (type === "focus") {
      setButtonFlg(true);
    } else {
      setButtonFlg(false);
    }
  };
  const onBlurOfCustomerNo = (needSetName = false, value?: any) => {
    setLoggerListManageFun(`JD:F36:v='${needSetName}',v='${value}'`);
    setButtonDisableFlg("blur");
    let noError = true;
    setFormErrorListValue("customer_no_error", "");
    if (isEmptyStringOrNumber(formList.customer_no)) {
      setLoggerListManageFun(`JD:F36_F1`);

      let message = com("common.constants.messages.MS0001", {
        param1: t("general.label.customer_no"),
      });
      setFormErrorListValue("customer_no_error", message);
      noError = false;
      // } else if (value && value !== "" && Number(value) !== customerNoTemp) {
    } else {
      setLoggerListManageFun(`JD:F36_F2`);
      // if (customerNoTemp === "") {
      //   setCustomerNoTemp(formList.customer_no);
      // } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(formList.customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0202 response", response);
          if (!getApiResultStatus(code)) {
            let message = com("common.constants.messages.MS0004", {
              param1: t("general.label.customer_no"),
            });
            setFormErrorListValue("customer_no_error", message);
            noError = false;
          } else {
            if (response.customer_closed === true) {
              let message = com("common.constants.messages.MS0085", {
                param1: response.customer_no,
              });
              setAlertMessage(message);
              setAlertType("warning");
            } else {
              setAlertMessage("");
              setAlertType("");
            }
            const _customer_number = response.customer_no;
            // setFormListValue("customer_no", _customer_number);
            if (needSetName) {
              // as the defualt value for customer info
              setFormList((prevState: any) => ({
                ...prevState,
                customer_name: response.customer_name,
                currency: response.currency,
                limit_amount_currency: response.currency,
                contactDealer: {
                  ...prevState.contactDealer,
                  name: response.customer_name,
                  mail_address: response.mail_address_repair
                    ? response.mail_address_repair
                    : "",
                  tel_no: response.tel_no ? response.tel_no : "",
                  fax_no: response.fax_no ? response.fax_no : "",
                  sms_receive_notification: response.sms_receive_notification,
                  mail_receive_notification: response.mail_receive_notification,
                },
                addressReturnTo: {
                  ...prevState.addressReturnTo,
                  name: response.customer_name ? response.customer_name : "",
                  country: response.country ? response.country : "",
                  address_1: response.address_1 ? response.address_1 : "",
                  address_2: response.address_2 ? response.address_2 : "",
                  address_3: response.address_3 ? response.address_3 : "",
                  postal_cd: response.postal_cd ? response.postal_cd : "",
                  self_pickup: false,
                },
                addressBillTo: {
                  ...prevState.addressBillTo,
                  name: response.customer_name ? response.customer_name : "",
                  country: response.country ? response.country : "",
                  address_1: response.address_1 ? response.address_1 : "",
                  address_2: response.address_2 ? response.address_2 : "",
                  address_3: response.address_3 ? response.address_3 : "",
                  postal_cd: response.postal_cd ? response.postal_cd : "",
                },
              }));
            }
            // display the pupup of memorandum
            if (response.memorandum && response.memorandum !== "") {
              setConfirmationOpen(true);
              setPopUpTitle("prompt_title_memorandum");
              setPopUpContent(response.memorandum);
              setPopUpButton("notification");
            }
          }
        });
      // }
    }
    return noError;
  };

  const getIllegalCharactersErrorMessage = (error_list: any) => {
    let _error_message = "";
    if (error_list.length > 0) {
      error_list.forEach((item: any, index: number) => {
        if (index !== 0) {
          _error_message += ",";
        }
        if (item.flg) {
          _error_message += com(`common.constants.messages.${item.message_id}`);
        } else {
          _error_message += item.message_id;
        }
      });
    }
    return _error_message;
  };

  const onBlurOfCustomerPONo = (value?: any) => {
    setLoggerListManageFun(
      `JD:F37:v='${value}',v='${formList.customer_po_no}'`
    );
    let noError = true;
    setFormErrorListValue("customer_po_no_error", "");
    if (value || formList.customer_po_no) {
      if (checkIllegalCharUnicode(value ? value : formList.customer_po_no)) {
        setLoggerListManageFun(`JD:F37_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(value ? value : formList.customer_po_no)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("customer_po_no_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfLocationNo = (value?: any) => {
    setLoggerListManageFun(`JD:F38:v='${value}',v='${formList.location_no}'`);
    let noError = true;
    setFormErrorListValue("location_no_error", "");
    if (value || formList.location_no) {
      if (checkIllegalCharUnicode(value ? value : formList.location_no)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(value ? value : formList.location_no)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("location_no_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfReceivedDateDate = () => {
    setLoggerListManageFun(
      `JD:F39:v='${formList.received_date_date}',v='${isEmptyNumber(
        formList.received_date_date
      )}'`
    );
    let noError = true;
    setFormErrorListValue("received_date_date_error", "");
    if (isEmptyNumber(formList.received_date_date)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.label.received_date_date"),
      });
      setFormErrorListValue("received_date_date_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfReceivedDateTime = () => {
    setLoggerListManageFun(`JD:F40:v='${formList.received_date_time}'`);
    let noError = true;
    setFormErrorListValue("received_date_time_error", "");
    if (isEmptyNumber(formList.received_date_time)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.label.received_date_time"),
      });
      setFormErrorListValue("received_date_time_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfWarrantyApprovedBy = () => {
    setLoggerListManageFun(`JD:F41:v='${formList.warranty_approved_by}'`);
    setFormErrorListValue("warranty_approved_by_error", "");
    let noError = true;
    if (!checkMaxLength(formList.warranty_approved_by, 10)) {
      let message = com("common.constants.messages.MS0002", {
        param1: t("general.group_repair_option.warranty_approved_by_message"),
        param2: 10,
      });
      setFormErrorListValue("warranty_approved_by_error", message);
      noError = false;
    } else if (
      !masterExitOfTechnician(
        formList.warranty_approved_by,
        "warranty_approved_by"
      )
    ) {
      let message = com("common.constants.messages.MS0004", {
        param1: t("general.group_repair_option.warranty_approved_by_message"),
      });
      setFormErrorListValue("warranty_approved_by_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfModelNo = () => {
    setLoggerListManageFun(`JD:F42:v='${formList.model_no}'`);
    setButtonDisableFlg("blur");
    console.log("onBlurOfModelNo", formList.model_no);
    let _model_no: string = formList.model_no;
    let noError = true;
    if (!checkMaxLength(_model_no, 15)) {
      setLoggerListManageFun(`JD:F42:F1`);
      let message = com("common.constants.messages.MS0002", {
        param1: t("general.label.model_no"),
        param2: 15,
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
    } else if (!masterExitOfModelNo(_model_no)) {
      setLoggerListManageFun(`JD:F42:F2`);

      let message = com("common.constants.messages.MS0004", {
        param1: t("general.label.model_no"),
      });
      setFormErrorListValue("model_no_error", message);
      noError = false;
    }
    if (noError) {
      setFormErrorListValue("model_no_error", "");
      setFormErrorListValue("serial_no_error", "");
    }
    return noError;
  };

  const onBlurOfSerialNo = () => {
    setLoggerListManageFun(`JD:F43:v='${formList.serial_no}'`);
    setButtonDisableFlg("blur");
    let noError = true;
    if (!checkMaxLength(formList.serial_no, 15)) {
      setLoggerListManageFun(`JD:F43:F1`);
      let message = com("common.constants.messages.MS0002", {
        param1: t("general.label.serial_no"),
        param2: 15,
      });
      setFormErrorListValue("serial_no_error", message);
      noError = false;
    }
    if (formList.serial_no) {
      if (checkIllegalCharUnicode(formList.serial_no)) {
        setLoggerListManageFun(`JD:F43:F2`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.serial_no)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("serial_no_error", message);
        noError = false;
      }
    }
    if (noError) {
      setFormErrorListValue("serial_no_error", "");
    }
    return noError;
  };

  const onBlurOfReplyDateDate = () => {
    setLoggerListManageFun(
      `JD:F44:v='${formList.reply_date_date}',v='${formList.reply_date_time}'`
    );
    let noError = true;
    setFormErrorListValue("reply_date_date_error", "");
    setFormErrorListValue("reply_date_time_error", "");
    if (
      isEmptyNumber(formList.reply_date_date) &&
      !isEmptyNumber(formList.reply_date_time)
    ) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.group_quote.reply_date_date"),
      });
      setFormErrorListValue("reply_date_date_error", message);
      noError = false;
    } else if (
      !isEmptyNumber(formList.reply_date_date) &&
      isEmptyNumber(formList.reply_date_time)
    ) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.group_quote.reply_date_time"),
      });
      setFormErrorListValue("reply_date_time_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfReplyDateTime = () => {
    setLoggerListManageFun(
      `JD:F45:v='${formList.reply_date_time}',v='${formList.reply_date_date}'`
    );
    let noError = true;
    setFormErrorListValue("reply_date_date_error", "");
    setFormErrorListValue("reply_date_time_error", "");
    if (
      isEmptyNumber(formList.reply_date_time) &&
      !isEmptyNumber(formList.reply_date_date)
    ) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.group_quote.reply_date_time"),
      });
      setFormErrorListValue("reply_date_time_error", message);
      noError = false;
    } else if (
      !isEmptyNumber(formList.reply_date_time) &&
      isEmptyNumber(formList.reply_date_date)
    ) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("general.group_quote.reply_date_date"),
      });
      setFormErrorListValue("reply_date_date_error", message);
      noError = false;
    }
    return noError;
  };

  async function onBlurOfTradeInItemNo() {
    setLoggerListManageFun(`JD:F46:v='${formList.trade_in_item_no}'`);
    let noError = true;
    setAlertMessage("");
    setAlertType("");
    if (formList.trade_in_item_no !== "") {
      const { isExisted, isSuspend } = await masterExitOfItemNo(
        formList.trade_in_item_no
      );
      setFormErrorListValue("trade_in_item_no_error", "");
      if (!checkMaxLength(formList.trade_in_item_no, 35)) {
        setLoggerListManageFun(`JD:F46_F1`);
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.group_trade_in.trade_in_item_no"),
          param2: 35,
        });
        setFormErrorListValue("trade_in_item_no_error", message);
        noError = false;
      }
      if (!isExisted) {
        setLoggerListManageFun(`JD:F46_F2`);
        let message = com("common.constants.messages.MS0004", {
          param1: t("general.group_trade_in.trade_in_item_no"),
        });
        setFormErrorListValue("trade_in_item_no_error", message);
        noError = false;
      }
      if (isExisted && isSuspend) {
        setLoggerListManageFun(`JD:F46_F3`);
        let message = com("common.constants.messages.MS0052", {
          itemNum: formList.trade_in_item_no,
        });
        //setFormErrorListValue("trade_in_item_no_error", message);
        setAlertMessage(message);
        setAlertType("warning");
      }
    } else {
      setLoggerListManageFun(`JD:F46_F4`);
      setFormErrorListValue("trade_in_item_no_error", "");
    }
    return noError;
  }

  const onBlurOfDealerName = () => {
    setLoggerListManageFun(`JD:F47:v='${formList.contactDealer.name}'`);

    let noError = true;
    setFormErrorListValue("dealer_name_error", "");
    if (formList.contactDealer.name) {
      if (checkIllegalCharUnicode(formList.contactDealer.name)) {
        setLoggerListManageFun(`JD:F47_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.contactDealer.name)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("dealer_name_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfDealerEmailAddress = () => {
    setLoggerListManageFun(`JD:F48:v='${formList.contactDealer.mail_address}'`);

    let noError = true;
    setFormErrorListValue("dealer_mail_address_error", "");
    if (formList.contactDealer.mail_address) {
      if (checkUmlautsCharUnicode(formList.contactDealer.mail_address)) {
        let message = com("common.constants.messages.MS0122");
        setFormErrorListValue("dealer_mail_address_error", message);
        noError = false;
      } else if (!emailValidation(formList.contactDealer.mail_address)) {
        setLoggerListManageFun(`JD:F48_F1`);
        let message = com("common.constants.messages.MS0040");
        setFormErrorListValue("dealer_mail_address_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfEndUserName = () => {
    setLoggerListManageFun(`JD:F49:v='${formList.contactEndUser.name}'`);

    let noError = true;
    setFormErrorListValue("endUser_name_error", "");
    if (formList.contactEndUser.name) {
      if (checkIllegalCharUnicode(formList.contactEndUser.name)) {
        setLoggerListManageFun(`JD:F49_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.contactEndUser.name)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("endUser_name_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfEndUserEmailAddress = () => {
    setLoggerListManageFun(
      `JD:F50:v='${formList.contactEndUser.mail_address}'`
    );

    let noError = true;
    setFormErrorListValue("endUser_mail_address_error", "");
    if (formList.contactEndUser.mail_address) {
      if (checkUmlautsCharUnicode(formList.contactEndUser.mail_address)) {
        let message = com("common.constants.messages.MS0122");
        setFormErrorListValue("endUser_mail_address_error", message);
        noError = false;
      } else if (!emailValidation(formList.contactEndUser.mail_address)) {
        setLoggerListManageFun(`JD:F50_F1`);
        let message = com("common.constants.messages.MS0040");
        setFormErrorListValue("endUser_mail_address_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfPickupName = () => {
    setLoggerListManageFun(`JD:F51:v='${formList.addressPickup.name}'`);

    let noError = true;
    setFormErrorListValue("pickup_name_error", "");
    if (formList.addressPickup.name) {
      if (checkIllegalCharUnicode(formList.addressPickup.name)) {
        setLoggerListManageFun(`JD:F51_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressPickup.name)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("pickup_name_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfPickupAddress2 = () => {
    setLoggerListManageFun(`JD:F52:v='${formList.addressPickup.address_2}'`);

    let noError = true;
    setFormErrorListValue("pickup_address_2_error", "");
    if (formList.addressPickup.address_2) {
      if (checkIllegalCharUnicode(formList.addressPickup.address_2)) {
        setLoggerListManageFun(`JD:F52_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressPickup.address_2)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("pickup_address_2_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfPickupAddress3 = () => {
    setLoggerListManageFun(`JD:F53:v='${formList.addressPickup.address_3}'`);

    let noError = true;
    setFormErrorListValue("pickup_address_3_error", "");
    if (formList.addressPickup.address_3) {
      if (checkIllegalCharUnicode(formList.addressPickup.address_3)) {
        setLoggerListManageFun(`JD:F53_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressPickup.address_3)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("pickup_address_3_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfPickupAddress1 = () => {
    setLoggerListManageFun(`JD:F54:v='${formList.addressPickup.address_1}'`);

    let noError = true;
    setFormErrorListValue("pickup_address_1_error", "");
    if (formList.addressPickup.address_1) {
      if (checkIllegalCharUnicode(formList.addressPickup.address_1)) {
        setLoggerListManageFun(`JD:F54_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressPickup.address_1)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("pickup_address_1_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfPickupPostalCd = () => {
    setLoggerListManageFun(`JD:F55:v='${formList.addressPickup.postal_cd}'`);

    let noError = true;
    setFormErrorListValue("pickup_postal_cd_error", "");
    if (formList.addressPickup.postal_cd) {
      if (checkIllegalCharUnicode(formList.addressPickup.postal_cd)) {
        setLoggerListManageFun(`JD:F55_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressPickup.postal_cd)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("pickup_postal_cd_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfReturnAddressName = (value: any) => {
    setLoggerListManageFun(
      `JD:F56:v='${value}',v='${isEmptyCheck(
        value
      )}',v='${checkIllegalCharUnicode(value)}'`
    );

    let noError = true;
    setFormErrorListValue("returnTo_name_error", "");
    if (isEmptyCheck(value)) {
      setLoggerListManageFun(`JD:F56_F1`);
      let message = com("common.constants.messages.MS0001", {
        param1: t("customer_info.name"),
      });
      setFormErrorListValue("returnTo_name_error", message);
      noError = false;
    } else if (checkIllegalCharUnicode(value)) {
      setLoggerListManageFun(`JD:F56_F2`);
      let error_list = illegalCharactersErrorMessage(
        checkIllegalCharResultUnicode(value)
      );
      let message = com("common.constants.messages.MS0093", {
        param1: getIllegalCharactersErrorMessage(error_list),
      });
      setFormErrorListValue("returnTo_name_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfReturnAddress2 = () => {
    setLoggerListManageFun(`JD:F57:v='${formList.addressReturnTo.address_2}'`);

    let noError = true;
    setFormErrorListValue("returnTo_address_2_error", "");
    if (formList.addressReturnTo.address_2) {
      setLoggerListManageFun(`JD:F57_F1`);
      if (checkIllegalCharUnicode(formList.addressReturnTo.address_2)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressReturnTo.address_2)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("returnTo_address_2_error", message);
        noError = false;
      } else if (!checkMaxLength(formList.addressReturnTo.address_2, 30)) {
        setLoggerListManageFun(`JD:F57_F2`);
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.label.returnTo_address_2"),
          param2: 30,
        });
        setFormErrorListValue("returnTo_address_2_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfReturnAddress3 = () => {
    setLoggerListManageFun(`JD:F58:v='${formList.addressReturnTo.address_3}'`);

    let noError = true;
    setFormErrorListValue("returnTo_address_3_error", "");
    if (formList.addressReturnTo.address_3) {
      if (checkIllegalCharUnicode(formList.addressReturnTo.address_3)) {
        setLoggerListManageFun(`JD:F58_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressReturnTo.address_3)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("returnTo_address_3_error", message);
        noError = false;
      } else if (!checkMaxLength(formList.addressReturnTo.address_3, 30)) {
        setLoggerListManageFun(`JD:F58_F2`);
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.label.returnTo_address_3"),
          param2: 30,
        });
        setFormErrorListValue("returnTo_address_3_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfReturnAddress1 = () => {
    setLoggerListManageFun(`JD:F59:v='${formList.addressReturnTo.address_1}'`);

    let noError = true;
    setFormErrorListValue("returnTo_address_1_error", "");
    if (formList.addressReturnTo.address_1) {
      if (checkIllegalCharUnicode(formList.addressReturnTo.address_1)) {
        setLoggerListManageFun(`JD:F59_F1`);
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressReturnTo.address_1)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("returnTo_address_1_error", message);
        noError = false;
      } else if (!checkMaxLength(formList.addressReturnTo.address_1, 30)) {
        setLoggerListManageFun(`JD:F59_F2`);
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.label.returnTo_address_1"),
          param2: 30,
        });
        setFormErrorListValue("returnTo_address_1_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfReturnPostalCd = () => {
    setLoggerListManageFun(
      `JD:F60:v='${formList.addressReturnTo.postal_cd
      }',v='${checkIllegalCharUnicode(formList.addressReturnTo.postal_cd)}'`
    );

    let noError = true;
    setFormErrorListValue("returnTo_postal_cd_error", "");
    if (formList.addressReturnTo.postal_cd) {
      if (checkIllegalCharUnicode(formList.addressReturnTo.postal_cd)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(formList.addressReturnTo.postal_cd)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("returnTo_postal_cd_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const onBlurOfBillToAddressName = (value: any) => {
    setLoggerListManageFun(
      `JD:F61:v='${value}',v='${isEmptyCheck(
        value
      )}',v='${checkIllegalCharUnicode(value)}'`
    );

    let noError = true;
    setFormErrorListValue("billTo_name_error", "");
    if (isEmptyCheck(value)) {
      let message = com("common.constants.messages.MS0001", {
        param1: t("customer_info.name"),
      });
      setFormErrorListValue("billTo_name_error", message);
      noError = false;
    } else if (checkIllegalCharUnicode(value)) {
      let error_list = illegalCharactersErrorMessage(
        checkIllegalCharResultUnicode(value)
      );
      let message = com("common.constants.messages.MS0093", {
        param1: getIllegalCharactersErrorMessage(error_list),
      });
      setFormErrorListValue("billTo_name_error", message);
      noError = false;
    }
    return noError;
  };

  const onBlurOfBillToAddress2 = (value: any) => {
    setLoggerListManageFun(
      `JD:F62:v='${value}',v='${checkIllegalCharUnicode(
        value
      )}',v='${checkMaxLength(value, 30)}'`
    );

    let noError = true;
    setFormErrorListValue("billTo_address_2_error", "");
    if (value) {
      if (checkIllegalCharUnicode(value)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(value)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("billTo_address_2_error", message);
        noError = false;
      } else if (!checkMaxLength(value, 30)) {
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.label.billTo_address_2"),
          param2: 30,
        });
        setFormErrorListValue("billTo_address_2_error", message);
        noError = false;
      }
    }
    return noError;
  };

  // address3 blur and logger ok
  const onBlurOfBillToAddress3 = (value: any) => {
    setLoggerListManageFun(
      `JD:F63:v='${value}",v='${checkIllegalCharUnicode(
        value
      )}',v='${checkMaxLength(value, 30)}'`
    );

    let noError = true;
    setFormErrorListValue("billTo_address_3_error", "");
    if (value) {
      if (checkIllegalCharUnicode(value)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(value)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("billTo_address_3_error", message);
        noError = false;
      } else if (!checkMaxLength(value, 30)) {
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.label.billTo_address_3"),
          param2: 30,
        });
        setFormErrorListValue("billTo_address_3_error", message);
        noError = false;
      }
    }
    return noError;
  };

  // address1 blur and logger ok
  const onBlurOfBillToAddress1 = (value: any) => {
    setLoggerListManageFun(
      `JD:F64:v1='${value}',v='${checkIllegalCharUnicode(
        value
      )}',v='${checkMaxLength(value, 30)}'`
    );

    let noError = true;
    setFormErrorListValue("billTo_address_1_error", "");
    if (value) {
      if (checkIllegalCharUnicode(value)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(value)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("billTo_address_1_error", message);
        noError = false;
      } else if (!checkMaxLength(value, 30)) {
        let message = com("common.constants.messages.MS0002", {
          param1: t("general.label.billTo_address_1"),
          param2: 30,
        });
        setFormErrorListValue("billTo_address_1_error", message);
        noError = false;
      }
    }
    return noError;
  };

  // postal cd on blur and logger ok
  const onBlurOfBillToPostalCd = (value: any) => {
    setLoggerListManageFun(
      `JD:F65:v1='${value}',v='${checkIllegalCharUnicode(value)}'`
    );

    let noError = true;
    setFormErrorListValue("billTo_postal_cd_error", "");
    if (value) {
      if (checkIllegalCharUnicode(value)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(value)
        );
        let message = com("common.constants.messages.MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setFormErrorListValue("billTo_postal_cd_error", message);
        noError = false;
      }
    }
    return noError;
  };

  // multiple validations [end]

  const notificationButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        onClick={() => handleConfirmationClose(popupType)}
        color="primary"
      >
        {com("models.model_window.prompt_button_OK")}
      </Button>
    </div>
  );

  const confirmationButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={() => handleConfirmationClose("")}>
        {com("models.model_window.prompt_button_No")}
      </Button>
      <Button
        variant="contained"
        onClick={() => handleConfirmationYes(popupType)}
        color="primary"
        className={classes.buttonSetting}
      >
        {com("models.model_window.prompt_button_Yes")}
      </Button>
    </div>
  );

  //input job no on blur and logger ok
  const handleInputJobNoOnBlur = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLoggerListManageFun(
      `JD:F66:v1='${e.target.value}',v='${formList.job_no}'`
    );
    if (e.target.value !== "" && Number(e.target.value) !== formList.job_no) {
      handleInputJobNoEvent(e.target.value);
    } else if (
      e.target.value !== "" &&
      Number(e.target.value) === formList.job_no
    ) {
      if (value === 2) {
        if (!partsTabActive) {
          setPartsTabFlg(true);
        }
        setSaveButtonHiddenFlg(true);
      } else if (value === 3) {
        setSaveButtonHiddenFlg(true);
      }
    } else {
      setAlertMessage("");
    }
  };

  //input job no press enter and logger ok
  const handleInputJobNoPressEnter = () => {
    setLoggerListManageFun(`JD:F67:v='${searchValue}',v='${formList.job_no}'`);

    if (searchValue !== "" && Number(searchValue) !== formList.job_no) {
      handleInputJobNoEvent(searchValue);
    } else if (searchValue !== "" && Number(searchValue) === formList.job_no) {
      setAlertMessage("");
      if (value === 2) {
        if (!partsTabActive) {
          setPartsTabFlg(true);
        }
        setSaveButtonHiddenFlg(true);
      } else if (value === 3) {
        setSaveButtonHiddenFlg(true);
      }
    } else {
      setAlertMessage("");
    }
  };

  // input job no event and logger OK
  const handleInputJobNoEvent = (jobNo: any) => {
    let _logger = `JD:F68:v='${jobNo}'`;
    if (jobNo !== "" && !checkMinLength(jobNo, 8)) {
      setAlertMessage("");
      let message = com("common.constants.messages.MS0038", {
        length: 8,
      });
      setAlertMessage(message);
      // API AT0102 check job no whether exited
    } else if (jobNo !== "") {
      api
        .getReadRepairJobAPI(jobNo, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AT0102 response", response);
          if (code !== 200) {
            const message = com(
              "common.constants.messages." + response.message_id,
              {
                param1: response.key
                  ? com("common.constants.messages_key_name." + response.key)
                  : "",
              }
            );
            setAlertMessage(message);
          } else {
            setLoggerListManageFun(`JD:F68_GS:v='${jobNo}'`);
            setAlertMessage("");
            const path =
              GetPrivateAppRoutePath(PathKey.JOBSDETAIL) + `?jobno=${jobNo}`;
            setFormList(defaultValueBlank);
            setDataList(defaultValueBlank);
            setSaveButtonHiddenFlg(false);
            setPartsTabActive(false);
            handleOnClick(path);
          }
        });
    } else {
      setAlertMessage("");
      const path =
        GetPrivateAppRoutePath(PathKey.JOBSDETAIL) + `?jobno=${jobNo}`;
      setFormList(defaultValueBlank);
      setDataList(defaultValueBlank);
      setSaveButtonHiddenFlg(false);
      setPartsTabActive(false);
      handleOnClick(path);
    }
    setLoggerListManageFun(_logger);
  };

  // move forward to jobDetail
  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  const contents = (
    <form noValidate autoComplete="off">
      {errMessageList.length > 0 && (
        <JobsDetailErrorMessageBox
          open={errorMessageOpen}
          handleClose={handleErrorMessageClose}
          message={errMessageList}
        />
      )}
      <AlertMesssageBox
        show={alertMessage && alertMessage !== ""}
        message={alertMessage}
        type={alertType}
      />
      <div className={classes.root}>
        <AppBar className={classes.AppBar} position="static">
          <Tabs value={value} onChange={handleChange}>
            <Tab
              className={clsx(
                classes.tabButton,
                genaralTabError && classes.errorTabButton
              )}
              label={t("tabLables.general")}
            />
            <Tab
              className={clsx(
                classes.tabButton,
                custInfoTabError && classes.errorTabButton
              )}
              label={t("tabLables.customer_Info")}
            />
            <Tab
              className={classes.tabButton}
              disabled={isPartsDisable()}
              label={t("tabLables.parts_drawings")}
            />
            <Tab
              className={classes.tabButton}
              id={
                (internalUseMemoList && internalUseMemoList.length > 0) ||
                  (otherMemoList && otherMemoList.length > 0)
                  ? "tab-area"
                  : ""
              }
              label={t("tabLables.memo")}
            />
            {((internalUseMemoList && internalUseMemoList.length > 0) ||
              (otherMemoList && otherMemoList.length > 0)) && (
                <i className={classes.point}></i>
              )}
          </Tabs>
        </AppBar>
        <h2 className={classes.title}>
          {t("general.label.job_no")}
          <span>&nbsp;</span>
          <TextField
            value={dataList.job_no}
            variant="outlined"
            inputRef={(input) => {
              if (input != null && jobNoInputFlg) {
                setJobNoInputFlg(false);
                input.focus();
              }
            }}
            className={classes.textField}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d]/g, "").substr(0, 8);
              setDataListValue("job_no", value);
              return setSearchValue(value);
            }}
            inputProps={{ maxLength: 8 }}
            onBlur={handleInputJobNoOnBlur}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleInputJobNoPressEnter();
              }
            }}
          />
        </h2>
        <div className={classes.buttonArea}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => handleRefresh2()}
          >
            {t("general.button.button_refresh")}
          </Button>
          {userPermisssionInfo.save_btn_authFlg && (
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={buttonFlg}
              color="primary"
              startIcon={<SaveIcon />}
              className={clsx(saveButtonHiddenFlg && classes.componentHidden)}
            >
              {t("general.button.button_save")}
            </Button>
          )}
          {userPermisssionInfo.delete_job_btn_authFlg && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleJobDeleteConfirm}
            >
              {t("general.button.button_delete_job")}
            </Button>
          )}
          {userPermisssionInfo.repair_job_detailcompelete_btn_authFlg && (
            <Button
              variant="contained"
              onClick={handleJobCompleteConfirm}
              disabled={buttonFlg}
              color="primary"
              startIcon={<CheckCircleOutlineIcon />}
              className={clsx(saveButtonHiddenFlg && classes.componentHidden)}
            >
              {t("general.button.button_complete_job")}
            </Button>
          )}
          {userPermisssionInfo.print_btn_authFlg && (
            <Button
              variant="contained"
              onClick={handlePrint}
              disabled={
                dataList.customer_no === "" || dataList.customer_no === null
              }
              color="primary"
              startIcon={<PrintIcon />}
              className={clsx(printButtonHiddenFlg && classes.componentHidden)}
            >
              {t("general.button.button_print")}
            </Button>
          )}
        </div>
      </div>
      <Paper className={classes.paper}>
        <div hidden={value !== 0}>
          <JobsDetailGeneral
            formList={formList}
            setFormListValue={setFormListValue}
            setFormList={setFormList}
            repairHistoryList={repairHistoryList}
            setStartFlg={setStartFlg}
            formErrorList={formErrorList}
            setFormErrorList={setFormErrorList}
            setFormErrorListValue={setFormErrorListValue}
            dataList={dataList}
            handleResend={handleConfirmationResend}
            mediaType={mediaType}
            setMediaType={setMediaType}
            handleUpload={handleUpload}
            handleOnClickLink={handleOnClickLink}
            handleDownload={handleDownload}
            handleDeleteMedia={handleMediaDeleteConfirm}
            getRepairHistory={getRepairHistory}
            repairJobPartsList={repairJobPartsList}
            onBlurOfCustomerNo={onBlurOfCustomerNo}
            onBlurOfCustomerPONo={onBlurOfCustomerPONo}
            onBlurOfLocationNo={onBlurOfLocationNo}
            onBlurOfReceivedDateDate={onBlurOfReceivedDateDate}
            onBlurOfReceivedDateTime={onBlurOfReceivedDateTime}
            onBlurOfTechnicianCode={onBlurOfTechnicianCode}
            onBlurOfWarrantyApprovedBy={onBlurOfWarrantyApprovedBy}
            onBlurOfModelNo={onBlurOfModelNo}
            onBlurOfSerialNo={onBlurOfSerialNo}
            onBlurOfReplyDateDate={onBlurOfReplyDateDate}
            onBlurOfReplyDateTime={onBlurOfReplyDateTime}
            onBlurOfTradeInItemNo={onBlurOfTradeInItemNo}
            handleSave={handleSave}
            userPermisssionInfo={userPermisssionInfo}
            setButtonDisableFlg={setButtonDisableFlg}
            setRepairHistoryList={setRepairHistoryList}
            setPartsTabActive={setPartsTabActive}
            customerNoTemp={customerNoTemp}
            setCustomerNoTemp={setCustomerNoTemp}
            setConfirmationOpen={setConfirmationOpen}
            setPopUpTitle={setPopUpTitle}
            setPopUpContent={setPopUpContent}
            setPopUpButton={setPopUpButton}
            setAlertMessage={setAlertMessage}
            setAlertType={setAlertType}
          />
        </div>
        <div hidden={value !== 1}>
          <JobsDetailCustomerInfo
            formList={formList}
            setFormListValue={setFormListValue}
            setFormList={setFormList}
            formErrorList={formErrorList}
            setFormErrorList={setFormErrorList}
            setFormErrorListValue={setFormErrorListValue}
            onBlurOfDealerName={onBlurOfDealerName}
            onBlurOfDealerEmailAddress={onBlurOfDealerEmailAddress}
            onBlurOfEndUserName={onBlurOfEndUserName}
            onBlurOfEndUserEmailAddress={onBlurOfEndUserEmailAddress}
            onBlurOfPickupName={onBlurOfPickupName}
            onBlurOfPickupAddress2={onBlurOfPickupAddress2}
            onBlurOfPickupAddress3={onBlurOfPickupAddress3}
            onBlurOfPickupAddress1={onBlurOfPickupAddress1}
            onBlurOfPickupPostalCd={onBlurOfPickupPostalCd}
            onBlurOfReturnAddressName={onBlurOfReturnAddressName}
            onBlurOfReturnAddress2={onBlurOfReturnAddress2}
            onBlurOfReturnAddress3={onBlurOfReturnAddress3}
            onBlurOfReturnAddress1={onBlurOfReturnAddress1}
            onBlurOfReturnPostalCd={onBlurOfReturnPostalCd}
            onBlurOfBillToAddressName={onBlurOfBillToAddressName}
            onBlurOfBillToAddress2={onBlurOfBillToAddress2}
            onBlurOfBillToAddress3={onBlurOfBillToAddress3}
            onBlurOfBillToAddress1={onBlurOfBillToAddress1}
            onBlurOfBillToPostalCd={onBlurOfBillToPostalCd}
            userPermisssionInfo={userPermisssionInfo}
          />
        </div>

        <div hidden={value !== 2}>
          <JobsDetailPartsDrawing
            dataList={dataList}
            partsTabFlg={partsTabFlg}
            errorMessage={errorMessage}
            successMessage={successMessage}
            partsTabStarted={partsTabStarted}
            updateBaseData={updateBaseData}
            userPermisssionInfo={userPermisssionInfo}
            formList={formList}
          />
        </div>
        <div hidden={value !== 3}>
          <JobsDetailMemo
            customerMemorandum={customerMemorandum}
            OtherContents={OtherContents}
            handleClick={handleClick}
            status={status}
            ChangeTextAreaValue={ChangeTextAreaValue}
            handleEditEvent={handleEditEvent}
            handleCancel={handleCancel}
            handleDeleteEvent={handleDeleteEvent}
            otherMemoList={otherMemoList}
            internalUseMemoList={internalUseMemoList}
            memoEditData={memoEditData}
            dataList={dataList}
            userPermisssionInfo={userPermisssionInfo}
          />
        </div>
      </Paper>
      <ModelWindow
        title={popUpTitle}
        contents={confirmationContents}
        button={
          popUpButton === "notification"
            ? notificationButton
            : confirmationButton
        }
        openFlg={confirmationOpen}
        handleCloseEvent={
          popUpButton === "notification"
            ? () => handleConfirmationClose(popupType)
            : () => handleConfirmationClose("")
        }
      />
    </form>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
