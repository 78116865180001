import React, { FC, useState, useCallback, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// route
import { useHistory } from "react-router-dom";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// api
import api from "../../../modules/api/Api";
// common
import { getApiResultStatus } from "../../../modules/common.methods";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { getSign, getCSVTitle } from "../methods/Promotion.Methods";
// methods
import {
  isEmptyCheck,
  checkMaxLength,
} from "../../../modules/hooks/UseValication";
import {
  priceInputBySignForDecimal
} from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { PromotionWarningMessageBox } from "./Promotion.WarningMessageBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    buttonControl02: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "0 20px 15px 20px",
    },
    area: {
      display: "flex",
      alignItems: "center",
    },
    item: {
      "&:not(:last-child)": {
        marginRight: "10px",
      },
      "& span": {
        marginRight: "5px",
      },
    },
    input: {
      display: "none",
    },
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    divWidth:{
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
    errormessage: {
      margin: 0,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },    
  })
);

// upload csv success
let upload_csv_success = 0;

export const PromotionSearchControlButton = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { userPermisssionInfo } = props;

  //const [forceRegisterFlag, setForceRegisterFlag] = useState(false);
  //const [webshopNotReleaseRorceRegisterFlag, setWebshopNotReleaseRorceRegisterFlag] = useState(false);
  //const [bothForceRegisterFlag, setBothForceRegisterFlag] = useState(false);
  
  const [updateCompletedFlag, setUpdateCompletedFlag] = useState(false);

  const [requesteData, setRequesteData] = useState({});
  const [csvValueIndex, setCsvValueIndex] = useState(0);
  const [csvRecordCount, setCsvRecordCount] = useState(0);
  const [csvSuccessCount, setCsvSuccessCount] = useState(0);
  // const [csvDuplicatedPromoNoList, setCsvDuplicatedPromoNoList] = useState<string[]>([]);
  
  const [csvErrorList, setCsvErrorList] = useState() as any;
  const [csvPromoNo, setCsvPromoNo] = useState() as any;
  const [csvDuplicatePromoNo, setCsvDuplicatePromoNo] = useState() as any;
  const [showSuccessAndErrorMessage, setShowSuccessAndErrorMessage] = useState([]) as any;
  const [successAndErrorMessageList, setSuccessAndErrorMessageList] = useState([])  as any;
  const [webshopNotReleaseItemList, setWebshopNotReleaseItemList] = useState([])  as any;

  // loading
  const { setCommonInfo, getCommonConfig } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  // "MS0096": "Number of records in csv file:{{param.param1}}",
  // "MS0097": "Number of successful registered records:{{param.param1}}",
  // "MS0098": "Number of records not register for duplicated:{{param.param1}}",
  // "MS0099": "promotion No:{{param.param1}}",
  // "MS0100": "Number of error:{{param.param1}}",

  const PromotionModelWindow = 
  <div className={classes.divWidth}>
    {successAndErrorMessageList.length > 0 && 
    <h2 className={classes.title}>{t("error")}</h2>
    } 
    <p className={classes.message}>{successAndErrorMessageList}</p>
  </div>


  const config = getCommonConfig() as any;

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  const handleCreatePromotion = () => {
    const path = "/private/promotionDetail";
    handleOnClick(path);
  };

  //click upload button
  const handlerUpload = (files: any) => {
    const file = files[0];
    if (files && files.length > 0) {
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = reader.result;
        let errorData = [] as any;

        let dataList = parseData(data, errorData);
        if (errorData.length>0){
          props.setErrMessageList(errorData);
          props.setErrorMessageOpen(true);          
        } else {
          if (dataList.length > 0) {

            handleInputValue(dataList, errorData,props.salesAnalysisDefinition).then(errorList => { 

              if (errorList.length > 0) {
                
              props.setErrMessageList(errorList.sort((para1,para2)=>{
                let array1 = para1.split(" ");
                let array2 = para2.split(" ");
    
                return array1[1]*1-array2[1]*1;
              }));
              props.setErrorMessageOpen(true);
            }
            });
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const defaultCSVValue: any = {
    promo_no: "",
    promo_name: "",
    start_date: "",
    end_date: "",
    promo_type: "",
    priority: "",
    analysis_cd: "",
    allow_overlap: "0",
    exclusive_flag: "0",
    webshop_flag: "1",
    others_flag: "1",
    // csv_flag: "1",
    terms_cd: "",
    promotion_customer: [],
    category_considered: [],
    category_excluded: [],
    condition_amount_base: [],
    condition_unit_base: [],
    free_goods: [],
    condition_set: [],
    condition_single: [],
    promotion_code: [],
    dealer_discount: [],
  };

  const handleQuotationMarkswithSemicolon = (rowLine: any,splitsymbol: any)=>{

    let strRowLine = String(rowLine);
    let strSplitsymbol = String(splitsymbol);
    let newSplitSymbol = "\"" + strSplitsymbol + "\"";
    let field = strRowLine.split(newSplitSymbol);

    let splitSymbol2 = "\"\"";
    let splitSymbol3 = "\"";
    for(let i=0; i<field.length; i++) {

      field[i] = field[i].replace(new RegExp(splitSymbol2,'g'), splitSymbol3);

      if (i < (field.length-1)) {
        if (!checkSplitSymbol(field[i],splitsymbol)) {
          return [];
        }
      } else {
        if (field[i].slice(-1) === splitsymbol) {

          let strTemp = field[i];
          strTemp = strTemp.slice(0,-1);
          if (!checkSplitSymbol(strTemp,splitsymbol)) {
            return [];
          }
        }
      }

    }

    return field;
  }

  const handleQuotationMarks = (rowLine: any,splitsymbol: any)=>{

    let strRowLine = String(rowLine);
    let strSplitsymbol = String(splitsymbol);

    let field = strRowLine.split(strSplitsymbol);

    for(let i=0; i<field.length; i++) {

      if (field[i].length > 0 && field[i].startsWith("\"") && field[i].endsWith("\"")) {
        field[i] = field[i].slice(1,-1);
      }

    }
    return field;

  }

  const checkSplitSymbol = (field: any, splitsymbol: any) => {

    let strField = String(field);

    if (strField === splitsymbol) {
        return false;
    } else {
      if (strField.includes("\""+splitsymbol)) {
          return false;
      } else if (strField.includes(splitsymbol+"\"")) {
        return false;
      }
    }

    return true;
    
  }

  // parse Data
  const parseData = (data: any, errorData: any[]) => {

    const rowLines = data.split(/\r\n|\n/);
    let dataList = [] as any;
    // let errorData = [] as any;
    let message = {};
    let _defaultCSVValue: any;
    let isExsit = false;
    let holeNum = 0;
    let array_promo_no: string[] = new Array(rowLines.length - 1);
    let array_serial_no: string[] = new Array(rowLines.length - 1);
    let array_duplicate_flag: boolean[] = new Array(rowLines.length - 1);

    if (rowLines.length < 2) {
      message = com("MS0057", {});
      errorData.push(message + "\n");
      return dataList;
    }

    const splitsymbol = getSign(config);
    const csvTitle = getCSVTitle(splitsymbol, t, props.salesAnalysisDefinition);
    const csvFileTitle = String(rowLines[0]) + "\n";
    const csvTitleLength = csvTitle.split(splitsymbol).length;

    if (
      rowLines[0].split(splitsymbol).length !==
      csvTitle.split(splitsymbol).length
    ) {
      message = com("MS0011", { rowNum: t("row") + " " + "1" });
      errorData.push(message + "\n");
      return dataList;
    } else if (csvFileTitle !== csvTitle) {
      message = com("MS0011", { rowNum: t("row") + " " + "1" });
      errorData.push(message + "\n");
      return dataList;
    }

    for (let i = 1; i < rowLines.length; i++) {
      if (rowLines[i] === "") {
        continue;
      }
      let rowMessage1: any = [];
         
      if (rowLines[i].length >1 && rowLines[i].startsWith("\"") && rowLines[i].startsWith("\"")) {
        rowMessage1 = handleQuotationMarkswithSemicolon(rowLines[i],splitsymbol);

        if (rowMessage1.length === 0) {
          let j = i+1;
          message = com("MS0011", { rowNum: t("row") + " " + j });
          errorData.push(message + "\n");
          continue;
        }

        rowMessage1[0] = rowMessage1[0].slice(1);
        // if (rowMessage1[rowMessage1.length-1].slice(-1) === splitsymbol) {
        //   rowMessage1[rowMessage1.length-1] = rowMessage1[rowMessage1.length-1].slice(0,-1);
        // }
        if (rowMessage1[rowMessage1.length-1].slice(-1) === "\"") {
          rowMessage1[rowMessage1.length-1] = rowMessage1[rowMessage1.length-1].slice(0,-1);
        }
      } else {
        rowMessage1 = handleQuotationMarks(rowLines[i],splitsymbol);
      }
      let customer_list_size = props.salesAnalysisDefinition.customer_list.length;
      let item_list_size = props.salesAnalysisDefinition.item_list.length;
      let currentPosition = 15;
      for (let index = 0; index < rowMessage1.length; index++) {
        if (index === currentPosition+customer_list_size || 
            index === currentPosition+customer_list_size + 5 + item_list_size || 
            index === currentPosition+customer_list_size + 12 + item_list_size*2) {
          rowMessage1[index] = rowMessage1[index].trimRight();
        } else {
          rowMessage1[index] = rowMessage1[index].trim();
        }
      }

      _defaultCSVValue = JSON.parse(JSON.stringify(defaultCSVValue));

      if (rowMessage1.length === csvTitleLength) {
        array_promo_no[i - 1] = rowMessage1[0];
        array_serial_no[i - 1] = rowMessage1[1];
        array_duplicate_flag[i - 1] = false;
        if (dataList.length > 0) {
          let isDuplicate = checkDuplicate(
            array_promo_no[i - 1],
            array_serial_no[i - 1],
            i + 1,
            array_promo_no,
            array_serial_no,
            array_duplicate_flag,
            errorData
          );
          for (let j = 0; j < dataList.length; j++) {
            if (rowMessage1[0] === dataList[j].promo_no && !isDuplicate) {
              _defaultCSVValue = dataList[j];
              isExsit = true;
              holeNum = j;
            }
          }
        }
       
        if (isExsit) {
          _defaultCSVValue = readUpLoadData(rowMessage1, _defaultCSVValue, props.salesAnalysisDefinition, i,errorData);
          dataList[holeNum] = _defaultCSVValue;
          isExsit = false;
        } else {
          _defaultCSVValue = readUpLoadData(rowMessage1, _defaultCSVValue, props.salesAnalysisDefinition, i,errorData);
          dataList.push(_defaultCSVValue);
        }
      } else {
       
        let j = i+1;
        message = com("MS0011", { rowNum: t("row") + " " + j });
        errorData.push(message + "\n");
      }
    }
    return dataList;
  };

  const readUpLoadData = (
    rowMessage: any[],
    _defaultCSVValue: any,
    salesAnalysisDefinition: any,
    i: number,
    errorData: any[]
  ) => {
    let customer_list_size = 0;
    let item_list_size = 0;
    if (salesAnalysisDefinition.customer_list) {
      customer_list_size = salesAnalysisDefinition.customer_list.length;
    }
    if (salesAnalysisDefinition.item_list) {
      item_list_size = salesAnalysisDefinition.item_list.length;
    }

    const numberValidation = /^\d+$/;

    if (!numberValidation.test(rowMessage[1])) {
      let message = com("MS0011", {
        rowNum: t("row") + " " + (i+1),
        param1: t("serial_no"),
      });
      errorData.push(message + "\n");
    }

    let currentPosition = 0;
    if (
      (rowMessage[1] !== "" && rowMessage[1] === "1") ||
      rowMessage[0] === "" ||
      rowMessage[0] !== _defaultCSVValue.promo_no
    ) {
      _defaultCSVValue.line_no = i + 1;
      _defaultCSVValue.promo_no = rowMessage[0];
      _defaultCSVValue.promo_name = rowMessage[2];
      _defaultCSVValue.start_date = rowMessage[3];
      _defaultCSVValue.end_date = rowMessage[4];
      _defaultCSVValue.promo_type = rowMessage[5];
      _defaultCSVValue.priority = rowMessage[6];
      _defaultCSVValue.analysis_cd = rowMessage[7];
      _defaultCSVValue.allow_overlap = rowMessage[8];
      _defaultCSVValue.exclusive_flag = rowMessage[9];
      _defaultCSVValue.webshop_flag = rowMessage[10] !== "" ? rowMessage[10] : "0:";
      _defaultCSVValue.others_flag = rowMessage[11] !== "" ? rowMessage[11] : "0:";      

      _defaultCSVValue.terms_cd = rowMessage[41+customer_list_size+item_list_size*4];
    }

    if (
      rowMessage.find((item,index)=>{
        return index>11 && index<(15+customer_list_size) && (item !== "");
      })
    ) {
      // to do casc
      let promotionCustomerObj: any;
      promotionCustomerObj = {
          line_no: i + 1,
          seq_no: rowMessage[1],
          customer_no: rowMessage[12],
          customer_type: rowMessage[13],
          discount_code: rowMessage[14]
      }

      for (let iidex=0; iidex<customer_list_size;iidex++) {
        promotionCustomerObj[salesAnalysisDefinition.customer_list[iidex]] = rowMessage[15+iidex];
      }
      
      _defaultCSVValue.promotion_customer.push(promotionCustomerObj);
    }

    currentPosition = 15 + customer_list_size;//customer


    // to do isac
    if (
      rowMessage.find((item,index)=>{
        return index>(currentPosition-1) && index<(currentPosition+5+item_list_size) && (item !== "");
      })
    ) {
      let categoryConsideredObj: any;
      categoryConsideredObj={
        line_no: i + 1,
        seq_no: rowMessage[1],
        item_no: rowMessage[currentPosition],
        discount_code: rowMessage[currentPosition+1],
        item_class: rowMessage[currentPosition+2],
        quantity: rowMessage[currentPosition+3+item_list_size],
        unit_packet: rowMessage[currentPosition+4+item_list_size],
        data_type: "1",
      };
      for (let iidex=0; iidex<item_list_size;iidex++) {
        categoryConsideredObj[salesAnalysisDefinition.item_list[iidex]] = rowMessage[currentPosition+3+iidex];
      }

      _defaultCSVValue.category_considered.push(categoryConsideredObj);
    }

    currentPosition = currentPosition + 5 + item_list_size;// customer+item

    // to do isac
    if (
      rowMessage.find((item,index)=>{
        return index>(currentPosition-1) && index<(currentPosition+3+item_list_size) && (item !== "");
      })

    ) {
      let categoryExcludedObj: any;
      categoryExcludedObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        item_no: rowMessage[currentPosition],
        discount_code: rowMessage[currentPosition+1],
        item_class: rowMessage[currentPosition+2],
        data_type: "2",
      };
      for (let iidex=0; iidex<item_list_size;iidex++) {
        categoryExcludedObj[salesAnalysisDefinition.item_list[iidex]] = rowMessage[currentPosition+3+iidex];
      }
      _defaultCSVValue.category_excluded.push(categoryExcludedObj);
    }

    currentPosition = currentPosition + 3 + item_list_size;// customer+item*2

    let unitBase_or_amountBase = rowMessage[currentPosition];
    if (
      rowMessage[currentPosition] !== "" &&
      (rowMessage[currentPosition] === "4" || rowMessage[currentPosition].toString().indexOf("1") > -1)
      ) {
        const conditionUnitBaseObj = {
          line_no: i + 1,
          seq_no: rowMessage[1],
          packet: rowMessage[currentPosition+1],
          discount_rate: rowMessage[currentPosition+3],
          data_type: "4",
        };
      _defaultCSVValue.condition_unit_base.push(conditionUnitBaseObj);

    } else if (
      rowMessage[currentPosition] !== "" &&
      (rowMessage[currentPosition] === "5" || rowMessage[currentPosition].toString().indexOf("2") > -1)
    ) {
      const conditionAmountBaseObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        purchase_amount: rowMessage[currentPosition+2],
        discount_rate: rowMessage[currentPosition+3],
        data_type: "5",
      };

      _defaultCSVValue.condition_amount_base.push(conditionAmountBaseObj);
    }

    currentPosition = currentPosition + 4;//customer+item*2

    // to do isac
    if (
      rowMessage.find((item,index)=>{
        return index>(currentPosition-1) && index<(currentPosition+4+item_list_size) && (item !== "");
      })
    ) {
      let freeGoodsObj: any;
      freeGoodsObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        item_no: rowMessage[currentPosition],
        discount_code: rowMessage[currentPosition+1],
        item_class: rowMessage[currentPosition+2],
        quantity: rowMessage[currentPosition+3+item_list_size],
        data_type: "3",
      }; 

      for (let iidex=0; iidex<item_list_size;iidex++) {
        freeGoodsObj[salesAnalysisDefinition.item_list[iidex]] = rowMessage[currentPosition+3+iidex];
      }

      _defaultCSVValue.free_goods.push(freeGoodsObj);
    }

    currentPosition = currentPosition+4+item_list_size;//customer+item*3

    // to do isac
    if (
      rowMessage[currentPosition] !== "" &&
      (unitBase_or_amountBase === "6" || rowMessage[currentPosition].toString().indexOf("1") > -1)
    ) {
      let conditionSingleObj: any;
      conditionSingleObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        single_type: rowMessage[currentPosition+1],
        item_no: rowMessage[currentPosition+2],
        discount_code: rowMessage[currentPosition+3],
        item_class: rowMessage[currentPosition+4],
        quantity: rowMessage[currentPosition+5+item_list_size],
        special_price: rowMessage[currentPosition+6+item_list_size],
        data_type: "6",
      };

      for (let iidex=0; iidex<item_list_size;iidex++) {
        conditionSingleObj[salesAnalysisDefinition.item_list[iidex]] = rowMessage[currentPosition+5+iidex];
      }

      _defaultCSVValue.condition_single.push(conditionSingleObj);

    } else if (
      rowMessage[currentPosition] !== "" &&
      (unitBase_or_amountBase === "7" || rowMessage[currentPosition].toString().indexOf("2") > -1)
    ) {
      let conditionSetObj: any;
      conditionSetObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        item_no: rowMessage[currentPosition+2],
        discount_code: rowMessage[currentPosition+3],
        item_class: rowMessage[currentPosition+4],
        quantity: rowMessage[currentPosition+5+item_list_size],
        special_price: rowMessage[currentPosition+6+item_list_size],
        data_type: "7",
      };

      for (let iidex=0; iidex<item_list_size;iidex++) {
        conditionSetObj[salesAnalysisDefinition.item_list[iidex]] = rowMessage[currentPosition+5+iidex];
      }

      _defaultCSVValue.condition_set.push(conditionSetObj);
    }

    currentPosition = currentPosition+7+item_list_size;//customer+item*4
  
    if (
      rowMessage[currentPosition] !== "" ||
      rowMessage[currentPosition+1] !== "" ||
      rowMessage[currentPosition+2] !== ""
      ) {
      const promotionCodeObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        promotion_code: rowMessage[currentPosition],
        discount_rate: rowMessage[currentPosition+1],
        promo_code_type: rowMessage[currentPosition+2],
        data_type: "8",
      };
      _defaultCSVValue.promotion_code.push(promotionCodeObj);
    }

    if (rowMessage[currentPosition+4] !== "") {
      const dealerDiscountObj = {
        line_no: i + 1,
        seq_no: rowMessage[1],
        discount_rate: rowMessage[currentPosition+4],
        data_type: "9",
      };
      _defaultCSVValue.dealer_discount.push(dealerDiscountObj);
    }

    return _defaultCSVValue;
  };

  // handle Input Value
  const checkDuplicate = (
    promo_no: string,
    serial_no: string,
    lineNum: number,
    array_promo_no: string[],
    array_serial_no: string[],
    array_duplicate_flag: boolean[],
    errorData: any[]
  ) => {
    let message = "";
    for (let i = 0; i < lineNum - 2; i++) {
      if (promo_no === array_promo_no[i] && serial_no === array_serial_no[i]) {
        if (!array_duplicate_flag[i]) {
          array_duplicate_flag[i] = true;

          message = com("MA0001", {
            rowNum: t("row") + " " + (i + 2),
            param1: t("serial_no"),
            param2: t("promo_no"),
          });
          errorData.push(message + "\n");
        }

        array_duplicate_flag[lineNum - 2] = true;
        message = com("MA0001", {
          rowNum: t("row") + " " + lineNum,
          param1: t("serial_no"),
          param2: t("promo_no"),
        });
        errorData.push(message + "\n");

        return true;
      }
    }
    return false;
  };

  // handle Input Value
  const handleInputValue = async(value: any, errorData: any[], salesAnalysisDefinition: any)=>{

    console.log("handleInputValue", value);

    let dataObj: any[] = [];
    let formatDataObj: any[] = [];
    // let errorData: any[] = [];
    let message = "";

    let customer_list_size = 0;
    let item_list_size = 0;
    if (salesAnalysisDefinition.customer_list) {
      customer_list_size = salesAnalysisDefinition.customer_list.length;
    }
    if (salesAnalysisDefinition.item_list) {
      item_list_size = salesAnalysisDefinition.item_list.length;
    }

    value.forEach((item: any) => {
      console.log("handleInputValue item", item);
      if (isEmptyCheck(item.promo_type)) {
        message = com("MS0001", {
          rowNum: t("row") + " " + item.line_no,
          param1: t("promotion_type"),
        });
        errorData.push(message + "\n");
      } else {
        let array_promo_type = item.promo_type.split(":");

        if (
          array_promo_type[0] !== "10" &&
          array_promo_type[0] !== "20" &&
          array_promo_type[0] !== "30" &&
          array_promo_type[0] !== "40" &&
          array_promo_type[0] !== "50" &&
          array_promo_type[0] !== "60"
        ) {
          message = com("MS0011", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("promotion_type"),
          });
          errorData.push(message + "\n");
        }
      }
    });

    // filter match promo type json data
    dataObj = value.filter((item: any) => {
      return (
        item.promo_type.indexOf("10") > -1 ||
        item.promo_type.indexOf("20") > -1 ||
        item.promo_type.indexOf("30") > -1 ||
        item.promo_type.indexOf("40") > -1 ||
        item.promo_type.indexOf("50") > -1 ||
        item.promo_type.indexOf("60") > -1
      );
    });

    if (dataObj.length > 0) {
      dataObj.forEach((item: any) => {
        const obj: any = {};
        obj.line_no = item.line_no;
        obj.promo_no = item.promo_no;
        obj.promo_name = item.promo_name;
        obj.promo_type = item.promo_type;
        obj.analysis_cd = item.analysis_cd;
        obj.start_date = item.start_date;
        obj.end_date = item.end_date;
        obj.promotion_customer = item.promotion_customer;

        obj.exclusive_flag = item.exclusive_flag;
        obj.webshop_flag = item.webshop_flag;
        obj.others_flag = item.others_flag;

        if (
          item.promo_type.indexOf("10") > -1 ||
          item.promo_type.indexOf("30") > -1 ||
          item.promo_type.indexOf("40") > -1 ||
          item.promo_type.indexOf("50") > -1
        ) {
          obj.priority = item.priority;
          obj.allow_overlap = item.allow_overlap;
        }

        if (
          item.promo_type.indexOf("10") > -1 ||
          item.promo_type.indexOf("20") > -1 ||
          item.promo_type.indexOf("30") > -1 ||
          item.promo_type.indexOf("50") > -1
        ) {
          obj.category_considered = item.category_considered;
          obj.category_excluded = item.category_excluded;
        }

        if (item.promo_type.indexOf("10") > -1) {
          obj.condition_unit_base = item.condition_unit_base;
          obj.condition_amount_base = item.condition_amount_base;
        }

        if (item.promo_type.indexOf("20") > -1) {
          obj.dealer_discount = item.dealer_discount;
        }

        if (item.promo_type.indexOf("30") > -1) {
          obj.free_goods = item.free_goods;
        }

        if (item.promo_type.indexOf("40") > -1) {
          obj.condition_single = item.condition_single;
          obj.condition_set = item.condition_set;
        }

        if (item.promo_type.indexOf("50") > -1) {
          obj.promotion_code = item.promotion_code;
        }

        if (item.promo_type.indexOf("60") > -1) {
          obj.terms_cd = item.terms_cd;
        }

        formatDataObj.push(obj);
      });

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////      
    // customer
    const customerNoNullCheck = (promotion_customer: any) => {

      const customerObj: any[] = promotion_customer;

      for (let i = 0; i < customerObj.length; i++) {
        const obj = {
          customer_type: customerObj[i].customer_type,
          customer_no: customerObj[i].customer_no,
          discount_code: customerObj[i].discount_code,
          csac1: customerObj[i].csac1,
          csac2: customerObj[i].csac2,
          csac3: customerObj[i].csac3,
          csac4: customerObj[i].csac4,
          csac5: customerObj[i].csac5,
          csac6: customerObj[i].csac6,
          csac7: customerObj[i].csac7,
          csac8: customerObj[i].csac8,
          csac9: customerObj[i].csac9,
          csac10: customerObj[i].csac10,
          promotion_region: customerObj[i].promotion_region
        };
        const keys = Object.values(obj);
        const nullFlag = keys.every((item: any) => {
          return item === "";
        });

        if (!nullFlag) {
          return true;
        }
      }
      return false;
    };

      const numberValidation = /^\d+$/;
      const numberOrAlphabetValidation = /^[a-zA-Z0-9]+$/;
      const priceValidation = /^\d{1,8}(\.\d{1,2})?$/;

      const dateValidation = (date: any) => {
        const validation =
          /^((19|20)\d\d)-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
        if (validation.test(date)) {
          const targetDate = date.split("-");
          const compareDate = [
            new Date(date).getFullYear(),
            new Date(date).getMonth() + 1,
            new Date(date).getDate(),
          ];

          if (
            Number(targetDate[0]) === Number(compareDate[0]) &&
            Number(targetDate[1]) === Number(compareDate[1]) &&
            Number(targetDate[2]) === Number(compareDate[2])
          ) {
            return true;
          }
        } else {
          return false;
        }
      };

      formatDataObj.forEach((item: any) => {
        // promotion name
        if (!checkMaxLength(item.promo_name, 50)) {
          message = com("MS0002", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("promo_name"),
            param2: 50,
          });
          errorData.push(message + "\n");
        }

        // promotion type
        if (isEmptyCheck(item.promo_type)) {
          message = com("MS0001", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("promotion_type"),
          });
          errorData.push(message + "\n");
        } else if (
          item.promo_type.indexOf("10") === -1 &&
          item.promo_type.indexOf("20") === -1 &&
          item.promo_type.indexOf("30") === -1 &&
          item.promo_type.indexOf("40") === -1 &&
          item.promo_type.indexOf("50") === -1 &&
          item.promo_type.indexOf("60") === -1
        ) {
          message = com("MS0011", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("promotion_type"),
          });
          errorData.push(message + "\n");
        }

        if (
          item.promo_type.indexOf("10") > -1 ||
          item.promo_type.indexOf("30") > -1 ||
          item.promo_type.indexOf("40") > -1 ||
          item.promo_type.indexOf("50") > -1
        ) {
          // priority
          const matchPriority = (priority: any) => {
            const priorityArray = getName("priority_option").filter(
              (option: any) => {
                return option.code === priority;
              }
            );
            return priorityArray.length === 0 ? true : false;
          };

          if (isEmptyCheck(item.priority)) {
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("priority"),
            });
            errorData.push(message + "\n");
          } else if (matchPriority(item.priority)) {
            message = com("MS0011", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("priority"),
            });
            errorData.push(message + "\n");
          }

          // allow overlap
          if (!isEmptyCheck(item.allow_overlap)) {
            if (
              item.allow_overlap.slice(0, 2) !== "0:" &&
              item.allow_overlap.slice(0, 2) !== "1:"
            ) {
              message = com("MS0011", {
                rowNum: t("row") + " " + item.line_no,
                param1: t("allow_overlap"),
              });
              errorData.push(message + "\n");
            }
          }
        }

        // exclusive_flag
        if (!isEmptyCheck(item.exclusive_flag)) {
          if (
            item.exclusive_flag.slice(0, 2) !== "0:" &&
            item.exclusive_flag.slice(0, 2) !== "1:"
          ) {
            message = com("MS0011", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("exclusive_flag"),
            });
            errorData.push(message + "\n");
          }
        }

        // webshop_flag
        if (!isEmptyCheck(item.webshop_flag)) {
          if (
            item.webshop_flag.slice(0, 2) !== "0:" &&
            item.webshop_flag.slice(0, 2) !== "1:"
          ) {
            message = com("MS0011", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("webshop_flag"),
            });
            errorData.push(message + "\n");
          }
        }

        // others_flag
        if (!isEmptyCheck(item.others_flag)) {
          if (
            item.others_flag.slice(0, 2) !== "0:" &&
            item.others_flag.slice(0, 2) !== "1:"
          ) {
            message = com("MS0011", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("others_flag"),
            });
            errorData.push(message + "\n");
          }
        }

        // analysis code
        if (!checkMaxLength(item.analysis_cd, 15)) {
          message = com("MS0002", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("analysis_cd"),
            param2: 15,
          });
          errorData.push(message + "\n");
        }

        // date
        if (isEmptyCheck(item.start_date)) {
          message = com("MS0001", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("start_date"),
          });
          errorData.push(message + "\n");
        } else {
          if (!dateValidation(item.start_date)) {
            message = com("MS0011", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("start_date"),
            });
            errorData.push(message + "\n");
          } else {
            if (!isEmptyCheck(item.end_date) && dateValidation(item.end_date)) {
              if (
                new Date(item.start_date).getTime() >
                new Date(item.end_date).getTime()
              ) {
                let message = com("MS0005", {
                  rowNum: t("row") + " " + item.line_no,
                  startDate: t("start_date"),
                  endDate: t("end_date"),
                });
                errorData.push(message + "\n");
              }
            }
          }
        }

        if (!isEmptyCheck(item.end_date) && !dateValidation(item.end_date)) {
          message = com("MS0011", {
            rowNum: t("row") + " " + item.line_no,
            param1: t("end_date"),
          });
          errorData.push(message + "\n");
        }

        // customer
        const customer = item.promotion_customer;
        if (customer.length > 0) {
          customer.forEach((option: any) => {
          
            // customer no
            if (!isEmptyCheck(option.customer_no)) {

              if (!numberValidation.test(option.customer_no)) {
                message = com("MS0011", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("customer_no"),
                });
                errorData.push(message + "\n");

              } else if (!checkMaxLength(option.customer_no, 8)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("customer_no"),
                    param2: 8,
                  });
                  errorData.push(message + "\n");
              }
            }

            // customer type
            if (!isEmptyCheck(option.customer_type)) {
              if (!checkMaxLength(option.customer_type, 4)) {
                message = com("MS0002", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("customer_type"),
                  param2: 4,
                  param3: t("customer"),
                });
                errorData.push(message + "\n");
              }
            }

            // discount code
            if (!isEmptyCheck(option.discount_code)) {
              if (!checkMaxLength(option.discount_code, 2)) {
                message = com("MS0002", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("discount_code"),
                  param2: 2,
                  param3: t("customer"),
                });
                errorData.push(message + "\n");
              } 
              // else if (
              //   !numberOrAlphabetValidation.test(option.discount_code)
              // ) {
              //   message = com("MS0011", {
              //     rowNum: t("row") + " " + option.line_no,
              //     param1: t("discount_code"),
              //     param2: t("customer"),
              //   });
              //   errorData.push(message + "\n");
              // }
            }

            // csac
            for (let iidex=0;iidex<customer_list_size;iidex++) {

              // if (!isEmptyCheck(option.csac1)) {
              if (!isEmptyCheck(option[salesAnalysisDefinition.customer_list[iidex]])) {
                // if (!checkMaxLength(option.csac1, 5)) {
                if (!checkMaxLength(option[salesAnalysisDefinition.customer_list[iidex]], 5)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    // param1: t("csac1"),
                    // param1: t(option[salesAnalysisDefinition.customer_list[iidex]]),
                    param1: t(salesAnalysisDefinition.customer_list[iidex]),
                    param2: 5,
                  });
                  errorData.push(message + "\n");

                }
              }
            }
          });
        }

        // has same customer no
        if (customer.length > 1) {
          const customerArray: any[] = [];
          customer.forEach((option: any) => {
            if (!isEmptyCheck(option.customer_no)) {
              customerArray.push(option);
            }
          });

          const notNullObj: any[] = [];
          customerArray.forEach((option: any) => {
            const customerNoArray: any[] = [];
            notNullObj.forEach((child: any) => {
              for (let key in child) {
                customerNoArray.push(key);
              }
            });

            if (customerNoArray.length === 0) {
              notNullObj.push({
                [option.customer_no]: [option.line_no],
              });
            } else {
              const index = customerNoArray.indexOf(option.customer_no);
              if (index === -1) {
                notNullObj.push({
                  [option.customer_no]: [option.line_no],
                });
              } else {
                let keys = "";
                for (let key in notNullObj[index]) {
                  keys = key;
                }
                notNullObj[index][keys].push(option.line_no);
              }
            }
          });

          let values: any[] = [];
          notNullObj.forEach((option: any) => {
            for (let key in option) {
              if (option[key].length > 1) {
                values.push(option[key]);
              }
            }
          });
          values = values.flat();

          values.forEach((option: any) => {
            message = com("MS0087", {
              rowNum: t("row") + " " + option,
              param2: t("customer_no"),
            });
            errorData.push(message + "\n");
          });
        }

        // has same customer recoed
        if (customer.length > 1) {
          const customerRecordArray: any[] = [];
          customer.forEach((option: any) => {

            const obj: any = {
              customer_type: option.customer_type,
              customer_no: option.customer_no,
              discount_code: option.discount_code,
            };
            for (let iidex=0;iidex<customer_list_size;iidex++) {
              obj[salesAnalysisDefinition.customer_list[iidex]] = option[salesAnalysisDefinition.customer_list[iidex]];
            }

            const values = Object.values(obj);
            const hasValueFlag = values.some((item: any) => {
              return item !== "";
            });

            if (hasValueFlag) {
              customerRecordArray.push(option);
            }
          });

          const notNullRecord: any[] = [];
          customerRecordArray.forEach((option: any) => {
            const customerArray: any[] = [];
            notNullRecord.forEach((child: any) => {
              for (let key in child) {
                customerArray.push(key);
              }
            });
            const obj: any = {
              customer_type: option.customer_type,
              customer_no: option.customer_no,
              discount_code: option.discount_code,
            };
            for (let iidex=0;iidex<customer_list_size;iidex++) {
              obj[salesAnalysisDefinition.customer_list[iidex]] = option[salesAnalysisDefinition.customer_list[iidex]];
            }

            if (customerArray.length === 0) {
              notNullRecord.push({
                [Object.values(obj).toString()]: [option.line_no],
              });
            } else {
              const index = customerArray.indexOf(
                Object.values(obj).toString()
              );
              if (index === -1) {
                notNullRecord.push({
                  [Object.values(obj).toString()]: [option.line_no],
                });
              } else {
                let keys = "";
                for (let key in notNullRecord[index]) {
                  keys = key;
                }
                notNullRecord[index][keys].push(option.line_no);
              }
            }
          });

          let values: any[] = [];
          notNullRecord.forEach((option: any) => {
            for (let key in option) {
              if (option[key].length > 1) {
                values.push(option[key]);
              }
            }
          });
          values = values.flat();

          values.forEach((option: any) => {
            message = com("MS0087", {
              rowNum: t("row") + " " + option,
              param2: t("customer_information"),
            });
            errorData.push(message + "\n");
          });
        }

        // has no customer information
        if (
          item.promo_type.indexOf("20") > -1 ||
          item.promo_type.indexOf("60") > -1
        ) {
          if (item.promotion_customer.length === 0) {
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("customer_information"),
            });
            errorData.push(message + "\n");
          } else {
            let customerNoNullCheckResult = customerNoNullCheck(item.promotion_customer);
            if (!customerNoNullCheckResult) {
              message = com("MS0057", {
                rowNum: t("row") + " " + item.line_no,
              });
              errorData.push(message + "\n");
            }          
          }

        }

        // Considered and Excluded
        if (
          item.promo_type.indexOf("10") > -1 ||
          item.promo_type.indexOf("20") > -1 ||
          item.promo_type.indexOf("30") > -1 ||
          item.promo_type.indexOf("50") > -1
        ) {
          const considered = item.category_considered;
          const excluded = item.category_excluded;

          // Considered
          if (considered.length > 0) {
            considered.forEach((option: any) => {
              // item class
              if (!isEmptyCheck(option.item_class)) {
                if (!checkMaxLength(option.item_class, 5)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_class"),
                    param2: 5,
                    param3: t("considered"),
                  });
                  errorData.push(message + "\n");
                }
              }

              // discount code
              if (!isEmptyCheck(option.discount_code)) {
                if (!checkMaxLength(option.discount_code, 3)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("discount_code"),
                    param2: 3,
                    param3: t("considered"),
                  });
                  errorData.push(message + "\n");
                } 
                // else if (
                //   !numberOrAlphabetValidation.test(option.discount_code)
                // ) {
                //   message = com("MS0011", {
                //     rowNum: t("row") + " " + option.line_no,
                //     param1: t("discount_code"),
                //     param2: t("considered"),
                //   });
                //   errorData.push(message + "\n");
                // }
              }

              // item no
              if (!isEmptyCheck(option.item_no)) {
                if (!checkMaxLength(option.item_no, 35)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_no"),
                    param2: 35,
                    param3: t("considered"),
                  });
                  errorData.push(message + "\n");
                }
              }

              if (item.promo_type.indexOf("10") > -1) {
                // unit packet
                if (!isEmptyCheck(option.unit_packet)) {
                  if (!numberValidation.test(option.unit_packet)) {
                    message = com("MS0011", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("units_per_packet"),
                    });
                    errorData.push(message.replace("&#x2F;", "/") + "\n");

                  } else if (!checkMaxLength(option.unit_packet, 9)) {
                      message = com("MS0002", {
                        rowNum: t("row") + " " + option.line_no,
                        param1: t("units_per_packet"),
                        param2: 9,
                      });
                      errorData.push(message.replace("&#x2F;", "/") + "\n");

                  } else if (Number(option.unit_packet) > 2147483647) {
                    message = com("MS0083", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("units_per_packet"),
                    });
                    errorData.push(message.replace("&#x2F;", "/") + "\n");
                  } else if (Number(option.unit_packet) < 1) {
                    message = com("MS0110", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("units_per_packet"),
                    });
                    errorData.push(message.replace("&#x2F;", "/") + "\n");

                  }
                }

                // to do isac
                if (
                  isEmptyCheck(option.item_class) &&
                  isEmptyCheck(option.discount_code) &&
                  isEmptyCheck(option.item_no) &&
                  // isEmptyCheck(option.isac3) &&
                  // isEmptyCheck(option.isac6) &&
                  salesAnalysisDefinition.item_list.every((item:any)=>{
                    return isEmptyCheck(option[item]);
                  }) &&
                  !isEmptyCheck(option.unit_packet)
                ) {
                  message = com("MS0001", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_information"),
                    param2: t("considered"),
                  });
                  errorData.push(message + "\n");
                } else if (
                  // to do isac
                  (!isEmptyCheck(option.item_class) ||
                    !isEmptyCheck(option.discount_code) ||
                    !isEmptyCheck(option.item_no) ||
                    salesAnalysisDefinition.item_list.some((item:any)=>{
                      return !isEmptyCheck(option[item]);
                    })) &&
                  isEmptyCheck(option.unit_packet)
                ) {
                  message = com("MS0001", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("units_per_packet"),
                  });
                  errorData.push(message.replace("&#x2F;", "/") + "\n");
                }
              } else {
                // quantity
                if (!isEmptyCheck(option.quantity)) {
                  if (!numberValidation.test(option.quantity)) {
                    message = com("MS0011", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("quantity"),
                      param2: t("considered"),
                    });
                    errorData.push(message + "\n");

                  } else if (!checkMaxLength(option.quantity, 9)) {
                      message = com("MS0002", {
                        rowNum: t("row") + " " + option.line_no,
                        param1: t("quantity"),
                        param2: 9,
                        param3: t("considered"),
                      });
                      errorData.push(message + "\n");
  
                  } else if (Number(option.quantity) < 1) {
                    message = com("MS0110", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("quantity"),
                      param2: t("considered"),
                    });
                    errorData.push(message + "\n");
                  }
                }

                // to do isac
                if (
                  isEmptyCheck(option.item_class) &&
                  isEmptyCheck(option.discount_code) &&
                  isEmptyCheck(option.item_no) &&
                  salesAnalysisDefinition.item_list.every((item:any)=>{
                    return isEmptyCheck(option[item]);
                  }) &&

                  !isEmptyCheck(option.quantity)
                ) {
                  message = com("MS0001", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_information"),
                    param2: t("considered"),
                  });
                  errorData.push(message + "\n");
                } else if (
                  // to do isac
                  (!isEmptyCheck(option.item_class) ||
                    !isEmptyCheck(option.discount_code) ||
                    !isEmptyCheck(option.item_no) ||
                    salesAnalysisDefinition.item_list.some((item:any)=>{
                      return !isEmptyCheck(option[item]);
                    })) &&
                  isEmptyCheck(option.quantity)
                ) {
                  message = com("MS0001", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("quantity"),
                    param2: t("considered"),
                  });
                  errorData.push(message + "\n");
                }
              }
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                if (!isEmptyCheck(option[item])) {
                  if (!checkMaxLength(option[item], 5)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t(item),
                      param2: 5,
                      param3: t("considered"),
                    });
                    errorData.push(message + "\n");
                  }
                }
              });
            });
          }

          // has same item recoed
          if (considered.length > 1) {
            const consideredRecordArray: any[] = [];
            considered.forEach((option: any) => {
              const obj: any = {
                item_class: option.item_class,
                discount_code: option.discount_code,
                item_no: option.item_no,
              };
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                obj[item] = option[item];
              });

              const values = Object.values(obj);
              const hasValueFlag = values.some((item: any) => {
                return item !== "";
              });

              if (hasValueFlag) {
                consideredRecordArray.push(option);
              }
            });

            const notNullRecord: any[] = [];
            consideredRecordArray.forEach((option: any) => {
              const consideredArray: any[] = [];
              notNullRecord.forEach((child: any) => {
                for (let key in child) {
                  consideredArray.push(key);
                }
              });

              const obj: any = {
                item_class: option.item_class,
                discount_code: option.discount_code,
                item_no: option.item_no,
              };
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                obj[item] = option[item];
              });

              if (consideredArray.length === 0) {
                notNullRecord.push({
                  [Object.values(obj).toString()]: [option.line_no],
                });
              } else {
                const index = consideredArray.indexOf(
                  Object.values(obj).toString()
                );
                if (index === -1) {
                  notNullRecord.push({
                    [Object.values(obj).toString()]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullRecord[index]) {
                    keys = key;
                  }
                  notNullRecord[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullRecord.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("considered"),
                param2: t("item_information"),
              });
              errorData.push(message + "\n");
            });
          }

          // has same item no
          if (considered.length > 1) {
            const consideredArray: any[] = [];
            considered.forEach((option: any) => {
              if (!isEmptyCheck(option.item_no)) {
                consideredArray.push(option);
              }
            });

            const notNullObj: any[] = [];
            consideredArray.forEach((option: any) => {
              const itemNoArray: any[] = [];
              notNullObj.forEach((child: any) => {
                for (let key in child) {
                  itemNoArray.push(key);
                }
              });

              if (itemNoArray.length === 0) {
                notNullObj.push({
                  [option.item_no]: [option.line_no],
                });
              } else {
                const index = itemNoArray.indexOf(option.item_no);
                if (index === -1) {
                  notNullObj.push({
                    [option.item_no]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullObj[index]) {
                    keys = key;
                  }
                  notNullObj[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullObj.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("considered"),
                param2: t("item"),
              });
              errorData.push(message + "\n");
            });
          }

          // Excluded
          if (excluded.length > 0) {
            excluded.forEach((option: any) => {
              // item class
              if (!isEmptyCheck(option.item_class)) {
                if (!checkMaxLength(option.item_class, 5)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_class"),
                    param2: 5,
                    param3: t("excluded"),
                  });
                  errorData.push(message + "\n");
                }
              }

              // discount code
              if (!isEmptyCheck(option.discount_code)) {
                if (!checkMaxLength(option.discount_code, 3)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("discount_code"),
                    param2: 3,
                    param3: t("excluded"),
                  });
                  errorData.push(message + "\n");
                } 
                // else if (
                //   !numberOrAlphabetValidation.test(option.discount_code)
                // ) {
                //   message = com("MS0011", {
                //     rowNum: t("row") + " " + option.line_no,
                //     param1: t("discount_code"),
                //     param2: t("excluded"),
                //   });
                //   errorData.push(message + "\n");
                // }
              }

              // item no
              if (!isEmptyCheck(option.item_no)) {
                if (!checkMaxLength(option.item_no, 35)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_no"),
                    param2: 35,
                    param3: t("excluded"),
                  });
                  errorData.push(message + "\n");
                }
              }
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                // obj[item] = option[item];
                if (!isEmptyCheck(option[item])) {
                  if (!checkMaxLength(option[item], 5)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t(item),
                      param2: 5,
                      param3: t("excluded"),
                    });
                    errorData.push(message + "\n");
                  }
                }
              });
            });
          }

          // has same item recoed
          if (excluded.length > 1) {
            const excludedRecordArray: any[] = [];
            excluded.forEach((option: any) => {
              const obj: any = {
                item_class: option.item_class,
                discount_code: option.discount_code,
                item_no: option.item_no,
              };
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                obj[item] = option[item];
              });

              const values = Object.values(obj);
              const hasValueFlag = values.some((item: any) => {
                return item !== "";
              });

              if (hasValueFlag) {
                excludedRecordArray.push(option);
              }
            });

            const notNullRecord: any[] = [];
            excludedRecordArray.forEach((option: any) => {
              const excludedArray: any[] = [];
              notNullRecord.forEach((child: any) => {
                for (let key in child) {
                  excludedArray.push(key);
                }
              });

              const obj: any = {
                item_class: option.item_class,
                discount_code: option.discount_code,
                item_no: option.item_no,
              };
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                obj[item] = option[item];
              });

              if (excludedArray.length === 0) {
                notNullRecord.push({
                  [Object.values(obj).toString()]: [option.line_no],
                });
              } else {
                const index = excludedArray.indexOf(
                  Object.values(obj).toString()
                );
                if (index === -1) {
                  notNullRecord.push({
                    [Object.values(obj).toString()]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullRecord[index]) {
                    keys = key;
                  }
                  notNullRecord[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullRecord.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("excluded"),
                param2: t("item_information"),
              });
              errorData.push(message + "\n");
            });
          }

          // has same item no
          if (excluded.length > 1) {
            const excludedArray: any[] = [];
            excluded.forEach((option: any) => {
              if (!isEmptyCheck(option.item_no)) {
                excludedArray.push(option);
              }
            });

            const notNullObj: any[] = [];
            excludedArray.forEach((option: any) => {
              const itemNoArray: any[] = [];
              notNullObj.forEach((child: any) => {
                for (let key in child) {
                  itemNoArray.push(key);
                }
              });

              if (itemNoArray.length === 0) {
                notNullObj.push({
                  [option.item_no]: [option.line_no],
                });
              } else {
                const index = itemNoArray.indexOf(option.item_no);
                if (index === -1) {
                  notNullObj.push({
                    [option.item_no]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullObj[index]) {
                    keys = key;
                  }
                  notNullObj[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullObj.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("excluded"),
                param2: t("item"),
              });
              errorData.push(message + "\n");
            });
          }
        }

        // Discount
        if (item.promo_type.indexOf("10") > -1) {
          const unitBase = item.condition_unit_base;
          const amountBase = item.condition_amount_base;
          if (unitBase.length === 0 && amountBase.length === 0) {
            message = com("MS0053", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("unit_base"),
              param2: t("amount_base"),
            });
            errorData.push(message + "\n");
          }

          if (unitBase.length > 0 && amountBase.length > 0) {
            unitBase.forEach((option: any) => {
              message = com("MS0088", {
                rowNum: t("row") + " " + option.line_no,
                param1: t("unit_base"),
                param2: t("amount_base"),
              });
              errorData.push(message + "\n");
            });

            amountBase.forEach((option: any) => {
              message = com("MS0088", {
                rowNum: t("row") + " " + option.line_no,
                param1: t("unit_base"),
                param2: t("amount_base"),
              });
              errorData.push(message + "\n");
            });
          }

          // Unit Base
          if (unitBase.length > 0 && amountBase.length === 0) {
            unitBase.forEach((option: any) => {
              // packet
              if (!isEmptyCheck(option.packet)) {

                if (!numberValidation.test(option.packet)) {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("packet"),
                  });
                  errorData.push(message + "\n");

                } else if (!checkMaxLength(option.packet, 10)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("packet"),
                      param2: 10,
                    });
                    errorData.push(message + "\n");
                }

              } else {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("packet"),
                });
                errorData.push(message + "\n");
              }

              // discount rate
              if (!isEmptyCheck(option.discount_rate)) {
                let discount_rate = priceInputBySignForDecimal(option.discount_rate);
                if (!isNaN(Number(discount_rate))) {
                  if (
                    Number(discount_rate) > 100 ||
                    Number(discount_rate) < 0
                  ) {
                    message = com("MS0011", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("discount_rate"),
                    });
                    errorData.push(message + "\n");
                  }
                } else {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("discount_rate"),
                  });
                  errorData.push(message + "\n");
                }
              } else {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("discount_rate"),
                });
                errorData.push(message + "\n");
              }
            });

            // compare packet value
            const hasNullValue = unitBase.every((item: any) => {
              return item.packet !== "";
            });

            if (hasNullValue) {
              if (unitBase.length > 1) {
                let targetIndex: any = "";
                let targetValue: any = "";
                const filterValues: any[] = [];
                for (let i = 0; i < unitBase.length; i++) {
                  if (i + 1 !== unitBase.length) {
                    if (unitBase[i + 1].packet*1 <= unitBase[i].packet*1) {
                      targetIndex = i;
                      targetValue = unitBase[i].packet;
                      break;
                    }
                  }
                }

                for (let i = targetIndex + 1; i < unitBase.length; i++) {
                  if (unitBase[i].packet <= targetValue) {
                    filterValues.push(unitBase[i]);
                  }
                }

                filterValues.forEach((item: any) => {
                  message = com("MS0086", {
                    rowNum: t("row") + " " + item.line_no,
                    param1: t("packet"),
                  });
                  errorData.push(message + "\n");
                });
              }
            }
          }

          // Amount Base
          if (unitBase.length === 0 && amountBase.length > 0) {
            amountBase.forEach((option: any) => {
              // Purchase Amount more than
              if (!isEmptyCheck(option.purchase_amount)) {
                if (isNaN(option.purchase_amount)) {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("purchase_amount"),
                  });
                  errorData.push(message + "\n");
                } else {
                  if (!checkMaxLength(option.purchase_amount, 11)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("purchase_amount"),
                      param2: 11,
                    });
                    errorData.push(message + "\n");
                  } else if (Number(option.purchase_amount) === 0) {
                    message = com("MS0086", {
                      rowNum: t("row") + " " + item.line_no,
                      param1: t("purchase_amount"),
                    });
                    errorData.push(message + "\n");
                  } else if (!priceValidation.test(option.purchase_amount)) {
                    message = com("MS0082", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("purchase_amount"),
                    });
                    errorData.push(message + "\n");
                  }
                }
              } else {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("purchase_amount"),
                });
                errorData.push(message + "\n");
              }

              // discount rate
              if (!isEmptyCheck(option.discount_rate)) {
                let discount_rate = priceInputBySignForDecimal(option.discount_rate);
                if (!isNaN(Number(discount_rate))) {
                  if (
                    Number(discount_rate) > 100 ||
                    Number(discount_rate) < 0
                  ) {
                    message = com("MS0011", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("discount_rate"),
                    });
                    errorData.push(message + "\n");
                  }
                } else {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("discount_rate"),
                  });
                  errorData.push(message + "\n");
                }
              } else {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("discount_rate"),
                });
                errorData.push(message + "\n");
              }
            });

            // compare purchase amount value
            const hasNullValue = amountBase.every((item: any) => {
              return item.purchase_amount !== "";
            });

            if (hasNullValue) {
              if (amountBase.length > 1) {
                let targetIndex: any = "";
                let targetValue: any = "";
                const filterValues: any[] = [];
                for (let i = 0; i < amountBase.length; i++) {
                  if (i + 1 !== amountBase.length) {
                    if (
                      amountBase[i + 1].purchase_amount <=
                      amountBase[i].purchase_amount
                    ) {
                      targetIndex = i;
                      targetValue = amountBase[i].purchase_amount;
                      break;
                    }
                  }
                }

                for (let i = targetIndex + 1; i < amountBase.length; i++) {
                  if (amountBase[i].purchase_amount <= targetValue) {
                    filterValues.push(amountBase[i]);
                  }
                }

                filterValues.forEach((item: any) => {
                  message = com("MS0086", {
                    rowNum: t("row") + " " + item.line_no,
                    param1: t("purchase_amount"),
                  });
                  errorData.push(message + "\n");
                });
              }
            }
          }
        }

        // Dealer Discount
        if (item.promo_type.indexOf("20") > -1) {
          if (item.dealer_discount.length > 0) {
            const dealerDiscount = item.dealer_discount[0].discount_rate;
            // discount rate
            if (!isEmptyCheck(dealerDiscount)) {
              let discount_rate = priceInputBySignForDecimal(dealerDiscount);
              if (!isNaN(Number(discount_rate))) {
                if (
                  Number(discount_rate) > 100 ||
                  Number(discount_rate) < 0
                ) {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + item.line_no,
                    param1: t("discount_rate"),
                  });
                  errorData.push(message + "\n");
                }
              } else {
                message = com("MS0011", {
                  rowNum: t("row") + " " + item.line_no,
                  param1: t("discount_rate"),
                });
                errorData.push(message + "\n");
              }
            }
          } else {
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("discount_rate"),
              param2: t("dealer_discount"),
            });
            errorData.push(message + "\n");
          }
        }

        // Free Goods
        if (item.promo_type.indexOf("30") > -1) {
          const freeGoods = item.free_goods;
          if (freeGoods.length > 0) {
            freeGoods.forEach((option: any) => {
              // item class
              if (!isEmptyCheck(option.item_class)) {
                if (!checkMaxLength(option.item_class, 5)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_class"),
                    param2: 5,
                    param3: t("free_goods"),
                  });
                  errorData.push(message + "\n");
                }
              }

              // discount code
              if (!isEmptyCheck(option.discount_code)) {
                if (!checkMaxLength(option.discount_code, 3)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("discount_code"),
                    param2: 3,
                    param3: t("free_goods"),
                  });
                  errorData.push(message + "\n");
                } 
                // else if (
                //   !numberOrAlphabetValidation.test(option.discount_code)
                // ) {
                //   message = com("MS0011", {
                //     rowNum: t("row") + " " + option.line_no,
                //     param1: t("discount_code"),
                //     param2: t("free_goods"),
                //   });
                //   errorData.push(message + "\n");
                // }
              }

              // item no
              if (!isEmptyCheck(option.item_no)) {
                if (!checkMaxLength(option.item_no, 35)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_no"),
                    param2: 35,
                    param3: t("free_goods"),
                  });
                  errorData.push(message + "\n");
                }
              }

              // quantity
              if (!isEmptyCheck(option.quantity)) {
                if (!numberValidation.test(option.quantity)) {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("quantity"),
                    param2: t("free_goods"),
                  });
                  errorData.push(message + "\n");

                } else if (!checkMaxLength(option.quantity, 9)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("quantity"),
                      param2: 9,
                      param3: t("free_goods"),
                    });
                    errorData.push(message + "\n");

                } else if (Number(option.quantity)<1) {
                  message = com("MS0110", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("quantity"),
                    param2: t("free_goods"),
                  });
                  errorData.push(message + "\n");
                }
              }

              // to do isac
              if (
                isEmptyCheck(option.item_class) &&
                isEmptyCheck(option.discount_code) &&
                isEmptyCheck(option.item_no) &&
                salesAnalysisDefinition.item_list.every((item:any)=>{
                  return isEmptyCheck(option[item]);
                }) &&
                !isEmptyCheck(option.quantity)
              ) {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("item_information"),
                  param2: t("free_goods"),
                });
                errorData.push(message + "\n");
              } else if (
                // to do isac
                (!isEmptyCheck(option.item_class) ||
                  !isEmptyCheck(option.discount_code) ||
                  !isEmptyCheck(option.item_no) ||
                  salesAnalysisDefinition.item_list.some((item:any)=>{
                    return !isEmptyCheck(option[item]);
                  })) &&
                isEmptyCheck(option.quantity)
              ) {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("quantity"),
                  param2: t("free_goods"),
                });
                errorData.push(message + "\n");
              }
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                if (!isEmptyCheck(option[item])) {
                  if (!checkMaxLength(option[item], 5)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t(item),
                      param2: 5,
                      param3: t("free_goods"),
                    });
                    errorData.push(message + "\n");
                  }
                }
              });
            });
          } else {
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("item_information"),
              param2: t("free_goods"),
            });
            errorData.push(message + "\n");
          }

          // has same item recoed
          if (freeGoods.length > 1) {
            const freeGoodsRecordArray: any[] = [];
            freeGoods.forEach((option: any) => {
              const obj: any = {
                item_class: option.item_class,
                discount_code: option.discount_code,
                item_no: option.item_no,
              };
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                obj[item] = option[item];
              });

              const values = Object.values(obj);
              const hasValueFlag = values.some((item: any) => {
                return item !== "";
              });

              if (hasValueFlag) {
                freeGoodsRecordArray.push(option);
              }
            });

            const notNullRecord: any[] = [];
            freeGoodsRecordArray.forEach((option: any) => {
              const freeGoodsArray: any[] = [];
              notNullRecord.forEach((child: any) => {
                for (let key in child) {
                  freeGoodsArray.push(key);
                }
              });

              const obj: any = {
                item_class: option.item_class,
                discount_code: option.discount_code,
                item_no: option.item_no,
              };
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                obj[item] = option[item];
              })

              if (freeGoodsArray.length === 0) {
                notNullRecord.push({
                  [Object.values(obj).toString()]: [option.line_no],
                });
              } else {
                const index = freeGoodsArray.indexOf(
                  Object.values(obj).toString()
                );
                if (index === -1) {
                  notNullRecord.push({
                    [Object.values(obj).toString()]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullRecord[index]) {
                    keys = key;
                  }
                  notNullRecord[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullRecord.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("free_goods"),
                param2: t("item_information"),
              });
              errorData.push(message + "\n");
            });
          }

          // has same item no
          if (freeGoods.length > 1) {
            const freeGoodsArray: any[] = [];
            freeGoods.forEach((option: any) => {
              if (!isEmptyCheck(option.item_no)) {
                freeGoodsArray.push(option);
              }
            });

            const notNullObj: any[] = [];
            freeGoodsArray.forEach((option: any) => {
              const itemNoArray: any[] = [];
              notNullObj.forEach((child: any) => {
                for (let key in child) {
                  itemNoArray.push(key);
                }
              });

              if (itemNoArray.length === 0) {
                notNullObj.push({
                  [option.item_no]: [option.line_no],
                });
              } else {
                const index = itemNoArray.indexOf(option.item_no);
                if (index === -1) {
                  notNullObj.push({
                    [option.item_no]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullObj[index]) {
                    keys = key;
                  }
                  notNullObj[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullObj.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("free_goods"),
                param2: t("item"),
              });
              errorData.push(message + "\n");
            });
          }
        }

        // Special Price
        if (item.promo_type.indexOf("40") > -1) {

          const single = item.condition_single;
          const set = item.condition_set;

          if (single.length === 0 && set.length === 0) {
            message = com("MS0053", {
              rowNum: t("row") + " " + (item.line_no*1+1),
              param1:  t("special_price"),
              param2: t("set"),
            });
            errorData.push(message + "\n");
          }
          
          if (single.length > 0 && set.length > 0) {
            single.forEach((option: any) => {
              message = com("MS0088", {
                rowNum: t("row") + " " + option.line_no,
                param1: t("single"),
                param2: t("set"),
              });
              errorData.push(message + "\n");
            });

            set.forEach((option: any) => {
              message = com("MS0088", {
                rowNum: t("row") + " " + option.line_no,
                param1: t("single"),
                param2: t("set"),
              });
              errorData.push(message + "\n");
            });
          }

          if (single.length > 1 && set.length === 0) {
            single.forEach((option: any) => {
              message = com("MS0089", {
                rowNum: t("row") + " " + option.line_no,
                param1: t("single"),
              });
              errorData.push(message + "\n");
            });
          }

          if (single.length === 0 && set.length < 2) {
            set.forEach((option: any) => {
              message = com("MS0090", {
                rowNum: t("row") + " " + option.line_no,
                param1: t("set"),
              });
              errorData.push(message + "\n");
            });
          }

          if (single.length === 1 && set.length === 0) {

            // quantity
            if (!isEmptyCheck(single[0].quantity)) {
              if (!numberValidation.test(single[0].quantity)) {
                message = com("MS0011", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("quantity"),
                  param2: t("single"),
                });
                errorData.push(message + "\n");

              } else if (!checkMaxLength(single[0].quantity, 9)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + single[0].line_no,
                    param1: t("quantity"),
                    param2: 9,
                    param3: t("single"),
                  });
                  errorData.push(message + "\n");
  
              } else if (Number(single[0].quantity) < 1) {
                message = com("MS0110", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("quantity"),
                  param2: t("single"),
                });
                errorData.push(message + "\n");

              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + single[0].line_no,
                param1: t("quantity"),
                param2: t("single"),
              });
              errorData.push(message + "\n");
            }

            // special price
            if (!isEmptyCheck(single[0].special_price)) {
              if (isNaN(single[0].special_price)) {
                message = com("MS0011", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("special_price"),
                  param2: t("single"),
                });
                errorData.push(message + "\n");
              } else {
                if (!checkMaxLength(single[0].special_price, 11)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + single[0].line_no,
                    param1: t("special_price"),
                    param2: 11,
                    param3: t("single"),
                  });
                  errorData.push(message + "\n");
                } else if (Number(single[0].special_price) === 0) {
                  message = com("MS0086", {
                    rowNum: t("row") + "ﾂ�" + item.line_no,
                    param1: t("special_price"),
                    param2: t("single"),
                  });
                  errorData.push(message + "\n");
                } else if (!priceValidation.test(single[0].special_price)) {
                  message = com("MS0082", {
                    rowNum: t("row") + " " + single[0].line_no,
                    param1: t("special_price"),
                    param2: t("single"),
                  });
                  errorData.push(message + "\n");
                }
              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + single[0].line_no,
                param1: t("special_price"),
                param2: t("single"),
              });
              errorData.push(message + "\n");
            }

            // more than / times
            if (!isEmptyCheck(single[0].single_type)) {
              if (
                single[0].single_type.indexOf("1") === -1 &&
                single[0].single_type.indexOf("2") === -1
              ) {
                message = com("MS0011", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("single_type"),
                });
                errorData.push(message + "\n");
              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + single[0].line_no,
                param1: t("single_type"),
              });
              errorData.push(message + "\n");
            }

            // item class
            if (!isEmptyCheck(single[0].item_class)) {
              if (!checkMaxLength(single[0].item_class, 5)) {
                message = com("MS0002", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("item_class"),
                  param2: 5,
                  param3: t("single"),
                });
                errorData.push(message + "\n");
              } 
              // else if (
              //   !numberOrAlphabetValidation.test(single[0].item_class)
              // ) {
              //   message = com("MS0011", {
              //     rowNum: t("row") + " " + single[0].line_no,
              //     param1: t("item_class"),
              //     param2: t("single"),
              //   });
              //   errorData.push(message + "\n");
              // }
            }

            // discount code
            if (!isEmptyCheck(single[0].discount_code)) {
              if (!checkMaxLength(single[0].discount_code, 3)) {
                message = com("MS0002", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("discount_code"),
                  param2: 3,
                  param3: t("single"),
                });
                errorData.push(message + "\n");
              } 
              // else if (
              //   !numberOrAlphabetValidation.test(single[0].discount_code)
              // ) {
              //   message = com("MS0011", {
              //     rowNum: t("row") + " " + single[0].line_no,
              //     param1: t("discount_code"),
              //     param2: t("single"),
              //   });
              //   errorData.push(message + "\n");
              // }
            }

            // item no
            if (!isEmptyCheck(single[0].item_no)) {
              if (!checkMaxLength(single[0].item_no, 35)) {
                message = com("MS0002", {
                  rowNum: t("row") + " " + single[0].line_no,
                  param1: t("item_no"),
                  param2: 35,
                  param3: t("single"),
                });
                errorData.push(message + "\n");
              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + single[0].line_no,
                param1: t("item_no"),
                param2: t("single"),
              });
              errorData.push(message + "\n");
            }
            salesAnalysisDefinition.item_list.some((item:any)=>{

              if (!isEmptyCheck(single[0][item])) {
                if (!checkMaxLength(single[0][item], 5)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + single[0].line_no,
                    param1: t(item),
                    param2: 5,
                    param3: t("single"),
                  });
                  errorData.push(message + "\n");
                }
              }
            });
          }

          if (single.length === 0 && set.length >= 2) {
            set.forEach((option: any) => {
              // quantity
              if (!isEmptyCheck(option.quantity)) {
                if (!numberValidation.test(option.quantity)) {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("quantity"),
                    param2: t("set"),
                  });
                  errorData.push(message + "\n");

                } else if (!checkMaxLength(option.quantity, 9)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t("quantity"),
                      param2: 9,
                      param3: t("set"),
                    });
                    errorData.push(message + "\n");
  
                } else if (Number(option.quantity) < 1) {
                  message = com("MS0110", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("quantity"),
                    param2: t("set"),
                  });
                  errorData.push(message + "\n");

                }
              } else {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("quantity"),
                  param2: t("set"),
                });
                errorData.push(message + "\n");
              }

              // item class
              if (!isEmptyCheck(option.item_class)) {
                if (!checkMaxLength(option.item_class, 5)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_class"),
                    param2: 5,
                    param3: t("set"),
                  });
                  errorData.push(message + "\n");
                }
              }

              // discount code
              if (!isEmptyCheck(option.discount_code)) {
                if (!checkMaxLength(option.discount_code, 3)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("discount_code"),
                    param2: 3,
                    param3: t("set"),
                  });
                  errorData.push(message + "\n");
                } 
                // else if (
                //   !numberOrAlphabetValidation.test(option.discount_code)
                // ) {
                //   message = com("MS0011", {
                //     rowNum: t("row") + " " + option.line_no,
                //     param1: t("discount_code"),
                //     param2: t("set"),
                //   });
                //   errorData.push(message + "\n");
                // }
              }

              // item no
              if (!isEmptyCheck(option.item_no)) {
                if (!checkMaxLength(option.item_no, 35)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + option.line_no,
                    param1: t("item_no"),
                    param2: 35,
                    param3: t("set"),
                  });
                  errorData.push(message + "\n");
                }
              } else {
                message = com("MS0001", {
                  rowNum: t("row") + " " + option.line_no,
                  param1: t("item_no"),
                  param2: t("set"),
                });
                errorData.push(message + "\n");
              }
              salesAnalysisDefinition.item_list.forEach((item:any)=>{
                
                if (!isEmptyCheck(option[item])) {
                  if (!checkMaxLength(option[item], 5)) {
                    message = com("MS0002", {
                      rowNum: t("row") + " " + option.line_no,
                      param1: t(item),
                      param2: 5,
                      param3: t("set"),
                    });
                    errorData.push(message + "\n");
                  }
                }
              });
            });

            // has same item no
            const setArray: any[] = [];
            set.forEach((option: any) => {
              if (!isEmptyCheck(option.item_no)) {
                setArray.push(option);
              }
            });

            const notNullObj: any[] = [];
            setArray.forEach((option: any) => {
              const itemNoArray: any[] = [];
              notNullObj.forEach((child: any) => {
                for (let key in child) {
                  itemNoArray.push(key);
                }
              });

              if (itemNoArray.length === 0) {
                notNullObj.push({
                  [option.item_no]: [option.line_no],
                });
              } else {
                const index = itemNoArray.indexOf(option.item_no);
                if (index === -1) {
                  notNullObj.push({
                    [option.item_no]: [option.line_no],
                  });
                } else {
                  let keys = "";
                  for (let key in notNullObj[index]) {
                    keys = key;
                  }
                  notNullObj[index][keys].push(option.line_no);
                }
              }
            });

            let values: any[] = [];
            notNullObj.forEach((option: any) => {
              for (let key in option) {
                if (option[key].length > 1) {
                  values.push(option[key]);
                }
              }
            });
            values = values.flat();

            values.forEach((option: any) => {
              message = com("MS0087", {
                rowNum: t("row") + " " + option,
                param1: t("set"),
                param2: t("item"),
              });
              errorData.push(message + "\n");
            });

            // special price
			if(set&&set.length>0){
				set.forEach((_set_item:any)=>{
					if (!isEmptyCheck(_set_item.special_price)) {
              if (isNaN(_set_item.special_price)) {
                message = com("MS0011", {
                  rowNum: t("row") + " " + _set_item.line_no,
                  param1: t("special_price"),
                  param2: t("set"),
                });
                errorData.push(message + "\n");
              } else {
                if (!checkMaxLength(_set_item.special_price, 11)) {
                  message = com("MS0002", {
                    rowNum: t("row") + " " + _set_item.line_no,
                    param1: t("special_price"),
                    param2: 11,
                    param3: t("set"),
                  });
                  errorData.push(message + "\n");
                } else if (Number(_set_item.special_price) === 0) {
                  message = com("MS0086", {
                    rowNum: t("row") + "ﾂ�" + item.line_no,
                    param1: t("special_price"),
                    param2: t("set"),
                  });
                  errorData.push(message + "\n");
                } else if (!priceValidation.test(_set_item.special_price)) {
                  message = com("MS0082", {
                    rowNum: t("row") + " " + _set_item.line_no,
                    param1: t("special_price"),
                    param2: t("set"),
                  });
                  errorData.push(message + "\n");
                }
              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + _set_item.line_no,
                param1: t("special_price"),
                param2: t("set"),
              });
              errorData.push(message + "\n");
            }
				})
			}
            
          }
        }

        // Promotion Code
        if (item.promo_type.indexOf("50") > -1) {
          // allow overlap
          if (!isEmptyCheck(item.allow_overlap)) {
            if (item.allow_overlap.slice(0, 2) !== "1:") {
              message = com("MS0011", {
                rowNum: t("row") + " " + item.line_no,
                param1: t("allow_overlap"),
              });
              errorData.push(message + "\n");
            }
          } else {
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("allow_overlap"),
            });
            errorData.push(message + "\n");
          }

          if (item.promotion_code.length > 0) {
            const promotionCode = item.promotion_code[0].promotion_code;
            const discountRate = item.promotion_code[0].discount_rate;
            const promoCodeType = item.promotion_code[0].promo_code_type;

            // promotion code
            // if (!isEmptyCheck(promotionCode)) {
            //   if (!checkMaxLength(promotionCode, 10)) {
            //     message = com("MS0002", {
            //       rowNum: t("row") + " " + item.line_no,
            //       param1: t("promotion_code"),
            //       param2: 10,
            //     });
            //     errorData.push(message + "\n");
            //   } else if (!numberOrAlphabetValidation.test(promotionCode)) {
            //     message = com("MS0011", {
            //       rowNum: t("row") + " " + item.line_no,
            //       param1: t("promotion_code"),
            //     });
            //     errorData.push(message + "\n");
            //   }
            // }

            // discount rate
            if (!isEmptyCheck(discountRate)) {
              let discount_rate = priceInputBySignForDecimal(discountRate);
              if (!isNaN(Number(discount_rate))) {
                if (
                  Number(discount_rate) > 100 ||
                  Number(discount_rate) < 0
                ) {
                  message = com("MS0011", {
                    rowNum: t("row") + " " + item.line_no,
                    param1: t("discount_rate"),
                  });
                  errorData.push(message + "\n");
                }
              } else {
                message = com("MS0011", {
                  rowNum: t("row") + " " + item.line_no,
                  param1: t("discount_rate"),
                });
                errorData.push(message + "\n");
              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + item.line_no,
                param1: t("discount_rate"),
              });
              errorData.push(message + "\n");
            }

            // promo code type
            if (!isEmptyCheck(promoCodeType)) {
              if (
                promoCodeType.indexOf("1:") === -1 &&
                promoCodeType.indexOf("2:") === -1
              ) {
                message = com("MS0011", {
                  rowNum: t("row") + " " + item.line_no,
                  param1: t("promo_code_type"),
                });
                errorData.push(message + "\n");
              }
            } else {
              message = com("MS0001", {
                rowNum: t("row") + " " + item.line_no,
                param1: t("promo_code_type"),
              });
              errorData.push(message + "\n");
            }
          } else {
            // discount rate
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("discount_rate"),
            });
            errorData.push(message + "\n");

            // promo code type
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("promo_code_type"),
            });
            errorData.push(message + "\n");
          }
        }

        // Dealer Payment Term
        if (item.promo_type.indexOf("60") > -1) {
          if (!isEmptyCheck(item.terms_cd)) {
            if (!checkMaxLength(item.terms_cd, 2)) {
              message = com("MS0002", {
                rowNum: t("row") + " " + item.line_no,
                param1: t("terms_cd"),
                param2: 2,
              });
              errorData.push(message + "\n");
            }
          } else {
            message = com("MS0001", {
              rowNum: t("row") + " " + item.line_no,
              param1: t("terms_cd"),
            });
            errorData.push(message + "\n");
          }
        }
      });

      errorData = Array.from(new Set(errorData));

      if (errorData.length === 0) {
        // clear null value
        formatDataObj.forEach((item: any) => {
          for (let key in item) {
            if (Array.isArray(item[key])) {
              if (item[key].length === 0) {
                delete item[key];
              } else {
                item[key].forEach((option: any) => {
                  for (let key in option) {
                    if (option[key] === "") {
                      delete option[key];
                    }
                  }
                });
              }
            } else {
              if (item[key] === "") {
                delete item[key];
              }
            }
          }
        });

        // body request
        const _request: any[] = [];
        formatDataObj.forEach((item: any) => {
          const obj: any = {};
          const promotionDetail: any = [];
          for (let key in item) {
            if (Array.isArray(item[key])) {
              if (key === "promotion_customer") {
                item[key].forEach((option: any) => {
                  for (let key in option) {
                    if (key === "customer_no") {
                      option[key] = Number(option[key]);
                    }
                  }
                });
                obj[key] = item[key];
              } else {
                if (key === "condition_single") {
                  item[key][0].single_type = item[key][0].single_type.slice(
                    0,
                    1
                  );
                  promotionDetail.push(item[key][0]);
                } else if (key === "promotion_code") {
                  item[key][0].promo_code_type = item[
                    key
                  ][0].promo_code_type.slice(0, 1);
                  promotionDetail.push(item[key][0]);
                } else {
                  item[key].forEach((option: any) => {
                    promotionDetail.push(option);
                  });
                }
              }
            } else {
              if (key === "promo_type") {
                obj[key] = item[key].slice(0, 2);
              } else if (key === "allow_overlap") {
                obj[key] = item[key].slice(0, 1);
              } else if (key === "exclusive_flag") {
                obj[key] = item[key].slice(0, 1);
              } else if (key === "webshop_flag") {
                obj[key] = item[key].slice(0, 1);
              } else if (key === "others_flag") {
                obj[key] = item[key].slice(0, 1);
              } else {
                obj[key] = item[key];
              }
            }
          }

          if (!("end_date" in obj)) {
            obj["end_date"] = "9999-12-31";
          }

          if (promotionDetail.length > 0) {
            obj["promotion_detail"] = promotionDetail;
          }
          obj.draft_flag = "0";
          
          if (item.condition_unit_base && item.condition_unit_base.length > 0) {
            obj.promo_sub_type = 4;
          } else if (item.condition_amount_base && item.condition_amount_base.length > 0) {
            obj.promo_sub_type = 5;
          } else if (item.condition_single && item.condition_single.length > 0) {
            obj.promo_sub_type = 6;
          } else if (item.condition_set && item.condition_set.length > 0) {
            obj.promo_sub_type = 7;

          }

          _request.push(obj);
        });

        const errorList: any[] = [];
       
        apiLoading(true);
        if (_request && _request.length > 0) {
          setRequesteData(_request);
          setCsvRecordCount(_request.length);
          setCsvValueIndex(0);
          upload_csv_success=0;
          setCsvSuccessCount(0);
          setShowSuccessAndErrorMessage(com("MS0012", {
            registerQty: 0,
          }))
          setCsvErrorList(errorList);
          setSuccessAndErrorMessageList(errorList);

          handleCSVUploadApi(_request,0,2,0,errorList);
        }
      }
    }
    return errorData;
  };

  const handleCSVUploadApi = async (
    _request: any, 
    item_index: any,
    forceUpdateFlag: any,
    // webshopReleaseForceUpdateFlag: any,
    paraSuccessCount: any=0,
    paraErrorList: any=[]
    ) => {
    let item: any = _request[item_index];
	
	// customer noがあれば、ほかの値がクリア必要
    if (item.promotion_customer && item.promotion_customer.length > 0) {
      for (let i = 0; i < item.promotion_customer.length; i++) {
        let _item = item.promotion_customer[i];

        if (
          _item.customer_no !== null &&
          _item.customer_no !== undefined &&
          _item.customer_no !== ""
        ) {
          let customer_item = {
            customer_no: _item.customer_no,
            line_no: _item.line_no,
            seq_no: _item.seq_no,
          };

          item.promotion_customer[i] = customer_item;
        }
      }
    }
    // item noがあれば、ほかの値がクリア必要
     if (item.promotion_detail && item.promotion_detail.length > 0) {
       for (let i = 0; i < item.promotion_detail.length; i++) {
         let _item = item.promotion_detail[i];

         if (
           _item.item_no !== null &&
           _item.item_no !== undefined &&
           _item.item_no !== ""
        ) {
           const _list:any = process.env.REACT_APP_PROMOTION_ITEM_NO_CLEAR_PROJECT&&process.env.REACT_APP_PROMOTION_ITEM_NO_CLEAR_PROJECT.split(',');

		   for(let key in _item){
			   if(_list.includes(key)) {
				   delete _item[key];
			   }
		   }
         }
      }
    }
    if (forceUpdateFlag===0 || forceUpdateFlag===2) {
      item.duplicate_check_flag="true";
      item.webshop_release_check_flag="true";
	  // item is suspend.
	  item.suspend_check_flag="true";
	  // customer is closed.
      item.customer_closed_check_flag="true";
    } else if (forceUpdateFlag===1 || forceUpdateFlag===3) {
      item.duplicate_check_flag="false";
      item.webshop_release_check_flag="false";
	  // item is suspend.
	  item.suspend_check_flag="false";
	  // customer is closed.
      item.customer_closed_check_flag="false";
    }

    await api
      .uploadPromotionCSVAPI(config, item)
      .then(({ response, code }: { response: any; code: any }) => {

        let errorList: any[] = [];
        if (forceUpdateFlag === 2) {
          errorList = paraErrorList;
        } else {
          errorList = csvErrorList;
        }

        if (!getApiResultStatus(code)) {
          if (Array.isArray(response)) {
            props.setAlertShow(false);
            response.forEach((item: any) => {
             
              let dataType = "";
              if (item.data_type === "1") {
                dataType = "considered";
              } else if (item.data_type === "2") {
                dataType = "excluded";
              } else if (item.data_type === "3") {
                dataType = "free_goods";
              } else if (item.data_type === "4") {
                dataType = "unit_base";
              } else if (item.data_type === "5") {
                dataType = "amount_base";
              } else if (item.data_type === "6") {
                dataType = "single";
              } else if (item.data_type === "7") {
                dataType = "set";
              } else if (item.data_type === "8") {
                dataType = "promotion_code";
              } else if (item.data_type === "9") {
                dataType = "dealer_discount";
              }

              let error = "";
              error += t("promotion_no") + item.promo_no + " ";
              if (item.message_id && item.message_id === "MA0011") {
                error += com(item.message_id, {
                  rowNum: t("row") + " " + item.line_no,
                });
              } else if (dataType === "") {
                error += com(item.message_id, {
                  rowNum: t("row") + " " + item.line_no,
                  param1: "API." + t(item.column_name),
                });
              } else {
                error += com(item.message_id, {
                  rowNum: t("row") + " " + item.line_no,
                  param1: "API." + t(item.column_name),
                  param2: t(dataType),
                });
              }
              errorList.push(error + "\n");
              setCsvErrorList(errorList);
              setSuccessAndErrorMessageList(errorList);
            });
          } else {
            props.errorMessage(response);
          }

          if (_request.length > item_index+1) {
            let successCount = 0;
            if (forceUpdateFlag === 2) {
              successCount = paraSuccessCount;

            } else if (forceUpdateFlag === 3) {
              successCount = upload_csv_success;
            } else {
              successCount = csvSuccessCount;
            }

            let index = item_index+1;
            setCsvValueIndex(index);
            const state_check = forceUpdateFlag===3?forceUpdateFlag:2;
            handleCSVUploadApi(_request,index,state_check,successCount,paraErrorList);
          } else {          
            setUpdateCompletedFlag(true);
          }
        } else {
          if (response && response.length>0) {

            /*if (response[0].message_id === "MA0009" && response[0].item_message_id === "MS0099") {
              setCsvPromoNo(response[0].promo_no);
              setCsvDuplicatePromoNo(response[0].duplicate_promo_no);
              setWebshopNotReleaseItemList([...response[0].item_no]);

              setBothForceRegisterFlag(true); 
			  
            } else {*/
			  setCsvPromoNo(response[0].promo_no);
              if (response[0].message_id === "MA0009") {
                setCsvDuplicatePromoNo(response[0].duplicate_promo_no);
                //setForceRegisterFlag(true);
				setWarningMessageType((prev:any)=>[...prev,'1']);
                setWarningMessageFlag(true);				
              }
              if (response[0].item_message_id === "MS0099") {
                setWebshopNotReleaseItemList([...response[0].item_no]);
                // setWebshopNotReleaseRorceRegisterFlag(true);
				setWarningMessageType((prev:any)=>[...prev,'2']);
				setWarningMessageFlag(true);
              }
			  // suspend_flg 
			  if(response[0].suspend_message_id === "MS0052"){
				setCsvSuspendItemNo(response[0].suspend_item_no);
				setWarningMessageType((prev:any)=>[...prev,'3']);
				setWarningMessageFlag(true);
			  }
			  // customer_closed 
			  if(response[0].closed_customer_message_id === "MS0085"){
                setCsvClosedCustomerNo(response[0].closed_customer_no);
				setWarningMessageType((prev:any)=>[...prev,'4']);
				setWarningMessageFlag(true);
			  }
            //}
			
          } else {

            let successCount = 0;
            if (forceUpdateFlag === 2) {
              successCount = paraSuccessCount;
            } else if(forceUpdateFlag === 3){
              successCount = upload_csv_success;
            } else {
              successCount = csvSuccessCount;
            }
            successCount++;
            upload_csv_success=successCount;
            setCsvSuccessCount(successCount);
            setShowSuccessAndErrorMessage(com("MS0012", {
              registerQty: successCount,
            }))
            if (_request.length > item_index+1) {
              let index = item_index+1;
              setCsvValueIndex(index);
              const state_check = forceUpdateFlag===3?forceUpdateFlag:2;
              handleCSVUploadApi(_request,index,state_check,successCount,paraErrorList);
            } else {
              setUpdateCompletedFlag(true);
            }
           }
         }
      });
  }

  /*const handleBothForceConfirmNo = () => {
    setBothForceRegisterFlag(false);
    let _request : any = requesteData;

    if (_request.length > csvValueIndex+1) {

      let index = csvValueIndex+1;
      setCsvValueIndex(index);
      handleCSVUploadApi(_request,index,0);
    } else {;
      setShowSuccessAndErrorMessage(com("MS0012", {
        registerQty: csvSuccessCount,
      }))

      setUpdateCompletedFlag(true);
    }

  }; */


  /*const handleWebshopReleaseConfirmNo = () => {
    setWebshopNotReleaseRorceRegisterFlag(false);
    let _request : any = requesteData;

    if (_request.length > csvValueIndex+1) {

      let index = csvValueIndex+1;
      setCsvValueIndex(index);
      handleCSVUploadApi(_request,index,0);
    } else {;
      // apiLoading(false);
      setShowSuccessAndErrorMessage(com("MS0012", {
        registerQty: csvSuccessCount,
      }))

      setUpdateCompletedFlag(true);
    }

  }; */

  /*const handleForceRegisterNo = () => {

    setForceRegisterFlag(false);

    let _request : any = requesteData;
    
    if (_request.length > csvValueIndex+1) {

      let index = csvValueIndex+1;
      setCsvValueIndex(index);
      handleCSVUploadApi(_request,index,0);
    } else {;
      setShowSuccessAndErrorMessage(com("MS0012", {
        registerQty: csvSuccessCount,
      }))

      setUpdateCompletedFlag(true);
    }

  };*/

  /*const handleBothForceConfirmYes = () => {
    setBothForceRegisterFlag(false);
    let _request : any = requesteData;
   
    let index = csvValueIndex;

    handleCSVUploadApi(_request,index,1);
  }; */  

  /*const handleWebshopReleaseConfirmYes = () => {
    setWebshopNotReleaseRorceRegisterFlag(false);
    let _request : any = requesteData;
   
    let index = csvValueIndex;

    handleCSVUploadApi(_request,index,1);
  }; */  
  

  /*const handleForceRegister = () => {
    setForceRegisterFlag(false);

    let _request : any = requesteData;
   
    let index = csvValueIndex;

    handleCSVUploadApi(_request,index,1);

  };*/

  const handleUpdateCompletedClose = () => {
    setUpdateCompletedFlag(false);
    setCsvValueIndex(0);
    setCsvSuccessCount(0);
    upload_csv_success = 0;
    setTimeout(() => {
      props.searchHandle("");  
    }, 100);

  };

  /*const handleCancelAfter = () => {

    setForceRegisterFlag(false);
    
    setUpdateCompletedFlag(true);

  };*/

  /*const handleWebshopReleaseConfirmCancel = () => {

    setWebshopNotReleaseRorceRegisterFlag(false);
    
    setUpdateCompletedFlag(true);

  };*/

  /*const handleBothForceConfirmCancel = () => {

    setBothForceRegisterFlag(false);
    
    setUpdateCompletedFlag(true);

  };*/
  
  /*const handleAllOK = () => {
	  // bothForceRegisterFlag
	  if(bothForceRegisterFlag){
		setBothForceRegisterFlag(false);
	  }
	  // webshopNotReleaseRorceRegisterFlag
	  if(webshopNotReleaseRorceRegisterFlag){
		  setWebshopNotReleaseRorceRegisterFlag(false);
	  }
	  // forceRegisterFlag
	  if(forceRegisterFlag){
		setForceRegisterFlag(false);
	  }
	  let _request : any = requesteData;
   
    let index = csvValueIndex;

    handleCSVUploadApi(_request,index,3);
  }*/

 /* const attentionForceRegisterPromotionListButton = (
    <div className={classes.buttonControl}>

      <Button variant="contained" onClick={handleCancelAfter}>
        {t("cancel")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleForceRegisterNo}
      >
        {t("no")}
      </Button>
      <Button variant="contained" onClick={handleForceRegister}>
        {t("ok")}
      </Button>
	  <Button variant="contained" onClick={handleAllOK}>
        {t("btn_all_ok")}
      </Button>
    </div>
  );*/

  /*const webshopReleaseConfirmButton = (
    <div className={classes.buttonControl}>

      <Button variant="contained" onClick={handleWebshopReleaseConfirmCancel}>
        {t("cancel")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleWebshopReleaseConfirmNo}
      >
        {t("no")}
      </Button>
      <Button variant="contained" onClick={handleWebshopReleaseConfirmYes}>
        {t("ok")}
      </Button>
	  <Button variant="contained" onClick={handleAllOK}>
        {t("btn_all_ok")}
      </Button>
    </div>
  );*/

  /*const bothForceConfirmButton = (
    <div className={classes.buttonControl}>

      <Button variant="contained" onClick={handleBothForceConfirmCancel}>
        {t("cancel")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleBothForceConfirmNo}
      >
        {t("no")}
      </Button>
      <Button variant="contained" onClick={handleBothForceConfirmYes}>
        {t("ok")}
      </Button>
	   <Button variant="contained" onClick={handleAllOK}>
        {t("btn_all_ok")}
      </Button>
    </div>
  );*/

  const PromotionModelWindowButton = (
    <div className={classes.buttonControl02}>
      <Button 
      className={classes.buttonWidth}
      variant="contained" 
      onClick={handleUpdateCompletedClose} 
      color="primary">
        {t("prompt_button_OK")}
      </Button>
    </div>
  );
  const [warningMessageFlag,setWarningMessageFlag]=useState(false);
  const [warningMessageType,setWarningMessageType]=useState([])as any;
  const [csvSuspendItemNo,setCsvSuspendItemNo]=useState([])as any;
  const [csvClosedCustomerNo,setCsvClosedCustomerNo]=useState([])as any;
  const warningMessageCannel=()=>{
	  closeWarningMessageFlag();
	  setUpdateCompletedFlag(true);
  }
  const warningMessageOK=()=>{
	  closeWarningMessageFlag();
	  let _request:any = requesteData;
	  let index = csvValueIndex;
      handleCSVUploadApi(_request,index,1);
  }
  const warningMessageAllOK=()=>{
	  closeWarningMessageFlag();
	  let _request:any = requesteData; 
      let index = csvValueIndex;
      handleCSVUploadApi(_request,index,3);
  }
  const closeWarningMessageFlag=()=>{
      setWarningMessageFlag(false);
	  setWarningMessageType([]);
  }
  const warningMessageClose=()=>{
	  closeWarningMessageFlag();
	  let _request:any = requesteData;
	  if (_request.length > csvValueIndex+1) {
		  let index = csvValueIndex+1;
		  setCsvValueIndex(index);
		  handleCSVUploadApi(_request,index,0);
		} else {
		  setShowSuccessAndErrorMessage(com("MS0012", {
			registerQty: csvSuccessCount,
		 }))
	   setUpdateCompletedFlag(true);
	  }
  }
  return (
    <>
    <div className={classes.root}>
      <div className={classes.area}>
        {!props.selectedPromotions && (
          <>
            <div className={classes.item}>
              <span>{props.numSelected}</span>
              {t("txt_PromotionsSelected")}
            </div>
            <div className={classes.item}  data-testid="btn_Delete" >
              {userPermisssionInfo.csd_promotion_list_delete_authFlg && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={props.deletePromotionAlert}
                >
                  {t("btn_Delete")}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      <div className={classes.area}>
        <div className={classes.item}>
          {/* {userPermisssionInfo.csd_promotion_list_download_authFlg && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              disabled={props.selectedPromotions}
              onClick={props.downloadCSV}
            >
              {t("download_to_csv")}
            </Button>
          )} */}
		  {userPermisssionInfo.csd_promotion_list_download_authFlg && (
			  <Button
				variant="contained"
				color="primary"
				startIcon={<CloudDownloadIcon />}
				onClick={props.downloadCSVBySearch}
			  >
				{t("download_to_csv")}
			  </Button>
			)}
        </div>
        <div className={classes.item}>
          {userPermisssionInfo.csd_promotion_list_import_authFlg && (
            <>
              <input
                accept=".csv"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e: any) => handlerUpload(e.target.files)}
                onClick={(e: any) => {
                  e.target.value = null;
                }}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                >
                  {t("import_from_csv")}
                </Button>
              </label>
            </>
          )}
        </div>
        <div className={classes.item} data-testid="btn_create_new">
          {userPermisssionInfo.csd_promotion_list_create_new_authFlg && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddBoxIcon />}
              onClick={handleCreatePromotion}
            >
              {t("create_new")}
            </Button>
          )}
        </div>
      </div>
    </div>


    {/* {forceRegisterFlag && (
       <ModelWindow
          title="prompt_title_attention"
          contents={
            <p>{com('MS0094')}<br/>{getName("messages.MS0095",{param1:csvPromoNo,param2:csvDuplicatePromoNo})}</p>
          }
          openFlg={forceRegisterFlag}
          button={attentionForceRegisterPromotionListButton}
          handleCloseEvent={handleForceRegisterNo}
        />
	)}*/}

      {/*{webshopNotReleaseRorceRegisterFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={
            <div className={classes.divWidth}>
              <p  className={classes.errormessage}>{getName('messages.MS0099')}</p>
              <p  className={classes.errormessage}>{t("promotion_code")}:{csvPromoNo}</p>
              <p className={classes.errormessage}>
                {t("item")}:
                {webshopNotReleaseItemList.map((item:any, itemIndex: any)=>{
                  if (itemIndex === 0) {
                    return item;
                  } else {
                    return <>, {item}</>;
                  }
                }
                )}
              </p>
            </div>
          }
          openFlg={webshopNotReleaseRorceRegisterFlag}
          button={webshopReleaseConfirmButton}
          handleCloseEvent={handleWebshopReleaseConfirmNo}
        />
	)}*/}

      {/*{bothForceRegisterFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={
            <div className={classes.divWidth}>
              <p>{com('MS0094')}<br/>{getName("messages.MS0095",{param1:csvPromoNo,param2:csvDuplicatePromoNo})}</p>
              <p  className={classes.errormessage}>{getName('messages.MS0099')}</p>
              <p className={classes.errormessage}>
                {t("item")}:
                {webshopNotReleaseItemList.map((item:any, itemIndex: any)=>{
                  if (itemIndex === 0) {
                    return item;
                  } else {
                    return <>, {item}</>;
                  }
                }
                )}
              </p>
            </div>
          }
          openFlg={bothForceRegisterFlag}
          button={bothForceConfirmButton}
          handleCloseEvent={handleBothForceConfirmNo}
        />
	)}*/}
	{warningMessageFlag && (
			<PromotionWarningMessageBox
			  open={warningMessageFlag}
			  csvPromoNo={csvPromoNo}
			  csvDuplicatePromoNo={csvDuplicatePromoNo}
			  webshopNotReleaseItemList={webshopNotReleaseItemList}
			  csvSuspendItemNo={csvSuspendItemNo}
			  csvClosedCustomerNo={csvClosedCustomerNo}
			  handleCannel={warningMessageCannel}
			  handleNo={warningMessageClose}
			  handleOK={warningMessageOK}
			  handleAllOK={warningMessageAllOK}
			  handleClose={warningMessageClose}
			  warningMessageType={warningMessageType}
			/>
		  )}
      {updateCompletedFlag && (
              <ModelWindow
              title={showSuccessAndErrorMessage}
              notTitle = {true}
              contents={PromotionModelWindow}
              button={PromotionModelWindowButton}
              openFlg={updateCompletedFlag}
              handleCloseEvent={handleUpdateCompletedClose}
            />
          
      )}
    </>
  );
};
