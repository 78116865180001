import React, { useEffect, useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { MasterMaintenanceSearchControlCSearch } from "./MasterMaintenance.CSearch";

// UI
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import { PhoneNumberInput } from "../../../modules/components/phoneInput/PhoneNumberInput";
import MenuItem from "@material-ui/core/MenuItem";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { AlertMesssageBox } from "../../../modules/components/alert/AlertMesssageBox";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// api
import api from "../../../modules/api/Api";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";

// check
import { isEmptyStringOrNumber } from "../../../modules/hooks/UseValication";

// hook
import { useStyles } from "../hooks/editor.useStyles";
// constants
import { TabKey } from "../MasterMaintenance.constants";
import {
  isEmptyCheck,
  emailValidation,
  checkMaxLength,
} from "../../../modules/hooks/UseValication";

export const MasterMaintenanceDZUserRegistrationEditor = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.dZUserRegistration",
  });

  const [customerNo, setCustomerNo] = useState("");
  const [customerName, setCustomerName] = useState("");

  const { com } = useLocale({ defaultPath: "common.constants" });
  const { getCommonConfig, getCommonInfo, getUserInfoRepair } = useCommonInfo();

  let config = getCommonConfig() as any;

  let commonInfo = getCommonInfo() as any;

  // init country
  let userInfoRepair = getUserInfoRepair() as any;
  const {
    changeData,
    editData,
    editList,
    setEditList,
    handleBack,
    handleSubmit,
    affiliateCd,
    primaryLocale,
    customerMasterData,
    telUsedCountry,
    alertEditMessageList,
    setAlertEditMessageList
  } = props;
  const {
    given_name,
    family_name,
    middle_name,
    company_name,
    affiliate_cd,
    primary_locale,
    tel_no,
    mail_address,
    customer_no,
    customer_name,
    street,
    postal_cd,
    city,
    role_id,
    sms_receive_notification,
    mail_receive_notification,
    mailAddressFlg,
    registerFlg,
  } = editData;

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("given_name" === itemName) {
      givenNameValidationCheck(itemValue);
    } else if ("family_name" === itemName) {
      familyNameValidationCheck(itemValue);
    } else if ("middle_name" === itemName) {
      middleNameValidationCheck(itemValue);
    } else if ("company_name" === itemName) {
      companyNameValidationCheck(itemValue);
    } else if ("mail_address" === itemName) {
      mailAddressValidationCheck(itemValue);
    } else if ("customer_no" === itemName) {
      customerNoValidationCheck(itemValue);
    }
  };

  const isBlank = (name: any) => {
    if (name !== null && name !== undefined && name !== '') {
      return !checkMaxLength(name.trim(), 50);
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (
      isEmptyCheck(given_name) ||
      !checkMaxLength(given_name.trim(), 50) ||
      givenNameErrorFlg ||
      isEmptyCheck(family_name) ||
      !checkMaxLength(family_name.trim(), 50) ||
      familyNameErrorFlg ||
      isBlank(middle_name) ||
      middleNameErrorFlg ||
      !checkMaxLength(company_name, 50) ||
      companyNameErrorFlg ||
      (mailAddressFlg &&
        (isEmptyCheck(mail_address) ||
          !checkMaxLength(mail_address, 254) ||
          mailAddressErrorFlg)) ||
      isEmptyStringOrNumber(customer_no) ||
      !checkMaxLength(customer_no, 8) ||
      customerNoErrorFlg
    ) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
    if (
      !isEmptyStringOrNumber(customer_no) &&
      customerNo !== String(customer_no)
    ) {
      setCustomerNo(String(customer_no));
      setCustomerName(customer_name);
    }
  });

  useEffect(() => {
    if (customerNo === "") {
      setCustomerName("");
      return;
    }

    let searchCustomerNo = Number(customerNo);
    let selectCustomerInfo = commonInfo.customerList.filter((item: any) => {
      return item.customer_no === searchCustomerNo;
    });
    if (selectCustomerInfo && selectCustomerInfo.length > 0) {
      setCustomerName(selectCustomerInfo[0].customer_name);
    } else {
      setCustomerName("");
    }
  }, [customerNo]);

  const handleClickConfirm = () => {
    let flg = false;
    if (givenNameValidationCheck(editData.given_name)) {
      flg = true;
    }
    if (familyNameValidationCheck(editData.family_name)) {
      flg = true;
    }
    if (middleNameValidationCheck(editData.middle_name)) {
      flg = true;
    }
    if (companyNameValidationCheck(editData.company_name)) {
      flg = true;
    }
    if (mailAddressValidationCheck(editData.mail_address)) {
      flg = true;
    }
    if (customerNoValidationCheck(editData.customer_no)) {
      flg = true;
    }
    if (!flg) {
      handleSubmit(TabKey.DZUserRegistration, registerFlg, "confirm");
    }
  };

  const [disabledFlg, setDisabledFlg] = React.useState(true);
  const [givenNameErrorFlg, setGivenNameErrorFlg] = React.useState(false);
  const [givenNameErrorMessage, setGivenNameErrorMessage] = React.useState("");
  const [familyNameErrorFlg, setFamilyNameErrorFlg] = React.useState(false);
  const [familyNameErrorMessage, setFamilyNameErrorMessage] =
    React.useState("");
  const [middleNameErrorFlg, setMiddleNameErrorFlg] = React.useState(false);
  const [middleNameErrorMessage, setMiddleNameErrorMessage] =
    React.useState("");
  const [companyNameErrorFlg, setCompanyNameErrorFlg] = React.useState(false);
  const [companyNameErrorMessage, setCompanyNameErrorMessage] =
    React.useState("");
  const [mailAddressErrorFlg, setMailAddressErrorFlg] = React.useState(false);
  const [mailAddressErrorMessage, setMailAddressErrorMessage] =
    React.useState("");
  const [customerNoErrorFlg, setCustomerNoErrorFlg] = React.useState(false);
  const [customerNoErrorMessage, setCustomerNoErrorMessage] =
    React.useState("");

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = React.useState(false);

  const roleId = com("role_id");

  const handleCSearch = () => {
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    setAlertEditMessageList([]);

    if (row.customer_closed === true) {
      let message = com("messages.MS0085", {
        param1: row.customer_no,
      });

      const obj: any = {
        no: alertEditMessageList.length,
        message: message,
        alertType: "warning",
      };
      const newList: any = alertEditMessageList.slice(0);
      newList.push(obj);
      setAlertEditMessageList(newList);
    }

    const customerValue = {
      customer_no: row.customer_no.toString(),
      customer_name: row.customer_name,
    };
    let valueList = Object.assign({}, editList, customerValue);
    setEditList(valueList);

    setCustomerNoErrorMessage("");
    setCustomerNoErrorFlg(false);

    if (row.customer_no.toString() !== customerNo) {
      setCustomerNo(row.customer_no.toString());
      setCustomerName(row.customer_name);
    }
  };

  //Given Name check
  const givenNameValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value.trim())) {
      flg = isEmptyCheck(value.trim());
      let message = com("messages.MS0001", {
        param1: t("given_name"),
      });
      setGivenNameErrorFlg(flg);
      setGivenNameErrorMessage(message);
    } else if (!checkMaxLength(value.trim(), 50)) {
      flg = !checkMaxLength(value.trim(), 50);
      let message = com("messages.MS0002", {
        param1: t("given_name"),
        param2: 50,
      });
      setGivenNameErrorFlg(flg);
      setGivenNameErrorMessage(message);
    } else {
      setGivenNameErrorFlg(flg);
      setGivenNameErrorMessage("");
    }
    return flg;
  };

  //Family Name check
  const familyNameValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value.trim())) {
      flg = isEmptyCheck(value.trim());
      let message = com("messages.MS0001", {
        param1: t("family_name"),
      });
      setFamilyNameErrorFlg(flg);
      setFamilyNameErrorMessage(message);
    } else if (!checkMaxLength(value.trim(), 50)) {
      flg = !checkMaxLength(value.trim(), 50);
      let message = com("messages.MS0002", {
        param1: t("family_name"),
        param2: 50,
      });
      setFamilyNameErrorFlg(flg);
      setFamilyNameErrorMessage(message);
    } else {
      setFamilyNameErrorFlg(flg);
      setFamilyNameErrorMessage("");
    }
    return flg;
  };

  //Middle Name check
  const middleNameValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 50);
      let message = com("messages.MS0002", {
        param1: t("middle_name"),
        param2: 50,
      });
      setMiddleNameErrorFlg(flg);
      setMiddleNameErrorMessage(message);
    } else {
      setMiddleNameErrorFlg(flg);
      setMiddleNameErrorMessage("");
    }
    return flg;
  };

  //Company Name check
  const companyNameValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 20);
      let message = com("messages.MS0002", {
        param1: t("company_name"),
        param2: 50,
      });
      setCompanyNameErrorFlg(flg);
      setCompanyNameErrorMessage(message);
    } else {
      setCompanyNameErrorFlg(flg);
      setCompanyNameErrorMessage("");
    }
    return flg;
  };

  //Mail Address check
  const mailAddressValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("mail_address"),
      });
      setMailAddressErrorFlg(flg);
      setMailAddressErrorMessage(message);
    } else if (!checkMaxLength(value, 254)) {
      flg = !checkMaxLength(value, 254);
      let message = com("messages.MS0002", {
        param1: t("mail_address"),
        param2: 254,
      });
      setMailAddressErrorFlg(flg);
      setMailAddressErrorMessage(message);
    } else {
      setMailAddressErrorFlg(flg);
      setMailAddressErrorMessage("");
      const emailFlg = emailValidation(value);
      let message = com("messages.MS0040");
      if (!emailFlg) {
        setMailAddressErrorFlg(!emailFlg);
        setMailAddressErrorMessage(message);
      } else {
        setMailAddressErrorFlg(!emailFlg);
        setMailAddressErrorMessage("");
      }
    }
    return flg;
  };

  //Customer No. check
  const customerNoValidationCheck = (value: any) => {
    let flg = false;
    const customerNoHaveFlg = customerMasterData.filter(
      (item: any) => item.no == value
    );

    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("customer_no"),
      });
      setEditList(Object.assign({}, editData, { customer_name: "" }));
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage(message);
    } else if (!checkMaxLength(value, 8)) {
      flg = !checkMaxLength(value, 8);
      let message = com("messages.MS0002", {
        param1: t("customer_no"),
        param2: 8,
      });
      setEditList(Object.assign({}, editData, { customer_name: "" }));
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage(message);
    } else if (customerNoHaveFlg.length == 0) {
      flg = true;
      let message = com("messages.MS0004", {
        param1: t("customer_no"),
      });
      setEditList(Object.assign({}, editData, { customer_name: "" }));
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage(message);
    } else {
      setEditList(
        Object.assign({}, editData, {
          customer_name: customerNoHaveFlg[0].customer_name,
        })
      );
      setCustomerNoErrorFlg(flg);
      setCustomerNoErrorMessage("");
    }
    return flg;
  };

  // message box
  const [open, setOpen] = useState(false);
  // reject button click event
  const handleReject = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    setOpen(false);
    props.handleSubmit(TabKey.DZUserRegistration, registerFlg, "reject");
  };

  const onBlurOfCustomerNo = () => {
    setAlertEditMessageList([]);
    setCustomerNoErrorFlg(false);

    if (isEmptyStringOrNumber(customer_no)) {
      setCustomerNoErrorMessage("");
      setCustomerNoErrorFlg(false);
      setCustomerNo("");
      setCustomerName("");
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(customer_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_no"),
            });
            setCustomerNoErrorMessage(message);
            setCustomerName("");
            setCustomerNoErrorFlg(true);
          } else {
            setCustomerNoErrorMessage("");
            setCustomerNoErrorFlg(false);

            if (response.customer_closed === true) {
              let message = com("messages.MS0085", {
                param1: response.customer_no,
              });

              const obj: any = {
                no: alertEditMessageList.length,
                message: message,
                alertType: "warning",
              };
              const newList: any = alertEditMessageList.slice(0);
              newList.push(obj);
              setAlertEditMessageList(newList);
            }

            setCustomerName(response.customer_name);
          }
        });
    }
  };

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <>
      <div>
        {alertEditMessageList.length > 0 && (
          <AlertMesssageBox
            key={alertEditMessageList[alertEditMessageList.length - 1].no}
            show={true}
            message={
              alertEditMessageList[alertEditMessageList.length - 1].message
            }
            type={
              alertEditMessageList[alertEditMessageList.length - 1].alertType
            }
          />
        )}
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <table className={classes.tableUserInfo}>
          <tbody>
            <tr>
              <th>
                {t("given_name")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <TextField
                  error={givenNameErrorFlg}
                  helperText={givenNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={given_name}
                  onBlur={(e) => changeDataListen("given_name", e.target.value)}
                  onChange={(e) => changeData("given_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("family_name")}
                <span className={classes.mark}>*</span>
              </th>
              <td>
                <TextField
                  error={familyNameErrorFlg}
                  helperText={familyNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={family_name}
                  onBlur={(e) =>
                    changeDataListen("family_name", e.target.value)
                  }
                  onChange={(e) => changeData("family_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("middle_name")}</th>
              <td>
                <TextField
                  error={middleNameErrorFlg}
                  helperText={middleNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={middle_name}
                  onBlur={(e) =>
                    changeDataListen("middle_name", e.target.value)
                  }
                  onChange={(e) => changeData("middle_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("company_name")}</th>
              <td>
                <TextField
                  error={companyNameErrorFlg}
                  helperText={companyNameErrorMessage}
                  variant="outlined"
                  className={classes.textField}
                  value={company_name}
                  onBlur={(e) =>
                    changeDataListen("company_name", e.target.value)
                  }
                  onChange={(e) => changeData("company_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("customer_number")}
                <span className={classes.mark}>*</span>
              </th>
              <td className={classes.customerNo}>
                {/*            
            <td className={customer_name ? classes.customerNoStyle : undefined}>
              
              <TextField
                error={customerNoErrorFlg}
                helperText={customerNoErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={customer_no}
                onBlur={(e) => changeDataListen("customer_no", e.target.value)}
                onChange={(e) =>
                  changeData("customer_no", e.target.value)
                }
              /> 
*/}
                <Autocomplete
                  id="customer_no"
                  freeSolo
                  disableClearable
                  options={commonInfo.customerList}
                  getOptionLabel={getOptionCustomerLabel}
                  filterOptions={filterCustomerOptions}
                  inputValue={String(customer_no)}
                  value={String(customer_no)}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "300px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) => {
                    let isSelected = false;
                    if (option.customer_no && customerNo) {
                      isSelected =
                        option.customer_no.toString().toUpperCase() ===
                        customer_no.toString().toUpperCase();
                    }
                    return isSelected;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      error={customerNoErrorFlg}
                      helperText={customerNoErrorMessage}
                      onBlur={onBlurOfCustomerNo}
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {
                      changeData("customer_no", value.customer_no.toString());
                    }
                  }}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      const val = value.substr(0, 8);
                      changeData("customer_no", val);
                    }
                  }}
                />
                <IconButton
                  className={classes.iconButton}
                  onClick={handleCSearch}
                >
                  <SearchIcon />
                </IconButton>
                <div className={classes.inputNameContainer}>{customerName}</div>
              </td>
            </tr>
            <tr>
              <th>{t("street_house_number")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  className={classes.textField}
                  value={street}
                  onBlur={(e) => changeDataListen("street", e.target.value)}
                  onChange={(e) => changeData("street", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("postal_code")}
                <span className={classes.mark2}>*</span>
              </th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  className={classes.textField}
                  value={postal_cd}
                  onBlur={(e) => changeDataListen("postal_cd", e.target.value)}
                  onChange={(e) => changeData("postal_cd", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("city")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  className={classes.textField}
                  value={city}
                  onBlur={(e) => changeDataListen("city", e.target.value)}
                  onChange={(e) => changeData("city", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("affiliate_cd")}</th>
              <td>
                <TextField
                  disabled={true}
                  variant="outlined"
                  className={classes.textField}
                  value={affiliate_cd}
                  onBlur={(e) =>
                    changeDataListen("affiliate_cd", e.target.value)
                  }
                  onChange={(e) => changeData("affiliate_cd", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("primary_locale")}</th>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={
                      primary_locale ? primary_locale.toLocaleLowerCase() : ""
                    }
                    onChange={(e) =>
                      changeData("primary_locale", e.target.value)
                    }
                  >
                    {primaryLocale.map((item: any) => (
                      <MenuItem value={item} key={item}>
                        {item.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </td>
            </tr>
            <tr>
              <th>{t("tel_no")}</th>
              <td className={classes.phoneNumberInput}>
                <PhoneNumberInput
                  country={telUsedCountry}
                  value={tel_no}
                  onBlur={(e) => changeDataListen("tel_no", e)}
                  onChange={(e) => changeData("tel_no", "+" + e)}
                />
              </td>
            </tr>
            <tr>
              <th>
                {t("mail_address")}
                {mailAddressFlg && <span className={classes.mark}>*</span>}{" "}
              </th>
              <td>
                <TextField
                  error={mailAddressErrorFlg}
                  helperText={mailAddressErrorMessage}
                  disabled={!mailAddressFlg}
                  variant="outlined"
                  className={classes.textField}
                  value={mail_address}
                  onBlur={(e) =>
                    changeDataListen("mail_address", e.target.value)
                  }
                  onChange={(e) => changeData("mail_address", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>{t("role_id")}</th>
              <td>
                <RadioGroup
                  name="roleId"
                  className={classes.radioContainer}
                  value={role_id ? role_id : ""}
                  onChange={(e) => changeData("role_id", e.target.value)}
                >
                  <FormControlLabel
                    className={classes.radio}
                    value={roleId[0].code}
                    control={<Radio color="primary" />}
                    label={roleId[0].name}
                  />
                  <FormControlLabel
                    className={classes.radio}
                    value={roleId[1].code}
                    control={<Radio color="primary" />}
                    label={roleId[1].name}
                  />
                  <FormControlLabel
                    className={classes.radio}
                    value={roleId[2].code}
                    control={<Radio color="primary" />}
                    label={roleId[2].name}
                  />
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <th>{t("sms_receive_notification")}</th>
              <td>
                <Checkbox
                  className={classes.checkboxLayout}
                  color="primary"
                  checked={
                    sms_receive_notification ? sms_receive_notification : ""
                  }
                  onChange={(e) =>
                    changeData("sms_receive_notification", e.target.checked)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>{t("mail_receive_notification")}</th>
              <td>
                <Checkbox
                  className={classes.checkboxLayout}
                  color="primary"
                  checked={
                    mail_receive_notification ? mail_receive_notification : ""
                  }
                  onChange={(e) =>
                    changeData("mail_receive_notification", e.target.checked)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classes.buttonContainer2}>
          <Button
            className={classes.buttonWidth2}
            variant="contained"
            onClick={handleBack}
          >
            {t("back")}
          </Button>
          <Button
            className={classes.buttonWidth2}
            color="secondary"
            variant="contained"
            onClick={() => handleReject()}
          >
            {t("reject")}
          </Button>
          <Button
            className={classes.buttonWidth2}
            disabled={disabledFlg}
            variant="contained"
            color="primary"
            onClick={() => handleClickConfirm()}
          >
            {t("confirm")}
          </Button>
          <MessageBox
            open={open}
            handleSend={handleSend}
            handleClose={handleClose}
            message={com("messages.MS0054")}
          />
        </div>
      </form>
      <MasterMaintenanceSearchControlCSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </>
  );
};
