export const defaultValue = {
  TrackingNo: "TRPK1234567890",
  CustomerNo: "12345678",
  CustomerName: "Customer Init",
  CustomerPONo: "CUST-PO001",
  Location: "D01",
  ReceivedDate: new Date(),
  Technician: "W0001",
  AccessoryAttached: false,
  Model: "6904VJ",
  SerialNo: "94NTX100300",
  FaultDescription: "Text of Fault Description.",
  RepairOption: "3",
  ReplayDate: new Date(),
  QuoteResult: "3",
  TradeInItemNo: "PW5001C",
  TradeInApprove: true,
  TrackingNumberForPickup: "TRPK1234567890",
  SelfPickup: false,
  CustomerMemorandum: "CustomerMemorandum",
};

export const defaultValueBlank: { [key: string]: any } = {
  job_no: "",
  service_center_name: "",
  trackingNo: "",
  statusName: "",
  order_no: null,
  dz_order_no: "",
  customer_no: "",
  customer_name: "",
  customer_po_no: "",
  location_no: "",
  received_date: null,
  received_date_date: null,
  received_date_time: null,
  technician_cd: "",
  accessory_attached: false,
  model_no: "",
  serial_no: "",
  repair_option: "",
  warranty_approved_by: "",
  limit_amount: "",
  quote_sent_date: null,
  quote_sent_count: 0,
  quote_url: "",
  reply_date: null,
  reply_date_date: null,
  reply_date_time: null,
  quote_result: "",
  trade_in_item_no: "",
  trade_in_item_price: null,
  trade_in_item_price_currency: "",
  trade_in_approve: false,
  tracking_no_pickup: "",
  memorandum: "",
  noteFaultDescription: { note_type: 30, note: "" },
  notePartialRepairMemo: { note_type: 40, note: "" },
  contactDealer: {
    contact_type: 1,
    name: "",
    mail_address: "",
    tel_no: "",
    fax_no: "",
    sms_receive_notification: false,
    mail_receive_notification: true,
  },
  contactEndUser: {
    contact_type: 2,
    name: "",
    mail_address: "",
    tel_no: "",
    fax_no: "",
    sms_receive_notification: false,
    mail_receive_notification: false,
  },
  addressPickup: {
    address_type: 1,
    name: "",
    country: "DE",
    address_1: "",
    address_2: "",
    address_3: "",
    state_cd: "",
    postal_cd: "",
    self_pickup: false,
  },
  addressReturnTo: {
    address_type: 2,
    name: "",
    country: "DE",
    address_1: "",
    address_2: "",
    address_3: "",
    state_cd: "",
    postal_cd: "",
    self_pickup: false,
  },
  addressBillTo: {
    address_type: 3,
    name: "",
    country: "DE",
    address_1: "",
    address_2: "",
    address_3: "",
    state_cd: "",
    postal_cd: "",
    self_pickup: false,
  },
};

export const defaultErrorValueBlank = {
  customer_no_error: "",
  customer_po_no_error: "",
  location_no_error: "",
  received_date_date_error: "",
  received_date_time_error: "",
  technician_cd_error: "",
  model_no_error: "",
  serial_no_error: "",
  reply_date_date_error: "",
  reply_date_time_error: "",
  warranty_approved_by_error: "",
  limit_amount_error: "",
  trade_in_item_no_error: "",
  dealer_name_error: "",
  dealer_mail_address_error: "",
  endUser_name_error: "",
  endUser_mail_address_error: "",
  pickup_name_error: "",
  pickup_address_2_error: "",
  pickup_address_3_error: "",
  pickup_address_1_error: "",
  pickup_postal_cd_error: "",
  returnTo_name_error: "",
  returnTo_address_2_error: "",
  returnTo_address_3_error: "",
  returnTo_address_1_error: "",
  returnTo_postal_cd_error: "",
  billTo_name_error: "",
  billTo_address_2_error: "",
  billTo_address_3_error: "",
  billTo_address_1_error: "",
  billTo_postal_cd_error: "",
};

export const CustomerMemorandum = {
  name: "",
  memo: "CustomerMemorandum",
  date: "",
  buttonFlg: false,
};

export const OtherContents = {
  title: "Other Contents",
  name: "Giver_name Middle_name Family_name",
  memo: "Other Contents",
  date: "21/10/2020 11:40",
  buttonFlg: false,
};

export const InternalUseValue = [
  {
    name: "Giver_name Middle_name Family_name",
    memo: "Memo for Internal use",
    date: "21/10/2020 11:40",
    buttonFlg: true,
  },
];
