import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      minHeight: "48px",
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:not(:first-child)": {
          verticalAlign: "top",
          paddingTop: "8px",
          paddingBottom: "5px",
        },
      },
      "&:last-child td": {
        borderBottom: "none",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0"
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    checkbox: {
      minHeight: "42px",
    },
    customerNo: {
      width: "170px",
      position: "relative",
      margin: "0 auto",
      "& .MuiOutlinedInput-input": {
        paddingRight: "30px",
      },
    },
    iconButton: {
      position: "absolute",
      right: "3px",
      top: "2px",
      padding: "2px",
      "& .MuiSvgIcon-root": {
        width: "22px",
        height: "22px",
      },
    },
  })
);

export const PromotionDetailCustomerTableTbody = (props: any) => {
  const { selected, setLastIndex } = props;
  const classes = useStyles();

  const { getCommonInfo } =  useCommonInfo();

  let commonInfo = getCommonInfo() as any;

  const isSelected = (name: string) => selected.indexOf(name) > -1;

  const errorData = props.errorValueList.promotion_customer;

  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };
  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <TableBody data-testid="customer_body">
      {props.data.map((row: any, index: number) => {
        setLastIndex(index);
        const isItemSelected = isSelected(row.index);
        const errorFlg1 =
          errorData &&
          errorData[index] &&
          errorData[index].customer_no_error !== "";

        const customerTypeErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_type_error !== "";

        const customerCsac1ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac1_error !== "";

        const customerCsac2ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac2_error !== "";

        const customerCsac3ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac3_error !== "";

        const customerCsac4ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac4_error !== "";

        const customerCsac5ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac5_error !== "";

        const customerCsac6ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac6_error !== "";

        const customerCsac7ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac7_error !== "";

        const customerCsac8ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac8_error !== "";

        const customerCsac9ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac9_error !== "";

        const customerCsac10ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].customer_csac10_error !== "";

        const customerPromotionRegionErrorFlg =
        errorData &&
        errorData[index] &&
        errorData[index].customer_promotion_region_error !== "";


        return (
          <TableRow
            className={classes.tableRow}
            hover={true}
            tabIndex={-1}
            key={row.index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div className={classes.checkbox} data-testid={"customer_checkbox"+index}>
                {
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => props.handleClick(event, row)}
                    disabled={props.reviewFlg}
                  />
                }
              </div>
            </TableCell>
            <TableCell align="center">
              <TextField
                variant="outlined"
                value={row.customer_type}
                onChange={(e: any) => {
                  const value = e.target.value
                    // .replace(/[^\w]/g, "")
                    .substr(0, 4);
                  props.setCustomerValue("customer_type", value, index);
                }}
                onBlur={(e) =>
                  props.changeDataListen(
                    "customer_customer_type",
                    e.target.value,
                    index
                  )
                }
                error={customerTypeErrorFlg}
                helperText={
                  customerTypeErrorFlg ? errorData[index].customer_type_error : ""
                }
                disabled={props.customerEditList[index] || props.reviewFlg}
              />
            </TableCell>
            <TableCell align="center">
              <div className={classes.customerNo} data-testid={"customerNo"+index}>

                <Autocomplete
                  id="customer_no"
                  freeSolo
                  disableClearable
                  options={commonInfo.customerList}
                  getOptionLabel={getOptionCustomerLabel}
                  filterOptions={filterCustomerOptions}
                  inputValue={String(row.customer_no)}
                  value={String(row.customer_no)}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "200px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) =>
                    option.customer_no.toString().toUpperCase() ===
                    props.data[index].customer_no.toString().toUpperCase()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      onBlur={() => {
                        props.onBlurOfCustomerNo(index);
                      }}
                      onFocus={() => props.onFocusOfCustomerNo(index)}
                      error={errorFlg1}
                      helperText={
                        errorFlg1 ? errorData[index].customer_no_error : ""
                      }
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {

                      props.setCustomerValue("customer_no", value.customer_no.toString(), index);
                    }
                  }}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      let val = value.substr(0, 8);
                      props.setCustomerValue("customer_no", val, index);
                    }
                  }}
                  disabled={props.reviewFlg}
                />
                <IconButton
                  className={classes.iconButton}
                  onClick={() => props.handleCSearch(index)}
                  disabled={props.reviewFlg}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </TableCell>
            <TableCell data-testid={"customer_name"+index}>{row.customer_name}</TableCell>
            <TableCell data-testid={"customer_discount_code"+index} align="center">
              <TextField
                variant="outlined"
                value={row.discount_code}
                onChange={(e) => {
                  const value = e.target.value
                    // .replace(/[^\w]/g, "")
                    .substr(0, 2);
                  props.setCustomerValue("discount_code", value, index);
                }}

                disabled={props.customerEditList[index] || props.reviewFlg}
              />
            </TableCell>
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac1") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac1}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac1", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac1",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac1ErrorFlg}
                    helperText={
                      customerCsac1ErrorFlg ? errorData[index].customer_csac1_error : ""
                    }
                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac2") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac2}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac2", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac2",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac2ErrorFlg}
                    helperText={
                      customerCsac2ErrorFlg ? errorData[index].customer_csac2_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac3") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac3}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac3", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac3",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac3ErrorFlg}
                    helperText={
                      customerCsac3ErrorFlg ? errorData[index].customer_csac3_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac4") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac4}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac4", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac4",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac4ErrorFlg}
                    helperText={
                      customerCsac4ErrorFlg ? errorData[index].customer_csac4_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac5") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac5}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac5", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac5",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac5ErrorFlg}
                    helperText={
                      customerCsac5ErrorFlg ? errorData[index].customer_csac5_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac6") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac6}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac6", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac6",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac6ErrorFlg}
                    helperText={
                      customerCsac6ErrorFlg ? errorData[index].customer_csac6_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac7") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac7}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac7", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac7",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac7ErrorFlg}
                    helperText={
                      customerCsac7ErrorFlg ? errorData[index].customer_csac7_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac8") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac8}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac8", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac8",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac8ErrorFlg}
                    helperText={
                      customerCsac8ErrorFlg ? errorData[index].customer_csac8_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac9") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac9}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac9", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac9",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac9ErrorFlg}
                    helperText={
                      customerCsac9ErrorFlg ? errorData[index].customer_csac9_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("csac10") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.csac10}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("csac10", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_csac10",
                        e.target.value,
                        index
                      )
                    }
                    error={customerCsac10ErrorFlg}
                    helperText={
                      customerCsac10ErrorFlg ? errorData[index].customer_csac10_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
            {props.salesAnalysisList.customer_list &&
              props.salesAnalysisList.customer_list.length > 0 &&
              props.salesAnalysisList.customer_list.includes("promotion_region") && (
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.promotion_region}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setCustomerValue("promotion_region", value, index);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "customer_promotion_region",
                        e.target.value,
                        index
                      )
                    }
                    error={customerPromotionRegionErrorFlg}
                    helperText={
                      customerPromotionRegionErrorFlg ? errorData[index].customer_promotion_region_error : ""
                    }

                    disabled={props.customerEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
