import {
  Badge,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../hooks/UseLocale";
import { GetNavigationDrawerItemGroups } from "./Navigation.Drawer.Menu.Items";
import { useHistory } from "react-router-dom";
import { UseDrawerStyles } from "./Navigation.Drawer.Style";
import { useNotitificationContext } from "../../../hooks/NotificationContext";
import { useCommonInfo } from "../../../hooks/CommonInfoProvider";
import api from "../../../api/Api";
import { responseStatusCodes, Sum } from "../../../constants/common.constants";
import { GetPrivateAppRoutePath, PathKey } from "../../../../pages/AppRoutes";

type Prop = {
  isDrawerOpen: boolean;
  onCloseEvent: () => void;
  onMouseOverDrawerEvent: () => void;
  onMouseLeaveDrawerEvent: () => void;
};

export const NavigationDrawerMenu: FC<Prop> = ({
  isDrawerOpen,
  onCloseEvent,
  onMouseOverDrawerEvent,
  onMouseLeaveDrawerEvent,
}) => {
  const { t } = useLocale({
    defaultPath: "modules.components.navigationDrawer",
  });
  const classes = UseDrawerStyles();
  const history = useHistory();
  const handleOnClick = useCallback(
    (path: string, targetBlank: boolean = false) => {
      onCloseEvent();
      if (targetBlank) {
        window.open(path);
      } else {
        history.push(path);
      }
    },
    [history]
  );
  const {
    locationControl,
    setLocationControl,
    numberOfLocationControl,
    setNumberOfLocationControl,
  } = useNotitificationContext();
  const { getCommonConfig, getUserInfoRepair, setUserInfoRepair } =
    useCommonInfo();
  let config = getCommonConfig() as any;
  const userInfoRepair = getUserInfoRepair() as any;
  const [initFlag, setInitFlag] = useState(false);

  useEffect(() => {
    // config.repairShowFlag Repair部分が表示・非表示用フラグ
    if (config.token !== undefined && !initFlag) {
      setInitFlag(true);
      getRepairJobLocationControl();
    }
  }, [config, initFlag]);

  const getRepairJobLocationControl = async () => {
    let list: any = [];
    let service_center: string = "";
    if (config.repairShowFlag) {
      await api
        .getRepairJobLocationControlAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (code === responseStatusCodes.success) {
            list = response;
          }
        });
    }
    if (!userInfoRepair.service_center) {
      await api
        .getReadUserInfoAPIForUserinfoRepair(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0108 response: ", response);
          if (code === responseStatusCodes.success) {
            service_center = response.service_center;
            setUserInfoRepair(response);
          }
        });
    } else {
      service_center = userInfoRepair.service_center;
    }
    getServiceCenterInformation(service_center, list);
  };

  const getServiceCenterInformation = (service_center: any, list: any) => {
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.service_center_id === service_center) {
        console.log(
          "getServiceCenterInformation sum",
          Sum(
            item.repair.total_num,
            item.send_back.total_num,
            item.dispose.total_num
          )
        );
        setNumberOfLocationControl(
          Sum(
            item.repair.total_num,
            item.send_back.total_num,
            item.dispose.total_num
          )
        );
      }
    }
  };
  return (
    <Drawer
      variant="permanent"
      onMouseOver={onMouseOverDrawerEvent}
      onMouseLeave={onMouseLeaveDrawerEvent}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerMenuOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerMenuOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      {/* Margin for Toolbar */}
      <div className={classes.toolbar}></div>
      {GetNavigationDrawerItemGroups().map((m, i) => {
        return (
          <React.Fragment key={i}>
            {m.menuItems.map(
              (m2, i2) =>
                m2.showFlg && (
                  <>
                    <List key={i}>
                      <ListItem
                        button
                        key={i2}
                        onClick={() => handleOnClick(m2.path, m2.targetBlank)}
                        id={m2.textKey}
                      >
                        <ListItemIcon>
                          <Badge
                            badgeContent={
                              m2.path ===
                              GetPrivateAppRoutePath(PathKey.LOCATIONCONTROL)
                                ? numberOfLocationControl
                                : 0
                            }
                            color="secondary"
                          >
                            <m2.icon />
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary={t(m2.textKey)} />
                      </ListItem>
                    </List>
                  </>
                )
            )}
            <Divider />
          </React.Fragment>
        );
      })}
    </Drawer>
  );
};
