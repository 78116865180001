import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import {
  priceInputBySign,
  decimalPointIsLenth,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      minHeight: "48px",
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:not(:first-child)": {
          verticalAlign: "top",
          paddingTop: "8px",
          paddingBottom: "5px",
        },
      },
      "&:last-child td": {
        borderBottom: "none",
      },
    },
    checkbox: {
      minHeight: "42px",
    },
    purchaseAmountMoreThan: {
      display: "flex",
      margin: "0 auto",

      "& .MuiInputBase-root": {
        marginRight: "5px",
      },
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    discountRate: {
      display: "flex",
      "& .MuiInputBase-root": {
        marginRight: "5px",
      },
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
    unit: {
      marginTop: "6px",
    },
  })
);

export const PromotionDetailConditionsAmountBaseTableTbody = (props: any) => {
  const { selected } = props;
  const classes = useStyles();
  const isSelected = (name: string) => selected.indexOf(name) > -1;
  const { getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;

  return (
    <TableBody data-testid="condition_amount_base_body">
      {props.data.map((row: any, index: number) => {
        const isItemSelected = isSelected(row.index);
        const errorData = props.errorValueList.condition_amount_base;
        const errorFlg1 =
          errorData &&
          errorData[index] &&
          errorData[index].purchase_amount_error !== "";
        const errorFlg2 =
          errorData &&
          errorData[index] &&
          errorData[index].discount_rate_error !== "";
        const type = "condition_amount_base";
        return (
          <TableRow
            className={classes.tableRow}
            hover={true}
            tabIndex={-1}
            key={row.index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div
                className={classes.checkbox}
                data-testid={"condition_amount_base_checkbox" + index}
              >
                {
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => props.handleClick(event, row)}
                    disabled={props.reviewFlg}
                  />
                }
              </div>
            </TableCell>
            <TableCell>
              <div
                className={classes.purchaseAmountMoreThan}
                data-testid={"purchase_amount" + index}
              >
                <TextField
                  variant="outlined"
                  value={row.purchase_amount_format}
                  onChange={(e: any) => {
                    if (props.unitBaseOrAmountBaseValue === "2") {
                      let value = "";
                      let _price = priceInputBySign(e.target.value);
                      if (
                        !isNaN(_price) &&
                        _price >= 0 &&
                        decimalPointIsLenth(e.target.value)
                      ) {
                        value = e.target.value.slice(0, 11);
                      } else {
                        value = row.purchase_amount_format;
                      }
                      props.setCustomFieldValue(
                        "purchase_amount",
                        value,
                        index,
                        type
                      );
                    }
                  }}
                  onBlur={(e) =>
                    props.changeDataListen(
                      "amountBase_purchase_amount",
                      e.target.value,
                      index
                    )
                  }
                  error={errorFlg1}
                  helperText={
                    errorFlg1 ? errorData[index].purchase_amount_error : ""
                  }
                  disabled={props.reviewFlg}
                />
                <div className={classes.unit}>
                  {symbolByCurrency(config.currency)}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div
                className={classes.discountRate}
                data-testid={"amountBase_discount_rate" + index}
              >
                <TextField
                  variant="outlined"
                  value={row.discount_rate_format}
                  onChange={(e) => {
                    if (props.unitBaseOrAmountBaseValue === "2") {
                      let value = "";
                      let _discount_rate = priceInputBySign(e.target.value);
                      if (
                        !isNaN(_discount_rate) &&
                        _discount_rate >= 0 &&
                        decimalPointIsLenth(e.target.value)
                      ) {
                        value = e.target.value.slice(0, 11);
                      } else {
                        value = row.discount_rate_format
                          ? row.discount_rate_format
                          : "";
                      }
                      props.setCustomFieldValue(
                        "discount_rate",
                        value,
                        index,
                        type
                      );
                    }
                  }}
                  onBlur={(e) =>
                    props.changeDataListen(
                      "amountBase_discount_rate",
                      e.target.value,
                      index
                    )
                  }
                  error={errorFlg2}
                  helperText={
                    errorFlg2 ? errorData[index].discount_rate_error : ""
                  }
                  disabled={props.reviewFlg}
                />
                <div className={classes.unit}>%</div>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
