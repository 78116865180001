export const permission = [
  {
    value: "permissionId1",
    label: "permissionId1",
    showFlg: true,
  },
  {
    value: "permissionId2",
    label: "permissionId2",
    showFlg: true,
  },
  {
    value: "permissionId3",
    label: "permissionId3",
    showFlg: true,
  },
  {
    value: "permissionId4",
    label: "permissionId4",
    showFlg: true,
  },
  {
    value: "permissionId5",
    label: "permissionId5",
    showFlg: true,
  },
  {
    value: "permissionId6",
    label: "permissionId6",
    showFlg: true,
  },
  {
    value: "permissionId7",
    label: "permissionId7",
    showFlg: true,
  },
  {
    value: "permissionId8",
    label: "permissionId8",
    showFlg: true,
  },
  {
    value: "permissionId9",
    label: "permissionId9",
    showFlg: true,
  },
  {
    value: "permissionId10",
    label: "permissionId10",
    showFlg: true,
  },
];

export enum TabKey {
  useMaster,
  DZUserInfo,
  DZUserRegistration,
  customerMaster,
  itemMaster,
  role,
  userRole,
  serviceCenter,
  printSetting,
  repairDestination,
  laborMaster,
}

export interface HeadCell {
  id: string;
  label: string;
  searchFlg?: boolean;
}

export const defaultEditValue: any = {
  userinfodz: {},
  userinfo_repair: {},
  permission: {},
};
