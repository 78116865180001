import { http } from "./Http";

// AT0601
export const GetRepairRegistrationPrintAPI = (job_no: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/docugen/repair/${affiliate_cd}/${job_no}`,
    config: config,
  };
  return http(param);
};

// AT0602
export const GetWagonLoadingListPrintAPI = (
  location_no: any,
  job_type: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/docugen/wagon/${affiliate_cd}/${location_no}/${job_type}`,
    config: config,
  };
  return http(param);
};

// AT0603
export const GetReceiptLabelPrintAPI = (
  job_no: any,
  copies: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/docugen/receipt/${affiliate_cd}/${job_no}/${copies}`,
    config: config,
  };
  return http(param);
};

// AT0605
export const GetConsoleRepairRegistrationPrintAPI = (job_no: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/docugen/consolerepair/${affiliate_cd}/${job_no}`,
    config: config,
  };
  return http(param);
};
