import React, { useState } from "react";
// components
import { JobsDetailGeneralRepairMediaThead } from "./JobsDetail.General.RepairMedia.Thead";
import { JobsDetailGeneralRepairMediaTbody } from "./JobsDetail.General.RepairMedia.Tbody";
// hooks
import { useStyles } from "../hooks/useStyles";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      "& td": {
        paddingRight: "5px",
      },
    },
    controlSelect: {
      minWidth: "210px",
      "& .MuiInputBase-root": {
        height: "30px",
      },
    },
    media_type: {
      "& .MuiOutlinedInput-input": {
        padding: "0px",
        height: "30px",
        lineHeight: "30px",
        paddingLeft: "5px",
      },
    },
  })
);

export const JobsDetailGeneralRepairMedia = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const {
    data,
    mediaType,
    setMediaType,
    handleUpload,
    handleOnClickLink,
    handleDownload,
    handleDeleteMedia,
    userPermisssionInfo,
  } = props;
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.group_repair_media",
  });

  const goButtonDisabledFlg = () => {
    return !Boolean(mediaType);
  };

  const handleOnChange = (value: any) => {
    setMediaType(value);
  };

  const handleFileSelected = (file: any) => {
    handleUpload(file);
  };

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name")}</h3>

      <div className={classes.groupTopButton}>
        <table className={myClasses.tableLayout}>
          <tbody>
          {userPermisssionInfo.upload_authFlg && (
            <tr>
              <td>{t("select_media_type")}</td>
              <td>
                <FormControl
                  variant="outlined"
                  className={myClasses.controlSelect}
                >
                  <Select
                    id="media_type"
                    className={myClasses.media_type}
                    value={mediaType}
                    onChange={(e) => handleOnChange(e.target.value)}
                    native
                  >
                    <option value={""}></option>
                    {Array.isArray(com("media_type")) ? (
                      com("media_type").map((option: any) => (
                        (option.code == 1 || option.code === 2 || option.code === 3 || option.code === 4 ) && (
                        <option key={option.code} value={option.code}>
                          {option.name}
                        </option>)
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </td>
              <td>
                <input
                  // accept=".csv"
                  id="contained-button-file"
                  type="file"
                  className={classes.uploadInput}
                  disabled={goButtonDisabledFlg()}
                  onChange={(e: any) => handleFileSelected(e.target.files)}
                  onClick={(e: any)=> { e.target.value = null }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    disabled={goButtonDisabledFlg()}
                    className={classes.uploadButton}
                  >
                    {t("btn_upload")}
                  </Button>
                </label>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>

      <TableContainer className={classes.groupContainer}>
        <Table stickyHeader>
          <JobsDetailGeneralRepairMediaThead />
          <JobsDetailGeneralRepairMediaTbody
            data={data}
            handleOnClickLink={handleOnClickLink}
            handleDownload={handleDownload}
            handleDeleteMedia={handleDeleteMedia}
            userPermisssionInfo={userPermisssionInfo}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
