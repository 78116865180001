import React, { FC, useState, useCallback, useEffect } from "react";
// route
import { useHistory } from "react-router-dom";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { PromotionName } from "./components/PromotionDetail.PromotionName";
import { General } from "./components/PromotionDetail.General";
import { Period } from "./components/PromotionDetail.Period";
import { Customer } from "./components/PromotionDetail.Customer";
import { Category } from "./components/PromotionDetail.Category";
import { FreeGoods } from "./components/PromotionDetail.FreeGoods";
import { Term } from "./components/PromotionDetail.Term";
import { Conditions } from "./components/PromotionDetail.Conditions";
import { UpdateHistory } from "./components/PromotionDetail.UpdateHistory";
import { PromotionCode } from "./components/PromotionDetail.PromotionCode";
import { ModelWindow } from "../../modules/components/model/ModelWindow";
import {
  date,
  time,
} from "../../modules/components/numberFormat/NumberFormat.Date";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import { RepairRegisterIcon } from "../../modules/components/icons/Icons";
import SaveIcon from "@material-ui/icons/Save";
// api
import api from "../../modules/api/Api";
// valication
import { isEmptyCheck } from "../../modules/hooks/UseValication";
// check
import { isEmptyStringOrNumber } from "../../modules/hooks/UseValication";
// methods
import { getApiResultStatus, getSignByCSV } from "../../modules/common.methods";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
// permissions
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import {
  priceOutputBySign,
  priceInputBySign,
} from "../../modules/components/numberFormat/NumberFormat.Currency";
import { conditionSetFormat } from "./methods/PromotionDetail.Methods";
// common json
const defaultValue: any = {
  promo_name: "",
  promo_type: "10",
  priority: "",
  analysis_cd: "",
  allow_overlap: "0",
  exclusive_flag: "0",
  webshop_flag: "1",
  others_flag: "1",
  start_date: null as any,
  end_date: null as any,
  limit_type: "0",
  draft_flag: "",
  promotion_customer: [
    {
      index: 0,
      customer_type: "",
      customer_no: "",
      customer_name: "",
      discount_code: "",
      csac1: "",
      csac2: "",
      csac3: "",
      csac4: "",
      csac5: "",
      csac6: "",
      csac7: "",
      csac8: "",
      csac9: "",
      csac10: "",
      promotion_region: "",
    },
  ],
  category_considered: [
    {
      index: 0,
      data_type: "1",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,
      item_no: "",
      description: "",
      unit_packet: "",
    },
  ],
  category_excluded: [
    {
      index: 0,
      data_type: "2",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,
      item_no: "",
      description: "",
    },
  ],
  planner_code_considered: "",
  planner_code_excluded: "",
  condition_unit_base: [
    {
      index: 0,
      data_type: "4",
      packet: "",
      discount_rate: "",
      discount_rate_format: "",
    },
  ],
  condition_amount_base: [
    {
      index: 0,
      data_type: "5",
      purchase_amount: "",
      discount_rate: "",
      discount_rate_format: "",
    },
  ],
  unitBase_or_amountBase: "1",
};

// category json
const defaultCategoryValue = {
  category_considered: [
    {
      index: 0,
      data_type: "1",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,

      item_no: "",
      description: "",
      unit_packet: "",
    },
  ],
  category_excluded: [
    {
      index: 0,
      data_type: "2",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,

      item_no: "",
      description: "",
    },
  ],
  planner_code_considered: "",
  planner_code_excluded: "",
};

// discount json
const defaultDiscountValue = {
  condition_unit_base: [
    {
      index: 0,
      data_type: "4",
      packet: "",
      discount_rate: "",
      discount_rate_format: "",
    },
  ],
  condition_amount_base: [
    {
      index: 0,
      data_type: "5",
      purchase_amount: "",
      purchase_amount_format: "",
      discount_rate: "",
      discount_rate_format: "",
    },
  ],
  unitBase_or_amountBase: "1",
};

// dealer discount json
const defaultDealerDiscountValue = {
  dealer_discount: [
    {
      data_type: "9",
      discount_rate: "",
      discount_rate_format: "",
    },
  ],
};

// free goods json
const defaultFreeGoodsValue = {
  free_goods: [
    {
      index: 0,
      data_type: "3",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,

      item_no: "",
      description: "",
      quantity: "",
    },
  ],
  planner_code_free_goods: "",
};

// special price json
const defaultSpecialPriceValue = {
  condition_single: [
    {
      data_type: "6",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,

      item_no: "",
      description: "",
      quantity: "",
      special_price: "",
      single_type: "1",
    },
  ],
  condition_set: [
    {
      index: 0,
      data_type: "7",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,

      item_no: "",
      description: "",
      quantity: "",
      special_price: "",
      unit_price: "",
      unit_price_format: "",
    },
    {
      index: 1,
      data_type: "7",
      discount_code: "",
      item_class: "",
      isac1: "",
      isac2: "",
      isac3: "",
      isac4: "",
      isac5: "",
      isac6: "",
      isac7: "",
      isac8: "",
      // webshop_release: 1,

      item_no: "",
      description: "",
      quantity: "",
      special_price: "",
      unit_price: "",
      unit_price_format: "",
    },
  ],
  single_or_set: "1",
  planner_code_single: "",
  planner_code_set: "",
};

// promotion code json
const defaultPromotionCodeValue = {
  promotion_code: [
    {
      data_type: "8",
      discount_rate: "",
      discount_rate_format: "",
      promo_code_type: "1",
    },
  ],
};

// term json
const defaultTermValue = {
  terms_cd: "",
};

// error json
const errorListPromotionType = {
  condition_unit_base: [
    {
      packet_error: "",
      discount_rate_error: "",
    },
  ],
  condition_amount_base: [
    {
      purchase_amount_error: "",
      discount_rate_error: "",
    },
  ],
  free_goods: [
    {
      item_no_error: "",
      quantity_error: "",
      item_class_error: "",
      isac1_error: "",
      isac2_error: "",
      isac3_error: "",
      isac4_error: "",
      isac5_error: "",
      isac6_error: "",
      isac7_error: "",
      isac8_error: "",
    },
  ],
  condition_unit_base_error: "",
  condition_amount_base_error: "",
  free_goods_item_error: "",
  condition_single: [
    {
      item_no_error: "",
      quantity_error: "",
      special_price_error: "",
    },
  ],
  condition_set: [
    {
      item_no_error: "",
      quantity_error: "",
      unit_price_error: "",
    },
    {
      item_no_error: "",
      quantity_error: "",
      unit_price_error: "",
    },
  ],
  set_item_error: "",
  // dealer discount
  dealerDiscount_discount_rate_error: "",
  set_special_price_error: "",
  // promotion code
  promotionCode_discount_rate_error: "",
  // payment term
  terms_cd_error: "",
};

// category json
const errorListCategory = {
  category_considered: [
    {
      item_no_error: "",
      unit_packet_error: "",
      item_class_error: "",
      isac1_error: "",
      isac2_error: "",
      isac3_error: "",
      isac4_error: "",
      isac5_error: "",
      isac6_error: "",
      isac7_error: "",
      isac8_error: "",
    },
  ],
  category_excluded: [
    {
      item_no_error: "",
      item_class_error: "",
      isac1_error: "",
      isac2_error: "",
      isac3_error: "",
      isac4_error: "",
      isac5_error: "",
      isac6_error: "",
      isac7_error: "",
      isac8_error: "",
    },
  ],
  considered_item_error: "",
  excluded_item_error: "",
};

// error common json
const errorList: any = {
  start_date_error: "",
  end_date_error: "",
  priority_error: "",
  webshop_error: "",
  analysis_cd_error: "",
  promo_name_error: "",
  promotion_customer: [
    {
      customer_no_error: "",
      customer_type_error: "",
      customer_csac1_error: "",
      customer_csac2_error: "",
      customer_csac3_error: "",
      customer_csac4_error: "",
      customer_csac5_error: "",
      customer_csac6_error: "",
      customer_csac7_error: "",
      customer_csac8_error: "",
      customer_csac9_error: "",
      customer_csac10_error: "",
      customer_promotion_region_error: "",
    },
  ],
  customer_error: "",
  customer_oneFieldRequird_error: "",
  category_considered: [
    {
      item_no_error: "",
      unit_packet_error: "",
      item_class_error: "",
      isac1_error: "",
      isac2_error: "",
      isac3_error: "",
      isac4_error: "",
      isac5_error: "",
      isac6_error: "",
      isac7_error: "",
      isac8_error: "",
    },
  ],
  category_excluded: [
    {
      item_no_error: "",
      item_class_error: "",
      isac1_error: "",
      isac2_error: "",
      isac3_error: "",
      isac4_error: "",
      isac5_error: "",
      isac6_error: "",
      isac7_error: "",
      isac8_error: "",
    },
  ],
  considered_item_error: "",
  excluded_item_error: "",
  ...errorListPromotionType,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    message: {
      margin: 0,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    divWidth: {
      width: "400px",
    },
  })
);

export const Page: FC = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "models.model_window" });
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const [searchValueList, setSearchValueList] = useState(
    JSON.parse(JSON.stringify(defaultValue))
  );
  const [defaultValueList, setDefaultValueList] = useState(
    JSON.parse(JSON.stringify(defaultValue))
  );
  const [oldValue, setOldValue] = useState(
    JSON.parse(JSON.stringify(defaultValue))
  );

  const [editValueList, setEditValueList] = useState();
  const [errorValueList, setErrorValueList] = useState(errorList);
  const [modalOpen, setModalOpen] = useState(false);
  const [disabledCondition, setDisabledCondition] = useState(false);
  const [disabledSuccess, setDisabledSuccess] = useState(false);
  const [property, setProperty] = useState([]) as any;
  const [promotionTypeValue, setPromotionTypeValue] = useState();
  const [checkResultValue, setCheckResultValue] = useState("") as any;
  const [registerPromotion, setRegisterPromotion] = useState(true);
  const [onfocusFlag, setOnfocusFlag] = useState(false);
  const [onCustomerNoFlag, setOnCustomerNoFlag] = useState(false);

  const [onConsideredItemNoFlag, setOnConsideredItemNoFlag] = useState(false);
  const [onExcludedItemNoFlag, setOnExcludedItemNoFlag] = useState(false);
  const [onFreeGoodsItemNoFlag, setOnFreeGoodsItemNoFlag] = useState(false);
  const [onSetItemNoFlag, setOnSetItemNoFlag] = useState(false);
  const [customerNo, setCustomerNo] = useState("") as any;
  const [alertCustomerNoMessageFlag, setAlertCustomerNoMessageFlag] =
    useState(false);
  const [itemNo, setItemNo] = useState("") as any;
  const [itemNoWebshopReleaseFlag, setItemNoWebshopReleaseFlag] =
    useState(false);
  const [itemNoSuspendFlag, setItemNoSuspendFlag] = useState(false);
  const [alertItemNoMessageFlag, setAlertItemNoMessageFlag] = useState(false);
  const [saveToDraftsButtonFlag, setSaveToDraftsButtonFlag] = useState(true);
  const [draftsQuitWithoutSaving, setDraftsQuitWithoutSaving] = useState(false);
  const [saveToDrafts, setSaveToDrafts] = useState(false);
  const [conditionsSearchValueList, setConditionsSearchValueList] = useState(
    {}
  ) as any;
  const [returnToPromotionList, setReturnToPromotionList] = useState(false);

  const [forceRegisterDisplay, setForceRegisterDisplay] = useState(false);

  const [forceDraftRegisterDisplay, setForceDraftRegisterDisplay] =
    useState(false);
  const [forceUpdateDisplay, setForceUpdateDisplay] = useState(false);

  const [discardChanges, setDiscardChanges] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  // const [webshopReleaseFlag, setWebshopReleaseFlag] = useState(false);
  // const [createWebshopReleaseFlag, setCreateWebshopReleaseFlag] =
  //   useState(false);

  const [deletePromotion, setDeletePromotion] = useState(false);
  const [customerEditList, setCustomerEditList] = useState([false]);
  const [customerInfo, setCustomerInfo] = useState([false]);
  const [customerInfoIndex, setCustomerInfoIndex] = useState("") as any;
  const [itemInfo, setItemInfo] = useState([false]);
  const [itemInfoIndex, setItemInfoIndex] = useState("") as any;
  const [itemNoTypeInfo, setItemNoTypeInfo] = useState("") as any;
  const [itemResponseInfo, setItemResponseInfo] = useState("") as any;
  const [consideredEditList, setConsideredEditList] = useState([false]);
  const [excludedEditList, setExcludedEditList] = useState([false]);
  const [freeGoodsEditList, setFreeGoodsEditList] = useState([false]);
  const [promotionInformation, setPromotionInformation] = useState({}) as any;
  const history = useHistory();
  const createPromotionFlag = history.location.search;

  const [numSelectedCustomer, setNumSelectedCustomer] = useState(0);
  const [selectedArrayCustomer, setSelectedArrayCustomer] = useState<string[]>(
    []
  );
  const [selectedRowArrayCustomer, setSelectedRowArrayCustomer] = useState([]);
  const [indeterminateCustomer, setIndeterminateCustomer] = useState(false);
  const [allCheckedCustomer, setAllCheckedCustomer] = useState(false);

  const [numSelectedUnitBase, setNumSelectedUnitBase] = useState(0);
  const [selectedArrayUnitBase, setSelectedArrayUnitBase] = useState<string[]>(
    []
  );
  const [selectedRowArrayUnitBase, setSelectedRowArrayUnitBase] = useState([]);
  const [indeterminateUnitBase, setIndeterminateUnitBase] = useState(false);
  const [allCheckedUnitBase, setAllCheckedUnitBase] = useState(false);

  const [numSelectedAmountBase, setNumSelectedAmountBase] = useState(0);
  const [selectedArrayAmountBase, setSelectedArrayAmountBase] = useState<
    string[]
  >([]);
  const [selectedRowArrayAmountBase, setSelectedRowArrayAmountBase] = useState(
    []
  );
  const [indeterminateAmountBase, setIndeterminateAmountBase] = useState(false);
  const [allCheckedAmountBase, setAllCheckedAmountBase] = useState(false);

  const [numSelectedConsidered, setNumSelectedConsidered] = useState(0);
  const [selectedArrayConsidered, setSelectedArrayConsidered] = useState<
    string[]
  >([]);
  const [selectedRowArrayConsidered, setSelectedRowArrayConsidered] = useState(
    []
  );
  const [indeterminateConsidered, setIndeterminateConsidered] = useState(false);
  const [allCheckedConsidered, setAllCheckedConsidered] = useState(false);

  const [numSelectedExcluded, setNumSelectedExcluded] = useState(0);
  const [selectedArrayExcluded, setSelectedArrayExcluded] = useState<string[]>(
    []
  );
  const [selectedRowArrayExcluded, setSelectedRowArrayExcluded] = useState([]);
  const [indeterminateExcluded, setIndeterminateExcluded] = useState(false);
  const [allCheckedExcluded, setAllCheckedExcluded] = useState(false);

  const [numSelectedFreeGoods, setNumSelectedFreeGoods] = useState(0);
  const [selectedArrayFreeGoods, setSelectedArrayFreeGoods] = useState<
    string[]
  >([]);
  const [selectedRowArrayFreeGoods, setSelectedRowArrayFreeGoods] = useState(
    []
  );
  const [indeterminateFreeGoods, setIndeterminateFreeGoods] = useState(false);
  const [allCheckedFreeGoods, setAllCheckedFreeGoods] = useState(false);

  const [numSelectedSet, setNumSelectedSet] = useState(0);
  const [selectedArraySet, setSelectedArraySet] = useState<string[]>([]);
  const [selectedRowArraySet, setSelectedRowArraySet] = useState([]);
  const [indeterminateSet, setIndeterminateSet] = useState(false);
  const [allCheckedSet, setAllCheckedSet] = useState(false);

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

  const [salesAnalysisList, setSalesAnalysisList] = useState({}) as any;

  const [pagePostRequestData, setPagePostRequestData] = useState({}) as any;

  const [changeItemWebshopReleaseData, setChangeItemWebshopReleaseData] =
    useState([]) as any;
  const [postRequestDataState, setPostRequestDataState] = useState();
  const [promoNoState, setPromoNoState] = useState("");
  const [typeState, setTypeState] = useState("");

  // loading
  const {
    setCommonInfo,
    getCommonConfig,
    getCommonInfo,
    setCommonInfoFull,

    setPromoDetailPageInfo,
    getPromoDetailPageInfo,
    getHistoryRouter,
    getUserPermissions,
    setUserPermissions,
    getHotKey,
    setHotKey,
    getSalesAnalysisDefinition,
    setSalesAnalysisDefinition,
  } = useCommonInfo();
  const promoDetailPageInfo = getPromoDetailPageInfo() as any;
  let historyRouter = getHistoryRouter() as any;

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  let hotKeyInfo = getHotKey() as any;
  let commonInfo = getCommonInfo() as any;
  let salesAnalysisDefinition = getSalesAnalysisDefinition() as any;

  const getOptionLabel2 = (option: any) => option.item_no || "";
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  // permissions
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();

  // promo no
  const PROMO_NO_QUERY_STRING = "?promotionNo=";
  const [promoNo, setPromo] = useState(
    props.history.location.search.replace(PROMO_NO_QUERY_STRING, "")
  );

  // start
  const [startFlg, setStartFlg] = useState(false);
  // review
  const [reviewFlg, setReviewFlg] = useState(false);
  // payment
  const [paymentTermList, setPaymentTermList] = useState([]);
  // response
  const [responseData, setResponseData] = useState({});
  // set special price
  const [specialPrice, setSpecialPrice] = useState("");
  const [specialPriceFormat, setSpecialPriceFormat] = useState("");
  const [defaultPrice, setDefaultPrice] = useState("");
  // single special price
  const [singleSpecialPriceFormat, setSingleSpecialPriceFormat] = useState("");
  // get item api
  const [categoryM, setCategoryM] = useState([]) as any;
  const [categoryA, setCategoryA] = useState([]) as any;
  const [categoryP, setCategoryP] = useState([]) as any;
  const [categoryO, setCategoryO] = useState([]) as any;
  const [categoryAll, setCategoryAll] = useState([]) as any;
  const [categoryConsidered, setCategoryConsidered] = useState([]) as any;
  const [categoryExcluded, setCategoryExcluded] = useState([]) as any;
  const [categoryFreeGoods, setCategoryFreeGoods] = useState([]) as any;
  const [categorySingle, setCategorySingle] = useState([]) as any;
  const [categorySet, setCategorySet] = useState([]) as any;
  const [analysisList, setAnalysisList] = useState([]) as any;
  const [plannerCodeData, setPlannerCodeData] = useState([]) as any;
  const [hasSeqNoRow, setHasSeqNoRow] = useState(true);
  const [termCodeTable, setTermCodeTable] = useState(false);

  const [fromDraftsFlag, setFromDraftsFlag] = useState(false);

  const [analysisCodeDescription, setAnalysisCodeDescription] = useState("");

  const [unitPrice, setUnitPrice] = useState([0, 0]);

  const getNewPromoNo = (): any => {
    if (history.location.search) {
      const promoNo: any = /\d+/.exec(history.location.search);
      return promoNo[0];
    }
  };

  useEffect(() => {
    if (!startFlg && Object.keys(config).length > 0) {
      setStartFlg(true);
      getDefaultApiList();

      if (history.location.search.indexOf("drafts=true") > -1) {
        setFromDraftsFlag(true);
      }
    }
  }, [startFlg, config]);

  useEffect(() => {
    if (
      hotKeyInfo.handleF6RegisterPromotion &&
      !registerPromotion &&
      !onfocusFlag
    ) {
      handleRegisterPromotion();
    }
    setHotKey("handleF6RegisterPromotion", false);
  }, [hotKeyInfo.handleF6RegisterPromotion]);

  useEffect(() => {
    if (hotKeyInfo.handleF8SaveToDraft && !registerPromotion && !onfocusFlag) {
      handleSaveToDrafts();
    }
    setHotKey("handleF8SaveToDraft", false);
  }, [hotKeyInfo.handleF8SaveToDraft]);

  useEffect(() => {
    if (
      hotKeyInfo.handleF9Refresh &&
      userPermisssionInfo.csd_promotion_detail_refresh_authFlg
    ) {
      handleFresh(false);
    }
    setHotKey("handleF9Refresh", false);
  }, [hotKeyInfo.handleF9Refresh]);

  const changeTermCodeTable = (item: any, list: any = paymentTermList) => {
    const filterData = list.filter((option: any) => {
      return option.terms_cd === item;
    });
    if (filterData.length > 0) {
      setTermCodeTable(filterData[0]);
    } else {
      setTermCodeTable(false);
    }
  };

  const changeAnalysisCodeDescription = (
    item: any,
    list: any = analysisList
  ) => {
    const filterData = list.filter((option: any) => {
      return option.analysis_cd === item;
    });
    if (filterData.length > 0) {
      setAnalysisCodeDescription(filterData[0].description);
    } else {
      setAnalysisCodeDescription("");
    }
  };

  const setBaseItemMessage = (response: any) => {
    let _plannerCode: any;
    const categoryMList: any[] = [];
    const categoryAList: any[] = [];
    const categoryPList: any[] = [];
    const categoryOList: any[] = [];
    setPlannerCodeData(response);
    _plannerCode = response;
    _plannerCode.forEach((item: any) => {
      if (item.planner_cd === "M") {
        categoryMList.push(item);
      } else if (item.planner_cd === "A") {
        categoryAList.push(item);
      } else if (item.planner_cd === "P") {
        categoryPList.push(item);
      } else if (item.planner_cd === "O") {
        categoryOList.push(item);
      }
    });
    setCategoryM(categoryMList);
    setCategoryA(categoryAList);
    setCategoryP(categoryPList);
    setCategoryO(categoryOList);
    setCategoryAll(_plannerCode);
    setCategoryConsidered(_plannerCode);
    setCategoryExcluded(_plannerCode);
    setCategoryFreeGoods(_plannerCode);
    setCategorySingle(_plannerCode);
    setCategorySet(_plannerCode);
    return _plannerCode;
  };

  // set onfocusFlag
  const setOnfocusHandler = (focusFlag: boolean) => {
    setOnfocusFlag(focusFlag);
  };

  // get item List
  const getDefaultApiList = (refreshFlg: boolean = false) => {
    let apiList = [];
    let _response: any;
    let _plannerCode: any;
    let _analysisCodeList: any;
    let _paymentTermList: any;
    let localUserPermissions = {};
    let masterCustomerList: any = [];
    setErrorValueList({
      start_date_error: "",
      end_date_error: "",
      priority_error: "",
      webshop_error: "",
      analysis_cd_error: "",
      promo_name_error: "",
      promotion_customer: [
        {
          customer_no_error: "",
          customer_type_error: "",
          customer_csac1_error: "",
          customer_csac2_error: "",
          customer_csac3_error: "",
          customer_csac4_error: "",
          customer_csac5_error: "",
          customer_csac6_error: "",
          customer_csac7_error: "",
          customer_csac8_error: "",
          customer_csac9_error: "",
          customer_csac10_error: "",
          customer_promotion_region_error: "",
        },
      ],
      customer_error: "",
      customer_oneFieldRequird_error: "",
      category_considered: [
        {
          item_no_error: "",
          unit_packet_error: "",
          item_class_error: "",
          isac1_error: "",
          isac2_error: "",
          isac3_error: "",
          isac4_error: "",
          isac5_error: "",
          isac6_error: "",
          isac7_error: "",
          isac8_error: "",
        },
      ],
      category_excluded: [
        {
          item_no_error: "",
          item_class_error: "",
          isac1_error: "",
          isac2_error: "",
          isac3_error: "",
          isac4_error: "",
          isac5_error: "",
          isac6_error: "",
          isac7_error: "",
          isac8_error: "",
        },
      ],
      considered_item_error: "",
      excluded_item_error: "",
      ...{
        condition_unit_base: [
          {
            packet_error: "",
            discount_rate_error: "",
          },
        ],
        condition_amount_base: [
          {
            purchase_amount_error: "",
            discount_rate_error: "",
          },
        ],
        free_goods: [
          {
            item_no_error: "",
            quantity_error: "",
            item_class_error: "",
            isac1_error: "",
            isac2_error: "",
            isac3_error: "",
            isac4_error: "",
            isac5_error: "",
            isac6_error: "",
            isac7_error: "",
            isac8_error: "",
          },
        ],
        condition_unit_base_error: "",
        condition_amount_base_error: "",
        free_goods_item_error: "",
        condition_single: [
          {
            item_no_error: "",
            quantity_error: "",
            special_price_error: "",
          },
        ],
        condition_set: [
          {
            item_no_error: "",
            quantity_error: "",
            unit_price_error: "",
          },
          {
            item_no_error: "",
            quantity_error: "",
            unit_price_error: "",
          },
        ],
        set_item_error: "",
        // dealer discount
        dealerDiscount_discount_rate_error: "",
        set_special_price_error: "",
        // promotion code
        promotionCode_discount_rate_error: "",
        // payment term
        terms_cd_error: "",
      },
    });
    console.log("errorList", errorList);
    apiLoading(true);
    // refresh true or false
    if (!refreshFlg) {
      // item master AM0305
      if (!categoryAll || categoryAll.length === 0) {
        // get base item master list
        if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
          let api1 = api
            .getBaseItemListAPI(config)
            .then(({ response, code }: { response: any; code: any }) => {
              console.log("AM0305", response);
              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                setCommonInfo("itemBaseList", response);
                _plannerCode = setBaseItemMessage(response);
              }
            });
          apiList.push(api1);
        } else {
          _plannerCode = setBaseItemMessage(commonInfo.itemBaseList);
        }
      }
    }

    // get payment term information AM2108
    let api2 = api
      .getReadPaymentTermListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AM2108", response);
        if (!getApiResultStatus(code)) {
          if (code !== 404) {
            errorMessage(response);
          }
        } else {
          _paymentTermList = response;
          setPaymentTermList(response);
        }
      });
    apiList.push(api2);

    // get promo number
    const _promoNo = Number(getNewPromoNo());
    // update promo
    if (_promoNo) {
      // get promotion information AM2102
      let api3 = api
        .getReadPromotionAPI(config, _promoNo)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM2102 GetReadPromotionAPI", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            _response = response;
            if (
              _response.promo_type === "20" ||
              _response.promo_type === "60"
            ) {
              setDisabledCondition(true);
              if (_response) {
                // dealer discount
                if (_response.dealer_discount.length > 0) {
                  _response.dealer_discount[0].discount_rate_format =
                    priceOutputBySign(
                      _response.dealer_discount[0].discount_rate
                    );
                }
              }
            } else {
              setDisabledCondition(false);
              // locale format
              if (_response) {
                // unit base
                if (_response.condition_unit_base.length > 0) {
                  _response.condition_unit_base.forEach((item: any) => {
                    item.discount_rate_format = priceOutputBySign(
                      item.discount_rate
                    );
                  });
                }
                // amount base
                if (_response.condition_amount_base.length > 0) {
                  _response.condition_amount_base.forEach((item: any) => {
                    item.purchase_amount_format = priceOutputBySign(
                      item.purchase_amount
                    );
                    item.discount_rate_format = priceOutputBySign(
                      item.discount_rate
                    );
                  });
                }
                // single
                if (_response.condition_single.length > 0) {
                  _response.condition_single.forEach((item: any) => {
                    item.special_price_format = priceOutputBySign(
                      item.special_price
                    );
                  });
                }
                // set
                if (_response.condition_set.length > 0) {
                  _response.condition_set.forEach((item: any) => {
                    // item.special_price_format = priceOutputBySign(
                    //   item.special_price
                    // );
                    item.unit_price_format = priceOutputBySign(
                      item.special_price
                    );
                    item.unit_price = priceOutputBySign(item.special_price);
                  });
                }
                // promotion code
                if (_response.promotion_code.length > 0) {
                  _response.promotion_code[0].discount_rate_format =
                    priceOutputBySign(
                      _response.promotion_code[0].discount_rate
                    );
                }
              }
            }
          }
          // set review model
          setReviewFlg(promoDetailPageInfo.reviewFlg);
          let obj = Object.assign({}, promoDetailPageInfo, {
            reviewFlg: false,
          });
          setPromoDetailPageInfo(obj);
        });
      apiList.push(api3);
    }
    let api4 = api
      .getReadSalesAnalysisCodeListAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AM2102", response);
        if (!getApiResultStatus(code)) {
          if (code !== 404) {
            errorMessage(response);
          }
        } else {
          _analysisCodeList = response;
          setAnalysisList(response);
        }
      });
    apiList.push(api4);

    // permission
    if (!userPermissions.permission_group) {
      const api5 = getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
      apiList.push(api5);
    } else {
      localUserPermissions = userPermissions;
    }

    // sales_analysis_definition API
    if (Object.keys(salesAnalysisDefinition).length === 0) {
      const api6 = api
        .getSalesAnalysisDefinitionAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("getSalesAnalysisDefinitionAPI", response);

          if (!getApiResultStatus(code)) {
            // MOS多言語の場合は、404は正確です。
            // errorMessage(response);
            if (code !== 404) {
              errorMessage(response);
            } else {
              const obj: any = {};
              obj.customer_list = [];
              obj.item_list = [];
              setSalesAnalysisDefinition(obj);
              setSalesAnalysisList(obj);
            }
          } else {
            setSalesAnalysisDefinition(response);
            setSalesAnalysisList(response);
          }
        });
      apiList.push(api6);
    } else {
      setSalesAnalysisList(salesAnalysisDefinition);
    }

    //AM0205 customer
    if (commonInfo.customerList.length === 0) {
      const api7 = api
        .getCustomerDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("get AM0205 customer master", response);
          if (getApiResultStatus(code)) {
            masterCustomerList = response;
          } else {
            errorMessage(response);
          }
        });
      apiList.push(api7);
    } else {
      masterCustomerList = commonInfo.customerList;
    }
    Promise.all(apiList).then(() => {
      apiLoading(false);
      initStart(localUserPermissions);
      if (_response) {
        setResponseData(_response);
        init(_response, _plannerCode, _paymentTermList, _analysisCodeList);
      }
      setCommonInfoFull({
        customerList: masterCustomerList,
        loadingFlg: false,
      });
    });
  };

  const initStart = (localUserPermissions: any) => {
    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(userPermissionsInfo);
    apiLoading(false);
  };

  const init = (
    response: any,
    plannerCode: any,
    paymentTermList: any,
    analysisCodeList: any
  ) => {
    console.log("init", response);
    // clear customer selected checkbox
    setSelectedArrayCustomer([]);
    setSelectedRowArrayCustomer([]);
    setIndeterminateCustomer(false);
    setAllCheckedCustomer(false);

    // clear considered selected checkbox
    setSelectedArrayConsidered([]);
    setSelectedRowArrayConsidered([]);
    setIndeterminateConsidered(false);
    setAllCheckedConsidered(false);

    // clear excluded selected checkbox
    setSelectedArrayExcluded([]);
    setSelectedRowArrayExcluded([]);
    setIndeterminateExcluded(false);
    setAllCheckedExcluded(false);

    // clear unit base selected checkbox
    setSelectedArrayUnitBase([]);
    setSelectedRowArrayUnitBase([]);
    setIndeterminateUnitBase(false);
    setAllCheckedUnitBase(false);

    // clear amount base selected checkbox
    setSelectedArrayAmountBase([]);
    setSelectedRowArrayAmountBase([]);
    setIndeterminateAmountBase(false);
    setAllCheckedAmountBase(false);

    // clear free goods selected checkbox
    setSelectedArrayFreeGoods([]);
    setSelectedRowArrayFreeGoods([]);
    setIndeterminateFreeGoods(false);
    setAllCheckedFreeGoods(false);

    // clear set selected checkbox
    setSelectedArraySet([]);
    setSelectedRowArraySet([]);
    setIndeterminateSet(false);
    setAllCheckedSet(false);

    const _response = JSON.parse(JSON.stringify(response));

    if (_response.condition_set.length > 0) {
      const unitPriceList: any[] = [];
      _response.condition_set.forEach((item: any) => {
        if ("cost" in item) {
          unitPriceList.push(item.cost);
        } else {
          unitPriceList.push(0);
        }
      });
      setUnitPrice(unitPriceList);
    }

    const customDisabledAry = [
      "promotion_customer",
      "category_considered",
      "category_excluded",
      "free_goods",
      "condition_single",
      "condition_set",
    ];

    // add customer disabled list
    // add considered disabled list
    // add excluded disabled list
    // add free goods disabled list
    // add condition set disabled list
    customDisabledAry.forEach((item: string) => {
      const getLength: number = _response[item].length;
      if (getLength > 0) {
        const editdAry: any[] = [];
        if (item === "promotion_customer") {
          _response[item].forEach((item: any) => {
            if (item.customer_no !== "") {
              editdAry.push(true);
            } else {
              editdAry.push(false);
            }
          });
          setCustomerEditList(editdAry);
        } else {
          _response[item].forEach((item: any) => {
            if (item.item_no !== "") {
              editdAry.push(true);
            } else {
              editdAry.push(false);
            }
          });
          if (item === "category_considered") {
            setConsideredEditList(editdAry);
          } else if (item === "category_excluded") {
            setExcludedEditList(editdAry);
          } else if (item === "free_goods") {
            setFreeGoodsEditList(editdAry);
          }
        }
      }
    });

    if (
      "category_considered" in _response &&
      _response.category_considered.length > 0
    ) {
      const categoryConsidered = JSON.parse(
        JSON.stringify(_response.category_considered)
      );
      categoryConsidered.forEach((item: any) => {
        if (_response.promo_type !== "10") {
          item.unit_packet = item.quantity;
        }
        delete item.quantity;
      });
      _response.category_considered = categoryConsidered;
    }

    // add index in Array
    for (let key in _response) {
      if (Array.isArray(_response[key])) {
        if (_response[key].length > 0) {
          _response[key].map((item: any, index: number) => {
            item.index = index;
            return item;
          });
        }

        if (key === "promotion_customer") {
          if (_response[key].length === 0) {
            _response[key] = defaultValue.promotion_customer;
          }
        }

        if (
          _response.promo_type === "10" ||
          _response.promo_type === "20" ||
          _response.promo_type === "30" ||
          _response.promo_type === "50"
        ) {
          if (key === "category_considered") {
            if (_response[key].length === 0) {
              _response[key] = defaultValue.category_considered;
            }
          }

          if (key === "category_excluded") {
            if (_response[key].length === 0) {
              _response[key] = defaultValue.category_excluded;
            }
          }
        }

        if (_response.promo_type === "10") {
          if (
            _response["condition_unit_base"].length === 0 &&
            _response["condition_amount_base"].length > 0
          ) {
            _response.unitBase_or_amountBase = "2";
            _response["condition_unit_base"] =
              defaultDiscountValue.condition_unit_base;
            _response["condition_amount_base"].forEach((item: any) => {
              item.purchase_amount = String(Number(item.purchase_amount));
            });
          }

          if (
            _response["condition_amount_base"].length === 0 &&
            _response["condition_unit_base"].length > 0
          ) {
            _response.unitBase_or_amountBase = "1";
            _response["condition_amount_base"] =
              defaultDiscountValue.condition_amount_base;
          }

          if (
            _response["condition_unit_base"].length === 0 &&
            _response["condition_amount_base"].length === 0
          ) {
            _response.unitBase_or_amountBase = "1";
            _response["condition_unit_base"] =
              defaultDiscountValue.condition_unit_base;
            _response["condition_amount_base"] =
              defaultDiscountValue.condition_amount_base;
          }
        }

        if (_response.promo_type === "20") {
          if (_response["dealer_discount"].length === 0) {
            _response["dealer_discount"] =
              defaultDealerDiscountValue.dealer_discount;
          }
        }

        if (_response.promo_type === "30") {
          if (_response["free_goods"].length === 0) {
            _response["free_goods"] = defaultFreeGoodsValue.free_goods;
          }
        }

        if (_response.promo_type === "40") {
          if (
            _response["condition_single"].length === 0 &&
            _response["condition_set"].length > 0
          ) {
            setSingleSpecialPriceFormat("");

            _response.single_or_set = "2";
            _response["condition_single"] =
              defaultSpecialPriceValue.condition_single;

            if (_response["condition_set"].length === 1) {
              _response["condition_set"].push(
                defaultSpecialPriceValue.condition_set[1]
              );
            }
            console.log("init _response", _response);
            if (_response["condition_set"][0]["special_price"] === "") {
              setSpecialPrice("");
              setSpecialPriceFormat("");
              setDefaultPrice("");
            } else {
              setSpecialPrice(
                String(Number(_response["condition_set"][0]["special_price"]))
              );
              setSpecialPriceFormat(
                _response["condition_set"][0]["special_price_format"]
              );
              setDefaultPrice(
                String(Number(_response["condition_set"][0]["special_price"]))
              );
            }
          }

          if (
            _response["condition_set"].length === 0 &&
            _response["condition_single"].length > 0
          ) {
            setSpecialPrice("");
            setSpecialPriceFormat("");
            setDefaultPrice("");
            _response.single_or_set = "1";
            _response["condition_set"] = defaultSpecialPriceValue.condition_set;
            if (_response["condition_single"][0]["single_type"] === "") {
              _response["condition_single"][0]["single_type"] = "1";
            }
            if (_response["condition_single"][0].special_price === "") {
              _response["condition_single"][0].special_price = "";
              setSingleSpecialPriceFormat("");
            } else {
              _response["condition_single"][0].special_price = String(
                Number(_response["condition_single"][0].special_price)
              );
              setSingleSpecialPriceFormat(
                String(Number(_response["condition_single"][0].special_price))
              );
            }
          }

          if (
            _response["condition_single"].length === 0 &&
            _response["condition_set"].length === 0
          ) {
            _response.single_or_set = "1";
            _response["condition_single"] =
              defaultSpecialPriceValue.condition_single;
            _response["condition_set"] = defaultSpecialPriceValue.condition_set;
          }
        }

        if (_response.promo_type === "50") {
          if (_response["promotion_code"].length === 0) {
            _response["promotion_code"] =
              defaultPromotionCodeValue.promotion_code;
          } else {
            if (_response["promotion_code"][0]["promo_code_type"] === "") {
              _response["promotion_code"][0]["promo_code_type"] = "1";
            }
          }
        }

        if (_response.promo_type === "60") {
          changeTermCodeTable(_response.terms_cd, paymentTermList);
        }
      }
    }

    _response.planner_code_considered = "";
    _response.planner_code_excluded = "";
    _response.planner_code_free_goods = "";
    _response.planner_code_single = "";
    _response.planner_code_set = "";

    if (_response.start_date !== "" && _response.start_date !== null) {
      if (_response.start_date.indexOf("0001") > -1) {
        _response.start_date = null;
      } else {
        _response.start_date = Date.parse(_response.start_date);
      }
    }

    if (_response.end_date !== "" && _response.end_date !== null) {
      if (_response.end_date.indexOf("9999") > -1) {
        _response.end_date = null;
        _response.limit_type = "1";
      } else {
        _response.end_date = Date.parse(_response.end_date);
        _response.limit_type = "0";
      }
    } else {
      _response.limit_type = "1";
    }

    if (_response.allow_overlap !== "1") {
      _response.allow_overlap = "0";
    }

    if (_response.exclusive_flag !== "1") {
      _response.exclusive_flag = "0";
    }

    if (_response.webshop_flag !== "1") {
      _response.webshop_flag = "0";
    }

    if (_response.others_flag !== "1") {
      _response.others_flag = "0";
    }

    const customErrorAry = [
      "promotion_customer",
      "category_considered",
      "category_excluded",
      "condition_unit_base",
      "condition_amount_base",
      "free_goods",
      "condition_set",
    ];

    // add customer error list
    // add considered error list
    // add excluded error list
    // add unit base error list
    // add amount base error list
    // add free goods error list
    // add condition set error list
    const _errorList = JSON.parse(JSON.stringify(errorValueList));

    customErrorAry.forEach((item: string) => {
      if (item in _response) {
        const getLen: number = _response[item].length;
        let getRow: any = {};
        if (item === "promotion_customer") {
          getRow = _errorList.promotion_customer[0];
        } else if (item === "category_considered") {
          getRow = _errorList.category_considered[0];
        } else if (item === "category_excluded") {
          getRow = _errorList.category_excluded[0];
        } else if (item === "condition_unit_base") {
          getRow = _errorList.condition_unit_base[0];
        } else if (item === "condition_amount_base") {
          getRow = _errorList.condition_amount_base[0];
        } else if (item === "free_goods") {
          getRow = _errorList.free_goods[0];
        } else if (item === "condition_set") {
          getRow = _errorList.condition_set[0];
        }

        for (let key in getRow) {
          getRow[key] = "";
        }

        const errorList = [];
        for (let i = 0; i < getLen; i++) {
          errorList.push({ ...getRow });
        }
        if (item === "promotion_customer") {
          _errorList.promotion_customer = errorList;
        } else if (item === "category_considered") {
          _errorList.category_considered = errorList;
        } else if (item === "category_excluded") {
          _errorList.category_excluded = errorList;
        } else if (item === "condition_unit_base") {
          _errorList.condition_unit_base = errorList;
        } else if (item === "condition_amount_base") {
          _errorList.condition_amount_base = errorList;
        } else if (item === "free_goods") {
          _errorList.free_goods = errorList;
        } else if (item === "condition_set") {
          _errorList.condition_set = errorList;
        }
      }
    });

    _errorList.start_date_error = "";
    _errorList.end_date_error = "";
    _errorList.priority_error = "";
    _errorList.webshop_error = "";
    _errorList.analysis_cd_error = "";
    _errorList.promo_name_error = "";

    _errorList.customer_error = "";
    _errorList.customer_oneFieldRequird_error = "";
    _errorList.considered_item_error = "";
    _errorList.excluded_item_error = "";
    _errorList.condition_unit_base_error = "";
    _errorList.condition_amount_base_error = "";
    _errorList.free_goods_item_error = "";
    _errorList.set_item_error = "";
    _errorList.set_special_price_error = "";
    _errorList.dealerDiscount_discount_rate_error = "";
    _errorList.promotionCode_discount_rate_error = "";
    _errorList.terms_cd_error = "";

    // set Analysis Code Description
    if (
      _response.analysis_cd !== "" &&
      analysisCodeList &&
      analysisCodeList.length > 0
    ) {
      let _itemList = analysisCodeList.filter(
        (item: any) => item.analysis_cd === _response.analysis_cd
      );

      if (_itemList.length > 0) {
        setAnalysisCodeDescription(_itemList[0].description);
      }
    }

    setErrorValueList(_errorList);
    setSearchValueList(_response);
    setConditionsSearchValueList(_response);
    setDefaultValueList(JSON.parse(JSON.stringify(_response)));

    setOldValue(JSON.parse(JSON.stringify(_response)));
    setEditValueList(_response);
  };

  const setOldData = (property: any, value: any) => {
    let valueList = Object.assign({}, oldValue);

    valueList = Object.assign({}, valueList, { [property]: value });
    setOldValue(valueList);
  };

  const setSearchValue = (property: any, value: any, flag?: boolean) => {
    let valueList = Object.assign({}, searchValueList);

    if (property === "promo_type") {
      // clear considered selected checkbox
      setSelectedArrayConsidered([]);
      setSelectedRowArrayConsidered([]);
      setIndeterminateConsidered(false);
      setAllCheckedConsidered(false);

      // clear excluded selected checkbox
      setSelectedArrayExcluded([]);
      setSelectedRowArrayExcluded([]);
      setIndeterminateExcluded(false);
      setAllCheckedExcluded(false);

      // clear unit base selected checkbox
      setSelectedArrayUnitBase([]);
      setSelectedRowArrayUnitBase([]);
      setIndeterminateUnitBase(false);
      setAllCheckedUnitBase(false);

      // clear amount base selected checkbox
      setSelectedArrayAmountBase([]);
      setSelectedRowArrayAmountBase([]);
      setIndeterminateAmountBase(false);
      setAllCheckedAmountBase(false);

      // clear free goods selected checkbox
      setSelectedArrayFreeGoods([]);
      setSelectedRowArrayFreeGoods([]);
      setIndeterminateFreeGoods(false);
      setAllCheckedFreeGoods(false);

      // clear set selected checkbox
      setSelectedArraySet([]);
      setSelectedRowArraySet([]);
      setIndeterminateSet(false);
      setAllCheckedSet(false);

      setPromotionTypeValue(value);
      const changedPropertyFlag = flag ? flag : hasChangedProperty();

      if (changedPropertyFlag) {
        setConsideredEditList([false]);
        setExcludedEditList([false]);
        setFreeGoodsEditList([false]);

        const customerErrorValueList = JSON.parse(
          JSON.stringify(errorValueList.promotion_customer)
        );
        customerErrorValueList.forEach((item: any) => {
          item.customer_no_error = "";
        });

        let setErrorList = Object.assign(
          {},
          errorValueList,
          JSON.parse(JSON.stringify(errorListPromotionType)),
          { promotion_customer: customerErrorValueList },
          { customer_error: "" },
          { customer_oneFieldRequird_error: "" }
        );

        setErrorList = Object.assign(
          {},
          setErrorList,
          { promotion_customer: customerErrorValueList },
          { customer_error: "" },
          { customer_oneFieldRequird_error: "" }
        );

        // if (value === "10" || value === "30" || value === "50") {
        if (
          value === "10" ||
          value === "20" ||
          value === "30" ||
          value === "50"
        ) {
          setErrorList = Object.assign(
            {},
            setErrorList,
            JSON.parse(JSON.stringify(errorListCategory))
          );
        }

        setSpecialPrice("");
        setSpecialPriceFormat("");
        setSingleSpecialPriceFormat("");
        if (
          "condition_unit_base" in valueList ||
          "condition_amount_base" in valueList ||
          "unitBase_or_amountBase" in valueList ||
          "dealer_discount" in valueList ||
          "free_goods" in valueList ||
          "condition_single" in valueList ||
          "condition_set" in valueList ||
          "single_or_set" in valueList ||
          "promotion_code" in valueList ||
          "terms_cd" in valueList ||
          "planner_code_free_goods" in valueList ||
          "planner_code_single" in valueList ||
          "planner_code_set" in valueList
        ) {
          delete valueList["condition_unit_base"];
          delete valueList["condition_amount_base"];
          delete valueList["unitBase_or_amountBase"];
          delete valueList["dealer_discount"];
          delete valueList["free_goods"];
          delete valueList["condition_single"];
          delete valueList["condition_set"];
          delete valueList["single_or_set"];
          delete valueList["promotion_code"];
          delete valueList["terms_cd"];
          delete valueList["planner_code_free_goods"];
          delete valueList["planner_code_single"];
          delete valueList["planner_code_set"];
        }

        // if (value === "20" || value === "40" || value === "60") {
        if (value === "40" || value === "60") {
          if (
            "category_considered" in valueList ||
            "category_excluded" in valueList ||
            "planner_code_considered" in valueList ||
            "planner_code_excluded" in valueList
          ) {
            delete valueList["category_considered"];
            delete valueList["category_excluded"];
            delete valueList["planner_code_considered"];
            delete valueList["planner_code_excluded"];

            // clear considered selected checkbox
            setSelectedArrayConsidered([]);
            setSelectedRowArrayConsidered([]);
            setIndeterminateConsidered(false);
            setAllCheckedConsidered(false);

            // clear excluded selected checkbox
            setSelectedArrayExcluded([]);
            setSelectedRowArrayExcluded([]);
            setIndeterminateExcluded(false);
            setAllCheckedExcluded(false);
          }
        }

        // if (value === "10" || value === "30" || value === "50") {
        if (
          value === "10" ||
          value === "20" ||
          value === "30" ||
          value === "50"
        ) {
          valueList = Object.assign(
            {},
            valueList,
            JSON.parse(JSON.stringify(defaultCategoryValue))
          );
        }

        let getDefault = "";
        if (value === "20" || value === "60") {
          setDisabledCondition(true);
          if (value === "20") {
            getDefault = JSON.parse(JSON.stringify(defaultDealerDiscountValue));
          } else if (value === "60") {
            getDefault = JSON.parse(JSON.stringify(defaultTermValue));
          }
          valueList.priority = "";

          setErrorList = Object.assign({}, setErrorList, {
            priority_error: "",
          });
        } else {
          setDisabledCondition(false);
          if (value === "10" || value === "40" || value === "50") {
            if (value === "10") {
              getDefault = JSON.parse(JSON.stringify(defaultDiscountValue));
            } else if (value === "40") {
              getDefault = JSON.parse(JSON.stringify(defaultSpecialPriceValue));
            } else if (value === "50") {
              getDefault = JSON.parse(
                JSON.stringify(defaultPromotionCodeValue)
              );
            }
          } else if (value === "30") {
            getDefault = JSON.parse(JSON.stringify(defaultFreeGoodsValue));
          }
        }

        if (value !== "50") {
          valueList.allow_overlap = "0";
        } else {
          valueList.allow_overlap = "1";
        }
        valueList.exclusive_flag = "0";
        valueList.webshop_flag = "1";
        valueList.others_flag = "1";

        valueList = Object.assign(
          {},
          valueList,
          { [property]: value },
          getDefault
        );

        const defaultObj = {
          promo_name: "",
          promo_type: "10",
          priority: "",
          analysis_cd: "",
          allow_overlap: "0",
          exclusive_flag: "0",
          webshop_flag: "1",
          others_flag: "1",
          start_date: null,
          end_date: null,
          limit_type: "0",
        };
        const defaultValue = JSON.parse(JSON.stringify(valueList));
        if ("promotion_customer" in defaultValue) {
          defaultValue["promotion_customer"].forEach((item: any) => {
            for (let key in item) {
              if (key !== "index") {
                item[key] = "";
              }
            }
          });
        }

        const defaultList = Object.assign({}, defaultValue, defaultObj);
        setDefaultValueList(JSON.parse(JSON.stringify(defaultList)));

        setErrorValueList(setErrorList);
        setConditionsSearchValueList(getDefault);

        setOldValue(JSON.parse(JSON.stringify(valueList)));
      } else {
        setModalOpen(true);
      }
    } else if (
      property === "category_considered" ||
      property === "category_excluded" ||
      property === "condition_unit_base" ||
      property === "condition_amount_base" ||
      property === "unitBase_or_amountBase" ||
      property === "dealer_discount" ||
      property === "free_goods" ||
      property === "condition_single" ||
      property === "condition_set" ||
      property === "single_or_set" ||
      property === "promotion_code" ||
      property === "terms_cd" ||
      property === "planner_code_considered" ||
      property === "planner_code_excluded" ||
      property === "planner_code_free_goods" ||
      property === "planner_code_single" ||
      property === "planner_code_set"
    ) {
      valueList = Object.assign({}, valueList, { [property]: value });
      setConditionsSearchValueList(
        Object.assign({}, conditionsSearchValueList, { [property]: value })
      );
    } else {
      if (property === "end_date" && value !== null) {
        valueList = Object.assign(
          {},
          valueList,
          { [property]: value },
          { limit_type: "0" }
        );
      } else if (property === "limit_type" && value === "1") {
        valueList = Object.assign(
          {},
          valueList,
          { [property]: value },
          { end_date: null }
        );
        let errorList = Object.assign({}, errorValueList, {
          start_date_error: "",
        });
        setErrorValueList(errorList);
      } else {
        valueList = Object.assign({}, valueList, { [property]: value });
      }
    }

    setSearchValueList(valueList);
  };

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = getName("messages." + response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  // warning message
  // const warningMessage = (message_id: any, key: any = "") => {
  //   setAlertShow(true);
  //   const message = getName("messages." + message_id, {
  //     param1: t(key),
  //   });
  //   setAlertMessage(message);
  //   setAlertType("warning");
  // };

  // call this method when add or delete row
  const setDefaultValue = (property: any, value: any) => {
    let defaultList = Object.assign({}, defaultValueList, {
      [property]: value,
    });
    setDefaultValueList(JSON.parse(JSON.stringify(defaultList)));
  };

  const setErrorValue = (property: any, value: any) => {
    const errorList = Object.assign({}, errorValueList, { [property]: value });
    setErrorValueList(errorList);
  };

  useEffect(() => {
    if (createPromotionFlag) {
      const searchValue = JSON.parse(JSON.stringify(searchValueList));
      const defaultValue = JSON.parse(JSON.stringify(defaultValueList));

      delete searchValue["planner_code_considered"];
      delete searchValue["planner_code_excluded"];
      delete searchValue["planner_code_free_goods"];
      delete searchValue["planner_code_single"];
      delete searchValue["planner_code_set"];
      delete searchValue["limit_type"];

      delete defaultValue["planner_code_considered"];
      delete defaultValue["planner_code_excluded"];
      delete defaultValue["planner_code_free_goods"];
      delete defaultValue["planner_code_single"];
      delete defaultValue["planner_code_set"];
      delete defaultValue["limit_type"];

      if (
        searchValue.unitBase_or_amountBase ===
        defaultValue.unitBase_or_amountBase
      ) {
        if (searchValue.unitBase_or_amountBase === "1") {
          delete searchValue["condition_amount_base"];
          delete defaultValue["condition_amount_base"];
        } else if (searchValue.unitBase_or_amountBase === "2") {
          delete searchValue["condition_unit_base"];
          delete defaultValue["condition_unit_base"];
        }
      }

      if (searchValue.single_or_set === defaultValue.single_or_set) {
        if (searchValue.single_or_set === "1") {
          delete searchValue["condition_set"];
          delete defaultValue["condition_set"];
        } else if (searchValue.single_or_set === "2") {
          delete searchValue["condition_single"];
          delete defaultValue["condition_single"];
        }
      }
      if (
        JSON.stringify(searchValue) === JSON.stringify(defaultValue) &&
        Number(specialPrice) === Number(defaultPrice) &&
        hasSeqNoRow
      ) {
        setSaveToDraftsButtonFlag(true);
        if (fromDraftsFlag) {
          setRegisterPromotion(false);
        } else {
          setRegisterPromotion(true);
        }
      } else {
        setSaveToDraftsButtonFlag(false);
        setRegisterPromotion(false);
      }
    } else {
      if (
        JSON.stringify(searchValueList) === JSON.stringify(defaultValueList)
      ) {
        setSaveToDraftsButtonFlag(true);
        setRegisterPromotion(true);
      } else {
        setSaveToDraftsButtonFlag(false);
        setRegisterPromotion(false);
      }
    }
  }, [
    searchValueList,
    defaultValueList,
    hasSeqNoRow,
    createPromotionFlag,
    fromDraftsFlag,
    specialPrice,
  ]);

  const hasChangedProperty = () => {
    const conditionsSearchValue = JSON.parse(
      JSON.stringify(conditionsSearchValueList)
    );

    const propertyList: any[] = [
      "category_considered",
      "category_excluded",
      "condition_unit_base",
      "condition_amount_base",
      "condition_single",
      "condition_set",
      "dealer_discount",
      "promotion_code",
      "free_goods",
      "term",
    ];
    const changedList: any = {};
    const changedResult: any = {};
    const conditionsList: any[] = [];

    propertyList.forEach((property) => {
      if (Array.isArray(conditionsSearchValue[property])) {
        const propertyArray: any[] = [];
        conditionsSearchValue[property].forEach((item: any) => {
          for (let key in item) {
            if (
              key !== "index" &&
              key !== "seq_no" &&
              key !== "updated_date" &&
              key !== "data_type" &&
              key !== "promo_code" &&
              key !== "list_price" &&
              key !== "cost"
            ) {
              if (key === "single_type" || key === "promo_code_type") {
                if (item[key] !== "1") {
                  propertyArray.push(key);
                }
              } else {
                if (item[key] !== "") {
                  propertyArray.push(key);
                }
              }
            }
          }
        });
        if (propertyArray.length > 0) {
          changedList[property] = propertyArray;
        }
      } else if (property === "term") {
        if (
          "terms_cd" in conditionsSearchValue &&
          conditionsSearchValue["terms_cd"] !== ""
        ) {
          changedList[property] = ["terms_cd"];
        }
      }
    });

    if (specialPrice != "") {
      if (!("condition_set" in changedList)) {
        changedList["condition_set"] = [];
      }
      changedList["condition_set"].push("special_price");
    }

    for (let key in changedList) {
      if (
        key === "condition_unit_base" ||
        key === "condition_amount_base" ||
        key === "condition_single" ||
        key === "condition_set" ||
        key === "dealer_discount" ||
        key === "promotion_code"
      ) {
        conditionsList.push(changedList[key]);
        changedResult["conditions"] = Array.from(
          new Set(conditionsList.flat())
        );
      } else if (key === "category_considered") {
        changedResult["considered"] = Array.from(new Set(changedList[key]));
      } else if (key === "category_excluded") {
        changedResult["excluded"] = Array.from(new Set(changedList[key]));
      } else if (key === "free_goods") {
        changedResult["free_goods"] = Array.from(new Set(changedList[key]));
      } else {
        changedResult[key] = Array.from(new Set(changedList[key]));
      }
    }

    if ("considered" in changedResult) {
      const index = changedResult.considered.indexOf("unit_packet");
      if (index > -1) {
        if (searchValueList.promo_type === "10") {
          changedResult.considered.splice(index, 1, "units_per_packet");
        } else {
          changedResult.considered.splice(index, 1, "quantity");
        }
      }
    }

    setProperty(changedResult);
    return Object.keys(changedResult).length > 0 ? false : true;
  };

  const checkValication = (type: any) => {
    let _list: any = [];
    let _error: any = [];
    let _errorValue: any = {};
    let _packetName = "";
    let _discountRateName = "";
    if (type === "unitBase_packet") {
      _list = searchValueList["condition_unit_base"];
      _error = JSON.parse(JSON.stringify(errorValueList.condition_unit_base));
      _packetName = "packet";
      _discountRateName = "discount_rate";
    } else if (type === "amountBase_purchase_amount") {
      _list = searchValueList["condition_amount_base"];
      _error = JSON.parse(JSON.stringify(errorValueList.condition_amount_base));
      _packetName = "purchase_amount";
      _discountRateName = "discount_rate";
    }

    let _lastIndex = -1;
    for (let i = _list.length - 1; i >= 0; i--) {
      let _item = _list[i];
      if (_item[_packetName] !== "" || _item[_discountRateName] !== "") {
        _lastIndex = i;
        break;
      }
    }

    if (_lastIndex >= 0) {
      _list.forEach((_item: any, index: any) => {
        let _packetMessageNumber = 0;
        let _discountRateMessageNumber = 0;

        if (index <= _lastIndex) {
          // unitBase_packet
          if (Number(_item[_packetName]) === 0) {
            _packetMessageNumber = 1;
          } else {
            if (Number(_item[_packetName]) > 2147483647) {
              _packetMessageNumber = 2;
            }

            if (index > 0 && !_packetMessageNumber) {
              let _flg = false;
              for (let i = index - 1; i >= 0; i--) {
                if (_list[i][_packetName] !== "") {
                  if (
                    Number(_item[_packetName]) <= Number(_list[i][_packetName])
                  ) {
                    _packetMessageNumber = 3;
                    _flg = true;
                  }
                  if (_flg) {
                    break;
                  }
                }
              }
            }
          }

          if (_item[_discountRateName] === "") {
            _discountRateMessageNumber = 1;
          } else {
            if (
              priceInputBySign(_item[_discountRateName]) > 99 ||
              priceInputBySign(_item[_discountRateName]) < 1
            ) {
              _discountRateMessageNumber = 2;
            }
          }
        } else {
          _packetMessageNumber = 0;
          _discountRateMessageNumber = 0;
        }

        let _message1 = "";
        let _message2 = "";
        switch (_packetMessageNumber) {
          case 1:
            _message1 = getName("messages.MS0001", {
              param1:
                type === "unitBase_packet" ? t("packet") : t("purchase_amount"),
            });
            break;
          case 2:
            _message1 = getName("messages.MS0083");
            break;
          case 3:
            _message1 = getName("messages.MS0086");
            break;
        }
        switch (_discountRateMessageNumber) {
          case 1:
            _message2 = getName("messages.MS0001", {
              param1: t("discount_rate"),
            });
            break;
          case 2:
            _message2 = getName("messages.MS0045");
            break;
        }

        if (type === "unitBase_packet") {
          _error[index].packet_error = _message1;
          _error[index].discount_rate_error = _message2;
        } else if (type === "amountBase_purchase_amount") {
          _error[index].purchase_amount_error = _message1;
          _error[index].discount_rate_error = _message2;
        }
      });
    }

    if (type === "unitBase_packet") {
      _errorValue = {
        condition_unit_base: _error,
      };
    } else if (type === "amountBase_purchase_amount") {
      _errorValue = {
        condition_amount_base: _error,
      };
    }

    const _errorValueList = Object.assign({}, errorValueList, _errorValue);
    setErrorValueList(_errorValueList);
  };

  const getSign = (config: any) => {
    return getSignByCSV(config.affiliate_cd);
  };

  const changeDataListen = (name: string, value: any, index: number) => {
    const splitsymbol = getSign(config);

    if (name === "priority") {
      if (value !== "") {
        setErrorValue("priority_error", "");
      }
    } else if (name === "webshop_flag" || name === "others_flag") {
      if (value !== "") {
        setErrorValue("webshop_error", "");
      }
    } else if (name === "start_date") {
      if (value !== "") {
        setErrorValue("start_date_error", "");
      }
    } else if (name === "promo_name") {
      let promo_name_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        promo_name_error = getName("messages.MS0045");
      }
      setErrorValue("promo_name_error", promo_name_error);
    } else if (name === "customer_customer_type") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_type_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_type_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac1") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac1_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac1_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac2") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac2_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac2_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac3") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac3_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac3_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac4") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac4_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac4_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac5") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac5_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac5_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac6") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac6_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac6_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac7") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac7_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac7_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac8") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac8_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac8_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac9") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac9_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac9_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_csac10") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_csac10_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_csac10_error = getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "customer_promotion_region") {
      const errorValue = errorValueList.promotion_customer;
      errorValue[index].customer_promotion_region_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].customer_promotion_region_error =
          getName("messages.MS0045");
      }
      setErrorValue("promotion_customer", errorValue);
    } else if (name === "category_considered_item_class") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].item_class_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].item_class_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac1") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac1_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac1_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac2") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac2_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac2_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac3") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac3_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac3_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac4") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac4_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac4_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac5") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac5_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac5_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac6") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac6_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac6_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac7") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac7_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac7_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_considered_isac8") {
      const errorValue = errorValueList.category_considered;
      errorValue[index].isac8_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac8_error = getName("messages.MS0045");
      }
      setErrorValue("category_considered", errorValue);
    } else if (name === "category_excluded_item_class") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].item_class_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].item_class_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac1") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac1_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac1_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac2") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac2_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac2_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac3") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac3_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac3_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac4") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac4_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac4_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac5") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac5_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac5_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac6") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac6_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac6_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac7") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac7_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac7_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "category_excluded_isac8") {
      const errorValue = errorValueList.category_excluded;
      errorValue[index].isac8_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac8_error = getName("messages.MS0045");
      }
      setErrorValue("category_excluded", errorValue);
    } else if (name === "free_goods_item_class") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].item_class_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].item_class_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac1") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac1_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac1_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac2") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac2_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac2_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac3") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac3_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac3_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac4") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac4_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac4_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac5") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac5_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac5_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac6") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac6_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac6_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac7") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac7_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac7_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "free_goods_isac8") {
      const errorValue = errorValueList.free_goods;
      errorValue[index].isac8_error = "";
      if (!checkSplitSymbol(value, splitsymbol)) {
        errorValue[index].isac8_error = getName("messages.MS0045");
      }
      setErrorValue("free_goods", errorValue);
    } else if (name === "unitBase_or_amountBase") {
      const searchValue = JSON.parse(JSON.stringify(searchValueList));
      let obj: any;
      let error: any;
      if (searchValue.unitBase_or_amountBase === "1") {
        obj = {
          condition_unit_base: defaultDiscountValue.condition_unit_base,
          promo_sub_type: 5,
        };
        error = {
          condition_unit_base: [{ packet_error: "", discount_rate_error: "" }],
        };
      } else if (searchValue.unitBase_or_amountBase === "2") {
        obj = {
          condition_amount_base: defaultDiscountValue.condition_amount_base,
          promo_sub_type: 4,
        };
        error = {
          condition_amount_base: [
            { purchase_amount_error: "", discount_rate_error: "" },
          ],
        };
      }
      setSearchValueList((prevState: any) => {
        return Object.assign({}, prevState, obj);
      });
      setErrorValueList((prevState: any) => {
        return Object.assign({}, prevState, error);
      });
    } else if (name === "unitBase_packet") {
      if ("condition_unit_base" in searchValueList) {
        checkValication("unitBase_packet");
      }
    } else if (name === "unitBase_discount_rate") {
      if ("condition_unit_base" in searchValueList) {
        checkValication("unitBase_packet");
      }
    } else if (name === "amountBase_purchase_amount") {
      if ("condition_amount_base" in searchValueList) {
        checkValication("amountBase_purchase_amount");
      }
    } else if (name === "amountBase_discount_rate") {
      if ("condition_amount_base" in searchValueList) {
        checkValication("amountBase_purchase_amount");
      }
    } else if (name === "quantity") {
      if ("free_goods" in searchValueList) {
        const freeGoodsObj: any = searchValueList["free_goods"];
        const errorObj = errorValueList.free_goods;
        if (freeGoodsObj[index]["quantity"] !== "") {
          errorObj[index].quantity_error = "";
        }
        setErrorValueList(
          Object.assign({}, errorValueList, { free_goods: errorObj })
        );
      }
    } else if (name === "dealerDiscount_discount_rate") {
      if (
        !isEmptyCheck(value) &&
        (priceInputBySign(value) > 99 || priceInputBySign(value) < 1)
      ) {
        let message = getName("messages.MS0045");
        setErrorValue("dealerDiscount_discount_rate_error", message);
      } else {
        setErrorValue("dealerDiscount_discount_rate_error", "");
      }
    } else if (name === "terms_cd") {
      if (!isEmptyCheck(value)) {
        setErrorValue("terms_cd_error", "");
      }
    } else if (name === "promotionCode_discount_rate") {
      if (
        !isEmptyCheck(value) &&
        (priceInputBySign(value) > 99 || priceInputBySign(value) < 1)
      ) {
        let message = getName("messages.MS0045");
        setErrorValue("promotionCode_discount_rate_error", message);
      } else {
        setErrorValue("promotionCode_discount_rate_error", "");
      }
    } else if (name === "single_or_set") {
      let errorValue = Object.assign({}, errorValueList);
      const propertyArray: any[] = [];
      let obj: any;

      if (value === "1") {
        const propertyObj = {
          item_no_error: "",
          quantity_error: "",
          unit_price_error: "",
        };
        errorValue.condition_set.forEach((item: any) => {
          propertyArray.push(propertyObj);
        });
        errorValue.set_special_price_error = "";
        errorValue.set_item_error = "";
        errorValue = Object.assign({}, errorValue, {
          condition_set: propertyArray,
        });
        obj = {
          promo_sub_type: 6,
        };
        setSearchValueList((prevState: any) => {
          return Object.assign({}, prevState, obj);
        });
      } else if (value === "2") {
        const propertyObj = [
          {
            item_no_error: "",
            quantity_error: "",
            special_price_error: "",
          },
        ];
        errorValue = Object.assign({}, errorValue, {
          condition_single: propertyObj,
        });
      }
      setErrorValueList(errorValue);
      obj = {
        promo_sub_type: 7,
      };
      setSearchValueList((prevState: any) => {
        return Object.assign({}, prevState, obj);
      });
    } else if (name === "single_quantity") {
      if ("condition_single" in searchValueList) {
        const singleObj: any = searchValueList["condition_single"];
        const errorObj = errorValueList.condition_single;
        if (singleObj[index]["quantity"] !== "") {
          errorObj[index].quantity_error = "";
        }
        setErrorValueList(
          Object.assign({}, errorValueList, {
            condition_single: errorObj,
          })
        );
      }
    } else if (name === "units_per_packet") {
      const consideredObj: any = searchValueList["category_considered"];
      const errorObj = errorValueList.category_considered;
      if (consideredObj[index]["unit_packet"] !== "") {
        errorObj[index].unit_packet_error = "";
      }
      setErrorValueList(
        Object.assign({}, errorValueList, {
          category_considered: errorObj,
        })
      );
    } else if (name === "single_special_price") {
      if ("condition_single" in searchValueList) {
        const singleObj: any = searchValueList["condition_single"];
        const errorObj = errorValueList.condition_single;
        if (singleObj[index]["special_price"] !== "") {
          errorObj[index].special_price_error = "";
        }
        setErrorValueList(
          Object.assign({}, errorValueList, {
            condition_single: errorObj,
          })
        );
      }
    } else if (name === "set_quantity") {
      if ("condition_set" in searchValueList) {
        const setObj: any = searchValueList["condition_set"];
        const errorObj = errorValueList.condition_set;
        if (setObj[index]["quantity"] !== "") {
          errorObj[index].quantity_error = "";
        }
        setErrorValueList(
          Object.assign({}, errorValueList, { condition_set: errorObj })
        );
      }
    } else if (name === "set_special_price") {
      if (!isEmptyCheck(value)) {
        setErrorValue("set_special_price_error", "");
      }
    } else if (name === "set_unit_price") {
      if ("condition_set" in searchValueList) {
        const setObj: any = searchValueList["condition_set"];
        const errorObj = errorValueList.condition_set;

        if (setObj[index] && setObj[index]["unit_price"] !== "") {
          errorObj[index].unit_price_error = "";
        }
        setErrorValueList(
          Object.assign({}, errorValueList, { condition_set: errorObj })
        );
      }
    }
  };

  // execute all validations
  const handleRegisterPromotion = () => {
    console.log("handleRegisterPromotion");

    // error message
    let errorValue = errorValueList;
    // priority
    if (searchValueList.priority === "" && !disabledCondition) {
      let message = getName("messages.MS0046", {
        param1: t("priority"),
      });
      errorValue = Object.assign({}, errorValue, { priority_error: message });
      setErrorValueList(errorValue);
    } else {
      errorValue = Object.assign({}, errorValue, { priority_error: "" });
      setErrorValueList(errorValue);
    }

    // Applied For Webshop or others
    if (
      searchValueList.webshop_flag === "0" &&
      searchValueList.others_flag === "0"
    ) {
      let message = getName("messages.MS0046", {
        param1: t("webshop_or_others"),
      });
      errorValue = Object.assign({}, errorValue, { webshop_error: message });
      setErrorValueList(errorValue);
    } else {
      errorValue = Object.assign({}, errorValue, { webshop_error: "" });
      setErrorValueList(errorValue);
    }

    // start_date
    if (
      searchValueList.start_date === null ||
      searchValueList.start_date === ""
    ) {
      let message = getName("messages.MS0046", {
        param1: t("start_date"),
      });
      errorValue = Object.assign({}, errorValue, { start_date_error: message });
      setErrorValueList(errorValue);
    }

    // customer
    const customerNoNullCheck = () => {
      const customerObj: any[] = searchValueList.promotion_customer;
      const customerMessage = getName("messages.MS0057");
      for (let i = 0; i < customerObj.length; i++) {
        const obj = {
          customer_type: customerObj[i].customer_type,
          customer_no: customerObj[i].customer_no,
          customer_name: customerObj[i].customer_name,
          discount_code: customerObj[i].discount_code,
          csac1: customerObj[i].csac1,
          csac2: customerObj[i].csac2,
          csac3: customerObj[i].csac3,
          csac4: customerObj[i].csac4,
          csac5: customerObj[i].csac5,
          csac6: customerObj[i].csac6,
          csac7: customerObj[i].csac7,
          csac8: customerObj[i].csac8,
          csac9: customerObj[i].csac9,
          csac10: customerObj[i].csac10,
          promotion_region: customerObj[i].promotion_region,
        };
        const keys = Object.values(obj);
        const nullFlag = keys.every((item: any) => {
          return item === "";
        });

        if (nullFlag) {
          return customerMessage;
        }
      }
      return "";
    };

    // unitPacket
    const unitPacketNullCheck = () => {
      const consideredObj: any[] = JSON.parse(
        JSON.stringify(searchValueList.category_considered)
      );
      const consideredError: any[] = JSON.parse(
        JSON.stringify(errorValueList.category_considered)
      );
      const hasValueMessage = getName("messages.MS0001", {
        param1: t("units_per_packet"),
      });
      const hasValueMessage2 = getName("messages.MS0001", {
        param1: t("quantity"),
      });
      const noValueMessage = getName("messages.MS0001", {
        param1: t("item_information"),
      });

      const lessOneMessage = getName("messages.MS0110", {
        param1: t("units_per_packet"),
      });
      const lessOneMessag2 = getName("messages.MS0110", {
        param1: t("quantity"),
      });

      consideredObj.forEach((item: any, index: number) => {
        let noValueFlag = false;
        let hasValueFlag = false;
        let unitPacket = item.unit_packet;

        delete item.index;
        delete item.seq_no;
        delete item.data_type;
        delete item.unit_packet;
        delete item.single_type;
        delete item.special_price;
        delete item.updated_date;
        delete item.cost;
        delete item.list_price;
        delete item.webshop_release;

        const itemValues = Object.values(item);

        hasValueFlag = itemValues.some((item: any) => {
          return item !== "";
        });

        noValueFlag = itemValues.every((item: any) => {
          return item === "";
        });

        if (hasValueFlag && unitPacket === "") {
          if (searchValueList.promo_type === "10") {
            consideredError[index].unit_packet_error = hasValueMessage;
          } else {
            consideredError[index].unit_packet_error = hasValueMessage2;
          }
        } else if (noValueFlag && unitPacket !== "") {
          consideredError[index].unit_packet_error = noValueMessage;
        } else {
          if (unitPacket !== "" && unitPacket * 1 < 1) {
            if (searchValueList.promo_type === "10") {
              consideredError[index].unit_packet_error = lessOneMessage;
            } else {
              consideredError[index].unit_packet_error = lessOneMessag2;
            }
          } else {
            consideredError[index].unit_packet_error = "";
          }
        }
      });

      return consideredError;
    };

    // quantity
    const quantityNullCheck = () => {
      const freeGoodsObj: any[] = JSON.parse(
        JSON.stringify(searchValueList.free_goods)
      );
      const freeGoodsError: any[] = JSON.parse(
        JSON.stringify(errorValueList.free_goods)
      );
      let freeGoodsItemError: string = "";
      const hasValueMessage = getName("messages.MS0001", {
        param1: t("quantity"),
      });
      const quantityMessage = getName("messages.MS0110", {
        param1: t("quantity"),
      });

      const noValueMessage = getName("messages.MS0001", {
        param1: t("item_information"),
      });

      let flag: any[] = [];

      for (let i = 0; i < freeGoodsObj.length; i++) {
        let hasValueFlag = false;
        let noValueFlag = false;
        let quantity = freeGoodsObj[i].quantity;

        delete freeGoodsObj[i].index;
        delete freeGoodsObj[i].seq_no;
        delete freeGoodsObj[i].data_type;
        delete freeGoodsObj[i].quantity;
        delete freeGoodsObj[i].updated_date;
        delete freeGoodsObj[i].cost;
        delete freeGoodsObj[i].list_price;
        delete freeGoodsObj[i].webshop_release;

        const itemValues = Object.values(freeGoodsObj[i]);

        hasValueFlag = itemValues.some((item: any) => {
          return item !== "";
        });

        noValueFlag = itemValues.every((item: any) => {
          return item === "";
        });

        if (hasValueFlag) {
          flag.push("hasValue");
          if (quantity === "") {
            freeGoodsError[i].quantity_error = hasValueMessage;
          } else if (Number(quantity) < 1) {
            freeGoodsError[i].quantity_error = quantityMessage;
          } else {
            freeGoodsError[i].quantity_error = "";
          }
        } else if (noValueFlag) {
          if (quantity === "") {
            flag.push("noValue");
          } else {
            flag.push("quantityOnly");
          }
          freeGoodsError[i].quantity_error = "";
        } else {
          freeGoodsError[i].quantity_error = "";
        }
      }

      const isAllFalse = flag.every((item: any) => {
        return item === "noValue";
      });

      const hasFalse = flag.some((item: any) => {
        return item === "quantityOnly";
      });

      if (isAllFalse || hasFalse) {
        freeGoodsItemError = noValueMessage;
      } else {
        freeGoodsItemError = "";
      }

      return {
        free_goods: freeGoodsError,
        free_goods_item_error: freeGoodsItemError,
      };
    };

    // single
    const singleNullCheck = () => {
      const obj: any[] = JSON.parse(
        JSON.stringify(searchValueList.condition_single)
      );
      const error: any[] = JSON.parse(
        JSON.stringify(errorValueList.condition_single)
      );
      const unitPacketMessage = getName("messages.MS0001", {
        param1: t("quantity"),
      });

      const quantityMessage = getName("messages.MS0110", {
        param1: t("quantity"),
      });

      const specialPriceMessage = getName("messages.MS0001", {
        param1: t("special_price"),
      });

      const itemMessage = getName("messages.MS0001", {
        param1: t("item"),
      });

      const priceMaxValueMessage = getName("messages.MS0082");

      if (obj[0].item_no === "") {
        error[0].item_no_error = itemMessage;
      }

      if (obj[0].quantity === "") {
        error[0].quantity_error = unitPacketMessage;
      } else if (Number(obj[0].quantity) < 1) {
        error[0].quantity_error = quantityMessage;
      }

      if (obj[0].special_price === "" || obj[0].special_price === "0") {
        error[0].special_price_error = specialPriceMessage;
      } else {
        if (!priceValidation(Number(obj[0].special_price))) {
          error[0].special_price_error = priceMaxValueMessage;
        } else {
          error[0].special_price_error = "";
        }
      }

      return {
        condition_single: error,
      };
    };

    // set
    const setNullCheck = () => {
      const obj: any[] = JSON.parse(
        JSON.stringify(searchValueList.condition_set)
      );
      const error: any[] = JSON.parse(
        JSON.stringify(errorValueList.condition_set)
      );
      let specialPriceError = "";

      const unitPacketMessage = getName("messages.MS0001", {
        param1: t("quantity"),
      });

      const quantityMessage = getName("messages.MS0110", {
        param1: t("quantity"),
      });

      const itemMessage = getName("messages.MS0001", {
        param1: t("item"),
      });

      const specialPriceMessage = getName("messages.MS0001", {
        param1: t("special_price"),
      });

      const unitPriceMessage = getName("messages.MS0001", {
        param1: t("unit_price"),
      });

      const errorMessage = getName("messages.MS0056", {
        param1: t("item"),
      });

      const priceMaxValueMessage = getName("messages.MS0082");

      obj.forEach((item: any, index: number) => {
        if (item.item_no === "") {
          error[index].item_no_error = itemMessage;
        }

        if (item.quantity === "") {
          error[index].quantity_error = unitPacketMessage;
        } else if (Number(item.quantity) < 1) {
          error[index].quantity_error = quantityMessage;
        }

        if (item.unit_price === "" || item.unit_price === "0") {
          error[index].unit_price_error = unitPriceMessage;
        } else {
          if (!priceValidation(Number(item.unit_price))) {
            error[index].unit_price_error = priceMaxValueMessage;
          } else {
            error[index].unit_price_error = "";
          }
        }
      });

      // if (specialPrice === "" || specialPrice === "0") {
      //   specialPriceError = specialPriceMessage;
      // } else {
      //   if (!priceValidation(Number(specialPrice))) {
      //     specialPriceError = priceMaxValueMessage;
      //   } else {
      //     specialPriceError = "";
      //   }
      // }

      const sameValue = obj.filter((item: any, index: number) => {
        return item.item_no !== "" && error[index].item_no_error === "";
      });

      const sameValueArray: any[] = [];
      sameValue.forEach((item: any) => {
        sameValueArray.push(item.item_no);
      });

      const hasSameItemNoHandler = () => {
        const notNullObj: any = {};
        for (let i = 0; i < sameValueArray.length; i++) {
          if (notNullObj[sameValueArray[i]]) {
            return true;
          }
          notNullObj[sameValueArray[i]] = true;
        }
        return false;
      };

      return {
        set_item_error: hasSameItemNoHandler() ? errorMessage : "",
        condition_set: error,
        set_special_price_error: specialPriceError,
      };
    };

    const hasSameRecord = (type: any) => {
      const dataObj: any[] = JSON.parse(JSON.stringify(searchValueList[type]));
      const notNullArray: any[] = [];
      const errorMessage = getName("messages.MS0084");
      dataObj.forEach((item: any) => {
        delete item.index;
        delete item.seq_no;
        delete item.data_type;
        delete item.updated_date;
        delete item.unit_packet;
        delete item.quantity;
        delete item.special_price;
        delete item.cost;
        delete item.list_price;
        delete item.webshop_release;

        for (let key in item) {
          if (!isNaN(item[key]) && item[key] !== "") {
            item[key] = Number(item[key]);
          }
        }

        const values = Object.values(item);
        const hasValueFlag = values.some((item: any) => {
          return item !== "";
        });

        if (hasValueFlag) {
          notNullArray.push(values.toString());
        }
      });

      const hasSameRecordHandler = () => {
        const notNullObj: any = {};
        for (let i = 0; i < notNullArray.length; i++) {
          if (notNullObj[notNullArray[i]]) {
            return true;
          }
          notNullObj[notNullArray[i]] = true;
        }

        return false;
      };

      return hasSameRecordHandler() ? errorMessage : "";
    };

    // price
    const priceValidation = (value: any) => {
      const regexp = /^\d{1,8}(\.\d{1,2})?$/g;
      return regexp.test(value);
    };

    const sameRecordCustomer = hasSameRecord("promotion_customer");
    errorValue.customer_error = sameRecordCustomer;

    // promotion type discount
    if (searchValueList.promo_type === "10") {
      let errorObj: any = {};
      // unitBase_or_amountBase
      if ("unitBase_or_amountBase" in searchValueList) {
        if (searchValueList["unitBase_or_amountBase"] === "1") {
          // unit base
          const unitBaseObj: any[] = searchValueList["condition_unit_base"];
          errorObj = JSON.parse(JSON.stringify(errorValue.condition_unit_base));
          for (let i = 0; i < unitBaseObj.length; i++) {
            if (i === 0) {
              if (unitBaseObj[i].packet === "") {
                errorObj[i].packet_error = getName("messages.MS0001", {
                  param1: t("packet"),
                });
              }
              if (unitBaseObj[i].discount_rate === "") {
                errorObj[i].discount_rate_error = getName("messages.MS0001", {
                  param1: t("discount_rate"),
                });
              }
            }
          }

          errorValue = Object.assign({}, errorValue, {
            condition_unit_base: errorObj,
          });
        } else if (searchValueList["unitBase_or_amountBase"] === "2") {
          // amount base
          const amountBaseObj: any = searchValueList["condition_amount_base"];
          console.log("handleRegisterPromotion", amountBaseObj);
          errorObj = JSON.parse(
            JSON.stringify(errorValue.condition_amount_base)
          );

          for (let i = 0; i < amountBaseObj.length; i++) {
            if (i === 0) {
              if (amountBaseObj[i].purchase_amount === "") {
                errorObj[i].purchase_amount_error = getName("messages.MS0001", {
                  param1: t("purchase_amount"),
                });
              }
              if (amountBaseObj[i].discount_rate === "") {
                errorObj[i].discount_rate_error = getName("messages.MS0001", {
                  param1: t("discount_rate"),
                });
              }
            }
          }

          errorValue = Object.assign({}, errorValue, {
            condition_amount_base: errorObj,
          });
        }
      }
      const sameRecordConsidered = hasSameRecord("category_considered");
      const sameRecordExcluded = hasSameRecord("category_excluded");
      const consideredUnitPacket = unitPacketNullCheck();
      errorValue.considered_item_error = sameRecordConsidered;
      errorValue.excluded_item_error = sameRecordExcluded;
      errorValue.category_considered = consideredUnitPacket;
      setErrorValueList(errorValue);
    } else if (searchValueList.promo_type === "20") {
      if ("dealer_discount" in searchValueList) {
        const discountRate =
          searchValueList["dealer_discount"][0]["discount_rate"];
        if (discountRate === "") {
          let message = getName("messages.MS0001", {
            param1: t("discount_rate"),
          });
          errorValue = Object.assign({}, errorValue, {
            dealerDiscount_discount_rate_error: message,
          });
        } else if (
          discountRate !== "" &&
          (Number(discountRate) > 99 || Number(discountRate) < 1)
        ) {
          let message = getName("messages.MS0045");
          errorValue = Object.assign({}, errorValue, {
            dealerDiscount_discount_rate_error: message,
          });
        }
      }
      const sameRecordConsidered = hasSameRecord("category_considered");
      const sameRecordExcluded = hasSameRecord("category_excluded");
      const consideredUnitPacket = unitPacketNullCheck();

      errorValue.considered_item_error = sameRecordConsidered;
      errorValue.excluded_item_error = sameRecordExcluded;
      errorValue.category_considered = consideredUnitPacket;

      const customerNoNull = customerNoNullCheck();

      errorValue = Object.assign({}, errorValue, {
        customer_oneFieldRequird_error: customerNoNull,
      });
      setErrorValueList(errorValue);
    } else if (searchValueList.promo_type === "30") {
      if ("free_goods" in searchValueList) {
        const sameRecordFreeGoods = hasSameRecord("free_goods");
        const sameRecordConsidered = hasSameRecord("category_considered");
        const sameRecordExcluded = hasSameRecord("category_excluded");
        const consideredUnitPacket = unitPacketNullCheck();
        const { free_goods, free_goods_item_error } = quantityNullCheck();

        errorValue = Object.assign({}, errorValue, {
          free_goods_item_error: sameRecordFreeGoods
            ? sameRecordFreeGoods
            : free_goods_item_error,
          considered_item_error: sameRecordConsidered,
          excluded_item_error: sameRecordExcluded,
          category_considered: consideredUnitPacket,
          free_goods,
        });
        setErrorValueList(errorValue);
      }
    } else if (searchValueList.promo_type === "40") {
      if ("single_or_set" in searchValueList) {
        if (searchValueList["single_or_set"] === "1") {
          const { condition_single } = singleNullCheck();
          const propertyArray: any[] = [];
          const condition_item_set = {
            item_no_error: "",
            quantity_error: "",
            unit_price_error: "",
          };
          errorValue.condition_set.forEach((item: any) => {
            propertyArray.push(condition_item_set);
          });

          errorValue.set_special_price_error = "";
          errorValue.set_item_error = "";
          errorValue = Object.assign({}, errorValue, {
            condition_single,
            condition_set: propertyArray,
          });

          setErrorValueList(errorValue);
        } else if (searchValueList["single_or_set"] === "2") {
          const { condition_set, set_item_error, set_special_price_error } =
            setNullCheck();
          const condition_single = [
            {
              item_no_error: "",
              quantity_error: "",
              special_price_error: "",
            },
          ];
          errorValue = Object.assign({}, errorValue, {
            set_item_error,
            condition_set,
            condition_single,
            set_special_price_error,
          });
          setErrorValueList(errorValue);
        }
      }
    } else if (searchValueList.promo_type === "50") {
      if ("promotion_code" in searchValueList) {
        const discountRate =
          searchValueList["promotion_code"][0]["discount_rate"];
        if (discountRate === "") {
          let message = getName("messages.MS0046", {
            param1: t("discount_rate"),
          });

          errorValue = Object.assign({}, errorValue, {
            promotionCode_discount_rate_error: message,
          });
        } else if (
          discountRate !== "" &&
          (Number(discountRate) > 99 || Number(discountRate) < 1)
        ) {
          let message = getName("messages.MS0045");
          errorValue = Object.assign({}, errorValue, {
            promotionCode_discount_rate_error: message,
          });
        }
      }
      const sameRecordConsidered = hasSameRecord("category_considered");
      const sameRecordExcluded = hasSameRecord("category_excluded");
      const consideredUnitPacket = unitPacketNullCheck();
      errorValue.considered_item_error = sameRecordConsidered;
      errorValue.excluded_item_error = sameRecordExcluded;
      errorValue.category_considered = consideredUnitPacket;
      setErrorValueList(errorValue);
    } else if (searchValueList.promo_type === "60") {
      if ("terms_cd" in searchValueList) {
        if (searchValueList["terms_cd"] === "") {
          let message = getName("messages.MS0001", {
            param1: t("terms_cd"),
          });
          errorValue = Object.assign({}, errorValue, {
            terms_cd_error: message,
          });
        }
      }

      const customerNoNull = customerNoNullCheck();

      errorValue = Object.assign({}, errorValue, {
        customer_oneFieldRequird_error: customerNoNull,
      });

      setErrorValueList(errorValue);
    }

    // check all custom field
    let checkResult: any = "";
    let errorList: any = {};

    const commonError = {
      start_date_error: errorValue.start_date_error,
      end_date_error: errorValue.end_date_error,
      priority_error: errorValue.priority_error,
      webshop_error: errorValue.webshop_error,
      analysis_cd_error: errorValue.analysis_cd_error,
      promo_name_error: errorValue.promo_name_error,

      promotion_customer: errorValue.promotion_customer,
      customer_error: errorValue.customer_error,
      customer_oneFieldRequird_error: errorValue.customer_oneFieldRequird_error,
    };

    const discountError = {
      condition_unit_base: errorValue.condition_unit_base,
      condition_amount_base: errorValue.condition_amount_base,
      condition_unit_base_error: errorValue.condition_unit_base_error,
      condition_amount_base_error: errorValue.condition_amount_base_error,
      category_considered: errorValue.category_considered,
      category_excluded: errorValue.category_excluded,
      considered_item_error: errorValue.considered_item_error,
      excluded_item_error: errorValue.excluded_item_error,
    };

    const dealerDiscountError = {
      dealerDiscount_discount_rate_error:
        errorValue.dealerDiscount_discount_rate_error,
      category_considered: errorValue.category_considered,
      category_excluded: errorValue.category_excluded,
    };

    const freeGoodsError = {
      free_goods: errorValue.free_goods,
      free_goods_item_error: errorValue.free_goods_item_error,
      category_considered: errorValue.category_considered,
      category_excluded: errorValue.category_excluded,
      considered_item_error: errorValue.considered_item_error,
      excluded_item_error: errorValue.excluded_item_error,
    };

    const specialPriceError = {
      condition_single: errorValue.condition_single,
      condition_set: errorValue.condition_set,
      set_special_price_error: errorValue.set_special_price_error,
      set_item_error: errorValue.set_item_error,
    };

    const promotionCodeError = {
      promotionCode_discount_rate_error:
        errorValue.promotionCode_discount_rate_error,
      category_considered: errorValue.category_considered,
      category_excluded: errorValue.category_excluded,
      considered_item_error: errorValue.considered_item_error,
      excluded_item_error: errorValue.excluded_item_error,
    };

    const paymentTermError = {
      terms_cd_error: errorValue.terms_cd_error,
    };

    if (searchValueList.promo_type === "10") {
      errorList = Object.assign({}, commonError, discountError);
    } else if (searchValueList.promo_type === "20") {
      errorList = Object.assign({}, commonError, dealerDiscountError);
    } else if (searchValueList.promo_type === "30") {
      errorList = Object.assign({}, commonError, freeGoodsError);
    } else if (searchValueList.promo_type === "40") {
      errorList = Object.assign({}, commonError, specialPriceError);
    } else if (searchValueList.promo_type === "50") {
      errorList = Object.assign({}, commonError, promotionCodeError);
    } else if (searchValueList.promo_type === "60") {
      errorList = Object.assign({}, commonError, paymentTermError);
    }

    if (Object.keys(errorList).length > 0) {
      for (let key in errorList) {
        if (Array.isArray(errorList[key])) {
          for (let i = 0; i < errorList[key].length; i++) {
            for (let keyChild in errorList[key][i]) {
              if (errorList[key][i][keyChild] !== "") {
                checkResult = false;
                break;
              }
            }
          }
        } else {
          if (errorList[key] !== "" && errorList[key] !== null) {
            checkResult = false;
            break;
          }
        }
      }
      if (checkResult === "") {
        checkResult = true;
      }
    } else {
      checkResult = false;
    }
    console.log(
      "handleRegisterPromotion",
      createPromotionFlag,
      fromDraftsFlag,
      checkResult
    );

    if (hasError(errorValue)) {
      return;
    }

    if (createPromotionFlag && !fromDraftsFlag) {
      setSaveChanges(checkResult);
    } else {
      setCheckResultValue(checkResult);
    }
  };

  const onBlurOfAnalysisCode = () => {
    setErrorValue("analysis_cd_error", "");
    const getAnalysisCode = searchValueList.analysis_cd;
    if (getAnalysisCode.length > 0) {
      if (analysisList.length > 0) {
        let _flg = false;
        let _itemList = analysisList.filter(
          (item: any) =>
            item.analysis_cd.toUpperCase() === getAnalysisCode.toUpperCase()
        );
        if (_itemList.length > 0) {
          _flg = true;
          setSearchValue("analysis_cd", _itemList[0].analysis_cd);
        } else {
          _flg = false;
        }

        if (!_flg) {
          let message = getName("messages.MS0004", {
            rowNum: t("analysis_cd"),
            param1: getAnalysisCode,
          });
          setErrorValue("analysis_cd_error", message);
        }
      }
    } else {
      setErrorValue("analysis_cd_error", "");
    }
  };

  const onBlurOfPaymentTermCode = () => {
    setErrorValue("terms_cd_error", "");
    const getTermsCode = searchValueList.terms_cd;
    if (getTermsCode.length > 0) {
      if (paymentTermList.length > 0) {
        let _flg = false;
        let _itemList: any = paymentTermList.filter(
          (item: any) =>
            item.terms_cd.toUpperCase() === getTermsCode.toUpperCase()
        );
        if (_itemList.length > 0) {
          _flg = true;
          setSearchValue("terms_cd", _itemList[0].terms_cd);
        } else {
          _flg = false;
        }

        if (!_flg) {
          let message = getName("messages.MS0004", {
            rowNum: t("terms_cd"),
            param1: getTermsCode,
          });
          setErrorValue("terms_cd_error", message);
        }
      }
    } else {
      setErrorValue("terms_cd_error", "");
    }
  };

  const checkSplitSymbol = (field: any, splitsymbol: any) => {
    let strField = String(field);

    if (strField === splitsymbol) {
      return false;
    } else {
      if (strField.includes('"' + splitsymbol)) {
        return false;
      } else if (strField.includes(splitsymbol + '"')) {
        return false;
      }
    }

    return true;
  };

  const onBlurOfCustomerNo = (index: number) => {
    const errorValue = errorValueList.promotion_customer;
    errorValue[index].customer_no_error = "";
    setErrorValue("promotion_customer", errorValue);
    if (
      isEmptyStringOrNumber(
        searchValueList.promotion_customer[index]["customer_no"]
      )
    ) {
      const setCustomerField = JSON.parse(
        JSON.stringify(searchValueList.promotion_customer)
      );
      setCustomerField[index]["customer_name"] = "";

      if (
        !isEmptyStringOrNumber(
          oldValue.promotion_customer[index]["customer_no"]
        )
      ) {
        setCustomerField[index].customer_type = "";
        setCustomerField[index].discount_code = "";
        setCustomerField[index].csac1 = "";
        setCustomerField[index].csac2 = "";
        setCustomerField[index].csac3 = "";
        setCustomerField[index].csac4 = "";
        setCustomerField[index].csac5 = "";
        setCustomerField[index].csac6 = "";
        setCustomerField[index].csac7 = "";
        setCustomerField[index].csac8 = "";
        setCustomerField[index].csac9 = "";
        setCustomerField[index].csac10 = "";
        setCustomerField[index].promotion_region = "";
      }

      setSearchValueList(
        Object.assign({}, searchValueList, {
          promotion_customer: setCustomerField,
        })
      );
      customerEditList.splice(index, 1, false);
      setOnfocusHandler(false);
      setOnCustomerNoFlag(false);
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(
          searchValueList.promotion_customer[index]["customer_no"],
          config
        )
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = getName("messages.MS0004", {
              param1: t("customer_no"),
            });
            errorValue[index].customer_no_error = message;
            setErrorValue("promotion_customer", errorValue);

            const setCustomerField = JSON.parse(
              JSON.stringify(searchValueList.promotion_customer)
            );
            setCustomerField[index]["customer_name"] = "";
            setSearchValueList(
              Object.assign({}, searchValueList, {
                promotion_customer: setCustomerField,
              })
            );
            const editListAry = JSON.parse(JSON.stringify(customerEditList));
            editListAry.splice(index, 1, false);
            setCustomerEditList(editListAry);
          } else {
            const setCustomerField = JSON.parse(
              JSON.stringify(searchValueList.promotion_customer)
            );
            setCustomerField[index].customer_type = String(
              response.customer_type
            );
            setCustomerField[index].customer_name = String(
              response.customer_name
            );
            setCustomerField[index].discount_code = String(
              response.customer_discount_cd
            );
            setCustomerField[index].csac1 = String(response.csac1);
            setCustomerField[index].csac2 = String(response.csac2);
            setCustomerField[index].csac3 = String(response.csac3);
            setCustomerField[index].csac4 = String(response.csac4);
            setCustomerField[index].csac5 = String(response.csac5);
            setCustomerField[index].csac6 = String(response.csac6);
            setCustomerField[index].csac7 = String(response.csac7);
            setCustomerField[index].csac8 = String(response.csac8);
            setCustomerField[index].csac9 = String(response.csac9);
            setCustomerField[index].csac10 = String(response.csac10);
            setCustomerField[index].promotion_region = String(
              response.promotion_region
            );
            if (response.customer_closed === true) {
              setCustomerNo(response.customer_no);
              setAlertCustomerNoMessageFlag(true);
              setCustomerInfo(setCustomerField);
              setCustomerInfoIndex(index);
            } else {
              setSearchValue("promotion_customer", setCustomerField);
              const editListAry = JSON.parse(JSON.stringify(customerEditList));
              editListAry.splice(index, 1, true);
              setCustomerEditList(editListAry);
            }
          }
          setOnfocusHandler(false);
          setOnCustomerNoFlag(false);
        });
    }
    let valueList = Object.assign({}, oldValue);

    valueList.promotion_customer[index]["customer_no"] =
      searchValueList.promotion_customer[index]["customer_no"];

    setOldValue(valueList);
  };

  const onFocusOfCustomerNo = (index: number) => {
    setOnfocusHandler(true);
    setOnCustomerNoFlag(true);
  };

  const onBlurOfSetItem = (index: number) => {
    setOnfocusHandler(false);
  };

  const onFocusOfSetItem = (index: number) => {
    setOnfocusHandler(true);
  };

  const onFocusOfSingleItem = (index: number) => {
    setOnfocusHandler(true);
  };

  const onFocusOfItemNo = (type: any, index: number) => {
    if (type === "category_considered") {
      setOnConsideredItemNoFlag(true);
    } else if (type === "category_excluded") {
      setOnExcludedItemNoFlag(true);
    } else if (type === "free_goods") {
      setOnFreeGoodsItemNoFlag(true);
    } else if (type === "condition_set") {
      setOnSetItemNoFlag(true);
    }
  };

  async function onBlurOfItemNo(type: any, index: number) {
    let getItemNo = "";
    let setCategoryField: any[] = [];
    let errorValue: any[] = [];

    if (type === "category_considered") {
      getItemNo = searchValueList["category_considered"][index]["item_no"];
      setCategoryField = JSON.parse(
        JSON.stringify(searchValueList["category_considered"])
      );
      errorValue = errorValueList.category_considered;
    } else if (type === "category_excluded") {
      getItemNo = searchValueList["category_excluded"][index]["item_no"];
      setCategoryField = JSON.parse(
        JSON.stringify(searchValueList["category_excluded"])
      );
      errorValue = errorValueList.category_excluded;
    } else if (type === "free_goods") {
      if ("free_goods" in searchValueList) {
        getItemNo = searchValueList["free_goods"][index]["item_no"];
        setCategoryField = JSON.parse(
          JSON.stringify(searchValueList["free_goods"])
        );
        errorValue = errorValueList.free_goods;
      }
    } else if (type === "condition_single") {
      if ("condition_single" in searchValueList) {
        getItemNo = searchValueList["condition_single"][index]["item_no"];
        setCategoryField = JSON.parse(
          JSON.stringify(searchValueList["condition_single"])
        );
        errorValue = errorValueList.condition_single;
      }
    } else if (type === "condition_set") {
      if ("condition_set" in searchValueList) {
        getItemNo = searchValueList["condition_set"][index]["item_no"];
        setCategoryField = JSON.parse(
          JSON.stringify(searchValueList["condition_set"])
        );
        errorValue = errorValueList.condition_set;
      }
    }

    if (getItemNo.length > 0) {
      let _item: any;
      // get item number
      // AM0302
      // apiLoading(true);
      await api
        .getReadItemMasterAPI(encodeURIComponent(getItemNo), config)
        .then(({ code, response }: { code: any; response: any }) => {
          // apiLoading(false);
          if (!getApiResultStatus(code)) {
            if (code === 404) {
              setCategoryField[index].description = "";

              let message = getName("messages.MS0004", {
                rowNum: t("item_no"),
                param1: setCategoryField[index].item_no,
              });
              errorValue[index].item_no_error = message;

              if (type === "category_considered") {
                consideredEditList.splice(index, 1, false);
              } else if (type === "category_excluded") {
                excludedEditList.splice(index, 1, false);
              } else if (type === "free_goods") {
                freeGoodsEditList.splice(index, 1, false);
              } else if (type === "condition_set") {
                calcUnitPrice(0, index);
              }
            }
          } else {
            _item = response;
            setCategoryField[index].item_class = String(_item.item_class);
            setCategoryField[index].discount_code = String(
              _item.item_discount_cd
            );
            setCategoryField[index].item_no = String(_item.item_no);
            setCategoryField[index].isac1 = String(_item.isac1);
            setCategoryField[index].isac2 = String(_item.isac2);
            setCategoryField[index].isac3 = String(_item.isac3);
            setCategoryField[index].isac4 = String(_item.isac4);
            setCategoryField[index].isac5 = String(_item.isac5);
            setCategoryField[index].isac6 = String(_item.isac6);
            setCategoryField[index].isac7 = String(_item.isac7);
            setCategoryField[index].isac8 = String(_item.isac8);
            setCategoryField[index].webshop_release = _item.webshop_release;
            setCategoryField[index].description = String(_item.description);
            errorValue[index].item_no_error = "";

            if (_item.webshop_release === 0 || _item.suspend_flg === true) {
              setItemNo(String(_item.item_no));
              if (_item.webshop_release === 0) {
                setItemNoWebshopReleaseFlag(true);
              }
              if (_item.suspend_flg === true) {
                setItemNoSuspendFlag(true);
              }
              setAlertItemNoMessageFlag(true);
              setItemNoTypeInfo(type);
              setItemInfo(setCategoryField);
              setItemInfoIndex(index);
              setItemResponseInfo(response);
            } else {
              if (type === "category_considered") {
                consideredEditList.splice(index, 1, true);
              } else if (type === "category_excluded") {
                excludedEditList.splice(index, 1, true);
              } else if (type === "free_goods") {
                freeGoodsEditList.splice(index, 1, true);
              } else if (type === "condition_set") {
                calcUnitPrice(itemResponseInfo.cost, index);
              }
              setSearchValue(type, setCategoryField);
            }
          }
          if (type === "condition_single") {
            setOnfocusHandler(false);
          } else if (type === "category_considered") {
            setOnConsideredItemNoFlag(false);
          } else if (type === "category_excluded") {
            setOnExcludedItemNoFlag(false);
          } else if (type === "free_goods") {
            setOnFreeGoodsItemNoFlag(false);
          } else if (type === "condition_set") {
            setOnSetItemNoFlag(false);
          }
        });
    } else {
      if (oldValue[type] && oldValue[type].length > index) {
        if (!isEmptyStringOrNumber(oldValue[type][index]["item_no"])) {
          setCategoryField[index].item_class = "";
          setCategoryField[index].discount_code = "";
          setCategoryField[index].description = "";
          errorValue[index].item_no_error = "";
          setCategoryField[index].isac1 = "";
          setCategoryField[index].isac2 = "";
          setCategoryField[index].isac3 = "";
          setCategoryField[index].isac4 = "";
          setCategoryField[index].isac5 = "";
          setCategoryField[index].isac6 = "";
          setCategoryField[index].isac7 = "";
          setCategoryField[index].isac8 = "";
        }

        if (type === "category_considered") {
          consideredEditList.splice(index, 1, false);
        } else if (type === "category_excluded") {
          excludedEditList.splice(index, 1, false);
        } else if (type === "free_goods") {
          freeGoodsEditList.splice(index, 1, false);
        } else if (type === "condition_set") {
          calcUnitPrice(0, index);
        }
      }
      if (type === "condition_single") {
        setOnfocusHandler(false);
      } else if (type === "category_considered") {
        setOnConsideredItemNoFlag(false);
      } else if (type === "category_excluded") {
        setOnExcludedItemNoFlag(false);
      } else if (type === "free_goods") {
        setOnFreeGoodsItemNoFlag(false);
      } else if (type === "condition_set") {
        setOnSetItemNoFlag(false);
      }
      setSearchValue(type, setCategoryField);
    }
    setErrorValue(type, errorValue);
    let valueList = Object.assign({}, oldValue);
    if (type === "category_considered") {
      valueList.category_considered[index]["item_no"] =
        searchValueList.category_considered[index]["item_no"];
    } else if (type === "category_excluded") {
      valueList.category_excluded[index]["item_no"] =
        searchValueList.category_excluded[index]["item_no"];
    } else if (type === "free_goods") {
      valueList.free_goods[index]["item_no"] =
        searchValueList.free_goods[index]["item_no"];
    }

    setOldValue(valueList);
  }

  const plannerCodeChange = (type: any, value: any, index?: number) => {
    if (type === "category_considered") {
      if (value === "1") {
        setCategoryConsidered(categoryM);
      } else if (value === "2") {
        setCategoryConsidered(categoryA);
      } else if (value === "3") {
        setCategoryConsidered(categoryP);
      } else if (value === "4") {
        setCategoryConsidered(categoryO);
      } else if (value === "") {
        setCategoryConsidered(categoryAll);
      }
    } else if (type === "category_excluded") {
      if (value === "1") {
        setCategoryExcluded(categoryM);
      } else if (value === "2") {
        setCategoryExcluded(categoryA);
      } else if (value === "3") {
        setCategoryExcluded(categoryP);
      } else if (value === "4") {
        setCategoryExcluded(categoryO);
      } else if (value === "") {
        setCategoryExcluded(categoryAll);
      }
    } else if (type === "free_goods") {
      if (value === "1") {
        setCategoryFreeGoods(categoryM);
      } else if (value === "2") {
        setCategoryFreeGoods(categoryA);
      } else if (value === "3") {
        setCategoryFreeGoods(categoryP);
      } else if (value === "4") {
        setCategoryFreeGoods(categoryO);
      } else if (value === "") {
        setCategoryFreeGoods(categoryAll);
      }
    } else if (type === "condition_single") {
      if (value === "1") {
        setCategorySingle(categoryM);
      } else if (value === "2") {
        setCategorySingle(categoryA);
      } else if (value === "3") {
        setCategorySingle(categoryP);
      } else if (value === "4") {
        setCategorySingle(categoryO);
      } else if (value === "") {
        setCategorySingle(categoryAll);
      }
    } else if (type === "condition_set") {
      if (value === "1") {
        setCategorySet(categoryM);
      } else if (value === "2") {
        setCategorySet(categoryA);
      } else if (value === "3") {
        setCategorySet(categoryP);
      } else if (value === "4") {
        setCategorySet(categoryO);
      } else if (value === "") {
        setCategorySet(categoryAll);
      }
    }
  };

  // Delete unnecessary attributes
  const DeleteUnnecessaryAttributes = (data: any) => {
    // amount base
    if (data.condition_amount_base && data.condition_amount_base.length > 0) {
      data.condition_amount_base.forEach((item: any) => {
        delete item.purchase_amount_format;
      });
    }
    // single
    if (data.condition_single && data.condition_single.length > 0) {
      data.condition_single.forEach((item: any) => {
        delete item.special_price_format;
      });
    }
    // set
    if (data.condition_set && data.condition_set.length > 0) {
      data.condition_set.forEach((item: any) => {
        delete item.special_price_format;
      });
    }
    return data;
  };

  // post request data handler
  const postDataHandler = (type: any) => {
    let _post: any = JSON.parse(JSON.stringify(searchValueList));
    console.log("handleRegisterPromotion postDataHandler", _post);
    _post = conditionSetFormat(searchValueList);
    // Delete unnecessary attributes
    _post = DeleteUnnecessaryAttributes(_post);

    const postKeys: any[] = Object.keys(_post);
    const arrayList: any[] = [];
    const promotionDetail: any[] = [];
    let postRequestData: any = {};

    if (postKeys.indexOf("unitBase_or_amountBase") > -1) {
      if (_post.unitBase_or_amountBase === "1") {
        postRequestData.promo_sub_type = 4;
        postKeys.splice(postKeys.indexOf("condition_amount_base"), 1);
      } else if (_post.unitBase_or_amountBase === "2") {
        postRequestData.promo_sub_type = 5;
        postKeys.splice(postKeys.indexOf("condition_unit_base"), 1);
      }
    }

    if (postKeys.indexOf("single_or_set") > -1) {
      if (_post.single_or_set === "1") {
        postRequestData.promo_sub_type = 6;
        postKeys.splice(postKeys.indexOf("condition_set"), 1);
      } else if (_post.single_or_set === "2") {
        postRequestData.promo_sub_type = 7;
        postKeys.splice(postKeys.indexOf("condition_single"), 1);
      }
    }

    postKeys.forEach((item: any) => {
      if (Array.isArray(_post[item]) && item !== "updated_history") {
        _post[item].forEach((child: any) => {
          const obj: any = {};
          for (let key in child) {
            if (
              key !== "index" &&
              key !== "data_type" &&
              key !== "cost" &&
              key !== "list_price"
            ) {
              if (child[key] !== "") {
                if (key === "customer_no") {
                  obj[key] = Number(child[key]);
                } else {
                  if (_post.promo_type !== "10") {
                    if (
                      item === "category_considered" &&
                      key === "unit_packet"
                    ) {
                      obj["quantity"] = child[key];
                    } else {
                      obj[key] = child[key];
                    }
                  } else {
                    obj[key] = child[key];
                  }
                }
              }
            }
          }
          if (Object.keys(obj).length > 0) {
            if (item === "promotion_customer") {
              arrayList.push(obj);
            } else {
              obj.data_type = child.data_type;
              promotionDetail.push(obj);
            }
          }
        });
      } else {
        if (item === "promo_name") {
          if (_post[item] !== "") {
            postRequestData[item] = _post[item];
          } else {
            postRequestData[item] = `${date(new Date().getTime())}`;
          }
        } else if (item === "priority") {
          if (
            _post.promo_type === "10" ||
            _post.promo_type === "30" ||
            _post.promo_type === "40" ||
            _post.promo_type === "50"
          ) {
            if (_post[item] !== "") {
              postRequestData[item] = _post[item];
            }
          }
        } else if (item === "allow_overlap") {
          if (
            _post.promo_type === "10" ||
            _post.promo_type === "30" ||
            _post.promo_type === "40" ||
            _post.promo_type === "50"
          ) {
            if (_post[item] !== "0") {
              postRequestData[item] = _post[item];
            }
          }
        } else if (item === "exclusive_flag") {
          if (_post[item] !== "0") {
            postRequestData[item] = _post[item];
          }
        } else if (item === "webshop_flag") {
          if (
            _post[item] === null ||
            _post[item] === "" ||
            _post[item] === undefined
          ) {
            postRequestData[item] = "0";
          } else {
            postRequestData[item] = _post[item];
          }
        } else if (item === "others_flag") {
          if (
            _post[item] === null ||
            _post[item] === "" ||
            _post[item] === undefined
          ) {
            postRequestData[item] = "0";
          } else {
            postRequestData[item] = _post[item];
          }
        } else if (item === "start_date") {
          if (
            _post[item] !== "" &&
            _post[item] !== null &&
            !isNaN(Date.parse(dateFormat(new Date(_post[item]))))
          ) {
            postRequestData[item] = dateFormat(new Date(_post[item]));
          }
        } else if (item === "end_date") {
          if (
            _post[item] === "" ||
            _post[item] === null ||
            isNaN(Date.parse(dateFormat(new Date(_post[item]))))
          ) {
            postRequestData[item] = "9999-12-31";
          } else {
            postRequestData[item] = dateFormat(new Date(_post[item]));
          }
        } else if (item === "draft_flag") {
          postRequestData[item] = type;
        } else if (item === "promo_type" || item === "analysis_cd") {
          if (_post[item] !== "") {
            postRequestData[item] = _post[item];
          }
        } else if (item === "updated_date") {
          postRequestData[item] = _post[item];
        } else if (_post.promo_type === "60") {
          if (item === "terms_cd") {
            if (_post[item] !== "") {
              postRequestData[item] = _post[item];
            }
          }
        }
      }
    });

    if (arrayList.length > 0) {
      postRequestData["promotion_customer"] = arrayList;
    }
    if (promotionDetail.length > 0) {
      postRequestData["promotion_detail"] = promotionDetail;
    }

    if (specialPrice !== "" && _post.single_or_set === "2") {
      if (
        "promotion_detail" in postRequestData &&
        postRequestData["promotion_detail"].length > 0
      ) {
        const index = postRequestData["promotion_detail"].findIndex(
          (item: any) => {
            return item.data_type === "7";
          }
        );

        postRequestData["promotion_detail"][index].special_price = specialPrice;
      }

      if (!("promotion_detail" in postRequestData)) {
        if (_post.single_or_set === "2") {
          const setArray = [];
          setArray.push({
            data_type: "7",
            special_price: specialPrice,
          });
          postRequestData["promotion_detail"] = setArray;
        }
      }
    }

    postRequestData = parsePostData(postRequestData);

    let webshopReleaseList = [];
    if (
      postRequestData.promotion_detail &&
      postRequestData.promotion_detail.length > 0
    ) {
      webshopReleaseList = postRequestData.promotion_detail.filter(
        (itemObject: any) => {
          if (
            (itemObject.data_type === "1" ||
              itemObject.data_type === "2" ||
              itemObject.data_type === "3" ||
              itemObject.data_type === "6" ||
              itemObject.data_type === "7") &&
            itemObject.hasOwnProperty("webshop_release") &&
            itemObject.webshop_release === 0
          ) {
            return true;
          }
        }
      );

      postRequestData.promotion_detail.forEach((itemObject: any) => {
        if (itemObject.hasOwnProperty("webshop_release")) {
          delete itemObject["webshop_release"];
        }
      });
    }

    // if (webshopReleaseList.length > 0) {
    //   setPostRequestDataState(postRequestData);

    //   setTypeState(type);
    //   setChangeItemWebshopReleaseData(webshopReleaseList);
    //   setCreateWebshopReleaseFlag(true);
    // } else {
    setChangeItemWebshopReleaseData([]);
    callCreatePromotionAPI(type, postRequestData);
    // }
  };

  const callCreatePromotionAPI = (
    paraType?: any,
    paraPostRequestData?: any
  ) => {
    let postRequestData: any;
    let type: any;
    if (paraType) {
      postRequestData = paraPostRequestData;
      type = paraType;
    } else {
      postRequestData = postRequestDataState;
      type = typeState;
    }

    if (type === "0") {
      postRequestData.duplicate_check_flag = "true";
    } else {
      postRequestData.duplicate_check_flag = "false";
    }

    apiLoading(true);

    api
      .createPromotionAPI(config, postRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setPromotionInformation(response);
          if (type === "0") {
            if (response && response.message_id === "MA0009") {
              setPagePostRequestData(
                JSON.parse(JSON.stringify(postRequestData))
              );
              setForceRegisterDisplay(true);
            } else {
              // register promotion
              setDisabledSuccess(true);
            }
          } else if (type === "1") {
            // save to draft
            setSaveToDrafts(true);
          }
        }
      });
  };

  // edit request data handler
  const editDataHandler = (type?: any) => {
    let _post: any = JSON.parse(JSON.stringify(searchValueList));
    console.log("handleRegisterPromotion editDataHandler", _post);
    _post = conditionSetFormat(searchValueList);
    // Delete unnecessary attributes
    _post = DeleteUnnecessaryAttributes(_post);

    const _default: any = editValueList;

    const postKeys: any[] = Object.keys(_post);
    const arrayList: any[] = [];
    const promotionDetail: any[] = [];
    let postRequestData: any = {};

    if (_post.promo_type !== _default.promo_type) {
      if (postKeys.indexOf("unitBase_or_amountBase") > -1) {
        if (_post.unitBase_or_amountBase === "1") {
          // postRequestData.promo_sub_type = 4;
          postKeys.splice(postKeys.indexOf("condition_amount_base"), 1);
        } else if (_post.unitBase_or_amountBase === "2") {
          // postRequestData.promo_sub_type = 5;
          postKeys.splice(postKeys.indexOf("condition_unit_base"), 1);
        }

        if (_post.promo_sub_type !== _default.promo_sub_type) {
          postRequestData.promo_sub_type = _post.promo_sub_type;
        }
      }

      if (postKeys.indexOf("single_or_set") > -1) {
        if (_post.single_or_set === "1") {
          // postRequestData.promo_sub_type = 6;
          postKeys.splice(postKeys.indexOf("condition_set"), 1);
        } else if (_post.single_or_set === "2") {
          // postRequestData.promo_sub_type = 7;
          postKeys.splice(postKeys.indexOf("condition_single"), 1);
        }
        if (_post.promo_sub_type !== _default.promo_sub_type) {
          postRequestData.promo_sub_type = _post.promo_sub_type;
        }
      }

      const _postSeqNo: any[] = [];
      _post["promotion_customer"].forEach((item: any) => {
        if (item.seq_no) {
          _postSeqNo.push(item.seq_no);
        }
      });

      const _defaultSeqNo: any[] = [];
      _default["promotion_customer"].forEach((item: any) => {
        if (item.seq_no) {
          _defaultSeqNo.push(item.seq_no);
        }
      });

      const deleteItem: any[] = [];
      _defaultSeqNo.forEach((item: any) => {
        if (_postSeqNo.indexOf(item) === -1) {
          deleteItem.push(item);
        }
      });

      const editItem: any[] = [];
      _postSeqNo.forEach((item: any) => {
        if (_defaultSeqNo.indexOf(item) > -1) {
          editItem.push(item);
        }
      });

      // delete
      deleteItem.forEach((option: any) => {
        const obj: any = {};
        const defaultIndex = _defaultSeqNo.indexOf(option);
        obj.updated_flg = "2";
        obj.seq_no = _default["promotion_customer"][defaultIndex].seq_no;
        obj.updated_date =
          _default["promotion_customer"][defaultIndex].updated_date;

        if (Object.keys(obj).length > 0) {
          arrayList.push(obj);
        }
      });

      // edit
      editItem.forEach((option: any) => {
        const obj: any = {};
        const postIndex = _postSeqNo.indexOf(option);
        const defaultIndex = _defaultSeqNo.indexOf(option);
        if (
          JSON.stringify(_post["promotion_customer"][postIndex]) !==
          JSON.stringify(_default["promotion_customer"][defaultIndex])
        ) {
          const objValues: any[] = [];
          for (let key in _post["promotion_customer"][postIndex]) {
            if (
              key !== "seq_no" &&
              key !== "data_type" &&
              key !== "updated_date" &&
              key !== "cost" &&
              key !== "list_price" &&
              key !== "index"
            ) {
              objValues.push(_post["promotion_customer"][postIndex][key]);
            }
          }

          const allNullFlag = objValues.every((item: any) => {
            return item === "";
          });

          if (allNullFlag) {
            obj.updated_flg = "2";
            obj.seq_no = _post["promotion_customer"][postIndex].seq_no;
            obj.updated_date =
              _post["promotion_customer"][postIndex].updated_date;
          } else {
            for (let key in _post["promotion_customer"][postIndex]) {
              if (key !== "index" && key !== "data_type") {
                obj.updated_flg = "1";
                if (
                  _post["promotion_customer"][postIndex][key] !==
                  _default["promotion_customer"][defaultIndex][key]
                ) {
                  if (key === "customer_no") {
                    if (_post["promotion_customer"][postIndex][key] === "") {
                      obj[key] = "";
                    } else {
                      obj[key] = Number(
                        _post["promotion_customer"][postIndex][key]
                      );
                    }
                  } else {
                    obj[key] = _post["promotion_customer"][postIndex][key];
                  }
                  obj.updated_date =
                    _post["promotion_customer"][postIndex].updated_date;
                  obj.seq_no = _post["promotion_customer"][postIndex].seq_no;
                }
              }
            }
          }
        }

        if (Object.keys(obj).length > 0) {
          arrayList.push(obj);
        }
      });

      // new add
      _post["promotion_customer"].forEach((postChild: any, index: number) => {
        const obj: any = {};
        if (postChild.seq_no === "" || !("seq_no" in postChild)) {
          for (let key in postChild) {
            if (key !== "index" && key !== "data_type") {
              if (postChild[key] !== "") {
                obj.updated_flg = "0";
                if (key === "customer_no") {
                  obj[key] = Number(postChild[key]);
                } else {
                  obj[key] = postChild[key];
                }
              }
            }
          }
        }

        if (Object.keys(obj).length > 0) {
          arrayList.push(obj);
        }
      });

      if (arrayList.length > 0) {
        postRequestData["promotion_customer"] = arrayList;
      }

      postKeys.forEach((item: any) => {
        if (
          Array.isArray(_post[item]) &&
          item !== "updated_history" &&
          item !== "promotion_customer"
        ) {
          _post[item].forEach((child: any) => {
            const obj: any = {};
            for (let key in child) {
              if (key !== "index" && key !== "data_type") {
                if (child[key] !== "") {
                  if (key === "customer_no") {
                    if (child[key] === "") {
                      obj[key] = "";
                    } else {
                      obj[key] = Number(child[key]);
                    }
                  } else {
                    if (_post.promo_type !== "10") {
                      if (
                        item === "category_considered" &&
                        key === "unit_packet"
                      ) {
                        obj["quantity"] = child[key];
                      } else {
                        obj[key] = child[key];
                      }
                    } else {
                      obj[key] = child[key];
                    }
                  }
                }
              }
            }
            if (Object.keys(obj).length > 0) {
              obj.updated_flg = "0";
              obj.data_type = child.data_type;
              promotionDetail.push(obj);
            }
          });
        } else {
          if (item === "promo_name") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "priority") {
            if (
              _post.promo_type === "10" ||
              _post.promo_type === "30" ||
              _post.promo_type === "40" ||
              _post.promo_type === "50"
            ) {
              if (_post[item] !== _default[item]) {
                postRequestData[item] = _post[item];
              }
            }
          } else if (item === "allow_overlap") {
            if (
              _post.promo_type === "10" ||
              _post.promo_type === "30" ||
              _post.promo_type === "40" ||
              _post.promo_type === "50"
            ) {
              if (_post[item] !== _default[item]) {
                postRequestData[item] = _post[item];
              }
            }
          } else if (item === "exclusive_flag") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "webshop_flag") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "others_flag") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "start_date") {
            if (_post[item] !== _default[item]) {
              if (!isNaN(Date.parse(dateFormat(new Date(_post[item]))))) {
                postRequestData[item] = dateFormat(new Date(_post[item]));
              }
            }
          } else if (item === "end_date") {
            if (_post[item] !== _default[item]) {
              if (
                _post[item] === "" ||
                _post[item] === null ||
                isNaN(Date.parse(dateFormat(new Date(_post[item]))))
              ) {
                postRequestData[item] = "9999-12-31";
              } else {
                postRequestData[item] = dateFormat(new Date(_post[item]));
              }
            }
          } else if (item === "draft_flag") {
            postRequestData[item] = type;
          } else if (item === "promo_type" || item === "analysis_cd") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "updated_date") {
            postRequestData[item] = _post[item];
          } else if (_post.promo_type === "60") {
            if (item === "terms_cd") {
              if (_post[item] !== _default[item]) {
                postRequestData[item] = _post[item];
              }
            }
          }
        }
      });

      if (arrayList.length > 0) {
        postRequestData["promotion_customer"] = arrayList;
      }
      if (promotionDetail.length > 0) {
        postRequestData["promotion_detail"] = promotionDetail;
      }

      if (specialPrice !== "" && _post.single_or_set === "2") {
        if (
          "promotion_detail" in postRequestData &&
          postRequestData["promotion_detail"].length > 0
        ) {
          const index = postRequestData["promotion_detail"].findIndex(
            (item: any) => {
              return item.data_type === "7";
            }
          );

          postRequestData["promotion_detail"][index].special_price =
            specialPrice;
        }

        if (!("promotion_detail" in postRequestData)) {
          if (_post.single_or_set === "2") {
            const setArray = [];
            setArray.push({
              data_type: "7",
              special_price: specialPrice,
              updated_flg: "0",
            });
            postRequestData["promotion_detail"] = setArray;
          }
        }
      }
    } else {
      const deleteHandler = (type: any) => {
        _default[type].forEach((item: any) => {
          const obj: any = {};
          obj.updated_flg = "2";
          obj.seq_no = item.seq_no;
          obj.updated_date = item.updated_date;
          obj.data_type = item.data_type;
          promotionDetail.push(obj);
        });
      };

      const addHandler = (type: any) => {
        _post[type].forEach((item: any) => {
          const obj: any = {};
          for (let key in item) {
            if (key !== "index" && key !== "data_type") {
              if (item[key] !== "") {
                obj.updated_flg = "0";
                obj[key] = item[key];
              }
            }
          }

          if (Object.keys(obj).length > 0) {
            if (item === "promotion_customer") {
              arrayList.push(obj);
            } else {
              obj.data_type = item.data_type;
              promotionDetail.push(obj);
            }
          }
        });
      };

      if (postKeys.indexOf("unitBase_or_amountBase") > -1) {
        if (_post.unitBase_or_amountBase === _default.unitBase_or_amountBase) {
          if (_post.unitBase_or_amountBase === "1") {
            postKeys.splice(postKeys.indexOf("condition_amount_base"), 1);
          } else if (_post.unitBase_or_amountBase === "2") {
            postKeys.splice(postKeys.indexOf("condition_unit_base"), 1);
          }
        } else {
          if (_post.unitBase_or_amountBase === "1") {
            deleteHandler("condition_amount_base");
            addHandler("condition_unit_base");
          } else if (_post.unitBase_or_amountBase === "2") {
            deleteHandler("condition_unit_base");
            addHandler("condition_amount_base");
          }
        }

        if (_post.promo_sub_type !== _default.promo_sub_type) {
          postRequestData.promo_sub_type = _post.promo_sub_type;
        }
      }

      if (postKeys.indexOf("single_or_set") > -1) {
        if (_post.single_or_set === _default.single_or_set) {
          if (_post.single_or_set === "1") {
            postKeys.splice(postKeys.indexOf("condition_set"), 1);
          } else if (_post.single_or_set === "2") {
            postKeys.splice(postKeys.indexOf("condition_single"), 1);
          }
        } else {
          if (_post.single_or_set === "1") {
            deleteHandler("condition_set");
            addHandler("condition_single");
          } else if (_post.single_or_set === "2") {
            deleteHandler("condition_single");
            addHandler("condition_set");
          }
        }

        if (_post.promo_sub_type !== _default.promo_sub_type) {
          postRequestData.promo_sub_type = _post.promo_sub_type;
        }
      }

      postKeys.forEach((item: any) => {
        if (Array.isArray(_post[item]) && Array.isArray(_default[item])) {
          if (JSON.stringify(_post[item]) === JSON.stringify(_default[item])) {
            return false;
          } else {
            const _postSeqNo: any[] = [];
            _post[item].forEach((item: any) => {
              if (item.seq_no) {
                _postSeqNo.push(item.seq_no);
              }
            });

            const _defaultSeqNo: any[] = [];
            _default[item].forEach((item: any) => {
              if (item.seq_no) {
                _defaultSeqNo.push(item.seq_no);
              }
            });

            const deleteItem: any[] = [];
            _defaultSeqNo.forEach((item: any) => {
              if (_postSeqNo.indexOf(item) === -1) {
                deleteItem.push(item);
              }
            });

            const editItem: any[] = [];
            _postSeqNo.forEach((item: any) => {
              if (_defaultSeqNo.indexOf(item) > -1) {
                editItem.push(item);
              }
            });

            // delete
            if (
              (item !== "condition_unit_base" &&
                item !== "condition_amount_base" &&
                item !== "condition_single" &&
                item !== "condition_set") ||
              ((item === "condition_unit_base" ||
                item === "condition_amount_base") &&
                _post.unitBase_or_amountBase ===
                  _default.unitBase_or_amountBase) ||
              ((item === "condition_single" || item === "condition_set") &&
                _post.single_or_set === _default.single_or_set)
            ) {
              deleteItem.forEach((option: any) => {
                const obj: any = {};
                const defaultIndex = _defaultSeqNo.indexOf(option);
                obj.updated_flg = "2";
                obj.seq_no = _default[item][defaultIndex].seq_no;
                obj.updated_date = _default[item][defaultIndex].updated_date;

                if (Object.keys(obj).length > 0) {
                  if (item === "promotion_customer") {
                    arrayList.push(obj);
                  } else {
                    obj.data_type = _default[item][defaultIndex].data_type;
                    promotionDetail.push(obj);
                  }
                }
              });
            }

            // edit
            if (
              (item !== "condition_unit_base" &&
                item !== "condition_amount_base" &&
                item !== "condition_single" &&
                item !== "condition_set") ||
              ((item === "condition_unit_base" ||
                item === "condition_amount_base") &&
                _post.unitBase_or_amountBase ===
                  _default.unitBase_or_amountBase) ||
              ((item === "condition_single" || item === "condition_set") &&
                _post.single_or_set === _default.single_or_set)
            ) {
              editItem.forEach((option: any) => {
                const obj: any = {};
                const postIndex = _postSeqNo.indexOf(option);
                const defaultIndex = _defaultSeqNo.indexOf(option);
                if (
                  JSON.stringify(_post[item][postIndex]) !==
                  JSON.stringify(_default[item][defaultIndex])
                ) {
                  const objValues: any[] = [];
                  for (let key in _post[item][postIndex]) {
                    if (
                      key !== "seq_no" &&
                      key !== "data_type" &&
                      key !== "updated_date" &&
                      key !== "cost" &&
                      key !== "list_price" &&
                      key !== "index"
                    ) {
                      objValues.push(_post[item][postIndex][key]);
                    }
                  }

                  const allNullFlag = objValues.every((item: any) => {
                    return item === "";
                  });

                  if (allNullFlag) {
                    obj.updated_flg = "2";
                    obj.seq_no = _post[item][postIndex].seq_no;
                    obj.updated_date = _post[item][postIndex].updated_date;
                  } else {
                    if (item === "promotion_customer") {
                      if (
                        _post[item][postIndex]["customer_no"] !==
                        _default[item][postIndex]["customer_no"]
                      ) {
                        obj.updated_flg = "1";

                        if (_post[item][postIndex]["customer_no"] !== "") {
                          for (let key in _post[item][postIndex]) {
                            if (key !== "index" && key !== "data_type") {
                              if (key === "customer_no") {
                                obj[key] = Number(_post[item][postIndex][key]);
                              } else {
                                obj[key] = "";
                              }
                            }
                          }
                        } else {
                          for (let key in _post[item][postIndex]) {
                            if (key !== "index" && key !== "data_type") {
                              obj[key] = _post[item][postIndex][key];
                            }
                          }
                        }

                        obj.updated_date = _post[item][postIndex].updated_date;
                        obj.data_type = _post[item][postIndex].data_type;

                        obj.seq_no = _post[item][postIndex].seq_no;
                      } else if (
                        !_post[item][postIndex]["customer_no"] ||
                        _post[item][postIndex]["customer_no"] === ""
                      ) {
                        let change_flag = 0;
                        for (let key in _post[item][postIndex]) {
                          if (key !== "index" && key !== "data_type") {
                            if (
                              _post[item][postIndex][key] !==
                              _default[item][postIndex][key]
                            ) {
                              change_flag = 1;
                              obj[key] = _post[item][postIndex][key];
                            }
                          }
                        }
                        if (change_flag === 1) {
                          obj.updated_flg = "1";
                          obj.updated_date =
                            _post[item][postIndex].updated_date;
                          obj.data_type = _post[item][postIndex].data_type;

                          obj.seq_no = _post[item][postIndex].seq_no;
                        }
                      }
                    } else {
                      if (
                        _post[item][postIndex]["item_no"] !==
                        _default[item][postIndex]["item_no"]
                      ) {
                        obj.updated_flg = "1";

                        if (_post[item][postIndex]["item_no"] !== "") {
                          for (let key in _post[item][postIndex]) {
                            if (
                              key !== "index" &&
                              key !== "data_type" &&
                              key !== "cost" &&
                              key !== "list_price"
                            ) {
                              if (
                                key === "discount_code" ||
                                key === "item_class" ||
                                key === "description" ||
                                key === "isac1" ||
                                key === "isac2" ||
                                key === "isac3" ||
                                key === "isac4" ||
                                key === "isac5" ||
                                key === "isac6" ||
                                key === "isac7" ||
                                key === "isac8"
                              ) {
                                obj[key] = "";
                              } else if (key === "special_price") {
                                obj[key] = _post[item][postIndex][key];
                              } else {
                                if (
                                  _post[item][postIndex][key] !==
                                  _default[item][postIndex][key]
                                ) {
                                  if (_post.promo_type !== "10") {
                                    if (
                                      item === "category_considered" &&
                                      key === "unit_packet"
                                    ) {
                                      obj["quantity"] =
                                        _post[item][postIndex][key];
                                    } else {
                                      obj[key] = _post[item][postIndex][key];
                                    }
                                  } else {
                                    obj[key] = _post[item][postIndex][key];
                                  }
                                }
                              }
                            }
                          }
                        } else {
                          for (let key in _post[item][postIndex]) {
                            if (
                              key !== "index" &&
                              key !== "data_type" &&
                              key !== "cost" &&
                              key !== "list_price"
                            ) {
                              if (
                                key === "discount_code" ||
                                key === "item_class" ||
                                key === "description" ||
                                key === "isac1" ||
                                key === "isac2" ||
                                key === "isac3" ||
                                key === "isac4" ||
                                key === "isac5" ||
                                key === "isac6" ||
                                key === "isac7" ||
                                key === "isac8"
                              ) {
                                obj[key] = _post[item][postIndex][key];
                              } else {
                                if (
                                  _post[item][postIndex][key] !==
                                  _default[item][postIndex][key]
                                ) {
                                  if (_post.promo_type !== "10") {
                                    if (
                                      item === "category_considered" &&
                                      key === "unit_packet"
                                    ) {
                                      obj["quantity"] =
                                        _post[item][postIndex][key];
                                    } else {
                                      obj[key] = _post[item][postIndex][key];
                                    }
                                  } else {
                                    obj[key] = _post[item][postIndex][key];
                                  }
                                }
                              }
                            }
                          }
                        }
                        obj.updated_date = _post[item][postIndex].updated_date;
                        obj.data_type = _post[item][postIndex].data_type;
                        obj.seq_no = _post[item][postIndex].seq_no;
                      } else if (
                        !_post[item][postIndex]["item_no"] ||
                        _post[item][postIndex]["item_no"] === ""
                      ) {
                        let change_flag = 0;

                        for (let key in _post[item][postIndex]) {
                          if (
                            key !== "index" &&
                            key !== "data_type" &&
                            key !== "cost" &&
                            key !== "list_price"
                          ) {
                            if (
                              _post[item][postIndex][key] !==
                              _default[item][postIndex][key]
                            ) {
                              change_flag = 1;

                              if (_post.promo_type !== "10") {
                                if (
                                  item === "category_considered" &&
                                  key === "unit_packet"
                                ) {
                                  obj["quantity"] = _post[item][postIndex][key];
                                } else {
                                  obj[key] = _post[item][postIndex][key];
                                }
                              } else {
                                obj[key] = _post[item][postIndex][key];
                              }
                            }
                          }
                        }

                        if (change_flag === 1) {
                          obj.updated_flg = "1";
                          obj.updated_date =
                            _post[item][postIndex].updated_date;
                          obj.data_type = _post[item][postIndex].data_type;

                          obj.seq_no = _post[item][postIndex].seq_no;
                        }
                      } else if (_post[item][postIndex]["item_no"] !== "") {
                        let change_flag = 0;

                        for (let key in _post[item][postIndex]) {
                          if (
                            key !== "index" &&
                            key !== "data_type" &&
                            key !== "cost" &&
                            key !== "list_price"
                          ) {
                            if (
                              key !== "discount_code" &&
                              key !== "item_class" &&
                              key !== "description" &&
                              key !== "isac1" &&
                              key !== "isac2" &&
                              key !== "isac3" &&
                              key !== "isac4" &&
                              key !== "isac5" &&
                              key !== "isac6" &&
                              key !== "isac7" &&
                              key !== "isac8"
                            ) {
                              if (
                                _post[item][postIndex][key] !==
                                _default[item][postIndex][key]
                              ) {
                                change_flag = 1;

                                if (_post.promo_type !== "10") {
                                  if (
                                    item === "category_considered" &&
                                    key === "unit_packet"
                                  ) {
                                    obj["quantity"] =
                                      _post[item][postIndex][key];
                                  } else {
                                    obj[key] = _post[item][postIndex][key];
                                  }
                                } else {
                                  obj[key] = _post[item][postIndex][key];
                                }
                              }
                            }
                          }
                        }

                        if (change_flag === 1) {
                          obj.updated_flg = "1";
                          obj.updated_date =
                            _post[item][postIndex].updated_date;
                          obj.data_type = _post[item][postIndex].data_type;

                          obj.seq_no = _post[item][postIndex].seq_no;
                        }
                      }
                    }
                  }
                }

                if (Object.keys(obj).length > 0) {
                  if (item === "promotion_customer") {
                    arrayList.push(obj);
                  } else {
                    promotionDetail.push(obj);
                  }
                }
              });
            }

            // new add
            if (
              (item !== "condition_unit_base" &&
                item !== "condition_amount_base" &&
                item !== "condition_single" &&
                item !== "condition_set") ||
              ((item === "condition_unit_base" ||
                item === "condition_amount_base") &&
                _post.unitBase_or_amountBase ===
                  _default.unitBase_or_amountBase) ||
              ((item === "condition_single" || item === "condition_set") &&
                _post.single_or_set === _default.single_or_set)
            ) {
              _post[item].forEach((postChild: any, index: number) => {
                const obj: any = {};
                if (postChild.seq_no === "" || !("seq_no" in postChild)) {
                  for (let key in postChild) {
                    if (key !== "index" && key !== "data_type") {
                      if (postChild[key] !== "") {
                        obj.updated_flg = "0";
                        if (key === "customer_no") {
                          if (postChild[key] === "") {
                            obj[key] = "";
                          } else {
                            obj[key] = Number(postChild[key]);
                          }
                        } else {
                          if (_post.promo_type !== "10") {
                            if (
                              item === "category_considered" &&
                              key === "unit_packet"
                            ) {
                              obj["quantity"] = postChild[key];
                            } else {
                              obj[key] = postChild[key];
                            }
                          } else {
                            obj[key] = postChild[key];
                          }
                        }
                      }
                    }
                  }
                  if (item === "condition_set") {
                    obj.special_price = specialPrice;
                  }
                }

                if (Object.keys(obj).length > 0) {
                  if (item === "promotion_customer") {
                    arrayList.push(obj);
                  } else {
                    obj.data_type = postChild.data_type;
                    promotionDetail.push(obj);
                  }
                }
              });
            }
          }
        } else {
          if (item === "promo_name") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "priority") {
            if (
              _post.promo_type === "10" ||
              _post.promo_type === "30" ||
              _post.promo_type === "40" ||
              _post.promo_type === "50"
            ) {
              if (_post[item] !== _default[item]) {
                postRequestData[item] = _post[item];
              }
            }
          } else if (item === "analysis_cd") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "allow_overlap") {
            if (
              _post.promo_type === "10" ||
              _post.promo_type === "30" ||
              _post.promo_type === "40" ||
              _post.promo_type === "50"
            ) {
              if (_post[item] !== _default[item]) {
                postRequestData[item] = _post[item];
              }
            }
          } else if (item === "exclusive_flag") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "webshop_flag") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "others_flag") {
            if (_post[item] !== _default[item]) {
              postRequestData[item] = _post[item];
            }
          } else if (item === "start_date") {
            if (_post[item] !== _default[item]) {
              if (!isNaN(Date.parse(dateFormat(new Date(_post[item]))))) {
                postRequestData[item] = dateFormat(new Date(_post[item]));
              }
            }
          } else if (item === "end_date") {
            if (_post[item] !== _default[item]) {
              if (
                _post[item] === "" ||
                _post[item] === null ||
                isNaN(Date.parse(dateFormat(new Date(_post[item]))))
              ) {
                postRequestData[item] = "9999-12-31";
              } else {
                postRequestData[item] = dateFormat(new Date(_post[item]));
              }
            }
          } else if (item === "draft_flag") {
            postRequestData[item] = type;
          } else if (_post.promo_type === "60") {
            if (item === "terms_cd") {
              if (_post[item] !== _default[item]) {
                postRequestData[item] = _post[item];
              }
            }
          }
        }
      });

      if (arrayList.length > 0) {
        postRequestData["promotion_customer"] = arrayList;
      }
      if (promotionDetail.length > 0) {
        postRequestData["promotion_detail"] = promotionDetail;
      }

      postRequestData.updated_date = _default.updated_date;

      if (_post.promo_type === "40") {
        if (_post.single_or_set === "2") {
          if (
            Number(specialPrice) !==
            Number(_default.condition_set[0].special_price)
          ) {
            const singleSeqNo = _post.condition_single[0].seq_no;
            const postSeqNo: any[] = [];

            const setSeqNo: any[] = [];
            _default.condition_set.forEach((item: any) => {
              setSeqNo.push(item.seq_no);
            });

            if (
              postRequestData["promotion_detail"] &&
              postRequestData["promotion_detail"].length > 0
            ) {
              if (singleSeqNo) {
                postRequestData["promotion_detail"].forEach((item: any) => {
                  if (item.seq_no !== singleSeqNo) {
                    item.special_price = specialPrice;
                    postSeqNo.push(item.seq_no);
                  }
                });
              } else {
                postRequestData["promotion_detail"].forEach((item: any) => {
                  item.special_price = specialPrice;
                  postSeqNo.push(item.seq_no);
                });
              }

              // setSeqNo.forEach((item: any) => {
              for (let index = 0; index < setSeqNo.length; index++) {
                let item = setSeqNo[index];
                if (postSeqNo.indexOf(item) === -1) {
                  const obj: any = {};
                  obj.special_price = specialPrice;
                  obj.updated_flg = "1";
                  obj.seq_no = item;
                  obj.updated_date =
                    _default["condition_set"][index].updated_date;
                  obj.data_type = _default["condition_set"][index].data_type;

                  postRequestData["promotion_detail"].push(obj);
                }
                // });
              }
            } else {
              postRequestData["promotion_detail"] = [];
              // setSeqNo.forEach((item: any) => {
              for (let index = 0; index < setSeqNo.length; index++) {
                let item = setSeqNo[index];
                const obj: any = {};
                obj.special_price = specialPrice;
                obj.updated_flg = "1";
                obj.seq_no = item;
                obj.updated_date =
                  _default["condition_set"][index].updated_date;
                obj.data_type = _default["condition_set"][index].data_type;

                postRequestData["promotion_detail"].push(obj);
              }
              // });
            }
          }
        }
      }

      setSaveChanges(false);
    }

    let _promoNo: any = "";

    if (fromDraftsFlag) {
      // get promo number
      _promoNo = Number(getNewPromoNo());
    } else {
      _promoNo = promoNo;
    }
    postRequestData = parsePostData(postRequestData);
    let webshopReleaseList = [];
    if (
      postRequestData.promotion_detail &&
      postRequestData.promotion_detail.length > 0
    ) {
      webshopReleaseList = postRequestData.promotion_detail.filter(
        (itemObject: any) => {
          if (
            (itemObject.data_type === "1" ||
              itemObject.data_type === "2" ||
              itemObject.data_type === "3" ||
              itemObject.data_type === "6" ||
              itemObject.data_type === "7") &&
            (itemObject.updated_flg === "0" ||
              itemObject.updated_flg === "1") &&
            itemObject.hasOwnProperty("webshop_release") &&
            itemObject.webshop_release === 0
          ) {
            return true;
          }
        }
      );

      postRequestData.promotion_detail.forEach((itemObject: any) => {
        if (itemObject.hasOwnProperty("webshop_release")) {
          delete itemObject["webshop_release"];
        }
      });
    }
    // if (webshopReleaseList.length > 0) {
    //   setPostRequestDataState(postRequestData);
    //   setPromoNoState(_promoNo);

    //   setTypeState(type);
    //   setChangeItemWebshopReleaseData(webshopReleaseList);
    //   //setWebshopReleaseFlag(true);
    // } else {
    setChangeItemWebshopReleaseData([]);
    callUpdatePromotionAPI(_promoNo, type, postRequestData);
    // }
  };

  const callUpdatePromotionAPI = (
    paraPromotionNo?: any,
    paraType?: any,
    paraPostRequestData?: any
  ) => {
    let postRequestData: any;
    let type: any;
    let promotionNo: any;
    if (paraPromotionNo) {
      promotionNo = paraPromotionNo;
      postRequestData = paraPostRequestData;
      type = paraType;
    } else {
      promotionNo = promoNoState;
      postRequestData = postRequestDataState;
      type = typeState;
    }

    if (type === "1") {
      let default_condition_amount_base_flag = true;
      if (
        postRequestData.promo_sub_type === 4 &&
        "promotion_detail" in postRequestData
      ) {
        //unit base

        if ("condition_amount_base" in defaultValueList) {
          for (
            let i = 0;
            i < defaultValueList.condition_amount_base.length;
            i++
          ) {
            if (
              (defaultValueList.condition_amount_base[i].purchase_amount !==
                null &&
                defaultValueList.condition_amount_base[i].purchase_amount !==
                  "") ||
              (defaultValueList.condition_amount_base[i].discount_rate !==
                null &&
                defaultValueList.condition_amount_base[i].discount_rate !== "")
            ) {
              default_condition_amount_base_flag = false;
              break;
            }
          }

          if (default_condition_amount_base_flag) {
            for (let i = 0; i < postRequestData.promotion_detail.length; i++) {
              if (
                postRequestData.promotion_detail[i].data_type === "5" &&
                postRequestData.promotion_detail[i].updated_flg === "2"
              ) {
                postRequestData.promotion_detail.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      if (
        postRequestData.promo_sub_type === 5 &&
        "promotion_detail" in postRequestData
      ) {
        if ("condition_unit_base" in defaultValueList) {
          for (
            let i = 0;
            i < defaultValueList.condition_unit_base.length;
            i++
          ) {
            if (
              (defaultValueList.condition_unit_base[i].packet !== null &&
                defaultValueList.condition_unit_base[i].packet !== "") ||
              (defaultValueList.condition_unit_base[i].discount_rate !== null &&
                defaultValueList.condition_unit_base[i].discount_rate !== "")
            ) {
              default_condition_amount_base_flag = false;
              break;
            }
          }

          if (default_condition_amount_base_flag) {
            for (let i = 0; i < postRequestData.promotion_detail.length; i++) {
              if (
                postRequestData.promotion_detail[i].data_type === "4" &&
                postRequestData.promotion_detail[i].updated_flg === "2"
              ) {
                postRequestData.promotion_detail.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      if (
        postRequestData.promo_sub_type === 6 &&
        "promotion_detail" in postRequestData
      ) {
        if ("condition_set" in defaultValueList) {
          const defaultFileterMethod = (detailItem: any) => {
            if (
              (detailItem.item_no === null || detailItem.item_no === "") &&
              (detailItem.quantity === null || detailItem.quantity === "") &&
              (detailItem.special_price === null ||
                detailItem.special_price === "")
            ) {
              return detailItem.seq_no;
            }
          };

          const seq_no_list =
            defaultValueList.condition_set.filter(defaultFileterMethod);

          if (seq_no_list.length > 0) {
            const fileterMethod = (detailItem: any) => {
              if (
                !(
                  detailItem.data_type === "7" && detailItem.updated_flg === "2"
                )
              ) {
                return detailItem;
              } else {
                const seq_no = seq_no_list.find((item: any) => {
                  if (item.seq_no === detailItem.seq_no) {
                    return detailItem.seq_no;
                  }
                });

                if (seq_no === undefined) {
                  return detailItem;
                }
              }
            };
            postRequestData.promotion_detail =
              postRequestData.promotion_detail.filter(fileterMethod);
          }
        }
      }
      if (
        postRequestData.promo_sub_type === 7 &&
        "promotion_detail" in postRequestData
      ) {
        if ("condition_single" in defaultValueList) {
          for (let i = 0; i < defaultValueList.condition_single.length; i++) {
            if (
              (defaultValueList.condition_single[i].item_no !== null &&
                defaultValueList.condition_single[i].item_no !== "") ||
              (defaultValueList.condition_single[i].quantity !== null &&
                defaultValueList.condition_single[i].quantity !== "") ||
              (defaultValueList.condition_single[i].special_price !== null &&
                defaultValueList.condition_single[i].special_price !== "")
            ) {
              default_condition_amount_base_flag = false;
              break;
            }
          }
          if (default_condition_amount_base_flag) {
            const fileterMethod = (detailItem: any) => {
              if (
                !(
                  detailItem.data_type === "6" && detailItem.updated_flg === "2"
                )
              ) {
                return detailItem;
              }
            };
            postRequestData.promotion_detail =
              postRequestData.promotion_detail.filter(fileterMethod);
          }
        }
      }
    }

    if (type === "1") {
      postRequestData.duplicate_check_flag = "false";
    } else {
      postRequestData.duplicate_check_flag = "true";
    }
    postRequestData.promoNo = promotionNo;

    apiLoading(true);

    api
      .updatePromotionAPI(config, promotionNo, postRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        setSaveChanges(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          if (type === "1") {
            // save to draft
            setSaveToDrafts(true);
          } else if (type === "0") {
            // register promotion
            if (
              response &&
              response.length > 0 &&
              response[0].message_id === "MA0009"
            ) {
              // warningMessage("MA0009", response[0].duplicate_promo_no);
              setPagePostRequestData(
                JSON.parse(JSON.stringify(postRequestData))
              );
              setForceDraftRegisterDisplay(true);
            } else {
              setDisabledSuccess(true);
            }
          } else {
            if (
              response &&
              response.length > 0 &&
              response[0].message_id === "MA0009"
            ) {
              // warningMessage("MA0009", response[0].duplicate_promo_no);
              setPagePostRequestData(
                JSON.parse(JSON.stringify(postRequestData))
              );
              setForceUpdateDisplay(true);
            } else {
              setRegisterPromotion(true);
              setOnfocusFlag(false);

              setTimeout(() => {
                window.scrollTo(0, 0);
                handleFresh(true);
              }, 100);
            }
          }
        }
      });
  };

  const parsePostData = (postRequestData: any) => {
    if ("promotion_customer" in postRequestData) {
      for (let i = 0; i < postRequestData.promotion_customer.length; i++) {
        if ("customer_no" in postRequestData.promotion_customer[i]) {
          if (postRequestData.promotion_customer[i].customer_no === "") {
            postRequestData.promotion_customer[i].customer_no = null;
          }
        }
        if (
          "customer_no" in postRequestData.promotion_customer[i] &&
          postRequestData.promotion_customer[i].customer_no !== null
        ) {
          for (let key in postRequestData.promotion_customer[i]) {
            if (
              !(
                key === "customer_no" ||
                key === "updated_flg" ||
                key === "updated_date" ||
                key === "seq_no"
              )
            ) {
              delete postRequestData.promotion_customer[i][key];
            }
          }
        } else {
          if (
            isEmptyStringOrNumber(postRequestData.promotion_customer[i].seq_no)
          ) {
            for (let key in postRequestData.promotion_customer[i]) {
              if (
                !(
                  key === "updated_flg" ||
                  key === "updated_date" ||
                  key === "seq_no"
                )
              ) {
                if (
                  !("updated_flg" in postRequestData.promotion_customer[i]) ||
                  postRequestData.promotion_customer[i]["updated_flg"] === "0"
                ) {
                  if (
                    postRequestData.promotion_customer[i][key] === null ||
                    postRequestData.promotion_customer[i][key] === ""
                  ) {
                    delete postRequestData.promotion_customer[i][key];
                  }
                }
              }
            }
          } else {
            let defaultObject = defaultValueList.promotion_customer.find(
              (defaultValueObject: any) => {
                return (
                  defaultValueObject.seq_no ===
                  postRequestData.promotion_customer[i]["seq_no"]
                );
              }
            );
            if (defaultObject === undefined) {
              if (
                postRequestData.promotion_customer[i]["updated_flg"] === "2"
              ) {
                for (let key in postRequestData.promotion_customer[i]) {
                  if (
                    !(
                      key === "updated_flg" ||
                      key === "updated_date" ||
                      key === "seq_no"
                    )
                  ) {
                    delete postRequestData.promotion_customer[i][key];
                  }
                }
              } else if (
                postRequestData.promotion_customer[i]["updated_flg"] === "0"
              ) {
                for (let key in postRequestData.promotion_customer[i]) {
                  if (!(key === "updated_flg" || key === "updated_date")) {
                    if (
                      postRequestData.promotion_customer[i][key] === null ||
                      postRequestData.promotion_customer[i][key] === ""
                    ) {
                      delete postRequestData.promotion_customer[i][key];
                    }
                  }
                }
              }
            } else if (
              !("customer_no" in defaultObject) ||
              defaultObject.customer_no === null ||
              defaultObject.customer_no === ""
            ) {
              delete postRequestData.promotion_customer[i].customer_no;

              for (let key in postRequestData.promotion_customer[i]) {
                if (
                  !(
                    key === "customer_no" ||
                    key === "updated_flg" ||
                    key === "updated_date" ||
                    key === "seq_no"
                  )
                ) {
                  if (
                    defaultObject[key] ===
                    postRequestData.promotion_customer[i][key]
                  ) {
                    delete postRequestData.promotion_customer[i][key];
                  }
                }
              }
            } else {
              let customerNoOthersFlag = false;
              for (let key in postRequestData.promotion_customer[i]) {
                if (
                  !(
                    key === "customer_no" ||
                    key === "updated_flg" ||
                    key === "updated_date" ||
                    key === "seq_no"
                  )
                ) {
                  if (
                    postRequestData.promotion_customer[i][key] === null ||
                    postRequestData.promotion_customer[i][key] === ""
                  ) {
                    delete postRequestData.promotion_customer[i][key];
                  } else {
                    customerNoOthersFlag = true;
                  }
                }
              }
              if (!customerNoOthersFlag) {
                postRequestData.promotion_customer[i].updated_flg = "2";
              }
            }
          }
        }
      }
    }
    if ("promotion_detail" in postRequestData) {
      for (let i = 0; i < postRequestData.promotion_detail.length; i++) {
        if (
          "item_no" in postRequestData.promotion_detail[i] &&
          postRequestData.promotion_detail[i].item_no !== null &&
          postRequestData.promotion_detail[i].item_no !== ""
        ) {
          for (let key in postRequestData.promotion_detail[i]) {
            if (
              !(
                key === "item_no" ||
                key === "updated_flg" ||
                key === "updated_date" ||
                key === "seq_no" ||
                key === "data_type" ||
                key === "unit_packet" ||
                key === "quantity" ||
                key === "special_price" ||
                key === "single_type" ||
                key === "webshop_release"
              )
            ) {
              delete postRequestData.promotion_detail[i][key];
            }
          }

          if (postRequestData.promotion_detail[i]["updated_flg"] === "1") {
            if (
              !isEmptyStringOrNumber(postRequestData.promotion_detail[i].seq_no)
            ) {
              let defaultObject = getDefaultObject(
                postRequestData.promotion_detail[i]["seq_no"]
              );
              if ("unit_packet" in postRequestData.promotion_detail[i]) {
                if (
                  postRequestData.promotion_detail[i]["unit_packet"] ===
                  defaultObject["unit_packet"]
                ) {
                  delete postRequestData.promotion_detail[i]["unit_packet"];
                }
              } else if ("quantity" in postRequestData.promotion_detail[i]) {
                let default_quantity;
                if ("unit_packet" in defaultObject) {
                  default_quantity = defaultObject["unit_packet"];
                } else {
                  default_quantity = defaultObject["quantity"];
                }

                if (
                  postRequestData.promotion_detail[i]["quantity"] ===
                  default_quantity
                ) {
                  delete postRequestData.promotion_detail[i]["quantity"];
                }
              }

              if ("special_price" in postRequestData.promotion_detail[i]) {
                if (
                  postRequestData.promotion_detail[i]["special_price"] ===
                    defaultObject["special_price"] &&
                  postRequestData.promotion_detail[i]["item_no"] ===
                    defaultObject["item_no"]
                ) {
                  delete postRequestData.promotion_detail[i]["special_price"];
                }
              }

              if ("single_type" in postRequestData.promotion_detail[i]) {
                if (
                  postRequestData.promotion_detail[i]["single_type"] ===
                  defaultObject["single_type"]
                ) {
                  delete postRequestData.promotion_detail[i]["single_type"];
                }
              }
            }
          }
        } else {
          if (
            isEmptyStringOrNumber(postRequestData.promotion_detail[i].seq_no)
          ) {
            for (let key in postRequestData.promotion_detail[i]) {
              if (
                !(
                  key === "updated_flg" ||
                  key === "updated_date" ||
                  key === "seq_no" ||
                  key === "data_type" ||
                  key === "single_type"
                )
              ) {
                if (
                  postRequestData.promotion_detail[i]["updated_flg"] === "0"
                ) {
                  if (
                    postRequestData.promotion_detail[i][key] === null ||
                    postRequestData.promotion_detail[i][key] === ""
                  ) {
                    delete postRequestData.promotion_detail[i][key];
                  }
                }
              }
            }
          } else {
            let defaultObject = getDefaultObject(
              postRequestData.promotion_detail[i]["seq_no"]
            );

            if (defaultObject === undefined) {
              if (postRequestData.promotion_detail[i]["updated_flg"] === "2") {
                for (let key in postRequestData.promotion_detail[i]) {
                  if (
                    !(
                      key === "updated_flg" ||
                      key === "updated_date" ||
                      key === "seq_no"
                    )
                  ) {
                    delete postRequestData.promotion_detail[i][key];
                  }
                }
              } else if (
                postRequestData.promotion_detail[i]["updated_flg"] === "0"
              ) {
                for (let key in postRequestData.promotion_detail[i]) {
                  if (!(key === "updated_flg" || key === "updated_date")) {
                    if (
                      postRequestData.promotion_detail[i][key] === null ||
                      postRequestData.promotion_detail[i][key] === ""
                    ) {
                      delete postRequestData.promotion_detail[i][key];
                    }
                  }
                }
              }
            } else if (
              !("item_no" in defaultObject) ||
              defaultObject.item_no === null ||
              defaultObject.item_no === ""
            ) {
              delete postRequestData.promotion_detail[i].item_no;

              for (let key in postRequestData.promotion_detail[i]) {
                if (
                  !(
                    key === "item_no" ||
                    key === "updated_flg" ||
                    key === "updated_date" ||
                    key === "seq_no" ||
                    key === "data_type"
                  )
                ) {
                  if (
                    defaultObject[key] ===
                    postRequestData.promotion_detail[i][key]
                  ) {
                    delete postRequestData.promotion_detail[i][key];
                  }
                }
              }
            } else {
              let detailNoOthersFlag = false;
              for (let key in postRequestData.promotion_detail[i]) {
                if (
                  !(
                    key === "item_no" ||
                    key === "updated_flg" ||
                    key === "updated_date" ||
                    key === "seq_no" ||
                    key === "data_type"
                  )
                ) {
                  if (
                    (key === "unit_packet" || key === "quantity") &&
                    ("unit_packet" in defaultObject ||
                      "quantity" in defaultObject)
                  ) {
                    let default_quantity;
                    if ("unit_packet" in defaultObject) {
                      default_quantity = defaultObject["unit_packet"];
                    } else {
                      default_quantity = defaultObject["quantity"];
                    }

                    if (
                      default_quantity ===
                      postRequestData.promotion_detail[i][key]
                    ) {
                      delete postRequestData.promotion_detail[i][key];
                    } else {
                      detailNoOthersFlag = true;
                    }
                  } else if (
                    postRequestData.promotion_detail[i][key] === null ||
                    postRequestData.promotion_detail[i][key] === ""
                  ) {
                    delete postRequestData.promotion_detail[i][key];
                  } else {
                    detailNoOthersFlag = true;
                  }
                }
              }
              if (!detailNoOthersFlag) {
                postRequestData.promotion_detail[i].updated_flg = "2";
              }
            }
          }
        }
        // if (postRequestData.promotion_detail[i].seq_no) {
        //   if (postRequestData.promotion_detail[i].updated_flg !== "2" && isNullValueOfPostData(postRequestData.promotion_detail[i])) {
        //     postRequestData.promotion_detail[i].updated_flg = "2";
        //   }
        // }
      }

      postRequestData.promotion_detail =
        postRequestData.promotion_detail.filter((item: any) => {
          if (item.seq_no) {
            // if (item.updated_flg !== "2" && isNullValueOfPostData(item)) {
            //     return false;
            // } else {
            //     return true;
            // }
            return true;
          } else {
            return !isNullValueOfPostData(item);
          }
        });
    }

    return postRequestData;
  };

  const isNullValueOfPostData = (dataObject: any) => {
    for (let key in dataObject) {
      if (
        !(
          key === "data_type" ||
          key === "updated_date" ||
          key === "updated_flg" ||
          key === "seq_no" ||
          key === "webshop_release"
        )
      ) {
        if (dataObject[key]) {
          return false;
        }
      }
    }
    return true;
  };

  const getDefaultObject = (seq_no: String) => {
    let defaultObject;

    if ("category_considered" in defaultValueList) {
      defaultObject = defaultValueList.category_considered.find(
        (defaultValueObject: any) => {
          return defaultValueObject.seq_no === seq_no;
        }
      );
    }

    if (defaultObject === undefined) {
      if ("category_excluded" in defaultValueList) {
        defaultObject = defaultValueList.category_excluded.find(
          (defaultValueObject: any) => {
            return defaultValueObject.seq_no === seq_no;
          }
        );
      }
    }

    if (defaultObject === undefined) {
      if ("free_goods" in defaultValueList) {
        defaultObject = defaultValueList.free_goods.find(
          (defaultValueObject: any) => {
            return defaultValueObject.seq_no === seq_no;
          }
        );
      }
    }

    if (defaultObject === undefined) {
      if ("condition_single" in defaultValueList) {
        defaultObject = defaultValueList.condition_single.find(
          (defaultValueObject: any) => {
            return defaultValueObject.seq_no === seq_no;
          }
        );
      }
    }

    if (defaultObject === undefined) {
      if ("condition_set" in defaultValueList) {
        defaultObject = defaultValueList.condition_set.find(
          (defaultValueObject: any) => {
            return defaultValueObject.seq_no === seq_no;
          }
        );
      }
    }

    return defaultObject;
  };

  const dateFormat = (data: any) => {
    const formatDoubleDigit = (data: any) => {
      return data >= 10 ? data : "0" + data;
    };
    const getYear = data.getFullYear();
    const getMonth = formatDoubleDigit(data.getMonth() + 1);
    const getDate = formatDoubleDigit(data.getDate());
    return getYear + "-" + getMonth + "-" + getDate;
  };

  const calcUnitPrice = (cost: any = 0, index: number) => {
    const getUnitPrice = JSON.parse(JSON.stringify(unitPrice));
    getUnitPrice.splice(index, 1, cost);
    setUnitPrice(getUnitPrice);
  };

  // delete promotion
  const deletePromotionHandler = () => {
    const _post: any = searchValueList;

    let params = {
      updated_date: _post.updated_date,
    };

    apiLoading(true);
    api
      .deletePromotionAPI(config, promoNo, params)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const message = getName("messages.MS0014");
          setAlertMessage(message);
          setAlertShow(true);
          setAlertType("success");
          handlePromotionList();
        }
        setDeletePromotion(false);
      });
  };

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  const buttonFocus = () => {
    console.log("buttonFocus");
  };

  // Drafts button click event
  const handleDrafts = () => {
    const path = GetPrivateAppRoutePath(PathKey.Drafts);
    handleOnClick(path);
  };

  // Yes button click event
  const handlePromotionList = () => {
    if (
      historyRouter.from === String(PathKey.ORDERDETAIL) ||
      historyRouter.from === String(PathKey.SIMULATE)
    ) {
      history.goBack();
    } else {
      const path = GetPrivateAppRoutePath(PathKey.PROMOTION);
      handleOnClick(path);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handlePromotionType = () => {
    setModalOpen(false);
    setProperty([]);
    setSearchValue("promo_type", promotionTypeValue, true);
  };

  const handleValidations = () => {
    setCheckResultValue("");
  };

  const handleRegisterCancel = () => {
    setCheckResultValue("");
  };

  const handleRegisterSuccess = () => {
    setCheckResultValue("");

    if (!fromDraftsFlag) {
      postDataHandler("0");
    } else {
      editDataHandler("0");
    }
  };

  const handleQuitwithoutSaving = () => {
    if (registerPromotion) {
      handlePromotionList();
    } else {
      setDraftsQuitWithoutSaving(true);
    }
  };

  const handleQuitwithoutSavingClose = () => {
    setDraftsQuitWithoutSaving(false);
  };

  const handleSaveToDrafts = () => {
    if (hasError(errorValueList)) {
      return;
    }

    if (!fromDraftsFlag) {
      postDataHandler("1");
    } else {
      editDataHandler("1");
    }
  };

  const handleFresh = (saveFlag: boolean) => {
    if (saveFlag) {
      const message = getName("messages.MS0058");
      setAlertMessage(message);
      setAlertShow(true);
      setAlertType("success");
    } else {
      setAlertMessage("");
      setAlertShow(false);
    }

    setHasSeqNoRow(true);
    setDiscardChanges(false);
    getDefaultApiList(saveFlag);
  };

  const handleReturnToPromotionList = () => {
    if (registerPromotion) {
      handlePromotionList();
    } else {
      setReturnToPromotionList(true);
    }
  };

  const handleDiscardChanges = () => {
    setDiscardChanges(true);
  };

  const handleReturnToPromotionListYes = () => {
    setReturnToPromotionList(false);
  };

  const handleForceRegisterToPromotionListYes = () => {
    setForceRegisterDisplay(false);
  };

  const handleForceDraftRegisterToPromotionListYes = () => {
    setForceDraftRegisterDisplay(false);
  };

  const handleForceUpdateToPromotionListYes = () => {
    setForceUpdateDisplay(false);
  };

  const handleDiscardChangesYes = () => {
    init(responseData, plannerCodeData, paymentTermList, analysisList);
    setDiscardChanges(false);
    setHasSeqNoRow(true);
  };

  const handleDiscardChangesNo = () => {
    setDiscardChanges(false);
  };

  const handleDeletePromotion = () => {
    setDeletePromotion(true);
  };

  const handleCloseDeletePromotion = () => {
    setDeletePromotion(false);
  };

  const handleDeletePromotionYes = () => {
    deletePromotionHandler();
  };

  const handleCloseSaveChanges = () => {
    setSaveChanges(false);
  };

  // const handleCreateWebshopReleaseConfirmNo = () => {
  //   setCreateWebshopReleaseFlag(false);
  // };

  // const handleCreateWebshopReleaseConfirmYes = () => {
  //   setCreateWebshopReleaseFlag(false);
  //   callCreatePromotionAPI();
  // };

  // const handleWebshopReleaseConfirmNo = () => {
  //   setWebshopReleaseFlag(false);
  // };

  // const handleWebshopReleaseConfirmYes = () => {
  //   setWebshopReleaseFlag(false);
  //   callUpdatePromotionAPI();
  // };

  const handleAlertCustomerNoConfirmNo = () => {
    setAlertCustomerNoMessageFlag(false);
    const setCustomerField = JSON.parse(
      JSON.stringify(searchValueList.promotion_customer)
    );
    setCustomerField[customerInfoIndex].customer_no = "";
    setCustomerField[customerInfoIndex].customer_type = "";
    setCustomerField[customerInfoIndex].customer_name = "";
    setCustomerField[customerInfoIndex].discount_code = "";
    setCustomerField[customerInfoIndex].csac1 = "";
    setCustomerField[customerInfoIndex].csac2 = "";
    setCustomerField[customerInfoIndex].csac3 = "";
    setCustomerField[customerInfoIndex].csac4 = "";
    setCustomerField[customerInfoIndex].csac5 = "";
    setCustomerField[customerInfoIndex].csac6 = "";
    setCustomerField[customerInfoIndex].csac7 = "";
    setCustomerField[customerInfoIndex].csac8 = "";
    setCustomerField[customerInfoIndex].csac9 = "";
    setCustomerField[customerInfoIndex].csac10 = "";
    setCustomerField[customerInfoIndex].promotion_region = "";
    setSearchValue("promotion_customer", setCustomerField);
    const editListAry = JSON.parse(JSON.stringify(customerEditList));
    editListAry.splice(customerInfoIndex, 1, true);
    setCustomerEditList(editListAry);
  };

  const handleAlertCustomerNoConfirmYes = () => {
    setAlertCustomerNoMessageFlag(false);
    setSearchValue("promotion_customer", customerInfo);
    const editListAry = JSON.parse(JSON.stringify(customerEditList));
    editListAry.splice(customerInfoIndex, 1, true);
    setCustomerEditList(editListAry);
  };

  const handleAlertItemNoConfirmNo = () => {
    setAlertItemNoMessageFlag(false);
    setItemNoWebshopReleaseFlag(false);
    setItemNoSuspendFlag(false);

    const setCategoryField = JSON.parse(
      JSON.stringify(searchValueList[itemNoTypeInfo])
    );

    setCategoryField[itemInfoIndex].item_no = "";
    setCategoryField[itemInfoIndex].item_class = "";
    setCategoryField[itemInfoIndex].discount_code = "";
    setCategoryField[itemInfoIndex].description = "";
    errorValueList[itemNoTypeInfo][itemInfoIndex].item_no_error = "";
    setCategoryField[itemInfoIndex].isac1 = "";
    setCategoryField[itemInfoIndex].isac2 = "";
    setCategoryField[itemInfoIndex].isac3 = "";
    setCategoryField[itemInfoIndex].isac4 = "";
    setCategoryField[itemInfoIndex].isac5 = "";
    setCategoryField[itemInfoIndex].isac6 = "";
    setCategoryField[itemInfoIndex].isac7 = "";
    setCategoryField[itemInfoIndex].isac8 = "";
    setSearchValue(itemNoTypeInfo, setCategoryField);
    if (itemNoTypeInfo === "category_considered") {
      consideredEditList.splice(itemInfoIndex, 1, false);
    } else if (itemNoTypeInfo === "category_excluded") {
      excludedEditList.splice(itemInfoIndex, 1, false);
    } else if (itemNoTypeInfo === "free_goods") {
      freeGoodsEditList.splice(itemInfoIndex, 1, false);
    } else if (itemNoTypeInfo === "condition_set") {
      calcUnitPrice(0, itemInfoIndex);
    }
  };

  const handleAlertItemNoConfirmYes = () => {
    setAlertItemNoMessageFlag(false);
    setItemNoWebshopReleaseFlag(false);
    setItemNoSuspendFlag(false);
    setSearchValue(itemNoTypeInfo, itemInfo);
    if (itemNoTypeInfo === "category_considered") {
      consideredEditList.splice(itemInfoIndex, 1, true);
    } else if (itemNoTypeInfo === "category_excluded") {
      excludedEditList.splice(itemInfoIndex, 1, true);
    } else if (itemNoTypeInfo === "free_goods") {
      freeGoodsEditList.splice(itemInfoIndex, 1, true);
    } else if (itemNoTypeInfo === "condition_set") {
      calcUnitPrice(itemResponseInfo.cost, itemInfoIndex);
    }
  };

  const handleCloseSaveYes = () => {
    editDataHandler();
  };

  // Yes button click event
  const handleForceRegister = () => {
    pagePostRequestData.duplicate_check_flag = "false";

    setForceRegisterDisplay(false);
    apiLoading(true);

    api
      .createPromotionAPI(config, pagePostRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setPromotionInformation(response);
          // register promotion
          setDisabledSuccess(true);
        }
      });
  };

  const handleForceDraftRegister = () => {
    pagePostRequestData.duplicate_check_flag = "false";
    const _promoNo = pagePostRequestData.promoNo;

    setForceDraftRegisterDisplay(false);
    apiLoading(true);

    api
      .updatePromotionAPI(config, _promoNo, pagePostRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        setSaveChanges(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setDisabledSuccess(true);
        }
      });
  };

  const handleForceUpdate = () => {
    pagePostRequestData.duplicate_check_flag = "false";
    const _promoNo = pagePostRequestData.promoNo;

    setForceDraftRegisterDisplay(false);
    setForceUpdateDisplay(false);
    apiLoading(true);

    api
      .updatePromotionAPI(config, _promoNo, pagePostRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        setSaveChanges(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setRegisterPromotion(true);
          setOnfocusFlag(false);

          setTimeout(() => {
            window.scrollTo(0, 0);
            handleFresh(true);
          }, 100);
        }
      });
  };

  const attentionRegister = <p>{getName("messages.MS0068")}</p>;

  const attentionDraftsQuitWithoutSaving = (
    <p data-testid="attentionDraftsQuitWithoutSaving">
      {getName("messages.MS0059")}
      <br />
      {getName("messages.MS0065")}
    </p>
  );

  const attentionChangePromotion = (
    <p data-testid="attentionChangePromotion">
      {getName("messages.MS0071")}
      <br />
      <br />
      {Object.keys(property).map((item: any) => {
        return (
          <>
            {property[item].map((option: any) => {
              if (option === "single_type") {
                return (
                  <>
                    <span>
                      {t([item])} :{" "}
                      {t([option === "1" ? "more_than" : "times"])}
                    </span>
                    <br />
                  </>
                );
              } else if (option === "promo_code_type") {
                return (
                  <>
                    <span>
                      {t([item])} :{" "}
                      {t([option === "1" ? "single_use" : "multiple_use"])}
                    </span>
                    <br />
                  </>
                );
              } else {
                return (
                  <>
                    <span>
                      {t([item])} : {t([option])}
                    </span>
                    <br />
                  </>
                );
              }
            })}
          </>
        );
      })}
      <br />
      {getName("messages.MS0072")}
    </p>
  );

  const attentionValidations = (
    <p>
      {getName("messages.MS0073")}
      <br />
      {getName("messages.MS0074")}
    </p>
  );

  const successSaveToDrafts = (
    <p>
      {getName("messages.MS0075")}
      {!fromDraftsFlag && (
        <>
          <br />
          {getName("messages.MS0076")}
          <span className={classes.fontBold}>
            {promotionInformation.promo_no}
          </span>
        </>
      )}
    </p>
  );

  // error message check
  const hasError = (errorData: any) => {
    for (let i in errorData) {
      if (typeof errorData[i] === "object") {
        if (hasError(errorData[i])) {
          return true;
        }
      } else {
        if (errorData[i].trim() !== "") {
          return true;
        }
      }
    }
    return false;
  };

  const successRegister = (
    <p>
      {getName("messages.MS0077")}
      {!fromDraftsFlag && (
        <>
          <br />
          {getName("messages.MS0078")}
          <span className={classes.fontBold} data-testid="info_promo_no">
            {promotionInformation.promo_no}
          </span>
          {searchValueList.promo_type === "50" && (
            <>
              <br />
              {getName("messages.MS0079")}
              <span className={classes.fontBold}>
                {promotionInformation.promo_code}
              </span>
            </>
          )}
        </>
      )}
    </p>
  );

  const attentionForceChangePromotion = <p>{getName("messages.MS0094")}</p>;

  const attentionReturnToPromotionList = (
    <p data-testid="attentionReturnToPromotionList">
      {getName("messages.MS0060")}
    </p>
  );

  const attentionDeletePromotion = <p>{getName("messages.MS0080")}</p>;

  const attentionSaveChanges = (
    <p data-testid="attentionSaveChanges">{getName("messages.MS0081")}</p>
  );

  const attentionRegisterButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        data-testid="prompt_button_No"
        onClick={handleRegisterCancel}
      >
        {com("prompt_button_No")}
      </Button>
      <Button
        variant="contained"
        data-testid="prompt_button_OK"
        onClick={handleRegisterSuccess}
      >
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const attentionDraftsQuitWithoutSavingButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleQuitwithoutSavingClose}
      >
        {com("prompt_button_No")}
      </Button>
      <Button
        variant="contained"
        data-testid="prompt_button_Yes"
        onClick={handlePromotionList}
      >
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionChangePromotionButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" color="secondary" onClick={handleClose}>
        {com("prompt_button_No")}
      </Button>
      <Button
        variant="contained"
        data-testid="prompt_button_Yes"
        onClick={handlePromotionType}
      >
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const successRegisterButton = (
    <div className={classes.singleButtonControl}>
      <Button
        variant="contained"
        data-testid="prompt_button_OK"
        onClick={handlePromotionList}
      >
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const attentionValidationsButton = (
    <div className={classes.singleButtonControl}>
      <Button variant="contained" onClick={handleValidations}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const successSaveToDraftsButton = (
    <div className={classes.singleButtonControl}>
      <Button
        data-testid="prompt_button_OK"
        variant="contained"
        onClick={handleDrafts}
      >
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const attentionReturnToPromotionListButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleReturnToPromotionListYes}
      >
        {com("prompt_button_No")}
      </Button>
      <Button
        data-testid="prompt_button_Yes"
        variant="contained"
        onClick={handlePromotionList}
      >
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionForceRegisterPromotionListButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleForceRegisterToPromotionListYes}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleForceRegister}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionForceDraftRegisterPromotionListButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleForceDraftRegisterToPromotionListYes}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleForceDraftRegister}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionForceUpdatePromotionListButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleForceUpdateToPromotionListYes}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleForceUpdate}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionDiscardChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        data-testid="prompt_button_No"
        variant="contained"
        color="secondary"
        onClick={handleDiscardChangesNo}
      >
        {com("prompt_button_No")}
      </Button>
      <Button
        data-testid="prompt_button_Yes"
        variant="contained"
        onClick={handleDiscardChangesYes}
      >
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionDeletePromotionButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseDeletePromotion}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleDeletePromotionYes}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  const attentionSaveChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        data-testid="prompt_button_No"
        variant="contained"
        color="secondary"
        onClick={handleCloseSaveChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button
        data-testid="prompt_button_Yes"
        variant="contained"
        onClick={handleCloseSaveYes}
      >
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // const webshopReleaseConfirmButton = (
  //   <div className={classes.buttonControl}>
  //     <Button
  //       variant="contained"
  //       color="secondary"
  //       onClick={handleWebshopReleaseConfirmNo}
  //     >
  //       {com("prompt_button_No")}
  //     </Button>
  //     <Button variant="contained" onClick={handleWebshopReleaseConfirmYes}>
  //       {com("prompt_button_Yes")}
  //     </Button>
  //   </div>
  // );

  // const createWebshopReleaseConfirmButton = (
  //   <div className={classes.buttonControl}>
  //     <Button
  //       variant="contained"
  //       color="secondary"
  //       onClick={handleCreateWebshopReleaseConfirmNo}
  //     >
  //       {com("prompt_button_No")}
  //     </Button>
  //     <Button
  //       variant="contained"
  //       onClick={handleCreateWebshopReleaseConfirmYes}
  //     >
  //       {com("prompt_button_Yes")}
  //     </Button>
  //   </div>
  // );

  const alertCustomerNoConfirmButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAlertCustomerNoConfirmNo}
      >
        {com("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" onClick={handleAlertCustomerNoConfirmYes}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const alertItemNoConfirmButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAlertItemNoConfirmNo}
      >
        {com("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" onClick={handleAlertItemNoConfirmYes}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  const getComponents = () => {
    if (
      searchValueList.promo_type === "10" ||
      searchValueList.promo_type === "20" ||
      searchValueList.promo_type === "40" ||
      searchValueList.promo_type === "50"
    ) {
      return (
        <Conditions
          searchValueList={searchValueList}
          setSearchValue={setSearchValue}
          oldData={oldValue}
          setOldData={setOldData}
          errorValueList={errorValueList}
          changeDataListen={changeDataListen}
          setErrorValueList={setErrorValueList}
          defaultValueList={defaultValueList}
          setDefaultValue={setDefaultValue}
          setSearchValueList={setSearchValueList}
          specialPrice={specialPrice}
          setSpecialPrice={setSpecialPrice}
          specialPriceFormat={specialPriceFormat}
          setSpecialPriceFormat={setSpecialPriceFormat}
          singleSpecialPriceFormat={singleSpecialPriceFormat}
          setSingleSpecialPriceFormat={setSingleSpecialPriceFormat}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categorySingle={categorySingle}
          categorySet={categorySet}
          setCategorySet={setCategorySet}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfItemNo={onFocusOfItemNo}
          onFocusOfSingleItem={onFocusOfSingleItem}
          plannerCodeChange={plannerCodeChange}
          numSelectedSet={numSelectedSet}
          setNumSelectedSet={setNumSelectedSet}
          selectedArraySet={selectedArraySet}
          setSelectedArraySet={setSelectedArraySet}
          selectedRowArraySet={selectedRowArraySet}
          setSelectedRowArraySet={setSelectedRowArraySet}
          indeterminateSet={indeterminateSet}
          setIndeterminateSet={setIndeterminateSet}
          allCheckedSet={allCheckedSet}
          setAllCheckedSet={setAllCheckedSet}
          categoryAll={categoryAll}
          numSelectedUnitBase={numSelectedUnitBase}
          setNumSelectedUnitBase={setNumSelectedUnitBase}
          selectedArrayUnitBase={selectedArrayUnitBase}
          setSelectedArrayUnitBase={setSelectedArrayUnitBase}
          selectedRowArrayUnitBase={selectedRowArrayUnitBase}
          setSelectedRowArrayUnitBase={setSelectedRowArrayUnitBase}
          indeterminateUnitBase={indeterminateUnitBase}
          setIndeterminateUnitBase={setIndeterminateUnitBase}
          allCheckedUnitBase={allCheckedUnitBase}
          setAllCheckedUnitBase={setAllCheckedUnitBase}
          numSelectedAmountBase={numSelectedAmountBase}
          setNumSelectedAmountBase={setNumSelectedAmountBase}
          selectedArrayAmountBase={selectedArrayAmountBase}
          setSelectedArrayAmountBase={setSelectedArrayAmountBase}
          selectedRowArrayAmountBase={selectedRowArrayAmountBase}
          setSelectedRowArrayAmountBase={setSelectedRowArrayAmountBase}
          indeterminateAmountBase={indeterminateAmountBase}
          setIndeterminateAmountBase={setIndeterminateAmountBase}
          allCheckedAmountBase={allCheckedAmountBase}
          setAllCheckedAmountBase={setAllCheckedAmountBase}
          setHasSeqNoRow={setHasSeqNoRow}
          hasSeqNoRow={hasSeqNoRow}
          setDefaultValueList={setDefaultValueList}
          reviewFlg={reviewFlg}
          onSetItemNoFlag={onSetItemNoFlag}
          unitPrice={unitPrice}
          setUnitPrice={setUnitPrice}
          calcUnitPrice={calcUnitPrice}
          onBlurOfSetItem={onBlurOfSetItem}
          onFocusOfSetItem={onFocusOfSetItem}
          salesAnalysisList={salesAnalysisList}
        />
      );
    } else if (searchValueList.promo_type === "30") {
      return (
        <FreeGoods
          searchValueList={searchValueList}
          setSearchValue={setSearchValue}
          oldData={oldValue}
          setOldData={setOldData}
          errorValueList={errorValueList}
          changeDataListen={changeDataListen}
          setErrorValueList={setErrorValueList}
          defaultValueList={defaultValueList}
          setDefaultValue={setDefaultValue}
          numSelectedFreeGoods={numSelectedFreeGoods}
          setNumSelectedFreeGoods={setNumSelectedFreeGoods}
          selectedArrayFreeGoods={selectedArrayFreeGoods}
          setSelectedArrayFreeGoods={setSelectedArrayFreeGoods}
          selectedRowArrayFreeGoods={selectedRowArrayFreeGoods}
          setSelectedRowArrayFreeGoods={setSelectedRowArrayFreeGoods}
          indeterminateFreeGoods={indeterminateFreeGoods}
          setIndeterminateFreeGoods={setIndeterminateFreeGoods}
          allCheckedFreeGoods={allCheckedFreeGoods}
          setAllCheckedFreeGoods={setAllCheckedFreeGoods}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categoryFreeGoods={categoryFreeGoods}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfItemNo={onFocusOfItemNo}
          freeGoodsEditList={freeGoodsEditList}
          setFreeGoodsEditList={setFreeGoodsEditList}
          plannerCodeChange={plannerCodeChange}
          setHasSeqNoRow={setHasSeqNoRow}
          hasSeqNoRow={hasSeqNoRow}
          reviewFlg={reviewFlg}
          onFreeGoodsItemNoFlag={onFreeGoodsItemNoFlag}
          salesAnalysisList={salesAnalysisList}
        />
      );
    } else if (searchValueList.promo_type === "60") {
      return (
        <Term
          searchValueList={searchValueList}
          setSearchValue={setSearchValue}
          errorValueList={errorValueList}
          changeDataListen={changeDataListen}
          paymentTermList={paymentTermList}
          changeTermCodeTable={changeTermCodeTable}
          termCodeTable={termCodeTable}
          reviewFlg={reviewFlg}
          onBlurOfPaymentTermCode={onBlurOfPaymentTermCode}
        />
      );
    }
  };

  const getButton =
    !fromDraftsFlag &&
    (!reviewFlg ? (
      <div className={classes.buttonArea}>
        {userPermisssionInfo.csd_promotion_detail_refresh_authFlg && (
          <Button
            data-testid="btn_refresh"
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              handleFresh(false);
            }}
          >
            {t("refresh")}
          </Button>
        )}
        {userPermisssionInfo.csd_promotion_detail_delete_authFlg && (
          <Button
            color="secondary"
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={handleDeletePromotion}
          >
            {t("btn_Delete")}
          </Button>
        )}
        <Button
          variant="contained"
          data-testid="return_to_promotion_list"
          onClick={handleReturnToPromotionList}
        >
          {t("return_to_promotion_list")}
        </Button>
      </div>
    ) : (
      <div className={classes.buttonArea}>
        <Button
          variant="contained"
          data-testid="return_to_promotion_list"
          onClick={handleReturnToPromotionList}
        >
          {t("return_to_promotion_list")}
        </Button>
      </div>
    ));

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      {(!createPromotionFlag || fromDraftsFlag) && (
        <div className={classes.buttonArea}>
          <Button
            variant="contained"
            data-testid="btn_drafts"
            onClick={handleDrafts}
          >
            {t("drafts")}
          </Button>
        </div>
      )}

      {createPromotionFlag && getButton}

      <PromotionName
        searchValueList={searchValueList}
        setSearchValue={setSearchValue}
        createPromotionFlag={createPromotionFlag}
        reviewFlg={reviewFlg}
        fromDraftsFlag={fromDraftsFlag}
        changeDataListen={changeDataListen}
        errorValueList={errorValueList}
      />

      {createPromotionFlag && !fromDraftsFlag && (
        <UpdateHistory data={responseData} />
      )}

      {searchValueList.promo_type === "50" &&
        createPromotionFlag &&
        !fromDraftsFlag && <PromotionCode searchValueList={searchValueList} />}

      <General
        searchValueList={searchValueList}
        setSearchValue={setSearchValue}
        errorValueList={errorValueList}
        disabledCondition={disabledCondition}
        changeDataListen={changeDataListen}
        createPromotionFlag={createPromotionFlag}
        analysisList={analysisList}
        getOptionLabel2={getOptionLabel2}
        filterOptions2={filterOptions2}
        onBlurOfAnalysisCode={onBlurOfAnalysisCode}
        reviewFlg={reviewFlg}
        fromDraftsFlag={fromDraftsFlag}
        analysisCodeDescription={analysisCodeDescription}
        changeAnalysisCodeDescription={changeAnalysisCodeDescription}
      />
      <Period
        searchValueList={searchValueList}
        setSearchValue={setSearchValue}
        errorValueList={errorValueList}
        setErrorValueList={setErrorValueList}
        setErrorValue={setErrorValue}
        setSearchValueList={setSearchValueList}
        changeDataListen={changeDataListen}
        reviewFlg={reviewFlg}
      />
      <Customer
        searchValueList={searchValueList}
        setSearchValue={setSearchValue}
        oldData={oldValue}
        setOldData={setOldData}
        defaultValueList={defaultValueList}
        setDefaultValue={setDefaultValue}
        customerEditList={customerEditList}
        setCustomerEditList={setCustomerEditList}
        onBlurOfCustomerNo={onBlurOfCustomerNo}
        onFocusOfCustomerNo={onFocusOfCustomerNo}
        errorValueList={errorValueList}
        setErrorValueList={setErrorValueList}
        setErrorValue={setErrorValue}
        setHasSeqNoRow={setHasSeqNoRow}
        hasSeqNoRow={hasSeqNoRow}
        numSelected={numSelectedCustomer}
        setNumSelected={setNumSelectedCustomer}
        selectedArray={selectedArrayCustomer}
        setSelectedArray={setSelectedArrayCustomer}
        selectedRowArray={selectedRowArrayCustomer}
        setSelectedRowArray={setSelectedRowArrayCustomer}
        indeterminate={indeterminateCustomer}
        setIndeterminate={setIndeterminateCustomer}
        allChecked={allCheckedCustomer}
        setAllChecked={setAllCheckedCustomer}
        reviewFlg={reviewFlg}
        onCustomerNoFlag={onCustomerNoFlag}
        setAlertShow={setAlertShow}
        setAlertMessage={setAlertMessage}
        setAlertType={setAlertType}
        salesAnalysisList={salesAnalysisList}
        changeDataListen={changeDataListen}
      />
      {(searchValueList.promo_type === "10" ||
        searchValueList.promo_type === "20" ||
        searchValueList.promo_type === "30" ||
        searchValueList.promo_type === "50") && (
        <Category
          searchValueList={searchValueList}
          setSearchValue={setSearchValue}
          oldData={oldValue}
          setOldData={setOldData}
          changeDataListen={changeDataListen}
          setErrorValueList={setErrorValueList}
          defaultValueList={defaultValueList}
          setDefaultValue={setDefaultValue}
          errorValueList={errorValueList}
          getOptionLabel2={getOptionLabel2}
          filterOptions2={filterOptions2}
          categoryConsidered={categoryConsidered}
          categoryExcluded={categoryExcluded}
          onBlurOfItemNo={onBlurOfItemNo}
          onFocusOfItemNo={onFocusOfItemNo}
          consideredEditList={consideredEditList}
          setConsideredEditList={setConsideredEditList}
          excludedEditList={excludedEditList}
          setExcludedEditList={setExcludedEditList}
          plannerCodeChange={plannerCodeChange}
          numSelectedConsidered={numSelectedConsidered}
          setNumSelectedConsidered={setNumSelectedConsidered}
          selectedArrayConsidered={selectedArrayConsidered}
          setSelectedArrayConsidered={setSelectedArrayConsidered}
          selectedRowArrayConsidered={selectedRowArrayConsidered}
          setSelectedRowArrayConsidered={setSelectedRowArrayConsidered}
          indeterminateConsidered={indeterminateConsidered}
          setIndeterminateConsidered={setIndeterminateConsidered}
          allCheckedConsidered={allCheckedConsidered}
          setAllCheckedConsidered={setAllCheckedConsidered}
          numSelectedExcluded={numSelectedExcluded}
          setNumSelectedExcluded={setNumSelectedExcluded}
          selectedArrayExcluded={selectedArrayExcluded}
          setSelectedArrayExcluded={setSelectedArrayExcluded}
          selectedRowArrayExcluded={selectedRowArrayExcluded}
          setSelectedRowArrayExcluded={setSelectedRowArrayExcluded}
          indeterminateExcluded={indeterminateExcluded}
          setIndeterminateExcluded={setIndeterminateExcluded}
          allCheckedExcluded={allCheckedExcluded}
          setAllCheckedExcluded={setAllCheckedExcluded}
          setHasSeqNoRow={setHasSeqNoRow}
          hasSeqNoRow={hasSeqNoRow}
          reviewFlg={reviewFlg}
          onConsideredItemNoFlag={onConsideredItemNoFlag}
          onExcludedItemNoFlag={onExcludedItemNoFlag}
          salesAnalysisList={salesAnalysisList}
        />
      )}

      {getComponents()}

      {(!createPromotionFlag || fromDraftsFlag) && !reviewFlg && (
        <div className={classes.buttonArea}>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={handleQuitwithoutSaving}
            data-testid="btn_quit_without_saving"
            id="btn_quit_without_saving"
            tabIndex={0}
            onFocus={() => buttonFocus()}
          >
            {t("quit_without_saving")}
          </Button>
          <Button
            data-testid="save_to_drafts"
            variant="contained"
            onClick={handleSaveToDrafts}
            disabled={saveToDraftsButtonFlag}
            color="primary"
            startIcon={<SaveIcon />}
          >
            {t("save_to_drafts")}
          </Button>
          <Button
            variant="contained"
            data-testid="register_promotion"
            startIcon={<RepairRegisterIcon />}
            color="primary"
            onClick={handleRegisterPromotion}
            disabled={registerPromotion}
          >
            {t("register_promotion")}
          </Button>
        </div>
      )}

      {createPromotionFlag && !fromDraftsFlag && !reviewFlg && (
        <div className={classes.buttonArea}>
          <Button
            data-testid="discard_changes"
            color="secondary"
            variant="contained"
            startIcon={<DeleteIcon />}
            disabled={registerPromotion || onfocusFlag}
            onClick={handleDiscardChanges}
            id="btn_discard_changes"
            tabIndex={0}
            onFocus={() => buttonFocus()}
          >
            {t("discard_changes")}
          </Button>
          {userPermisssionInfo.csd_promotion_detail_save_changes_authFlg && (
            <Button
              data-testid="save_changes"
              variant="contained"
              disabled={registerPromotion || onfocusFlag}
              onClick={handleRegisterPromotion}
              color="primary"
              startIcon={<SaveIcon />}
            >
              {t("save_changes")}
            </Button>
          )}
        </div>
      )}

      {checkResultValue === true && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionRegister}
          openFlg={checkResultValue}
          button={attentionRegisterButton}
          handleCloseEvent={handleRegisterCancel}
        />
      )}

      {draftsQuitWithoutSaving && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDraftsQuitWithoutSaving}
          openFlg={draftsQuitWithoutSaving}
          button={attentionDraftsQuitWithoutSavingButton}
          handleCloseEvent={handleQuitwithoutSavingClose}
        />
      )}

      {modalOpen && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionChangePromotion}
          openFlg={modalOpen}
          button={attentionChangePromotionButton}
          handleCloseEvent={handleClose}
        />
      )}

      {checkResultValue === false && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionValidations}
          openFlg={!checkResultValue}
          button={attentionValidationsButton}
          handleCloseEvent={handleValidations}
        />
      )}

      {saveToDrafts && (
        <ModelWindow
          title="prompt_title_success"
          contents={successSaveToDrafts}
          openFlg={saveToDrafts}
          button={successSaveToDraftsButton}
          handleCloseEvent={handleDrafts}
        />
      )}

      {disabledSuccess && (
        <ModelWindow
          title="prompt_title_success"
          contents={successRegister}
          openFlg={disabledSuccess}
          button={successRegisterButton}
          handleCloseEvent={handlePromotionList}
        />
      )}

      {returnToPromotionList && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionReturnToPromotionList}
          openFlg={returnToPromotionList}
          button={attentionReturnToPromotionListButton}
          handleCloseEvent={handleReturnToPromotionListYes}
        />
      )}

      {forceRegisterDisplay && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionForceChangePromotion}
          openFlg={forceRegisterDisplay}
          button={attentionForceRegisterPromotionListButton}
          handleCloseEvent={handleForceRegisterToPromotionListYes}
        />
      )}

      {forceDraftRegisterDisplay && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionForceChangePromotion}
          openFlg={forceDraftRegisterDisplay}
          button={attentionForceDraftRegisterPromotionListButton}
          handleCloseEvent={handleForceDraftRegisterToPromotionListYes}
        />
      )}

      {forceUpdateDisplay && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionForceChangePromotion}
          openFlg={forceUpdateDisplay}
          button={attentionForceUpdatePromotionListButton}
          handleCloseEvent={handleForceUpdateToPromotionListYes}
        />
      )}

      {discardChanges && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionReturnToPromotionList}
          openFlg={discardChanges}
          button={attentionDiscardChangesButton}
          handleCloseEvent={handleDiscardChangesNo}
        />
      )}

      {deletePromotion && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeletePromotion}
          openFlg={deletePromotion}
          button={attentionDeletePromotionButton}
          handleCloseEvent={handleCloseDeletePromotion}
        />
      )}

      {saveChanges && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionSaveChanges}
          openFlg={saveChanges}
          button={attentionSaveChangesButton}
          handleCloseEvent={handleCloseSaveChanges}
        />
      )}

      {/* {webshopReleaseFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={
            <div className={classes.divWidth}>
              <p className={classes.message}>{getName("messages.MS0099")}</p>
              <p className={classes.message}>
                {changeItemWebshopReleaseData.map(
                  (item: any, itemIndex: any) => {
                    if (itemIndex === 0) {
                      return item.item_no;
                    } else {
                      return <>, {item.item_no}</>;
                    }
                  }
                )}
              </p>
            </div>
          }
          openFlg={webshopReleaseFlag}
          button={webshopReleaseConfirmButton}
          handleCloseEvent={handleWebshopReleaseConfirmNo}
        />
      )} */}

      {/* {createWebshopReleaseFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={
            <div className={classes.divWidth}>
              <p className={classes.message}>{getName("messages.MS0099")}</p>
              <p className={classes.message}>
                {changeItemWebshopReleaseData.map(
                  (item: any, itemIndex: any) => {
                    if (itemIndex === 0) {
                      return item.item_no;
                    } else {
                      return <>, {item.item_no}</>;
                    }
                  }
                )}
              </p>
            </div>
          }
          openFlg={createWebshopReleaseFlag}
          button={createWebshopReleaseConfirmButton}
          handleCloseEvent={handleCreateWebshopReleaseConfirmNo}
        />
      )} */}

      {alertCustomerNoMessageFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={
            <div className={classes.divWidth}>
              <p className={classes.message}>
                {getName("messages.MS0085", {
                  param1: customerNo,
                })}
              </p>
            </div>
          }
          openFlg={alertCustomerNoMessageFlag}
          button={alertCustomerNoConfirmButton}
          handleCloseEvent={handleAlertCustomerNoConfirmNo}
        />
      )}

      {alertItemNoMessageFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={
            <div className={classes.divWidth}>
              {itemNoWebshopReleaseFlag && (
                <p className={classes.message}>
                  {getName("messages.MS0096", {
                    itemNum: itemNo,
                  })}
                </p>
              )}
              {itemNoSuspendFlag && (
                <p className={classes.message}>
                  {getName("messages.MS0052", {
                    itemNum: itemNo,
                  })}
                </p>
              )}
            </div>
          }
          openFlg={alertItemNoMessageFlag}
          button={alertItemNoConfirmButton}
          handleCloseEvent={handleAlertItemNoConfirmNo}
        />
      )}
    </>
  );
  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
