import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "customer_no", label: "list_header_customer_no" },
  { id: "customer_name", label: "list_header_customer_name" },
  { id: "address", label: "list_header_address" },
  { id: "postal_code", label: "list_header_postal_code" },
  { id: "city", label: "list_header_city" },
  { id: "tel_no", label: "list_header_telephone_no" },
  { id: "customer_closed", label: "list_header_customer_closed" },
];

export const CustomerSearchResultTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.prompt_csearch",
  });

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        <TableCell className={classes.theadCell}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.theadCell}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
