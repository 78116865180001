import React, { useState } from "react";
// components
import { JobsDetailMemoGroupBox } from "./JobsDetail.Memo.MemoGroupBox";
import { JobsDetailMemoTextArea } from "./JobsDetail.Memo.TextArea";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";

// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// hooks
import { useStyles } from "../hooks/common.styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupArea: {
      maxWidth: "700px",
      margin: "0 auto",
      padding: "20px 10px",
    },
  })
);

export const JobsDetailMemo = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.jobDetail.memo" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });

  const {
    otherMemoList,
    internalUseMemoList,
    handleEditEvent,
    handleDeleteEvent,
    memoEditData,
    customerMemorandum,
    dataList,
    userPermisssionInfo,
  } = props;

  // message box
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const handleSend = () => {
    setOpen(false);
    handleDeleteEvent(deleteData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // delete button click event
  const handleDeleteConfirmEvent = (item: any) => {
    setOpen(true);
    setDeleteData(item);
  };

  return (
    <div className={`${classes.groupArea} ${myClasses.groupArea}`}>
      <h3 className={classes.groupTitle}>{t("memo_title")}</h3>
      <JobsDetailMemoGroupBox
        CustomerMemorandum={props.CustomerMemorandum}
        otherMemoList={otherMemoList}
        internalUseMemoList={internalUseMemoList}
        handleEditEvent={handleEditEvent}
        handleDeleteEvent={handleDeleteConfirmEvent}
        customerMemorandum={customerMemorandum}
        userPermisssionInfo={userPermisssionInfo}
      />
      {userPermisssionInfo.internal_memo_authFlg && (
        <JobsDetailMemoTextArea
          status={props.status}
          editData={memoEditData}
          ChangeTextAreaValue={props.ChangeTextAreaValue}
          handleClick={props.handleClick}
          handleCancel={props.handleCancel}
          placeHolder={t("memo_placeholder")}
          dataList={dataList}
          userPermisssionInfo={userPermisssionInfo}
        />
      )}
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0033")}
      />
    </div>
  );
};
