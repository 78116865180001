import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",

      "& dl": {
        paddingLeft: "10px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        paddingLeft: "5px",
      },
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    advancedButton: {
      "& button": {
        width: "140px",
        padding: "5px 0",
      },
    },
    searchControlSelect: {
      width: "140px",
      "& .MuiInputBase-root": {
        height: "30px",
      },
    },
    buttonArea: {
      padding: "32px 0 0 10px",
      "& .MuiButton-root": {
        padding: "6px 12px",
      },
    },
    buttonCondition: {
      marginTop: "6px",
      height: "30px",
      "& .MuiButton-label": {
        paddingTop: "2px",
      },
    },
    buttonCondition2: {
      marginTop: "10px",
      height: "35px",
      "& .MuiButton-label": {
        paddingTop: "2px",
      },
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePickerArea: {
      width: "400px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePicker: {
      width: "47%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    datePickerInterval: {
      position: "absolute",
      top: "20px",
      left: "50%",
      marginTop: "4px",
      marginLeft: "-4px",
      borderTop: "1px solid #000",
      width: "5px",
      display: "block",
    },
    advancedHideFlg: {
      display: "none",
    },
    jobDd: {
      width: "100px",
    },
    technicianCodeDd: {
      width: "250px",
    },
    modelNoDd: {
      width: "150px",
    },
    customerNmDd: {
      width: "150px",
    },
    emailDd: {
      width: "200px",
    },
    technicianDd: {
      width: "150px",
    },
    inputWidth120: {
      width: "120px!important",
    },
    autoComplete: {
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    buttonWrapper: {
      display: "flex",
    },
    downloadButton: {
      paddingTop: "10px",
      paddingLeft: "10px",
    },
  })
);
