import React, { useState, useEffect } from "react";
import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import HomeIcon from "@material-ui/icons/Home";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { GetPrivateAppRoutePath, PathKey } from "../../../../pages/AppRoutes";
import { useCommonInfo } from "../../../../modules/hooks/CommonInfoProvider";
import { useSecureHttpRequest } from "../../../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../../../modules/hooks/Permissions";
import {
  JobsIcon,
  LocationControlIcon,
  MasterMaintenanceIcon,
  MobileAppIcon,
  RepairRegisterIcon,
  PromotionIcon,
  OrderIcon,
  SimulateIcon,
  ClaimIcon,
  ApIcon,
} from "../../icons/Icons";

export interface NavigationDrawerItemGroup {
  menuItems: NavigationDrawerItem[];
}

export interface NavigationDrawerItem {
  textKey: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  path: string;
  showFlg: boolean;
  targetBlank?: boolean;
}

export const GetNavigationDrawerItemGroups =
  (): NavigationDrawerItemGroup[] => {
    // loading
    const { getCommonConfig, getUserPermissions, setUserPermissions } =
      useCommonInfo();
    const config = getCommonConfig() as any;
    let userPermissions = getUserPermissions() as any;
    const { getUserPermissionsRe } = useSecureHttpRequest();
    const permissionsInfomation = new Permissions();
    const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

    useEffect(() => {
      if (config.token) {
        setPermission();
      }
    }, [config.token]);

    // set permission of Receiving and Location Control
    const setPermission = async () => {
      let localUserPermissions = {};
      if (userPermissions.permission_group) {
        localUserPermissions = userPermissions;
      } else {
        await getUserPermissionsRe().then((r: any) => {
          localUserPermissions = r.response;
          setUserPermissions(r.response);
        });
      }

      permissionsInfomation.setPermissionsInfo(localUserPermissions);
      const tempUserPermissionsInfo =
        permissionsInfomation.getPermissionsInfo();
      setUserPermisssionInfo(tempUserPermissionsInfo);
    };

    return [
      {
        menuItems: [
          {
            textKey: "txtHome",
            icon: HomeIcon,
            path: GetPrivateAppRoutePath(PathKey.DASHBOARD),
            showFlg: true,
          },
          {
            textKey: "txtJobs",
            icon: JobsIcon,
            path: GetPrivateAppRoutePath(PathKey.JOBS),
            showFlg:
              userPermisssionInfo.job_allocation_authFlg ||
              userPermisssionInfo.job_register_authFlg ||
              userPermisssionInfo.jobdetail_general_authFlg ||
              userPermisssionInfo.jobdetail_parts_and_drawing_authFlg ||
              userPermisssionInfo.customer_info_authFlg ||
              userPermisssionInfo.delete_memo_authFlg ||
              userPermisssionInfo.price_authFlg ||
              userPermisssionInfo.delete_job_btn_authFlg,
          },
          {
            textKey: "txtReceiving",
            icon: RepairRegisterIcon,
            path: GetPrivateAppRoutePath(PathKey.RECEIVING),
            showFlg: userPermisssionInfo.receiving_authFlg,
          },
          {
            textKey: "txtLocationControl",
            icon: LocationControlIcon,
            path: GetPrivateAppRoutePath(PathKey.LOCATIONCONTROL),
            showFlg: userPermisssionInfo.location_control_authFlg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtPromotion",
            icon: PromotionIcon,
            path: GetPrivateAppRoutePath(PathKey.PROMOTION),
            showFlg: userPermisssionInfo.csd_promotion_show_authFlg,
          },
          {
            textKey: "txtOrder",
            icon: OrderIcon,
            path: GetPrivateAppRoutePath(PathKey.ORDER),
            showFlg: userPermisssionInfo.csd_order_show_authFlg,
          },
          {
            textKey: "txtSimulate",
            icon: SimulateIcon,
            path: GetPrivateAppRoutePath(PathKey.SIMULATE),
            showFlg:
              userPermisssionInfo.csd_simulation_add_item_authFlg ||
              userPermisssionInfo.csd_simulation_delete_item_authFlg ||
              userPermisssionInfo.csd_simulation_clear_authFlg ||
              userPermisssionInfo.csd_simulation_simulate_authFlg ||
              userPermisssionInfo.csd_simulation_view_promo_detail_authFlg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtClaim",
            icon: ClaimIcon,
            path: GetPrivateAppRoutePath(PathKey.CLAIM),
            showFlg: userPermisssionInfo.wco_claim_update_authFlg || 
                     userPermisssionInfo.wco_claim_inquiry_authFlg ||
                     userPermisssionInfo.wco_claim_approve_authFlg ||
                     userPermisssionInfo.wco_claim_pre_approve_authFlg,
          },
          {
            textKey: "txtAp",
            icon: ApIcon,
            path: GetPrivateAppRoutePath(PathKey.AP),
            showFlg: userPermisssionInfo.wco_claim_note_authFlg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtMasterMaintenance",
            icon: MasterMaintenanceIcon,
            path: GetPrivateAppRoutePath(PathKey.MASTERMAINTENANCE),
            showFlg:
              userPermisssionInfo.user_master_consoleFlg ||
              userPermisssionInfo.user_master_DZFlg ||
              userPermisssionInfo.customer_memorandum_masterFlg ||
              userPermisssionInfo.item_master ||
              userPermisssionInfo.role_masterFlg ||
              userPermisssionInfo.service_senter_masterFlg ||
              userPermisssionInfo.printer_masterFlg ||
              userPermisssionInfo.repair_destination_masterFlg || 
              userPermisssionInfo.wco_labor_master_authFlg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtAndroidApplication",
            icon: MobileAppIcon,
            path: GetPrivateAppRoutePath(PathKey.Shuriken),
            showFlg: userPermisssionInfo.takumi_mobile_authFlg,
          },
        ],
      },
      {
        menuItems: [
          {
            textKey: "txtReport",
            icon: AssessmentIcon,
            path: GetPrivateAppRoutePath(PathKey.REPORT),
            showFlg: userPermisssionInfo.report_authFlg,
          },
        ],
      },
    ];
  };
