import React from "react";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { isEmptyCheck } from "../../../modules/hooks/UseValication";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "20px 0 10px 0",
    },
    textField: {
      width: "100%",
      "& .MuiFilledInput-multiline": {
        padding: "5px",
      },
      "& .MuiInputBase-root:before": {
        display: "none",
      },
      "& .MuiInputBase-root:after": {
        display: "none",
      },
      "& .MuiFilledInput-root": {
        borderRadius: "5px",
        background: "#fff",
        border: "1px solid #000",
      },
    },
    buttonControl: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "flex-end",
    },
    saveButton: {
      marginLeft: "50px",
    },
  })
);

export const JobsDetailMemoTextArea = (props: any) => {
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.button",
  });
  const { editData, placeHolder, dataList, userPermisssionInfo } = props;

  const btnDisableFlg = () => {
    return isEmptyCheck(editData.note);
  };
  const btnDiffContents = () => {
    const list: any = dataList.repair_note;
    const item: any =
      dataList.repair_note && dataList.repair_note.length > 0
        ? list.filter((item: any) => item.sub_no === editData.sub_no)[0]
        : { note: "" };

    return item ? item.note === editData.note : false;
  };

  const sendButtonArea = (
    <Button
      variant="contained"
      color="primary"
      onClick={props.handleClick}
      disabled={btnDisableFlg()}
    >
      {t("button_send")}
    </Button>
  );

  const saveButtonArea = (
    <>
      <div>
        <Button variant="contained" onClick={props.handleCancel}>
          {t("button_cancel")}
        </Button>
      </div>
      <div className={myClasses.saveButton}>
        <Button
          color="primary"
          variant="contained"
          onClick={props.handleClick}
          disabled={btnDisableFlg() || btnDiffContents()}
        >
          {t("button_save")}
        </Button>
      </div>
    </>
  );

  return (
    <div className={myClasses.root}>
      <div>
        <TextField
          id="filled-textarea"
          variant="filled"
          multiline
          placeholder={placeHolder}
          rows={4}
          className={myClasses.textField}
          value={editData.note}
          onChange={(e) => props.ChangeTextAreaValue(e.target.value)}
        />
      </div>
      <div className={myClasses.buttonControl}>
        {userPermisssionInfo.send_btn_authFlg &&
          (props.status ? sendButtonArea : saveButtonArea)}
      </div>
    </div>
  );
};
