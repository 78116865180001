import React, { useState, useCallback } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingLeft: "5px",
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
      },
    },
    searchTitleCell: {
      width: "100px",
    },
    searchTitleCell2: {
      width: "120px",
    },
    searchmessageCell: {
      width: "130px",
      paddingRight: "20px",
    },
    customerInputText: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        width: "100px",
      },
      "& .PrivateNotchedOutline-root-47.MuiOutlinedInput-notchedOutline": {
        width: "100px",
      },
    },
    searchButton: {
      height: "30px",
      padding: "0 16px",
    },
  })
);

export const CustomerSearchControl = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.prompt_csearch",
  });
  const { conditionList, setConditionList, handleClickSearch } = props;

  // search condition value
  const setConditionValue = (property: any, value: any) => {
    setConditionList((prevState: any) => ({ ...prevState, [property]: value }));
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.tableLayout}>
        <tbody>
          <tr>
            <td className={classes.searchTitleCell}>
              {t("condition_customer_no")}
            </td>
            <td className={classes.searchmessageCell}>
              <TextField
                className={classes.customerInputText}
                value={conditionList.customer_no}
                variant="outlined"
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^\d]/g, "")
                    .substr(0, 8);
                  return setConditionValue("customer_no", value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td className={classes.searchTitleCell2}>
              {t("condition_customer_name")}
            </td>
            <td className={classes.searchmessageCell}>
              <TextField
                value={conditionList.customer_name}
                variant="outlined"
                onChange={(e) =>
                  setConditionValue("customer_name", e.target.value)
                }
                inputProps={{ maxLength: 50 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td className={classes.searchTitleCell}>
              {t("condition_postal_code")}
            </td>
            <td className={classes.searchmessageCell}>
              <TextField
                value={conditionList.postal_cd}
                variant="outlined"
                onChange={(e) => setConditionValue("postal_cd", e.target.value)}
                inputProps={{ maxLength: 9 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickSearch}
                className={classes.searchButton}
              >
                {t("btn_search")}
              </Button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td className={classes.searchmessageCell}></td>
            <td>{t("condition_address")}</td>
            <td className={classes.searchmessageCell}>
              <TextField
                value={conditionList.address}
                variant="outlined"
                onChange={(e) => setConditionValue("address", e.target.value)}
                inputProps={{ maxLength: 50 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td>{t("condition_state")}</td>
            <td className={classes.searchmessageCell}>
              <TextField
                value={conditionList.state_cd}
                variant="outlined"
                onChange={(e) => setConditionValue("state_cd", e.target.value)}
                inputProps={{ maxLength: 3 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td className={classes.searchmessageCell}></td>
            <td>{t("condition_city")}</td>
            <td className={classes.searchmessageCell}>
              <TextField
                value={conditionList.city}
                variant="outlined"
                onChange={(e) => setConditionValue("city", e.target.value)}
                inputProps={{ maxLength: 50 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td>{t("condition_telephone_no")}</td>
            <td className={classes.searchmessageCell}>
              <TextField
                value={conditionList.tel_no}
                variant="outlined"
                onChange={(e) => setConditionValue("tel_no", e.target.value)}
                inputProps={{ maxLength: 25 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch();
                  }
                }}
              />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
