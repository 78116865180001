import { http } from "./Http";

// AM1601
export const GetAffiliateCodeListAPI = (config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/affiliates`,
    config: config,
  };
  return http(param);
};
