import React, { useState } from "react";
// components
import { MasterMaintenanceSearchBox } from "./MasterMaintenance.SearchBox";
import { MasterMaintenanceUserMasterThead } from "./MasterMaintenance.UserMaster.Thead";
import { MasterMaintenanceUserMasterTbody } from "./MasterMaintenance.UserMaster.Tbody";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { MasterMaintenanceErrorMessageBox } from "./MasterMaintenance.ErrorMessageBox";
import { TabKey } from "../MasterMaintenance.constants";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// hook
import { useStyles } from "../hooks/table.useStyles";
// methods
import {
  isEmptyCheck,
  emailValidation,
  checkMaxLength,
  checkTypeNumber,
  checkUmlautsCharUnicode,
} from "../../../modules/hooks/UseValication";

import {
  getApiResultStatus,
} from "../../../modules/common.methods";

import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";

export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
    },
    buttonArea: {
      marginRight: "10px",
    },
    input: {
      display: "none",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
  })
);

export const MasterMaintenanceUserMaster = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.userMaster",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const { setCommonInfo, getCommonConfig } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const [searchKey, setSearchKey] = useState("");
  const {
    useMasterResultList,
    config,
    api,
    getUserInfoDetailListAPI,
    getDzinfoDetailListAPI,
    serviceCenterDataList,
    customerMasterData,
    userPermisssionInfo,
    userRepairDisabledFlag,
    downloadUserMasterInfoCSV,
  } = props;
  // pager
  const [page, setPage] = useState(0);
  // 1.3 according to the row number, calculate the number of pages(20 lines per page)
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // delete
  const [deleteRow, setDeleteRow] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // search
  const handleChangeKey = (value: any) => {
    setSearchKey(value);
    props.SearchResult(value, TabKey.useMaster);
    setPage(0);
  };

  const handleSearch = () => {
    props.SearchResult(searchKey, TabKey.useMaster);
    setPage(0);
  };

  // delete button click event
  const handleDelete = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  // message box
  const [open, setOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    props.handleDelete(deleteRow, TabKey.useMaster);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };
  const handleSuccessAndErrorMessageClose = () => {
    setSuccessAndErrorMessageOpen(false);
  };

  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  // success and error message box
  const [successAndErrorMessageOpen, setSuccessAndErrorMessageOpen] = useState(false);

  const [errMessageList, setErrMessageList] = useState([]);
  const [showSuccessAndErrorMessage, setShowSuccessAndErrorMessage] = useState([]);
  const [successAndErrorMessageList, setSuccessAndErrorMessageList] = useState([]);

  let successQuantity = 0;
  let successQuantityList: any = []
  let errorDataList: any = []
  let errorMessage = {};
  let count = 0;
  let timerId: any;

  //click upload button
  const handlerUpload = (files: any) => {
    const file = files[0];
    if (files && files.length > 0) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = reader.result;
        var dataList = parseData(data);
        const errorNumber = handleInputValue(dataList);
        if (errorNumber.length > 0) {
          setErrorMessageOpen(true);
        } else {
          apiLoading(true);
          createUserInfo(dataList, 1);
        }
      };
      reader.onerror = errorHandler;
      reader.readAsText(file);
    }
  };

  const createUserInfo = async (dataList: any, i: any) => {
    clearTimeout(timerId)
    if (i < dataList.length) {
      const param = getUseMasterNewData(dataList[i]);
      await api
        .createUserInfoAPI(param, config)
        .then(({ code, response }: { code: any; response: any }) => {
          console.log("post AM0101 userInfo", code, response);
          if (getApiResultStatus(code)) {
            successQuantity = successQuantity + 1;
            successQuantityList.push(successQuantity)
          } else {
            errorMessage = com(response.message_id, {
              rowNum: t("row") + "" + Number(i + 1),
              param1: "API." + t(response.key),
            });
            errorDataList.push(errorMessage + "\n")
          }
          count++;
          timerId = setTimeout(() => {
            createUserInfo(dataList, i);
          }, 1000)
          i++;
          if (count === dataList.length - 1) {
            setShowSuccessAndErrorMessage(com("MS0012", {
              registerQty: successQuantityList.length,
            }))
            setSuccessAndErrorMessageList(errorDataList)
            apiLoading(false);
            setSuccessAndErrorMessageOpen(true)
            getUserInfoDetailListAPI();
            if (userPermisssionInfo.user_master_consoleFlg) {
              getDzinfoDetailListAPI()
            }
          }
        });
    }
  };

  // parse Data
  const parseData = (data: any) => {
    const rowLines = data.split(/\r\n|\n/);
    let dataList = [];
    for (let i = 0; i < rowLines.length; i++) {
      const rowMessage1 = rowLines[i].split(",");
      if (rowMessage1.length > 1) {
        if (!userRepairDisabledFlag && rowMessage1[9] !== "erp_user_id" && rowMessage1[10] !== "service_center") {
          rowMessage1[9] = "";
          rowMessage1[10] = "";
        }
        rowMessage1[15] = i;
        dataList.push(rowMessage1);
      } else if (rowMessage1.length <= 1) {
        const rowMessage2 = rowLines[i].split(";");
        if (rowMessage2.length > 1) {
          if (!userRepairDisabledFlag && rowMessage2[9] !== "erp_user_id" && rowMessage2[10] !== "service_center") {
            rowMessage2[9] = "";
            rowMessage2[10] = "";
          }
          rowMessage2[15] = i;
          dataList.push(rowMessage2);
        }
      }
    }
    return dataList
  };

  // handle Input Value
  const handleInputValue = (value: any) => {
    let errorData: any = []
    let message = {}
    if (16 != value[0].length) {
      message = com("MS0045", {});
      errorData.push(message + "\n")
      setErrMessageList(errorData);
      return errorData
    }
    else if (1 == value.length) {
      message = t("data");
      errorData.push(message + "\n")
      setErrMessageList(errorData);
      return errorData
    }
    else {
      for (var j = 1; j < value.length; j++) {
        let validateData = [] as any;
        validateData = value[j]
        if (validateData.length === 0) {
          continue;
        }
        if (validateData.length === 16) {
          if (isEmptyCheck(validateData[0].trim())) {
            message = com("MS0001", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("given_name"),
            });
            errorData.push(message + "\n")
          }
          if (!checkMaxLength(validateData[0].trim(), 50)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("given_name"),
              param2: 50,
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[1].trim())) {
            message = com("MS0001", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("family_name"),
            });
            errorData.push(message + "\n")
          }
          if (!checkMaxLength(validateData[1].trim(), 50)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("family_name"),
              param2: 50,
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[2].trim(), 50)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("middle_name"),
              param2: 50,
            });
            errorData.push(message + "\n")
          }
          if (isEmptyCheck(validateData[3].trim())) {
            message = com("MS0001", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("affiliate_cd"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[3].trim(), 5)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("affiliate_cd"),
              param2: 5,
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[4].trim())) {
            message = com("MS0001", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("primary_locale"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[4].trim(), 2)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("primary_locale"),
              param2: 2,
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[5].trim(), 25)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("tel_no"),
              param2: 25,
            });
            errorData.push(message + "\n");
          }
          if (
            !checkTypeNumber(
              validateData[5].trim().substr(0, 1) == "+"
                ? validateData[5].trim().substr(1)
                : validateData[5].trim()
            )
          ) {
            message = com("MS0011", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("tel_no"),
            });
            errorData.push(message + "\n");
          }
          if (isEmptyCheck(validateData[6].trim())) {
            message = com("MS0001", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("mail_address"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(validateData[6].trim(), 254)) {
            message = com("MS0002", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("mail_address"),
              param2: 254,
            });
            errorData.push(message + "\n");
          }
          if (checkUmlautsCharUnicode(validateData[6].trim())) {
            message = com("MS0122", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("mail_address"),
            });
            errorData.push(message + "\n");
          } else if (!emailValidation(validateData[6].trim())) {
            message = com("MS0040", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("mail_address"),
            });
            errorData.push(message + "\n");
          }

          if (validateData[7].trim().toUpperCase() != "TRUE") {
            message = com("MS0011", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("use_repair"),
            });
            errorData.push(message + "\n")
          }
          if (validateData[8].trim().toUpperCase() != "TRUE" && validateData[8].trim().toUpperCase() != "FALSE") {
            message = com("MS0011", {
              rowNum: t("row") + "" + Number(validateData[15] + 1),
              param1: t("use_dz"),
            });
            errorData.push(message + "\n")
          }
          if (userRepairDisabledFlag) {
            if (!checkMaxLength(validateData[9].trim(), 30)) {
              message = com("MS0002", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("erp_user_id"),
                param2: 30,
              });
              errorData.push(message + "\n")
            }
            if (config.affiliate_cd.toUpperCase() === "MD") {
              if (isEmptyCheck(validateData[10].trim())) {
                message = com("MS0001", {
                  rowNum: t("row") + "" + Number(validateData[15] + 1),
                  param1: t("service_center"),
                });
                errorData.push(message + "\n")
              }
              if (!checkMaxLength(validateData[10].trim(), 3)) {
                message = com("MS0002", {
                  rowNum: t("row") + "" + Number(validateData[15] + 1),
                  param1: t("service_center"),
                  param2: 3,
                });
                errorData.push(message + "\n")
              } else if (!serverCenterNoValidationCheck(validateData[10].trim())) {
                message = com("MS0004", {
                  rowNum: t("row") + "" + Number(validateData[15] + 1),
                  param1: t("service_center"),
                });
                errorData.push(message + "\n")
              }
            }
          }
          if (validateData[8].toUpperCase() === "TRUE") {
            if (isEmptyCheck(validateData[11].trim())) {
              message = com("MS0001", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("customer_no"),
              });
              errorData.push(message + "\n")
            }
            if (!checkMaxLength(validateData[11].trim(), 8)) {
              message = com("MS0002", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("customer_no"),
                param2: 8,
              });
              errorData.push(message + "\n")
            } else if (!customerNoValidationCheck(validateData[11].trim())) {
              message = com("MS0004", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("customer_no"),
              });
              errorData.push(message + "\n")
            }
            if (
              validateData[12].trim() != "1" &&
              validateData[12].trim() != "2" &&
              validateData[12].trim() != "3"
            ) {
              message = com("MS0011", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("role_id"),
              });
              errorData.push(message + "\n");
            }
            if (validateData[13].trim().toUpperCase() != "TRUE" &&
              validateData[13].trim().toUpperCase() != "FALSE") {
              message = com("MS0011", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("sms_receive_notification"),
              });
              errorData.push(message + "\n")
            }
            if (validateData[14].trim().toUpperCase() != "TRUE" &&
              validateData[14].trim().toUpperCase() != "FALSE") {
              message = com("MS0011", {
                rowNum: t("row") + "" + Number(validateData[15] + 1),
                param1: t("mail_receive_notification"),
              });
              errorData.push(message + "\n")
            }
          }
        } else {
          message = com("MS0045", {
          });
          errorData.push(message + "\n")
          setErrMessageList(errorData);
          return errorData
        }
      }
      setErrMessageList(errorData);
      return errorData
    }
  };

  // get useMaster new data
  const getUseMasterNewData = (editList: any) => {
    return {
      given_name: editList[0].trim(),
      family_name: editList[1].trim(),
      middle_name: editList[2].trim(),
      affiliate_cd: editList[3].trim(),
      primary_locale: editList[4].trim(),
      tel_no: editList[5].trim(),
      mail_address: editList[6].trim(),
      use_repair: editList[7].trim().toUpperCase() === "TRUE" ? true : false,
      use_dz: editList[8].trim().toUpperCase() === "TRUE" ? true : false,
      user_block: false,
      userinfo_repair: {
        erp_user_id: editList[9].trim(),
        service_center: editList[10].trim(),
      },
      userinfodz: {
        customer_no: editList[11].trim(),
        role_id: editList[12].trim(),
        sms_receive_notification: editList[13].trim().toUpperCase() === "TRUE" ? true : false,
        mail_receive_notification: editList[14].trim().toUpperCase() === "TRUE" ? true : false,
      },
    };
  };

  //Server Center check
  const serverCenterNoValidationCheck = (value: any) => {
    if (value.length !== 0) {
      const serverCenterHaveFlg = serviceCenterDataList.filter(
        (item: any) => item.service_center_id == value
      );
      if (serverCenterHaveFlg.length === 0) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  };

  //Customer No. check
  const customerNoValidationCheck = (value: any) => {
    if (value.length !== 0) {
      const customerNoHaveFlg = customerMasterData.filter(
        (item: any) => item.no == value
      );
      if (customerNoHaveFlg.length === 0) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  };

  const UserMasterModelWindow =
    <div className={myClasses.divWidth}>
      {successAndErrorMessageList.length > 0 &&
        <h2 className={myClasses.title}>{t("error")}</h2>
      }
      <p className={myClasses.message}>{successAndErrorMessageList}</p>
    </div>

  const UserMasterModelWindowButton = (
    <div className={myClasses.buttonControl}>
      <Button
        className={myClasses.buttonWidth}
        variant="contained"
        onClick={handleSuccessAndErrorMessageClose}
        color="primary">
        {t("prompt_button_OK")}
      </Button>
    </div>
  );


  const errorHandler = (e: any) => {
    if (e.target.error.name == "NotReadableError") {
      alert("Canno't read file !");
    }
  };

  return (
    <div>
      <div className={myClasses.header}>
        <MasterMaintenanceSearchBox
          searchKey={searchKey}
          handleChangeKey={handleChangeKey}
          handleSearch={handleSearch}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={myClasses.buttonArea}
            startIcon={<CloudDownloadIcon />}
            onClick={downloadUserMasterInfoCSV}
          >
            {t("download")}
          </Button>
          <input
            accept=".csv"
            className={myClasses.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(e: any) => handlerUpload(e.target.files)}
            onClick={(e: any) => { e.target.value = null }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={myClasses.buttonArea}
              startIcon={<BackupIcon />}
            >
              {t("upload")}
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.handleRegister(TabKey.useMaster)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t("register")}
          </Button>
        </div>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <MasterMaintenanceUserMasterThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            userRepairDisabledFlag={userRepairDisabledFlag}
          />
          <MasterMaintenanceUserMasterTbody
            data={useMasterResultList}
            pageInfo={pageInfo}
            order={order}
            orderBy={orderBy}
            handleEdit={props.handleEdit}
            handleDelete={handleDelete}
            serviceCenterDataList={serviceCenterDataList}
            userRepairDisabledFlag={userRepairDisabledFlag}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        component="div"
        count={useMasterResultList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
      {errMessageList.length > 0 &&
        <MasterMaintenanceErrorMessageBox
          open={errorMessageOpen}
          handleClose={handleErrorMessageClose}
          message={errMessageList}
        />
      }
      {
        <ModelWindow
          title={showSuccessAndErrorMessage}
          notTitle={true}
          contents={UserMasterModelWindow}
          button={UserMasterModelWindowButton}
          openFlg={successAndErrorMessageOpen}
          handleCloseEvent={handleSuccessAndErrorMessageClose}
        />
      }
    </div>
  );
};
