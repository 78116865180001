// constants
import { TabKey } from "../MasterMaintenance.constants";
import { getSignByCSV, getCodeNameByNumber } from "../../../modules/common.methods";

export const semicolon = ["MD"];

// date length
export const date2length = (v: any, len: number) => {
  let _t: string = typeof v === "number" ? v.toString() : v;
  for (let i = 0; i < len; i++) {
    if (typeof _t === "string" && _t.length <= len - 1) {
      _t = "0" + _t;
    }
  }
  return _t;
};

// get CSV Date
export const getCSVDate = (date: any) => {
  const _date = new Date(date);
  let y = _date.getFullYear().toString();
  let m: any = date2length(_date.getMonth() + 1, 2);
  let d: any = date2length(_date.getDate(), 2);
  return `${y}-${m}-${d}`;
};

// get CSV Date
export const getCSVDateWithHours = (date: any) => {
  const _date = new Date(date);
  let y = _date.getFullYear().toString();
  let m: any = date2length(_date.getMonth() + 1, 2);
  let d: any = date2length(_date.getDate(), 2);
  let H: any = date2length(_date.getHours(), 2);
  let M: any = date2length(_date.getMinutes(), 2);
  let S: any = date2length(_date.getSeconds(), 2);

  return `${y}-${m}-${d} ${H}:${M}:${S}`;
};

export const checkSplitSymbol = (field: any, splitsymbol: any) => {
  let strField = String(field);

  if (strField === splitsymbol) {
    return false;
  } else {
    if (strField.includes('"' + splitsymbol)) {
      return false;
    } else if (strField.includes(splitsymbol + '"')) {
      return false;
    }
  }

  return true;
};

// get CSV file name
export const getCSVFileName = () => {
  const date = new Date();
  let y = date.getFullYear().toString();
  let m: any = date2length(date.getMonth() + 1, 2);
  let d: any = date2length(date.getDate(), 2);
  let hh = date2length(date.getHours(), 2);
  let mm = date2length(date.getMinutes(), 2);
  let ss = date2length(date.getSeconds(), 2);

  return y + m + d + hh + mm + ss;
};
export const SearchResultMethods = (
  key: string,
  list: any[],
  header: any = [],
  type?: any
) => {
  let text: any;
  if (key && list && list.length > 0) {
    let newList = list.slice(0);
    key = key.toString().toUpperCase();
    newList = newList.filter((item: any, index: number) => {
      let flg = false;
      for (let i = 0; i < header.length; i++) {
        const searchItem = header[i];
        if (searchItem.searchFlg) {
          if (searchItem.id === "role_id") {
            if (type === TabKey.DZUserInfo) {
              if (item["userinfodz"]["role_id"] !== undefined) {
                text = item["userinfodz"]["role_id"].toString().toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "customer_number_DZ") {
            if (type === TabKey.DZUserInfo) {
              if (item["userinfodz"]["customer_no"] !== undefined) {
                text = item["userinfodz"]["customer_no"]
                  .toString()
                  .toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else if (type === TabKey.DZUserRegistration) {
              if (item["customer_no"] !== undefined) {
                text = item["customer_no"].toString().toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "customer_name") {
            if (type === TabKey.DZUserInfo) {
              if (item["userinfodz"]["customer_name"] !== undefined) {
                text = item["userinfodz"]["customer_name"]
                  .toString()
                  .toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else if (type === TabKey.DZUserRegistration) {
              if (item["customer_name"] !== undefined) {
                text = item["customer_name"].toString().toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "sms_receive_notification") {
            if (type === TabKey.DZUserInfo) {
              if (
                item["userinfodz"]["sms_receive_notification"] !== undefined
              ) {
                text = item["userinfodz"]["sms_receive_notification"]
                  .toString()
                  .toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "mail_receive_notification") {
            if (type === TabKey.DZUserInfo) {
              if (
                item["userinfodz"]["mail_receive_notification"] !== undefined
              ) {
                text = item["userinfodz"]["mail_receive_notification"]
                  .toString()
                  .toUpperCase();
              } else {
                if (item[searchItem.id] !== undefined) {
                  text = item[searchItem.id].toString().toUpperCase();
                }
              }
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "created_date") {
            if (item["userinfodz"]["created_date"] !== undefined) {
              text = item["userinfodz"]["created_date"]
                .toString()
                .toUpperCase();
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "technician_code") {
            if (item["userinfo_repair"]["erp_user_id"] !== undefined) {
              text = item["userinfo_repair"]["erp_user_id"]
                .toString()
                .toUpperCase();
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else if (searchItem.id === "service_center") {
            if (item["userinfo_repair"]["service_center"] !== undefined) {
              text = item["userinfo_repair"]["service_center"]
                .toString()
                .toUpperCase();
            } else {
              if (item[searchItem.id] !== undefined) {
                text = item[searchItem.id].toString().toUpperCase();
              }
            }
          } else {
            if (item[searchItem.id] !== undefined) {
              text = item[searchItem.id].toString().toUpperCase();
            }
          }
          if (text !== undefined && text.indexOf(key) !== -1) {
            if (!flg) {
              flg = true;
            }
          }
        }
        text = "";
      }
      return flg;
    });
    return newList;
  } else {
    if (list && list.length > 0) {
      return list.slice(0);
    } else {
      return [];
    }
  }
};

// sort
export const setSortData = (
  data: any,
  order: any,
  orderBy: any,
  type?: any
) => {
  if (orderBy) {
    data.sort((a: any, b: any) => {
      let sort = 0;
      if (Object.prototype.toString.call(b[orderBy]) === "[object Number]") {
        if (b[orderBy] === null && a[orderBy] !== null) {
          sort = -1;
        } else if (b[orderBy] !== null && a[orderBy] === null) {
          sort = 1;
        } else if (b[orderBy] < a[orderBy]) {
          sort = -1;
        } else if (b[orderBy] > a[orderBy]) {
          sort = 1;
        }
      } else {
        if ("technician_code" === orderBy) {
          b[orderBy] = b["userinfo_repair"]["erp_user_id"];
          a[orderBy] = a["userinfo_repair"]["erp_user_id"];
        }
        if ("service_center" === orderBy) {
          b[orderBy] = b["userinfo_repair"]["service_center"];
          a[orderBy] = a["userinfo_repair"]["service_center"];
        }
        if ("role_id" == orderBy) {
          if (type === TabKey.DZUserRegistration) {
            b[orderBy] = b["role_id"];
            a[orderBy] = a["role_id"];
          } else {
            b[orderBy] = b["userinfodz"]["role_id"];
            a[orderBy] = a["userinfodz"]["role_id"];
          }
        }
        if ("customer_number_DZ" === orderBy) {
          if (type === TabKey.DZUserRegistration) {
            b[orderBy] = b["customer_no"];
            a[orderBy] = a["customer_no"];
          } else {
            b[orderBy] = b["userinfodz"]["customer_no"];
            a[orderBy] = a["userinfodz"]["customer_no"];
          }
        }
        if ("customer_name" === orderBy) {
          if (
            type === TabKey.customerMaster ||
            type === TabKey.DZUserRegistration
          ) {
            b[orderBy] = b["customer_name"];
            a[orderBy] = a["customer_name"];
          } else {
            b[orderBy] = b["userinfodz"]["customer_name"];
            a[orderBy] = a["userinfodz"]["customer_name"];
          }
        }
        if ("sms_receive_notification" === orderBy) {
          if (
            type === TabKey.customerMaster ||
            type === TabKey.DZUserRegistration
          ) {
            b[orderBy] = b["sms_receive_notification"];
            a[orderBy] = a["sms_receive_notification"];
          } else {
            b[orderBy] = b["userinfodz"]["sms_receive_notification"];
            a[orderBy] = a["userinfodz"]["sms_receive_notification"];
          }
        }
        if ("mail_receive_notification" === orderBy) {
          if (
            type === TabKey.customerMaster ||
            type === TabKey.DZUserRegistration
          ) {
            b[orderBy] = b["mail_receive_notification"];
            a[orderBy] = a["mail_receive_notification"];
          } else {
            b[orderBy] = b["userinfodz"]["mail_receive_notification"];
            a[orderBy] = a["userinfodz"]["mail_receive_notification"];
          }
        }
        if ("created_date" === orderBy) {
          b[orderBy] = b["userinfodz"]["created_date"];
          a[orderBy] = a["userinfodz"]["created_date"];
        }
        if ("last_login_date" === orderBy) {
          b[orderBy] = b["userinfodz"]["last_login_date"];
          a[orderBy] = a["userinfodz"]["last_login_date"];
        }
        if ("repair_access" === orderBy) {
          b[orderBy] = b["userinfodz"]["repair_access"];
          a[orderBy] = a["userinfodz"]["repair_access"];
        }
        if ("eordering_ordering" === orderBy) {
          b[orderBy] = b["userinfodz"]["eordering_ordering"];
          a[orderBy] = a["userinfodz"]["eordering_ordering"];
        }
        if ("eordering_display_dealer_price" === orderBy) {
          b[orderBy] = b["userinfodz"]["eordering_display_dealer_price"];
          a[orderBy] = a["userinfodz"]["eordering_display_dealer_price"];
        }
        if ("eordering_order_invoices" === orderBy) {
          b[orderBy] = b["userinfodz"]["eordering_order_invoices"];
          a[orderBy] = a["userinfodz"]["eordering_order_invoices"];
        }
        if ("contacts_new" === orderBy) {
          b[orderBy] = b["userinfodz"]["contacts_new"];
          a[orderBy] = a["userinfodz"]["contacts_new"];
        }
        if ("wco_claim" === orderBy) {
          b[orderBy] = b["userinfodz"]["wco_claim"];
          a[orderBy] = a["userinfodz"]["wco_claim"];
        }
        if ("wco_credit_note" === orderBy) {
          b[orderBy] = b["userinfodz"]["wco_credit_note"];
          a[orderBy] = a["userinfodz"]["wco_credit_note"];
        }
        if ("nc_show" === orderBy) {
          b[orderBy] = b["userinfodz"]["nc_show"];
          a[orderBy] = a["userinfodz"]["nc_show"];
        }
        if ("nc_apply_cancel" === orderBy) {
          b[orderBy] = b["userinfodz"]["nc_apply_cancel"];
          a[orderBy] = a["userinfodz"]["nc_apply_cancel"];
        }
        if ("item_no" === orderBy) {
          b[orderBy] = b["model_no"];
          a[orderBy] = a["model_no"];
        }
        if (!b[orderBy]) {
          b[orderBy] = "";
        }
        if (!a[orderBy]) {
          a[orderBy] = "";
        }
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
      }
      return order === "desc" ? sort : -sort;
    });
  }
  return data;
};

// response list add id
export const setListId = (list: any) => {
  const newList = list.slice(0);
  newList.map((item: any, index: any) => (item.rid = index));
  return newList;
};

// get useMaster new data
export const getUseMasterNewData = (editList: any) => {
  return {
    given_name: editList.given_name.trim(),
    family_name: editList.family_name.trim(),
    middle_name: editList.middle_name !== '' && editList.middle_name !== null && editList.middle_name !== undefined ? editList.middle_name.trim() : '',
    affiliate_cd: editList.affiliate_cd,
    primary_locale: editList.primary_locale,
    tel_no: editList.tel_no,
    mail_address: editList.mail_address,
    use_repair: true,
    use_dz: editList.use_dz,
    user_block: editList.user_block,
    updated_date: editList.updated_date,
    userinfo_repair: {
      erp_user_id: editList.userinfo_repair.erp_user_id,
      service_center: editList.userinfo_repair.service_center,
      updated_date: editList.userinfo_repair.updated_date,
    },
    userinfodz: {
      customer_no: editList.userinfodz.customer_no,
      role_id: editList.userinfodz.role_id,
      sms_receive_notification: editList.userinfodz.sms_receive_notification,
      mail_receive_notification: editList.userinfodz.mail_receive_notification,
      updated_date: editList.userinfodz.updated_date,
    },
  };
};
// get itemMaster new data
export const getItemMasterNewData = (
  editList: any,
  serviceCenterDataList: any,
  itemServiceCenterCheckedList: any
) => {
  const list: any = [];
  serviceCenterDataList.forEach((item: any) => {
    if (itemServiceCenterCheckedList[item.service_center_id]) {
      const itemMessage = {
        service_center_id: item.service_center_id,
      };
      list.push(itemMessage);
    }
  });
  return {
    item_no: encodeURIComponent(editList.item_no),
    description: editList.description,
    model_no: editList.model_no,
    list_price: editList.list_price,
    item_discount_cd: editList.item_discount_cd,
    service_center_list: list,
    memo: editList.memo,
    updated_date: editList.updated_date,
  };
};
// get DZUserInfo new data
export const getDZUserInfoNewData = (editList: any) => {
  return {
    given_name: editList.given_name.trim(),
    family_name: editList.family_name.trim(),
    middle_name: editList.middle_name !== '' && editList.middle_name !== null && editList.middle_name !== undefined ? editList.middle_name.trim() : '',
    affiliate_cd: editList.affiliate_cd,
    primary_locale: editList.primary_locale,
    tel_no: editList.tel_no,
    mail_address: editList.mail_address,
    use_repair:
      editList.use_repair !== undefined &&
        editList.use_repair !== "" &&
        editList.use_repair.toString().toUpperCase() === "TRUE"
        ? true
        : false,
    use_dz: true,
    user_block: editList.user_block,
    updated_date: editList.updated_date,
    userinfo_repair: {
      erp_user_id: editList.userinfo_repair.erp_user_id,
      service_center: editList.userinfo_repair.service_center,
      updated_date: editList.userinfo_repair.updated_date,
    },
    userinfodz: {
      customer_no: editList.userinfodz.customer_no,
      role_id: editList.userinfodz.role_id,
      sms_receive_notification: editList.userinfodz.sms_receive_notification,
      mail_receive_notification: editList.userinfodz.mail_receive_notification,
      updated_date: editList.userinfodz.updated_date,
    },
  };
};
// get DZUserRegistration new data
export const getDZUserRegistrationNewData = (editList: any) => {
  return {
    given_name: editList.given_name.trim(),
    family_name: editList.family_name.trim(),
    middle_name: editList.middle_name !== '' && editList.middle_name !== null && editList.middle_name !== undefined ? editList.middle_name.trim() : '',
    company_name: editList.company_name,
    customer_no: editList.customer_no,
    affiliate_cd: editList.affiliate_cd,
    primary_locale: editList.primary_locale,
    tel_no: editList.tel_no,
    mail_address: editList.mail_address,
    role_id: editList.role_id,
    sms_receive_notification: editList.sms_receive_notification,
    mail_receive_notification: editList.mail_receive_notification,
    updated_date: editList.updated_date,
  };
};

// get DZUserInfo new data
export const getDZUserInfoFromegistrationNewData = (editList: any) => {
  return {
    given_name: editList.given_name.trim(),
    family_name: editList.family_name.trim(),
    middle_name: editList.middle_name !== '' && editList.middle_name !== null && editList.middle_name !== undefined ? editList.middle_name.trim() : '',
    affiliate_cd: editList.affiliate_cd,
    primary_locale: editList.primary_locale,
    tel_no: editList.tel_no,
    mail_address: editList.mail_address,
    use_repair: false,
    use_dz: true,
    user_block: false,
    updated_date: editList.updated_date,
    userinfo_repair: {
      erp_user_id: editList.userinfo_repair.erp_user_id,
      service_center: editList.userinfo_repair.service_center,
      updated_date: editList.userinfo_repair.updated_date,
    },
    userinfodz: {
      customer_no: editList.customer_no,
      role_id: editList.role_id,
      sms_receive_notification: editList.sms_receive_notification,
      mail_receive_notification: editList.mail_receive_notification,
      updated_date: editList.updated_date,
    },
  };
};

// get customerMaster new data
export const getCustomerMasterNewData = (editList: any) => {
  return {
    customer_name: editList.customer_name,
    address_1: editList.address_1,
    address_2: editList.address_2,
    address_3: editList.address_3,
    country: editList.country,
    state_cd: editList.state_cd,
    postal_cd: editList.postal_cd,
    currency: editList.currency,
    mail_address: editList.mail_address,
    mail_address_repair: editList.mail_address_repair,
    tel_no: editList.tel_no,
    fax_no: editList.fax_no,
    customer_discount_cd: editList.customer_discount_cd,
    postage_free_code: editList.postage_free_code,
    locale: editList.locale,
    memorandum: editList.memorandum,
    sms_receive_notification: editList.sms_receive_notification,
    mail_receive_notification: editList.mail_receive_notification,
    quote_note: editList.quote_note,
    updated_date: editList.updated_date,
    asc_flg: editList.asc_flg,
    wco_picture: editList.wco_picture,
    wco_auto_approve: editList.wco_auto_approve,
    wco_credit_note_creation: editList.wco_credit_note_creation,
    wco_credit_note_interval: editList.wco_credit_note_interval,
  };
};
// get role new data
export const getRoleNewData = (
  editList: any,
  permissionList: any,
  permissionCheckedList: any
) => {
  const list: any = [];
  permissionList.forEach((item: any) => {
    if (
      permissionCheckedList[
      "G" + item.permission_group + "ID" + item.permission_id
      ]
    ) {
      const itemMessage = {
        permission_group: item.permission_group,
        permission_id: item.permission_id,
      };
      list.push(itemMessage);
    }
  });
  return {
    role_group: editList.role_group,
    role_name: editList.role_name,
    role_permissions: list,
    updated_date: editList.updated_date,
  };
};

// get serviceCenter new data
export const getServiceCenterNewData = (editList: any) => {
  return {
    service_center_id: editList.service_center_id,
    name: editList.name,
    address_1: editList.address_1,
    address_2: editList.address_2,
    address_3: editList.address_3,
    country: editList.country,
    state_cd: editList.state_cd,
    postal_cd: editList.postal_cd,
    facility_cd: editList.facility_cd,
    warehouse: editList.warehouse,
    updated_date: editList.updated_date,
  };
};

export const getEditOrRegisterSetting = (
  flg: boolean = false,
  type: any,
  affiliate_cd: any
) => {
  if (type === TabKey.useMaster) {
    return flg
      ? {
        affiliate_cd:
          getAffiliateCdAndPrimaryLocale(affiliate_cd).affiliate_cd,
        primary_locale:
          getAffiliateCdAndPrimaryLocale(affiliate_cd).primary_locale,
        use_dz: !flg,
        user_block: !flg,
        mailAddressFlg: flg,
        registerFlg: flg,
        userinfodz: {
          role_id: "2",
          sms_receive_notification: !flg,
          mail_receive_notification: !flg,
        },
      }
      : {
        mailAddressFlg: flg,
        registerFlg: flg,
      };
  } else if (type === TabKey.DZUserInfo) {
    return flg
      ? {
        affiliate_cd:
          getAffiliateCdAndPrimaryLocale(affiliate_cd).affiliate_cd,
        primary_locale:
          getAffiliateCdAndPrimaryLocale(affiliate_cd).primary_locale,
        user_block: !flg,
        mailAddressFlg: flg,
        registerFlg: flg,
        userinfodz: {
          role_id: "2",
          sms_receive_notification: !flg,
          mail_receive_notification: !flg,
        },
      }
      : { mailAddressFlg: flg, registerFlg: flg };
  } else if (type === TabKey.DZUserRegistration) {
    return flg
      ? {
        affiliate_cd:
          getAffiliateCdAndPrimaryLocale(affiliate_cd).affiliate_cd,
        primary_locale:
          getAffiliateCdAndPrimaryLocale(affiliate_cd).primary_locale,
        user_block: !flg,
        mailAddressFlg: flg,
        registerFlg: flg,
        role_id: "1",
        sms_receive_notification: !flg,
        mail_receive_notification: !flg,
      }
      : { mailAddressFlg: flg, registerFlg: flg };
  } else if (type === TabKey.serviceCenter) {
    return { country: getCountry(affiliate_cd).country, registerFlg: flg };
  } else if (type === TabKey.laborMaster) {
    return { registerFlg: flg };
  } else if (type === TabKey.role) {
    return flg
      ? { role_group: 1, roleIDFlg: true, registerFlg: flg }
      : { registerFlg: flg };
  }
  return {
    user_block: flg,
    mailAddressFlg: flg,
    registerFlg: flg,
  };
};

export const createDeleteParam = (updatedDate: any) => {
  return {
    updated_date: updatedDate,
  };
};

export const createDeleteClaimLaborParam = (row: any) => {
  return [
    {
      created_by: row.created_by,
      created_date: row.created_date,
      error_description: row.error_description,
      error_type: row.error_type,
      fault_description: row.fault_description,
      index: row.index,
      labor_value: row.labor_value,
      model_no: row.model_no,
      update_flg: "2",
      updated_by: row.updated_by,
      updated_date: row.updated_date,
    },
  ];
};

export const deleteItemFromList = (id: any, list: any) => {
  const newList = list.slice(0) as any;
  newList.splice(id, 1);
  return newList;
};

export const getCountriesListArray = (list: any) => {
  let newList: any = [];
  const objList: any = Object.keys(list);
  for (let i = 0; i < objList.length; i++) {
    const obj = {
      id: objList[i],
      name: list[objList[i]],
    };
    newList.push(obj);
  }
  return newList;
};

export const getSign = (config: any) => {
  return getSignByCSV(config.affiliate_cd);
};

export const getCSVTitleInfo = (t: any) => {
  let _title: any = {
    model_no: t("laborMaster.model_no"),
    error_type: t("laborMaster.error_type"),
    error_description: t("laborMaster.error_description"),
    labor_value: t("laborMaster.labor_value"),
    fault_description: t("laborMaster.fault_description"),
    delete_flg: t("laborMaster.delete_flg"),
  };
  return _title;
};

export const getDZuserInfoCSVTitleInfo = (t: any, config: any) => {
  let _title: any = {};
  if (config.affiliate_cd.toUpperCase() !== "MD") {
    _title = {
      given_name: t("dZUserInfo.given_name"),
      family_name: t("dZUserInfo.family_name"),
      middle_name: t("dZUserInfo.middle_name"),
      affiliate_cd: t("dZUserInfo.affiliate_cd"),
      primary_locale: t("dZUserInfo.primary_locale"),
      tel_no: t("dZUserInfo.tel_no"),
      mail_address: t("dZUserInfo.mail_address"),
      role_id: t("dZUserInfo.role_id"),
      customer_no: t("dZUserInfo.customer_number"),
      customer_name: t("dZUserInfo.customer_name"),
      sms_receive_notification: t("dZUserInfo.sms_receive_notification"),
      mail_receive_notification: t("dZUserInfo.mail_receive_notification"),
      created_date: t("dZUserInfo.created_date"),
      last_login_date: t("dZUserInfo.last_login_date"),
      eordering_ordering: t("dZUserInfo.eordering_ordering"),
      eordering_display_dealer_price: t(
        "dZUserInfo.eordering_display_dealer_price"
      ),
      eordering_order_invoices: t("dZUserInfo.eordering_order_invoices"),
      contacts_new: t("dZUserInfo.contacts_new"),
      wco_claim: t("dZUserInfo.wco_claim"),
      wco_credit_note: t("dZUserInfo.wco_credit_note"),
      user_block: t("dZUserInfo.user_block"),
    };
  } else {
    _title = {
      given_name: t("dZUserInfo.given_name"),
      family_name: t("dZUserInfo.family_name"),
      middle_name: t("dZUserInfo.middle_name"),
      affiliate_cd: t("dZUserInfo.affiliate_cd"),
      primary_locale: t("dZUserInfo.primary_locale"),
      tel_no: t("dZUserInfo.tel_no"),
      mail_address: t("dZUserInfo.mail_address"),
      role_id: t("dZUserInfo.role_id"),
      customer_no: t("dZUserInfo.customer_number"),
      customer_name: t("dZUserInfo.customer_name"),
      sms_receive_notification: t("dZUserInfo.sms_receive_notification"),
      mail_receive_notification: t("dZUserInfo.mail_receive_notification"),
      created_date: t("dZUserInfo.created_date"),
      last_login_date: t("dZUserInfo.last_login_date"),
      repair_access: t("dZUserInfo.repair_access"),
      eordering_ordering: t("dZUserInfo.eordering_ordering"),
      eordering_display_dealer_price: t(
        "dZUserInfo.eordering_display_dealer_price"
      ),
      eordering_order_invoices: t("dZUserInfo.eordering_order_invoices"),
      nc_show: t("dZUserInfo.nc_show"),
      nc_apply_cancel: t("dZUserInfo.nc_apply_cancel"),
      contacts_new: t("dZUserInfo.contacts_new"),
      wco_claim: t("dZUserInfo.wco_claim"),
      wco_credit_note: t("dZUserInfo.wco_credit_note"),
      user_block: t("dZUserInfo.user_block"),
    };
  }
  return _title;
};

export const getUserMasterInfoCSVTitleInfo = (t: any, data: any) => {
  let roleNameList: any;

  let _title: any = {
    given_name: t("userMaster.given_name"),
    family_name: t("userMaster.family_name"),
    middle_name: t("userMaster.middle_name"),
    affiliate_cd: t("userMaster.affiliate_cd"),
    tel_no: t("userMaster.tel_no"),
    mail_address: t("userMaster.mail_address"),
    use_repair: t("userMaster.use_repair"),
    erp_user_id: t("userMaster.technician_code"),
    service_center: t("userMaster.service_center"),
    use_dz: t("userMaster.use_dz"),
    role_id: t("userMaster.role_id"),
    customer_no: t("userMaster.customer_number"),
    user_block: t("userMaster.user_block"),
  };

  if (data.RoleList != "" && data.RoleList.length > 0) {
    roleNameList = data.RoleList.slice(0);
    for (let key in roleNameList) {
      let roleName = "role_name" + [key]
      _title[roleName] = roleNameList[key].role_name;
    }
  };

  _title["delete_flg"] = t("userMaster.delete");
  _title["created_date"] = t("userMaster.created_date");
  _title["created_by"] = t("userMaster.created_by");
  _title["updated_date"] = t("userMaster.updated_date");
  _title["updated_by"] = t("userMaster.updated_by");
  return _title;
};

export const getCSVTitle = (sign: any, t: any) => {
  let str = "";

  const _title: any = getCSVTitleInfo(t);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item] !== "") {
      if (itemIndex < titleSize) {
        str += _title[item] + sign;
      } else {
        str += _title[item];
      }
    }
  }
  str += "\n";
  return str;
};

export const getDZuserInfoCSVTitle = (sign: any, t: any, config: any) => {
  let str = "";

  const _title: any = getDZuserInfoCSVTitleInfo(t, config);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item] !== "") {
      if (itemIndex < titleSize) {
        str += _title[item] + sign;
      } else {
        str += _title[item];
      }
    }
  }
  str += "\n";
  return str;
};

export const getUserMasterInfoCSVTitle = (sign: any, t: any, data: any) => {
  let str = "";

  const _title: any = getUserMasterInfoCSVTitleInfo(t, data);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item] !== "") {
      if (itemIndex < titleSize) {
        str += _title[item] + sign;
      } else {
        str += _title[item];
      }
    }
  }
  str += "\n";
  return str;
};

export const doQuotationMarks = (item: any) => {
  let str = String(item);
  return str.replace(/[\"]/g, '""');
};

// get CSV contents
export const getCSVContents = (t: any, data: any, config: any) => {
  let str = "";
  const sign = getSign(config);
  const _title = getCSVTitleInfo(t);
  str += getCSVTitle(sign, t);

  data.forEach((item: any) => {
    for (let key in _title) {
      let v = item[key] === null || item[key] === undefined ? "" : item[key];
      v = doQuotationMarks(v);
      if (
        key === "model_no" ||
        key === "error_type" ||
        key === "error_description" ||
        key === "labor_value" ||
        key === "fault_description" ||
        key === "delete_flg"
      ) {
        if (!v) {
          v = "";
        }
        if (key === "delete_flg") {
          str += '"' + v + '"';
        } else {
          str += '"' + v + '"' + sign;
        }
      }
    }

    str += "\n";
  });
  return str;
};

// get DZuserInfo CSV contents
export const getDZuserInfoCSVContents = (t: any, data: any, config: any) => {
  let str = "";
  const sign = getSign(config);
  const _title = getDZuserInfoCSVTitleInfo(t, config);
  str += getDZuserInfoCSVTitle(sign, t, config);

  data.forEach((item: any) => {
    for (let key in _title) {
      let v = "";
      if (
        item[key] === null ||
        item[key] === undefined ||
        key === "created_date"
      ) {
        if (item["userinfodz"]) {
          if (
            item["userinfodz"][key] === null ||
            item["userinfodz"][key] === undefined
          ) {
            v = "";
          } else {
            v = item["userinfodz"][key];
          }
        }
      } else {
        v = item[key];
      }

      v = getDZuserInfoFormatValue(key, v, config);

      v = doQuotationMarks(v);
      if (
        key === "given_name" ||
        key === "family_name" ||
        key === "middle_name" ||
        key === "affiliate_cd" ||
        key === "primary_locale" ||
        key === "tel_no" ||
        key === "mail_address" ||
        key === "role_id" ||
        key === "customer_no" ||
        key === "customer_name" ||
        key === "sms_receive_notification" ||
        key === "mail_receive_notification" ||
        key === "created_date" ||
        key === "last_login_date" ||
        key === "repair_access" ||
        key === "eordering_ordering" ||
        key === "eordering_display_dealer_price" ||
        key === "eordering_order_invoices" ||
        key === "nc_show" ||
        key === "nc_apply_cancel" ||
        key === "contacts_new" ||
        key === "wco_claim" ||
        key === "wco_credit_note" ||
        key === "user_block"
      ) {
        if (!v) {
          v = "";
        }
        if (key === "user_block") {
          str += '"' + v + '"';
        } else {
          str += '"' + v + '"' + sign;
        }
      }
    }

    str += "\n";
  });
  return str;
};

const getDZuserInfoFormatValue = (key: any, value: any, config: any) => {
  let retValue = value;

  if (key === "primary_locale") {
    retValue = value.toLocaleUpperCase();
  } else if (key === "sms_receive_notification") {
    retValue = value ? "Y" : "N";
  } else if (key === "mail_receive_notification") {
    retValue = value ? "Y" : "N";
  } else if (key === "created_date") {
    retValue = !value || value === "" ? "" : getCSVDate(value);
  } else if (key === "last_login_date") {
    retValue = !value || value === "" ? "" : getCSVDate(value);
  } else if (
    config.affiliate_cd.toUpperCase() === "MD" &&
    key === "repair_access"
  ) {
    retValue = value ? "Y" : "N";
  } else if (key === "eordering_ordering") {
    retValue = value ? "Y" : "N";
  } else if (key === "eordering_display_dealer_price") {
    retValue = value ? "Y" : "N";
  } else if (key === "eordering_order_invoices") {
    retValue = value ? "Y" : "N";
  } else if (config.affiliate_cd.toUpperCase() === "MD" && key === "nc_show") {
    retValue = value ? "Y" : "N";
  } else if (
    config.affiliate_cd.toUpperCase() === "MD" &&
    key === "nc_apply_cancel"
  ) {
    retValue = value ? "Y" : "N";
  } else if (key === "contacts_new") {
    retValue = value ? "Y" : "N";
  } else if (key === "wco_claim") {
    retValue = value ? "Y" : "N";
  } else if (key === "wco_credit_note") {
    retValue = value ? "Y" : "N";
  } else if (key === "user_block") {
    retValue = value ? "Y" : "N";
  }

  return retValue;
};

// get UserMasterInfo CSV contents
export const getUserMasterInfoCSVContents = (t: any, data: any, config: any, com: any, serviceCenterDataList: any) => {
  let str = "";
  const sign = getSign(config);
  const _title = getUserMasterInfoCSVTitleInfo(t, data);
  str += getUserMasterInfoCSVTitle(sign, t, data);

  let userList = data.UserList.slice(0);
  let roleList = data.RoleList.slice(0);

  userList.forEach((item: any) => {
    for (let key in _title) {
      let v = "";
      let w = "";
      if (
        item["User"][key] === null ||
        item["User"][key] === undefined
      ) {
        v = "";
      } else {
        v = item["User"][key];
      }

      v = getUserMasterInfoFormatValue(key, v);

      v = doQuotationMarks(v);
      if (
        key === "given_name" ||
        key === "family_name" ||
        key === "middle_name" ||
        key === "affiliate_cd" ||
        key === "tel_no" ||
        key === "mail_address" ||
        key === "use_repair" ||
        key === "erp_user_id" ||
        key === "service_center" ||
        key === "use_dz" ||
        key === "role_id" ||
        key === "customer_no" ||
        key === "user_block" ||
        key === "delete_flg" ||
        key === "created_date" ||
        key === "created_by" ||
        key === "updated_date" ||
        key === "updated_by"

      ) {
        if (!v) {
          v = "";
        }
        if (key === "created_date") {
          if (v) {
            v = getCSVDateWithHours(v);
          } else {
            v = "";
          }
        }
        if (key === "updated_date") {
          if (v) {
            v = getCSVDateWithHours(v);
          } else {
            v = "";
          }
        }
        if (key === "service_center" && v !== "") {
          v = v + ":" + getServiceCenterName(v, serviceCenterDataList);
        }
        if (key === "role_id" && v !== "") {
          v = v + ":" + getCodeNameByNumber(v, com("role_id"));
        }

        w = getUserRole(item, roleList, sign);

        if (key === "delete_flg") {
          str += w + '"' + v + '"' + sign;
        } else if (key === "updated_by") {
          str += '"' + v + '"';
        } else {
          str += '"' + v + '"' + sign;
        }
      }
    }

    str += "\n";
  });
  return str;
};

const getUserRole = (item: any, roleList: any, sign: any) => {
  let v = "N";
  let str = "";

  for (let i = 0; i < roleList.length; i++) {
    let role = roleList[i].role_group + "_" + roleList[i].role_id;
    if (item["UserRoleList"] !== null && item["UserRoleList"].length > 0) {
      for (let j = 0; j < item["UserRoleList"].length; j++) {
        let userRole = item["UserRoleList"][j].role_group + "_" + item["UserRoleList"][j].role_id;
        if (userRole === role) {
          v = "Y"
          break;
        } else {
          v = "N";
        }
      }
    }
    str += '"' + v + '"' + sign;
  }
  return str;
};

const getUserMasterInfoFormatValue = (key: any, value: any) => {
  let retValue = value;

  if (key === "use_repair") {
    retValue = value ? "Y" : "N";
  } else if (key === "use_dz") {
    retValue = value ? "Y" : "N";
  } else if (key === "user_block") {
    retValue = value ? "Y" : "N";
  } else if (key === "delete_flg") {
    retValue = value ? "Y" : "N";
  }
  return retValue;
};

const getAffiliateCdAndPrimaryLocale = (affiliate_cd: any) => {
  const language_list: string = process.env
    .REACT_APP_AFFILIATE_CD_LANGUAGE as string;
  const affiliate_list: string = process.env.REACT_APP_AFFILIATE_CD as string;

  let _language_list = language_list.split(",");
  let _affiliate_list = affiliate_list.split(",");
  let _afflate_cd = affiliate_cd.toLocaleUpperCase();
  let index = _affiliate_list.indexOf(_afflate_cd);
  if (index !== -1) {
    return {
      affiliate_cd: affiliate_cd,
      primary_locale: _language_list[index],
    };
  } else {
    return {
      affiliate_cd: "MD",
      primary_locale: "de",
    };
  }
};

const getCountry = (affiliate_cd: any) => {
  const country_list: string = process.env
    .REACT_APP_AFFILIATE_CD_COUNTRY as string;
  const affiliate_list: string = process.env.REACT_APP_AFFILIATE_CD as string;

  let _country_list = country_list.split(",");
  let _affiliate_list = affiliate_list.split(",");
  let _afflate_cd = affiliate_cd.toLocaleUpperCase();
  let index = _affiliate_list.indexOf(_afflate_cd);
  if (index !== -1) {
    return {
      country: _country_list[index].toLocaleUpperCase(),
    };
  } else {
    return {
      country: "DE",
    };
  }
};

const getServiceCenterName = (value: any, serviceCenterDataList: any) => {
  let retValue = value;
  if (serviceCenterDataList.length > 0) {
    for (let key in serviceCenterDataList) {
      if (value === serviceCenterDataList[key].service_center_id) {
        retValue = serviceCenterDataList[key].name;
      }
    }
  }
  return retValue;
};
