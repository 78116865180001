import React from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getAPIErrorMessage } from "../../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
    divWidth: {
      width: "500px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    buttonControl02: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
  })
);

export const ClaimCompleteMessageBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "models.model_window",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });

  const contents = (
    <div className={classes.divWidth}>
      {props.errMessageList.length > 0 && (
        <h2 className={classes.title}>{t("prompt_title_error")}</h2>
      )}
      <p className={classes.message}>
        {props.errMessageList.map((item: any) => (
          <span>
            {item.claim_no}:{item.error_message}
            <br />
          </span>
        ))}
      </p>
    </div>
  );

  const windownButton = (
    <div className={classes.buttonControl02}>
      <Button
        className={classes.buttonWidth}
        variant="contained"
        onClick={props.handleClose}
        color="primary"
      >
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title={com("pre_approve_success", {
        registerQty: props.SuccessList.length,
      })}
      notTitle={true}
      contents={contents}
      button={windownButton}
      openFlg={props.open}
      handleCloseEvent={props.handleClose}
    />
  );
};
