import React from "react";
// hooks
import { useStyles } from "../hooks/common.styles";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    multilineTextField: {
      margin: 0,
      height: "80px",
      overflow: "auto",
      background: "rgba(0, 0, 0, 0.09)",
      borderRadius: "5px",
      padding: "5px",
      wordBreak: "break-word",
      whiteSpace: "pre-line",
    },
  })
);
export const JobsDetailPartsDrawingItemMemorandum = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { memo, title } = props;

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{title}</h3>
      <p className={myClasses.multilineTextField}>{memo}</p>
    </div>
  );
};
