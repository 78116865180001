import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/table.useStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

// component
interface HeadCell {
  id: string;
  label: string;
}
const headCells: HeadCell[] = [
  { id: "claim_no", label: "table_claim_no" },
  { id: "customer_no", label: "table_service_partner" },
  { id: "customer_no", label: "table_customer_no" },
  { id: "customer_po_no", label: "table_customer_po_no" },
  { id: "model_no", label: "table_model_no" },
  { id: "entry_date", label: "table_entered_date" },
  { id: "updated_date", label: "table_update_date" },
  { id: "approve_date", label: "table_approved_date" },
  { id: "updated_by", label: "table_update_by" },
  { id: "total_amount", label: "table_total" },
  { id: "status", label: "table_status" },
];
export const ClaimResultTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.claim" });
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox" className={classes.cellCheckbox}>
          <Checkbox
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <TableSortLabel
              active={orderBy.indexOf(headCell.id) > -1}
              direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy.indexOf(headCell.id) > -1 ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
