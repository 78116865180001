import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export let headCells: HeadCell[] = [
  { id: "Edit", label: "" },
  { id: "Delete", label: "" },
  {
    id: "given_name",
    label: "given_name",
    searchFlg: true,
  },
  {
    id: "family_name",
    label: "family_name",
    searchFlg: true,
  },
  {
    id: "middle_name",
    label: "middle_name",
    searchFlg: true,
  },
  { id: "affiliate_cd", label: "affiliate_cd", searchFlg: true },
  { id: "primary_locale", label: "primary_locale", searchFlg: true },
  { id: "tel_no", label: "tel_no", searchFlg: true },
  { id: "mail_address", label: "mail_address", searchFlg: true },
  { id: "role_id", label: "role_id", searchFlg: true },
  { id: "customer_number_DZ", label: "customer_number", searchFlg: true },
  { id: "customer_name", label: "customer_name", searchFlg: true },
  {
    id: "sms_receive_notification",
    label: "sms_receive_notification",
    searchFlg: true,
  },
  {
    id: "mail_receive_notification",
    label: "mail_receive_notification",
    searchFlg: true,
  },
  {
    id: "created_date",
    label: "created_date",
    searchFlg: true,
  },
  {
    id: "last_login_date",
    label: "last_login_date",
    searchFlg: true,
  },
  { id: "repair_access", label: "repair_access", searchFlg: true },
  { id: "eordering_ordering", label: "eordering_ordering", searchFlg: true },
  {
    id: "eordering_display_dealer_price",
    label: "eordering_display_dealer_price",
    searchFlg: true,
  },
  {
    id: "eordering_order_invoices",
    label: "eordering_order_invoices",
    searchFlg: true,
  },
  { id: "nc_show", label: "nc_show", searchFlg: true },
  { id: "nc_apply_cancel", label: "nc_apply_cancel", searchFlg: true },
  { id: "contacts_new", label: "contacts_new", searchFlg: true },
  { id: "wco_claim", label: "wco_claim", searchFlg: true },
  { id: "wco_credit_note", label: "wco_credit_note", searchFlg: true },
  { id: "user_block", label: "user_block", searchFlg: true },
  { id: "Edit", label: "" },
  { id: "Delete", label: "" },
];

export const MasterMaintenanceDZUserInfoThead = (props: any) => {
  const { order, orderBy, onRequestSort, config } = props;
  const languagePath = "modules.components.MasterMaintenance.dZUserInfo";

  if (config.affiliate_cd.toUpperCase() !== "MD") {
    headCells = [
      { id: "Edit", label: "" },
      { id: "Delete", label: "" },
      {
        id: "given_name",
        label: "given_name",
        searchFlg: true,
      },
      {
        id: "family_name",
        label: "family_name",
        searchFlg: true,
      },
      {
        id: "middle_name",
        label: "middle_name",
        searchFlg: true,
      },
      { id: "affiliate_cd", label: "affiliate_cd", searchFlg: true },
      { id: "primary_locale", label: "primary_locale", searchFlg: true },
      { id: "tel_no", label: "tel_no", searchFlg: true },
      { id: "mail_address", label: "mail_address", searchFlg: true },
      { id: "role_id", label: "role_id", searchFlg: true },
      { id: "customer_number_DZ", label: "customer_number", searchFlg: true },
      { id: "customer_name", label: "customer_name", searchFlg: true },
      {
        id: "sms_receive_notification",
        label: "sms_receive_notification",
        searchFlg: true,
      },
      {
        id: "mail_receive_notification",
        label: "mail_receive_notification",
        searchFlg: true,
      },
      {
        id: "created_date",
        label: "created_date",
        searchFlg: true,
      },
      {
        id: "last_login_date",
        label: "last_login_date",
        searchFlg: true,
      },
      {
        id: "eordering_ordering",
        label: "eordering_ordering",
        searchFlg: true,
      },
      {
        id: "eordering_display_dealer_price",
        label: "eordering_display_dealer_price",
        searchFlg: true,
      },
      {
        id: "eordering_order_invoices",
        label: "eordering_order_invoices",
        searchFlg: true,
      },
      { id: "contacts_new", label: "contacts_new", searchFlg: true },
      { id: "wco_claim", label: "wco_claim", searchFlg: true },
      { id: "wco_credit_note", label: "wco_credit_note", searchFlg: true },
      { id: "user_block", label: "user_block", searchFlg: true },
      { id: "Edit", label: "" },
      { id: "Delete", label: "" },
    ];
  }

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
