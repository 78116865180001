import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
    table: {
      width: "100%",
      margin: "0",
      borderSpacing: "0",
      "&  td": {
        paddingTop: "1em",
      },
    },
    iconCell: { verticalAlign: "top" },
    titleCell: {
      width: "100px",
      paddingRight: "5px",
      textAlign: "right",
      verticalAlign: "top",
      "& > p": {
        margin: "0",
      },
    },
    svgIcon: {
      color: "#56BD5B",
    },
    svgIconGray: {
      color: "gray",
    },
    textField: {
      width: "95%",
      "& input": {
        width: "95%",
        height: "20px",
        lineHeight: "20px",
        padding: "5px",
      },
    },
    postionCell: {
      paddingRight: "120px",
      position: "relative",
    },
    smallButton: {
      minWidth: "20px",
      height: "30px",
    },
    buttonPosition: {
      width: "130px",
      height: "29px",
      position: "absolute",
      top: "14px",
      right: "1px",
      "& .MuiTouchRipple-root": {
        width: "180px",
      }
    },
    mustMark: {
      color: "red",
    },
    photoInput: {
      display: "none",
    },
    photoList: {
      listStyle: "none",
      margin: "0",
      padding: "0",
    },
    photoListItem: {
      display: "flex",
      justifyContent: "space-between",
      padding: "5px 0",
      borderTop: "1px solid #000",
      "&:first-child": {
        borderTop: "none",
      },
      "& > a": {
        display: "block",
        paddingRight: "10px",
      },
      "& > button": {
        padding: "0",
        borderRadius: "0",
      },
    },
    multiline: {
      width: "95%",
      "& > .MuiOutlinedInput-multiline": {
        padding: "5px 0 5px 5px",
      },
    },
    flexCell: {
      display: "flex",
    },
    labelsField: {
      padding: "0 5px",
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
        textAlign: "center",
      },
    },
    printButton: {
      padding: "0 50px 0 10px",
      "& button": {
        padding: "0",
        borderRadius: "0",
      },
    },
    receiveButton: {
      width: "130px",
      height: "29px",
      position: "absolute",
      top: "14px",
      right: "1px",
      "& .MuiTouchRipple-root": {
        width: "180px",
      }
    },
    wagonDzContents: {
      padding: "0 10px",
    },
    wagonDzTitle: {
      textAlign: "center",
    },
    wagonDzTitleInput: {
      width: "110px",
      marginLeft: "10px",
      "& input": {
        width: "100%",
        height: "40px",
        lineHeight: "40px",
        padding: "2px",
        textAlign: "center",
        color: "#039F54",
        fontWeight: "bold",
        fontSize: "2em",
      },
    },
    wagonDzSubTitle: {
      textAlign: "center",
      color: "#039F54",
    },
    wagonDzTable: {
      border: "1px solid #000",
      borderSpacing: "0",
      width: "100%",
      "& tr th:not(:first-child),& tr td:not(:first-child)": {
        borderLeft: "1px solid #000",
      },
      "& td": {
        borderTop: "1px solid #000",
      },
    },
    wagonDzTableTitle: {
      background: "rgba(240, 242, 244, 0.5)",
    },
    wagonDzTableTbody: {
      "& td": {
        textAlign: "center",
      },
    },
    wagonDzTableButton: {
      width: "50px",
      "& button": {
        padding: "0",
        borderRadius: "none",
      },
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
    wagonDzTableControl: {
      listStyle: "none",
      width: "100%",
      margin: "0",
      padding: "10px 0 0 0",
      height: "50px",
    },
    wagonDzTablePrevButton: {
      float: "left",
    },
    wagonDzTableNextButton: {
      float: "right",
    },
    wagonDztableControlButton: {
      height: "30px",
    },
    no: {
      width: "40px",
    },
    labelsText: {
      margin: "0",
      padding: "0 20px",
      lineHeight: "30px",
    },
  })
);
