import React from "react";
// UI
import { useStyles } from "../hooks/useStyles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export const Note = (props: any) => {
  const classes = useStyles();
  return (
    <tr>
      <td className={classes.titleCell}>
        <p>
          {props.text}
          <span className={classes.mustMark}></span>
        </p>
      </td>
      <td className={classes.postionCell}>
        <TextField
          variant="outlined"
          value={props.note}
          className={classes.multiline}
          placeholder={props.placeHolder}
          multiline
          rows={4}
          onChange={(e) =>
            props.changeData("note", e.target.value, "repair_note")
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              name="accessoryAttached"
              color="primary"
              checked={props.accessory_attached}
              onChange={(e) =>
                props.changeData("accessory_attached", e.target.checked)
              }
            />
          }
          label={props.textAccessoryAttached}
        />
      </td>
    </tr>
  );
};
