import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// constants
import { TabKey } from "../MasterMaintenance.constants";
import {
  isEmptyCheck,
  checkMaxLength,
} from "../../../modules/hooks/UseValication";

// api
import api from "../../../modules/api/Api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "&  td": {
        paddingBottom: "20px",
      },
    },
    mark: {
      color: "red",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
        paddingTop: "5px",
      },
    },
    selectContainer: {
      width: "100%",
      height: "30px",
    },
    select: {
      height: "30px",
      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "300px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    line: {
      border: "1px solid black",
    },
    buttonWidth: {
      width: "90px",
      height: "35px",
    },
    buttonWidth2: {
      width: "130px",
      height: "35px",
    },
  })
);

export const MasterMaintenanceRoleEditor = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.role",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    changeData,
    editData,
    handleBack,
    handleSubmit,
    roleGroup,
    permissionList,
    permissionCheckedList,
    setPermissionValue,
    apiLoading,
    config,
    roleGroupFlg,
    setRoleGroupFlg,
  } = props;
  const { role_group, role_id, role_name, registerFlg } = editData;

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("role_name" === itemName) {
      roleNameValidationCheck(itemValue);
    }
  };

  useEffect(() => {
    if (isEmptyCheck(role_name) || roleNameErrorFlg) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
  });

  console.log("role permissionList", permissionList);
  console.log("permissionCheckedList:", permissionCheckedList);

  const [permissionGroupList, setPermissionGroupList] = React.useState([]);
  const [disabledFlg, setDisabledFlg] = React.useState(true);
  const [roleNameErrorFlg, setRoleNameErrorFlg] = React.useState(false);
  const [roleNameErrorMessage, setRoleNameErrorMessage] = React.useState("");

  //Role Name check
  const roleNameValidationCheck = (value: any) => {
    let flg = false;
    if (isEmptyCheck(value)) {
      flg = isEmptyCheck(value);
      let message = com("messages.MS0001", {
        param1: t("role_name"),
      });
      setRoleNameErrorFlg(flg);
      setRoleNameErrorMessage(message);
    } else if (!checkMaxLength(value, 50)) {
      flg = !checkMaxLength(value, 50);
      let message = com("messages.MS0002", {
        param1: t("role_name"),
        param2: 50,
      });
      setRoleNameErrorFlg(flg);
      setRoleNameErrorMessage(message);
    } else {
      setRoleNameErrorFlg(flg);
      setRoleNameErrorMessage("");
    }
  };

  // start
  const [startFlg, setStartFlg] = useState(false);
  useEffect(() => {
    if (!startFlg || !roleGroupFlg) {
      setStartFlg(true);
      setRoleGroupFlg(true);
      apiLoading(true);
      let permissionGroupValue: any = [];
      permissionList.map((item: any) => {
        if (item.permission_group == role_group) {
          permissionGroupValue.push(item);
        }
      });
      setPermissionGroupList(permissionGroupValue);

      if (registerFlg) {
        // AM1901
        api
          .getPermissionListAPI(config)
          .then(({ response }: { response: any }) => {
            console.log("getPermissionListAPI response", response);
            apiLoading(false);
            if (response && response.length > 0) {
              let newList: any = {};
              response.forEach((item: any) => {
                newList[
                  "G" + item.permission_group + "ID" + item.permission_id
                ] = item.selected_permission_id !== undefined;
              });
              setPermissionValue(newList);
            }
          });
      } else {
        // AM1302
        api
          .getReadRolePermissionListAPI(role_group, role_id, config)
          .then(({ response }: { response: any }) => {
            console.log("getReadRolePermissionListAPI response", response);
            apiLoading(false);
            if (response && response.length > 0) {
              let newList: any = {};
              response.forEach((item: any) => {
                newList[
                  "G" + item.permission_group + "ID" + item.permission_id
                ] = item.selected_permission_id !== undefined;
              });
              setPermissionValue(newList);
            }
          });
      }
    }
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>{t("role_group")}</th>
            <td>
              <FormControl
                disabled={!registerFlg}
                variant="outlined"
                className={classes.selectContainer}
              >
                <Select
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  value={role_group}
                  onChange={(e) => changeData("role_group", e.target.value)}
                >
                  {roleGroup.map((item: any) => (
                    <MenuItem value={item.role_group} key={item.role_group}>
                      {item.role_group_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
          </tr>
          {!registerFlg && (
            <tr>
              <th>{t("role_id")}</th>
              <td>
                <TextField
                  disabled
                  variant="outlined"
                  className={classes.textField}
                  value={role_id}
                  onChange={(e) => changeData("role_id", e.target.value)}
                />
              </td>
            </tr>
          )}
          <tr>
            <th>
              {t("role_name")}
              <span className={classes.mark}>*</span>
            </th>
            <td>
              <TextField
                error={roleNameErrorFlg}
                helperText={roleNameErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={role_name}
                onBlur={(e) => changeDataListen("role_name", e.target.value)}
                onChange={(e) => changeData("role_name", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>{t("permission")}</th>
            <td className={classes.line}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {permissionGroupList.map((item: any) => (
                    <FormControlLabel
                      key={
                        "G" + item.permission_group + "ID" + item.permission_id
                      }
                      control={
                        <Checkbox
                          checked={
                            permissionCheckedList[
                              "G" +
                                item.permission_group +
                                "ID" +
                                item.permission_id
                            ]
                          }
                          color="primary"
                          onChange={(e: any) =>
                            changeData(
                              "G" +
                                item.permission_group +
                                "ID" +
                                item.permission_id,
                              e.target.checked,
                              "permission"
                            )
                          }
                          name={item.permission_name}
                        />
                      }
                      label={item.permission_name}
                      labelPlacement="start"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.buttonWidth2}
                  variant="contained"
                  onClick={handleBack}
                >
                  {t("back")}
                </Button>
                <Button
                  className={classes.buttonWidth2}
                  disabled={disabledFlg}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(TabKey.role, registerFlg)}
                >
                  {t("submit")}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
