import React from "react";

// component
import { ModelTable } from "./Report.Model.Table";

export const Model = (props: any) => {
  const {
    data
  } = props;
  return (
    <>
      <ModelTable
        data={data}
      />
    </>
  );
};
