import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/control.useStyles";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";

const defaultValue = {
  technician_cd: "",
};

export const JobsTechnicialListControl = (props: any) => {
  const { getCommonInfo } = useCommonInfo();
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.jobs" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  // search value
  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const setSearchValue = (property: any, value: any) => {
    const valueList = Object.assign({}, searchValueList, { [property]: value });
    setSearchValueList(valueList);
  };

  useEffect(() => {
    if (props.reloadFlg) {
      setSearchValueList(defaultValue);
      props.setReloadFlg(false);
      resetTableContainerScroll();
    }
    if (props.forwardToTechnicianFlg) {
      setSearchValueList(defaultValue);
      resetTableContainerScroll();
      props.setForwardToTechnicianFlg(false);
    }
    if (props.isClickedSortHeader) {
      props.setClickedSortHeader(false);
      handleSearchEvent(false);
    }
  }, [
    props.reloadFlg,
    props.forwardToTechnicianFlg,
    props.isClickedSortHeader,
  ]);

  // chech the button whether is disabled
  const isDisabled = () => {
    return !Boolean(searchValueList.technician_cd);
  };

  // reset the scroll position
  const resetTableContainerScroll = () => {
    let tableContainer = document.getElementById(
      "tableContainer_technicianList"
    );
    if (tableContainer) {
      tableContainer.scrollTop = 0;
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
    }
  };

  // search button click event
  const handleSearchEvent = (needCheck = true) => {
    props.setAlertMessageList([]);
    props.setTechnicianCode("");
    // alert(searchValueList.technician_cd);
    if (
      needCheck ||
      (!needCheck &&
        searchValueList.technician_cd &&
        searchValueList.technician_cd !== "")
    ) {
      let isExisted = false;
      let technicans = props.technicianAggregateInfo.tech_list;
      for (let index in technicans) {
        if (
          technicans[index].erp_user_id.toUpperCase() ===
          searchValueList.technician_cd.toUpperCase()
        ) {
          isExisted = true;
          props.setTechnicianCode(technicans[index].erp_user_id);
          let rowData = document.getElementById(technicans[index].erp_user_id);
          if (rowData) {
            rowData.focus();
            let tableContainer = document.getElementById(
              "tableContainer_technicianList"
            );
            let tableHead = document.getElementById("tableHead_technician");
            if (tableContainer && tableHead) {
              // tableContainer.scrollTop = 0;
              tableContainer.scrollTop =
                rowData.getBoundingClientRect().top -
                tableHead.getBoundingClientRect().bottom;
              // alert(tableContainer.scrollTop);
            }
          }
          break;
        }
      }
      if (needCheck && !isExisted) {
        const obj: any = {
          no: props.alertMessageList.length,
          message: com("messages.MS0004", {
            param1: t("table_technician_code"),
          }),
          alertType: "error",
        };
        let alertMessageListNew: any = [obj];
        props.setAlertMessageList(alertMessageListNew);
      }
    }
  };

  // clear button click event
  const handleClearEvent = () => {
    props.setAlertMessageList([]);
    props.setTechnicianCode("");
    props.setOrder("asc");
    props.setOrderBy("erp_user_id");
    setSearchValueList(defaultValue);
    resetTableContainerScroll();
  };
  let commonInfo = getCommonInfo() as any;

  const getOptionLabel = (option: any) =>
    option.erp_user_id + " : " + option.name || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    // limit: 10,
    stringify: getOptionLabel,
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <dl>
          <dt>{t("txt_technician_cd_2")}</dt>
          <dd className={classes.technicianCodeDd}>
            <Autocomplete
              className={classes.autoComplete}
              id="technician_cd"
              freeSolo
              disableClearable
              options={commonInfo.technicianList}
              getOptionLabel={getOptionLabel}
              filterOptions={filterOptions}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "250px",
                  }}
                  placement="bottom-start"
                />
              )}
              inputValue={searchValueList.technician_cd}
              value={searchValueList.technician_cd}
              getOptionSelected={(option: any, value: any) =>
                option.erp_user_id.toString().toUpperCase() ===
                searchValueList.technician_cd.toString().toUpperCase()
              }
              renderInput={(params) => (
                <TextField {...params} margin="normal" variant="outlined" />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  setSearchValue("technician_cd", value.erp_user_id);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  setSearchValue("technician_cd", value);
                }
              }}
            />
          </dd>
        </dl>
        <div className={classes.buttonArea}>
          <Button
            className={classes.buttonCondition}
            variant="contained"
            color="primary"
            onClick={() => handleSearchEvent()}
            startIcon={<SearchIcon />}
            disabled={isDisabled()}
          >
            {t("btn_Search")}
          </Button>
        </div>
        <div className={classes.buttonArea}>
          <Button
            className={classes.buttonCondition}
            variant="contained"
            onClick={handleClearEvent}
            startIcon={<RefreshIcon />}
          >
            {t("btn_Clear")}
          </Button>
        </div>
      </div>
    </form>
  );
};
