import React, { FC, useState, useEffect, useCallback } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { OrderSearchControl } from "./components/Order.SearchControl";
import { OrderSearchResultTable } from "./components/Order.SearchResult.Table";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// api
import api from "../../modules/api/Api";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// methods
import { getApiResultStatus, 
         getCodeNameByNumber, 
         getAPIErrorInfo} from "../../modules/common.methods";
// route
import { useHistory } from "react-router-dom";
import { PathKey } from "../../pages/AppRoutes";
// permissions
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import { isAllEmpty, isAllErrMsgmpty, getCSVContents, getCSVFileName, } from "./methods/Order.Methods";
import FileSaver from "file-saver";

export const defaultValue = {
  order_no: "",
  order_approved: [0],
  order_confirmed: [0],
  customer_no: "",
  perPage: 10,
  page: 0,
  order_entry_date_from: null,
  order_entry_date_to: null,
  customer_no_error: "",
  order_entry_date_from_error: "",
  order_entry_date_to_error: "",
};

export const Page: FC = () => {
  const [orderData, setOrderData] = useState(defaultValue);
  const [resultData, setResultData] = useState([]);
  // start
  const [startFlg, setStartFlg] = useState(false);
  // language
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

  // loading
  const {
    setCommonInfo,
    getCommonInfo,
    setCommonInfoFull,
    getCommonConfig,
    setOrderInfo,
    getHistoryRouter,
    getOrderInfo,
    getUserPermissions,
    setUserPermissions,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  const history = useHistory();

  const config = getCommonConfig() as any;

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  const moveToDetail = (path: any) => {
    handleOnClick(path);
  };

  // permissions
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();

  let historyRouter = getHistoryRouter() as any;
  let orderInfo = getOrderInfo() as any;

  const changeOrderData = (property: any, value: any) => {
    const valueList = Object.assign({}, orderData, { [property]: value });
    setOrderData(valueList);
  };

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = com("messages." + response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  // search button click event
  const searchHandler = () => {
    searchHandlerFunc();
    setOrderInfo((prevState: any) => {
      return Object.assign({}, prevState, orderData, { page: 0 });
    });
  };

  // search list api
  const searchHandlerFunc = (param: any = "") => {
    if (!param) {
      param = orderData;
    }
    apiLoading(true);
    let params = getParam(param);
    setAlertMessage("");
    setAlertShow(false);
    // AT1305
    api
      .getReadOrderListAPI(config, params)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          setResultData([]);
          apiLoading(false);
        } else {
          if (response) {
            if (response.total_num >= 2000) {
              const message = com("messages.MS0049");
              setAlertMessage(message);
              setAlertShow(true);
              setAlertType("warning");
            }
            if (response.orders.length > 0) {
              response.orders.forEach((item: any) => {
                item.order_approve_name = getCodeNameByNumber(
                  item.order_approve,
                  com("order_approved")
                );
                item.order_confirmation_name = getCodeNameByNumber(
                  item.order_confirmation,
                  com("order_confirmed")
                );
              });
            }
            setResultData(response.orders.slice(0));
            apiLoading(false);
          }
        }
      });
  };

  const dateFormat = (data: any) => {
    const formatDoubleDigit = (data: any) => {
      return data >= 10 ? data : "0" + data;
    };
    const getYear = data.getFullYear();
    const getMonth = formatDoubleDigit(data.getMonth() + 1);
    const getDate = formatDoubleDigit(data.getDate());
    return getYear + "-" + getMonth + "-" + getDate;
  };

  const getParam = (param: any) => {
    let _param = "";
    if (param.order_no) {
      _param = `order_no=${param.order_no}`;
    } else {
      if (param.order_approved.length < 3 && param.order_approved.length > 0) {
        param.order_approved.forEach((item: any, index: any) => {
          _param += `&order_approve=${item}`;
        });
      } else {
        _param += "&order_approve=";
      }
      if (
        param.order_confirmed.length < 3 &&
        param.order_confirmed.length > 0
      ) {
        param.order_confirmed.forEach((item: any) => {
          _param += `&order_confirmation=${item}`;
        });
      } else {
        _param += "&order_confirmation=";
      }
      if (param.customer_no) {
        _param += `&customer_no=${param.customer_no}`;
      } else {
        _param += "&customer_no=";
      }
      if (param.order_entry_date_from) {
        _param += `&order_entry_date_from=${encodeURIComponent(dateFormat(new Date(param.order_entry_date_from)))}`;
      } else {
        _param += "&order_entry_date_from=";
      }
      if (param.order_entry_date_to) {
        _param += `&order_entry_date_to=${encodeURIComponent(dateFormat(new Date(param.order_entry_date_to)))}`;
      } else {
        _param += "&order_entry_date_to=";
      }
    }
    return _param;
  };

  // init
  useEffect(() => {
    if (!startFlg && Object.keys(config).length > 0) {
      // getDefaultApiList();
      if (historyRouter.to === String(PathKey.ORDER)) {
        if (historyRouter.from === String(PathKey.ORDERDETAIL)) {
          setOrderData(orderInfo);
          searchHandlerFunc(orderInfo);
        } else {
          setOrderData(defaultValue);
          searchHandlerFunc();
          const newData = Object.assign({}, orderInfo, {
            order_no: "",
            order_approved: [0],
            order_confirmed: [0],
            customer_no: "",
            customer_no_error: "",
            page: 0,
          });
          setOrderInfo(newData);
        }
        setStartFlg(true);
      }
      if (commonInfo.customerList.length === 0) {
        api
          .getCustomerDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("get AM0205 customer master", response);
            if (getApiResultStatus(code)) {
              if (response && response.length > 0) {
                setCommonInfoFull({
                  customerList: response,
                });
              }
            } else {
              if (code !== 404) {
                errorMessage(response);
              }
            }
          });
      }
    } else {
      if (!commonInfo.loadingFlg && Object.keys(config).length === 0) {
        apiLoading(true);
      }
    }
  }, [startFlg, config, historyRouter]);

  const [permissionsFlag, setPermissionFlag] = useState(false);
  useEffect(() => {
    if (!permissionsFlag && Object.keys(config).length > 0) {
      setPermissionFlag(true);
      getLocalUserPermissions();
    }
  }, [permissionsFlag, config]);

  const getLocalUserPermissions = () => {
    let apiList = [];
    let localUserPermissions = {};
    apiLoading(true);
    // permission
    if (!userPermissions.permission_group) {
      const api5 = getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
      apiList.push(api5);
    } else {
      localUserPermissions = userPermissions;
    }

    Promise.all(apiList).then(() => {
      initStart(localUserPermissions);
    });
  };

  const initStart = (localUserPermissions: any) => {
    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(userPermissionsInfo);
  };

  const uploadPersonInCharge = async (
    path: any,
    row: any,
    logingUserName: any,
    loginUserSub: any
  ) => {
    let postRequestData = {
      updated_date: row.updated_date,
      person_in_charge: logingUserName,
      person_in_charge_sub: loginUserSub,
    };

    apiLoading(true);

    if (row.order_approve !== 1) {
      api
      .updateOrderPersonInChargeAPI(config, row.order_no, postRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        

        if (!getApiResultStatus(code)) {
          errorMessage(response);
          apiLoading(false);
        } else {
          moveToDetail(path);
        }
      });
    } else {
      moveToDetail(path);
    }


  };

  const beforeToDetail = (path: any, row: any) => {
    if (row.person_in_charge && row.person_in_charge.length > 0) {
      moveToDetail(path);
    } else {
      let logingUserName = "";
      let loginUserSub = "";

      // if (config.affiliate_cd === "MD") {
      if (config.middle_name && config.middle_name.length > 0) {
        logingUserName =
          config.given_name +
          " " +
          config.middle_name +
          " " +
          config.family_name;
      } else {
        logingUserName = config.given_name + " " + config.family_name;
      }
      // }

      if (config.sub === undefined) {
        loginUserSub = "";
      } else {
        loginUserSub = config.sub;
      }
      uploadPersonInCharge(path, row, logingUserName, loginUserSub);
    }
  };

  const setCSV = (data: any) => {
    const str = getCSVContents(com, t, data, config);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "OrderList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const downloadCSVBySearch = () => {
    setAlertMessage("");
    setAlertShow(false);
    if (isAllEmpty(orderData)) {
      const message = com("messages.MS0007");
      setAlertMessage(message);
      setAlertShow(true);
      setAlertType("error");
    } else if (isAllErrMsgmpty(orderData)) {
      apiLoading(true);
      let _params: any = getParam(orderData);
      api
        .getOrderCSVListAPI(config, _params)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            apiLoading(false);
            response = getAPIErrorInfo(
              response,
              "getOrderCSVListAPI"
            );
              const errorMessage = com("messages." + response.message_id, {
                param1: t(response.key),
              });
              setAlertMessage(errorMessage);
              setAlertShow(true);
              setAlertType("error");
          } else {
            const url = response.content;
            api
              .getDownloadFile(url)
              .then(({ code, response }: { code: any; response: any }) => {
                if (!getApiResultStatus(code)) {
                  apiLoading(false);
                  const errorMessage = com("messages." + response.message_id, {
                    param1: com("messages_key_name." + response.key),
                  });
                  setAlertMessage(errorMessage);
                  setAlertShow(true);
                  setAlertType("error");
                } else {
                  let reader: any = new FileReader();
                  reader.readAsText(response, "UTF-8");
                  reader.onload = function () {
                    let data = this.result;
                    setCSV(JSON.parse(data));
                    apiLoading(false);
                  };
                }
              });
          }
        });
    }
  };

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <OrderSearchControl
        orderData={orderData}
        setOrderData={setOrderData}
        changeOrderData={changeOrderData}
        searchHandler={searchHandler}
        userPermisssionInfo={userPermisssionInfo}
        api={api}
        config={config}
        commonInfo={commonInfo}
        setAlertShow={setAlertShow}
        setAlertMessage={setAlertMessage}
        setAlertType={setAlertType}
        downloadCSVBySearch={downloadCSVBySearch}
      />
      <OrderSearchResultTable
        resultData={resultData}
        beforeToDetail={beforeToDetail}
      />
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
