import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { WagonDZOverviewItem } from "./Receiving.WagonDZOverview.Item";
import { WagonDZOverviewControl } from "./Receiving.WagonDZOverview.Control";
// UI
import { useStyles } from "../hooks/useStyles";
import TextField from "@material-ui/core/TextField";

export const WagonDZOverview = (props: any) => {
  const classes = useStyles();
  const {
    subTitle,
    data,
    changeMessage,
    handleEditEvent,
    handleDeleteEvent,
    btnNextWagon,
    handleNextWagonClick,
    findCarInfor,
  } = props;
  const { t } = useLocale({ defaultPath: "modules.components.receiving" });

  const getNextWagonButtonDisable = () => {
    if(data.title !=="" && data.list.length > 0) {
      return false;
    }else {
      return true;
    }
  }

  return (
    <div className={classes.wagonDzContents}>
      <h1 className={classes.wagonDzTitle}>
        {subTitle === "DZ"
          ? t("txt_wagonDzOverview")
          : t("txt_wagonNDzOverview")}
        <TextField
          variant="outlined"
          value={data.title}
          className={classes.wagonDzTitleInput}
          onChange={(e) => changeMessage(e.target.value, subTitle)}
          onBlur={(e) => findCarInfor(e.target.value, subTitle)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              findCarInfor(data.title, subTitle);
            }
          }}
        />
      </h1>
      <h2 className={classes.wagonDzSubTitle}>
        {t("txt_Quantity")}
        <span>{data.sub_no}</span>
      </h2>
      <table className={classes.wagonDzTable}>
        <thead>
          <tr className={classes.wagonDzTableTitle}>
            <th>#</th>
            <th>{t("table_title_JobNo")}</th>
            <th>{t("table_title_Model")}</th>
            <th className={classes.wagonDzTableButton}>
              {t("table_btn_Edit")}
            </th>
            <th className={classes.wagonDzTableButton}>
              {t("table_btn_Delete")}
            </th>
          </tr>
        </thead>
        <WagonDZOverviewItem
          list={data.list}
          subTitle={subTitle}
          wangonNumber={data.title}
          handleEditEvent={handleEditEvent}
          handleDeleteEvent={handleDeleteEvent}
        />
      </table>
      <WagonDZOverviewControl
        disabledFlg={getNextWagonButtonDisable()}
        handleNextWagonClick={handleNextWagonClick}
        btnNextWagon={btnNextWagon}
        subTitle={subTitle}
        wangonNumber={data.title}
      />
    </div>
  );
};
