import { http, upload, download } from "./Http";

// AT1801
export const GetUploadSignedUrlByWco = (media_name: any, config: any) => {
  const { baseURLClaim, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/doculdl/upload/${affiliate_cd}/${media_name}`,
    config: config,
  };
  return http(param);
};

// AT1802
export const GetDownloadSignedUrlByWco = (
  claims_no: any,
  sub_no: any,
  config: any,
  type: string = ""
) => {
  const { baseURLClaim, affiliate_cd, token } = config;
  const _url = type === "pdf" ? `${claims_no}` : `${claims_no}/${sub_no}`;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/doculdl/download/${affiliate_cd}/${_url}`,
    config: config,
  };
  return http(param);
};

// AT0407
export const GetDownloadCreditNoteSignedUrlByWco = (
  ref_no: any,
  config: any
) => {
  const { baseURLClaim, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURLClaim}/v1/doculdl/download/credit_note/${affiliate_cd}/${ref_no}`,
    config: config,
  };
  return http(param);
};
