import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export const headCells: HeadCell[] = [
  {
    id: "service_center_id",
    label: "service_center_id",
    searchFlg: true,
  },
  {
    id: "name",
    label: "name",
    searchFlg: true,
  },
  {
    id: "address_1",
    label: "address_1",
    searchFlg: true,
  },
  { id: "address_2", label: "address_2", searchFlg: true },
  { id: "address_3", label: "address_3", searchFlg: true },
  { id: "country", label: "country", searchFlg: true },
  { id: "state_cd", label: "state_cd", searchFlg: true },
  { id: "postal_cd", label: "postal_cd", searchFlg: true },
  { id: "facility_cd", label: "facility_cd", searchFlg: true },
  { id: "warehouse", label: "warehouse", searchFlg: true },
  { id: "Edit", label: "" },
  { id: "Delete", label: "" },
];

export const MasterMaintenanceServiceCenterThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath = "modules.components.MasterMaintenance.serviceCenter";

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
