import React from "react";
import clsx from "clsx";
// hook
import { useStyles } from "../hooks/table.useStyles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// root
import { Link } from "react-router-dom";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// language
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";

type Order = "asc" | "desc";

export const SearchResultTableTbody = (props: any) => {
  const { pageInfo, selected, order, orderBy } = props;
  const classes = useStyles();
  const isSelected = (name: string) => selected.indexOf(name) > -1;

  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);

        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]"
          ) {
            if (bValues[0] === null && aValues[0] !== null) {
              sort = -1;
            } else if (bValues[0] !== null && aValues[0] === null) {
              sort = 1;
            } else if (bValues[0] < aValues[0]) {
              sort = -1;
            } else if (bValues[0] > aValues[0]) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        // console.log("sort", aValues, bValues);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          const isItemSelected = isSelected(row.job_no);
          const path =
            GetPrivateAppRoutePath(PathKey.JOBSDETAIL) + `?jobno=${row.job_no}`;
          return (
            <TableRow
              className={clsx(
                classes.tableRow,
                row.elapsed_time >= 24 &&
                  row.elapsed_time < 48 &&
                  classes.tableRowYellow,
                row.elapsed_time >= 48 && classes.tableRowRed
              )}
              hover
              onClick={(event) => props.userPermisssionInfo.job_allocation_authFlg?(props.handleClick(event, row)):""}
              tabIndex={-1}
              key={row.job_no}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                {props.userPermisssionInfo.job_allocation_authFlg? (
                <Checkbox 
                  checked={isItemSelected} 
                />
                ): (
                  <Checkbox 
                  disabled={!props.userPermisssionInfo.job_allocation_authFlg}
                />
                )}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.received_date || row.received_date === ""
                  ? ""
                  : date(new Date(row.received_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.elapsed_time + " h"}
              </TableCell>
              <TableCell className={classes.nowrap}>
                <Link to={path}>{row.job_no}</Link>
              </TableCell>
              <TableCell className={classes.nowrap}>{row.statusName}</TableCell>
              <TableCell className={classes.nowrap}>{row.model_no}</TableCell>
              <TableCell className={classes.nowrap}>{row.serial_no}</TableCell>
              <TableCell className={classes.nowrap}>
                {row.customer_po_no}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.customer_no}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.customer_name}
              </TableCell>
              <TableCell className={classes.nowrap}>{row.postal_cd}</TableCell>
              <TableCell className={classes.nowrap}>{row.address_1}</TableCell>
              <TableCell className={classes.nowrap}>{row.address}</TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.quote_sent_date || row.quote_sent_date === ""
                  ? ""
                  : date(new Date(row.quote_sent_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.quote_remind_date || row.quote_remind_date === ""
                  ? ""
                  : date(new Date(row.quote_remind_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.quoteResultName}
              </TableCell>
              <TableCell className={classes.nowrap}>
                {row.location_no}
              </TableCell>
              <TableCell className={classes.nowrap}>{row.name}</TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
