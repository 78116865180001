import React, { useState } from "react";
// components
import { MasterMaintenanceSearchBox } from "./MasterMaintenance.SearchBox";
import { MasterMaintenanceLaborMasterThead } from "./MasterMaintenance.LaborMaster.Thead";
import { MasterMaintenanceLaborMasterTbody } from "./MasterMaintenance.LaborMaster.Tbody";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { MasterMaintenanceErrorMessageBox } from "./MasterMaintenance.ErrorMessageBox";
import { TabKey } from "../MasterMaintenance.constants";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// api
import api from "../../../modules/api/Api";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { getSign, checkSplitSymbol } from "../methods/common.methods";

// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// hook
import { useStyles } from "../hooks/table.useStyles";
// methods
import {
  checkMaxLength,
  checkTypeNumber,
  checkTypeNumberWithoutZero,
} from "../../../modules/hooks/UseValication";

import {
  getApiResultStatus,
} from "../../../modules/common.methods";

export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
    },
    buttonArea: {
      marginRight: "10px",
    },
    input: {
      display: "none",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
  })
);

export const MasterMaintenanceLaborMaster = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.laborMaster",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const {
    setCommonInfo,
    getCommonConfig,
    getCommonInfo,
    setCommonInfoFull
  } = useCommonInfo();

  const config = getCommonConfig() as any;
  const commonInfo = getCommonInfo() as any;

  const apiLoading = (flg: boolean) => {

    setCommonInfo("loadingFlg", flg);
  };

  const [searchKey, setSearchKey] = useState("");
  const {
    laborMasterResultList,
    getLaborMasterListAPI,
    downloadCSV,
    serviceCenterDataList,
  } = props;
  // pager
  const [page, setPage] = useState(0);
  // 1.3 according to the row number, calculate the number of pages(20 lines per page)
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // delete
  const [deleteRow, setDeleteRow] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // search
  const handleChangeKey = (value: any) => {
    setSearchKey(value);
    props.SearchResult(value, TabKey.laborMaster);
    setPage(0);
  };

  const handleSearch = () => {
    props.SearchResult(searchKey, TabKey.laborMaster);
    setPage(0);
  };

  // delete button click event
  const handleDelete = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  // message box
  const [open, setOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    props.handleDelete(deleteRow, TabKey.laborMaster);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };
  const handleSuccessAndErrorMessageClose = () => {
    setSuccessAndErrorMessageOpen(false);
    getLaborMasterListAPI();
  };

  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  // success and error message box
  const [successAndErrorMessageOpen, setSuccessAndErrorMessageOpen] = useState(false);

  const [errMessageList, setErrMessageList] = useState([]);
  const [showSuccessAndErrorMessage, setShowSuccessAndErrorMessage] = useState([]);
  const [successAndErrorMessageList, setSuccessAndErrorMessageList] = useState([]);

  const csvDefaultValue: any = {
    model_no: "",
    error_type: "",
    error_description: "",
    labor_value: "",
    fault_description: false,
    // updated_date: "",
    line_no: 0
  };

  const isDateValue = (value: any) => {

    if (!value) {
      return false;
    }
    if (value.length !== 20) {
      return false;
    }

    let newDateValue = new Date(value);

    return !isNaN(newDateValue.getTime());
  }

  const getCSVTitleInfo = (t: any) => {
    let _title: any = {
      model_no: t("model_no"),
      error_type: t("error_type"),
      error_description: t("error_description"),
      labor_value: t("labor_value"),
      fault_description: t("fault_description"),
      delete_flg: t("delete_flg"),
    }
    return _title;
  };

  const getCSVTitle = (sign: any, t: any) => {

    let str = "";

    const _title: any = getCSVTitleInfo(t);

    let titleSize = Object.getOwnPropertyNames(_title).length;
    let itemIndex = 0;
    for (let item in _title) {
      itemIndex++;
      if (_title[item] !== "") {
        if (itemIndex < titleSize) {
          str += _title[item] + sign;
        } else {
          str += _title[item];
        }
      }
    }
    str += "\n";
    return str;
  };


  //click upload button
  const handlerUpload = async (files: any) => {

    console.log("handlerUpload:", files);
    const file = files[0];
    if (files && files.length > 0) {
      var reader = new FileReader();
      reader.onload = async function (e) {

        let errorData: any = [];
        let message = {};

        let postData: any = [];
        let editData: any = [];

        let data = reader.result;

        let csvDataList = parseData(data);

        if (!csvDataList || csvDataList.length === 0) {
          return;
        }
        let dbDataList = JSON.parse(JSON.stringify(laborMasterResultList));
        let dbModelNo: any = [];

        csvDataList.sort((item1: any, item2: any) => {
          if (item1.model_no < item2.model_no) {
            return -1;
          } else if (item1.model_no > item2.model_no) {
            return 1;
          } else {
            if (item1.error_type < item2.error_type) {
              return -1;
            } else if (item1.error_type > item2.error_type) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        dbDataList.sort((item1: any, item2: any) => {
          if (item1.model_no < item2.model_no) {
            return -1;
          } else if (item1.model_no > item2.model_no) {
            return 1;
          } else {
            if (item1.error_type < item2.error_type) {
              return -1;
            } else if (item1.error_type > item2.error_type) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        let model_no_temp = "";
        dbDataList.forEach((dataObject: any, index: any) => {

          if (index === 0) {
            dbModelNo = [...dbModelNo, dataObject.model_no];
          } else {
            if (model_no_temp !== dataObject.model_no) {
              dbModelNo = [...dbModelNo, dataObject.model_no];
            }
          }
          model_no_temp = dataObject.model_no;
        });

        const csvChangeDataList: any[] = [];
        const csvRegisterDataList: any[] = [];
        model_no_temp = "";
        let dbModelNo_temp: any = JSON.parse(JSON.stringify(dbModelNo));
        let update_flag = -1;

        csvDataList.forEach((dataObject: any) => {
          if (model_no_temp !== dataObject.model_no) {
            let iPosition: Number = dbModelNo_temp.indexOf(dataObject.model_no);
            if (iPosition !== -1) {
              csvChangeDataList.push(dataObject);
              dbModelNo_temp.splice(iPosition, 1);
              update_flag = 0;
            } else {
              csvRegisterDataList.push(dataObject);
              update_flag = 1;
            }
          } else {
            if (update_flag === 0) {
              csvChangeDataList.push(dataObject);
            } else if (update_flag === 1) {
              csvRegisterDataList.push(dataObject);
            }
          }
          model_no_temp = dataObject.model_no;

        });

        model_no_temp = "";
        let model_error_type = "";

        for (let index = 0; index < csvChangeDataList.length; index++) {

          let dataObject: any = csvChangeDataList[index];

          if (dataObject.error_type && model_no_temp === dataObject.model_no && model_error_type === dataObject.error_type) {
            message = com("MS0087", {
              rowNum: t("row") + " " + dataObject.line_no,
              param2: t("model_no") + ": " + dataObject.model_no + " " + t("error_type") + ": " + dataObject.error_type,
            });
            errorData.push(message + "\n");
          }
          model_no_temp = dataObject.model_no;
          model_error_type = dataObject.error_type;
        }

        model_no_temp = "";
        let prev_model_error = 0;

        for (let index = 0; index < csvRegisterDataList.length; index++) {

          let dataObject: any = csvRegisterDataList[index];

          if (dataObject.error_type) {

            message = com("MS0108", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("error_type"),
            });
            errorData.push(message + "\n");
          }

          if (model_no_temp !== dataObject.model_no) {

            if (!masterExitOfModelNo(dataObject.model_no)) {
              message = com("MS0004", {
                rowNum: t("row") + " " + dataObject.line_no,
                param1: t("model_no"),
              });
              errorData.push(message + "\n");
              prev_model_error = 1;
            } else {
              prev_model_error = 0;
            }
          } else {
            if (prev_model_error === 1) {
              message = com("MS0004", {
                rowNum: t("row") + " " + dataObject.line_no,
                param1: t("model_no"),
              });
              errorData.push(message + "\n");
            }
          }
          model_no_temp = dataObject.model_no;
        }

        if (errorData.length > 0) {
          setErrMessageList(errorData.sort((para1: any, para2: any) => {
            let array1 = para1.split(" ");
            let array2 = para2.split(" ");

            return array1[1] * 1 - array2[1] * 1;
          }));
          setErrorMessageOpen(true);
          return;
        }

        model_no_temp = "";
        let editDataSub: any = [];

        for (let index = 0; index < csvChangeDataList.length; index++) {
          let dataObject: any = csvChangeDataList[index];

          if (model_no_temp && model_no_temp !== dataObject.model_no) {
            editData[editData.length] = editDataSub;
            editDataSub = [];
          }
          editDataSub = [...editDataSub, dataObject];
          model_no_temp = dataObject.model_no;
        }

        if (editDataSub.length > 0) {
          editData[editData.length] = editDataSub;
        }

        let postDataSub: any = [];
        model_no_temp = "";

        for (let index = 0; index < csvRegisterDataList.length; index++) {
          let dataObject: any = csvRegisterDataList[index];

          if (model_no_temp && model_no_temp !== dataObject.model_no) {

            postData[postData.length] = postDataSub;
            postDataSub = [];
          }
          postDataSub = [...postDataSub, dataObject];
          model_no_temp = dataObject.model_no;
        }

        if (postDataSub.length > 0) {
          postData[postData.length] = postDataSub;
        }

        if (postData && postData.length > 0) {
          apiLoading(true);
          errorData = registerData(postData, 0, editData, 0, errorData);
        } else {

          apiLoading(true);
          if (editData && editData.length > 0) {
            errorData = updateData(editData, 0, 0, errorData);
          }
        }

        if (errorData.length > 0) {
          setErrMessageList(errorData.sort((para1: any, para2: any) => {
            let array1 = para1.split(" ");
            let array2 = para2.split(" ");

            return array1[1] * 1 - array2[1] * 1;
          }));
          setErrorMessageOpen(true);
          return;
        }

      };

      reader.readAsText(file);

    }
  };

  const registerData = async (postData: any, index: any, editData: any, successQty: number, paraErrorData: any) => {

    await api
      .createClaimLaborAPIForCSV(postData[index], config)
      .then(({ response, code }: { response: any; code: any }) => {

        if (!getApiResultStatus(code)) {
          let error = "";
          if (response.message_id === "MA0006") {
            error = com(response.message_id) + " " +
              t("row") + " " + response.line_no +
              t("model_no") + ": " + t(response.model_no);
          } else {
            error = com(response.message_id, {
              rowNum: t("row") + " " + response.line_no,
              param1: ("model_no") + ": " + response.model_no +
                "API." + t(response.column_name)
            });
          }
          paraErrorData[paraErrorData.length] = error + "\n";

        } else {
          successQty = successQty + postData[index].length;
        }
        if (index < postData.length - 1) {
          registerData(postData, ++index, editData, successQty, paraErrorData);
        } else {
          if (editData && editData.length > 0) {
            updateData(editData, 0, successQty, paraErrorData);
          } else {
            apiLoading(false);

            setShowSuccessAndErrorMessage(com("MS0012", {
              registerQty: successQty,
            }));
            setSuccessAndErrorMessageList(paraErrorData);

            setSuccessAndErrorMessageOpen(true);

          }
        }
      });

  }

  const updateData = async (editData: any, index: any, successQty: number, paraErrorData: any) => {


    let model_no = encodeURIComponent(editData[index][0].model_no);

    await api
      .updateClaimLaborAPIForCSV(model_no, editData[index], config)
      .then(({ response, code }: { response: any; code: any }) => {

        if (!getApiResultStatus(code)) {

          let error = "";
          if (response.message_id === "MA0006") {
            error = t("row") + " " + response.line_no + " " +
              t("model_no") + ": " + response.model_no + " " +
              com(response.message_id);
          } else {
            error = com(response.message_id, {
              rowNum: t("row") + " " + response.line_no,
              param1: t("model_no") + ": " + response.model_no +
                "API." + t(response.column_name)
            });
          }
          paraErrorData[paraErrorData.length] = error + "\n";

        } else {
          successQty = successQty + editData[index].length;
        }
        if (index < editData.length - 1) {
          updateData(editData, ++index, successQty, paraErrorData);
        } else {
          apiLoading(false);

          setShowSuccessAndErrorMessage(com("MS0012", {
            registerQty: successQty,
          }));
          setSuccessAndErrorMessageList(paraErrorData);

          setSuccessAndErrorMessageOpen(true);

        }
      });
    return paraErrorData;

  }

  const masterExitOfModelNo = (value: string) => {
    let isExisted = false;
    let items = commonInfo.itemList;

    if (value && value !== "") {
      if (items.length > 0) {
        for (let index in items) {
          if (
            items[index].model_no.toString().toUpperCase() ===
            value.toString().toUpperCase()
          ) {
            isExisted = true;
            break;
          }
        }
      }
    }

    return isExisted;
  };

  const handleQuotationMarkswithSemicolon = (rowLine: any, splitsymbol: any) => {

    let strRowLine = String(rowLine);
    let strSplitsymbol = String(splitsymbol);
    let splitSymbol = "\"" + strSplitsymbol + "\"";

    let field = strRowLine.split(splitSymbol);

    let splitSymbol2 = "\"\"";
    let splitSymbol3 = "\"";

    for (let i = 0; i < field.length; i++) {
      field[i] = field[i].replace(new RegExp(splitSymbol2, 'g'), splitSymbol3);

      if (i < (field.length - 1)) {
        if (!checkSplitSymbol(field[i], splitsymbol)) {
          return [];
        }
      } else {
        if (field[i].slice(-1) === splitsymbol) {

          let strTemp = field[i];
          strTemp = strTemp.slice(0, -1);
          if (!checkSplitSymbol(strTemp, splitsymbol)) {
            return [];
          }
        }
      }

    }
    return field;

  }

  const handleQuotationMarks = (rowLine: any, splitsymbol: any) => {

    let strRowLine = String(rowLine);
    let strSplitsymbol = String(splitsymbol);

    let field = strRowLine.split(strSplitsymbol);

    for (let i = 0; i < field.length; i++) {

      if (field[i].length > 0 && field[i].startsWith("\"") && field[i].endsWith("\"")) {
        field[i] = field[i].slice(1, -1);
      }
    }

    return field;

  }

  // parse Data
  const parseData = (data: any) => {
    const rowLines = data.split(/\r\n|\n/);
    let dataList = [] as any;

    let errorData: any = [];
    let message = {};

    if (rowLines.length < 2) {
      message = com("MS0057", {});
      errorData.push(message + "\n");
      setErrMessageList(errorData);
      return dataList;
    }

    const splitsymbol = getSign(config);
    const csvTitle = getCSVTitle(splitsymbol, t);
    const csvFileTitle = String(rowLines[0]) + "\n";
    const csvTitleLength = csvTitle.split(splitsymbol).length;

    if (csvFileTitle !== csvTitle) {
      message = com("MS0011", { rowNum: t("row") + " " + "1" });
      errorData.push(message + "\n");
      setErrMessageList(errorData);
      setErrorMessageOpen(true);
      return dataList;
    }

    for (let i = 1; i < rowLines.length; i++) {

      let rowMessage: any = [];
      if (rowLines[i].length > 1 && rowLines[i].startsWith("\"") && rowLines[i].startsWith("\"")) {
        rowMessage = handleQuotationMarkswithSemicolon(rowLines[i], splitsymbol);

        if (rowMessage.length === 0) {
          let j = i + 1;
          message = com("MS0011", { rowNum: t("row") + " " + j });
          errorData.push(message + "\n");
          continue;
        }

        rowMessage[0] = rowMessage[0].slice(1);

        if (rowMessage[rowMessage.length - 1].slice(-1) === "\"") {
          rowMessage[rowMessage.length - 1] = rowMessage[rowMessage.length - 1].slice(0, -1);
        }

      } else {
        rowMessage = handleQuotationMarks(rowLines[i], splitsymbol);
      }

      if (rowMessage.length === csvTitleLength) {

        for (let index = 0; index < csvTitleLength; index++) {
          if (index === 0) {
            rowMessage[index] = rowMessage[index].trimRight();
          } else {
            rowMessage[index] = rowMessage[index].trim();
          }
        }

        const dataObject: any = JSON.parse(JSON.stringify(csvDefaultValue));

        dataObject.line_no = i + 1;
        dataObject.model_no = rowMessage[0];
        dataObject.error_type = rowMessage[1];
        dataObject.error_description = rowMessage[2];
        dataObject.labor_value = rowMessage[3];
        dataObject.delete_flg = rowMessage[5];

        if (rowMessage[4].trim().toUpperCase() === "TRUE") {
          dataObject.fault_description = true;
        } else if (rowMessage[4].trim().toUpperCase() === "FALSE" || rowMessage[4] === "") {
          dataObject.fault_description = false;
        } else {
          message = com("MS0011", {
            rowNum: t("row") + " " + dataObject.line_no,
            param1: t("fault_description"),
          });
          errorData.push(message + "\n");
        }

        dataList.push(dataObject);

        if (!dataObject.model_no) {
          message = com("MS0001", {
            rowNum: t("row") + " " + dataObject.line_no,
            param1: t("model_no"),
          });
          errorData.push(message + "\n");
        } else {
          if (!checkMaxLength(dataObject.model_no, 35)) {
            message = com("MS0002", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("model_no"),
              param2: 35,
            });
            errorData.push(message + "\n");
          }
        }

        if (dataObject.error_type) {
          if (!checkTypeNumber(dataObject.error_type)) {
            message = com("MS0011", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("error_type"),
            });
            errorData.push(message + "\n");
          }
          if (!checkMaxLength(dataObject.error_type, 3)) {
            message = com("MS0002", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("error_type"),
              param2: 3,
            });
            errorData.push(message + "\n");
          }

        }

        if (!dataObject.error_description) {
          message = com("MS0001", {
            rowNum: t("row") + " " + dataObject.line_no,
            param1: t("error_description"),
          });
          errorData.push(message + "\n");
        } else {
          if (!checkMaxLength(dataObject.error_description, 256)) {
            message = com("MS0002", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("error_description"),
              param2: 256,
            });
            errorData.push(message + "\n");
          }
        }

        if (!dataObject.labor_value) {
          message = com("MS0001", {
            rowNum: t("row") + " " + dataObject.line_no,
            param1: t("labor_value"),
          });
          errorData.push(message + "\n");
        } else {
          if (!checkTypeNumberWithoutZero(dataObject.labor_value)) {
            message = com("MS0011", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("labor_value"),
            });
            errorData.push(message + "\n");
          }

          if (!checkMaxLength(dataObject.labor_value, 8)) {
            message = com("MS0002", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("labor_value"),
              param2: 8,
            });
            errorData.push(message + "\n");
          }
        }

        if (!dataObject.delete_flg) {
          message = com("MS0001", {
            rowNum: t("row") + " " + dataObject.line_no,
            param1: t("delete_flg"),
          });
          errorData.push(message + "\n");
        } else {
          if (!(dataObject.delete_flg === "0" || dataObject.delete_flg === "1")) {
            message = com("MS0011", {
              rowNum: t("row") + " " + dataObject.line_no,
              param1: t("delete_flg"),
            });
            errorData.push(message + "\n");
          }
        }

      } else {
        if (!(rowMessage.length === 1 && rowMessage[0].trim() === "")) {
          message = com("MS0011", { rowNum: t("row") + " " + (i + 1) });
          errorData.push(message + "\n");
        }
      }
    }

    if (errorData.length > 0) {
      setErrMessageList(errorData);
      setErrorMessageOpen(true);
      return;
    }

    return dataList;
  };


  const UserMasterModelWindow =
    <div className={myClasses.divWidth}>
      {successAndErrorMessageList.length > 0 &&
        <h2 className={myClasses.title}>{t("error")}</h2>
      }
      <p className={myClasses.message}>{successAndErrorMessageList}</p>
    </div>

  const UserMasterModelWindowButton = (
    <div className={myClasses.buttonControl}>
      <Button
        className={myClasses.buttonWidth}
        variant="contained"
        onClick={handleSuccessAndErrorMessageClose}
        color="primary">
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <div>
      <div className={myClasses.header}>
        <MasterMaintenanceSearchBox
          searchKey={searchKey}
          handleChangeKey={handleChangeKey}
          handleSearch={handleSearch}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={myClasses.buttonArea}
            startIcon={<CloudDownloadIcon />}
            onClick={downloadCSV}
          >
            {t("download")}
          </Button>
          <>
            <input
              accept=".csv"
              className={myClasses.input}
              id="contained-button-file001"
              multiple
              type="file"
              onChange={(e: any) => handlerUpload(e.target.files)}
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />
            <label htmlFor="contained-button-file001">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={myClasses.buttonArea}
                startIcon={<BackupIcon />}
              >
                {t("upload")}
              </Button>
            </label>
          </>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.handleRegister(TabKey.laborMaster)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t("register")}
          </Button>
        </div>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <MasterMaintenanceLaborMasterThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <MasterMaintenanceLaborMasterTbody
            data={laborMasterResultList}
            pageInfo={pageInfo}
            order={order}
            orderBy={orderBy}
            handleEdit={props.handleEdit}
            handleDelete={handleDelete}
            serviceCenterDataList={serviceCenterDataList}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        component="div"
        count={laborMasterResultList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
      {errMessageList.length > 0 &&
        <MasterMaintenanceErrorMessageBox
          open={errorMessageOpen}
          handleClose={handleErrorMessageClose}
          message={errMessageList}
        />
      }
      {
        <ModelWindow
          title={showSuccessAndErrorMessage}
          notTitle={true}
          contents={UserMasterModelWindow}
          button={UserMasterModelWindowButton}
          openFlg={successAndErrorMessageOpen}
          handleCloseEvent={handleSuccessAndErrorMessageClose}
        />
      }
    </div>


  );
};
