const conditionSetDleteProperty = [
  "total_unit_price",
  "total_unit_price_format",
  "unit_price",
  "unit_price_format",
  "special_price",
];
export const conditionSetFormat = (list: any) => {
  let _list = list.condition_set;
  if (_list && _list.length > 0) {
    let new_list: any = [];
    _list.forEach((item: any) => {
      let obj: any = {};
      for (let key in item) {
        if (!conditionSetDleteProperty.includes(key)) {
          obj[key] = item[key];
        }
        if (key === "unit_price") {
          obj.special_price = item.unit_price;
        }
      }
      new_list.push(obj);
    });
    list.condition_set = JSON.parse(JSON.stringify(new_list));
  }
  return list;
};
