import React from "react";
// components
import { JobsDetailMemoBox } from "./JobsDetail.Memo.MemoBox";

// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      padding: "0 0 20px 0",
    },
    CustomerMemorandum: {
      padding: "20px 10px 0 10px",
    },
    otherContents: {
      padding: "0 10px",
    },
    internalUse: {
      maxHeight: "500px",
      overflow: "auto",
      padding: "0 10px",
    },
    spacing: {
      paddingTop: "20px",
    },
  })
);

export const JobsDetailMemoGroupBox = (props: any) => {
  const myClasses = myStyles();
  const {
    otherMemoList,
    internalUseMemoList,
    handleEditEvent,
    handleDeleteEvent,
    customerMemorandum,
    userPermisssionInfo,
  } = props;
  // console.log("customerMemorandum", customerMemorandum);

  return (
    <>
      {(customerMemorandum && customerMemorandum.note) ||
      (internalUseMemoList && internalUseMemoList.length > 0) ||
      (otherMemoList && otherMemoList.length > 0) ? (
        <div className={myClasses.root}>
          {customerMemorandum && customerMemorandum.note ? (
            <div className={myClasses.CustomerMemorandum}>
              <JobsDetailMemoBox
                title="memo_customer_memorandum"
                item={customerMemorandum}
                buttonFlg={false}
                userPermisssionInfo={userPermisssionInfo}
              />
            </div>
          ) : null}
          {otherMemoList && otherMemoList.length > 0 ? (
            <div className={myClasses.otherContents}>
              {otherMemoList.map((item: any, index: number) => (
                <div key={index} className={myClasses.spacing}>
                  <JobsDetailMemoBox
                    title="memo_other_contents"
                    item={item}
                    buttonFlg={false}
                    userPermisssionInfo={userPermisssionInfo}
                  />
                </div>
              ))}
            </div>
          ) : null}
          {internalUseMemoList && internalUseMemoList.length > 0 ? (
            <div className={myClasses.internalUse}>
              {internalUseMemoList.map((item: any, index: number) => (
                <div key={index} className={myClasses.spacing}>
                  <JobsDetailMemoBox
                    title="memo_internal_use"
                    item={item}
                    buttonFlg={true}
                    handleEditEvent={handleEditEvent}
                    handleDeleteEvent={handleDeleteEvent}
                    userPermisssionInfo={userPermisssionInfo}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
