import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      minHeight: "48px",
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:not(:first-child)": {
          verticalAlign: "top",
          paddingTop: "8px",
          paddingBottom: "5px",
        },
      },
      "&:last-child td": {
        borderBottom: "none",
      },
      "& .MuiAutocomplete-root": {
        minHeight: "30px",
        padding: "0"
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":
        {
          padding: "0px",
          paddingLeft: "5px",
        },
    },
    checkbox: {
      minHeight: "42px",
    },
    item_no: {
      width: "140px",
      margin: "0 auto"
    },
    unitPacket: {
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important"
      }
    },
  })
);

export const PromotionDetailCategoryConsideredTableTbody = (props: any) => {
  const { selected } = props;
  const classes = useStyles();
  const isSelected = (name: string) => selected.indexOf(name) > -1;

  return (
    <TableBody data-testid="catergory_considered_item_body">
      {props.data.map((row: any, index: number) => {
        const isItemSelected = isSelected(row.index);
        const errorData = props.errorValueList.category_considered;
        const errorFlg1 =
          errorData &&
          errorData[index] &&
          errorData[index].item_no_error !== "";
        const errorFlg2 =
          errorData &&
          errorData[index] &&
          errorData[index].unit_packet_error !== "";

        const itemClassErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].item_class_error !== "";

        const isac1ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac1_error !== "";

        const isac2ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac2_error !== "";

        const isac3ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac3_error !== "";

        const isac4ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac4_error !== "";

        const isac5ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac5_error !== "";

        const isac6ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac6_error !== "";

        const isac7ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac7_error !== "";

        const isac8ErrorFlg =
          errorData &&
          errorData[index] &&
          errorData[index].isac8_error !== "";

        const type = "category_considered";
        return (
          <TableRow
            className={classes.tableRow}
            hover={true}
            tabIndex={-1}
            key={row.index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div className={classes.checkbox} data-testid={"catergory_considered_item_checkbox"+index}>
                {
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => props.handleClick(event, row)}
                    disabled={props.reviewFlg}
                  />
                }
              </div>
            </TableCell>
            <TableCell align="center">
              <TextField
                variant="outlined"
                value={row.item_class}
                onChange={(e) => {
                  const value = e.target.value
                    // .replace(/[^\w]/g, "")
                    .substr(0, 5);
                  props.setValue("item_class", value, index, type);
                }}
                onBlur={(e) =>
                  props.changeDataListen(
                    "category_considered_item_class",
                    e.target.value,
                    index
                  )
                }
                error={itemClassErrorFlg}
                helperText={
                  itemClassErrorFlg ? errorData[index].item_class_error : ""
                }

                disabled={props.consideredEditList[index] || props.reviewFlg}
              />
            </TableCell>
            <TableCell align="center">
              <TextField
                variant="outlined"
                value={row.discount_code}
                onChange={(e) => {
                  const value = e.target.value
                    // .replace(/[^\w]/g, "")
                    .substr(0, 3);
                  props.setValue("discount_code", value, index, type);
                }}
                disabled={props.consideredEditList[index] || props.reviewFlg}
              />
            </TableCell>
            <TableCell align="center">
              <div className={classes.item_no}  data-testid={"category_considered_item_no"+index}>
                <Autocomplete
                  id="item_no"
                  freeSolo
                  disableClearable
                  options={props.categoryConsidered}
                  getOptionLabel={props.getOptionLabel2}
                  filterOptions={props.filterOptions2}
                  inputValue={props.data[index].item_no}
                  value={props.data[index].item_no}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "200px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) =>
                    option.item_no.toString().toUpperCase() ===
                    props.data[index].item_no.toString().toUpperCase()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      onBlur={() => {
                        props.onBlurOfItemNo(type, index);
                      }}
                      onFocus={() => props.onFocusOfItemNo(type, index)}
                      error={errorFlg1}
                      helperText={errorFlg1 ? errorData[index].item_no_error : ""}
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {
                      props.setValue("item_no", value.item_no, index, type);
                    }
                  }}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      const val = value.substr(0, 35);
                      props.setValue("item_no", val, index, type);
                    }
                  }}
                  disabled={props.reviewFlg}
                />
              </div>
            </TableCell>
            <TableCell  data-testid={"category_considered_description"+index}>{row.description}</TableCell>
            <TableCell align="center">
              <div className={classes.unitPacket} data-testid={"category_considered_unitPacket"+index}>
                <TextField
                  variant="outlined"
                  value={row.unit_packet}
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^\d]/g, "")
                      .substr(0, 9);
                    props.setValue("unit_packet", value, index, type);
                  }}
                  onBlur={(e) =>
                    props.changeDataListen(
                      "units_per_packet",
                      e.target.value,
                      index
                    )
                  }
                  disabled={props.reviewFlg}
                  error={errorFlg2}
                  helperText={errorFlg2 ? errorData[index].unit_packet_error : ""}
                />
              </div>
            </TableCell>

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac1") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac1}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac1", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac1",
                        e.target.value,
                        index
                      )
                    }
                    error={isac1ErrorFlg}
                    helperText={
                      isac1ErrorFlg ? errorData[index].isac1_error : ""
                    }
    
                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac2") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac2}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac2", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac2",
                        e.target.value,
                        index
                      )
                    }
                    error={isac2ErrorFlg}
                    helperText={
                      isac2ErrorFlg ? errorData[index].isac2_error : ""
                    }

                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac3") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac3}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac3", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac3",
                        e.target.value,
                        index
                      )
                    }
                    error={isac3ErrorFlg}
                    helperText={
                      isac3ErrorFlg ? errorData[index].isac3_error : ""
                    }

                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac4") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac4}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac4", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac4",
                        e.target.value,
                        index
                      )
                    }
                    error={isac4ErrorFlg}
                    helperText={
                      isac4ErrorFlg ? errorData[index].isac4_error : ""
                    }

                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
            )}

              {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac5") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac5}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac5", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac5",
                        e.target.value,
                        index
                      )
                    }
                    error={isac5ErrorFlg}
                    helperText={
                      isac5ErrorFlg ? errorData[index].isac5_error : ""
                    }
                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
            )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac6") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac6}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac6", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac6",
                        e.target.value,
                        index
                      )
                    }
                    error={isac6ErrorFlg}
                    helperText={
                      isac6ErrorFlg ? errorData[index].isac6_error : ""
                    }
                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
            )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac7") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac7}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac7", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac7",
                        e.target.value,
                        index
                      )
                    }
                    error={isac7ErrorFlg}
                    helperText={
                      isac7ErrorFlg ? errorData[index].isac7_error : ""
                    }
                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
            )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac8") && (            
                <TableCell align="center">
                  <TextField
                    variant="outlined"
                    value={row.isac8}
                    onChange={(e) => {
                      const value = e.target.value
                        // .replace(/[^\w]/g, "")
                        .substr(0, 5);
                      props.setValue("isac8", value, index, type);
                    }}
                    onBlur={(e) =>
                      props.changeDataListen(
                        "category_considered_isac8",
                        e.target.value,
                        index
                      )
                    }
                    error={isac8ErrorFlg}
                    helperText={
                      isac8ErrorFlg ? errorData[index].isac8_error : ""
                    }
                    disabled={props.consideredEditList[index] || props.reviewFlg}
                  />
                </TableCell>
            )}


          </TableRow>
        );
      })}
    </TableBody>
  );
};
