import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import {
  date,
  time,
} from "../../../modules/components/numberFormat/NumberFormat.Date";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import { currency, 
         priceOutputBySign, 
         priceInputBySignForDecimal 
        } from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { i18text } from "../../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& td:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    dateCell: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    descriptionCell: {
      wordBreak: "break-all",
      whiteSpace: "break-spaces",
    },
  })
);

export const PromotionDetailUpdateHistoryTableTbody = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  // get currency
  const { getCommonConfig } = useCommonInfo();
  const config = getCommonConfig() as any;

  const editDateTime = (dateTime: string) => {
    let editedDateTime = "";
    if (dateTime && dateTime !== "") {
      let dateTimeLong = new Date(dateTime).getTime();
      editedDateTime = date(dateTimeLong) + " " + time(dateTimeLong);
    }
    return editedDateTime;
  };

  const editDescription = (
    table_id: string,
    column_id: string,
    content: any
  ) => {
    let editedDescription = "";
    let getKey = table_id + "_" + column_id;
    if (getKey) {
      let statusName = content;
      let array = com(column_id);
      if (Array.isArray(array) && content && content !== "") {
        for (let index in array) {
          if (array[index].code.toString() === content) {
            statusName = array[index].name;
            break;
          }
        }
      }
      if (column_id === "start_date" || column_id === "end_date") {
        if (isNaN(statusName) && !isNaN(Date.parse(statusName))) {
          if (statusName && statusName !== "") {
            if (statusName.indexOf("9999") === -1) {
              let dateTimeLong = new Date(statusName).getTime();
              statusName = date(dateTimeLong);
            } else {
              statusName = "Unlimited";
            }
          }
        }
      }
      editedDescription =
        i18text(com, "messages_table_name.", getKey) + ": " + statusName;
    }
    return editedDescription;
  };

  const editDescriptionByUpdateType = (row: any) => {
    let editedDescription = "";
    if (row.update_type === "1") {
      editedDescription = com("messages.MS0043", {
        tableName: i18text(com, "messages_table_name.", row.edit_table_id),
      });
    } else if (row.update_type === "3") {
      editedDescription = com("messages.MS0044", {
        tableName: i18text(com, "messages_table_name.", row.edit_table_id),
      });
    } else {
      let content = "";
      let column_id = "";
      if (row.edit_column_id === "promo_code_type") {
        if (row.edit_content === "1") {
          content = t("single_use");
        } else if (row.edit_content === "2") {
          content = t("multiple_use");
        }
      } else if (row.edit_column_id === "single_type") {
        if (row.edit_content === "1") {
          content = t("more_than");
        } else if (row.edit_content === "2") {
          content = t("times");
        }
      } else if (
        row.edit_column_id === "purchase_amount" ||
        row.edit_column_id === "special_price"
      ) {
        // content = String(Number(row.edit_content)).concat("€");
        // 金額Format
        content = currency(row.edit_content, config.currency);
      } else if (
        row.edit_column_id === "allow_overlap" ||
        row.edit_column_id === "exclusive_flag" || 
        row.edit_column_id === "webshop_flag" || 
        row.edit_column_id === "others_flag"
      ) {
        // Allow Overlap and Exclusive
        if (row.edit_content === "0") {
          content = t("no");
        } else if (row.edit_content === "1") {
          content = t("yes");
        }
      } else if (row.edit_column_id === "promo_type") {
        com("promotion_type").forEach((item: any) => {
          if (item.code === row.edit_content) {
            content = item.name;
          }
        });
      } else if (row.edit_column_id === "discount_rate") {
        content = priceOutputBySign(row.edit_content).concat("%");
      } else {
        content = row.edit_content;
      }

      if (row.edit_column_id === "unit_packet") {
        if (
          props.data.promo_type === "10" ||
          props.data.promo_type === "20" ||
          props.data.promo_type === "30" ||
          props.data.promo_type === "50"
        ) {
          column_id = "units_per_packet";
        } else {
          column_id = row.edit_column_id;
        }
      } else {
        column_id = row.edit_column_id;
      }

      editedDescription = editDescription(
        row.edit_table_id,
        column_id,
        content
      );
    }
    return editedDescription;
  };

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  // DateTime UpdatedBy Descriptionが同じレコードが非表示になります。
  const checkRepeatData = (data: any) => {
    let _data: any = [];
    if (data.length > 0) {
      data.forEach((row: any) => {
        if (_data.length > 0) {
          let repeat_check_list = _data.filter(
            (item: any) =>
              row.created_date === item.created_date &&
              row.updated_by === item.updated_by &&
              row.edit_content === item.edit_content &&
              row.edit_column_id === item.edit_column_id &&
              row.edit_column_id === "special_price"
          );
          if (repeat_check_list.length === 0) {
            _data.push(row);
          }
        } else {
          _data.push(row);
        }
      });
    }
    return _data;
  };

  return (
    <TableBody className={classes.tbody}>
      {props.data.updated_history &&
        setSortData(
          checkRepeatData(props.data.updated_history),
          "desc",
          "created_date"
        ).map((row: any, index: number) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell className={classes.dateCell}>
                {editDateTime(row.created_date)}
              </TableCell>
              <TableCell
                data-testid={"history_updatedBy" + index}
                className={classes.dateCell}
              >
                {row.created_by ? row.created_by : ""}
              </TableCell>
              <TableCell
                data-testid={"history_description" + index}
                className={classes.descriptionCell}
              >
                {editDescriptionByUpdateType(row)}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
