import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM1201
export const CreateUserRoleAPI = (
  roleDataList: any,
  roleDataCheckedList: any,
  sub: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const list: any = [];
  roleDataList.forEach((item: any) => {
    if (roleDataCheckedList["G" + item.role_group + "ID" + item.role_id]) {
      const itemMessage = {
        sub: sub,
        role_group: item.role_group,
        role_id: item.role_id,
      };
      list.push(itemMessage);
    }
  });
  const param = {
    token: token,
    url: `${baseURL}/v1/user_roles/${affiliate_cd}`,
    method: methods.post,
    data: list,
    config: config,
  };
  return http(param);
};

// AM1202
export const GetReadUserRoleListAPI = (sub: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/user_roles/${affiliate_cd}/${sub}`,
    config: config,
  };
  return http(param);
};

// AM1203
export const UpdateUserRoleAPI = (
  editList: any,
  roleDataList: any,
  roleDataCheckedList: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const list: any = [];
  roleDataList.forEach((item: any) => {
    if (roleDataCheckedList["G" + item.role_group + "ID" + item.role_id]) {
      const itemMessage = {
        role_group: item.role_group,
        role_id: item.role_id,
      };
      list.push(itemMessage);
    }
  });
  const param = {
    token: token,
    url: `${baseURL}/v1/user_roles/${affiliate_cd}/${editList.sub}`,
    method: methods.patch,
    data: list,
    config: config,
  };
  return http(param);
};
