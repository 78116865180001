export const getMemoEditValue = () => {
  return {
    note: "",
  };
};

export const getDataListDefault = () => {
  return {
    updated_date: undefined,
    model_no: undefined,
  };
};

export const getPartsDrawingData = () => {
  return {
    model_no: undefined,
    memo: undefined,
  };
};
