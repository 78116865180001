import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/stepper.useStyles";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// component
import { StepperArea } from "../../../modules/components/stepperArea/StepperArea";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const SummaryStepper = (props: any) => {
  const classes = useStyles();
  const {
    claimType,
    claimStatus,
    formList,
    handleToDeclilne,
    handleToApprove,
    handleToSave,
    handleEdit,
    handleReturn,
    handleToPrint,
    userPermisssionInfo,
    dataList,
  } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  const getStepper = (status: any, type: any) => {
    if (type === 0) {
      return 1;
    } else {
      console.log("getSepper", type, status);
      return status === "100" ||
        status === "300" ||
        status === "400" ||
        status === "500" ||
        status === "600"
        ? 1
        : 0;
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.area}>
        <div className={classes.title}>
          {t("summary_title_claim_no")}
          {formList.claim_no}
        </div>
      </div>
      <div className={classes.area}>
        <div className={classes.stepperArea}>
          <StepperArea
            claimType={claimType}
            claimStatus={claimStatus}
            activeStep={getStepper(claimStatus, claimType)}
          />
        </div>
      </div>
      <div className={classes.area}>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonArea}>
            {userPermisssionInfo.wco_claim_update_authFlg &&
              (Number(claimStatus) === 200 || Number(claimStatus) === 210) && (
                <div className={classes.button}>
                  <Button
                    variant="contained"
                    onClick={handleEdit}
                    startIcon={<EditIcon />}
                  >
                    {t("btn_edit")}
                  </Button>
                </div>
              )}

            <div style={{ marginLeft: "1px" }}>
              <Button
                variant="contained"
                onClick={handleReturn}
                startIcon={<ArrowBackIcon />}
              >
                {t("btn_return")}
              </Button>
            </div>

            {(Number(claimStatus) === 200 || Number(claimStatus) === 210) &&
              userPermisssionInfo.wco_claim_update_authFlg && (
                <div className={classes.buttonSubmit}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToSave}
                    startIcon={<SaveIcon />}
                  >
                    {t("btn_save")}
                  </Button>
                </div>
              )}

            {userPermisssionInfo.wco_claim_update_authFlg &&
              (Number(claimStatus) === 200 || Number(claimStatus) === 210) && (
                <div className={classes.buttonSubmit}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleToDeclilne}
                    startIcon={<CancelIcon />}
                  >
                    {t("btn_decline")}
                  </Button>
                </div>
              )}

            {userPermisssionInfo.wco_claim_approve_authFlg &&
              (Number(claimStatus) === 200 || Number(claimStatus) === 210) && (
                <div className={classes.buttonSubmit}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToApprove}
                    disabled={!dataList.pre_approve_flg}
                    startIcon={<CheckCircleOutlineIcon />}
                  >
                    {t("btn_approve")}
                  </Button>
                </div>
              )}

            {Number(claimStatus) >= 200 && Number(claimStatus) < 600 && (
              <div className={classes.buttonSubmit}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToPrint}
                  startIcon={<PrintIcon />}
                >
                  {t("btn_print")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
