import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM0801
export const CreateRepairDestinationMasterAPI = (data: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/repair_destinations/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0804
export const DeleteRepairDestinationMasterAPI = (
  region_cd: any,
  data: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/repair_destinations/${affiliate_cd}/${region_cd}`,
    config: config,
    method: methods.delete,
    data: data,
  };
  return http(param);
};

// AM0805
export const GetRepairDestinationDetailListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/repair_destinations/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
