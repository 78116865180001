import React, { useState } from "react";
// components
import { MasterMaintenanceSearchBox } from "./MasterMaintenance.SearchBox";
import { MasterMaintenanceDZUserRegistrationThead } from "./MasterMaintenance.DZUserRegistration.Thead";
import { MasterMaintenanceDZUserRegistrationTbody } from "./MasterMaintenance.DZUserRegistration.Tbody";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { MasterMaintenanceErrorMessageBox } from "./MasterMaintenance.ErrorMessageBox";
import { TabKey } from "../MasterMaintenance.constants";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
// hook
import { useStyles } from "../hooks/table.useStyles";
import RefreshIcon from "@material-ui/icons/Refresh";

export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
    },
    buttonArea: {
      marginRight: "10px",
    },
    input: {
      display: "none",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
  })
);

export const MasterMaintenanceDZUserRegistration = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.dZUserRegistration",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const [searchKey, setSearchKey] = useState("");
  // pager
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // delete
  const [deleteRow, setDeleteRow] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // search
  const handleChangeKey = (value: any) => {
    setSearchKey(value);
    props.SearchResult(value, TabKey.DZUserRegistration);
  };

  const handleSearch = () => {
    props.SearchResult(searchKey, TabKey.DZUserRegistration);
  };

  // delete button click event
  const handleDelete = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  // message box
  const [open, setOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    props.handleDelete(deleteRow, TabKey.DZUserRegistration);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };
  const handleSuccessAndErrorMessageClose = () => {
    setSuccessAndErrorMessageOpen(false);
  };

  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  // success and error message box
  const [successAndErrorMessageOpen, setSuccessAndErrorMessageOpen] =
    useState(false);

  const [errMessageList, setErrMessageList] = useState([]);
  const [showSuccessAndErrorMessage, setShowSuccessAndErrorMessage] = useState(
    []
  );
  const [successAndErrorMessageList, setSuccessAndErrorMessageList] = useState(
    []
  );

  const DZUserInfoModelWindow = (
    <div className={myClasses.divWidth}>
      {successAndErrorMessageList.length > 0 && (
        <h2 className={myClasses.title}>{t("error")}</h2>
      )}
      <p className={myClasses.message}>{successAndErrorMessageList}</p>
    </div>
  );

  const DZUserInfoModelWindowButton = (
    <div className={myClasses.buttonControl}>
      <Button
        className={myClasses.buttonWidth}
        variant="contained"
        onClick={handleSuccessAndErrorMessageClose}
        color="primary"
      >
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <div>
      <div className={myClasses.header}>
        <div>
          <MasterMaintenanceSearchBox
            searchKey={searchKey}
            handleChangeKey={handleChangeKey}
            handleSearch={handleSearch}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={myClasses.buttonArea}
            startIcon={<RefreshIcon />}
            onClick={props.getDZUserRegistrationListAPI}
          >
            {t("refresh")}
          </Button>
        </div>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <MasterMaintenanceDZUserRegistrationThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <MasterMaintenanceDZUserRegistrationTbody
            data={props.dZUserRegistrationResultList}
            pageInfo={pageInfo}
            order={order}
            orderBy={orderBy}
            handleEdit={props.handleEdit}
            handleDelete={handleDelete}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        component="div"
        count={props.dZUserRegistrationResultList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
      {errMessageList.length > 0 && (
        <MasterMaintenanceErrorMessageBox
          open={errorMessageOpen}
          handleClose={handleErrorMessageClose}
          message={errMessageList}
        />
      )}
      {
        <ModelWindow
          title={showSuccessAndErrorMessage}
          notTitle={true}
          contents={DZUserInfoModelWindow}
          button={DZUserInfoModelWindowButton}
          openFlg={successAndErrorMessageOpen}
          handleCloseEvent={handleSuccessAndErrorMessageClose}
        />
      }
    </div>
  );
};
