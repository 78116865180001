import React, { useState, useEffect } from "react";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
  })
);

export const ClaimDetailPopup = (props: any) => {
  const classes = useStyles();
  const {
    returnWindowFlag,
    handleCloseReturnChanges,
    handleCloseReturn,
    declineFlag,
    declineSuccessFlag,
    handleCloseDecline,
    handleCloseDeclineChanges,
    handleCloseDeclineSuccessChanges,
    approveFlag,
    approveSuccessFlag,
    handleCloseApprove,
    handleCloseApproveChanges,
    handleCloseApproveSuccessChanges,
  } = props;
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { com } = useLocale({ defaultPath: "models.model_window" });
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  // return message
  const attentionReturnChanges = (
    <p>
      {getName("messages.MS0097")}
      <br />
      {getName("messages.MS0065")}
    </p>
  );
  const attentionReturnChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseReturnChanges}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseReturn}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );
  // decline message
  const attentionDeclineChanges = <p>{getName("messages.MS0106")}</p>;
  const attentionDeclineChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseDecline}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseDeclineChanges}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // decline complete message
  const attentionDeclineSuccessChanges = <p>{getName("messages.MS0107")}</p>;
  const attentionDeclineSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseDeclineSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // approve message
  const attentionApproveChanges = <p>{getName("messages.MS0104")}</p>;
  const attentionApproveChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseApprove}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseApproveChanges}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // approve complete message
  const attentionApproveSuccessChanges = <p>{getName("messages.MS0105")}</p>;
  const attentionApproveSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseApproveSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <>
      {returnWindowFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionReturnChanges}
          openFlg={returnWindowFlag}
          button={attentionReturnChangesButton}
          handleCloseEvent={handleCloseReturnChanges}
        />
      )}
      {declineFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeclineChanges}
          openFlg={declineFlag}
          button={attentionDeclineChangesButton}
          handleCloseEvent={handleCloseDecline}
        />
      )}
      {declineSuccessFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeclineSuccessChanges}
          openFlg={declineSuccessFlag}
          button={attentionDeclineSuccessChangesButton}
          handleCloseEvent={handleCloseDeclineChanges}
        />
      )}
      {approveFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionApproveChanges}
          openFlg={approveFlag}
          button={attentionApproveChangesButton}
          handleCloseEvent={handleCloseApprove}
        />
      )}
      {approveSuccessFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionApproveSuccessChanges}
          openFlg={approveSuccessFlag}
          button={attentionApproveSuccessChangesButton}
          handleCloseEvent={handleCloseApproveSuccessChanges}
        />
      )}
    </>
  );
};
