import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Link from "@material-ui/core/Link";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const JobsDetailGeneralRepairMediaTbody = (props: any) => {
  const classes = useStyles();
  const { data, handleOnClickLink, handleDownload, handleDeleteMedia, userPermisssionInfo } = props;
  const { com } = useLocale({ defaultPath: "common.constants" });
  const mediaTypeList = com("media_type");

  // fetch the status name from constants
  const getMediaType = (code: number) => {
    let mediaType = "";
    if (code) {
      for (let index in mediaTypeList) {
        if (mediaTypeList[index].code === code) {
          mediaType = mediaTypeList[index].name;
          break;
        }
      }
    }
    return mediaType;
  };

  const editDateTime = (dateTime: string) => {
    let editedDateTime = "";
    if (dateTime && dateTime !== "") {
      let dateTimeLong = new Date(dateTime).getTime();
      editedDateTime = date(dateTimeLong);
    }
    return editedDateTime;
  };

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (b[orderBy] < a[orderBy]) {
          sort = -1;
        } else if (b[orderBy] > a[orderBy]) {
          sort = 1;
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody className={classes.tbody}>
      {data.repair_media &&
        setSortData(data.repair_media, "asc", "sub_no").map(
          (row: any, index: number) => {
            return (
              <TableRow hover tabIndex={-1} key={index}>
                <TableCell>{getMediaType(row.media_type)}</TableCell>
                <TableCell>
                  <Link
                    href="#"
                    onClick={() => handleOnClickLink(row)}
                    underline="always"
                  >
                    {row.media_name}
                  </Link>
                </TableCell>
                <TableCell className={classes.dateCell}>
                  {editDateTime(row.updated_date)}
                </TableCell>
                <TableCell className={classes.iconCell}>
                  {userPermisssionInfo.download_authFlg && (
                  <IconButton
                    color="primary"
                    onClick={() => handleDownload(row)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  )}
                </TableCell>
                <TableCell className={classes.iconCell}>
                {userPermisssionInfo.delete_media_authFlg && (row.media_type === 2 || row.media_type === 4) && (
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteMedia(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                </TableCell>
              </TableRow>
            );
          }
        )}
    </TableBody>
  );
};
