import React from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

export const TechnicianListResultTableTfoot = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "modules.components.jobs" });
  return (
    <TableFooter className={classes.tfoot}>
      <TableRow>
        <TableCell colSpan={2}>
          {com("table_technician_footer_text", {
            number: props.data.tech_list.length,
          })}
        </TableCell>
        <TableCell className={classes.nowrapTechnician} align="right">{props.data.allocated_qty_total}</TableCell>
        <TableCell className={classes.nowrapTechnician} align="right">{props.data.repair_start_qty_total}</TableCell>
        <TableCell className={classes.nowrapTechnician} align="right">{props.data.quotation_open_qty_total}</TableCell>
        <TableCell className={classes.nowrapTechnician} align="right">{props.data.quotation_answered_total}</TableCell>
        <TableCell className={classes.nowrapTechnician} align="right">{props.data.repair_end_qty_total}</TableCell>
      </TableRow>
    </TableFooter>
  );
};
