import { GlobalConfigurationModel } from "../models/GlobalConfigurationModel";
import {
  DashboardMessageListModel,
  DashboardMessageModel,
} from "../models/DashboardMessageListModel";
import { GetGlobalConfiguration } from "./GlobalConfiguration";
import {
  DeleteDashboardMessageAPI,
  GetDashboardMessageAPI,
  GetDashboardMessageListAPI,
  PostDashboardMessageAPI,
  UpdateDashboardMessageAPI,
} from "./Dashboard";
import { GetRepairConfiguration } from "./RepairConfiguration";
import { RepairConfigurationModel } from "../models/RepairConfigurationModel";
import { GlobalUserInformationModel } from "../models/GlobalUserInformationModel";
import { GetGlobalUserInformation } from "./GlobalUserInformation";
import { UserPermissions } from "../models/UserPermissionModel";
import { GetUserPermissions } from "./UserPermission";
import {
  GetReadItemMasterAPI,
  UpdateItemMasterAPI,
  GetItemDetailListAPI,
  GetItemDetailForJobsAPI,
  GetBaseItemListAPI,
  GetItemDetailForWcoAPI,
} from "./Item";
import {
  CreateUserInfoAPI,
  GetReadUserInfoAPI,
  GetReadUserInfoAPIforDZ,
  UpdateUserInfoAPIforDZ,
  UpdateUserInfoAPI,
  DeleteUserInfoAPI,
  GetUserinfoDetailListAPI,
  GetUserinfoDetailListAPIByDz,
  GetReadUserInfoAPIForUserinfoRepair,
  UpdateUserInfoAPIForUserinfoRepair,
  ResetUserInfoMFAAPI,
  GetUserInfoCSVAPIForDownload,
} from "./UserInfo";
import {
  GetCustomerDetailListAPI,
  UpdateCustomerMasterAPI,
  GetReadCustomerMasterAPI,
  GetCustomerDetailListAPIforSearch,
  GetMasterCustomerDetailListAPI,
} from "./Customer";
import { GetAffiliateCodeListAPI } from "./AffiliateCode";
import { DeleteRoleMasterAPI, GetRoleListAPI } from "./Role";
import {
  CreateServiceCenterMasterAPI,
  UpdateServiceCenterMasterAPI,
  DeleteServiceCenterMasterAPI,
  GetServiceCenterDetailListAPI,
} from "./ServiceCenter";
import { GetPostalCodeListAPI } from "./PostalCode";
import { GetDocumentFormListAPI } from "./DocumentForm";
import { GetPrinterDetailListAPI } from "./Printer";
import {
  CreateRoleandRolePermissionAPI,
  GetReadRolePermissionListAPI,
  UpdatePermissionAPI,
} from "./RolePermission";
import { GetRoleGroupListAPI } from "./RoleGroup";
import { GetPermissionListAPI } from "./Permission";
import { GetERPUserDetailAPI, GetERPUserDetailListAPI } from "./ERPUser";
import {
  CreateUserRoleAPI,
  GetReadUserRoleListAPI,
  UpdateUserRoleAPI,
} from "./UserRole";
import {
  CreateRepairDestinationMasterAPI,
  DeleteRepairDestinationMasterAPI,
  GetRepairDestinationDetailListAPI,
} from "./RepairDestination";
import {
  CreatePrintSettingAPI,
  GetPrintSettingListAPI,
  DeletePrintSettingAPI,
} from "./PrintSetting";
import {
  CreateRepairJobAPI,
  GetReadRepairJobAPI,
  UpdateRepairJobAPI,
  GetReadRepairJobListAPI,
  GetReadRepairJobTechList,
  CreateRepairMediaAPI,
  CreateRepairNoteAPI,
  DeleteRepairNoteAPI,
  DeleteReceivedJob,
  UpdateMultipleRepairJobAPI,
  DeleteRepairMediaAPI,
  GetRepairJobLocationControlAPI,
  CreateRepairContactAPI,
  CreateRepairAddressAPI,
  DeleteRepairJobAPI,
  QuotaResendAPI,
  DownloadCSVRepairListAPI,
  GetDownloadJobRepairByS3singedUrlApi,
} from "./RepairJobHeader";
import {
  GetUploadSignedUrl,
  GetDownloadSignedUrl,
  GetUploadFile,
  GetDownloadFile,
  GetAppFile,
} from "./ULDLDocument";
import {
  GetRepairRegistrationPrintAPI,
  GetWagonLoadingListPrintAPI,
  GetReceiptLabelPrintAPI,
  GetConsoleRepairRegistrationPrintAPI,
} from "./DocumentGenerator";
import {
  GetReadRepairJobPartsListAPI,
  MultipleUpdateRepairJobPartsAPI,
} from "./RepairJobParts";
import {
  GetPartsModel,
  GetFileUrl,
  GetRegionsForModel,
  GetWebMSIConfiguration,
} from "./WebMSI";
import {
  GetRepairNotificationMessageListAPI,
  UpdateRepairNotificationMessageAPI,
} from "./RepairNotificationMessage";
import { GetPermissionGroupListAPI } from "./PermissionGroup";
import {
  GetReadOrderAPIforDZ,
  UpdateOrderAPIforDZ,
  GetReadOrderListAPI,
  GetReadOrderAPI,
  UpdateOrderAPI,
  UpdateOrderPersonInChargeAPI,
  GetReadOrderPersonInChargeListAPI,
  GetOrderCSVListAPI,
} from "./Order";
import {
  GetReadPromotionListAPI,
  GetReadPromotionAPI,
  CreatePromotionAPI,
  UpdatePromotionAPI,
  DeletePromotionAPI,
  GetReadPromotionDraftListAPI,
  GetDownloadPromotionAPI,
  GetReadPaymentTermListAPI,
  GetReadSalesAnalysisCodeListAPI,
  UploadPromotionCSVAPI,
  GetPaymentTermInformationApi,
  GetDownloadPromotionByS3singedUrlApi,
} from "./Promotion";
import {
  UpdateDZUserRegistrationAPI,
  GetDZUserRegistrationListAPI,
} from "./dzuserRegistration";
import {
  GetReadPriceAPIforDZ,
  GetReadPriceAPIforSimulation,
  GetReadPriceAPIforClaim,
} from "./Price";
import { GetAlternateItemAPI } from "./AlternateItem";
import { WebMSIConfigurationModel } from "../models/WebMSIConfigurationModel";
import { GetSalesAnalysisDefinitionAPI } from "./SalesAnalysis";
import {
  GetReadClaimListAPI,
  GetCreditNotesForSearch,
  GetReadClaimListForCSV,
  GetReadClaimDetailAPI,
  CreateClaimAPI,
  UpdateClaimAPI,
  DeleteClaimAPI,
  GetReadRepairHistoryListAPI,
} from "./ClaimHeader";
import {
  CreateClaimLaborAPI,
  CreateClaimLaborAPIForCSV,
  GetReadClaimLaborAPI,
  UpdateClaimLaborAPI,
  UpdateClaimLaborAPIForCSV,
  GetReadClaimLaborListAPI,
} from "./ClaimLabor";
import {
  GetUploadSignedUrlByWco,
  GetDownloadSignedUrlByWco,
  GetDownloadCreditNoteSignedUrlByWco,
} from "./ULDLDocumentWCO";
import {
  GetReportMonthlyBarGraphAPI,
  GetReportDailyBarGraphAPI,
  GetReportListAPI,
  GetReportTechnicianAPI,
  GetReportModelAPI,
} from "./Report";
import { GetClaimPrintAPI, GetClaimPrintAPIDumi } from "./DocumentGeneratorWco";
import {
  GetEndusersOverview,
  GetEndusersDetail,
  GetTools,
  GetFileDZ,
} from "./Dz";

type API = {
  // For Endpoint
  getGlobalConfiguration: (token: string) => Promise<GlobalConfigurationModel>;
  // For User Configuration - it is more general
  getGlobalUserInformation: (
    token: string,
    url: string
  ) => Promise<GlobalUserInformationModel>;
  // For Repair Configuration
  getRepairConfiguration: (
    token: string,
    url: string
  ) => Promise<RepairConfigurationModel>;
  // For User Permission
  getUserPermissions: (token: string, url: string) => Promise<UserPermissions>;
  // For Dashboard Messages
  postDashboardMessage: (body: any, config: any) => any;
  getDashboardMessage: (
    message_type: number,
    permission_group: number,
    config: any
  ) => Promise<DashboardMessageModel>;
  updateDashboardMessage: (
    dashboardMessage: DashboardMessageModel,
    config: any
  ) => any;
  deleteDashboardMessage: (
    dashboardMessage: DashboardMessageModel,
    config: any
  ) => any;
  getDashboardMessageList: (config: any) => any;
  getItemDetailListAPI: (config: any) => any;
  updateUserInfoAPI: (data: any, sub: any, config: any) => any;
  getUserinfoDetailListAPI: (config: any) => any;
  getCustomerDetailListAPI: (config: any) => any;
  getMasterCustomerDetailListAPI: (config: any) => any;
  updateItemMasterAPI: (data: any, config: any) => any;
  getAffiliateCodeListAPI: (config: any) => any;
  getRoleListAPI: (config: any) => any;
  createUserInfoAPI: (data: any, config: any) => any;
  getReadUserInfoAPI: (sub: any, config: any) => any;
  getReadUserInfoAPIforDZ: (sub: any, config: any) => any;
  getServiceCenterDetailListAPI: (config: any) => any;
  deleteUserInfoAPI: (data: any, sub: any, config: any) => any;
  updateUserInfoAPIforDZ: (data: any, sub: any, config: any) => any;
  getPostalCodeListAPI: (config: any) => any;
  getDocumentFormListAPI: (config: any) => any;
  getPrinterDetailListAPI: (config: any) => any;
  getReadCustomerMasterAPI: (customer_no: any, config: any) => any;
  getCustomerDetailListAPIforSearch: (
    query_parameters: any,
    config: any
  ) => any;
  updateCustomerMasterAPI: (customer_no: any, data: any, config: any) => any;
  updatePermissionAPI: (
    data: any,
    role_group: any,
    role_id: any,
    config: any
  ) => any;
  createRoleandRolePermissionAPI: (data: any, config: any) => any;
  getRoleGroupListAPI: (config: any) => any;
  getPermissionListAPI: (config: any) => any;
  getReadRolePermissionListAPI: (
    role_group: any,
    role_id: any,
    config: any
  ) => any;
  deleteRoleMasterAPI: (
    role_group: any,
    role_id: any,
    data: any,
    config: any
  ) => any;
  createServiceCenterMasterAPI: (data: any, config: any) => any;
  updateServiceCenterMasterAPI: (
    service_center_id: any,
    data: any,
    config: any
  ) => any;
  getUserinfoDetailListAPIByDz: (config: any, query_parameters: string) => any;
  getERPUserDetailAPI: (erp_user_id: any, config: any) => any;
  getERPUserDetailListAPI: (config: any) => any;
  getReadUserRoleListAPI: (sub: any, config: any) => any;
  createUserRoleAPI: (
    roleDataList: any,
    roleDataCheckedList: any,
    sub: any,
    config: any
  ) => any;
  updateUserRoleAPI: (
    editList: any,
    roleDataList: any,
    roleDataCheckedList: any,
    config: any
  ) => any;
  deleteServiceCenterMasterAPI: (
    service_center_id: any,
    data: any,
    config: any
  ) => any;
  createRepairDestinationMasterAPI: (data: any, config: any) => any;
  deleteRepairDestinationMasterAPI: (
    region_cd: any,
    data: any,
    config: any
  ) => any;
  getRepairDestinationDetailListAPI: (config: any) => any;
  createPrintSettingAPI: (sub: any, data: any, config: any) => any;
  getPrintSettingListAPI: (sub: any, config: any) => any;
  deletePrintSettingAPI: (
    printer_id: any,
    service_center_id: any,
    form_id: any,
    sub: any,
    data: any,
    config: any
  ) => any;
  //JobHeader
  getReadUserInfoAPIForUserinfoRepair: (config: any) => any;
  updateUserInfoAPIForUserinfoRepair: (data: any, config: any) => any;
  resetUserInfoMFAAPI: (sub: any, config: any) => any;
  getUserInfoCSVAPIForDownload: (config: any) => any;
  createRepairJobAPI: (data: any, config: any) => any;
  getReadRepairJobAPI: (job_no: any, config: any) => any;
  getReadRepairJobTechList: (config: any) => any;
  getUploadSignedUrl: (media_name: any, config: any) => any;
  getUploadFile: (url: any, data: any) => any;
  getReceiptLabelPrintAPI: (job_no: any, copies: any, config: any) => any;
  updateRepairJobAPI: (job_no: any, data: any, config: any) => any;
  createRepairNoteAPI: (data: any, config: any) => any;
  createRepairMediaAPI: (data: any, config: any) => any;
  getReadRepairJobListAPI: (query_parameters: any, config: any) => any;
  getDownloadSignedUrl: (job_no: any, sub_no: any, config: any) => any;
  getDownloadFile: (url: any) => any;
  deleteRepairNoteAPI: (
    job_no: any,
    note_type: any,
    sub_no: any,
    data: any,
    config: any
  ) => any;
  getReadItemMasterAPI: (item_no: any, config: any) => any;
  getItemDetailForJobsAPI: (model_no: any, config: any) => any;
  getBaseItemListAPI: (config: any) => any;
  deleteReceivedJob: (job_no: any, data: any, config: any) => any;
  getRepairRegistrationPrintAPI: (job_no: any, config: any) => any;
  getConsoleRepairRegistrationPrintAPI: (job_no: any, config: any) => any;
  getWagonLoadingListPrintAPI: (
    location_no: any,
    job_type: any,
    config: any
  ) => any;
  updateMultipleRepairJobAPI: (data: any, config: any) => any;
  deleteRepairMediaAPI: (
    job_no: any,
    sub_no: any,
    data: any,
    config: any
  ) => any;
  getReadRepairJobPartsListAPI: (job_no: any, config: any) => any;
  multipleUpdateRepairJobPartsAPI: (job_no: any, data: any, config: any) => any;
  getRepairJobLocationControlAPI: (config: any) => any;
  createRepairContactAPI: (data: any, config: any) => any;
  createRepairAddressAPI: (data: any, config: any) => any;
  deleteRepairJobAPI: (job_no: any, data: any, config: any) => any;
  quotaResendAPI: (job_no: string, data: any, config: any) => any;
  getRegionsForModel: (model: any, config: any) => any;
  getPartsModel: (model: any, locale: any, region: any, config: any) => any;
  getRepairNotificationMessageListAPI: (config: any) => any;
  updateRepairNotificationMessageAPI: (
    notification_id: any,
    config: any
  ) => any;
  getPermissionGroupListAPI: (config: any) => any;
  getFileUrl: (path: any, config: any) => any;
  getAppFile: (config: any) => any;
  getReadOrderAPIforDZ: (config: any, order_no: any) => any;
  updateOrderAPIforDZ: (config: any, order_no: any) => any;
  getReadOrderListAPI: (config: any, query_parameters: any) => any;
  getReadOrderAPI: (config: any, order_no: any) => any;
  updateOrderAPI: (config: any, order_no: any, data: any) => any;
  updateOrderPersonInChargeAPI: (config: any, order_no: any, data: any) => any;
  getReadOrderPersonInChargeListAPI: (config: any) => any;
  getOrderCSVListAPI: (config: any, data: any) => any;
  getReadPromotionListAPI: (config: any, query_parameters: any) => any;
  getReadPromotionAPI: (config: any, promotion_no: any) => any;
  createPromotionAPI: (config: any, data: any) => any;
  updatePromotionAPI: (config: any, promotion_no: any, data: any) => any;
  deletePromotionAPI: (config: any, promotion_no: any, data: any) => any;
  getReadPromotionDraftListAPI: (config: any, query_parameters: any) => any;
  getDownloadPromotionAPI: (config: any, data: any) => any;
  getReadPaymentTermListAPI: (config: any) => any;
  updateDZUserRegistrationAPI: (data: any, email: any, config: any) => any;
  getDZUserRegistrationListAPI: (config: any) => any;
  getReadPriceAPIforDZ: (query_parameters: any, config: any) => any;
  getAlternateItemAPI: (query_parameters: any, config: any) => any;
  getReadPriceAPIforSimulation: (query_parameters: any, config: any) => any;
  getReadPriceAPIforClaim: (query_parameters: any, config: any) => any;
  getReadSalesAnalysisCodeListAPI: (config: any) => any;
  getWebMSIConfiguration: (
    token: string,
    url: string
  ) => Promise<WebMSIConfigurationModel>;
  getReportListAPI: (query_parameters: any, config: any) => any;
  getReportDailyBarGraphAPI: (query_parameters: any, config: any) => any;
  getReportMonthlyBarGraphAPI: (query_parameters: any, config: any) => any;
  getReportTechnicianAPI: (query_parameters: any, config: any) => any;
  getReportModelAPI: (query_parameters: any, config: any) => any;
  uploadPromotionCSVAPI: (config: any, data: any) => any;
  getSalesAnalysisDefinitionAPI: (config: any) => any;
  getReadClaimListAPI: (query_parameters: any, config: any) => any;
  getReadClaimDetailAPI: (claim_no: any, config: any) => any;
  createClaimLaborAPI: (data: any, config: any) => any;
  createClaimLaborAPIForCSV: (data: any, config: any) => any;
  getReadClaimLaborAPI: (model_no: any, config: any) => any;
  updateClaimLaborAPI: (model_no: any, data: any, config: any) => any;
  updateClaimLaborAPIForCSV: (model_no: any, data: any, config: any) => any;
  getReadClaimLaborListAPI: (config: any) => any;
  getCreditNotesForSearch: (query_parameters: any, config: any) => any;
  getReadClaimListForCSV: (query_parameters: any, config: any) => any;

  getUploadSignedUrlByWco: (media_name: any, config: any) => any;
  getDownloadSignedUrlByWco: (
    claims_no: any,
    sub_no: any,
    config: any,
    type?: any
  ) => any;
  createClaimAPI: (config: any, data: any) => any;
  getDownloadCreditNoteSignedUrlByWco: (ref_no: any, config: any) => any;
  updateClaimAPI: (claim_no: any, config: any, data: any) => any;
  deleteClaimAPI: (config: any, claim_no: any, data: any) => any;
  getClaimPrintAPI: (claim_no: any, config: any) => any;
  getReadRepairHistoryListAPI: (query_parameters: any, config: any) => any;
  getEndusersOverview: (config: any) => any;
  getEndusersDetail: (config: any, enduser_no: any) => any;
  getTools: (config: any, enduser_no: any) => any;
  getItemDetailForWcoAPI: (model_no: any, config: any) => any;
  getClaimPrintAPIDumi: (config: any) => any;
  getFileDZ: (config: any, url: any) => any;
  getPaymentTermInformationApi: (config: any, data: any) => any;
  downloadCSVRepairListAPI: (config: any, data: any) => any;
  getDownloadPromotionByS3singedUrlApi: (config: any, data: any) => any;
  getDownloadJobRepairByS3singedUrlApi: (config: any, data: any) => any;
};

var api: API = {
  getGlobalConfiguration: GetGlobalConfiguration,
  getGlobalUserInformation: GetGlobalUserInformation,
  getRepairConfiguration: GetRepairConfiguration,
  getUserPermissions: GetUserPermissions,
  // For Dashboard Messages
  postDashboardMessage: PostDashboardMessageAPI,
  getDashboardMessage: GetDashboardMessageAPI,
  updateDashboardMessage: UpdateDashboardMessageAPI,
  deleteDashboardMessage: DeleteDashboardMessageAPI,
  getDashboardMessageList: GetDashboardMessageListAPI,
  getItemDetailListAPI: GetItemDetailListAPI,
  getUserinfoDetailListAPI: GetUserinfoDetailListAPI,
  getCustomerDetailListAPI: GetCustomerDetailListAPI,
  getMasterCustomerDetailListAPI: GetMasterCustomerDetailListAPI,
  updateUserInfoAPI: UpdateUserInfoAPI,
  updateItemMasterAPI: UpdateItemMasterAPI,
  getAffiliateCodeListAPI: GetAffiliateCodeListAPI,
  getRoleListAPI: GetRoleListAPI,
  createUserInfoAPI: CreateUserInfoAPI,
  getReadUserInfoAPI: GetReadUserInfoAPI,
  getReadUserInfoAPIforDZ: GetReadUserInfoAPIforDZ,
  getServiceCenterDetailListAPI: GetServiceCenterDetailListAPI,
  getDocumentFormListAPI: GetDocumentFormListAPI,
  getPrinterDetailListAPI: GetPrinterDetailListAPI,
  getReadCustomerMasterAPI: GetReadCustomerMasterAPI,
  getCustomerDetailListAPIforSearch: GetCustomerDetailListAPIforSearch,
  updateUserInfoAPIforDZ: UpdateUserInfoAPIforDZ,
  deleteUserInfoAPI: DeleteUserInfoAPI,
  updateCustomerMasterAPI: UpdateCustomerMasterAPI,
  updatePermissionAPI: UpdatePermissionAPI,
  createRoleandRolePermissionAPI: CreateRoleandRolePermissionAPI,
  getRoleGroupListAPI: GetRoleGroupListAPI,
  getPermissionListAPI: GetPermissionListAPI,
  getReadRolePermissionListAPI: GetReadRolePermissionListAPI,
  deleteRoleMasterAPI: DeleteRoleMasterAPI,
  createServiceCenterMasterAPI: CreateServiceCenterMasterAPI,
  updateServiceCenterMasterAPI: UpdateServiceCenterMasterAPI,
  getUserinfoDetailListAPIByDz: GetUserinfoDetailListAPIByDz,
  getERPUserDetailAPI: GetERPUserDetailAPI,
  getERPUserDetailListAPI: GetERPUserDetailListAPI,
  getReadUserRoleListAPI: GetReadUserRoleListAPI,
  createUserRoleAPI: CreateUserRoleAPI,
  updateUserRoleAPI: UpdateUserRoleAPI,
  deleteServiceCenterMasterAPI: DeleteServiceCenterMasterAPI,
  createRepairDestinationMasterAPI: CreateRepairDestinationMasterAPI,
  deleteRepairDestinationMasterAPI: DeleteRepairDestinationMasterAPI,
  getRepairDestinationDetailListAPI: GetRepairDestinationDetailListAPI,
  createPrintSettingAPI: CreatePrintSettingAPI,
  getPrintSettingListAPI: GetPrintSettingListAPI,
  deletePrintSettingAPI: DeletePrintSettingAPI,
  getPostalCodeListAPI: GetPostalCodeListAPI,
  getReadUserInfoAPIForUserinfoRepair: GetReadUserInfoAPIForUserinfoRepair,
  updateUserInfoAPIForUserinfoRepair: UpdateUserInfoAPIForUserinfoRepair,
  resetUserInfoMFAAPI: ResetUserInfoMFAAPI,
  getUserInfoCSVAPIForDownload: GetUserInfoCSVAPIForDownload,
  createRepairJobAPI: CreateRepairJobAPI,
  getReadRepairJobAPI: GetReadRepairJobAPI,
  getReadRepairJobTechList: GetReadRepairJobTechList,
  getUploadSignedUrl: GetUploadSignedUrl,
  getUploadFile: GetUploadFile,
  getReceiptLabelPrintAPI: GetReceiptLabelPrintAPI,
  updateRepairJobAPI: UpdateRepairJobAPI,
  createRepairMediaAPI: CreateRepairMediaAPI,
  createRepairNoteAPI: CreateRepairNoteAPI,
  getReadRepairJobListAPI: GetReadRepairJobListAPI,
  getDownloadSignedUrl: GetDownloadSignedUrl,
  getDownloadFile: GetDownloadFile,
  deleteRepairNoteAPI: DeleteRepairNoteAPI,
  getReadItemMasterAPI: GetReadItemMasterAPI,
  getItemDetailForJobsAPI: GetItemDetailForJobsAPI,
  getBaseItemListAPI: GetBaseItemListAPI,
  deleteReceivedJob: DeleteReceivedJob,
  getRepairRegistrationPrintAPI: GetRepairRegistrationPrintAPI,
  getConsoleRepairRegistrationPrintAPI: GetConsoleRepairRegistrationPrintAPI,
  getWagonLoadingListPrintAPI: GetWagonLoadingListPrintAPI,
  updateMultipleRepairJobAPI: UpdateMultipleRepairJobAPI,
  deleteRepairMediaAPI: DeleteRepairMediaAPI,
  getReadRepairJobPartsListAPI: GetReadRepairJobPartsListAPI,
  multipleUpdateRepairJobPartsAPI: MultipleUpdateRepairJobPartsAPI,
  getRepairJobLocationControlAPI: GetRepairJobLocationControlAPI,
  createRepairContactAPI: CreateRepairContactAPI,
  createRepairAddressAPI: CreateRepairAddressAPI,
  deleteRepairJobAPI: DeleteRepairJobAPI,
  quotaResendAPI: QuotaResendAPI,
  getPartsModel: GetPartsModel,
  getRegionsForModel: GetRegionsForModel,
  getRepairNotificationMessageListAPI: GetRepairNotificationMessageListAPI,
  updateRepairNotificationMessageAPI: UpdateRepairNotificationMessageAPI,
  getPermissionGroupListAPI: GetPermissionGroupListAPI,
  getFileUrl: GetFileUrl,
  getAppFile: GetAppFile,
  getReadOrderAPIforDZ: GetReadOrderAPIforDZ,
  updateOrderAPIforDZ: UpdateOrderAPIforDZ,
  getReadOrderListAPI: GetReadOrderListAPI,
  updateOrderAPI: UpdateOrderAPI,
  updateOrderPersonInChargeAPI: UpdateOrderPersonInChargeAPI,
  getReadOrderPersonInChargeListAPI: GetReadOrderPersonInChargeListAPI,
  getOrderCSVListAPI: GetOrderCSVListAPI,
  getReadOrderAPI: GetReadOrderAPI,
  getReadPromotionListAPI: GetReadPromotionListAPI,
  getReadPromotionAPI: GetReadPromotionAPI,
  createPromotionAPI: CreatePromotionAPI,
  updatePromotionAPI: UpdatePromotionAPI,
  deletePromotionAPI: DeletePromotionAPI,
  getReadPromotionDraftListAPI: GetReadPromotionDraftListAPI,
  getDownloadPromotionAPI: GetDownloadPromotionAPI,
  getReadPaymentTermListAPI: GetReadPaymentTermListAPI,
  getDZUserRegistrationListAPI: GetDZUserRegistrationListAPI,
  updateDZUserRegistrationAPI: UpdateDZUserRegistrationAPI,
  getReadPriceAPIforDZ: GetReadPriceAPIforDZ,
  getAlternateItemAPI: GetAlternateItemAPI,
  getReadPriceAPIforSimulation: GetReadPriceAPIforSimulation,
  getReadPriceAPIforClaim: GetReadPriceAPIforClaim,
  getWebMSIConfiguration: GetWebMSIConfiguration,
  getReadSalesAnalysisCodeListAPI: GetReadSalesAnalysisCodeListAPI,
  getReportListAPI: GetReportListAPI,
  uploadPromotionCSVAPI: UploadPromotionCSVAPI,
  getSalesAnalysisDefinitionAPI: GetSalesAnalysisDefinitionAPI,
  getReadClaimListAPI: GetReadClaimListAPI,
  getReadClaimDetailAPI: GetReadClaimDetailAPI,
  createClaimLaborAPI: CreateClaimLaborAPI,
  createClaimLaborAPIForCSV: CreateClaimLaborAPIForCSV,
  getReadClaimLaborAPI: GetReadClaimLaborAPI,
  updateClaimLaborAPI: UpdateClaimLaborAPI,
  updateClaimLaborAPIForCSV: UpdateClaimLaborAPIForCSV,
  getReadClaimLaborListAPI: GetReadClaimLaborListAPI,
  getCreditNotesForSearch: GetCreditNotesForSearch,
  getReadClaimListForCSV: GetReadClaimListForCSV,

  getUploadSignedUrlByWco: GetUploadSignedUrlByWco,
  getDownloadSignedUrlByWco: GetDownloadSignedUrlByWco,
  createClaimAPI: CreateClaimAPI,
  getReportMonthlyBarGraphAPI: GetReportMonthlyBarGraphAPI,
  getReportTechnicianAPI: GetReportTechnicianAPI,
  getReportModelAPI: GetReportModelAPI,
  getReportDailyBarGraphAPI: GetReportDailyBarGraphAPI,
  getDownloadCreditNoteSignedUrlByWco: GetDownloadCreditNoteSignedUrlByWco,
  updateClaimAPI: UpdateClaimAPI,
  deleteClaimAPI: DeleteClaimAPI,
  getClaimPrintAPI: GetClaimPrintAPI,
  getReadRepairHistoryListAPI: GetReadRepairHistoryListAPI,
  getEndusersOverview: GetEndusersOverview,
  getEndusersDetail: GetEndusersDetail,
  getTools: GetTools,
  getItemDetailForWcoAPI: GetItemDetailForWcoAPI,
  getClaimPrintAPIDumi: GetClaimPrintAPIDumi,
  getFileDZ: GetFileDZ,
  getPaymentTermInformationApi: GetPaymentTermInformationApi,
  downloadCSVRepairListAPI: DownloadCSVRepairListAPI,
  getDownloadPromotionByS3singedUrlApi: GetDownloadPromotionByS3singedUrlApi,
  getDownloadJobRepairByS3singedUrlApi: GetDownloadJobRepairByS3singedUrlApi,
};

export default api;
