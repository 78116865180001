import React from "react";
// components
import { SortTableThead } from "../../../modules/components/sortTable/SortTableThead";
// constants
import { HeadCell } from "../MasterMaintenance.constants";

export const headCells: HeadCell[] = [
  { id: "Edit", label: "" },
  {
    id: "given_name",
    label: "given_name",
    searchFlg: true,
  },
  {
    id: "family_name",
    label: "family_name",
    searchFlg: true,
  },
  {
    id: "middle_name",
    label: "middle_name",
    searchFlg: true,
  },
  {
    id: "company_name",
    label: "company_name",
    searchFlg: true,
  },
  { id: "customer_number_DZ", label: "customer_number", searchFlg: true },
  { id: "customer_name", label: "customer_name", searchFlg: true },
  { id: "affiliate_cd", label: "affiliate_cd", searchFlg: true },
  { id: "primary_locale", label: "primary_locale", searchFlg: true },
  { id: "tel_no", label: "tel_no", searchFlg: true },
  { id: "mail_address", label: "mail_address", searchFlg: true },
  { id: "role_id", label: "role_id", searchFlg: true },

  {
    id: "sms_receive_notification",
    label: "sms_receive_notification",
    searchFlg: true,
  },
  {
    id: "mail_receive_notification",
    label: "mail_receive_notification",
    searchFlg: true,
  },
  { id: "Edit", label: "" },
];

export const MasterMaintenanceDZUserRegistrationThead = (props: any) => {
  const { order, orderBy, onRequestSort } = props;
  const languagePath =
    "modules.components.MasterMaintenance.dZUserRegistration";

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <SortTableThead
      checkboxFlg={false}
      headCells={headCells}
      createSortHandler={createSortHandler}
      defaultPath={languagePath}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  );
};
