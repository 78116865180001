import React, { useState, useEffect } from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";

export const CustomerSearchResultTableTbody = (props: any) => {
  const {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    researchFlg,
    setResearchFlg,
  } = props;
  const classes = useStyles();
  let initCustomerNo = 0;
  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (
          b[orderBy].toString().toUpperCase() <
          a[orderBy].toString().toUpperCase()
        ) {
          sort = -1;
        } else if (
          b[orderBy].toString().toUpperCase() >
          a[orderBy].toString().toUpperCase()
        ) {
          sort = 1;
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [initFlg, setInitFlg] = useState(true);

  useEffect(() => {
    // init selected on the first row.
    if (researchFlg) {
      setResearchFlg(false);
      setOrder("asc");
      setOrderBy("");
    }
  }, [researchFlg]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedValue(event.target.value);
    setInitFlg(false);
  };

  const handleClick = (row: any) => {
    props.handleClick(row);
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy).map(
        (row: any, index: number) => {
          return (
            <TableRow
              className={classes.tbody}
              hover
              onClick={() => handleClick(row)}
              key={row.customer_no}
            >
              <TableCell>
                <Radio
                  name="customerSelection"
                  checked={
                    initFlg
                      ? index === 0
                      : Number(selectedValue) === row.customer_no
                  }
                  value={row.customer_no}
                  onChange={(event: any) => handleChange(event, index)}
                />
              </TableCell>
              <TableCell>{row.customer_no}</TableCell>
              <TableCell>{row.customer_name}</TableCell>
              <TableCell>{row.address_3}</TableCell>
              <TableCell>{row.postal_cd}</TableCell>
              <TableCell>{row.address_1}</TableCell>
              <TableCell>{row.tel_no}</TableCell>
              <TableCell align="center">{row.customer_closed === true ? "Y" : "N"}</TableCell>
            </TableRow>
          );
        }
      )}
    </TableBody>
  );
};
