import React from "react";
// UI
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 300,
    },
    SearchButtonArea: {
      padding: "10px 0",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  })
);

export const MasterMaintenanceSearchBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.searchTool",
  });

  const handleSubmit = () => {
    return false;
  };

  return (
    <div className={classes.SearchButtonArea}>
      <Paper className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder={t("search")}
          inputProps={{ "aria-label": "search google maps" }}
          onChange={(e) => {
            e.preventDefault();
            props.handleChangeKey(e.target.value);
          }}
        />
        <IconButton className={classes.iconButton} onClick={props.handleSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
};
