import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { SimulateSearchControl } from "./components/Simulate.SearchControl";
import { SimulateSearchResult } from "./components/Simulate.SearchResult";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// api
import api from "../../modules/api/Api";
import { PathKey } from "../../pages/AppRoutes";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// methods
import { getApiResultStatus } from "../../modules/common.methods";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// method
import { getCodeName } from "../../modules/common.methods";
// permissions
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";

const defaultValue = {
  customer_discount_code: "",
  customer_no: "",
  customer_name: "",
  item_no: "",
  order_qty: "",
  description: "",
  start_date: new Date(),
  // promotion code項目追加
  promotion_code: "",
};

const errorList = {
  customer_no_error: "",
  item_no_error: "",
  start_date_error: "",
};

// result table上のPaymentTerm情報のDefault追加
const paymentTermInfoDefault = {
  terms_cd: "",
  terms_description: "",
  promotion_no: "",
};

export const Page: FC = () => {
  const { t } = useLocale({ defaultPath: "modules.components.simulate" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const [defaultValueList, setDefaultValueList] = useState(defaultValue);
  const [errorValueList, setErrorValueList] = useState(errorList);
  const [selectedSimulate, setSelectedSimulate] = useState(true);
  const [data, setData] = useState([]);
  const [freeGoodsList, setFreeGoodsList] = useState([]);
  const [freeGoods, setFreeGoods] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [customerNoDisabled, setCustomerNoDisabled] = useState(false);
  // check
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const [simulateDisaled, setSimulateDisabled] = useState(false);

  const [startFlg, setStartFlg] = useState(false);
  const {
    getCommonInfo,
    getCommonConfig,
    setCommonInfo,
    getSimulateInfo,
    setSimulateInfo,
    getHistoryRouter,
    setCommonInfoFull,
    getUserPermissions,
    setUserPermissions,
    getHotKey,
    setHotKey,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  const config = getCommonConfig() as any;
  let hotKeyInfo = getHotKey() as any;

  // permissions
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();

  let historyRouter = getHistoryRouter() as any;
  const simulateInfo = getSimulateInfo() as any;

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = com(`messages.${response.message_id}`, {
      param1:
        response.key === "promotion_code"
          ? searchValueList.promotion_code
          : t(response.key),
      param2: response.key === "promotion_code" ? t(response.key) : "",
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

  useEffect(() => {
    let apiList = [];
    let masterCustomerList: any = [];
    let itemBaseList: any = [];

    if (!startFlg && Object.keys(config).length > 0) {
      if (historyRouter.to === String(PathKey.SIMULATE)) {
        if (historyRouter.from === String(PathKey.PROMOTIONDETAIL)) {
          setData(simulateInfo.data);
          setFreeGoodsList(simulateInfo.freeGoods);
          // result table情報
          if (simulateInfo.resultTitle) {
            let _resultTitle = simulateInfo.resultTitle;
            // total amount
            if (_resultTitle.totalAmount) {
              setTotalAmount(_resultTitle.totalAmount);
            }
            // free goods
            if (_resultTitle.freeGoods) {
              setFreeGoods(_resultTitle.freeGoods);
            }
            // payment term information
            if (_resultTitle.terms_info) {
              setPaymentTermInfo({
                terms_cd: _resultTitle.terms_info.terms_cd,
                terms_description: _resultTitle.terms_info.terms_description,
                promotion_no: _resultTitle.terms_info.promotion_no,
              });
            }
          }
          if (simulateInfo.customer) {
            const searchParam = Object.assign({}, searchValueList, {
              customer_no: simulateInfo.customer.customer_no,
              customer_name: simulateInfo.customer.customer_name,
              customer_discount_code:
                simulateInfo.customer.customer_discount_code,
              promotion_code: simulateInfo.customer.promotion_code,
            });
            setSearchValueList(searchParam);
          }
        } else {
          setSimulateInfoFunc([], [], defaultValue);
          setData([]);
          setFreeGoodsList([]);
          setSearchValueList(defaultValue);
        }
        getDefaultApiList();
        setStartFlg(true);

        if (commonInfo.customerList.length === 0) {
          const api1 = api
            .getCustomerDetailListAPI(config)
            .then(({ response, code }: { response: any; code: any }) => {
              console.log("AM0205 response: ", response);

              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                masterCustomerList = response;
              }
            });
          apiList.push(api1);
        } else {
          masterCustomerList = commonInfo.customerList;
        }

        // get base item master list
        if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
          // api
          const api2 = api
            .getBaseItemListAPI(config)
            .then(({ response, code }: { response: any; code: any }) => {
              console.log("AM0305 response: ", response);
              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                // setCommonInfoFull({
                //   itemBaseList: response,
                //   loadingFlg: false,
                // });
                itemBaseList = response;
              }
            });
          apiList.push(api2);
        } else {
          // apiLoading(false);
          itemBaseList = commonInfo.itemBaseList;
        }

        if (apiList.length > 0) {
          Promise.all(apiList).then(() => {
            setCommonInfoFull({
              customerList: masterCustomerList,
              itemBaseList: itemBaseList,
              loadingFlg: false,
            });
          });
        } else {
          apiLoading(false);
        }
      }
    } else {
      if (!commonInfo.loadingFlg && Object.keys(config).length === 0) {
        apiLoading(true);
      }
    }
  }, [startFlg, config, historyRouter]);

  useEffect(() => {
    if (
      hotKeyInfo.handleF6Simulate &&
      !((data && data.length === 0) || simulateDisaled)
    ) {
      calcAmount();
    }
    setHotKey("handleF6Simulate", false);
  }, [hotKeyInfo.handleF6Simulate]);

  const getDefaultApiList = () => {
    let apiList = [];
    let localUserPermissions = {};
    apiLoading(true);

    // permission
    if (!userPermissions.permission_group) {
      const api5 = getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
      apiList.push(api5);
    } else {
      localUserPermissions = userPermissions;
    }

    Promise.all(apiList).then(() => {
      apiLoading(false);
      initStart(localUserPermissions);
    });
  };

  const initStart = (localUserPermissions: any) => {
    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(userPermissionsInfo);
    apiLoading(false);
  };

  const setSearchValue = (property: any, value: any) => {
    const valueList = Object.assign({}, searchValueList, { [property]: value });
    setSearchValueList(valueList);
  };

  // clear button click event
  const handleClearEvent = () => {
    const valueList = Object.assign({}, defaultValueList);
    setSearchValueList(valueList);
    setFreeGoodsList([]);
    setData([]);
    setSimulateInfo([]);
    setFreeGoods("");
    setSelectedInformation([], []);
    setErrorValueList(errorList);
    setPaymentTermInfo(paymentTermInfoDefault);
  };

  // Simulate button click event
  const calcAmount = (_newData: any = "") => {
    const _params = getPriceParam(_newData);
    _newData = _newData ? _newData : data;
    apiLoading(true);
    setAlertShow(false);
    setAlertMessage("");
    api
      .getReadPriceAPIforSimulation(_params, config)
      .then(({ code, response }: { code: any; response: any }) => {
        let _close_flg = true;
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          let totalAmount: any = 0;
          // if (response && response.lines.length > 0 && _newData.length > 0) {
          //   _newData.forEach((item: any, index: any) => {
          //     let price_line_list = response.lines.filter(
          //       (subItem: any) => subItem.line_no === item.line_no
          //     );
          //     if (price_line_list.length > 0) {
          //       newData[index] = Object.assign({}, item, price_line_list[0]);
          //       const _total =
          //         newData[index].order_qty * newData[index].net_price;
          //       totalAmount += isNaN(_total) ? 0 : _total;
          //     } else {
          //       newData[index] = Object.assign({}, item);
          //     }
          //   });
          // }
          if (response && response.lines && response.lines.length > 0) {
            response.lines.forEach((item: any) => {
              const _total = Number(item.order_qty) * Number(item.net_price);
              totalAmount = Number(totalAmount) + (isNaN(_total) ? 0 : _total);
            });
          }
          setTotalAmount(totalAmount);
          setData(response.lines);
          let _flg = 0;
          let _showFreeGoods: any = [];
          if (response && response.free_goods.length > 0) {
            const _freeGoods: any = response.free_goods.slice(0);
            _freeGoods.forEach((item: any) => {
              const subList = {
                selected: 0,
                list: item.slice(0),
              };
              _showFreeGoods.push(subList);
            });

            _flg = 1;
          }
          const _freeGoodsText = getCodeName(_flg, com("freeGoods_flg"));
          setFreeGoods(_freeGoodsText);
          setFreeGoodsList(_showFreeGoods);

          // get payment term description
          if (response && response.lines && response.lines.length > 0) {
            const _payment_term = response.lines[0].payment_term;
            if (_payment_term) {
              _close_flg = false;
              getPaymentTermDescription(
                _payment_term,
                response,
                _showFreeGoods,
                totalAmount,
                _freeGoodsText,
                response.lines[0].payment_term_promotion_no
              );
            }
          }
        }
        if (_close_flg) {
          apiLoading(false);
        }
      });
  };

  const [paymentTermInfo, setPaymentTermInfo] = useState(
    paymentTermInfoDefault
  );
  const getPaymentTermDescription = (
    _payment_term: any,
    _response: any,
    _showFreeGoods: any,
    _totalAmount: any,
    _freeGoodsText: any,
    payment_term_promotion_no: any
  ) => {
    api
      .getPaymentTermInformationApi(config, _payment_term)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        let termsInfo: any = "";
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setPaymentTermInfo({
            terms_cd: response.terms_cd,
            terms_description: response.terms_description,
            promotion_no: payment_term_promotion_no,
          });

          termsInfo = {
            terms_cd: response.terms_cd,
            terms_description: response.terms_description,
          };
        }
        setSimulateInfoFunc(_response.lines, _showFreeGoods, "", {
          totalAmount: _totalAmount,
          freeGoods: _freeGoodsText,
          terms_info: termsInfo
            ? {
                terms_cd: response.terms_cd,
                terms_description: response.terms_description,
                promotion_no: payment_term_promotion_no,
              }
            : termsInfo,
        });
      });
  };

  const setFreeGoodsValue = (value: any, index: any) => {
    let _data: any = freeGoodsList.slice(0);
    _data[index]["selected"] = value;
    setFreeGoodsList(_data);
  };

  const getValueCheck = (data: any) => data !== null && data !== undefined;

  const getDate = (date: any) => {
    if (date) {
      const d = new Date(date);
      date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      console.log("getDate2", date);
    }
    return date;
  };

  const getPriceParam = (_newData: any = "") => {
    let _data = _newData ? _newData : data;
    let _params = "";
    let _dataList: any = [];
    if (_data && _data.length > 0) {
      _data.forEach((item: any, index: number) => {
        let _item = JSON.parse(JSON.stringify(item));
        let flg: number = -1;
        for (let i: number = 0; i < _dataList.length; i++) {
          if (_item.item_no === _dataList[i].item_no) {
            flg = i;
          }
        }
        if (flg === -1) {
          _item.line_no = _dataList.length + 1;
          _dataList.push(_item);
        } else {
          _dataList[flg].order_qty =
            Number(_dataList[flg].order_qty) + Number(_item.order_qty);
        }
      });
      _dataList.forEach((item: any, index: number) => {
        if (index === 0) {
          _params += `customer_discount_code=${encodeURIComponent(
            getValueCheck(searchValueList.customer_discount_code)
              ? searchValueList.customer_discount_code
              : ""
          )}`;
          _params += `&customer_no=${encodeURIComponent(
            getValueCheck(searchValueList.customer_no)
              ? searchValueList.customer_no
              : ""
          )}`;
          _params += `&proc_date=${
            getValueCheck(getDate(searchValueList.start_date))
              ? getDate(searchValueList.start_date)
              : getDate(new Date())
          }`;
          // promotion code追加
          _params += `&promotion_code=${encodeURIComponent(
            getValueCheck(searchValueList.promotion_code)
              ? searchValueList.promotion_code
              : ""
          )}`;
        }
        _params += `&line_no=${encodeURIComponent(item.line_no)}`;
        _params += `&item_no=${encodeURIComponent(item.item_no)}`;
        _params += `&order_qty=${encodeURIComponent(item.order_qty)}`;
        // APIにより各ItemNoがpromotion code不要
        // _params += `&promotion_code=${encodeURIComponent(
        //   getValueCheck(item.promotion_code) ? item.promotion_code : ""
        // )}`;
      });
    }
    return _params;
  };

  const setErrorValue = (property: any, value: any) => {
    const errorList = Object.assign({}, errorValueList, { [property]: value });
    setErrorValueList(errorList);
  };

  // add event
  const addEventHandle = () => {
    const _list =
      data && data.length > 0
        ? data.filter((item: any) => item.item_no === searchValueList.item_no)
        : [];
    if (_list.length === 0) {
      const _item: any = {
        line_no: data && data.length + 1,
        item_no: searchValueList.item_no,
        description: searchValueList.description,
        order_qty:
          searchValueList.order_qty && Number(searchValueList.order_qty)
            ? Number(searchValueList.order_qty)
            : 1,
        customer_discount_code: searchValueList.customer_discount_code,
        customer_no: searchValueList.customer_no,
        customer_name: searchValueList.customer_name,
        promotion_code: searchValueList.promotion_code,
      };
      let newData: any = data ? data.slice(0) : [];
      newData.push(_item);
      const searchParam = Object.assign({}, searchValueList, {
        item_no: "",
        order_qty: "",
        description: "",
      });
      setSimulateInfoFunc(newData, "", {
        customer_no: searchValueList.customer_no,
        customer_name: searchValueList.customer_name,
        customer_discount_code: searchValueList.customer_discount_code,
        promotion_code: searchValueList.promotion_code,
      });
      setSearchValueList(searchParam);
      setData(newData);
    } else {
      const message = com("messages.MS0055", {
        rowNum: t("item_no"),
        param1: searchValueList.item_no,
      });
      const itemValue = {
        item_no_error: message,
      };
      const errorList = Object.assign({}, errorValueList, itemValue);
      setErrorValueList(errorList);
    }
    const element = document.getElementById("btn_simulate");
    if (element) {
      setTimeout(() => {
        element.focus();
      });
    }
  };

  const setSimulateInfoFunc = (
    _data: any,
    _freeGoods: any = "",
    _customer: any = "",
    _resultTitle: any = ""
  ) => {
    let obj: any = { data: _data };
    if (_freeGoods) {
      obj["freeGoods"] = _freeGoods;
    }
    if (_customer) {
      obj["customer"] = _customer;
    }
    if (_resultTitle) {
      obj["resultTitle"] = _resultTitle;
    }
    const _siumlateInfo = Object.assign({}, simulateInfo, obj);
    setSimulateInfo(_siumlateInfo);
  };

  // delete event
  const deleteEventHandle = () => {
    let newData: any = [];
    if (data && data.length > 0) {
      data.forEach((item: any, index: any) => {
        const selectedIndex = selectedArray.indexOf(item.line_no);
        if (selectedIndex < 0) {
          item.line_no = index + 1;
          newData.push(item);
        }
      });
      if (newData.length > 0) {
        calcAmount(newData);
      } else {
        setData(newData);
        setSimulateInfoFunc(newData);
        setFreeGoodsList([]);
      }
      setSelectedInformation([], []);
    }
  };

  // selected
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selectedArray.indexOf(row.line_no);
    // row selected no list
    let newSelected: string[] = [];
    // row selected data list
    let newSelectedRowArray: any = [];
    if (selectedIndex === -1) {
      // row selected no list
      newSelected = newSelected.concat(selectedArray, row.line_no);
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(0);
      newSelectedRowArray.push(row);
    } else if (selectedIndex === 0) {
      // row selected no list
      newSelected = newSelected.concat(selectedArray.slice(1));
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(1);
    } else if (selectedIndex === selectedArray.length - 1) {
      // row selected no list
      newSelected = newSelected.concat(selectedArray.slice(0, -1));
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(0, -1);
    } else if (selectedIndex > 0) {
      // row selected no list
      newSelected = newSelected.concat(
        selectedArray.slice(0, selectedIndex),
        selectedArray.slice(selectedIndex + 1)
      );
      // row selected data list
      newSelectedRowArray = selectedRowArray.slice(0, selectedIndex);
      newSelectedRowArray.push(selectedRowArray.slice(selectedIndex + 1));
    }
    setSelectedInformation(newSelected, newSelectedRowArray);
  };

  // all select event
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const _data = data
        .slice(0)
        .filter((item: any) => item.free_goods_flag !== 1);
      const newSelecteds = _data.map((item: any) => item.line_no);
      setSelectedInformation(newSelecteds, _data);
      return;
    }
    setSelectedInformation([], []);
  };

  const setSelectedInformation = (list: string[], rowList: any) => {
    setSelectedArray(list);
    setSelectedRowArray(rowList);
    changeNumSelected(list.length);
    const checkedData = data.filter((item: any) => item.free_goods_flag !== 1);
    const flg = list.length > 0 && list.length < checkedData.length;
    setIndeterminate(flg);
    const allCheckedFlg = list.length > 0 && list.length === checkedData.length;
    setAllChecked(allCheckedFlg);
  };

  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelected(num);
    if (num > 0) {
      setSelectedSimulate(false);
    } else {
      setSelectedSimulate(true);
    }
  };

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <SimulateSearchControl
        itemList={commonInfo.itemBaseList ? commonInfo.itemBaseList : []}
        setErrorValue={setErrorValue}
        searchValueList={searchValueList}
        setSearchValue={setSearchValue}
        setSearchValueList={setSearchValueList}
        errorValueList={errorValueList}
        selectedSimulate={selectedSimulate}
        commonInfo={commonInfo}
        customerNoDisabled={customerNoDisabled}
        setCustomerNoDisabled={setCustomerNoDisabled}
        setErrorValueList={setErrorValueList}
        addEventHandle={addEventHandle}
        deleteEventHandle={deleteEventHandle}
        data={data}
        apiLoading={apiLoading}
        userPermisssionInfo={userPermisssionInfo}
        setAlertShow={setAlertShow}
        setAlertMessage={setAlertMessage}
        setAlertType={setAlertType}
      />
      <SimulateSearchResult
        handleClearEvent={handleClearEvent}
        setSelectedSimulate={setSelectedSimulate}
        data={data}
        setData={setData}
        freeGoods={freeGoods}
        totalAmount={totalAmount}
        calcAmount={calcAmount}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        handleSelectAllClick={handleSelectAllClick}
        handleClick={handleClick}
        changeNumSelected={changeNumSelected}
        setSelectedInformation={setSelectedInformation}
        setIndeterminate={setIndeterminate}
        setAllChecked={setAllChecked}
        numSelected={numSelected}
        freeGoodsList={freeGoodsList}
        allChecked={allChecked}
        setFreeGoodsValue={setFreeGoodsValue}
        indeterminate={indeterminate}
        userPermisssionInfo={userPermisssionInfo}
        setSimulateDisabled={setSimulateDisabled}
        simulateDisaled={simulateDisaled}
        errorValueList={errorValueList}
        paymentTermInfo={paymentTermInfo}
      />
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
