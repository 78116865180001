import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "erp_user_id", label: "table_technician_code" },
  { id: "name", label: "table_technician_name" },
  { id: "allocated_qty", label: "table_allocated_qty" },
  { id: "repair_start_qty", label: "table_repair_start_qty" },
  { id: "quotation_open_qty", label: "table_quotation_open_qty" },
  { id: "quotation_answered_qty", label: "table_quotation_answered_qty" },
  { id: "repair_end_qty", label: "table_repair_end_qty" },
];

export const TechnicianListResultTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.jobs" });

  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.thead} id="tableHead_technician">
      <TableRow className={classes.tableRowHeader}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
