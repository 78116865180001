import React from "react";
// UI
import { useStyles } from "../hooks/useStyles";
import Button from "@material-ui/core/Button";

export const WagonDZOverviewControl = (props: any) => {
  const classes = useStyles();

  return (
    <ul className={classes.wagonDzTableControl}>
      <li className={classes.wagonDzTableNextButton}>
        <Button
          disabled={props.disabledFlg}
          className={classes.wagonDztableControlButton}
          variant="contained"
          color="primary"
          component="span"
          onClick={() =>
            props.handleNextWagonClick(props.wangonNumber, props.subTitle)
          }
        >
          {props.btnNextWagon}
        </Button>
      </li>
    </ul>
  );
};
