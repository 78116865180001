import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";

export const JobsDetailGeneralFaultDescription = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.label",
  });
  const { formList, setFormListValue, userPermisssionInfo } = props;

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("fault_description")}</h3>
      <TextField
        disabled={!userPermisssionInfo.fault_description_authFlg}
        value={formList.noteFaultDescription.note}
        className={classes.multilineTextField}
        placeholder={t("placeholder_fault_description")}
        variant="outlined"
        multiline
        rows={3}
        onChange={(e) =>
          setFormListValue("noteFaultDescription", e.target.value, "note")
        }
        inputProps={{ maxLength: 2048 }}
      />
    </div>
  );
};
