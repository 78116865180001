import React, { FC, useEffect, useState } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { StyledPieGraph } from "./components/PieGraph";
import { StyledBarGraph } from "./components/BarGraph";
import { StyledBarGraphVertical } from "./components/BarGraphVertical";
import { StyledBarGraphJobEntry } from "./components/BarGraphJobEntry";
import { StyledBarGraphRepair } from "./components/BarGraphRepair";
import { StyledBarGraphGoodsOut } from "./components/BarGraphGoodsOut";
import { StyledBarGraphQA } from "./components/BarGraphQA";
import { Technician } from "./components/Report.Technician";
import { Model } from "./components/Report.Model";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";

// UI
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { DatePicker } from "../../modules/components/datePicker/DatePicker";

// hook
import { useStyles } from "./hooks/control.useStyles";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
import { getLocale } from "../../modules/i18n";
// api
import api from "../../modules/api/Api";
// check
import { checkMaxLength } from "../../modules/hooks/UseValication";
import { getCSVContents } from "./methods/common.methods";
import FileSaver from "file-saver";

let nowdateFrom = new Date() as any;
let nowdateTo = new Date() as any;
let lastMonth = new Date(nowdateFrom - 86400 * 1000) as any;
let nowdateFromData = new Date(lastMonth.setDate(1)) as any;
let nowdateToData = new Date(nowdateTo - 86400 * 1000) as any;
let nowdateToDataChart = new Date(nowdateTo) as any;

const defaultValue = {
  service_center_id: [""],
  service_center_addr1: [""],
  technician_cd: "",
  report_from_date: nowdateFromData.setHours(0, 0, 0, 0),
  report_to_date: nowdateToData.setHours(23, 59, 59, 999),
  tech_model_from_date: nowdateFromData.setHours(0, 0, 0, 0),
  tech_model_to_date: nowdateToData.setHours(23, 59, 59, 999),
  daily_chart_date: nowdateToDataChart.setHours(23, 59, 59, 999),
  technician_cd_error: "",
  report_from_date_error: "",
  report_to_date_error: "",
  tech_model_from_date_error: "",
  tech_model_to_date_error: "",
};

const MonthlyBarGraphDefaultData = {
  bar_monthly: [],
  bar_within_48h: [],
};

const DailyBarGraphDefaultData = {
  goods_in: [],
  job_entry: [],
  repair_qty_48h: [],
  repair_qty: [],
  repair_waranty_charge: [],
  task_for_qa: [],
  bar_task_for_qa: [],
  goods_out: [],
};

// format date
function dateFormat(fmt: any, date: any) {
  let ret: any;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
  } as any;
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

export const Page: FC = () => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.report" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const [monthlyBarGraphResult, setMonthlyBarGraphResult] = useState<any>(
    MonthlyBarGraphDefaultData
  );
  const [dailyBarGraphResult, setDailyBarGraphResult] = useState<any>(
    DailyBarGraphDefaultData
  );
  const [btnClickType, setBtnClickType] = useState<any>("");
  const yearList: any = process.env.REACT_APP_REPORT_YEAR_LIST?.split(",");

  const {
    getCommonInfo,
    setCommonInfo,
    getUserInfoRepair,
    getCommonConfig,
    setUserInfoRepair,
    setCommonInfoFull,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const commonInfo = getCommonInfo() as any;
  const userInfoRepair = getUserInfoRepair() as any;
  let config = getCommonConfig() as any;

  // search value
  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const [initFlg, setInitFlg] = useState(true);
  const [alertMessageList, setAlertMessageList] = useState([]);
  const [disabledFlg, setDisabledFlg] = useState(false);
  const [techModelDisabledFlg, setTechModelDisabledFlg] = useState(false);

  useEffect(() => {
    if (initFlg && Object.keys(config).length > 0) {
      let data: any = {};
      let masterServiceCenterList: any = [];
      let masterTechnicianList: any = [];
      let serviceCenterListApi;
      let userInfoRepairApi;
      let erpUserListApi;
      let localUserInfoRepair = {};
      apiLoading(true);
      data = Object.assign({}, defaultValue, {
        service_center_id: [userInfoRepair.service_center],
      });
      // get service Center List
      // service center master AM0705
      serviceCenterListApi = api
        .getServiceCenterDetailListAPI(config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AM0705 response: ", response);
          if (code !== 200) {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          } else {
            masterServiceCenterList = response;
          }
        });

      // Read UserInfo API For Userinfo Repair AM0108
      if (userInfoRepair.service_center === undefined) {
        userInfoRepairApi = api
          .getReadUserInfoAPIForUserinfoRepair(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0108 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              localUserInfoRepair = response;
              data = Object.assign({}, defaultValue, {
                service_center_id: [response.service_center],
              });
              setUserInfoRepair(localUserInfoRepair);
            }
          });
      }

      // fetch master data
      // erp user master(technician) AM0405
      if (commonInfo.technicianList.length === 0) {
        erpUserListApi = api
          .getERPUserDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0405 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterTechnicianList = response;
            }
          });
      } else {
        masterTechnicianList = commonInfo.technicianList;
        erpUserListApi = true;
      }

      Promise.all([
        serviceCenterListApi,
        userInfoRepairApi,
        erpUserListApi,
      ]).then(() => {
        commonInfo.serviceCenterList = masterServiceCenterList;
        setCommonInfoFull({
          serviceCenterList: masterServiceCenterList,
          technicianList: masterTechnicianList,
        });
        setInitFlg(false);
        apiLoading(false);
        setSearchValueList(data);
      });
    }
  }, [initFlg, config]);

  const setSearchValue = (property: any, value: any) => {
    setSearchValueList((preSearchValueList) => {
      return Object.assign({}, preSearchValueList, { [property]: value });
    });
  };
  // tab
  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 1) {
      getDailyBarGraphData();
    } else if (newValue === 2) {
      getMonthlyBarGraphData();
    } else if (newValue === 3) {
      getTechnicianData(newValue);
    } else if (newValue === 4) {
      getModelData(newValue);
    } else {
      setBtnClickType("");
      setAlertMessageList([]);
      if (searchValueList.service_center_id.length <= 0) {
        const data = Object.assign({}, defaultValue, {
          service_center_id: [userInfoRepair.service_center],
        });
        setSearchValueList(data);
      }
    }
  };

  // Selete Daily chart date
  useEffect(() => {
    if (btnClickType === "daily_chart") {
      //console.log("useEffect===============", searchValueList);
      getDailyBarGraphData();
    }
    if (btnClickType === "monthly_chart") {
      getMonthlyBarGraphData();
    }
  }, [searchValueList]);

  const getYear = () => {
    let nowDate = new Date() as any;
    let nowMonth = nowDate.getMonth() +1;
    let year;
    if (nowMonth > 3) {
      year = nowDate.getFullYear();
    } else {
      year = nowDate.getFullYear() -1;
    }
    return year;
  }

  // Selete Year
  const [yearValue, setYearValue] = React.useState(getYear());
  const handleYearChange = (event: React.ChangeEvent<{ value: any }>) => {
    setYearValue(event.target.value);
  };

  useEffect(() => {
    if (btnClickType === "monthly_chart") {
      //console.log("useEffect===============", yearValue);
      
      getMonthlyBarGraphData();
    }
    setYearValue(yearValue);
  }, [yearValue]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchValue("service_center_id", event.target.value);
  };
  const onBlurOfReportFromDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.report_to_date > 0 &&
      searchValueList.report_from_date > 0 &&
      searchValueList.report_from_date > searchValueList.report_to_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_report_from_date"),
        endDate: t("txt_report_to_date"),
      });
      setSearchValue("report_from_date_error", message);
      setDisabledFlg(true);
    } else {
      setSearchValue("report_from_date_error", "");
      setDisabledFlg(false);
    }
  };

  const onBlurOfTechModelFromDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.tech_model_to_date > 0 &&
      searchValueList.tech_model_from_date > 0 &&
      searchValueList.tech_model_from_date > searchValueList.tech_model_to_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_tech_model_from_date"),
        endDate: t("txt_tech_model_to_date"),
      });
      setSearchValue("tech_model_from_date_error", message);
      setTechModelDisabledFlg(true);
    } else {
      setSearchValue("tech_model_from_date_error", "");
      setTechModelDisabledFlg(false);
    }
  };

  const onBlurOfReportToDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.report_to_date > 0 &&
      searchValueList.report_from_date > 0 &&
      searchValueList.report_from_date > searchValueList.report_to_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_report_from_date"),
        endDate: t("txt_report_to_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        report_from_date_error: message,
        report_to_date_error: "",
      });
      setSearchValueList(valueList);
      setDisabledFlg(true);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        report_from_date_error: "",
        report_to_date_error: "",
      });
      setSearchValueList(valueList);
      setDisabledFlg(false);
    }
  };

  const onBlurOfTechModelToDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.tech_model_to_date > 0 &&
      searchValueList.tech_model_from_date > 0 &&
      searchValueList.tech_model_from_date > searchValueList.tech_model_to_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_tech_model_from_date"),
        endDate: t("txt_tech_model_to_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        tech_model_from_date_error: message,
        tech_model_to_date_error: "",
      });
      setSearchValueList(valueList);
      setTechModelDisabledFlg(true);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        tech_model_from_date_error: "",
        tech_model_to_date_error: "",
      });
      setSearchValueList(valueList);
      setTechModelDisabledFlg(false);
    }
  };

  const checkReportFromDate = (date: any) => {
    if (
      searchValueList.report_to_date > 0 &&
      date > searchValueList.report_to_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_report_from_date"),
        endDate: t("txt_report_to_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        report_from_date_error: message,
        report_from_date: date,
      });
      setSearchValueList(valueList);
      setDisabledFlg(true);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        report_from_date_error: "",
        report_from_date: date,
      });
      setSearchValueList(valueList);
      setDisabledFlg(false);
    }
  };

  const checkTechModelFromDate = (date: any) => {
    if (
      searchValueList.tech_model_to_date > 0 &&
      date > searchValueList.tech_model_to_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_tech_model_from_date"),
        endDate: t("txt_tech_model_to_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        tech_model_from_date_error: message,
        tech_model_from_date: date,
      });
      setSearchValueList(valueList);
      setTechModelDisabledFlg(true);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        tech_model_from_date_error: "",
        tech_model_from_date: date,
      });
      setSearchValueList(valueList);
      setTechModelDisabledFlg(false);
    }
  };

  const checkReportToDate = (date: any) => {
    if (
      searchValueList.report_from_date > 0 &&
      searchValueList.report_from_date > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_report_from_date"),
        endDate: t("txt_report_to_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        report_from_date_error: message,
        report_to_date: date,
        report_to_date_error: "",
      });
      setSearchValueList(valueList);
      setDisabledFlg(true);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        report_from_date_error: "",
        report_to_date: date,
        report_to_date_error: "",
      });
      setSearchValueList(valueList);
      setDisabledFlg(false);
    }
  };

  const checkTechModelToDate = (date: any) => {
    if (
      searchValueList.tech_model_from_date > 0 &&
      searchValueList.tech_model_from_date > date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_tech_model_from_date"),
        endDate: t("txt_tech_model_to_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        tech_model_from_date_error: message,
        tech_model_to_date: date,
        tech_model_to_date_error: "",
      });
      setSearchValueList(valueList);
      setTechModelDisabledFlg(true);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        tech_model_from_date_error: "",
        tech_model_to_date: date,
        tech_model_to_date_error: "",
      });
      setSearchValueList(valueList);
      setTechModelDisabledFlg(false);
    }
  };

  const checkChartDate = (date: any) => {
    let valueList = Object.assign({}, searchValueList, {
      report_from_date_error: "",
      daily_chart_date: date,
      report_to_date_error: "",
    });
    setSearchValueList(valueList);
    setDisabledFlg(false);
  };

  const getParameter = (newValue?: any) => {
    setAlertMessageList([]);
    let param_serviceCenterId = "";
    let param_reportFromDate = "";
    let param_reportToDate = "";
    let query_parameters = "";
    if (searchValueList.service_center_id.length <= 0) {
      const obj: any = {
        no: alertMessageList.length,
        message: com("messages.MS0046", {
          param1: t("txt_service_center"),
        }),
        alertType: "error",
      };
      let alertMessageListNew: any = [obj];
      setAlertMessageList(alertMessageListNew);
    } else {
      for (let index in searchValueList.service_center_id) {
        param_serviceCenterId =
          param_serviceCenterId +
          "&service_center_id=" +
          searchValueList.service_center_id[index];
      }
      if (newValue === 3 || newValue === 4) {
        let tech_model_from_date = searchValueList.tech_model_from_date;
        param_reportFromDate = dateFormat(
          "YYYY-mm-dd",
          new Date(tech_model_from_date)
        );

        let tech_model_to_date = searchValueList.tech_model_to_date;
        param_reportToDate = dateFormat(
          "YYYY-mm-dd",
          new Date(tech_model_to_date)
        );
      } else {
        let report_from_date = searchValueList.report_from_date;
        param_reportFromDate = dateFormat(
          "YYYY-mm-dd",
          new Date(report_from_date)
        );

        let report_to_date = searchValueList.report_to_date;
        param_reportToDate = dateFormat("YYYY-mm-dd", new Date(report_to_date));
      }

      if (newValue === 4) {
        query_parameters =
          "lang=" +
          getLocale() +
          "&report_from_date=" +
          encodeURIComponent(param_reportFromDate).substring(0, 10) +
          "&report_to_date=" +
          encodeURIComponent(param_reportToDate).substring(0, 10) +
          param_serviceCenterId +
          "&technician_cd=" +
          encodeURIComponent(searchValueList.technician_cd);
      } else {
        query_parameters =
          "lang=" +
          getLocale() +
          "&report_from_date=" +
          encodeURIComponent(param_reportFromDate).substring(0, 10) +
          "&report_to_date=" +
          encodeURIComponent(param_reportToDate).substring(0, 10) +
          param_serviceCenterId;
      }
    }
    return query_parameters;
  };

  const handleDownloadEvent = () => {
    apiLoading(true);
    let query_parameters = getParameter();
    if (query_parameters === "") {
      apiLoading(false);
    } else {
      if (value === 0) {
        downLoadFile(query_parameters);
      } else if (value === 3) {
        setCSV(technicianResult, value);
      } else if (value === 4) {
        setCSV(modelResult, value);
      }
    }
  };

  const downLoadFile = (query_parameters: any) => {
    // Read Report List AXXX
    api
      .getReportListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        if (code !== 200) {
          if (code == 404) {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages.MS0004", {
                param1: t("txt_report"),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          } else {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          }
        } else {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download =
            "EBIS_Repair_DailyReport_" +
            dateFormat(
              "YYYY-mm-dd",
              new Date(searchValueList.report_from_date)
            ) +
            "_" +
            dateFormat("YYYY-mm-dd", new Date(searchValueList.report_to_date)) +
            ".csv";
          a.click();
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const setCSV = (data: any, tabValue: any) => {
    let name;
    const str = getCSVContents(t, data, config, tabValue);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    if (value === 3) {
      name =
        "EBIS_Repair_TechnicianReport_" +
        dateFormat(
          "YYYY-mm-dd",
          new Date(searchValueList.tech_model_from_date)
        ) +
        "_" +
        dateFormat("YYYY-mm-dd", new Date(searchValueList.tech_model_to_date)) +
        ".csv";
    } else if (value === 4) {
      name =
        "EBIS_Repair_ModelReport_" +
        dateFormat(
          "YYYY-mm-dd",
          new Date(searchValueList.tech_model_from_date)
        ) +
        "_" +
        dateFormat("YYYY-mm-dd", new Date(searchValueList.tech_model_to_date)) +
        ".csv";
    }
    FileSaver.saveAs(blob, name);
    apiLoading(false);
  };

  const handleDownloadTechnicianOrModelEvent = () => {
    handleDownloadEvent();
  };

  const getMonthlyBarGraphData = () => {
    apiLoading(true);
    // Monthly Bar Chart
    //console.log("getMonthlyBarGraphData====================");
    setAlertMessageList([]);
    setMonthlyBarGraphResult(MonthlyBarGraphDefaultData);
    setBtnClickType("monthly_chart");
    let param_serviceCenterId = "";
    if (searchValueList.service_center_id.length > 0) {
      for (let index in searchValueList.service_center_id) {
        param_serviceCenterId =
          param_serviceCenterId +
          "&service_center_id=" +
          searchValueList.service_center_id[index];
      }
    } else {
      param_serviceCenterId = "";
    }

    let daily_chart_date = searchValueList.daily_chart_date;
    let param_dailyChartDate = dateFormat(
      "YYYY-mm-dd",
      new Date(daily_chart_date)
    );

    let query_parameters =
      "lang=" +
      getLocale() +
      "&monthly_chart_year=" +
      yearValue +
      param_serviceCenterId;
    api
      .getReportMonthlyBarGraphAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AXXX response: ", response);
        console.log("AXXX code: ", code);
        if (code !== 200) {
          if (code === 404) {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages.MS0004", {
                param1: t("txt_report"),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          } else if (code === 400) {
            setAlertMessageList([]);
          } else {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          }
        } else {
          setMonthlyBarGraphResult(response);
          //console.log(monthlyBarGraphResult);
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const getDailyBarGraphData = () => {
    apiLoading(true);
    // Bar Chart
    //console.log("getDailyBarGraphData====================");
    setAlertMessageList([]);
    setDailyBarGraphResult(DailyBarGraphDefaultData);
    setBtnClickType("daily_chart");
    let param_serviceCenterId = "";
    if (searchValueList.service_center_id.length > 0) {
      for (let index in searchValueList.service_center_id) {
        param_serviceCenterId =
          param_serviceCenterId +
          "&service_center_id=" +
          searchValueList.service_center_id[index];
      }
    } else {
      param_serviceCenterId = "";
    }

    let daily_chart_date = searchValueList.daily_chart_date;
    let param_dailyChartDate = dateFormat(
      "YYYY-mm-dd",
      new Date(daily_chart_date)
    );

    let query_parameters =
      "lang=" +
      getLocale() +
      "&daily_chart_date=" +
      encodeURIComponent(param_dailyChartDate).substring(0, 10) +
      param_serviceCenterId;
    api
      .getReportDailyBarGraphAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AXXX response: ", response);
        console.log("AXXX code: ", code);
        if (code !== 200) {
          if (code === 404) {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages.MS0004", {
                param1: t("txt_report"),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          } else if (code == 400) {
            setAlertMessageList([]);
          } else {
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          }
        } else {
          setDailyBarGraphResult(response);
          //console.log(dailyBarGraphResult);
        }
      })
      .then(() => {
        apiLoading(false);
      });
  };

  const getTechnicianData = (newValue: any) => {
    setBtnClickType("technician");
    apiLoading(true);
    let query_parameters = getParameter(newValue);
    if (query_parameters === "") {
      apiLoading(false);
    } else {
      api
        .getReportTechnicianAPI(query_parameters, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AXXX response: ", response);
          console.log("AXXX code: ", code);
          if (code !== 200) {
            if (code === 404) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages.MS0004", {
                  param1: t("txt_report"),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else if (code === 400) {
              setAlertMessageList([]);
            } else {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            }
          } else {
            let technicianSearchResult = editedReportTechnicianResponse(
              JSON.parse(JSON.stringify(response))
            );
            setTechnicianResult(technicianSearchResult);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    }
  };

  const editedReportTechnicianResponse = (response: any) => {
    let technicianSearchResult = response;
    for (let index in technicianSearchResult) {
      if (
        technicianSearchResult[index].service_center !== undefined &&
        technicianSearchResult[index].service_center !== ""
      ) {
        technicianSearchResult[index].service_center = getServiceCenterName(
          technicianSearchResult[index].service_center
        );
      }
      if (
        technicianSearchResult[index].working_days !== undefined &&
        technicianSearchResult[index].working_days !== ""
      ) {
        technicianSearchResult[index].working_days = Number(
          technicianSearchResult[index].working_days
        );
      }
      if (
        technicianSearchResult[index].goods_in !== undefined &&
        technicianSearchResult[index].goods_in !== ""
      ) {
        technicianSearchResult[index].goods_in = Number(
          technicianSearchResult[index].goods_in
        );
      }
      if (
        technicianSearchResult[index].task_for_qa !== undefined &&
        technicianSearchResult[index].task_for_qa !== ""
      ) {
        technicianSearchResult[index].task_for_qa = Number(
          technicianSearchResult[index].task_for_qa
        );
      }
      if (
        technicianSearchResult[index].job_entry !== undefined &&
        technicianSearchResult[index].job_entry !== ""
      ) {
        technicianSearchResult[index].job_entry = Number(
          technicianSearchResult[index].job_entry
        );
      }
      if (
        technicianSearchResult[index].worked_tool_qty !== undefined &&
        technicianSearchResult[index].worked_tool_qty !== ""
      ) {
        technicianSearchResult[index].worked_tool_qty = Number(
          technicianSearchResult[index].worked_tool_qty
        );
      }
      if (
        technicianSearchResult[index].waranty_repair !== undefined &&
        technicianSearchResult[index].waranty_repair !== ""
      ) {
        technicianSearchResult[index].waranty_repair = Number(
          technicianSearchResult[index].waranty_repair
        );
      }
      if (
        technicianSearchResult[index].charge_repair !== undefined &&
        technicianSearchResult[index].charge_repair !== ""
      ) {
        technicianSearchResult[index].charge_repair = Number(
          technicianSearchResult[index].charge_repair
        );
      }
      if (
        technicianSearchResult[index].inspection_for_quote !== undefined &&
        technicianSearchResult[index].inspection_for_quote !== ""
      ) {
        technicianSearchResult[index].inspection_for_quote = Number(
          technicianSearchResult[index].inspection_for_quote
        );
      }
      if (
        technicianSearchResult[index].acc !== undefined &&
        technicianSearchResult[index].acc !== ""
      ) {
        technicianSearchResult[index].acc = Number(
          technicianSearchResult[index].acc
        );
      }
      // technician's avr prepair time
      if (
        technicianSearchResult[index].avr_repair_time !== undefined &&
        technicianSearchResult[index].avr_repair_time !== ""
      ) {
        technicianSearchResult[index].avr_repair_time_format = Number(
          technicianSearchResult[index].avr_repair_time
        );
        technicianSearchResult[index].avr_repair_time = 
          technicianSearchResult[index].avr_repair_time.replace(".", ",")
      }
      // technician's vs total avr
      if (
        technicianSearchResult[index].vs_total_avr !== undefined &&
        technicianSearchResult[index].vs_total_avr !== ""
      ) {
        technicianSearchResult[index].vs_total_avr_format = Number(
          technicianSearchResult[index].vs_total_avr
        );
        technicianSearchResult[index].vs_total_avr = 
          technicianSearchResult[index].vs_total_avr.replace(".", ",")
      }
    }
    return technicianSearchResult;
  };

  // fetch the service center name from constants
  const getServiceCenterName = (code: string) => {
    let serviceCenterName = "";
    if (code && code !== "") {
      for (let index in commonInfo.serviceCenterList) {
        if (commonInfo.serviceCenterList[index].service_center_id === code) {
          serviceCenterName = commonInfo.serviceCenterList[index].address_1;
          break;
        }
      }
    }
    return serviceCenterName;
  };

  const getModelData = (newValue: any) => {
    setBtnClickType("model");
    apiLoading(true);
    let query_parameters = getParameter(newValue);
    if (query_parameters === "") {
      apiLoading(false);
    } else {
      api
        .getReportModelAPI(query_parameters, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AXXX response: ", response);
          console.log("AXXX code: ", code);
          if (code !== 200) {
            if (code === 404) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages.MS0004", {
                  param1: t("txt_report"),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else if (code === 400) {
              setAlertMessageList([]);
            } else {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            }
          } else {
            let modelSearchResult = editedReportModelResponse(
              JSON.parse(JSON.stringify(response))
            );
            for (let index in modelSearchResult) {
              console.log("modelSearchResult[index]", modelSearchResult[index]);
              if (
                modelSearchResult[index].service_center !== undefined &&
                modelSearchResult[index].service_center !== ""
              ) {
                modelSearchResult[index].service_center = getServiceCenterName(
                  modelSearchResult[index].service_center
                );
              }
            }
            setModelResult(modelSearchResult);
          }
        })
        .then(() => {
          apiLoading(false);
        });
    }
  };

  const editedReportModelResponse = (response: any) => {
    let modelSearchResult = response;
    for (let index in modelSearchResult) {
      if (
        modelSearchResult[index].service_center !== undefined &&
        modelSearchResult[index].service_center !== ""
      ) {
        modelSearchResult[index].service_center = getServiceCenterName(
          modelSearchResult[index].service_center
        );
      }
      if (
        modelSearchResult[index].total_repair !== undefined &&
        modelSearchResult[index].total_repair !== ""
      ) {
        modelSearchResult[index].total_repair = Number(
          modelSearchResult[index].total_repair
        );
      }
      if (
        modelSearchResult[index].waranty_repair !== undefined &&
        modelSearchResult[index].waranty_repair !== ""
      ) {
        modelSearchResult[index].waranty_repair = Number(
          modelSearchResult[index].waranty_repair
        );
      }
      if (
        modelSearchResult[index].charge_repair !== undefined &&
        modelSearchResult[index].charge_repair !== ""
      ) {
        modelSearchResult[index].charge_repair = Number(
          modelSearchResult[index].charge_repair
        );
      }
      if (
        modelSearchResult[index].percent !== undefined &&
        modelSearchResult[index].percent !== ""
      ) {
        modelSearchResult[index].percent = Number(
          modelSearchResult[index].percent
        );
      }
      // model's avr prepair time
      if (
        modelSearchResult[index].avr_repair_time !== undefined &&
        modelSearchResult[index].avr_repair_time !== ""
      ) {
        modelSearchResult[index].avr_repair_time_format = Number(
          modelSearchResult[index].avr_repair_time
        );
        modelSearchResult[index].avr_repair_time = 
          modelSearchResult[index].avr_repair_time.replace(".", ",")
      }
    }
    return modelSearchResult;
  };

  const handleSearchEvent = () => {
    if (value === 3) {
      getTechnicianData(value);
    } else if (value === 4) {
      getModelData(value);
    }
  };

  const [technicianResult, setTechnicianResult] = useState([]) as any;
  const [modelResult, setModelResult] = useState([]) as any;

  const getOptionLabel = (option: any) =>
    option.erp_user_id + " : " + option.name || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    // limit: 10,
    stringify: getOptionLabel,
  });

  const onBlurOfTechnicianCode = () => {
    let noError = true;
    setSearchValue("technician_cd_error", "");
    if (!checkMaxLength(searchValueList.technician_cd, 10)) {
      let message = com("messages.MS0002", {
        param1: t("txt_technician_code"),
        param2: 10,
      });
      setSearchValue("technician_cd_error", message);
      noError = false;
      setTechModelDisabledFlg(true);
    } else if (!masterExitOfTechnician(searchValueList.technician_cd)) {
      let message = com("messages.MS0004", {
        param1: t("txt_technician_code"),
      });
      setSearchValue("technician_cd_error", message);
      noError = false;
      setTechModelDisabledFlg(true);
    } else {
      setSearchValue("technician_cd_error", "");
      setTechModelDisabledFlg(false);
    }
    return noError;
  };

  // technician code exist validation
  const masterExitOfTechnician = (value: string) => {
    let isExisted = false;
    let technicians = commonInfo.technicianList;

    if (value && value !== "") {
      if (technicians.length > 0) {
        for (let index in technicians) {
          if (
            technicians[index].erp_user_id.toUpperCase() === value.toUpperCase()
          ) {
            isExisted = true;
            break;
          }
        }
      } else {
        // TODO call api to check whether existed
      }
    } else {
      isExisted = true;
    }

    return isExisted;
  };

  const contents = (
    <div id="contents">
      {alertMessageList.length > 0 &&
        alertMessageList.map((item: any) => (
          <AlertMesssageBox
            key={item.no}
            show={item.message}
            message={item.message}
            type={item.alertType}
          />
        ))}
      <div className={classes.TabRoot}>
        <AppBar className={classes.AppBar} position="static">
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab className={classes.tabButton} label={t("tab_download")} />
            <Tab className={classes.tabButton} label={t("tab_daily_chart")} />
            <Tab className={classes.tabButton} label={t("tab_monthly_chart")} />
            <Tab className={classes.tabButton} label={t("tab_technician")} />
            <Tab className={classes.tabButton} label={t("tab_model")} />
          </Tabs>
        </AppBar>
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.row}>
          <dl>
            <dt>{t("txt_service_center")}</dt>
            <dd>
              <FormControl variant="outlined">
                <Select
                  multiple
                  id="service_center"
                  className={classes.searchControlSelect}
                  value={searchValueList.service_center_id}
                  onChange={handleChange}
                  renderValue={() => ""}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {Array.isArray(commonInfo.serviceCenterList) ? (
                    commonInfo.serviceCenterList.map((service_center: any) => (
                      <MenuItem
                        key={service_center.service_center_id}
                        value={service_center.service_center_id}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            searchValueList.service_center_id.indexOf(
                              service_center.service_center_id
                            ) > -1
                          }
                        />
                        <ListItemText primary={service_center.address_1} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </dd>
          </dl>
          <dl hidden={value !== 4}>
            <dt>{t("txt_technician_code")}</dt>
            <dd>
              <Autocomplete
                className={classes.autoComplete}
                id="technician_code"
                freeSolo
                disableClearable
                options={commonInfo.technicianList}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                inputValue={searchValueList.technician_cd}
                value={searchValueList.technician_cd}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      minWidth: "300px",
                    }}
                    placement="bottom-start"
                  />
                )}
                getOptionSelected={(option: any, value: any) => {
                  let isSelected = false;
                  if (option.erp_user_id && searchValueList.technician_cd) {
                    isSelected =
                      option.erp_user_id.toString().toUpperCase() ===
                      searchValueList.technician_cd.toString().toUpperCase();
                  }
                  return isSelected;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    error={searchValueList.technician_cd_error !== ""}
                    helperText={searchValueList.technician_cd_error}
                    onBlur={onBlurOfTechnicianCode}
                  />
                )}
                onChange={(event: object, value: any, reason: string) => {
                  if (reason === "select-option") {
                    setSearchValue(
                      "technician_cd",
                      value.erp_user_id.toString()
                    );
                  }
                }}
                onInputChange={(event: object, value: any, reason: string) => {
                  if (reason === "input" || reason === "clear") {
                    setSearchValue("technician_cd", value.toString());
                  }
                }}
              />
            </dd>
          </dl>
          <dl className={classes.datePickerArea} hidden={value !== 0}>
            <dt>{t("txt_report_date")}</dt>
            <dd className={classes.datePickerContainer}>
              <DatePicker
                value={searchValueList.report_from_date}
                onChange={(date: any) =>
                  setSearchValue("report_from_date", date)
                }
                onAccept={(date: any) =>
                  checkReportFromDate(new Date(date).setHours(0, 0, 0, 0))
                }
                className={classes.datePicker}
                error={searchValueList.report_from_date_error !== ""}
                helperText={searchValueList.report_from_date_error}
                onBlur={(e: any) => onBlurOfReportFromDate(e)}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.report_from_date_error &&
                    error !== ""
                  ) {
                    setSearchValue("report_from_date_error", error);
                  }
                }}
              />
              <span className={classes.datePickerInterval}></span>
              <DatePicker
                value={searchValueList.report_to_date}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setSearchValue(
                      "report_to_date",
                      new Date(date).setHours(23, 59, 59, 999)
                    );
                  } else {
                    setSearchValue("report_to_date", date);
                  }
                }}
                onAccept={(date: any) =>
                  checkReportToDate(new Date(date).setHours(23, 59, 59, 999))
                }
                className={classes.datePicker}
                error={searchValueList.report_to_date_error !== ""}
                helperText={searchValueList.report_to_date_error}
                onBlur={(e: any) => onBlurOfReportToDate(e)}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.report_to_date_error &&
                    error !== ""
                  ) {
                    setSearchValue("report_to_date_error", error);
                  }
                }}
              />
            </dd>
          </dl>
          <dl
            className={classes.datePickerArea}
            hidden={value !== 3 && value !== 4}
          >
            <dt>{t("txt_tech_model_date")}</dt>
            <dd className={classes.datePickerContainer}>
              <DatePicker
                value={searchValueList.tech_model_from_date}
                onChange={(date: any) =>
                  setSearchValue("tech_model_from_date", date)
                }
                onAccept={(date: any) =>
                  checkTechModelFromDate(new Date(date).setHours(0, 0, 0, 0))
                }
                className={classes.datePicker}
                error={searchValueList.tech_model_from_date_error !== ""}
                helperText={searchValueList.tech_model_from_date_error}
                onBlur={(e: any) => onBlurOfTechModelFromDate(e)}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.tech_model_from_date_error &&
                    error !== ""
                  ) {
                    setSearchValue("tech_model_from_date_error", error);
                  }
                }}
                maxDate={nowdateToData}
              />
              <span className={classes.datePickerInterval}></span>
              <DatePicker
                value={searchValueList.tech_model_to_date}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setSearchValue(
                      "tech_model_to_date",
                      new Date(date).setHours(23, 59, 59, 999)
                    );
                  } else {
                    setSearchValue("tech_model_to_date", date);
                  }
                }}
                onAccept={(date: any) =>
                  checkTechModelToDate(new Date(date).setHours(23, 59, 59, 999))
                }
                className={classes.datePicker}
                error={searchValueList.tech_model_to_date_error !== ""}
                helperText={searchValueList.tech_model_to_date_error}
                onBlur={(e: any) => onBlurOfTechModelToDate(e)}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.tech_model_to_date_error &&
                    error !== ""
                  ) {
                    setSearchValue("tech_model_to_date_error", error);
                  }
                }}
                maxDate={nowdateToData}
              />
            </dd>
          </dl>
          <dl className={classes.datePickerAreaDaily} hidden={value !== 1}>
            <dt>{t("txt_chart_date")}</dt>
            <dd className={classes.datePickerContainer}>
              <DatePicker
                value={searchValueList.daily_chart_date}
                onChange={(date: any) => {
                  if (date && date !== "") {
                    setSearchValue(
                      "daily_chart_date",
                      new Date(date).setHours(23, 59, 59, 999)
                    );
                  } else {
                    setSearchValue("daily_chart_date", date);
                  }
                }}
                onAccept={(date: any) =>
                  checkChartDate(new Date(date).setHours(23, 59, 59, 999))
                }
                className={classes.datePicker}
                error={searchValueList.report_to_date_error !== ""}
                helperText={searchValueList.report_to_date_error}
                onBlur={(e: any) => onBlurOfReportToDate(e)}
                onError={(error: any, value: any) => {
                  if (
                    error !== searchValueList.report_to_date_error &&
                    error !== ""
                  ) {
                    setSearchValue("report_to_date_error", error);
                  }
                }}
              />
            </dd>
          </dl>
          <dl hidden={value !== 2}>
            <dt>{"Year"}</dt>
            <dd>
              <FormControl variant="outlined">
                <Select
                  id="monthly_chart_year"
                  className={classes.searchControlSelect}
                  value={yearValue}
                  onChange={handleYearChange}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {yearList.map((year: any) => (
                    <MenuItem key={year} value={year}>
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </dd>
          </dl>
          <div
            className={classes.buttonArea}
            hidden={value !== 3 && value !== 4}
          >
            <Button
              className={classes.buttonCondition}
              variant="contained"
              disabled={techModelDisabledFlg}
              color="primary"
              onClick={() => handleSearchEvent()}
              startIcon={<SearchIcon />}
            >
              {t("btn_search")}
            </Button>
          </div>
          <div className={classes.buttonArea} hidden={value !== 0}>
            <Button
              className={classes.buttonCondition}
              variant="contained"
              disabled={disabledFlg}
              color="primary"
              onClick={() => handleDownloadEvent()}
              startIcon={<DownloadIcon />}
            >
              {t("btn_download")}
            </Button>
          </div>
          <div
            className={classes.buttonArea02}
            hidden={value !== 3 && value !== 4}
          >
            <Button
              className={classes.buttonCondition}
              variant="contained"
              disabled={techModelDisabledFlg}
              color="primary"
              onClick={() => handleDownloadTechnicianOrModelEvent()}
              startIcon={<DownloadIcon />}
            >
              {t("btn_download")}
            </Button>
          </div>
        </div>
      </form>
      {btnClickType === "daily_chart" && (
        <div className={classes.pageWrapper}>
          <div className={classes.multiColumnPie}>
            <StyledPieGraph
              title={t("pie_title_goods_in")}
              callData={dailyBarGraphResult.goods_in}
            />
            <StyledBarGraphJobEntry
              title={t("bar_title_job_entry")}
              callData={JSON.stringify([
                dailyBarGraphResult.job_entry.bar_in_system,
                dailyBarGraphResult.job_entry.bar_outstanding,
              ])}
            />
          </div>
          <div className={classes.multiColumnPie}>
            <StyledPieGraph
              title={t("pie_title_repair")}
              callData={dailyBarGraphResult.repair_qty_48h}
            />
            <StyledBarGraphRepair
              title={t("bar_title_repair")}
              callData={JSON.stringify([
                dailyBarGraphResult.repair_qty.bar_qty,
                dailyBarGraphResult.repair_qty.bar_bo,
              ])}
            />
            <StyledPieGraph
              title={t("pie_title_repair")}
              callData={dailyBarGraphResult.repair_waranty_charge}
            />
          </div>
          <div className={classes.multiColumnPie}>
            <StyledPieGraph
              title={t("pie_title_task_for_qa")}
              callData={dailyBarGraphResult.task_for_qa}
            />
            <StyledBarGraphQA
              title={t("chart_component_waiting")}
              callData={JSON.stringify([
                dailyBarGraphResult.bar_task_for_qa.bar_task_qa,
              ])}
            />
            <StyledBarGraphGoodsOut
              title={t("pie_title_goods_out")}
              callData={JSON.stringify([
                dailyBarGraphResult.goods_out.bar_goods_out_detail,
                dailyBarGraphResult.goods_out.bar_outstanding_goods_out,
              ])}
            />
          </div>
        </div>
      )}
      {btnClickType === "monthly_chart" && (
        <div className={classes.pageWrapper}>
          <div className={classes.multiColumnBar}>
            <StyledBarGraphVertical
              title={t("bar_title_rapir_time")}
              callData={monthlyBarGraphResult.bar_monthly}
            />
            <StyledBarGraph
              title={t("bar_title_repair48h")}
              callData={monthlyBarGraphResult.bar_within_48h}
            />
          </div>
        </div>
      )}
      {btnClickType === "technician" && (
        <div className={classes.pageWrapper}>
          <div>
            <Technician data={technicianResult} />
          </div>
        </div>
      )}
      {btnClickType === "model" && (
        <div className={classes.pageWrapper}>
          <div>
            <Model data={modelResult} />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
