export const customerPoNoShowFlag = (list: any) => {
  return list.order_source === "DZO";
};
export const discardChageDisabled = (
  flag: any,
  tableData: any,
  orderHeader: any, customerPoNoAuthFlg: any
) => {
  if (flag) {
    flag = !(
      customerPoNoAuthFlg &&
      tableData.customer_po_no !== orderHeader.customer_po_no
    );
  }
  return flag;
};
