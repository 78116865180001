import React from "react";
// component
import { TechnicianListResultTableThead } from "./Jobs.TechnicianListResult.Table.Thead";
import { TechnicianListResultTableTbody } from "./Jobs.TechnicianListResult.Table.Tbody";
import { TechnicianListResultTableTfoot } from "./Jobs.TechnicianListResult.Table.Tfoot";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

export const JobsTechnicialListResultTable = (props: any) => {
  const classes = useStyles();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? "desc" : "asc");
    props.setOrderBy(property);
    props.setClickedSortHeader(true);
  };

  return (
    <Paper>
      <TableContainer
        className={classes.groupContainer}
        id={"tableContainer_technicianList"}
      >
        <Table stickyHeader>
          <TechnicianListResultTableThead
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={handleRequestSort}
          />
          <TechnicianListResultTableTbody
            data={props.data}
            technicianCode={props.technicianCode}
            order={props.order}
            orderBy={props.orderBy}
          />
          <TechnicianListResultTableTfoot data={props.data} />
        </Table>
      </TableContainer>
    </Paper>
  );
};
