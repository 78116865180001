import React from "react";

// UI
import { useStyles } from "../hooks/useStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const WagonNo = (props: any) => {
  const classes = useStyles();
  const {
    error,
    errorMessage,
    wagonNumberValicationCheck,
    receiveButtonDisabledFlg,
    changeData,
    location_no,
    handleReceiveEvent,
  } = props;
  return (
    <tr>
      <td className={classes.titleCell}>
        <p>
          {props.text}
          <span className={classes.mustMark}>*</span>
        </p>
      </td>
      <td className={classes.postionCell}>
        <TextField
          variant="outlined"
          error={error}
          helperText={errorMessage}
          value={location_no}
          className={classes.textField}
          onChange={(e) =>
            changeData("location_no", e.target.value.substr(0, 5))
          }
          onBlur={wagonNumberValicationCheck}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              wagonNumberValicationCheck();
            }
          }}
        />
        <Button
          className={classes.receiveButton}
          variant="contained"
          color="primary"
          component="span"
          disabled={receiveButtonDisabledFlg}
          onClick={handleReceiveEvent}
        >
          {props.btnReceive}
        </Button>
      </td>
    </tr>
  );
};
