import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      padding: "10px 20px",
      borderBottom: "1px solid #CFCFCF",
    },
    item: {
      paddingRight: "10px",
    },
  })
);
export const JobsTechnicialListResultTitle = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.jobs" });

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={props.handleReload}
        >
          {t("btn_reload")}
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          startIcon={<LibraryBooksIcon />}
          onClick={props.handleTechnicianList}
        >
          {t("btn_job_list")}
        </Button>
      </div>
    </div>
  );
};
