import { http, upload, download } from "./Http";

// AT0401
export const GetUploadSignedUrl = (media_name: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/doculdl/upload/${affiliate_cd}/${media_name}`,
    config: config,
  };
  return http(param);
};

// AT0402
export const GetDownloadSignedUrl = (job_no: any, sub_no: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/doculdl/download/${affiliate_cd}/${job_no}/${sub_no}`,
    config: config,
  };
  return http(param);
};

export const GetUploadFile = (url: any, data: any) => {
  return upload(url, data);
};

export const GetDownloadFile = (url: any) => {
  return download(url);
};

export const GetAppFile = (config: any) => {
  const { baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/doculdl/download/mobile_app`,
    config: config,
  };
  return http(param);
};
