import React, { useCallback } from "react";
// route
import { useHistory } from "react-router-dom";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete";
// methods
import {
  isEmptyCheck,
  emailValidation,
  checkMaxLength,
  checkTypeNumber,
} from "../../../modules/hooks/UseValication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "0 20px 15px 20px",
    },
    area: {
      display: "flex",
      alignItems: "center",
    },
    item: {
      "&:not(:last-child)": {
        marginRight: "10px"
      },
      "& span": {
        marginRight: "5px",
      },
    },
    input: {
      display: "none",
    }
  })
);

export const DraftsSearchControlButton = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useLocale({ defaultPath: "modules.components.drafts" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });

  const handlePromotionList = () => {
    const path = "/private/promotion";
    handleOnClick(path);
  };

  const handleOnClick = useCallback((path: string) => history.push(path), [
    history,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.area}>
        {props.numSelected > 0 && (
          <>
            <div className={classes.item}>
              <span>{props.numSelected}</span>
              {t("txt_PromotionsSelected")}
            </div>
            <div className={classes.item}>
              <Button
                data-testid="btn_Delete" 
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={props.deletePromotionAlert}
              >
                {t("btn_Delete")}
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={classes.area}>
        <div className={classes.item}>
          <Button variant="contained"  data-testid="return_to_promotion_list" onClick={handlePromotionList}>
            {t("return_to_promotion_list")}
          </Button>
        </div>
      </div>
    </div>
  );
};
