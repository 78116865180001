import { http } from "./Http";

// AM2201
export const GetReadPriceAPIforDZ = (query_parameters: any, config: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/prices/dz/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

// AM2202
export const GetReadPriceAPIforSimulation = (
  query_parameters: any,
  config: any
) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/prices/sim/${affiliate_cd}?${query_parameters}`,
    //url: `${baseURLShop}/v1/prices/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

// AM2202
export const GetReadPriceAPIforClaim = (query_parameters: any, config: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/prices/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
