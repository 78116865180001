import React from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getAPIErrorMessage } from "../../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
    divWidth: {
      width: "500px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
  })
);

export const ClaimErrorMessageBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "models.model_window",
  });

  // pre_approve_flag_error:Pre approve is on and cannot be modified
  // pre_approve_status_error:Status can only be entered or re entered to be updated
  const contents = (
    <div className={classes.divWidth}>
      <p className={classes.message}>
        {props.errMessageList.length > 0 &&
          props.errMessageList.map((item: any) => (
            <span>
              {item.claim_no}&nbsp;
              {t(
                item.error_type === "1"
                  ? "pre_approve_flag_error"
                  : "pre_approve_status_error"
              )}
              <br />
            </span>
          ))}
      </p>
    </div>
  );

  const windownButton = (
    <div className={classes.buttonControl}>
      <Button
        className={classes.buttonWidth}
        variant="contained"
        onClick={props.handleClose}
        color="primary"
      >
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_error"
      contents={contents}
      button={windownButton}
      openFlg={props.open}
      handleCloseEvent={props.handleClose}
    />
  );
};
