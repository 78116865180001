import { getSignByCSV } from "../../../modules/common.methods";

export const semicolon = ["MD"];

export const getSign = (config: any) => {
  return getSignByCSV(config.affiliate_cd);
};

export const doQuotationMarks = (item: any)=>{
  let str = String(item);
  return str.replace(/[\"]/g, "\"\"");
}

export const getCSVTitleInfo = (t: any, tabValue: any) => {
  let _title: any;
  if (tabValue === 3) {
    _title = {
      technician_code: t("technician_code"),
      technician_name: t("technician_name"),
      service_center: t("service_center"),
      working_days: t("working_days"),
      goods_in: t("goods_in"),
      task_for_qa: t("task_for_qa"),
      job_entry: t("job_entry"),
      worked_tool_qty: t("worked_tool_qty"),
      waranty_repair: t("waranty_repair"),
      charge_repair: t("charge_repair"),
      inspection_for_quote: t("inspection_for_quote"),
      acc: t("acc"),
      avr_repair_time: t("avr_repair_time"),
      vs_total_avr: t("vs_total_avr"),
    };
  } else if (tabValue === 4) {
    _title = {
      model: t("model"),
      total_repair: t("total_repair"),
      waranty_repair: t("waranty_repair"),
      charge_repair: t("charge_repair"),
      inspection_for_quote: t("inspection_for_quote"),
      percent: t("percent"),
      avr_repair_time: t("avr_repair_time"),
      top_used_parts: t("top_used_parts"),
      description: t("description"),
    };
  }
  return _title;
};

export const getCSVTitle = (sign: any, t: any, tabValue: any) => {
  let str = "";

  const _title: any = getCSVTitleInfo(t, tabValue);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item] !== "") {
      if (itemIndex < titleSize) {
        str += _title[item] + sign;
      } else {
        str += _title[item];
      }
    }
  }
  str += "\n";
  return str;
};

// get CSV contents
export const getCSVContents = (
  t: any,
  data: any,
  config: any,
  tabValue: any
) => {
  let str = "";
  const sign = getSign(config);
  const _title = getCSVTitleInfo(t, tabValue);
  str += getCSVTitle(sign, t, tabValue);
  data.forEach((item: any) => {
    for (let key in _title) {
      let v = item[key] === null || item[key] === undefined ? "" : item[key];
      if (tabValue === 3) {
        if (
          key === "technician_code" ||
          key === "technician_name" ||
          key === "service_center" ||
          key === "working_days" ||
          key === "goods_in" ||
          key === "task_for_qa" ||
          key === "job_entry" ||
          key === "worked_tool_qty" ||
          key === "waranty_repair" ||
          key === "charge_repair" ||
          key === "inspection_for_quote" ||
          key === "acc" ||
          key === "avr_repair_time" ||
          key === "vs_total_avr"
        ) {
          if (!v) {
            v = "";
          }
          str += v + sign;
        }
      } else if (tabValue === 4) {
        if (
          key === "model" ||
          key === "total_repair" ||
          key === "waranty_repair" ||
          key === "charge_repair" ||
          key === "inspection_for_quote" ||
          key === "percent" ||
          key === "avr_repair_time" ||
          key === "top_used_parts"         
        ) {
          if (!v) {
            v = "";
          }
          
        }
        if (key === "description") {
          v = doQuotationMarks(v);
          v = "\"" + v + "\"";
        }
        str += v + sign;
      }
    }

    str += "\n";
  });
  return str;
};
