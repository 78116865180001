import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      minHeight: "48px",
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "8px",
        paddingBottom: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        verticalAlign: "top",
      },
      "&:last-child td": {
        borderBottom: "none",
      },
    },
    checkbox: {
      minHeight: "42px",
    },
    discountRate: {
      display: "flex",
      "& .MuiInputBase-root": {
        marginRight: "5px",
      },
    },
    unit: {
      marginTop: "5px",
    },
    plannerCode: {
      width: "100%",
    },
    item_no: {
      width: "140px",
      margin: "0 auto",
    },
    unitPrice: {
      textAlign: "right",
      marginTop: "5px",
    },
    quantity: {
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "5px !important",
      },
    },
  })
);

export const PromotionDetailConditionsSingleTableTbody = (props: any) => {
  const classes = useStyles();

  const errorData = props.errorValueList.condition_single;
  const type = "condition_single";

  return (
    <TableBody>
      {props.data.condition_single.map((row: any, index: number) => {
        const errorFlg1 =
          errorData &&
          errorData[index] &&
          errorData[index].quantity_error !== "";
        const errorFlg2 =
          errorData &&
          errorData[index] &&
          errorData[index].item_no_error !== "";
        return (
          <TableRow className={classes.tableRow} hover={true} key={index}>
            <TableCell>{row.item_class}</TableCell>
            <TableCell>{row.discount_code}</TableCell>
            <TableCell>
              <div className={classes.item_no} data-testid={"condition_single_item_no"+index}>
                <Autocomplete
                  id="item_no"
                  freeSolo
                  disableClearable
                  options={props.categorySingle}
                  getOptionLabel={props.getOptionLabel2}
                  filterOptions={props.filterOptions2}
                  inputValue={props.data.condition_single[index].item_no}
                  value={props.data.condition_single[index].item_no}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        whiteSpace: "nowrap",
                        wordBreak: "keep-all",
                        minWidth: "200px",
                      }}
                      placement="bottom-start"
                    />
                  )}
                  getOptionSelected={(option: any, value: any) =>
                    option.item_no.toString().toUpperCase() ===
                    props.data.condition_single[index].item_no
                      .toString()
                      .toUpperCase()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      onBlur={() => {
                        props.onBlurOfItemNo(type, index);
                      }}
                      onFocus={() => props.onFocusOfSingleItem(index)}
                      error={errorFlg2}
                      helperText={
                        errorFlg2 ? errorData[index].item_no_error : ""
                      }
                    />
                  )}
                  onChange={(event: object, value: any, reason: string) => {
                    if (reason === "select-option") {
                      props.setSingleOrSetValue(
                        "item_no",
                        value.item_no,
                        index,
                        type
                      );
                    }
                  }}
                  onInputChange={(
                    event: object,
                    value: string,
                    reason: string
                  ) => {
                    if (reason === "input" || reason === "clear") {
                      const val = value.substr(0, 35);
                      props.setSingleOrSetValue("item_no", val, index, type);
                    }
                  }}
                  disabled={props.reviewFlg}
                />
              </div>
            </TableCell>
            <TableCell  data-testid={"condition_single_description"+index}>{row.description}</TableCell>
            <TableCell>
              <div className={classes.quantity} data-testid={"condition_single_quantity"+index}>
                <TextField
                  variant="outlined"
                  value={row.quantity}
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^\d]/g, "")
                      .substr(0, 9);
                    props.setSingleOrSetValue("quantity", value, index, type);
                  }}
                  onBlur={(e) =>
                    props.changeDataListen(
                      "single_quantity",
                      e.target.value,
                      index
                    )
                  }
                  error={errorFlg1}
                  helperText={errorFlg1 ? errorData[index].quantity_error : ""}
                  disabled={props.reviewFlg}
                />
              </div>
            </TableCell>

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac1") && (
                <TableCell>{row.isac1}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac2") && (
                <TableCell>{row.isac2}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac3") && (
                <TableCell>{row.isac3}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac4") && (
                <TableCell>{row.isac4}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac5") && (
                <TableCell>{row.isac5}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac6") && (
                <TableCell>{row.isac6}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac7") && (
                <TableCell>{row.isac7}</TableCell>
              )}

            {props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes("isac8") && (
                <TableCell>{row.isac8}</TableCell>
              )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
