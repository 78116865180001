// api
import api from "../../modules/api/Api";
// methods
import { getApiResultStatus } from "../../modules/common.methods";

// - button click event
export const decreaseCount = (props: any) => {
  const count = props.labelsField;
  if (count - 1 >= 0) {
    props.changeData("labelsField", Number(count) - 1);
  }
};

// + button click event
export const increaseCount = (props: any) => {
  const count = props.labelsField;
  if (count <= 9) {
    props.changeData("labelsField", Number(count) + 1);
  }
};

// add button click event
export const addBtnDisableFlg = (props: any) => {
  return !(Boolean(props.job_no) && props.repair_media.length < 10);
};

// add button click event
export const printDisableFlg = (props: any) => {
  return !Boolean(props.job_no);
};

export const CheckFlg = (value: any, error: any) => {
  return value && !error;
};

export const getJonNoInfo = (config: any) => {
  // get service_center_id
  // AM0108
  return api
    .getReadUserInfoAPIForUserinfoRepair(config)
    .then(({ code, response }: { code: any; response: any }) => {
      if (getApiResultStatus(code)) {
        // fix value
        // job_type = 1(Console)
        // status = 200(Outbound transit )
        const param = {
          service_center_id: response.service_center,
          job_type: 1,
          status: "200",
        };
        // get job_no value
        // AT0101
        return api
          .createRepairJobAPI(param, config)
          .then(({ code, response }: { code: any; response: any }) => {
            if (getApiResultStatus(code)) {
              return {
                successFlg: true,
                data: response,
              };
            } else {
              return {
                successFlg: false,
                data: response,
              };
            }
          });
      } else {
        return {
          successFlg: false,
          data: response,
        };
      }
    });
};

export const getReceiveParams = (data: any, repairNote: any, diffData: any, userInfoRepair: any) => {
  console.log("getReceiveParams", diffData);
  return {
    tracking_no_pickup: !diffData.tracking_no_pickup
      ? data.tracking_no_pickup
      : undefined,
    repair_note:
      repairNote.updated_date && !diffData.note
        ? [
          {
            note: repairNote.note,
            sub_no: repairNote.sub_no,
            note_type: repairNote.note_type,
            updated_date: repairNote.updated_date,
          },
        ]
        : undefined,
    accessory_attached: !diffData.accessory_attached
      ? data.accessory_attached
      : undefined,
    location_no: data.location_no,
    service_center_id: data.service_center_id === userInfoRepair.service_center ? data.service_center_id : userInfoRepair.service_center,
    updated_date: data.updated_date,
    status: "300",
  };
};

export const getWagonList = () => {
  return {
    title: "",
    sub_no: "",
    list: [],
  };
};

export const getDeleteData = () => {
  return {
    item: "",
    subTitle: "",
    wangonNumber: "",
  };
};

export const getRepairNoteDefaultData = () => {
  return {
    note_type: "",
    note: "",
    sub_no: "",
    updated_date: "",
  };
};

export const getFormDefaultData = () => {
  return {
    job_no: "",
    tracking_no_pickup: "",
    photo: "",
    repair_media: [],
    repair_note: [],
    accessory_attached: false,
    labelsField: "4",
    location_no: "",
    jobNoFlg: false,
    service_center_id: "",
  };
};

export const getResponseData = () => {
  return {
    repair_note: [],
    tracking_no_pickup: "",
    accessory_attached: false,
    location_no: "",
  };
};

export const getRepairListByStatus = (status: any) => {
  return status === "100" || status === "200";
};

export const getJobTypeByNumber = (job_type: number) => {
  return job_type === 2;
};

export const getMediaByNumber = (media_type: number) => {
  return media_type === 2;
};

export const getNoteByNumber = (note_type: number) => {
  return note_type === 10;
};

export const getJobTypeByBoolean = (type: string) => {
  return type === "DZ" ? 2 : 1;
};

export const getDiffContents = () => {
  return {
    note: true,
    tracking_no_pickup: true,
    accessory_attached: true,
  };
};
