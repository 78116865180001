import React from "react";
// components
import { JobsTechnicialListResultTitle } from "./Jobs.TechnicianListResult.Title";
import { JobsTechnicialListResultTable } from "./Jobs.TechnicianListResult.Table";

export const JobsTechnicialListResult = (props: any) => {
  return (
    <>
      <JobsTechnicialListResultTitle
        handleReload={props.handleReload}
        handleTechnicianList={props.handleTechnicianList}
        order={props.order}
        setOrder={props.setOrder}
        orderBy={props.orderBy}
        setOrderBy={props.setOrderBy}
      />
      <JobsTechnicialListResultTable
        data={props.technicianAggregateInfo}
        technicianCode={props.technicianCode}
        order={props.order}
        setOrder={props.setOrder}
        orderBy={props.orderBy}
        setOrderBy={props.setOrderBy}
        isClickedSortHeader={props.isClickedSortHeader}
        setClickedSortHeader={props.setClickedSortHeader}
      />
    </>
  );
};
