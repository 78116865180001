import { http } from "./Http";

// AM1005
export const GetPrinterDetailListAPI = (config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/printers/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
