import React, { FC, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { useHistory } from "react-router-dom";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
import { Sum } from "../constants/common.constants";
import {
  WebSocketEventLocationModel,
  WebSocketEventNotificationModel,
} from "../models/WebSocketEventModel";
import { useLocale } from "./UseLocale";
import { useCommonInfo } from "./CommonInfoProvider";
// api
// import api from "../../modules/api/Api";
// import { getApiResultStatus } from "../../modules/common.methods";

export interface NotificationContextType {
  pushNotification: WebSocketEventNotificationModel;
  setPushNotification: (
    pushNotification: WebSocketEventNotificationModel
  ) => void;
  numberOfNotificationMessages: number;
  setNumberOfNotificationMessages: (
    numberOfNotificationMessages: number
  ) => void;
  locationControl: WebSocketEventLocationModel[];
  setLocationControl: (locationControl: WebSocketEventLocationModel[]) => void;
  numberOfLocationControl: number;
  setNumberOfLocationControl: (numberOfLocationControl: number) => void;
  numberOfPopupControl: number;
  setNumberOfPopupControl: (numberOfPopupControl: number) => void;
}

const initialNotificationContext = {
  pushNotification: {},
  setPushNotification: (
    pushNotification: WebSocketEventNotificationModel
  ) => {},
  numberOfNotificationMessages: 0,
  setNumberOfNotificationMessages: (numberOfNotificationMessages: number) => {},
  locationControl: [],
  setLocationControl: (locationControl: WebSocketEventLocationModel[]) => {},
  numberOfLocationControl: 0,
  setNumberOfLocationControl: (numberOfLocationControl: number) => {},
  numberOfPopupControl: 0,
  setNumberOfPopupControl: (numberOfPopupControl: number) => {},
};

export const NotificationContext = createContext<NotificationContextType>(
  initialNotificationContext
);
export const useNotitificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider: FC = ({ children }) => {
  const context: NotificationContextType = useContext(NotificationContext);
  const { t } = useLocale({ defaultPath: "models.job" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const [pushNotification, setPushNotification] = useState(
    context.pushNotification
  );
  const [numberOfNotificationMessages, setNumberOfNotificationMessages] =
    useState(context.numberOfNotificationMessages);
  const [locationControl, setLocationControl] = useState(
    context.locationControl
  );
  const [numberOfLocationControl, setNumberOfLocationControl] = useState(
    context.numberOfLocationControl
  );
  const [numberOfPopupControl, setNumberOfPopupControl] = useState(
    context.numberOfPopupControl
  );
  const history = useHistory();
  const { getUserInfoRepair, getCommonConfig, setUserInfoRepair } =
    useCommonInfo();
  // let userInfoRepair = getUserInfoRepair() as any;
  // const config = getCommonConfig() as any;
  // alert
  // const [alertMessageList, setAlertMessageList] = useState([]) as any;

  const notificationContext: NotificationContextType = {
    pushNotification,
    setPushNotification,
    numberOfNotificationMessages,
    setNumberOfNotificationMessages,
    locationControl,
    setLocationControl,
    numberOfLocationControl,
    setNumberOfLocationControl,
    numberOfPopupControl,
    setNumberOfPopupControl,
  };

  // const errorMessage = (response: any) => {
  //   const message = com(response.message_id, {
  //     param1: "API." + t(response.key),
  //   });
  //   const obj: any = {
  //     no: alertMessageList.length,
  //     message: message,
  //     alertType: "error",
  //   };
  //   const newList: any = alertMessageList.slice(0);
  //   newList.push(obj);
  //   setAlertMessageList(newList);
  // };

  useEffect(() => {
    if (pushNotification.job_no !== undefined) {
      let notify = new Notification(pushNotification.message!, {
        body: `${t("jobNo")}${pushNotification.job_no}`,
        icon: "/favicon.ico",
      });
      notify.onclick = (event) => {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        history.push(
          `${GetPrivateAppRoutePath(PathKey.JOBSDETAIL)}?jobno=${
            pushNotification.job_no
          }`
        );
      };
    }
  }, [pushNotification]);

  return (
    <NotificationContext.Provider value={notificationContext}>
      {children}
    </NotificationContext.Provider>
  );
};
