import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM0701
export const CreateServiceCenterMasterAPI = (data: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const url = `${baseURL}/v1/service_centers/${affiliate_cd}`;
  const param = {
    token: token,
    url: url,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0703
export const UpdateServiceCenterMasterAPI = (
  service_center_id: any,
  data: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const url = `${baseURL}/v1/service_centers/${affiliate_cd}/${service_center_id}`;
  const param = {
    token: token,
    url: url,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0704
export const DeleteServiceCenterMasterAPI = (
  service_center_id: any,
  data: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/service_centers/${affiliate_cd}/${service_center_id}`,
    method: methods.delete,
    data: data,
    config: config,
  };
  return http(param);
};

// AM0705
export const GetServiceCenterDetailListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/service_centers/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
