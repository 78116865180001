import { createStyles, Drawer, Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../hooks/UseLocale";
import { useHistory } from "react-router-dom";
import { UseDrawerStyles } from "./Navigation.Drawer.Style";
import { NavigationDrawerNotificationItem } from "./Navigation.Drawer.Notification.Item";
import { NavigationDrawerNotificationConfirmationItem } from "./Navigation.Drawer.Notification.Confirmation.Item";
import {
  RepairNotificationMessage,
  WebSocketEventNotificationModel,
} from "../../../models/WebSocketEventModel";
import api from "../../../../modules/api/Api";
import { useCommonInfo } from "../../../hooks/CommonInfoProvider";
import { getApiResultStatus } from "../../../common.methods";
import { useNotitificationContext } from "../../../hooks/NotificationContext";
import { NavigationDrawerNotificationPopup } from "./Navigation.Drawer.Notification.Popup";

type Prop = {
  isDrawerOpen: boolean;
  onToggleNotificationDrawerEvent: () => void;
};

let repairNotificationMessageList: RepairNotificationMessage[] = [];

export const NavigationDrawerNotification: FC<Prop> = ({
  isDrawerOpen,
  onToggleNotificationDrawerEvent,
}) => {
  const { t } = useLocale({
    defaultPath: "modules.components.navigationDrawer",
  });
  const classes = UseDrawerStyles();
  const [registerResponse, setRegisterResponse] = useState([]);
  const { getCommonConfig } = useCommonInfo();
  let config = getCommonConfig() as any;
  // const [startFlag, setStartFlag] = useState(false);
  const {
    pushNotification,
    setNumberOfNotificationMessages,
    numberOfPopupControl,
  } = useNotitificationContext();

  useEffect(() => {
    // config.repairShowFlag Repair部分が表示・非表示用フラグ
    if (config.token !== undefined && config.repairShowFlag) {
      getRepairNotificationMessageList().then(
        (data: RepairNotificationMessage[]) => {
          repairNotificationMessageList = data;
          let num = data.filter((f) => !f.checked).length;
          // User doesn't accept Notification.
          if (window.Notification && Notification.permission === "default") {
            // Count up for notification confirmation.
            num = num + 1;
          }
          setNumberOfNotificationMessages(num);
        }
      );
    }
  }, [config, pushNotification, numberOfPopupControl]);

  const getRepairNotificationMessageList = () => {
    return api
      .getRepairNotificationMessageListAPI(config)
      .then(({ code, response }: { code: any; response: any }) => {
        let list = [];
        if (getApiResultStatus(code)) {
          if (response && response.length > 0) {
            list = response.slice(0);
            setRegisterResponse(list);
          }
        } else {
          if (code !== 404) {
            // errorMessage(response);
          }
        }
        return list;
      });
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor={"right"}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerNotificationOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
          [classes.drawerNotificationClose]: !isDrawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerNotificationOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
            [classes.drawerNotificationClose]: !isDrawerOpen,
          }),
        }}
      >
        {/* Margin for Toolbar */}
        <div className={classes.toolbar}></div>
        <NavigationDrawerNotificationConfirmationItem />
        {repairNotificationMessageList.length > 0 ? (
          repairNotificationMessageList.map((m, i) => {
            return (
              <NavigationDrawerNotificationItem
                key={i}
                checked={m.checked}
                job_no={m.job_no}
                updated_date={m.updated_date}
                message={m.message}
                notification_id={m.notification_id}
                config={config}
                data_type={m.data_type}
                message_type={m.message_type}
                onToggleNotificationDrawerEvent={
                  onToggleNotificationDrawerEvent
                }
              />
            );
          })
        ) : (
          <div className={classes.blank}>{t("txtNoNewNotifications")}</div>
        )}
      </Drawer>
      {/* <NavigationDrawerNotificationPopup config={config} /> */}
    </>
  );
};
