import React from "react";
// component
import { TextInput } from "../../../modules/components/textInput/TextInput";
// hook
import { useStyles } from "../hooks/PartsDrawing.styles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// date
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
import {
  priceInputBySign,
  priceOutputByCountryCode,
  decimalPointIsLenth,
} from "../../../modules/components/numberFormat/NumberFormat.Currency";
import { symbolByCurrency } from "../../../modules/components/numberFormat/currenicies";
import { determineDataType } from "../../../modules/common.methods";
export const JobsDetailPartsDrawingRegisterTbody = (props: any) => {
  const classes = useStyles();
  const {
    data,
    setRegisterData,
    selected,
    setRegisterDataProperty,
    registerRowClick,
    alternateItemList,
    status,
    orderQtyAuthDisable,
    setRegisterProjectBlur,
    priceDisabled,
  } = props;
  const isSelected = (name: number) => selected.indexOf(name) !== -1;
  const getAltemativeItem = (AltemativeItem: any) => {
    if (AltemativeItem !== "") {
      if (Array.isArray(alternateItemList)) {
        const list = alternateItemList.filter(
          (item: any) => AltemativeItem === Number(item.code)
        );
        return list.length > 0 ? list[0].name : alternateItemList[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getEstimatedArrivalDate = (newdate: any) => {
    const d: any = newdate ? new Date(newdate) : null;
    return d ? date(d.getTime()) : "";
  };

  const changePrice = (value: any, index: any) => {
    let list = [...data];
    list[index].net_price = priceInputBySign(value);
    list[index].net_price_format = value;
    setRegisterData(list);
    setRegisterDataProperty("net_price", priceInputBySign(value), index);
  };

  return (
    <TableBody>
      {determineDataType(data) &&
        data.map((row: any, index: number) => {
          const isItemSelected = isSelected(Number(index));
          return (
            <TableRow
              className={classes.registerTbody}
              hover
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
            >
              <TableCell
                padding="checkbox"
                className={classes.registerCheckbox}
              >
                <Checkbox
                  checked={isItemSelected}
                  className={classes.checkboxArea}
                  onChange={() => registerRowClick(index)}
                />
              </TableCell>
              <TableCell className={classes.registerCellId}>
                {index + 1}
              </TableCell>
              <TableCell className={classes.nowrapCell}>
                {row.item_no}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell className={classes.Qty}>
                <TextInput
                  value={row.order_qty ? row.order_qty : ""}
                  className={classes.registerTextField}
                  disabled={orderQtyAuthDisable}
                  onChange={(e: any) => {
                    let value = e.target.value;
                    setRegisterDataProperty("order_qty", value, index);
                  }}
                  onBlur={(e: any) => {
                    setRegisterProjectBlur(row, index);
                  }}
                />
              </TableCell>
              <TableCell className={classes.AllocatedQtyTd}>
                {row.allocated_qty}
              </TableCell>
              <TableCell className={classes.BOQty}>{row.bo_qty}</TableCell>
              <TableCell className={classes.estimatedArrivalDate}>
                {getEstimatedArrivalDate(row.estimated_arrival_date)}
              </TableCell>
              <TableCell className={classes.AltemativeItem}>
                {getAltemativeItem(row.alternate_item)}
              </TableCell>
              <TableCell className={classes.price}>
                <TextInput
                  value={row.net_price_format ? row.net_price_format : ""}
                  disabled={priceDisabled}
                  className={classes.registerTextField}
                  onChange={(e: any) => {
                    let value = "";
                    let _price: any = priceInputBySign(e.target.value);
                    if (
                      !isNaN(_price) &&
                      _price >= 0 &&
                      decimalPointIsLenth(e.target.value)
                    ) {
                      value = e.target.value;
                    } else {
                      value = row.net_price_format;
                    }
                    changePrice(value, index);
                  }}
                  onBlur={(e: any) => {
                    setRegisterProjectBlur(row, index);
                  }}
                />
              </TableCell>
              <TableCell className={classes.currency}>
                {symbolByCurrency(row.currency)}
              </TableCell>
              <TableCell className={classes.amount}>
                {priceOutputByCountryCode(row.amount)}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
