export default class Permissions {
  _permissionsGroup: any;
  _permissionsList: any;
  _status: any;
  _quoteResult: any;

  //receiving
  _receiving_authFlg: boolean = false;

  //jobs
  //job Allocate
  _job_allocation_authFlg: boolean = false;
  //job Register
  _job_register_authFlg: boolean = false;
  //Job List Download
  _job_list_download_authFlg: boolean = false;

  //Jobdetail General Tab
  _jobdetail_general_authFlg: boolean = false;
  //Customer No.
  _customer_no_authFlg: boolean = false;
  //Customer PO No.
  _customer_po_no_authFlg: boolean = false;
  //Location No.
  _location_no_authFlg: boolean = false;
  //Received Date
  _received_date_authFlg: boolean = false;
  //Technician Code
  _technician_cd_authFlg: boolean = false;
  //Accessory attached
  _accessory_attached_authFlg: boolean = false;
  //Model No.
  _model_no_authFlg: boolean = false;
  //Serial No.
  _serial_no_authFlg: boolean = false;
  //Fault Description
  _fault_description_authFlg: boolean = false;
  //Repair Option
  _repair_option_authFlg: boolean = false;
  //Warranty Approved By
  _warranty_approved_by_authFlg: boolean = false;
  //Limit Amount
  _limit_amount_authFlg: boolean = false;
  //Reply Date
  _reply_date_authFlg: boolean = false;
  //Quote Result
  _quote_result_authFlg: boolean = false;
  //Trade-In Item No.
  _trade_in_item_no_authFlg: boolean = false;
  //Trade-In approve
  _trade_in_approve_authFlg: boolean = false;
  //Trade-In reference
  _trade_in_reference_authFlg: boolean = false;
  //Partial Repair Memo
  _partial_repair_memo_authFlg: boolean = false;
  //Save Button
  _save_btn_authFlg: boolean = false;
  //Delete Job Button
  _delete_job_btn_authFlg: boolean = false;
  //Print Button
  _print_btn_authFlg: boolean = false;
  //Resend Button
  _resend_btn_authFlg: boolean = false;
  //Upload Button
  _upload_authFlg: boolean = false;
  //Download Icon
  _download_authFlg: boolean = false;
  //Delete Media Icon
  _delete_media_authFlg: boolean = false;

  //Customer Info Tab
  _customer_info_authFlg: boolean = false;

  //Jobdetail Parts&Drawing Tab
  _jobdetail_parts_and_drawing_authFlg: boolean = false;
  //Add Item
  _add_item_authFlg: boolean = false;
  //Delete Item
  _delete_item_authFlg: boolean = false;
  //Register button
  _register_authFlg: boolean = false;
  //Item No Input
  _item_no_authFlg: boolean = false;
  //Order Qty Input
  _order_qty_authFlg: boolean = false;
  //Price  Input
  _price_authFlg: boolean = false;
  //End User memo
  _endUser_memo_authFlg: boolean = false;
  //Register Button
  _register_btn_authFlg: boolean = false;
  //Repair Compelete Button
  _repair_compelete_btn_authFlg: boolean = false;
  //Repair Job Detail Compelete Button
  _repair_job_detailcompelete_btn_authFlg: boolean = false;

  //Memo Tab
  //Internal memo Input
  _internal_memo_authFlg: boolean = false;
  //Send Button
  _send_btn_authFlg: boolean = false;
  //Edit Icon
  _edit_memo_authFlg: boolean = false;
  //Delete Icon
  _delete_memo_authFlg: boolean = false;

  //location control
  _location_control_authFlg: boolean = false;

  //Master Maintenance
  //User Master Console
  _user_master_consoleFlg: boolean = false;
  //User Master DZ
  _user_master_DZFlg: boolean = false;
  //Customer Memorandum Master
  _customer_memorandum_masterFlg: boolean = false;
  //Item Master
  _item_master: boolean = false;
  //Role Master
  _role_masterFlg: boolean = false;
  //Service Center Master
  _service_senter_masterFlg: boolean = false;
  //Printer Master
  _printer_masterFlg: boolean = false;
  //Repair Destination Master
  _repair_destination_masterFlg: boolean = false;

  //Report
  _report_authFlg: boolean = false;

  //Takumi Mobile
  _takumi_mobile_authFlg: boolean = false;

  // Dashboard
  // System Message Editor
  _system_message_editor: boolean = false;
  // Repair Business Message Editor
  _repair_business_message_editor: boolean = false;
  // CSD Buisiness Message Editor
  _csd_business_message_editor: boolean = false;

  // Promotion Show
  _csd_promotion_show_authFlg: boolean = false;

  // Promotion List
  // Search
  _csd_promotion_list_search_authFlg: boolean = false;
  // Clear
  _csd_promotion_list_clear_authFlg: boolean = false;
  // Download
  _csd_promotion_list_download_authFlg: boolean = false;
  // Import
  _csd_promotion_list_import_authFlg: boolean = false;
  // Create New
  _csd_promotion_list_create_new_authFlg: boolean = false;
  // Delete
  _csd_promotion_list_delete_authFlg: boolean = false;
  // View Detail
  _csd_promotion_list_view_detail_authFlg: boolean = false;

  // Promotion Create
  // Go to Drafts
  _csd_promotion_create_go_to_drafts_authFlg: boolean = false;
  // Save to Drafts
  _csd_promotion_create_save_to_drafts_authFlg: boolean = false;
  // Register
  _csd_promotion_create_register_authFlg: boolean = false;

  // Promotion Detail
  // Refresh
  _csd_promotion_detail_refresh_authFlg: boolean = false;
  // Delete
  _csd_promotion_detail_delete_authFlg: boolean = false;
  // Save Changes
  _csd_promotion_detail_save_changes_authFlg: boolean = false;

  // Drafts
  // Search
  _csd_drafts_search_authFlg: boolean = false;
  // Clear
  _csd_drafts_clear_authFlg: boolean = false;

  // Order Show
  _csd_order_show_authFlg: boolean = false;

  // Order List
  // Search
  _csd_order_list_search_authFlg: boolean = false;
  // View Detail
  _csd_order_list_view_detail_authFlg: boolean = false;

  // Order Detail
  // Edit Qty
  _csd_order_detail_edit_qty_authFlg: boolean = false;
  // Edit Net Price
  _csd_order_detail_edit_net_price_authFlg: boolean = false;
  // Submit
  _csd_order_detail_submit_authFlg: boolean = false;

  // Simulate
  // Simulate Show Flg
  _csd_simulation_show_authFlg: boolean = false;
  // Add Item
  _csd_simulation_add_item_authFlg: boolean = false;
  // Delete Item
  _csd_simulation_delete_item_authFlg: boolean = false;
  // Clear
  _csd_simulation_clear_authFlg: boolean = false;
  // Simulate
  _csd_simulation_simulate_authFlg: boolean = false;
  // View Promo Detail
  _csd_simulation_view_promo_detail_authFlg: boolean = false;

  // WCO
  // Claim Update Flg
  _wco_claim_update_authFlg: boolean = false;
  // Claim Inquiry Flg
  _wco_claim_inquiry_authFlg: boolean = false;
  // Credit Note Flg
  _wco_claim_note_authFlg: boolean = false;
  // Labor Master Flg
  _wco_labor_master_authFlg: boolean = false;
  // Claim Approve Flg
  _wco_claim_approve_authFlg: boolean = false;
  // Claim Pre-Approve Flg
  _wco_claim_pre_approve_authFlg: boolean = false;
  // Claim Buisiness Message Editor
  _wco_business_message_editor: boolean = false;



  //set permission
  setPermissionsInfo(userPermissions: any, status?: any, quoteResult?: any) {
    console.log("userPermissions:", userPermissions);
    for (let i = 0; i < userPermissions.length; i++) {
      console.log("userPermissions.length:", userPermissions.length);
      this._permissionsGroup = userPermissions[i].permission_group;
      this._permissionsList = userPermissions[i].permission_id;
      this._status = status;
      this._quoteResult = quoteResult;
      if (
        this._permissionsGroup !== undefined &&
        this._permissionsList !== undefined &&
        this._permissionsList.length > 0
      ) {
        if (this._permissionsGroup == 1) {
          //receiving
          if (this._permissionsList.indexOf(1) >= 0) {
            this._receiving_authFlg = true;
          }

          //Job Allocation
          if (this._permissionsList.indexOf(2) >= 0) {
            this._job_allocation_authFlg = true;
          }

          //Jobs - Register
          if (this._permissionsList.indexOf(3) >= 0) {
            this._job_register_authFlg = true;
          }

          //Job List Download
          if (this._permissionsList.indexOf(24) >= 0) {
            this._job_list_download_authFlg = true;
          }

          //Job Detail - General
          if (this._permissionsList.indexOf(4) >= 0) {
            this._jobdetail_general_authFlg = true;
            this._customer_no_authFlg = true;
            this._customer_po_no_authFlg = true;
            this._location_no_authFlg = true;
            this._received_date_authFlg = true;
            this._technician_cd_authFlg = true;
            this._accessory_attached_authFlg = true;
            this._model_no_authFlg = true;
            this._serial_no_authFlg = true;
            this._fault_description_authFlg = true;
            this._repair_option_authFlg = true;
            this._warranty_approved_by_authFlg = true;
            this._limit_amount_authFlg = true;
            this._reply_date_authFlg = true;
            this._quote_result_authFlg = true;
            this._trade_in_item_no_authFlg = true;
            this._trade_in_approve_authFlg = true;
            this._trade_in_reference_authFlg = true;
            this._partial_repair_memo_authFlg = true;
            this._save_btn_authFlg = true;
            this._resend_btn_authFlg = true;
            this._upload_authFlg = true;
            this._delete_media_authFlg = true;
            this._internal_memo_authFlg = true;
            this._send_btn_authFlg = true;
            this._edit_memo_authFlg = true;
            if (this._status === "100" || this._status === "200") {
              this._warranty_approved_by_authFlg = false;
              this._reply_date_authFlg = false;
              this._quote_result_authFlg = false;
              this._trade_in_item_no_authFlg = false;
              this._trade_in_approve_authFlg = false;
              this._trade_in_reference_authFlg = false;
              this._upload_authFlg = false;
              this._resend_btn_authFlg = false;
            } //Received
            else if (this._status === "300") {
              this._reply_date_authFlg = false;
              this._quote_result_authFlg = false;
              this._trade_in_item_no_authFlg = false;
              this._trade_in_approve_authFlg = false;
              this._trade_in_reference_authFlg = false;
              this._resend_btn_authFlg = false;
            } //Repair Start
            else if (this._status === "400") {
              this._customer_no_authFlg = false;
              this._customer_po_no_authFlg = false;
              this._accessory_attached_authFlg = false;
              this._limit_amount_authFlg = false;
              this._reply_date_authFlg = false;
              this._quote_result_authFlg = false;
              this._trade_in_item_no_authFlg = false;
              this._trade_in_approve_authFlg = false;
              this._trade_in_reference_authFlg = false;
              this._resend_btn_authFlg = false;
              this._repair_option_authFlg = false;
            } //Waiting for B/O
            else if (this._status === "410") {
              this._customer_no_authFlg = false;
              this._customer_po_no_authFlg = false;
              this._accessory_attached_authFlg = false;
              this._limit_amount_authFlg = false;
              this._reply_date_authFlg = false;
              this._quote_result_authFlg = false;
              this._trade_in_item_no_authFlg = false;
              this._trade_in_approve_authFlg = false;
              this._trade_in_reference_authFlg = false;
              this._resend_btn_authFlg = false;
              this._repair_option_authFlg = false;
            } //Quotation Open
            else if (this._status === "500") {
              this._customer_no_authFlg = false;
              this._limit_amount_authFlg = false;
              this._repair_option_authFlg = false;
            } //Quotation Answered
            else if (this._status === "510") {
              this._customer_no_authFlg = false;
              this._customer_po_no_authFlg = false;
              this._model_no_authFlg = false;
              this._serial_no_authFlg = false;
              this._repair_option_authFlg = false;
              this._limit_amount_authFlg = false;
            } //Repair End
            else if (this._status === "600") {
              this._customer_no_authFlg = false;
              this._customer_po_no_authFlg = false;
              this._received_date_authFlg = false;
              this._technician_cd_authFlg = false;
              this._accessory_attached_authFlg = false;
              this._model_no_authFlg = false;
              this._serial_no_authFlg = false;
              this._fault_description_authFlg = false;
              this._repair_option_authFlg = false;
              this._warranty_approved_by_authFlg = false;
              this._limit_amount_authFlg = false;
              this._reply_date_authFlg = false;
              this._quote_result_authFlg = false;
              this._trade_in_item_no_authFlg = false;
              this._trade_in_approve_authFlg = false;
              this._trade_in_reference_authFlg = false;
              this._partial_repair_memo_authFlg = false;
              this._resend_btn_authFlg = false;
            } //Return Transit or Complete
            else if (this._status === "700" || this._status === "800") {
              this._customer_no_authFlg = false;
              this._customer_po_no_authFlg = false;
              this._location_no_authFlg = false;
              this._received_date_authFlg = false;
              this._technician_cd_authFlg = false;
              this._accessory_attached_authFlg = false;
              this._model_no_authFlg = false;
              this._serial_no_authFlg = false;
              this._fault_description_authFlg = false;
              this._repair_option_authFlg = false;
              this._warranty_approved_by_authFlg = false;
              this._limit_amount_authFlg = false;
              this._reply_date_authFlg = false;
              this._quote_result_authFlg = false;
              this._trade_in_item_no_authFlg = false;
              this._trade_in_approve_authFlg = false;
              this._trade_in_reference_authFlg = false;
              this._partial_repair_memo_authFlg = false;
              this._resend_btn_authFlg = false;
              this._save_btn_authFlg = false;
            }
          }
          //Job Detail - Parts&Drawing
          if (this._permissionsList.indexOf(5) >= 0) {
            this._jobdetail_parts_and_drawing_authFlg = true;
            this._item_no_authFlg = true;
            this._order_qty_authFlg = true;
            this._endUser_memo_authFlg = true;
            this._register_btn_authFlg = true;
            this._repair_compelete_btn_authFlg = true;
            this._add_item_authFlg = true;
            this._delete_item_authFlg = true;
            //Waiting for transit or Outbound transit or Repair End or Return Transit or Complete
            if (
              this._status === "100" ||
              this._status === "200" ||
              this._status === "600" ||
              this._status === "700" ||
              this._status === "800"
            ) {
              this._item_no_authFlg = false;
              this._order_qty_authFlg = false;
              this._endUser_memo_authFlg = false;
              this._register_btn_authFlg = false;
              this._repair_compelete_btn_authFlg = false;
              this._add_item_authFlg = false;
              this._delete_item_authFlg = false;
            } //Received or Waiting for B/O or Quotation Open or Quotation Answered
            else if (
              this._status === "300" ||
              this._status === "410" ||
              this._status === "500" ||
              this._status === "510"
            ) {
              this._endUser_memo_authFlg = false;
              this._repair_compelete_btn_authFlg = false;
            } //quote_result is 1 and 7 register button is activity
            if (
              this._status === "510" &&
              this._quoteResult !== 1 &&
              this._quoteResult !== 7
            ) {
              this._register_authFlg = true;
            }
          }
          //Job Detail - Customer Info
          if (this._permissionsList.indexOf(6) >= 0) {
            this._customer_info_authFlg = true;
            //Repair End or Return Transit or Complete
            if (
              this._status === "600" ||
              this._status === "700" ||
              this._status === "800"
            ) {
              this._customer_info_authFlg = false;
            }
          }
          //Job Detail – Memo Delete
          if (this._permissionsList.indexOf(7) >= 0) {
            this._delete_memo_authFlg = true;
          }
          //Job Detail – Update Price
          if (
            this._permissionsList.indexOf(8) >= 0 &&
            (this._status === "400" ||
              (this._status === "510" && this._quoteResult === 7))
          ) {
            this._price_authFlg = true;
          }
          //Job Detail – Delete
          if (this._permissionsList.indexOf(9) >= 0) {
            this._delete_job_btn_authFlg = true;
            //Return Transit or Complete
            if (
              this._status === "600" ||
              this._status === "700" ||
              this._status === "800"
            ) {
              this._delete_job_btn_authFlg = false;
            }
          }
          //location control
          if (this._permissionsList.indexOf(10) >= 0) {
            this._location_control_authFlg = true;
          }
          //Dashboard - Memorandum for Repair
          if (this._permissionsList.indexOf(11) >= 0) {
            this._repair_business_message_editor = true;
          }
          //Master Maintenance
          if (this._permissionsList.indexOf(12) >= 0) {
            this._role_masterFlg = true;
          }
          if (this._permissionsList.indexOf(13) >= 0) {
            this._service_senter_masterFlg = true;
          }
          if (this._permissionsList.indexOf(14) >= 0) {
            this._customer_memorandum_masterFlg = true;
          }
          if (this._permissionsList.indexOf(15) >= 0) {
            this._item_master = true;
          }
          if (this._permissionsList.indexOf(16) >= 0) {
            this._printer_masterFlg = true;
          }
          if (this._permissionsList.indexOf(17) >= 0) {
            this._user_master_consoleFlg = true;
          }
          if (this._permissionsList.indexOf(18) >= 0) {
            this._user_master_DZFlg = true;
          }
          if (this._permissionsList.indexOf(19) >= 0) {
            this._repair_destination_masterFlg = true;
          }
          // Dashboard - Memorandum for System
          if (this._permissionsList.indexOf(20) >= 0) {
            this._system_message_editor = true;
          }
          if (this._permissionsList.indexOf(21) >= 0) {
            this._report_authFlg = true;
          }
          if (this._permissionsList.indexOf(22) >= 0) {
            this._takumi_mobile_authFlg = true;
          }
          if (this._permissionsList.indexOf(23) >= 0) {
            if (
              this._status === "300" ||
              this._status === "400" ||
              this._status === "410" ||
              this._status === "500" ||
              this._status === "510" ||
              this._status === "600" ||
              this._status === "700"
            ) {
              this._repair_job_detailcompelete_btn_authFlg = true;
            }
          }
        }
        if (this._permissionsGroup == 2) {
          if (this._permissionsList.indexOf(1) >= 0) {
            this._user_master_DZFlg = true;
          }
          if (this._permissionsList.indexOf(2) >= 0) {
            this._csd_business_message_editor = true;
          }
          if (this._permissionsList.indexOf(3) >= 0) {
            this._csd_promotion_show_authFlg = true;
            this._csd_promotion_list_search_authFlg = true;
            this._csd_promotion_list_clear_authFlg = true;
            this._csd_promotion_list_download_authFlg = true;
            this._csd_promotion_list_view_detail_authFlg = true;
            this._csd_promotion_detail_refresh_authFlg = true;
          }
          if (this._permissionsList.indexOf(4) >= 0) {
            this._csd_promotion_list_import_authFlg = true;
            this._csd_promotion_list_create_new_authFlg = true;
            this._csd_promotion_create_go_to_drafts_authFlg = true;
            this._csd_promotion_create_save_to_drafts_authFlg = true;
            this._csd_promotion_create_register_authFlg = true;
            this._csd_drafts_search_authFlg = true;
            this._csd_drafts_clear_authFlg = true;
          }
          if (this._permissionsList.indexOf(5) >= 0) {
            this._csd_promotion_detail_save_changes_authFlg = true;
          }
          if (this._permissionsList.indexOf(6) >= 0) {
            this._csd_promotion_list_delete_authFlg = true;
            this._csd_promotion_detail_delete_authFlg = true;
          }
          if (this._permissionsList.indexOf(7) >= 0) {
            this._csd_simulation_add_item_authFlg = true;
            this._csd_simulation_delete_item_authFlg = true;
            this._csd_simulation_clear_authFlg = true;
            this._csd_simulation_simulate_authFlg = true;
            this._csd_simulation_view_promo_detail_authFlg = true;
          }
          if (this._permissionsList.indexOf(8) >= 0) {
            this._csd_order_show_authFlg = true;
            this._csd_order_list_search_authFlg = true;
            this._csd_order_list_view_detail_authFlg = true;
          }
          if (this._permissionsList.indexOf(9) >= 0) {
            this._csd_order_detail_edit_qty_authFlg = true;
            this._csd_order_detail_edit_net_price_authFlg = true;
            this._csd_order_detail_submit_authFlg = true;
          }
        }
        if (this._permissionsGroup == 3) {
          if (this._permissionsList.indexOf(1) >= 0) {
            this._wco_claim_update_authFlg = true;
          }
          if (this._permissionsList.indexOf(2) >= 0) {
            this._wco_claim_inquiry_authFlg = true;
          }
          if (this._permissionsList.indexOf(3) >= 0) {
            this._wco_claim_note_authFlg = true;
          }
          if (this._permissionsList.indexOf(4) >= 0) {
            this._wco_labor_master_authFlg = true;
          }
          if (this._permissionsList.indexOf(5) >= 0) {
            this._wco_claim_approve_authFlg = true;
          } 
          if (this._permissionsList.indexOf(6) >= 0) {
            this._wco_claim_pre_approve_authFlg = true;
          } 
          if (this._permissionsList.indexOf(7) >= 0) {
            this._wco_business_message_editor = true;
          }                   
        }
      }

      //Default-User
      //Print Button
      if (
        this._status === "100" ||
        this._status === "200" ||
        this._status === "300" ||
        this._status === "400" ||
        this._status === "410" ||
        this._status === "500" ||
        this._status === "510"
      ) {
        this._print_btn_authFlg = true;
      }
      //Download Icon
      if (
        this._status === "100" ||
        this._status === "200" ||
        this._status === "300" ||
        this._status === "400" ||
        this._status === "410" ||
        this._status === "500" ||
        this._status === "510" ||
        this._status === "600" ||
        this._status === "700" ||
        this._status === "800"
      ) {
        this._download_authFlg = true;
      }
    }
  }
  //get permision
  getPermissionsInfo() {
    return {
      receiving_authFlg: this._receiving_authFlg,
      job_allocation_authFlg: this._job_allocation_authFlg,
      job_register_authFlg: this._job_register_authFlg,
      job_list_download_authFlg: this._job_list_download_authFlg,
      jobdetail_general_authFlg: this._jobdetail_general_authFlg,
      customer_no_authFlg: this._customer_no_authFlg,
      customer_po_no_authFlg: this._customer_po_no_authFlg,
      location_no_authFlg: this._location_no_authFlg,
      received_date_authFlg: this._received_date_authFlg,
      technician_cd_authFlg: this._technician_cd_authFlg,
      accessory_attached_authFlg: this._accessory_attached_authFlg,
      model_no_authFlg: this._model_no_authFlg,
      serial_no_authFlg: this._serial_no_authFlg,
      fault_description_authFlg: this._fault_description_authFlg,
      repair_option_authFlg: this._repair_option_authFlg,
      warranty_approved_by_authFlg: this._warranty_approved_by_authFlg,
      limit_amount_authFlg: this._limit_amount_authFlg,
      reply_date_authFlg: this._reply_date_authFlg,
      quote_result_authFlg: this._quote_result_authFlg,
      trade_in_item_no_authFlg: this._trade_in_item_no_authFlg,
      trade_in_approve_authFlg: this._trade_in_approve_authFlg,
      trade_in_reference_authFlg: this._trade_in_reference_authFlg,
      partial_repair_memo_authFlg: this._partial_repair_memo_authFlg,
      save_btn_authFlg: this._save_btn_authFlg,
      jobdetail_parts_and_drawing_authFlg:
        this._jobdetail_parts_and_drawing_authFlg,
      delete_job_btn_authFlg: this._delete_job_btn_authFlg,
      location_control_authFlg: this._location_control_authFlg,
      print_btn_authFlg: this._print_btn_authFlg,
      resend_btn_authFlg: this._resend_btn_authFlg,
      upload_authFlg: this._upload_authFlg,
      download_authFlg: this._download_authFlg,
      delete_media_authFlg: this._delete_media_authFlg,
      customer_info_authFlg: this._customer_info_authFlg,
      add_item_authFlg: this._add_item_authFlg,
      delete_item_authFlg: this._delete_item_authFlg,
      register_authFlg: this._register_authFlg,
      item_no_authFlg: this._item_no_authFlg,
      order_qty_authFlg: this._order_qty_authFlg,
      price_authFlg: this._price_authFlg,
      endUser_memo_authFlg: this._endUser_memo_authFlg,
      register_btn_authFlg: this._register_btn_authFlg,
      repair_compelete_btn_authFlg: this._repair_compelete_btn_authFlg,
      repair_job_detailcompelete_btn_authFlg: this._repair_job_detailcompelete_btn_authFlg,
      internal_memo_authFlg: this._internal_memo_authFlg,
      send_btn_authFlg: this._send_btn_authFlg,
      edit_memo_authFlg: this._edit_memo_authFlg,
      delete_memo_authFlg: this._delete_memo_authFlg,
      user_master_consoleFlg: this._user_master_consoleFlg,
      user_master_DZFlg: this._user_master_DZFlg,
      customer_memorandum_masterFlg: this._customer_memorandum_masterFlg,
      item_master: this._item_master,
      role_masterFlg: this._role_masterFlg,
      service_senter_masterFlg: this._service_senter_masterFlg,
      printer_masterFlg: this._printer_masterFlg,
      repair_destination_masterFlg: this._repair_destination_masterFlg,
      report_authFlg: this._report_authFlg,
      takumi_mobile_authFlg: this._takumi_mobile_authFlg,
      system_message_editor: this._system_message_editor,
      repair_business_message_editor: this._repair_business_message_editor,
      csd_business_message_editor: this._csd_business_message_editor,
      csd_promotion_show_authFlg: this._csd_promotion_show_authFlg,
      csd_promotion_list_search_authFlg:
        this._csd_promotion_list_search_authFlg,
      csd_promotion_list_clear_authFlg: this._csd_promotion_list_clear_authFlg,
      csd_promotion_list_download_authFlg:
        this._csd_promotion_list_download_authFlg,
      csd_promotion_list_import_authFlg:
        this._csd_promotion_list_import_authFlg,
      csd_promotion_list_create_new_authFlg:
        this._csd_promotion_list_create_new_authFlg,
      csd_promotion_list_delete_authFlg:
        this._csd_promotion_list_delete_authFlg,
      csd_promotion_list_view_detail_authFlg:
        this._csd_promotion_list_view_detail_authFlg,
      csd_promotion_create_go_to_drafts_authFlg:
        this._csd_promotion_create_go_to_drafts_authFlg,
      csd_promotion_create_save_to_drafts_authFlg:
        this._csd_promotion_create_save_to_drafts_authFlg,
      csd_promotion_create_register_authFlg:
        this._csd_promotion_create_register_authFlg,
      csd_promotion_detail_refresh_authFlg:
        this._csd_promotion_detail_refresh_authFlg,
      csd_promotion_detail_delete_authFlg:
        this._csd_promotion_detail_delete_authFlg,
      csd_promotion_detail_save_changes_authFlg:
        this._csd_promotion_detail_save_changes_authFlg,
      csd_drafts_search_authFlg: this._csd_drafts_search_authFlg,
      csd_drafts_clear_authFlg: this._csd_drafts_clear_authFlg,
      csd_order_show_authFlg: this._csd_order_show_authFlg,
      csd_order_list_search_authFlg: this._csd_order_list_search_authFlg,
      csd_order_list_view_detail_authFlg:
        this._csd_order_list_view_detail_authFlg,
      csd_order_detail_edit_qty_authFlg:
        this._csd_order_detail_edit_qty_authFlg,
      csd_order_detail_edit_net_price_authFlg:
        this._csd_order_detail_edit_net_price_authFlg,
      csd_order_detail_submit_authFlg: this._csd_order_detail_submit_authFlg,
      csd_simulation_add_item_authFlg: this._csd_simulation_add_item_authFlg,
      csd_simulation_delete_item_authFlg:
        this._csd_simulation_delete_item_authFlg,
      csd_simulation_clear_authFlg: this._csd_simulation_clear_authFlg,
      csd_simulation_simulate_authFlg: this._csd_simulation_simulate_authFlg,
      csd_simulation_view_promo_detail_authFlg:
        this._csd_simulation_view_promo_detail_authFlg,
      wco_claim_update_authFlg: this._wco_claim_update_authFlg,
      wco_claim_inquiry_authFlg: this._wco_claim_inquiry_authFlg,
      wco_claim_note_authFlg: this._wco_claim_note_authFlg,
      wco_labor_master_authFlg: this._wco_labor_master_authFlg,
      wco_claim_approve_authFlg: this._wco_claim_approve_authFlg,
      wco_claim_pre_approve_authFlg: this._wco_claim_pre_approve_authFlg,
      wco_business_message_editor: this._wco_business_message_editor,
    };
  }
}
