import React, { FC, useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AlertProps } from '@material-ui/lab/Alert'
import { Alert } from './Alert';
import { useLocale } from '../../hooks/UseLocale';


type Props = {
  open: boolean;
  severity: AlertProps["severity"];
  messageId?: string;
}

export const AlertBox: FC<Props> = ({
  open = false,
  severity = "success",
  messageId = "MS0015",
}) => {
  const [openFlag, setOpenFlag] = useState(true);
  const { t } = useLocale({ defaultPath: "common.constants.messages" });

  useEffect(() => {
    setOpenFlag(open);
  }, [])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setOpenFlag(false);
  };
  return (
    <Snackbar
      open={openFlag}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        severity={severity}
        onClose={handleClose}
      >
        {t(messageId)}
      </Alert>
    </Snackbar>
  );
};
