import React from "react";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// root
import { Link } from "react-router-dom";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// route
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
// methods
import { getCodeNameByNumber } from "../../../modules/common.methods";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";

type Order = "asc" | "desc";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "& td:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& td": {
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
  })
);

export const PromotionSearchResultPaymentTermTableTbody = (props: any) => {
  const { pageInfo, selected, order, orderBy } = props;
  const classes = useStyles();
  const isSelected = (name: string) => selected.indexOf(name) > -1;
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });

  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);

        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]" ||
            orderBys[0] === "promo_no" ||
            orderBys[0] === "customer_no" ||
            orderBys[0] === "special_price"
          ) {
            const aValue = Number(aValues[0]);
            const bValue = Number(bValues[0]);
            if (bValues === null && aValues !== null) {
              sort = -1;
            } else if (bValue !== null && aValue === null) {
              sort = 1;
            } else if (bValue < aValue) {
              sort = -1;
            } else if (bValue > aValue) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          const isItemSelected = isSelected(row.promo_no);
          const path =
            GetPrivateAppRoutePath(PathKey.PROMOTIONDETAIL) +
            `?promotionNo=${row.promo_no}`;
          return (
            <TableRow
              className={classes.tableRow}
              hover
              onClick={(event) => props.handleClick(event, row)}
              tabIndex={-1}
              key={row.promo_no}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isItemSelected} />
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                <Link to={path}>{row.promo_no}</Link>
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.promo_name}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.start_date || row.start_date === ""
                  ? ""
                  : date(new Date(row.start_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.end_date ||
                row.end_date === "" ||
                row.end_date.indexOf("9999") > -1
                  ? ""
                  : date(new Date(row.end_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.promotion_status
                  ? getCodeNameByNumber(
                      row.promotion_status,
                      com("status_option")
                    )
                  : ""}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_type === ""
                  ? ""
                  : row.customer_type_more_flg === "1"
                  ? `${row.customer_type},${t("others")}`
                  : row.customer_type}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_no === ""
                  ? ""
                  : row.customer_no_more_flg === "1"
                  ? `${row.customer_no},${t("others")}`
                  : row.customer_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_name === ""
                  ? ""
                  : row.customer_name_more_flg === "1"
                  ? `${row.customer_name},${t("others")}`
                  : row.customer_name}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_discount_code === ""
                  ? ""
                  : row.customer_discount_code_more_flg === "1"
                  ? `${row.customer_discount_code},${t("others")}`
                  : row.customer_discount_code}
              </TableCell>
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac1") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac1 === ""
                      ? ""
                      : row.csac1_more_flg === "1"
                      ? `${row.csac1},${t("others")}`
                      : row.csac1}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac2") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac2 === ""
                      ? ""
                      : row.csac2_more_flg === "1"
                      ? `${row.csac2},${t("others")}`
                      : row.csac2}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac3") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac3 === ""
                      ? ""
                      : row.csac3_more_flg === "1"
                      ? `${row.csac3},${t("others")}`
                      : row.csac3}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac4") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac4 === ""
                      ? ""
                      : row.csac4_more_flg === "1"
                      ? `${row.csac4},${t("others")}`
                      : row.csac4}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac5") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac5 === ""
                      ? ""
                      : row.csac5_more_flg === "1"
                      ? `${row.csac5},${t("others")}`
                      : row.csac5}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac6") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac6 === ""
                      ? ""
                      : row.csac6_more_flg === "1"
                      ? `${row.csac6},${t("others")}`
                      : row.csac6}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac7") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac7 === ""
                      ? ""
                      : row.csac7_more_flg === "1"
                      ? `${row.csac7},${t("others")}`
                      : row.csac7}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac8") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac8 === ""
                      ? ""
                      : row.csac8_more_flg === "1"
                      ? `${row.csac8},${t("others")}`
                      : row.csac8}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac9") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac9 === ""
                      ? ""
                      : row.csac9_more_flg === "1"
                      ? `${row.csac9},${t("others")}`
                      : row.csac9}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("csac10") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.csac10 === ""
                      ? ""
                      : row.csac10_more_flg === "1"
                      ? `${row.csac10},${t("others")}`
                      : row.csac10}
                  </TableCell>
                )}
              {props.salesAnalysisList.customer_list &&
                props.salesAnalysisList.customer_list.length > 0 &&
                props.salesAnalysisList.customer_list.includes("promotion_region") && (
                  <TableCell className={classes.nowrap} align="center">
                    {row.promotion_region === ""
                      ? ""
                      : row.promotion_region_more_flg === "1"
                      ? `${row.promotion_region},${t("others")}`
                      : row.promotion_region}
                  </TableCell>
                )}
              <TableCell className={classes.nowrap} align="center">
                {row.payment_term_code}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                <Link to={path}>{t("go")}</Link>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
