import React, { FC, useEffect, useState } from "react";
// UI
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
import FileSaver from "file-saver";
import {
  getCSVContents,
  getCSVFileName,
  preApproveCheck,
} from "./methods/Claim.Common.Methods";
import { ClaimErrorMessageBox } from "./components/Claim.Error.Message.Box";
import { ClaimCompleteMessageBox } from "./components/Claim.Complete.Message.Box";
// api
import api from "../../modules/api/Api";
// common
import { getApiResultStatus, getAPIErrorInfo } from "../../modules/common.methods";

// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { ClaimSearchControl } from "./components/Claim.SearchControl";
import { ClaimResult } from "./components/Claim.Result";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// route
import { useHistory } from "react-router-dom";
import { PathKey } from "../../pages/AppRoutes";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../modules/hooks/Permissions";

const defaultValue = {
  claim_no: "",
  customer_no: "",
  customer_po_no: "",
  model_no: "",
  select_status: ["200", "210"],
  postal_code: "",
  entry_date_from: null,
  entry_date_to: null,
  approved_date_from: null,
  approved_date_to: null,
  claim_no_error: "",
  customer_no_error: "",
  customer_po_no_error: "",
  model_no_error: "",
  postal_code_error: "",
  entry_date_from_error: "",
  entry_date_to_error: "",
  approved_date_from_error: "",
  approved_date_to_error: "",
  select_pre_approve: "",
};

export const Page: FC = () => {
  const {
    setCommonInfo,
    getCommonInfo,
    setCommonInfoFull,
    getCommonConfig,
    getHistoryRouter,
    setClaimsPageInfo,
    getClaimsPageInfo,
    setClaimsSearchMessage,
    getClaimsSearchMessage,
    getUserPermissions,
    setUserPermissions,
  } = useCommonInfo();

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;
  let config = getCommonConfig() as any;
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({
    defaultPath: "modules.components.claim",
  });
  const commonInfo = getCommonInfo() as any;
  let historyRouter = getHistoryRouter() as any;
  let claimsSearchMessage = getClaimsSearchMessage() as any;
  let pageMessage = getClaimsPageInfo() as any;

  const [initFlg, setInitFlg] = useState(true);
  const [searchFlg, setSearchFlg] = useState(false);
  const [isloadingCompleted, setIsloadingCompleted] = useState(false);
  const [alertMessageList, setAlertMessageList] = useState([]);
  // search value
  const [searchValueList, setSearchValueList] = useState(defaultValue);
  // search result
  const [resultList, setResultList] = useState<any[]>([]);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);

  const [searchClickFlg, setSearchClickFlg] = useState(false);

  // set permission of Claim
  const setPermission = async () => {
    let localUserPermissions = {};
    if (userPermissions.permission_group) {
      localUserPermissions = userPermissions;
    } else {
      await getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
    }

    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const tempUserPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(tempUserPermissionsInfo);
  };

  // init search
  useEffect(() => {
    if (initFlg && config.token) {
      console.log("claim start 2022 test");

      setInitFlg(false);
      apiLoading(true);
      setPermission();
      let masterItemList: any = [];
      let masterItemBaseList: any = [];
      let masterCustomerList: any = [];

      let itemListApi;
      let itemBaseListApi;
      let customerListApi;
      // item master AM0305
      if (!commonInfo.itemList || commonInfo.itemList.length === 0) {
        itemListApi = api
          .getItemDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0305 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterItemList = response;
            }
          });
      } else {
        masterItemList = commonInfo.itemList;
      }

      // get base item master list
      if (!commonInfo.itemBaseList || commonInfo.itemBaseList.length === 0) {
        itemBaseListApi = api
          .getBaseItemListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("base item response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterItemBaseList = response;
            }
          });
      } else {
        masterItemBaseList = commonInfo.itemBaseList;
      }

      // get customer master list
      if (!commonInfo.customerList || commonInfo.customerList.length === 0) {
        customerListApi = api
          .getCustomerDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("get AM0205 customer master: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterCustomerList = response;
            }
          });
      } else {
        masterCustomerList = commonInfo.customerList;
      }

      Promise.all([itemListApi, itemBaseListApi, customerListApi]).then(() => {
        setCommonInfoFull({
          itemList: masterItemList,
          itemBaseList: masterItemBaseList,
          customerList: masterCustomerList,
          loadingFlg: false,
        });
        setIsloadingCompleted(true);
      });
    }
  });

  useEffect(() => {
    let data: any = {};
    if (isloadingCompleted) {
      if (historyRouter.to === String(PathKey.CLAIM)) {
        if (
          historyRouter.from === String(PathKey.CLAIMDETAIL) ||
          historyRouter.from === String(PathKey.SUMMARY)
        ) {
          data = Object.assign({}, defaultValue, claimsSearchMessage);
        } else {
          data = Object.assign({}, defaultValue);
          const obj = Object.assign({}, pageMessage, {
            page: 0,
          });
          setClaimsPageInfo(obj);
        }
        setSearchValueList(data);
        let query_parameters = conditionSearch(data);
        search(data, query_parameters, false);
      }
    }
  }, [historyRouter, isloadingCompleted]);

  // common search method
  const search = (data: any, query_parameters: string, appendErr = false) => {
    // 検索結果のリスト
    setPreApproveDataList([]);
    setResultList([]);
    setSearchFlg(true);
    // Read Claim List AT1705
    api
      .getReadClaimListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1705 response: ", response);
        const searchMessage = Object.assign({}, data);
        setClaimsSearchMessage(searchMessage);
        if (code !== 200) {
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name_wco." + response.key),
            }),
            alertType: "error",
          };
          let alertMessageListNew: any = [obj];
          setAlertMessageList(alertMessageListNew);
          apiLoading(false);
        } else {
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages.MS0049"),
            alertType: "warning",
          };
          if (response.total_num && response.total_num >= 2000) {
            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          }
          let newList = response.claims_list.slice(0);
          if (newList.length > 0) {
            newList.forEach((item: any) => {
              if (!item.currency) {
                item.currency = config.currency;
              }
            });
          }

          // 検索結果のリスト
          setPreApproveDataList(JSON.parse(JSON.stringify(newList)));
          setResultList(JSON.parse(JSON.stringify(newList)));
          apiLoading(false);
        }
      });
  };

  // set search value
  const setSearchValue = (property: any, value: any) => {
    setSearchValueList((preSearchValueList) => {
      return Object.assign({}, preSearchValueList, { [property]: value });
    });
  };

  // search button click event
  const handleSearchEvent = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedArray([]);
    setAlertMessageList([]);
    if (isAllErrMsgmpty()) {
      setSearchClickFlg(true);
      let query_parameters = conditionSearch(searchValueList, false);
      search(searchValueList, query_parameters, false);
    }
  };

  const conditionSearch = (data: any, appendErr = false) => {
    let param_select_status = "";
    let param_entryDateFrom = "";
    let param_entryDateTo = "";
    if (data.select_status.length > 0) {
      for (let index in data.select_status) {
        param_select_status =
          param_select_status + "&status=" + data.select_status[index];
      }
    } else {
      param_select_status = "&status=";
    }
    let entry_date_from = data.entry_date_from;
    if (entry_date_from !== null) {
      param_entryDateFrom = new Date(entry_date_from).toISOString();
    } else {
      param_entryDateFrom = "";
    }

    let entry_date_to = data.entry_date_to;
    if (entry_date_to !== null) {
      param_entryDateTo = new Date(entry_date_to).toISOString();
    } else {
      param_entryDateTo = "";
    }

    let param_approvedDateFrom, param_approvedDateTo;
    let approved_date_from = data.approved_date_from;
    if (approved_date_from !== null) {
      param_approvedDateFrom = new Date(approved_date_from).toISOString();
    } else {
      param_approvedDateFrom = "";
    }

    let approved_date_to = data.approved_date_to;
    if (approved_date_to !== null) {
      param_approvedDateTo = new Date(approved_date_to).toISOString();
    } else {
      param_approvedDateTo = "";
    }

    let query_parameters =
      "claim_no=" +
      encodeURIComponent(data.claim_no) +
      "&customer_no=" +
      encodeURIComponent(data.customer_no) +
      "&customer_po_no=" +
      encodeURIComponent(data.customer_po_no) +
      "&model_no=" +
      encodeURIComponent(data.model_no) +
      param_select_status +
      "&postal_code=" +
      encodeURIComponent(data.postal_code) +
      "&entry_date_from=" +
      encodeURIComponent(param_entryDateFrom) +
      "&entry_date_to=" +
      encodeURIComponent(param_entryDateTo) +
      "&approve_date_from=" +
      encodeURIComponent(param_approvedDateFrom) +
      "&approve_date_to=" +
      encodeURIComponent(param_approvedDateTo) +
      "&pre_approve_flg=" +
      data.select_pre_approve;

    console.log("query_parameters", query_parameters);
    return query_parameters;
  };

  // clear button click event
  const handleClearEvent = () => {
    setSelectedArray([]);
    setAlertMessageList([]);
    const valueList = Object.assign({}, defaultValue, {
      select_status: [],
    });
    setSearchValueList(valueList);
  };

  const downloadCSV = () => {
    setAlertMessageList([]);
    apiLoading(true);
    let query_parameters = conditionSearch(searchValueList, false);
    // AT1707
    api
      .getReadClaimListForCSV(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        if (!getApiResultStatus(code)) {
          apiLoading(false);
          response = getAPIErrorInfo(
            response,
            "getDownloadClaimsByS3singedUrlApi"
          );
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name_wco." + response.key),
            }),
            alertType: "error",
          };

          let alertMessageListNew: any = [obj];
          setAlertMessageList(alertMessageListNew);
        } else {
          console.log("AT1707", code, response);
          const url = response.content;
          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              if (!getApiResultStatus(code)) {
                const obj: any = {
                  no: alertMessageList.length,
                  message: com("messages." + response.message_id, {
                    param1: com("messages_key_name." + response.key),
                  }),
                  alertType: "error",
                };
                apiLoading(false);
                let alertMessageListNew: any = [obj];
                setAlertMessageList(alertMessageListNew);
              } else {
                let reader: any = new FileReader();
                reader.readAsText(response, "UTF-8");
                reader.onload = function () {
                  let data = this.result;
                  setCSV(JSON.parse(data));
                  apiLoading(false);
                };
              }
            });
        }
      });
  };

  const setCSV = (data: any) => {
    const str = getCSVContents(com, t, data, config);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "claimList" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const isAllErrMsgmpty = () => {
    let result = false;
    if (
      searchValueList.claim_no_error === "" &&
      searchValueList.customer_no_error === "" &&
      searchValueList.customer_po_no_error === "" &&
      searchValueList.model_no_error === "" &&
      searchValueList.postal_code_error === "" &&
      searchValueList.entry_date_from_error === "" &&
      searchValueList.approved_date_from_error === ""
    ) {
      result = true;
    }
    return result;
  };

  // 仕様追加：pre-approve一括
  const [preApproveDataList, setPreApproveDataList] = useState([]);
  const [preApproveErrorList, setApproveErrorList] = useState([]);
  const [preApproveModelFlag, setPreApproveModelFlag] = useState(false);
  const [preApproveSuccessList, setPreApproveSuccessList] = useState([]);
  const [preApproveCompleteModelFlag, setPreApproveCompleteModelFlag] =
    useState(false);

  const preApproveAll = () => {
    const param: any = selectedArray;
    if (param.length > 0) {
      const { result, message_list } = preApproveCheck(
        param,
        preApproveDataList
      );
      // API送信
      if (message_list.length === 0) {
        apiLoading(true);
        let error_list: any = [];
        let success_list: any = [];
        preApproveAPICall(0, result, error_list, success_list);
      } else {
        // error 表示
        setApproveErrorList(message_list);
        setPreApproveModelFlag(true);
      }
    }
  };

  const preApproveAPICall = async (
    number: any,
    list: any,
    error_list: any,
    success_list: any
  ) => {
    await api
      .updateClaimAPI(list[number].claim_no, config, list[number])
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          error_list.push({
            claim_no: list[number].claim_no,
            error_message: com("messages." + response.message_id, {
              param1: t(response.key),
            }),
          });
        } else {
          success_list.push(list[number]);
        }
        if (number < list.length - 1) {
          preApproveAPICall(++number, list, error_list, success_list);
        } else {
          // api実行終わり
          apiLoading(false);
          preApproveComplete(error_list, success_list);
        }
      });
  };

  const preApproveComplete = (error_list: any, success_list: any) => {
    setPreApproveSuccessList(success_list);
    setApproveErrorList(error_list);
    setPreApproveCompleteModelFlag(true);
  };

  const handlePreApproveClose = () => {
    setPreApproveModelFlag(false);
  };

  const handlePreApproveCompleteClose = () => {
    setPreApproveCompleteModelFlag(false);
    let query_parameters = conditionSearch(searchValueList);
    search(searchValueList, query_parameters, false);
  };

  const contents = (
    <div id="contents">
      {alertMessageList.length > 0 &&
        alertMessageList.map((item: any) => (
          <AlertMesssageBox
            key={item.no}
            show={item.message}
            message={item.message}
            type={item.alertType}
          />
        ))}
      <ClaimSearchControl
        alertMessageList={alertMessageList}
        setAlertMessageList={setAlertMessageList}
        handleSearchEvent={handleSearchEvent}
        handleClearEvent={handleClearEvent}
        setSearchValueList={setSearchValueList}
        searchValueList={searchValueList}
        setSearchValue={(property: any, value: any) =>
          setSearchValue(property, value)
        }
        conditionSearch={conditionSearch}
        isAllErrMsgmpty={isAllErrMsgmpty}
      />
      <ClaimResult
        data={resultList}
        downloadCSV={downloadCSV}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        isloadingCompleted={isloadingCompleted}
        setSearchClickFlg={setSearchClickFlg}
        searchClickFlg={searchClickFlg}
        searchFlg={searchFlg}
        setSearchFlg={setSearchFlg}
        setClaimsSearchMessage={setClaimsSearchMessage}
        userPermisssionInfo={userPermisssionInfo}
        preApproveAll={preApproveAll}
      />
      <ClaimErrorMessageBox
        open={preApproveModelFlag}
        errMessageList={preApproveErrorList}
        handleClose={handlePreApproveClose}
      />
      <ClaimCompleteMessageBox
        open={preApproveCompleteModelFlag}
        errMessageList={preApproveErrorList}
        SuccessList={preApproveSuccessList}
        handleClose={handlePreApproveCompleteClose}
      />
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
