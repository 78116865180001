import React from "react";
// components
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    buttonControl02: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      padding: "0 20px 15px 20px",
    },
    area: {
      display: "flex",
      alignItems: "center",
    },
    item: {
      "&:not(:last-child)": {
        marginRight: "10px",
      },
      "& span": {
        marginRight: "5px",
      },
    },
    input: {
      display: "none",
    },
    message: {
      margin: 0,
      whiteSpace: "pre",
    },
    title: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    divWidth: {
      width: "400px",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
    errormessageTitle: {
      marginBottom: 0,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    errormessage: {
      margin: 0,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
  })
);

export const PromotionWarningMessageBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const { getName } = useLocale({ defaultPath: "common.constants" });

  const {
    open,
    csvPromoNo,
    csvDuplicatePromoNo,
    webshopNotReleaseItemList,
    csvClosedCustomerNo,
    csvSuspendItemNo,
    handleCannel,
    handleNo,
    handleOK,
    handleAllOK,
    handleClose,
    warningMessageType,
  } = props;

  const contents = (
    <div className={classes.divWidth}>
      {warningMessageType &&
        warningMessageType.length > 0 &&
        warningMessageType.includes("1") && (
          <p>
            {com("MS0094")}
            <br />
            {getName("messages.MS0095", {
              param1: csvPromoNo,
              param2: csvDuplicatePromoNo,
            })}
          </p>
        )}
      {!warningMessageType.includes("1") && (
        <p className={classes.errormessageTitle}>
          {t("promotion_code")}:{csvPromoNo}
        </p>
      )}
      {warningMessageType &&
        warningMessageType.length > 0 &&
        warningMessageType.includes("2") && (
          <div>
            <p className={classes.errormessageTitle}>
              {getName("messages.MS0099")}
            </p>
            <p className={classes.errormessage}>
              {t("item")}:
              {webshopNotReleaseItemList &&
                webshopNotReleaseItemList.length > 0 &&
                webshopNotReleaseItemList.map((item: any, itemIndex: any) => {
                  if (itemIndex === 0) {
                    return item;
                  } else {
                    return <>, {item}</>;
                  }
                })}
            </p>
          </div>
        )}

      {warningMessageType &&
        warningMessageType.length > 0 &&
        warningMessageType.includes("3") && (
          <div>
            <p className={classes.errormessageTitle}>
              {getName("messages.promotion_csv_upload_item_suspended")}
            </p>
            <p className={classes.errormessage}>
              {t("item")}:
              {csvSuspendItemNo &&
                csvSuspendItemNo.length > 0 &&
                csvSuspendItemNo.map((item: any, itemIndex: any) => {
                  if (itemIndex === 0) {
                    return item;
                  } else {
                    return <>, {item}</>;
                  }
                })}
            </p>
          </div>
        )}
      {warningMessageType &&
        warningMessageType.length > 0 &&
        warningMessageType.includes("4") && (
          <div>
            <p className={classes.errormessageTitle}>
              {getName("messages.promotion_csv_upload_Customer_closed")}
            </p>
            <p className={classes.errormessage}>
              {t("promotion_customer_no")}:
              {csvClosedCustomerNo &&
                csvClosedCustomerNo.length > 0 &&
                csvClosedCustomerNo.map((item: any, itemIndex: any) => {
                  if (itemIndex === 0) {
                    return item;
                  } else {
                    return <>, {item}</>;
                  }
                })}
            </p>
          </div>
        )}
    </div>
  );

  const windownButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCannel}>
        {t("cancel")}
      </Button>

      <Button variant="contained" color="secondary" onClick={handleNo}>
        {t("no")}
      </Button>
      <Button variant="contained" onClick={handleOK}>
        {t("ok")}
      </Button>
      <Button variant="contained" onClick={handleAllOK}>
        {t("btn_all_ok")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_attention"
      contents={contents}
      button={windownButton}
      openFlg={open}
      handleCloseEvent={handleClose}
    />
  );
};
