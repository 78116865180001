import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "center"
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& th:last-child": {
        width: "140px"
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowHeader: { 
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "rgba(240, 242, 244)",
      },
    }
  })
);

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "purchase_amount", label: "purchase_amount" },
  { id: "table_discount_rate", label: "table_discount_rate" }
];

export const PromotionDetailConditionsAmountBaseTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox">
          <Checkbox
            data-testid="condition_amount_base_allCheckbox"
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
            disabled={props.reviewFlg}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
          >
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
