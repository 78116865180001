import React from "react";
import clsx from "clsx";
// hooks
import { useStyles } from "../hooks/useStyles";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      "& th": {
        width: "120px",
        minWidth: "auto!important",
      },
    },
    controlSelect: {
      minWidth: "100px",
      "& .MuiInputBase-root": {
        height: "30px",
      },
      "& .MuiSelect-iconOutlined": {
        right: "3px",
      },
      "& .MuiTypography-displayBlock": {
        paddingRight: "27px",
        textOverflow: "ellipsis",
      },
    },
    blankTd: {
      width: "20%",
    },
    limit_amount: {
      "& .MuiInputBase-input": {
        textAlign: "right",
        paddingRight: "10px",
      },
    },
    limit_amount_currency: {
      paddingLeft: "5px",
    },
    componentHidden: {
      display: "none",
    },
    inputArea: {
      width: "100px!important",
      padding: "0px",
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        padding: "0px",
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child": {
        padding: "0px",
        paddingLeft: "5px",
      },
    },
  })
);

export const JobsDetailGeneralRepairOption = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.group_repair_option",
  });

  const {
    formList,
    setFormListValue,
    dataList,
    setFormList,
    formErrorList,
    setFormErrorList,
    onBlurOfWarrantyApprovedBy,
    handleSave,
    userPermisssionInfo,
    repairJobPartsList,
  } = props;

  const {
    getCommonInfo,
    setCommonInfo,
    getHotKey,
    setHotKey,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;

  const onChangeHandle = (value: number) => {
    if (value !== 5) {
      setFormList((prevState: any) => ({
        ...prevState,
        repair_option: value,
        warranty_approved_by: dataList.warranty_approved_by,
        limit_amount: dataList.limit_amount,
        reply_date: dataList.reply_date,
        reply_date_date: dataList.reply_date_date,
        reply_date_time: dataList.reply_date_time,
        quote_result: dataList.quote_result,
        trade_in_item_no: dataList.trade_in_item_no,
        trade_in_approve: dataList.trade_in_approve,
        notePartialRepairMemo: dataList.notePartialRepairMemo,
      }));

      setFormErrorList((prevState: any) => ({
        ...prevState,
        reply_date_date_error: "",
        reply_date_time_error: "",
        warranty_approved_by_error: "",
        limit_amount_error: "",
        trade_in_item_no_error: "",
      }));
    } else {
      setFormList((prevState: any) => ({
        ...prevState,
        repair_option: value,
        warranty_approved_by: dataList.warranty_approved_by,
        limit_amount: dataList.limit_amount,
        reply_date: dataList.reply_date,
        reply_date_date: dataList.reply_date_date,
        reply_date_time: dataList.reply_date_time,
        quote_result: dataList.quote_result,
        trade_in_item_no: dataList.trade_in_item_no,
        trade_in_approve: dataList.trade_in_approve,
        noteFaultDescription: dataList.noteFaultDescription,
      }));

      setFormErrorList((prevState: any) => ({
        ...prevState,
        reply_date_date_error: "",
        reply_date_time_error: "",
        warranty_approved_by_error: "",
        limit_amount_error: "",
        trade_in_item_no_error: "",
      }));
    }
  };

  const getOptionLabel = (option: any) =>
    option.erp_user_id + " : " + option.name || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    // limit: 10,
    stringify: getOptionLabel,
  });

  const getRepairOptionStatus = () => {
    let repairOptionStatus = !userPermisssionInfo.repair_option_authFlg;
    if (formList.status !== undefined && formList.status === "300") {     
      if (repairJobPartsList.length > 0) {
        repairOptionStatus = true;
      }else {
        repairOptionStatus = false;
      }
    }
    else if (formList.status !== undefined && formList.status === "400") {    
      if (repairJobPartsList.length <= 0) {
        repairOptionStatus = false;
      }else {
        repairOptionStatus = true;
      }
    }
    else if (formList.status !== undefined && formList.status === "410") {     
      if (repairJobPartsList.length <= 0) {
        repairOptionStatus = false;
      }else {
        repairOptionStatus = true;
      }
    }
    else if (formList.status !== undefined && formList.status === "500") {     
      if (repairJobPartsList.length <= 0) {
        repairOptionStatus = false;
      }else {
        repairOptionStatus = true;
      }
    } 
    return repairOptionStatus;   
  };

  const getLimitamountStatus = () => {
    if (userPermisssionInfo.limit_amount_authFlg) {
      if (repairJobPartsList.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name")}</h3>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t("repair_option")}</th>
            <td>
              <FormControl
                variant="outlined"
                className={myClasses.controlSelect}
              >
                <Select
                  disabled={getRepairOptionStatus()}
                  id="repair_option"
                  className={classes.RepairOption}
                  value={formList.repair_option}
                  onChange={(e) => onChangeHandle(Number(e.target.value))}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {Array.isArray(com("repair_option")) ? (
                    com("repair_option").map((option: any) => (
                      <MenuItem key={option.code} value={option.code}>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </td>
            <td className={myClasses.blankTd}></td>
          </tr>
          <tr
            className={clsx(
              formList.repair_option !== 1 &&
                formList.repair_option !== 2 &&
                myClasses.componentHidden
            )}
          >
            <th>{t("warranty_approved_by")}</th>
            <td className={classes.vertialAlignTop} colSpan={2}>
              <div className={classes.displayFlexArea}>
                <div className={classes.inputContainer}>
                  <Autocomplete
                    disabled={!userPermisssionInfo.warranty_approved_by_authFlg}
                    className={myClasses.inputArea}
                    id="technician_cd"
                    freeSolo
                    disableClearable
                    options={commonInfo.technicianList}
                    getOptionLabel={getOptionLabel}
                    filterOptions={filterOptions}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          whiteSpace: "nowrap",
                          wordBreak: "keep-all",
                          minWidth: "250px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                    inputValue={formList.warranty_approved_by}
                    value={formList.warranty_approved_by}
                    getOptionSelected={(option: any, value: any) =>
                      option.erp_user_id.toUpperCase() ===
                      formList.warranty_approved_by.toUpperCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        error={formErrorList.warranty_approved_by_error !== ""}
                        helperText={formErrorList.warranty_approved_by_error}
                        onBlur={onBlurOfWarrantyApprovedBy}
                      />
                    )}
                    onChange={(event: object, value: any, reason: string) => {
                      if (reason === "select-option") {
                        setFormListValue(
                          "warranty_approved_by",
                          value.erp_user_id
                        );
                      }
                    }}
                    onInputChange={(
                      event: object,
                      value: string,
                      reason: string
                    ) => {
                      if (reason === "input" || reason === "clear") {
                        setFormListValue(
                          "warranty_approved_by",
                          value.substring(0, 10)
                        );
                      }
                    }}
                  />
                </div>
                <div className={classes.inputNameContainer}>
                  {!formErrorList.warranty_approved_by_error && (
                    <p>{formList.warranty_approved_by_name}</p>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr
            className={clsx(
              formList.repair_option !== 4 && myClasses.componentHidden
            )}
          >
            <th>{t("limit_amount")}</th>
            <td>
              {/* TODO input number decimal 2-digital */}
              <TextField
                disabled={getLimitamountStatus()}
                className={myClasses.limit_amount}
                variant="outlined"
                value={formList.limit_amount}
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^\.\,\d]/g, "")
                    .substr(0, 13);
                  return setFormListValue("limit_amount", value);
                }}
              />
            </td>
            <td className={myClasses.blankTd}>
              {/* TODO currency change to € */}
              <p className={myClasses.limit_amount_currency}>
                {formList.currency}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
