import React, { FC, useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// components
import { JobNo } from "./Receiving.Form.JobNo";
import { TrackingNo } from "./Receiving.Form.TrackingNo";
import { Photo } from "./Receiving.Form.Photo";
import { Note } from "./Receiving.Form.Note";
import { Labels } from "./Receiving.Form.Labels";
import { WagonNo } from "./Receiving.Form.WagonNo";
// UI
import { useStyles } from "../hooks/useStyles";

export const ReceivingForm = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.receiving" });

  const {
    jobNoErrorFlg,
    jobNoErrorMessage,
    formData,
    changeData,
    jobNumberValicationCheck,
    handleRegisterJobEvent,
    jobNoInputDisableFlg,
    jobNoButtonDisableFlg,
    fileUpload,
    photoDelete,
    handlePrintEvent,
    handleReceiveEvent,
    wagonNoErrorFlg,
    wagonNoErrorMessage,
    wagonNumberValicationCheck,
    receiveButtonDisabledFlg,
    repairNote,
    downloadPhoto,
    repairMedia,
  } = props;
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <JobNo
            error={jobNoErrorFlg}
            errorMessage={jobNoErrorMessage}
            job_no={formData.job_no}
            changeData={changeData}
            jobNumberValicationCheck={jobNumberValicationCheck}
            handleRegisterJobEvent={handleRegisterJobEvent}
            text={t("job_no")}
            jobNoInputDisableFlg={jobNoInputDisableFlg}
            jobNoButtonDisableFlg={jobNoButtonDisableFlg}
          />
          <TrackingNo
            tracking_no_pickup={formData.tracking_no_pickup}
            changeData={changeData}
            text={t("txt_TrackingNo")}
          />
          <Photo
            fileUpload={fileUpload}
            job_no={formData.job_no}
            repair_media={repairMedia}
            photoDelete={photoDelete}
            text={t("txt_Photo")}
            btnAdd={t("btn_Add")}
            jobNoErrorFlg={jobNoErrorFlg}
            downloadPhoto={downloadPhoto}
          />
          <Note
            note={repairNote.note}
            text={t("note")}
            textAccessoryAttached={t("txt_Accessory_attached")}
            placeHolder={t("txt_placeHolder")}
            changeData={changeData}
            accessory_attached={formData.accessory_attached}
          />
          <Labels
            labelsField={formData.labelsField}
            job_no={formData.job_no}
            changeData={changeData}
            handlePrintEvent={handlePrintEvent}
            text={t("txt_Labels")}
            jobNoErrorFlg={jobNoErrorFlg}
          />
          <WagonNo
            error={wagonNoErrorFlg}
            errorMessage={wagonNoErrorMessage}
            location_no={formData.location_no}
            changeData={changeData}
            handleReceiveEvent={handleReceiveEvent}
            wagonNumberValicationCheck={wagonNumberValicationCheck}
            text={t("location_no")}
            btnReceive={t("btn_Receive")}
            receiveButtonDisabledFlg={receiveButtonDisabledFlg}
          />
        </tbody>
      </table>
    </form>
  );
};
