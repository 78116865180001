import { currency } from './NumberFormat.Currency';
import { date, time, timeWithoutSecond } from './NumberFormat.Date';

type NumberFormat = {
  currency: (input: number, currency?: string) => string;
  date: (input: number | string) => string;
  time: (input: number | string) => string;
  timeWithoutSecond: (input: number | string) => string;
};

var numberFormat: NumberFormat = {
  currency: currency,
  date: date,
  time: time,
  timeWithoutSecond: timeWithoutSecond,
};

export default numberFormat;
