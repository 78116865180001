import React from "react";
// UI
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Typography from "@material-ui/core/Typography";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// constants
import { STEP_LIST } from "../../constants/claim.constants";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      backgroundColor: "#F9F9F9",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      },
    },
  })
);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#008CA2",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#008CA2",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export const StepperArea = (props: any) => {
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {  claimStatus } = props;

  const getItem = (paramStatus: any) => {
    let result: any = STEP_LIST.find((item: any)=>{
      return item.status === paramStatus;
    });
    if (!result) {
      result = STEP_LIST.find((item)=>{
          return item.status === "0";
      });      
    }
    return result;
  }

  const isStepFailed = (step: any) => {
    return step === 1;
  };

  let item = getItem(claimStatus);

  return (
    <>
      <Stepper
        className={classes.stepper}
        alternativeLabel
        activeStep={item.activeStep}
        connector={<QontoConnector />}
      >
        {item.label.map((label: any, index: any) => {
          const labelProps: any = {};
          if (item.status==="300" && isStepFailed(index)) {
            labelProps.optional = (
            <Typography variant="caption" color="error">
            </Typography>
          );
          
          labelProps.error = true;
        }
          
        return (
          <Step key={index}>
          <StepLabel {...labelProps}>{com("step_type." + label)}</StepLabel>
          </Step>
          );
        })}
      </Stepper>
    </>
  );
};
