import React, { useState, useEffect } from "react";
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    buttonArea: {
      display: "flex",
      justifyContent: "flex-end",
      "& button": {
        marginLeft: "10px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    memoArea: {
      minWidth: "400px",
      margin: "0 auto",
    },
    title: {
      fontWeight: "bold",
      fontSize: "14px",
      margin: "0",
      padding: "0 20px 0 0",
      whiteSpace: "pre",
    },
    multilineTextField: {
      width: "100%",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px!important",
      },
    },
    error: {
      color: "red",
      margin: "0",
    },
  })
);

export const SummaryPopup = (props: any) => {
  const classes = useStyles();
  const {
    declineFlag,
    declineSuccessFlag,
    handleCloseDecline,
    handleCloseDeclineChanges,
    handleCloseDeclineSuccessChanges,
    approveFlag,
    approveSuccessFlag,
    handleCloseApprove,
    handleCloseApproveChanges,
    handleCloseApproveSuccessChanges,
    memoFlag,
    memoMessage,
    changeMemoMessage,
    handleCloseMemoChanges,
    handleCloseMemo,
    memoStatus,
    errorMessage,
  } = props;
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { com } = useLocale({ defaultPath: "models.model_window" });
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  // decline message
  const attentionDeclineChanges = <p>{getName("messages.MS0106")}</p>;
  const attentionDeclineChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseDecline}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseDeclineChanges}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // decline complete message
  const attentionDeclineSuccessChanges = <p>{getName("messages.MS0107")}</p>;
  const attentionDeclineSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseDeclineSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // approve message
  const attentionApproveChanges = <p>{getName("messages.MS0104")}</p>;
  const attentionApproveChangesButton = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseApprove}
      >
        {com("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={handleCloseApproveChanges}>
        {com("prompt_button_Yes")}
      </Button>
    </div>
  );

  // approve complete message
  const attentionApproveSuccessChanges = <p>{getName("messages.MS0105")}</p>;
  const attentionApproveSuccessChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={handleCloseApproveSuccessChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  // memo
  const attentionMemoChanges = (
    <div className={classes.memoArea}>
      <p className={classes.title}>{t("summary_memo")}</p>
      <TextField
        className={classes.multilineTextField}
        variant="outlined"
        multiline
        rows={10}
        value={memoMessage}
        onChange={(e: any) => changeMemoMessage(e.target.value)}
        inputProps={{ maxLength: 2048 }}
      />
      <p className={classes.error}>{errorMessage}</p>
    </div>
  );
  const attentionMemoChangesButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" color="secondary" onClick={handleCloseMemo}>
        {com("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" onClick={handleCloseMemoChanges}>
        {com("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <>
      {declineFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeclineChanges}
          openFlg={declineFlag}
          button={attentionDeclineChangesButton}
          handleCloseEvent={handleCloseDecline}
        />
      )}
      {declineSuccessFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionDeclineSuccessChanges}
          openFlg={declineSuccessFlag}
          button={attentionDeclineSuccessChangesButton}
          handleCloseEvent={handleCloseDeclineSuccessChanges}
        />
      )}
      {approveFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionApproveChanges}
          openFlg={approveFlag}
          button={attentionApproveChangesButton}
          handleCloseEvent={handleCloseApprove}
        />
      )}
      {approveSuccessFlag && (
        <ModelWindow
          title="prompt_title_attention"
          contents={attentionApproveSuccessChanges}
          openFlg={approveSuccessFlag}
          button={attentionApproveSuccessChangesButton}
          handleCloseEvent={handleCloseApproveSuccessChanges}
        />
      )}
      {memoFlag && (
        <ModelWindow
          title={memoStatus === "300" ? "memo_decline" : "memo_approve"}
          contents={attentionMemoChanges}
          openFlg={memoFlag}
          button={attentionMemoChangesButton}
          handleCloseEvent={handleCloseMemo}
        />
      )}
    </>
  );
};
