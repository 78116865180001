import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM1301
export const CreateRoleandRolePermissionAPI = (data: any, config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/role_permissions/${affiliate_cd}`,
    method: methods.post,
    data: data,
    config: config,
  };
  return http(param);
};

// AM1302
export const GetReadRolePermissionListAPI = (
  role_group: any,
  role_id: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/role_permissions/${affiliate_cd}/${role_group}/${role_id}`,
    config: config,
  };
  return http(param);
};

// AM1304
export const UpdatePermissionAPI = (
  data: any,
  role_group: any,
  role_id: any,
  config: any
) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/role_permissions/${affiliate_cd}/${role_group}/${role_id}`,
    method: methods.patch,
    data: data,
    config: config,
  };
  return http(param);
};
