import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
// route
import { useHistory } from "react-router-dom";
// components
import { SearchControlAdvanced } from "./Jobs.SearchControl.Advanced";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/control.useStyles";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
import {
  checkMinLength,
  checkMaxLength,
  checkTypeNumber,
} from "../../../modules/hooks/UseValication";
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";
// UI
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import FormControl from "@material-ui/core/FormControl";
import MinimizeIcon from "@material-ui/icons/Remove";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// common
import {
  getApiResultStatus,
  getAPIErrorInfo,
} from "../../../modules/common.methods";
// api
import api from "../../../modules/api/Api";
import { defaultValue } from "../methods/Jobs.methods";

export const SearchControl = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    getCommonInfo,
    getCommonConfig,
    getUserInfoRepair,
    setJobsSearchMessage,
    getHistoryRouter,
    getJobsSearchMessage,
    getHotKey,
    setHotKey,
    setCommonInfo,
  } = useCommonInfo();
  const commonInfo = getCommonInfo() as any;
  const config = getCommonConfig() as any;
  const userInfoRepair = getUserInfoRepair() as any;

  const { t } = useLocale({ defaultPath: "modules.components.jobs" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const statusList = com("status");
  const quoteResultList = com("quote_result");

  // search value
  let { searchValueList, setSearchValueList } = props;
  const [initFlg, setInitFlg] = useState(true);
  const [jobNoInputFlg, setJobNoInputFlg] = useState(true);
  let historyRouter = getHistoryRouter() as any;
  let searchMessage = getJobsSearchMessage() as any;
  let hotKeyInfo = getHotKey() as any;

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  useEffect(() => {
    let data: any = {};
    if (initFlg && props.isloadingCompleted) {
      if (historyRouter.to === String(PathKey.JOBS)) {
        if (
          history.action === "POP" &&
          historyRouter.from === String(PathKey.JOBSDETAIL)
        ) {
          data = Object.assign({}, defaultValue, searchMessage);
        } else {
          data = Object.assign({}, defaultValue, {
            service_center_id: [userInfoRepair.service_center],
          });
          setJobsSearchMessage({});
        }
        setSearchValueList(data);
        conditionSearch(data);
        setInitFlg(false);
      }
    }
  }, [historyRouter, initFlg, props.isloadingCompleted]);

  useEffect(() => {
    if (hotKeyInfo.handleF8Search) {
      handleSearchEvent();
    }
    setHotKey("handleF8Search", false);
  }, [hotKeyInfo.handleF8Search]);

  const setSearchValue = (property: any, value: any) => {
    setSearchValueList((preSearchValueList: any) => {
      return Object.assign({}, preSearchValueList, { [property]: value });
    });
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchValue("service_center_id", event.target.value);
  };

  // advanced button click event
  const [advancedShowFlg, setAdvancedShowFlg] = useState(true);
  const handleAdvancedEvent = () => {
    setAdvancedShowFlg(!advancedShowFlg);
  };

  // move forward to jobDetail
  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // fetch the status name from constants
  const getStatusName = (code: string) => {
    let statusName = "";
    if (code && code !== "") {
      for (let index in statusList) {
        if (statusList[index].code === code) {
          statusName = statusList[index].name;
          break;
        }
      }
    }
    return statusName;
  };

  // fetch the quote result name from constants
  const getQuoteResultName = (code: number) => {
    let quoteResultName = "";
    if (code) {
      for (let index in quoteResultList) {
        if (quoteResultList[index].code === code) {
          quoteResultName = quoteResultList[index].name;
          break;
        }
      }
    }
    return quoteResultName;
  };

  // go button click event
  const handleGoEvent = () => {
    if (!checkMinLength(searchValueList.job_no, 8)) {
      props.setAlertMessageList([]);
      let message = com("messages.MS0038", {
        length: 8,
      });
      setSearchValue("job_no_error", message);
      // API AT0102 check job no whether exited
    } else if (searchValueList.job_no !== "") {
      props.setAlertMessageList([]);
      setSearchValue("job_no_error", "");
      api
        .getReadRepairJobAPI(searchValueList.job_no, config)
        .then(({ response, code }: { response: any; code: any }) => {
          console.log("AT0102 response", response);
          if (code !== 200) {
            const obj: any = {
              no: props.alertMessageList.length,
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };
            let alertMessageListNew: any = [obj];
            props.setAlertMessageList(alertMessageListNew);
          } else {
            const path =
              GetPrivateAppRoutePath(PathKey.JOBSDETAIL) +
              `?jobno=${searchValueList.job_no}`;
            handleOnClick(path);
          }
        });
    } else {
      setSearchValue("job_no_error", "");
      // do nothing
      // const path =
      //   GetPrivateAppRoutePath(PathKey.JOBSDETAIL) +
      //   `?jobno=${searchValueList.job_no}`;
      // handleOnClick(path);
    }
  };

  useEffect(() => {
    // init search
    // let query_parameters =
    //   "status=300&status=400&status=510&service_center_id=" +
    //   encodeURIComponent(userInfoRepair.service_center);
    // search(query_parameters);
    // setSearchValue("service_center_id", [userInfoRepair.service_center]);
    if (props.reSearchFlg) {
      props.setReSearchFlg(false);
      conditionSearch(searchValueList, true);
    }
  }, [props.reSearchFlg]);

  // common search method
  const search = (data: any, query_parameters: string, appendErr = false) => {
    // alert(query_parameters);
    if (!appendErr) {
      props.setAlertMessageList([]);
    }
    props.setResultList([]);
    props.setSearchFlg(true);

    // Read Repair Job List AT0105
    api
      .getReadRepairJobListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT0105 response: ", response);
        const searchMessage = Object.assign({}, data);
        setJobsSearchMessage(searchMessage);
        if (code !== 200) {
          const obj: any = {
            no: props.alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name." + response.key),
            }),
            alertType: "error",
          };
          if (!appendErr) {
            let alertMessageListNew: any = [obj];
            props.setAlertMessageList(alertMessageListNew);
          } else {
            let alertMessageListNew: any = props.alertMessageList.slice(0);
            alertMessageListNew.push(obj);
            props.setAlertMessageList(alertMessageListNew);
          }
        } else {
          const obj: any = {
            no: props.alertMessageList.length,
            message: com("messages.MS0049"),
            alertType: "warning",
          };
          if (response.total_num && response.total_num >= 2000) {
            let alertMessageListNew: any = [obj];
            props.setAlertMessageList(alertMessageListNew);
          }
          let newList = response.jobs_list.slice(0);
          newList.map((item: any) => {
            item.elapsed_time_number = Number(item.elapsed_time);
            if (item.address_2 === "") {
              item.address = item.address_3 === "" ? "" : item.address_3;
            } else {
              item.address =
                item.address_3 === ""
                  ? item.address_2
                  : item.address_2 + ", " + item.address_3;
            }
            item.statusName = getStatusName(item.status);
            item.quoteResultName = getQuoteResultName(item.quote_result);
          });
          props.setResultList(newList);
        }
      });
  };

  const conditionSearch = (data: any, appendErr = false) => {
    let param_serviceCenterId = "";
    let param_status = "";
    let param_receivedDateFrom = "";
    let param_receivedDateTo = "";
    let param_quote_result = "";
    if (data.service_center_id.length > 0) {
      for (let index in data.service_center_id) {
        param_serviceCenterId =
          param_serviceCenterId +
          "&service_center_id=" +
          data.service_center_id[index];
      }
    } else {
      param_serviceCenterId = "&service_center_id=";
    }
    if (data.status.length > 0) {
      for (let index in data.status) {
        param_status = param_status + "&status=" + data.status[index];
      }
    } else {
      param_status = "&status=";
    }
    let received_date_from = data.received_date_from;
    if (received_date_from !== null) {
      param_receivedDateFrom = new Date(received_date_from).toISOString();
    } else {
      param_receivedDateFrom = "";
    }

    let received_date_to = data.received_date_to;
    if (received_date_to !== null) {
      param_receivedDateTo = new Date(received_date_to).toISOString();
    } else {
      param_receivedDateTo = "";
    }
    if (data.quote_result.length > 0) {
      for (let index in data.quote_result) {
        param_quote_result =
          param_quote_result + "&quote_result=" + data.quote_result[index];
      }
    } else {
      param_quote_result = "&quote_result=";
    }

    let query_parameters =
      "job_no=" +
      encodeURIComponent(data.job_no) +
      "&customer_no=" +
      encodeURIComponent(data.customer_no) +
      "&customer_po_no=" +
      encodeURIComponent(data.customer_po_no) +
      "&model_no=" +
      encodeURIComponent(data.model_no) +
      "&serial_no=" +
      encodeURIComponent(data.serial_no) +
      "&tracking_no=" +
      encodeURIComponent(data.tracking_no) +
      "&postal_cd=" +
      encodeURIComponent(data.postal_cd) +
      param_serviceCenterId +
      "&customer_name=" +
      encodeURIComponent(data.customer_name) +
      "&address=" +
      encodeURIComponent(data.address) +
      "&address_1=" +
      encodeURIComponent(data.address_1) +
      "&mail_address=" +
      encodeURIComponent(data.mail_address) +
      param_status +
      "&technician_cd=" +
      encodeURIComponent(data.technician_cd) +
      "&order_no=" +
      encodeURIComponent(data.order_no) +
      "&received_date_from=" +
      encodeURIComponent(param_receivedDateFrom) +
      "&received_date_to=" +
      encodeURIComponent(param_receivedDateTo) +
      "&location_no=" +
      encodeURIComponent(data.location_no) +
      "&quote_sent_count=" +
      encodeURIComponent(data.quote_sent_count) +
      param_quote_result +
      "&trade_in_approve=" +
      encodeURIComponent(data.trade_in_approve);

    //console.log("query_parameters:",query_parameters);
    search(data, query_parameters, appendErr);
  };

  // search button click event
  const handleSearchEvent = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isAllEmpty()) {
      const obj: any = {
        no: props.alertMessageList.length,
        message: com("messages.MS0007"),
        alertType: "error",
      };
      let alertMessageListNew: any = [obj];
      props.setAlertMessageList(alertMessageListNew);
    } else if (isAllErrMsgmpty()) {
      props.setSearchClickFlg(true);
      conditionSearch(searchValueList, false);
    }
  };

  // clear button click event
  const handleClearEvent = () => {
    props.setAlertMessageList([]);
    const valueList = Object.assign({}, defaultValue, {
      service_center_id: [],
      status: [],
      quote_result: [],
    });
    setSearchValueList(valueList);
  };

  const goButtonDisabledFlg = () => {
    return !Boolean(searchValueList.job_no);
  };

  const changeAdvanced = () => {
    return advancedShowFlg ? <AddIcon /> : <MinimizeIcon />;
  };

  // master exist validation
  const masterExitOfCustomerNo = () => {
    let isExisted = false;
    let customers = commonInfo.customerList;
    if (searchValueList.customer_no !== "" && customers.length > 0) {
      for (let index in customers) {
        if (
          customers[index].customer_no.toString().toUpperCase() ===
          searchValueList.customer_no.toString().toUpperCase()
        ) {
          isExisted = true;
          break;
        }
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  const masterExitOfModelNo = () => {
    let isExisted = false;
    let items = commonInfo.itemList;
    if (searchValueList.model_no !== "" && items.length > 0) {
      for (let index in items) {
        if (
          items[index].model_no.toString().toUpperCase() ===
          searchValueList.model_no.toString().toUpperCase()
        ) {
          isExisted = true;
          let modelNoByDB = items[index].model_no.toString();
          console.log("model_no", modelNoByDB);
          setSearchValue("model_no", modelNoByDB);
          break;
        }
      }
    } else {
      isExisted = true;
    }
    return isExisted;
  };

  // focus out
  const onBlurOfJobNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMinLength(e.target.value, 8)) {
      let message = com("messages.MS0038", {
        length: 8,
      });
      setSearchValue("job_no_error", message);
    } else {
      setSearchValue("job_no_error", "");
    }
  };

  const onBlurOfCustomerNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMaxLength(e.target.value, 8)) {
      let message = com("messages.MS0002", {
        param1: t("txt_customer_no"),
        param2: 8,
      });
      setSearchValue("customer_no_error", message);
    } else if (!checkTypeNumber(e.target.value)) {
      let message = com("messages.MS0039");
      setSearchValue("customer_no_error", message);
    } else if (!masterExitOfCustomerNo()) {
      let message = com("messages.MS0004", {
        param1: t("txt_customer_no"),
      });
      setSearchValue("customer_no_error", message);
    } else {
      setSearchValue("customer_no_error", "");
    }
  };

  const onBlurOfModelNo = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!checkMaxLength(e.target.value, 35)) {
      let message = com("messages.MS0002", {
        param1: t("txt_model_no"),
        param2: 35,
      });
      setSearchValue("model_no_error", message);
    } else {
      setSearchValue("model_no_error", "");
    }
  };

  const getOptionLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const getOptionLabel2 = (option: any) => option.model_no || "";

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel,
  });
  const filterOptions2 = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionLabel2,
  });

  const isAllErrMsgmpty = () => {
    let result = false;
    if (
      searchValueList.job_no_error === "" &&
      searchValueList.customer_no_error === "" &&
      searchValueList.model_no_error === "" &&
      searchValueList.customer_name_error === "" &&
      searchValueList.mail_address_error === "" &&
      searchValueList.technician_cd_error === "" &&
      searchValueList.received_date_from_error === "" &&
      searchValueList.received_date_to_error === "" &&
      searchValueList.location_no_error === "" &&
      searchValueList.quote_sent_count_error === ""
    ) {
      result = true;
    }
    return result;
  };

  const isAllEmpty = () => {
    let result = false;
    if (
      searchValueList.job_no === "" &&
      searchValueList.customer_no === "" &&
      searchValueList.customer_po_no === "" &&
      searchValueList.model_no === "" &&
      searchValueList.serial_no === "" &&
      searchValueList.tracking_no === "" &&
      searchValueList.postal_cd === "" &&
      searchValueList.service_center_id.length === 0 &&
      searchValueList.customer_name === "" &&
      searchValueList.address === "" &&
      searchValueList.address_1 === "" &&
      searchValueList.mail_address === "" &&
      searchValueList.status.length === 0 &&
      searchValueList.technician_cd === "" &&
      searchValueList.order_no === "" &&
      searchValueList.received_date_from === null &&
      searchValueList.received_date_to === null &&
      searchValueList.location_no === "" &&
      searchValueList.quote_sent_count === "" &&
      searchValueList.quote_result.length === 0 &&
      searchValueList.trade_in_approve === ""
    ) {
      result = true;
    }
    return result;
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <dl>
          <dt>{t("txt_job_no")}</dt>
          <dd className={classes.jobDd}>
            <TextField
              data-testid="job_no"
              value={searchValueList.job_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/g, "").substr(0, 8);
                return setSearchValue("job_no", value);
              }}
              error={searchValueList.job_no_error !== ""}
              helperText={searchValueList.job_no_error}
              onBlur={onBlurOfJobNo}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleGoEvent();
                }
              }}
              inputRef={(input) => {
                if (input != null && jobNoInputFlg) {
                  setJobNoInputFlg(false);
                  input.focus();
                }
              }}
            />
          </dd>
        </dl>
        <div className={classes.buttonArea}>
          <Button
            className={classes.buttonCondition}
            variant="contained"
            color="primary"
            disabled={goButtonDisabledFlg()}
            onClick={handleGoEvent}
          >
            {t("btn_Go")}
          </Button>
        </div>
        <dl>
          <dt>{t("txt_customer_no")}</dt>
          <dd className={classes.jobDd}>
            <Autocomplete
              className={classes.autoComplete}
              id="customer_no"
              freeSolo
              disableClearable
              options={commonInfo.customerList}
              getOptionLabel={getOptionLabel}
              filterOptions={filterOptions}
              inputValue={searchValueList.customer_no}
              value={searchValueList.customer_no}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "300px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) => {
                let isSelected = false;
                if (option.customer_no && searchValueList.customer_no) {
                  isSelected =
                    option.customer_no.toString().toUpperCase() ===
                    searchValueList.customer_no.toString().toUpperCase();
                }
                return isSelected;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={searchValueList.customer_no_error !== ""}
                  helperText={searchValueList.customer_no_error}
                  onBlur={onBlurOfCustomerNo}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  setSearchValue("customer_no", value.customer_no.toString());
                }
              }}
              onInputChange={(event: object, value: any, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  setSearchValue("customer_no", value.toString());
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_customer_po_no")}</dt>
          <dd>
            <TextField
              value={searchValueList.customer_po_no}
              variant="outlined"
              onChange={(e) => setSearchValue("customer_po_no", e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_model_no")}</dt>
          <dd className={classes.modelNoDd}>
            <Autocomplete
              className={classes.autoComplete}
              id="model_no"
              freeSolo
              disableClearable
              options={commonInfo.itemList}
              getOptionLabel={getOptionLabel2}
              filterOptions={filterOptions2}
              inputValue={searchValueList.model_no}
              value={searchValueList.model_no}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "200px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) =>
                option.model_no.toString().toUpperCase() ===
                searchValueList.model_no.toString().toUpperCase()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={searchValueList.model_no_error !== ""}
                  helperText={searchValueList.model_no_error}
                  onBlur={onBlurOfModelNo}
                />
              )}
              onChange={(event: object, value: any, reason: string) => {
                if (reason === "select-option") {
                  console.log("reason1");
                  setSearchValue("model_no", value.model_no);
                }
              }}
              onInputChange={(event: object, value: string, reason: string) => {
                if (reason === "input" || reason === "clear") {
                  console.log("reason2");
                  setSearchValue("model_no", value);
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_serial_no")}</dt>
          <dd>
            <TextField
              value={searchValueList.serial_no}
              variant="outlined"
              onChange={(e) => setSearchValue("serial_no", e.target.value)}
              inputProps={{ maxLength: 15 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_tracking_no")}</dt>
          <dd>
            <TextField
              value={searchValueList.tracking_no}
              variant="outlined"
              onChange={(e) => setSearchValue("tracking_no", e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_postal_cd")}</dt>
          <dd className={classes.jobDd}>
            <TextField
              value={searchValueList.postal_cd}
              variant="outlined"
              onChange={(e) => setSearchValue("postal_cd", e.target.value)}
              inputProps={{ maxLength: 9 }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("txt_service_center")}</dt>
          <dd>
            <FormControl variant="outlined">
              <Select
                multiple
                id="service_center"
                className={classes.searchControlSelect}
                value={searchValueList.service_center_id}
                onChange={handleChange}
                renderValue={() => ""}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {Array.isArray(commonInfo.serviceCenterList) ? (
                  commonInfo.serviceCenterList.map((service_center: any) => (
                    <MenuItem
                      key={service_center.service_center_id}
                      value={service_center.service_center_id}
                    >
                      <Checkbox
                        color="primary"
                        checked={
                          searchValueList.service_center_id.indexOf(
                            service_center.service_center_id
                          ) > -1
                        }
                      />
                      <ListItemText primary={service_center.address_1} />
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
            <div className={classes.advancedButton}>
              <Button
                className={classes.buttonCondition2}
                variant="contained"
                startIcon={changeAdvanced()}
                onClick={handleAdvancedEvent}
              >
                {t("btn_Advanced")}
              </Button>
            </div>
          </dd>
        </dl>
        <div>
          <div className={classes.buttonWrapper}>
            <div className={classes.buttonArea}>
              <Button
                className={classes.buttonCondition}
                data-testid="job_List_btn_Search"
                variant="contained"
                color="primary"
                onClick={(event) => handleSearchEvent(event)}
                startIcon={<SearchIcon />}
              >
                {t("btn_Search")}
              </Button>
            </div>
            <div className={classes.buttonArea}>
              <Button
                className={classes.buttonCondition}
                variant="contained"
                onClick={handleClearEvent}
                startIcon={<RefreshIcon />}
              >
                {t("btn_Clear")}
              </Button>
            </div>
          </div>
          {/* {props.userPermisssionInfo.job_list_download_authFlg && (
            <div className={classes.downloadButton}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={downloadCSVBySearch}
              >
                {t("btn_download_job")}
              </Button>
            </div>
          )} */}
        </div>
      </div>
      <div className={clsx(advancedShowFlg && classes.advancedHideFlg)}>
        <SearchControlAdvanced
          searchValueList={searchValueList}
          setSearchValue={(property: any, value: any) =>
            setSearchValue(property, value)
          }
          setSearchValueList={setSearchValueList}
        />
      </div>
    </form>
  );
};
