import React, { useState } from "react";
// components
import { MasterMaintenanceSearchBox } from "./MasterMaintenance.SearchBox";
import { MasterMaintenanceRoleThead } from "./MasterMaintenance.Role.Thead";
import { MasterMaintenanceRoleTbody } from "./MasterMaintenance.Role.Tbody";
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { TabKey } from "../MasterMaintenance.constants";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// hook
import { useStyles } from "../hooks/table.useStyles";

export const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "center",
    },
    buttonArea: {
      marginRight: "10px",
    },
    input: {
      display: "none",
    },
  })
);

export const MasterMaintenanceRole = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.role",
  });
  const [searchKey, setSearchKey] = useState("");
  const { roleGroup } = props;
  // pager
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // delete
  const [deleteRow, setDeleteRow] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  // page
  const pageInfo = {
    page: page,
    rowsPerPage: rowsPerPage,
  };

  // order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // search
  const handleChangeKey = (value: any) => {
    setSearchKey(value);
    props.SearchResult(value, TabKey.role);
    setPage(0);
  };

  const handleSearch = () => {
    props.SearchResult(searchKey, TabKey.role);
    setPage(0);
  };

  // delete button click event
  const handleRoleDelete = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  // message box
  const [open, setOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    props.handleDelete(deleteRow, TabKey.role);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={myClasses.header}>
        <MasterMaintenanceSearchBox
          searchKey={searchKey}
          handleChangeKey={handleChangeKey}
          handleSearch={handleSearch}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.handleRegister(TabKey.role)}
          startIcon={<AddCircleOutlineIcon />}
        >
          {t("register")}
        </Button>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <MasterMaintenanceRoleThead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <MasterMaintenanceRoleTbody
            data={props.roleResultList}
            pageInfo={pageInfo}
            order={order}
            orderBy={orderBy}
            handleEdit={props.handleEdit}
            handleDelete={handleRoleDelete}
            roleGroup={roleGroup}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        component="div"
        count={props.roleResultList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("label_rows_per_page")}
      />
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
    </div>
  );
};
