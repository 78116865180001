import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// UI
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// root
import { Link } from "react-router-dom";
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTableCell-root": {
        padding: "5px 10px",
      },
    },
    groupContainer: {
      maxHeight: 125,
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      borderBottom: "1px solid rgba(198, 209, 221, 0.5)",
    },
    generalModelLink: {
      color: "#3D899F",
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: "repairHistory",
    label: "repair_history",
  },
];

export const JobsDetailGeneralRepairModelTableList = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.label",
  });

  const getPermisssionInfo = () => {
    let permisssions = false;
    if (props.userPermisssionInfo.wco_claim_update_authFlg || 
        props.userPermisssionInfo.wco_claim_inquiry_authFlg) {
      permisssions = true;
    }
    return permisssions;
  };

  return (
    <TableContainer className={myClasses.groupContainer}>
      <Table stickyHeader className={myClasses.root}>
        <TableHead className={classes.thead}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tbody}>
          {props.data.map((row: any, index: number) => {
            let path;
            if (row.type_kind === 1) {
              path =
                GetPrivateAppRoutePath(PathKey.JOBSDETAIL) +
                `?jobno=${row.history_no}`;
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell>
                      <Link
                        to={path}
                        className={myClasses.generalModelLink}
                        onClick={props.handleLinkClick}
                      >
                        {row.history_no}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
            } else {
              path =
                GetPrivateAppRoutePath(PathKey.SUMMARY) +
                `?claimno=${row.history_no}`;
                if (getPermisssionInfo()) {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>
                        <Link
                          to={path}
                          className={myClasses.generalModelLink}
                          onClick={props.handleLinkClick}
                        >
                          {row.history_no}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                }else {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>
                        <span>
                          {row.history_no}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                }               
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
