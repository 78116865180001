// UI
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "4px",
    borderBottom: "1px solid #CFCFCF",
    "& .MuiAppBar-root": {
      width: "auto",
    },
    "& .MuiTabs-indicator": {
      background: "#3D899F",
    },
  },
  container: {
    position: "relative",
  },
  buttonControl: {
    display: "flex",
    position: "absolute",
    right: "0",
    bottom: "60px",
  },
  buttonNextArea: {
    paddingLeft: "10px",
    // position: "absolute",
    // right: "40px",
    // bottom: "40px",
  },
  buttonPrevArea: {
    // position: "absolute",
    // left: "40px",
    // bottom: "40px",
  },
  buttonMessage: {
    height: "38px",
  },
  AppBar: {
    borderRadius: "10px 10px 0 0",
    overflow: "hidden",
    // background: "transparent",
  },
  tabButton: {
    padding: "6px 10px",
    Width: "30px",
    background: "gray",
    // textTransform: "inherit",
    "&.Mui-selected": {
      background: "#ffffff",
      color: "#3D899F",
    },
    "&.Mui-disabled": {
      background: "rgba(169, 169, 169)!important",
      opacity: "1!important",
    },
  },
  errorTabButton: {
    padding: "6px 10px",
    Width: "30px",
    background: "#f44336",
    // textTransform: "inherit",
    "&.Mui-selected": {
      background: "#FFC7BA",
      // color: "#3D899F",
    },
    "&.Mui-disabled": {
      background: "rgba(169, 169, 169)!important",
      opacity: "1!important",
    },
  },
  paper: {
    padding: "20px 10px 100px 10px",
  },
  searchButton: {
    marginTop: "13px",
    marginLeft: "5px",
  },
  claim_header: {
    display: "flex",
    justifyContent: "space-between",
  },
  history_area: {
    maxWidth: "50%",
  },
  errorTab: {
    color: "red",
    textAlign: "center",
  },
}));
