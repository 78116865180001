import { download_CSV_file,http } from "./Http";

//
export const GetReportListAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/report/csvdl/daily/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return download_CSV_file(param);
};

export const GetReportDailyBarGraphAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/report/graph/daily/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};


export const GetReportMonthlyBarGraphAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/report/graph/monthly/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

export const GetReportTechnicianAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/report/staff/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

export const GetReportModelAPI = (query_parameters: any, config: any) => {
  const { affiliate_cd, baseURL, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/report/model/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
