import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid #CFCFCF",

      "& dl": {
        paddingLeft: "10px",
      },
      "& dd": {
        margin: "0",
        paddingTop: "5px",
      },
      "& .MuiOutlinedInput-input": {
        height: "30px",
        padding: "0",
      },
    },
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingLeft: "5px",
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
      },
    },
    row: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      padding: "10px 20px 10px 10px",
      borderTop: "1px solid #CFCFCF",
    },
    advancedButton: {
      paddingTop: "10px",
      "& button": {
        width: "140px",
        padding: "5px 0",
      },
    },
    searchControlSelect: {
      width: "140px",
    },
    buttonArea: {
      padding: "32px 0 0 10px",
      "& .MuiButton-root": {
        padding: "6px 12px",
      },
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px!important",
      justifyContent: "space-between",
    },
    datePickerArea: {
      width: "400px",
      "& dd": {
        paddingTop: "9px",
      },
    },
    datePicker: {
      width: "47%",
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
    },
    datePickerInterval: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "4px",
      marginLeft: "-4px",
      borderTop: "1px solid #000",
      width: "5px",
      display: "block",
    },
    advancedHideFlg: {
      display: "none",
    },
    customerInputText: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        width: "100px",
      },
      "& .PrivateNotchedOutline-root-47.MuiOutlinedInput-notchedOutline": {
        width: "100px",
      },
    },
    searchTitleCell: {
      width: "100px",
    },
    searchTitleCell2: {
      width: "120px",
    },
    searchmessageCell: {
      width: "130px",
      paddingRight: "20px",
    },
    searchButton: {
      height: "30px",
      padding: "0 16px",
    },
  })
);
