// api
import api from "../../../modules/api/Api";
// setListId
import { setListId } from "./common.methods";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";

// Read Print Setting　List API
export const GetPrintSettingListAPI = (sub: any, config: any) => {
  // AM1102
  return api
    .getPrintSettingListAPI(sub, config)
    .then(({ response, code }: { response: any; code: any }) => {
      console.log("GetPrintSettingListAPI complete!!", code, response);
      if (response && response.length > 0) {
        return setListId(response);
      }
      return [];
    });
};

// AM1101
export const CreatePrintSettingAPI = (sub: any, param: any, config: any) => {
  return api.createPrintSettingAPI(sub, param, config)
  .then(({ response, code }: { response: any; code: any }) => {
    if(getApiResultStatus(code)) {
      return GetPrintSettingListAPI(sub, config);
    }else {
      return response;
    }
  });
};

export const DeletePrintSettingAPI = (
  printer_id: any,
  service_center_id: any,
  form_id: any,
  sub: any,
  param: any,
  config: any
) => {
  return api.deletePrintSettingAPI(
    printer_id,
    service_center_id,
    form_id,
    sub,
    param,
    config
  );
};

// Read Repair Destination List API
export const GetRepairDestinationListAPI = (config: any) => {
  // AM0805
  return api
    .getRepairDestinationDetailListAPI(config)
    .then(({ response, code }: { response: any; code: any }) => {
      console.log("GetRepairDestinationListAPI complete!!", code, response);
      if (response && response.length > 0) {
        return setListId(response);
      }
      return [];
    });
};

export const CreateRepairDestinationAPI = (param: any, config: any) => {
  // AM0801
  return api.createRepairDestinationMasterAPI(param, config).then(() => {
    return GetRepairDestinationListAPI(config);
  });
};

export const DeleteRepairDestinationAPI = (
  region_cd: any,
  data: any,
  config: any
) => {
  return api.deleteRepairDestinationMasterAPI(region_cd, data, config);
};
