import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// component
import { ModelWindow } from "../../../modules/components/model/ModelWindow";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "517px",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
  })
);

export const OrderDetailModelWindow = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "models.model_window" });

  const attentionButtonTypeOne = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={props.closeModelWindow}
      >
        {t("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" onClick={props.sendData}>
        {t("prompt_button_SendToDZ")}
      </Button>
    </div>
  );
  const attentionButtonTypeTwo = (
    <div className={classes.singleButtonControl}>
      <Button variant="contained" onClick={props.sendData}>
        {t("prompt_button_Close")}
      </Button>
    </div>
  );

  const attentionButtonTypeThree = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={props.closeModelWindow}
      >
        {t("prompt_button_Cancel")}
      </Button>
      <Button variant="contained" onClick={props.sendData}>
        {t("prompt_button_SendToBPCS")}
      </Button>
    </div>
  );

  const attentionButtonTypeFour = (
    <div className={classes.buttonControl}>
      <Button
        variant="contained"
        color="secondary"
        onClick={props.closeModelWindow}
      >
        {t("prompt_button_No")}
      </Button>
      <Button variant="contained" onClick={props.sendData}>
        {t("prompt_button_Yes")}
      </Button>
    </div>
  );
  return (
    <>
      <ModelWindow
        title="prompt_title_attention"
        contents={props.attentionDiscard}
        openFlg={props.modalOpenDiscard}
        button={attentionButtonTypeFour}
        handleCloseEvent={props.closeModelWindow}
      />
      <ModelWindow
        title="prompt_title_attention"
        contents={props.attentionSendToDZ}
        openFlg={props.modalOpenSendDZ}
        button={attentionButtonTypeOne}
        handleCloseEvent={props.closeModelWindow}
      />
      <ModelWindow
        title="prompt_title_attention"
        contents={props.attentionSentToDZ}
        openFlg={props.modalOpenSuccessDz}
        button={attentionButtonTypeTwo}
        handleCloseEvent={props.closeModelWindow}
      />
      <ModelWindow
        title="prompt_title_attention"
        contents={props.attentionSendToBPCS}
        openFlg={props.modalOpenSendBPCS}
        button={attentionButtonTypeThree}
        handleCloseEvent={props.closeModelWindow}
      />
      <ModelWindow
        title="prompt_title_attention"
        contents={props.attentionSentToBPCS}
        openFlg={props.modalOpenSuccessBPCS}
        button={attentionButtonTypeTwo}
        handleCloseEvent={props.closeModelWindow}
      />
      <ModelWindow
        title="prompt_title_attention"
        contents={props.attentionLeavePage}
        openFlg={props.modalOpenReturn}
        button={attentionButtonTypeFour}
        handleCloseEvent={props.closeModelWindow}
      />
    </>
  );
};
