import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";

export const JobsDetailGeneralPartialRepairMemo = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.general.label",
  });
  const { formList, setFormListValue, userPermisssionInfo } = props;

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("partial_repair_memo")}</h3>
      <TextField
        disabled={!userPermisssionInfo.partial_repair_memo_authFlg}
        value={formList.notePartialRepairMemo.note}
        className={classes.multilineTextField}
        placeholder={t("placeholder_partial_repair_memo")}
        variant="outlined"
        multiline
        rows={3}
        onChange={(e) =>
          setFormListValue("notePartialRepairMemo", e.target.value, "note")
        }
        inputProps={{ maxLength: 2048 }}
      />
    </div>
  );
};
