import { getNumberDataType } from "../../../modules/common.methods";

export const getMemoEditValue = () => {
  return {
    note: "",
  };
};

export const getDataListDefault = () => {
  return {
    updated_date: undefined,
    model_no: undefined,
  };
};

export const getPartsData = () => {
  return {
    model_no: undefined,
    memo: undefined,
  };
};

export const diffContents = (
  _claim_no: string,
  formList: any,
  dataList: any,
  transportMessage: any = {},
  chargesValue: any = {}
) => {
  // common
  if (dataList.customer_po_no !== formList.customer_po_no) {
    return false;
  }
  console.log("diffContents", formList, dataList);
  // // endUser
  // if (!diffEnduserTab(formList, dataList)) {
  //   return false;
  // }
  // console.log("diffContents1");

  // // tool information
  // if (!toolInformationTab(formList, dataList)) {
  //   return false;
  // }
  // console.log("diffContents2");

  // parts
  if (!partsTab(formList, dataList)) {
    return false;
  }
  console.log("diffContents3");

  // // upload
  // if (!uploadTab(formList, dataList)) {
  //   return false;
  // }
  // console.log("diffContents4");

  if (!transportMessageInfo(transportMessage, dataList)) {
    return false;
  }
  console.log("diffContents5");

  if (!chargesMessageInfo(chargesValue, dataList)) {
    return false;
  }
  console.log("diffContents6");

  return true;
};

const diffEnduserTab = (formList: any, dataList: any) => {
  console.log("diffContents1 customer_type");

  if (
    !diffMessage(
      dataList.claim_address.customer_type,
      formList.claim_address.customer_type
    )
  ) {
    return false;
  }
  console.log("diffContents1 company_name");

  if (
    !diffMessage(
      dataList.claim_address.company_name,
      formList.claim_address.company_name
    )
  ) {
    return false;
  }
  console.log("diffContents1 last_name");

  if (
    !diffMessage(
      dataList.claim_address.last_name,
      formList.claim_address.last_name
    )
  ) {
    return false;
  }
  console.log("diffContents1 first_name");

  if (
    !diffMessage(
      dataList.claim_address.first_name,
      formList.claim_address.first_name
    )
  ) {
    return false;
  }
  console.log("diffContents1 postal_code");

  if (
    !diffMessage(
      dataList.claim_address.postal_code,
      formList.claim_address.postal_code
    )
  ) {
    return false;
  }
  console.log("diffContents1 address_line_1");

  if (
    !diffMessage(
      dataList.claim_address.address_line_1,
      formList.claim_address.address_line_1
    )
  ) {
    return false;
  }
  console.log("diffContents1 address_line_2");

  if (
    !diffMessage(
      dataList.claim_address.address_line_2,
      formList.claim_address.address_line_2
    )
  ) {
    return false;
  }
  console.log("diffContents1 city");

  if (!diffMessage(dataList.claim_address.city, formList.claim_address.city)) {
    return false;
  }
  console.log("diffContents1 state");

  if (
    !diffMessage(dataList.claim_address.state, formList.claim_address.state)
  ) {
    return false;
  }
  console.log("diffContents1 country_code");

  if (
    !diffMessage(
      dataList.claim_address.country_code,
      formList.claim_address.country_code
    )
  ) {
    return false;
  }
  console.log("diffContents1 mail_address");

  if (
    !diffMessage(
      dataList.claim_address.mail_address,
      formList.claim_address.mail_address
    )
  ) {
    return false;
  }

  if (
    !diffMessage(dataList.claim_address.tel_no, formList.claim_address.tel_no)
  ) {
    return false;
  }

  if (
    !diffMessage(dataList.claim_address.fax_no, formList.claim_address.fax_no)
  ) {
    return false;
  }

  if (!diffMessage(dataList.privacy_check_flg, formList.privacy_check_flg)) {
    return false;
  }

  return true;
};

const toolInformationTab = (formList: any, dataList: any) => {
  console.log("toolInformationTab", formList, dataList);
  // model no
  if (!diffMessage(dataList.model_no, formList.model_no)) {
    return false;
  }
  // serial no
  if (!diffMessage(dataList.serial_no, formList.serial_no)) {
    return false;
  }

  if (!diffMessage(dataList.condition_of_tool, formList.condition_of_tool)) {
    return false;
  }

  if (!diffMessage(dataList.device_runtime, formList.device_runtime)) {
    return false;
  }

  if (!diffMessage(dataList.purchased_date, formList.purchased_date)) {
    return false;
  }

  if (!diffMessage(dataList.received_date, formList.received_date)) {
    return false;
  }

  if (!diffMessage(dataList.repair_date, formList.repair_date)) {
    return false;
  }
  if (!diffMessage(dataList.manufactured_year, formList.manufactured_year)) {
    return false;
  }
  if (!diffMessage(dataList.manufactured_month, formList.manufactured_month)) {
    return false;
  }
  if (!diffMessage(dataList.error_type, formList.error_type)) {
    return false;
  }
  if (!diffMessage(dataList.guarantee_type, formList.guarantee_type)) {
    return false;
  }

  // note
  const old_note_list =
    dataList.claim_note && dataList.claim_note.length > 0
      ? dataList.claim_note.filter((item: any) => item.note_type === 10)
      : [];
  const old_note = old_note_list.length > 0 ? old_note_list[0].note : "";

  const new_note_list =
    formList.claim_note && formList.claim_note.length > 0
      ? formList.claim_note.filter((item: any) => item.note_type === 10)
      : [];
  const new_note = new_note_list.length > 0 ? new_note_list[0].note : "";

  if (!diffMessage(old_note, new_note)) {
    return false;
  }

  return true;
};

const partsTab = (formList: any, dataList: any) => {
  let _flg = true;
  console.log(
    "diffContents3 create & update",
    formList.claim_line,
    dataList.claim_line
  );
  if (formList.claim_line.length > 0) {
    for (let i = 0; i < formList.claim_line.length; i++) {
      const item = formList.claim_line[i];
      // edit data
      if (item.line_no !== "" && item.line_no !== undefined) {
        if (dataList.claim_line.length > 0) {
          // response data
          const _response = dataList.claim_line.filter(
            (subItem: any) => subItem.line_no === item.line_no
          );
          console.log("diffContents3 update3", _response, item);
          if (_response.length > 0) {
            const subItem = _response[0];
            if (!diffMessage(item.order_qty, subItem.order_qty, "number")) {
              _flg = false;
            }
            if (!diffMessage(item.net_price, subItem.net_price, "number")) {
              _flg = false;
            }
            if (!diffMessage(item.item_no, subItem.item_no)) {
              _flg = false;
            }
          } else {
            console.log("diffContents3 update2", _flg);
            // error
            _flg = false;
          }
        } else {
          console.log("diffContents3 update1", _flg);
          // error
          _flg = false;
        }
      } else {
        console.log("diffContents3 create", _flg);
        // create data
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return _flg;
  }
  console.log("diffContents3 delete");
  // delete

  if (dataList.claim_line.length > 0) {
    for (let i = 0; i < dataList.claim_line.length; i++) {
      const item = dataList.claim_line[i];
      if (formList.claim_line.length > 0) {
        // input data
        const _input = formList.claim_line.filter(
          (subItem: any) => subItem.line_no === item.line_no
        );
        if (_input.length === 0) {
          _flg = false;
        }
      } else {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }

  if (!_flg) {
    return _flg;
  }

  if (
    !diffMessage(
      dataList.claim_address.freight_distance,
      formList.claim_address.freight_distance
    )
  ) {
    _flg = false;
  }

  if (!_flg) {
    return _flg;
  }

  console.log("diffContents3 attention_check_flg");

  if (
    !diffMessage(dataList.attention_check_flg, formList.attention_check_flg)
  ) {
    _flg = false;
  }

  return _flg;
};

const uploadTab = (formList: any, dataList: any) => {
  console.log("diffContents4 claim_media_invoice create");
  let _flg = true;
  if (formList.claim_media.claim_media_invoice.length > 0) {
    for (let i = 0; i < formList.claim_media.claim_media_invoice.length; i++) {
      const item = formList.claim_media.claim_media_invoice[i];
      // create data
      if (item.sub_no === "" || item.sub_no === undefined) {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return false;
  }
  console.log("diffContents4 claim_media_invoice delete");
  if (dataList.claim_media.claim_media_invoice.length > 0) {
    for (let i = 0; i < dataList.claim_media.claim_media_invoice.length; i++) {
      const item = dataList.claim_media.claim_media_invoice[i];
      if (formList.claim_media.claim_media_invoice.length > 0) {
        // input data
        const _input = formList.claim_media.claim_media_invoice.filter(
          (subItem: any) => subItem.sub_no === item.sub_no
        );
        if (_input.length === 0) {
          _flg = false;
        }
      } else {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return false;
  }
  console.log("diffContents4 claim_media_picture create");

  if (formList.claim_media.claim_media_picture.length > 0) {
    for (let i = 0; i < formList.claim_media.claim_media_picture.length; i++) {
      const item = formList.claim_media.claim_media_picture[i];
      // create data
      if (item.sub_no === "" || item.sub_no === undefined) {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  if (!_flg) {
    return false;
  }
  console.log("diffContents4 claim_media_picture delete", _flg);

  if (dataList.claim_media.claim_media_picture.length > 0) {
    for (let i = 0; i < dataList.claim_media.claim_media_picture.length; i++) {
      const item = dataList.claim_media.claim_media_picture[i];
      if (formList.claim_media.claim_media_picture.length > 0) {
        // input data
        const _input = formList.claim_media.claim_media_picture.filter(
          (subItem: any) => subItem.sub_no === item.sub_no
        );
        if (_input.length === 0) {
          _flg = false;
        }
      } else {
        _flg = false;
      }
      if (!_flg) {
        break;
      }
    }
  }
  return _flg;
};

const diffMessage = (data: any, response: any, type: string = "") => {
  if (type === "") {
    return getStringDataType(data) === getStringDataType(response);
  } else if (type === "number") {
    return getNumberDataType(data) === getNumberDataType(response);
  }
};

const transportMessageInfo = (formList: any, dataList: any) => {
  let _flg = true;
  if (!diffMessage(dataList.transport_cost_flg, formList.transport_cost_flg)) {
    return false;
  }

  if (!diffMessage(dataList.weight, formList.weight)) {
    return false;
  }

  if (
    !diffMessage(dataList.transport_way_pick_up, formList.transport_way_pick_up)
  ) {
    return false;
  }

  if (
    !diffMessage(dataList.transport_way_return, formList.transport_way_return)
  ) {
    return false;
  }

  if (!diffMessage(dataList.shipping_method, formList.shipping_method)) {
    return false;
  }

  return _flg;
};

const chargesMessageInfo = (formList: any, dataList: any) => {
  let _flg = true;
  if (!diffMessage(dataList.total_amount, formList.total_amount)) {
    return false;
  }

  return _flg;
};

export const getStringDataType = (value: any) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "number" || typeof value === "boolean") {
    return String(value);
  } else {
    return "";
  }
};
