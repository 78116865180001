import { isTemplateMiddleOrTemplateTail } from "typescript";
import {
  getCodeNameByNumber,
  getSignByCSV,
} from "../../../modules/common.methods";
export const semicolon = ["MD"];

// get CSV Date
export const getCSVDate = (date: any) => {
  const _date = new Date(date);
  let y = _date.getFullYear().toString();
  let m: any = date2length(_date.getMonth() + 1, 2);
  let d: any = date2length(_date.getDate(), 2);
  return `${y}-${m}-${d}`;
};

// date length
export const date2length = (v: any, len: number) => {
  let _t: string = typeof v === "number" ? v.toString() : v;
  for (let i = 0; i < len; i++) {
    if (typeof _t === "string" && _t.length <= len - 1) {
      _t = "0" + _t;
    }
  }
  return _t;
};

// get CSV file name
export const getCSVFileName = () => {
  const date = new Date();
  let y = date.getFullYear().toString();
  let m: any = date2length(date.getMonth() + 1, 2);
  let d: any = date2length(date.getDate(), 2);
  let hh = date2length(date.getHours(), 2);
  let mm = date2length(date.getMinutes(), 2);
  let ss = date2length(date.getSeconds(), 2);

  return y + m + d + hh + mm + ss;
};

export const getCSVTitleInfo = (t: any, salesAnalysisDefinition: any) => {
  let _title: any = {
    promo_no: t("promo_no"),
    serial_no: t("serial_no"),
    promo_name: t("promo_name"),
    start_date: t("start_date"),
    end_date: t("end_date"),
    promotion_type: t("promotion_type"),
    priority: t("priority"),
    analysis_code: t("analysis_cd"),
    allow_overlap: t("allow_overlap"),
    exclusive_flag: t("exclusive_flag"),
    webshop_flag: t("webshop_flag"),
    others_flag: t("others_flag"),
    customer_no: t("customer_no"),
    customer_type: t("customer_type"),
    customer_discount_code: t("customer_discount_code"),
    csac1: t("csac1"),
    csac2: t("csac2"),
    csac3: t("csac3"),
    csac4: t("csac4"),
    csac5: t("csac5"),
    csac6: t("csac6"),
    csac7: t("csac7"),
    csac8: t("csac8"),
    csac9: t("csac9"),
    csac10: t("csac10"),
    promotion_region: t("promotion_region"),
    considered_item_no: t("considered") + "." + t("item"),
    considered_item_discount_code:
      t("considered") + "." + t("item_discount_code"),
    considered_item_class: t("considered") + "." + t("item_class"),
    considered_isac1: t("considered") + "." + t("isac1"),
    considered_isac2: t("considered") + "." + t("isac2"),
    considered_isac3: t("considered") + "." + t("isac3"),
    considered_isac4: t("considered") + "." + t("isac4"),
    considered_isac5: t("considered") + "." + t("isac5"),
    considered_isac6: t("considered") + "." + t("isac6"),
    considered_isac7: t("considered") + "." + t("isac7"),
    considered_isac8: t("considered") + "." + t("isac8"),
    considered_quantity: t("considered") + "." + t("quantity"),
    considered_unit_packet: t("considered") + "." + t("unit_packet"),
    excluded_item_no: t("excluded") + "." + t("item"),
    excluded_item_discount_code: t("excluded") + "." + t("item_discount_code"),
    excluded_item_class: t("excluded") + "." + t("item_class"),
    excluded_isac1: t("excluded") + "." + t("isac1"),
    excluded_isac2: t("excluded") + "." + t("isac2"),
    excluded_isac3: t("excluded") + "." + t("isac3"),
    excluded_isac4: t("excluded") + "." + t("isac4"),
    excluded_isac5: t("excluded") + "." + t("isac5"),
    excluded_isac6: t("excluded") + "." + t("isac6"),
    excluded_isac7: t("excluded") + "." + t("isac7"),
    excluded_isac8: t("excluded") + "." + t("isac8"),
    discount_unit_amount: t("discount") + "." + t("table_unit_amount"),
    discount_packet: t("discount") + "." + t("packet"),
    discount_purchase_amount_more_than:
      t("discount") + "." + t("purchase_amount"),
    discount_discount_rate: t("discount") + "." + t("discount_rate"),
    free_goods_item_no: t("free_goods") + "." + t("item"),
    free_goods_item_discount_code:
      t("free_goods") + "." + t("item_discount_code"),
    free_goods_item_class: t("free_goods") + "." + t("item_class"),
    free_goods_isac1: t("free_goods") + "." + t("isac1"),
    free_goods_isac2: t("free_goods") + "." + t("isac2"),
    free_goods_isac3: t("free_goods") + "." + t("isac3"),
    free_goods_isac4: t("free_goods") + "." + t("isac4"),
    free_goods_isac5: t("free_goods") + "." + t("isac5"),
    free_goods_isac6: t("free_goods") + "." + t("isac6"),
    free_goods_isac7: t("free_goods") + "." + t("isac7"),
    free_goods_isac8: t("free_goods") + "." + t("isac8"),
    free_goods_quantity: t("free_goods") + "." + t("quantity"),
    special_price_single_set:
      t("special_price") + "." + t("single") + "/" + t("set"),
    special_price_more_than_times:
      t("special_price") + "." + t("more_than") + "/" + t("times"),
    special_price_item_no: t("special_price") + "." + t("item"),
    special_price_item_discount_code:
      t("special_price") + "." + t("item_discount_code"),
    special_price_item_class: t("special_price") + "." + t("item_class"),
    special_price_isac1: t("special_price") + "." + t("isac1"),
    special_price_isac2: t("special_price") + "." + t("isac2"),
    special_price_isac3: t("special_price") + "." + t("isac3"),
    special_price_isac4: t("special_price") + "." + t("isac4"),
    special_price_isac5: t("special_price") + "." + t("isac5"),
    special_price_isac6: t("special_price") + "." + t("isac6"),
    special_price_isac7: t("special_price") + "." + t("isac7"),
    special_price_isac8: t("special_price") + "." + t("isac8"),
    special_price_quantity: t("special_price") + "." + t("quantity"),
    special_price_special_price: t("special_price") + "." + t("special_price"),
    promotion_code_promotion_code:
      t("table_promotion_code") + "." + t("table_promotion_code"),
    promotion_code_discount_rate:
      t("table_promotion_code") + "." + t("discount_rate"),
    promotion_code_promotion_code_type:
      t("table_promotion_code") +
      "." +
      t("single_use") +
      "/" +
      t("multiple_use"),
    payment_term_code_payment_term_code: t("payment_term_code"),
    dealer_discount_discount_rate:
      t("dealer_discount") + "." + t("discount_rate"),
    promotion_status: t("status"),
  };

  for (let _name in _title) {
    let _flag = true;
    if (_name.indexOf("csac") !== -1 || _name.indexOf("promotion_region") !== -1) {
      _flag = salesAnalysisDefinition.customer_list.includes(_name);
    } else if (_name.indexOf("isac") !== -1) {
      let textList = _name.split("isac");
      if (textList.length > 1) {
        _flag = salesAnalysisDefinition.item_list.includes(
          "isac" + textList[1]
        );
      }
    }
    if (!_flag) {
      delete _title[_name];
    }
  }

  return _title;
};

export const getCSVTitle = (
  sign: any,
  t: any,
  salesAnalysisDefinition: any
) => {
  let str = "";
  const _title: any = getCSVTitleInfo(t, salesAnalysisDefinition);
  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item] !== "") {
      if (itemIndex < titleSize) {
        str += _title[item] + sign;
      } else {
        str += _title[item];
      }
    }
  }
  str += "\n";
  return str;
};

export const getSign = (config: any) => {
  return getSignByCSV(config.affiliate_cd);
};

export const doQuotationMarks = (item: any)=>{
  let str = String(item);
  return str.replace(/[\"]/g, "\"\"");
}

// get CSV contents
export const getCSVContents = (
  com: any,
  t: any,
  data: any,
  config: any,
  salesAnalysisDefinition: any
) => {
  let str = "";
  const sign = getSign(config);
  const _title = getCSVTitleInfo(t, salesAnalysisDefinition);
  str += getCSVTitle(sign, t, salesAnalysisDefinition);

  data.forEach((item: any) => {
    for (let key in _title) {
      let v = item[key] === null || item[key] === undefined ? "" : item[key];
      v = doQuotationMarks(v);
      if (key === "start_date") {
        if (v) {
          v = getCSVDate(v);
        } else {
          v = "";
        }
      }
      if (key === "end_date") {
        if (!v || v.indexOf("9999") > -1) {
          v = "";
        } else {
          v = getCSVDate(v);
        }
      }
      if (key === "promotion_type" && v !== "") {
        v = v + ":" + getCodeNameByNumber(v, com("promotion_type"));
      }
      if (key === "allow_overlap" && v !== "") {
        v = v + ":" + getCodeNameByNumber(Number(v), com("allow_overlap_code"));
      }

      if (key === "exclusive_flag" && v !== "") {
        v =
          v + ":" + getCodeNameByNumber(Number(v), com("exclusive_flag_code"));
      }

      if (key === "webshop_flag" && v !== "") {
        v =
          v + ":" + getCodeNameByNumber(Number(v), com("webshop_flag_code"));
      }

      if (key === "others_flag" && v !== "") {
        v =
          v + ":" + getCodeNameByNumber(Number(v), com("others_flag_code"));
      }

      if (key === "discount_unit_amount" && v !== "") {
        v = v + ":" + getCodeNameByNumber(v, com("unit_amount_code"));
      }
      if (key === "special_price_single_set" && v !== "") {
        v = v + ":" + getCodeNameByNumber(v, com("set_single_code"));
      }
      if (key === "special_price_more_than_times" && v !== "") {
        v = v + ":" + getCodeNameByNumber(Number(v), com("more_than_times"));
      }
      if (key === "promotion_code_promotion_code_type" && v !== "") {
        v =
          v + ":" + getCodeNameByNumber(Number(v), com("promotion_code_type"));
      }
      if (key === "promotion_status") {
        if (v !== "") {
          v =
            v + ":" + getCodeNameByNumber(v, com("status_option"));
        }
        str += "\"" + v + "\"";
      } else {
        str += "\"" + v + "\"" +sign;
      }
    }

    str += "\n";
  });
  return str;
};
