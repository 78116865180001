import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/information.userStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const SummaryAscInformation = (props: any) => {
  const classes = useStyles();
  const { customerInfo } = props;
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });

  return (
    <form noValidate autoComplete="off">
      <div>
        <div>
          <div className={classes.box}>
            {t("summary_title_asc_information")}
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_asc_name")}</span>
            <span className={classes.message}>
              {customerInfo.customer_name}
            </span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_account")}</span>
            <span className={classes.message}>{customerInfo.customer_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_phone_number")}</span>
            <span className={classes.message}>{customerInfo.tel_no}</span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_address")}</span>
            <span className={classes.message}>
              {customerInfo.address_1 && (
                <>
                  <span style={{ fontWeight: "normal" }}>
                    {customerInfo.address_1}
                  </span>
                  <br />
                </>
              )}
              {customerInfo.address_2 && (
                <>
                  <span style={{ fontWeight: "normal" }}>
                    {customerInfo.address_2}
                  </span>
                  <br />
                </>
              )}
              {customerInfo.address_3 && (
                <>
                  <span style={{ fontWeight: "normal" }}>
                    {customerInfo.address_3}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className={classes.row}>
            <span>{t("summary_txt_post_code")}</span>
            <span className={classes.message}>{customerInfo.postal_cd}</span>
          </div>
        </div>
      </div>
    </form>
  );
};
