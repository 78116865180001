import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "center"
      },
      "& th:not(:first-child)": {
        borderLeft: "1px solid rgba(198, 209, 221)",
      },
      "& th:nth-child(5)": {
        width: "350px"
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableRowHeader: {
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "rgba(240, 242, 244)",
      },
    }
  })
);

interface HeadCell {
  id: string;
  label: string;
  checkFlg?: boolean;
}

const headCellsFirst: HeadCell[] = [
  { id: "item_class", label: "item_class" },
  { id: "discount_code", label: "item_discount_code" },
  { id: "item_no", label: "item_no" },
  { id: "table_item_description", label: "table_item_description" },
  { id: "units_per_packet", label: "units_per_packet" },
  // { id: "isac3", label: "isac3" },
  // { id: "isac6", label: "isac6" }
  { id: "isac1", label: "isac1", checkFlg: true },
  { id: "isac2", label: "isac2", checkFlg: true },
  { id: "isac3", label: "isac3", checkFlg: true },
  { id: "isac4", label: "isac4", checkFlg: true },
  { id: "isac5", label: "isac5", checkFlg: true },
  { id: "isac6", label: "isac6", checkFlg: true },
  { id: "isac7", label: "isac7", checkFlg: true },
  { id: "isac8", label: "isac8", checkFlg: true }

];

const headCellsSecond: HeadCell[] = [
  { id: "item_class", label: "item_class" },
  { id: "discount_code", label: "item_discount_code" },
  { id: "item_no", label: "item_no" },
  { id: "table_item_description", label: "table_item_description" },
  { id: "quantity", label: "quantity" },
  // { id: "isac3", label: "isac3" },
  // { id: "isac6", label: "isac6" }
  { id: "isac1", label: "isac1", checkFlg: true },
  { id: "isac2", label: "isac2", checkFlg: true },
  { id: "isac3", label: "isac3", checkFlg: true },
  { id: "isac4", label: "isac4", checkFlg: true },
  { id: "isac5", label: "isac5", checkFlg: true },
  { id: "isac6", label: "isac6", checkFlg: true },
  { id: "isac7", label: "isac7", checkFlg: true },
  { id: "isac8", label: "isac8", checkFlg: true }

];

export const PromotionDetailCategoryConsideredTableThead = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });

  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tableRowHeader}>
        <TableCell padding="checkbox">
          <Checkbox
            data-testid="catergory_considered_item_allCheckbox"
            indeterminate={props.indeterminate}
            checked={props.allChecked}
            onChange={props.onSelectAllClick}
            disabled={props.reviewFlg}
          />
        </TableCell>
        {props.searchValueList.promo_type === "10" &&
          headCellsFirst.map((headCell) => (
            // <TableCell
            //   key={headCell.id}
            // >
            //   {t(headCell.label)}
            // </TableCell>
            (props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes(headCell.id)) ||
            !headCell.checkFlg ? (
              <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
            ) : null
          ))
        }

        {props.searchValueList.promo_type !== "10" &&
          headCellsSecond.map((headCell) => (
            // <TableCell
            //   key={headCell.id}
            // >
            //   {t(headCell.label)}
            // </TableCell>
            (props.salesAnalysisList.item_list &&
              props.salesAnalysisList.item_list.length > 0 &&
              props.salesAnalysisList.item_list.includes(headCell.id)) ||
            !headCell.checkFlg ? (
              <TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
            ) : null

          ))
        }
      </TableRow>
    </TableHead>
  );
};
