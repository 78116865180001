import React from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
// method
import { setSortData } from "../methods/common.methods";
// components
import { TabKey } from "../MasterMaintenance.constants";

export const MasterMaintenanceItemMasterTbody = (props: any) => {
  const { pageInfo, order, orderBy } = props;
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.itemMaster",
  });

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          return (
            <TableRow className={classes.tbody} hover tabIndex={-1} key={index}>
              <TableCell className={classes.nowrap}>{row.model_no}</TableCell>
              <TableCell className={classes.nowrap}>
                {row.description}
              </TableCell>
              <TableCell className={classes.contents}><div className={classes.memo}>{row.memo}</div></TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => props.handleEdit(row,TabKey.itemMaster)}
                >
                  {t("edit")}
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
