import { http } from "./Http";

// AM1601
export const GetPermissionListAPI = (config: any) => {
  const { baseURL, affiliate_cd, token } = config;
  const param = {
    token: token,
    url: `${baseURL}/v1/permissions/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};
