import React, { useState } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { useStyles } from "../hooks/control.useStyles";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
// UI
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
// component
import { CustomerSearch } from "../../../modules/components/customerSearch/CSearch";
import { isEmptyStringOrNumber } from "../../../modules/hooks/UseValication";
// api
import api from "../../../modules/api/Api";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// methods
import { getApiResultStatus } from "../../../modules/common.methods";

export const SearchControl = (props: any) => {
  const { getCommonInfo, getCommonConfig } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;

  const classes = useStyles();

  const {
    alertMessageList,
    setAlertMessageList,
    searchValueList,
    setSearchValue,
    issueUpdatedDate,
    setSearchValueList,
    checkApFrom,
    onBlurOfApFrom,
    checkApTo,
    onBlurOfApTo,
    handleSearchEvent,
    handleClearEvent,
    handleCustomerSearch,
    handleCreditIssue,
    setCustomerMessage,
    isAllErrMsgmpty,
  } = props;
  const { t } = useLocale({ defaultPath: "modules.components.ap" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  let config = getCommonConfig() as any;
  const statusList = com("wco_ap_status");
  const [customerNoSelect, setCustomerNoSelect] = useState(0);
  const [customerNoOnFocus, setCustomerNoOnFocus] = useState(false);

  // button click event of customer search
  const [CSearchOpen, setCSearchOpen] = useState(false);

  const handleCSearch = (customerType: any) => {
    setCustomerNoSelect(customerType);
    setCSearchOpen(true);
  };

  const CSearchClose = () => {
    setCSearchOpen(false);
  };

  const getCustomerRow = (row: any) => {
    setCSearchOpen(false);
    setCustomerMessage(row, customerNoSelect);
  };

  // focus out customer No.
  const onBlurOfCustomerNo = () => {
    setSearchValue("customer_no_1_error", "");
    if (isEmptyStringOrNumber(searchValueList.customer_no_1)) {
      const customerValue = {
        customer_name_1: "",
        customer_no_1_error: "",
      };
      const valueList = Object.assign({}, searchValueList, customerValue);
      setSearchValueList(valueList);
    } else {
      // API AM0202 check customer no whether exited
      api
        .getReadCustomerMasterAPI(searchValueList.customer_no_1, config)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            let message = com("messages.MS0004", {
              param1: t("customer_no"),
            });
            setSearchValue("customer_no_1_error", message);
            setSearchValue("customer_name_1", "");
          } else {
            if (response.customer_closed === true) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages.MS0085", {
                  param1: response.customer_no,
                }),
                alertType: "warning",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            }
            const customerValue = {
              customer_name_1: response.customer_name,
              // customer_no_1: response.customer_no,
              customer_no_1_error: "",
            };
            const valueList = Object.assign({}, searchValueList, customerValue);
            setSearchValueList(valueList);
          }
        });
    }
  };


  const getOptionCustomerLabel = (option: any) => {
    if (option.customer_no && option.customer_name) {
      return option.customer_no.toString() + " : " + option.customer_name || "";
    } else {
      return "";
    }
  };

  const filterCustomerOptions = createFilterOptions({
    matchFrom: "start",
    limit: 10,
    stringify: getOptionCustomerLabel,
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.row}>
        <dl>
          <dt>{t("credit_note_no")}</dt>
          <dd className={classes.creditNoteNo}>
            <TextField
              value={searchValueList.credit_note_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value.substr(0, 30);
                setSearchValue("credit_note_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
              error={searchValueList.credit_note_no_error !== ""}
              helperText={searchValueList.credit_note_no_error}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("customer_no")}</dt>
          <dd className={classes.customerNo}>
            <Autocomplete
              id="customer_no_1"
              freeSolo
              disableClearable
              options={commonInfo.customerList}
              getOptionLabel={getOptionCustomerLabel}
              filterOptions={filterCustomerOptions}
              inputValue={String(searchValueList.customer_no_1)}
              value={String(searchValueList.customer_no_1)}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    minWidth: "300px",
                  }}
                  placement="bottom-start"
                />
              )}
              getOptionSelected={(option: any, value: any) => {
                let isSelected = false;
                if (option.customer_no && searchValueList.customer_no_1) {
                  isSelected =
                    option.customer_no.toString().toUpperCase() ===
                    searchValueList.customer_no_1.toString().toUpperCase();
                }
                return isSelected;
                              
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  error={searchValueList.customer_no_1_error !== ""}
                  helperText={searchValueList.customer_no_1_error}
                  onBlur={onBlurOfCustomerNo}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {

                      handleSearchEvent();
                    }
                  }}
                />
              )}
              onChange={(
                event: object,
                value: any,
                reason: string
              ) => {
                if (reason === "select-option") {
                  setSearchValue("customer_no_1", value.customer_no.toString());
                }
              }}
              onInputChange={(
                event: object,
                value: string,
                reason: string
              ) => {
                if (reason === "input" || reason === "clear") {
                  const val = value.substr(0, 8);
                  setSearchValue("customer_no_1", val);
                }
              }}
            />
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                handleCSearch("1");
              }}
              id="btn_promotion_list_search"
              tabIndex={0}
            >
              <SearchIcon />
            </IconButton>
          </dd>
        </dl>       
        <dl>
          <dd className={classes.claimDd}>
            <p>{searchValueList.customer_name_1}</p>
          </dd>
        </dl>   
        <dl>
          <dt>{t("customer_po_no")}</dt>
          <dd className={classes.customerPoNo}>
            <TextField
              value={searchValueList.customer_po_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value.substr(0, 30);
                setSearchValue("customer_po_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("ref_no")}</dt>
          <dd className={classes.refNo}>
            <TextField
              value={searchValueList.ref_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value.substr(0, 10);
                setSearchValue("ref_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
            />
          </dd>
        </dl>
        <dl>
          <dt>{t("claim_no")}</dt>
          <dd className={classes.claimNo}>
            <TextField
              value={searchValueList.claim_no}
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value
                  .replace(/[^\d]/g, "")
                  .substr(0, 10);
                setSearchValue("claim_no", value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchEvent();
                }
              }}
            />
          </dd>
        </dl>
        <dl className={classes.datePickerArea}>
          <dt>
            <span> {t("ap_from")}</span>
            <span className={classes.datePickerTitle}>{t("ap_to")}</span>
          </dt>
          <dd className={classes.datePickerContainer}>
            <DatePicker
              value={searchValueList.ap_from}
              onChange={(date: any) => setSearchValue("ap_from", date)}
              onAccept={(date: any) =>
                checkApFrom(new Date(date).setHours(0, 0, 0, 0))
              }
              className={classes.datePicker}
              error={searchValueList.ap_from_error !== ""}
              helperText={searchValueList.ap_from_error}
              onBlur={(e: any) => onBlurOfApFrom(e)}
              onError={(error: any, value: any) => {
                if (error !== searchValueList.ap_from_error && error !== "") {
                  setSearchValue("ap_from_error", error);
                }
              }}
            />
            <span className={classes.datePickerInterval}></span>
            <DatePicker
              value={searchValueList.ap_to}
              onChange={(date: any) => {
                if (date && date !== "") {
                  setSearchValue(
                    "ap_to",
                    new Date(date).setHours(23, 59, 59, 999)
                  );
                } else {
                  setSearchValue("ap_to", date);
                }
              }}
              onAccept={(date: any) =>
                checkApTo(new Date(date).setHours(23, 59, 59, 999))
              }
              className={classes.datePicker}
              error={searchValueList.ap_to_error !== ""}
              helperText={searchValueList.ap_to_error}
              onBlur={(e: any) => onBlurOfApTo(e)}
              onError={(error: any, value: any) => {
                if (error !== searchValueList.ap_to_error && error !== "") {
                  setSearchValue("ap_to_error", error);
                }
              }}
            />
          </dd>
        </dl>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonArea}>
            <Button
              className={classes.buttonCondition}
              disabled={!isAllErrMsgmpty()}
              variant="contained"
              color="primary"
              onClick={handleSearchEvent}
              startIcon={<SearchIcon />}
            >
              {t("btn_ap_search")}
            </Button>
          </div>
          <div className={classes.buttonArea}>
            <Button
              className={classes.buttonCondition}
              variant="contained"
              onClick={handleClearEvent}
              startIcon={<RefreshIcon />}
            >
              {t("btn_ap_clear")}
            </Button>
          </div>
        </div>
        <div className={classes.credit_issue}>
          <dl>
            <dt>{t("customer_no")}</dt>
            <dd className={classes.customerNo}>
       
              <Autocomplete
                id="customer_no_2"
                freeSolo
                disableClearable
                options={commonInfo.customerList}
                getOptionLabel={getOptionCustomerLabel}
                filterOptions={filterCustomerOptions}
                inputValue={String(searchValueList.customer_no_2)}
                value={String(searchValueList.customer_no_2)}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      whiteSpace: "nowrap",
                      wordBreak: "keep-all",
                      minWidth: "300px",
                    }}
                    placement="bottom-start"
                  />
                )}
                getOptionSelected={(option: any, value: any) => {
                  let isSelected = false;
                  if (option.customer_no && searchValueList.customer_no_1) {
                    isSelected =
                      option.customer_no.toString().toUpperCase() ===
                      searchValueList.customer_no_2.toString().toUpperCase();
                  }
                  return isSelected;
                                
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    error={searchValueList.customer_no_2_error !== ""}
                    helperText={searchValueList.customer_no_2_error}
                    onBlur={
                      (e) => {
                        setCustomerNoOnFocus(false);
                        const value = e.target.value;
                        handleCustomerSearch(value);
                      }                    
                    }
                  />
                )}
                onChange={(
                  event: object,
                  value: any,
                  reason: string
                ) => {
                  if (reason === "select-option") {
                    setSearchValue("customer_no_2", value.customer_no.toString());
                  }
                }}
                onInputChange={(
                  event: object,
                  value: string,
                  reason: string
                ) => {
                  if (reason === "input" || reason === "clear") {
                    const val = value.substr(0, 8);
                    setSearchValue("customer_no_2", val);
                  }
                }}
              /> 

              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleCSearch("2");
                }}
                id="btn_promotion_list_search"
                tabIndex={0}
              >
                <SearchIcon />
              </IconButton>                   
            </dd>
          </dl>
          <dl>
          <dd className={classes.claimDd}>
            <p>{searchValueList.customer_name_2}</p>
          </dd>
        </dl>  

          <div className={classes.buttonArea}>
            <Button
              className={classes.buttonCondition}
              variant="contained"
              color="primary"
              onClick={handleCreditIssue}
              disabled={!customerNoOnFocus && issueUpdatedDate ? false : true}
            >
              {t("credit_issue")}
            </Button>
          </div>
        </div>
      </div>
      <CustomerSearch
        CSearchOpen={CSearchOpen}
        CSearchClose={CSearchClose}
        handleSend={(row: any) => {
          getCustomerRow(row);
        }}
      />
    </form>
  );
};
