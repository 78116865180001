import React, { useState, useCallback, useEffect } from "react";
// UI
import { useStyles } from "../hooks/warrantyCharges";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// methods
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// method
import { getTransportCostShow } from "../../../modules/common.methods";

export const SummaryWarrantyCharges = (props: any) => {
  const classes = useStyles();
  // language
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const {
    formList,
    chargesValue,
    errorType,
    transportMessage,
    partsListAdministrativeCost,
    config,
  } = props;

  const labor_layer = (
    <Paper className={classes.paper}>
      <span>{t("summary_title_warranty_charges_labor")}</span>
      <span>
        {chargesValue.labor_value} &nbsp;/&nbsp;
        {currency(chargesValue.labor_amount, formList.currency)}
      </span>
    </Paper>
  );

  const administrative_cost_layer = (
    <Paper className={classes.paperOther}>
      <span>{t("administrative_cost")}</span>
      <span>
        {currency(chargesValue.administrativer_amount, formList.currency)}
      </span>
    </Paper>
  );

  const freight_layer = (
    <Paper className={classes.paperOther}>
      {
        <>
          <span>{t("summary_title_warranty_charges_freight")}</span>
          <span>
            {currency(chargesValue.freight_amount, formList.currency)}
          </span>
        </>
      }
    </Paper>
  );

  return (
    <form noValidate autoComplete="off">
      <div>
        <div>
          <div className={classes.box}>
            {t("summary_title_warranty_charges")}
          </div>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              {labor_layer}
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <span>{t("summary_title_warranty_charges_parts")}</span>
                <span>{chargesValue.qty_amount}</span>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={6} spacing={1}>
              {partsListAdministrativeCost.length > 0
                ? administrative_cost_layer
                : getTransportCostShow(config.affiliate_cd)
                ? freight_layer
                : null}
            </Grid>
            <Grid item xs={6} spacing={1}>
              <Paper className={classes.paperOther}>
                <span>{t("summary_title_warranty_charges_amount")}</span>
                <span>
                  {currency(chargesValue.parts_amount, formList.currency)}
                </span>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={6} spacing={1}>
              {partsListAdministrativeCost.length > 0 &&
              getTransportCostShow(config.affiliate_cd)
                ? freight_layer
                : null}
            </Grid>
            <Grid item xs={6} spacing={1}>
              <Paper className={classes.paperOther}>
                <span>{t("summary_title_warranty_charges_total")}</span>
                <span>
                  {currency(chargesValue.total_amount, formList.currency)}
                </span>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </form>
  );
};
