import { CompassCalibrationOutlined } from "@material-ui/icons";
import { useState } from "react";
import api from "../api/Api";
import { GlobalConfigurationModel } from "../models/GlobalConfigurationModel";
import { UserPermissions } from "../models/UserPermissionModel";

const initialUserPermissions = {
  response: undefined,
  code: 0,
};

export const useUserPermission = (): {
  getUserPermissions: (token: string, url: string) => Promise<UserPermissions>;
} => {
  const [permissions, setPermissions] = useState<UserPermissions>(
    initialUserPermissions
  );

  return {
    getUserPermissions: async (
      token: string,
      url: string
    ): Promise<UserPermissions> => {
      url =
        url.replace("/repair", "") +
        process.env.REACT_APP_REPAIR_PATH +
        "/v1/user_permissions";
      if (permissions.response === undefined) {
        var remotePermissions = await api.getUserPermissions(token, url);
        setPermissions(remotePermissions);
        return remotePermissions.response;
      } else {
        return permissions.response;
      }
    },
  };
};