import React from "react";
import clsx from "clsx";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

export const TechnicianListResultTableTbody = (props: any) => {
  const { order, orderBy } = props;
  const classes = useStyles();

  // sort
  const setSortData = (data: any, order: any, orderBy: any) => {
    if (orderBy) {
      data.sort((a: any, b: any) => {
        let sort = 0;
        if (Object.prototype.toString.call(b[orderBy]) === "[object Number]") {
          if (b[orderBy] === null && a[orderBy] !== null) {
            sort = -1;
          } else if (b[orderBy] !== null && a[orderBy] === null) {
            sort = 1;
          } else if (b[orderBy] < a[orderBy]) {
            sort = -1;
          } else if (b[orderBy] > a[orderBy]) {
            sort = 1;
          }
        } else {
          if (!b[orderBy]) {
            b[orderBy] = "";
          }
          if (!a[orderBy]) {
            a[orderBy] = "";
          }
          if (
            b[orderBy].toString().toUpperCase() <
            a[orderBy].toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            b[orderBy].toString().toUpperCase() >
            a[orderBy].toString().toUpperCase()
          ) {
            sort = 1;
          }
        }
        // console.log("sort", a[orderBy], b[orderBy]);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody>
      {setSortData(props.data.tech_list, order, orderBy).map(
        (row: any, index: number) => {
          return (
            <TableRow
              className={clsx(
                classes.tbody,
                row.erp_user_id.toUpperCase() ===
                  props.technicianCode.toUpperCase() &&
                  classes.tableRowHighlight
              )}
              tabIndex={-1}
              key={row.erp_user_id}
              id={row.erp_user_id}
              ref={props.testRef}
            >
              <TableCell className={classes.nowrapTechnician}>{row.erp_user_id}</TableCell>
              <TableCell className={classes.nowrapTechnician}>{row.name}</TableCell>
              <TableCell className={classes.nowrapTechnician} align="right">{row.allocated_qty}</TableCell>
              <TableCell className={classes.nowrapTechnician} align="right">{row.repair_start_qty}</TableCell>
              <TableCell className={classes.nowrapTechnician} align="right">{row.quotation_open_qty}</TableCell>
              <TableCell className={classes.nowrapTechnician} align="right">{row.quotation_answered_qty}</TableCell>
              <TableCell className={classes.nowrapTechnician} align="right">{row.repair_end_qty}</TableCell>
            </TableRow>
          );
        }
      )}
    </TableBody>
  );
};
