import React, { FC, useState, useEffect } from "react";
import clsx from "clsx";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { SearchControl } from "./components/Jobs.SearchControl";
import { SearchResult } from "./components/Jobs.SearchResult";
import { JobsTechnicialListControl } from "./components/Jobs.TechnicianListControl";
import { JobsTechnicialListResult } from "./components/Jobs.TechnicianListResult";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../modules/hooks/Permissions";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// UI
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// api
import api from "../../modules/api/Api";
import FileSaver from "file-saver";
import {
  getCSVContents,
  getCSVFileName,
  isAllEmpty,
  isAllErrMsgmpty,
  defaultValue,
  createParam,
} from "./methods/Jobs.methods";
// common
import {
  getApiResultStatus,
  getAPIErrorInfo,
} from "../../modules/common.methods";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainHideFlg: {
      display: "none",
    },
  })
);

const defaultTechnicianAggregateInfo = {
  tech_list: [],
  allocated_qty_total: 0,
  repair_start_qty_total: 0,
  quotation_open_qty_total: 0,
  repair_end_qty_total: 0,
};

export const Page: FC = () => {
  // const [alertShow, setAlertShow] = useState(true);
  const [mainShowFlg, setMainShowFlg] = useState(true);
  const [initFlg, setInitFlg] = useState(true);
  const [reloadFlg, setReloadFlg] = useState(false);
  const [searchFlg, setSearchFlg] = useState(false);
  const [reSearchFlg, setReSearchFlg] = useState(false);
  const [isloadingCompleted, setIsloadingCompleted] = useState(false);
  const [forwardToTechnicianFlg, setForwardToTechnicianFlg] = useState(false);
  const [alertMessageList, setAlertMessageList] = useState([]);
  const classes = useStyles();
  const { com } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({ defaultPath: "modules.components.jobs" });
  // search result
  const [resultList, setResultList] = useState<any[]>([]);
  // technician aggregate list
  const [technicianAggregateInfo, setTechnicianAggregateInfo] = useState<any>(
    defaultTechnicianAggregateInfo
  );
  // the technician code of highlight row on the technicianList
  const [technicianCode, setTechnicianCode] = useState("");
  // technician list order
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("erp_user_id");
  const [isClickedSortHeader, setClickedSortHeader] = useState(false);
  const {
    getCommonInfo,
    setCommonInfoFull,
    getCommonConfig,
    setCommonInfo,
    getUserInfoRepair,
    setUserInfoRepair,
    getUserPermissions,
    setUserPermissions,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  let commonInfo = getCommonInfo() as any;
  let config = getCommonConfig() as any;
  let userInfoRepair = getUserInfoRepair() as any;
  let userPermissions = getUserPermissions() as any;

  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const [searchClickFlg, setSearchClickFlg] = useState(false);

  // set permission of jobs
  const setPermission = async () => {
    let localUserPermissions = {};
    if (userPermissions.permission_group) {
      localUserPermissions = userPermissions;
    } else {
      await getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        console.log("r.response:", r.response);
        setUserPermissions(r.response);
      });
    }

    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(userPermissionsInfo);
  };

  useEffect(() => {
    // init search
    if (initFlg && config.token) {
      setInitFlg(false);
      apiLoading(true);
      setPermission();
      let masterCustomerList: any = [];
      let masterTechnicianList: any = [];
      let masterItemList: any = [];
      let masterServiceCenterList: any = [];
      let localUserInfoRepair = {};

      let customerLisApi;
      let erpUserListApi;
      let itemListApi;
      let serviceCenterListApi;
      let userInfoRepairApi;

      // fetch master data
      // customer master AM0205
      if (commonInfo.customerList.length === 0) {
        customerLisApi = api
          .getCustomerDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0205 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterCustomerList = response;
            }
          });
      } else {
        masterCustomerList = commonInfo.customerList;
      }

      // erp user master(technician) AM0405
      if (commonInfo.technicianList.length === 0) {
        erpUserListApi = api
          .getERPUserDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0405 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterTechnicianList = response;
            }
          });
      } else {
        masterTechnicianList = commonInfo.technicianList;
      }

      // item master AM0305
      if (commonInfo.itemList.length === 0) {
        itemListApi = api
          .getItemDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0305 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterItemList = response;
            }
          });
      } else {
        masterItemList = commonInfo.itemList;
      }

      // service center master AM0705
      if (commonInfo.serviceCenterList.length === 0) {
        serviceCenterListApi = api
          .getServiceCenterDetailListAPI(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0705 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = [obj];
              setAlertMessageList(alertMessageListNew);
            } else {
              masterServiceCenterList = response;
            }
          });
      } else {
        masterServiceCenterList = commonInfo.serviceCenterList;
      }

      // service center master AM0108
      if (!userInfoRepair.service_center) {
        userInfoRepairApi = api
          .getReadUserInfoAPIForUserinfoRepair(config)
          .then(({ response, code }: { response: any; code: any }) => {
            console.log("AM0108 response: ", response);
            if (code !== 200) {
              const obj: any = {
                no: alertMessageList.length,
                message: com("messages." + response.message_id, {
                  param1: com("messages_key_name." + response.key),
                }),
                alertType: "error",
              };
              let alertMessageListNew: any = alertMessageList.slice(0);
              alertMessageListNew.push(obj);
              setAlertMessageList(alertMessageListNew);
            } else {
              localUserInfoRepair = response;
            }
          });
      } else {
        localUserInfoRepair = userInfoRepair;
      }

      Promise.all([
        customerLisApi,
        erpUserListApi,
        itemListApi,
        serviceCenterListApi,
        userInfoRepairApi,
      ]).then(() => {
        setCommonInfoFull({
          customerList: masterCustomerList,
          technicianList: masterTechnicianList,
          itemList: masterItemList,
          serviceCenterList: masterServiceCenterList,
          loadingFlg: false,
        });
        setUserInfoRepair(localUserInfoRepair);
        setIsloadingCompleted(true);
      });
    }
  });

  // fetch the TechnicianAggregateInfo AT0107
  const fetchTechnicianAggregateInfo = () => {
    api
      .getReadRepairJobTechList(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT0107 response: ", response);
        if (code !== 200) {
          const obj: any = {
            no: alertMessageList.length,
            message: com("messages." + response.message_id, {
              param1: com("messages_key_name." + response.key),
            }),
            alertType: "error",
          };
          let alertMessageListNew: any = [obj];
          setAlertMessageList(alertMessageListNew);
        } else {
          setTechnicianAggregateInfo(response);
          // setTechnicianAggregateInfo(technicianAggregateInformation);
        }
      });
  };

  const handleTechnicianListShow = () => {
    setMainShowFlg(false);
    setAlertMessageList([]);
    setTechnicianCode("");
    setForwardToTechnicianFlg(true);
    setOrder("asc");
    setOrderBy("erp_user_id");
    fetchTechnicianAggregateInfo();
  };
  const handleTechnicianListHide = () => {
    setMainShowFlg(true);
    setAlertMessageList([]);
  };
  const handleReload = () => {
    // alert("handleReload");
    setAlertMessageList([]);
    setTechnicianCode("");
    setReloadFlg(true);
    setOrder("asc");
    setOrderBy("erp_user_id");
    fetchTechnicianAggregateInfo();
  };

  // error message
  const errorMessage = (response: any) => {
    const obj: any = {
      no: alertMessageList.length,
      message: com("messages." + response.message_id, {
        param1: com("messages_key_name." + response.key),
      }),
      alertType: "error",
    };
    let alertMessageListNew: any = [obj];
    setAlertMessageList(alertMessageListNew);
  };

  const downloadCSV = (selectedArray: any) => {
    const param: any = selectedArray;
    console.log("test123", selectedArray);
    handleReload();
    api
      .downloadCSVRepairListAPI(param, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("downloadCSVRepairListAPI", response);

        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setCSV(response);
        }
      });
  };

  const setCSV = (data: any) => {
    const str = getCSVContents(com, t, data, config);
    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8",
    });
    const name = "repair_" + getCSVFileName() + ".csv";
    FileSaver.saveAs(blob, name);
  };

  const [searchValueList, setSearchValueList] = useState(defaultValue);
  const downloadCSVBySearch = () => {
    setAlertMessageList([]);
    if (isAllEmpty(searchValueList)) {
      const obj: any = {
        no: alertMessageList.length,
        message: com("messages.MS0007"),
        alertType: "error",
      };
      let alertMessageListNew: any = [obj];
      setAlertMessageList(alertMessageListNew);
    } else if (isAllErrMsgmpty(searchValueList)) {
      let param: any = createParam(searchValueList, false);
      apiLoading(true);
      let _params: any = createParam(searchValueList, false);
      api
        .getDownloadJobRepairByS3singedUrlApi(config, _params)
        .then(({ response, code }: { response: any; code: any }) => {
          if (!getApiResultStatus(code)) {
            apiLoading(false);
            response = getAPIErrorInfo(
              response,
              "getDownloadJobRepairByS3singedUrlApi"
            );
            const obj: any = {
              no: alertMessageList.length,
              message: com("messages." + response.message_id, {
                param1: com("messages_key_name." + response.key),
              }),
              alertType: "error",
            };

            let alertMessageListNew: any = [obj];
            setAlertMessageList(alertMessageListNew);
          } else {
            const url = response.content;
            api
              .getDownloadFile(url)
              .then(({ code, response }: { code: any; response: any }) => {
                if (!getApiResultStatus(code)) {
                  const obj: any = {
                    no: alertMessageList.length,
                    message: com("messages." + response.message_id, {
                      param1: com("messages_key_name." + response.key),
                    }),
                    alertType: "error",
                  };
                  apiLoading(false);
                  let alertMessageListNew: any = [obj];
                  setAlertMessageList(alertMessageListNew);
                } else {
                  let reader: any = new FileReader();
                  reader.readAsText(response, "UTF-8");
                  reader.onload = function () {
                    let data = this.result;
                    setCSV(JSON.parse(data));
                    apiLoading(false);
                  };
                }
              });
          }
        });
    }
  };

  const contents = (
    <div id="contents">
      {alertMessageList.length > 0 &&
        alertMessageList.map((item: any) => (
          <AlertMesssageBox
            key={item.no}
            show={item.message}
            message={item.message}
            type={item.alertType}
          />
        ))}
      <div className={clsx(!mainShowFlg && classes.mainHideFlg)}>
        <SearchControl
          setResultList={setResultList}
          initFlg={initFlg}
          setInitFlg={setInitFlg}
          setAlertMessageList={setAlertMessageList}
          alertMessageList={alertMessageList}
          isloadingCompleted={isloadingCompleted}
          setSearchFlg={setSearchFlg}
          reSearchFlg={reSearchFlg}
          setReSearchFlg={setReSearchFlg}
          setSearchClickFlg={setSearchClickFlg}
          userPermisssionInfo={userPermisssionInfo}
          setCSV={setCSV}
          searchValueList={searchValueList}
          setSearchValueList={setSearchValueList}
        />
        <SearchResult
          handleTechnicianList={handleTechnicianListShow}
          data={resultList}
          searchFlg={searchFlg}
          setSearchFlg={setSearchFlg}
          setAlertMessageList={setAlertMessageList}
          alertMessageList={alertMessageList}
          reSearchFlg={reSearchFlg}
          setReSearchFlg={setReSearchFlg}
          userPermisssionInfo={userPermisssionInfo}
          isloadingCompleted={isloadingCompleted}
          searchClickFlg={searchClickFlg}
          setSearchClickFlg={setSearchClickFlg}
          downloadCSV={downloadCSV}
          downloadCSVBySearch={downloadCSVBySearch}
        />
      </div>
      <div className={clsx(mainShowFlg && classes.mainHideFlg)}>
        <JobsTechnicialListControl
          setAlertMessageList={setAlertMessageList}
          alertMessageList={alertMessageList}
          technicianAggregateInfo={technicianAggregateInfo}
          reloadFlg={reloadFlg}
          setReloadFlg={setReloadFlg}
          setTechnicianCode={setTechnicianCode}
          forwardToTechnicianFlg={forwardToTechnicianFlg}
          setForwardToTechnicianFlg={setForwardToTechnicianFlg}
          order={order}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          isClickedSortHeader={isClickedSortHeader}
          setClickedSortHeader={setClickedSortHeader}
        />
        <JobsTechnicialListResult
          technicianAggregateInfo={technicianAggregateInfo}
          handleTechnicianList={handleTechnicianListHide}
          handleReload={handleReload}
          technicianCode={technicianCode}
          order={order}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          isClickedSortHeader={isClickedSortHeader}
          setClickedSortHeader={setClickedSortHeader}
        />
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
