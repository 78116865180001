import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing}: Theme) =>
    createStyles({
        title: {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
        },
        input: {
            fontSize: "0.8em",
        },
        alertTitle: {
            fontSize: "0.8em",
        },
        alertText: {
            fontSize: "1.6em",
        },
    })
);