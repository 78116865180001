import React from "react";
// UI
import { useStyles } from "../hooks/useStyles";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Link from "@material-ui/core/Link";

export const PhotoItem = (props: any) => {
  const classes = useStyles();
  const { repair_media } = props;

  if (repair_media && repair_media.length < 1) {
    return null;
  }
  const list = repair_media.map((item: any, index: number) => (
    <li key={item.uid} className={classes.photoListItem}>
      {item.updated_date ? (
        <Link onClick={() => props.downloadPhoto(item)}>{item.media_name}</Link>
      ) : (
        <span>{item.media_name}</span>
      )}
      <IconButton onClick={() => props.photoDelete(item, index)}>
        <Close />
      </IconButton>
    </li>
  ));

  return list;
};
