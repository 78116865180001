import { http } from "./Http";
import { methods } from "../constants/common.constants";

// AM2101
export const GetReadPromotionListAPI = (config: any, query_parameters: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

// AM2102
export const GetReadPromotionAPI = (config: any, promotion_no: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/${affiliate_cd}/${promotion_no}`,
    config: config,
  };
  return http(param);
};

// AM2103
export const CreatePromotionAPI = (config: any, data: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/${affiliate_cd}`,
    config: config,
    method: methods.post,
    data: data,
    flg: true,
  };
  return http(param);
};

// AM2104
export const UpdatePromotionAPI = (
  config: any,
  promotion_no: any,
  data: any
) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/${affiliate_cd}/${promotion_no}`,
    config: config,
    method: methods.patch,
    data: data,
    flg: true,
  };
  return http(param);
};

// AM2105
export const DeletePromotionAPI = (
  config: any,
  promotion_no: any,
  data: any
) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/${affiliate_cd}/${promotion_no}`,
    config: config,
    data: data,
    method: methods.delete,
  };
  return http(param);
};

// AM2106
export const GetReadPromotionDraftListAPI = (
  config: any,
  query_parameters: any
) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/draft/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};

// AM2107
export const GetDownloadPromotionAPI = (config: any, data: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/dl/${affiliate_cd}`,
    config: config,
    data: data,
    method: methods.post,
    flg: true,
  };
  return http(param);
};

// AM2108
export const GetReadPaymentTermListAPI = (config: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/paymenttem/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

// 2109
export const GetReadSalesAnalysisCodeListAPI = (config: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/analysis_code/${affiliate_cd}`,
    config: config,
  };
  return http(param);
};

//
export const UploadPromotionCSVAPI = (config: any, data: any) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/up/${affiliate_cd}`,
    config: config,
    data: data,
    method: methods.post,
    flg: true,
  };
  return http(param);
};

//
export const GetPaymentTermInformationApi = (
  config: any,
  query_parameters: any
) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/paymenttem/${affiliate_cd}/${query_parameters}`,
    config: config,
  };
  return http(param);
};

export const GetDownloadPromotionByS3singedUrlApi = (
  config: any,
  query_parameters: any
) => {
  const { baseURLShop, token, affiliate_cd } = config;
  const param = {
    token: token,
    url: `${baseURLShop}/v1/promotions/dls3url/${affiliate_cd}?${query_parameters}`,
    config: config,
  };
  return http(param);
};
