import React from "react";
// hooks
import { useStyles } from "../hooks/useStyles";

import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      "& th": {
        width: "100px",
      },
    },
  })
);

export const JobsDetailCustomerInfoRepairAddressReturn = (props: any) => {
  const classes = useStyles();
  const myClasses = myStyles();
  const {
    formList,
    setFormListValue,
    countriesList,
    formErrorList,
    onBlurOfReturnAddressName,
    onBlurOfReturnAddress2,
    onBlurOfReturnAddress3,
    onBlurOfReturnAddress1,
    onBlurOfReturnPostalCd,
    userPermisssionInfo,
  } = props;
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.customer_info",
  });

  const handleStr = () => {
    let str_tracking_no_return = "";
    if (formList.tracking_no_return !== undefined && formList.tracking_no_return !== "") {
      str_tracking_no_return = formList.tracking_no_return;
      if (str_tracking_no_return.substr(0,1) == "%") {
        str_tracking_no_return = str_tracking_no_return.replace(str_tracking_no_return.substr(0,8), "").replace(str_tracking_no_return.substr(-6,6), "");
      }
    }
    return str_tracking_no_return;
  }

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name_address_return")}</h3>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t("self_pickup")}</th>
            <td>
              <Checkbox
                disabled={!userPermisssionInfo.customer_info_authFlg}
                className={classes.checkboxLayout}
                color="primary"
                checked={formList.addressReturnTo.self_pickup}
                onChange={(e) =>
                  setFormListValue(
                    "addressReturnTo",
                    e.target.checked,
                    "self_pickup"
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <th>
              {t("name")}
              <span>*</span>
            </th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressReturnTo.name}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressReturnTo", e.target.value, "name")
                }
                inputProps={{ maxLength: 30 }}
                onBlur={(e: any) =>onBlurOfReturnAddressName(e.target.value)}
                error={formErrorList.returnTo_name_error !== ""}
                helperText={formErrorList.returnTo_name_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("country")}</th>
            <td>
              <FormControl variant="outlined">
                <Select
                  disabled={!userPermisssionInfo.customer_info_authFlg}
                  id="country_return"
                  value={formList.addressReturnTo.country}
                  onChange={(e) =>
                    setFormListValue(
                      "addressReturnTo",
                      e.target.value,
                      "country"
                    )
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {countriesList.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>{t("address_2")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressReturnTo.address_2}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue(
                    "addressReturnTo",
                    e.target.value,
                    "address_2"
                  )
                }
                onBlur={onBlurOfReturnAddress2}
                error={formErrorList.returnTo_address_2_error !== ""}
                helperText={formErrorList.returnTo_address_2_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("address_3")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressReturnTo.address_3}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue(
                    "addressReturnTo",
                    e.target.value,
                    "address_3"
                  )
                }
                onBlur={onBlurOfReturnAddress3}
                error={formErrorList.returnTo_address_3_error !== ""}
                helperText={formErrorList.returnTo_address_3_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("address_1")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressReturnTo.address_1}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue(
                    "addressReturnTo",
                    e.target.value,
                    "address_1"
                  )
                }
                onBlur={onBlurOfReturnAddress1}
                error={formErrorList.returnTo_address_1_error !== ""}
                helperText={formErrorList.returnTo_address_1_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("postal_cd")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressReturnTo.postal_cd}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue(
                    "addressReturnTo",
                    e.target.value,
                    "postal_cd"
                  )
                }
                onBlur={onBlurOfReturnPostalCd}
                error={formErrorList.returnTo_postal_cd_error !== ""}
                helperText={formErrorList.returnTo_postal_cd_error}
                inputProps={{ maxLength: 9 }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className={`${classes.tableLayout} ${myClasses.tableLayout}`}>
        <tbody>
          <tr>
            <th>{t("return_shipment_date")}</th>
            <td>
              <p>
                {!formList.return_shipment_date ||
                formList.return_shipment_date === ""
                  ? ""
                  : date(new Date(formList.return_shipment_date).getTime())}
              </p>
            </td>
          </tr>
          <tr>
            <th>{t("return_carrier")}</th>
            <td>
              <p>{formList.return_carrier_name}</p>
            </td>
          </tr>
          <tr>
            <th>{t("completed_date")}</th>
            <td>
              <p>
                {!formList.completed_date || formList.completed_date === ""
                  ? ""
                  : date(new Date(formList.completed_date).getTime())}
              </p>
            </td>
          </tr>
          <tr>
            <th>{t("tracking_no_return")}</th>
            <td>
              <p className={classes.pHight}>{handleStr()}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
