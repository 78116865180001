import React, { FC, useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { DatePicker } from "../../../modules/components/datePicker/DatePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
      "& h1": {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "25px"
      },
      "& dl": {
        display: "flex",
        margin: 0,
        marginRight: "20px"
      },
      "& dd": {
        marginLeft: "15px",
        margin: 0
      }
    },
    rows: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      "&:not(:last-child)": {
        marginBottom: "20px"
      }
    },
    datePickerContainer: {
      position: "relative",
      display: "flex",
      marginTop: "-4px !important",
      justifyContent: "space-between",
      width: "150px"
    },
    datePickerArea: {
      "& .MuiInputBase-root": {
        width: "150px"
      }
    },
    datePicker: {
      margin: "0",
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },
      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
      "& .Mui-error": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInputBase-root": {
        borderColor: "#f44336",
      },
      "& .Mui-error.MuiInput-root.Mui-focused": {
        borderColor: "#f44336",
      },
    },
    unlimited: {
      marginTop: "-10px",
    }
  })
);

export const Period = (props: any) => {
  const { t } = useLocale({ defaultPath: "modules.components.promotion" });
  const { com } = useLocale({ defaultPath: "common.constants" });
  const {
    searchValueList,
    setSearchValueList,
    setSearchValue,
    errorValueList,
    setErrorValueList,
    setErrorValue,
    changeDataListen,
    reviewFlg
  } = props;
  const classes = useStyles();

  const onBlurOfStartDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.end_date > 0 &&
      searchValueList.start_date > 0 &&
      searchValueList.start_date > searchValueList.end_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      setErrorValue("start_date_error", message);
    } else {
      changeDataListen("start_date", e.target.value)
    }
  };

  const onBlurOfEndDate = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      searchValueList.end_date > 0 &&
      searchValueList.start_date > 0 &&
      searchValueList.start_date > searchValueList.end_date
    ) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
        end_date_error: "",
      });
      setErrorValueList(errorList);
    } else {
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
        end_date_error: "",
      });
      setErrorValueList(errorList);
    }
  };

  const checkStartDate = (date: any) => {
    if (searchValueList.end_date > 0 && date > searchValueList.end_date) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
        // start_date: date,
      });
      setErrorValueList(errorList);
    } else {
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
        // start_date: date,
      });
      setErrorValueList(errorList);
    }
  };

  const checkEndDate = (date: any) => {
    if (searchValueList.start_date > 0 && searchValueList.start_date > date) {
      let message = com("messages.MS0005", {
        startDate: t("txt_start_date"),
        endDate: t("txt_end_date"),
      });
      let valueList = Object.assign({}, searchValueList, {
        end_date: date,
        limit_type: "0"
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: message,
        end_date_error: "",
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    } else {
      let valueList = Object.assign({}, searchValueList, {
        end_date: date,
        limit_type: "0"
      });
      let errorList = Object.assign({}, errorValueList, {
        start_date_error: "",
        end_date_error: ""
      });
      setSearchValueList(valueList);
      setErrorValueList(errorList);
    }
  };

  return (
    <div className={classes.root}>
      <h1>{t("period")}</h1>
      <div className={classes.rows}>
        <dl className={classes.datePickerArea}>
          <dt>{t("from")}</dt>
          <dd className={classes.datePickerContainer} data-testid="start_date">
            <DatePicker
              value={searchValueList.start_date}
              onChange={(date: any) =>
                setSearchValue("start_date", date)
              }
              onAccept={(date: any) =>
                checkStartDate(new Date(date).setHours(0, 0, 0, 0))
              }
              className={classes.datePicker}
              error={errorValueList.start_date_error !== ""}
              helperText={errorValueList.start_date_error}
              onBlur={(e: any) => {
                onBlurOfStartDate(e)
              }}
              onError={(error: any, value: any) => {
                if (
                  error !== errorValueList.start_date_error &&
                  error !== ""
                ) {
                  setErrorValue("start_date_error", error);
                }
              }}
              disabled={reviewFlg}
            />
          </dd>
        </dl>
        <dl className={classes.datePickerArea}>
          <dt>{t("to")}</dt>
          <dd className={classes.datePickerContainer} data-testid="end_date">
            <DatePicker
              value={searchValueList.end_date}
              onChange={(date: any) => {
                setSearchValue("end_date", date);
              }}
              onAccept={(date: any) =>
                checkEndDate(new Date(date).setHours(23, 59, 59, 999))
              }
              className={classes.datePicker}
              error={errorValueList.end_date_error !== ""}
              helperText={errorValueList.end_date_error}
              onBlur={(e: any) => onBlurOfEndDate(e)}
              onError={(error: any, value: any) => {
                if (
                  error !== errorValueList.end_date_error &&
                  error !== ""
                ) {
                  setErrorValue("end_date_error", error);
                }
              }}
              disabled={reviewFlg}
            />
          </dd>
        </dl>
        <div className={classes.unlimited}>
          <FormControlLabel
            checked={searchValueList.limit_type === "0" ? false : true}
            value="unlimited"
            control={<Checkbox color="primary" />}
            label={t("unlimited")}
            labelPlacement="end"
            disabled={reviewFlg}
            onChange={(e: any) =>
              setSearchValue("limit_type", e.target.checked === false ? "0" : "1")
            }
          />
        </div>
      </div>
    </div>
  );
};
