import React from "react";

// hooks
import { useStyles } from "../hooks/useStyles";
// components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";

export const JobsDetailCustomerInfoRepairAddressBillTo = (props: any) => {
  const classes = useStyles();
  const {
    formList,
    setFormListValue,
    countriesList,
    formErrorList,
    onBlurOfBillToAddressName,
    onBlurOfBillToAddress2,
    onBlurOfBillToAddress3,
    onBlurOfBillToAddress1,
    onBlurOfBillToPostalCd,
    userPermisssionInfo,
  } = props;
  const { t } = useLocale({
    defaultPath: "modules.components.jobDetail.customer_info",
  });

  return (
    <div className={classes.groupArea}>
      <h3 className={classes.groupTitle}>{t("group_name_address_billTo")}</h3>
      <table className={classes.tableLayout}>
        <tbody>
        <tr>
          <th>
          </th>
          <td className={classes.tdHight}>
          </td>
          </tr>
          <tr>
            <th>
              {t("name")}
              <span>*</span>
            </th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressBillTo.name}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressBillTo", e.target.value, "name")
                }
                inputProps={{ maxLength: 30 }}
                onBlur={(e: any) => onBlurOfBillToAddressName(e.target.value)}
                error={formErrorList.billTo_name_error !== ""}
                helperText={formErrorList.billTo_name_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("country")}</th>
            <td>
              <FormControl variant="outlined">
                <Select
                  disabled={!userPermisssionInfo.customer_info_authFlg}
                  id="country_billTo"
                  value={formList.addressBillTo.country}
                  onChange={(e) =>
                    setFormListValue("addressBillTo", e.target.value, "country")
                  }
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {countriesList.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>{t("address_2")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressBillTo.address_2}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressBillTo", e.target.value, "address_2")
                }
                onBlur={(e: any) => onBlurOfBillToAddress2(e.target.value)}
                error={formErrorList.billTo_address_2_error !== ""}
                helperText={formErrorList.billTo_address_2_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("address_3")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressBillTo.address_3}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressBillTo", e.target.value, "address_3")
                }
                onBlur={(e: any) => onBlurOfBillToAddress3(e.target.value)}
                error={formErrorList.billTo_address_3_error !== ""}
                helperText={formErrorList.billTo_address_3_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("address_1")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressBillTo.address_1}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressBillTo", e.target.value, "address_1")
                }
                onBlur={(e: any) => onBlurOfBillToAddress1(e.target.value)}
                error={formErrorList.billTo_address_1_error !== ""}
                helperText={formErrorList.billTo_address_1_error}
              />
            </td>
          </tr>
          <tr>
            <th>{t("postal_cd")}</th>
            <td>
              <TextField
                disabled={!userPermisssionInfo.customer_info_authFlg}
                value={formList.addressBillTo.postal_cd}
                variant="outlined"
                onChange={(e) =>
                  setFormListValue("addressBillTo", e.target.value, "postal_cd")
                }
                onBlur={(e: any) => onBlurOfBillToPostalCd(e.target.value)}
                error={formErrorList.billTo_postal_cd_error !== ""}
                helperText={formErrorList.billTo_postal_cd_error}
                inputProps={{ maxLength: 9 }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
