import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableLayout: {
      width: "100%",
      "& th": {
        textAlign: "left",
        fontWeight: "normal",
        verticalAlign: "top",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        width: "30%",
        minWidth: "150px",
      },
      "& th span": {
        color: "red",
      },
      "& td": {
        paddingBottom: "10px",
        position: "relative",
      },
      "& .MuiOutlinedInput-input": {
        width: "100%",
        padding: "0",
        paddingLeft: "5px",
        height: "30px",
        lineHeight: "30px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& p": {
        margin: 0,
        color: "#686868",
      },
    },
    checkboxLayout: {
      padding: "0",
    },
    groupContainer: {
      maxHeight: 250,
      borderTop: "1px solid rgba(198, 209, 221, 0.5)",
      borderBottom: "1px solid rgba(198, 209, 221, 0.5)",
    },
    groupArea: {
      marginTop: "20px",
      border: "1px solid #767676",
      padding: "20px 10px 10px 10px",
      borderRadius: "10px",
      position: "relative",
      "& .MuiOutlinedInput-multiline": {
        padding: "0",
      },
      "& textarea": {
        width: "100%",
      },
    },
    groupTitle: {
      position: "absolute",
      top: "-20px",
      left: "5px",
      fontSize: "12px",
      background: "#fff",
      fontWeight: "normal",
      padding: "0 5px",
    },
    buttonArea: {
      position: "absolute",
      top: "20px",
      right: "10px",
      width: "155px",
    },
    multilineTextField: {
      width: "100%",
      "& .MuiOutlinedInput-multiline": {
        padding: "5px!important",
      },
    },
    RepairOption: {
      width: "100%",
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    thead2: {
      "& th": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& th:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    tbody: {
      "& td": {
        borderLeft: "1px solid rgba(198, 209, 221, 0.5)",
      },
      "& td:last-child": {
        borderRight: "1px solid rgba(198, 209, 221, 0.5)",
      },
    },
    dateCell: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    DescriptionCell: {
      wordBreak: "break-all",
      whiteSpace: "break-spaces",
    },
    iconCell: {
      textAlign: "center",
      padding: 0,
      width: "30px",
      "& button": {
        padding: 0,
      },
    },
    groupTopButton: {
      display: "flex",
      paddingBottom: "10px",
      justifyContent: "flex-end",
    },
    datePickerComponent: {
      margin: 0,
      "& .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        height: "30px",
        overflow: "hidden",
        padding: "5px",
      },

      "& .MuiInput-root:before": { display: "none" },
      "& .MuiInput-root:after": { display: "none" },
      "&:hover .MuiInput-root": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "& .MuiInput-root.Mui-focused": {
        border: "2px solid #3D899F",
      },
    },
    customerInputTd: {
      minWidth: "80px",
      width: "17%",
    },
    customerSearchTd: {
      minWidth: "40px",
      width: "8%",
    },
    customerSearchBtn: {
      minWidth: "35px",
      width: "8%",
      "& .MuiButton-startIcon": {
        marginLeft: "0",
        marginRight: "0",
      },
    },
    generalBlankTd: {
      minWidth: "85px",
      width: "25%",
    },
    uploadInput: {
      display: "none",
    },
    generalDateTd: {
      minWidth: "155px",
    },
  })
);
