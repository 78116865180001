import React, { useState, useEffect } from "react";
// components
import { ClaimDetailPartsSvgMessage } from "./ClaimDetail.Parts.SvgMessage";
import { ClaimDetailPartsTechnicalInformation } from "./ClaimDetail.Parts.TechnicalInformation";
import { ClaimDetailPartsModel } from "./ClaimDetail.Parts.Model";
import { ClaimDetailPartsTableControl } from "./ClaimDetail.Parts.TableControl";
import { ClaimDetailPartsRegisterThead } from "./ClaimDetail.Parts.Register.Thead";
import { ClaimDetailPartsRegisterTbody } from "./ClaimDetail.Parts.Register.Tbody";
import { ClaimDetailPartsControl } from "./ClaimDetail.Parts.Parts.Control";
// UI
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { ClaimDetailPartsThead } from "./ClaimDetail.Parts.Thead";
import { ClaimDetailPartsTbody } from "./ClaimDetail.Parts.Tbody";
// css
import { useStylesTop } from "../hooks/PartsTab.styles";
// api
import api from "../../../modules/api/Api";
// common
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
import { getLocale } from "../../../modules/i18n";
// method
import {
  getApiResultStatus,
  IsBlank,
  getRegionPullDownDefault,
  getRegionFunc,
  determineDataType,
} from "../../../modules/common.methods";
import {
  init,
  getSvgPath,
  judgeSuspend,
  zoom,
  handleTableMouseEvent,
  setTableSelectedListFun,
  setSvgSelectedListFun,
  getSelectedCurrectMessage,
  handleTableDoubleClickEvent,
  handleSvgDoubleClickEvent,
  addReigsterMessage,
  deleteReigsterMessage,
  handleClickEventBySvgFunc,
  setDefaultSVGandPartsFunc,
  // getPartsDrawingData,
  // getPriceParam,
  editAdditionalItem,
} from "../methods/ClaimDetail.Parts.Methods";
// constants
import { zoomScale, layerList } from "../constants/ClaimDetail.Parts.constants";
// import { PARTS_LIST } from "../../../modules/constants/claim.constants";
import { priceOutputByCountryCode } from "../../../modules/components/numberFormat/NumberFormat.Currency";
//
let dropX = 0;
let dropY = 0;

export const ClaimDetailParts = (props: any) => {
  const classes = useStylesTop();
  const [partsModel, setPartsModel] = useState("");
  const {
    partsInitFlag,
    dataList,
    errorMessage,
    registerData,
    setRegisterData,
    setFormListValue,
    formErrorList,
    setForListFull,
    clearPartsFlag,
    setClearPartsFlag,
    transportMessage,
    setTransportMessage,
    setTransportListValue,
    responseList,
    setFormErrorListValue,
    clearErrorCheck,
    handleGetPrice,
    errorRegisterList,
    setErrorRegisterList,
    itemNoErrorList,
    setItemNoErrorList,
    total,
    setTotal,
    tempPartsList,
    partsDefaultList,
    refreshFlag,
    setRefreshFlag,
  } = props;
  // language
  const { t } = useLocale({
    defaultPath: "modules.components.claim_detail",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });

  // pages
  const [pages, setPages] = useState([]);
  // regionList
  const [regionList, setRegionList] = useState([]);
  const [holdRegionList, setholdRegionList] = useState([]);
  // models
  const [specificationModels, setSpecificationModels] = useState([]);
  // parts
  const [parts, setParts] = useState([]);
  // svg path
  const [svgDocPath, setSvgDocPath] = useState([]);
  // drop style
  const [dropStyle, setDropStyle] = useState({ transform: "translate(0, 0)" });
  // common
  const { getCommonConfig, setCommonInfo, getUserInfoRepair } = useCommonInfo();
  let userInfoRepair = getUserInfoRepair() as any;
  let config = getCommonConfig() as any;
  // loading
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  // svg mouseWheel
  const [wheelList, setWheelList] = useState([]);
  const mouseWheel = (e: any) => {
    e.preventDefault();

    const list = wheelList.slice(0) as any;
    const deltaY: any = e.deltaY;
    list.unshift(deltaY);
    setWheelList(list);
  };
  // svg dragMouseDown
  const dragMouseDown = (e: any) => {
    let strPosX = 0,
      strPosY = 0,
      endPosX = 0,
      endPosY = 0;
    e.preventDefault();
    strPosX = e.clientX;
    strPosY = e.clientY;

    document.onmousemove = function (e2: any) {
      e2.preventDefault();
      endPosX = strPosX - e2.clientX;
      endPosY = strPosY - e2.clientY;
      // Reset Start Position
      strPosX = e2.clientX;
      strPosY = e2.clientY;
      // set the element's new position:
      dropX = dropX - Number(endPosX);
      dropY = dropY - Number(endPosY);
      const tabLayerDrawing2 = document.getElementById("tabLayerDrawing");
      if (tabLayerDrawing2 && tabLayerDrawing2.style) {
        tabLayerDrawing2.style.transform = `translate(${dropX + "px"}, ${dropY + "px"
          })`;
      }
    };

    document.onmouseup = function () {
      // Clear event
      document.onmouseup = null;
      document.onmousemove = null;
    };
  };

  // const [partsDrawingData, setPartsDrawingData] = useState(
  //   getPartsDrawingData()
  // );
  const [innerShowFlg, setInnerShowFlg] = useState(false);
  const [moviesList, setMoviesList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [fullParts, setFullParts] = useState([]);
  // const [active, setActive] = useState<boolean>(false);
  const [region, setRegion] = useState("");
  let msiApiRegion: any;
  let registerDatalist: any = [];
  const requestCountrys: any = process.env.REACT_APP_WEBMSI_REQUEST_COUNTRYS;
  const [changeRegionValue, setChangeRegionValue] = useState("");
  let regionValue = "";
  const [startFlg, setStartFlg] = useState(false);
  var _flg: any;
  var _data: any;
  // start
  useEffect(() => {
    if (partsInitFlag && Object.keys(userInfoRepair).length === 0) {
      apiLoading(true);
    }
    if (
      partsInitFlag &&
      Object.keys(userInfoRepair).length > 0 &&
      !startFlg &&
      Object.keys(dataList).length > 0
    ) {
      setStartFlg(true);
      if (!partsModel || partsModel !== dataList.model_no) {
        apiLoading(true);
        clearParts();
        setPartsModel(dataList.model_no);
        initStart();
      } else {
        apiLoading(false);
      }
    }
  }, [partsInitFlag, userInfoRepair, dataList]);

  useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false);
      setStartFlg(false);
      setPartsModel("");
    }
  }, [refreshFlag]);

  useEffect(() => {
    if (clearPartsFlag) {
      clearParts();
      setClearPartsFlag(false);
    }
  }, [clearPartsFlag]);

  // init
  const initStart = () => {
    let MODEL: any = dataList.model_no;
    console.log("cliamDetail parts", MODEL);
    getModelImage(MODEL);
  };
  const getModelImage = (MODEL: any) => {
    // get list
    let _region: any;
    let _models: any;
    let _needCountrys: any = [];
    api
      .getRegionsForModel(MODEL, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (getApiResultStatus(code)) {
          msiApiRegion = response;

          if (msiApiRegion.regions !== "" && msiApiRegion.regions !== null) {
            if (msiApiRegion.regions.length > 0) {
              msiApiRegion.regions.forEach((item: any) => {
                if (requestCountrys.includes(item)) {
                  _needCountrys.push(item);
                }
              });
              if (_needCountrys.length > 0) {
                setholdRegionList(_needCountrys);
              } else {
                setRegionList([]);
              }
            }
          }
          const registerDatalist = dataList.claim_line
            ? JSON.parse(JSON.stringify(dataList.claim_line))
            : [];
          if (registerDatalist.length > 0) {
            registerDatalist.sort((a: any, b: any) => {
              const d1: any = new Date(Date.parse(a.created_date));
              const d2: any = new Date(Date.parse(b.created_date));
              // create_date同じ場合は、最後挿入したデータが最上位
              if (a.created_date === b.created_date) {
                return b.line_no - a.line_no;
              }
              return d2 - d1;
            });
            if (registerDatalist[0].webmsi_region !== "") {
              _region = registerDatalist[0].webmsi_region;
              _models = registerDatalist[0].webmsi_spec;
              getAllInfomation(_region, _needCountrys, MODEL, _models);
            } else {
              // _region = userInfoRepair.country;
              getAllInfomation(_region, _needCountrys, MODEL);
            }
          } else {
            // _region = userInfoRepair.country;
            getAllInfomation(_region, _needCountrys, MODEL);
          }
        } else {
          apiLoading(false);
        }
      });
  };

  useEffect(() => {
    if (changeRegionValue) {
      regionValue = changeRegionValue;
      //initStart();
      // regions
      setRegionList(holdRegionList);
      getAllInfomation(
        regionValue,
        holdRegionList,
        partsModel,
        models ? models : undefined
      );
    }
  }, [changeRegionValue]);

  const getAllInfomation = (
    _region: any,
    _needCountrys: any,
    MODEL: any,
    _models?: any
  ) => {
    apiLoading(true);
    // get parts Message
    const LOCALE = getLocale();
    let apiList = [];
    let msiApiResult: any;

    _region = getRegionPullDownDefault(
      _needCountrys,
      _region,
      getRegionFunc(config.affiliate_cd, userInfoRepair.country),
      process.env.REACT_APP_SORT_PRIORITY
    );

    console.log("claimDetail parts", MODEL);
    if (!MODEL || !LOCALE || !_region) {
      setInnerShowFlg(false);
      apiLoading(false);
    } else {
      const api1 = getRegionList(MODEL, LOCALE, _region).then(
        ({ code, response }: { code: any; response: any }) => {
          if (getApiResultStatus(code)) {
            if (response.specificationModels.length > 0) {
              msiApiResult = response;
              setRegion(_region);
              regionValue = _region;
              setModels(_models);
            } else {
              return getRegionList(MODEL, LOCALE, userInfoRepair.country).then(
                ({ code, response }: { code: any; response: any }) => {
                  if (getApiResultStatus(code)) {
                    if (response.specificationModels.length > 0) {
                      msiApiResult = response;
                      setRegion(userInfoRepair.country);
                      regionValue = userInfoRepair.country;
                      setModels(_models);
                    } else {
                      return getRegionList(
                        MODEL,
                        LOCALE,
                        _needCountrys[0]
                      ).then(
                        ({ code, response }: { code: any; response: any }) => {
                          if (getApiResultStatus(code)) {
                            msiApiResult = response;
                            setRegion(_needCountrys[0]);
                            regionValue = _needCountrys[0];
                            setModels(_models);
                          } else {
                            apiLoading(false);
                          }
                        }
                      );
                    }
                  } else {
                    apiLoading(false);
                  }
                }
              );
            }
          } else {
            apiLoading(false);
          }
        }
      );
      apiList.push(api1);

      Promise.all(apiList).then(() => {
        console.log("parts api complete", msiApiResult);
        let partsList: any = [];
        if (msiApiResult) {
          try {
            const data: any = init(msiApiResult, _region, _models);
            // pages list
            setPages(data.self_pages);
            // regions
            setRegionList(_needCountrys);
            // models
            setSpecificationModels(data.self_specificationModels);
            // parts
            setParts(data.self_parts);
            setFullParts(data.self_parts);
            setMoviesList(data.technicalInformation_movies);
            setDocumentsList(data.technicalInformation_documents);
            partsList = data.self_parts;
            const pathlist: any = getSvgPath(data.self_drawings);
            setSvgDocPath(pathlist);
            if (data.dataFlg) {
              setInnerShowFlg(false);
            } else {
              setInnerShowFlg(true);
            }
          } catch (e) {
            setInnerShowFlg(false);
          }
        } else {
          setInnerShowFlg(false);
        }
        const registerDatalist = dataList.claim_line ? dataList.claim_line : [];
        if (registerDatalist.length > 0) {
          setCurrency(registerDatalist[0].currency);
          setTotalFunc(registerDatalist, "default");
          setDefaultHighLight(registerDatalist, partsList);
        }
        apiLoading(false);
      });
      const tabLayerId: any = document.getElementById("tabLayerId");
      const tabLayerDrawing = document.getElementById("tabLayerDrawing");
      if (tabLayerId) {
        tabLayerId.addEventListener("wheel", function (e: any) {
          mouseWheel(e);
        });
      }
      if (tabLayerDrawing) {
        tabLayerDrawing.addEventListener("mousedown", function (e: any) {
          dragMouseDown(e);
        });
      }

      return () => {
        if (tabLayerId) {
          tabLayerId.removeEventListener(
            "wheel",
            function (e: any) {
              mouseWheel(e);
            },
            false
          );
        }
        if (tabLayerDrawing) {
          tabLayerDrawing.removeEventListener(
            "mousedown",
            function (e: any) {
              dragMouseDown(e);
            },
            false
          );
        }
      };
    }
  };

  const getRegionList = (MODEL: any, LOCALE: any, _region: any) => {
    return api.getPartsModel(MODEL, LOCALE, _region, config);
  };

  const [changeParts, setChangeParts] = useState([]);

  useEffect(() => {
    const drawingNo: any = changeParts[changeParts.length - 1];
    const currentMessage = getSelectedCurrectMessage(drawingNo, fullParts);
    if (currentMessage.message) {
      var table = document.getElementsByClassName(classes.groupContainer)[0];
      table.scrollTop =
        document.getElementById(currentMessage.message.displayId)?.offsetTop! -
        table.clientHeight / 2;
      const { newTableData, newSvgData } = handleClickEventBySvgFunc(
        currentMessage.message,
        tableSelectedList,
        registerData,
        svgSelectedList
      );
      setTableSelectedList(newTableData);
      setSvgSelectedList(newSvgData);
    }
  }, [changeParts]);
  const [currency, setCurrency] = useState("");

  const setDefaultHighLight = (list: any, partsList: any) => {
    const { newTableData, newSvgData } = setDefaultSVGandPartsFunc(
      list,
      partsList
    );
    if (newTableData.length === 0) {
      setTableSelectedList([""]);
    } else {
      setTableSelectedList(newTableData);
    }

    setSvgSelectedList(newSvgData);
  };

  useEffect(() => {
    const deltaY = wheelList[0];
    if (deltaY !== undefined) {
      let s: any = scale;
      if (deltaY > 0) {
        s = scale - zoomScale;
      } else {
        s = scale + zoomScale;
      }
      s = s < 0 ? 1 : s;
      setScale(s);
      zoom(s);
    }
  }, [wheelList]);

  // doubleClickList
  const [doubleClickList, setDoubleClickList] = useState([]);

  useEffect(() => {
    const drawingNo: any = changeParts[changeParts.length - 1];
    const currentMessage = getSelectedCurrectMessage(drawingNo, fullParts);

    handleDoubleClickBySvgList(currentMessage);
  }, [doubleClickList]);

  async function handleDoubleClickBySvgList(currentMessage: any) {
    if (currentMessage.message) {
      // 登録時のqtyは1とする。
      const { flg, data } = await searchItemNo(
        currentMessage.message.item_no,
        1
      );
      if (flg) {
        if (data) {
          _flg = flg;
          _data = data;
        }
        const { newRegisterData, newSvgData, newTableData } =
          addReigsterMessage(
            ItemNoError,
            data,
            fullParts,
            tableSelectedList,
            svgSelectedList,
            registerData
          );
        setTotalFunc(newRegisterData);
        registerScrollMoving();
        setSvgSelectedList(newSvgData);
        setTableSelectedList(newTableData);
        setSelectedInformation(selected, newRegisterData);
      } else {
        const { newReigsterData, newSvgData, newTableData } =
          handleSvgDoubleClickEvent(
            currentMessage.message,
            registerData,
            svgSelectedList,
            tableSelectedList,
            fullParts
          );
        setTotalFunc(newReigsterData);
        registerScrollMoving();
        setSvgSelectedList(newSvgData);
        setTableSelectedList(newTableData);
        setSelectedInformation(selected, newReigsterData);
      }
    }
  }

  // tab change
  const [currentPages, setCurrentPages] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentPages(newValue);
  };

  let timer: any;
  const registerScrollMoving = () => {
    const element: any = document.getElementById("registerTable");
    const Head: any = document.getElementById("registerHead");
    clearInterval(timer);
    timer = setInterval(setScrollMoving, 1000);
  };

  const setScrollMoving = () => {
    const element: any = document.getElementById("registerTable");
    const top =
      element.scrollHeight > element.clientHeight
        ? element.scrollHeight - element.clientHeight
        : 0;

    if (element.scrollTop + 10 < top) {
      element.scrollTop = top;
    } else {
      clearInterval(timer);
    }
  };

  // svg
  const [emphasizeParts, setEmphasizeParts] = useState("");

  const handleMouseOverEventBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    if (layer === "layer2") {
      handleTableMouseEvent(id, "mouseover");
    }
    const currentMessage = getSelectedCurrectMessage(id, fullParts);
    if (currentMessage.message) {
      setEmphasizeParts(currentMessage.message.uniqueID);
    }
  };
  const handleMouseLeaveEventBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    if (layer === "layer2") {
      handleTableMouseEvent(id, "");
    }
    setEmphasizeParts("");
  };
  const [svgSelectedList, setSvgSelectedList] = useState([]);
  const handleClickEventBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    let newPartsList: any = changeParts.slice(0);
    newPartsList.push(id);
    setChangeParts(newPartsList);
  };
  const handleDoubleClickBySvg = (e: any, node: any, layer: string) => {
    const id = node.dataset.id;
    let newPartsList: any = doubleClickList.slice(0);
    newPartsList.push(id);
    setDoubleClickList(newPartsList);
  };

  // zoom
  const [scale, setScale] = useState(1);
  const zoomIn = () => {
    const s = scale + zoomScale;
    setScale(s);
    zoom(s);
  };
  const zoomOut = () => {
    let s = scale - zoomScale;
    if (s < 0) s = 1;
    setScale(s);
    zoom(s);
  };

  // change regions
  const handleChangeregions = (value: string) => {
    setChangeRegionValue(value);
    setRegion(value);
    console.log("region test", value, models);
  };

  // current model
  const [models, setModels] = useState("Full");

  // change models
  const handleChangeModels = (value: string) => {
    setModels(value);
    const part = specificationModels.filter(
      (f: any) => f.specificationCode === value
    )[0]["parts"];
    setParts(part);
  };

  console.log("region test models", models);

  // table selected list
  const [tableSelectedList, setTableSelectedList] = useState<string[]>([]);

  const handleTableRowClick = (e: any, row: any) => {
    // page
    if (row.page !== undefined && row.page > 0) {
      if (pages.length > 1) {
        setCurrentPages(row.page - 1);
      }
    }
    const newTableData = setTableSelectedListFun(
      row,
      tableSelectedList,
      registerData
    );
    setTableSelectedList(newTableData);
    const newSvgData = setSvgSelectedListFun(
      row,
      svgSelectedList,
      fullParts,
      tableSelectedList,
      registerData
    );
    setSvgSelectedList(newSvgData);
  };

  async function handleDoubleClickByTable(e: any, row: any) {
    if (row.item_no) {
      // 登録時のqtyは1とする。
      const { flg, data } = await searchItemNo(row.item_no, 1);
      if (flg) {
        if (data) {
          _flg = flg;
          _data = data;
        }
        const { newRegisterData, newSvgData, newTableData } =
          addReigsterMessage(
            ItemNoError,
            data,
            fullParts,
            tableSelectedList,
            svgSelectedList,
            registerData
          );
        setTotalFunc(newRegisterData);
        registerScrollMoving();
        setSvgSelectedList(newSvgData);
        setTableSelectedList(newTableData);
        setSelectedInformation(selected, newRegisterData);
      } else {
        const { newReigsterData, newSvgData, newTableData } =
          handleTableDoubleClickEvent(
            row,
            registerData,
            svgSelectedList,
            tableSelectedList,
            fullParts
          );
        setTotalFunc(newReigsterData);
        registerScrollMoving();
        setSvgSelectedList(newSvgData);
        setTableSelectedList(newTableData);
        setSelectedInformation(selected, newReigsterData);
      }

      // page
      if (row.page !== undefined && row.page > 0) {
        if (pages.length > 1) {
          setCurrentPages(row.page - 1);
        }
      }
    }
  }

  // control button's information
  const [itemNo, setItemNo] = useState("");
  const setItemNoValue = (value: any) => setItemNo(value);

  async function handleAddEvent() {
    setItemNoError(false);
    setItemNoErrorMessage("");
    const parts_list: any = partsDefaultList.list_all;
    if (!parts_list.includes(itemNo)) {
      if (itemNo) {
        const { flg, data } = await searchItemNo("", 1);
        if (flg) {
          if (data) {
            data.order_qty = 1;
            _flg = flg;
            _data = data;
          }
          addItem(data);
        }
      }
    } else {
      setItemNoError(true);
      setItemNoErrorMessage(
        com("messages.item_no_add_error", { param1: itemNo })
      );
    }
  }

  const addItem = (data: any) => {
    const { newRegisterData, newSvgData, newTableData } = addReigsterMessage(
      ItemNoError,
      data,
      fullParts,
      tableSelectedList,
      svgSelectedList,
      registerData
    );
    setTotalFunc(newRegisterData, "clickAddButton");
    registerScrollMoving();
    setItemNo("");
    setSvgSelectedList(newSvgData);
    setTableSelectedList(newTableData);
  };

  const handleDeleteEvent = () => {
    const { newTableData, newSvgData, newReigsterList } = deleteReigsterMessage(
      registerData,
      selected,
      fullParts,
      svgSelectedList,
      tableSelectedList
    );
    setTableSelectedList(newTableData);
    setSvgSelectedList(newSvgData);
    setTotalFunc(newReigsterList);
    setSelected([]);
    setChecked(false);
    setIndeterminate(false);
  };

  // register
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = registerData.slice(0);
      const newSelecteds: any = [];
      const parts_list: any = partsDefaultList.list_all;
      if (data.length > 0) {
        data.forEach((item: any, index: number) => {
          if (!parts_list.includes(item.item_no)) {
            newSelecteds.push(Number(index));
          }
        });
      }
      setSelectedInformation(newSelecteds);
      return;
    }
    setSelectedInformation([]);
  };
  const [selected, setSelected] = useState([]);

  const setRegisterDataProperty = (type: string, value: any, index: number) => {
    // priceApi buttonのFlag
    // setFormListValue("partsQtyChangeFlag", true);
    clearErrorCheck("claim_line_error");

    let list = registerData.slice(0);
    list[index] = Object.assign({}, list[index], { [type]: value });
    setTotalFunc(list, "registerData");
  };

  const registerRowClick = (row: any) => {
    if (row !== undefined) {
      const selectedList: any = selected.slice(0);
      const selectedIndex: any = selectedList.indexOf(row);
      let newSelected: string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelectedInformation(newSelected);
    }
  };

  const setSelectedInformation = (list: any, registerList: any = []) => {
    const parts_list: any = partsDefaultList.list_all;

    const registerListMessage =
      registerList.length > 0
        ? registerList.filter((item: any) => !parts_list.includes(item.item_no))
        : registerData.filter(
          (item: any) => !parts_list.includes(item.item_no)
        );
    setSelected(list);
    const flg = list.length > 0 && list.length < registerListMessage.length;
    setIndeterminate(flg);
    const check = list.length > 0 && list.length === registerListMessage.length;
    setChecked(check);
  };

  const [ItemNoError, setItemNoError] = useState(false);
  const [ItemNoErrorMessage, setItemNoErrorMessage] = useState("");

  const searchItemNo = (_itemNo: any = "", order_qty: any, clickType?: any) => {
    let tableFlg = true;
    if (!_itemNo) {
      tableFlg = false;
      _itemNo = itemNo;
    }
    if (_itemNo) {
      if (clickType === "clickAddButton") {
        order_qty = 1;
      }

      let query_parameters =
        "customer_no=" +
        encodeURIComponent(responseList.customer_no) +
        "&item_no=" +
        encodeURIComponent(_itemNo.trim()) +
        "&order_qty=" +
        encodeURIComponent(order_qty);

      // get item number
      // AM0302
      return api
        .getAlternateItemAPI(query_parameters, config)
        .then(({ code, response }: { code: any; response: any }) => {
          if (clickType === "clickAddButton") {
            apiLoading(false);
          }
          if (!getApiResultStatus(code)) {
            if (code === 404) {
              if (!tableFlg) {
                setItemNoError(true);
                const message = com("messages." + response.message_id, {
                  param1: itemNo,
                  rowNum: t("Item_No"),
                });
                setItemNoErrorMessage(message);
              }
            }
            return {
              flg: false,
              data: null,
            };
          } else {
            if (response.item_no !== undefined && response.item_no !== "") {
              let FlgFor999999 = false;
              let getResult = Object.values(response);
              for (let i = 0; i < getResult.length; i++) {
                if ("999999" === getResult[i]) {
                  FlgFor999999 = true;
                  break;
                }
              }
              if (
                FlgFor999999 ||
                (response.relative_qty === 0 &&
                  response.additional_item_01_qty === 0 &&
                  response.additional_item_02_qty === 0 &&
                  response.additional_item_03_qty === 0 &&
                  response.additional_item_04_qty === 0 &&
                  response.additional_item_05_qty === 0 &&
                  response.additional_item_06_qty === 0 &&
                  response.additional_item_07_qty === 0 &&
                  response.additional_item_08_qty === 0 &&
                  response.additional_item_09_qty === 0 &&
                  response.additional_item_10_qty === 0)
              ) {
                response.order_qty = order_qty;
              } else {
                response = editAdditionalItem(response, order_qty);
              }
            }
            setItemNoError(false);
            setItemNoErrorMessage("");
            return {
              flg: true,
              data: response,
            };
          }
        })
        .then((response: any) => response);
    } else {
      setItemNoError(false);
      setItemNoErrorMessage("");
      return {
        flg: true,
        data: null,
      };
    }
  };

  // Memo
  const getErrorMessageByitem = (row: any) => {
    let message: any;
    if (row.type === "1") {
      message = com("messages.MA0005", {
        param1: t("txt_order_qty"),
        rowNum: `#${row.row} `,
      });
    } else if (row.type === "2") {
      message = com("messages.MS0011", {
        param1: t("txt_order_qty"),
        rowNum: `#${row.row}`,
      });
    } else if (row.type === "3") {
      message = com("messages.MS0011", {
        param1: t(row.param),
        rowNum: `#${row.row}`,
      });
    } else if (row.type === "4") {
      message = `#${row.row} ` + com("messages.ordered_qty_error");
    } else if (row.type === "5") {
      message =
        `#${row.row} ` +
        t("Item_No") +
        com("messages.MS0004", {
          param1: row.item_no,
        });
    } else if (row.type === "6") {
      message =
        `#${row.row} ` +
        com("messages.MS0052", {
          itemNum: row.item_no,
        });
    } else if (row.type === "8") {
      message =
        `#${row.row} ` +
        com("messages.MS0113", {
          alterToItemNo: row.replaced_item_no,
          oldItemNo: row.item_no,
        });
    }
    return message;
  };

  const getErrorMessageByInfo = () => {
    let list: any = [];
    if (itemNoErrorList.length > 0 && errorRegisterList.length === 0) {
      list = itemNoErrorList.slice(0);
    } else if (errorRegisterList.length > 0 && itemNoErrorList.length === 0) {
      list = errorRegisterList.slice(0);
    } else {
      list = []
        .concat(itemNoErrorList, errorRegisterList)
        .sort((x: any, y: any): any => {
          return x.row - y.row;
        });
    }
    return list;
  };

  const errorRegisterMessage = getErrorMessageByInfo().map(
    (item: any, index: number) => (
      <div
        className={
          item.type !== "6" && item.type !== "8"
            ? classes.registerError
            : classes.registerErrorOrange
        }
      >
        <p key={index}>{getErrorMessageByitem(item)}</p>
      </div>
    )
  );

  // message box
  async function setTotalFunc(list: any, type: any = "") {
    let t: any = 0;
    let newList = list.slice(0);
    let errorList: any = [];
    if (list.length > 0) {
      for (let i = 0; i < newList.length; i++) {
        let item = newList[i];
        const amount: any =
          !IsBlank(item.order_qty) &&
            !IsBlank(item.net_price) &&
            !isNaN(item.order_qty) &&
            !isNaN(item.net_price)
            ? Number(item.order_qty) * Number(item.net_price)
            : 0;
        t = t + amount;
        // item.amount = amount ? amount.toFixed(2) : 0;
        if (type === "default") {
          item.rightFlg = Boolean(item.net_price);
          if (typeof item.net_price === "number") {
            //item.net_price = item.net_price.toFixed(2);
          }
        }

        // diff exist check
        let diffFlg = true;
        if (registerData.length > 0) {
          let diffList = registerData.filter(
            (oldItem: any) => String(oldItem.item_no) === String(item.item_no)
          );
          if (diffList.length === 0) {
            diffFlg = true;
          } else {
            diffFlg = false;
          }
        }
        if (diffFlg) {
          if (
            type === "default" ||
            type === "addDefaultMessage" ||
            type === "registerData"
          ) {
            //do nothing
          } else {
            if (!item["webmsi_region"]) {
              item["webmsi_region"] = region;
            }
            if (!item["webmsi_spec"]) {
              item["webmsi_spec"] =
                models === undefined || models === "" ? "Full" : models;
            }
            if (type === "clickAddButton") {
              // when click button
              // const { flg, data } = await searchItemNo(
              //   item.item_no,
              //   "clickAddButton"
              // );
              errorList = judgeSuspend(_flg, item, i, errorList);
            } else {
              //const { flg, data } = await searchItemNo(item.item_no, item.order_qty);
              errorList = judgeSuspend(_flg, item, i, errorList);
            }
          }
        } else {
          if (!item.suspend_exist) {
            const _list = list.filter(
              (subItem: any) => subItem.item_no === item.item_no
            );

            if (_list.length > 0) {
              item.suspend_exist = _list[0].suspend_exist;
            }
          }

          if (item.suspend_exist === "1") {
            errorList.push({
              row: i + 1,
              type: "5",
              item_no: item.item_no,
            });
          } else if (item.suspend_exist === "2") {
            // errorList.push({
            //   row: i + 1,
            //   type: "6",
            //   item_no: item.item_no,
            // });
          }

          if (
            item.replaced_item_no !== undefined &&
            item.replaced_item_no !== ""
          ) {
            errorList.push({
              row: i + 1,
              type: "8",
              item_no: item.replaced_item_no,
              replaced_item_no: item.item_no,
            });
          }
        }
      }
    }
    setItemNoErrorList(errorList);
    if (type !== "registerData") {
      if (newList.length > 0) {
        for (let j = 0; j < newList.length; j++) {
          afterChangeCheck(j, j, newList);
        }
      }
    }
    if (newList.length === 0) {
      setItemNoErrorList([]);
      setErrorRegisterList([]);
    }
    t = (t as number).toFixed(2);
    setRegisterData(newList);
    setTotal(t);
    apiLoading(false);
  }

  const afterChangeCheck = (row: any, index: number, getCheckList: any) => {
    const newList = getCheckList.slice(0);
    let errorList: any = [];
    newList.forEach((item: any, subIndex: number) => {
      if (IsBlank(item.order_qty) || item.order_qty === "0") {
        const error = {
          row: subIndex + 1,
          type: "1",
        };
        errorList.push(error);
      } else {
        if (isNaN(item.order_qty)) {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_order_qty",
          };
          errorList.push(error);
        }
      }

      if (
        !IsBlank(item.order_qty) &&
        !IsBlank(item.allocated_qty) &&
        item.order_qty < item.allocated_qty
      ) {
        const error = {
          row: subIndex + 1,
          type: "4",
          param: "txt_order_qty",
        };
        errorList.push(error);
      }

      if (!IsBlank(item.net_price)) {
        const price: any = Number(item.net_price);
        if (!isNaN(price)) {
          //item.net_price = price.toFixed(2);
        } else {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_Price",
          };
          errorList.push(error);
        }
      }
    });
    setErrorRegisterList(errorList);
  };

  const openLink = (url: any, type: any = "") => {
    // apiLoading(true);
    api
      .getFileUrl(url, config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          const url = response.url;
          if (url) {
            window.open(url);
          }
        }
      });
  };

  const setRegisterProjectBlur = (row: any, index: number) => {
    const newList = registerData.slice(0);
    let errorList: any = [];
    newList.forEach((item: any, subIndex: number) => {
      if (IsBlank(item.order_qty) || item.order_qty === "0") {
        const error = {
          row: subIndex + 1,
          type: "1",
        };
        errorList.push(error);
      } else {
        if (isNaN(item.order_qty)) {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_order_qty",
          };
          errorList.push(error);
        }
      }

      if (
        !IsBlank(item.order_qty) &&
        !IsBlank(item.allocated_qty) &&
        item.order_qty < item.allocated_qty
      ) {
        const error = {
          row: subIndex + 1,
          type: "4",
          param: "txt_order_qty",
        };
        errorList.push(error);
      }

      if (!IsBlank(item.net_price)) {
        const price: any = Number(item.net_price);
        if (!isNaN(price)) {
          // item.net_price = price.toFixed(2);
          item.net_price_format = priceOutputByCountryCode(item.net_price);
          console.log("priceOutputByCountryCode", item);
        } else {
          const error = {
            row: subIndex + 1,
            type: "3",
            param: "txt_Price",
          };
          errorList.push(error);
        }
      }

      // 数字に変更
      item.order_qty = Number(item.order_qty);

      // 該当なレコードは手動で編集フラグ判別
      if (subIndex === index) {
        let _manually_update_flg = false;
        if (!item.manually_update_flg) {
          if (item.line_no) {
            let _oldList =
              tempPartsList.length > 0
                ? tempPartsList.filter(
                  (_item: any) =>
                    item.line_no === _item.line_no &&
                    item.item_no === _item.item_no
                )
                : [];
            let _responseList =
              responseList.claim_line.length > 0
                ? responseList.claim_line.filter(
                  (_item: any) =>
                    item.line_no === _item.line_no &&
                    item.item_no === _item.item_no
                )
                : [];

            if (_oldList.length > 0) {
              item.manually_update_flg =
                Number(_oldList[0].net_price) !== Number(item.net_price);
              console.log(
                "newList update temp",
                _oldList,
                item.manually_update_flg,
                item.item_no,
                Number(_oldList[0].net_price),
                Number(item.net_price)
              );
            } else if (_responseList.length > 0) {
              item.manually_update_flg =
                Number(_responseList[0].net_price) !== Number(item.net_price);
              console.log(
                "newList update response",
                _responseList,
                item.manually_update_flg,
                item.item_no
              );
            } else {
              _manually_update_flg = true;
            }
          } else {
            _manually_update_flg = true;
          }

          if (_manually_update_flg) {
            item.manually_update_flg =
              item.net_price !== undefined &&
              item.net_price !== "" &&
              Number(item.net_price) !== 0;
            console.log(
              "newList create",
              item.manually_update_flg,
              item.item_no,
              tempPartsList
            );
          }
        }
      }
    });
    console.log("newList222", newList, responseList.claim_line, tempPartsList);
    setRegisterData(newList);
    setErrorRegisterList(errorList);
  };

  const [svgPaths, setSvgPaths] = useState([]);
  useEffect(() => {
    if (tableSelectedList.length > 0) {
      setSvgPaths(svgDocPath);
    }
  }, [svgDocPath, tableSelectedList]);

  const attentionCheckFlgBlur = () => {
    if (dataList.attention_check_flg) {
      clearErrorCheck("attention_check_flg_error");
    } else {
      const message: any = com("messages.MS0001", {
        param1: t("attention_check_flg"),
      });
      setFormErrorListValue("attention_check_flg_error", message);
    }
  };

  // clear
  const clearParts = () => {
    setStartFlg(false);
    setPages([]);
    setRegionList([]);
    setholdRegionList([]);
    setSpecificationModels([]);
    setParts([]);
    setSvgDocPath([]);
    setDropStyle({ transform: "translate(0, 0)" });
    setWheelList([]);
    // setPartsDrawingData(getPartsDrawingData());
    setInnerShowFlg(false);
    setMoviesList([]);
    setDocumentsList([]);
    setFullParts([]);
    // setActive(false);
    setRegion("");
    setModels("Full");
    setCurrency("");
    setSvgSelectedList([]);
    setErrorRegisterList([]);
    setItemNoErrorList([]);
    setCurrentPages(0);
    setSvgPaths([]);
    setChangeParts([]);
    setDoubleClickList([]);
    setEmphasizeParts("");
    setScale(1);
    setTableSelectedList([]);
    setItemNo("");
    setIndeterminate(false);
    setChecked(false);
    setSelected([]);
    setItemNoError(false);
    setItemNoErrorMessage("");
    setTotal("");
  };

  const rightAreaTop = (
    <>
      <ClaimDetailPartsModel
        region={region}
        models={models}
        regionTitle={t("txt_region")}
        title={t("txt_Spec")}
        documentTitle={t("txt_TechnicalInformationDocument")}
        regionList={regionList}
        handleChangeregions={handleChangeregions}
        specificationModels={specificationModels}
        handleChangeModels={handleChangeModels}
        documentsList={documentsList}
        documentClick={openLink}
      />
      <div>
        <TableContainer className={classes.groupContainer}>
          <Table stickyHeader>
            <ClaimDetailPartsThead />
            <ClaimDetailPartsTbody
              data={parts}
              handleClick={handleTableRowClick}
              tableSelectedList={tableSelectedList}
              emphasizeParts={emphasizeParts}
              handleMouseOver={handleTableMouseEvent}
              handleMouseleave={handleTableMouseEvent}
              handleDoubleClick={handleDoubleClickByTable}
              openLink={openLink}
            />
          </Table>
        </TableContainer>
      </div>
    </>
  );

  const rightAreaBottom = (
    <>
      <ClaimDetailPartsTableControl
        itemNo={itemNo}
        setItemNoValue={setItemNoValue}
        handleAddEvent={handleAddEvent}
        handleDeleteEvent={handleDeleteEvent}
        error={ItemNoError}
        errorMessage={ItemNoErrorMessage}
        addButtonDisabled={true}
        deleteButtonDisabled={true}
        itemNoName={t("Item_No")}
        AddButtonName={t("AddButtonName")}
        DeleteButtonName={t("DeleteButtonName")}
      />
      <div>
        {errorRegisterList.length > 0 || itemNoErrorList.length > 0
          ? errorRegisterMessage
          : null}
        <TableContainer className={classes.groupContainer} id="registerTable">
          <Table stickyHeader>
            <ClaimDetailPartsRegisterThead
              indeterminate={indeterminate}
              checked={checked}
              onSelectAllClick={onSelectAllClick}
              totalName={t("txt_Total") + ":"}
              total={total}
              currency={dataList.currency}
            />
            <ClaimDetailPartsRegisterTbody
              data={registerData}
              selected={selected}
              setRegisterData={setRegisterData}
              setRegisterDataProperty={setRegisterDataProperty}
              registerRowClick={registerRowClick}
              setRegisterProjectBlur={setRegisterProjectBlur}
              dataList={dataList}
              partsListAll={partsDefaultList.list_all}
            />
          </Table>
        </TableContainer>
        {formErrorList.claim_line_error !== "" && (
          <p className={classes.register_error_message}>
            {formErrorList.claim_line_error}
          </p>
        )}
      </div>
      <div>
        <ClaimDetailPartsControl
          handleGetPrice={handleGetPrice}
          errorRegisterList={errorRegisterList}
          dataList={dataList}
          setFormListValue={setFormListValue}
          formErrorList={formErrorList}
          responseList={responseList}
          errorMessage={errorMessage}
          transportMessage={transportMessage}
          setTransportMessage={setTransportMessage}
          setTransportListValue={setTransportListValue}
          setFormErrorListValue={setFormErrorListValue}
          apiLoading={apiLoading}
          attentionCheckFlgBlur={attentionCheckFlgBlur}
          clearErrorCheck={clearErrorCheck}
          itemNoErrorList={itemNoErrorList}
          freightList={partsDefaultList.freight_list}
          region={region}
          models={models}
        />
      </div>
    </>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {moviesList && moviesList.length > 0 && innerShowFlg ? (
            <ClaimDetailPartsTechnicalInformation
              title={t("txt_TechnicalInformation")}
              moviesList={moviesList}
              moviesClick={openLink}
            />
          ) : null}
          {
            <div className={classes.svgArea}>
              <Tabs
                value={currentPages}
                onChange={handleTabChange}
                className={classes.tabArea}
              >
                {determineDataType(pages) &&
                  pages.map((item) => (
                    <Tab
                      label={item}
                      key={item}
                      className={classes.tabButton}
                    />
                  ))}
              </Tabs>
              <div
                className={innerShowFlg ? classes.tabLayer : undefined}
                id="tabLayerId"
              >
                <div
                  className={classes.drawingContainer}
                  id="tabLayerDrawing"
                  style={dropStyle}
                >
                  {determineDataType(svgPaths) &&
                    svgPaths.map((item: any, index: number) => (
                      <div
                        className={classes.layerContainer}
                        hidden={currentPages !== index}
                        key={index}
                        id="svgContainer"
                      >
                        {determineDataType(layerList) &&
                          layerList.map((subItem) => {
                            return (
                              <ClaimDetailPartsSvgMessage
                                key={item[subItem]}
                                path={item[subItem]}
                                layer={subItem}
                                handleMouseOver={handleMouseOverEventBySvg}
                                handleMouseLeave={handleMouseLeaveEventBySvg}
                                handleClick={handleClickEventBySvg}
                                handleDoubleClick={handleDoubleClickBySvg}
                                selected={tableSelectedList}
                                contentsUrl={config.webMSIContentsURL}
                              />
                            );
                          })}
                      </div>
                    ))}
                </div>
                {innerShowFlg && (
                  <div className={classes.zoomButtonContainer}>
                    <IconButton onClick={zoomIn}>
                      <ZoomIn className={classes.zoomIn}> </ZoomIn>
                    </IconButton>
                    <IconButton onClick={zoomOut}>
                      <ZoomOut className={classes.zoomOut}></ZoomOut>
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          }
        </Grid>
        <Grid item xs={8} className={classes.rightArea}>
          {innerShowFlg && rightAreaTop}
          {rightAreaBottom}
        </Grid>
      </Grid>
    </>
  );
};
