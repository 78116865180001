import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerLayer: {
      width: "100%",
      height: "200px",
      margin: "0 auto",
      border: "1px solid #ccc",
      borderRadius: "15px",
    },
    titleBox: {
      width: "100%",
      height: "20%",
      background: "#008CA2",
      position: "relative",
      borderRadius: "15px 15px 0px 0px",
    },
    titleBody: {
      color: "#FFFFFF",
      textAlign: "left",
      marginLeft: "10%",
      display: "flex",
      justifyContent: "flex-start",
      "& :first-child": {
        color: "#fff",
      },
    },
    icon: {
      marginTop: "7px",
    },
    iconSvg: {
      marginTop: "7px",
      width: "80px",
      height: "70px",
    },
    iconSvgPhoto: {
      marginTop: "7px",
      width: "80px",
      height: "74px",
    },
    text: {
      marginTop: "9px",
      fontSize: "16px",
      marginLeft: "5px"
    },
    messageDetail: {
      overflow: "auto",
      height: "148px",
      wordBreak: "break-all",
      marginLeft: "0",
      padding: "0 10px",
      letterSpacing: "1px",
    },
    media_list: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "auto",
      height: "148px",
      marginLeft: "21px",
    },
    media_item: {
      textAlign: "center",
      padding: "0 10px",
      "& p": {
        margin: "0",
        display: " -webkit-box",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "-webkit-box-orient": "vertical",
        "-webkitLineClamp": "2",
        width: "80px",
      },
    },
    messageDetailList: {
      margin: "0",
      listStyle: "none",
      padding: "0",
      "& li+li": {
        paddingTop: "5px",
        borderTop: "1px solid gray",
      },
    },
    item: { paddingBottom: "5px" },
    message: { margin: "0", paddingBottom: "5px", whiteSpace: "break-spaces" },
    bottomMessage: {
      borderTop: "1px dashed gray",
      paddingTop: "5px",
      display: "flex",
      justifyContent: "space-between",
    },
    date: {
      margin: "0",
    },
    status: {
      margin: "0",
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);
