import React from "react";
// hook
import { useStyles } from "../hooks/table.useStyles";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

type Order = "asc" | "desc";

export const ModelTableTbody = (props: any) => {
  const { pageInfo, order, orderBy } = props;
  const classes = useStyles();

  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);

        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]"
          ) {
            if (bValues[0] === null && aValues[0] !== null) {
              sort = -1;
            } else if (bValues[0] !== null && aValues[0] === null) {
              sort = 1;
            } else if (bValues[0] < aValues[0]) {
              sort = -1;
            } else if (bValues[0] > aValues[0]) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        // console.log("sort", aValues, bValues);
        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };

  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .map((row: any, index: number) => {
          return (
            <TableRow className={classes.tbody}>
              <TableCell className={classes.nowrap}>
                {row.model}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {row.total_repair}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {row.waranty_repair}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {row.charge_repair}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {row.inspection_for_quote}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {row.percent + "%"}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {row.avr_repair_time}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.top_used_parts}
              </TableCell>
              <TableCell className={classes.contents}>
                {row.description}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
