import React, { FC, useEffect, useState, useCallback } from "react";
import { useStyles } from "./hooks/summary.userStyle";
// route
import { useHistory, useParams } from "react-router-dom";
// components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
import { SummaryStepper } from "./components/Summary.Stepper";
import { SummaryAscInformation } from "./components/Summary.AscInformation";
import { SummaryUserCompanyInformation } from "./components/Summary.UserCompanyInformation";
import { SummaryRepairInformation } from "./components/Summary.RepairInformation";
import { SummaryWarrantyCharges } from "./components/Summary.WarrantyCharges";
import { SummaryMessageToCustomer } from "./components/Summary.MessageToCustomer";
import { SummaryDocuments } from "./components/Summary.Documents";
import { SummaryPhoto } from "./components/Summary.Photo";
import { SummaryUsedPartdTable } from "./components/Summary.UsedParts.Table";
import { ClaimDetailHistory } from "../../modules/components/claimHistory/ClaimHistory";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// api
import api from "../../modules/api/Api";
// methods
import {
  getApiResultStatus,
  getClaimDetailParams,
  handleDiffChangedData,
  diffContents,
  getPartsList,
} from "../../modules/common.methods";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
import {
  DEFAULT_VALUE_BLANK,
  CLAIM_NO_QUERY_STRING,
} from "../../modules/constants/claim.constants";
import { SummaryPopup } from "./components/Summary.Popup";
import { GetPrivateAppRoutePath, PathKey } from "../AppRoutes";
import { setLoborTotal } from "../../modules/methods/claims.methods";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import Permissions from "../../modules/hooks/Permissions";
// change history
import { SummaryChangesHistory } from "./components/Summary.Changes.History";
import { changedHistory } from "./Summary.methods";

export const Page: FC = () => {
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");

  const classes = useStyles();
  const history = useHistory();
  const { getName } = useLocale({ defaultPath: "common.constants" });
  const { t } = useLocale({ defaultPath: "modules.components.claim_detail" });
  const { com } = useLocale();

  const [startFlg, setStartFlg] = useState(false);
  // form DB value
  const [formList, setFormList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );
  const [dataList, setDataList] = useState(
    JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK))
  );

  // page Type=> 0:create, 1:Edit
  const [claimType, setClaimType] = useState(0);
  const [claimStatus, setClaimStatus] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({});
  const [errorType, setErrorType] = useState([]);
  const [repairHistoryList, setRepairHistoryList] = useState([]);
  const [showHistoryFlg, setShowHistoryFlg] = useState(false);
  // common
  const {
    getCommonInfo,
    getCommonConfig,
    setCommonInfo,
    getUserPermissions,
    setUserPermissions,
  } = useCommonInfo();
  let commonInfo = getCommonInfo() as any;
  let config = getCommonConfig() as any;

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();
  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;
  const [changesBeforeMessage, setChangesBeforeMessage] = useState({}) as any;

  const getNewClaimNo = (): string => {
    return history.location.search.replace(CLAIM_NO_QUERY_STRING, "");
  };

  const [transportMessage, setTransportMessage] = useState({
    transport_way_pick_up: false,
    transport_way_return: false,
  });

  // set permission of Summary
  const setPermission = async () => {
    let localUserPermissions = {};
    if (userPermissions.permission_group) {
      localUserPermissions = userPermissions;
    } else {
      await getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
    }

    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    const tempUserPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    setUserPermisssionInfo(tempUserPermissionsInfo);
  };

  const getCustomerInfo = (customer_no: any) => {
    api
      .getReadCustomerMasterAPI(customer_no, config)
      .then(({ response, code }: { response: any; code: any }) => {
        apiLoading(false);
        console.log("getReadCustomerMasterAPI", response);
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          setCustomerInfo(response);
        }
      });
  };

  const [partsDefaultList, setPartsDefaultList] = useState({
    list_all: [],
    labor_list: [],
    administrative_cost_list: [],
    freight_list: [],
  });

  // page Init
  useEffect(() => {
    const _claim_no = getNewClaimNo();
    if (Object.keys(config).length === 0) {
      apiLoading(true);
    }

    if (!startFlg && Object.keys(config).length > 0) {
      setStartFlg(true);

      const _parts_list = getPartsList(config.affiliate_cd);
      setPartsDefaultList(_parts_list);

      api.getClaimPrintAPIDumi(config);
      if (_claim_no) {
        setClaimType(1);
        apiLoading(true);
        setPermission();
        // fetch the detail info AT1702
        api
          .getReadClaimDetailAPI(Number(_claim_no), config)
          .then(({ code, response }: { code: any; response: any }) => {
            console.log("AT1702 response: ", response);
            if (!getApiResultStatus(code)) {
              errorMessage(response);
              apiLoading(false);
            } else {
              // changes history
              if (response.claim_changed_history) {
                response.claim_changed_history.claim_updated_history =
                  changedHistory(response);
                setChangesBeforeMessage(
                  JSON.parse(JSON.stringify(response.claim_changed_history))
                );
              }
              if (!response.claim_line) {
                response.claim_line = DEFAULT_VALUE_BLANK.claim_line;
              }
              if (!response.claim_address) {
                response.claim_address = DEFAULT_VALUE_BLANK.claim_address;
              }
              // set status
              setClaimStatus(response.status);
              // transport message
              let _transportMessage: any = {
                weight: response.weight,
                shipping_method: response.shipping_method,
                transport_way_pick_up: response.transport_way_pick_up,
                transport_way_return: response.transport_way_return,
                transport_cost_flg: response.transport_cost_flg,
              };
              setTransportMessage(_transportMessage);
              // set response data
              setDataList(JSON.parse(JSON.stringify(response)));
              // set page data
              setFormList(JSON.parse(JSON.stringify(response)));
              // get tool information
              getToolsInformation(response.claim_address);
              if (response.customer_no) {
                getCustomerInfo(response.customer_no);
              } else {
                apiLoading(false);
              }

              // memoMessage初期化
              if (response.claim_note.length > 0) {
                const _list: any = response.claim_note.filter(
                  (item: any) => item.note_type === 99
                );
                const _message: any = _list.length > 0 ? _list[0].note : "";
                setMemoMessage(_message);
              }
            }
          });
      } else {
        handleReturn();
      }
    }
  }, [startFlg, config]);

  const [endUserInfoToolsList, setEndUserInfoToolsList] = useState([]) as any;
  const getToolsInformation = (data: any) => {
    console.log("getToolsInformation", data);
    if (data && data.enduser_no) {
      apiLoading(true);
      api
        .getTools(config, data.enduser_no)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          console.log("get end user detail tools", response);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setEndUserInfoToolsList(response);
          }
        });
    }
  };

  // memo
  const [memoFlag, setMemoFlag] = useState(false);
  const [memoMessage, setMemoMessage] = useState("");
  const changeMemoMessage = (newData: any) => {
    setMemoMessage(newData);
  };

  useEffect(() => {
    if (
      dataList.serial_no !== "" &&
      dataList.serial_no !== "*" &&
      dataList.model_no !== ""
    ) {
      let query_parameters =
        "model_no=" +
        encodeURIComponent(dataList.model_no) +
        "&serial_no=" +
        encodeURIComponent(dataList.serial_no) +
        "&claim_no=" +
        encodeURIComponent(dataList.claim_no ? dataList.claim_no : 0) +
        "&job_no=" +
        encodeURIComponent(0);
      getRepairHistory(query_parameters);
    }
    if (dataList.model_no !== "") {
      getReadModelErrorTypeList(dataList.model_no);
    }
  }, [dataList]);

  // common search method
  const getRepairHistory = (query_parameters: string) => {
    setRepairHistoryList([]);
    // Read Repair Job List AT1708
    return api
      .getReadRepairHistoryListAPI(query_parameters, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("AT1708 response: ", response);
        if (getApiResultStatus(code)) {
          let newList: any = [];
          if (response.history_list && response.history_list.length > 0) {
            response.history_list.map((item: any) => {
              if (item.history_no) {
                let obj: any = {
                  history_no: item.history_no,
                  entry_date: item.entry_date,
                  customer_no: item.customer_no,
                  type_kind: item.type_kind,
                };
                newList.push(obj);
              }
            });
          }
          setRepairHistoryList(newList);
        }
      });
  };

  const getReadModelErrorTypeList = async (model_no: any) => {
    apiLoading(true);
    await api
      .getReadClaimLaborAPI(encodeURIComponent(model_no), config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("get Model Error Type List", code, response);
        apiLoading(false);
        if (getApiResultStatus(code)) {
          if (response.length > 0) {
            if (Array.isArray(response)) {
              response.map((item: any, index: number) => {
                item.index = index;
              });
            }
          }
          setErrorType(response);
          const _charges = setLoborTotal(
            response,
            dataList,
            transportMessage,
            partsDefaultList
          );
          console.log(
            "charges",
            _charges,
            response,
            dataList,
            transportMessage
          );
          setChargesValue(_charges);
        } else {
          if (code === 404) {
            setErrorType([]);
            let message = com("common.constants.messages.MS0100", {
              param1: t("txt_model_no"),
            });
            //setFormErrorListValue("model_no_error", message);
          } else {
            errorMessage(response);
          }
        }
      });
  };

  const [chargesValue, setChargesValue] = useState({
    qty_amount: 0,
    parts_amount: 0,
    total_amount: 0,
    administrativer_amount: 0,
  });

  const openHistory = () => {
    setShowHistoryFlg(true);
  };

  const closeHistory = () => {
    setShowHistoryFlg(false);
  };

  // error message
  const errorMessage = (response: any) => {
    let message = "";
    if (response.message_id) {
      message = getName("messages." + response.message_id, {
        param1: t(response.key),
      });
    } else if (response.message) {
      if (response.status && response.status === 403) {
        message = getName("messages.MA0007");
      } else {
        message = response.message;
      }
    }
    if (message) {
      setAlertShow(true);
      setAlertMessage(message);
      setAlertType("error");
    }
  };

  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // return button
  const handleReturn = () => {
    const path = GetPrivateAppRoutePath(PathKey.CLAIM);
    handleOnClick(path);
  };

  // edit button
  const handleEdit = () => {
    const path =
      GetPrivateAppRoutePath(PathKey.CLAIMDETAIL) +
      `?claimno=${formList.claim_no}`;
    handleOnClick(path);
  };

  //decline button
  const [declineFlag, setDeclineFlag] = useState(false);
  const [declineSuccessFlag, setDeclineSuccessFlag] = useState(false);
  const handleToDeclilne = () => {
    setDeclineFlag(true);
    setMemoStatus("300");
  };
  const handleCloseDecline = () => {
    setDeclineFlag(false);
  };
  const handleToDeclilneFunc = () => {
    setDeclineFlag(false);
    setMemoFlag(true);
  };
  const handleCloseDeclineSuccessChanges = () => {
    handleReturn();
  };

  // approve button
  const [approveFlag, setApproveFlag] = useState(false);
  const [approveSuccessFlag, setApproveSuccessFlag] = useState(false);
  const handleToApprove = () => {
    setApproveFlag(true);
    setMemoStatus("400");
  };
  const handleCloseApprove = () => {
    setApproveFlag(false);
  };
  const handleToApproveFunc = () => {
    setApproveFlag(false);
    // setMemoFlag(true);
    setPatchAPI();
  };
  const handleCloseApproveSuccessChanges = () => {
    handleReturn();
  };
  // memo window
  const [memoStatus, setMemoStatus] = useState("");
  const [memoErrorMessage, setMemoErrorMessage] = useState("");
  const handleCloseMemoChanges = () => {
    console.log(
      "handleCloseMemoChanges",
      memoMessage,
      formList.status,
      memoStatus
    );
    setMemoErrorMessage("");
    if (Number(memoStatus) === 300 && memoMessage === "") {
      setMemoErrorMessage(
        com("common.constants.messages.MS0001", {
          param1: t("summary_decline_note"),
        })
      );
    } else {
      setPatchAPI();
    }
  };
  const handleCloseMemo = () => {
    setMemoFlag(false);
  };
  const setPatchAPI = () => {
    const params = handleDiffChangedData(
      formList,
      dataList,
      memoStatus,
      memoMessage,
      transportMessage,
      chargesValue
    );
    console.log("handleToDeclilneFunc", params);
    if (Object.keys(params).length > 0) {
      apiLoading(true);
      api
        .updateClaimAPI(formList.claim_no, config, params)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          setMemoFlag(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            if (memoStatus === "300") {
              setDeclineSuccessFlag(true);
            } else {
              setApproveSuccessFlag(true);
            }
          }
        });
    } else {
      handleReturn();
    }
  };

  // fileDownlad
  const fileDownload = (row: any) => {
    apiLoading(true);
    // Get Download signed url AT0402
    api
      .getDownloadSignedUrlByWco(formList.claim_no, row.sub_no, config)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          apiLoading(false);
          const url = response.content;
          let w = window.open();
          if (w) {
            w.location.href = url;
          }
        }
      });
  };

  const [downloadMessage, setDownloadMessage] = useState("");
  const handleToPrint = () => {
    const _claim_no = getNewClaimNo();
    setDownloadMessage("");
    apiLoading(true);
    // setTimeFunc(_claim_no, 0);
    handleToPrintFunc(_claim_no, 0);
  };

  let timer: any;
  const setTimeFunc = (_claim_no: any, count: number) => {
    clearTimeout(timer);
    console.log("setTimeFunc", count);

    if (count > 2) {
      setDownloadMessage(
        com("common.constants.messages.download_button_error")
      );
      apiLoading(false);
    } else {
      timer = setTimeout(() => {
        handleToPrintFunc(_claim_no, count + 1);
      }, 3000);
    }
  };

  const handleToPrintFunc = (_claim_no: any, count: number) => {
    console.log("setTimeFunc handleToPrintFunc", count);
    api
      .getDownloadSignedUrlByWco(_claim_no, "", config, "pdf")
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          if (code === "404" || code === 404) {
            setTimeFunc(_claim_no, count);
          } else {
            apiLoading(false);
            errorMessage(response);
          }
        } else {
          clearTimeout(timer);
          // const url = response.signed_url;
          const url = response.content;
          const fileName = `Warranty_Claim_${_claim_no}.pdf`;

          api
            .getDownloadFile(url)
            .then(({ code, response }: { code: any; response: any }) => {
              apiLoading(false);

              if (!getApiResultStatus(code)) {
                errorMessage(response);
              } else {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
              }
            });
        }
      });
  };

  const changeView = (path: any) => {
    clearData();
    handleOnClick(path);
  };

  // 画面リロードのため、データクリア必要
  const clearData = () => {
    setAlertShow(false);
    setAlertMessage("");
    setAlertType("error");
    setFormList(JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)));
    setDataList(JSON.parse(JSON.stringify(DEFAULT_VALUE_BLANK)));
    setClaimType(0);
    setClaimStatus(0);
    setCustomerInfo({});
    setErrorType([]);
    setRepairHistoryList([]);
    setShowHistoryFlg(false);
    // permission
    setUserPermisssionInfo({});
    // transport
    setTransportMessage({
      transport_way_pick_up: false,
      transport_way_return: false,
    });
    // tools
    setEndUserInfoToolsList([]);
    // memo
    setMemoFlag(false);
    setMemoMessage("");
    // charges
    setChargesValue({
      qty_amount: 0,
      parts_amount: 0,
      total_amount: 0,
      administrativer_amount: 0,
    });
    // decline
    setDeclineFlag(false);
    setDeclineSuccessFlag(false);
    // approve
    setApproveFlag(false);
    setApproveSuccessFlag(false);
    // memoStatus
    setMemoStatus("");
    setMemoErrorMessage("");
    // download
    setDownloadMessage("");
    setStartFlg(false);
  };

  // const [preApproveFlagError, setPreApproveFlagError] = useState("");
  const handleToSave = () => {
    let _flg = diffMethods();
    if (_flg) {
      const _message = com("common.constants.messages.MS0117");
      setAlertMessage(_message);
      setAlertType("error");
    } else {
      const params = handleDiffChangedData(
        formList,
        dataList,
        "",
        "",
        transportMessage,
        chargesValue
      );
      console.log("handleToSaveFunc", params);
      if (Object.keys(params).length > 0) {
        apiLoading(true);
        api
          .updateClaimAPI(formList.claim_no, config, params)
          .then(({ code, response }: { code: any; response: any }) => {
            apiLoading(false);
            if (!getApiResultStatus(code)) {
              errorMessage(response);
            } else {
              handleReturn();
            }
          });
      }
    }
  };

  const diffMethods = () => {
    const _claim_no = getNewClaimNo();
    return diffContents(_claim_no, formList, dataList, transportMessage);
  };

  const changeData = (key: string, value: any, subKey: string = "") => {
    if (subKey !== "") {
      setFormList((prevState: any) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setFormList((prevState: any) => ({ ...prevState, [key]: value }));
    }
  };

  const fileDownloadFile = (message: any) => {
    console.log("claim detail file", message);
    api
      .getFileDZ(config, message.link)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("claim detail file response", response);
        if (!getApiResultStatus(code)) {
          errorMessage({ message_id: "MA0003" });
        } else {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download = message.filename;
          a.click();
        }
      });
  };

  const contents = (
    <div id="contents">
      <AlertMesssageBox
        show={alertMessage && alertMessage !== ""}
        message={alertMessage}
        type={alertType}
      />
      {downloadMessage !== "" && (
        <AlertMesssageBox
          show={downloadMessage && downloadMessage !== ""}
          message={downloadMessage}
          type={"warning"}
        />
      )}
      <SummaryStepper
        claimType={claimType}
        handleToDeclilne={handleToDeclilne}
        handleToApprove={handleToApprove}
        formList={formList}
        handleEdit={handleEdit}
        claimStatus={claimStatus}
        handleReturn={handleReturn}
        handleToPrint={handleToPrint}
        handleToSave={handleToSave}
        userPermisssionInfo={userPermisssionInfo}
        dataList={dataList}
      />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <SummaryMessageToCustomer
                    list={formList.claim_note}
                    status={formList.status}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <SummaryAscInformation customerInfo={customerInfo} />
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <SummaryUserCompanyInformation
                    data={formList.claim_address}
                  />
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <SummaryDocuments
                    data={formList.claim_media.claim_media_invoice}
                    fileDownload={fileDownload}
                    formList={formList}
                    endUserInfoToolsList={endUserInfoToolsList}
                    fileDownloadFile={fileDownloadFile}
                  />
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper className={classes.paper}>
                  <SummaryWarrantyCharges
                    formList={formList}
                    chargesValue={chargesValue}
                    errorType={errorType}
                    transportMessage={transportMessage}
                    partsListAdministrativeCost={
                      partsDefaultList.administrative_cost_list
                    }
                    config={config}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>
              <SummaryRepairInformation
                data={formList}
                errorType={errorType}
                repairHistoryList={repairHistoryList}
                openHistory={openHistory}
                closeHistory={closeHistory}
                showHistoryFlg={showHistoryFlg}
                changeView={changeView}
                changeData={changeData}
                // preApproveFlagError={preApproveFlagError}
                formList={formList}
                dataList={dataList}
                userPermisssionInfo={userPermisssionInfo}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.paper}>
              <SummaryPhoto
                data={formList.claim_media.claim_media_picture}
                fileDownload={fileDownload}
              />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <SummaryUsedPartdTable
                dataList={formList.claim_line}
                formList={formList}
                partsListAll={partsDefaultList.list_all}
                changesBeforeCurrency={
                  changesBeforeMessage ? changesBeforeMessage.currency : ""
                }
                changesBeforeList={
                  changesBeforeMessage &&
                  changesBeforeMessage.claim_line &&
                  changesBeforeMessage.claim_line.length > 0
                    ? changesBeforeMessage.claim_line
                    : []
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {claimType !== 0 && (
        <div className={classes.history_container}>
          <div className={classes.history_area}>
            <div className={classes.boxTitle}>{t("update_history")}</div>
            <ClaimDetailHistory
              dataList={formList.claim_updated_history}
              currency={config.currency}
              errorType={errorType}
            />
          </div>
          {changesBeforeMessage &&
            Object.keys(changesBeforeMessage).length > 0 && (
              <div className={classes.change_history_area}>
                <div className={classes.boxTitle}>{t("changes_history")}</div>
                <SummaryChangesHistory
                  dataList={changesBeforeMessage.claim_updated_history}
                  currency={config.currency}
                  errorType={errorType}
                />
              </div>
            )}
        </div>
      )}
      <SummaryPopup
        declineFlag={declineFlag}
        declineSuccessFlag={declineSuccessFlag}
        handleCloseDecline={handleCloseDecline}
        handleCloseDeclineChanges={handleToDeclilneFunc}
        handleCloseDeclineSuccessChanges={handleCloseDeclineSuccessChanges}
        approveFlag={approveFlag}
        approveSuccessFlag={approveSuccessFlag}
        handleCloseApprove={handleCloseApprove}
        handleCloseApproveChanges={handleToApproveFunc}
        handleCloseApproveSuccessChanges={handleCloseApproveSuccessChanges}
        memoMessage={memoMessage}
        memoFlag={memoFlag}
        changeMemoMessage={changeMemoMessage}
        handleCloseMemoChanges={handleCloseMemoChanges}
        handleCloseMemo={handleCloseMemo}
        memoStatus={memoStatus}
        errorMessage={memoErrorMessage}
      />
    </div>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
