import React, { useState } from "react";
// components
import { ModelWindow } from "../model/ModelWindow";
// language
import { useLocale } from "../../hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: 0,
    },
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    buttonWidth: {
      width: "90px",
      height: "30px",
    },
    buttonSetting: {
      marginLeft: "50px",
      width: "90px",
      height: "30px",
    },
  })
);

export const MessageBox = (props: any) => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "models.model_window",
  });

  const contents = <p className={classes.message}>{props.message}</p>;

  const windownButton = (
    <div className={classes.buttonControl}>
      <Button 
        className={classes.buttonWidth}
        variant="contained" 
        onClick={props.handleClose}>
        {t("prompt_button_Cancel")}
      </Button>
      <Button
        className={classes.buttonSetting}
        variant="contained"
        onClick={props.handleSend}
        color="primary"
      >
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  const okButton = (
    <div className={classes.buttonControl}>
      <Button variant="contained" onClick={props.handleClose}>
        {t("prompt_button_OK")}
      </Button>
    </div>
  );

  return (
    <ModelWindow
      title="prompt_title_Confrim"
      contents={contents}
      button={props.buttonType === "ok" ? okButton : windownButton}
      openFlg={props.open}
      handleCloseEvent={props.handleClose}
    />
  );
};
