import React, { useState, useCallback, useEffect } from "react";
// route
import { useHistory } from "react-router-dom";
// component
import { SearchResultTitle } from "./Jobs.SearchResult.Title";
import { SearchResultTable } from "./Jobs.SearchResult.Table";

export const SearchResult = (props: any) => {
  const [numSelected, setNumSelected] = useState(0);
  const [selectedArray, setSelectedArray] = useState<string[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [needResetOrderBy, setNeedResetOrderBy] = useState(false);
  const history = useHistory();
  // change the selected number
  const changeNumSelected = (num: number) => {
    setNumSelected(num);
  };

  // Cancel button click event
  const handleCancel = () => {
    // alert("handleCancel");
    setSelectedArray([]);
    setSelectedRowArray([]);
    changeNumSelected(0);
    setIndeterminate(false);
    setAllChecked(false);
  };

  useEffect(() => {
    // init search
    if (props.searchFlg) {
      handleCancel();
      props.setSearchFlg(false);
      setNeedResetOrderBy(true);
    }
  }, [props.searchFlg]);

  //
  const handleOnClick = useCallback(
    (path: string) => history.push(path),
    [history]
  );

  // Register button click event
  const handleRegister = () => {
    // alert("handleRegister");
    const path = "/private/jobDetail";
    sessionStorage.removeItem("createJobNo");
    handleOnClick(path);
  };

  return (
    <>
      <SearchResultTitle
        numSelected={numSelected}
        selectedArray={selectedArray}
        selectedRowArray={selectedRowArray}
        handleCancel={handleCancel}
        handleRegister={handleRegister}
        handleTechnicianList={props.handleTechnicianList}
        data={props.data}
        alertMessageList={props.alertMessageList}
        setAlertMessageList={props.setAlertMessageList}
        setReSearchFlg={props.setReSearchFlg}
        userPermisssionInfo={props.userPermisssionInfo}
        downloadCSV={props.downloadCSV}
        downloadCSVBySearch={props.downloadCSVBySearch}
      />
      <SearchResultTable
        data={props.data}
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        selectedRowArray={selectedRowArray}
        setSelectedRowArray={setSelectedRowArray}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        changeNumSelected={(num: number) => changeNumSelected(num)}
        needResetOrderBy={needResetOrderBy}
        setNeedResetOrderBy={setNeedResetOrderBy}
        userPermisssionInfo={props.userPermisssionInfo}
        isloadingCompleted={props.isloadingCompleted}
        searchClickFlg={props.searchClickFlg}
        setSearchClickFlg={props.setSearchClickFlg}
      />
    </>
  );
};
