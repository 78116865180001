import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

// components
import { MessageBox } from "../../../modules/components/messageBox/MessageBox";
import { MasterMaintenanceErrorMessageBox } from "./MasterMaintenance.ErrorMessageBox";
import { useCommonInfo } from "../../../modules/hooks/CommonInfoProvider";
// api
import {
  GetRepairDestinationListAPI,
  CreateRepairDestinationAPI,
  DeleteRepairDestinationAPI,
} from "../methods/api.methods";
import {
  deleteItemFromList,
  createDeleteParam,
} from "../methods/common.methods";
import {
  getApiResultStatus,
} from "../../../modules/common.methods";
// valication
import { isEmptyCheck } from "../../../modules/hooks/UseValication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    table: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      borderSpacing: "0",
      "& th": {
        width: "130px",
        fontWeight: "normal",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        textAlign: "right",
        verticalAlign: "Top",
        paddingRight: "15px",
        paddingBottom: "20px",
      },
      "& td": {
        paddingBottom: "20px",
      },
    },
    table02: {
      width: "100%",
      maxWidth: "800px",
      margin: "0 auto",
      borderSpacing: "0",
    },
    mark: {
      color: "red",
    },
    textField: {
      width: "100%",
      height: "30px",
      "& .MuiInputBase-root": {
        lineHeight: "30px",
        height: "30px",
      },
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
        paddingTop: "5px",
      },
    },
    selectContainer: {
      width: "100%",
      height: "30px",
    },
    select: {
      height: "30px",
      width: "700px",
      "& .MuiSelect-selectMenu": {},
      "& .MuiOutlinedInput-input": {
        height: "28px",
        padding: "0",
        paddingLeft: "5px",
        paddingTop: "10px",
      },
    },
    radioContainer: {
      flexDirection: "row",
    },
    radio: {
      "& .MuiRadio-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    buttonContainer: {
      width: "100%",
      maxWidth: "300px",
      paddingTop: "20px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
    },
    buttonContainer02: {
      width: "100%",
      height: "30px",
      maxWidth: "300px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
    },
    divStyle: {
      paddingTop: "20px",
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      border: "1px solid rgba(198, 209, 221)",
    },
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        background: "rgba(240, 242, 244)",
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    tbody: {
      "& td": {
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    theadWidth15: {
      width: "15%",
      textAlign: "center",
    },
    theadWidth70: {
      width: "70%",
      textAlign: "center",
    },
    buttonWidth: {
      width: "90px",
      height: "35px",
    },
  })
);

const repairDestinationDefault = {
  region_cd: "",
  service_center_id: "",
};

export const MasterMaintenanceRepairDestinationEditor = (props: any) => {
  const classes = useStyles();
  const { editData, handleBack, regionData, config } = props;
  const { service_center_id, name } = editData;

  const [pageData, setPageData] = useState([]);
  const [startFlg, setStartFlg] = useState(false);
  const [repairDestination, setRepairDestination] = useState(
    repairDestinationDefault
  );
  // delete
  const [deleteRow, setDeleteRow] = useState([]);
  const [errMessageList, setErrMessageList] = useState([]);
  // Error message box
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);

  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  // loading
  const { 
    setCommonInfo, 
  } = useCommonInfo();

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };

  useEffect(() => {
    if (!startFlg) {
      setStartFlg(true);
      GetRepairDestinationListAPI(config).then((response: any) => {
        setPageData(response);
      });
    }
  });

  const regionList = pageData.filter(
    (item: any) => item.service_center_id == service_center_id
  );

  const addList = () => {
    apiLoading(true);
    const param = {
      region_cd: repairDestination.region_cd,
      service_center_id: repairDestination.service_center_id,
    };

    if(pageData.length !==0) {
      const repairDestinationHaveFlg = pageData.filter(
        (item: any) => item.region_cd
        === repairDestination.region_cd
      );

      if (repairDestinationHaveFlg.length > 0) {
        let errorData: any = []
        let message = {}
        message = com("MS0050", {
          param1: t("repairDestination.region_name"),
        });
        errorData.push(message+ "\n")
        setErrMessageList(errorData);
        setErrorMessageOpen(true);
        apiLoading(false);
      }else {
        creatRepairDestination(param);
      }
    }else {
      creatRepairDestination(param);
     }
  };

  const creatRepairDestination = (param: any) => {
    // AM0801
    CreateRepairDestinationAPI(param, config).then((response: any) => {
      if(response !== undefined && response.code === 400) {
        let errorData: any = []
        let message = {}
        message = com("MA0001", {
          param1: t("repairDestination.region_name"),
        });
        errorData.push(message+ "\n")
        setErrMessageList(errorData);
        setErrorMessageOpen(true);
      }else {
        setPageData(response);
      }
    }).then(() => {
      apiLoading(false);
    });
  };

  const changeData = (type: any, value: any) => {
    const newList = Object.assign({}, repairDestination, {
      [type]: value,
      service_center_id,
    });
    setRepairDestination(newList);
  };

  const [disabledFlg, setDisabledFlg] = React.useState(true);
  const [regionErrorFlg, setRegionErrorFlg] = React.useState(false);
  const [regionErrorMessage, setRegionErrorMessage] = React.useState("");
  const changeDataListen = (itemName: string, itemValue: any) => {
    console.log("changeDataListen", itemName, itemValue);
    if ("region_cd" === itemName) {
      regionValidationCheck(itemValue);
    }
  };

  //Region Name check
  const regionValidationCheck = (value: any) => {
    const flg = isEmptyCheck(value);
    if (flg) {
      setRegionErrorFlg(flg);
      let message = com("MS0046", {
        param1: t("repairDestination.region_name"),
      });
      setRegionErrorMessage(message);
    } else {
      setRegionErrorFlg(flg);
      setRegionErrorMessage("");
    }
  };

  useEffect(() => {
    if (isEmptyCheck(repairDestination.region_cd) || regionErrorFlg) {
      setDisabledFlg(true);
    } else {
      setDisabledFlg(false);
    }
  });

  // delete button click event
  const handleDeleteSet = (row: any) => {
    setOpen(true);
    setDeleteRow(row);
  };

  const handleDelete = (row: any) => {
    apiLoading(true);
    const param = createDeleteParam(row.updated_date);

    DeleteRepairDestinationAPI(row.region_cd, param, config)
    .then(({ code, response }: { code: any; response: any }) => {
      console.log("delete AM0804 repairDestination", code, response);
      if (getApiResultStatus(code)) {
        window.scrollTo(0, 0);
        const newList = deleteItemFromList(row.rid, pageData);
        setPageData(newList);
      }else {
        const message = com(response.message_id, {
          param1: t("API." + response.key),
        });
        setErrMessageList(message);
        setErrorMessageOpen(true);
      }
    }).then(() => {
      apiLoading(false);
    });
  };

  // message box
  const [open, setOpen] = useState(false);
  const handleSend = () => {
    setOpen(false);
    handleDelete(deleteRow);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>{t("repairDestination.service_center_id")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={service_center_id}
              />
            </td>
          </tr>
          <tr>
            <th>{t("repairDestination.name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={name}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.divStyle}>
        <table className={classes.table02}>
          <tbody>
            <tr>
              <th align="left">
                {t("repairDestination.region_name")}
                <span className={classes.mark}>*</span>
              </th>
            </tr>
            <tr>
              <td>
                <FormControl
                  variant="outlined"
                  className={classes.selectContainer}
                  error={regionErrorFlg}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={repairDestination.region_cd}
                    onBlur={(e) =>
                      changeDataListen("region_cd", e.target.value)
                    }
                    onChange={(e) => changeData("region_cd", e.target.value)}
                  >
                    {regionData.map((item: any) => (
                      <MenuItem value={item.region_cd} key={item.region_cd}>
                        {item.region_cd}：{item.region_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{regionErrorMessage}</FormHelperText>
                </FormControl>
              </td>
              <td>
                <Button
                  className={classes.buttonContainer02}
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={disabledFlg}
                  onClick={addList}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t("repairDestination.add")}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.divStyle}>
        <Table className={classes.table02}>
          <TableHead>
            <TableRow className={classes.thead}>
              <TableCell className={classes.theadWidth15}>
                {t("repairDestination.region_code")}
              </TableCell>
              <TableCell className={classes.theadWidth70}>
                {t("repairDestination.region_name")}
              </TableCell>
              <TableCell className={classes.theadWidth15}>
                {t("repairDestination.delete")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {regionList.map((row: any, index: number) => {
              const regionName = regionData.filter(
                (item: any) => item.region_cd == row.region_cd
              );
              return (
                <TableRow key={index}>
                  <TableCell className={classes.nowrap} align="right">
                    {regionName.length > 0 ? regionName[0].region_cd : ""}
                  </TableCell>
                  <TableCell className={classes.nowrap}>
                    {regionName.length > 0 ? regionName[0].region_name : ""}
                  </TableCell>
                  <TableCell className={classes.nowrap} align="center">
                    <IconButton
                      onClick={() => handleDeleteSet(row)}
                      color="secondary"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.buttonContainer}>
        <Button 
          className={classes.buttonWidth}
          variant="contained" 
          onClick={handleBack}>
          {t("repairDestination.back")}
        </Button>
      </div>
      <MessageBox
        open={open}
        handleSend={handleSend}
        handleClose={handleClose}
        message={com("MS0013")}
      />
      {errMessageList.length > 0 &&
        <MasterMaintenanceErrorMessageBox
        open={errorMessageOpen}
        handleClose={handleErrorMessageClose}
        message={errMessageList}
      />
      }
    </form>
  );
};
