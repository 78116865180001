import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// hook
import { useStyles } from "./hooks/control.useStyles";
// UI
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
// constants
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// api
import api from "../../modules/api/Api";
import { getApiResultStatus } from "../../modules/common.methods";
import { Prompt } from "react-router-dom";
import { useNotitificationContext } from "../../modules/hooks/NotificationContext";
import { Sum } from "../../modules/constants/common.constants";
import {
  DisposeIcon,
  PutawayIcon,
  RepairStartIcon,
  ReturnIcon,
} from "../../modules/components/icons/Icons";

export enum TabKey {
  REPAIR,
  PUTAWAY,
  SENDBACK,
  DISPOSE,
}

export const Page: FC = () => {
  const classes = useStyles();
  const { t } = useLocale({
    defaultPath: "modules.components.LocationControl",
  });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  const [value, setValue] = React.useState("Repair");
  const [count, setCount] = useState(0);
  const [textDefaultValue, setTextDefaultValue] = React.useState("");
  const [flg, setFlg] = React.useState("Repair");

  const [locationBackGround, setLocationBackGround] = React.useState(false);
  const [jobNoBackGround, setJobNoBackGround] = React.useState(false);
  const [repairList, setRepairList] = useState([]);
  const [sendBackList, setSendBackList] = useState([]);
  const [disposeList, setDisposeList] = useState([]);
  const [locationValue, setLocationValue] = React.useState("");
  const [repairJobNumberValue, setRepairJobNumberValue] = React.useState("");
  const [modelValue, setModelValue] = React.useState("");
  const [quoteResult, setQuoteResult] = React.useState(0);
  const [updatedDateValue, setUpdatedDateValue] = React.useState("");

  const [repairTotleNumber, setRepairTotleNumber] = useState(0);
  const [sendBackTotleNumber, setSendBackTotleNumber] = useState(0);
  const [disposeTotleNumber, setDisposeTotleNumber] = useState(0);

  const [locationDisable, setLocationDisable] = useState(false);
  const [jobNumberDisable, setJobNumberDisable] = useState(false);

  const [tempJobNoValue, setTempJobNoValue] = React.useState("");
  const [tempPutAwayLocationValue, setTempPutAwayLocationValue] =
    React.useState("");
  const [tempPutAwayjobNoValue, setTempPutAwayJobNoValue] = React.useState("");

  // loading
  const {
    setCommonInfo,
    getCommonConfig,
    setLocationHotKey,
    getLocationHotKey,
    getUserInfoRepair,
    setUserInfoRepair,
  } = useCommonInfo();
  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const config = getCommonConfig() as any;
  const keyValue = getLocationHotKey() as any;
  const userInfoRepair = getUserInfoRepair() as any;
  const [tempKeyValue, setTempKeyValue] = React.useState(
    keyValue.locationTabName
  );

  // API
  const [startFlg, setStartFlg] = useState(false);
  const [initEndedFlg, setInitEndedFlg] = useState(false);

  // alert
  const [alertMessageList, setAlertMessageList] = useState([]) as any;

  // Notification
  const { locationControl, setNumberOfLocationControl } =
    useNotitificationContext();

  // start 初期の時、最新データを取得するため
  useEffect(() => {
    if (!startFlg && config.token) {
      setStartFlg(true);
      getLocationDatalListAPI();
    }
  }, [startFlg, config]);

  useEffect(() => {
    if (config.token && locationControl.length > 0) {
      loadData();
    }
  }, [config, locationControl]);

  // set init value
  useEffect(() => {
    if (initEndedFlg) {
      setInitEndedFlg(false);
      initData(value);
    }
  }, [initEndedFlg]);

  useEffect(() => {
    let tabNamekey: any = "";
    switch (value) {
      case "Repair":
        tabNamekey = TabKey.REPAIR;
        break;
      case "Put Away":
        tabNamekey = TabKey.PUTAWAY;
        break;
      case "Send Back":
        tabNamekey = TabKey.SENDBACK;
        break;
      case "Dispose":
        tabNamekey = TabKey.DISPOSE;
        break;
    }
    setLocationHotKey("locationTabName", tabNamekey);
  }, [value]);

  useEffect(() => {
    if (tempKeyValue != keyValue.locationTabName) {
      switch (keyValue.locationTabName) {
        case TabKey.REPAIR:
          handleClick("Repair");
          setTempKeyValue(keyValue.locationTabName);
          break;
        case TabKey.PUTAWAY:
          handleClick("Put Away");
          setTempKeyValue(keyValue.locationTabName);
          break;
        case TabKey.SENDBACK:
          handleClick("Send Back");
          setTempKeyValue(keyValue.locationTabName);
          break;
        case TabKey.DISPOSE:
          handleClick("Dispose");
          setTempKeyValue(keyValue.locationTabName);
          break;
      }
    }
  });

  const loadData = () => {
    apiLoading(true);
    getServiceCenterInformation(userInfoRepair, locationControl);
  };

  const successMessage = (type: any) => {
    let message = "";
    switch (type) {
      case "patch":
        message = com("MS0019");
        break;
      default:
        message = com("MS0015");
        break;
    }
    const obj: any = {
      no: alertMessageList.length,
      message: message,
      alertType: "success",
    };
    const newList: any = alertMessageList.slice(0);
    newList.push(obj);
    setAlertMessageList(newList);
  };

  const errorMessage = (response: any) => {
    const message = com(response.message_id, {
      param1: "API." + t(response.key),
    });
    const obj: any = {
      no: alertMessageList.length,
      message: message,
      alertType: "error",
    };
    const newList: any = alertMessageList.slice(0);
    newList.push(obj);
    setAlertMessageList(newList);
  };

  // AT0102
  const getRepairJobAPI = (jobNo: any) => {
    return api
      .getReadRepairJobAPI(jobNo, config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AT0102 LocationDatal", response);
        if (getApiResultStatus(code)) {
          if (response) {
            return response;
          }
        } else {
          errorMessage(response);
        }
      });
  };

  // AT0103
  const updateLocationData = (jobNo: any, param: any) => {
    return api
      .updateRepairJobAPI(jobNo, param, config)
      .then(({ code, response }: { code: any; response: any }) => {
        console.log("patch AT0103 userInfo", code, response);
        if (getApiResultStatus(code)) {
          successMessage("patch");
          getLocationDatalListAPI();
        } else {
          errorMessage(response);
        }
      });
  };

  // AT0115
  const getLocationDatalListAPI = () => {
    return api
      .getRepairJobLocationControlAPI(config)
      .then(({ response, code }: { response: any; code: any }) => {
        console.log("get AT0115 LocationDatal", response);
        let list = response;
        if (getApiResultStatus(code)) {
          if (response) {
            if (!userInfoRepair.service_center) {
              api
                .getReadUserInfoAPIForUserinfoRepair(config)
                .then(({ response, code }: { response: any; code: any }) => {
                  console.log("AM0108 response: ", response);
                  if (code !== 200) {
                    errorMessage(response);
                  } else {
                    setUserInfoRepair(response);
                    getServiceCenterInformation(response, list);
                  }
                });
            } else {
              getServiceCenterInformation(userInfoRepair, list);
            }
          }
        } else {
          errorMessage(response);
        }
      });
  };

  const getServiceCenterInformation = (data: any, list: any) => {
    const service_center = data.service_center;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.service_center_id === service_center) {
        let repairInitList = item.repair.location_control;
        setRepairList(repairInitList);
        let sendBackInitList = item.send_back.location_control;
        setSendBackList(sendBackInitList);
        let disposeInitList = item.dispose.location_control;
        setDisposeList(disposeInitList);
        setRepairTotleNumber(item.repair.total_num);
        setSendBackTotleNumber(item.send_back.total_num);
        setDisposeTotleNumber(item.dispose.total_num);
        setNumberOfLocationControl(
          Sum(
            item.repair.total_num,
            item.send_back.total_num,
            item.dispose.total_num
          )
        );
        apiLoading(false);
        setInitEndedFlg(true);
      }
    }
  };

  //show Repair or Dispose data
  const setData = (buttonType: any, dataCount: number, isClickButton: any) => {
    if (buttonType === "Repair") {
      let repairDataList: any = [];
      if (repairList.length > 0) {
        for (let i = 0; i < repairList.length; i++) {
          repairDataList[i] = repairList[i];
        }
        for (let j = 0; j < repairDataList.length; j++) {
          if (!isClickButton) {
            if (
              locationBackGround ||
              jobNoBackGround ||
              (locationBackGround && jobNoBackGround)
            ) {
              if (tempJobNoValue == repairDataList[j].job_no) {
                setHoldData(repairDataList, j);
                return;
              } else {
                setDitailData(repairDataList, dataCount);
              }
            } else {
              setDitailData(repairDataList, dataCount);
            }
          } else {
            setDitailData(repairDataList, dataCount);
          }
        }
      } else {
        setLocationValue("");
        setRepairJobNumberValue("");
        setModelValue("");
        setUpdatedDateValue("");
        setCount(0);
        setLocationBackGround(false);
        setJobNoBackGround(false);
        setAlertShow(false);
        setTempJobNoValue("");
      }
    }
    if (buttonType === "Dispose") {
      if (disposeList.length > 0) {
        let disposeDataList: any = [];
        for (let i = 0; i < disposeList.length; i++) {
          disposeDataList[i] = disposeList[i];
        }
        for (let j = 0; j < disposeDataList.length; j++) {
          if (!isClickButton) {
            if (
              locationBackGround ||
              jobNoBackGround ||
              (locationBackGround && jobNoBackGround)
            ) {
              if (tempJobNoValue == disposeDataList[j].job_no) {
                setHoldData(disposeDataList, j);
                return;
              } else {
                setDitailData(disposeDataList, dataCount);
              }
            } else {
              setDitailData(disposeDataList, dataCount);
            }
          } else {
            setDitailData(disposeDataList, dataCount);
          }
        }
      } else {
        setLocationValue("");
        setRepairJobNumberValue("");
        setModelValue("");
        setUpdatedDateValue("");
        setCount(0);
        setLocationBackGround(false);
        setJobNoBackGround(false);
        setAlertShow(false);
        setTempJobNoValue("");
      }
    }
  };

  //show Put Away or Send Back data
  const setPutAwayOrSendBackData = (buttonType: any) => {
    if (buttonType === "Put Away") {
      if (
        tempPutAwayjobNoValue !== "" ||
        locationBackGround ||
        jobNoBackGround ||
        (locationBackGround && jobNoBackGround)
      ) {
        setRepairJobNumberValue(tempPutAwayjobNoValue);
        if (tempPutAwayLocationValue !== "") {
          setLocationValue(tempPutAwayLocationValue);
        }
        setLocationBackGround(locationBackGround);
        setJobNoBackGround(jobNoBackGround);
        setLocationDisable(locationBackGround);
        setJobNumberDisable(jobNoBackGround);
        setTextDefaultValue("");
      } else {
        setLocationValue("");
        setRepairJobNumberValue("");
        setLocationBackGround(false);
        setJobNoBackGround(false);
        setModelValue("");
        setAlertShow(false);
      }
    }
    if (buttonType === "Send Back") {
      if (
        tempJobNoValue !== "" ||
        locationBackGround ||
        jobNoBackGround ||
        (locationBackGround && jobNoBackGround)
      ) {
        if (searchSendBackValue(tempJobNoValue)) {
          setLocationBackGround(locationBackGround);
          setJobNoBackGround(jobNoBackGround);
          setLocationDisable(locationBackGround);
          setJobNumberDisable(jobNoBackGround);
          setTextDefaultValue("");
        } else {
          setLocationValue("");
          setRepairJobNumberValue("");
          setLocationBackGround(false);
          setJobNoBackGround(false);
          setModelValue("");
          setAlertShow(false);
        }
      } else {
        setLocationValue("");
        setRepairJobNumberValue("");
        setLocationBackGround(false);
        setJobNoBackGround(false);
        setModelValue("");
        setAlertShow(false);
      }
    }
  };

  const setHoldData = (dataList: any, dataCount: any) => {
    setLocationValue(dataList[dataCount].location_no);
    setRepairJobNumberValue(dataList[dataCount].job_no);
    setModelValue(dataList[dataCount].model_no);
    setUpdatedDateValue(dataList[dataCount].updated_date);
    setCount(dataCount);
    setLocationBackGround(locationBackGround);
    setJobNoBackGround(jobNoBackGround);
    setAlertShow(false);
    setLocationDisable(locationBackGround);
    setJobNumberDisable(jobNoBackGround);
    setTempJobNoValue(tempJobNoValue);
  };

  const setDitailData = (dataList: any, dataCount: any) => {
    setLocationValue(dataList[dataCount].location_no);
    setRepairJobNumberValue(dataList[dataCount].job_no);
    setModelValue(dataList[dataCount].model_no);
    setQuoteResult(dataList[dataCount].quote_result);
    setUpdatedDateValue(dataList[dataCount].updated_date);
    setCount(dataCount);
    setLocationBackGround(false);
    setJobNoBackGround(false);
    setAlertShow(false);
    setTempJobNoValue(dataList[dataCount].job_no);
  };

  //search Send Back value
  const searchSendBackValue = (value: any) => {
    let sendBackDataList: any = [];
    for (let i = 0; i < sendBackList.length; i++) {
      sendBackDataList[i] = sendBackList[i];
    }
    for (let j = 0; j < sendBackDataList.length; j++) {
      if (sendBackDataList[j].job_no == value) {
        setLocationValue(sendBackDataList[j].location_no);
        setRepairJobNumberValue(sendBackDataList[j].job_no);
        setModelValue(sendBackDataList[j].model_no);
        setUpdatedDateValue(sendBackDataList[j].updated_date);
        setTempJobNoValue(sendBackDataList[j].job_no);
        return true;
      }
    }
    return false;
  };

  //click tab button
  const handleClick = (e: string) => {
    setValue(e);
    setFlg(e);
    setLocationDisable(false);
    setJobNumberDisable(false);
    setLocationBackGround(false);
    setJobNoBackGround(false);
    if (e === "Put Away" || e === "Send Back") {
      setPutAwayOrSendBackData(e);
    } else {
      if (e === "Repair") {
        setData("Repair", 0, false);
      } else {
        setData("Dispose", 0, false);
      }
    }
  };

  //click before icon button
  const clickBeforeIcon = () => {
    setLocationBackGround(false);
    setJobNoBackGround(false);
    setLocationDisable(false);
    setJobNumberDisable(false);
    setData(value, count - 1, true);
    setCount(count - 1);
  };

  //click next icon button
  const clickNextIcon = () => {
    setLocationBackGround(false);
    setJobNoBackGround(false);
    setLocationDisable(false);
    setJobNumberDisable(false);
    setData(value, count + 1, true);
    setCount(count + 1);
  };

  // get Repair data
  const getRepairData = (editList: any) => {
    return {
      location_no: editList.location_no,
      updated_date: editList.updated_date,
    };
  };

  // get Put Away data
  const getPutAwayData = (editList: any) => {
    return {
      location_no: editList.location_no,
      updated_date: editList.updated_date,
    };
  };

  // get Send Back data
  const getSendBackData = (editList: any) => {
    return {
      location_no: editList.location_no,
      updated_date: editList.updated_date,
    };
  };

  // get Dispose data
  const getDisposeData = (editList: any) => {
    return {
      location_no: editList.location_no,
      status: editList.status,
      updated_date: editList.updated_date,
    };
  };

  // click Next Button
  function clickNextButton(value: any) {
    if (value == "Repair") {
      let editList = { location_no: "", updated_date: updatedDateValue };
      let param = getRepairData(editList);
      updateLocationData(repairJobNumberValue, param);
      clickClearButton();
    } else if (value === "Put Away") {
      let editList = {
        location_no: locationValue,
        updated_date: updatedDateValue,
      };
      let param = getPutAwayData(editList);
      updateLocationData(repairJobNumberValue, param);
      clickClearButton();
    } else if (value === "Send Back") {
      let editList = { location_no: "", updated_date: updatedDateValue };
      let param = getSendBackData(editList);
      updateLocationData(repairJobNumberValue, param);
      clickClearButton();
    } else if (value === "Dispose") {
      let editList: any;
      if (quoteResult === 5) {
        editList = {
          location_no: "",
          status: "800",
          updated_date: updatedDateValue,
        };
      } else {
        editList = {
          location_no: "",
          updated_date: updatedDateValue,
        };
      }

      let param = getDisposeData(editList);
      updateLocationData(repairJobNumberValue, param);
      clickClearButton();
    }
  }

  //click clear button
  const clickClearButton = () => {
    setLocationBackGround(false);
    setJobNoBackGround(false);
    setTextDefaultValue("");
    setLocationValue("");
    setRepairJobNumberValue("");
    setModelValue("");
    setTempPutAwayLocationValue("");
    setTempPutAwayJobNoValue("");
    setTempJobNoValue("");
  };

  // get Location Control data
  const initData = (value: any) => {
    setAlertMessageList([]);
    if (value == "Repair" && repairTotleNumber > 0) {
      handleClick("Repair");
    } else if (
      value == "Repair" &&
      repairTotleNumber == 0 &&
      sendBackTotleNumber > 0
    ) {
      handleClick("Send Back");
    } else if (
      value == "Repair" &&
      repairTotleNumber == 0 &&
      sendBackTotleNumber == 0 &&
      disposeTotleNumber > 0
    ) {
      handleClick("Dispose");
    } else if (value == "Put Away") {
      handleClick("Put Away");
    } else if (value == "Send Back" && sendBackTotleNumber > 0) {
      handleClick("Send Back");
    } else if (
      value == "Send Back" &&
      sendBackTotleNumber == 0 &&
      repairTotleNumber > 0
    ) {
      handleClick("Repair");
    } else if (
      value == "Send Back" &&
      sendBackTotleNumber == 0 &&
      repairTotleNumber == 0 &&
      disposeTotleNumber > 0
    ) {
      handleClick("Dispose");
    } else if (value == "Dispose" && disposeTotleNumber > 0) {
      handleClick("Dispose");
    } else if (
      value == "Dispose" &&
      disposeTotleNumber == 0 &&
      repairTotleNumber > 0
    ) {
      handleClick("Repair");
    } else if (
      value == "Dispose" &&
      disposeTotleNumber == 0 &&
      repairTotleNumber == 0 &&
      sendBackTotleNumber > 0
    ) {
      handleClick("Send Back");
    } else {
      handleClick("Repair");
    }
  };

  //button [clear] disable
  const changeNextDisable = () => {
    if (value === "Put Away") {
      return false;
    }
    if (value === "Send Back") {
      return false;
    }
    return true;
  };

  //button [next] disable
  const buttonNextDisable = () => {
    if (locationDisable && jobNumberDisable) {
      return false;
    }
    return true;
  };

  const changeValue = (event: any) => {
    const text = event.target.value.toString().toUpperCase();
    if (value === "Repair") {
      if (text.length > 0 && text.length <= 5 && text == locationValue) {
        setLocationBackGround(true);
        setTextDefaultValue("");
        setAlertShow(false);
        setLocationDisable(true);
      } else if (
        text.length > 5 &&
        text.length <= 8 &&
        text == repairJobNumberValue
      ) {
        setJobNoBackGround(true);
        setTextDefaultValue("");
        setAlertShow(false);
        setJobNumberDisable(true);
      } else if (text.length != 0) {
        setAlertShow(true);
        setAlertMessage(com("MS0018"));
        setTextDefaultValue(text);
      }
    } else if (value === "Put Away") {
      if (
        text.length > 0 &&
        text.length <= 5 &&
        repairJobNumberValue.length != 0
      ) {
        setLocationBackGround(true);
        setTextDefaultValue("");
        setLocationValue(text);
        setLocationDisable(true);
        setAlertShow(false);
        setTempPutAwayLocationValue(text);
      } else if (text.length > 5 && text.length <= 8) {
        const repairJobData = getRepairJobAPI(text);
        Promise.all([repairJobData]).then((repairJobData) => {
          let receiveData = repairJobData;
          if (undefined != receiveData[0]) {
            if (text == receiveData[0].job_no) {
              setJobNoBackGround(true);
              setLocationValue(receiveData[0].location_no);
              setRepairJobNumberValue(receiveData[0].job_no);
              setModelValue(receiveData[0].model_no);
              setUpdatedDateValue(receiveData[0].updated_date);
              setTextDefaultValue("");
              setJobNumberDisable(true);
              setAlertShow(false);
              setTempPutAwayJobNoValue(receiveData[0].job_no);
            } else {
              setAlertShow(true);
              setAlertMessage(com("MS0018"));
              setTextDefaultValue(text);
            }
          } else {
            setAlertType("error");
          }
        });
      } else if (text.length != 0) {
        setTextDefaultValue(text);
      }
    } else if (value === "Send Back") {
      if (repairJobNumberValue.length != 0) {
        if (text.length > 0 && text.length <= 5 && locationValue == text) {
          setLocationBackGround(true);
          setTextDefaultValue("");
          setAlertShow(false);
          setLocationDisable(true);
        } else if (
          text.length > 5 &&
          text.length <= 8 &&
          repairJobNumberValue == text
        ) {
          setJobNoBackGround(true);
          setTextDefaultValue("");
          setAlertShow(false);
          setJobNumberDisable(true);
        } else if (text.length != 0) {
          setAlertShow(true);
          setAlertMessage(com("MS0018"));
          setTextDefaultValue(text);
        }
      } else if (text.length > 5 && text.length <= 8) {
        if (text.length > 5 && text.length <= 8) {
          if (searchSendBackValue(text)) {
            setTextDefaultValue("");
            setAlertShow(false);
          } else if (text.length != 0) {
            setAlertShow(true);
            setAlertMessage(com("MS0018"));
            setTextDefaultValue(text);
          }
        }
      } else if (text.length != 0) {
        setAlertShow(true);
        setAlertMessage(com("MS0018"));
        setTextDefaultValue(text);
      }
    } else if (value === "Dispose") {
      if (text.length > 0 && text.length <= 5 && locationValue == text) {
        setLocationBackGround(true);
        setTextDefaultValue("");
        setAlertShow(false);
        setLocationDisable(true);
      } else if (
        text.length > 5 &&
        text.length <= 8 &&
        repairJobNumberValue == text
      ) {
        setJobNoBackGround(true);
        setTextDefaultValue("");
        setAlertShow(false);
        setJobNumberDisable(true);
      } else if (text.length != 0) {
        setAlertShow(true);
        setAlertMessage(com("MS0018"));
        setTextDefaultValue(text);
      }
    } else {
      setTextDefaultValue(text);
    }
  };

  const beforeIconButtonDisable = () => {
    if (value == "Repair" && count == 0) {
      return true;
    }
    if (value == "Dispose" && count == 0) {
      return true;
    }
    return false;
  };

  const nextIconButtonDisable = () => {
    if (
      value == "Repair" &&
      (count + 1 == repairList.length || repairList.length == 0)
    ) {
      return true;
    }
    if (
      value == "Dispose" &&
      (count + 1 == disposeList.length || disposeList.length == 0)
    ) {
      return true;
    }
    return false;
  };

  const onChangeValue = (event: any) => {
    const text = event.target.value.toString().toUpperCase();
    setTextDefaultValue(text);
  };

  const changeColor = (value: string) => {
    return flg === value ? "primary" : "default";
  };

  const changeDirection = () => {
    return value === "Put Away" ? "row-reverse" : "row";
  };

  // alert
  const [alertShow, setAlertShow] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("Message");
  const [alertType, setAlertType] = React.useState("error");

  const contents = (
    <>
      <Prompt
        message={() => {
          setLocationHotKey("locationTabName", TabKey.REPAIR);
          return true;
        }}
      />
      <Grid container className={classes.root} spacing={2}>
        {alertMessageList.length > 0 && (
          <Grid item xs={12}>
            <Grid container justify="center">
              <div className={classes.width700}>
                <AlertMesssageBox
                  key={alertMessageList[alertMessageList.length - 1].no}
                  show={true}
                  message={
                    alertMessageList[alertMessageList.length - 1].message
                  }
                  type={alertMessageList[alertMessageList.length - 1].alertType}
                />
              </div>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <Paper className={classes.paper}>
                <Button
                  variant="contained"
                  color={changeColor("Repair")}
                  className={classes.button}
                  onClick={() => handleClick("Repair")}
                  startIcon={<RepairStartIcon />}
                >
                  {repairTotleNumber > 0 && (
                    <Badge badgeContent={repairTotleNumber} color="secondary">
                      <span className={classes.buttonText}>
                        {t("btn_repair")}
                      </span>
                    </Badge>
                  )}
                  {repairTotleNumber <= 0 && (
                    <span className={classes.buttonText}>
                      {t("btn_repair")}
                    </span>
                  )}
                </Button>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Button
                  variant="contained"
                  color={changeColor("Put Away")}
                  className={classes.button}
                  onClick={() => handleClick("Put Away")}
                  startIcon={<PutawayIcon />}
                >
                  {t("btn_put_away")}
                </Button>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Button
                  variant="contained"
                  color={changeColor("Send Back")}
                  className={classes.button}
                  onClick={() => handleClick("Send Back")}
                  startIcon={<ReturnIcon />}
                >
                  {sendBackTotleNumber > 0 && (
                    <Badge badgeContent={sendBackTotleNumber} color="secondary">
                      <span className={classes.buttonText}>
                        {t("btn_send_back")}
                      </span>
                    </Badge>
                  )}
                  {sendBackTotleNumber <= 0 && (
                    <span className={classes.buttonText}>
                      {t("btn_send_back")}
                    </span>
                  )}
                </Button>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Button
                  variant="contained"
                  color={changeColor("Dispose")}
                  className={classes.button}
                  onClick={() => handleClick("Dispose")}
                  startIcon={<DisposeIcon />}
                >
                  {disposeTotleNumber > 0 && (
                    <Badge badgeContent={disposeTotleNumber} color="secondary">
                      <span className={classes.buttonText}>
                        {t("btn_dispose")}
                      </span>
                    </Badge>
                  )}
                  {disposeTotleNumber <= 0 && (
                    <span className={classes.buttonText}>
                      {t("btn_dispose")}
                    </span>
                  )}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <div className={classes.width700}>
              {value == "Put Away" && (
                <Grid container justify="center">
                  <h1>{t("put_away")}</h1>
                </Grid>
              )}
              {value == "Send Back" && (
                <Grid container justify="center">
                  <h1>{t("send_back")}</h1>
                </Grid>
              )}
              {value == "Repair" && (
                <Grid container justify="space-between">
                  <IconButton
                    color="primary"
                    component="span"
                    disabled={beforeIconButtonDisable()}
                  >
                    <NavigateBeforeIcon onClick={() => clickBeforeIcon()} />
                  </IconButton>
                  <h1>{t("repair")}</h1>
                  <IconButton
                    color="primary"
                    component="span"
                    disabled={nextIconButtonDisable()}
                  >
                    <NavigateNextIcon onClick={() => clickNextIcon()} />
                  </IconButton>
                </Grid>
              )}
              {value == "Dispose" && (
                <Grid container justify="space-between">
                  <IconButton
                    color="primary"
                    component="span"
                    disabled={beforeIconButtonDisable()}
                  >
                    <NavigateBeforeIcon onClick={() => clickBeforeIcon()} />
                  </IconButton>
                  <h1>{t("dispose")}</h1>
                  <IconButton
                    color="primary"
                    component="span"
                    disabled={nextIconButtonDisable()}
                  >
                    <NavigateNextIcon onClick={() => clickNextIcon()} />
                  </IconButton>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction={changeDirection()}
            justify="center"
            spacing={1}
          >
            <Grid item>
              <Paper
                className={
                  locationBackGround ? classes.paper03 : classes.paper02
                }
              >
                <div
                  className={
                    locationBackGround
                      ? classes.fontCenterModel02
                      : classes.fontCenterModel
                  }
                >
                  {t("location")}
                </div>
                <div>
                  <h1
                    className={
                      locationBackGround
                        ? classes.fontCenter02
                        : classes.fontCenter
                    }
                  >
                    {locationValue}
                  </h1>
                </div>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                className={jobNoBackGround ? classes.paper03 : classes.paper02}
              >
                <div
                  className={
                    jobNoBackGround
                      ? classes.fontCenterModel02
                      : classes.fontCenterModel
                  }
                >
                  {t("repair_job_number")}
                </div>
                <div className={classes.divStyle}>
                  <h1
                    className={
                      jobNoBackGround
                        ? classes.fontCenter02
                        : classes.fontCenter
                    }
                  >
                    {repairJobNumberValue}
                  </h1>
                </div>
                <div
                  className={
                    jobNoBackGround
                      ? classes.fontCenterModel02
                      : classes.fontCenterModel
                  }
                >
                  {t("model")}
                  <b
                    className={
                      jobNoBackGround
                        ? classes.fontCenterModel02
                        : classes.fontCenterModel
                    }
                  >
                    &nbsp;{modelValue}
                  </b>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <div className={classes.width700}>
              <AlertMesssageBox
                show={alertShow}
                message={alertMessage}
                type={alertType}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <TextField
              id="filled-full-width"
              label={t("scan_Job")}
              className={classes.textFieldInput}
              placeholder=""
              InputLabelProps={{
                shrink: false,
              }}
              variant="filled"
              value={textDefaultValue}
              inputProps={{ maxLength: 8 }}
              onChange={onChangeValue}
              onBlur={changeValue}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  changeValue(e);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={10}>
            <Grid item></Grid>
            <Grid item>
              <Button
                disabled={changeNextDisable()}
                variant="contained"
                className={classes.buttonToNextLocation}
                size="large"
                color="primary"
                onClick={() => clickClearButton()}
              >
                {t("btn_clear")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={buttonNextDisable()}
                variant="contained"
                className={classes.buttonToNextLocation}
                size="large"
                color="primary"
                onClick={() => clickNextButton(value)}
              >
                {t("btn_next")}
              </Button>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
