import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
// hook
import { useStyles } from "../hooks/editor.useStyles";
// constants
import { TabKey } from "../MasterMaintenance.constants";
import {
  isEmptyCheck,
  emailValidation,
  checkMaxLength,
  checkUmlautsCharUnicode,
} from "../../../modules/hooks/UseValication";

export const MasterMaintenanceCustomerMasterEditor = (props: any) => {
  const classes = useStyles();
  const { changeData, editData, handleBack, handleSubmit } = props;
  const {
    customer_no,
    customer_name,
    memorandum,
    mail_address_repair,
    sms_receive_notification,
    mail_receive_notification,
    asc_flg,
    wco_picture,
    wco_auto_approve,
    wco_credit_note_interval,
  } = editData;

  const { t } = useLocale({
    defaultPath: "modules.components.MasterMaintenance.customerMaster",
  });
  const { com } = useLocale({ defaultPath: "common.constants" });

  const [memorandumErrorFlg, setMemorandumErrorFlg] = React.useState(false);
  const [memorandumErrorMessage, setMemorandumErrorMessage] =
    React.useState("");
  const [mailAddressRepairErrorFlg, setMailAddressRepairErrorFlg] =
    React.useState(false);
  const [mailAddressRepairErrorMessage, setMailAddressRepairErrorMessage] =
    React.useState("");
  const [wcoCreditNoteIntervalErrorFlg, setWcoCreditNoteIntervalErrorFlg] =
    React.useState(false);
  const [
    wcoCreditNoteIntervalErrorMessage,
    setWcoCreditNoteIntervalErrorMessage,
  ] = React.useState("");
  const [disabledFlg, setDisabledFlg] = React.useState(true);

  const changeDataListen = (itemName: string, itemValue: any) => {
    if ("mail_address_repair" === itemName) {
      mailAddressValidationCheck(itemValue);
    }
    if ("memorandum" === itemName) {
      memorandumValidationCheck(itemValue);
    }
    if (asc_flg) {
      if ("wco_credit_note_interval" === itemName) {
        wcoCreditNoteIntervalValidationCheck(itemValue);
      }
    }
  };

  useEffect(() => {
    if (
      !checkMaxLength(memorandum, 200) ||
      memorandumErrorFlg ||
      !checkMaxLength(mail_address_repair, 50) ||
      mailAddressRepairErrorFlg
    ) {
      setDisabledFlg(true);
    } else {
      if (asc_flg === false) {
        setDisabledFlg(false);
      } else {
        if (isEmptyCheck(wco_credit_note_interval) ||
          wcoCreditNoteIntervalErrorFlg) {
          setDisabledFlg(true);
        } else {
          setDisabledFlg(false);
        }
      }
    }
  });

  useEffect(() => {
    if (asc_flg === false) {
      editData.wco_picture = false;
      editData.wco_auto_approve = false;
      editData.wco_credit_note_interval = "";
    }
  }, [asc_flg]);

  //Mail Address check
  const mailAddressValidationCheck = (value: any) => {
    let flg = false;
    let message = "";
    if (value !== "") {
      if (!checkMaxLength(value, 50)) {
        flg = true;
        message = com("messages.MS0002", {
          param1: t("mail_address"),
          param2: 50,
        });
      } else if (checkUmlautsCharUnicode(value)) {
        message = com("messages.MS0122");
        flg = true;
      } else if (!emailValidation(value)) {
        message = com("messages.MS0040");
        flg = true;
      }
    }
    setMailAddressRepairErrorFlg(flg);
    setMailAddressRepairErrorMessage(message);
  };

  //memorandum check
  const memorandumValidationCheck = (value: any) => {
    let flg = false;
    if (!checkMaxLength(value, 200)) {
      flg = !checkMaxLength(value, 200);
      let message = com("messages.MS0002", {
        param1: t("memorandum"),
        param2: 200,
      });
      setMemorandumErrorFlg(flg);
      setMemorandumErrorMessage(message);
    } else {
      setMemorandumErrorFlg(flg);
      setMemorandumErrorMessage("");
    }
  };

  //wco Credit Note Interval check
  const wcoCreditNoteIntervalValidationCheck = (value: any) => {
    const flg: boolean = isEmptyCheck(value);
    if (flg) {
      let message = com("messages.MS0046", {
        param1: t("wco_credit_note_interval"),
      });
      setWcoCreditNoteIntervalErrorFlg(true);
      setWcoCreditNoteIntervalErrorMessage(message);
    } else {
      setWcoCreditNoteIntervalErrorFlg(false);
      setWcoCreditNoteIntervalErrorMessage("");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <table className={classes.tableUserInfo}>
        <tbody>
          <tr>
            <th>{t("customer_number")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={customer_no}
              />
            </td>
          </tr>
          <tr>
            <th>{t("customer_name")}</th>
            <td>
              <TextField
                disabled
                variant="outlined"
                className={classes.textField}
                value={customer_name}
              />
            </td>
          </tr>
          <tr>
            <th>{t("mail_address_repair")}</th>
            <td>
              <TextField
                error={mailAddressRepairErrorFlg}
                helperText={mailAddressRepairErrorMessage}
                variant="outlined"
                className={classes.textField}
                value={mail_address_repair}
                onBlur={(e) =>
                  changeDataListen("mail_address_repair", e.target.value)
                }
                onChange={(e) =>
                  changeData("mail_address_repair", e.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <th>{t("memorandum")}</th>
            <td>
              <TextField
                error={memorandumErrorFlg}
                helperText={memorandumErrorMessage}
                variant="outlined"
                className={classes.textAreaForCustomer}
                value={memorandum}
                onBlur={(e) => changeDataListen("memorandum", e.target.value)}
                onChange={(e) => changeData("memorandum", e.target.value)}
                multiline
                rows={4}
              />
            </td>
          </tr>
          <tr>
            <th>{t("sms_receive_notification")}</th>
            <td>
              <Checkbox
                className={classes.checkboxLayout}
                color="primary"
                checked={
                  sms_receive_notification !== undefined
                    ? sms_receive_notification
                    : false
                }
                onChange={(e) =>
                  changeData("sms_receive_notification", e.target.checked)
                }
              />
            </td>
          </tr>
          <tr>
            <th>{t("mail_receive_notification")}</th>
            <td>
              <Checkbox
                className={classes.checkboxLayout}
                color="primary"
                checked={
                  mail_receive_notification !== undefined
                    ? mail_receive_notification
                    : false
                }
                onChange={(e) =>
                  changeData("mail_receive_notification", e.target.checked)
                }
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={classes.groupArea}>
                <h3 className={classes.groupTitle}>{t("wco")}</h3>
                <table className={classes.tableUserInfo02}>
                  <tbody>
                    <tr>
                      <th>{t("asc_flg")}</th>
                      <td>
                        <Checkbox
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={asc_flg !== undefined ? asc_flg : false}
                          onChange={(e) =>
                            changeData("asc_flg", e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("wco_picture")}</th>
                      <td>
                        <Checkbox
                          disabled={!asc_flg}
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={
                            wco_picture !== undefined ? wco_picture : false
                          }
                          onChange={(e) =>
                            changeData("wco_picture", e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("wco_auto_approve")}</th>
                      <td>
                        <Checkbox
                          disabled={!asc_flg}
                          className={classes.checkboxLayout}
                          color="primary"
                          checked={
                            wco_auto_approve !== undefined
                              ? wco_auto_approve
                              : false
                          }
                          onChange={(e) =>
                            changeData("wco_auto_approve", e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {t("wco_credit_note_interval")}
                        <span
                          className={asc_flg ? classes.mark : classes.mark2}
                        >
                          *
                        </span>
                      </th>
                      <td>
                        <FormControl
                          disabled={!asc_flg}
                          variant="outlined"
                          className={classes.selectContainer}
                        >
                          <Select
                            id="demo-simple-select-outlined"
                            error={wcoCreditNoteIntervalErrorFlg}
                            className={classes.select}
                            value={
                              wco_credit_note_interval !== undefined
                                ? wco_credit_note_interval
                                : ""
                            }
                            onBlur={(e) =>
                              changeDataListen(
                                "wco_credit_note_interval",
                                e.target.value
                              )
                            }
                            onChange={(e) =>
                              changeData(
                                "wco_credit_note_interval",
                                e.target.value
                              )
                            }
                          >
                            {Array.isArray(com("wco_credit_note_interval")) ? (
                              com("wco_credit_note_interval").map(
                                (manufacturedMonth: any) => (
                                  <MenuItem
                                    key={manufacturedMonth.code}
                                    value={manufacturedMonth.code}
                                  >
                                    <ListItemText
                                      primary={manufacturedMonth.name}
                                    />
                                  </MenuItem>
                                )
                              )
                            ) : (
                              <></>
                            )}
                          </Select>
                          <FormHelperText>
                            {wcoCreditNoteIntervalErrorMessage}
                          </FormHelperText>
                        </FormControl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonWidth2}
          variant="contained"
          onClick={handleBack}
        >
          {t("back")}
        </Button>
        <Button
          className={classes.buttonWidth2}
          disabled={disabledFlg}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(TabKey.customerMaster)}
        >
          {t("submit")}
        </Button>
      </div>
    </form>
  );
};
